import { uniqBy } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';

import NewClauseDetails from './NewClauseDetails';
import {
    DealSectionFragment,
    LinkedClauseFragment,
    SearchClauseBankDocument,
    useNewClauseMutation,
    useNewSectionGroupClauseMutation,
    useNewSectionGroupGroupClauseMutation,
    useSearchClauseBankQuery
} from '../../../generated/graphql';
import { Clauses, SortedClauses } from '../../../pages/Settings/ClauseBank';
import Select from '../../Inputs/Select';
import Loading from '../../Loading';
import Window from '../../Window';

type openDetails = {
    section?: string;
};

type NewClauseWindowProps = {
    dealID?: string | null;
    organisationID: string;
    openDetails?: openDetails;
    sections?: DealSectionFragment[];
    simple?: boolean;
    refetch: () => void;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
};
export type LinkedClause = LinkedClauseFragment;

const NewClauseWindow = ({
    dealID = null,
    organisationID,
    simple = false,
    sections,
    openDetails,
    refetch,
    ...props
}: NewClauseWindowProps) => {
    // Error message
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    // Sections and Groups Selection
    const [sectionName, setSectionName] = useState<string | null>();
    const [groupName, setGroupName] = useState<string | null>();
    const [clauseGroupName, setClauseGroupName] = useState<string | null>();
    const [clauses, setClauses] = useState<SortedClauses>({});

    const { data } = useSearchClauseBankQuery({
        variables: {
            organisationID: organisationID ? [organisationID] : [],
            query: ''
        },
        fetchPolicy: 'cache-and-network',
        notifyOnNetworkStatusChange: true
    });

    useEffect(() => {
        // Sort clauses into sections/groups
        if (data) return sortResponse(data.clauseBank);
    }, [data]);

    const sortResponse = (results: Clauses) => {
        let sortedClauses: SortedClauses = {};

        if (results) {
            results
                .filter((clause) => {
                    return clause?.section?.title;
                })
                .map((clause) => {
                    let sectionName = clause.section.title;
                    let groupName = clause.section.group.title;
                    sortedClauses[sectionName] = sortedClauses[sectionName] ? sortedClauses[sectionName] : {};
                    sortedClauses[sectionName][groupName] = sortedClauses[sectionName][groupName]
                        ? sortedClauses[sectionName][groupName]
                        : [];

                    return sortedClauses[sectionName][groupName].push(clause);
                });
        }

        setClauses(sortedClauses);
    };

    // Add clauses dropdowns
    const [dropdowns, setDropdowns] = useState<
        {
            title?: string | null;
            groups: {
                title?: string | null;
                groups: {
                    title?: string | null;
                }[];
            }[];
        }[]
    >([]);

    const [linkedClauses, setLinkedClauses] = useState<LinkedClause[]>([]);

    const addLinkedClause = (clause: LinkedClause) => {
        setLinkedClauses([...linkedClauses, clause]);
    };
    const removeLinkedClause = (clause: LinkedClause) => {
        setLinkedClauses(linkedClauses.filter((linkedClause) => linkedClause._id !== clause._id));
    };

    useEffect(() => {
        const _dropdowns: typeof dropdowns =
            Object.keys(clauses).map((section, sectionIndex) => ({
                title: Object.keys(clauses)[sectionIndex],
                groups:
                    Object.keys(clauses[section]).map((group) => ({
                        title: group,
                        groups:
                            uniqBy(
                                clauses[section][group].map((clause) => ({
                                    title: clause.section?.group.group ? clause.section?.group.group.title : ''
                                })),
                                'title'
                            ) ?? []
                    })) ?? []
            })) ?? [];
        setDropdowns(_dropdowns);
    }, [clauses]);

    // Input Refs
    const shortFormRef = useRef<HTMLInputElement>(null);
    const longFormRef = useRef<HTMLInputElement>(null);
    const valueRef = useRef<HTMLInputElement>(null);
    const valueSuffixRef = useRef<HTMLInputElement>(null);
    const clauseBankRef = useRef<HTMLInputElement>(null);

    // Clause mutations
    const [newClauseBank, { loading: newClauseBankLoading, error: newClauseBankError }] = useNewClauseMutation();

    const [newSectionGroupClause, { loading: newSectionGroupClauseLoading }] = useNewSectionGroupClauseMutation();
    const [newSectionGroupGroupClause, { loading: newSectionGroupGroupClauseLoading }] =
        useNewSectionGroupGroupClauseMutation();

    // Submit button
    // Add to clause bank if selected, then add to template
    const handleAddClauseClick = async () => {
        const shortForm = shortFormRef.current?.value;
        const longForm = longFormRef.current?.value;
        const value = valueRef.current?.value;
        const valueSuffix = valueSuffixRef.current?.value;
        // const dealBreaker = dealBreakerRef.current?.checked
        const clauseBank = clauseBankRef.current?.checked;

        if (!sectionName || sectionName.trim().length === 0 || !groupName || groupName.trim().length === 0)
            return setErrorMessage('Please enter a section and group name');

        let newClauseId;

        if (clauseBank) {
            const newClauseData = await newClauseBank({
                variables: {
                    organisationID,
                    value,
                    valueSuffix,
                    shortForm,
                    longForm,
                    section: sectionName,
                    level1Group: groupName,
                    level2Group: clauseGroupName,
                    linked: {
                        add: linkedClauses.map((linkedClause) => linkedClause._id)
                    }
                },
                refetchQueries: [
                    {
                        query: SearchClauseBankDocument,
                        variables: { query: '', organisationID }
                    }
                ]
            });

            if (newClauseBankError) {
                return;
                // handleFetchError(
                //     "Could not add the clause to the clause bank",
                //     newClauseBankError,
                //     newClauseBankData?.organisationClauseAdd
                // )
            }

            newClauseId = newClauseData.data?.organisationClauseAdd._id!;
        }

        if (!dealID) {
            return; //only save to the clause bank, nothing else
        }

        // If level 2 group, create an invisible clause
        if (clauseGroupName) {
            await newSectionGroupGroupClause({
                variables: {
                    dealID,
                    sectionTitle: sectionName ?? 'Untitled Section',
                    groupTitle: groupName ?? 'Untitled Group',
                    clauseGroupTitle: clauseGroupName,

                    clauseIndex: 0,
                    value: value,
                    valueSuffix: valueSuffix,
                    shortForm: shortForm,
                    longForm: longForm,
                    linked: linkedClauses,
                    clauseRef: newClauseId
                }
            });
        } else {
            await newSectionGroupClause({
                variables: {
                    dealID,
                    sectionTitle: sectionName ?? 'Untitled Section',
                    groupTitle: groupName ?? 'Untitled Group',

                    clauseIndex: 0,
                    value: value,
                    valueSuffix: valueSuffix,
                    shortForm: shortForm,
                    longForm: longForm,
                    linkedClauses: linkedClauses,
                    clauseRef: newClauseId
                }
            });
        }

        refetch();
        props.setShow(false);
    };

    if (newSectionGroupClauseLoading || newClauseBankLoading || newSectionGroupGroupClauseLoading)
        return (
            <Window title="New Clause" width="w-2/5" {...props}>
                <Loading />
            </Window>
        );

    return (
        <Window
            title="New Clause"
            width="w-2/5"
            primaryBtn
            primaryBtnText="Add Clause"
            onPrimaryBtnClick={handleAddClauseClick}
            {...props}
        >
            <div>
                <form className="flex flex-col px-2 pt-2 pb-16 md:py-0" onSubmit={(e) => e.preventDefault()}>
                    <p className="mt-2 text-gray-600 font-medium text-sm mb-0.5">Group</p>
                    <Select
                        data={
                            dropdowns?.map((item) => ({
                                id: item.title,
                                text: item.title
                            })) ?? []
                        }
                        createable
                        placeholder="Clause Group"
                        className="w-full md:w-1/2"
                        onItemClick={(section) => setSectionName(section)}
                    />
                    <p className="mt-2 text-gray-600 font-medium text-sm mb-0.5">Heading</p>
                    <Select
                        data={
                            dropdowns
                                ?.find((item) => item.title === sectionName)
                                ?.groups.map((item) => ({
                                    id: item.title,
                                    text: item.title
                                })) ?? []
                        }
                        disabled={!sectionName}
                        createable
                        clearable
                        placeholder="Clause Heading"
                        className="w-full md:w-1/2"
                        onItemClick={(group) => setGroupName(group)}
                    />
                    <p className="mt-2 text-gray-600 font-medium text-sm mb-0.5">Sub-list Heading</p>
                    <Select
                        data={
                            dropdowns
                                ?.find((item) => item.title === sectionName)
                                ?.groups?.find((item) => item.title === groupName)
                                ?.groups.map((item) => ({
                                    id: item.title,
                                    text: item.title
                                })) ?? []
                        }
                        disabled={!sectionName || !groupName}
                        createable
                        clearable
                        placeholder="Clause Sub-List Heading"
                        className="w-full md:w-1/2"
                        onItemClick={(clauseGroup) => setClauseGroupName(clauseGroup)}
                    />
                    <div className="mt-2">
                        <NewClauseDetails
                            organisationID={organisationID}
                            simple={simple}
                            sectionName={sectionName}
                            groupName={groupName}
                            clauseGroupName={clauseGroupName}
                            valueRef={valueRef}
                            suffixRef={valueSuffixRef}
                            shortFormRef={shortFormRef}
                            longFormRef={longFormRef}
                            linkedClauses={linkedClauses}
                            addLinkedClauses={addLinkedClause}
                            removeLinkedClauses={removeLinkedClause}
                        />
                    </div>
                    {!simple && (
                        <div className="flex flex-row items-center mr-6">
                            <label
                                htmlFor="deal-breaker"
                                className="text-sm text-newGray-darkish mr-2 text-center md:text-left"
                            >
                                Save To Clause Bank
                            </label>
                            <input
                                type="checkbox"
                                ref={clauseBankRef}
                                className="form-checkbox p-2 rounded-sm bg-white text-newGray-darkish border border-newGray-darkish focus:outline-none"
                            />
                        </div>
                    )}
                    {errorMessage && (
                        <div className="bg-red-200 border border-red-600 rounded py-2 px-4 my-4 mt-2">
                            <span className="text-center text-red-600">{errorMessage}</span>
                        </div>
                    )}
                </form>
            </div>
        </Window>
    );
};

export default NewClauseWindow;
