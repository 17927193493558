import { useApolloClient } from '@apollo/client';
import React, { useEffect, useState } from 'react';

import {
    PracticeType,
    useGetLayoutUserInfoQuery,
    useGetUserOrganisationQuery,
    useUpdateOrganisationMutation
} from '../../generated/graphql';
import { Button } from '../../shared/button';
import OrganisationAvatarUpload from '../../shared/file/OrganisationAvatarUpload';
import { handleFetchError } from '../../shared/helpers';
import Input, { InputType } from '../../shared/Inputs';
import Select from '../../shared/Inputs/Select';
import Loading from '../../shared/Loading';

const Company = () => {
    const client = useApolloClient();
    const { data, loading, error } = useGetUserOrganisationQuery();
    const { data: userData } = useGetLayoutUserInfoQuery();

    const [companyName, setCompanyName] = useState('');
    const [companyWebsite, setCompanyWebsite] = useState('');
    const [companyAddressLine1, setCompanyAddressLine1] = useState('');
    const [companyAddressLine2, setCompanyAddressLine2] = useState('');
    const [companyAddressCity, setCompanyAddressCity] = useState('');
    const [companyAddressPostCode, setCompanyAddressPostCode] = useState('');
    const [straightToLeaseStart, setStraightToLeaseStart] = useState('');
    const [straightToLeaseEnd, setStraightToLeaseEnd] = useState('');
    const [optionId, setOptionId] = useState<PracticeType | undefined>(
        data?.user.organisation.practiceType ? data?.user.organisation.practiceType : undefined
    );
    const [practiceTypeLabel, setPracticeTypeLabel] = useState<string | undefined>();

    const [isUpdating, setIsUpdating] = useState(false);

    useEffect(() => {
        // Check for errors
        handleFetchError('Could not load your company details', error, data?.user);
        if (data?.user.__typename === 'User') {
            userData?.user.type === 'AGENT' && setOptionId(data?.user.organisation.practiceType);
            setCompanyName(data.user.organisation.name ?? '');
            setCompanyWebsite(data.user.organisation.website ?? '');
            setCompanyAddressLine1(data.user.organisation.address?.line1 ?? '');
            setCompanyAddressLine2(data.user.organisation.address?.line2 ?? '');
            setCompanyAddressCity(data.user.organisation.address?.city ?? '');
            setCompanyAddressPostCode(data.user.organisation.address?.postCode ?? '');
            setStraightToLeaseStart(data.user.organisation.straightToLease?.start ?? '');
            setStraightToLeaseEnd(data.user.organisation.straightToLease?.end ?? '');
        }
    }, [data, error]);

    const [updateCompany, { error: updateCompanyError, loading: updateCompanyLoading }] =
        useUpdateOrganisationMutation();

    useEffect(() => {
        switch (optionId ? optionId : data?.user.organisation.practiceType) {
            case PracticeType.Legal:
                return setPracticeTypeLabel('Legal Practice');
            case PracticeType.Agency:
                return setPracticeTypeLabel('Agency Practice');
            case PracticeType.PropertyManagement:
                return setPracticeTypeLabel('Property Management Practice');
        }
    }, [data?.user.organisation.practiceType, optionId]);

    const handleSaveClick = async () => {
        if (!data || data.user.__typename !== 'User') return;

        const input = {
            name: companyName,
            website: companyWebsite,
            address: {
                line1: companyAddressLine1,
                line2: companyAddressLine2,
                city: companyAddressCity,
                postCode: companyAddressPostCode
            },
            straightToLease: {
                start: straightToLeaseStart,
                end: straightToLeaseEnd
            },
            practiceType: optionId
        };

        const { data: updateCompanyData } = await updateCompany({
            variables: {
                id: data.user.organisation._id,
                input
            },
            optimisticResponse: {
                organisationUpdate: {
                    __typename: 'Organisation',
                    _id: data.user.organisation._id,
                    ...input
                }
            }
        });

        client.cache.modify({
            id: client.cache.identify({
                __typename: 'Organisation',
                _id: data.user.organisation._id
            }),
            fields: {
                straightToLease() {
                    return input.straightToLease;
                }
            }
        });

        if (updateCompanyError || updateCompanyData?.organisationUpdate.__typename !== 'Organisation')
            return handleFetchError(
                'Failed to update your company details',
                updateCompanyError,
                updateCompanyData?.organisationUpdate
            );

        setIsUpdating(false);
    };

    if (loading || updateCompanyLoading) return <Loading />;

    if (error) return null;

    const organisationId = data?.user.__typename === 'User' ? data.user.organisation._id : '';

    const avatar = data?.user.__typename === 'User' ? data.user.organisation.avatar : '';

    return (
        <div className="w-full lg:w-4/5 p-6">
            {/* Company Details */}
            <h1 className="mb-4 font-semibold text-xl">
                {userData?.user.type === 'AGENT' ? 'Practice Details' : 'Company Details'}
            </h1>
            <label className="block text-newGray-darkish mb-2">Logo</label>
            <OrganisationAvatarUpload id={organisationId} avatar={avatar} companyName={companyName} />
            <form onSubmit={(e) => e.preventDefault()}>
                {/* Fist and Last Name */}
                <div className="flex flex-wrap  w-full gap-4">
                    {userData?.user.type === 'AGENT' && (
                        <div className="flex flex-wrap flex-col w-1/2 gap-2 pr-2">
                            <label htmlFor="lastName" className="block text-newGray-darkish  font-medium">
                                Practice Type
                            </label>
                            <Select
                                value={
                                    data?.user.organisation.practiceType
                                        ? {
                                              value: data?.user.organisation.practiceType,
                                              label: practiceTypeLabel
                                          }
                                        : optionId && {
                                              value: optionId,
                                              label: practiceTypeLabel
                                          }
                                }
                                onItemClick={(id) => id && (setOptionId(id as PracticeType), setIsUpdating(true))}
                                data={[
                                    {
                                        id: 'LEGAL',
                                        text: 'Legal Practice'
                                    },
                                    {
                                        id: 'AGENCY',
                                        text: 'Agency Practice'
                                    },
                                    {
                                        id: 'PROPERTY_MANAGEMENT',
                                        text: 'Property Management Practice'
                                    }
                                ]}
                                graySelect={true}
                                className={` w-full`}
                            />
                        </div>
                    )}
                    <div className={`flex flex-wrap md:flex-no-wrap w-full gap-4 mb-4`}>
                        <div className="w-full md:w-1/2">
                            <label htmlFor="firstName" className="block text-newGray-darkish mb-2 font-medium">
                                {userData?.user.type === 'AGENT' ? 'Practice Name' : 'Company Name'}
                            </label>
                            <Input
                                type={InputType.text}
                                placeholder="Name"
                                name="companyName"
                                value={companyName}
                                onChange={(e) => {
                                    setCompanyName(e.target.value);
                                    setIsUpdating(true);
                                }}
                                className={`bg-gray-200 border-none w-full`}
                            />
                        </div>
                        <div className="w-full md:w-1/2">
                            <label htmlFor="lastName" className="block text-newGray-darkish mb-2 font-medium">
                                {userData?.user.type === 'AGENT' ? 'Practice Website' : 'Company Website'}
                            </label>
                            <Input
                                type={InputType.text}
                                placeholder="Website"
                                name="companyWebsite"
                                value={companyWebsite}
                                onChange={(e) => {
                                    setCompanyWebsite(e.target.value);
                                    setIsUpdating(true);
                                }}
                                className={`bg-gray-200  border-none w-full`}
                            />
                        </div>
                    </div>
                </div>
                <div className="flex flex-wrap md:flex-no-wrap w-full gap-4 items-end">
                    <div className="w-full md:w-1/2">
                        <label htmlFor="firstName" className="block text-newGray-darkish mb-2 font-medium">
                            {userData?.user.type === 'AGENT' ? 'Practice Address' : 'Company Address'}
                        </label>
                        <Input
                            type={InputType.text}
                            placeholder="Line 1"
                            name="companyAddressLine1"
                            value={companyAddressLine1}
                            onChange={(e) => {
                                setCompanyAddressLine1(e.target.value);
                                setIsUpdating(true);
                            }}
                            className={`bg-gray-200 mb-4 border-none w-full`}
                        />
                    </div>
                    <div className="w-full md:w-1/2">
                        <Input
                            type={InputType.text}
                            placeholder="Line 2"
                            name="companyAddressLine2"
                            value={companyAddressLine2}
                            onChange={(e) => {
                                setCompanyAddressLine2(e.target.value);
                                setIsUpdating(true);
                            }}
                            className={`bg-gray-200 mb-4 border-none w-full`}
                        />
                    </div>
                </div>
                <div className="flex flex-wrap md:flex-no-wrap w-full gap-4">
                    <div className="w-full md:w-1/2">
                        <Input
                            type={InputType.text}
                            placeholder="City"
                            name="companyAddressCity"
                            value={companyAddressCity}
                            onChange={(e) => {
                                setCompanyAddressCity(e.target.value);
                                setIsUpdating(true);
                            }}
                            className={`bg-gray-200 mb-4 border-none w-full`}
                        />
                    </div>
                    <div className="w-full md:w-1/2">
                        <Input
                            type={InputType.text}
                            placeholder="Post Code"
                            name="companyAddressPostCode"
                            value={companyAddressPostCode}
                            onChange={(e) => {
                                setCompanyAddressPostCode(e.target.value);
                                setIsUpdating(true);
                            }}
                            className={`bg-gray-200 mb-4 border-none w-full`}
                        />
                    </div>
                </div>

                {/* Save Button*/}
                {isUpdating && (
                    <div className="flex justify-end w-full mt-2">
                        <div className="w-full md:w-auto">
                            <Button
                                background="newTeal-main"
                                text="white"
                                onClick={handleSaveClick}
                                className={`w-full md:px-8 font-semibold`}
                            >
                                Save Changes
                            </Button>
                        </div>
                    </div>
                )}
            </form>
        </div>
    );
};

export default Company;
