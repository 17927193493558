import React from 'react';

import ErrorIcon from '../../shared/ErrorIcon';

type ErrorProps = {
    message?: string | null;
};

const ErrorMessage = ({ message }: ErrorProps) => {
    return (
        <>
            <ErrorIcon />
            <h1 className="text-xl font-bold text-center mt-5">{message ?? 'An unknown error occured'}</h1>
            <p className="mt-2">{message ?? 'Sorry about that...'}</p>
        </>
    );
};

export default ErrorMessage;
