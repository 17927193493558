import React, { useContext } from 'react';

import ToolbarContext from './Context';
import { ReactComponent as Arrow } from '../../../../../assets/images/arrow/right/black.svg';
import { Button } from '../../../../../shared/button';

type ToolBarProps = {
    visible: boolean;
};

const ToolBar = ({ visible }: ToolBarProps) => {
    const { handleNextClauseClick } = useContext(ToolbarContext);

    return (
        <div
            className={`w-full sticky flex items-center justify-center bottom-4 md:bottom-2 pointer-events-none transition-opacity duration-200 ${
                !visible ? 'opacity-0 z-0' : 'opacity-100 z-40'
            }`}
        >
            <Button
                onClick={() => handleNextClauseClick()}
                border="none"
                height="12"
                className="relative bg-gray-900 text-white font-semibold p-4 rounded shadow-lg flex gap-2 pointer-events-auto cursor-pointer"
                id="NextClauseButton"
            >
                Next Clause
                <Arrow className="stroke-current text-newTeal-main w-6 h-6" />
            </Button>
        </div>
    );
};

export default ToolBar;
