import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';

import { DealCardContext } from '..';
import { BulkSelectContext } from '../../../../shared/bulk/context';

const DealCardNotSent = ({}) => {
    const { selectMode } = useContext(BulkSelectContext);
    const { id, tenantView, oldestApproval } = useContext(DealCardContext);
    const history = useHistory();

    return (
        <div
            className="px-4  cursor-pointer"
            onClick={() => {
                !selectMode && history.push(tenantView ? `/units/deal/${id}` : `/deals/${id}`);
            }}
        >
            <div className="max-h-full bg-navy-lightest rounded">
                <p className="flex flex-col justify-center items-center py-16 mb-1">
                    <span className="font-medium text-newGray-main">
                        {`${oldestApproval ? 'Waiting Approval' : 'Draft Deal - Not sent to the tenant yet'}`}
                    </span>
                </p>
            </div>
        </div>
    );
};

export default DealCardNotSent;
