import React from 'react';
import FlipMove from 'react-flip-move';

import Clause from './Clause/index';
import { ClauseMode } from './Types';
import { DealState, GetDealDetailsQuery, GetUserTypeQuery } from '../../../../generated/graphql';
import Accordion from '../../../../shared/Accordian';
import useMobileScreen from '../../../../shared/hooks/useMobileScreen';
import { DealType } from '../../../../shared/Negotiator/ConfirmClauseDeleteWindow.tsx';
import Group from '../../../../shared/Negotiator/Group';
import Section from '../../../../shared/Negotiator/Section';

type NegotiateSectionsType = {
    deal: any;
    typeData: GetUserTypeQuery | undefined;
    setShowAddClauseWindow: React.Dispatch<React.SetStateAction<boolean>>;
    setOpenDetails: React.Dispatch<
        React.SetStateAction<{
            section?: string | undefined;
            level2Group?: string | undefined;
        } | null>
    >;
    detailsData: GetDealDetailsQuery | undefined;
    setDealComplete: React.Dispatch<any>;
    isClauseReordering: boolean;
    isTitlesReordering: boolean;
    setClauseReordering?: React.Dispatch<React.SetStateAction<boolean>>;
    setTitlesReordering?: React.Dispatch<React.SetStateAction<boolean>>;
};

const NegotiateSections = ({
    deal,
    typeData,
    setShowAddClauseWindow,
    setOpenDetails,
    detailsData,
    setDealComplete,
    isClauseReordering,
    isTitlesReordering,
    setClauseReordering,
    setTitlesReordering
}: NegotiateSectionsType) => {
    const isMobile = useMobileScreen();
    return (
        <>
            {/* Sections */}
            {deal?.sections?.map((section: any, index: number) => (
                <div className="bg-white mb-4 rounded-xl actor-list-shadow py-8 px-10 pb-6" key={section._id}>
                    <Section
                        isReordering={isTitlesReordering}
                        userType={typeData?.user.__typename === 'User' ? typeData.user.type : undefined}
                        description={section.description}
                        rics={section.rics}
                        length={deal?.sections?.length}
                        index={index}
                        setShow={setShowAddClauseWindow}
                        setOpenDetails={setOpenDetails}
                        id={section._id}
                        deal={detailsData?.deal.__typename === 'Deal' ? detailsData?.deal : null}
                        key={section._id}
                        title={section.title}
                        setClauseReordering={setClauseReordering}
                        setTitlesReordering={setTitlesReordering}
                    >
                        {' '}
                    </Section>
                    {section.groups?.map((group: any, groupIndex: number) => (
                        <div className="mt-4" key={group._id}>
                            <Accordion
                                sectionID={section._id}
                                isReordering={isTitlesReordering}
                                title={group.title}
                                description={group.description}
                                rics={group.rics}
                                parentID={group.parentID}
                                id={group._id}
                                className="bg-white py-4"
                                isOpen={group.clauseSummary.incomplete > 0}
                                isCompleted={group.clauseSummary.incomplete === 0}
                                length={section.groups?.length}
                                index={groupIndex}
                                deal={detailsData?.deal.__typename === 'Deal' ? detailsData?.deal : null}
                            >
                                <FlipMove leaveAnimation="accordionVertical" className={`${isMobile && '-mr-1'}`}>
                                    {group.clauses?.map((clause: any, index: number) => {
                                        const allClausesID = group.clauses?.map((clause: any) => {
                                            return {
                                                _id: clause._id
                                            };
                                        });
                                        return (
                                            <Clause
                                                parentID={clause.parentID}
                                                index={index}
                                                clauses={group.clauses?.length}
                                                isReordering={isClauseReordering}
                                                id={clause._id}
                                                allClausesID={allClausesID}
                                                dealType={DealType.DEAL}
                                                deal={deal ?? null}
                                                setDealComplete={setDealComplete}
                                                key={clause._id}
                                                value={clause.value ?? ''}
                                                valueSuffix={clause.valueSuffix ?? ''}
                                                shortForm={clause.shortForm ?? ''}
                                                longForm={clause.longForm ?? ''}
                                                tags={clause.tags ?? []}
                                                approved={!!clause.approved}
                                                deleted={!!clause.deleted}
                                                visible={clause.visible}
                                                linkedClauses={clause.linked}
                                                history={clause.history ?? []}
                                                comments={clause.comments}
                                                dealBreaker={clause.dealBreaker}
                                                sectionName={section.title}
                                                groupName={group.title}
                                                groupID={group._id}
                                                mode={
                                                    detailsData?.deal.__typename === 'Deal' &&
                                                    (detailsData.deal.currentUserParty ===
                                                        detailsData.deal.state.toString() ||
                                                        detailsData.deal.state === DealState.Agreed)
                                                        ? clause.history &&
                                                          clause.history.length > 0 &&
                                                          clause.history[clause.history?.length - 1].party ===
                                                              detailsData.deal.currentUserParty
                                                            ? ClauseMode.MODIFIED
                                                            : ClauseMode.EDIT
                                                        : ClauseMode.VIEW
                                                }
                                            >
                                                <>
                                                    {clause.groups?.map((level2Group: any) => (
                                                        <Group
                                                            isReordering={isClauseReordering}
                                                            id={level2Group._id}
                                                            parentID={level2Group.parentID}
                                                            deal={deal ?? null}
                                                            key={level2Group._id}
                                                            title={level2Group.title}
                                                            level={2}
                                                            clauseID={clause._id}
                                                            index={index}
                                                            clauses={group.clauses?.length}
                                                            setShow={setShowAddClauseWindow}
                                                            setOpenDetails={setOpenDetails}
                                                            clauseGroupInput={{
                                                                section: section.title,
                                                                group: group.title,
                                                                groupLength: level2Group.clauses
                                                                    ? level2Group.clauses.length
                                                                    : 0
                                                            }}
                                                            type={DealType.DEAL}
                                                        >
                                                            <FlipMove leaveAnimation="accordionVertical">
                                                                {level2Group.clauses?.map(
                                                                    (level2Clause: any, index: number) => (
                                                                        <Clause
                                                                            isReordering={isClauseReordering}
                                                                            id={level2Clause._id}
                                                                            parentID={level2Clause.parentID}
                                                                            setDealComplete={setDealComplete}
                                                                            dealType={DealType.DEAL}
                                                                            deal={deal ?? null}
                                                                            key={level2Clause._id}
                                                                            value={level2Clause.value ?? ''}
                                                                            valueSuffix={level2Clause.valueSuffix ?? ''}
                                                                            shortForm={level2Clause.shortForm ?? ''}
                                                                            longForm={level2Clause.longForm ?? ''}
                                                                            tags={level2Clause.tags ?? []}
                                                                            approved={!!level2Clause.approved}
                                                                            deleted={!!level2Clause.deleted}
                                                                            dealBreaker={level2Clause.dealBreaker}
                                                                            linkedClauses={level2Clause.linked}
                                                                            history={level2Clause.history ?? []}
                                                                            comments={level2Clause.comments}
                                                                            sectionName={section.title}
                                                                            groupName={group.title}
                                                                            groupID={level2Group._id}
                                                                            clauseGroupName={level2Group.title}
                                                                            visible
                                                                            mode={
                                                                                detailsData?.deal.__typename ===
                                                                                    'Deal' &&
                                                                                detailsData.deal.currentUserParty ===
                                                                                    detailsData.deal.state.toString()
                                                                                    ? level2Clause.history &&
                                                                                      level2Clause.history.length > 0 &&
                                                                                      level2Clause.history[
                                                                                          level2Clause.history?.length -
                                                                                              1
                                                                                      ].party ===
                                                                                          detailsData.deal
                                                                                              .currentUserParty
                                                                                        ? ClauseMode.MODIFIED
                                                                                        : ClauseMode.EDIT
                                                                                    : ClauseMode.VIEW
                                                                            }
                                                                        />
                                                                    )
                                                                )}
                                                            </FlipMove>
                                                        </Group>
                                                    ))}
                                                </>
                                            </Clause>
                                        );
                                    })}
                                </FlipMove>
                            </Accordion>
                        </div>
                    ))}
                </div>
            ))}
        </>
    );
};

export default NegotiateSections;
