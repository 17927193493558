/* eslint-disable @typescript-eslint/no-unused-vars */
import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { ReactComponent as Cross } from '../../assets/images/cross/currentColor.svg';
import {
    AvatarType,
    DealFilterCountsDocument,
    GetDealCardDetailsDocument,
    TemplateStatusFilter,
    Unit,
    UnitCondition,
    UnitUse,
    useGetBuildingsAutofillDetailsQuery,
    useGetTemplateCardDetailsLazyQuery,
    useGetUserTypeQuery,
    useNewDealMutation,
    UserType,
    useUploadAvatarMutation
} from '../../generated/graphql';
import { Button } from '../../shared/button';
import { handleFetchError } from '../../shared/helpers';
import useMobileScreen from '../../shared/hooks/useMobileScreen';
import { InputType } from '../../shared/Inputs';
import Autofill from '../../shared/Inputs/Autofill';
import Select from '../../shared/Inputs/Select';
import Loading from '../../shared/Loading';
import useNewUnitComponent from '../../shared/NewUnit';
import useNewUnit from '../../shared/NewUnit/hooks/useNewUnit';
import Window from '../../shared/Window';

type NewDealWindowProps = {
    selectedUnitID?: string;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
    refetch?: () => void;
    countsRefetch?: () => void;
};

const NewDealWindow = ({ selectedUnitID, refetch, countsRefetch, ...props }: NewDealWindowProps) => {
    const history = useHistory();
    const mobileScreen = useMobileScreen();
    const [selectedTemplate, setSelectedTemplate] = useState<{ label: string; value: string } | undefined>();

    // Error message
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    // Show new unit form
    const [showNewUnit, setShowNewUnit] = useState<boolean>(false);

    const [unitID, setUnitID] = useState<string | null>(null);
    const [templateID, setTemplateID] = useState<string | null>(null);
    const [globalTemplates, setGlobalTemplates] = useState<{ id: string; text: string }[]>([]);

    const [organisationTemplates, setOrganisationTemplates] = useState<{ id: string; text: string }[]>([]);

    // Form values
    const buildingNameRef = useRef<HTMLInputElement>(null);
    const unitNameRef = useRef<HTMLInputElement>(null);
    const addressLine1Ref = useRef<HTMLInputElement>(null);
    const addressLine2Ref = useRef<HTMLInputElement>(null);
    const urlRef = useRef<HTMLInputElement>(null);
    const addressCityRef = useRef<HTMLInputElement>(null);
    const addressPostCodeRef = useRef<HTMLInputElement>(null);
    const [use, setUse] = useState<UnitUse | null>(null);
    const [condition, setCondition] = useState<UnitCondition | null>(null);
    const areaRef = useRef<HTMLInputElement>(null);
    const [emails, setEmails] = useState<string[]>([]);
    const [newAvatar, setNewAvatar] = useState<any>(null);
    const [associatedCompany, setAssociatedCompany] = useState<string>('');

    // User Type Check
    const { data: typeData, loading: typeLoading, error: typeError } = useGetUserTypeQuery();

    // Add Unit Image
    const [mediaCreate, { error: uploadError }] = useUploadAvatarMutation();

    useEffect(() => {
        // Check for errors
        handleFetchError('Could not load user', typeError, typeData?.user);
    }, [typeData, typeError]);

    // Form values

    // New Unit component
    const { setAddressLine1Value, setAddressLine2Value, setAddressCityValue, setAddressPostCodeValue, NewUnit } =
        useNewUnitComponent({
            setNewAvatar,
            unitNameRef,
            addressLine1Ref,
            urlRef,
            addressLine2Ref,
            addressCityRef,
            addressPostCodeRef,
            areaRef,
            emails,
            setEmails,
            userType: typeData?.user.__typename === 'User' && typeData.user.type,
            setUse,
            setCondition,
            setAssociatedCompany,
            associatedCompany,
            className: 'bg-navy-lightest py-2 mb-4 px-2 border-b border-newTeal-main'
        });

    // Building Autocomplete
    const { data: unitsData, loading: unitsLoading, error: unitsError } = useGetBuildingsAutofillDetailsQuery();
    const [buildings, setBuildings] = useState<
        {
            id: string;
            text: string;
            subtext?: string;
            organisation: string;
            address?: {
                line1?: string | null;
                line2?: string | null;
                city?: string | null;
                postCode?: string | null;
            };
        }[]
    >([]);
    const [units, setUnits] = useState<
        {
            id: string;
            text: string;
        }[]
    >([]);

    useEffect(() => {
        if (selectedUnitID) {
            setUnitID(selectedUnitID);
            const foundUnit = unitsData?.units.results.find((unit) => {
                return unit._id === selectedUnitID;
            });

            if (foundUnit && foundUnit.use) {
                loadTemplates({
                    variables: {
                        filter: {
                            organisation: [foundUnit.organisation._id],
                            use: foundUnit.use,
                            status: [TemplateStatusFilter.Live]
                        }
                    }
                });
            }
        }
    }, [selectedUnitID, unitsData]);

    useEffect(() => {
        // Check for errors
        handleFetchError('Could not load your buildings', unitsError, unitsData?.units.results);

        // Check if we got any buildings back
        if (unitsData) {
            // Format for autofill type
            const buildings:
                | {
                      id: string;
                      text: string;
                      subtext: string;
                      organisation: string;
                      unitName: string;
                      address: {
                          line1: string | null | undefined;
                          line2: string | null | undefined;
                          city: string | null | undefined;
                          postCode: string | null | undefined;
                      };
                  }[]
                | undefined = [];

            unitsData?.units?.results.map((unit) => {
                const item = {
                    id: unit._id,
                    // If there is another building with the same name, under another organisation, then append the organisation's name to the building text
                    text: unit.buildingName,
                    subtext: unit.organisation.name,
                    organisation: unit.organisation._id,
                    unitName: unit.name ?? '',
                    address: {
                        line1: unit.address.line1,
                        line2: unit.address.line2,
                        city: unit.address.city,
                        postCode: unit.address.postCode
                    }
                };

                // Remove duplicate buildings
                if (buildings.find((building) => building.text === item.text) || unit.pending === true) return;

                buildings.push(item);
            });

            buildings && setBuildings(buildings);
        }
    }, [unitsData, unitsError]);

    // User Templates
    const [loadTemplates, { data: templateData, loading: templateLoading, error: templateError }] =
        useGetTemplateCardDetailsLazyQuery({
            fetchPolicy: 'network-only',
            variables: {
                filter: { status: [TemplateStatusFilter.Live] }
            }
        });

    useEffect(() => {
        if (unitID) {
            const unit = unitsData?.units.results.find((_unit) => {
                return _unit._id === unitID;
            });
            if (unit) {
                loadTemplates({
                    variables: {
                        filter: {
                            organisation: [unit.organisation._id],
                            use: unit.use,
                            status: [TemplateStatusFilter.Live]
                        }
                    }
                });
            }
        }
    }, [unitID]);

    const newUnitOrg = typeData?.user.type === UserType.Agent ? associatedCompany : typeData?.user.organisation._id;

    useEffect(() => {
        if (use && showNewUnit) {
            setTemplateID(null);
            loadTemplates({
                variables: {
                    filter: {
                        organisation: newUnitOrg ? [newUnitOrg] : [],
                        use: use,
                        status: [TemplateStatusFilter.Live]
                    }
                }
            });
        }
    }, [newUnitOrg, showNewUnit, use]);

    const [templates, setTemplates] = useState<{ id: string; text: string }[]>([]);

    useEffect(() => {
        // Check for errors
        handleFetchError('Could not load your templates', templateError, templateData?.templates.results);

        if (templateData) {
            const templates = templateData.templates?.results.map((template) => ({
                id: template._id,
                text: template.name ?? ''
            }));
            templates && setTemplates(templates);

            const currentTemplates = templateData?.templates.results.filter((template) =>
                templates.map((temp) => template._id === temp.id)
            );

            const globalTemplatesData = currentTemplates?.filter((template) => template.global === true);

            const organisationTemplatesData = currentTemplates?.filter((template) => template.global === false);

            const getTemplatesOptions = (templatesOptions) => {
                return templatesOptions?.map((template) => ({
                    id: template._id,
                    text: template.name ?? ''
                }));
            };

            if (organisationTemplatesData) {
                setOrganisationTemplates(getTemplatesOptions(organisationTemplatesData));
            }

            if (globalTemplatesData) {
                setGlobalTemplates(getTemplatesOptions(globalTemplatesData));
            }
        }
    }, [templateData, templateError]);

    // User clicked autocompleted building
    const handleBuildingClick = (id: string) => {
        setTemplateID(null);
        setUnitID(null);
        const building = buildings.find((building) => building.id === id);

        if (!building) {
            // Enable all inputs
            addressLine1Ref.current?.removeAttribute('disabled');
            addressLine2Ref.current?.removeAttribute('disabled');
            addressCityRef.current?.removeAttribute('disabled');
            addressPostCodeRef.current?.removeAttribute('disabled');
            return;
        }

        // Disable all inputs
        addressLine1Ref.current?.setAttribute('disabled', 'true');
        addressLine2Ref.current?.setAttribute('disabled', 'true');
        addressCityRef.current?.setAttribute('disabled', 'true');
        addressPostCodeRef.current?.setAttribute('disabled', 'true');

        // Set input values
        building.address?.line1 && setAddressLine1Value(building.address?.line1);
        building.address?.line2 && setAddressLine2Value(building.address?.line2);
        building.address?.city && setAddressCityValue(building.address?.city);
        building.address?.postCode && setAddressPostCodeValue(building.address?.postCode);

        // Set units dropdown
        if (unitsData) {
            const units: { id: string; text: string }[] = [];
            unitsData?.units?.results.forEach((unit) => {
                if (building.text === unit.buildingName) {
                    units.push({
                        id: unit._id,
                        text: unit.name ?? ''
                    });
                }
            });
            setUnits(units);
        }
    };

    const newUnit = useNewUnit(setErrorMessage, typeData?.user.__typename === 'User' && typeData.user.type);
    const [newDeal, { error: newDealError, loading: newDealLoading }] = useNewDealMutation();

    const handleConfirmClick = async () => {
        let selectedUnitID;

        if (showNewUnit) {
            const buildingName = buildingNameRef.current?.value;
            const unitName = unitNameRef.current?.value;
            const addressLine1 = addressLine1Ref.current?.value;
            const addressLine2 = addressLine2Ref.current?.value;
            const addressCity = addressCityRef.current?.value;
            const addressPostCode = addressPostCodeRef.current?.value;
            const unitUse = use;
            const unitCondition = condition;
            const unitArea = areaRef.current?.value;
            const url = urlRef.current?.value;

            const data = await newUnit(
                buildingName,
                unitName,
                addressLine1,
                addressLine2,
                addressCity,
                addressPostCode,
                unitUse,
                unitCondition,
                unitArea,
                emails,
                url,
                associatedCompany
            );

            data?.unitCreate.__typename === 'Unit' && (selectedUnitID = data.unitCreate._id);

            if (selectedUnitID && newAvatar) {
                const fileParts = newAvatar.name.split('.');
                const fileType = fileParts[1];

                const res = await mediaCreate({
                    variables: {
                        type: AvatarType.Unit,
                        filetype: newAvatar.type,
                        id: selectedUnitID
                    }
                });

                if (uploadError) {
                    // handleFetchError(
                    //     "Something went wrong",
                    //     uploadError,
                    //     res?.data
                    // )
                    return;
                }

                const signedUrl: string =
                    res.data?.avatarCreate.__typename === 'S3Payload' ? res.data.avatarCreate.signedUrl : '';

                await axios.put(signedUrl, newAvatar, {
                    headers: {
                        'Content-Type': fileType,
                        'Access-Control-Allow-Origin': '*'
                    }
                });
            }

            selectedUnitID = data?.unitCreate._id;
        } else {
            selectedUnitID = unitID;
        }

        if (!selectedUnitID || selectedUnitID.length === 0) {
            setErrorMessage('Please either select or create a demise');

            return;
        }

        if (!templateID) {
            setErrorMessage('Please select a template');

            return;
        }

        const { data: newDealData } = await newDeal({
            variables: {
                unitID: selectedUnitID ?? '',
                templateID: templateID === 'empty' ? null : templateID
            },
            onCompleted: () => {
                refetch && refetch();
                countsRefetch && countsRefetch();
            },
            refetchQueries: [
                {
                    query: GetDealCardDetailsDocument,
                    variables: {
                        limit: 10,
                        offset: 0
                    }
                }
            ]
        });

        if (newDealError) {
            return;
        }

        props.setShow(false);
        // Can't call refetch as may not be on the deals page
        // So we update cache manually
        // refetch()

        // Redirect to deal
        newDealData?.dealCreate.__typename === 'Deal' && history.push(`/deals/${newDealData?.dealCreate._id}`);
    };

    useEffect(() => {
        if (templateID) {
            const templateFound = templates.find((template) => {
                return template.id === templateID;
            });
            setSelectedTemplate({
                label: templateFound?.text ?? '',
                value: templateFound?.id ?? ''
            });
        }
    }, [templateID, templates]);

    const handlePlaceholder = () => {
        if (templateLoading) {
            return 'Loading templates...';
        } else if (templateData?.templates.results.length === 0) {
            return 'No templates for unit use';
        } else {
            return 'Select template';
        }
    };

    if (typeLoading || unitsLoading || newDealLoading)
        return (
            <Window
                header={
                    <div className="flex p-2 ml-4 mr-2 mt-3 mb-2 place-items-center justify-between">
                        <div className="flex flex-row items-center">
                            <p className="text-xl font-semibold border-b-3 border-newTeal-main mr-4">New Deal</p>
                        </div>
                        <Cross
                            className="w-5 h-5 -mt-4"
                            onClick={() => {
                                props.setShow(false);
                            }}
                        />
                    </div>
                }
                title="New Deal"
                width="w-2/5"
                {...props}
            >
                <Loading />
            </Window>
        );
    if (typeError) return null;

    return (
        <Window
            header={
                <div className="flex p-2 ml-4 mr-2 mt-3 mb-2 place-items-center justify-between">
                    <div className="flex flex-row items-center">
                        <p className="text-xl font-semibold border-b-3 border-newTeal-main mr-4">New Deal</p>
                    </div>
                    <Cross
                        className="w-5 h-5 -mt-4"
                        onClick={() => {
                            props.setShow(false);
                        }}
                    />
                </div>
            }
            width={`w-${showNewUnit ? '2/5' : '1/3'}`}
            primaryBtn
            onPrimaryBtnClick={handleConfirmClick}
            scrollContent={mobileScreen ? true : false}
            className={`md:max-h-11/12 ${showNewUnit ? 'overflow-y-scroll' : 'md:overflow-visible'}`}
            {...props}
        >
            <form
                className={`flex flex-col pt-8 pb-20 md:py-4 px-4 w-full min-h-full`}
                style={{
                    overflowY: mobileScreen ? 'scroll' : 'inherit'
                }}
                onSubmit={(event) => {
                    event.preventDefault();
                    handleConfirmClick();
                }}
            >
                {!selectedUnitID && (
                    <>
                        <div className={`flex flex-row px-2 w-full md:w-${showNewUnit ? '1/2 md:mr-2' : 'full'} mb-4`}>
                            <Autofill
                                containerClassName="mt-2 md:mt-0"
                                type={InputType.text}
                                data={buildings}
                                onItemClick={handleBuildingClick}
                                placeholder="Enter building name"
                                label="Building Name"
                                className="w-full relative"
                                showOnClick={showNewUnit ? false : true}
                                ref={buildingNameRef}
                                endElement={
                                    <div
                                        className="w-full border-t border-navy-light px-4 py-3 text-gray-600 hover:text-black transitionAll cursor-pointer"
                                        onClick={() => {
                                            setShowNewUnit(true);
                                        }}
                                    >
                                        <span className="text-sm">No Matches - Create new space?</span>
                                    </div>
                                }
                            ></Autofill>
                        </div>

                        {!showNewUnit ? (
                            <div className="flex flex-row flex-wrap justify-between items-end px-2 gap-3 mb-4">
                                <div className="flex-1">
                                    <p className="text-gray-600 font-medium text-sm mb-0.5">Demise Name</p>
                                    <Select
                                        data={units}
                                        onItemClick={(id) => id && setUnitID(id)}
                                        placeholder="Select demise name"
                                        disabled={units.length === 0}
                                        value={{
                                            label:
                                                unitID !== null
                                                    ? unitsData?.units.results.find((unit) => {
                                                          return unit._id === unitID;
                                                      })?.name
                                                    : 'Select demise name',
                                            value: unitID !== null ? unitID : null
                                        }}
                                    />
                                </div>
                                <Button
                                    onClick={() => setShowNewUnit(true)}
                                    background="navy-light"
                                    text="black"
                                    className="px-4 w-full md:w-4/12 font-semibold text-sm"
                                >
                                    New Space
                                </Button>
                            </div>
                        ) : (
                            <>
                                <div className="w-full bg-navy-lightest flex flex-row justify-between items-center px-2">
                                    <p className="pb-2 pt-3 ml-1">New Space</p>
                                </div>
                                {NewUnit}
                            </>
                        )}
                    </>
                )}

                <div className="w-full px-2 relative block">
                    <p className="text-gray-600 font-medium text-sm mb-0.5">Template</p>
                    <Select
                        data={templates}
                        subGroup={[
                            { text: 'Global', options: globalTemplates },
                            {
                                text: 'Custom',
                                options: organisationTemplates
                            }
                        ]}
                        onItemClick={(id) => id && setTemplateID(id)}
                        disabled={
                            selectedUnitID && !templateLoading
                                ? false
                                : templates.length === 0 ||
                                  (!showNewUnit && units.length === 0) ||
                                  (showNewUnit && !use) ||
                                  templateLoading ||
                                  templateData?.templates.results.length === 0 ||
                                  (!selectedUnitID && templateLoading)
                        }
                        placeholder={handlePlaceholder()}
                        value={templateID ? selectedTemplate : null}
                        className="w-full bg-navy-lightest mt-0"
                    />
                </div>

                {errorMessage && (
                    <div className="bg-red-200 border border-red-600 rounded py-2 px-4 mx-2 mt-4">
                        <span className="text-center text-red-600">{errorMessage}</span>
                    </div>
                )}
            </form>
        </Window>
    );
};

export default NewDealWindow;
