import React, { useEffect, useState } from 'react';

import Task from './Task';
import { GetDealUnitDetailsQuery } from '../../../../../generated/graphql';

type JourneyProps = {
    dealID: string;
    data: GetDealUnitDetailsQuery;
};

const Journey = ({ dealID, data }: JourneyProps) => {
    // Details
    const { ...details } = data?.deal.__typename === 'Deal' && data.deal.details;
    delete details['__typename'];

    const [nextStage, setNextStage] = useState<number | null>(null);

    useEffect(() => {
        for (const key in details) {
            if (!details[key]?.completed) {
                // Get 'x' number from 'ReadinessStageX' typename to update the state
                details[key] && setNextStage(Number(details[key].__typename.slice(11, 12)));
                break;
            }
        }
    }, [details]);

    if (data.deal.__typename !== 'Deal' || !data.deal.details)
        return (
            <div className="p-3 bg-white rounded">
                <p className="text-sm text-newGray-darkish">No tasks to complete</p>
            </div>
        );

    return (
        <div className="rounded flex flex-col overflow-hidden">
            <Task
                dealID={dealID}
                stage={1}
                nextStage={nextStage}
                title="Tenant Information"
                complete={!!data.deal.details.one?.completed}
            />
            <Task
                dealID={dealID}
                stage={2}
                nextStage={nextStage}
                title="Lawyer Details"
                complete={!!data.deal.details.two?.completed}
            />
            <Task
                dealID={dealID}
                stage={3}
                nextStage={nextStage}
                title="Team"
                complete={!!data.deal.details.team?.completed}
            />
            <Task
                dealID={dealID}
                stage={4}
                nextStage={nextStage}
                title="Covenant"
                complete={!!data.deal.details.three?.completed}
            />
            <Task
                dealID={dealID}
                stage={5}
                nextStage={nextStage}
                title="AML Check"
                complete={!!data.deal.details.aml?.completed}
            />
            <Task
                dealID={dealID}
                stage={6}
                nextStage={nextStage}
                title="Final Information"
                complete={!!data.deal.details.final?.completed}
            />
        </div>
    );
};

export default Journey;
