import React from 'react';

import ClauseContext from './Context';
import { CommentType, DealUserParty } from '../../../../../generated/graphql';
import { ClauseType } from '../Types';

const Badge = () => {
    const { type, comments, value, currentUserParty, setBadged, dealBreaker } = React.useContext(ClauseContext);

    let text: string | null = null;

    // Set badge text
    switch (type) {
        case ClauseType.NEW:
            text = 'New';
            break;
        case ClauseType.VALUE:
            text = 'Value Changed';
            break;
        case ClauseType.CHANGED:
            text = 'Clause Changed';
            break;
        case ClauseType.SWAPPED:
            text = 'Clause Swapped';
            break;
        case ClauseType.REMOVED:
            text = 'Clause Removed';
            break;
        case ClauseType.COMMENTED:
            if (comments && comments.length > 0) {
                if (comments[comments.length - 1].type === CommentType.Removal) text = 'Removal Requested';
                else text = 'New Comment';
            }
            break;
        default:
            // Tenant first offer
            if (value === '*' && currentUserParty === DealUserParty.Tenant && !dealBreaker) text = 'Make First Offer';
    }

    // Set badge, if needed
    if (!text) return null;

    setBadged!(true);

    return (
        <span
            className={
                'bg-orange-400 text-black rounded text-xs px-3 text-center badgeHidden font-semibold badgeVisible'
            }
        >
            {text}
        </span>
    );
};

export default Badge;
