import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import DealMessagesWindow from './DealMessagesWindow';
import { ReactComponent as MessageIcon } from '../../../../../assets/images/message/black.svg';
import { DealUserParty, useGetDealDetailsQuery } from '../../../../../generated/graphql';
import { MainContext } from '../../../../../layout/main.provider';
import { useUpdateUrl } from '../../../../../shared/Filter/helpers';

type CoverNoteProps = {
    id: string;
    currentUserParty?: DealUserParty | null;
    visible: boolean;
    forceCloseCoverNote?: boolean;
};

const CoverNote = ({ id, currentUserParty, visible, forceCloseCoverNote }: CoverNoteProps) => {
    const { windowOpen } = useContext(MainContext);

    const [showDealMessagesWindow, setShowDealMessagesWindow] = useState<boolean>(false);

    const history = useHistory();
    const { token } = useParams();

    const {
        loading: detailsLoading,
        error: detailsError,
        data: detailsData
    } = useGetDealDetailsQuery({
        variables: {
            dealID: id,
            token
        }
    });

    const { addToUrl, removeFromUrl } = useUpdateUrl();

    useEffect(() => {
        if (showDealMessagesWindow) {
            addToUrl({ newMessage: true });
        } else {
            removeFromUrl(['newMessage']);
        }
    }, [showDealMessagesWindow]);

    useEffect(() => {
        // Check for errors
        if (detailsError && !detailsLoading) {
            // handleFetchError(
            //     "Could not load the deal",
            //     detailsError,
            //     detailsData?.deal
            // )

            return history.push('/deals');
        }

        if (detailsData?.deal.__typename === 'Deal') {
            if (
                !detailsData.deal.round?.seen &&
                detailsData.deal.state?.toString() === detailsData.deal.currentUserParty &&
                !forceCloseCoverNote
            ) {
                setShowDealMessagesWindow(true);
            }
        }
        // eslint-disable-next-line
    }, [detailsData]);

    return (
        <>
            {showDealMessagesWindow && (
                <DealMessagesWindow id={id} currentUserParty={currentUserParty} setShow={setShowDealMessagesWindow} />
            )}
            <button
                className={`fixed right-7 bottom-4 md:bottom-2 ${
                    windowOpen ? 'z-0' : 'z-40'
                } rounded-full p-4 bg-black focus:outline-none transition-opacity duration-200 ${
                    !visible ? 'opacity-0' : 'opacity-100'
                }`}
                onClick={() => setShowDealMessagesWindow(true)}
            >
                <MessageIcon
                    className="w-5 h-5 text-white stroke-current transform"
                    style={{
                        transform: 'scaleX(-1)'
                    }}
                />
            </button>
            {visible && (
                <div
                    onClick={() => setShowDealMessagesWindow(true)}
                    className={`${
                        !showDealMessagesWindow ? 'coverNoteLarge' : 'coverNoteSmall'
                    } rounded-full fixed right-3 bottom-4 md:bottom-2 z-30 bg-newGray-darkish`}
                    style={{ width: '52px', height: '52px' }}
                ></div>
            )}
        </>
    );
};

export default CoverNote;
