import React, { useContext } from 'react';

import { FilterContext } from './context';
import { FieldFilter } from './FilterWindow';
import { ReactComponent as CloseIcon } from '../../assets/images/cross/black.svg';

type RadioListChipProps = {
    value: any;
    filter: FieldFilter;
};

const RadioListChip = ({ value, filter }: RadioListChipProps) => {
    const { removeRadioListValue } = useContext(FilterContext);

    return (
        <div
            className={'bg-newGray-main flex items-center pr-2 cursor-pointer rounded-l ml-1 rounded-r h-7 text-xs'}
            style={{
                minWidth: '7rem'
            }}
        >
            <div
                className="flex items-center justify-center cursor-pointer"
                style={{
                    width: '2rem'
                }}
                onClick={() => removeRadioListValue(filter.id)}
            >
                <CloseIcon width={13} height={13} />
            </div>
            <p>
                {filter.label} ({value === false ? 'No' : 'Yes'})
            </p>
        </div>
    );
};

export default RadioListChip;
