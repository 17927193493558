import React from 'react';

import { DealDetailsFragment, DealSendType, DealUserParty } from '../../../../../../generated/graphql';
import DealSendWindow from '../../../DealSendWindow';
import AddUserWindow from '../Windows/AddUserWindow';
import ConfirmRemoveTenantWindow from '../Windows/ConfirmRemoveTenantWindow';
import RemoveUserWindow from '../Windows/RemoveUserWindow';
import TenantPendingMembersWindow from '../Windows/TenantPendingMembersWindow';

type DealActorsWindowsProps = {
    dealID: string;
    organisationID: string | undefined;
    deal?: DealDetailsFragment;
    userID: string | null;
    activated: boolean;
    type: 'Landlord' | 'Tenant';
    userEmail: string | null;
    tenantPendingMembers?: string[];
    currentUserParty: DealUserParty;
    setUserEmail: React.Dispatch<React.SetStateAction<string | null>>;
    showAddUserWindow: boolean;
    setShowAddUserWindow: React.Dispatch<React.SetStateAction<boolean>>;
    showRemoveUserWindow: boolean;
    setShowRemoveUserWindow: React.Dispatch<React.SetStateAction<boolean>>;
    showEmailUserWindow: boolean;
    setShowEmailUserWindow: React.Dispatch<React.SetStateAction<boolean>>;
    showRemoveTenantWindow: boolean;
    setShowRemoveTenantWindow: React.Dispatch<React.SetStateAction<boolean>>;
    showTenantPendingMembersWindow?: boolean;
    setShowTenantPendingMembersWindow?: React.Dispatch<React.SetStateAction<boolean>>;
    setShowingTenantPending?: React.Dispatch<React.SetStateAction<boolean>>;
    agentArray?: any;
};

const DealActorsWindows = ({
    dealID,
    organisationID,
    deal,
    userID,
    activated,
    type,
    userEmail,
    tenantPendingMembers,
    currentUserParty,
    setUserEmail,
    showAddUserWindow,
    setShowAddUserWindow,
    showRemoveUserWindow,
    setShowRemoveUserWindow,
    showEmailUserWindow,
    setShowEmailUserWindow,
    showRemoveTenantWindow,
    setShowRemoveTenantWindow,
    showTenantPendingMembersWindow,
    setShowTenantPendingMembersWindow,
    setShowingTenantPending,
    agentArray
}: DealActorsWindowsProps) => {
    return (
        <>
            {showAddUserWindow && (
                <AddUserWindow
                    email={userEmail}
                    dealID={dealID}
                    deal={deal}
                    setShow={setShowAddUserWindow}
                    type={type}
                    agentArray={agentArray}
                />
            )}
            {showRemoveUserWindow && userID && (
                <RemoveUserWindow
                    dealID={dealID}
                    userID={userID}
                    activated={activated}
                    organisationID={organisationID ?? ''}
                    type={type}
                    setShow={setShowRemoveUserWindow}
                />
            )}
            {showEmailUserWindow && (
                <DealSendWindow
                    dealID={dealID}
                    email={userEmail ?? ''}
                    type={type === 'Landlord' ? DealSendType.Landlord : DealSendType.Tenant}
                    currentUserParty={currentUserParty}
                    setShow={setShowEmailUserWindow}
                />
            )}
            {showRemoveTenantWindow && organisationID && (
                <ConfirmRemoveTenantWindow
                    dealID={dealID}
                    tenantID={organisationID}
                    setShow={setShowRemoveTenantWindow}
                />
            )}
            {showTenantPendingMembersWindow && tenantPendingMembers && tenantPendingMembers.length > 0 && (
                <TenantPendingMembersWindow
                    emails={tenantPendingMembers}
                    setShow={setShowTenantPendingMembersWindow}
                    setShowAddUserWindow={setShowAddUserWindow}
                    setUserEmail={setUserEmail}
                    setShowingTenantPending={setShowingTenantPending}
                />
            )}
        </>
    );
};

export default DealActorsWindows;
