import { format } from 'date-fns';
import React from 'react';
import { useHistory } from 'react-router-dom';

type CompletedDealButtonProps = {
    id: string;
    tenantName: string;
    date: string;
};

const CompletedDealButton = ({ id, tenantName, date }: CompletedDealButtonProps) => {
    const history = useHistory();

    return (
        <div
            className="rounded-lg bg-navy-lightest p-2 w-1/4 h-16 mr-8 sm:hidden xl:flex flex-col justify-between hover:shadow-md transition duration-200"
            onClick={() => history.push(`/deals/${id}`)}
        >
            <div className="flex flex-col">
                <p className="font-semibold text-xxs text-newGray-darkish leading-3 truncate">{tenantName}</p>
                <p className="text-xxs text-gray-600">{format(new Date(date), 'dd/MM/yyy')}</p>
            </div>
            <p className="text-gray-600 flex justify-center" style={{ fontSize: '8px' }}>
                Last Agreed Deal
            </p>
        </div>
    );
};

export default CompletedDealButton;
