import React from 'react';

import { ReactComponent as AwardTeal } from '../../../assets/images/award/teal.svg';
import { ReactComponent as Award } from '../../../assets/images/award/white.svg';
import { ReactComponent as Bell } from '../../../assets/images/bell/white.svg';
import { ReactComponent as AMLCheck } from '../../../assets/images/check/square/leastGreen.svg';
import { ReactComponent as AMLCheckThinner } from '../../../assets/images/check/square/leastGreenThinner.svg';
import { ReactComponent as Codepen } from '../../../assets/images/codepen/white.svg';
import { ReactComponent as Covenant } from '../../../assets/images/file/leastGreen.svg';
import { ReactComponent as CovenantThinner } from '../../../assets/images/file/leastGreenThinner.svg';
import { ReactComponent as File } from '../../../assets/images/file/white.svg';
import { ReactComponent as Grid } from '../../../assets/images/grid/white.svg';
import { ReactComponent as Like } from '../../../assets/images/like/teal.svg';
import { ReactComponent as Login } from '../../../assets/images/login/teal.svg';
import { ReactComponent as Map } from '../../../assets/images/map/white.svg';
import { ReactComponent as Monitor } from '../../../assets/images/monitor/white.svg';
import { ReactComponent as Plus } from '../../../assets/images/plus/teal.svg';
import { ReactComponent as Settings } from '../../../assets/images/settings/white.svg';
import { ReactComponent as FinalInfo } from '../../../assets/images/stats/leastGreen.svg';
import { ReactComponent as FinalInfoThinner } from '../../../assets/images/stats/leastGreenThinner.svg';
import { ReactComponent as TenantDetails } from '../../../assets/images/tenantDetails/tenantDetails.svg';
import { ReactComponent as TenantDetailsThinner } from '../../../assets/images/tenantDetails/tenantDetailsThinner.svg';
import { ReactComponent as LawyerDetails } from '../../../assets/images/user/leastGreen.svg';
import { ReactComponent as LawyerDetailsThinner } from '../../../assets/images/user/leastGreenThinner.svg';
import { ReactComponent as Team } from '../../../assets/images/users/leastGreen.svg';
import { ReactComponent as TeamThinner } from '../../../assets/images/users/leastGreenThinner.svg';
import { ReactComponent as Video } from '../../../assets/images/video/white.svg';

export type NavRouteName =
    | 'My Deal'
    | 'Home'
    | 'Dashboard'
    | 'Notifications'
    | 'Leasing Guide'
    | 'Guidance Videos'
    | 'Settings'
    | 'New Deal'
    | 'Get Ready'
    | 'My Deals'
    | 'My Spaces'
    | 'Templates'
    | 'Readiness'
    | 'Login'
    | 'Create Account'
    | 'Tenant Details'
    | 'AML Check'
    | 'Covenant'
    | 'Team'
    | 'Lawyer Details'
    | 'Final Info';

type NavIconProps = {
    routeName: NavRouteName;
    isThinner?: boolean;
};

const NavIcon = ({ routeName, isThinner }: NavIconProps) => {
    switch (routeName) {
        case 'My Deal':
            return <Grid className="w-4 h-4 mr-2" />;
        case 'Home':
        case 'Dashboard':
            return <Monitor className="w-4 h-4 mr-2" />;
        case 'Notifications':
            return <Bell className="w-4 h-4 mr-2" />;
        case 'Leasing Guide':
            return <Map className="w-4 h-4 mr-2" />;
        case 'Guidance Videos':
            return <Video className="w-4 h-4 mr-2" />;
        case 'Settings':
            return <Settings className="w-4 h-4 mr-2" />;
        case 'New Deal':
            return <Like className="w-4 h-4 mr-2" />;
        case 'Get Ready':
            return <AwardTeal className="w-4 h-4 mr-2" />;
        case 'My Deals':
            return <Grid className="w-4 h-4 mr-2" />;
        case 'My Spaces':
            return <Codepen className="w-4 h-4 mr-2" />;
        case 'Templates':
            return <File className="w-4 h-4 mr-2" />;
        case 'Readiness':
            return <Award className="w-4 h-4 mr-2" />;
        case 'Login':
            return <Login className="w-4 h-4 mr-2" />;
        case 'Create Account':
            return <Plus className="w-4 h-4 mr-2" />;
        case 'Tenant Details':
            return isThinner ? (
                <TenantDetailsThinner className="w-7 h-7" />
            ) : (
                <TenantDetails className="w-5 h-5 mr-2 lg:ml-2 xl:ml-0" />
            );
        case 'AML Check':
            return isThinner ? (
                <AMLCheckThinner className="w-7 h-7" />
            ) : (
                <AMLCheck className="w-5 h-5 mr-2 lg:ml-2 xl:ml-0 text-newTeal-main" />
            );
        case 'Covenant':
            return isThinner ? (
                <CovenantThinner className="w-7 h-7" />
            ) : (
                <Covenant className="w-5 h-5 mr-2 lg:ml-2 xl:ml-0" />
            );
        case 'Team':
            return isThinner ? <TeamThinner className="w-7 h-7" /> : <Team className="w-5 h-5 mr-2 lg:ml-2 xl:ml-0" />;
        case 'Lawyer Details':
            return isThinner ? (
                <LawyerDetailsThinner className="w-7 h-7" />
            ) : (
                <LawyerDetails className="w-5 h-5 mr-2 lg:ml-2 xl:ml-0" />
            );
        case 'Final Info':
            return isThinner ? (
                <FinalInfoThinner className="w-7 h-7" />
            ) : (
                <FinalInfo className="w-5 h-5 mr-2 lg:ml-2 xl:ml-0" />
            );
        default:
            return null;
    }
};

export default NavIcon;
