import _, { omit } from 'lodash';
import qs from 'qs';

import { FilterValues, getSessionFilterValues, getSessionSort, Sort } from './context';
import { FieldFilter, FilterConfig, FilterType, Range, RangeDate } from './FilterWindow';
import { OrderBy } from '../../generated/graphql';
import { RadioValue } from '../Inputs/Radio';

/**
 * Transform UI Filter values to backend expected format
 * @param filters filter config
 * @param values list of filter values
 */
export const transformFilters = (filters: FieldFilter[], values: FilterValues) =>
    filters.reduce((result, item) => {
        if (item.type !== FilterType.RadioList && _.isEmpty(values[item.id])) return result;

        if (item.type === FilterType.Range) {
            const valueAsRange = values[item.id] as Range;
            if (valueAsRange.from) result[`${item.id}From`] = valueAsRange.from;
            if (valueAsRange.to) result[`${item.id}To`] = valueAsRange.to;

            return result;
        } else if (item.type === FilterType.RangeDate) {
            const valueAsRange = values[item.id] as RangeDate;
            if (valueAsRange.from) result[`${item.id}From`] = valueAsRange.from;
            if (valueAsRange.to) result[`${item.id}To`] = valueAsRange.to;

            return result;
        } else if (item.type === FilterType.RadioList) {
            const valueAsRadio = values[item.id] as RadioValue;
            if (values[item.id] === {}) {
                result[`${item.id}`] = [undefined];
            } else {
                result[`${item.id}`] = valueAsRadio;
            }
            return result;
        }

        result[item.id] = values[item.id];

        return result;
    }, {});

/**
 * Transform UI Filter values to url expected format
 * @param filters filter config
 * @param values list of filter values
 */
export const transformToFilterUrlParams = (filters: FieldFilter[], values: FilterValues) =>
    filters.reduce((result, item) => {
        if (item.type !== FilterType.RadioList && _.isEmpty(values[item.id])) return result;

        let transformed;

        if (item.type === FilterType.Range) {
            transformed = values[item.id] as Range;
            if (transformed.from) result[`${item.id}From`] = transformed.from;
            if (transformed.to) result[`${item.id}To`] = transformed.to;

            return result;
        }

        if (item.type === FilterType.RangeDate) {
            transformed = values[item.id] as RangeDate;
            if (transformed.from) result[`${item.id}From`] = transformed.from;
            if (transformed.to) result[`${item.id}To`] = transformed.to;

            return result;
        }

        if (item.type === FilterType.RadioList) {
            transformed = values[item.id] as RadioValue;
            result[`${item.id}`] = transformed;
            return result;
        }

        transformed = values[item.id] as string[];
        result[item.id] = transformed.join(',');

        return result;
    }, {});

/**
 * Initial values are read from URL params.
 */
export const initializeFilterValues = (filters: FilterConfig, page: string) => {
    const valuesFromStorage = getSessionFilterValues(page);

    if (valuesFromStorage) return JSON.parse(valuesFromStorage);

    // get the current initializer
    const initializer = createUrlInitializer();

    // reduce the filters into a single object
    return filters.reduce((acc, filter) => {
        // get a filter's initial value using the initializer
        const value = initializer(filter);

        // set the filter on the accumulator and return it to move on to the next
        // filter
        acc[filter.id] = value;

        return acc;
    }, {});
};

/**
 * Creates an empty initial value for a filter based on its type.
 */
export const emptyInitializer = (filter: FieldFilter) => {
    switch (filter.type) {
        case FilterType.AsyncList:
        case FilterType.List:
            return [];
        case FilterType.Range:
            return { from: null, to: null };
        case FilterType.RadioList:
            return;

        default:
            return '';
    }
};

/**
 * Creates empty filter values for a config
 * @param filters
 * @returns filter values
 */
export const emptyFilterValues = (filters: FilterConfig) => {
    // reduce the filters into a single object
    return filters.reduce((acc, filter) => {
        // get a filter's initial value using the initializer
        const value = emptyInitializer(filter);

        // set the filter on the accumulator and return it to move on to the next
        // filter
        acc[filter.id] = value;

        return acc;
    }, {});
};

/**
 * Provides an initial value for a filter based on its type. Selects the
 * initial value from either the current URL's search params or a default
 * value.
 */
export const createUrlInitializer = () => {
    const urlParams = new URLSearchParams(window.location.search);

    return (filter: FieldFilter) => {
        const { id } = filter;

        // if a value isn't found in the url params, we'll use its empty value instead
        const emptyValue = emptyInitializer(filter);

        switch (filter.type) {
            case FilterType.AsyncList:
            case FilterType.List: {
                let value = urlParams.get(id)?.split(',') ?? emptyValue;
                return value;
            }
            case FilterType.RadioList: {
                let value = urlParams.get(id)?.split(',') ?? emptyValue;
                return value;
            }
            case FilterType.Range: {
                const fromParam = urlParams.get(`${id}From`);
                const toParam = urlParams.get(`${id}To`);
                const from = fromParam ? parseInt(fromParam) : null;
                const to = toParam ? parseInt(toParam) : null;

                return { from, to };
            }
            default:
                return urlParams.get(id) ?? emptyValue;
        }
    };
};

/**
 * Provides an initial value for sort. Selects the
 * initial value from the current URL's search params.
 */
export const initializeSort = (page: string): Sort | undefined => {
    const valuesFromStorage = getSessionSort(page);

    if (valuesFromStorage) return JSON.parse(valuesFromStorage);

    const urlParams = new URLSearchParams(window.location.search);

    const value = urlParams.get('order') ?? undefined;

    if (value) {
        const isDesc = value.startsWith('-');

        return {
            field: isDesc ? value.slice(1) : value,
            order: isDesc ? OrderBy.Desc : OrderBy.Asc
        };
    }

    return undefined;
};

type UrlSort = {
    order: string;
};

/**
 * Transform UI Sort values to URL expected format
 * @param sort sort values
 */
export const transformToSortUrlParams = (sort: Sort): UrlSort => {
    const order = sort.field;
    if (sort.order === OrderBy.Desc) {
        return { order: `-${order}` };
    } else {
        return { order };
    }
};

/**
 * Add sort/filter params to url
 */
export const useUpdateUrl = () => {
    const updateUrl = (params: Record<string, any>) => {
        // Early return if the params already match the ones in the url
        const locationSearch = qs.parse(window.location.search.slice(1));
        const { org, landlordorg, ...rest } = locationSearch;
        if (_.isEqual({ ...rest }, params)) return;

        const encodedURL = qs.stringify({ org, landlordorg, ...params });

        window.history.replaceState({ org, landlordorg, ...params }, document.title, `?${encodedURL}`);
    };

    const addToUrl = (params: Record<string, any>, push = false) => {
        const locationSearch = qs.parse(window.location.search.slice(1));
        const newLocationSearch = { ...locationSearch, ...params };

        const encodedURL = qs.stringify(newLocationSearch);

        if (push) {
            window.history.pushState(newLocationSearch, document.title, `?${encodedURL}`);
        } else {
            window.history.replaceState(newLocationSearch, document.title, `?${encodedURL}`);
        }
    };

    const removeFromUrl = (params: string[]) => {
        const locationSearch = qs.parse(window.location.search.slice(1));
        const newLocationSearch = omit(locationSearch, params);

        const encodedURL = qs.stringify(newLocationSearch);

        window.history.replaceState(newLocationSearch, document.title, `?${encodedURL}`);
    };

    const goBack = () => window.history.back();

    return { updateUrl, addToUrl, removeFromUrl, goBack };
};

export const isRangeEmpty = (range: Range) => {
    if (!range || (!range.from && !range.to)) return true;
    return false;
};
