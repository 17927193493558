import { Reference, StoreObject, useApolloClient } from '@apollo/client';
import React, { useRef, useState } from 'react';

import {
    useDeleteGroupMutation,
    useDeleteSectionMutation,
    useGetDealDetailsLazyQuery,
    useUpdateGroupNoIndexesMutation,
    useUpdateSectionNoIndexesMutation
} from '../../generated/graphql';
import Input, { InputType } from '../Inputs';
import Loading from '../Loading';
import Window from '../Window';

type EditHelpWindowProps = {
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
    dealID: string | undefined;
    title: string | null;
    sectionID?: string | null;
    parentID: string;
    groupID?: string;
    description?: string | null;
    rics?: string | null;
};
const EditHelpWindow = ({
    setShow,
    dealID,
    parentID,
    sectionID,
    groupID,
    title,
    description,
    rics
}: EditHelpWindowProps) => {
    const client = useApolloClient();
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [refetch] = useGetDealDetailsLazyQuery({
        variables: {
            dealID: dealID
        },
        fetchPolicy: 'cache-and-network'
    });

    const [editSection, { error: editSectionError, loading: editSectionLoading }] = useUpdateSectionNoIndexesMutation();

    const [editGroup, { error: editGroupError, loading: editGroupLoading }] = useUpdateGroupNoIndexesMutation();

    const [deleteGroup, { error: deleteGroupError, loading: deleteGroupLoading }] = useDeleteGroupMutation();

    const [deleteSection, { error: deleteSectionError, loading: deleteSectionLoading }] = useDeleteSectionMutation();

    const updateUpdateCache = (
        titleValue: string | undefined,
        descriptionValue: string | undefined,
        ricsValue: string | undefined
    ) => {
        client.cache.modify({
            id: client.cache.identify({
                __typename: sectionID ? 'Section' : 'Group',
                _id: sectionID ? sectionID : groupID
            }),
            fields: {
                title() {
                    return titleValue;
                },
                rics() {
                    return ricsValue || '';
                },
                description() {
                    return descriptionValue || '';
                }
            }
        });
        refetch();
        setShow(false);
    };

    const updateDeleteCache = () => {
        if (sectionID) {
            client.cache.modify({
                id: client.cache.identify({
                    __typename: 'Deal',
                    _id: dealID
                }),
                fields: {
                    sections(existingSections = [], { readField }) {
                        return existingSections.filter(
                            (sectionRef: Reference | StoreObject | undefined) =>
                                sectionID !== readField('_id', sectionRef)
                        );
                    }
                }
            });
        } else if (groupID && parentID) {
            client.cache.modify({
                id: client.cache.identify({
                    __typename: 'Section',
                    _id: parentID
                }),
                fields: {
                    groups(existingGroups = [], { readField }) {
                        return existingGroups.filter(
                            (groupRef: Reference | StoreObject | undefined) => groupID !== readField('_id', groupRef)
                        );
                    }
                }
            });
        }

        refetch();

        setShow(false);
    };

    const handleDelete = async () => {
        if (!dealID) return;
        if (sectionID) {
            await deleteSection({
                variables: {
                    dealID,
                    sectionID
                }
            });

            if (deleteSectionError) {
                return;
            } else {
                updateDeleteCache();
            }
        } else if (groupID) {
            await deleteGroup({
                variables: {
                    dealID,
                    parentID,
                    groupID
                }
            });

            if (deleteGroupError) {
                return;
            } else {
                updateDeleteCache();
            }
        }
    };

    const handleUpdate = async () => {
        if (!dealID) return;
        const descriptionValue = descriptionRef.current?.value.trim();

        const ricsValue = ricsRef.current?.value.trim();

        const titleValue = titleRef.current?.value.trim();

        if (!titleValue) {
            setErrorMessage('Title is a required field');
            return;
        }
        if (sectionID) {
            await editSection({
                variables: {
                    dealID,
                    id: sectionID,
                    description: descriptionValue,
                    rics: ricsValue,
                    title: titleValue
                }
            });

            if (editSectionError) {
                return;
            } else {
                return updateUpdateCache(titleValue, descriptionValue, ricsValue);
            }
        } else if (groupID) {
            await editGroup({
                variables: {
                    dealID,
                    parentID,
                    id: groupID,
                    description: descriptionValue,
                    rics: ricsValue,
                    title: titleValue
                }
            });
            if (editGroupError) {
                return;
            } else {
                return updateUpdateCache(titleValue, descriptionValue, ricsValue);
            }
        }
    };
    const titleRef = useRef<HTMLInputElement>(null);
    const descriptionRef = useRef<HTMLTextAreaElement>(null);
    const ricsRef = useRef<HTMLTextAreaElement>(null);
    return (
        <Window
            width="w-2/5"
            title={`Edit Clause ${sectionID ? 'Group' : 'Heading'}`}
            setShow={setShow}
            primaryBtn={!editSectionLoading && !editGroupLoading && !deleteGroupLoading && !deleteSectionLoading}
            onPrimaryBtnClick={handleUpdate}
            secondaryBtn={!editSectionLoading && !editGroupLoading && !deleteGroupLoading && !deleteSectionLoading}
            secondaryBtnText={`Delete Clause ${sectionID ? 'Group' : 'Heading'}`}
            onSecondaryBtnClick={handleDelete}
        >
            <form
                className="flex flex-col py-4 px-2 w-full"
                onSubmit={(event) => {
                    event.preventDefault();
                    handleUpdate();
                }}
            >
                {editSectionLoading || editGroupLoading || deleteGroupLoading || deleteSectionLoading ? (
                    <Loading />
                ) : (
                    <>
                        <div className="flex flex-row items-center md:flex-no-wrap w-full gap-4 mb-4">
                            <Input
                                type={InputType.text}
                                ref={titleRef}
                                defaultValue={title}
                                placeholder="Name"
                                className="w-full"
                                label={`Clause ${sectionID ? 'Group' : 'Heading'} Name`}
                            />
                        </div>
                        <div className="flex flex-col">
                            <label htmlFor="section-name" className="text-sm text-gray-600 mb-2">
                                Description/Help Text
                            </label>
                            <textarea
                                ref={descriptionRef}
                                rows={2}
                                defaultValue={description ? description : undefined}
                                className="p-3 border border-navy-light focus:border-newTeal-main rounded w-full  focus:outline-none"
                                placeholder="Description text"
                            ></textarea>
                            <label htmlFor="section-name" className="text-sm text-gray-600 mb-2 mt-2">
                                Longer RICS Explainer
                            </label>
                            <textarea
                                ref={ricsRef}
                                rows={3}
                                defaultValue={rics ? rics : undefined}
                                className="p-3 border border-navy-light focus:border-newTeal-main rounded w-full  focus:outline-none"
                                placeholder="Description text"
                            ></textarea>
                        </div>
                    </>
                )}
                {errorMessage && (
                    <div className="bg-red-200 border border-red-600 rounded w-full py-2 px-4 mt-4">
                        <span className="text-center text-red-600">{errorMessage}</span>
                    </div>
                )}
            </form>
        </Window>
    );
};

export default EditHelpWindow;
