import React, { useContext, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';

import DealHeaderContext from './DealHeaderContext';
import { ReactComponent as Archive } from '../../../../assets/images/archive/black.svg';
import { ReactComponent as ReturnArrow } from '../../../../assets/images/arrow/up-left/black.svg';
import { ReactComponent as CircleCheck } from '../../../../assets/images/check/circle/white.svg';
import { ReactComponent as Copy } from '../../../../assets/images/copy/black.svg';
import { ReactComponent as Save } from '../../../../assets/images/save/newSave/black.svg';
import { ReactComponent as Send } from '../../../../assets/images/send/black.svg';
import {
    DealApprovalSendType,
    DealSendType,
    DealState,
    GetNegotiationSummaryDocument,
    useGetStagesCompletedQuery,
    useUndoDealMutation
} from '../../../../generated/graphql';
import RejectDealDropdown from '../../../../pages/Deals/Deal/Approve/RejectDealDropdown';
import { DealContext } from '../../../../pages/Deals/Deal/context';
import DealSendWindow from '../../../../pages/Deals/Deal/DealSendWindow';
import { canReturn } from '../../../../pages/Deals/Deal/Negotiate';
import TenantDetailsWindow from '../../../../pages/Units/Unit/Landlord/TenantDetailsWindow';
import { ButtonColor, Button as ButtonNew, ButtonSize } from '../../../../shared/button-new';
import { Flex } from '../../../../shared/Flex';
import Select from '../../../../shared/Inputs/Select';
import { isLandlordSide, useUserType } from '../../../common/helpers';
import { LandlordFilesContext } from '../../../components/LandlordsFileModal/LandlordFilesContext';

import { DealHeaderPage } from './';

export enum DealMode {
    Negotiator,
    Preview
}

type ButtonProps = {
    backgroundColor: string;
    text: string;
    color?: string;
    icon: React.ReactNode;
    click: () => void;
    className?: string;
    height?: string;
    labelClassName?: string;
    dataTip?: string;
};

const Button = ({
    backgroundColor,
    text,
    icon,
    click,
    color,
    className,
    height,
    labelClassName,
    dataTip
}: ButtonProps) => {
    return (
        <div
            data-tip={dataTip}
            className={`px-2 h-${
                height ?? '11'
            } mb-2 border justify-center flex flex-row items-center rounded-lg ${className} ${backgroundColor} ${color} cursor-pointer`}
            onClick={click}
        >
            {icon}
            <p className={`md:hidden  ${labelClassName ?? 'lg:flex'}`}>{text}</p>
        </div>
    );
};

const DealHeaderButtons = () => {
    const {
        page,
        detailsData,
        setHideToolbar,
        canSend,
        setShareWindowType,
        setShowSendWindow,
        shareWindowType,
        setShowRejectDealWindow,
        setShowApproveDealWindow,
        isClauseReordering,
        isTitlesReordering,
        setTitlesReordering = () => {},
        setClauseReordering = () => {},
        getDealDetails
    } = useContext(DealHeaderContext);

    const { id, token } = useParams<{ id: string; token: string }>();

    const { data: stages } = useGetStagesCompletedQuery({
        variables: {
            dealID: id,
            token
        }
    });

    const { downloadOpen = () => {} } = useContext(DealContext);

    const { setShowLandlordFilesWindow } = useContext(LandlordFilesContext);
    const [showSendWindow, setShowShareWindow] = useState(false);
    const [tenantReadinessWindow, setTenantReadinessWindow] = useState(false);

    const { isEdit, setIsEdit, setSeenDealAgreed, reload, setReload } = useContext(DealContext);
    const [undoDeal] = useUndoDealMutation();

    const doSendDeal = (type: DealSendType) => {
        setShareWindowType(type);
        setShowSendWindow(true);
    };
    const { isTenantSide } = useUserType(undefined, detailsData?.deal.currentUserParty);

    const history = useHistory();

    const handleSendOtherParty = (party: DealSendType) => {
        setHideToolbar && setHideToolbar(false);
        doSendDeal(party);
    };
    const stage = stages?.deal.details;
    const stageStates = [
        stage?.one?.completed,
        stage?.two?.completed,
        stage?.team?.completed,
        stage?.three?.completed,
        stage?.aml?.completed,
        stage?.final?.completed
    ];

    const completedStages = stageStates.filter((isCompleted) => isCompleted).length;

    const isCompleted = detailsData?.deal.state === DealState.Complete;
    const tenantState = detailsData?.deal.state === DealState.Tenant;
    const isAgreed = detailsData?.deal.state === DealState.Agreed;

    const isDefault = page === DealHeaderPage.DEFAULT;
    const isApprove = page === DealHeaderPage.APPROVE;

    const isLandlordCurrentParty = isLandlordSide(detailsData?.deal.currentUserParty);

    const approvalResponse = shareWindowType === DealSendType.Other ? DealApprovalSendType.Other : undefined;

    const returnToAgreed = canReturn(detailsData?.deal, isEdit);

    const isReordering = isTitlesReordering || isClauseReordering;

    const handleMoreOptionsClick = (id) => {
        if (id === 'files') setShowLandlordFilesWindow(true);
        else if (id === 'preview') {
            isDefault
                ? history.push(`/deals/${detailsData?.deal._id}/preview`)
                : history.push(`/deals/${detailsData?.deal._id}/`);
        }
    };

    const handleChangeMode = (mode: DealMode) => {
        if (mode === DealMode.Negotiator) {
            history.push(`/deals/${detailsData?.deal._id}/negotiate`);
            return;
        }

        if (mode === DealMode.Preview) {
            history.push(`/deals/${detailsData?.deal._id}/preview`);
            return;
        }
    };

    const negotiatorPreviewAction = useMemo(() => {
        const btnText = isDefault ? 'Preview Mode' : 'Negotiator';

        return (
            <ButtonNew
                color={ButtonColor.secondary}
                size={ButtonSize.large}
                text={btnText}
                onClick={() => handleChangeMode(isDefault ? DealMode.Preview : DealMode.Negotiator)}
            />
        );
    }, [isDefault]);

    return (
        <>
            {showSendWindow && (
                <DealSendWindow
                    isCompleted={isCompleted}
                    dealID={detailsData?.deal._id!}
                    type={DealSendType.Other}
                    currentUserParty={detailsData?.deal.currentUserParty!}
                    showEmail={shareWindowType !== DealSendType.Landlord && shareWindowType !== DealSendType.Tenant}
                    setShow={setShowShareWindow}
                    approvalResponse={approvalResponse}
                />
            )}
            {tenantReadinessWindow && <TenantDetailsWindow dealID={id} setShow={setTenantReadinessWindow} />}
            <div
                className={`flex flex-row ${
                    returnToAgreed && !isReordering ? 'md:w-3/12' : 'md:w-1/6'
                } lg:w-5/12 xl:w-4/12 content-center justify-end items-center`}
            >
                <div className="flex flex-row w-full">
                    <div
                        className={`flex flex-col ${
                            isCompleted && 'justify-end'
                        } text-08 sm:ml-1 lg:ml-2 sm:mr-2 lg:mr-2 ${
                            returnToAgreed && !isReordering ? 'sm:w-4/12 lg:w-3/12 xl:w-2/12' : 'w-1/2'
                        } `}
                    >
                        {!isApprove && !isReordering && !isTenantSide && (
                            <div
                                onClick={() => setTenantReadinessWindow(true)}
                                className={`px-2 h-11 mb-2 border flex flex-row items-center justify-center rounded-lg bg-navy-lightest hover:bg-navy-light cursor-pointer`}
                            >
                                <div className="text-base lg:mr-4">
                                    <span className="text-lg font-semibold">{completedStages}</span>
                                    /6
                                </div>
                                <p className={`md:hidden ${returnToAgreed ? 'lg:hidden' : 'lg:flex'}`}>
                                    Tenant Readiness
                                </p>
                            </div>
                        )}
                        {!isApprove &&
                            !isReordering &&
                            (isTenantSide || (!isTenantSide && (isCompleted || isAgreed))) && (
                                // Files
                                <div
                                    onClick={() => setShowLandlordFilesWindow(true)}
                                    className={`px-2 h-11 ${
                                        !isCompleted && 'mb-2'
                                    } border flex flex-row items-center justify-center rounded-lg bg-navy-lightest hover:bg-navy-light cursor-pointer`}
                                >
                                    <Archive className={`w-4 h-4 ${!returnToAgreed && 'lg:mr-4'}`} />
                                    <p
                                        className={`md:hidden ${returnToAgreed ? 'lg:hidden' : 'lg:flex'}  ${
                                            isLandlordCurrentParty && 'mr-14'
                                        }`}
                                    >
                                        {isTenantSide ? 'Landlord Files' : 'Files'}
                                    </p>
                                </div>
                            )}

                        {!isTenantSide && !isCompleted && !isAgreed && !isReordering && !isApprove && isDefault && (
                            <Select
                                data={[
                                    {
                                        id: 'files',
                                        text: 'Files'
                                    },
                                    {
                                        id: 'preview',
                                        text: 'Preview Mode'
                                    }
                                ]}
                                searchable={false}
                                onItemClick={(id) => handleMoreOptionsClick(id!)}
                                value={{
                                    value: 'placeholder',
                                    label: 'More Actions'
                                }}
                                createable
                                controlNoBorder
                                backgroundColor="#F6F6F8"
                                dropdownIndicatorColor="black"
                                paddingLeft="1.5rem"
                                paddingRight="1.5rem"
                                color="black"
                                minHeight="2.5rem"
                                height="2.5rem"
                                minWidth="98%"
                                className=" h-11 border flex flex-row items-center justify-center rounded-lg bg-navy-lightest cursor-pointer"
                            />
                        )}
                        {!isCompleted &&
                            !isReordering &&
                            !isApprove &&
                            (isTenantSide || (!isTenantSide && !isDefault)) &&
                            // Preview
                            Button({
                                backgroundColor: 'bg-navy-lightest  hover:bg-navy-light',
                                text: isDefault ? 'Preview Mode' : 'Negotiator',
                                icon: <Copy className={`w-4 h-4 ${!returnToAgreed && 'lg:mr-4'}`} />,
                                labelClassName: `${returnToAgreed ? '' : 'lg:flex'}`,
                                click: () => {
                                    isDefault
                                        ? history.push(`/deals/${detailsData?.deal._id}/preview`)
                                        : history.push(`/deals/${detailsData?.deal._id}/`);
                                }
                            })}
                    </div>
                    <div
                        className={`flex text-08 flex-col ${
                            returnToAgreed && !isReordering ? 'sm:w-4/12 lg:w-3/12 xl:w-5/12' : 'w-1/2'
                        } `}
                    >
                        {isApprove ? (
                            <div
                                className={`px-2 h-11 mb-2 border flex flex-row items-center justify-center rounded-lg bg-newTeal-main cursor-pointer`}
                                onClick={() => setShowApproveDealWindow && setShowApproveDealWindow(true)}
                            >
                                <p className={`md:hidden  lg:flex`}>Approved</p>
                            </div>
                        ) : (
                            !isCompleted &&
                            !isReordering && (
                                <div
                                    className={`px-2 h-11 mb-2 border flex flex-row items-center justify-center text-black rounded-lg ${
                                        !canSend ||
                                        (isLandlordCurrentParty && tenantState) ||
                                        (!isLandlordCurrentParty && !tenantState)
                                            ? 'bg-navy-lightest text-newGray-darkish '
                                            : 'bg-newTeal-light hover:bg-newTeal-main hover:text-white'
                                    } cursor-pointer`}
                                    onClick={() =>
                                        canSend &&
                                        ((isLandlordCurrentParty && !tenantState) ||
                                            (!isLandlordCurrentParty && tenantState)) &&
                                        handleSendOtherParty(
                                            isLandlordCurrentParty ? DealSendType.Tenant : DealSendType.Landlord
                                        )
                                    }
                                    data-border-color={'white'}
                                    data-tip={
                                        !isLandlordCurrentParty
                                            ? !tenantState
                                                ? 'The Terms are currently with the counterparty.'
                                                : 'You must action all the clauses before sending to counterparty.'
                                            : tenantState
                                            ? 'The Terms are currently with the counterparty.'
                                            : 'You must action all the clauses before sending to counterparty.'
                                    }
                                    data-for={'send'}
                                >
                                    <Send className={`w-5 h-5  ${!returnToAgreed ? 'lg:mr-4 xl:ml-2' : 'xl:mr-4'} `} />
                                    <p className={`md:hidden  ${returnToAgreed ? 'xl:flex' : 'lg:flex'}`}>
                                        Send to Counterparty
                                    </p>
                                </div>
                            )
                        )}
                        {(!canSend ||
                            (!tenantState && !isLandlordCurrentParty) ||
                            (tenantState && isLandlordCurrentParty)) && (
                            <ReactTooltip
                                backgroundColor="black"
                                textColor="white"
                                effect="solid"
                                place="bottom"
                                clickable={false}
                                borderColor="white"
                                id={'send'}
                            />
                        )}
                        {page === DealHeaderPage.APPROVE
                            ? detailsData &&
                              setShowRejectDealWindow && (
                                  <RejectDealDropdown
                                      background={'navy-lightest'}
                                      border={'bg-navy-lightest'}
                                      className={'w-full rounded-lg'}
                                      fontWeight={'font-normal'}
                                      height={'11'}
                                      dealID={detailsData.deal._id}
                                      showMakeChanges={
                                          !!(
                                              detailsData.deal &&
                                              (!detailsData.deal.approvalID ||
                                                  (detailsData.deal.approvalID && detailsData.deal.hasAccess))
                                          )
                                      }
                                      setShowRejectDealWindow={setShowRejectDealWindow}
                                  />
                              )
                            : !isCompleted &&
                              Button({
                                  backgroundColor: 'bg-navy-lightest hover:bg-navy-light',
                                  text: isReordering ? 'Save Order' : 'Send for Approval',
                                  color: 'text-black',
                                  labelClassName: 'lg:flex',
                                  icon: isReordering ? (
                                      <Save className="w-5 h-5 lg:mr-4 xl:mr-4" />
                                  ) : (
                                      <CircleCheck className={`w-5 h-5 ${!returnToAgreed ? 'lg:mr-4' : 'xl:mr-4'}`} />
                                  ),
                                  click: () => {
                                      if (isReordering) {
                                          if (detailsData?.deal.__typename === 'Deal') {
                                              getDealDetails({
                                                  variables: {
                                                      dealID: detailsData?.deal._id
                                                  }
                                              });
                                          }
                                          setTitlesReordering(false);
                                          setClauseReordering(false);
                                      } else {
                                          setShareWindowType(DealSendType.Approver);
                                          setShowSendWindow(true);
                                      }
                                  }
                              })}

                        {isCompleted && (
                            <Flex className="gap-2" direction="column">
                                <ButtonNew
                                    color={ButtonColor.primary}
                                    size={ButtonSize.large}
                                    text="Download Deal Data"
                                    onClick={downloadOpen}
                                />
                                {negotiatorPreviewAction}
                            </Flex>
                        )}
                    </div>
                    {returnToAgreed &&
                        !isReordering &&
                        Button({
                            dataTip: 'Return To Agreed Terms',
                            backgroundColor: 'bg-newTeal-main',
                            text: 'Return To Agreed Terms',
                            color: 'text-black',
                            icon: <ReturnArrow className="w-6 h-6 lg:mr-2 xl:mr-4" />,
                            height: '24',
                            className: 'sm:w-4/12 lg:w-6/12 xl:w-5/12 text-08 ml-2',
                            click: async () => {
                                await undoDeal({
                                    variables: {
                                        dealID: detailsData?.deal._id
                                    },
                                    refetchQueries: [
                                        {
                                            query: GetNegotiationSummaryDocument,
                                            variables: {
                                                id: detailsData?.deal._id
                                            }
                                        }
                                    ],
                                    onCompleted: () => {
                                        setSeenDealAgreed(false);
                                        setReload(!reload);
                                    }
                                });
                                setIsEdit(false);
                            }
                        })}
                </div>
            </div>
            <ReactTooltip
                backgroundColor="#484444"
                textColor="white"
                effect="solid"
                place="bottom"
                clickable={false}
                multiline
                scrollHide
            />
        </>
    );
};

export default DealHeaderButtons;
