import { FieldInputProps, FormikProps } from 'formik';
import React, { useState } from 'react';
import { ActionMeta, ValueType } from 'react-select';
import SelectComponent, { Props } from 'react-select';
import CreateSelectComponent from 'react-select/creatable';

import { ReactComponent as Cross } from '../../assets/images/cross/newCross.svg';
import {
    SingleUnitCardFragment,
    useGetAllUserTypeQuery,
    useGetUserOrganisationQuery,
    UserType
} from '../../generated/graphql';

export type Option = { text?: string | null; id?: string | null };

export type SelectProps = Props & {
    data: Option[];
    subGroup?: {
        text: string;
        options: Option[];
    }[];
    required?: boolean;
    createable?: boolean;
    clearable?: boolean;
    searchable?: boolean;
    defaultValue?: ValueType<{ label: string; value: string }, boolean>;
    autoFocus?: boolean;
    isMulti?: boolean;
    disabled?: boolean;
    className?: string;
    placeholder?: string;
    label?: string;
    containerClassName?: string;
    chipBackground?: string;
    graySelect?: boolean;
    unit?: SingleUnitCardFragment;
    onItemClick?: (id?: string | null) => any;
    onChange?:
        | ((
              value: ValueType<
                  {
                      label: string;
                      value: string;
                  },
                  boolean
              >,
              action: ActionMeta<{
                  label: string;
                  value: string;
              }>
          ) => void)
        | undefined;
    error?: string;
    blackStyle?: boolean;
    initialChips?: string[] | null;
    setEmails?: React.Dispatch<React.SetStateAction<string[]>>;
    isBulk?: boolean;
    agentEmail?: string;
    controlNoBorder?: boolean;
    field?: FieldInputProps<any>;
    form?: FormikProps<any>;
    bgLeast?: boolean;
    borderRadius?: string;
    minHeight?: string;
    minWidth?: string;
    height?: string;
    paddingBottom?: string;
    marginTop?: string;
    indicatorMarginTop?: string;
    alignItems?: string;
    placeholderPaddingBottom?: string;
    backgroundColor?: string;
    hoverBackgroundColor?: string;
    dropdownIndicatorColor?: string;
    paddingRight?: string;
    paddingLeft?: string;
};

const Select = ({
    data,
    defaultValue,
    placeholder,
    createable,
    clearable,
    searchable,
    autoFocus,
    isMulti = false,
    disabled,
    className,
    containerClassName,
    color,
    chipBackground,
    onItemClick,
    onChange,
    label,
    required,
    graySelect,
    error,
    blackStyle,
    initialChips,
    setEmails,
    isBulk,
    subGroup,
    agentEmail,
    unit,
    controlNoBorder,
    field,
    form,
    value,
    bgLeast,
    borderRadius,
    minHeight,
    minWidth,
    height,
    paddingBottom,
    marginTop,
    indicatorMarginTop,
    alignItems,
    placeholderPaddingBottom,
    backgroundColor,
    hoverBackgroundColor,
    dropdownIndicatorColor,
    paddingRight,
    paddingLeft,
    ...passDownProps
}: SelectProps) => {
    const dropdownOptions = (options: Option[]) => {
        return options
            .filter((item) => item && item.id && item.text)
            .map((item) => ({
                value: item.id ?? '',
                label: item.text ?? ''
            }));
    };
    const props: Props = {
        ...passDownProps,
        options: subGroup
            ? subGroup.map((group) => ({
                  label: group.text,

                  options: dropdownOptions(group.options)
              }))
            : dropdownOptions(data),
        className,
        styles: {
            // input: (styles, state) => ({ ...styles, borderColor: "#3CA48E" }),
            option: (styles: React.CSSProperties, { data, isFocused }) => ({
                ...styles,
                borderBottom: '1px solid #f1f1f1',
                backgroundColor: isFocused ? '#f5f5f5' : 'none',
                color: chipBackground && !blackStyle ? chipBackground : 'black',
                '&:hover': {
                    backgroundColor: '#f1f1f1'
                }
            }),
            group: () => ({
                marginTop: 0
            }),
            groupHeading: () => ({
                borderBottom: '1px solid #f1f1f1',
                backgroundColor: '#f5f5f5',
                fontWeight: '500',
                color: '#a6a6a6',
                fontSize: '12px',
                padding: '10px',
                textTransform: 'uppercase'
            }),
            singleValue: (styles: React.CSSProperties) => ({
                ...styles,
                color: isBulk ? 'white' : 'black',
                paddingBottom: paddingBottom ?? 'auto',
                fontWeight: isBulk ? 600 : styles.fontWeight
            }),
            control: (styles: React.CSSProperties, state) => ({
                ...styles,
                alignItems: alignItems ?? 'center',
                paddingBottom: state.isFocused ? placeholderPaddingBottom : paddingBottom ?? 'auto',
                paddingRight: paddingRight ?? 'auto',
                paddingLeft: paddingLeft ?? 'auto',
                minHeight: minHeight ? minHeight : initialChips ? '0' : '3rem',
                height: height ? height : blackStyle ? 'auto' : '3rem',
                boxShadow: 'none',
                overflowY: 'hidden',
                borderRadius: borderRadius,
                border: initialChips || controlNoBorder ? 'none' : '',
                borderColor: initialChips
                    ? 'none'
                    : state.isFocused && isBulk
                    ? '#95e9e4'
                    : state.isFocused && !isBulk
                    ? '#3CA48E'
                    : '#D9D9D9',
                cursor: 'pointer',
                backgroundColor:
                    backgroundColor ?? (isBulk ? '#3CA48E' : graySelect ? '#f1f1f1' : bgLeast ? '#3CA48E' : 'white'),
                minWidth: minWidth ? minWidth : isBulk ? '10.5rem' : 'auto',
                '&:hover': {
                    borderColor: '#3CA48E',
                    boxShadow: `0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)`,
                    backgroundColor: hoverBackgroundColor ?? '#3CA48E'
                }
            }),
            valueContainer: (styles: React.CSSProperties) => ({
                ...styles,
                height: height,
                overflow: 'hidden',
                paddingBottom: paddingBottom ?? 'auto',
                padding: initialChips ? '0' : '2px 8px',
                marginTop: marginTop ?? 'none'
            }),
            clearIndicator: (styles: React.CSSProperties) => ({
                ...styles,
                width: initialChips ? '0' : '2rem'
            }),
            dropdownIndicator: (styles: React.CSSProperties) => ({
                ...styles,
                width: '2rem',
                color: graySelect ? 'black' : isBulk ? 'white' : dropdownIndicatorColor ?? '#d0d0d0'
            }),
            indicatorSeparator: (styles: React.CSSProperties) => ({
                ...styles,
                display: 'none'
            }),
            indicatorsContainer: (styles: React.CSSProperties) => ({
                ...styles,
                paddingBottom: paddingBottom ?? 'auto',
                marginTop: indicatorMarginTop ?? 'none'
            }),
            placeholder: (styles: React.CSSProperties) => ({
                ...styles,
                paddingBottom: placeholderPaddingBottom ?? 'auto',
                color: '#aeb6c6'
            }),
            multiValue: (styles) => ({
                ...styles,
                marginBottom: initialChips ? '0.75rem' : 'auto',
                borderRadius: '9999px',
                backgroundColor: chipBackground ?? '#D7EDE8',
                overflow: 'hidden',
                border: '1px solid #3CA48E'
            }),
            multiValueLabel: (styles) => ({
                ...styles,
                color: color ?? 'black',
                paddingLeft: '10px',
                paddingRight: '0',
                marginRight: '0'
            }),
            multiValueRemove: (styles) => ({
                ...styles,
                width: '1.5rem',
                color: color ?? 'black',
                ':hover': {
                    backgroundColor: chipBackground ?? '#D7EDE8',
                    color: 'lightgray',
                    cursor: 'pointer'
                }
            })
        },
        defaultValue,
        autoFocus,
        isMulti,
        placeholder,
        value,
        isDisabled: disabled,
        isClearable: clearable,
        isSearchable: searchable,
        noOptionsMessage:
            createable && isMulti && !initialChips
                ? () => 'Start typing to add a new item'
                : initialChips
                ? () => 'Start typing to invite a new user'
                : undefined,
        onChange: onChange ? onChange : (newValue: any, actionMeta: any) => onItemClick && onItemClick(newValue?.value)
    };
    const { data: userOrganisationData } = useGetUserOrganisationQuery();
    const { data: typeData } = useGetAllUserTypeQuery();

    const checkIfActivated = (chip: string) => {
        const trustedUsersArray =
            typeData?.user.type !== UserType.Agent
                ? userOrganisationData?.user.organisation.trustedUsers
                : unit?.organisation.trustedUsers;
        return trustedUsersArray?.find((trustedUser) => trustedUser.email === chip && trustedUser.activated);
    };

    const [chips, setChips] = useState<string[]>(initialChips ?? []);

    const removeChips = (index: number) => {
        const newChipsArray = [...chips];
        newChipsArray.splice(index, 1);
        setChips(newChipsArray);
        setEmails && setEmails(newChipsArray);
    };

    if (createable && !initialChips)
        return (
            <div className={`relative w-full flex-1 ${containerClassName}`}>
                {label && <p className="text-gray-600 font-medium text-sm mb-0.5">{label}</p>}
                <CreateSelectComponent
                    {...props}
                    // className={`${props.className} ${label && "mt-6"}`}
                />
            </div>
        );
    else if (initialChips)
        return (
            <div className={`relative w-full flex-1 ${containerClassName}`}>
                {label && <p className="text-gray-600 font-medium text-sm ">{label}</p>}
                <div
                    className="border-navy-light border w-full h-12 pt-6 mt-6 z-10 absolute"
                    style={{ height: '-webkit-fill-available' }}
                />
                <div
                    className={`flex flex-wrap items-center z-20 relative w-full mt-7 mr-1 px-2`}
                    style={{ display: 'contents !important' }}
                >
                    <div className="mr-12">
                        {chips &&
                            chips.map((chip, i) => (
                                <div className="chip mt-1 mb-3 mr-1" key={i}>
                                    <div className="chip-content-select">
                                        {checkIfActivated(chip)
                                            ? checkIfActivated(chip)?.firstName +
                                              ' ' +
                                              checkIfActivated(chip)?.lastName +
                                              ' (' +
                                              checkIfActivated(chip)?.organisation.name +
                                              ')'
                                            : chip}
                                    </div>
                                    {agentEmail && chip !== agentEmail ? (
                                        <div className="chip-close h-5 w-3" onClick={() => removeChips(i)}>
                                            <Cross className="chip-svg h-3 w-4 " />
                                        </div>
                                    ) : (
                                        <></>
                                    )}
                                    {!agentEmail && (
                                        <div className="chip-close h-5 w-3" onClick={() => removeChips(i)}>
                                            <Cross className="chip-svg h-3 w-4 " />
                                        </div>
                                    )}
                                </div>
                            ))}
                    </div>
                    <CreateSelectComponent {...props} />
                </div>
            </div>
        );
    return (
        <div className={`relative w-full flex-1 ${containerClassName}`}>
            {label && (
                <p className="absolute top-0 left-0 text-gray-600 font-medium text-sm mb-0.5">
                    {label}
                    <span className="text-newTeal-main ml-1">{required && '*'}</span>
                </p>
            )}
            <SelectComponent {...props} className={`text-lg ${props.className} ${label && 'mt-6'}`} />
            {field && form?.touched[field.name] && form?.errors[field.name] && (
                <div className="text-red-600">{form?.errors[field?.name]}</div>
            )}
            {error && <p className="text-red-500 font-medium mb-0.5">{error}</p>}
        </div>
    );
};

export default Select;
