import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import EditUnitWindow from './EditUnitWindow';
import Tasks from './Tasks';
import TenantDetailsWindow from './TenantDetailsWindow';
import UnitReadiness from './UnitReadiness';
import UnitReadinessDesktop from './UnitReadinessDesktop';
import { ReactComponent as Chevron } from '../../../../assets/images/chevron/right/black.svg';
import { ReactComponent as Close } from '../../../../assets/images/cross/black.svg';
import { ReactComponent as Envelope } from '../../../../assets/images/envelope/black.svg';
import { ReactComponent as Phone } from '../../../../assets/images/phone/black.svg';
import { ReactComponent as Plus } from '../../../../assets/images/plus/black.svg';
import { ReactComponent as BlackPlus } from '../../../../assets/images/plus/black.svg';
import {
    GetUserOrganisationDocument,
    useGetUnitDetailsQuery,
    useGetUnitRatingQuery,
    useGetUserProfileQuery,
    useInviteUserMutation,
    UserType,
    useVerifyUnitMutation
} from '../../../../generated/graphql';
import { Button } from '../../../../shared/button';
import Grid from '../../../../shared/Grid';
import { handleFetchError, useQuery } from '../../../../shared/helpers';
import useMobileScreen from '../../../../shared/hooks/useMobileScreen';
import EmailMultiInput from '../../../../shared/Inputs/EmailMultiInput';
import Loading from '../../../../shared/Loading';
import Window from '../../../../shared/Window';
import DealCard from '../../../Deals/DealCard';
import NewDealWindow from '../../../Deals/NewDealWindow';
import useSetHeaderContent from '../hooks/useSetHeaderContent';
import UnitDeals from '../UnitDeals';
import UnitOverview from '../UnitOverview';
import UnitOverviewDesktop from '../UnitOverviewDesktop';

export enum ReadinessTabs {
    All = 'all',
    Completed = 'completed',
    Files = 'files',
    Todo = 'todo'
}

export const ReadinessTabsLabelMap = {
    [ReadinessTabs.All]: 'All',
    [ReadinessTabs.Todo]: 'To Do',
    [ReadinessTabs.Completed]: 'Completed',
    [ReadinessTabs.Files]: 'Files'
} as const;

type LandlordProps = {
    id: string;
    selectedTab: {
        index: number;
        tab: string;
    };
    setHeaderContent: React.Dispatch<React.SetStateAction<React.ReactChild | null>>;
};

const Landlord = ({ id, selectedTab, setHeaderContent: _setHeaderContent }: LandlordProps) => {
    const history = useHistory();
    const location = useLocation();
    const query = useQuery();
    const token = query.get('token');
    const setHeaderContent = useSetHeaderContent(_setHeaderContent);

    const {
        data: detailsData,
        loading: detailsLoading,
        error: detailsError,
        refetch
    } = useGetUnitDetailsQuery({
        fetchPolicy: 'cache-and-network',
        variables: {
            unitID: id
        }
    });

    // Get unit rating separately so that we can refetch it later
    const { data: ratingsData, loading: ratingsLoading } = useGetUnitRatingQuery({
        variables: {
            unitID: id
        }
    });

    const [verifyUnit, { error: verifyError }] = useVerifyUnitMutation();

    // Set to loading if need to verify token
    const [verificationLoading, setVerificationLoading] = useState<boolean>(!!token);

    const [tenantDetailsWindow, setTenantDetailsWindow] = useState<boolean>(false);

    const [selectedDealId, setSelectedDealId] = useState<string>();

    const [showNewDealWindow, setShowNewDealWindow] = useState<boolean>(false);

    const [showEditUnitWindow, setShowEditUnitWindow] = useState<boolean>(false);

    const [showTeamList, setShowTeamList] = useState<boolean>(false);

    const [showAddUser, setShowAddUser] = useState<boolean>(false);
    const [email, setEmail] = useState<string>();
    const { data: typeData } = useGetUserProfileQuery();

    const landlordTeam = detailsData?.unit.organisation.members || [];

    const [inviteUser, { loading: addActorLoading }] = useInviteUserMutation();

    const [emails, setEmails] = useState<string[]>([]);
    const [onFocus, setOnFocus] = useState<boolean>(false);

    const isMobile = useMobileScreen();
    // Set header content and handle errors
    useEffect(() => {
        detailsData && setHeaderContent(detailsData.unit);
    }, [detailsData]);

    // Check if verification route
    useEffect(() => {
        const verify = async (token?: string) => {
            if (token) {
                await verifyUnit({
                    variables: {
                        token: token
                    }
                });

                if (verifyError) {
                    // Redirect to units page if error, and not already verified
                    // If user is not logged in, redirect to login page and store details
                    if (verifyError.graphQLErrors[0].extensions?.code === 'UNAUTHENTICATED')
                        return history.push(`/login?prev=${location.pathname}?token=${token}`);

                    return history.push('/units');
                }

                setVerificationLoading(false);
            }
        };
        verificationLoading && token && verify(token);
    }, [token, history, verifyError, verifyUnit, verificationLoading, location.pathname]);

    useEffect(() => {
        // Wait for verification first
        if (verificationLoading) return;

        // Check for errors
        handleFetchError('Could not load the demise', detailsError, detailsData?.unit);
        // Redirect to units page if error e.g. ID in url is not valid
        if (detailsError) history.push('/units');
    }, [detailsData, detailsError, history, verificationLoading]);

    const [activeTab, setActiveTab] = useState<keyof typeof ReadinessTabsLabelMap>(ReadinessTabs.All);

    const existingTasks = useMemo(() => {
        if (!detailsData?.unit.tasks) return [];

        return detailsData.unit.tasks.filter((task) => task.task.title !== 'Deleted Task');
    }, [detailsData?.unit.tasks]);

    const completedTasks = useMemo(() => {
        return existingTasks.filter((task) => task.value !== null || !task.applicable);
    }, [existingTasks]);

    const todoTasks = useMemo(() => {
        return existingTasks.filter((task) => task.value === null && task.applicable);
    }, [existingTasks]);

    const allTasks = useMemo(() => {
        // show uncompleted tasks first
        return [...todoTasks, ...completedTasks];
    }, [completedTasks, todoTasks]);

    const fileTasks = useMemo(() => {
        return existingTasks.filter((task) => task.task.type === 'FILE');
    }, [existingTasks]);

    const getTabCount = useCallback(
        (tab: ReadinessTabs) => {
            switch (tab) {
                case ReadinessTabs.All:
                    return allTasks.length;
                case ReadinessTabs.Completed:
                    return completedTasks.length;
                case ReadinessTabs.Files:
                    return fileTasks.length;
                case ReadinessTabs.Todo:
                    return todoTasks.length;
                default:
                    return null;
            }
        },
        [allTasks, completedTasks, fileTasks, todoTasks]
    );

    const tabsContent = useMemo(() => {
        return Object.keys(ReadinessTabsLabelMap).map((tab) => {
            const readinessTab = tab as ReadinessTabs;
            return (
                <Button
                    height="10"
                    fontWeight="light"
                    background="white"
                    className={`mr-2 p-5 border-none mt-2 owner-button-shadow  ${
                        activeTab === readinessTab ? 'bg-newGray-darkish text-white' : 'text-newGray-darkish'
                    }`}
                    onClick={() => {
                        setActiveTab(readinessTab);
                    }}
                >
                    {ReadinessTabsLabelMap[readinessTab]} ({getTabCount(readinessTab)})
                </Button>
            );
        });
    }, [activeTab, allTasks, completedTasks, todoTasks]);

    const taskListContent = useMemo(() => {
        let tasks: any = [];

        switch (activeTab) {
            case ReadinessTabs.All:
                tasks = allTasks;
                break;
            case ReadinessTabs.Completed:
                tasks = completedTasks;
                break;
            case ReadinessTabs.Files:
                tasks = fileTasks;
                break;
            case ReadinessTabs.Todo:
                tasks = todoTasks;
                break;
            default:
                break;
        }

        return (
            <div
                className={`${
                    !isMobile &&
                    'bg-white py-10 mt-4 overflow-hidden flex justify-center items-center rounded-xl actor-list-shadow'
                } `}
            >
                {isMobile && (
                    <div className="w-full">
                        <p className="text-base font-semibold text-newGray-darkish">Readiness Tasks</p>
                        <div className={'flex flex-row flex-wrap border-b border-newGray-main pb-4 xl:mb-4 mr-2'}>
                            {tabsContent}
                        </div>
                    </div>
                )}
                <Tasks cardView={isMobile ?? false} expanded tasks={tasks} unitID={id}></Tasks>
            </div>
        );
    }, [activeTab, allTasks, completedTasks, fileTasks, todoTasks]);

    const renderReadiness = (rating: number) => {
        return isMobile ? (
            <div className="mb-2 mx-auto">
                <div className="flex flex-col md:flex-row items-center">
                    <UnitReadiness rating={rating} className="md:w-1/2 w-full mb-8 md:mb-2" />
                </div>
            </div>
        ) : (
            <UnitReadinessDesktop rating={rating} />
        );
    };

    const handleAddUser = async () => {
        await inviteUser({
            variables: {
                organisationID: detailsData?.unit.organisation._id,
                emails,
                type: UserType.Landlord
            },
            refetchQueries: [{ query: GetUserOrganisationDocument }]
        });
        setEmail('');
        setShowAddUser(false);
        refetch();
    };

    if (detailsLoading || ratingsLoading) return <Loading />;

    if (verificationLoading)
        return (
            <div className="h-full flex flex-col items-center content-center justify-center">
                <div>
                    <Loading />
                    <h2 className="text-xl text-center text-gray-800">Verifying the demise...</h2>
                </div>
            </div>
        );

    if (detailsData?.unit.__typename !== 'Unit' || ratingsData?.unit.__typename !== 'Unit') return null;

    return (
        <>
            {tenantDetailsWindow && (
                <TenantDetailsWindow
                    dealID={selectedDealId}
                    setShow={() => {
                        history.replace(`/units/${detailsData.unit._id}`);
                        setTenantDetailsWindow(false);
                    }}
                />
            )}
            {showNewDealWindow && (
                <NewDealWindow
                    setShow={setShowNewDealWindow}
                    selectedUnitID={detailsData?.unit.__typename === 'Unit' ? detailsData.unit._id : undefined}
                />
            )}
            {showEditUnitWindow && detailsData?.unit.__typename === 'Unit' && (
                <EditUnitWindow setShow={setShowEditUnitWindow} unitID={detailsData.unit._id} refetch={refetch} />
            )}
            {showTeamList && (
                <Window setShow={setShowTeamList} title="Landlord Team">
                    <div>
                        {landlordTeam?.map((member) => (
                            <div className="bg-white rounded h-20 flex items-center">
                                <div
                                    className="flex flex-row items-center border-r-2 h-12"
                                    style={{ minWidth: '150px' }}
                                >
                                    <p className="ml-3 mr-3 text-sm font-semibold text-newGray-darkish">
                                        {member.firstName && member.lastName
                                            ? member.firstName + ' ' + member.lastName
                                            : 'Invitation sent'}
                                    </p>
                                </div>
                                <div className="ml-3 mr-3 justify-between flex flex-col w-full">
                                    <div className="flex flex-row items-center mb-1" style={{ maxWidth: '100%' }}>
                                        <Envelope stroke="#a9a9a9" className="w-5 h-5 mr-2 text-sm -mt-1" />
                                        <p
                                            className="text-sm overflow-x-hidden"
                                            style={{
                                                textOverflow: 'ellipsis'
                                            }}
                                        >
                                            {member.email}
                                        </p>
                                    </div>
                                    {member.mobile && (
                                        <p className="text-sm">
                                            {' '}
                                            <Phone stroke="#a9a9a9" className="w-5 h-5 mr-2 text-sm inline -mt-1 " />
                                            {member.mobile}
                                        </p>
                                    )}
                                </div>
                            </div>
                        ))}
                    </div>
                </Window>
            )}
            {selectedTab.tab === 'Readiness' && (
                <>
                    <div className={`${!isMobile && 'w-full flex md:flex-col xl:flex-row'}`}>
                        {!isMobile && (
                            <div
                                className={
                                    'md:w-full xl:w-1/6 sticky top-0 left-10 z-30 h-full pt-4 right-0 md:bg-navy-light xl:bg-transparent'
                                }
                            >
                                <div className="w-full">
                                    <div className="flex xl:flex-col md:flex-row items-start flex-wrap pb-4 xl:mb-4 mr-2">
                                        {tabsContent}
                                    </div>
                                </div>
                            </div>
                        )}

                        <div className={`flex flex-col ${isMobile ? 'w-full' : 'md:w-full xl:w-5/6'}`}>
                            {/* Readiness */}
                            {renderReadiness(ratingsData.unit.rating)}

                            {/* Task List */}
                            {taskListContent}
                        </div>
                    </div>
                </>
            )}
            {selectedTab.tab === 'More Information' && (
                <div className={`md:w-4/5 container mx-auto flex flex-col ${isMobile && 'md:flex-row'} py-4`}>
                    {/* Details */}
                    {isMobile ? (
                        <div className="md:mr-6 flex-1 mb-4">
                            <div className="flex flex-row justify-between items-end mb-4">
                                <p className="text-base font-semibold text-newGray-darkish">Details</p>
                                {(typeData?.user.type === 'LANDLORD' || typeData?.user.type === 'ADMIN') && (
                                    <div
                                        className="flex justify-center items-center cursor-pointer quickTransitionAll"
                                        onClick={() => setShowAddUser(!showAddUser)}
                                    >
                                        {!showAddUser ? (
                                            <Plus className="w-auto mr-2 h-5" />
                                        ) : (
                                            <Close className="w-auto mr-2 h-5" />
                                        )}
                                        <span className={!showAddUser ? 'w-28' : 'w-auto'}>
                                            {!showAddUser && 'Add Colleague'}
                                        </span>
                                    </div>
                                )}
                            </div>
                            {showAddUser && (
                                <div className="mt-2">
                                    <form
                                        className="flex flex-row flex-wrap items-end w-full rounded"
                                        onSubmit={(e) => {
                                            e.preventDefault();
                                        }}
                                    >
                                        <EmailMultiInput
                                            setEmails={setEmails}
                                            defaultValue={email}
                                            name="email"
                                            placeholder={
                                                emails.length > 0 ? 'Type to add another email' : 'Enter email'
                                            }
                                            label="Email"
                                            className=" w-full mr-1"
                                            setOnFocus={setOnFocus}
                                            inputClassName={
                                                onFocus
                                                    ? 'bg-white border border-newTeal-main rounded'
                                                    : 'bg-white rounded'
                                            }
                                        />
                                        <div className="flex flex-row justify-between w-full my-3">
                                            <div />
                                            <Button
                                                type="button"
                                                onClick={() => {
                                                    handleAddUser();
                                                }}
                                                text="white"
                                                background="newTeal-main"
                                                className="px-8 font-semibold font-sm mr-1"
                                                disabled={addActorLoading}
                                            >
                                                {addActorLoading ? 'Adding...' : 'Add'}
                                            </Button>
                                        </div>
                                    </form>
                                </div>
                            )}
                            <div className="bg-white rounded h-16 flex items-center">
                                <div className="flex flex-row items-center border-r-2 h-8 w-40">
                                    <p className="ml-3 mr-3 text-sm font-semibold text-newGray-darkish">Space Owner</p>
                                </div>
                                <div className="ml-3 mr-3 justify-between flex flex-row w-full">
                                    <p className="text-base font-semibold text-black-700">
                                        {detailsData?.unit.organisation.name}
                                    </p>
                                    <div
                                        className="flex flex-row items-center cursor-pointer"
                                        onClick={() => setShowTeamList(true)}
                                    >
                                        <p>View Team</p>
                                        <Chevron className="w-8 h-5" />
                                    </div>
                                </div>
                            </div>
                            <div className="flex flex-row justify-between items-end mb-2 mt-4 mr-2">
                                <div />
                                <button
                                    onClick={() => setShowEditUnitWindow((showEditUnitWindow) => !showEditUnitWindow)}
                                    className="flex flex-row focus:outline-none"
                                >
                                    Edit
                                </button>
                            </div>
                            <UnitOverview
                                _id={detailsData.unit._id ?? ''}
                                address={detailsData.unit.address}
                                area={detailsData?.unit.area}
                                use={detailsData?.unit.use}
                                condition={detailsData?.unit.condition}
                                actors={detailsData?.unit.actors}
                                avatar={detailsData?.unit.avatar ?? ''}
                                url={detailsData?.unit.url}
                            />
                        </div>
                    ) : (
                        <div className="-mr-3 flex-1 mb-4">
                            <p className="text-xl font-semibold text-gray-800 mb-2">Details</p>
                            <UnitOverviewDesktop
                                _id={detailsData.unit._id ?? ''}
                                address={detailsData.unit.address}
                                area={detailsData?.unit.area}
                                use={detailsData?.unit.use}
                                condition={detailsData?.unit.condition}
                                actors={detailsData?.unit.actors}
                                avatar={detailsData?.unit.avatar ?? ''}
                                url={detailsData?.unit.url}
                                setShowAddUser={setShowAddUser}
                                setShowEditUnitWindow={setShowEditUnitWindow}
                                unitDetails={detailsData.unit}
                            />
                        </div>
                    )}
                    {isMobile ? (
                        <div className="flex-1">
                            {/* Negotiations */}
                            <div className="flex flex-row justify-between items-end mb-4 mr-2">
                                <p className="text-base font-semibold text-newGray-darkish">Negotiations</p>
                                <button
                                    onClick={() => setShowNewDealWindow((showNewDealWindow) => !showNewDealWindow)}
                                    className="flex flex-row focus:outline-none"
                                >
                                    <BlackPlus className="w-4 mr-1" />
                                    New Deal
                                </button>
                            </div>
                            <UnitDeals
                                data={detailsData}
                                negotiationDeals={true}
                                setShowTenantDetails={setTenantDetailsWindow}
                                setSelectedDealId={setSelectedDealId}
                            />
                        </div>
                    ) : (
                        <div>
                            <div className="flex flex-row  mr-2">
                                <p className="text-xl font-semibold text-gray-800 mb-2">Negotiations</p>
                                <button
                                    onClick={() => setShowNewDealWindow((showNewDealWindow) => !showNewDealWindow)}
                                    className="flex flex-row text-xs h-6 items-center rounded focus:outline-none bg-navy-lightest text-newGray-darkish px-2 ml-2 cursor-pointer owner-button-shadow"
                                >
                                    <BlackPlus className="w-3 mr-1" />
                                    New deal
                                </button>
                            </div>
                            <Grid>
                                {detailsData.unit.deals?.map((deal) => {
                                    if (deal.pending && typeData?.user.type !== 'AGENT') return null;

                                    return (
                                        <DealCard
                                            showSelectButton={false}
                                            deal={deal}
                                            id={deal._id}
                                            key={deal._id}
                                            userType={typeData?.user.type}
                                            name={
                                                deal.tenant?.name
                                                    ? deal.tenant?.name
                                                    : deal.unit?.organisation.name ?? ''
                                            }
                                            created={deal.created}
                                            clientName={
                                                typeData?.user.type === 'AGENT'
                                                    ? deal.currentUserParty === 'TENANT'
                                                        ? deal.tenant?.name
                                                        : deal.unit?.organisation.name
                                                    : null
                                            }
                                            unitID={detailsData.unit._id}
                                            unitName={deal.unit?.name ?? 'Untitled Demise'}
                                            unitRating={detailsData.unit?.rating ?? 0}
                                            pending={deal.pending}
                                            buildingName={deal.unit?.buildingName ?? 'Untitled Building'}
                                            state={deal.state}
                                            currentUserParty={deal.currentUserParty}
                                            round={deal.round}
                                            clauseSummary={deal.clauseSummary}
                                            hasHiddenUnit={deal.hasHiddenUnit}
                                        />
                                    );
                                })}
                            </Grid>
                        </div>
                    )}
                </div>
            )}
        </>
    );
};

export default Landlord;
