import { capitalize } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';

import { ReactComponent as Info } from '../../../../../../assets/images/info/black_new.svg';
import {
    DealDetailsFragment,
    DealUserParty,
    GetDealDetailsDocument,
    GetUserOrganisationDocument,
    useAddActorToDealMutation,
    useGetDealDetailsQuery,
    useGetUserOrganisationQuery,
    useGetUserProfileQuery,
    User,
    UserDetailsFragment,
    UserType
} from '../../../../../../generated/graphql';
import useMobileScreen from '../../../../../../shared/hooks/useMobileScreen';
import EmailMultiInput from '../../../../../../shared/Inputs/EmailMultiInput';
import Select, { Option } from '../../../../../../shared/Inputs/Select';
import Window from '../../../../../../shared/Window';

enum AdvisorType {
    OutsideAgent = 'OutsideAgent',
    TeamAgent = 'TeamAgent'
}

type AddUserType = UserType & AdvisorType;

type AddUserWindowProps = {
    dealID: string;
    deal?: DealDetailsFragment;
    email?: string | null;
    type: 'Landlord' | 'Tenant';
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
    agentArray?: any;
};

const AddUserWindow = ({ email, dealID, deal, type, agentArray, ...props }: AddUserWindowProps) => {
    const { data: userOrganisationData } = useGetUserOrganisationQuery();
    // Error message
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    const emailRef = useRef<HTMLInputElement>();
    const [userType, setUserType] = useState<AddUserType | undefined>();
    const [asMember, setAsMember] = useState<boolean>(false);
    const [emails, setEmails] = useState<string[]>([]);
    const [pickerData, setPickerData] = useState<UserDetailsFragment[] | undefined>([]);

    const { data: typeData } = useGetUserProfileQuery();

    const [addUser, { error: addUserError, loading: addUserLoading }] = useAddActorToDealMutation();

    const { refetch } = useGetDealDetailsQuery({
        variables: {
            dealID: dealID
        }
    });

    const isLandlordOrAdmin = typeData?.user.type && [UserType.Admin, UserType.Landlord].includes(typeData?.user.type);
    const isAgent = typeData?.user.type === UserType.Agent;
    const isTenant = typeData?.user.type === UserType.Tenant;

    const isMobile = useMobileScreen();

    useEffect(() => {
        setAsMember(!(userType === AdvisorType.OutsideAgent));
    }, [userType]);

    const handleAdd = async () => {
        if (!emails) return setErrorMessage('Please enter an email address');

        if (!userType) return setErrorMessage('Please select user type');

        await addUser({
            variables: {
                dealID,
                emails,
                type:
                    userType === AdvisorType.OutsideAgent || userType === AdvisorType.TeamAgent
                        ? UserType.Agent
                        : (userType as UserType),
                asMember,
                party: type === 'Landlord' ? DealUserParty.Landlord : DealUserParty.Tenant
            },
            onError: () => {
                refetch();
            },
            refetchQueries: [
                {
                    query: GetDealDetailsDocument,
                    variables: {
                        dealID
                    }
                },
                { query: GetUserOrganisationDocument }
            ]
        });
        if (addUserError) return;

        props.setShow(false);
    };
    if (addUserError && emails.length > 1) props.setShow(false);
    const [showConfirm, setShowConfirm] = useState<boolean>(false);

    const getOptions = (): Option[] => {
        if (isAgent && type === capitalize(UserType.Landlord)) {
            return [
                {
                    id: AdvisorType.OutsideAgent,
                    text: 'Advisor (outside your organisation)'
                },
                { id: AdvisorType.TeamAgent, text: 'Advisor (team member)' }
            ];
        }

        if (isLandlordOrAdmin) {
            return [
                { id: UserType.Agent, text: 'Advisor' },
                { id: UserType.Landlord, text: 'Colleague' }
            ];
        }

        if (isTenant && type === capitalize(UserType.Tenant)) {
            return [
                { id: UserType.Agent, text: 'Advisor' },
                { id: UserType.Tenant, text: 'Colleague' }
            ];
        }

        if (isAgent && type === capitalize(UserType.Tenant)) {
            return [
                { id: UserType.Tenant, text: 'Tenant' },
                { id: AdvisorType.TeamAgent, text: 'Colleague' },
                {
                    id: AdvisorType.OutsideAgent,
                    text: 'Advisor (outside your organisation)'
                }
            ];
        }

        return [];
    };

    const filterData = (users: User[]) => {
        const pickerDataFilter = users?.filter((trustedUser) => {
            const actorsEmails = agentArray?.map((actor: User) => actor.email);
            const tenantAgents = deal?.tenant?.members.map((member) => member.email);
            const tenantAndLandlordAgents = actorsEmails?.concat(tenantAgents);
            const agentsSet = new Set(tenantAndLandlordAgents);
            return !agentsSet.has(trustedUser.email);
        });

        users && setPickerData(pickerDataFilter);
    };

    useEffect(() => {
        if (userOrganisationData) {
            if (userType === AdvisorType.TeamAgent) {
                filterData(userOrganisationData.user.organisation.members as User[]);
            } else {
                filterData(userOrganisationData?.user.organisation.trustedUsers as User[]);
            }
        }
    }, [userType]);

    return (
        <Window
            title="Add User"
            width="w-1/4"
            primaryBtn
            noScroll
            onPrimaryBtnClick={() => handleAdd()}
            primaryBtnText={addUserLoading ? 'Saving...' : 'Confirm'}
            {...props}
        >
            <>
                {userType && (
                    <div className={`inline-flex items-center w-full px-4 py-3 bg-navy-lightest ${isMobile && 'mt-4'}`}>
                        {userType === UserType.Agent || userType === AdvisorType.OutsideAgent ? (
                            <p className="text-xs inline-flex items-center ">
                                <Info className="w-14 h-4" />
                                Use this account type to invite people in other companies that you work closely with,
                                such as Agents/Managing Agents and Lawyers
                            </p>
                        ) : userType === AdvisorType.TeamAgent ? (
                            <p className="text-xs inline-flex items-center">
                                <Info className="w-6 h-4" />
                                Use this account type to invite members of your company
                            </p>
                        ) : (
                            userType === UserType.Tenant && (
                                <p className="text-xs inline-flex items-center">
                                    <Info className="w-6 h-4" />
                                    Use this account type to invite members of the Tenant company.
                                </p>
                            )
                        )}
                    </div>
                )}
                {showConfirm && (
                    <Window
                        title="Confirm"
                        width="w-2/5"
                        setShow={setShowConfirm}
                        primaryBtn
                        onPrimaryBtnClick={() => handleAdd()}
                        secondaryBtn
                        onSecondaryBtnClick={() => setShowConfirm(false)}
                    >
                        <div className="text-center p-4">
                            “Are they a team member” checkbox remains unchecked. By continuing you will be adding a user
                            who will be created a separate company rather than joining yours. Do you wish to proceed?
                        </div>
                    </Window>
                )}
                <form className="pt-10 md:pt-4 p-4 w-full flex flex-col gap-2 md:mb-8">
                    <Select
                        className="z-30"
                        label="Account Type"
                        data={getOptions()}
                        onItemClick={(id) => id && setUserType(id as AddUserType)}
                    />
                    <div />
                    {(userType === UserType.Agent && isLandlordOrAdmin) || userType === AdvisorType.TeamAgent ? (
                        userOrganisationData &&
                        pickerData && (
                            <Select
                                data={Object.values(pickerData).map((trustedUser) => {
                                    return {
                                        text: trustedUser.activated
                                            ? trustedUser.firstName +
                                              ' ' +
                                              trustedUser.lastName +
                                              ' (' +
                                              trustedUser.organisation.name +
                                              ')'
                                            : trustedUser.email,
                                        id: trustedUser.email
                                    };
                                })}
                                label="Email"
                                placeholder={emails && emails.length > 0 ? 'Type to add another email' : 'Enter email'}
                                isMulti
                                blackStyle
                                createable
                                color="black"
                                chipBackground="white"
                                className="md:mr-2 bg-navy-lightest w-full mb-4"
                                onChange={(value: any) => {
                                    if (!value || value.length === 0) {
                                        setEmails([]);
                                        return;
                                    }

                                    const users = value.map((item: any) => {
                                        return item.value;
                                    });

                                    setEmails(users);
                                }}
                            />
                        )
                    ) : (
                        <EmailMultiInput
                            setEmails={setEmails}
                            ref={emailRef}
                            defaultValue={email}
                            name="email"
                            placeholder={emails.length > 0 ? 'Type to add another email' : 'Enter email'}
                            label="Email"
                            className="border-b border-t border-gray-50"
                        />
                    )}

                    {errorMessage && (
                        <div className="bg-red-200 border border-red-600 rounded py-2 px-4 my-4 mt-2">
                            <span className="text-center text-red-600">{errorMessage}</span>
                        </div>
                    )}
                </form>
            </>
        </Window>
    );
};

export default AddUserWindow;
