import React from 'react';

import Window from '../../../../../../shared/Window';
import TenantPendingMembersList, { TenantPendingMembersListProps } from '../DealActors/TenantPendingMembersList';

type TenantPendingMembersWindowProps = TenantPendingMembersListProps & {
    setShow?: React.Dispatch<React.SetStateAction<boolean>>;
    setShowingTenantPending?: React.Dispatch<React.SetStateAction<boolean>>;
};

const TenantPendingMembersWindow = ({ setShow, ...props }: TenantPendingMembersWindowProps) => {
    if (!setShow) return null;
    return (
        <Window
            title="Pending Members"
            setShow={(value) => {
                setShow(value);
                props.setShowingTenantPending && props.setShowingTenantPending(value);
            }}
            width="w-1/4"
            zIndex="40"
        >
            <div className="p-4">
                <p className="w-full text-sm mb-4 mt-8 md:mt-0">
                    These are members of your team awaiting an invite. Send them an invite now so they can get started
                    on Kato.
                </p>
                <TenantPendingMembersList inWindow {...props} />
            </div>
        </Window>
    );
};

export default TenantPendingMembersWindow;
