import React from 'react';

import ReadinessBar from '../../../../shared/ReadinessBar';

type UnitReadinessDesktopProps = {
    rating: number;
    total?: number;
    className?: string;
};

const UnitReadinessDesktop = ({ rating, total = 100, className }: UnitReadinessDesktopProps) => {
    return (
        <div className="xl:top-0 md:top-20 right-0 left-0 z-20 sticky">
            <div className="h-8 z-20 w-full -mb-4 bg-navy-light" />
            <div className={`z-30 rounded-xl w-full bg-white actor-list-shadow`}>
                <div className="py-8 px-10 flex flex-row items-center justify-between">
                    <p className="font-semibold text-xl text-gray-800">Readiness Tasks</p>
                    {/* Bar */}
                    <div className="w-52 flex flex-row items-center">
                        <ReadinessBar
                            rating={rating}
                            total={total}
                            rounded={true}
                            background={'navy-light'}
                            className="h-5"
                        />
                        <p className="text-newGray-darkish font-semibold ml-2">
                            {(((rating || 0) / (total || 100)) * 100).toFixed(0)}%
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UnitReadinessDesktop;
