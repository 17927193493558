import React from 'react';

import { AddedEventProps } from './AddedEvent';
import { ReactComponent as Edit } from '../../../../../assets/images/hash/black.svg';
import { formatClause } from '../../../../../shared/helpers';

type ChangedEventProps = AddedEventProps & {};

const ChangedEvent = ({ event }: ChangedEventProps) => {
    return (
        <div>
            <h3 className="font-semibold text-sm mb-2">
                {event.party.charAt(0) + event.party.slice(1).toLocaleLowerCase()} changed the clause to
            </h3>
            <div className="border border-navy-light rounded py-1 px-2 flex flex-row items-center justify-between gap-2">
                <Edit className="w-5 h-5 text-newTeal-main" />
                <span
                    dangerouslySetInnerHTML={{
                        __html:
                            formatClause(
                                event.change?.new.longForm ?? '',
                                event.change?.new.value,
                                event.change?.new.valueSuffix,
                                []
                            ) ?? ''
                    }}
                ></span>
            </div>
        </div>
    );
};

export default ChangedEvent;
