import React, { useEffect } from 'react';
import Iframe from 'react-iframe';

import useMobileScreen from '../../shared/hooks/useMobileScreen';
import useScreenHeight from '../../shared/hooks/useScreenHeight';
import useScreenWidth from '../../shared/hooks/useScreenWidth';

type GuidanceVideosPageProps = {
    userType?: string;
    setHeaderContent: React.Dispatch<React.SetStateAction<React.ReactChild | null>>;
};

const GuidanceVideosPage = ({ setHeaderContent, userType }: GuidanceVideosPageProps) => {
    useEffect(() => {
        setHeaderContent(<h1 className="text-xl font-bold p-2">Guidance Videos</h1>);
    }, [setHeaderContent]);
    const screenHeight = useScreenHeight();
    const screenWidth = useScreenWidth();
    const mobileScreen = useMobileScreen();

    return (
        <>
            <Iframe
                url={
                    userType === 'TENANT'
                        ? 'https://www.getleast.com/guidance-videos-tenant'
                        : userType === 'AGENT'
                        ? 'https://www.getleast.com/guidance-videos-agent'
                        : 'https://www.getleast.com/guidance-videos-landlord'
                }
                width={mobileScreen ? `${screenWidth}` : '100%'}
                height={mobileScreen ? `${screenHeight}` : '100%'}
                scrolling="yes"
                loading="lazy"
            />
        </>
    );
};

export default GuidanceVideosPage;
