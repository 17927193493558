import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import WindowFooter from './layout/WindowFooter';
import WindowHeader from './layout/WindowHeader';
import StageCompleted from './StageCompleted';
import StageContext from './StageContext';
import { GetDealTenantDetailsDocument, useTenantActionStageOneMutation } from '../../../../../generated/graphql';
import { handleFetchError } from '../../../../../shared/helpers';
import Input, { InputType } from '../../../../../shared/Inputs/index';
import Loading from '../../../../../shared/Loading';
import Window from '../../../../../shared/Window';

const Stage1Window = () => {
    const history = useHistory();

    const { dealID, details, backTo, setShowStage1Window: setShow } = React.useContext(StageContext);

    // Tenant Entity
    const [addressLine1, setAddressLine1] = useState<string>(details?.one?.entity?.address?.line1 ?? '');
    const [addressLine2, setAddressLine2] = useState<string>(details?.one?.entity?.address?.line2 ?? '');
    const [city, setCity] = useState<string>(details?.one?.entity?.address?.city ?? '');
    const [postcode, setPostcode] = useState<string>(details?.one?.entity?.address?.postCode ?? '');

    // Current Business Address
    const [businessAddressLine1, setBusinessAddressLine1] = useState<string>(
        details?.one?.building?.address?.line1 ?? ''
    );
    const [businessAddressLine2, setBusinessAddressLine2] = useState<string>(
        details?.one?.building?.address?.line2 ?? ''
    );
    const [businessCity, setBusinessCity] = useState<string>(details?.one?.building?.address?.city ?? '');
    const [businessPostcode, setBusinessPostcode] = useState<string>(details?.one?.building?.address?.postCode ?? '');

    // Same Address
    let sameRegAddress = false;
    if (
        addressLine1 &&
        addressLine2 &&
        city &&
        postcode &&
        addressLine1 === businessAddressLine1 &&
        addressLine2 === businessAddressLine2 &&
        city === businessCity &&
        postcode === businessPostcode
    ) {
        sameRegAddress = true;
    }
    const [sameAddress, setSameAddress] = useState<boolean>(sameRegAddress);

    // New Business, no address
    const [noBusinessAddress, setNoBusinessAddress] = useState<boolean>(
        details?.one?.building?.visible === undefined ? false : details?.one?.building?.visible ? false : true
    );

    // Set stage one
    const [actionStage, { error: actionStageError, loading: actionStageLoading }] = useTenantActionStageOneMutation();

    const saveToDB = async (completed?: boolean) => {
        const { data } = await actionStage({
            variables: {
                dealID: dealID!,
                completed: completed ?? false,
                entity: {
                    address: {
                        line1: addressLine1,
                        line2: addressLine2,
                        city,
                        postCode: postcode
                    }
                },
                building: {
                    visible: !noBusinessAddress,
                    address: {
                        line1: businessAddressLine1,
                        line2: businessAddressLine2,
                        city: businessCity,
                        postCode: businessPostcode
                    }
                }
            },
            refetchQueries: [
                {
                    query: GetDealTenantDetailsDocument,
                    variables: {
                        dealID: dealID!
                    }
                }
            ]
        });

        if (actionStageError) {
            return handleFetchError('Could not set your details', actionStageError, data?.dealTenantDetailsSet);
        }
    };

    const handleCompleteStage = async () => {
        // Local fields error Obj
        let fieldsErr = {
            entityName: false,
            entityCompanyNumber: false,
            addressLine1: false,
            addressLine2: false,
            city: false,
            postcode: false,
            businessAddressLine1: false,
            businessAddressLine2: false,
            businessCity: false,
            businessPostcode: false
        };

        // Check Empty Fields
        const checkEmptyField = (field: string, fieldValue: string | undefined) => {
            !fieldValue
                ? (fieldsErr = { ...fieldsErr, [field]: true })
                : (fieldsErr = { ...fieldsErr, [field]: false });
        };

        checkEmptyField('addressLine1', addressLine1);
        checkEmptyField('city', city);
        checkEmptyField('postcode', postcode);
        if (!noBusinessAddress) {
            checkEmptyField('businessAddressLine1', businessAddressLine1);
            checkEmptyField('businessCity', businessCity);
            checkEmptyField('businessPostcode', businessPostcode);
        }

        // Send to backend
        const noErrors = Object.values(fieldsErr).every((v) => v === false);
        noErrors ? saveToDB(true) : saveToDB(false);

        // Go to the next stage
        history.push('#readiness-stage2');
    };

    const handleSameAddress = () => {
        setSameAddress(!sameAddress);
        if (!sameAddress) {
            setBusinessAddressLine1(addressLine1);
            setBusinessAddressLine2(addressLine2);
            setBusinessCity(city);
            setBusinessPostcode(postcode);
        } else {
            setBusinessAddressLine1('');
            setBusinessAddressLine2('');
            setBusinessCity('');
            setBusinessPostcode('');
        }
    };

    const handleNoAddress = () => {
        setNoBusinessAddress(!noBusinessAddress);
        setSameAddress(false);
        setBusinessAddressLine1('');
        setBusinessAddressLine2('');
        setBusinessCity('');

        setBusinessPostcode('');
    };

    return (
        <Window
            width="w-4/5"
            scrollContent={true}
            className="md:max-h-11/12 h-full"
            contentBackground="navy-light"
            contentClassName="mb-52 md:mb-0"
            header={
                <WindowHeader
                    setShow={setShow!}
                    backTo={backTo!}
                    stage="1"
                    title="Provide Company Details"
                    description="Great news that you’ve found an amazing new space for your business.
                    To get things going, tell us the name of the entity that will sign the lease"
                />
            }
            footer={
                <WindowFooter
                    setShow={setShow!}
                    backTo={backTo!}
                    onNextClick={async () => {
                        await handleCompleteStage();
                    }}
                />
            }
            setShow={setShow!}
            onCloseClick={() => {
                setShow!(false);
                history.push(backTo!);
            }}
        >
            <div className="flex flex-col">
                <div className="w-full flex-1 px-2">
                    {actionStageLoading && <Loading className="mt-20" />}
                    <div
                        className={`container overflow-y-auto lg:w-4/5 md:w-5/5 mx-auto sm:pt-8 sm:pb-12 pb-64 pt-0 ${
                            actionStageLoading && 'opacity-0'
                        }`}
                    >
                        <div className="flex flex-col md:flex-row">
                            {/* Left */}
                            <div className="md:w-1/2 w-full mr-4">
                                {/* Entity address */}
                                <h3 className="font-semibold text-newGray-darkish">Tenant Entity Registered Address</h3>
                                <p className="text-gray-600 font-medium text-sm mt-2 mb-0.5">
                                    <span className="text-newTeal-main mr-1">*</span>
                                    Address Line 1
                                </p>
                                <Input
                                    type={InputType.text}
                                    value={addressLine1}
                                    onChange={(e) => {
                                        if (sameAddress) {
                                            setAddressLine1(e.target.value);
                                            setBusinessAddressLine1(e.target.value);
                                        } else {
                                            setAddressLine1(e.target.value);
                                        }
                                    }}
                                    placeholder="Enter Address Line 1"
                                    className={`w-full`}
                                    autoComplete="off"
                                />
                                <Input
                                    type={InputType.text}
                                    value={addressLine2}
                                    onChange={(e) => {
                                        if (sameAddress) {
                                            setAddressLine2(e.target.value);
                                            setBusinessAddressLine2(e.target.value);
                                        } else {
                                            setAddressLine2(e.target.value);
                                        }
                                    }}
                                    placeholder="Enter Address Line 2"
                                    label="Address Line 2"
                                    className="w-full mt-2"
                                    containerClassName="mt-2"
                                    autoComplete="off"
                                />
                                <div className="flex mt-2">
                                    <div className="w-full md:w-3/5 mr-2">
                                        <p className="text-gray-600 font-medium text-sm mb-0.5">
                                            <span className="text-newTeal-main mr-1">*</span>
                                            City
                                        </p>
                                        <Input
                                            type={InputType.text}
                                            value={city}
                                            onChange={(e) => {
                                                if (sameAddress) {
                                                    setCity(e.target.value);
                                                    setBusinessCity(e.target.value);
                                                } else {
                                                    setCity(e.target.value);
                                                }
                                            }}
                                            placeholder="Enter City"
                                            className={`w-full`}
                                            autoComplete="off"
                                        />
                                    </div>
                                    <div className="w-full md:w-2/5">
                                        <p className="text-gray-600 font-medium text-sm mb-0.5">
                                            <span className="text-newTeal-main mr-1">*</span>
                                            Postcode
                                        </p>
                                        <Input
                                            type={InputType.text}
                                            value={postcode}
                                            onChange={(e) => {
                                                if (sameAddress) {
                                                    setPostcode(e.target.value);
                                                    setBusinessPostcode(e.target.value);
                                                } else {
                                                    setPostcode(e.target.value);
                                                }
                                            }}
                                            placeholder="Enter Postcode"
                                            className={`w-full`}
                                            autoComplete="off"
                                        />
                                    </div>
                                </div>
                            </div>
                            {/* Right */}
                            <div className="md:w-1/2 w-full">
                                <h3 className="font-semibold text-newGray-darkish mb-2 mt-6 sm:mt-0">
                                    Current Business Address
                                </h3>
                                {/* Same Address */}
                                {!noBusinessAddress && (
                                    <label className="block mb-4">
                                        <input
                                            type="checkbox"
                                            id="optional"
                                            checked={sameAddress}
                                            onChange={handleSameAddress}
                                            className="leading-tight w-6 h-6 form-checkbox p-2 rounded bg-white text-newTeal-main focus:shadow-none focus:outline-none"
                                        />
                                        <span className="text-sm text-newGray-darkish ml-2">
                                            Same as Registered Address
                                        </span>
                                    </label>
                                )}
                                {/* New Business, no address */}
                                {!sameAddress && (
                                    <label className={`block mb-4 ${noBusinessAddress && 'mt-12'}`}>
                                        <input
                                            type="checkbox"
                                            id="optional"
                                            checked={noBusinessAddress}
                                            onChange={handleNoAddress}
                                            className="leading-tight w-6 h-6 form-checkbox p-2 rounded bg-white text-newTeal-main focus:shadow-none focus:outline-none"
                                        />
                                        <span className="text-sm text-newGray-darkish ml-2">
                                            New Business, No Current Address
                                        </span>
                                    </label>
                                )}
                                {/* New Business Address */}
                                <div className={`${sameAddress && 'mt-14'}`}>
                                    <p className="text-gray-600 font-medium text-sm mt-2 mb-0.5">
                                        <span className="text-newTeal-main mr-1">*</span>
                                        Address Line 1
                                    </p>
                                    <Input
                                        type={InputType.text}
                                        value={businessAddressLine1}
                                        onChange={(e) => {
                                            setBusinessAddressLine1(e.target.value);
                                        }}
                                        disabled={sameAddress || noBusinessAddress ? true : false}
                                        placeholder="Enter Address Line 1"
                                        className={`w-full`}
                                        autoComplete="off"
                                    />
                                    <Input
                                        type={InputType.text}
                                        value={businessAddressLine2}
                                        onChange={(e) => {
                                            setBusinessAddressLine2(e.target.value);
                                        }}
                                        disabled={sameAddress || noBusinessAddress ? true : false}
                                        placeholder="Enter Address Line 2"
                                        label="Address Line 2"
                                        className="w-full mt-2"
                                        containerClassName="mt-2"
                                        autoComplete="off"
                                    />
                                    <div className="flex mt-2">
                                        <div className="w-full md:w-3/5 mr-2">
                                            <p className="text-gray-600 font-medium text-sm mb-0.5">
                                                <span className="text-newTeal-main mr-1">*</span>
                                                City
                                            </p>
                                            <Input
                                                type={InputType.text}
                                                value={businessCity}
                                                onChange={(e) => {
                                                    setBusinessCity(e.target.value);
                                                }}
                                                disabled={sameAddress || noBusinessAddress ? true : false}
                                                placeholder="Enter City"
                                                className={`w-full`}
                                                autoComplete="off"
                                            />
                                        </div>
                                        <div className="w-full md:w-2/5">
                                            <p className="text-gray-600 font-medium text-sm mb-0.5">
                                                <span className="text-newTeal-main mr-1">*</span>
                                                Postcode
                                            </p>
                                            <Input
                                                type={InputType.text}
                                                value={businessPostcode}
                                                onChange={(e) => {
                                                    setBusinessPostcode(e.target.value);
                                                }}
                                                disabled={sameAddress || noBusinessAddress ? true : false}
                                                placeholder="Enter Postcode"
                                                className={`w-full`}
                                                autoComplete="off"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Bottom text */}
                        <p className="text-gray-600 font-medium text-sm mb-6 mt-6">
                            <span className="text-newTeal-main mr-1">*</span>
                            All the fields are required to complete the stage
                        </p>
                        {details?.one?.completed && <StageCompleted />}
                    </div>
                </div>
            </div>
        </Window>
    );
};

export default Stage1Window;
