import React, { useState } from 'react';

type ErrorPopupProps = {
    title: string;
    details: string[];
};

const ErrorPopup = ({ title, details }: ErrorPopupProps) => {
    const [detail, showDetail] = useState<boolean>();

    return (
        <div
            className={`flex flex-col content-center items-start py-2`}
            onMouseEnter={() => showDetail(true)}
            onMouseLeave={() => showDetail(false)}
        >
            <h1 className="text-black font-semibold">{title}</h1>
            {detail && details.length > 0 && (
                <div className="">
                    <h2 className="text-black text-xs font-semibold">MORE DETAILS</h2>
                    {details.map((message, i) => (
                        <p className="text-black text-sm" key={i}>
                            {message}
                        </p>
                    ))}
                </div>
            )}
        </div>
    );
};

export default ErrorPopup;
