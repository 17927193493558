import React, { useContext } from 'react';

import Window from '../../../../../shared/Window';
import { ReadinessContext, STAGE } from '../context';
import AMLCheck from '../Stages/AML';
import Covenant from '../Stages/Covenant';
import Final from '../Stages/Final';
import Lawyer from '../Stages/Lawyer';
import Team from '../Stages/Team';
import TenantDetails from '../Stages/TenantDetails';

type LandlordsFileModalProps = {
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
};

const StageWindow = ({ setShow }: LandlordsFileModalProps) => {
    const { stage } = useContext(ReadinessContext);

    const renderStage = () => {
        switch (stage) {
            case STAGE.TENANT_INFORMATION:
                return <TenantDetails />;
            case STAGE.LAWYER:
                return <Lawyer />;
            case STAGE.TEAM:
                return <Team />;
            case STAGE.COVENANT:
                return <Covenant />;
            case STAGE.FINAL_INFO:
                return <Final onSuccess={() => setShow(false)} />;
            case STAGE.AML_CHECK:
                return <AMLCheck />;
            default:
                return null;
        }
    };

    return (
        <Window className="max-h-screen px-20" width="w-3/5" scrollContent={true} setShow={setShow}>
            <>{renderStage()}</>
        </Window>
    );
};

export default StageWindow;
