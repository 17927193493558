import { Reference, StoreObject } from '@apollo/client';
import React, { useState } from 'react';

import AdminPasswordWindow from './AdminPasswordWindow';
import { useAdminDeleteUserMutation, UserFilterCountsDocument } from '../../../generated/graphql';
import { handleFetchError } from '../../../shared/helpers';
import Loading from '../../../shared/Loading';
import Window from '../../../shared/Window';

type AdminDeleteUserWindowProps = {
    id: string;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
};

const AdminDeleteUserWindow = ({ id, setShow }: AdminDeleteUserWindowProps) => {
    const [showAdminPasswordWindow, setShowAdminPasswordWindow] = useState<boolean>(false);

    const [deleteUser, { error: deleteUserError, loading: deleteUserLoading }] = useAdminDeleteUserMutation();

    const handleSubmit = async (password: string) => {
        const { data: deleteUserData } = await deleteUser({
            variables: {
                id,
                input: {
                    password
                }
            },
            refetchQueries: () => [UserFilterCountsDocument],
            update(store, { data }) {
                if (!data) return;

                const deletedUserID = data?.userDelete ?? '';

                store.modify({
                    fields: {
                        users({ results = [] }, { readField }) {
                            return {
                                __typename: 'Users',
                                results: results.filter(
                                    (userRef: Reference | StoreObject) => deletedUserID !== readField('_id', userRef)
                                )
                            };
                        }
                    }
                });
            }
        });

        if (deleteUserError)
            return handleFetchError('Failed to delete the user', deleteUserError, deleteUserData?.userDelete);

        setShowAdminPasswordWindow(false);
        setShow(false);
    };

    if (deleteUserLoading)
        return (
            <Window title="Delete User" titleCenter width="w-1/4" setShow={setShow}>
                <Loading />
            </Window>
        );

    return (
        <>
            {showAdminPasswordWindow && (
                <AdminPasswordWindow
                    text="Please enter your admin password in order to delete this user."
                    onClick={handleSubmit}
                    setShow={setShowAdminPasswordWindow}
                />
            )}
            <Window
                title="Delete User"
                titleCenter
                width="w-1/4"
                primaryBtn
                onPrimaryBtnClick={() => setShowAdminPasswordWindow(true)}
                secondaryBtn
                onSecondaryBtnClick={() => setShow(false)}
                setShow={setShow}
            >
                <div className="flex flex-col items-center">
                    <h2 className="text-center text-lg my-8 w-4/5">
                        Are you sure you want to <span className="font-semibold text-uppercase">delete</span> this user?
                        They will lose access to all Kato systems.
                    </h2>
                </div>
            </Window>
        </>
    );
};

export default AdminDeleteUserWindow;
