import { ApolloCache } from '@apollo/client';

import {
    DeleteGroupMutation,
    GetDealDetailsDocument,
    GetDealDetailsQuery,
    GetTemplateDetailsDocument,
    GetTemplateDetailsQuery
} from '../../../generated/graphql';
import { DealType } from '../ConfirmClauseDeleteWindow.tsx';

const useDeleteGroup = (type: DealType) => {
    if (type === DealType.TEMPLATE) {
        return async (store: ApolloCache<DeleteGroupMutation>, objectID: string, templateID?: string) => {
            let templateData = store.readQuery<GetTemplateDetailsQuery>({
                query: GetTemplateDetailsDocument,
                variables: {
                    templateID
                }
            });

            if (!templateData || templateData.template.__typename !== 'Template') return;

            const filteredSections = templateData.template.deal.sections?.filter((section) => section._id !== objectID);

            const sections = templateData.template.deal.sections?.map((section) => {
                // Filter if deletedObjectID is a group
                const filteredGroups = section.groups?.filter((group) => group._id !== objectID);

                // Check if the group was found at this level
                if ((filteredGroups?.length ?? 0) < (section.groups?.length ?? 1)) {
                    return {
                        ...section,
                        groups: filteredGroups
                    };
                }

                return {
                    ...section,
                    groups: section.groups?.map((group) => {
                        // Filter if deletedObjectID is a clause
                        const filteredClauses = group.clauses?.filter((clause) => clause._id !== objectID);

                        // Check if the clause was found at this level
                        if ((filteredClauses?.length ?? 0) < (group.clauses?.length ?? 1)) {
                            return {
                                ...group,
                                clauses: filteredClauses
                            };
                        }

                        return {
                            ...group,
                            clauses: group.clauses?.map((clause) => {
                                // Filter if deletedObjectID is a group
                                const filteredGroups = clause.groups?.filter((group) => group._id !== objectID);

                                return {
                                    ...clause,
                                    groups: filteredGroups
                                };
                            })
                        };
                    })
                };
            });

            templateData &&
                store.writeQuery<GetTemplateDetailsQuery>({
                    query: GetTemplateDetailsDocument,
                    data: {
                        ...templateData,
                        template: {
                            ...templateData.template,
                            deal: {
                                ...templateData.template.deal,
                                sections:
                                    (filteredSections?.length ?? 0) < (templateData.template.deal.sections?.length ?? 1)
                                        ? filteredSections
                                        : sections
                            }
                        }
                    }
                });
        };
    }

    return async (store: ApolloCache<DeleteGroupMutation>, objectID: string, dealID?: string) => {
        let dealData = store.readQuery<GetDealDetailsQuery>({
            query: GetDealDetailsDocument,
            variables: {
                dealID
            }
        });

        if (!dealData || dealData.deal.__typename !== 'Deal') return;

        const filteredSections = dealData.deal.sections?.filter((section) => section._id !== objectID);

        const sections = dealData.deal.sections?.map((section) => {
            // Filter if deletedObjectID is a group
            const filteredGroups = section.groups?.filter((group) => group._id !== objectID);

            // Check if the group was found at this level
            if ((filteredGroups?.length ?? 0) < (section.groups?.length ?? 1)) {
                return {
                    ...section,
                    groups: filteredGroups
                };
            }

            return {
                ...section,
                groups: section.groups?.map((group) => {
                    const filteredClauses = group.clauses?.filter((clause) => clause._id !== objectID);

                    // Check if the clause was found at this level
                    if ((filteredClauses?.length ?? 0) < (group.clauses?.length ?? 1)) {
                        return {
                            ...group,
                            clauses: filteredClauses
                        };
                    }

                    return {
                        ...group,
                        clauses: group.clauses?.map((clause) => {
                            // Filter if deletedObjectID is a group
                            const filteredGroups = clause.groups?.filter((group) => group._id !== objectID);

                            return {
                                ...clause,
                                groups: filteredGroups
                            };
                        })
                    };
                })
            };
        });

        dealData &&
            store.writeQuery<GetDealDetailsQuery>({
                query: GetDealDetailsDocument,
                data: {
                    ...dealData,
                    deal: {
                        ...dealData.deal,
                        sections:
                            (filteredSections?.length ?? 0) < (dealData.deal.sections?.length ?? 1)
                                ? filteredSections
                                : sections
                    }
                }
            });
    };
};

export default useDeleteGroup;
