import React from 'react';
import { useHistory } from 'react-router-dom';

import ActionCard, { ActionCardType } from './ActionCard';
import TenantActionCard from './TenantActionCard';
import { DealState, DealUserParty } from '../../../generated/graphql';
import { DealCardImportant, dealMostImportant } from '../../../shared/helpers';

type AllActionsCardsProps = {
    deals: any;
    actionsNumber: number;
    noDealUnits: any;
    unitShowNumber: number;
    viewAll: () => void;
    showAllActions: boolean;
    shownIds: (string | undefined)[];
    units: (string | undefined)[];
};

const AllActionCards = ({
    deals,
    actionsNumber,
    noDealUnits,
    unitShowNumber,
    viewAll,
    showAllActions,
    shownIds,
    units
}: AllActionsCardsProps) => {
    /**
     * Render actionCard card based on action
     */
    const history = useHistory();
    const renderActionCard = (action: any) => {
        if (action.state === DealState.Complete) return null;

        const days = Math.ceil((new Date().getTime() - new Date(action.round?.date).getTime()) / (1000 * 3600 * 24));

        const type =
            action.state &&
            action.currentUserParty &&
            dealMostImportant(action.state, action.currentUserParty, days, action.unit?.rating ?? 0);

        const getPerson = () => {
            return (action.state === DealState.Landlord && action.currentUserParty === DealUserParty.Landlord) ||
                (action.state === DealState.Tenant && action.currentUserParty === DealUserParty.Tenant)
                ? 'You'
                : 'Them';
        };

        if (action.currentUserParty === 'TENANT')
            return (
                <div onClick={() => history.push(`/deals/${action._id}/negotiate`)}>
                    <TenantActionCard
                        id={action._id}
                        key={action._id}
                        state={action.state}
                        name={action.tenant?.name ?? action.unit?.organisation.name ?? ''}
                        dealId={action.dealID}
                        buildingName={action.unit?.name ?? 'Untitled Demise'}
                        address={action.unit?.buildingName ?? 'Untitled Building'}
                    />
                </div>
            );

        if (
            type === DealCardImportant.NEGOTIATIONS &&
            action.state !== DealState.Edit &&
            action.currentUserParty !== 'TENANT'
        ) {
            shownIds.push(action._id);
            return (
                <ActionCard
                    id={action._id}
                    key={action._id}
                    round={action.round}
                    state={action.state}
                    currentUserParty={action.currentUserParty}
                    type={ActionCardType.DEAL}
                    name={action.tenant?.name ?? action.unit?.organisation.name ?? ''}
                    buildingName={action.unit?.name ?? 'Untitled Demise'}
                    address={action.unit?.buildingName ?? 'Untitled Building'}
                    dealActionDetails={`${days} Day${days !== 1 ? 's' : ''} with ${getPerson()}`}
                />
            );
        }

        if (units.includes(action.unit?._id ?? '')) return null;

        shownIds.push(action._id);

        units.push(action.unit?._id);
        if (action.currentUserParty !== 'TENANT')
            return (
                <ActionCard
                    id={action.unit?._id ?? ''}
                    dealId={action._id}
                    round={action.round}
                    state={action.state}
                    currentUserParty={action.currentUserParty}
                    key={action._id}
                    type={ActionCardType.UNIT}
                    name={action.unit?.name ?? 'Untitled Demise'}
                    buildingName={action.unit?.buildingName ?? 'Untitled Building'}
                    address={action.unit?.address.line1 ?? ''}
                    rating={action.unit?.rating}
                    total={100}
                />
            );
    };

    return (
        <>
            {deals && deals.slice(0, actionsNumber).map((action: any) => renderActionCard(action))}
            {noDealUnits &&
                noDealUnits.length > 0 &&
                noDealUnits.slice(0, unitShowNumber).map((unit: any) => {
                    return (
                        deals[0]?.currentUserParty !== 'TENANT' && (
                            <ActionCard
                                id={unit?._id ?? ''}
                                key={unit._id}
                                type={ActionCardType.UNIT}
                                name={unit?.name ?? 'Untitled Demise'}
                                buildingName={unit?.buildingName ?? 'Untitled Building'}
                                address={unit?.address.line1 ?? ''}
                                rating={unit?.rating}
                                total={100}
                            />
                        )
                    );
                })}
            {[...(deals || []), ...(noDealUnits || [])].length > 3 && (
                <div className="w-full text-center py-2 mt-2">
                    <div className="font-bold text-gray-600 cursor-pointer" onClick={() => viewAll()}>
                        <p>{!showAllActions ? 'View All' : 'Hide'}</p>
                    </div>
                </div>
            )}
        </>
    );
};

export default AllActionCards;
