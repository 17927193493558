import axios from 'axios';
import { format } from 'date-fns';
import { saveAs } from 'file-saver';
import fileDownload from 'js-file-download';
import { useState } from 'react';

import { AdminTaskType, Deal, Task, useDownloadFilesMutation } from '../../../../generated/graphql';

const useDownloadFiles = (): [(ids: string[], deal: Deal) => Promise<void>, boolean] => {
    const [loading, setLoading] = useState<boolean>(false);
    const [downloadFiles] = useDownloadFilesMutation();

    return [
        async (ids: string[], deal: Deal) => {
            setLoading(true);
            const { data } = await downloadFiles({
                variables: {
                    ids
                },
                updateQueries: {
                    getDealUnitDetails: ({ deal }, { mutationResult }) => {
                        const { unit } = deal;
                        let { tasks, files } = unit;

                        mutationResult.data?.downloadDoc.forEach((doc) => {
                            let index = tasks.findIndex(
                                (task: Task) =>
                                    task._id === doc._id &&
                                    task.task.type === AdminTaskType.File &&
                                    task.value &&
                                    task.value?.__typename === 'File'
                            );

                            if (index !== -1) {
                                const updated = { ...tasks[index], ...doc };
                                tasks = [...tasks.slice(0, index), updated, ...tasks.slice(index + 1, tasks.length)];
                            }

                            index = files.findIndex((task: Task) => task._id === doc._id);

                            if (index !== -1) {
                                const updated = { ...files[index], ...doc };
                                files = [...files.slice(0, index), updated, ...files.slice(index + 1, files.length)];
                            }
                        });

                        return {
                            deal: {
                                ...deal,
                                unit: {
                                    ...unit,
                                    tasks,
                                    files
                                }
                            }
                        };
                    }
                }
            });

            if (data && data.downloadDoc.length > 1) {
                const JSZip = require('jszip');
                let zip = new JSZip();

                await Promise.all(
                    data.downloadDoc.map(async (doc) => {
                        const response = await axios.get(doc?.url!, {
                            responseType: 'blob'
                        });
                        zip.file(doc.name!, response.data);
                    })
                );

                zip.generateAsync({ type: 'blob' }).then((content: any) => {
                    const timestamp = format(new Date(), 'dd MMM yyyy');
                    saveAs(content, `${deal.unit?.organisation.name} Readiness Files - ${timestamp}.zip`);
                });
            } else {
                const response = await axios.get(data?.downloadDoc[0]?.url!, {
                    responseType: 'blob'
                });
                fileDownload(response.data, data?.downloadDoc[0].name!);
            }
            setLoading(false);
        },
        loading
    ];
};

export default useDownloadFiles;
