import { Field, Form, Formik, useFormikContext } from 'formik';
import React, { useContext, useEffect, useState } from 'react';
import * as Yup from 'yup';

import countries from '../../../../../assets/countries.json';
import { GetDealTenantDetailsDocument, useTenantActionStageThreeMutation } from '../../../../../generated/graphql';
import { handleFetchError } from '../../../../../shared/helpers';
import Loading from '../../../../../shared/Loading';
import { hasErrors } from '../../../../common/helpers';
import Checkbox from '../Checkbox';
import { ReadinessContext, STAGE } from '../context';
import Dropzone from '../Dropzone';
import TextField from '../InputField';
import SelectField from '../SelectField';
import Stage from '../Stage';

type CovenantValues = {
    guarantorName?: string | null;
    guarantorCompanyName?: string | null;
    guarantorJurisdistion?: string | null;
    currentAddressLine1?: string | null;
    currentAddressLine2?: string | null;
    currentCity?: string | null;
    currentPostcode?: string | null;
    noAccShare?: boolean | null;
    nominate?: boolean | null;
};

type CovenantFormProps = {
    filesLength: number | undefined;
    edited: boolean;
};

const Covenant = () => {
    const { details, loading } = useContext(ReadinessContext);

    const three = details?.three;
    const guarantorData = three?.guarantor;
    const initialValues = {
        guarantorName: guarantorData?.name,
        guarantorCompanyName: guarantorData?.company,
        guarantorJurisdistion: guarantorData?.jurisdiction,
        currentAddressLine1: guarantorData?.address?.line1,
        currentAddressLine2: guarantorData?.address?.line2,
        currentCity: guarantorData?.address?.city,
        currentPostcode: guarantorData?.address?.postCode,
        noAccShare: three?.noAccountsToShare ?? false,
        nominate: three?.nominateGuarantor ?? false
    };

    const validationSchema = Yup.object().shape({
        guarantorName: Yup.string().when('nominate', {
            is: (showForm: boolean) => showForm,
            then: Yup.string().required(' ')
        }),
        guarantorCompanyName: Yup.string().when('nominate', {
            is: (showForm: boolean) => showForm,
            then: Yup.string().required(' ')
        }),
        guarantorJurisdistion: Yup.string().when('nominate', {
            is: (showForm: boolean) => showForm,
            then: Yup.string().required(' ')
        }),
        currentAddressLine1: Yup.string().when('nominate', {
            is: (showForm: boolean) => showForm,
            then: Yup.string().required(' ')
        }),
        currentCity: Yup.string().when('nominate', {
            is: (showForm: boolean) => showForm,
            then: Yup.string().required(' ')
        }),
        currentPostcode: Yup.string().when('nominate', {
            is: (showForm: boolean) => showForm,
            then: Yup.string().required(' ')
        })
    });

    const filesLength = three?.files && three?.files.length;

    if (loading) return <Loading />;

    return (
        <Formik validationSchema={validationSchema} initialValues={initialValues} onSubmit={() => {}}>
            <CovenantForm filesLength={filesLength} edited={!!three?.edited} />
        </Formik>
    );
};

const CovenantForm = ({ edited, filesLength }: CovenantFormProps) => {
    const { values, validateForm, dirty, setFieldValue } = useFormikContext<CovenantValues>();

    const { isChanging, setStage, changeStage, dealID, unitID } = useContext(ReadinessContext);
    const [didUpload, setDidUpload] = useState<boolean>(false);

    const [actionStage, { error: actionStageError }] = useTenantActionStageThreeMutation();
    const saveValues = async () => {
        if (!dirty && edited && !didUpload) {
            changeStage();
            return;
        }

        const isValid = !hasErrors<CovenantValues>(await validateForm(values));

        const { data } = await actionStage({
            variables: {
                dealID: dealID!,
                noAccountsToShare: values.noAccShare,
                nominateGuarantor: values.nominate,
                audited: !values.noAccShare,
                completed: values.noAccShare
                    ? !values.nominate || (values.nominate && isValid)
                    : (!!filesLength && !values.nominate) || (!!filesLength && values.nominate && isValid),
                guarantor: {
                    name: values.guarantorName,
                    company: values.guarantorCompanyName,
                    jurisdiction: values.guarantorJurisdistion,
                    visible: values.nominate,
                    address: {
                        line1: values.currentAddressLine1,
                        line2: values.currentAddressLine2,
                        city: values.currentCity,
                        postCode: values.currentPostcode
                    }
                }
            },
            onCompleted: changeStage,
            refetchQueries: [
                {
                    query: GetDealTenantDetailsDocument,
                    variables: {
                        dealID: dealID!
                    }
                }
            ]
        });
        if (actionStageError) {
            return handleFetchError('Could not set your details', actionStageError, data?.dealTenantDetailsSet);
        }
    };

    useEffect(() => {
        if (isChanging) saveValues();
    }, [isChanging]);

    return (
        <Form className="bg-white pt-14 pb-8  h-full w-full mx-auto flex flex-col items-start">
            <Stage
                stageNumber={4}
                title="Covenant"
                nextStage="Stage 5 - AML Checks"
                onNextClick={() => {
                    setStage(STAGE.AML_CHECK);
                }}
            >
                <p className={`mt-2  mb-6`} style={{ fontSize: '0.7rem' }}>
                    Please provide as many years' (ideally 3 years) audited/ management accounts as possible for the
                    entity signing the lease.
                </p>
                {!values.noAccShare && unitID && (
                    <Dropzone dealID={dealID!} unitID={unitID} setDidUpload={setDidUpload} />
                )}
                {!filesLength && (
                    <Checkbox
                        name="noAccShare"
                        label="We do not have any accounts to share."
                        checked={!!values.noAccShare}
                    />
                )}
                <Checkbox
                    name="nominate"
                    label="We wish to nominate a Guarantor for our Lease."
                    checked={!!values.nominate}
                />
                {values.nominate && (
                    <>
                        <TextField name={`guarantorName`} label="Guarantor Name" required />
                        <TextField name={`guarantorCompanyName`} label="Guarantor Company Name" required />
                        <label className=" font-medium text-xs ">
                            <span className="text-newTeal-main text-md">*</span>
                            Guarantor Jurisdistion
                        </label>
                        <Field
                            placeholder={values.guarantorJurisdistion ?? 'Select Country'}
                            component={SelectField}
                            options={countries}
                            className={'text-xs'}
                            onChange={(e: any) => setFieldValue('guarantorJurisdistion', e.label)}
                        />
                        <TextField name={`currentAddressLine1`} label="Address Line 1" required />
                        <TextField name={`currentAddressLine2`} label="Address Line 2" />
                        <div className="flex flex-row w-72 gap-8">
                            <div className="flex flex-col w-1/2">
                                <TextField name={`currentCity`} label="City" width="w-full" required />
                            </div>
                            <div className="flex flex-col w-1/2">
                                <TextField name={`currentPostcode`} label="Postcode" width="w-full" required />
                            </div>
                        </div>
                    </>
                )}
            </Stage>
        </Form>
    );
};

export default Covenant;
