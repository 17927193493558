import { format } from 'date-fns';
import jsonata from 'jsonata';
import { cloneDeep } from 'lodash';
import React, { useState } from 'react';

import FilterChip from './FilterChip';
import { CheckboxListOption, FilterConfig, FilterType, Range, RangeDate } from './FilterWindow';
import { emptyFilterValues, initializeFilterValues, initializeSort, isRangeEmpty } from './helpers';
import { useDisclosure, Window } from './hooks/useDisclosure';
import RadioListFilterChip from './RadioListChip';
import RangeFilterChip from './RangeFilterChip';
import { OrderBy, UserType } from '../../generated/graphql';

export type Filter = {
    id: string;
    value: CheckboxListOption[] | Range;
    type: FilterType;
};

type ValuesFromQuery = {
    loading: boolean;
    data: any;
};

export type FilterContextType = {
    values: Record<string, any>;
    saveValues: (values: FilterValues) => void;
    pendingValues: Record<string, any>;
    savePendingValues: (values: FilterValues) => void;
    clear: () => void;
    isWindowOpen: boolean;
    window: Window | undefined;
    openWindow: (window?: Window) => void;
    close: () => void;
    sort: Sort | undefined;
    saveSort: (sort: Sort | undefined) => void;
    options: Record<string, ValuesFromQuery>;
    filters: FilterConfig;
    removeListValue: (filter: string, filterLabel: string) => void;
    removeRadioListValue: (filter: string) => void;
    removeRangeValue: (filter: string) => void;
    getLabelForValue: (filter: string, label: string) => string;
    chips: () => (JSX.Element | JSX.Element[] | null)[];
};

export const FilterContext = React.createContext<FilterContextType>({
    values: {},
    saveValues: () => {},
    pendingValues: {},
    savePendingValues: () => {},
    clear: () => {},
    isWindowOpen: false,
    window: undefined,
    openWindow: () => {},
    close: () => {},
    sort: undefined,
    saveSort: () => {},
    options: {},
    filters: [],
    removeListValue: () => {},
    removeRadioListValue: () => {},
    removeRangeValue: () => {},
    getLabelForValue: () => '',
    chips: () => []
});

type FilterProviderProps = {
    page: string;
    filters: FilterConfig;
    saveValuesOverride?: (values: FilterValues, userType?: UserType) => FilterValues;
    userType?: UserType;
    children: React.ReactNode;
};

export type Sort = {
    field: string;
    order: OrderBy;
};

export type FilterValues = Record<string, string[] | Range | undefined>;

const PageFilter = (page: string) => `${page}Filter`;
const PageSort = (page: string) => `${page}Sort`;

export const setSessionFilterValues = (values: FilterValues, page: string) =>
    sessionStorage.setItem(PageFilter(page), JSON.stringify(values));
export const getSessionFilterValues = (page: string) => sessionStorage.getItem(PageFilter(page));
export const removeSessionFilterValues = (page: string) => sessionStorage.removeItem(PageFilter(page));

export const setSessionSort = (sort: Sort, page: string) =>
    sessionStorage.setItem(PageSort(page), JSON.stringify(sort));
export const getSessionSort = (page: string) => sessionStorage.getItem(PageSort(page));
export const removeSessionSort = (page: string) => sessionStorage.removeItem(PageSort(page));

export const FilterProvider = ({ page, filters, saveValuesOverride, userType, children }: FilterProviderProps) => {
    const initialValues = initializeFilterValues(filters, page);
    const [values, setValues] = useState<FilterValues>(initialValues);

    const [pendingValues, setPendingValues] = useState<FilterValues>(cloneDeep(initialValues));

    const { isWindowOpen, window, openWindow, close } = useDisclosure();

    const [sort, setSort] = useState<Sort | undefined>(initializeSort(page));

    const clear = () => {
        removeSessionFilterValues(page);
        removeSessionSort(page);
        setValues(emptyFilterValues(filters));
    };

    let options: Record<string, ValuesFromQuery> = {};
    filters
        .filter((filter) => filter.type === FilterType.AsyncList)
        .forEach((filter) => {
            const { data, loading } = filter.query!({
                fetchPolicy: 'cache-and-network'
            });
            options[filter.id] = { data, loading };
        });

    const chips = () => {
        return Object.entries(values).map(([key, value]) => {
            const filter = filters.find((f) => f.id === key);

            if (!filter) return null;

            switch (filter?.type) {
                case FilterType.AsyncList:
                case FilterType.List:
                    return (value as string[]).map((v) => {
                        const label = getLabelForValue(key, v);
                        return <FilterChip value={v} label={label} filterId={key} filterLabel={filter.label} />;
                    });
                case FilterType.Range:
                    if (isRangeEmpty(value as Range)) return null;

                    return (
                        <RangeFilterChip
                            from={(value as Range).from ? (value as Range).from : undefined}
                            to={(value as Range).to ? (value as Range).to : undefined}
                            filter={filter}
                        />
                    );
                case FilterType.RangeDate:
                    if (isRangeEmpty(value as Range)) return null;
                    const dateValue = value as RangeDate;

                    return (
                        <RangeFilterChip
                            from={
                                (value as RangeDate).from
                                    ? dateValue.from && format(new Date(dateValue.from).getTime(), 'dd MMM yyyy')
                                    : undefined
                            }
                            to={
                                (value as RangeDate).to
                                    ? dateValue.to && format(new Date(dateValue.to).getTime(), 'dd MMM yyyy')
                                    : undefined
                            }
                            filter={filter}
                        />
                    );

                case FilterType.RadioList:
                    if (value === undefined) return null;

                    return <RadioListFilterChip value={value} filter={filter} />;
            }
        });
    };

    const removeRangeValue = (filter: string) => {
        const updatedValues = {
            ...values,
            [filter]: { from: undefined, to: undefined }
        };

        saveValues(updatedValues);
    };

    const removeListValue = (filter: string, value: string) => {
        const updatedValues = {
            ...values,
            [filter]: (values[filter] as string[]).filter((v) => v !== value)
        };

        saveValues(updatedValues);
    };

    const removeRadioListValue = (filter: string) => {
        const updatedValues = {
            ...values,
            [filter]: undefined
        };

        saveValues(updatedValues);
    };

    const getLabelForValue = (fieldId: string, value: string) => {
        const field = filters.find((f) => f.id === fieldId);

        if (!field) return '';

        if (field.type === FilterType.List) {
            return (field.options as CheckboxListOption[])?.find((o) => o.value === value)?.label || '';
        } else if (field.type === FilterType.AsyncList) {
            if (options[fieldId].loading) return '';

            const transformer = jsonata(field.path!);
            const data: CheckboxListOption[] = transformer.evaluate(options[fieldId].data);
            return data.find((o) => o.value === value)?.label || '';
        }

        return '';
    };

    const saveValues = (values: FilterValues) => {
        let cloned = cloneDeep(saveValuesOverride ? saveValuesOverride(values, userType) : values);

        setSessionFilterValues(cloned, page);

        setPendingValues(cloneDeep(cloned));
        setValues(cloned);
    };

    const saveSort = (sort: Sort | undefined) => {
        if (sort) setSessionSort(sort, page);
        else removeSessionSort(page);

        setSort(sort);
    };

    return (
        <FilterContext.Provider
            value={{
                values,
                saveValues,
                pendingValues,
                savePendingValues: setPendingValues,
                clear,
                isWindowOpen,
                window,
                openWindow,
                close,
                sort,
                saveSort,
                options,
                filters,
                removeListValue,
                removeRadioListValue,
                removeRangeValue,
                getLabelForValue,
                chips
            }}
        >
            {children}
        </FilterContext.Provider>
    );
};
