import React from 'react';
import { NavLink } from 'react-router-dom';

import { ReactComponent as Bell } from '../../../assets/images/bell/white.svg';
import { ReactComponent as Briefcase } from '../../../assets/images/briefcase/white.svg';
import { ReactComponent as Company } from '../../../assets/images/company/white.svg';
import { ReactComponent as Server } from '../../../assets/images/server/white.svg';
import { ReactComponent as User } from '../../../assets/images/user/white.svg';
import { ReactComponent as UserChecked } from '../../../assets/images/userCheck/white.svg';
import { ReactComponent as Users } from '../../../assets/images/users/white.svg';
import { useGetLayoutUserInfoQuery } from '../../../generated/graphql';
import { urlToQsInfo } from '../../../shared/helpers';
import useMobileScreen from '../../../shared/hooks/useMobileScreen';

const Settings = () => {
    const { data } = useGetLayoutUserInfoQuery({
        fetchPolicy: 'cache-only'
    });

    const smallScreenStyle = 'md:justify-center md:flex lg-xl:justify-start';

    const isMobile = useMobileScreen();

    return (
        <div
            data-testid="layout--sidebar"
            className={`md:pb-14 pt-7 bg-navy-main overflow-y-auto overflow-x-hidden sidebar-height sticky top-10`}
        >
            <span className={`text-white text-xs mb-2 lg-xl:ml-6 ${smallScreenStyle}`}>Account</span>
            <NavLink
                to={urlToQsInfo('/settings/profile', data?.user.type, data?.user.organisation.name)}
                className={`p-2 flex ${smallScreenStyle} items-center py-4 px-6`}
                activeClassName="px-6 bg-newGray-dark"
            >
                <User className="w-5 h-5 mr-2" />
                <span className="text-white md:hidden xl:flex text-sm">Account</span>
            </NavLink>
            <NavLink
                to={urlToQsInfo('/settings/notifications', data?.user.type, data?.user.organisation.name)}
                className={`p-2 cursor-pointer ${smallScreenStyle} py-4 flex items-center px-6`}
                activeClassName="px-6 bg-newGray-dark"
            >
                <Bell className="w-5 h-5 mr-2" />
                <span className="text-white md:hidden lg-xl:flex text-sm font-normal">Notifications</span>
            </NavLink>
            <div className="mt-6">
                <span className={`text-xs text-white ${smallScreenStyle} mb-2 lg-xl:ml-6 mt-8`}>
                    {data?.user.type === 'AGENT' ? 'Practice' : 'Company'}
                </span>
            </div>
            <NavLink
                to={urlToQsInfo('/settings/company', data?.user.type, data?.user.organisation.name)}
                className={`p-2 flex py-4 ${smallScreenStyle} items-center px-6`}
                activeClassName={`px-6 bg-newGray-dark`}
            >
                <Briefcase className="w-5 h-5 mr-2" />
                <span className="text-sm md:hidden lg-xl:flex text-white">
                    {data?.user.type === 'AGENT' ? 'Practice Details' : 'Company Details'}
                </span>
            </NavLink>
            <NavLink
                to={urlToQsInfo('/settings/team', data?.user.type, data?.user.organisation.name)}
                className={`p-2 flex py-4 ${smallScreenStyle} items-center px-6`}
                activeClassName="bg-newGray-dark"
            >
                <Users className="w-5 h-5 mr-2" />
                <span className="text-sm md:hidden lg-xl:flex  text-white">
                    {data?.user.type === 'AGENT' ? 'Colleagues' : 'Users'}
                </span>
            </NavLink>
            {data?.user.__typename === 'User' && data.user.type === 'AGENT' && (
                <NavLink
                    to={urlToQsInfo('/settings/clients', data?.user.type, data?.user.organisation.name)}
                    className={`p-2 flex py-4 ${smallScreenStyle} items-center px-6`}
                    activeClassName={`px-6 bg-newGray-dark`}
                >
                    <UserChecked className="w-5 h-5 mr-2" />
                    <span className="text-sm md:hidden lg-xl:flex text-white">Clients</span>
                </NavLink>
            )}

            {/* ensure loading before sending to null route */}
            {data?.user.__typename === 'User' && data.user.type !== 'TENANT' && (
                <NavLink
                    exact
                    to={urlToQsInfo(
                        `/settings/clausebank/${data?.user.__typename === 'User' ? data?.user.organisation?._id : ''}`,
                        data?.user.type,
                        data?.user.organisation.name
                    )}
                    className={`p-2 flex py-4 ${smallScreenStyle} items-center px-6`}
                    activeClassName="bg-newGray-dark"
                >
                    <Server className="w-5 h-5 mr-2" />
                    <span className="text-sm md:hidden lg-xl:flex text-white">Clause Bank</span>
                </NavLink>
            )}
            {data?.user.__typename === 'User' && data.user?.type === 'ADMIN' && !isMobile && (
                <>
                    <div className="mt-6">
                        <span className={`text-white text-xs ${smallScreenStyle} mb-2 lg-xl:ml-6 mt-8`}>Admin</span>
                    </div>
                    <NavLink
                        to={urlToQsInfo('/settings/clausebank', data?.user.type, data?.user.organisation.name)}
                        exact
                        className={`p-2 flex py-4 ${smallScreenStyle} items-center px-6`}
                        activeClassName="bg-newGray-dark"
                    >
                        <Server className="w-5 h-5 mr-2" />
                        <span className="text-sm md:hidden lg-xl:flex text-white">Clause Bank</span>
                    </NavLink>
                    <NavLink
                        to={urlToQsInfo('/settings/landlordreadiness', data?.user.type, data?.user.organisation.name)}
                        exact
                        className={`p-2 flex py-4 ${smallScreenStyle} items-center px-6`}
                        activeClassName="bg-newGray-dark"
                    >
                        <Server className="w-5 h-5 mr-2" />
                        <span className="text-sm md:hidden lg-xl:flex text-white">Landlord Readiness</span>
                    </NavLink>
                    <NavLink
                        exact
                        to={urlToQsInfo('/settings/companies', data?.user.type, data?.user.organisation.name)}
                        className={`p-2 flex py-4 ${smallScreenStyle} items-center px-6`}
                        activeClassName="bg-newGray-dark"
                    >
                        {' '}
                        <Company className="w-6 h-5 mr-1" />
                        <span className="text-sm md:hidden lg-xl:flex text-white">Companies</span>
                    </NavLink>
                    <NavLink
                        exact
                        to={urlToQsInfo('/settings/users', data?.user.type, data?.user.organisation.name)}
                        className={`p-2 flex py-4 ${smallScreenStyle} items-center px-6`}
                        activeClassName="bg-newGray-dark"
                    >
                        <Users className="w-5 h-5 mr-2" />
                        <span className="text-sm md:hidden lg-xl:flex text-white">Users</span>
                    </NavLink>
                </>
            )}
        </div>
    );
};

export default Settings;
