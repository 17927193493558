import React, { ReactNode, useContext, useMemo, useState } from 'react';

import DealHeaderContext from './DealHeaderContext';
import { ReactComponent as DeleteIcon } from '../../../../assets/images/x/x.svg';
import { DealActorFragment, DealUserParty, User } from '../../../../generated/graphql';
import { Button } from '../../../../shared/button';

export enum ListType {
    Landlord = 'LandlordList',
    Tenant = 'TenantList',
    Agent = 'AgentList',
    AgentSameOrg = 'AgentsInSameOrganisation',
    AgentNotSameOrg = 'AgentsNotInSameOrganisation'
}

type DealActorsListProps = {
    uniqueUsers: DealActorFragment[];
    currentParty?: false | DealUserParty | null | undefined;
    type: 'Landlord' | 'Tenant' | 'Agent' | 'Admin';
    setShowRemoveUserWindow: React.Dispatch<React.SetStateAction<boolean>>;
    setUser: React.Dispatch<React.SetStateAction<User | null>>;
    setShowEmailUserWindow: React.Dispatch<React.SetStateAction<boolean>>;
    listType?: ListType;
    action?: boolean;
    actionIcon?: ReactNode;
    onActionHandle?: () => void;
};

const DealActorsList = ({
    uniqueUsers,
    currentParty,
    type,
    setShowRemoveUserWindow,
    setUser,
    listType,
    action,
    actionIcon,
    onActionHandle
}: DealActorsListProps) => {
    const { detailsData, completedDeal } = useContext(DealHeaderContext);

    const [collapse, setCollapse] = useState<boolean>(listType === ListType.Landlord);

    const header = useMemo(() => {
        switch (listType) {
            case ListType.Landlord:
                return detailsData?.deal.unit?.organisation.name;
            case ListType.Tenant:
                return detailsData?.deal.tenant?.name;
            case ListType.Agent:
                return 'Advisors';
            case ListType.AgentSameOrg:
                return 'Your Company';
            case ListType.AgentNotSameOrg:
                return 'Other Advisors';
        }
    }, [listType]);

    return (
        <>
            <div
                className={`bg-navy-lightest w-full  ${
                    type === 'Landlord' ? 'h-10' : 'h-12 p-2'
                }  px-6 inline-flex items-center justify-between`}
                style={collapse ? { borderBottom: '1px white solid' } : {}}
                onClick={() => setCollapse(!collapse)}
            >
                <p className={`text-base`}>{header}</p>
                {action && <button onClick={onActionHandle}>{actionIcon}</button>}
            </div>
            {!collapse &&
                uniqueUsers?.map((user) => {
                    const isPending = user.pending || (!user.firstName && !user.lastName);

                    return (
                        <div
                            key={user._id}
                            className="flex flex-row relative items-center justify-start border-navy-light border-b"
                        >
                            <div className="w-100 flex flex-col justify-start relative">
                                <div className="w-100 flex justify-between items-center pl-6 pr-4 p-2">
                                    <div className="overflow-x-hidden" style={{ maxWidth: '300px' }}>
                                        {user.pending ? (
                                            <p className="text-gray-800 text-sm font-light text-opacity-50">
                                                Invitation sent
                                            </p>
                                        ) : !user.activated && !user.pending ? (
                                            <p className="text-gray-800 text-sm font-light text-opacity-50">
                                                User not invited
                                            </p>
                                        ) : (
                                            <h3
                                                style={{
                                                    textOverflow: 'ellipsis'
                                                }}
                                                className="text-base font-semibold"
                                            >{`${user.firstName} ${user.lastName}`}</h3>
                                        )}
                                        <p
                                            style={{
                                                textOverflow: 'ellipsis',
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden'
                                            }}
                                            className="text-black text-base"
                                        >
                                            {user.email}
                                        </p>
                                    </div>
                                    {([ListType.Landlord, ListType.Tenant].includes(listType!) && !isPending) ||
                                    completedDeal ? (
                                        <></>
                                    ) : (
                                        currentParty &&
                                        currentParty.toLowerCase() === type.toLowerCase() && (
                                            <Button
                                                onClick={() => {
                                                    setShowRemoveUserWindow(true);
                                                    setUser(user as User);
                                                }}
                                                height="9"
                                                border="none"
                                                className="text-newGray-main  hover:text-black w-9 p-2 transition duration-150 ml-2"
                                            >
                                                <DeleteIcon />
                                            </Button>
                                        )
                                    )}
                                </div>
                            </div>
                        </div>
                    );
                })}
        </>
    );
};

export default DealActorsList;
