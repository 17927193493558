import React, { useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';

import {
    GetTemplateCardDetailsDocument,
    UnitUse,
    useGetUserTypeQuery,
    UserType,
    useSaveTemplateDetailsMutation
} from '../../../generated/graphql';
import { Button } from '../../../shared/button';
import { handleFetchError, humanizeString } from '../../../shared/helpers';
import Input, { InputType } from '../../../shared/Inputs';
import Select from '../../../shared/Inputs/Select';
import { UseConditionToText } from '../../../shared/NewUnit/hooks/useGetUnitSelectData';
import Window from '../../../shared/Window';

type SaveTemplateWindowProps = {
    templateID: string;
    name?: string | null;
    organisationName?: string | null;
    description?: string | null;
    global?: boolean | null;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
    refetch: () => void;
    uses?: UnitUse[] | undefined | null;
};

const SaveTemplateWindow = ({
    templateID,
    name,
    organisationName,
    description,
    global,
    refetch,
    uses,
    ...props
}: SaveTemplateWindowProps) => {
    const history = useHistory();
    // Error message
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    // Form values
    const templateNameRef = useRef<HTMLInputElement>(null);
    const templateDescriptionRef = useRef<HTMLTextAreaElement>(null);
    const templateGlobalRef = useRef<HTMLInputElement>(null);
    const [associatedUses, setAssociatedUses] = useState<UnitUse[] | undefined | null>(uses);

    const { data: typeData } = useGetUserTypeQuery();

    const [saveTemplate, { error: saveTemplateError }] = useSaveTemplateDetailsMutation();

    const handleConfirmClick = async () => {
        const templateName = templateNameRef.current?.value;
        const templateDescription = templateDescriptionRef.current?.value;
        const templateGlobal =
            typeData?.user.__typename === 'User' && typeData.user.type === UserType.Admin
                ? templateGlobalRef.current?.checked
                : undefined;

        if (!templateName && !templateDescription) {
            setErrorMessage('At least one field is required');
            return;
        }

        if (!associatedUses || associatedUses.length === 0) {
            setErrorMessage('Associated uses must be selected');
            return;
        }

        const { data } = await saveTemplate({
            variables: {
                templateID,
                name: templateName,
                description: templateDescription,
                global: templateGlobal,
                associatedUses
            },
            refetchQueries: [
                {
                    query: GetTemplateCardDetailsDocument
                }
            ]
        });

        if (saveTemplateError) {
            handleFetchError('Failed to save the template', saveTemplateError, data?.templateUpdate);
            return;
        }

        refetch();
        props.setShow(false);
        history.push('/templates');
    };

    return (
        <Window
            title={'Save Template'}
            footer={
                <div className="w-full p-2 flex justify-end">
                    {typeData?.user.__typename === 'User' && typeData.user.type === UserType.Admin && (
                        <div className="flex flex-row items-center mr-6">
                            <label htmlFor="deal-breaker" className="text-sm text-newGray-darkish mr-2">
                                Available to all Kato Users?
                            </label>
                            <input
                                type="checkbox"
                                defaultChecked={!!global}
                                ref={templateGlobalRef}
                                className="form-checkbox p-2 rounded-sm bg-white text-newGray-darkish border border-newGray-darkish focus:outline-none"
                            />
                        </div>
                    )}
                    <Button
                        text="white"
                        background="newTeal-main"
                        className="px-10 font-semibold text-sm w-full md:w-auto"
                        onClick={handleConfirmClick}
                    >
                        Save
                    </Button>
                </div>
            }
            {...props}
        >
            <form className="flex flex-col py-4 px-2 ">
                <Input
                    type={InputType.text}
                    defaultValue={name}
                    placeholder="Template Name"
                    label="Template Name"
                    className="w-full mb-4"
                    ref={templateNameRef}
                />
                <Input
                    type={InputType.text}
                    defaultValue={organisationName}
                    placeholder="Organisation Name"
                    label="Organisation Name"
                    disabled
                    className="w-full mb-4 bg-navy-lightest"
                />
                <Select
                    data={Object.values(UnitUse).map((use) => {
                        return {
                            text: UseConditionToText(use),
                            id: use
                        };
                    })}
                    label="Designated Uses"
                    placeholder="Select use cases"
                    isMulti
                    className="md:mr-2 bg-navy-lightest w-full md:mb-auto"
                    defaultValue={
                        associatedUses
                            ? associatedUses.map((use) => ({
                                  value: use,
                                  label: UseConditionToText(use) ?? ''
                              }))
                            : undefined
                    }
                    onChange={(value: any) => {
                        if (!value || value.length === 0) {
                            setAssociatedUses([]);
                            return;
                        }

                        const uses = value.map((item: any) => {
                            return UnitUse[humanizeString(item.value)];
                        });

                        setAssociatedUses(uses);
                    }}
                />
                <p className="text-gray-600 font-medium text-sm mb-0.5 mt-4">Template Description</p>
                <textarea
                    cols={50}
                    rows={5}
                    defaultValue={description ?? ''}
                    placeholder="Template Description"
                    className="w-full border border-navy-light rounded p-4 focus:outline-none"
                    ref={templateDescriptionRef}
                ></textarea>
                {errorMessage && (
                    <div className="bg-red-200 border border-red-600 rounded w-full py-2 px-4 mt-4">
                        <span className="text-center text-red-600">{errorMessage}</span>
                    </div>
                )}
            </form>
        </Window>
    );
};

export default SaveTemplateWindow;
