import React from 'react';
import { SubmitHandler } from 'react-hook-form';

import { IUserFormData } from './Profile';
import { UserDetailsFragment } from '../../../generated/graphql';
import { Button } from '../../../shared/button';
import Input, { InputType } from '../../../shared/Inputs';
import Form from '../../../shared/Inputs/Form';

type ProfileFormProps = {
    defaultValues?: UserDetailsFragment;
    onSubmit: SubmitHandler<IUserFormData>;
    isUpdating: boolean;
    setUpdating: React.Dispatch<React.SetStateAction<boolean>>;
};

const ProfileForm = ({ defaultValues, onSubmit, isUpdating, setUpdating }: ProfileFormProps) => (
    <Form onSubmit={onSubmit} setShowButton={setUpdating}>
        {({ register, formState: { errors } }) => (
            <>
                {/* Fist and Last Name */}
                <div className="flex flex-wrap md:flex-no-wrap w-full gap-4">
                    <div className="w-full md:w-1/2">
                        <Input
                            type={InputType.text}
                            label={'First Name'}
                            defaultValue={defaultValues?.firstName}
                            error={errors.firstName}
                            {...register('firstName', {
                                required: 'First name is required'
                            })}
                            className={`bg-gray-200 mb-4 w-full`}
                        />
                    </div>
                    <div className="w-full md:w-1/2">
                        <Input
                            type={InputType.text}
                            label={'Last Name'}
                            defaultValue={defaultValues?.lastName}
                            error={errors.lastName}
                            {...register('lastName', {
                                required: 'Last name is required'
                            })}
                            className={`bg-gray-200 mb-4 w-full`}
                        />
                    </div>
                </div>

                {/* Email */}
                <div className="w-full">
                    <Input
                        type={InputType.email}
                        label={'Email'}
                        defaultValue={defaultValues?.email}
                        error={errors.email}
                        {...register('email', {
                            required: 'Email is required'
                        })}
                        className={`bg-gray-200 mb-4 w-full`}
                    />
                </div>

                {/* Mobile and User Type*/}
                <div className="flex flex-wrap md:flex-no-wrap w-full gap-4 mb-4">
                    <div className="w-full md:w-1/2">
                        <Input
                            type={InputType.phone}
                            label={'Phone Number'}
                            defaultValue={defaultValues?.mobile}
                            error={errors.mobile}
                            {...register('mobile', {
                                required: 'Mobile is required'
                            })}
                            className={`bg-gray-200 mb-4 w-full`}
                        />
                    </div>
                    <div className="w-full md:w-1/2">
                        <Input
                            type={InputType.text}
                            name="type"
                            label={'Account Type'}
                            disabled={true}
                            value={defaultValues?.type.toLocaleLowerCase()}
                            className="bg-navy-light text-black mb-4 w-full border border-newGray-main capitalize"
                        />
                    </div>
                </div>

                {/* Save Button*/}
                {isUpdating && (
                    <div className="flex justify-end w-full">
                        <div className="w-full md:w-auto">
                            <Button background="newTeal-main" text="white" className={`w-full md:px-8 font-semibold`}>
                                Save Changes
                            </Button>
                        </div>
                    </div>
                )}
            </>
        )}
    </Form>
);

export default ProfileForm;
