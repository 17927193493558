import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { DealCardContext } from '..';
import { ReactComponent as Check } from '../../../../assets/images/check/regularCheck/check.svg';
import { ReactComponent as Chevron } from '../../../../assets/images/chevron/right/black.svg';
import { DealUserParty } from '../../../../generated/graphql';
import { BulkSelectContext } from '../../../../shared/bulk/context';

type DealCardHeaderProps = {
    dealURL: string;
};

const DealCardHeader = ({ dealURL }: DealCardHeaderProps) => {
    const { selectMode, manageSelectState } = useContext(BulkSelectContext);
    const { compact, name, unitName, buildingName, selectState, setSelectState, showSelectButton, currentUserParty } =
        useContext(DealCardContext);

    useEffect(() => {
        if (!selectMode) setSelectState(false);
    }, [selectMode]);

    const history = useHistory();

    const isLandlord = currentUserParty === DealUserParty.Landlord;

    return (
        <>
            {compact && <Chevron className="w-6 h-6 absolute right-4 top-5" />}
            <div className="p-4 flex flex-row items-center">
                {showSelectButton && isLandlord && (
                    <div
                        className={`w-5 h-5 rounded z-20 mr-4 ${selectState ? 'bg-newTeal-main' : 'bg-navy-light'} `}
                        onClick={() => {
                            manageSelectState();
                            setSelectState(!selectState);
                        }}
                    >
                        {selectState && <Check className="w-5 h-5 text-white" />}
                    </div>
                )}
                <div
                    onClick={() => {
                        !selectMode && history.push(dealURL);
                    }}
                >
                    <h2 className={`text-xl font-semibold text-navy-main`}>{name}</h2>
                    <p className="font-light text-sm text-gray-600 text-base">{unitName}</p>
                    <p className="font-light text-sm text-gray-600 text-base">{buildingName}</p>
                </div>
            </div>
        </>
    );
};

export default DealCardHeader;
