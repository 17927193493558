import React, { useEffect, useState } from 'react';

import AddTaskWindow from './AddTaskWindow';
import Task from './Task';
import { useGetTasksQuery } from '../../../generated/graphql';
import { AddButton } from '../../../shared/add-button';
import { handleFetchError } from '../../../shared/helpers';
import useMobileScreen from '../../../shared/hooks/useMobileScreen';
import Loading from '../../../shared/Loading';

const LandlordReadiness = () => {
    const [showAddTaskWindow, setShowAddTaskWindow] = useState<boolean>(false);

    const { data, loading, error } = useGetTasksQuery();

    useEffect(() => {
        if (error) handleFetchError('Failed to load tasks', error, data?.tasks);
    }, [data, error]);

    const isMobile = useMobileScreen();

    return (
        <>
            {showAddTaskWindow && <AddTaskWindow setShow={setShowAddTaskWindow} />}
            <div className="py-5 px-5 border-b border-newGray-main flex flex-row w-full items-center">
                <AddButton
                    onClick={() => {
                        setShowAddTaskWindow(true);
                    }}
                    background="newTeal-main"
                    text="white"
                    height="12"
                    border="transparent"
                    className={`px-4 mr-2 my-2 ${isMobile ? 'w-full' : 'w-40'}  whitespace-no-wrap font-semibold`}
                    // disabled={loading}
                >
                    Create Task
                </AddButton>
            </div>
            <div className="px-5 pb-16">
                {data && data.tasks?.length === 0 ? (
                    <p className="mt-8">There are no admin tasks at the moment.</p>
                ) : (
                    <>
                        <div className="grid grid-cols-9 gap-4 py-4 px-4">
                            <span className="col-span-1 font-semibold text-gray-600">Index</span>
                            <span className="col-span-2 -ml-1 font-semibold text-gray-600">Task Name</span>
                            <span className="col-span-1 font-semibold text-gray-600 text-center">Weight</span>
                            <span className="col-span-2 -ml-3 font-semibold text-gray-600">Tags</span>
                            <span className="col-span-2 -ml-3 font-semibold text-gray-600">Use Cases</span>
                            <span className="col-span-1 -ml-4 font-semibold text-gray-600">Category</span>
                        </div>
                        {loading ? (
                            <Loading />
                        ) : (
                            data &&
                            [...data.tasks]
                                .sort((a, b) => a.index - b.index)
                                .map((task) => (
                                    <Task
                                        id={task._id}
                                        title={task.title}
                                        index={task.index}
                                        weight={task.weight}
                                        tags={task.tags ?? []}
                                        type={task.type}
                                        optional={task.optional}
                                        key={task._id}
                                        associatedUses={task.associatedUses}
                                    />
                                ))
                        )}
                    </>
                )}
            </div>
        </>
    );
};

export default LandlordReadiness;
