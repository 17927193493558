import React, { useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';

import WindowFooter from './layout/WindowFooter';
import WindowHeader from './layout/WindowHeader';
import StageCompleted from './StageCompleted';
import StageContext from './StageContext';
import { ReactComponent as CaretRight } from '../../../../../assets/images/chevron/right/black.svg';
import {
    GetDealTenantDetailsDocument,
    TenantCompanyType,
    useTenantActionStageSixMutation
} from '../../../../../generated/graphql';
import { Button } from '../../../../../shared/button';
import { handleFetchError } from '../../../../../shared/helpers';
import Input, { InputType } from '../../../../../shared/Inputs/index';
import Loading from '../../../../../shared/Loading';
import Window from '../../../../../shared/Window';

const Stage6Window = () => {
    const history = useHistory();
    const {
        dealID,
        details,
        backTo,
        setShowStage6Window: setShow,
        setShowDBContractorWindow,
        setShowManagerWindow,
        setShowSpecialistWindow
    } = React.useContext(StageContext);

    const choiceRef = useRef<HTMLDivElement>(null);

    // Initial Choices
    const [choice, setChoice] = useState<string | null>(
        !details?.six?.company?.type
            ? null
            : details.six.company.type === 'DB'
            ? 'Design & Build'
            : 'Architect & Contractor'
    );
    const [alreadyFitted, setAlreadyFitted] = useState<boolean>(details?.six?.fitted ?? false);

    // Company
    const [companyName, setCompanyName] = useState<string>(details?.six?.company?.name ?? '');
    const [contactName, setContactName] = useState<string>(details?.six?.company?.contactName ?? '');
    const [contactEmail, setContactEmail] = useState<string>(details?.six?.company?.email ?? '');
    const [contactNumber, setContactNumber] = useState<string>(details?.six?.company?.phone ?? '');

    // Project Manger
    const [mngCompanyName, setMngCompanyName] = useState<string>(details?.six?.projectManager?.company ?? '');
    const [mngContactName, setMngContactName] = useState<string>(details?.six?.projectManager?.name ?? '');
    const [mngContactEmail, setMngContactEmail] = useState<string>(details?.six?.projectManager?.email ?? '');
    const [mngContactNumber, setMngContactNumber] = useState<string>(details?.six?.projectManager?.phone ?? '');

    // No Manger
    const [noProjectManager, setNoProjectManager] = useState<boolean>(
        details?.six?.projectManager?.visible === undefined
            ? false
            : details?.six?.projectManager?.visible
            ? false
            : true
    );

    const clearForm = () => {
        setCompanyName('');
        setContactName('');
        setContactEmail('');
        setContactNumber('');
        setMngCompanyName('');
        setMngContactName('');
        setMngContactEmail('');
        setMngContactNumber('');
    };

    const handleWindow = (type: string) => {
        if (setShow && setShowDBContractorWindow && setShowManagerWindow && setShowSpecialistWindow) {
            setShow(false);
            switch (type) {
                case 'dbcontractor':
                    setShowDBContractorWindow(true);
                    break;
                case 'manager':
                    setShowManagerWindow(true);
                    break;
                case 'specialist':
                    setShowSpecialistWindow(true);
                    break;
            }
        }
    };

    // Set stage six
    const [actionStage, { error: actionStageError, loading: actionStageLoading }] = useTenantActionStageSixMutation();

    const saveToDB = async (completed?: boolean) => {
        let companyType = null;
        if (choice === 'Design & Build') companyType = TenantCompanyType.Db;
        if (choice === 'Architect & Contractor') companyType = TenantCompanyType.Ac;

        const { data } = await actionStage({
            variables: {
                dealID: dealID!,
                completed: completed ?? false,
                fitted: alreadyFitted,
                company: {
                    type: companyType,
                    name: companyName,
                    contactName: contactName,
                    email: contactEmail,
                    phone: contactNumber
                },
                projectManager: {
                    company: mngCompanyName,
                    name: mngContactName,
                    email: mngContactEmail,
                    phone: mngContactNumber,
                    visible: !noProjectManager
                }
            },
            refetchQueries: [
                {
                    query: GetDealTenantDetailsDocument,
                    variables: {
                        dealID: dealID!
                    }
                }
            ]
        });

        if (actionStageError) {
            return handleFetchError('Could not set your details', actionStageError, data?.dealTenantDetailsSet);
        }
    };

    const handleCompleteStage = async () => {
        if (!alreadyFitted) {
            // Local fields error Obj
            let fieldsErr = {
                companyName: false,
                contactName: false,
                contactEmail: false,
                contactNumber: false,
                mngCompanyName: false,
                mngContactName: false,
                mngContactEmail: false,
                mngContactNumber: false
            };

            const checkEmptyField = (field: string, fieldValue: string | undefined) => {
                !fieldValue
                    ? (fieldsErr = {
                          ...fieldsErr,
                          [field]: true
                      })
                    : (fieldsErr = {
                          ...fieldsErr,
                          [field]: false
                      });
            };

            // Check Empty Fields
            checkEmptyField('companyName', companyName);
            checkEmptyField('contactName', contactName);
            checkEmptyField('contactEmail', contactEmail);
            checkEmptyField('contactNumber', contactNumber);
            if (!noProjectManager) {
                checkEmptyField('mngCompanyName', mngCompanyName);
                checkEmptyField('mngContactName', mngContactName);
                checkEmptyField('mngContactEmail', mngContactEmail);
                checkEmptyField('mngContactNumber', mngContactNumber);
            }

            // Validate Emails
            const re = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

            const checkValidEmail = (field: string, fieldValue: string | undefined) => {
                if (fieldValue) {
                    !re.test(fieldValue)
                        ? (fieldsErr = {
                              ...fieldsErr,
                              [field]: true
                          })
                        : (fieldsErr = {
                              ...fieldsErr,
                              [field]: false
                          });
                }
            };

            if (!noProjectManager) {
                checkValidEmail('contactEmail', contactEmail);
                checkValidEmail('mngContactEmail', mngContactEmail);
            } else {
                checkValidEmail('contactEmail', contactEmail);
            }

            // Validate numbers
            const checkValidNumber = (field: string, fieldValue: string | undefined) => {
                if (fieldValue) {
                    isNaN(+fieldValue)
                        ? (fieldsErr = {
                              ...fieldsErr,
                              [field]: true
                          })
                        : (fieldsErr = {
                              ...fieldsErr,
                              [field]: false
                          });
                }
            };

            if (!noProjectManager) {
                checkValidNumber('contactNumber', contactNumber);
                checkValidNumber('mngContactNumber', mngContactNumber);
            } else {
                checkValidNumber('contactNumber', contactNumber);
            }

            // Send to backend
            const noErrors = Object.values(fieldsErr).every((v) => v === false);
            noErrors ? saveToDB(true) : saveToDB(false);

            // Go to the next stage
            history.push('#readiness-stage0');
        } else {
            saveToDB(true);
            // Go to the next stage
            history.push('#readiness-stage0');
        }
    };

    const switchChoice = (choice: 'Design & Build' | 'Architect & Contractor') => {
        clearForm();
        setChoice(choice);
        setAlreadyFitted(false);
        setTimeout(() => scrollToChoice(), 250);
    };

    const handleNoProjectManager = () => {
        setNoProjectManager(!noProjectManager);
        setMngCompanyName('');
        setMngContactName('');
        setMngContactEmail('');
        setMngContactNumber('');
    };

    const scrollToChoice = () => {
        if (choice && choiceRef.current) {
            choiceRef.current.scrollIntoView({
                behavior: 'smooth'
            });
        }
    };

    return (
        <Window
            width="w-4/5"
            scrollContent={true}
            className="md:max-h-11/12"
            contentBackground="navy-light"
            header={
                <WindowHeader
                    setShow={setShow!}
                    backTo={backTo!}
                    stage="6"
                    title="Instruct A Party To Fit Out Your Space"
                    description="Let’s get physical. Who is helping you fit-out and furnish your space?"
                />
            }
            footer={
                <WindowFooter
                    backTo="#readiness-stage5"
                    nextTo="#readiness-stage0"
                    onNextClick={async () => await handleCompleteStage()}
                />
            }
            setShow={setShow!}
            onCloseClick={() => {
                setShow!(false);
                history.push(backTo!);
            }}
        >
            <div className="flex flex-col min-h-full pb-2">
                <div className="w-full bg-navy-light flex-1 pb-4">
                    {actionStageLoading && <Loading className="mt-20" />}
                    <div className={`w-full flex flex-col items-center ${actionStageLoading && 'opacity-0'}`}>
                        {/* Big Choices */}
                        <div className="flex flex-col md:flex-row justify-center items-stretch lg:w-3/5 md:w-4/5 w-full px-4 md:px-0 sm:py-8 pt-0 pb-4">
                            <div
                                className={`flex flex-col w-full md:w-1/2 bg-white rounded mr-4 py-4 px-6 border border-white cursor-pointer mb-4 md:mb-0 ${
                                    choice === 'Design & Build' && 'border border-newTeal-main'
                                }`}
                                onClick={() => {
                                    switchChoice('Design & Build');
                                }}
                            >
                                <h3 className="text-xlg mb-4 font-semibold text-center">Design & Build</h3>
                                <div className="flex-1 flex items-start">
                                    <p className="text-center text-sm text-gray-600 font-medium w-4/5 mx-auto">
                                        This type of contractor does what it says on the tin. It is a one-stop-shop for
                                        designing and building your new workspace.
                                    </p>
                                </div>
                                <Button
                                    background="white"
                                    text="black"
                                    className="px-10 border-navy-light font-semibold w-full mt-5"
                                    onClick={() => {
                                        switchChoice('Design & Build');
                                    }}
                                >
                                    Select
                                </Button>
                            </div>
                            <div
                                className={`flex flex-col w-full md:w-1/2 bg-white rounded mr-4 py-4 px-6 border border-white cursor-pointer ${
                                    choice === 'Architect & Contractor' && 'border-newTeal-main'
                                }`}
                                onClick={() => {
                                    switchChoice('Architect & Contractor');
                                }}
                            >
                                <h3 className="text-xlg mb-4 font-semibold text-center">Architect & Contractor</h3>
                                <div className="flex-1 flex items-center">
                                    <p className="text-center text-sm text-gray-600 font-medium w-4/5 mx-auto">
                                        This is a more traditional approach where you appoint a specialist designer,
                                        project manager and contractor separately. Usually, an approach taken on bigger
                                        projects.
                                    </p>
                                </div>
                                <Button
                                    background="white"
                                    text="black"
                                    className="px-10 border-navy-light font-semibold w-full mt-5"
                                    onClick={() => {
                                        switchChoice('Architect & Contractor');
                                    }}
                                >
                                    Select
                                </Button>
                            </div>
                        </div>
                        <label className={`block mb-4 mx-4 md:mx-0 ${!choice && 'pb-64 md:pb-0'}`}>
                            <input
                                type="checkbox"
                                id="optional"
                                checked={alreadyFitted}
                                onChange={() => {
                                    setChoice(null);
                                    clearForm();
                                    setAlreadyFitted(!alreadyFitted);
                                }}
                                disabled={details?.six?.completed ? true : false}
                                className="leading-tight w-7 h-7 form-checkbox p-2 rounded bg-white text-newTeal-main focus:shadow-none focus:outline-none"
                            />
                            <span className="text-sm text-newGray-darkish ml-2">
                                The premises are fitted out already, I do not need to fit it out
                            </span>
                        </label>
                    </div>
                    {/* Form */}
                    {choice && (
                        <div ref={choiceRef} className="border-t border-b border-gray-450 py-6 pb-96 md:pb-0">
                            <div className="container lg:w-4/5 md:w-5/5 flex flex-col md:flex-row justify-center mx-auto">
                                <div className="md:w-1/2 w-full md:mr-4 mr-0 mb-4 px-4 md:mt-10">
                                    <p className="font-semibold text-newGray-darkish mb-2">
                                        {choice === 'Design & Build' ? 'Design & Build' : 'Architect & Contractor'}{' '}
                                        Company
                                    </p>
                                    <p className="mt-2 text-gray-600 font-medium text-sm mb-0.5">
                                        <span className="text-newTeal-main mr-1">*</span>
                                        Company Name
                                    </p>
                                    <Input
                                        type={InputType.text}
                                        value={companyName}
                                        onChange={(e) => {
                                            setCompanyName(e.target.value);
                                        }}
                                        placeholder="Enter Company Name"
                                        className={`w-full mb-1`}
                                        autoComplete="off"
                                    />
                                    <p className="mt-2 text-gray-600 font-medium text-sm mb-0.5">
                                        <span className="text-newTeal-main mr-1">*</span>
                                        Contact Name
                                    </p>
                                    <Input
                                        type={InputType.text}
                                        value={contactName}
                                        onChange={(e) => {
                                            setContactName(e.target.value);
                                        }}
                                        placeholder="Enter Contact Name"
                                        className={`w-full mb-1`}
                                        autoComplete="off"
                                    />
                                    <p className="mt-2 text-gray-600 font-medium text-sm mb-0.5">
                                        <span className="text-newTeal-main mr-1">*</span>
                                        Contact Email
                                    </p>
                                    <Input
                                        type={InputType.text}
                                        value={contactEmail}
                                        onChange={(e) => {
                                            setContactEmail(e.target.value);
                                        }}
                                        placeholder="Enter Contact Email"
                                        className={`w-full mb-1`}
                                        autoComplete="off"
                                    />
                                    <p className="mt-2 text-gray-600 font-medium text-sm mb-0.5">
                                        <span className="text-newTeal-main mr-1">*</span>
                                        Contact Number
                                    </p>
                                    <Input
                                        type={InputType.text}
                                        value={contactNumber}
                                        onChange={(e) => {
                                            setContactNumber(e.target.value);
                                        }}
                                        placeholder="Enter Contact Number"
                                        className={`w-full`}
                                        autoComplete="off"
                                    />
                                    <div
                                        className="mt-4 pl-3 pr-2 h-12 border border-newGray-main rounded flex justify-between items-center cursor-pointer text-gray-600 hover:bg-white hover:border-white hover:text-gray-800 transitionAll"
                                        onClick={() => {
                                            choice === 'Design & Build'
                                                ? handleWindow('dbcontractor')
                                                : handleWindow('specialist');
                                        }}
                                    >
                                        <p className="font-medium text-sm">
                                            {choice === 'Design & Build'
                                                ? 'Find a D&B Contractor'
                                                : 'Find a Fit-Out Specialist'}
                                        </p>
                                        <CaretRight className="w-6 h-6 text-newGray-main" />
                                    </div>
                                </div>
                                <div className="md:w-1/2 w-full px-4">
                                    <p className="font-semibold text-newGray-darkish mb-2">
                                        Third Party Project Manager
                                    </p>
                                    <label className="flex items-center mb-3 relative">
                                        <input
                                            type="checkbox"
                                            id="optional"
                                            checked={noProjectManager}
                                            onChange={handleNoProjectManager}
                                            className="leading-tight w-7 h-7 form-checkbox p-2 rounded bg-white text-newTeal-main focus:shadow-none focus:outline-none"
                                        />
                                        <span className="text-sm text-newGray-darkish ml-4">
                                            We're not using a project manager
                                        </span>
                                    </label>
                                    {/* Project manager fields */}
                                    <div>
                                        <p className="mt-2 text-gray-600 font-medium text-sm mb-0.5">
                                            <span className="text-newTeal-main mr-1">*</span>
                                            Manager Company Name
                                        </p>
                                        <Input
                                            type={InputType.text}
                                            value={mngCompanyName}
                                            onChange={(e) => {
                                                setMngCompanyName(e.target.value);
                                            }}
                                            disabled={noProjectManager ? true : false}
                                            placeholder="Enter Manager Company Name"
                                            className={`w-full mb-1`}
                                            autoComplete="off"
                                        />
                                        <p className="mt-2 text-gray-600 font-medium text-sm mb-0.5">
                                            <span className="text-newTeal-main mr-1">*</span>
                                            Manager Contact Name
                                        </p>
                                        <Input
                                            type={InputType.text}
                                            value={mngContactName}
                                            onChange={(e) => {
                                                setMngContactName(e.target.value);
                                            }}
                                            disabled={noProjectManager ? true : false}
                                            placeholder="Enter Manager Contact Name"
                                            className={`w-full mb-1`}
                                            autoComplete="off"
                                        />
                                        <p className="mt-2 text-gray-600 font-medium text-sm mb-0.5">
                                            <span className="text-newTeal-main mr-1">*</span>
                                            Manager Contact Email
                                        </p>
                                        <Input
                                            type={InputType.text}
                                            value={mngContactEmail}
                                            onChange={(e) => {
                                                setMngContactEmail(e.target.value);
                                            }}
                                            disabled={noProjectManager ? true : false}
                                            placeholder="Enter Manager Contact Email"
                                            className={`w-full mb-1`}
                                            autoComplete="off"
                                        />
                                        <p className="mt-2 text-gray-600 font-medium text-sm mb-0.5">
                                            <span className="text-newTeal-main mr-1">*</span>
                                            Manager Contact Number
                                        </p>
                                        <Input
                                            type={InputType.text}
                                            value={mngContactNumber}
                                            onChange={(e) => {
                                                setMngContactNumber(e.target.value);
                                            }}
                                            disabled={noProjectManager ? true : false}
                                            placeholder="Enter Manager Contact Number"
                                            className={`w-full`}
                                            autoComplete="off"
                                        />
                                        <div
                                            className={`mt-4 pl-3 pr-2 h-12 border text-newGray-main border-newGray-main rounded flex justify-between items-center ${
                                                noProjectManager
                                                    ? 'cursor-not-allowed opacity-50'
                                                    : 'cursor-pointer hover:bg-white hover:border-white hover:text-gray-800 transitionAll'
                                            }`}
                                            onClick={() => handleWindow('manager')}
                                        >
                                            <p className="font-medium text-sm">Find a Third Party Project Manager</p>
                                            <CaretRight className="w-6 h-6 text-newGray-main" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* Bottom text */}
                            <p className="text-gray-600 font-medium text-sm mt-2 mb-4 text-center">
                                <span className="text-newTeal-main mr-1">*</span>
                                All the fields are required to complete the stage
                            </p>
                        </div>
                    )}
                </div>
                {details?.six?.completed && <StageCompleted />}
            </div>
        </Window>
    );
};
export default Stage6Window;
