import { format } from 'date-fns';
import React from 'react';
import ReactTooltip from 'react-tooltip';

import { ReactComponent as Clock } from '../../assets/images/clock/black.svg';

type CardCreatedAtProps = {
    created?: string | Date;
    className?: string;
};

const CardCreatedAt = ({ created, className }: CardCreatedAtProps) => {
    const createdOnDate = created && 'Created on ' + format(new Date(created), 'dd MMM yyyy, h:mm a');

    return (
        <div className={`absolute top-0 right-0  mt-0 z-20 ${className ? className : `mr-11`}`}>
            <Clock
                data-border-color={'white'}
                data-tip={created && createdOnDate}
                className="w-4 h-4 text-sm mt-3 border-none"
                style={{ outline: 'none' }}
            />
            <ReactTooltip
                backgroundColor="#f4f4f4"
                textColor="black"
                effect="solid"
                place="bottom"
                clickable={false}
                borderColor="white"
            />
        </div>
    );
};

export default CardCreatedAt;
