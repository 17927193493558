import React from 'react';

import UnitsList from './UnitsList';
import { SingleUnitCardFragment, User } from '../../generated/graphql';

type UnitCardsProps = {
    user?: User;
    units: SingleUnitCardFragment[];
    countsRefetch: () => void;
    refetch: () => void;
};

const UnitCards = ({ user, units, countsRefetch, refetch }: UnitCardsProps) => {
    return <UnitsList user={user} units={units} countsRefetch={countsRefetch} refetch={refetch} />;
};

export default UnitCards;
