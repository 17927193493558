import React, { useEffect, useState } from 'react';

import LinkedClause from './LinkedClause';
import useLongFormBox from './LongFormBox';
import { Button } from '../../button';
import { formatClause } from '../../helpers';
import Input, { InputType } from '../../Inputs';
import LinkClauseWindow from '../LinkClauseWindow';

import { LinkedClause as LinkedClauseType } from '.';

type NewClauseDetailsProps = {
    organisationID: string;
    // If editing a clause, get its ID so it's not shown in the linked clause list
    clauseID?: string;
    simple?: boolean;
    sectionName?: string | null;
    groupName?: string | null;
    clauseGroupName?: string | null;
    defaultValue?: string | null;
    defaultShort?: string | null;
    defaultLong?: string | null;
    defaultSuffix?: string | null;
    valueRef: React.RefObject<HTMLInputElement>;
    suffixRef: React.RefObject<HTMLInputElement>;
    shortFormRef: React.RefObject<HTMLInputElement>;
    longFormRef: React.RefObject<HTMLInputElement>;
    linkedClauses: LinkedClauseType[];
    addLinkedClauses: (clause: LinkedClauseType) => void;
    removeLinkedClauses: (clause: LinkedClauseType) => void;
};

const NewClauseDetails = ({
    organisationID,
    clauseID,
    simple = false,
    sectionName,
    groupName,
    clauseGroupName,
    valueRef,
    defaultValue,
    defaultShort,
    suffixRef,
    shortFormRef,
    defaultSuffix,
    defaultLong,
    longFormRef,
    linkedClauses,
    addLinkedClauses,
    removeLinkedClauses
}: NewClauseDetailsProps) => {
    const { insertValue, updateClauseValue, formatClauseValue, LongFormBox } = useLongFormBox({
        label: 'Long Form',
        defaultValue: defaultLong
            ? formatClause(defaultLong, defaultValue, defaultSuffix ?? suffixRef.current?.value, [], true)
            : null
    });
    const [longFormValue, setLongFormValue] = useState<string>('');
    const [showLinkClauseWindow, setShowLinkClauseWindow] = useState<boolean>(false);
    const [insertedSuffix, setInsertedSuffix] = useState<string | undefined | null>(defaultSuffix);
    const [insertedDefault, setInsertedDefault] = useState<string | undefined | null>(defaultValue);

    useEffect(() => {
        setLongFormValue(formatClauseValue());
    }, [formatClauseValue]);

    const handleValueChange = (e: any) => {
        setInsertedDefault(e.target.value);
        if (!e.target.value) {
            updateClauseValue('*', suffixRef.current?.value);
        } else {
            updateClauseValue(e.target.value, suffixRef.current?.value);
        }
    };

    const handleDemiseChange = (e: any) => {
        setInsertedSuffix(e.target.value);
        if (!valueRef.current?.value) {
            updateClauseValue('*', e.target.value);
        } else {
            updateClauseValue(valueRef.current?.value, e.target.value);
        }
    };

    const handleSubmit = (e: any) => {
        e.preventDefault();
        insertValue();
    };

    return (
        <>
            {showLinkClauseWindow && sectionName && groupName && (
                <LinkClauseWindow
                    organisationID={organisationID}
                    clauseID={clauseID}
                    section={sectionName}
                    group={groupName}
                    clauseGroup={clauseGroupName}
                    linkedClauses={linkedClauses}
                    addLinkedClauses={addLinkedClauses}
                    removeLinkedClauses={removeLinkedClauses}
                    setShow={setShowLinkClauseWindow}
                    refetch={() => {}}
                />
            )}
            <div className="flex flex-col items-start content-center w-full mb-2">
                <div className="flex flex-row flex-wrap w-full items-center gap-4">
                    <Input
                        type={InputType.text}
                        defaultValue={defaultShort || undefined}
                        ref={shortFormRef}
                        placeholder="Clause Short Form"
                        className={`bg-navy-lightest w-full`}
                        label="Short Form"
                    />
                    {!simple && (
                        <>
                            <div className="float-right flex flex-col md:flex-row items-end mr-0 gap-4 w-full">
                                <div className="flex flex-col md:flex-row items-center gap-1 w-full">
                                    <Input
                                        type={InputType.text}
                                        defaultValue={defaultValue}
                                        ref={valueRef}
                                        onChange={handleValueChange}
                                        placeholder="Value"
                                        className="bg-navy-lightest text-center w-full md:width-auto"
                                        label="Default Value"
                                    />
                                    <Input
                                        type={InputType.text}
                                        ref={suffixRef}
                                        defaultValue={defaultSuffix || undefined}
                                        onChange={handleDemiseChange}
                                        placeholder="Unit"
                                        className="bg-navy-lightest text-center w-full md:width-auto"
                                        label="Unit of Measurements"
                                    />
                                </div>
                                <Button
                                    background={`${
                                        !insertedSuffix && !insertedDefault ? 'newGray-main' : 'newTeal-main'
                                    }`}
                                    text="white"
                                    onClick={handleSubmit}
                                    className="p-4 font-semibold w-full md:width-auto"
                                    disabled={!insertedSuffix && !insertedDefault}
                                >
                                    Insert
                                </Button>
                            </div>
                        </>
                    )}
                </div>
                {LongFormBox}
                <input type="hidden" defaultValue={defaultLong || undefined} value={longFormValue} ref={longFormRef} />
            </div>
            {!simple && (
                <div className="flex flex-row items-start content-center w-full p-2 gap-4">
                    <div className="flex flex-row items-center flex-1 p-4 border border-navy-light rounded h-12 overflow-y-hidden overflow-x-auto max-w-full">
                        {linkedClauses && linkedClauses.length > 0 ? (
                            linkedClauses?.map(
                                (linkedClause) =>
                                    linkedClause.shortForm && (
                                        <LinkedClause
                                            id={linkedClause._id!}
                                            removeLinkedClauses={removeLinkedClauses}
                                            key={linkedClause._id}
                                        >
                                            {linkedClause.shortForm}
                                        </LinkedClause>
                                    )
                            )
                        ) : (
                            <p className="text-gray-600">Link Alternative Clauses</p>
                        )}
                    </div>
                    <Button
                        onClick={() => setShowLinkClauseWindow(true)}
                        background="black"
                        disabled={!sectionName || !groupName}
                        text="white"
                        className={`px-8 font-semibold ${(!sectionName || !groupName) && 'opacity-25'}`}
                    >
                        Add Link
                    </Button>
                </div>
            )}
        </>
    );
};

export default NewClauseDetails;
