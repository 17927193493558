import React, { FC } from 'react';

export type ButtonType = 'button' | 'submit' | 'reset';

export type ButtonProps = {
    text?: string;
    background?: string;
    border?: string;
    height?: string;
    className?: string;
    onClick?: (event?: any) => void;
    onHover?: (event?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    disabled?: boolean;
    children?: React.ReactNode;
    notRounded?: boolean;
    style?: object;
    id?: string;
    type?: ButtonType;
    textAlignment?: string;
    fontWeight?: string;
};

export const Button: FC<ButtonProps> = ({
    text,
    background,
    border = background,
    className,
    height,
    onClick,
    onHover,
    disabled = false,
    children,
    style,
    id,
    type,
    textAlignment,
    fontWeight
}) => {
    return (
        <button
            type={type}
            onClick={onClick}
            onMouseOver={onHover}
            disabled={disabled}
            className={`${fontWeight ?? 'font-semibold'} h-${
                height ? height : '12'
            } text-${text} bg-${background} border ${
                text ? '' : 'text-sm md:text-lg'
            } border-${border} inline-flex rounded items-center justify-center ${
                textAlignment ?? 'text-center'
            } focus:outline-none ${disabled && 'cursor-not-allowed'} ${className}`}
            style={style}
            id={id}
        >
            {children}
        </button>
    );
};
