import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: any;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
};

export type ActivityLog = {
  __typename?: 'ActivityLog';
  /** Globally unique identifier */
  _id: Scalars['ID'];
  action: Scalars['String'];
  actor: User;
  created: Scalars['DateTime'];
  object: Scalars['JSON'];
};

/** Input a new address */
export type AddressCreateInput = {
  /** Address City. 1-100 characters. */
  city?: Maybe<Scalars['String']>;
  /** Address Country. 1-100 characters. */
  country?: Maybe<Scalars['String']>;
  /** Address County. 1-100 characters. */
  county?: Maybe<Scalars['String']>;
  /** Address Line 1. 1-100 characters. */
  line1?: Maybe<Scalars['String']>;
  /** Address Line 2. 1-100 characters. */
  line2?: Maybe<Scalars['String']>;
  /** Address Postal Code. 1-100 characters. */
  postCode?: Maybe<Scalars['String']>;
};

/** Address Type */
export type AddressType = {
  __typename?: 'AddressType';
  /** Address City. 1-100 characters. */
  city?: Maybe<Scalars['String']>;
  /** Address Country. 1-100 characters. */
  country?: Maybe<Scalars['String']>;
  /** Address County. 1-100 characters. */
  county?: Maybe<Scalars['String']>;
  /** Address Line 1. 1-100 characters. */
  line1?: Maybe<Scalars['String']>;
  /** Address Line 2. 1-100 characters. */
  line2?: Maybe<Scalars['String']>;
  /** Address Post Code. 1-100 characters. */
  postCode?: Maybe<Scalars['String']>;
};

export type AdminLinked = {
  __typename?: 'AdminLinked';
  results?: Maybe<Array<ClauseTemplate>>;
};

/** Deletes an organisation as an admin, requires password */
export type AdminOrganisationDeleteInput = {
  /** Admin's passsword */
  password: Scalars['String'];
};

export type AdminTask = {
  __typename?: 'AdminTask';
  /** Globally unique identifier */
  _id: Scalars['ID'];
  associatedUses?: Maybe<Array<UnitUse>>;
  /** Date the object was created */
  created?: Maybe<Scalars['DateTime']>;
  /** Task index */
  index: Scalars['Float'];
  optional: Scalars['Boolean'];
  /** Task tags */
  tags?: Maybe<Array<Scalars['String']>>;
  /** Task title */
  title: Scalars['String'];
  /** Task type */
  type: AdminTaskType;
  /** Task weight */
  weight: Scalars['Float'];
};

export type AdminTaskCreateInput = {
  associatedUses?: Maybe<Array<UnitUse>>;
  index: Scalars['Int'];
  optional?: Maybe<Scalars['Boolean']>;
  tags?: Maybe<Array<Scalars['String']>>;
  title: Scalars['String'];
  type: AdminTaskType;
  weight: Scalars['Int'];
};

/** Admin task types */
export enum AdminTaskType {
  Boolean = 'BOOLEAN',
  File = 'FILE',
  Integer = 'INTEGER',
  Text = 'TEXT'
}

export type AdminTaskUpdateInput = {
  associatedUses?: Maybe<Array<UnitUse>>;
  index?: Maybe<Scalars['Int']>;
  optional?: Maybe<Scalars['Boolean']>;
  tags?: Maybe<Array<Scalars['String']>>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<AdminTaskType>;
  weight?: Maybe<Scalars['Int']>;
};

export type AdminUserAccessTokenCreateInput = {
  password: Scalars['String'];
};

/** Deletes a user account as an admin, requires password */
export type AdminUserDeleteInput = {
  /** Admin's passsword */
  password: Scalars['String'];
};

/** Party the deal was agreed to by */
export enum AgreedBy {
  Landlord = 'LANDLORD',
  Tenant = 'TENANT'
}

/** Type of avatar */
export enum AvatarType {
  Organisation = 'ORGANISATION',
  Unit = 'UNIT'
}

export type BooleanValue = {
  __typename?: 'BooleanValue';
  booleanValue: Scalars['Boolean'];
};

export type BulkUnitUpdateInput = {
  available?: Maybe<Scalars['String']>;
};

export type Clause = {
  __typename?: 'Clause';
  /** Globally unique identifier */
  _id: Scalars['ID'];
  approved?: Maybe<Scalars['Boolean']>;
  clauseRef?: Maybe<Scalars['ID']>;
  comments?: Maybe<Array<Comment>>;
  /** Date the object was created */
  created?: Maybe<Scalars['DateTime']>;
  dealBreaker: DealBreaker;
  deleted?: Maybe<Scalars['Boolean']>;
  groups?: Maybe<Array<Group>>;
  history: Array<History>;
  index: Scalars['Float'];
  linked?: Maybe<Array<LinkedClause>>;
  longForm?: Maybe<Scalars['String']>;
  parentID: Scalars['ID'];
  rejected?: Maybe<Scalars['Boolean']>;
  shortForm?: Maybe<Scalars['String']>;
  tags: Array<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
  valueSuffix?: Maybe<Scalars['String']>;
  visible?: Maybe<Scalars['Boolean']>;
};

export type ClauseBankOptions = {
  level2Group?: Maybe<Scalars['String']>;
  section?: Maybe<Scalars['String']>;
};

export type ClauseSummary = {
  __typename?: 'ClauseSummary';
  complete: Scalars['Int'];
  incomplete: Scalars['Int'];
};

/** Admin clause */
export type ClauseTemplate = {
  __typename?: 'ClauseTemplate';
  /** Globally unique identifier */
  _id: Scalars['ID'];
  associatedUses?: Maybe<Array<UnitUse>>;
  /** Date the object was created */
  created?: Maybe<Scalars['DateTime']>;
  linked: AdminLinked;
  longForm?: Maybe<Scalars['String']>;
  section?: Maybe<ClauseTemplateSection>;
  shortForm?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Scalars['String']>>;
  /** Date the object was updated */
  updated?: Maybe<Scalars['DateTime']>;
  /** Date when deal clauses were updated */
  updatedDealsAt?: Maybe<Scalars['DateTime']>;
  /** Date template clauses were updated */
  updatedTemplatesAt?: Maybe<Scalars['DateTime']>;
  value?: Maybe<Scalars['String']>;
  valueSuffix?: Maybe<Scalars['String']>;
};

/** Input required to create a clause template */
export type ClauseTemplateInput = {
  associatedUses?: Maybe<Array<UnitUse>>;
  groupDescription?: Maybe<Scalars['String']>;
  level1Group: Scalars['String'];
  level2Group?: Maybe<Scalars['String']>;
  linked?: Maybe<LinkClauseTemplateInput>;
  longForm?: Maybe<Scalars['String']>;
  section: Scalars['String'];
  sectionDescription?: Maybe<Scalars['String']>;
  shortForm?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Scalars['String']>>;
  updated?: Maybe<Scalars['DateTime']>;
  updatedDealsAt?: Maybe<Scalars['DateTime']>;
  updatedTemplatesAt?: Maybe<Scalars['DateTime']>;
  value?: Maybe<Scalars['String']>;
  valueSuffix?: Maybe<Scalars['String']>;
};

export type ClauseTemplateLevel1Group = {
  __typename?: 'ClauseTemplateLevel1Group';
  /** Globally unique identifier */
  _id: Scalars['ID'];
  description?: Maybe<Scalars['String']>;
  group?: Maybe<ClauseTemplateLevel2Group>;
  rics?: Maybe<Scalars['String']>;
  title: Scalars['String'];
};

export type ClauseTemplateLevel2Group = {
  __typename?: 'ClauseTemplateLevel2Group';
  /** Globally unique identifier */
  _id: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
};

/** Admin clause bank sections */
export type ClauseTemplateSection = {
  __typename?: 'ClauseTemplateSection';
  /** Globally unique identifier */
  _id: Scalars['ID'];
  /** Date the object was created */
  created?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  group: ClauseTemplateLevel1Group;
  rics?: Maybe<Scalars['String']>;
  title: Scalars['String'];
};

export type Comment = {
  __typename?: 'Comment';
  /** Globally unique identifier */
  _id: Scalars['ID'];
  actor: User;
  /** Date the object was created */
  created?: Maybe<Scalars['DateTime']>;
  date?: Maybe<Scalars['DateTime']>;
  message?: Maybe<Scalars['String']>;
  type: CommentType;
};

/** Comment types */
export enum CommentType {
  Edit = 'EDIT',
  Removal = 'REMOVAL'
}

export type CreateOrganisationEmailsInput = {
  /** Check if organisation is active */
  active?: Maybe<Scalars['Boolean']>;
  address?: Maybe<AddressCreateInput>;
  /** Organisation avatar URL */
  avatar?: Maybe<Scalars['String']>;
  /** User's emails */
  emails?: Maybe<Array<Scalars['String']>>;
  /** Name of the organisation to create */
  name: Scalars['String'];
  practiceType?: Maybe<PracticeType>;
  /** Type of the organisation to create */
  type: UserType;
  /** Organisation website */
  website?: Maybe<Scalars['String']>;
};

export type CreateOrganisationInput = {
  /** Check if organisation is active */
  active?: Maybe<Scalars['Boolean']>;
  address?: Maybe<AddressCreateInput>;
  /** Organisation avatar URL */
  avatar?: Maybe<Scalars['String']>;
  /** Name of the organisation to create */
  name: Scalars['String'];
  practiceType?: Maybe<PracticeType>;
  /** Type of the organisation to create */
  type: UserType;
  /** Organisation website */
  website?: Maybe<Scalars['String']>;
};


export type Deal = {
  __typename?: 'Deal';
  /** Globally unique identifier */
  _id: Scalars['ID'];
  actors: Array<User>;
  agreedBy?: Maybe<AgreedBy>;
  /** Represents if landlord saw deal agreement */
  agreementSeenLandlord?: Maybe<Scalars['Boolean']>;
  /** Represents if tenant saw deal agreement */
  agreementSeenTenant?: Maybe<Scalars['Boolean']>;
  /** Only available when querying deal using a token for an approval request */
  approvalID?: Maybe<Scalars['String']>;
  approvals?: Maybe<DealApprovalsType>;
  /** Only available when querying deal using a token */
  approverReturnEmail?: Maybe<Scalars['String']>;
  /** Represents a template draft deal which is used to create other deals. */
  baseDeal?: Maybe<Scalars['Boolean']>;
  clauseSummary: ClauseSummary;
  client: Organisation;
  /** Date the object was created */
  created?: Maybe<Scalars['DateTime']>;
  /** Deal's creator */
  creator?: Maybe<User>;
  currentUserParty: DealUserParty;
  details: TenantDetails;
  files: Array<File>;
  /** Does the approval user have access to extended deal? Only available when querying deal using a token */
  hasAccess?: Maybe<Scalars['Boolean']>;
  hasHiddenUnit?: Maybe<Scalars['Boolean']>;
  isPinned: Scalars['Boolean'];
  /** Represents the activity log for a deal */
  logs: Array<ActivityLog>;
  messages: Array<Message>;
  pending: Scalars['Boolean'];
  preview?: Maybe<Scalars['String']>;
  round?: Maybe<DealRoundType>;
  score: Scalars['Float'];
  sections: Array<Section>;
  state: DealState;
  template?: Maybe<Unit>;
  tenant?: Maybe<Organisation>;
  unit?: Maybe<Unit>;
};

export type DealApprovalSendInput = {
  additionalApproval?: Maybe<Scalars['Boolean']>;
  approvalID?: Maybe<Scalars['ID']>;
  currentUserEmail?: Maybe<Scalars['String']>;
  currentUserParty?: Maybe<DealUserParty>;
  emails?: Maybe<Array<Scalars['String']>>;
  message?: Maybe<Scalars['String']>;
  type?: Maybe<DealApprovalSendType>;
};

export type DealApprovalSendResponse = {
  __typename?: 'DealApprovalSendResponse';
  emails?: Maybe<Array<Scalars['String']>>;
  type: DealApprovalSendType;
};

/** Approval send types */
export enum DealApprovalSendType {
  Approve = 'APPROVE',
  Invite = 'INVITE',
  Other = 'OTHER',
  Reject = 'REJECT'
}

export enum DealApprovalStatus {
  Complete = 'COMPLETE',
  Voided = 'VOIDED',
  Waiting = 'WAITING'
}

export type DealApprovalType = {
  __typename?: 'DealApprovalType';
  /** Globally unique identifier */
  _id: Scalars['ID'];
  /** Date the object was created */
  created?: Maybe<Scalars['DateTime']>;
  date: Scalars['DateTime'];
  email?: Maybe<Scalars['String']>;
  status: DealApprovalStatus;
};

export type DealApprovalsType = {
  __typename?: 'DealApprovalsType';
  landlord: Array<DealApprovalType>;
  tenant: Array<DealApprovalType>;
};

export type DealBreaker = {
  __typename?: 'DealBreaker';
  isDealBreaker: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
};

export type DealBreakerInput = {
  isDealBreaker: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
};

export type DealClauseCommentInput = {
  message: Scalars['String'];
  type: CommentType;
};

export type DealClauseInput = {
  dealBreaker?: Maybe<DealBreakerInput>;
  dealID: Scalars['ID'];
  index: Scalars['Int'];
  linked?: Maybe<Array<LinkedClauseInput>>;
  longForm?: Maybe<Scalars['String']>;
  parentID: Scalars['ID'];
  shortForm?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Scalars['String']>>;
  value?: Maybe<Scalars['String']>;
  valueSuffix?: Maybe<Scalars['String']>;
  visible: Scalars['Boolean'];
};

export type DealClausePartialInput = {
  clauseRef?: Maybe<Scalars['ID']>;
  index: Scalars['Int'];
  linked?: Maybe<Array<LinkedClauseInput>>;
  longForm?: Maybe<Scalars['String']>;
  shortForm?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Scalars['String']>>;
  value?: Maybe<Scalars['String']>;
  valueSuffix?: Maybe<Scalars['String']>;
};

export type DealClauseUpdateInput = {
  approved?: Maybe<Scalars['Boolean']>;
  clauseID: Scalars['ID'];
  comment?: Maybe<DealClauseCommentInput>;
  dealBreaker?: Maybe<DealBreakerInput>;
  dealID: Scalars['ID'];
  index?: Maybe<IndexUpdate>;
  longForm?: Maybe<Scalars['String']>;
  parentID: Scalars['ID'];
  shortForm?: Maybe<Scalars['String']>;
  swapping?: Maybe<Scalars['Boolean']>;
  tags?: Maybe<Array<Scalars['String']>>;
  value?: Maybe<Scalars['String']>;
  valueSuffix?: Maybe<Scalars['String']>;
};

export type DealFilter = {
  buildingName?: Maybe<Array<Scalars['String']>>;
  city?: Maybe<Array<Scalars['String']>>;
  client?: Maybe<Array<Scalars['String']>>;
  condition?: Maybe<Array<UnitCondition>>;
  createdFrom?: Maybe<Scalars['DateTime']>;
  createdTo?: Maybe<Scalars['DateTime']>;
  creator?: Maybe<Array<Scalars['String']>>;
  status?: Maybe<Array<DealStatusFilter>>;
  template?: Maybe<Array<Scalars['String']>>;
  use?: Maybe<Array<UnitUse>>;
};

export type DealFilterCount = {
  __typename?: 'DealFilterCount';
  complete: Scalars['Float'];
  hidden: Scalars['Float'];
  live: Scalars['Float'];
};

export type DealGroupInput = {
  dealID: Scalars['ID'];
  description?: Maybe<Scalars['String']>;
  index: Scalars['Int'];
  parentID: Scalars['ID'];
  rics?: Maybe<Scalars['String']>;
  title: Scalars['String'];
};

export type DealGroupUpdateInput = {
  dealID: Scalars['ID'];
  description?: Maybe<Scalars['String']>;
  groupID: Scalars['ID'];
  index?: Maybe<IndexUpdate>;
  parentID: Scalars['ID'];
  rics?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type DealInviteFromStageResponse = {
  __typename?: 'DealInviteFromStageResponse';
  email: Scalars['String'];
};

export type DealInviteInput = {
  /** Invite user as member of the organisation */
  asMember?: Maybe<Scalars['Boolean']>;
  /** User's emails */
  emails: Array<Scalars['String']>;
  /** Deal party to add the user to */
  party: DealUserParty;
  /** New user's type */
  type: UserType;
};

export type DealRemoveActorInput = {
  /** Deal party to remove the user from */
  party: DealUserParty;
  /** User's ID */
  userID: Scalars['ID'];
};

export type DealRoundType = {
  __typename?: 'DealRoundType';
  count: Scalars['Int'];
  date: Scalars['DateTime'];
  nudge?: Maybe<Scalars['DateTime']>;
  seen?: Maybe<Scalars['Boolean']>;
};

export type DealSectionGroupPartialInput = {
  description?: Maybe<Scalars['String']>;
  index?: Maybe<Scalars['Int']>;
  rics?: Maybe<Scalars['String']>;
  title: Scalars['String'];
};

export type DealSectionInput = {
  dealID: Scalars['ID'];
  description?: Maybe<Scalars['String']>;
  index: Scalars['Int'];
  rics?: Maybe<Scalars['String']>;
  title: Scalars['String'];
};

export type DealSectionUpdateInput = {
  dealID: Scalars['ID'];
  description?: Maybe<Scalars['String']>;
  index?: Maybe<IndexUpdate>;
  rics?: Maybe<Scalars['String']>;
  sectionID: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
};

export type DealSeenResponse = {
  __typename?: 'DealSeenResponse';
  dealID: Scalars['ID'];
};

export type DealSendInput = {
  accountType: UserType;
  emails?: Maybe<Array<Scalars['String']>>;
  message?: Maybe<Scalars['String']>;
  otherEmails?: Maybe<Array<Scalars['String']>>;
  type: DealSendType;
};

export enum DealSendType {
  Approver = 'APPROVER',
  Landlord = 'LANDLORD',
  Other = 'OTHER',
  Tenant = 'TENANT'
}

export type DealSort = {
  field: DealSortFields;
  order?: Maybe<OrderBy>;
};

/** Sort the deals by particular fields */
export enum DealSortFields {
  AddressLineOne = 'ADDRESS_LINE_ONE',
  BuildingName = 'BUILDING_NAME',
  Client = 'CLIENT',
  Created = 'CREATED',
  RoundCount = 'ROUND_COUNT',
  RoundTime = 'ROUND_TIME',
  TotalTimeNegotiating = 'TOTAL_TIME_NEGOTIATING'
}

/** Possible states a deal can be in */
export enum DealState {
  Agreed = 'AGREED',
  Complete = 'COMPLETE',
  Edit = 'EDIT',
  Landlord = 'LANDLORD',
  Tenant = 'TENANT'
}

/** Filter the deals by if they are completed, active or hidden */
export enum DealStatusFilter {
  Active = 'ACTIVE',
  Complete = 'COMPLETE',
  Draft = 'DRAFT',
  Hidden = 'HIDDEN',
  Live = 'LIVE',
  Pinned = 'PINNED'
}

/** Possible parties on a deal */
export enum DealUserParty {
  Landlord = 'LANDLORD',
  Other = 'OTHER',
  Tenant = 'TENANT'
}

export type Deals = {
  __typename?: 'Deals';
  pageInfo: PageInfo;
  results: Array<Deal>;
};

export type DeletedDeal = {
  __typename?: 'DeletedDeal';
  deletedDealID: Scalars['ID'];
};

export type DeletedObject = {
  __typename?: 'DeletedObject';
  deletedObjectID: Scalars['String'];
};

/** Notifications Disabled Inner */
export type DisabledNotificationInput = {
  /** If notificaiton is disabled via inplatform */
  email?: Maybe<Scalars['Boolean']>;
  /** If notificaiton is disabled via email */
  notification?: Maybe<Scalars['Boolean']>;
};

/** User's disabled notifications */
export type DisabledNotifications = {
  __typename?: 'DisabledNotifications';
  approvals?: Maybe<IDisabledNotification>;
  deals?: Maybe<IDisabledNotification>;
  documents?: Maybe<IDisabledNotification>;
  seen?: Maybe<IDisabledNotification>;
  units?: Maybe<IDisabledNotification>;
  users?: Maybe<IDisabledNotification>;
};

/** Disabled Notifications Type */
export type DisabledNotificationsInput = {
  /** Notifications disabled for approval related actions */
  approvals?: Maybe<DisabledNotificationInput>;
  /** Notifications disabled for deal related actions */
  deals?: Maybe<DisabledNotificationInput>;
  /** Notifications disabled for document related actions */
  documents?: Maybe<DisabledNotificationInput>;
  /** Notifications disabled for  */
  seen?: Maybe<DisabledNotificationInput>;
  /** Notifications disabled for units related actions */
  units?: Maybe<DisabledNotificationInput>;
  /** Notifications disabled for user related actions */
  users?: Maybe<DisabledNotificationInput>;
};

/** Type of document */
export enum DocType {
  Deal = 'DEAL',
  Unit = 'UNIT'
}

/** Input required to edit a clause template */
export type EditClauseTemplateInput = {
  associatedUses?: Maybe<Array<UnitUse>>;
  groupDescription?: Maybe<Scalars['String']>;
  level1Group?: Maybe<Scalars['String']>;
  level2Group?: Maybe<Scalars['String']>;
  linked?: Maybe<LinkClauseTemplateInput>;
  longForm?: Maybe<Scalars['String']>;
  section?: Maybe<Scalars['String']>;
  sectionDescription?: Maybe<Scalars['String']>;
  shortForm?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Scalars['String']>>;
  updated?: Maybe<Scalars['DateTime']>;
  updatedDealsAt?: Maybe<Scalars['DateTime']>;
  updatedTemplatesAt?: Maybe<Scalars['DateTime']>;
  value?: Maybe<Scalars['String']>;
  valueSuffix?: Maybe<Scalars['String']>;
};

export type File = {
  __typename?: 'File';
  /** Globally unique identifier */
  _id: Scalars['ID'];
  /** Date the object was created */
  created?: Maybe<Scalars['DateTime']>;
  downloads: Array<UserDownload>;
  name: Scalars['String'];
  other?: Maybe<Scalars['Boolean']>;
  url: Scalars['String'];
  user: User;
};

export type FileInput = {
  name: Scalars['String'];
  url: Scalars['String'];
};

export type FilesValue = {
  __typename?: 'FilesValue';
  filesValue: Array<File>;
};

export type FloatValue = {
  __typename?: 'FloatValue';
  floatValue: Scalars['Float'];
};

export type Group = {
  __typename?: 'Group';
  /** Globally unique identifier */
  _id: Scalars['ID'];
  clauseSummary: ClauseSummary;
  clauses: Array<Clause>;
  /** Date the object was created */
  created?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  index: Scalars['Float'];
  parentID: Scalars['String'];
  rics?: Maybe<Scalars['String']>;
  title: Scalars['String'];
};

export type History = {
  __typename?: 'History';
  /** Globally unique identifier */
  _id: Scalars['ID'];
  actor: User;
  change?: Maybe<HistoryChange>;
  /** Date the object was created */
  created?: Maybe<Scalars['DateTime']>;
  date?: Maybe<Scalars['DateTime']>;
  party: DealUserParty;
  type: HistoryEventType;
};

export type HistoryChange = {
  __typename?: 'HistoryChange';
  new: HistoryValue;
  old?: Maybe<HistoryValue>;
};

/** Possible history events */
export enum HistoryEventType {
  Approve = 'APPROVE',
  Comment = 'COMMENT',
  Create = 'CREATE',
  Delete = 'DELETE',
  New = 'NEW',
  Swap = 'SWAP',
  Update = 'UPDATE'
}

export type HistoryValue = {
  __typename?: 'HistoryValue';
  approved?: Maybe<Scalars['Boolean']>;
  comments?: Maybe<Array<Comment>>;
  longForm?: Maybe<Scalars['String']>;
  rejected?: Maybe<Scalars['Boolean']>;
  shortForm?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
  valueSuffix?: Maybe<Scalars['String']>;
};

export type IDisabledNotification = {
  __typename?: 'IDisabledNotification';
  /** Are the email notifications disabled? */
  email?: Maybe<Scalars['Boolean']>;
  /** Are the on platform notifications disabled? */
  notification?: Maybe<Scalars['Boolean']>;
};

export type IndexUpdate = {
  from?: Maybe<Scalars['Int']>;
  to: Scalars['Int'];
};

/** Details of invitation */
export type InviteDetails = {
  __typename?: 'InviteDetails';
  /** User's organisation associated with invitation token */
  organisation: Organisation;
  /** User associated with invitation token */
  user: InviteTokenUser;
};

export enum InviteFromStageType {
  Agent = 'AGENT',
  Lawyer = 'LAWYER',
  Signator = 'SIGNATOR'
}

/** User associated with invitation */
export type InviteTokenUser = {
  __typename?: 'InviteTokenUser';
  /** User's ID associated with invitation token */
  _id: Scalars['String'];
  /** User's email associated with invitation token */
  email: Scalars['String'];
  existing: Scalars['Boolean'];
  type: Scalars['String'];
};


export type LinkClauseTemplateInput = {
  add?: Maybe<Array<Scalars['String']>>;
  remove?: Maybe<Array<Scalars['String']>>;
};

export type LinkedClause = {
  __typename?: 'LinkedClause';
  /** Globally unique identifier */
  _id?: Maybe<Scalars['ID']>;
  /** Date the object was created */
  created?: Maybe<Scalars['DateTime']>;
  longForm: Scalars['String'];
  shortForm: Scalars['String'];
  tags: Array<Scalars['String']>;
  value: Scalars['String'];
  valueSuffix: Scalars['String'];
};

export type LinkedClauseInput = {
  _id?: Maybe<Scalars['String']>;
  longForm?: Maybe<Scalars['String']>;
  shortForm?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Scalars['String']>>;
  value?: Maybe<Scalars['String']>;
  valueSuffix?: Maybe<Scalars['String']>;
};

export type Message = {
  __typename?: 'Message';
  /** Globally unique identifier */
  _id: Scalars['ID'];
  /** Date the object was created */
  created?: Maybe<Scalars['DateTime']>;
  message: Scalars['String'];
  party: DealUserParty;
  sent: Scalars['DateTime'];
  user: User;
};

/** Details of multiple invitations */
export type MultiInviteDetails = {
  __typename?: 'MultiInviteDetails';
  /** User's organisation associated with invitation token */
  organisation: Organisation;
  /** Users associated with invitation token */
  users: Array<InviteTokenUser>;
};

export type Mutation = {
  __typename?: 'Mutation';
  activateOrganisation: Organisation;
  adminClauseAdd: ClauseTemplate;
  adminClauseDelete: Scalars['String'];
  adminClauseEdit: ClauseTemplate;
  adminClauseSectionsEdit: Scalars['String'];
  adminClauseUpdate: ClauseTemplate;
  adminMakeAdmin: User;
  adminTaskCreate: AdminTask;
  adminTaskDelete: Scalars['String'];
  adminTaskUpdate: AdminTask;
  adminUserAccessTokenCreate: User;
  avatarCreate: S3Payload;
  avatarDelete: Scalars['String'];
  bulkUnitUpdate: Array<Unit>;
  completeDeal: Deal;
  dealAddActor: Deal;
  dealApprovalSend: DealApprovalSendResponse;
  dealBulkAddActors: Array<User>;
  dealBulkHide: User;
  dealBulkUnhide: User;
  dealClauseCreate: Clause;
  dealClauseDelete: DeletedObject;
  dealClauseUndo: Clause;
  dealClauseUpdate: Clause;
  dealCreate: Deal;
  dealDelete: DeletedDeal;
  dealGroupCreate: Group;
  dealGroupDelete: DeletedObject;
  dealGroupUpdate: Group;
  dealHide: User;
  dealInviteFromStage: DealInviteFromStageResponse;
  dealNudge: NudgeResponse;
  dealRemoveActor: Deal;
  dealRemoveTenant: Deal;
  dealSectionCreate: Section;
  dealSectionDelete: DeletedObject;
  dealSectionGroupClauseCreate: Clause;
  dealSectionUpdate: Section;
  dealSeen: DealSeenResponse;
  dealSend: Deal;
  dealTenantDetailsSet: TenantDetails;
  dealUndo: Deal;
  dealUnhide: User;
  dealVerify: Deal;
  docCreate: S3Payload;
  docDelete: Scalars['String'];
  downloadDealPack: Deal;
  downloadDoc: Array<File>;
  downloadHoTs: Deal;
  getReadinessPack: S3Payload;
  newPractice: Organisation;
  organisationClauseAdd: OrganisationClause;
  organisationClauseDelete: Scalars['String'];
  organisationClauseEdit: OrganisationClause;
  organisationClauseUpdate: OrganisationClause;
  organisationCreate: OrganisationResult;
  organisationDelete?: Maybe<Scalars['String']>;
  organisationMemberRemove: Organisation;
  organisationPendingMemberRemove: Organisation;
  organisationSectionsEdit: Scalars['String'];
  organisationUpdate: Organisation;
  processPracticeInvite: User;
  removeTrustedUser: Scalars['String'];
  templateCreate: Template;
  templateDelete: TemplateDelete;
  templateDuplicate: Template;
  templateHide: User;
  templateRestore: Template;
  templateUnhide: User;
  templateUpdate: Template;
  trustedUsersInvite: MultiInviteDetails;
  unitAddActor: Unit;
  unitBulkAddActors: Array<User>;
  unitBulkHide: User;
  unitBulkUnhide: User;
  unitCreate: Unit;
  unitDelete: UnitDeleteResponse;
  unitDuplicate: Unit;
  unitHide: User;
  unitOtherFile: File;
  unitRemoveActor: Unit;
  unitTaskAction: Task;
  unitTaskRemoveFile: Task;
  unitUnhide: User;
  unitUpdate: Unit;
  unitVerify: Unit;
  updateAgreementSeen: Deal;
  user2FA?: Maybe<User>;
  userAccessTokenCreate?: Maybe<User>;
  userAccessTokenDelete: Scalars['Boolean'];
  userActivate: User;
  userAddPinnedDeal: User;
  userCreate: User;
  userDelete?: Maybe<Scalars['String']>;
  userInvite: MultiInviteDetails;
  userInviteCreate: User;
  userNotificationDelete: Scalars['String'];
  userNotificationsDisable: DisabledNotifications;
  userReadNotification: Notification;
  userRemovePinnedDeal: User;
  userRequestResetPassword: Scalars['Boolean'];
  userResend2FACode?: Maybe<Scalars['String']>;
  userResendActivation: Scalars['String'];
  userResetPassword: User;
  userSubscribe: User;
  userUpdate: User;
};


export type MutationActivateOrganisationArgs = {
  input: OrganisationActivateInput;
};


export type MutationAdminClauseAddArgs = {
  input: ClauseTemplateInput;
};


export type MutationAdminClauseDeleteArgs = {
  id: Scalars['ID'];
};


export type MutationAdminClauseEditArgs = {
  id: Scalars['ID'];
  input: EditClauseTemplateInput;
};


export type MutationAdminClauseSectionsEditArgs = {
  deleteGroup?: Maybe<Scalars['Boolean']>;
  deleteSection?: Maybe<Scalars['Boolean']>;
  fromGroup?: Maybe<Scalars['String']>;
  fromSection: Scalars['String'];
  groupDescription?: Maybe<Scalars['String']>;
  groupRics?: Maybe<Scalars['String']>;
  sectionDescription?: Maybe<Scalars['String']>;
  sectionRics?: Maybe<Scalars['String']>;
  toGroup?: Maybe<Scalars['String']>;
  toSection?: Maybe<Scalars['String']>;
};


export type MutationAdminClauseUpdateArgs = {
  id: Scalars['ID'];
  updateDeals: Scalars['Boolean'];
  updateTemplates: Scalars['Boolean'];
};


export type MutationAdminMakeAdminArgs = {
  id: Scalars['ID'];
  password: Scalars['String'];
};


export type MutationAdminTaskCreateArgs = {
  input: AdminTaskCreateInput;
};


export type MutationAdminTaskDeleteArgs = {
  id: Scalars['ID'];
};


export type MutationAdminTaskUpdateArgs = {
  id: Scalars['ID'];
  input: AdminTaskUpdateInput;
};


export type MutationAdminUserAccessTokenCreateArgs = {
  id: Scalars['ID'];
  input: AdminUserAccessTokenCreateInput;
};


export type MutationAvatarCreateArgs = {
  filetype: Scalars['String'];
  id: Scalars['ID'];
  type: AvatarType;
};


export type MutationAvatarDeleteArgs = {
  id: Scalars['String'];
  type: AvatarType;
};


export type MutationBulkUnitUpdateArgs = {
  all?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<UnitFilter>;
  input: BulkUnitUpdateInput;
  unitIDs?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCompleteDealArgs = {
  id: Scalars['ID'];
};


export type MutationDealAddActorArgs = {
  dealID: Scalars['ID'];
  input: DealInviteInput;
};


export type MutationDealApprovalSendArgs = {
  dealID: Scalars['ID'];
  input: DealApprovalSendInput;
};


export type MutationDealBulkAddActorsArgs = {
  all?: Maybe<Scalars['Boolean']>;
  dealIDs?: Maybe<Array<Scalars['ID']>>;
  emails?: Maybe<Array<Scalars['String']>>;
  filter?: Maybe<DealFilter>;
};


export type MutationDealBulkHideArgs = {
  all?: Maybe<Scalars['Boolean']>;
  dealIDs?: Maybe<Array<Scalars['ID']>>;
  filter?: Maybe<DealFilter>;
  removeOnActive?: Maybe<Scalars['Boolean']>;
};


export type MutationDealBulkUnhideArgs = {
  all?: Maybe<Scalars['Boolean']>;
  dealIDs?: Maybe<Array<Scalars['ID']>>;
  filter?: Maybe<DealFilter>;
  unhideConnectedUnit?: Maybe<Scalars['Boolean']>;
};


export type MutationDealClauseCreateArgs = {
  input: DealClauseInput;
};


export type MutationDealClauseDeleteArgs = {
  clauseID: Scalars['ID'];
  dealID: Scalars['ID'];
  parentID: Scalars['ID'];
};


export type MutationDealClauseUndoArgs = {
  clauseID: Scalars['ID'];
  dealID: Scalars['ID'];
  parentID: Scalars['ID'];
};


export type MutationDealClauseUpdateArgs = {
  input: DealClauseUpdateInput;
};


export type MutationDealCreateArgs = {
  templateID?: Maybe<Scalars['ID']>;
  unitID: Scalars['ID'];
};


export type MutationDealDeleteArgs = {
  id: Scalars['ID'];
};


export type MutationDealGroupCreateArgs = {
  input: DealGroupInput;
};


export type MutationDealGroupDeleteArgs = {
  dealID: Scalars['ID'];
  groupID: Scalars['ID'];
  parentID: Scalars['ID'];
};


export type MutationDealGroupUpdateArgs = {
  input: DealGroupUpdateInput;
};


export type MutationDealHideArgs = {
  dealID: Scalars['ID'];
  removeOnActive?: Maybe<Scalars['Boolean']>;
};


export type MutationDealInviteFromStageArgs = {
  dealID: Scalars['ID'];
  email: Scalars['String'];
  type: InviteFromStageType;
};


export type MutationDealNudgeArgs = {
  dealID: Scalars['ID'];
  message: Scalars['String'];
};


export type MutationDealRemoveActorArgs = {
  dealID: Scalars['ID'];
  input: DealRemoveActorInput;
};


export type MutationDealRemoveTenantArgs = {
  dealID: Scalars['ID'];
  tenantID: Scalars['ID'];
};


export type MutationDealSectionCreateArgs = {
  input: DealSectionInput;
};


export type MutationDealSectionDeleteArgs = {
  dealID: Scalars['ID'];
  sectionID: Scalars['ID'];
};


export type MutationDealSectionGroupClauseCreateArgs = {
  input: SectionGroupClauseInput;
};


export type MutationDealSectionUpdateArgs = {
  input: DealSectionUpdateInput;
};


export type MutationDealSeenArgs = {
  dealID: Scalars['ID'];
};


export type MutationDealSendArgs = {
  dealID: Scalars['ID'];
  input: DealSendInput;
};


export type MutationDealTenantDetailsSetArgs = {
  aml?: Maybe<TenantStageAmlInput>;
  dealID: Scalars['ID'];
  final?: Maybe<TenantStageFinalInput>;
  five?: Maybe<TenantStage5Input>;
  four?: Maybe<TenantStage4Input>;
  one?: Maybe<TenantStage1Input>;
  six?: Maybe<TenantStage6Input>;
  team?: Maybe<TenantStageTeamInput>;
  three?: Maybe<TenantStage3Input>;
  two?: Maybe<TenantStage2Input>;
};


export type MutationDealUndoArgs = {
  dealID: Scalars['ID'];
};


export type MutationDealUnhideArgs = {
  dealID: Scalars['ID'];
  unhideConnectedUnit?: Maybe<Scalars['Boolean']>;
};


export type MutationDealVerifyArgs = {
  token: Scalars['String'];
};


export type MutationDocCreateArgs = {
  filename: Scalars['String'];
  filetype: Scalars['String'];
  frontend?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  taskID?: Maybe<Scalars['ID']>;
  type: DocType;
};


export type MutationDocDeleteArgs = {
  id: Scalars['ID'];
  type: DocType;
  unitID?: Maybe<Scalars['ID']>;
};


export type MutationDownloadDealPackArgs = {
  dealID: Scalars['ID'];
};


export type MutationDownloadDocArgs = {
  ids: Array<Scalars['ID']>;
};


export type MutationDownloadHoTsArgs = {
  dealID: Scalars['ID'];
};


export type MutationGetReadinessPackArgs = {
  dealID: Scalars['ID'];
  shouldLog?: Maybe<Scalars['Boolean']>;
};


export type MutationNewPracticeArgs = {
  id: Scalars['ID'];
  name: Scalars['String'];
};


export type MutationOrganisationClauseAddArgs = {
  input: ClauseTemplateInput;
  organisationID: Scalars['ID'];
};


export type MutationOrganisationClauseDeleteArgs = {
  _id: Scalars['ID'];
  organisationID: Scalars['ID'];
};


export type MutationOrganisationClauseEditArgs = {
  _id: Scalars['ID'];
  input: EditClauseTemplateInput;
  organisationID: Scalars['ID'];
};


export type MutationOrganisationClauseUpdateArgs = {
  id: Scalars['ID'];
  organisationID: Scalars['ID'];
  updateDeals: Scalars['Boolean'];
  updateTemplates: Scalars['Boolean'];
};


export type MutationOrganisationCreateArgs = {
  input: CreateOrganisationEmailsInput;
};


export type MutationOrganisationDeleteArgs = {
  id?: Maybe<Scalars['ID']>;
  input?: Maybe<AdminOrganisationDeleteInput>;
};


export type MutationOrganisationMemberRemoveArgs = {
  organisationID: Scalars['ID'];
  userID: Scalars['ID'];
};


export type MutationOrganisationPendingMemberRemoveArgs = {
  email: Scalars['String'];
  organisationID: Scalars['ID'];
};


export type MutationOrganisationSectionsEditArgs = {
  deleteGroup?: Maybe<Scalars['Boolean']>;
  deleteSection?: Maybe<Scalars['Boolean']>;
  fromGroup?: Maybe<Scalars['String']>;
  fromSection: Scalars['String'];
  groupDescription?: Maybe<Scalars['String']>;
  groupRics?: Maybe<Scalars['String']>;
  organisationID: Scalars['ID'];
  sectionDescription?: Maybe<Scalars['String']>;
  sectionRics?: Maybe<Scalars['String']>;
  toGroup?: Maybe<Scalars['String']>;
  toSection?: Maybe<Scalars['String']>;
};


export type MutationOrganisationUpdateArgs = {
  id: Scalars['ID'];
  input: OrganisationUpdateInput;
};


export type MutationProcessPracticeInviteArgs = {
  inviteID: Scalars['ID'];
  response: Scalars['String'];
};


export type MutationRemoveTrustedUserArgs = {
  organisationID: Scalars['ID'];
  userID: Scalars['ID'];
};


export type MutationTemplateCreateArgs = {
  input: TemplateInput;
  organisationID?: Maybe<Scalars['ID']>;
};


export type MutationTemplateDeleteArgs = {
  id: Scalars['ID'];
  permanent: Scalars['Boolean'];
};


export type MutationTemplateDuplicateArgs = {
  associatedUses?: Maybe<Array<UnitUse>>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  organisation: Scalars['String'];
};


export type MutationTemplateHideArgs = {
  templateID: Scalars['ID'];
};


export type MutationTemplateRestoreArgs = {
  id: Scalars['ID'];
};


export type MutationTemplateUnhideArgs = {
  templateID: Scalars['ID'];
};


export type MutationTemplateUpdateArgs = {
  id: Scalars['ID'];
  input: TemplateInput;
};


export type MutationTrustedUsersInviteArgs = {
  input: TrustedUsersInviteInput;
  organisationID: Scalars['ID'];
};


export type MutationUnitAddActorArgs = {
  input: UnitInviteInput;
  unitID: Scalars['ID'];
};


export type MutationUnitBulkAddActorsArgs = {
  all?: Maybe<Scalars['Boolean']>;
  dealIDs?: Maybe<Array<Scalars['ID']>>;
  emails?: Maybe<Array<Scalars['String']>>;
  filter?: Maybe<UnitFilter>;
};


export type MutationUnitBulkHideArgs = {
  all?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<UnitFilter>;
  hideConnectedDeals?: Maybe<Scalars['Boolean']>;
  showHiddenDealsOnActive?: Maybe<Scalars['Boolean']>;
  unitIDs?: Maybe<Array<Scalars['ID']>>;
};


export type MutationUnitBulkUnhideArgs = {
  all?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<UnitFilter>;
  unhideConnectedDeals?: Maybe<Scalars['Boolean']>;
  unitIDs?: Maybe<Array<Scalars['ID']>>;
};


export type MutationUnitCreateArgs = {
  emails?: Maybe<Array<Scalars['String']>>;
  input: UnitCreateInput;
};


export type MutationUnitDeleteArgs = {
  unitID: Scalars['ID'];
};


export type MutationUnitDuplicateArgs = {
  area?: Maybe<Scalars['Int']>;
  buildingName?: Maybe<Scalars['String']>;
  condition?: Maybe<UnitCondition>;
  emails?: Maybe<Array<Scalars['String']>>;
  name?: Maybe<Scalars['String']>;
  organisation: Scalars['ID'];
  unitID: Scalars['ID'];
  use?: Maybe<UnitUse>;
};


export type MutationUnitHideArgs = {
  hideConnectedDeals?: Maybe<Scalars['Boolean']>;
  showHiddenDealsOnActive?: Maybe<Scalars['Boolean']>;
  unitID: Scalars['ID'];
};


export type MutationUnitOtherFileArgs = {
  name: Scalars['String'];
  unitID: Scalars['ID'];
  url: Scalars['String'];
};


export type MutationUnitRemoveActorArgs = {
  unitID: Scalars['ID'];
  userID: Scalars['ID'];
};


export type MutationUnitTaskActionArgs = {
  input: UnitTaskActionInput;
  unitID: Scalars['ID'];
};


export type MutationUnitTaskRemoveFileArgs = {
  fileID: Scalars['ID'];
  taskID: Scalars['ID'];
  unitID: Scalars['ID'];
};


export type MutationUnitUnhideArgs = {
  unhideConnectedDeals?: Maybe<Scalars['Boolean']>;
  unitID: Scalars['ID'];
};


export type MutationUnitUpdateArgs = {
  input: UnitUpdateInput;
  unitID: Scalars['ID'];
};


export type MutationUnitVerifyArgs = {
  token: Scalars['String'];
};


export type MutationUpdateAgreementSeenArgs = {
  currentUserParty: DealUserParty;
  id: Scalars['ID'];
};


export type MutationUser2FaArgs = {
  email: Scalars['String'];
  token: Scalars['String'];
};


export type MutationUserAccessTokenCreateArgs = {
  input: UserCreateAccessTokenInput;
};


export type MutationUserActivateArgs = {
  userActivationToken: Scalars['String'];
};


export type MutationUserAddPinnedDealArgs = {
  dealID: Scalars['ID'];
};


export type MutationUserCreateArgs = {
  input: UserCreateInput;
};


export type MutationUserDeleteArgs = {
  id?: Maybe<Scalars['ID']>;
  input?: Maybe<AdminUserDeleteInput>;
};


export type MutationUserInviteArgs = {
  input: UserInviteInput;
  organisationID: Scalars['ID'];
  resend?: Maybe<Scalars['Boolean']>;
};


export type MutationUserInviteCreateArgs = {
  input: UserInviteCreateInput;
  token: Scalars['String'];
};


export type MutationUserNotificationDeleteArgs = {
  notificationID: Scalars['ID'];
};


export type MutationUserNotificationsDisableArgs = {
  input: DisabledNotificationsInput;
};


export type MutationUserReadNotificationArgs = {
  id: Scalars['ID'];
};


export type MutationUserRemovePinnedDealArgs = {
  dealID: Scalars['ID'];
};


export type MutationUserRequestResetPasswordArgs = {
  email: Scalars['String'];
};


export type MutationUserResend2FaCodeArgs = {
  email: Scalars['String'];
};


export type MutationUserResendActivationArgs = {
  email: Scalars['String'];
};


export type MutationUserResetPasswordArgs = {
  input: UserResetPasswordInput;
};


export type MutationUserSubscribeArgs = {
  endpoint: Scalars['String'];
};


export type MutationUserUpdateArgs = {
  id?: Maybe<Scalars['ID']>;
  input: UserUpdateInput;
};

export type Notification = {
  __typename?: 'Notification';
  /** Globally unique identifier */
  _id: Scalars['ID'];
  /** Date the object was created */
  created?: Maybe<Scalars['DateTime']>;
  read: Scalars['Boolean'];
  sent: Scalars['DateTime'];
  text?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  type: NotificationType;
  url: Scalars['String'];
};

export enum NotificationType {
  Deal = 'DEAL',
  Invite = 'INVITE',
  Unit = 'UNIT'
}

export type NotificationsResponse = {
  __typename?: 'NotificationsResponse';
  notifications: Array<Notification>;
  pages: Scalars['Int'];
  total: Scalars['Int'];
};

export type NudgeResponse = {
  __typename?: 'NudgeResponse';
  dealID: Scalars['ID'];
};

/** Order in acsending/descending */
export enum OrderBy {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type Organisation = {
  __typename?: 'Organisation';
  /** Globally unique identifier */
  _id: Scalars['ID'];
  active?: Maybe<Scalars['Boolean']>;
  address: AddressType;
  /** Organisation avatar URL */
  avatar?: Maybe<Scalars['String']>;
  /** Organisation certification */
  certification?: Maybe<OrganisationCertification>;
  clauses?: Maybe<Array<OrganisationClause>>;
  /** Date the object was created */
  created?: Maybe<Scalars['DateTime']>;
  /** All of an organisation's deals */
  deals: Array<Deal>;
  /** All of an organisation's members */
  members: Array<User>;
  /** All of an organisation's members */
  membersSize: Scalars['Int'];
  /** Organisation name */
  name: Scalars['String'];
  pendingMembers?: Maybe<Array<Scalars['String']>>;
  practiceType?: Maybe<PracticeType>;
  straightToLease?: Maybe<StraightToLease>;
  /** All of an organisation's templates */
  templates: Array<Template>;
  /** All of an organisation's trusted users */
  trustedUsers: Array<User>;
  type?: Maybe<UserType>;
  /** All of an organisation's units */
  units: Array<Unit>;
  /** Organisation website */
  website?: Maybe<Scalars['String']>;
};


export type OrganisationMembersArgs = {
  filter?: Maybe<UserFilter>;
  query?: Maybe<Scalars['String']>;
  sort?: Maybe<UserSort>;
};


export type OrganisationTrustedUsersArgs = {
  filter?: Maybe<UserFilter>;
  sort?: Maybe<UserSort>;
};

export type OrganisationActivateInput = {
  email?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  organisationID: Scalars['ID'];
  sendInvite: Scalars['Boolean'];
};

/** Available organisation certification types */
export enum OrganisationCertification {
  Certified = 'CERTIFIED',
  Gold = 'GOLD',
  Platinum = 'PLATINUM',
  Silver = 'SILVER'
}

/** User's disabled notifications */
export type OrganisationClause = {
  __typename?: 'OrganisationClause';
  /** Globally unique identifier */
  _id: Scalars['ID'];
  associatedUses?: Maybe<Array<UnitUse>>;
  linked?: Maybe<OrganisationLinked>;
  longForm?: Maybe<Scalars['String']>;
  section: OrganisationSection;
  shortForm?: Maybe<Scalars['String']>;
  /** Date the object was updated */
  updated?: Maybe<Scalars['DateTime']>;
  /** Date when deal clauses were updated */
  updatedDealsAt?: Maybe<Scalars['DateTime']>;
  /** Date template clauses were updated */
  updatedTemplatesAt?: Maybe<Scalars['DateTime']>;
  value?: Maybe<Scalars['String']>;
  valueSuffix?: Maybe<Scalars['String']>;
};

export type OrganisationFilter = {
  createdFrom?: Maybe<Scalars['DateTime']>;
  createdTo?: Maybe<Scalars['DateTime']>;
  memberFrom?: Maybe<Scalars['Int']>;
  memberTo?: Maybe<Scalars['Int']>;
  type?: Maybe<Array<Scalars['String']>>;
};

export type OrganisationFilterCount = {
  __typename?: 'OrganisationFilterCount';
  agent: Scalars['Float'];
  all: Scalars['Float'];
  landlord: Scalars['Float'];
  tenant: Scalars['Float'];
};

export type OrganisationGroup = {
  __typename?: 'OrganisationGroup';
  /** Globally unique identifier */
  _id: Scalars['ID'];
  description?: Maybe<Scalars['String']>;
  group?: Maybe<OrganisationSubGroup>;
  rics?: Maybe<Scalars['String']>;
  title: Scalars['String'];
};

export type OrganisationLinked = {
  __typename?: 'OrganisationLinked';
  results?: Maybe<Array<OrganisationClause>>;
};

export type OrganisationResult = {
  __typename?: 'OrganisationResult';
  errors: Array<Scalars['String']>;
  organisation?: Maybe<Organisation>;
};

/** Organisation's sections */
export type OrganisationSection = {
  __typename?: 'OrganisationSection';
  /** Globally unique identifier */
  _id: Scalars['ID'];
  description?: Maybe<Scalars['String']>;
  group: OrganisationGroup;
  rics?: Maybe<Scalars['String']>;
  title: Scalars['String'];
};

export type OrganisationSort = {
  field: OrganisationSortFields;
  order?: Maybe<OrderBy>;
};

/** Sort the organisations by particular fields */
export enum OrganisationSortFields {
  CompanyCreatedDate = 'COMPANY_CREATED_DATE',
  CompanyName = 'COMPANY_NAME',
  NumberOfUsers = 'NUMBER_OF_USERS'
}

export type OrganisationSubGroup = {
  __typename?: 'OrganisationSubGroup';
  /** Globally unique identifier */
  _id: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
};

export type OrganisationUpdateInput = {
  address?: Maybe<AddressCreateInput>;
  avatar?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  practiceType?: Maybe<PracticeType>;
  straightToLease?: Maybe<StraightToLeaseInput>;
  website?: Maybe<Scalars['String']>;
};

export type Organisations = {
  __typename?: 'Organisations';
  pageInfo: PageInfo;
  results: Array<Organisation>;
};

export type PageInfo = {
  __typename?: 'PageInfo';
  hasNextPage: Scalars['Boolean'];
  hasResults: Scalars['Boolean'];
  total?: Maybe<Scalars['Float']>;
};

/** User's practice invites */
export type PracticeInvite = {
  __typename?: 'PracticeInvite';
  /** Globally unique identifier */
  _id: Scalars['ID'];
  /** Date the object was created */
  created?: Maybe<Scalars['DateTime']>;
  organisation: Organisation;
  seen: Scalars['Boolean'];
};

/** Available practice types */
export enum PracticeType {
  Agency = 'AGENCY',
  Legal = 'LEGAL',
  PropertyManagement = 'PROPERTY_MANAGEMENT'
}

export type Query = {
  __typename?: 'Query';
  checkEmail: Scalars['Boolean'];
  clauseBank: Array<ClauseTemplate>;
  clauseSections: Array<ClauseTemplateSection>;
  deal: Deal;
  dealFilterCounts: DealFilterCount;
  deals: Deals;
  exportDeals: S3Payload;
  exportUnits: S3Payload;
  invite: InviteDetails;
  notifications: NotificationsResponse;
  organisation: Organisation;
  organisationFilterCounts: OrganisationFilterCount;
  organisations: Organisations;
  tasks: Array<AdminTask>;
  template: Template;
  templateFilterCounts: TemplateFilterCount;
  /** Get all templates in user's organisations or shared directly with the user */
  templates: Templates;
  unit: Unit;
  unitFilterCounts: UnitFilterCount;
  /** Get all units in user's organisations or shared directly with the user */
  units: Units;
  user: User;
  userFilterCounts: UserFilterCount;
  users: Array<User>;
};


export type QueryCheckEmailArgs = {
  dealID: Scalars['String'];
  email: Scalars['String'];
  type: UserType;
};


export type QueryClauseBankArgs = {
  filter?: Maybe<TemplateFilter>;
  only?: Maybe<Scalars['Boolean']>;
  options?: Maybe<ClauseBankOptions>;
  organisationID?: Maybe<Array<Scalars['ID']>>;
  query?: Maybe<Scalars['String']>;
};


export type QueryClauseSectionsArgs = {
  organisationID: Array<Scalars['ID']>;
};


export type QueryDealArgs = {
  id?: Maybe<Scalars['ID']>;
  token?: Maybe<Scalars['String']>;
};


export type QueryDealFilterCountsArgs = {
  filter?: Maybe<DealFilter>;
  lean?: Maybe<Scalars['Boolean']>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  query?: Maybe<Scalars['String']>;
  sort?: Maybe<DealSort>;
};


export type QueryDealsArgs = {
  filter?: Maybe<DealFilter>;
  lean?: Maybe<Scalars['Boolean']>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  query?: Maybe<Scalars['String']>;
  sort?: Maybe<DealSort>;
};


export type QueryInviteArgs = {
  token: Scalars['String'];
};


export type QueryNotificationsArgs = {
  compact?: Maybe<Scalars['Boolean']>;
  page?: Maybe<Scalars['Int']>;
};


export type QueryOrganisationArgs = {
  id: Scalars['ID'];
};


export type QueryOrganisationFilterCountsArgs = {
  filter?: Maybe<OrganisationFilter>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  query?: Maybe<Scalars['String']>;
  sort?: Maybe<OrganisationSort>;
};


export type QueryOrganisationsArgs = {
  filter?: Maybe<OrganisationFilter>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  query?: Maybe<Scalars['String']>;
  sort?: Maybe<OrganisationSort>;
};


export type QueryTemplateArgs = {
  id: Scalars['ID'];
};


export type QueryTemplateFilterCountsArgs = {
  filter?: Maybe<TemplateFilter>;
  query?: Maybe<Scalars['String']>;
  sort?: Maybe<TemplateSort>;
};


export type QueryTemplatesArgs = {
  filter?: Maybe<TemplateFilter>;
  query?: Maybe<Scalars['String']>;
  sort?: Maybe<TemplateSort>;
};


export type QueryUnitArgs = {
  id: Scalars['ID'];
};


export type QueryUnitFilterCountsArgs = {
  filter?: Maybe<UnitFilter>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  query?: Maybe<Scalars['String']>;
  sort?: Maybe<UnitSort>;
};


export type QueryUnitsArgs = {
  filter?: Maybe<UnitFilter>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  query?: Maybe<Scalars['String']>;
  sort?: Maybe<UnitSort>;
};


export type QueryUserFilterCountsArgs = {
  filter?: Maybe<UserFilter>;
  query?: Maybe<Scalars['String']>;
  sort?: Maybe<UserSort>;
};


export type QueryUsersArgs = {
  filter?: Maybe<UserFilter>;
  query?: Maybe<Scalars['String']>;
  sort?: Maybe<UserSort>;
};

export type S3Payload = {
  __typename?: 'S3Payload';
  fileName: Scalars['String'];
  signedUrl: Scalars['String'];
  url: Scalars['String'];
};

export type Section = {
  __typename?: 'Section';
  /** Globally unique identifier */
  _id: Scalars['ID'];
  clauseSummary: ClauseSummary;
  /** Date the object was created */
  created?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  groups: Array<Group>;
  /** @deprecated Order is now maintained in the database rather than on the client side */
  index: Scalars['Float'];
  rics?: Maybe<Scalars['String']>;
  title: Scalars['String'];
};

export type SectionGroupClauseInput = {
  clause: DealClausePartialInput;
  clauseGroup?: Maybe<DealSectionGroupPartialInput>;
  dealID: Scalars['ID'];
  group: DealSectionGroupPartialInput;
  section: DealSectionGroupPartialInput;
};

export type StraightToLease = {
  __typename?: 'StraightToLease';
  end?: Maybe<Scalars['String']>;
  start?: Maybe<Scalars['String']>;
};

export type StraightToLeaseInput = {
  end?: Maybe<Scalars['String']>;
  start?: Maybe<Scalars['String']>;
};

export type StringValue = {
  __typename?: 'StringValue';
  stringValue: Scalars['String'];
};

export type Task = {
  __typename?: 'Task';
  /** Globally unique identifier */
  _id: Scalars['ID'];
  applicable: Scalars['Boolean'];
  /** Date the object was created */
  created?: Maybe<Scalars['DateTime']>;
  edited?: Maybe<Scalars['DateTime']>;
  task: AdminTask;
  value?: Maybe<UnitValue>;
};

export type Template = {
  __typename?: 'Template';
  /** Globally unique identifier */
  _id: Scalars['ID'];
  /** Users with access to template */
  actors: Array<User>;
  associatedUses?: Maybe<Array<UnitUse>>;
  created: Scalars['DateTime'];
  /** User who created the template */
  creator: User;
  /** Template's deal */
  deal: Deal;
  description?: Maybe<Scalars['String']>;
  /** Is the template globally available? */
  global: Scalars['Boolean'];
  hasAccess: Scalars['Boolean'];
  name?: Maybe<Scalars['String']>;
  /** Template's organisation */
  organisation: Organisation;
};

export type TemplateDelete = {
  __typename?: 'TemplateDelete';
  deletedTemplateID: Scalars['ID'];
};

export type TemplateFilter = {
  creator?: Maybe<Array<Scalars['String']>>;
  organisation?: Maybe<Array<Scalars['String']>>;
  status?: Maybe<Array<TemplateStatusFilter>>;
  type?: Maybe<Array<TemplateTypeFilter>>;
  use?: Maybe<UnitUse>;
  uses?: Maybe<Array<UnitUse>>;
};

export type TemplateFilterCount = {
  __typename?: 'TemplateFilterCount';
  bin: Scalars['Float'];
  hidden: Scalars['Float'];
  live: Scalars['Float'];
};

export type TemplateInput = {
  associatedUses?: Maybe<Array<UnitUse>>;
  description?: Maybe<Scalars['String']>;
  global?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
};

export type TemplateSort = {
  field: TemplateSortFields;
  order?: Maybe<OrderBy>;
};

/** Sort templates by particular fields */
export enum TemplateSortFields {
  Created = 'CREATED',
  Name = 'NAME'
}

/** Filter the template status */
export enum TemplateStatusFilter {
  Bin = 'BIN',
  Hidden = 'HIDDEN',
  Live = 'LIVE'
}

/** Filter the template type */
export enum TemplateTypeFilter {
  Custom = 'CUSTOM',
  Global = 'GLOBAL'
}

export type Templates = {
  __typename?: 'Templates';
  pageInfo: PageInfo;
  results: Array<Template>;
};

export type TenantCompany = {
  __typename?: 'TenantCompany';
  contactName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  type?: Maybe<TenantCompanyType>;
};

export type TenantCompanyInput = {
  contactName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  type?: Maybe<TenantCompanyType>;
};

export enum TenantCompanyType {
  Ac = 'AC',
  Db = 'DB'
}

export type TenantDetails = {
  __typename?: 'TenantDetails';
  aml?: Maybe<TenantStageAml>;
  final?: Maybe<TenantStageFinal>;
  five?: Maybe<TenantStage5>;
  four?: Maybe<TenantStage4>;
  one?: Maybe<TenantStage1>;
  six?: Maybe<TenantStage6>;
  team?: Maybe<TenantStageTeam>;
  three?: Maybe<TenantStage3>;
  two?: Maybe<TenantStage2>;
};

export type TenantEntity = {
  __typename?: 'TenantEntity';
  address?: Maybe<TenantEntityAddress>;
  company?: Maybe<Scalars['String']>;
  companyNumber?: Maybe<Scalars['String']>;
  guarantor?: Maybe<Scalars['String']>;
  jurisdiction?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  visible?: Maybe<Scalars['Boolean']>;
};

export type TenantEntityAddress = {
  __typename?: 'TenantEntityAddress';
  city?: Maybe<Scalars['String']>;
  line1?: Maybe<Scalars['String']>;
  line2?: Maybe<Scalars['String']>;
  postCode?: Maybe<Scalars['String']>;
};

export type TenantEntityAddressInput = {
  city?: Maybe<Scalars['String']>;
  line1?: Maybe<Scalars['String']>;
  line2?: Maybe<Scalars['String']>;
  postCode?: Maybe<Scalars['String']>;
};

export type TenantEntityInput = {
  address?: Maybe<TenantEntityAddressInput>;
  company?: Maybe<Scalars['String']>;
  companyNumber?: Maybe<Scalars['String']>;
  guarantor?: Maybe<Scalars['String']>;
  jurisdiction?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  visible?: Maybe<Scalars['Boolean']>;
};

export type TenantPerson = {
  __typename?: 'TenantPerson';
  company?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  invite?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  visible?: Maybe<Scalars['Boolean']>;
};

export type TenantPersonInput = {
  company?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  invite?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  visible?: Maybe<Scalars['Boolean']>;
};

export type TenantStage1 = {
  __typename?: 'TenantStage1';
  building?: Maybe<TenantEntity>;
  completed?: Maybe<Scalars['Boolean']>;
  edited?: Maybe<Scalars['DateTime']>;
  entity?: Maybe<TenantEntity>;
  isSameAddress?: Maybe<Scalars['Boolean']>;
};

export type TenantStage1Input = {
  building?: Maybe<TenantEntityInput>;
  completed?: Maybe<Scalars['Boolean']>;
  entity?: Maybe<TenantEntityInput>;
  isSameAddress?: Maybe<Scalars['Boolean']>;
};

export type TenantStage2 = {
  __typename?: 'TenantStage2';
  agent?: Maybe<TenantPerson>;
  completed?: Maybe<Scalars['Boolean']>;
  edited?: Maybe<Scalars['DateTime']>;
  lawyer?: Maybe<TenantPerson>;
};

export type TenantStage2Input = {
  agent?: Maybe<TenantPersonInput>;
  completed?: Maybe<Scalars['Boolean']>;
  lawyer?: Maybe<TenantPersonInput>;
};

export type TenantStage3 = {
  __typename?: 'TenantStage3';
  audited?: Maybe<Scalars['Boolean']>;
  completed?: Maybe<Scalars['Boolean']>;
  edited?: Maybe<Scalars['DateTime']>;
  files: Array<File>;
  guarantor?: Maybe<TenantEntity>;
  noAccountsToShare?: Maybe<Scalars['Boolean']>;
  nominateGuarantor?: Maybe<Scalars['Boolean']>;
};

export type TenantStage3Input = {
  audited?: Maybe<Scalars['Boolean']>;
  completed?: Maybe<Scalars['Boolean']>;
  file?: Maybe<FileInput>;
  guarantor?: Maybe<TenantEntityInput>;
  noAccountsToShare?: Maybe<Scalars['Boolean']>;
  nominateGuarantor?: Maybe<Scalars['Boolean']>;
};

export type TenantStage4 = {
  __typename?: 'TenantStage4';
  completed?: Maybe<Scalars['Boolean']>;
  edited?: Maybe<Scalars['DateTime']>;
  files: Array<File>;
  kyc?: Maybe<Scalars['Boolean']>;
  noLawyer?: Maybe<Scalars['Boolean']>;
};

export type TenantStage4Input = {
  completed?: Maybe<Scalars['Boolean']>;
  file?: Maybe<FileInput>;
  kyc?: Maybe<Scalars['Boolean']>;
  noLawyer?: Maybe<Scalars['Boolean']>;
};

export type TenantStage5 = {
  __typename?: 'TenantStage5';
  completed?: Maybe<Scalars['Boolean']>;
  edited?: Maybe<Scalars['DateTime']>;
  instructions?: Maybe<Scalars['Boolean']>;
  noLawyer?: Maybe<Scalars['Boolean']>;
  signed?: Maybe<Scalars['Boolean']>;
};

export type TenantStage5Input = {
  completed?: Maybe<Scalars['Boolean']>;
  instructions?: Maybe<Scalars['Boolean']>;
  noLawyer?: Maybe<Scalars['Boolean']>;
  signed?: Maybe<Scalars['Boolean']>;
};

export type TenantStage6 = {
  __typename?: 'TenantStage6';
  company?: Maybe<TenantCompany>;
  completed?: Maybe<Scalars['Boolean']>;
  edited?: Maybe<Scalars['DateTime']>;
  fitted?: Maybe<Scalars['Boolean']>;
  projectManager?: Maybe<TenantPerson>;
};

export type TenantStage6Input = {
  company?: Maybe<TenantCompanyInput>;
  completed?: Maybe<Scalars['Boolean']>;
  fitted?: Maybe<Scalars['Boolean']>;
  projectManager?: Maybe<TenantPersonInput>;
};

export type TenantStageAml = {
  __typename?: 'TenantStageAML';
  companyJurisdiction?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  companyRegistrationNumber?: Maybe<Scalars['String']>;
  completed?: Maybe<Scalars['Boolean']>;
  edited?: Maybe<Scalars['DateTime']>;
  personalDetails?: Maybe<Array<TenantPerson>>;
};

export type TenantStageAmlInput = {
  companyJurisdiction?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  companyRegistrationNumber?: Maybe<Scalars['String']>;
  completed?: Maybe<Scalars['Boolean']>;
  personalDetails?: Maybe<Array<TenantPersonInput>>;
};

export type TenantStageFinal = {
  __typename?: 'TenantStageFinal';
  completed?: Maybe<Scalars['Boolean']>;
  edited?: Maybe<Scalars['DateTime']>;
  informLandlord?: Maybe<Scalars['Boolean']>;
};

export type TenantStageFinalInput = {
  completed?: Maybe<Scalars['Boolean']>;
  informLandlord?: Maybe<Scalars['Boolean']>;
};

export type TenantStageTeam = {
  __typename?: 'TenantStageTeam';
  agent?: Maybe<TenantPerson>;
  completed?: Maybe<Scalars['Boolean']>;
  edited?: Maybe<Scalars['DateTime']>;
  tenant?: Maybe<TenantPerson>;
};

export type TenantStageTeamInput = {
  agent?: Maybe<TenantPersonInput>;
  completed?: Maybe<Scalars['Boolean']>;
  tenant?: Maybe<TenantPersonInput>;
};

export type TrustedUsersInviteInput = {
  /** Trusted user's emails */
  emails: Array<Scalars['String']>;
};

export type Unit = {
  __typename?: 'Unit';
  /** Globally unique identifier */
  _id: Scalars['ID'];
  /** Users with access to unit */
  actors: Array<User>;
  address: AddressType;
  area: Scalars['Int'];
  available?: Maybe<UnitAvailability>;
  avatar?: Maybe<Scalars['String']>;
  buildingName: Scalars['String'];
  condition: UnitCondition;
  /** Date the object was created */
  created?: Maybe<Scalars['DateTime']>;
  /** Unit's creator */
  creator?: Maybe<User>;
  /** Deals on this unit */
  deals: Array<Deal>;
  /** All files on a unit */
  files: Array<File>;
  hasHiddenDeals?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  /** Unit's organisation */
  organisation: Organisation;
  /** Is the unit pending verification? */
  pending: Scalars['Boolean'];
  /** Unit rating */
  rating: Scalars['Int'];
  straightToLease: Scalars['Boolean'];
  tasks: Array<Task>;
  url?: Maybe<Scalars['String']>;
  use: UnitUse;
  usedUseCaseTask?: Maybe<Scalars['Boolean']>;
};

/** Filter the units by if they are available to let or unavailable to let */
export enum UnitAvailability {
  AvailableToLet = 'AVAILABLE_TO_LET',
  UnavailableToLet = 'UNAVAILABLE_TO_LET'
}

/** Condition type for a unit */
export enum UnitCondition {
  CategoryA = 'CATEGORY_A',
  CategoryAplus = 'CATEGORY_APLUS',
  CategoryB = 'CATEGORY_B',
  Development = 'DEVELOPMENT',
  ExistingFitOut = 'EXISTING_FIT_OUT',
  Flex = 'FLEX',
  PlugAndPlay = 'PLUG_AND_PLAY',
  Ready = 'READY',
  ShellAndCore = 'SHELL_AND_CORE',
  WarmShell = 'WARM_SHELL',
  WhiteBox = 'WHITE_BOX'
}

/** Creates a new unit */
export type UnitCreateInput = {
  /** Unit's address details */
  address: AddressCreateInput;
  /** Unit's area */
  area: Scalars['Int'];
  /** If unit is available to let */
  available?: Maybe<UnitAvailability>;
  avatar?: Maybe<Scalars['String']>;
  /** Unit's building name. 1-100 characters */
  buildingName: Scalars['String'];
  /** Condition type for unit */
  condition: UnitCondition;
  /** Unit's name. 1-100 characters */
  name: Scalars['String'];
  organisation: Scalars['String'];
  /** If unit has straight to lease messaging enabled. (Defaults to true) */
  straightToLease?: Maybe<Scalars['Boolean']>;
  /** Unit's URL (optional) */
  url?: Maybe<Scalars['String']>;
  /** Use type for unit */
  use: UnitUse;
};

export type UnitDeleteResponse = {
  __typename?: 'UnitDeleteResponse';
  deletedUnitID: Scalars['String'];
};

export type UnitFilter = {
  areaFrom?: Maybe<Scalars['Int']>;
  areaTo?: Maybe<Scalars['Int']>;
  available?: Maybe<UnitAvailability>;
  buildingName?: Maybe<Array<Scalars['String']>>;
  city?: Maybe<Array<Scalars['String']>>;
  client?: Maybe<Array<Scalars['String']>>;
  condition?: Maybe<Array<UnitCondition>>;
  createdFrom?: Maybe<Scalars['DateTime']>;
  createdTo?: Maybe<Scalars['DateTime']>;
  creator?: Maybe<Array<Scalars['String']>>;
  status?: Maybe<Array<UnitStatusFilter>>;
  use?: Maybe<Array<UnitUse>>;
};

export type UnitFilterCount = {
  __typename?: 'UnitFilterCount';
  availableToLet: Scalars['Float'];
  hidden: Scalars['Float'];
  live: Scalars['Float'];
  unavailableToLet: Scalars['Float'];
};

export type UnitInviteInput = {
  /** Invite user as member of the organisation */
  asMember?: Maybe<Scalars['Boolean']>;
  /** User's email */
  emails: Array<Scalars['String']>;
  /** New user's type */
  type: UserType;
};

export type UnitSort = {
  field: UnitSortFields;
  order?: Maybe<OrderBy>;
};

/** Sort units by particular fields */
export enum UnitSortFields {
  AddressLineOne = 'ADDRESS_LINE_ONE',
  BuildingName = 'BUILDING_NAME',
  Client = 'CLIENT',
  Created = 'CREATED'
}

/** Filter the units by if they are active or hidden */
export enum UnitStatusFilter {
  Hidden = 'HIDDEN',
  Live = 'LIVE'
}

export type UnitTaskActionInput = {
  applicable?: Maybe<Scalars['Boolean']>;
  booleanValue?: Maybe<Scalars['Boolean']>;
  fileValue?: Maybe<Array<FileInput>>;
  floatValue?: Maybe<Scalars['Float']>;
  stringValue?: Maybe<Scalars['String']>;
  taskID: Scalars['ID'];
};

export type UnitUpdateInput = {
  /** Unit's address details */
  address?: Maybe<AddressCreateInput>;
  /** Unit's area */
  area?: Maybe<Scalars['Int']>;
  /** If unit is available to let */
  available?: Maybe<UnitAvailability>;
  avatar?: Maybe<Scalars['String']>;
  /** Unit's building name. 1-100 characters */
  buildingName?: Maybe<Scalars['String']>;
  /** Condition type for unit */
  condition?: Maybe<UnitCondition>;
  /** Unit's name. 1-100 characters */
  name?: Maybe<Scalars['String']>;
  /** If unit has straight to lease messaging enabled. (Defaults to true) */
  straightToLease?: Maybe<Scalars['Boolean']>;
  /** Unit's URL (optional) */
  url?: Maybe<Scalars['String']>;
};

/** Use type for a unit */
export enum UnitUse {
  Arch = 'ARCH',
  Fb = 'FB',
  Industrial = 'INDUSTRIAL',
  Managed = 'MANAGED',
  Medical = 'MEDICAL',
  Office = 'OFFICE',
  OfficeFlex = 'OFFICE_FLEX',
  RetailHighStreet = 'RETAIL_HIGH_STREET',
  RetailPopUp = 'RETAIL_POP_UP',
  RetailShoppingCentre = 'RETAIL_SHOPPING_CENTRE'
}

export type UnitValue = BooleanValue | File | FilesValue | FloatValue | StringValue;

export type Units = {
  __typename?: 'Units';
  pageInfo: PageInfo;
  results: Array<Unit>;
};

export type User = {
  __typename?: 'User';
  /** Globally unique identifier */
  _id: Scalars['ID'];
  activated: Scalars['Boolean'];
  /** User's associated organisation */
  associated: Array<Organisation>;
  /** Date the object was created */
  created?: Maybe<Scalars['DateTime']>;
  /** User's disabled notifications */
  disabledNotifications?: Maybe<DisabledNotifications>;
  email: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  hasMaxPinnedDeals: Scalars['Boolean'];
  /** User's hash for intercom */
  hash?: Maybe<Scalars['String']>;
  hiddenDeals?: Maybe<Array<UserHiddenDeal>>;
  hiddenTemplates?: Maybe<Array<Scalars['String']>>;
  hiddenUnits?: Maybe<Array<UserHiddenUnit>>;
  is2FAEnabled: Scalars['Boolean'];
  /** Is Tenant Side */
  isTenantSide: Scalars['Boolean'];
  lastLogin?: Maybe<Scalars['DateTime']>;
  lastName?: Maybe<Scalars['String']>;
  locked: Scalars['Boolean'];
  mobile?: Maybe<Scalars['String']>;
  /** User's most recent notificationns */
  notifications: Array<Notification>;
  /** User's organisation */
  organisation: Organisation;
  /** All User's associated organisations */
  organisations: Array<Organisation>;
  /** Is the user pending activation? */
  pending: Scalars['Boolean'];
  pinnedDeals?: Maybe<Deals>;
  /** User's practice invites */
  practiceInvites?: Maybe<Array<PracticeInvite>>;
  removed: Scalars['Boolean'];
  subscribers?: Maybe<Array<Scalars['String']>>;
  /** All User's trusted organisations */
  trustedOrganisations: Array<Organisation>;
  type: UserType;
};


export type UserTrustedOrganisationsArgs = {
  types?: Maybe<Array<UserType>>;
};

export type UserCreateAccessTokenInput = {
  email: Scalars['String'];
  password: Scalars['String'];
};

/** Create a new user */
export type UserCreateInput = {
  /** User's email. Used for login and notifications. Must be unique. */
  email: Scalars['String'];
  /** User's first name. 1-100 characters. */
  firstName: Scalars['String'];
  /** User's last name. 1-100 characters. */
  lastName: Scalars['String'];
  login: Scalars['Boolean'];
  /** User's mobile phone */
  mobile?: Maybe<Scalars['String']>;
  /** User's organisation details */
  organisation: CreateOrganisationInput;
  /** Account password. Must be at least 8 characters long. */
  password: Scalars['String'];
  /** User role. Will throw an error if admin is selected. */
  type: UserType;
};

export type UserDownload = {
  __typename?: 'UserDownload';
  /** Globally unique identifier */
  _id: Scalars['ID'];
  /** Date the object was created */
  created?: Maybe<Scalars['DateTime']>;
  timestamp: Scalars['DateTime'];
  user: Scalars['String'];
};

export type UserFilter = {
  createdFrom?: Maybe<Scalars['DateTime']>;
  createdTo?: Maybe<Scalars['DateTime']>;
  lastLoginFrom?: Maybe<Scalars['DateTime']>;
  lastLoginTo?: Maybe<Scalars['DateTime']>;
  locked?: Maybe<Scalars['Boolean']>;
  organisation?: Maybe<Array<Scalars['String']>>;
  type?: Maybe<Array<UserType>>;
};

export type UserFilterCount = {
  __typename?: 'UserFilterCount';
  admin: Scalars['Float'];
  agent: Scalars['Float'];
  all: Scalars['Float'];
  landlord: Scalars['Float'];
  tenant: Scalars['Float'];
};

/** User's hidden deals */
export type UserHiddenDeal = {
  __typename?: 'UserHiddenDeal';
  /** Globally unique identifier */
  _id: Scalars['ID'];
  /** Date the object was created */
  created?: Maybe<Scalars['DateTime']>;
};

/** User's hidden units */
export type UserHiddenUnit = {
  __typename?: 'UserHiddenUnit';
  /** Globally unique identifier */
  _id: Scalars['ID'];
  /** Date the object was created */
  created?: Maybe<Scalars['DateTime']>;
};

/** Create a new user from an invite */
export type UserInviteCreateInput = {
  /** User's email. Used for login and notifications. Must be unique. */
  email?: Maybe<Scalars['String']>;
  /** User's first name. 1-100 characters. */
  firstName?: Maybe<Scalars['String']>;
  /** User's last name. 1-100 characters. */
  lastName?: Maybe<Scalars['String']>;
  login?: Maybe<Scalars['Boolean']>;
  /** User's mobile phone */
  mobile?: Maybe<Scalars['String']>;
  organisation?: Maybe<Scalars['String']>;
  /** Account password. Must be at least 8 characters long. */
  password?: Maybe<Scalars['String']>;
  practiceType?: Maybe<PracticeType>;
  /** User role. Will throw an error if admin is selected. */
  type?: Maybe<UserType>;
};

/** Invite a new user */
export type UserInviteInput = {
  /** User's email to invite */
  emails: Array<Scalars['String']>;
  /** User type for invited user. */
  type: UserType;
};

/** Resets a user's password with a token */
export type UserResetPasswordInput = {
  /** User's new passsword */
  password: Scalars['String'];
  /** Reset token required to authenticate action */
  passwordResetToken: Scalars['String'];
};

export type UserSort = {
  field: UserSortFields;
  order?: Maybe<OrderBy>;
};

/** Sort the users by particular fields */
export enum UserSortFields {
  CompanyName = 'COMPANY_NAME',
  Created = 'CREATED',
  FirstName = 'FIRST_NAME',
  LastLogin = 'LAST_LOGIN'
}

/** Available user types */
export enum UserType {
  Admin = 'ADMIN',
  Agent = 'AGENT',
  Landlord = 'LANDLORD',
  Tenant = 'TENANT'
}

export type UserUpdateInput = {
  /** User's current password - required to update sensitive fields (email, password) */
  currentPassword?: Maybe<Scalars['String']>;
  /** User's email. Used for login and notifications. Must be unique. */
  email?: Maybe<Scalars['String']>;
  /** User's first name. 1-100 characters. */
  firstName?: Maybe<Scalars['String']>;
  is2FAEnabled?: Maybe<Scalars['Boolean']>;
  /** User's last name. 1-100 characters. */
  lastName?: Maybe<Scalars['String']>;
  locked?: Maybe<Scalars['Boolean']>;
  login?: Maybe<Scalars['Boolean']>;
  /** User's mobile phone */
  mobile?: Maybe<Scalars['String']>;
  /** Account password. Must be at least 8 characters long. */
  password?: Maybe<Scalars['String']>;
  /** User role. Will throw an error if admin is selected. */
  type?: Maybe<UserType>;
};

export type GetUnreadNotificationsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUnreadNotificationsQuery = (
  { __typename?: 'Query' }
  & { user: (
    { __typename?: 'User' }
    & Pick<User, '_id'>
    & { notifications: Array<(
      { __typename?: 'Notification' }
      & Pick<Notification, '_id' | 'read'>
    )> }
  ) }
);

export type LogoutMutationVariables = Exact<{ [key: string]: never; }>;


export type LogoutMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'userAccessTokenDelete'>
);

export type GetLayoutUserInfoQueryVariables = Exact<{ [key: string]: never; }>;


export type GetLayoutUserInfoQuery = (
  { __typename?: 'Query' }
  & { user: (
    { __typename: 'User' }
    & Pick<User, '_id' | 'firstName' | 'lastName' | 'email' | 'activated' | 'type' | 'isTenantSide'>
    & { organisation: (
      { __typename?: 'Organisation' }
      & Pick<Organisation, 'name' | '_id' | 'avatar'>
    ) }
  ) }
);

export type CreateUserMutationVariables = Exact<{
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  company: Scalars['String'];
  type: UserType;
  mobile?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  password: Scalars['String'];
  login: Scalars['Boolean'];
}>;


export type CreateUserMutation = (
  { __typename?: 'Mutation' }
  & { userCreate: (
    { __typename: 'User' }
    & Pick<User, '_id' | 'type'>
  ) }
);

export type LoginMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
}>;


export type LoginMutation = (
  { __typename?: 'Mutation' }
  & { userAccessTokenCreate?: Maybe<(
    { __typename: 'User' }
    & Pick<User, '_id' | 'activated' | 'type' | 'is2FAEnabled'>
  )> }
);

export type TwoFaMutationVariables = Exact<{
  email: Scalars['String'];
  token: Scalars['String'];
}>;


export type TwoFaMutation = (
  { __typename?: 'Mutation' }
  & { user2FA?: Maybe<(
    { __typename: 'User' }
    & Pick<User, '_id' | 'activated'>
  )> }
);

export type Resend2FaMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type Resend2FaMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'userResend2FACode'>
);

export type ResendActivationMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type ResendActivationMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'userResendActivation'>
);

export type RequestResetPasswordMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type RequestResetPasswordMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'userRequestResetPassword'>
);

export type NewPracticeMutationVariables = Exact<{
  id: Scalars['ID'];
  name: Scalars['String'];
}>;


export type NewPracticeMutation = (
  { __typename?: 'Mutation' }
  & { newPractice: (
    { __typename?: 'Organisation' }
    & Pick<Organisation, '_id' | 'name'>
  ) }
);

export type ProcessPracticeInviteMutationVariables = Exact<{
  inviteID: Scalars['ID'];
  response: Scalars['String'];
}>;


export type ProcessPracticeInviteMutation = (
  { __typename?: 'Mutation' }
  & { processPracticeInvite: (
    { __typename?: 'User' }
    & UserDetailsFragment
  ) }
);

export type ReadNotificationMutationVariables = Exact<{
  notificationID: Scalars['ID'];
}>;


export type ReadNotificationMutation = (
  { __typename?: 'Mutation' }
  & { userReadNotification: (
    { __typename?: 'Notification' }
    & Pick<Notification, '_id' | 'read'>
  ) }
);

export type GetCertificationQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCertificationQuery = (
  { __typename?: 'Query' }
  & { user: (
    { __typename?: 'User' }
    & Pick<User, '_id'>
    & { organisation: (
      { __typename?: 'Organisation' }
      & Pick<Organisation, '_id' | 'certification' | 'name'>
    ) }
  ) }
);

export type AddActorToDealMutationVariables = Exact<{
  dealID: Scalars['ID'];
  emails: Array<Scalars['String']> | Scalars['String'];
  type: UserType;
  party: DealUserParty;
  asMember?: Maybe<Scalars['Boolean']>;
}>;


export type AddActorToDealMutation = (
  { __typename?: 'Mutation' }
  & { dealAddActor: (
    { __typename?: 'Deal' }
    & { actors: Array<(
      { __typename?: 'User' }
      & Pick<User, '_id' | 'firstName' | 'lastName' | 'email' | 'type'>
    )>, tenant?: Maybe<(
      { __typename?: 'Organisation' }
      & Pick<Organisation, '_id'>
      & { members: Array<(
        { __typename?: 'User' }
        & Pick<User, '_id' | 'firstName' | 'lastName' | 'email' | 'type'>
      )> }
    )> }
  ) }
);

export type UndoDealMutationVariables = Exact<{
  dealID: Scalars['ID'];
}>;


export type UndoDealMutation = (
  { __typename?: 'Mutation' }
  & { dealUndo: (
    { __typename?: 'Deal' }
    & Pick<Deal, '_id'>
  ) }
);

export type RemoveActorFromDealMutationVariables = Exact<{
  dealID: Scalars['ID'];
  input: DealRemoveActorInput;
}>;


export type RemoveActorFromDealMutation = (
  { __typename?: 'Mutation' }
  & { dealRemoveActor: (
    { __typename?: 'Deal' }
    & Pick<Deal, '_id'>
    & { actors: Array<(
      { __typename?: 'User' }
      & Pick<User, '_id'>
    )> }
  ) }
);

export type RemoveTrustedUserMutationVariables = Exact<{
  organisationID: Scalars['ID'];
  id: Scalars['ID'];
}>;


export type RemoveTrustedUserMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'removeTrustedUser'>
);

export type GetPreviewLinkQueryVariables = Exact<{
  dealID: Scalars['ID'];
}>;


export type GetPreviewLinkQuery = (
  { __typename?: 'Query' }
  & { deal: (
    { __typename?: 'Deal' }
    & Pick<Deal, '_id' | 'preview'>
  ) }
);

export type GetDealSeenQueryVariables = Exact<{
  dealID?: Maybe<Scalars['ID']>;
  token?: Maybe<Scalars['String']>;
}>;


export type GetDealSeenQuery = (
  { __typename?: 'Query' }
  & { deal: (
    { __typename?: 'Deal' }
    & Pick<Deal, '_id' | 'state' | 'currentUserParty' | 'approverReturnEmail' | 'approvalID'>
    & { round?: Maybe<(
      { __typename?: 'DealRoundType' }
      & Pick<DealRoundType, 'count' | 'date'>
    )> }
  ) }
);

export type ApproveClauseMutationVariables = Exact<{
  dealID: Scalars['ID'];
  parentID: Scalars['ID'];
  clauseID: Scalars['ID'];
}>;


export type ApproveClauseMutation = (
  { __typename?: 'Mutation' }
  & { dealClauseUpdate: (
    { __typename?: 'Clause' }
    & Pick<Clause, '_id' | 'approved'>
    & { history: Array<(
      { __typename?: 'History' }
      & Pick<History, '_id' | 'type' | 'date' | 'party'>
      & { actor: (
        { __typename?: 'User' }
        & Pick<User, '_id' | 'firstName' | 'lastName'>
      ), change?: Maybe<(
        { __typename?: 'HistoryChange' }
        & { new: (
          { __typename?: 'HistoryValue' }
          & Pick<HistoryValue, 'value' | 'valueSuffix' | 'shortForm' | 'longForm' | 'approved' | 'rejected'>
        ), old?: Maybe<(
          { __typename?: 'HistoryValue' }
          & Pick<HistoryValue, 'value' | 'valueSuffix' | 'shortForm' | 'longForm' | 'approved' | 'rejected'>
        )> }
      )> }
    )> }
  ) }
);

export type CommentClauseMutationVariables = Exact<{
  dealID: Scalars['ID'];
  parentID: Scalars['ID'];
  clauseID: Scalars['ID'];
  comment: DealClauseCommentInput;
}>;


export type CommentClauseMutation = (
  { __typename?: 'Mutation' }
  & { dealClauseUpdate: (
    { __typename?: 'Clause' }
    & Pick<Clause, '_id' | 'approved' | 'rejected'>
    & { comments?: Maybe<Array<(
      { __typename?: 'Comment' }
      & Pick<Comment, '_id' | 'date' | 'type' | 'message'>
      & { actor: (
        { __typename?: 'User' }
        & Pick<User, '_id' | 'firstName' | 'lastName'>
      ) }
    )>>, history: Array<(
      { __typename?: 'History' }
      & Pick<History, '_id' | 'type' | 'date'>
      & { actor: (
        { __typename?: 'User' }
        & Pick<User, '_id' | 'firstName' | 'lastName'>
      ), change?: Maybe<(
        { __typename?: 'HistoryChange' }
        & { new: (
          { __typename?: 'HistoryValue' }
          & Pick<HistoryValue, 'value' | 'valueSuffix' | 'shortForm' | 'longForm' | 'approved' | 'rejected'>
        ), old?: Maybe<(
          { __typename?: 'HistoryValue' }
          & Pick<HistoryValue, 'value' | 'valueSuffix' | 'shortForm' | 'longForm' | 'approved' | 'rejected'>
        )> }
      )> }
    )> }
  ) }
);

export type CompleteDealMutationVariables = Exact<{
  dealID: Scalars['ID'];
}>;


export type CompleteDealMutation = (
  { __typename?: 'Mutation' }
  & { completeDeal: (
    { __typename?: 'Deal' }
    & Pick<Deal, '_id'>
  ) }
);

export type DealSeenMutationVariables = Exact<{
  dealID: Scalars['ID'];
}>;


export type DealSeenMutation = (
  { __typename?: 'Mutation' }
  & { dealSeen: (
    { __typename?: 'DealSeenResponse' }
    & Pick<DealSeenResponse, 'dealID'>
  ) }
);

export type UndoClauseMutationVariables = Exact<{
  dealID: Scalars['ID'];
  parentID: Scalars['ID'];
  clauseID: Scalars['ID'];
}>;


export type UndoClauseMutation = (
  { __typename?: 'Mutation' }
  & { dealClauseUndo: (
    { __typename?: 'Clause' }
    & Pick<Clause, '_id' | 'index' | 'value' | 'visible' | 'valueSuffix' | 'shortForm' | 'longForm' | 'approved' | 'rejected' | 'deleted'>
    & { comments?: Maybe<Array<(
      { __typename?: 'Comment' }
      & Pick<Comment, '_id' | 'date' | 'type' | 'message'>
      & { actor: (
        { __typename?: 'User' }
        & Pick<User, '_id' | 'firstName' | 'lastName'>
      ) }
    )>>, history: Array<(
      { __typename?: 'History' }
      & Pick<History, '_id' | 'type' | 'date' | 'party'>
      & { actor: (
        { __typename?: 'User' }
        & Pick<User, '_id' | 'firstName' | 'lastName'>
      ), change?: Maybe<(
        { __typename?: 'HistoryChange' }
        & { new: (
          { __typename?: 'HistoryValue' }
          & Pick<HistoryValue, 'value' | 'valueSuffix' | 'shortForm' | 'longForm' | 'approved' | 'rejected'>
        ), old?: Maybe<(
          { __typename?: 'HistoryValue' }
          & Pick<HistoryValue, 'value' | 'valueSuffix' | 'shortForm' | 'longForm' | 'approved' | 'rejected'>
        )> }
      )> }
    )> }
  ) }
);

export type UpdateAgreementSeenMutationVariables = Exact<{
  dealID: Scalars['ID'];
  currentUserParty: DealUserParty;
}>;


export type UpdateAgreementSeenMutation = (
  { __typename?: 'Mutation' }
  & { updateAgreementSeen: (
    { __typename?: 'Deal' }
    & Pick<Deal, '_id'>
  ) }
);

export type GetDealMessagesQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetDealMessagesQuery = (
  { __typename?: 'Query' }
  & { deal: (
    { __typename?: 'Deal' }
    & Pick<Deal, '_id' | 'currentUserParty'>
    & { unit?: Maybe<(
      { __typename?: 'Unit' }
      & Pick<Unit, 'straightToLease'>
      & { organisation: (
        { __typename?: 'Organisation' }
        & Pick<Organisation, 'avatar' | 'name'>
        & { straightToLease?: Maybe<(
          { __typename?: 'StraightToLease' }
          & Pick<StraightToLease, 'start' | 'end'>
        )> }
      ) }
    )>, messages: Array<(
      { __typename?: 'Message' }
      & Pick<Message, '_id' | 'message' | 'party' | 'sent'>
      & { user: (
        { __typename?: 'User' }
        & Pick<User, '_id' | 'firstName' | 'lastName'>
      ) }
    )> }
  ) }
);

export type GetNegotiationSummaryQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetNegotiationSummaryQuery = (
  { __typename?: 'Query' }
  & { deal: (
    { __typename?: 'Deal' }
    & Pick<Deal, '_id'>
    & { clauseSummary: (
      { __typename?: 'ClauseSummary' }
      & Pick<ClauseSummary, 'complete' | 'incomplete'>
    ), sections: Array<(
      { __typename?: 'Section' }
      & Pick<Section, '_id'>
      & { clauseSummary: (
        { __typename?: 'ClauseSummary' }
        & Pick<ClauseSummary, 'complete' | 'incomplete'>
      ), groups: Array<(
        { __typename?: 'Group' }
        & Pick<Group, '_id'>
        & { clauseSummary: (
          { __typename?: 'ClauseSummary' }
          & Pick<ClauseSummary, 'complete' | 'incomplete'>
        ), clauses: Array<(
          { __typename?: 'Clause' }
          & Pick<Clause, '_id'>
          & { groups?: Maybe<Array<(
            { __typename?: 'Group' }
            & Pick<Group, '_id'>
            & { clauseSummary: (
              { __typename?: 'ClauseSummary' }
              & Pick<ClauseSummary, 'complete' | 'incomplete'>
            ) }
          )>> }
        )> }
      )> }
    )> }
  ) }
);

export type SingleGroupFragment = (
  { __typename?: 'Group' }
  & Pick<Group, '_id' | 'parentID' | 'title' | 'description' | 'rics' | 'index'>
  & { clauseSummary: (
    { __typename?: 'ClauseSummary' }
    & Pick<ClauseSummary, 'complete' | 'incomplete'>
  ), clauses: Array<(
    { __typename?: 'Clause' }
    & { groups?: Maybe<Array<(
      { __typename?: 'Group' }
      & Pick<Group, '_id' | 'parentID' | 'title' | 'index'>
      & { clauses: Array<(
        { __typename?: 'Clause' }
        & SingleClauseNoGroupsFragment
      )> }
    )>> }
    & SingleClauseNoGroupsFragment
  )> }
);

export type SingleClauseNoGroupsFragment = (
  { __typename?: 'Clause' }
  & Pick<Clause, '_id' | 'parentID' | 'index' | 'value' | 'visible' | 'valueSuffix' | 'shortForm' | 'longForm' | 'approved' | 'rejected' | 'deleted' | 'tags'>
  & { dealBreaker: (
    { __typename?: 'DealBreaker' }
    & Pick<DealBreaker, 'isDealBreaker' | 'message'>
  ), comments?: Maybe<Array<(
    { __typename?: 'Comment' }
    & ClauseCommentFragment
  )>>, history: Array<(
    { __typename?: 'History' }
    & ClauseHistoryFragment
  )>, linked?: Maybe<Array<(
    { __typename?: 'LinkedClause' }
    & LinkedClauseFragment
  )>> }
);

export type ClauseCommentFragment = (
  { __typename?: 'Comment' }
  & Pick<Comment, '_id' | 'date' | 'type' | 'message'>
  & { actor: (
    { __typename?: 'User' }
    & SimpleDealActorFragment
  ) }
);

export type ClauseHistoryFragment = (
  { __typename?: 'History' }
  & Pick<History, '_id' | 'type' | 'date' | 'party'>
  & { actor: (
    { __typename?: 'User' }
    & SimpleDealActorFragment
  ), change?: Maybe<(
    { __typename?: 'HistoryChange' }
    & { new: (
      { __typename?: 'HistoryValue' }
      & HistoryChangeFragment
    ), old?: Maybe<(
      { __typename?: 'HistoryValue' }
      & HistoryChangeFragment
    )> }
  )> }
);

export type ClauseSummaryFragment = (
  { __typename?: 'ClauseSummary' }
  & Pick<ClauseSummary, 'incomplete' | 'complete'>
);

export type LinkedClauseFragment = (
  { __typename?: 'LinkedClause' }
  & Pick<LinkedClause, '_id' | 'value' | 'valueSuffix' | 'shortForm' | 'longForm' | 'tags'>
);

export type DealActorFragment = (
  { __typename?: 'User' }
  & Pick<User, '_id' | 'firstName' | 'lastName' | 'email' | 'type' | 'pending' | 'activated'>
  & { organisation: (
    { __typename?: 'Organisation' }
    & Pick<Organisation, '_id' | 'name'>
    & { members: Array<(
      { __typename?: 'User' }
      & Pick<User, '_id' | 'firstName' | 'lastName' | 'email'>
    )> }
  ) }
);

export type SimpleDealActorFragment = (
  { __typename?: 'User' }
  & Pick<User, '_id' | 'firstName' | 'lastName' | 'email'>
);

export type DealApprovalEntityFragment = (
  { __typename?: 'DealApprovalType' }
  & Pick<DealApprovalType, '_id' | 'email' | 'status' | 'date'>
);

export type DealApprovalsFragment = (
  { __typename?: 'DealApprovalsType' }
  & { landlord: Array<(
    { __typename?: 'DealApprovalType' }
    & DealApprovalEntityFragment
  )>, tenant: Array<(
    { __typename?: 'DealApprovalType' }
    & DealApprovalEntityFragment
  )> }
);

export type DealDetailsFragment = (
  { __typename?: 'Deal' }
  & Pick<Deal, '_id' | 'state' | 'currentUserParty' | 'isPinned' | 'agreedBy' | 'hasHiddenUnit' | 'approverReturnEmail' | 'approvalID' | 'hasAccess' | 'created' | 'agreementSeenTenant' | 'agreementSeenLandlord'>
  & { approvals?: Maybe<(
    { __typename?: 'DealApprovalsType' }
    & DealApprovalsFragment
  )>, round?: Maybe<(
    { __typename?: 'DealRoundType' }
    & DealRoundFragment
  )>, clauseSummary: (
    { __typename?: 'ClauseSummary' }
    & ClauseSummaryFragment
  ), unit?: Maybe<(
    { __typename?: 'Unit' }
    & DealUnitFragment
    & SingleUnitFragment
  )>, details: (
    { __typename?: 'TenantDetails' }
    & DealTenantDetailsFragment
  ), actors: Array<(
    { __typename?: 'User' }
    & DealActorFragment
  )>, tenant?: Maybe<(
    { __typename?: 'Organisation' }
    & DealTenantFragment
  )>, sections: Array<(
    { __typename?: 'Section' }
    & DealSectionFragment
  )>, template?: Maybe<(
    { __typename?: 'Unit' }
    & Pick<Unit, 'name'>
  )>, creator?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, '_id' | 'firstName' | 'lastName'>
  )>, logs: Array<(
    { __typename?: 'ActivityLog' }
    & Pick<ActivityLog, '_id' | 'action' | 'object' | 'created'>
    & { actor: (
      { __typename?: 'User' }
      & Pick<User, 'firstName' | 'lastName'>
    ) }
  )> }
);

export type DealRoundFragment = (
  { __typename?: 'DealRoundType' }
  & Pick<DealRoundType, 'count' | 'seen' | 'date'>
);

export type DealSectionFragment = (
  { __typename?: 'Section' }
  & Pick<Section, '_id' | 'title' | 'description' | 'rics' | 'index'>
  & { groups: Array<(
    { __typename?: 'Group' }
    & SingleGroupFragment
  )> }
);

export type DealTenantFragment = (
  { __typename?: 'Organisation' }
  & Pick<Organisation, '_id' | 'name' | 'pendingMembers' | 'active'>
  & { members: Array<(
    { __typename?: 'User' }
    & DealActorFragment
  )> }
);

export type DealTenantDetailsFragment = (
  { __typename?: 'TenantDetails' }
  & { one?: Maybe<(
    { __typename?: 'TenantStage1' }
    & { entity?: Maybe<(
      { __typename?: 'TenantEntity' }
      & Pick<TenantEntity, 'name' | 'companyNumber'>
      & { address?: Maybe<(
        { __typename?: 'TenantEntityAddress' }
        & Pick<TenantEntityAddress, 'line1' | 'line2' | 'city' | 'postCode'>
      )> }
    )>, building?: Maybe<(
      { __typename?: 'TenantEntity' }
      & { address?: Maybe<(
        { __typename?: 'TenantEntityAddress' }
        & Pick<TenantEntityAddress, 'line1' | 'line2' | 'city' | 'postCode'>
      )> }
    )> }
  )>, two?: Maybe<(
    { __typename?: 'TenantStage2' }
    & { lawyer?: Maybe<(
      { __typename?: 'TenantPerson' }
      & Pick<TenantPerson, 'name' | 'company' | 'email' | 'phone' | 'visible'>
    )> }
  )>, three?: Maybe<(
    { __typename?: 'TenantStage3' }
    & { files: Array<(
      { __typename?: 'File' }
      & Pick<File, '_id' | 'created' | 'name' | 'url'>
    )> }
  )>, team?: Maybe<(
    { __typename?: 'TenantStageTeam' }
    & { agent?: Maybe<(
      { __typename?: 'TenantPerson' }
      & Pick<TenantPerson, 'name' | 'company' | 'email' | 'phone' | 'visible'>
    )>, tenant?: Maybe<(
      { __typename?: 'TenantPerson' }
      & Pick<TenantPerson, 'name' | 'company' | 'email' | 'phone' | 'visible'>
    )> }
  )> }
);

export type DealUnitFragment = (
  { __typename?: 'Unit' }
  & Pick<Unit, '_id' | 'straightToLease' | 'name' | 'buildingName' | 'url' | 'use' | 'avatar' | 'rating' | 'area' | 'condition' | 'pending'>
  & { tasks: Array<(
    { __typename?: 'Task' }
    & UnitTaskFragment
  )>, deals: Array<(
    { __typename?: 'Deal' }
    & Pick<Deal, '_id'>
  )>, actors: Array<(
    { __typename?: 'User' }
    & DealActorFragment
  )>, address: (
    { __typename?: 'AddressType' }
    & SimpleAddressFragment
  ), organisation: (
    { __typename?: 'Organisation' }
    & DealUnitOrganisationFragment
  ) }
);

export type HistoryChangeFragment = (
  { __typename?: 'HistoryValue' }
  & Pick<HistoryValue, 'value' | 'valueSuffix' | 'shortForm' | 'longForm' | 'approved' | 'rejected'>
  & { comments?: Maybe<Array<(
    { __typename?: 'Comment' }
    & Pick<Comment, '_id' | 'message'>
  )>> }
);

export type SingleClauseNoHistoryFragment = (
  { __typename?: 'Clause' }
  & Pick<Clause, '_id' | 'parentID' | 'index' | 'value' | 'visible' | 'valueSuffix' | 'shortForm' | 'longForm' | 'approved' | 'rejected' | 'deleted' | 'tags' | 'clauseRef'>
  & { dealBreaker: (
    { __typename?: 'DealBreaker' }
    & Pick<DealBreaker, 'isDealBreaker' | 'message'>
  ), comments?: Maybe<Array<(
    { __typename?: 'Comment' }
    & ClauseCommentFragment
  )>>, linked?: Maybe<Array<(
    { __typename?: 'LinkedClause' }
    & LinkedClauseFragment
  )>> }
);

export type SingleGroupNoHistoryFragment = (
  { __typename?: 'Group' }
  & Pick<Group, '_id' | 'parentID' | 'title' | 'description' | 'rics' | 'index'>
  & { clauseSummary: (
    { __typename?: 'ClauseSummary' }
    & Pick<ClauseSummary, 'complete' | 'incomplete'>
  ), clauses: Array<(
    { __typename?: 'Clause' }
    & { groups?: Maybe<Array<(
      { __typename?: 'Group' }
      & Pick<Group, '_id' | 'parentID' | 'title' | 'index'>
      & { clauses: Array<(
        { __typename?: 'Clause' }
        & SingleClauseNoHistoryFragment
      )> }
    )>> }
    & SingleClauseNoHistoryFragment
  )> }
);

export type DealDetailsNoHistoryFragment = (
  { __typename?: 'Deal' }
  & Pick<Deal, '_id' | 'state' | 'currentUserParty' | 'isPinned' | 'hasHiddenUnit' | 'approverReturnEmail' | 'approvalID' | 'hasAccess' | 'created' | 'agreedBy' | 'agreementSeenTenant' | 'agreementSeenLandlord'>
  & { approvals?: Maybe<(
    { __typename?: 'DealApprovalsType' }
    & DealApprovalsFragment
  )>, round?: Maybe<(
    { __typename?: 'DealRoundType' }
    & DealRoundFragment
  )>, clauseSummary: (
    { __typename?: 'ClauseSummary' }
    & ClauseSummaryFragment
  ), unit?: Maybe<(
    { __typename?: 'Unit' }
    & DealUnitFragment
  )>, actors: Array<(
    { __typename?: 'User' }
    & DealActorFragment
  )>, tenant?: Maybe<(
    { __typename?: 'Organisation' }
    & DealTenantFragment
  )>, details: (
    { __typename?: 'TenantDetails' }
    & DealTenantDetailsFragment
  ), sections: Array<(
    { __typename?: 'Section' }
    & DealSectionNoHistoryFragment
  )>, template?: Maybe<(
    { __typename?: 'Unit' }
    & Pick<Unit, 'name'>
  )>, creator?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, '_id' | 'firstName' | 'lastName'>
  )>, logs: Array<(
    { __typename?: 'ActivityLog' }
    & Pick<ActivityLog, '_id' | 'action' | 'object' | 'created'>
    & { actor: (
      { __typename?: 'User' }
      & Pick<User, 'firstName' | 'lastName'>
    ) }
  )> }
);

export type DealSectionNoHistoryFragment = (
  { __typename?: 'Section' }
  & Pick<Section, '_id' | 'title' | 'description' | 'rics' | 'index'>
  & { groups: Array<(
    { __typename?: 'Group' }
    & SingleGroupNoHistoryFragment
  )> }
);

export type SimpleAddressFragment = (
  { __typename?: 'AddressType' }
  & Pick<AddressType, 'line1' | 'line2' | 'city' | 'postCode'>
);

export type DealUnitOrganisationFragment = (
  { __typename?: 'Organisation' }
  & Pick<Organisation, '_id' | 'avatar' | 'name' | 'website'>
  & { straightToLease?: Maybe<(
    { __typename?: 'StraightToLease' }
    & Pick<StraightToLease, 'start' | 'end'>
  )>, address: (
    { __typename?: 'AddressType' }
    & SimpleAddressFragment
  ), members: Array<(
    { __typename?: 'User' }
    & DealActorFragment
  )> }
);

export type UnitTaskFragment = (
  { __typename?: 'Task' }
  & Pick<Task, '_id' | 'applicable' | 'edited'>
  & { value?: Maybe<(
    { __typename?: 'BooleanValue' }
    & Pick<BooleanValue, 'booleanValue'>
  ) | (
    { __typename?: 'File' }
    & Pick<File, '_id' | 'created' | 'url' | 'name'>
  ) | (
    { __typename?: 'FilesValue' }
    & { filesValue: Array<(
      { __typename?: 'File' }
      & Pick<File, '_id' | 'created' | 'url' | 'name'>
    )> }
  ) | (
    { __typename?: 'FloatValue' }
    & Pick<FloatValue, 'floatValue'>
  ) | (
    { __typename?: 'StringValue' }
    & Pick<StringValue, 'stringValue'>
  )>, task: (
    { __typename?: 'AdminTask' }
    & Pick<AdminTask, '_id' | 'title' | 'index' | 'weight' | 'optional' | 'tags' | 'type'>
  ) }
);

export type DealBulkAddActorsMutationVariables = Exact<{
  dealIDs: Array<Scalars['ID']> | Scalars['ID'];
  emails: Array<Scalars['String']> | Scalars['String'];
  all: Scalars['Boolean'];
  filter: DealFilter;
}>;


export type DealBulkAddActorsMutation = (
  { __typename?: 'Mutation' }
  & { dealBulkAddActors: Array<(
    { __typename?: 'User' }
    & Pick<User, '_id'>
  )> }
);

export type RemoveTenantMutationVariables = Exact<{
  dealID: Scalars['ID'];
  tenantID: Scalars['ID'];
}>;


export type RemoveTenantMutation = (
  { __typename?: 'Mutation' }
  & { dealRemoveTenant: (
    { __typename?: 'Deal' }
    & Pick<Deal, '_id'>
    & { tenant?: Maybe<(
      { __typename?: 'Organisation' }
      & Pick<Organisation, '_id'>
    )> }
  ) }
);

export type SendDealMutationVariables = Exact<{
  dealID: Scalars['ID'];
  emails?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  otherEmails?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  message: Scalars['String'];
  type: DealSendType;
  accountType: UserType;
}>;


export type SendDealMutation = (
  { __typename?: 'Mutation' }
  & { dealSend: (
    { __typename?: 'Deal' }
    & Pick<Deal, '_id' | 'state' | 'currentUserParty'>
    & { tenant?: Maybe<(
      { __typename?: 'Organisation' }
      & Pick<Organisation, '_id'>
      & { deals: Array<(
        { __typename?: 'Deal' }
        & Pick<Deal, '_id'>
      )> }
    )> }
  ) }
);

export type SendDealApprovalMutationVariables = Exact<{
  dealID: Scalars['ID'];
  emails?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  message: Scalars['String'];
  approvalID?: Maybe<Scalars['ID']>;
  additionalApproval?: Maybe<Scalars['Boolean']>;
  currentUserParty: DealUserParty;
  currentUserEmail?: Maybe<Scalars['String']>;
  type?: Maybe<DealApprovalSendType>;
}>;


export type SendDealApprovalMutation = (
  { __typename?: 'Mutation' }
  & { dealApprovalSend: (
    { __typename?: 'DealApprovalSendResponse' }
    & Pick<DealApprovalSendResponse, 'emails' | 'type'>
  ) }
);

export type VerifyDealMutationVariables = Exact<{
  token: Scalars['String'];
}>;


export type VerifyDealMutation = (
  { __typename?: 'Mutation' }
  & { dealVerify: (
    { __typename?: 'Deal' }
    & Pick<Deal, '_id' | 'pending'>
  ) }
);

export type GetDealOrganisationNamesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetDealOrganisationNamesQuery = (
  { __typename?: 'Query' }
  & { deals: (
    { __typename?: 'Deals' }
    & { results: Array<(
      { __typename?: 'Deal' }
      & { unit?: Maybe<(
        { __typename?: 'Unit' }
        & { organisation: (
          { __typename?: 'Organisation' }
          & Pick<Organisation, 'name'>
        ) }
      )> }
    )> }
  ) }
);

export type GetDealDetailsQueryVariables = Exact<{
  dealID?: Maybe<Scalars['ID']>;
  token?: Maybe<Scalars['String']>;
}>;


export type GetDealDetailsQuery = (
  { __typename?: 'Query' }
  & { deal: (
    { __typename?: 'Deal' }
    & DealDetailsFragment
  ) }
);

export type GetDealDetailsNoHistoryQueryVariables = Exact<{
  dealID?: Maybe<Scalars['ID']>;
  token?: Maybe<Scalars['String']>;
}>;


export type GetDealDetailsNoHistoryQuery = (
  { __typename?: 'Query' }
  & { deal: (
    { __typename?: 'Deal' }
    & DealDetailsNoHistoryFragment
  ) }
);

export type OrganisationActivateMutationVariables = Exact<{
  organisationID: Scalars['ID'];
  name: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  sendInvite: Scalars['Boolean'];
}>;


export type OrganisationActivateMutation = (
  { __typename?: 'Mutation' }
  & { activateOrganisation: (
    { __typename?: 'Organisation' }
    & Pick<Organisation, '_id'>
  ) }
);

export type DealBulkHideMutationVariables = Exact<{
  dealIDs: Array<Scalars['ID']> | Scalars['ID'];
  all: Scalars['Boolean'];
  filter: DealFilter;
  removeOnActive?: Maybe<Scalars['Boolean']>;
}>;


export type DealBulkHideMutation = (
  { __typename?: 'Mutation' }
  & { dealBulkHide: (
    { __typename?: 'User' }
    & Pick<User, '_id'>
  ) }
);

export type DealBulkUnhideMutationVariables = Exact<{
  dealIDs: Array<Scalars['ID']> | Scalars['ID'];
  all: Scalars['Boolean'];
  filter: DealFilter;
  unhideConnectedUnit?: Maybe<Scalars['Boolean']>;
}>;


export type DealBulkUnhideMutation = (
  { __typename?: 'Mutation' }
  & { dealBulkUnhide: (
    { __typename?: 'User' }
    & Pick<User, '_id'>
  ) }
);

export type DeleteDealMutationVariables = Exact<{
  dealID: Scalars['ID'];
}>;


export type DeleteDealMutation = (
  { __typename?: 'Mutation' }
  & { dealDelete: (
    { __typename?: 'DeletedDeal' }
    & Pick<DeletedDeal, 'deletedDealID'>
  ) }
);

export type HideDealMutationVariables = Exact<{
  dealID: Scalars['ID'];
  removeOnActive?: Maybe<Scalars['Boolean']>;
}>;


export type HideDealMutation = (
  { __typename?: 'Mutation' }
  & { dealHide: (
    { __typename?: 'User' }
    & Pick<User, '_id'>
  ) }
);

export type NewDealMutationVariables = Exact<{
  unitID: Scalars['ID'];
  templateID?: Maybe<Scalars['ID']>;
}>;


export type NewDealMutation = (
  { __typename?: 'Mutation' }
  & { dealCreate: (
    { __typename?: 'Deal' }
    & Pick<Deal, '_id' | 'pending' | 'state' | 'score'>
    & { clauseSummary: (
      { __typename?: 'ClauseSummary' }
      & Pick<ClauseSummary, 'incomplete' | 'complete'>
    ), unit?: Maybe<(
      { __typename?: 'Unit' }
      & Pick<Unit, '_id' | 'url' | 'name' | 'buildingName' | 'rating'>
      & { actors: Array<(
        { __typename?: 'User' }
        & Pick<User, '_id'>
      )>, address: (
        { __typename?: 'AddressType' }
        & Pick<AddressType, 'line1'>
      ), organisation: (
        { __typename?: 'Organisation' }
        & Pick<Organisation, '_id' | 'name'>
        & { members: Array<(
          { __typename?: 'User' }
          & Pick<User, '_id'>
        )> }
      ) }
    )> }
  ) }
);

export type DealNudgeMutationVariables = Exact<{
  dealID: Scalars['ID'];
  message: Scalars['String'];
}>;


export type DealNudgeMutation = (
  { __typename?: 'Mutation' }
  & { dealNudge: (
    { __typename?: 'NudgeResponse' }
    & Pick<NudgeResponse, 'dealID'>
  ) }
);

export type PinDealMutationVariables = Exact<{
  dealID: Scalars['ID'];
}>;


export type PinDealMutation = (
  { __typename?: 'Mutation' }
  & { userAddPinnedDeal: (
    { __typename?: 'User' }
    & UserDetailsFragment
  ) }
);

export type UnhideDealMutationVariables = Exact<{
  dealID: Scalars['ID'];
  unhideConnectedUnit?: Maybe<Scalars['Boolean']>;
}>;


export type UnhideDealMutation = (
  { __typename?: 'Mutation' }
  & { dealUnhide: (
    { __typename?: 'User' }
    & Pick<User, '_id'>
  ) }
);

export type UnpinDealMutationVariables = Exact<{
  dealID: Scalars['ID'];
}>;


export type UnpinDealMutation = (
  { __typename?: 'Mutation' }
  & { userRemovePinnedDeal: (
    { __typename?: 'User' }
    & UserDetailsFragment
  ) }
);

export type DealCardFragment = (
  { __typename?: 'Deal' }
  & Pick<Deal, '_id' | 'pending' | 'state' | 'hasHiddenUnit' | 'score' | 'currentUserParty' | 'isPinned' | 'created' | 'agreedBy'>
  & { approvals?: Maybe<(
    { __typename?: 'DealApprovalsType' }
    & { landlord: Array<(
      { __typename?: 'DealApprovalType' }
      & Pick<DealApprovalType, '_id' | 'email' | 'status' | 'date'>
    )>, tenant: Array<(
      { __typename?: 'DealApprovalType' }
      & Pick<DealApprovalType, '_id' | 'email' | 'status' | 'date'>
    )> }
  )>, round?: Maybe<(
    { __typename?: 'DealRoundType' }
    & Pick<DealRoundType, 'count' | 'nudge' | 'date'>
  )>, clauseSummary: (
    { __typename?: 'ClauseSummary' }
    & Pick<ClauseSummary, 'incomplete' | 'complete'>
  ), actors: Array<(
    { __typename?: 'User' }
    & Pick<User, '_id' | 'type' | 'email' | 'firstName' | 'lastName'>
  )>, unit?: Maybe<(
    { __typename?: 'Unit' }
    & Pick<Unit, '_id' | 'url' | 'name' | 'buildingName' | 'rating'>
    & { actors: Array<(
      { __typename?: 'User' }
      & Pick<User, '_id' | 'type' | 'email' | 'firstName' | 'lastName'>
    )>, address: (
      { __typename?: 'AddressType' }
      & Pick<AddressType, 'line1'>
    ), organisation: (
      { __typename?: 'Organisation' }
      & Pick<Organisation, '_id' | 'name'>
      & { members: Array<(
        { __typename?: 'User' }
        & Pick<User, '_id'>
      )> }
    ) }
  )>, tenant?: Maybe<(
    { __typename?: 'Organisation' }
    & Pick<Organisation, 'name'>
    & { members: Array<(
      { __typename?: 'User' }
      & Pick<User, 'email' | '_id' | 'firstName' | 'lastName'>
    )> }
  )>, template?: Maybe<(
    { __typename?: 'Unit' }
    & Pick<Unit, '_id' | 'name'>
  )>, creator?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, '_id' | 'firstName' | 'lastName'>
  )> }
);

export type GetDealCardDetailsQueryVariables = Exact<{
  query?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  filter?: Maybe<DealFilter>;
  sort?: Maybe<DealSort>;
}>;


export type GetDealCardDetailsQuery = (
  { __typename?: 'Query' }
  & { deals: (
    { __typename?: 'Deals' }
    & { results: Array<(
      { __typename?: 'Deal' }
      & DealCardFragment
    )>, pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'total' | 'hasResults' | 'hasNextPage'>
    ) }
  ) }
);

export type ExportDealsQueryVariables = Exact<{ [key: string]: never; }>;


export type ExportDealsQuery = (
  { __typename?: 'Query' }
  & { exportDeals: (
    { __typename?: 'S3Payload' }
    & Pick<S3Payload, 'url'>
  ) }
);

export type GetDealBuildingNamesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetDealBuildingNamesQuery = (
  { __typename?: 'Query' }
  & { deals: (
    { __typename?: 'Deals' }
    & { results: Array<(
      { __typename?: 'Deal' }
      & { unit?: Maybe<(
        { __typename?: 'Unit' }
        & Pick<Unit, 'buildingName'>
      )> }
    )> }
  ) }
);

export type GetDealCitiesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetDealCitiesQuery = (
  { __typename?: 'Query' }
  & { deals: (
    { __typename?: 'Deals' }
    & { results: Array<(
      { __typename?: 'Deal' }
      & { unit?: Maybe<(
        { __typename?: 'Unit' }
        & { address: (
          { __typename?: 'AddressType' }
          & Pick<AddressType, 'city'>
        ) }
      )> }
    )> }
  ) }
);

export type GetDealOrganisationsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetDealOrganisationsQuery = (
  { __typename?: 'Query' }
  & { deals: (
    { __typename?: 'Deals' }
    & { results: Array<(
      { __typename?: 'Deal' }
      & { client: (
        { __typename?: 'Organisation' }
        & Pick<Organisation, '_id' | 'name'>
      ) }
    )> }
  ) }
);

export type GetDealTemplatesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetDealTemplatesQuery = (
  { __typename?: 'Query' }
  & { deals: (
    { __typename?: 'Deals' }
    & { results: Array<(
      { __typename?: 'Deal' }
      & { template?: Maybe<(
        { __typename?: 'Unit' }
        & Pick<Unit, '_id' | 'name'>
      )> }
    )> }
  ) }
);

export type GetDealCreatorQueryVariables = Exact<{ [key: string]: never; }>;


export type GetDealCreatorQuery = (
  { __typename?: 'Query' }
  & { deals: (
    { __typename?: 'Deals' }
    & { results: Array<(
      { __typename?: 'Deal' }
      & { creator?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, '_id' | 'firstName' | 'lastName'>
      )> }
    )> }
  ) }
);

export type DealFilterCountsQueryVariables = Exact<{
  query?: Maybe<Scalars['String']>;
  filter?: Maybe<DealFilter>;
}>;


export type DealFilterCountsQuery = (
  { __typename?: 'Query' }
  & { dealFilterCounts: (
    { __typename?: 'DealFilterCount' }
    & Pick<DealFilterCount, 'live' | 'complete' | 'hidden'>
  ) }
);

export type CompletedStagesFragment = (
  { __typename?: 'TenantDetails' }
  & { one?: Maybe<(
    { __typename?: 'TenantStage1' }
    & Pick<TenantStage1, 'completed'>
  )>, two?: Maybe<(
    { __typename?: 'TenantStage2' }
    & Pick<TenantStage2, 'completed'>
  )>, team?: Maybe<(
    { __typename?: 'TenantStageTeam' }
    & Pick<TenantStageTeam, 'completed'>
  )>, three?: Maybe<(
    { __typename?: 'TenantStage3' }
    & Pick<TenantStage3, 'completed'>
  )>, aml?: Maybe<(
    { __typename?: 'TenantStageAML' }
    & Pick<TenantStageAml, 'completed'>
  )>, final?: Maybe<(
    { __typename?: 'TenantStageFinal' }
    & Pick<TenantStageFinal, 'completed'>
  )> }
);

export type GetStagesCompletedQueryVariables = Exact<{
  dealID: Scalars['ID'];
  token?: Maybe<Scalars['String']>;
}>;


export type GetStagesCompletedQuery = (
  { __typename?: 'Query' }
  & { deal: (
    { __typename?: 'Deal' }
    & Pick<Deal, '_id'>
    & { details: (
      { __typename?: 'TenantDetails' }
      & CompletedStagesFragment
    ) }
  ) }
);

export type ActivateUserMutationVariables = Exact<{
  token: Scalars['String'];
}>;


export type ActivateUserMutation = (
  { __typename?: 'Mutation' }
  & { userActivate: (
    { __typename?: 'User' }
    & Pick<User, 'email'>
  ) }
);

export type NewInvitedUserMutationVariables = Exact<{
  token: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  organisation?: Maybe<Scalars['String']>;
  practiceType?: Maybe<PracticeType>;
  mobile: Scalars['String'];
  password: Scalars['String'];
}>;


export type NewInvitedUserMutation = (
  { __typename?: 'Mutation' }
  & { userInviteCreate: (
    { __typename?: 'User' }
    & Pick<User, '_id' | 'firstName' | 'lastName'>
    & { organisation: (
      { __typename?: 'Organisation' }
      & Pick<Organisation, 'name'>
    ) }
  ) }
);

export type ResetUserPasswordMutationVariables = Exact<{
  token: Scalars['String'];
  password: Scalars['String'];
}>;


export type ResetUserPasswordMutation = (
  { __typename?: 'Mutation' }
  & { userResetPassword: (
    { __typename?: 'User' }
    & Pick<User, 'email'>
  ) }
);

export type GetInviteDetailsQueryVariables = Exact<{
  token: Scalars['String'];
}>;


export type GetInviteDetailsQuery = (
  { __typename?: 'Query' }
  & { invite: (
    { __typename?: 'InviteDetails' }
    & { user: (
      { __typename?: 'InviteTokenUser' }
      & Pick<InviteTokenUser, '_id' | 'email' | 'type'>
    ), organisation: (
      { __typename?: 'Organisation' }
      & Pick<Organisation, '_id' | 'avatar' | 'name'>
      & { members: Array<(
        { __typename?: 'User' }
        & Pick<User, '_id'>
      )> }
    ) }
  ) }
);

export type GetOrganisationAvatarQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetOrganisationAvatarQuery = (
  { __typename?: 'Query' }
  & { organisation: (
    { __typename?: 'Organisation' }
    & Pick<Organisation, 'avatar'>
  ) }
);

export type UpdateAdminClauseMutationVariables = Exact<{
  id: Scalars['ID'];
  updateDeals: Scalars['Boolean'];
  updateTemplates: Scalars['Boolean'];
}>;


export type UpdateAdminClauseMutation = (
  { __typename?: 'Mutation' }
  & { adminClauseUpdate: (
    { __typename?: 'ClauseTemplate' }
    & Pick<ClauseTemplate, '_id' | 'updatedDealsAt' | 'updatedTemplatesAt'>
  ) }
);

export type UpdateOrganisationClauseMutationVariables = Exact<{
  id: Scalars['ID'];
  organisationID: Scalars['ID'];
  updateDeals: Scalars['Boolean'];
  updateTemplates: Scalars['Boolean'];
}>;


export type UpdateOrganisationClauseMutation = (
  { __typename?: 'Mutation' }
  & { organisationClauseUpdate: (
    { __typename?: 'OrganisationClause' }
    & Pick<OrganisationClause, '_id' | 'updatedDealsAt' | 'updatedTemplatesAt'>
  ) }
);

export type OrganisationCreateMutationVariables = Exact<{
  name: Scalars['String'];
  type: UserType;
  active?: Maybe<Scalars['Boolean']>;
  address?: Maybe<AddressCreateInput>;
  website?: Maybe<Scalars['String']>;
  avatar?: Maybe<Scalars['String']>;
  emails: Array<Scalars['String']> | Scalars['String'];
  practiceType?: Maybe<PracticeType>;
}>;


export type OrganisationCreateMutation = (
  { __typename?: 'Mutation' }
  & { organisationCreate: (
    { __typename?: 'OrganisationResult' }
    & Pick<OrganisationResult, 'errors'>
    & { organisation?: Maybe<(
      { __typename?: 'Organisation' }
      & Pick<Organisation, '_id'>
    )> }
  ) }
);

export type OrganisationDeleteMutationVariables = Exact<{
  id: Scalars['ID'];
  input: AdminOrganisationDeleteInput;
}>;


export type OrganisationDeleteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'organisationDelete'>
);

export type OrganisationCardFragment = (
  { __typename?: 'Organisation' }
  & Pick<Organisation, '_id' | 'name' | 'membersSize' | 'avatar' | 'website' | 'type' | 'created' | 'practiceType'>
  & { address: (
    { __typename?: 'AddressType' }
    & Pick<AddressType, 'line1' | 'line2' | 'city' | 'country' | 'postCode'>
  ) }
);

export type GetAllOrganisationsQueryVariables = Exact<{
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  query?: Maybe<Scalars['String']>;
  sort?: Maybe<OrganisationSort>;
  filter?: Maybe<OrganisationFilter>;
}>;


export type GetAllOrganisationsQuery = (
  { __typename?: 'Query' }
  & { organisations: (
    { __typename?: 'Organisations' }
    & { results: Array<(
      { __typename?: 'Organisation' }
      & OrganisationCardFragment
    )>, pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'total' | 'hasNextPage'>
    ) }
  ) }
);

export type OrganisationFilterCountsQueryVariables = Exact<{
  filter?: Maybe<OrganisationFilter>;
  query?: Maybe<Scalars['String']>;
}>;


export type OrganisationFilterCountsQuery = (
  { __typename?: 'Query' }
  & { organisationFilterCounts: (
    { __typename?: 'OrganisationFilterCount' }
    & Pick<OrganisationFilterCount, 'all' | 'landlord' | 'agent' | 'tenant'>
  ) }
);

export type DeleteTaskMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteTaskMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'adminTaskDelete'>
);

export type NewTaskMutationVariables = Exact<{
  title: Scalars['String'];
  index: Scalars['Int'];
  weight: Scalars['Int'];
  tags?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  optional: Scalars['Boolean'];
  type: AdminTaskType;
  associatedUses?: Maybe<Array<UnitUse> | UnitUse>;
}>;


export type NewTaskMutation = (
  { __typename?: 'Mutation' }
  & { adminTaskCreate: (
    { __typename?: 'AdminTask' }
    & Pick<AdminTask, '_id' | 'title' | 'index' | 'weight' | 'tags' | 'optional' | 'type' | 'associatedUses'>
  ) }
);

export type UpdateTaskMutationVariables = Exact<{
  id: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
  index?: Maybe<Scalars['Int']>;
  weight?: Maybe<Scalars['Int']>;
  tags?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  optional?: Maybe<Scalars['Boolean']>;
  type?: Maybe<AdminTaskType>;
  associatedUses?: Maybe<Array<UnitUse> | UnitUse>;
}>;


export type UpdateTaskMutation = (
  { __typename?: 'Mutation' }
  & { adminTaskUpdate: (
    { __typename?: 'AdminTask' }
    & Pick<AdminTask, '_id' | 'title' | 'index' | 'weight' | 'tags' | 'optional' | 'type' | 'associatedUses'>
  ) }
);

export type GetTasksQueryVariables = Exact<{ [key: string]: never; }>;


export type GetTasksQuery = (
  { __typename?: 'Query' }
  & { tasks: Array<(
    { __typename?: 'AdminTask' }
    & Pick<AdminTask, '_id' | 'title' | 'index' | 'weight' | 'optional' | 'tags' | 'type' | 'associatedUses'>
  )> }
);

export type AdminDeleteUserMutationVariables = Exact<{
  id: Scalars['ID'];
  input: AdminUserDeleteInput;
}>;


export type AdminDeleteUserMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'userDelete'>
);

export type AdminEditUserMutationVariables = Exact<{
  id: Scalars['ID'];
  input: UserUpdateInput;
}>;


export type AdminEditUserMutation = (
  { __typename?: 'Mutation' }
  & { userUpdate: (
    { __typename?: 'User' }
    & Pick<User, '_id' | 'firstName' | 'lastName' | 'email' | 'mobile' | 'locked'>
  ) }
);

export type AdminMakeAdminMutationVariables = Exact<{
  id: Scalars['ID'];
  password: Scalars['String'];
}>;


export type AdminMakeAdminMutation = (
  { __typename?: 'Mutation' }
  & { adminMakeAdmin: (
    { __typename?: 'User' }
    & Pick<User, '_id' | 'type'>
  ) }
);

export type InvitedUserFragment = (
  { __typename?: 'InviteTokenUser' }
  & Pick<InviteTokenUser, '_id' | 'email' | 'existing'>
);

export type OrganisationMemberFragment = (
  { __typename?: 'User' }
  & Pick<User, '_id' | 'firstName' | 'lastName' | 'email' | 'mobile' | 'type' | 'pending' | 'lastLogin' | 'activated'>
  & { organisation: (
    { __typename?: 'Organisation' }
    & Pick<Organisation, '_id' | 'name'>
  ) }
);

export type AdminUserImpersonateMutationVariables = Exact<{
  id: Scalars['ID'];
  input: AdminUserAccessTokenCreateInput;
}>;


export type AdminUserImpersonateMutation = (
  { __typename?: 'Mutation' }
  & { adminUserAccessTokenCreate: (
    { __typename?: 'User' }
    & Pick<User, '_id'>
  ) }
);

export type ClauseAddAdminMutationVariables = Exact<{
  value?: Maybe<Scalars['String']>;
  valueSuffix?: Maybe<Scalars['String']>;
  shortForm?: Maybe<Scalars['String']>;
  longForm?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  section: Scalars['String'];
  level1Group: Scalars['String'];
  level2Group?: Maybe<Scalars['String']>;
  linked?: Maybe<LinkClauseTemplateInput>;
  associatedUses?: Maybe<Array<UnitUse> | UnitUse>;
}>;


export type ClauseAddAdminMutation = (
  { __typename?: 'Mutation' }
  & { adminClauseAdd: (
    { __typename?: 'ClauseTemplate' }
    & Pick<ClauseTemplate, '_id' | 'value' | 'valueSuffix' | 'shortForm' | 'longForm' | 'tags' | 'associatedUses'>
    & { linked: (
      { __typename?: 'AdminLinked' }
      & { results?: Maybe<Array<(
        { __typename?: 'ClauseTemplate' }
        & Pick<ClauseTemplate, '_id' | 'value' | 'valueSuffix' | 'shortForm' | 'longForm'>
      )>> }
    ), section?: Maybe<(
      { __typename?: 'ClauseTemplateSection' }
      & Pick<ClauseTemplateSection, 'title'>
      & { group: (
        { __typename?: 'ClauseTemplateLevel1Group' }
        & Pick<ClauseTemplateLevel1Group, 'title'>
        & { group?: Maybe<(
          { __typename?: 'ClauseTemplateLevel2Group' }
          & Pick<ClauseTemplateLevel2Group, 'title'>
        )> }
      ) }
    )> }
  ) }
);

export type ClauseDeleteAdminMutationVariables = Exact<{
  _id: Scalars['ID'];
}>;


export type ClauseDeleteAdminMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'adminClauseDelete'>
);

export type ClauseDeleteOrganisationMutationVariables = Exact<{
  organisationID: Scalars['ID'];
  _id: Scalars['ID'];
}>;


export type ClauseDeleteOrganisationMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'organisationClauseDelete'>
);

export type ClauseEditAdminMutationVariables = Exact<{
  _id: Scalars['ID'];
  value?: Maybe<Scalars['String']>;
  valueSuffix?: Maybe<Scalars['String']>;
  shortForm?: Maybe<Scalars['String']>;
  longForm?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  section?: Maybe<Scalars['String']>;
  level1Group?: Maybe<Scalars['String']>;
  level2Group?: Maybe<Scalars['String']>;
  linked?: Maybe<LinkClauseTemplateInput>;
  associatedUses?: Maybe<Array<UnitUse> | UnitUse>;
}>;


export type ClauseEditAdminMutation = (
  { __typename?: 'Mutation' }
  & { adminClauseEdit: (
    { __typename?: 'ClauseTemplate' }
    & Pick<ClauseTemplate, '_id' | 'value' | 'valueSuffix' | 'shortForm' | 'longForm' | 'tags'>
    & { linked: (
      { __typename?: 'AdminLinked' }
      & { results?: Maybe<Array<(
        { __typename?: 'ClauseTemplate' }
        & Pick<ClauseTemplate, '_id' | 'value' | 'valueSuffix' | 'shortForm' | 'longForm'>
      )>> }
    ), section?: Maybe<(
      { __typename?: 'ClauseTemplateSection' }
      & Pick<ClauseTemplateSection, 'title' | 'description' | 'rics'>
      & { group: (
        { __typename?: 'ClauseTemplateLevel1Group' }
        & Pick<ClauseTemplateLevel1Group, 'title' | 'description' | 'rics'>
        & { group?: Maybe<(
          { __typename?: 'ClauseTemplateLevel2Group' }
          & Pick<ClauseTemplateLevel2Group, 'title'>
        )> }
      ) }
    )> }
  ) }
);

export type OrganisationClauseEditMutationVariables = Exact<{
  _id: Scalars['ID'];
  organisationID: Scalars['ID'];
  value?: Maybe<Scalars['String']>;
  valueSuffix?: Maybe<Scalars['String']>;
  shortForm?: Maybe<Scalars['String']>;
  longForm?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  section?: Maybe<Scalars['String']>;
  level1Group?: Maybe<Scalars['String']>;
  level2Group?: Maybe<Scalars['String']>;
  linked?: Maybe<LinkClauseTemplateInput>;
  associatedUses?: Maybe<Array<UnitUse> | UnitUse>;
}>;


export type OrganisationClauseEditMutation = (
  { __typename?: 'Mutation' }
  & { organisationClauseEdit: (
    { __typename?: 'OrganisationClause' }
    & Pick<OrganisationClause, '_id' | 'value' | 'valueSuffix' | 'associatedUses'>
    & { section: (
      { __typename?: 'OrganisationSection' }
      & Pick<OrganisationSection, 'title'>
      & { group: (
        { __typename?: 'OrganisationGroup' }
        & Pick<OrganisationGroup, 'title'>
        & { group?: Maybe<(
          { __typename?: 'OrganisationSubGroup' }
          & Pick<OrganisationSubGroup, 'title'>
        )> }
      ) }
    ) }
  ) }
);

export type OrganisationSectionsEditMutationVariables = Exact<{
  organisationID: Scalars['ID'];
  fromSection: Scalars['String'];
  toSection?: Maybe<Scalars['String']>;
  fromGroup?: Maybe<Scalars['String']>;
  toGroup?: Maybe<Scalars['String']>;
  sectionDescription?: Maybe<Scalars['String']>;
  sectionRics?: Maybe<Scalars['String']>;
  groupDescription?: Maybe<Scalars['String']>;
  groupRics?: Maybe<Scalars['String']>;
  deleteSection?: Maybe<Scalars['Boolean']>;
  deleteGroup?: Maybe<Scalars['Boolean']>;
}>;


export type OrganisationSectionsEditMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'organisationSectionsEdit'>
);

export type ClauseBankEditSectionsMutationVariables = Exact<{
  fromSection: Scalars['String'];
  toSection?: Maybe<Scalars['String']>;
  fromGroup?: Maybe<Scalars['String']>;
  toGroup?: Maybe<Scalars['String']>;
  sectionDescription?: Maybe<Scalars['String']>;
  sectionRics?: Maybe<Scalars['String']>;
  groupDescription?: Maybe<Scalars['String']>;
  groupRics?: Maybe<Scalars['String']>;
  deleteSection?: Maybe<Scalars['Boolean']>;
}>;


export type ClauseBankEditSectionsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'adminClauseSectionsEdit'>
);

export type DeleteUserMutationVariables = Exact<{ [key: string]: never; }>;


export type DeleteUserMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'userDelete'>
);

export type UserNotificationsDisableMutationVariables = Exact<{
  userEmail?: Maybe<Scalars['Boolean']>;
  userNotification?: Maybe<Scalars['Boolean']>;
  documentEmail?: Maybe<Scalars['Boolean']>;
  documentNotification?: Maybe<Scalars['Boolean']>;
  approvalEmail?: Maybe<Scalars['Boolean']>;
  approvalNotification?: Maybe<Scalars['Boolean']>;
  dealEmail?: Maybe<Scalars['Boolean']>;
  dealNotification?: Maybe<Scalars['Boolean']>;
  unitEmail?: Maybe<Scalars['Boolean']>;
  unitNotification?: Maybe<Scalars['Boolean']>;
  seenEmail?: Maybe<Scalars['Boolean']>;
  seenNotification?: Maybe<Scalars['Boolean']>;
}>;


export type UserNotificationsDisableMutation = (
  { __typename?: 'Mutation' }
  & { userNotificationsDisable: (
    { __typename?: 'DisabledNotifications' }
    & { deals?: Maybe<(
      { __typename?: 'IDisabledNotification' }
      & Pick<IDisabledNotification, 'email' | 'notification'>
    )>, users?: Maybe<(
      { __typename?: 'IDisabledNotification' }
      & Pick<IDisabledNotification, 'email' | 'notification'>
    )>, seen?: Maybe<(
      { __typename?: 'IDisabledNotification' }
      & Pick<IDisabledNotification, 'email' | 'notification'>
    )>, documents?: Maybe<(
      { __typename?: 'IDisabledNotification' }
      & Pick<IDisabledNotification, 'email' | 'notification'>
    )>, units?: Maybe<(
      { __typename?: 'IDisabledNotification' }
      & Pick<IDisabledNotification, 'email' | 'notification'>
    )>, approvals?: Maybe<(
      { __typename?: 'IDisabledNotification' }
      & Pick<IDisabledNotification, 'email' | 'notification'>
    )> }
  ) }
);

export type TrustedUsersInviteMutationVariables = Exact<{
  organisationID: Scalars['ID'];
  emails: Array<Scalars['String']> | Scalars['String'];
}>;


export type TrustedUsersInviteMutation = (
  { __typename?: 'Mutation' }
  & { trustedUsersInvite: (
    { __typename?: 'MultiInviteDetails' }
    & { users: Array<(
      { __typename?: 'InviteTokenUser' }
      & InvitedUserFragment
    )>, organisation: (
      { __typename?: 'Organisation' }
      & Pick<Organisation, '_id' | 'name'>
    ) }
  ) }
);

export type InviteUserMutationVariables = Exact<{
  organisationID: Scalars['ID'];
  emails: Array<Scalars['String']> | Scalars['String'];
  type: UserType;
  resend?: Maybe<Scalars['Boolean']>;
}>;


export type InviteUserMutation = (
  { __typename?: 'Mutation' }
  & { userInvite: (
    { __typename?: 'MultiInviteDetails' }
    & { users: Array<(
      { __typename?: 'InviteTokenUser' }
      & InvitedUserFragment
    )>, organisation: (
      { __typename?: 'Organisation' }
      & Pick<Organisation, '_id' | 'name'>
    ) }
  ) }
);

export type RemoveMemberMutationVariables = Exact<{
  organisationID: Scalars['ID'];
  userID: Scalars['ID'];
}>;


export type RemoveMemberMutation = (
  { __typename?: 'Mutation' }
  & { organisationMemberRemove: (
    { __typename?: 'Organisation' }
    & Pick<Organisation, '_id'>
    & { members: Array<(
      { __typename?: 'User' }
      & Pick<User, '_id' | 'firstName' | 'lastName' | 'email' | 'pending'>
    )> }
  ) }
);

export type RemovePendingMemberMutationVariables = Exact<{
  organisationID: Scalars['ID'];
  email: Scalars['String'];
}>;


export type RemovePendingMemberMutation = (
  { __typename?: 'Mutation' }
  & { organisationPendingMemberRemove: (
    { __typename?: 'Organisation' }
    & Pick<Organisation, '_id' | 'pendingMembers'>
  ) }
);

export type UpdateOrganisationMutationVariables = Exact<{
  id: Scalars['ID'];
  input: OrganisationUpdateInput;
}>;


export type UpdateOrganisationMutation = (
  { __typename?: 'Mutation' }
  & { organisationUpdate: (
    { __typename?: 'Organisation' }
    & Pick<Organisation, '_id' | 'name' | 'website' | 'practiceType'>
    & { address: (
      { __typename?: 'AddressType' }
      & Pick<AddressType, 'line1' | 'line2' | 'city' | 'postCode'>
    ) }
  ) }
);

export type UpdateUserMutationVariables = Exact<{
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  mobile?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  currentPassword?: Maybe<Scalars['String']>;
  is2FAEnabled?: Maybe<Scalars['Boolean']>;
}>;


export type UpdateUserMutation = (
  { __typename?: 'Mutation' }
  & { userUpdate: (
    { __typename?: 'User' }
    & Pick<User, '_id' | 'firstName' | 'lastName' | 'email' | 'mobile'>
  ) }
);

export type GetAllUsersQueryVariables = Exact<{
  query?: Maybe<Scalars['String']>;
  filter?: Maybe<UserFilter>;
  sort?: Maybe<UserSort>;
}>;


export type GetAllUsersQuery = (
  { __typename?: 'Query' }
  & { users: Array<(
    { __typename?: 'User' }
    & Pick<User, '_id' | 'type' | 'firstName' | 'lastName' | 'email' | 'mobile' | 'locked' | 'created' | 'lastLogin' | 'activated'>
    & { organisation: (
      { __typename?: 'Organisation' }
      & Pick<Organisation, 'name' | 'avatar' | '_id' | 'created' | 'website'>
      & { address: (
        { __typename?: 'AddressType' }
        & Pick<AddressType, 'line1' | 'line2' | 'city' | 'country' | 'postCode'>
      ) }
    ) }
  )> }
);

export type UserFilterCountsQueryVariables = Exact<{
  filter?: Maybe<UserFilter>;
  query?: Maybe<Scalars['String']>;
}>;


export type UserFilterCountsQuery = (
  { __typename?: 'Query' }
  & { userFilterCounts: (
    { __typename?: 'UserFilterCount' }
    & Pick<UserFilterCount, 'all' | 'admin' | 'landlord' | 'agent' | 'tenant'>
  ) }
);

export type GetUserOrganisationQueryVariables = Exact<{
  filter?: Maybe<UserFilter>;
  sort?: Maybe<UserSort>;
}>;


export type GetUserOrganisationQuery = (
  { __typename?: 'Query' }
  & { user: (
    { __typename?: 'User' }
    & Pick<User, '_id' | 'locked' | 'removed'>
    & { organisation: (
      { __typename?: 'Organisation' }
      & Pick<Organisation, '_id' | 'name' | 'website' | 'avatar' | 'type' | 'practiceType' | 'pendingMembers'>
      & { straightToLease?: Maybe<(
        { __typename?: 'StraightToLease' }
        & Pick<StraightToLease, 'start' | 'end'>
      )>, address: (
        { __typename?: 'AddressType' }
        & Pick<AddressType, 'line1' | 'line2' | 'city' | 'postCode'>
      ), members: Array<(
        { __typename?: 'User' }
        & OrganisationMemberFragment
      )>, trustedUsers: Array<(
        { __typename?: 'User' }
        & Pick<User, '_id' | 'firstName' | 'lastName' | 'type' | 'email' | 'mobile' | 'pending' | 'lastLogin' | 'activated'>
        & { organisation: (
          { __typename?: 'Organisation' }
          & Pick<Organisation, '_id' | 'name'>
        ) }
      )> }
    ), associated: Array<(
      { __typename?: 'Organisation' }
      & Pick<Organisation, '_id' | 'name' | 'avatar'>
      & { members: Array<(
        { __typename?: 'User' }
        & OrganisationMemberFragment
      )> }
    )>, practiceInvites?: Maybe<Array<(
      { __typename?: 'PracticeInvite' }
      & Pick<PracticeInvite, '_id'>
      & { organisation: (
        { __typename?: 'Organisation' }
        & Pick<Organisation, '_id' | 'name'>
      ) }
    )>> }
  ) }
);

export type UserDetailsFragment = (
  { __typename?: 'User' }
  & Pick<User, '_id' | 'firstName' | 'lastName' | 'mobile' | 'email' | 'type' | 'lastLogin' | 'activated' | 'locked' | 'is2FAEnabled' | 'hash'>
  & { organisation: (
    { __typename?: 'Organisation' }
    & Pick<Organisation, '_id' | 'name'>
  ) }
);

export type GetUserProfileQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUserProfileQuery = (
  { __typename?: 'Query' }
  & { user: (
    { __typename?: 'User' }
    & { disabledNotifications?: Maybe<(
      { __typename?: 'DisabledNotifications' }
      & { deals?: Maybe<(
        { __typename?: 'IDisabledNotification' }
        & Pick<IDisabledNotification, 'email' | 'notification'>
      )>, seen?: Maybe<(
        { __typename?: 'IDisabledNotification' }
        & Pick<IDisabledNotification, 'email' | 'notification'>
      )>, users?: Maybe<(
        { __typename?: 'IDisabledNotification' }
        & Pick<IDisabledNotification, 'email' | 'notification'>
      )>, documents?: Maybe<(
        { __typename?: 'IDisabledNotification' }
        & Pick<IDisabledNotification, 'email' | 'notification'>
      )>, approvals?: Maybe<(
        { __typename?: 'IDisabledNotification' }
        & Pick<IDisabledNotification, 'email' | 'notification'>
      )>, units?: Maybe<(
        { __typename?: 'IDisabledNotification' }
        & Pick<IDisabledNotification, 'email' | 'notification'>
      )> }
    )> }
    & UserDetailsFragment
  ) }
);

export type SaveTemplateDetailsMutationVariables = Exact<{
  templateID: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  global?: Maybe<Scalars['Boolean']>;
  associatedUses?: Maybe<Array<UnitUse> | UnitUse>;
}>;


export type SaveTemplateDetailsMutation = (
  { __typename?: 'Mutation' }
  & { templateUpdate: (
    { __typename?: 'Template' }
    & Pick<Template, '_id' | 'name' | 'description' | 'associatedUses' | 'global'>
  ) }
);

export type GetTemplateDetailsQueryVariables = Exact<{
  templateID: Scalars['ID'];
}>;


export type GetTemplateDetailsQuery = (
  { __typename?: 'Query' }
  & { template: (
    { __typename?: 'Template' }
    & Pick<Template, '_id' | 'name' | 'description' | 'associatedUses' | 'global' | 'created'>
    & { organisation: (
      { __typename?: 'Organisation' }
      & Pick<Organisation, '_id' | 'name'>
    ), deal: (
      { __typename?: 'Deal' }
      & DealDetailsFragment
    ), creator: (
      { __typename?: 'User' }
      & Pick<User, 'firstName' | 'lastName'>
    ) }
  ) }
);

export type DeleteTemplateMutationVariables = Exact<{
  templateID: Scalars['ID'];
  permanent: Scalars['Boolean'];
}>;


export type DeleteTemplateMutation = (
  { __typename?: 'Mutation' }
  & { templateDelete: (
    { __typename?: 'TemplateDelete' }
    & Pick<TemplateDelete, 'deletedTemplateID'>
  ) }
);

export type TemplateDuplicateMutationVariables = Exact<{
  organisation: Scalars['String'];
  templateID: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  associatedUses?: Maybe<Array<UnitUse> | UnitUse>;
}>;


export type TemplateDuplicateMutation = (
  { __typename?: 'Mutation' }
  & { templateDuplicate: (
    { __typename?: 'Template' }
    & Pick<Template, '_id' | 'name' | 'description' | 'associatedUses'>
  ) }
);

export type NewTemplateMutationVariables = Exact<{
  organisationID?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  description: Scalars['String'];
  associatedUses?: Maybe<Array<UnitUse> | UnitUse>;
}>;


export type NewTemplateMutation = (
  { __typename?: 'Mutation' }
  & { templateCreate: (
    { __typename?: 'Template' }
    & Pick<Template, '_id' | 'name' | 'associatedUses'>
    & { deal: (
      { __typename?: 'Deal' }
      & Pick<Deal, '_id'>
      & { sections: Array<(
        { __typename?: 'Section' }
        & Pick<Section, '_id' | 'title' | 'index'>
        & { groups: Array<(
          { __typename?: 'Group' }
          & Pick<Group, '_id' | 'title' | 'index'>
          & { clauses: Array<(
            { __typename?: 'Clause' }
            & Pick<Clause, '_id' | 'index' | 'value' | 'visible' | 'valueSuffix' | 'shortForm' | 'longForm'>
            & { dealBreaker: (
              { __typename?: 'DealBreaker' }
              & Pick<DealBreaker, 'isDealBreaker' | 'message'>
            ), groups?: Maybe<Array<(
              { __typename?: 'Group' }
              & Pick<Group, '_id' | 'title' | 'index'>
              & { clauses: Array<(
                { __typename?: 'Clause' }
                & Pick<Clause, '_id' | 'index' | 'value' | 'visible' | 'valueSuffix' | 'shortForm' | 'longForm'>
                & { dealBreaker: (
                  { __typename?: 'DealBreaker' }
                  & Pick<DealBreaker, 'isDealBreaker' | 'message'>
                ) }
              )> }
            )>> }
          )> }
        )> }
      )> }
    ) }
  ) }
);

export type RestoreTemplateMutationVariables = Exact<{
  templateID: Scalars['ID'];
}>;


export type RestoreTemplateMutation = (
  { __typename?: 'Mutation' }
  & { templateRestore: (
    { __typename?: 'Template' }
    & Pick<Template, '_id'>
  ) }
);

export type TemplateHideMutationVariables = Exact<{
  templateID: Scalars['ID'];
}>;


export type TemplateHideMutation = (
  { __typename?: 'Mutation' }
  & { templateHide: (
    { __typename?: 'User' }
    & Pick<User, '_id'>
  ) }
);

export type TemplateUnhideMutationVariables = Exact<{
  templateID: Scalars['ID'];
}>;


export type TemplateUnhideMutation = (
  { __typename?: 'Mutation' }
  & { templateUnhide: (
    { __typename?: 'User' }
    & Pick<User, '_id'>
  ) }
);

export type GetTemplateCardDetailsQueryVariables = Exact<{
  filter?: Maybe<TemplateFilter>;
  sort?: Maybe<TemplateSort>;
  query?: Maybe<Scalars['String']>;
}>;


export type GetTemplateCardDetailsQuery = (
  { __typename?: 'Query' }
  & { templates: (
    { __typename?: 'Templates' }
    & { results: Array<(
      { __typename?: 'Template' }
      & Pick<Template, '_id' | 'name' | 'description' | 'global' | 'hasAccess' | 'created' | 'associatedUses'>
      & { creator: (
        { __typename?: 'User' }
        & Pick<User, '_id' | 'firstName' | 'lastName'>
      ), organisation: (
        { __typename?: 'Organisation' }
        & Pick<Organisation, '_id' | 'name'>
      ) }
    )>, pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'total'>
    ) }
  ) }
);

export type GetTemplateOrganisationsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetTemplateOrganisationsQuery = (
  { __typename?: 'Query' }
  & { templates: (
    { __typename?: 'Templates' }
    & { results: Array<(
      { __typename?: 'Template' }
      & { organisation: (
        { __typename?: 'Organisation' }
        & Pick<Organisation, '_id' | 'name'>
      ) }
    )>, pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'total'>
    ) }
  ) }
);

export type GetTemplateCreatorsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetTemplateCreatorsQuery = (
  { __typename?: 'Query' }
  & { templates: (
    { __typename?: 'Templates' }
    & { results: Array<(
      { __typename?: 'Template' }
      & { creator: (
        { __typename?: 'User' }
        & Pick<User, '_id' | 'firstName' | 'lastName'>
      ) }
    )>, pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'total'>
    ) }
  ) }
);

export type TemplateFilterCountsQueryVariables = Exact<{
  filter?: Maybe<TemplateFilter>;
}>;


export type TemplateFilterCountsQuery = (
  { __typename?: 'Query' }
  & { templateFilterCounts: (
    { __typename?: 'TemplateFilterCount' }
    & Pick<TemplateFilterCount, 'live' | 'bin' | 'hidden'>
  ) }
);

export type ActionTaskMutationVariables = Exact<{
  unitID: Scalars['ID'];
  taskID: Scalars['ID'];
  applicable?: Maybe<Scalars['Boolean']>;
  stringValue?: Maybe<Scalars['String']>;
  floatValue?: Maybe<Scalars['Float']>;
  booleanValue?: Maybe<Scalars['Boolean']>;
  fileValue?: Maybe<Array<FileInput> | FileInput>;
}>;


export type ActionTaskMutation = (
  { __typename?: 'Mutation' }
  & { unitTaskAction: (
    { __typename?: 'Task' }
    & Pick<Task, '_id' | 'applicable'>
    & { value?: Maybe<(
      { __typename?: 'BooleanValue' }
      & Pick<BooleanValue, 'booleanValue'>
    ) | (
      { __typename?: 'File' }
      & Pick<File, '_id' | 'name' | 'url'>
    ) | (
      { __typename?: 'FilesValue' }
      & { filesValue: Array<(
        { __typename?: 'File' }
        & Pick<File, '_id' | 'name' | 'url'>
      )> }
    ) | (
      { __typename?: 'FloatValue' }
      & Pick<FloatValue, 'floatValue'>
    ) | (
      { __typename?: 'StringValue' }
      & Pick<StringValue, 'stringValue'>
    )> }
  ) }
);

export type UnitTaskRemoveFileMutationVariables = Exact<{
  unitID: Scalars['ID'];
  taskID: Scalars['ID'];
  fileID: Scalars['ID'];
}>;


export type UnitTaskRemoveFileMutation = (
  { __typename?: 'Mutation' }
  & { unitTaskRemoveFile: (
    { __typename?: 'Task' }
    & Pick<Task, '_id' | 'applicable'>
    & { value?: Maybe<(
      { __typename?: 'BooleanValue' }
      & Pick<BooleanValue, 'booleanValue'>
    ) | (
      { __typename?: 'File' }
      & Pick<File, '_id' | 'name' | 'url'>
    ) | (
      { __typename?: 'FilesValue' }
      & { filesValue: Array<(
        { __typename?: 'File' }
        & Pick<File, '_id' | 'name' | 'url'>
      )> }
    ) | (
      { __typename?: 'FloatValue' }
      & Pick<FloatValue, 'floatValue'>
    ) | (
      { __typename?: 'StringValue' }
      & Pick<StringValue, 'stringValue'>
    )> }
  ) }
);

export type TenantActionStageFourMutationVariables = Exact<{
  dealID: Scalars['ID'];
  completed?: Maybe<Scalars['Boolean']>;
  kyc?: Maybe<Scalars['Boolean']>;
  noLawyer?: Maybe<Scalars['Boolean']>;
}>;


export type TenantActionStageFourMutation = (
  { __typename?: 'Mutation' }
  & { dealTenantDetailsSet: (
    { __typename?: 'TenantDetails' }
    & { four?: Maybe<(
      { __typename?: 'TenantStage4' }
      & Pick<TenantStage4, 'edited' | 'completed' | 'kyc' | 'noLawyer'>
      & { files: Array<(
        { __typename?: 'File' }
        & Pick<File, '_id' | 'created' | 'name' | 'url'>
      )> }
    )> }
  ) }
);

export type TenantActionFinalStageMutationVariables = Exact<{
  dealID: Scalars['ID'];
  input: TenantStageFinalInput;
}>;


export type TenantActionFinalStageMutation = (
  { __typename?: 'Mutation' }
  & { dealTenantDetailsSet: (
    { __typename?: 'TenantDetails' }
    & { final?: Maybe<(
      { __typename?: 'TenantStageFinal' }
      & Pick<TenantStageFinal, 'completed' | 'edited' | 'informLandlord'>
    )> }
  ) }
);

export type TenantActionStageFiveMutationVariables = Exact<{
  dealID: Scalars['ID'];
  instructions?: Maybe<Scalars['Boolean']>;
  signed?: Maybe<Scalars['Boolean']>;
  completed?: Maybe<Scalars['Boolean']>;
  noLawyer?: Maybe<Scalars['Boolean']>;
}>;


export type TenantActionStageFiveMutation = (
  { __typename?: 'Mutation' }
  & { dealTenantDetailsSet: (
    { __typename?: 'TenantDetails' }
    & { five?: Maybe<(
      { __typename?: 'TenantStage5' }
      & Pick<TenantStage5, 'completed' | 'edited' | 'noLawyer' | 'instructions' | 'signed'>
    )> }
  ) }
);

export type TenantActionStageFourFileMutationVariables = Exact<{
  dealID: Scalars['ID'];
  file: FileInput;
}>;


export type TenantActionStageFourFileMutation = (
  { __typename?: 'Mutation' }
  & { dealTenantDetailsSet: (
    { __typename?: 'TenantDetails' }
    & { four?: Maybe<(
      { __typename?: 'TenantStage4' }
      & { files: Array<(
        { __typename?: 'File' }
        & Pick<File, '_id' | 'name' | 'created' | 'url'>
      )> }
    )> }
  ) }
);

export type TenantActionStageOneMutationVariables = Exact<{
  dealID: Scalars['ID'];
  completed?: Maybe<Scalars['Boolean']>;
  entity: TenantEntityInput;
  building?: Maybe<TenantEntityInput>;
  isSameAddress?: Maybe<Scalars['Boolean']>;
}>;


export type TenantActionStageOneMutation = (
  { __typename?: 'Mutation' }
  & { dealTenantDetailsSet: (
    { __typename?: 'TenantDetails' }
    & { one?: Maybe<(
      { __typename?: 'TenantStage1' }
      & Pick<TenantStage1, 'isSameAddress' | 'edited' | 'completed'>
      & { entity?: Maybe<(
        { __typename?: 'TenantEntity' }
        & Pick<TenantEntity, 'name' | 'companyNumber'>
        & { address?: Maybe<(
          { __typename?: 'TenantEntityAddress' }
          & Pick<TenantEntityAddress, 'line1' | 'line2' | 'city' | 'postCode'>
        )> }
      )>, building?: Maybe<(
        { __typename?: 'TenantEntity' }
        & Pick<TenantEntity, 'visible'>
        & { address?: Maybe<(
          { __typename?: 'TenantEntityAddress' }
          & Pick<TenantEntityAddress, 'line1' | 'line2' | 'city' | 'postCode'>
        )> }
      )> }
    )> }
  ) }
);

export type TenantActionStageSixMutationVariables = Exact<{
  dealID: Scalars['ID'];
  fitted?: Maybe<Scalars['Boolean']>;
  company?: Maybe<TenantCompanyInput>;
  projectManager?: Maybe<TenantPersonInput>;
  completed?: Maybe<Scalars['Boolean']>;
}>;


export type TenantActionStageSixMutation = (
  { __typename?: 'Mutation' }
  & { dealTenantDetailsSet: (
    { __typename?: 'TenantDetails' }
    & { six?: Maybe<(
      { __typename?: 'TenantStage6' }
      & Pick<TenantStage6, 'edited' | 'completed' | 'fitted'>
      & { company?: Maybe<(
        { __typename?: 'TenantCompany' }
        & Pick<TenantCompany, 'type' | 'name' | 'contactName' | 'email' | 'phone'>
      )>, projectManager?: Maybe<(
        { __typename?: 'TenantPerson' }
        & Pick<TenantPerson, 'name' | 'company' | 'email' | 'phone' | 'visible'>
      )> }
    )> }
  ) }
);

export type TenantActionStageThreeMutationVariables = Exact<{
  dealID: Scalars['ID'];
  audited?: Maybe<Scalars['Boolean']>;
  guarantor?: Maybe<TenantEntityInput>;
  completed?: Maybe<Scalars['Boolean']>;
  noAccountsToShare?: Maybe<Scalars['Boolean']>;
  nominateGuarantor?: Maybe<Scalars['Boolean']>;
}>;


export type TenantActionStageThreeMutation = (
  { __typename?: 'Mutation' }
  & { dealTenantDetailsSet: (
    { __typename?: 'TenantDetails' }
    & { three?: Maybe<(
      { __typename?: 'TenantStage3' }
      & Pick<TenantStage3, 'edited' | 'audited' | 'completed' | 'noAccountsToShare' | 'nominateGuarantor'>
      & { files: Array<(
        { __typename?: 'File' }
        & Pick<File, '_id' | 'name' | 'created' | 'url'>
      )>, guarantor?: Maybe<(
        { __typename?: 'TenantEntity' }
        & Pick<TenantEntity, 'name' | 'company' | 'jurisdiction' | 'visible'>
        & { address?: Maybe<(
          { __typename?: 'TenantEntityAddress' }
          & Pick<TenantEntityAddress, 'line1' | 'line2' | 'city' | 'postCode'>
        )> }
      )> }
    )> }
  ) }
);

export type TenantActionStageThreeFileMutationVariables = Exact<{
  dealID: Scalars['ID'];
  file: FileInput;
}>;


export type TenantActionStageThreeFileMutation = (
  { __typename?: 'Mutation' }
  & { dealTenantDetailsSet: (
    { __typename?: 'TenantDetails' }
    & { three?: Maybe<(
      { __typename?: 'TenantStage3' }
      & { files: Array<(
        { __typename?: 'File' }
        & Pick<File, '_id' | 'name' | 'created' | 'url'>
      )> }
    )> }
  ) }
);

export type TenantActionStageTwoMutationVariables = Exact<{
  dealID: Scalars['ID'];
  completed?: Maybe<Scalars['Boolean']>;
  lawyer?: Maybe<TenantPersonInput>;
  agent?: Maybe<TenantPersonInput>;
}>;


export type TenantActionStageTwoMutation = (
  { __typename?: 'Mutation' }
  & { dealTenantDetailsSet: (
    { __typename?: 'TenantDetails' }
    & { two?: Maybe<(
      { __typename?: 'TenantStage2' }
      & Pick<TenantStage2, 'completed' | 'edited'>
      & { lawyer?: Maybe<(
        { __typename?: 'TenantPerson' }
        & Pick<TenantPerson, 'name' | 'company' | 'email' | 'phone' | 'invite'>
      )>, agent?: Maybe<(
        { __typename?: 'TenantPerson' }
        & Pick<TenantPerson, 'name' | 'company' | 'email' | 'phone' | 'invite' | 'visible'>
      )> }
    )> }
  ) }
);

export type TenantSendInviteMutationVariables = Exact<{
  dealID: Scalars['ID'];
  type: InviteFromStageType;
  email: Scalars['String'];
}>;


export type TenantSendInviteMutation = (
  { __typename?: 'Mutation' }
  & { dealInviteFromStage: (
    { __typename?: 'DealInviteFromStageResponse' }
    & Pick<DealInviteFromStageResponse, 'email'>
  ) }
);

export type SingleUnitFragment = (
  { __typename?: 'Unit' }
  & Pick<Unit, '_id' | 'name' | 'area' | 'url' | 'use' | 'condition' | 'buildingName' | 'pending' | 'avatar' | 'rating' | 'available' | 'created'>
  & { deals: Array<(
    { __typename?: 'Deal' }
    & UnitSingleDealFragment
  )>, files: Array<(
    { __typename?: 'File' }
    & UnitSingleFileFragment
  )>, tasks: Array<(
    { __typename?: 'Task' }
    & UnitSingleTaskFragment
  )>, address: (
    { __typename?: 'AddressType' }
    & UnitAddressFragment
  ), actors: Array<(
    { __typename?: 'User' }
    & UnitSingleActorFragment
  )>, organisation: (
    { __typename?: 'Organisation' }
    & UnitOrganisationFragment
  ), creator?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, '_id' | 'firstName' | 'lastName'>
  )> }
);

export type TenantDetailsFragment = (
  { __typename?: 'TenantDetails' }
  & { one?: Maybe<(
    { __typename?: 'TenantStage1' }
    & Pick<TenantStage1, 'edited' | 'completed' | 'isSameAddress'>
    & { entity?: Maybe<(
      { __typename?: 'TenantEntity' }
      & Pick<TenantEntity, 'name' | 'companyNumber'>
      & { address?: Maybe<(
        { __typename?: 'TenantEntityAddress' }
        & Pick<TenantEntityAddress, 'line1' | 'line2' | 'city' | 'postCode'>
      )> }
    )>, building?: Maybe<(
      { __typename?: 'TenantEntity' }
      & Pick<TenantEntity, 'visible'>
      & { address?: Maybe<(
        { __typename?: 'TenantEntityAddress' }
        & Pick<TenantEntityAddress, 'line1' | 'line2' | 'city' | 'postCode'>
      )> }
    )> }
  )>, two?: Maybe<(
    { __typename?: 'TenantStage2' }
    & Pick<TenantStage2, 'edited' | 'completed'>
    & { lawyer?: Maybe<(
      { __typename?: 'TenantPerson' }
      & Pick<TenantPerson, 'name' | 'company' | 'email' | 'phone' | 'invite' | 'visible'>
    )>, agent?: Maybe<(
      { __typename?: 'TenantPerson' }
      & Pick<TenantPerson, 'name' | 'company' | 'email' | 'phone' | 'invite' | 'visible'>
    )> }
  )>, three?: Maybe<(
    { __typename?: 'TenantStage3' }
    & Pick<TenantStage3, 'edited' | 'completed' | 'audited' | 'noAccountsToShare' | 'nominateGuarantor'>
    & { files: Array<(
      { __typename?: 'File' }
      & Pick<File, '_id' | 'created' | 'name' | 'url'>
    )>, guarantor?: Maybe<(
      { __typename?: 'TenantEntity' }
      & Pick<TenantEntity, 'name' | 'company' | 'jurisdiction' | 'visible'>
      & { address?: Maybe<(
        { __typename?: 'TenantEntityAddress' }
        & Pick<TenantEntityAddress, 'line1' | 'line2' | 'city' | 'postCode'>
      )> }
    )> }
  )>, four?: Maybe<(
    { __typename?: 'TenantStage4' }
    & Pick<TenantStage4, 'edited' | 'completed' | 'noLawyer' | 'kyc'>
    & { files: Array<(
      { __typename?: 'File' }
      & Pick<File, '_id' | 'created' | 'name' | 'url'>
    )> }
  )>, five?: Maybe<(
    { __typename?: 'TenantStage5' }
    & Pick<TenantStage5, 'noLawyer' | 'edited' | 'completed' | 'instructions' | 'signed'>
  )>, six?: Maybe<(
    { __typename?: 'TenantStage6' }
    & Pick<TenantStage6, 'edited' | 'completed' | 'fitted'>
    & { company?: Maybe<(
      { __typename?: 'TenantCompany' }
      & Pick<TenantCompany, 'type' | 'name' | 'contactName' | 'email' | 'phone'>
    )>, projectManager?: Maybe<(
      { __typename?: 'TenantPerson' }
      & Pick<TenantPerson, 'name' | 'company' | 'email' | 'phone' | 'visible'>
    )> }
  )>, team?: Maybe<(
    { __typename?: 'TenantStageTeam' }
    & Pick<TenantStageTeam, 'edited' | 'completed'>
    & { tenant?: Maybe<(
      { __typename?: 'TenantPerson' }
      & Pick<TenantPerson, 'name' | 'company' | 'email' | 'phone' | 'invite' | 'visible'>
    )>, agent?: Maybe<(
      { __typename?: 'TenantPerson' }
      & Pick<TenantPerson, 'name' | 'company' | 'email' | 'phone' | 'invite' | 'visible'>
    )> }
  )>, aml?: Maybe<(
    { __typename?: 'TenantStageAML' }
    & Pick<TenantStageAml, 'completed' | 'edited' | 'companyName' | 'companyJurisdiction' | 'companyRegistrationNumber'>
    & { personalDetails?: Maybe<Array<(
      { __typename?: 'TenantPerson' }
      & Pick<TenantPerson, 'name' | 'phone'>
    )>> }
  )>, final?: Maybe<(
    { __typename?: 'TenantStageFinal' }
    & Pick<TenantStageFinal, 'edited' | 'completed' | 'informLandlord'>
  )> }
);

export type UnitAddressFragment = (
  { __typename?: 'AddressType' }
  & Pick<AddressType, 'line1' | 'line2' | 'city' | 'county' | 'postCode'>
);

export type UnitOrganisationSingleMemberFragment = (
  { __typename?: 'User' }
  & Pick<User, '_id' | 'firstName' | 'lastName' | 'email' | 'mobile' | 'type' | 'pending'>
);

export type UnitOrganisationFragment = (
  { __typename?: 'Organisation' }
  & Pick<Organisation, '_id' | 'name' | 'certification'>
  & { members: Array<(
    { __typename?: 'User' }
    & UnitOrganisationSingleMemberFragment
  )> }
);

export type UnitSingleActorFragment = (
  { __typename?: 'User' }
  & Pick<User, '_id' | 'firstName' | 'lastName' | 'email' | 'mobile' | 'type' | 'pending'>
  & { organisation: (
    { __typename?: 'Organisation' }
    & Pick<Organisation, '_id' | 'name'>
  ) }
);

export type UnitSingleDealFileFragment = (
  { __typename?: 'File' }
  & Pick<File, '_id' | 'other' | 'created' | 'name' | 'url'>
  & { user: (
    { __typename?: 'User' }
    & Pick<User, '_id' | 'firstName' | 'lastName'>
    & { organisation: (
      { __typename?: 'Organisation' }
      & Pick<Organisation, 'name'>
    ) }
  ) }
);

export type UnitSingleDealFragment = (
  { __typename?: 'Deal' }
  & Pick<Deal, '_id' | 'pending' | 'state' | 'isPinned' | 'currentUserParty' | 'created' | 'hasHiddenUnit'>
  & { round?: Maybe<(
    { __typename?: 'DealRoundType' }
    & Pick<DealRoundType, 'count' | 'nudge' | 'date'>
  )>, clauseSummary: (
    { __typename?: 'ClauseSummary' }
    & Pick<ClauseSummary, 'incomplete' | 'complete'>
  ), files: Array<(
    { __typename?: 'File' }
    & UnitSingleDealFileFragment
  )>, tenant?: Maybe<(
    { __typename?: 'Organisation' }
    & Pick<Organisation, 'name'>
  )>, unit?: Maybe<(
    { __typename?: 'Unit' }
    & Pick<Unit, 'url' | 'name' | 'buildingName'>
    & { organisation: (
      { __typename?: 'Organisation' }
      & Pick<Organisation, 'name'>
    ) }
  )> }
);

export type UnitSingleFileFragment = (
  { __typename?: 'File' }
  & Pick<File, '_id' | 'other' | 'created' | 'name' | 'url'>
  & { downloads: Array<(
    { __typename?: 'UserDownload' }
    & Pick<UserDownload, '_id' | 'user' | 'timestamp'>
  )> }
);

export type UnitSingleTaskFragment = (
  { __typename?: 'Task' }
  & Pick<Task, '_id' | 'applicable' | 'edited'>
  & { value?: Maybe<(
    { __typename?: 'BooleanValue' }
    & Pick<BooleanValue, 'booleanValue'>
  ) | (
    { __typename?: 'File' }
    & Pick<File, '_id' | 'name' | 'url'>
    & { downloads: Array<(
      { __typename?: 'UserDownload' }
      & Pick<UserDownload, '_id' | 'user' | 'timestamp'>
    )> }
  ) | (
    { __typename?: 'FilesValue' }
    & { filesValue: Array<(
      { __typename?: 'File' }
      & Pick<File, '_id' | 'name' | 'url'>
      & { downloads: Array<(
        { __typename?: 'UserDownload' }
        & Pick<UserDownload, '_id' | 'user' | 'timestamp'>
      )> }
    )> }
  ) | (
    { __typename?: 'FloatValue' }
    & Pick<FloatValue, 'floatValue'>
  ) | (
    { __typename?: 'StringValue' }
    & Pick<StringValue, 'stringValue'>
  )>, task: (
    { __typename?: 'AdminTask' }
    & Pick<AdminTask, 'tags' | '_id' | 'title' | 'index' | 'weight' | 'optional' | 'type'>
  ) }
);

export type AddActorToUnitMutationVariables = Exact<{
  unitID: Scalars['ID'];
  emails: Array<Scalars['String']> | Scalars['String'];
  type: UserType;
  asMember?: Maybe<Scalars['Boolean']>;
}>;


export type AddActorToUnitMutation = (
  { __typename?: 'Mutation' }
  & { unitAddActor: (
    { __typename?: 'Unit' }
    & Pick<Unit, '_id'>
    & { actors: Array<(
      { __typename?: 'User' }
      & Pick<User, '_id' | 'firstName' | 'lastName' | 'email' | 'mobile' | 'type' | 'pending'>
      & { organisation: (
        { __typename?: 'Organisation' }
        & Pick<Organisation, '_id' | 'name'>
      ) }
    )> }
  ) }
);

export type RemoveUserFromUnitMutationVariables = Exact<{
  unitID: Scalars['ID'];
  userID: Scalars['ID'];
}>;


export type RemoveUserFromUnitMutation = (
  { __typename?: 'Mutation' }
  & { unitRemoveActor: (
    { __typename?: 'Unit' }
    & Pick<Unit, '_id'>
    & { actors: Array<(
      { __typename?: 'User' }
      & Pick<User, '_id' | 'firstName' | 'lastName' | 'email' | 'mobile' | 'type' | 'pending'>
      & { organisation: (
        { __typename?: 'Organisation' }
        & Pick<Organisation, '_id' | 'name'>
      ) }
    )> }
  ) }
);

export type UnitBulkAddActorsMutationVariables = Exact<{
  dealIDs: Array<Scalars['ID']> | Scalars['ID'];
  emails: Array<Scalars['String']> | Scalars['String'];
  all: Scalars['Boolean'];
  filter: UnitFilter;
}>;


export type UnitBulkAddActorsMutation = (
  { __typename?: 'Mutation' }
  & { unitBulkAddActors: Array<(
    { __typename?: 'User' }
    & Pick<User, '_id'>
  )> }
);

export type UnitBulkHideMutationVariables = Exact<{
  unitIDs: Array<Scalars['ID']> | Scalars['ID'];
  all: Scalars['Boolean'];
  filter: UnitFilter;
  hideConnectedDeals?: Maybe<Scalars['Boolean']>;
  showHiddenDealsOnActive?: Maybe<Scalars['Boolean']>;
}>;


export type UnitBulkHideMutation = (
  { __typename?: 'Mutation' }
  & { unitBulkHide: (
    { __typename?: 'User' }
    & Pick<User, '_id'>
  ) }
);

export type UnitBulkUnhideMutationVariables = Exact<{
  unitIDs: Array<Scalars['ID']> | Scalars['ID'];
  all: Scalars['Boolean'];
  filter: UnitFilter;
  unhideConnectedDeals?: Maybe<Scalars['Boolean']>;
}>;


export type UnitBulkUnhideMutation = (
  { __typename?: 'Mutation' }
  & { unitBulkUnhide: (
    { __typename?: 'User' }
    & Pick<User, '_id'>
  ) }
);

export type UpdateUnitMutationVariables = Exact<{
  unitID: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  buildingName?: Maybe<Scalars['String']>;
  area?: Maybe<Scalars['Int']>;
  line1?: Maybe<Scalars['String']>;
  line2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  postCode?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  avatar?: Maybe<Scalars['String']>;
  straightToLease?: Maybe<Scalars['Boolean']>;
  condition?: Maybe<UnitCondition>;
  available?: Maybe<UnitAvailability>;
}>;


export type UpdateUnitMutation = (
  { __typename?: 'Mutation' }
  & { unitUpdate: (
    { __typename?: 'Unit' }
    & Pick<Unit, '_id' | 'name' | 'area' | 'url' | 'avatar' | 'use' | 'condition' | 'straightToLease' | 'available' | 'buildingName'>
  ) }
);

export type VerifyUnitMutationVariables = Exact<{
  token: Scalars['String'];
}>;


export type VerifyUnitMutation = (
  { __typename?: 'Mutation' }
  & { unitVerify: (
    { __typename?: 'Unit' }
    & Pick<Unit, '_id' | 'pending'>
  ) }
);

export type GetBuildingUpdateDetailsQueryVariables = Exact<{
  unitID: Scalars['ID'];
}>;


export type GetBuildingUpdateDetailsQuery = (
  { __typename?: 'Query' }
  & { unit: (
    { __typename?: 'Unit' }
    & Pick<Unit, '_id' | 'name' | 'url' | 'buildingName' | 'avatar' | 'area' | 'use' | 'condition' | 'straightToLease'>
    & { address: (
      { __typename?: 'AddressType' }
      & Pick<AddressType, 'line1' | 'line2' | 'city' | 'postCode'>
    ) }
  ) }
);

export type GetDealTenantDetailsQueryVariables = Exact<{
  dealID: Scalars['ID'];
  token?: Maybe<Scalars['String']>;
}>;


export type GetDealTenantDetailsQuery = (
  { __typename?: 'Query' }
  & { deal: (
    { __typename?: 'Deal' }
    & Pick<Deal, '_id'>
    & { unit?: Maybe<(
      { __typename?: 'Unit' }
      & Pick<Unit, '_id'>
    )>, tenant?: Maybe<(
      { __typename?: 'Organisation' }
      & { members: Array<(
        { __typename?: 'User' }
        & Pick<User, '_id' | 'type' | 'firstName' | 'lastName' | 'email' | 'mobile'>
        & { organisation: (
          { __typename?: 'Organisation' }
          & Pick<Organisation, 'name'>
        ) }
      )> }
    )>, details: (
      { __typename?: 'TenantDetails' }
      & { one?: Maybe<(
        { __typename?: 'TenantStage1' }
        & Pick<TenantStage1, 'edited' | 'completed' | 'isSameAddress'>
        & { entity?: Maybe<(
          { __typename?: 'TenantEntity' }
          & Pick<TenantEntity, 'name' | 'companyNumber'>
          & { address?: Maybe<(
            { __typename?: 'TenantEntityAddress' }
            & Pick<TenantEntityAddress, 'line1' | 'line2' | 'city' | 'postCode'>
          )> }
        )>, building?: Maybe<(
          { __typename?: 'TenantEntity' }
          & Pick<TenantEntity, 'visible'>
          & { address?: Maybe<(
            { __typename?: 'TenantEntityAddress' }
            & Pick<TenantEntityAddress, 'line1' | 'line2' | 'city' | 'postCode'>
          )> }
        )> }
      )>, two?: Maybe<(
        { __typename?: 'TenantStage2' }
        & Pick<TenantStage2, 'edited' | 'completed'>
        & { lawyer?: Maybe<(
          { __typename?: 'TenantPerson' }
          & Pick<TenantPerson, 'name' | 'company' | 'email' | 'invite' | 'phone' | 'visible'>
        )>, agent?: Maybe<(
          { __typename?: 'TenantPerson' }
          & Pick<TenantPerson, 'name' | 'company' | 'email' | 'invite' | 'visible'>
        )> }
      )>, three?: Maybe<(
        { __typename?: 'TenantStage3' }
        & Pick<TenantStage3, 'edited' | 'audited' | 'completed' | 'noAccountsToShare' | 'nominateGuarantor'>
        & { files: Array<(
          { __typename?: 'File' }
          & Pick<File, '_id' | 'created' | 'name' | 'url'>
        )>, guarantor?: Maybe<(
          { __typename?: 'TenantEntity' }
          & Pick<TenantEntity, 'name' | 'company' | 'jurisdiction' | 'visible'>
          & { address?: Maybe<(
            { __typename?: 'TenantEntityAddress' }
            & Pick<TenantEntityAddress, 'line1' | 'line2' | 'city' | 'postCode'>
          )> }
        )> }
      )>, four?: Maybe<(
        { __typename?: 'TenantStage4' }
        & Pick<TenantStage4, 'edited' | 'noLawyer' | 'completed' | 'kyc'>
        & { files: Array<(
          { __typename?: 'File' }
          & Pick<File, '_id' | 'created' | 'name' | 'url'>
        )> }
      )>, five?: Maybe<(
        { __typename?: 'TenantStage5' }
        & Pick<TenantStage5, 'edited' | 'noLawyer' | 'completed' | 'instructions' | 'signed'>
      )>, six?: Maybe<(
        { __typename?: 'TenantStage6' }
        & Pick<TenantStage6, 'edited' | 'completed' | 'fitted'>
        & { company?: Maybe<(
          { __typename?: 'TenantCompany' }
          & Pick<TenantCompany, 'type' | 'name' | 'contactName' | 'email' | 'phone'>
        )>, projectManager?: Maybe<(
          { __typename?: 'TenantPerson' }
          & Pick<TenantPerson, 'name' | 'company' | 'email' | 'phone' | 'visible'>
        )> }
      )>, team?: Maybe<(
        { __typename?: 'TenantStageTeam' }
        & Pick<TenantStageTeam, 'edited' | 'completed'>
        & { agent?: Maybe<(
          { __typename?: 'TenantPerson' }
          & Pick<TenantPerson, 'name' | 'company' | 'email' | 'phone' | 'visible'>
        )>, tenant?: Maybe<(
          { __typename?: 'TenantPerson' }
          & Pick<TenantPerson, 'name' | 'company' | 'email' | 'phone'>
        )> }
      )>, aml?: Maybe<(
        { __typename?: 'TenantStageAML' }
        & Pick<TenantStageAml, 'completed' | 'edited' | 'companyName' | 'companyJurisdiction' | 'companyRegistrationNumber'>
        & { personalDetails?: Maybe<Array<(
          { __typename?: 'TenantPerson' }
          & Pick<TenantPerson, 'name' | 'phone'>
        )>> }
      )>, final?: Maybe<(
        { __typename?: 'TenantStageFinal' }
        & Pick<TenantStageFinal, 'edited' | 'completed' | 'informLandlord'>
      )> }
    ) }
  ) }
);

export type GetDealUnitDetailsQueryVariables = Exact<{
  dealID: Scalars['ID'];
}>;


export type GetDealUnitDetailsQuery = (
  { __typename?: 'Query' }
  & { deal: (
    { __typename?: 'Deal' }
    & Pick<Deal, '_id' | 'pending' | 'state' | 'isPinned' | 'currentUserParty'>
    & { round?: Maybe<(
      { __typename?: 'DealRoundType' }
      & Pick<DealRoundType, 'count' | 'nudge' | 'date'>
    )>, clauseSummary: (
      { __typename?: 'ClauseSummary' }
      & Pick<ClauseSummary, 'incomplete' | 'complete'>
    ), details: (
      { __typename?: 'TenantDetails' }
      & TenantDetailsFragment
    ), unit?: Maybe<(
      { __typename?: 'Unit' }
      & SingleUnitFragment
    )> }
  ) }
);

export type GetUnitDetailsQueryVariables = Exact<{
  unitID: Scalars['ID'];
}>;


export type GetUnitDetailsQuery = (
  { __typename?: 'Query' }
  & { unit: (
    { __typename?: 'Unit' }
    & SingleUnitFragment
  ) }
);

export type GetUnitFilesTasksQueryVariables = Exact<{
  unitID: Scalars['ID'];
}>;


export type GetUnitFilesTasksQuery = (
  { __typename?: 'Query' }
  & { unit: (
    { __typename?: 'Unit' }
    & Pick<Unit, '_id'>
    & { files: Array<(
      { __typename?: 'File' }
      & Pick<File, '_id' | 'name' | 'created' | 'url'>
    )>, tasks: Array<(
      { __typename?: 'Task' }
      & Pick<Task, '_id' | 'applicable' | 'edited'>
      & { value?: Maybe<(
        { __typename?: 'BooleanValue' }
        & Pick<BooleanValue, 'booleanValue'>
      ) | (
        { __typename?: 'File' }
        & Pick<File, '_id' | 'name' | 'url'>
      ) | (
        { __typename?: 'FilesValue' }
        & { filesValue: Array<(
          { __typename?: 'File' }
          & Pick<File, '_id' | 'name' | 'url'>
        )> }
      ) | (
        { __typename?: 'FloatValue' }
        & Pick<FloatValue, 'floatValue'>
      ) | (
        { __typename?: 'StringValue' }
        & Pick<StringValue, 'stringValue'>
      )>, task: (
        { __typename?: 'AdminTask' }
        & Pick<AdminTask, '_id' | 'title' | 'index' | 'weight' | 'optional' | 'type'>
      ) }
    )> }
  ) }
);

export type GetUnitRatingQueryVariables = Exact<{
  unitID: Scalars['ID'];
}>;


export type GetUnitRatingQuery = (
  { __typename?: 'Query' }
  & { unit: (
    { __typename?: 'Unit' }
    & Pick<Unit, '_id' | 'rating'>
  ) }
);

export type GetUserCompanyQueryVariables = Exact<{
  unitID: Scalars['ID'];
}>;


export type GetUserCompanyQuery = (
  { __typename?: 'Query' }
  & { user: (
    { __typename?: 'User' }
    & { organisation: (
      { __typename?: 'Organisation' }
      & Pick<Organisation, 'name'>
    ) }
  ) }
);

export type SingleUnitCardFragment = (
  { __typename?: 'Unit' }
  & Pick<Unit, '_id' | 'name' | 'buildingName' | 'available' | 'rating' | 'pending' | 'hasHiddenDeals' | 'created' | 'area' | 'condition' | 'use'>
  & { address: (
    { __typename?: 'AddressType' }
    & UnitCardAddressFragment
  ), organisation: (
    { __typename?: 'Organisation' }
    & UnitCardOrganisationFragment
  ), deals: Array<(
    { __typename?: 'Deal' }
    & UnitCardSingleDealFragment
  )>, creator?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, '_id' | 'firstName' | 'lastName'>
  )>, actors: Array<(
    { __typename?: 'User' }
    & Pick<User, '_id' | 'type' | 'firstName' | 'lastName' | 'email' | 'mobile'>
    & { organisation: (
      { __typename?: 'Organisation' }
      & Pick<Organisation, '_id' | 'name'>
    ) }
  )> }
);

export type UnitCardAddressFragment = (
  { __typename?: 'AddressType' }
  & Pick<AddressType, 'line1'>
);

export type UnitCardOrganisationFragment = (
  { __typename?: 'Organisation' }
  & Pick<Organisation, 'name'>
  & { trustedUsers: Array<(
    { __typename?: 'User' }
    & Pick<User, 'firstName' | 'lastName' | 'activated' | 'email'>
    & { organisation: (
      { __typename?: 'Organisation' }
      & Pick<Organisation, 'name'>
    ) }
  )> }
);

export type UnitCardSingleDealFragment = (
  { __typename?: 'Deal' }
  & Pick<Deal, '_id' | 'state'>
  & { tenant?: Maybe<(
    { __typename?: 'Organisation' }
    & Pick<Organisation, 'name'>
  )>, round?: Maybe<(
    { __typename?: 'DealRoundType' }
    & Pick<DealRoundType, 'date'>
  )> }
);

export type BulkUnitUpdateMutationVariables = Exact<{
  unitIDs: Array<Scalars['ID']> | Scalars['ID'];
  all: Scalars['Boolean'];
  filter: UnitFilter;
  available?: Maybe<Scalars['String']>;
}>;


export type BulkUnitUpdateMutation = (
  { __typename?: 'Mutation' }
  & { bulkUnitUpdate: Array<(
    { __typename?: 'Unit' }
    & Pick<Unit, '_id' | 'available'>
  )> }
);

export type DeleteUnitMutationVariables = Exact<{
  unitID: Scalars['ID'];
}>;


export type DeleteUnitMutation = (
  { __typename?: 'Mutation' }
  & { unitDelete: (
    { __typename?: 'UnitDeleteResponse' }
    & Pick<UnitDeleteResponse, 'deletedUnitID'>
  ) }
);

export type UnitDuplicateMutationVariables = Exact<{
  unitID: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  emails?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  organisation: Scalars['ID'];
  use?: Maybe<UnitUse>;
  condition?: Maybe<UnitCondition>;
  area?: Maybe<Scalars['Int']>;
}>;


export type UnitDuplicateMutation = (
  { __typename?: 'Mutation' }
  & { unitDuplicate: (
    { __typename?: 'Unit' }
    & Pick<Unit, '_id'>
  ) }
);

export type HideUnitMutationVariables = Exact<{
  unitID: Scalars['ID'];
  hideConnectedDeals?: Maybe<Scalars['Boolean']>;
  showHiddenDealsOnActive?: Maybe<Scalars['Boolean']>;
}>;


export type HideUnitMutation = (
  { __typename?: 'Mutation' }
  & { unitHide: (
    { __typename?: 'User' }
    & Pick<User, '_id'>
  ) }
);

export type NewUnitMutationVariables = Exact<{
  emails?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  name: Scalars['String'];
  buildingName: Scalars['String'];
  area: Scalars['Int'];
  line1?: Maybe<Scalars['String']>;
  line2?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  postCode?: Maybe<Scalars['String']>;
  use: UnitUse;
  straightToLease?: Maybe<Scalars['Boolean']>;
  condition: UnitCondition;
  organisation: Scalars['String'];
}>;


export type NewUnitMutation = (
  { __typename?: 'Mutation' }
  & { unitCreate: (
    { __typename?: 'Unit' }
    & Pick<Unit, '_id' | 'name' | 'url' | 'buildingName'>
    & { address: (
      { __typename?: 'AddressType' }
      & Pick<AddressType, 'line1'>
    ) }
  ) }
);

export type UnhideUnitMutationVariables = Exact<{
  unitID: Scalars['ID'];
  unhideConnectedDeals?: Maybe<Scalars['Boolean']>;
}>;


export type UnhideUnitMutation = (
  { __typename?: 'Mutation' }
  & { unitUnhide: (
    { __typename?: 'User' }
    & Pick<User, '_id'>
  ) }
);

export type GetAllUserTypeQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllUserTypeQuery = (
  { __typename?: 'Query' }
  & { user: (
    { __typename?: 'User' }
    & Pick<User, '_id' | 'type' | 'firstName' | 'lastName' | 'hasMaxPinnedDeals' | 'email' | 'mobile' | 'subscribers'>
    & { trustedOrganisations: Array<(
      { __typename?: 'Organisation' }
      & Pick<Organisation, '_id' | 'name' | 'website' | 'type' | 'avatar'>
      & { address: (
        { __typename?: 'AddressType' }
        & Pick<AddressType, 'line1' | 'line2' | 'city' | 'postCode'>
      ) }
    )>, organisation: (
      { __typename?: 'Organisation' }
      & Pick<Organisation, '_id' | 'name' | 'website'>
    ) }
  ) }
);

export type GetBuildingsAutofillDetailsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetBuildingsAutofillDetailsQuery = (
  { __typename?: 'Query' }
  & { units: (
    { __typename?: 'Units' }
    & { results: Array<(
      { __typename?: 'Unit' }
      & Pick<Unit, '_id' | 'name' | 'use' | 'buildingName' | 'pending'>
      & { organisation: (
        { __typename?: 'Organisation' }
        & Pick<Organisation, '_id' | 'name'>
      ), address: (
        { __typename?: 'AddressType' }
        & Pick<AddressType, 'line1' | 'line2' | 'city' | 'postCode'>
      ) }
    )>, pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'total'>
    ) }
  ) }
);

export type GetUnitCardDetailsQueryVariables = Exact<{
  query?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  filter?: Maybe<UnitFilter>;
  sort?: Maybe<UnitSort>;
}>;


export type GetUnitCardDetailsQuery = (
  { __typename?: 'Query' }
  & { units: (
    { __typename?: 'Units' }
    & { results: Array<(
      { __typename?: 'Unit' }
      & SingleUnitCardFragment
    )>, pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'total' | 'hasResults' | 'hasNextPage'>
    ) }
  ) }
);

export type GetUnitBuildingNamesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUnitBuildingNamesQuery = (
  { __typename?: 'Query' }
  & { units: (
    { __typename?: 'Units' }
    & { results: Array<(
      { __typename?: 'Unit' }
      & Pick<Unit, 'buildingName'>
    )>, pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'total'>
    ) }
  ) }
);

export type GetUnitCitiesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUnitCitiesQuery = (
  { __typename?: 'Query' }
  & { units: (
    { __typename?: 'Units' }
    & { results: Array<(
      { __typename?: 'Unit' }
      & { address: (
        { __typename?: 'AddressType' }
        & Pick<AddressType, 'city'>
      ) }
    )>, pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'total'>
    ) }
  ) }
);

export type GetUnitOrganisationsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUnitOrganisationsQuery = (
  { __typename?: 'Query' }
  & { units: (
    { __typename?: 'Units' }
    & { results: Array<(
      { __typename?: 'Unit' }
      & { organisation: (
        { __typename?: 'Organisation' }
        & Pick<Organisation, '_id' | 'name'>
      ) }
    )>, pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'total'>
    ) }
  ) }
);

export type GetUnitCreatorQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUnitCreatorQuery = (
  { __typename?: 'Query' }
  & { units: (
    { __typename?: 'Units' }
    & { results: Array<(
      { __typename?: 'Unit' }
      & { creator?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, '_id' | 'firstName' | 'lastName'>
      )> }
    )>, pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'total'>
    ) }
  ) }
);

export type ExportUnitsQueryVariables = Exact<{ [key: string]: never; }>;


export type ExportUnitsQuery = (
  { __typename?: 'Query' }
  & { exportUnits: (
    { __typename?: 'S3Payload' }
    & Pick<S3Payload, 'url'>
  ) }
);

export type UnitFilterCountsQueryVariables = Exact<{
  query?: Maybe<Scalars['String']>;
  filter?: Maybe<UnitFilter>;
}>;


export type UnitFilterCountsQuery = (
  { __typename?: 'Query' }
  & { unitFilterCounts: (
    { __typename?: 'UnitFilterCount' }
    & Pick<UnitFilterCount, 'hidden' | 'live' | 'availableToLet' | 'unavailableToLet'>
  ) }
);

export type GetUserTypeQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUserTypeQuery = (
  { __typename?: 'Query' }
  & { user: (
    { __typename?: 'User' }
    & Pick<User, '_id' | 'type' | 'firstName' | 'lastName' | 'hasMaxPinnedDeals' | 'email' | 'mobile' | 'subscribers' | 'isTenantSide' | 'hiddenTemplates'>
    & { hiddenDeals?: Maybe<Array<(
      { __typename?: 'UserHiddenDeal' }
      & Pick<UserHiddenDeal, '_id'>
    )>>, hiddenUnits?: Maybe<Array<(
      { __typename?: 'UserHiddenUnit' }
      & Pick<UserHiddenUnit, '_id'>
    )>>, trustedOrganisations: Array<(
      { __typename?: 'Organisation' }
      & Pick<Organisation, '_id' | 'name' | 'website' | 'type' | 'avatar' | 'active'>
      & { address: (
        { __typename?: 'AddressType' }
        & Pick<AddressType, 'line1' | 'line2' | 'city' | 'postCode'>
      ) }
    )>, organisation: (
      { __typename?: 'Organisation' }
      & Pick<Organisation, '_id' | 'name' | 'website'>
    ) }
  ) }
);

export type SubscribeEndpointMutationVariables = Exact<{
  endpoint: Scalars['String'];
}>;


export type SubscribeEndpointMutation = (
  { __typename?: 'Mutation' }
  & { userSubscribe: (
    { __typename?: 'User' }
    & Pick<User, '_id' | 'subscribers'>
  ) }
);

export type SingleTemplateClauseFragment = (
  { __typename?: 'ClauseTemplate' }
  & Pick<ClauseTemplate, '_id' | 'value' | 'valueSuffix' | 'shortForm' | 'longForm' | 'tags' | 'associatedUses' | 'updated' | 'updatedDealsAt' | 'updatedTemplatesAt'>
  & { linked: (
    { __typename?: 'AdminLinked' }
    & { results?: Maybe<Array<(
      { __typename?: 'ClauseTemplate' }
      & SingleLinkedTemplateClauseFragment
    )>> }
  ), section?: Maybe<(
    { __typename?: 'ClauseTemplateSection' }
    & TemplateClauseSectionFragment
  )> }
);

export type TemplateClauseLevel1GroupFragment = (
  { __typename?: 'ClauseTemplateLevel1Group' }
  & Pick<ClauseTemplateLevel1Group, 'title' | 'description' | 'rics'>
  & { group?: Maybe<(
    { __typename?: 'ClauseTemplateLevel2Group' }
    & TemplateClauseLevel2GroupFragment
  )> }
);

export type TemplateClauseLevel2GroupFragment = (
  { __typename?: 'ClauseTemplateLevel2Group' }
  & Pick<ClauseTemplateLevel2Group, 'title'>
);

export type SingleLinkedTemplateClauseFragment = (
  { __typename?: 'ClauseTemplate' }
  & Pick<ClauseTemplate, '_id' | 'value' | 'valueSuffix' | 'shortForm' | 'longForm' | 'tags'>
);

export type TemplateClauseSectionFragment = (
  { __typename?: 'ClauseTemplateSection' }
  & Pick<ClauseTemplateSection, 'title' | 'description' | 'rics'>
  & { group: (
    { __typename?: 'ClauseTemplateLevel1Group' }
    & TemplateClauseLevel1GroupFragment
  ) }
);

export type AddClauseMutationVariables = Exact<{
  dealID: Scalars['ID'];
  parentID: Scalars['ID'];
  index: Scalars['Int'];
  value: Scalars['String'];
  valueSuffix: Scalars['String'];
  shortForm: Scalars['String'];
  longForm: Scalars['String'];
}>;


export type AddClauseMutation = (
  { __typename?: 'Mutation' }
  & { dealClauseCreate: (
    { __typename?: 'Clause' }
    & Pick<Clause, '_id' | 'index' | 'value' | 'valueSuffix' | 'shortForm' | 'longForm'>
  ) }
);

export type NewGroupMutationVariables = Exact<{
  dealID: Scalars['ID'];
  parentID: Scalars['ID'];
  title: Scalars['String'];
  index: Scalars['Int'];
}>;


export type NewGroupMutation = (
  { __typename?: 'Mutation' }
  & { dealGroupCreate: (
    { __typename?: 'Group' }
    & Pick<Group, '_id' | 'title'>
  ) }
);

export type NewInvisbleClauseMutationVariables = Exact<{
  dealID: Scalars['ID'];
  parentID: Scalars['ID'];
  index: Scalars['Int'];
}>;


export type NewInvisbleClauseMutation = (
  { __typename?: 'Mutation' }
  & { dealClauseCreate: (
    { __typename?: 'Clause' }
    & Pick<Clause, '_id' | 'index' | 'value' | 'visible'>
  ) }
);

export type NewSectionMutationVariables = Exact<{
  dealID: Scalars['ID'];
  title: Scalars['String'];
  index: Scalars['Int'];
}>;


export type NewSectionMutation = (
  { __typename?: 'Mutation' }
  & { dealSectionCreate: (
    { __typename?: 'Section' }
    & Pick<Section, '_id' | 'title'>
  ) }
);

export type NewSectionGroupClauseMutationVariables = Exact<{
  dealID: Scalars['ID'];
  sectionTitle: Scalars['String'];
  sectionDescription?: Maybe<Scalars['String']>;
  sectionRics?: Maybe<Scalars['String']>;
  sectionIndex?: Maybe<Scalars['Int']>;
  groupTitle: Scalars['String'];
  groupDescription?: Maybe<Scalars['String']>;
  groupRics?: Maybe<Scalars['String']>;
  groupIndex?: Maybe<Scalars['Int']>;
  clauseIndex: Scalars['Int'];
  value?: Maybe<Scalars['String']>;
  valueSuffix?: Maybe<Scalars['String']>;
  shortForm?: Maybe<Scalars['String']>;
  longForm?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  linkedClauses?: Maybe<Array<LinkedClauseInput> | LinkedClauseInput>;
  clauseRef?: Maybe<Scalars['ID']>;
}>;


export type NewSectionGroupClauseMutation = (
  { __typename?: 'Mutation' }
  & { dealSectionGroupClauseCreate: (
    { __typename?: 'Clause' }
    & Pick<Clause, '_id' | 'index' | 'value' | 'valueSuffix' | 'shortForm' | 'longForm'>
  ) }
);

export type NewSectionGroupGroupClauseMutationVariables = Exact<{
  dealID: Scalars['ID'];
  sectionTitle: Scalars['String'];
  sectionIndex?: Maybe<Scalars['Int']>;
  sectionDescription?: Maybe<Scalars['String']>;
  sectionRics?: Maybe<Scalars['String']>;
  groupDescription?: Maybe<Scalars['String']>;
  groupRics?: Maybe<Scalars['String']>;
  groupTitle: Scalars['String'];
  groupIndex?: Maybe<Scalars['Int']>;
  clauseGroupTitle: Scalars['String'];
  clauseGroupIndex?: Maybe<Scalars['Int']>;
  clauseIndex: Scalars['Int'];
  value?: Maybe<Scalars['String']>;
  valueSuffix?: Maybe<Scalars['String']>;
  shortForm?: Maybe<Scalars['String']>;
  longForm?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  linked?: Maybe<Array<LinkedClauseInput> | LinkedClauseInput>;
  clauseRef?: Maybe<Scalars['ID']>;
}>;


export type NewSectionGroupGroupClauseMutation = (
  { __typename?: 'Mutation' }
  & { dealSectionGroupClauseCreate: (
    { __typename?: 'Clause' }
    & Pick<Clause, '_id' | 'index' | 'value' | 'valueSuffix' | 'shortForm' | 'longForm'>
  ) }
);

export type ClauseSectionsQueryVariables = Exact<{
  organisationID?: Maybe<Array<Scalars['ID']> | Scalars['ID']>;
}>;


export type ClauseSectionsQuery = (
  { __typename?: 'Query' }
  & { clauseBank: Array<(
    { __typename?: 'ClauseTemplate' }
    & SingleTemplateClauseFragment
  )> }
);

export type GetOrganisationClausesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetOrganisationClausesQuery = (
  { __typename?: 'Query' }
  & { user: (
    { __typename?: 'User' }
    & { organisation: (
      { __typename?: 'Organisation' }
      & Pick<Organisation, '_id' | 'name'>
      & { clauses?: Maybe<Array<(
        { __typename?: 'OrganisationClause' }
        & Pick<OrganisationClause, '_id' | 'value' | 'valueSuffix' | 'shortForm' | 'longForm'>
      )>> }
    ), associated: Array<(
      { __typename?: 'Organisation' }
      & Pick<Organisation, '_id' | 'name'>
      & { clauses?: Maybe<Array<(
        { __typename?: 'OrganisationClause' }
        & Pick<OrganisationClause, '_id' | 'value' | 'valueSuffix' | 'shortForm' | 'longForm'>
      )>> }
    )> }
  ) }
);

export type SearchClauseBankQueryVariables = Exact<{
  organisationID?: Maybe<Array<Scalars['ID']> | Scalars['ID']>;
  query?: Maybe<Scalars['String']>;
  only?: Maybe<Scalars['Boolean']>;
  options?: Maybe<ClauseBankOptions>;
  filter?: Maybe<TemplateFilter>;
}>;


export type SearchClauseBankQuery = (
  { __typename?: 'Query' }
  & { clauseBank: Array<(
    { __typename?: 'ClauseTemplate' }
    & SingleTemplateClauseFragment
  )> }
);

export type DeleteClauseMutationVariables = Exact<{
  dealID: Scalars['ID'];
  parentID: Scalars['ID'];
  clauseID: Scalars['ID'];
}>;


export type DeleteClauseMutation = (
  { __typename?: 'Mutation' }
  & { dealClauseDelete: (
    { __typename?: 'DeletedObject' }
    & Pick<DeletedObject, 'deletedObjectID'>
  ) }
);

export type DeleteGroupMutationVariables = Exact<{
  dealID: Scalars['ID'];
  parentID: Scalars['ID'];
  groupID: Scalars['ID'];
}>;


export type DeleteGroupMutation = (
  { __typename?: 'Mutation' }
  & { dealGroupDelete: (
    { __typename?: 'DeletedObject' }
    & Pick<DeletedObject, 'deletedObjectID'>
  ) }
);

export type SetDealBreakerMutationVariables = Exact<{
  id: Scalars['ID'];
  dealID: Scalars['ID'];
  parentID: Scalars['ID'];
  dealBreaker: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
}>;


export type SetDealBreakerMutation = (
  { __typename?: 'Mutation' }
  & { dealClauseUpdate: (
    { __typename?: 'Clause' }
    & Pick<Clause, '_id'>
    & { dealBreaker: (
      { __typename?: 'DealBreaker' }
      & Pick<DealBreaker, 'isDealBreaker' | 'message'>
    ) }
  ) }
);

export type NewClauseMutationVariables = Exact<{
  organisationID: Scalars['ID'];
  value?: Maybe<Scalars['String']>;
  valueSuffix?: Maybe<Scalars['String']>;
  shortForm?: Maybe<Scalars['String']>;
  longForm?: Maybe<Scalars['String']>;
  section: Scalars['String'];
  level1Group: Scalars['String'];
  level2Group?: Maybe<Scalars['String']>;
  linked?: Maybe<LinkClauseTemplateInput>;
  associatedUses?: Maybe<Array<UnitUse> | UnitUse>;
}>;


export type NewClauseMutation = (
  { __typename?: 'Mutation' }
  & { organisationClauseAdd: (
    { __typename?: 'OrganisationClause' }
    & Pick<OrganisationClause, '_id' | 'value' | 'valueSuffix' | 'associatedUses'>
    & { section: (
      { __typename?: 'OrganisationSection' }
      & Pick<OrganisationSection, 'title'>
      & { group: (
        { __typename?: 'OrganisationGroup' }
        & Pick<OrganisationGroup, 'title'>
        & { group?: Maybe<(
          { __typename?: 'OrganisationSubGroup' }
          & Pick<OrganisationSubGroup, 'title'>
        )> }
      ) }
    ), linked?: Maybe<(
      { __typename?: 'OrganisationLinked' }
      & { results?: Maybe<Array<(
        { __typename?: 'OrganisationClause' }
        & Pick<OrganisationClause, '_id' | 'value' | 'valueSuffix' | 'shortForm' | 'longForm'>
      )>> }
    )> }
  ) }
);

export type GetDealClauseGroupsQueryVariables = Exact<{
  dealID: Scalars['ID'];
}>;


export type GetDealClauseGroupsQuery = (
  { __typename?: 'Query' }
  & { deal: (
    { __typename?: 'Deal' }
    & Pick<Deal, '_id'>
    & { sections: Array<(
      { __typename?: 'Section' }
      & Pick<Section, '_id' | 'title'>
      & { groups: Array<(
        { __typename?: 'Group' }
        & Pick<Group, '_id' | 'title'>
        & { clauses: Array<(
          { __typename?: 'Clause' }
          & Pick<Clause, '_id' | 'visible'>
          & { groups?: Maybe<Array<(
            { __typename?: 'Group' }
            & Pick<Group, '_id' | 'title'>
          )>> }
        )> }
      )> }
    )> }
  ) }
);

export type SwapClauseMutationVariables = Exact<{
  dealID: Scalars['ID'];
  parentID: Scalars['ID'];
  clauseID: Scalars['ID'];
  value?: Maybe<Scalars['String']>;
  valueSuffix?: Maybe<Scalars['String']>;
  shortForm?: Maybe<Scalars['String']>;
  longForm?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type SwapClauseMutation = (
  { __typename?: 'Mutation' }
  & { dealClauseUpdate: (
    { __typename?: 'Clause' }
    & Pick<Clause, '_id' | 'value' | 'valueSuffix' | 'shortForm' | 'longForm' | 'tags' | 'approved' | 'rejected'>
    & { history: Array<(
      { __typename?: 'History' }
      & Pick<History, '_id' | 'type' | 'date'>
      & { actor: (
        { __typename?: 'User' }
        & Pick<User, '_id' | 'firstName' | 'lastName'>
      ), change?: Maybe<(
        { __typename?: 'HistoryChange' }
        & { new: (
          { __typename?: 'HistoryValue' }
          & Pick<HistoryValue, 'value' | 'valueSuffix' | 'shortForm' | 'longForm' | 'approved' | 'rejected'>
        ), old?: Maybe<(
          { __typename?: 'HistoryValue' }
          & Pick<HistoryValue, 'value' | 'valueSuffix' | 'shortForm' | 'longForm' | 'approved' | 'rejected'>
        )> }
      )> }
    )> }
  ) }
);

export type DeleteSectionMutationVariables = Exact<{
  dealID: Scalars['ID'];
  sectionID: Scalars['ID'];
}>;


export type DeleteSectionMutation = (
  { __typename?: 'Mutation' }
  & { dealSectionDelete: (
    { __typename?: 'DeletedObject' }
    & Pick<DeletedObject, 'deletedObjectID'>
  ) }
);

export type MoveClauseMutationVariables = Exact<{
  dealID: Scalars['ID'];
  parentID: Scalars['ID'];
  clauseID: Scalars['ID'];
  indexFrom: Scalars['Int'];
  indexTo: Scalars['Int'];
}>;


export type MoveClauseMutation = (
  { __typename?: 'Mutation' }
  & { dealClauseUpdate: (
    { __typename?: 'Clause' }
    & Pick<Clause, '_id' | 'index'>
  ) }
);

export type UpdateClauseMutationVariables = Exact<{
  dealID: Scalars['ID'];
  parentID: Scalars['ID'];
  clauseID: Scalars['ID'];
  value?: Maybe<Scalars['String']>;
  valueSuffix?: Maybe<Scalars['String']>;
  shortForm?: Maybe<Scalars['String']>;
  longForm?: Maybe<Scalars['String']>;
}>;


export type UpdateClauseMutation = (
  { __typename?: 'Mutation' }
  & { dealClauseUpdate: (
    { __typename?: 'Clause' }
    & Pick<Clause, '_id' | 'parentID' | 'value' | 'valueSuffix' | 'shortForm' | 'longForm' | 'approved' | 'rejected'>
    & { history: Array<(
      { __typename?: 'History' }
      & Pick<History, '_id' | 'type' | 'date'>
      & { actor: (
        { __typename?: 'User' }
        & Pick<User, '_id' | 'firstName' | 'lastName'>
      ), change?: Maybe<(
        { __typename?: 'HistoryChange' }
        & { new: (
          { __typename?: 'HistoryValue' }
          & Pick<HistoryValue, 'value' | 'valueSuffix' | 'shortForm' | 'longForm' | 'approved' | 'rejected'>
        ), old?: Maybe<(
          { __typename?: 'HistoryValue' }
          & Pick<HistoryValue, 'value' | 'valueSuffix' | 'shortForm' | 'longForm' | 'approved' | 'rejected'>
        )> }
      )> }
    )> }
  ) }
);

export type UpdateGroupMutationVariables = Exact<{
  title?: Maybe<Scalars['String']>;
  parentID: Scalars['ID'];
  groupID: Scalars['ID'];
  dealID: Scalars['ID'];
  from: Scalars['Int'];
  to: Scalars['Int'];
}>;


export type UpdateGroupMutation = (
  { __typename?: 'Mutation' }
  & { dealGroupUpdate: (
    { __typename?: 'Group' }
    & Pick<Group, '_id' | 'title' | 'index'>
  ) }
);

export type UpdateGroupNoIndexesMutationVariables = Exact<{
  dealID: Scalars['ID'];
  parentID: Scalars['ID'];
  id: Scalars['ID'];
  description?: Maybe<Scalars['String']>;
  rics?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
}>;


export type UpdateGroupNoIndexesMutation = (
  { __typename?: 'Mutation' }
  & { dealGroupUpdate: (
    { __typename?: 'Group' }
    & Pick<Group, 'title' | 'description' | 'rics'>
  ) }
);

export type UpdateSectionMutationVariables = Exact<{
  dealID: Scalars['ID'];
  id: Scalars['ID'];
  from: Scalars['Int'];
  to: Scalars['Int'];
}>;


export type UpdateSectionMutation = (
  { __typename?: 'Mutation' }
  & { dealSectionUpdate: (
    { __typename?: 'Section' }
    & Pick<Section, 'index'>
  ) }
);

export type UpdateSectionNoIndexesMutationVariables = Exact<{
  dealID: Scalars['ID'];
  id: Scalars['ID'];
  description?: Maybe<Scalars['String']>;
  rics?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
}>;


export type UpdateSectionNoIndexesMutation = (
  { __typename?: 'Mutation' }
  & { dealSectionUpdate: (
    { __typename?: 'Section' }
    & Pick<Section, 'description' | 'rics' | 'title'>
  ) }
);

export type DeleteDocumentMutationVariables = Exact<{
  id: Scalars['ID'];
  type: DocType;
  unitID?: Maybe<Scalars['ID']>;
}>;


export type DeleteDocumentMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'docDelete'>
);

export type UploadAvatarMutationVariables = Exact<{
  type: AvatarType;
  filetype: Scalars['String'];
  id: Scalars['ID'];
}>;


export type UploadAvatarMutation = (
  { __typename?: 'Mutation' }
  & { avatarCreate: (
    { __typename?: 'S3Payload' }
    & Pick<S3Payload, 'signedUrl' | 'url'>
  ) }
);

export type UploadDocumentMutationVariables = Exact<{
  type: DocType;
  filename: Scalars['String'];
  filetype: Scalars['String'];
  id: Scalars['ID'];
  taskID?: Maybe<Scalars['ID']>;
  frontend?: Maybe<Scalars['Boolean']>;
}>;


export type UploadDocumentMutation = (
  { __typename?: 'Mutation' }
  & { docCreate: (
    { __typename?: 'S3Payload' }
    & Pick<S3Payload, 'signedUrl' | 'url'>
  ) }
);

export type UnitOtherFileMutationVariables = Exact<{
  unitID: Scalars['ID'];
  url: Scalars['String'];
  name: Scalars['String'];
}>;


export type UnitOtherFileMutation = (
  { __typename?: 'Mutation' }
  & { unitOtherFile: (
    { __typename?: 'File' }
    & Pick<File, '_id' | 'name' | 'url' | 'other'>
  ) }
);

export type SingleNotificationFragment = (
  { __typename?: 'Notification' }
  & Pick<Notification, '_id' | 'text' | 'sent' | 'read' | 'url' | 'title' | 'type'>
);

export type DeleteNotificationMutationVariables = Exact<{
  notificationID: Scalars['ID'];
}>;


export type DeleteNotificationMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'userNotificationDelete'>
);

export type GetDealStateQueryVariables = Exact<{
  dealID: Scalars['ID'];
}>;


export type GetDealStateQuery = (
  { __typename?: 'Query' }
  & { deal: (
    { __typename?: 'Deal' }
    & Pick<Deal, '_id' | 'state' | 'currentUserParty'>
    & { unit?: Maybe<(
      { __typename?: 'Unit' }
      & { organisation: (
        { __typename?: 'Organisation' }
        & Pick<Organisation, 'name'>
      ) }
    )>, clauseSummary: (
      { __typename?: 'ClauseSummary' }
      & Pick<ClauseSummary, 'complete' | 'incomplete'>
    ) }
  ) }
);

export type GetNotificationsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetNotificationsQuery = (
  { __typename?: 'Query' }
  & { user: (
    { __typename?: 'User' }
    & Pick<User, '_id'>
    & { notifications: Array<(
      { __typename?: 'Notification' }
      & Pick<Notification, '_id' | 'url' | 'type' | 'title' | 'text' | 'read' | 'sent'>
    )> }
  ) }
);

export type GetOrganisationFromIdQueryVariables = Exact<{
  id: Scalars['ID'];
  sort?: Maybe<UserSort>;
  filter?: Maybe<UserFilter>;
  query?: Maybe<Scalars['String']>;
}>;


export type GetOrganisationFromIdQuery = (
  { __typename?: 'Query' }
  & { organisation: (
    { __typename?: 'Organisation' }
    & Pick<Organisation, '_id' | 'name' | 'avatar' | 'website' | 'type' | 'created'>
    & { members: Array<(
      { __typename?: 'User' }
      & Pick<User, '_id' | 'firstName' | 'lastName' | 'email' | 'type' | 'mobile' | 'locked' | 'lastLogin' | 'activated'>
    )>, address: (
      { __typename?: 'AddressType' }
      & Pick<AddressType, 'line1' | 'line2' | 'city' | 'country' | 'postCode'>
    ), units: Array<(
      { __typename?: 'Unit' }
      & Pick<Unit, '_id' | 'use' | 'rating'>
    )>, clauses?: Maybe<Array<(
      { __typename?: 'OrganisationClause' }
      & Pick<OrganisationClause, '_id'>
    )>>, deals: Array<(
      { __typename?: 'Deal' }
      & Pick<Deal, '_id' | 'state' | 'created'>
      & { round?: Maybe<(
        { __typename?: 'DealRoundType' }
        & Pick<DealRoundType, 'date'>
      )> }
    )>, templates: Array<(
      { __typename?: 'Template' }
      & Pick<Template, '_id'>
    )> }
  ) }
);

export type GetUserOrganisationsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUserOrganisationsQuery = (
  { __typename?: 'Query' }
  & { user: (
    { __typename?: 'User' }
    & Pick<User, 'type'>
    & { trustedOrganisations: Array<(
      { __typename?: 'Organisation' }
      & Pick<Organisation, '_id' | 'name' | 'active'>
    )>, organisation: (
      { __typename?: 'Organisation' }
      & Pick<Organisation, '_id' | 'name'>
    ), associated: Array<(
      { __typename?: 'Organisation' }
      & Pick<Organisation, '_id' | 'name'>
    )> }
  ) }
);

export type NotificationsQueryVariables = Exact<{
  page?: Maybe<Scalars['Int']>;
  compact?: Maybe<Scalars['Boolean']>;
}>;


export type NotificationsQuery = (
  { __typename?: 'Query' }
  & { notifications: (
    { __typename?: 'NotificationsResponse' }
    & Pick<NotificationsResponse, 'total' | 'pages'>
    & { notifications: Array<(
      { __typename?: 'Notification' }
      & SingleNotificationFragment
    )> }
  ) }
);

export type DownloadFilesMutationVariables = Exact<{
  ids: Array<Scalars['ID']> | Scalars['ID'];
}>;


export type DownloadFilesMutation = (
  { __typename?: 'Mutation' }
  & { downloadDoc: Array<(
    { __typename?: 'File' }
    & Pick<File, '_id' | 'url' | 'name'>
    & { downloads: Array<(
      { __typename?: 'UserDownload' }
      & Pick<UserDownload, 'user' | 'timestamp'>
    )> }
  )> }
);

export type CheckEmailQueryVariables = Exact<{
  email: Scalars['String'];
  dealID: Scalars['String'];
  type: UserType;
}>;


export type CheckEmailQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'checkEmail'>
);

export type TenantActionStageAmlMutationVariables = Exact<{
  dealID: Scalars['ID'];
  completed?: Maybe<Scalars['Boolean']>;
  companyJurisdiction?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  companyRegistrationNumber?: Maybe<Scalars['String']>;
  personalDetails?: Maybe<Array<TenantPersonInput> | TenantPersonInput>;
}>;


export type TenantActionStageAmlMutation = (
  { __typename?: 'Mutation' }
  & { dealTenantDetailsSet: (
    { __typename?: 'TenantDetails' }
    & { aml?: Maybe<(
      { __typename?: 'TenantStageAML' }
      & Pick<TenantStageAml, 'completed' | 'edited' | 'companyName' | 'companyJurisdiction' | 'companyRegistrationNumber'>
      & { personalDetails?: Maybe<Array<(
        { __typename?: 'TenantPerson' }
        & Pick<TenantPerson, 'name' | 'phone'>
      )>> }
    )> }
  ) }
);

export type TenantActionStageTeamMutationVariables = Exact<{
  dealID: Scalars['ID'];
  completed?: Maybe<Scalars['Boolean']>;
  tenant?: Maybe<TenantPersonInput>;
  agent?: Maybe<TenantPersonInput>;
}>;


export type TenantActionStageTeamMutation = (
  { __typename?: 'Mutation' }
  & { dealTenantDetailsSet: (
    { __typename?: 'TenantDetails' }
    & { team?: Maybe<(
      { __typename?: 'TenantStageTeam' }
      & Pick<TenantStageTeam, 'completed' | 'edited'>
      & { tenant?: Maybe<(
        { __typename?: 'TenantPerson' }
        & Pick<TenantPerson, 'name' | 'company' | 'email' | 'phone' | 'invite'>
      )>, agent?: Maybe<(
        { __typename?: 'TenantPerson' }
        & Pick<TenantPerson, 'name' | 'company' | 'email' | 'phone' | 'invite' | 'visible'>
      )> }
    )> }
  ) }
);

export type DownloadDealPackMutationVariables = Exact<{
  dealID: Scalars['ID'];
}>;


export type DownloadDealPackMutation = (
  { __typename?: 'Mutation' }
  & { downloadDealPack: (
    { __typename?: 'Deal' }
    & Pick<Deal, '_id'>
  ) }
);

export type DownloadHoTsMutationVariables = Exact<{
  dealID: Scalars['ID'];
}>;


export type DownloadHoTsMutation = (
  { __typename?: 'Mutation' }
  & { downloadHoTs: (
    { __typename?: 'Deal' }
    & Pick<Deal, '_id'>
  ) }
);

export type GetReadinessPackMutationVariables = Exact<{
  dealID: Scalars['ID'];
  shouldLog: Scalars['Boolean'];
}>;


export type GetReadinessPackMutation = (
  { __typename?: 'Mutation' }
  & { getReadinessPack: (
    { __typename?: 'S3Payload' }
    & Pick<S3Payload, 'fileName' | 'signedUrl' | 'url'>
  ) }
);

export const DealApprovalEntityFragmentDoc = gql`
    fragment DealApprovalEntity on DealApprovalType {
  _id
  email
  status
  date
}
    `;
export const DealApprovalsFragmentDoc = gql`
    fragment DealApprovals on DealApprovalsType {
  landlord {
    ...DealApprovalEntity
  }
  tenant {
    ...DealApprovalEntity
  }
}
    ${DealApprovalEntityFragmentDoc}`;
export const DealRoundFragmentDoc = gql`
    fragment DealRound on DealRoundType {
  count
  seen
  date
}
    `;
export const ClauseSummaryFragmentDoc = gql`
    fragment ClauseSummary on ClauseSummary {
  incomplete
  complete
}
    `;
export const UnitTaskFragmentDoc = gql`
    fragment UnitTask on Task {
  _id
  value {
    ... on StringValue {
      stringValue
    }
    ... on FloatValue {
      floatValue
    }
    ... on BooleanValue {
      booleanValue
    }
    ... on File {
      _id
      created
      url
      name
    }
    ... on FilesValue {
      filesValue {
        _id
        created
        url
        name
      }
    }
  }
  applicable
  edited
  task {
    _id
    title
    index
    weight
    optional
    tags
    type
  }
}
    `;
export const DealActorFragmentDoc = gql`
    fragment DealActor on User {
  _id
  firstName
  lastName
  email
  type
  pending
  activated
  organisation {
    _id
    name
    members {
      _id
      firstName
      lastName
      email
    }
  }
}
    `;
export const SimpleAddressFragmentDoc = gql`
    fragment SimpleAddress on AddressType {
  line1
  line2
  city
  postCode
}
    `;
export const DealUnitOrganisationFragmentDoc = gql`
    fragment DealUnitOrganisation on Organisation {
  straightToLease {
    start
    end
  }
  _id
  avatar
  name
  website
  address {
    ...SimpleAddress
  }
  members {
    ...DealActor
  }
}
    ${SimpleAddressFragmentDoc}
${DealActorFragmentDoc}`;
export const DealUnitFragmentDoc = gql`
    fragment DealUnit on Unit {
  _id
  straightToLease
  name
  buildingName
  url
  use
  avatar
  rating
  area
  condition
  pending
  tasks {
    ...UnitTask
  }
  deals {
    _id
  }
  actors {
    ...DealActor
  }
  address {
    ...SimpleAddress
  }
  organisation {
    ...DealUnitOrganisation
  }
}
    ${UnitTaskFragmentDoc}
${DealActorFragmentDoc}
${SimpleAddressFragmentDoc}
${DealUnitOrganisationFragmentDoc}`;
export const UnitSingleDealFileFragmentDoc = gql`
    fragment UnitSingleDealFile on File {
  _id
  other
  created
  name
  url
  user {
    _id
    firstName
    lastName
    organisation {
      name
    }
  }
}
    `;
export const UnitSingleDealFragmentDoc = gql`
    fragment UnitSingleDeal on Deal {
  _id
  pending
  state
  isPinned
  currentUserParty
  created
  hasHiddenUnit
  round {
    count
    nudge
    date
  }
  clauseSummary {
    incomplete
    complete
  }
  files {
    ...UnitSingleDealFile
  }
  tenant {
    name
  }
  unit {
    url
    name
    buildingName
    organisation {
      name
    }
  }
}
    ${UnitSingleDealFileFragmentDoc}`;
export const UnitSingleFileFragmentDoc = gql`
    fragment UnitSingleFile on File {
  _id
  other
  created
  name
  url
  downloads {
    _id
    user
    timestamp
  }
}
    `;
export const UnitSingleTaskFragmentDoc = gql`
    fragment UnitSingleTask on Task {
  _id
  value {
    ... on StringValue {
      stringValue
    }
    ... on FloatValue {
      floatValue
    }
    ... on BooleanValue {
      booleanValue
    }
    ... on File {
      _id
      name
      url
      downloads {
        _id
        user
        timestamp
      }
    }
    ... on FilesValue {
      filesValue {
        _id
        name
        url
        downloads {
          _id
          user
          timestamp
        }
      }
    }
  }
  applicable
  edited
  task {
    tags
    _id
    title
    index
    weight
    optional
    type
  }
}
    `;
export const UnitAddressFragmentDoc = gql`
    fragment UnitAddress on AddressType {
  line1
  line2
  city
  county
  postCode
}
    `;
export const UnitSingleActorFragmentDoc = gql`
    fragment UnitSingleActor on User {
  _id
  firstName
  lastName
  email
  mobile
  type
  pending
  organisation {
    _id
    name
  }
}
    `;
export const UnitOrganisationSingleMemberFragmentDoc = gql`
    fragment UnitOrganisationSingleMember on User {
  _id
  firstName
  lastName
  email
  mobile
  type
  pending
}
    `;
export const UnitOrganisationFragmentDoc = gql`
    fragment UnitOrganisation on Organisation {
  _id
  name
  certification
  members {
    ...UnitOrganisationSingleMember
  }
}
    ${UnitOrganisationSingleMemberFragmentDoc}`;
export const SingleUnitFragmentDoc = gql`
    fragment SingleUnit on Unit {
  _id
  name
  area
  url
  use
  condition
  buildingName
  pending
  avatar
  rating
  available
  deals {
    ...UnitSingleDeal
  }
  files {
    ...UnitSingleFile
  }
  tasks {
    ...UnitSingleTask
  }
  address {
    ...UnitAddress
  }
  actors {
    ...UnitSingleActor
  }
  organisation {
    ...UnitOrganisation
  }
  created
  creator {
    _id
    firstName
    lastName
  }
}
    ${UnitSingleDealFragmentDoc}
${UnitSingleFileFragmentDoc}
${UnitSingleTaskFragmentDoc}
${UnitAddressFragmentDoc}
${UnitSingleActorFragmentDoc}
${UnitOrganisationFragmentDoc}`;
export const DealTenantDetailsFragmentDoc = gql`
    fragment DealTenantDetails on TenantDetails {
  one {
    entity {
      name
      companyNumber
      address {
        line1
        line2
        city
        postCode
      }
    }
    building {
      address {
        line1
        line2
        city
        postCode
      }
    }
  }
  two {
    lawyer {
      name
      company
      email
      phone
      visible
    }
  }
  three {
    files {
      _id
      created
      name
      url
    }
  }
  team {
    agent {
      name
      company
      email
      phone
      visible
    }
    tenant {
      name
      company
      email
      phone
      visible
    }
  }
}
    `;
export const DealTenantFragmentDoc = gql`
    fragment DealTenant on Organisation {
  _id
  name
  pendingMembers
  members {
    ...DealActor
  }
  active
}
    ${DealActorFragmentDoc}`;
export const SimpleDealActorFragmentDoc = gql`
    fragment SimpleDealActor on User {
  _id
  firstName
  lastName
  email
}
    `;
export const ClauseCommentFragmentDoc = gql`
    fragment ClauseComment on Comment {
  _id
  actor {
    ...SimpleDealActor
  }
  date
  type
  message
}
    ${SimpleDealActorFragmentDoc}`;
export const HistoryChangeFragmentDoc = gql`
    fragment HistoryChange on HistoryValue {
  value
  valueSuffix
  shortForm
  longForm
  approved
  rejected
  comments {
    _id
    message
  }
}
    `;
export const ClauseHistoryFragmentDoc = gql`
    fragment ClauseHistory on History {
  _id
  type
  actor {
    ...SimpleDealActor
  }
  date
  party
  change {
    new {
      ...HistoryChange
    }
    old {
      ...HistoryChange
    }
  }
}
    ${SimpleDealActorFragmentDoc}
${HistoryChangeFragmentDoc}`;
export const LinkedClauseFragmentDoc = gql`
    fragment LinkedClause on LinkedClause {
  _id
  value
  valueSuffix
  shortForm
  longForm
  tags
}
    `;
export const SingleClauseNoGroupsFragmentDoc = gql`
    fragment SingleClauseNoGroups on Clause {
  _id
  parentID
  index
  value
  visible
  valueSuffix
  shortForm
  longForm
  approved
  rejected
  deleted
  tags
  dealBreaker {
    isDealBreaker
    message
  }
  comments {
    ...ClauseComment
  }
  history {
    ...ClauseHistory
  }
  linked {
    ...LinkedClause
  }
}
    ${ClauseCommentFragmentDoc}
${ClauseHistoryFragmentDoc}
${LinkedClauseFragmentDoc}`;
export const SingleGroupFragmentDoc = gql`
    fragment SingleGroup on Group {
  _id
  parentID
  title
  description
  rics
  index
  clauseSummary {
    complete
    incomplete
  }
  clauses {
    ...SingleClauseNoGroups
    groups {
      _id
      parentID
      title
      index
      clauses {
        ...SingleClauseNoGroups
      }
    }
  }
}
    ${SingleClauseNoGroupsFragmentDoc}`;
export const DealSectionFragmentDoc = gql`
    fragment DealSection on Section {
  _id
  title
  description
  rics
  index
  groups {
    ...SingleGroup
  }
}
    ${SingleGroupFragmentDoc}`;
export const DealDetailsFragmentDoc = gql`
    fragment DealDetails on Deal {
  _id
  state
  currentUserParty
  isPinned
  agreedBy
  hasHiddenUnit
  approvals {
    ...DealApprovals
  }
  approverReturnEmail
  approvalID
  hasAccess
  round {
    ...DealRound
  }
  clauseSummary {
    ...ClauseSummary
  }
  unit {
    ...DealUnit
    ...SingleUnit
  }
  details {
    ...DealTenantDetails
  }
  actors {
    ...DealActor
  }
  tenant {
    ...DealTenant
  }
  sections {
    ...DealSection
  }
  template {
    name
  }
  created
  creator {
    _id
    firstName
    lastName
  }
  logs {
    _id
    actor {
      firstName
      lastName
    }
    action
    object
    created
  }
  unit {
    ...SingleUnit
  }
  agreementSeenTenant
  agreementSeenLandlord
}
    ${DealApprovalsFragmentDoc}
${DealRoundFragmentDoc}
${ClauseSummaryFragmentDoc}
${DealUnitFragmentDoc}
${SingleUnitFragmentDoc}
${DealTenantDetailsFragmentDoc}
${DealActorFragmentDoc}
${DealTenantFragmentDoc}
${DealSectionFragmentDoc}`;
export const SingleClauseNoHistoryFragmentDoc = gql`
    fragment SingleClauseNoHistory on Clause {
  _id
  parentID
  index
  value
  visible
  valueSuffix
  shortForm
  longForm
  approved
  rejected
  deleted
  tags
  clauseRef
  dealBreaker {
    isDealBreaker
    message
  }
  comments {
    ...ClauseComment
  }
  linked {
    ...LinkedClause
  }
}
    ${ClauseCommentFragmentDoc}
${LinkedClauseFragmentDoc}`;
export const SingleGroupNoHistoryFragmentDoc = gql`
    fragment SingleGroupNoHistory on Group {
  _id
  parentID
  title
  description
  rics
  index
  clauseSummary {
    complete
    incomplete
  }
  clauses {
    ...SingleClauseNoHistory
    groups {
      _id
      parentID
      title
      index
      clauses {
        ...SingleClauseNoHistory
      }
    }
  }
}
    ${SingleClauseNoHistoryFragmentDoc}`;
export const DealSectionNoHistoryFragmentDoc = gql`
    fragment DealSectionNoHistory on Section {
  _id
  title
  description
  rics
  index
  groups {
    ...SingleGroupNoHistory
  }
}
    ${SingleGroupNoHistoryFragmentDoc}`;
export const DealDetailsNoHistoryFragmentDoc = gql`
    fragment DealDetailsNoHistory on Deal {
  _id
  state
  currentUserParty
  isPinned
  hasHiddenUnit
  approvals {
    ...DealApprovals
  }
  approverReturnEmail
  approvalID
  hasAccess
  round {
    ...DealRound
  }
  clauseSummary {
    ...ClauseSummary
  }
  unit {
    ...DealUnit
  }
  actors {
    ...DealActor
  }
  tenant {
    ...DealTenant
  }
  details {
    ...DealTenantDetails
  }
  sections {
    ...DealSectionNoHistory
  }
  created
  template {
    name
  }
  creator {
    _id
    firstName
    lastName
  }
  agreedBy
  agreementSeenTenant
  agreementSeenLandlord
  logs {
    _id
    actor {
      firstName
      lastName
    }
    action
    object
    created
  }
}
    ${DealApprovalsFragmentDoc}
${DealRoundFragmentDoc}
${ClauseSummaryFragmentDoc}
${DealUnitFragmentDoc}
${DealActorFragmentDoc}
${DealTenantFragmentDoc}
${DealTenantDetailsFragmentDoc}
${DealSectionNoHistoryFragmentDoc}`;
export const DealCardFragmentDoc = gql`
    fragment DealCard on Deal {
  _id
  pending
  state
  hasHiddenUnit
  score
  currentUserParty
  isPinned
  approvals {
    landlord {
      _id
      email
      status
      date
    }
    tenant {
      _id
      email
      status
      date
    }
  }
  round {
    count
    nudge
    date
  }
  clauseSummary {
    incomplete
    complete
  }
  actors {
    _id
    type
    email
    firstName
    lastName
  }
  unit {
    _id
    url
    name
    buildingName
    rating
    actors {
      _id
      type
      email
      firstName
      lastName
    }
    address {
      line1
    }
    organisation {
      _id
      name
      members {
        _id
      }
    }
  }
  tenant {
    name
    members {
      email
      _id
      firstName
      lastName
    }
  }
  template {
    _id
    name
  }
  created
  creator {
    _id
    firstName
    lastName
  }
  agreedBy
}
    `;
export const CompletedStagesFragmentDoc = gql`
    fragment CompletedStages on TenantDetails {
  one {
    completed
  }
  two {
    completed
  }
  team {
    completed
  }
  three {
    completed
  }
  aml {
    completed
  }
  final {
    completed
  }
}
    `;
export const OrganisationCardFragmentDoc = gql`
    fragment OrganisationCard on Organisation {
  _id
  name
  membersSize
  address {
    line1
    line2
    city
    country
    postCode
  }
  avatar
  website
  type
  created
  practiceType
}
    `;
export const InvitedUserFragmentDoc = gql`
    fragment InvitedUser on InviteTokenUser {
  _id
  email
  existing
}
    `;
export const OrganisationMemberFragmentDoc = gql`
    fragment OrganisationMember on User {
  _id
  firstName
  lastName
  email
  mobile
  type
  pending
  lastLogin
  activated
  organisation {
    _id
    name
  }
}
    `;
export const UserDetailsFragmentDoc = gql`
    fragment UserDetails on User {
  _id
  firstName
  lastName
  mobile
  email
  type
  lastLogin
  activated
  organisation {
    _id
    name
  }
  locked
  is2FAEnabled
  hash
}
    `;
export const TenantDetailsFragmentDoc = gql`
    fragment TenantDetails on TenantDetails {
  one {
    edited
    completed
    isSameAddress
    entity {
      name
      companyNumber
      address {
        line1
        line2
        city
        postCode
      }
    }
    building {
      visible
      address {
        line1
        line2
        city
        postCode
      }
    }
  }
  two {
    edited
    completed
    lawyer {
      name
      company
      email
      phone
      invite
      visible
    }
    agent {
      name
      company
      email
      phone
      invite
      visible
    }
  }
  three {
    edited
    completed
    audited
    files {
      _id
      created
      name
      url
    }
    noAccountsToShare
    nominateGuarantor
    guarantor {
      name
      company
      jurisdiction
      visible
      address {
        line1
        line2
        city
        postCode
      }
    }
  }
  four {
    edited
    completed
    noLawyer
    kyc
    files {
      _id
      created
      name
      url
    }
  }
  five {
    noLawyer
    edited
    completed
    instructions
    signed
  }
  six {
    edited
    completed
    fitted
    company {
      type
      name
      contactName
      email
      phone
    }
    projectManager {
      name
      company
      email
      phone
      visible
    }
  }
  team {
    edited
    completed
    tenant {
      name
      company
      email
      phone
      invite
      visible
    }
    agent {
      name
      company
      email
      phone
      invite
      visible
    }
  }
  aml {
    completed
    edited
    companyName
    companyJurisdiction
    companyRegistrationNumber
    personalDetails {
      name
      phone
    }
  }
  final {
    edited
    completed
    informLandlord
  }
}
    `;
export const UnitCardAddressFragmentDoc = gql`
    fragment UnitCardAddress on AddressType {
  line1
}
    `;
export const UnitCardOrganisationFragmentDoc = gql`
    fragment UnitCardOrganisation on Organisation {
  name
  trustedUsers {
    organisation {
      name
    }
    firstName
    lastName
    activated
    email
  }
}
    `;
export const UnitCardSingleDealFragmentDoc = gql`
    fragment UnitCardSingleDeal on Deal {
  _id
  state
  tenant {
    name
  }
  round {
    date
  }
}
    `;
export const SingleUnitCardFragmentDoc = gql`
    fragment SingleUnitCard on Unit {
  _id
  name
  buildingName
  available
  address {
    ...UnitCardAddress
  }
  organisation {
    ...UnitCardOrganisation
  }
  rating
  pending
  deals {
    ...UnitCardSingleDeal
  }
  hasHiddenDeals
  created
  creator {
    _id
    firstName
    lastName
  }
  area
  condition
  use
  actors {
    _id
    type
    firstName
    lastName
    email
    mobile
    organisation {
      _id
      name
    }
  }
}
    ${UnitCardAddressFragmentDoc}
${UnitCardOrganisationFragmentDoc}
${UnitCardSingleDealFragmentDoc}`;
export const SingleLinkedTemplateClauseFragmentDoc = gql`
    fragment SingleLinkedTemplateClause on ClauseTemplate {
  _id
  value
  valueSuffix
  shortForm
  longForm
  tags
}
    `;
export const TemplateClauseLevel2GroupFragmentDoc = gql`
    fragment TemplateClauseLevel2Group on ClauseTemplateLevel2Group {
  title
}
    `;
export const TemplateClauseLevel1GroupFragmentDoc = gql`
    fragment TemplateClauseLevel1Group on ClauseTemplateLevel1Group {
  title
  description
  rics
  group {
    ...TemplateClauseLevel2Group
  }
}
    ${TemplateClauseLevel2GroupFragmentDoc}`;
export const TemplateClauseSectionFragmentDoc = gql`
    fragment TemplateClauseSection on ClauseTemplateSection {
  title
  description
  rics
  group {
    ...TemplateClauseLevel1Group
  }
}
    ${TemplateClauseLevel1GroupFragmentDoc}`;
export const SingleTemplateClauseFragmentDoc = gql`
    fragment SingleTemplateClause on ClauseTemplate {
  _id
  value
  valueSuffix
  shortForm
  longForm
  tags
  associatedUses
  linked {
    results {
      ...SingleLinkedTemplateClause
    }
  }
  section {
    ...TemplateClauseSection
  }
  updated
  updatedDealsAt
  updatedTemplatesAt
}
    ${SingleLinkedTemplateClauseFragmentDoc}
${TemplateClauseSectionFragmentDoc}`;
export const SingleNotificationFragmentDoc = gql`
    fragment SingleNotification on Notification {
  _id
  text
  sent
  read
  url
  title
  type
}
    `;
export const GetUnreadNotificationsDocument = gql`
    query getUnreadNotifications {
  user {
    ... on User {
      _id
      notifications {
        _id
        read
      }
    }
  }
}
    `;

/**
 * __useGetUnreadNotificationsQuery__
 *
 * To run a query within a React component, call `useGetUnreadNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUnreadNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUnreadNotificationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUnreadNotificationsQuery(baseOptions?: Apollo.QueryHookOptions<GetUnreadNotificationsQuery, GetUnreadNotificationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUnreadNotificationsQuery, GetUnreadNotificationsQueryVariables>(GetUnreadNotificationsDocument, options);
      }
export function useGetUnreadNotificationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUnreadNotificationsQuery, GetUnreadNotificationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUnreadNotificationsQuery, GetUnreadNotificationsQueryVariables>(GetUnreadNotificationsDocument, options);
        }
export type GetUnreadNotificationsQueryHookResult = ReturnType<typeof useGetUnreadNotificationsQuery>;
export type GetUnreadNotificationsLazyQueryHookResult = ReturnType<typeof useGetUnreadNotificationsLazyQuery>;
export type GetUnreadNotificationsQueryResult = Apollo.QueryResult<GetUnreadNotificationsQuery, GetUnreadNotificationsQueryVariables>;
export const LogoutDocument = gql`
    mutation logout {
  userAccessTokenDelete
}
    `;
export type LogoutMutationFn = Apollo.MutationFunction<LogoutMutation, LogoutMutationVariables>;

/**
 * __useLogoutMutation__
 *
 * To run a mutation, you first call `useLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutMutation, { data, loading, error }] = useLogoutMutation({
 *   variables: {
 *   },
 * });
 */
export function useLogoutMutation(baseOptions?: Apollo.MutationHookOptions<LogoutMutation, LogoutMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LogoutMutation, LogoutMutationVariables>(LogoutDocument, options);
      }
export type LogoutMutationHookResult = ReturnType<typeof useLogoutMutation>;
export type LogoutMutationResult = Apollo.MutationResult<LogoutMutation>;
export type LogoutMutationOptions = Apollo.BaseMutationOptions<LogoutMutation, LogoutMutationVariables>;
export const GetLayoutUserInfoDocument = gql`
    query getLayoutUserInfo {
  user {
    __typename
    ... on User {
      _id
      firstName
      lastName
      email
      activated
      organisation {
        name
        _id
        avatar
      }
      type
      isTenantSide
    }
  }
}
    `;

/**
 * __useGetLayoutUserInfoQuery__
 *
 * To run a query within a React component, call `useGetLayoutUserInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLayoutUserInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLayoutUserInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetLayoutUserInfoQuery(baseOptions?: Apollo.QueryHookOptions<GetLayoutUserInfoQuery, GetLayoutUserInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLayoutUserInfoQuery, GetLayoutUserInfoQueryVariables>(GetLayoutUserInfoDocument, options);
      }
export function useGetLayoutUserInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLayoutUserInfoQuery, GetLayoutUserInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLayoutUserInfoQuery, GetLayoutUserInfoQueryVariables>(GetLayoutUserInfoDocument, options);
        }
export type GetLayoutUserInfoQueryHookResult = ReturnType<typeof useGetLayoutUserInfoQuery>;
export type GetLayoutUserInfoLazyQueryHookResult = ReturnType<typeof useGetLayoutUserInfoLazyQuery>;
export type GetLayoutUserInfoQueryResult = Apollo.QueryResult<GetLayoutUserInfoQuery, GetLayoutUserInfoQueryVariables>;
export const CreateUserDocument = gql`
    mutation createUser($firstName: String!, $lastName: String!, $company: String!, $type: UserType!, $mobile: String, $email: String!, $password: String!, $login: Boolean!) {
  userCreate(
    input: {firstName: $firstName, lastName: $lastName, mobile: $mobile, email: $email, password: $password, type: $type, organisation: {name: $company, type: $type}, login: $login}
  ) {
    __typename
    ... on User {
      _id
      type
    }
  }
}
    `;
export type CreateUserMutationFn = Apollo.MutationFunction<CreateUserMutation, CreateUserMutationVariables>;

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      company: // value for 'company'
 *      type: // value for 'type'
 *      mobile: // value for 'mobile'
 *      email: // value for 'email'
 *      password: // value for 'password'
 *      login: // value for 'login'
 *   },
 * });
 */
export function useCreateUserMutation(baseOptions?: Apollo.MutationHookOptions<CreateUserMutation, CreateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUserMutation, CreateUserMutationVariables>(CreateUserDocument, options);
      }
export type CreateUserMutationHookResult = ReturnType<typeof useCreateUserMutation>;
export type CreateUserMutationResult = Apollo.MutationResult<CreateUserMutation>;
export type CreateUserMutationOptions = Apollo.BaseMutationOptions<CreateUserMutation, CreateUserMutationVariables>;
export const LoginDocument = gql`
    mutation login($email: String!, $password: String!) {
  userAccessTokenCreate(input: {email: $email, password: $password}) {
    __typename
    ... on User {
      _id
      activated
      type
      is2FAEnabled
    }
  }
}
    `;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, options);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const TwoFaDocument = gql`
    mutation TwoFA($email: String!, $token: String!) {
  user2FA(email: $email, token: $token) {
    __typename
    ... on User {
      _id
      activated
    }
  }
}
    `;
export type TwoFaMutationFn = Apollo.MutationFunction<TwoFaMutation, TwoFaMutationVariables>;

/**
 * __useTwoFaMutation__
 *
 * To run a mutation, you first call `useTwoFaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTwoFaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [twoFaMutation, { data, loading, error }] = useTwoFaMutation({
 *   variables: {
 *      email: // value for 'email'
 *      token: // value for 'token'
 *   },
 * });
 */
export function useTwoFaMutation(baseOptions?: Apollo.MutationHookOptions<TwoFaMutation, TwoFaMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TwoFaMutation, TwoFaMutationVariables>(TwoFaDocument, options);
      }
export type TwoFaMutationHookResult = ReturnType<typeof useTwoFaMutation>;
export type TwoFaMutationResult = Apollo.MutationResult<TwoFaMutation>;
export type TwoFaMutationOptions = Apollo.BaseMutationOptions<TwoFaMutation, TwoFaMutationVariables>;
export const Resend2FaDocument = gql`
    mutation resend2FA($email: String!) {
  userResend2FACode(email: $email)
}
    `;
export type Resend2FaMutationFn = Apollo.MutationFunction<Resend2FaMutation, Resend2FaMutationVariables>;

/**
 * __useResend2FaMutation__
 *
 * To run a mutation, you first call `useResend2FaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResend2FaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resend2FaMutation, { data, loading, error }] = useResend2FaMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useResend2FaMutation(baseOptions?: Apollo.MutationHookOptions<Resend2FaMutation, Resend2FaMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Resend2FaMutation, Resend2FaMutationVariables>(Resend2FaDocument, options);
      }
export type Resend2FaMutationHookResult = ReturnType<typeof useResend2FaMutation>;
export type Resend2FaMutationResult = Apollo.MutationResult<Resend2FaMutation>;
export type Resend2FaMutationOptions = Apollo.BaseMutationOptions<Resend2FaMutation, Resend2FaMutationVariables>;
export const ResendActivationDocument = gql`
    mutation resendActivation($email: String!) {
  userResendActivation(email: $email)
}
    `;
export type ResendActivationMutationFn = Apollo.MutationFunction<ResendActivationMutation, ResendActivationMutationVariables>;

/**
 * __useResendActivationMutation__
 *
 * To run a mutation, you first call `useResendActivationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendActivationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendActivationMutation, { data, loading, error }] = useResendActivationMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useResendActivationMutation(baseOptions?: Apollo.MutationHookOptions<ResendActivationMutation, ResendActivationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResendActivationMutation, ResendActivationMutationVariables>(ResendActivationDocument, options);
      }
export type ResendActivationMutationHookResult = ReturnType<typeof useResendActivationMutation>;
export type ResendActivationMutationResult = Apollo.MutationResult<ResendActivationMutation>;
export type ResendActivationMutationOptions = Apollo.BaseMutationOptions<ResendActivationMutation, ResendActivationMutationVariables>;
export const RequestResetPasswordDocument = gql`
    mutation requestResetPassword($email: String!) {
  userRequestResetPassword(email: $email)
}
    `;
export type RequestResetPasswordMutationFn = Apollo.MutationFunction<RequestResetPasswordMutation, RequestResetPasswordMutationVariables>;

/**
 * __useRequestResetPasswordMutation__
 *
 * To run a mutation, you first call `useRequestResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestResetPasswordMutation, { data, loading, error }] = useRequestResetPasswordMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useRequestResetPasswordMutation(baseOptions?: Apollo.MutationHookOptions<RequestResetPasswordMutation, RequestResetPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RequestResetPasswordMutation, RequestResetPasswordMutationVariables>(RequestResetPasswordDocument, options);
      }
export type RequestResetPasswordMutationHookResult = ReturnType<typeof useRequestResetPasswordMutation>;
export type RequestResetPasswordMutationResult = Apollo.MutationResult<RequestResetPasswordMutation>;
export type RequestResetPasswordMutationOptions = Apollo.BaseMutationOptions<RequestResetPasswordMutation, RequestResetPasswordMutationVariables>;
export const NewPracticeDocument = gql`
    mutation newPractice($id: ID!, $name: String!) {
  newPractice(id: $id, name: $name) {
    ... on Organisation {
      _id
      name
    }
  }
}
    `;
export type NewPracticeMutationFn = Apollo.MutationFunction<NewPracticeMutation, NewPracticeMutationVariables>;

/**
 * __useNewPracticeMutation__
 *
 * To run a mutation, you first call `useNewPracticeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNewPracticeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [newPracticeMutation, { data, loading, error }] = useNewPracticeMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useNewPracticeMutation(baseOptions?: Apollo.MutationHookOptions<NewPracticeMutation, NewPracticeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<NewPracticeMutation, NewPracticeMutationVariables>(NewPracticeDocument, options);
      }
export type NewPracticeMutationHookResult = ReturnType<typeof useNewPracticeMutation>;
export type NewPracticeMutationResult = Apollo.MutationResult<NewPracticeMutation>;
export type NewPracticeMutationOptions = Apollo.BaseMutationOptions<NewPracticeMutation, NewPracticeMutationVariables>;
export const ProcessPracticeInviteDocument = gql`
    mutation processPracticeInvite($inviteID: ID!, $response: String!) {
  processPracticeInvite(inviteID: $inviteID, response: $response) {
    ... on User {
      ...UserDetails
    }
  }
}
    ${UserDetailsFragmentDoc}`;
export type ProcessPracticeInviteMutationFn = Apollo.MutationFunction<ProcessPracticeInviteMutation, ProcessPracticeInviteMutationVariables>;

/**
 * __useProcessPracticeInviteMutation__
 *
 * To run a mutation, you first call `useProcessPracticeInviteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProcessPracticeInviteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [processPracticeInviteMutation, { data, loading, error }] = useProcessPracticeInviteMutation({
 *   variables: {
 *      inviteID: // value for 'inviteID'
 *      response: // value for 'response'
 *   },
 * });
 */
export function useProcessPracticeInviteMutation(baseOptions?: Apollo.MutationHookOptions<ProcessPracticeInviteMutation, ProcessPracticeInviteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ProcessPracticeInviteMutation, ProcessPracticeInviteMutationVariables>(ProcessPracticeInviteDocument, options);
      }
export type ProcessPracticeInviteMutationHookResult = ReturnType<typeof useProcessPracticeInviteMutation>;
export type ProcessPracticeInviteMutationResult = Apollo.MutationResult<ProcessPracticeInviteMutation>;
export type ProcessPracticeInviteMutationOptions = Apollo.BaseMutationOptions<ProcessPracticeInviteMutation, ProcessPracticeInviteMutationVariables>;
export const ReadNotificationDocument = gql`
    mutation readNotification($notificationID: ID!) {
  userReadNotification(id: $notificationID) {
    _id
    read
  }
}
    `;
export type ReadNotificationMutationFn = Apollo.MutationFunction<ReadNotificationMutation, ReadNotificationMutationVariables>;

/**
 * __useReadNotificationMutation__
 *
 * To run a mutation, you first call `useReadNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReadNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [readNotificationMutation, { data, loading, error }] = useReadNotificationMutation({
 *   variables: {
 *      notificationID: // value for 'notificationID'
 *   },
 * });
 */
export function useReadNotificationMutation(baseOptions?: Apollo.MutationHookOptions<ReadNotificationMutation, ReadNotificationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReadNotificationMutation, ReadNotificationMutationVariables>(ReadNotificationDocument, options);
      }
export type ReadNotificationMutationHookResult = ReturnType<typeof useReadNotificationMutation>;
export type ReadNotificationMutationResult = Apollo.MutationResult<ReadNotificationMutation>;
export type ReadNotificationMutationOptions = Apollo.BaseMutationOptions<ReadNotificationMutation, ReadNotificationMutationVariables>;
export const GetCertificationDocument = gql`
    query getCertification {
  user {
    ... on User {
      _id
      organisation {
        _id
        certification
        name
      }
    }
  }
}
    `;

/**
 * __useGetCertificationQuery__
 *
 * To run a query within a React component, call `useGetCertificationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCertificationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCertificationQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCertificationQuery(baseOptions?: Apollo.QueryHookOptions<GetCertificationQuery, GetCertificationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCertificationQuery, GetCertificationQueryVariables>(GetCertificationDocument, options);
      }
export function useGetCertificationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCertificationQuery, GetCertificationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCertificationQuery, GetCertificationQueryVariables>(GetCertificationDocument, options);
        }
export type GetCertificationQueryHookResult = ReturnType<typeof useGetCertificationQuery>;
export type GetCertificationLazyQueryHookResult = ReturnType<typeof useGetCertificationLazyQuery>;
export type GetCertificationQueryResult = Apollo.QueryResult<GetCertificationQuery, GetCertificationQueryVariables>;
export const AddActorToDealDocument = gql`
    mutation addActorToDeal($dealID: ID!, $emails: [String!]!, $type: UserType!, $party: DealUserParty!, $asMember: Boolean) {
  dealAddActor(
    dealID: $dealID
    input: {emails: $emails, type: $type, party: $party, asMember: $asMember}
  ) {
    actors {
      _id
      firstName
      lastName
      email
      type
    }
    tenant {
      _id
      members {
        _id
        firstName
        lastName
        email
        type
      }
    }
  }
}
    `;
export type AddActorToDealMutationFn = Apollo.MutationFunction<AddActorToDealMutation, AddActorToDealMutationVariables>;

/**
 * __useAddActorToDealMutation__
 *
 * To run a mutation, you first call `useAddActorToDealMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddActorToDealMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addActorToDealMutation, { data, loading, error }] = useAddActorToDealMutation({
 *   variables: {
 *      dealID: // value for 'dealID'
 *      emails: // value for 'emails'
 *      type: // value for 'type'
 *      party: // value for 'party'
 *      asMember: // value for 'asMember'
 *   },
 * });
 */
export function useAddActorToDealMutation(baseOptions?: Apollo.MutationHookOptions<AddActorToDealMutation, AddActorToDealMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddActorToDealMutation, AddActorToDealMutationVariables>(AddActorToDealDocument, options);
      }
export type AddActorToDealMutationHookResult = ReturnType<typeof useAddActorToDealMutation>;
export type AddActorToDealMutationResult = Apollo.MutationResult<AddActorToDealMutation>;
export type AddActorToDealMutationOptions = Apollo.BaseMutationOptions<AddActorToDealMutation, AddActorToDealMutationVariables>;
export const UndoDealDocument = gql`
    mutation undoDeal($dealID: ID!) {
  dealUndo(dealID: $dealID) {
    _id
  }
}
    `;
export type UndoDealMutationFn = Apollo.MutationFunction<UndoDealMutation, UndoDealMutationVariables>;

/**
 * __useUndoDealMutation__
 *
 * To run a mutation, you first call `useUndoDealMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUndoDealMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [undoDealMutation, { data, loading, error }] = useUndoDealMutation({
 *   variables: {
 *      dealID: // value for 'dealID'
 *   },
 * });
 */
export function useUndoDealMutation(baseOptions?: Apollo.MutationHookOptions<UndoDealMutation, UndoDealMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UndoDealMutation, UndoDealMutationVariables>(UndoDealDocument, options);
      }
export type UndoDealMutationHookResult = ReturnType<typeof useUndoDealMutation>;
export type UndoDealMutationResult = Apollo.MutationResult<UndoDealMutation>;
export type UndoDealMutationOptions = Apollo.BaseMutationOptions<UndoDealMutation, UndoDealMutationVariables>;
export const RemoveActorFromDealDocument = gql`
    mutation removeActorFromDeal($dealID: ID!, $input: DealRemoveActorInput!) {
  dealRemoveActor(dealID: $dealID, input: $input) {
    ... on Deal {
      _id
      actors {
        _id
      }
    }
  }
}
    `;
export type RemoveActorFromDealMutationFn = Apollo.MutationFunction<RemoveActorFromDealMutation, RemoveActorFromDealMutationVariables>;

/**
 * __useRemoveActorFromDealMutation__
 *
 * To run a mutation, you first call `useRemoveActorFromDealMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveActorFromDealMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeActorFromDealMutation, { data, loading, error }] = useRemoveActorFromDealMutation({
 *   variables: {
 *      dealID: // value for 'dealID'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveActorFromDealMutation(baseOptions?: Apollo.MutationHookOptions<RemoveActorFromDealMutation, RemoveActorFromDealMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveActorFromDealMutation, RemoveActorFromDealMutationVariables>(RemoveActorFromDealDocument, options);
      }
export type RemoveActorFromDealMutationHookResult = ReturnType<typeof useRemoveActorFromDealMutation>;
export type RemoveActorFromDealMutationResult = Apollo.MutationResult<RemoveActorFromDealMutation>;
export type RemoveActorFromDealMutationOptions = Apollo.BaseMutationOptions<RemoveActorFromDealMutation, RemoveActorFromDealMutationVariables>;
export const RemoveTrustedUserDocument = gql`
    mutation removeTrustedUser($organisationID: ID!, $id: ID!) {
  removeTrustedUser(organisationID: $organisationID, userID: $id)
}
    `;
export type RemoveTrustedUserMutationFn = Apollo.MutationFunction<RemoveTrustedUserMutation, RemoveTrustedUserMutationVariables>;

/**
 * __useRemoveTrustedUserMutation__
 *
 * To run a mutation, you first call `useRemoveTrustedUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveTrustedUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeTrustedUserMutation, { data, loading, error }] = useRemoveTrustedUserMutation({
 *   variables: {
 *      organisationID: // value for 'organisationID'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveTrustedUserMutation(baseOptions?: Apollo.MutationHookOptions<RemoveTrustedUserMutation, RemoveTrustedUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveTrustedUserMutation, RemoveTrustedUserMutationVariables>(RemoveTrustedUserDocument, options);
      }
export type RemoveTrustedUserMutationHookResult = ReturnType<typeof useRemoveTrustedUserMutation>;
export type RemoveTrustedUserMutationResult = Apollo.MutationResult<RemoveTrustedUserMutation>;
export type RemoveTrustedUserMutationOptions = Apollo.BaseMutationOptions<RemoveTrustedUserMutation, RemoveTrustedUserMutationVariables>;
export const GetPreviewLinkDocument = gql`
    query getPreviewLink($dealID: ID!) {
  deal(id: $dealID) {
    ... on Deal {
      _id
      preview
    }
  }
}
    `;

/**
 * __useGetPreviewLinkQuery__
 *
 * To run a query within a React component, call `useGetPreviewLinkQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPreviewLinkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPreviewLinkQuery({
 *   variables: {
 *      dealID: // value for 'dealID'
 *   },
 * });
 */
export function useGetPreviewLinkQuery(baseOptions: Apollo.QueryHookOptions<GetPreviewLinkQuery, GetPreviewLinkQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPreviewLinkQuery, GetPreviewLinkQueryVariables>(GetPreviewLinkDocument, options);
      }
export function useGetPreviewLinkLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPreviewLinkQuery, GetPreviewLinkQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPreviewLinkQuery, GetPreviewLinkQueryVariables>(GetPreviewLinkDocument, options);
        }
export type GetPreviewLinkQueryHookResult = ReturnType<typeof useGetPreviewLinkQuery>;
export type GetPreviewLinkLazyQueryHookResult = ReturnType<typeof useGetPreviewLinkLazyQuery>;
export type GetPreviewLinkQueryResult = Apollo.QueryResult<GetPreviewLinkQuery, GetPreviewLinkQueryVariables>;
export const GetDealSeenDocument = gql`
    query getDealSeen($dealID: ID, $token: String) {
  deal(id: $dealID, token: $token) {
    ... on Deal {
      _id
      state
      currentUserParty
      approverReturnEmail
      approvalID
      round {
        count
        date
      }
    }
  }
}
    `;

/**
 * __useGetDealSeenQuery__
 *
 * To run a query within a React component, call `useGetDealSeenQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDealSeenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDealSeenQuery({
 *   variables: {
 *      dealID: // value for 'dealID'
 *      token: // value for 'token'
 *   },
 * });
 */
export function useGetDealSeenQuery(baseOptions?: Apollo.QueryHookOptions<GetDealSeenQuery, GetDealSeenQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDealSeenQuery, GetDealSeenQueryVariables>(GetDealSeenDocument, options);
      }
export function useGetDealSeenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDealSeenQuery, GetDealSeenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDealSeenQuery, GetDealSeenQueryVariables>(GetDealSeenDocument, options);
        }
export type GetDealSeenQueryHookResult = ReturnType<typeof useGetDealSeenQuery>;
export type GetDealSeenLazyQueryHookResult = ReturnType<typeof useGetDealSeenLazyQuery>;
export type GetDealSeenQueryResult = Apollo.QueryResult<GetDealSeenQuery, GetDealSeenQueryVariables>;
export const ApproveClauseDocument = gql`
    mutation approveClause($dealID: ID!, $parentID: ID!, $clauseID: ID!) {
  dealClauseUpdate(
    input: {dealID: $dealID, parentID: $parentID, clauseID: $clauseID, approved: true}
  ) {
    ... on Clause {
      _id
      approved
      history {
        _id
        type
        actor {
          _id
          firstName
          lastName
        }
        date
        party
        change {
          new {
            value
            valueSuffix
            shortForm
            longForm
            approved
            rejected
          }
          old {
            value
            valueSuffix
            shortForm
            longForm
            approved
            rejected
          }
        }
      }
    }
  }
}
    `;
export type ApproveClauseMutationFn = Apollo.MutationFunction<ApproveClauseMutation, ApproveClauseMutationVariables>;

/**
 * __useApproveClauseMutation__
 *
 * To run a mutation, you first call `useApproveClauseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApproveClauseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [approveClauseMutation, { data, loading, error }] = useApproveClauseMutation({
 *   variables: {
 *      dealID: // value for 'dealID'
 *      parentID: // value for 'parentID'
 *      clauseID: // value for 'clauseID'
 *   },
 * });
 */
export function useApproveClauseMutation(baseOptions?: Apollo.MutationHookOptions<ApproveClauseMutation, ApproveClauseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ApproveClauseMutation, ApproveClauseMutationVariables>(ApproveClauseDocument, options);
      }
export type ApproveClauseMutationHookResult = ReturnType<typeof useApproveClauseMutation>;
export type ApproveClauseMutationResult = Apollo.MutationResult<ApproveClauseMutation>;
export type ApproveClauseMutationOptions = Apollo.BaseMutationOptions<ApproveClauseMutation, ApproveClauseMutationVariables>;
export const CommentClauseDocument = gql`
    mutation commentClause($dealID: ID!, $parentID: ID!, $clauseID: ID!, $comment: DealClauseCommentInput!) {
  dealClauseUpdate(
    input: {dealID: $dealID, parentID: $parentID, clauseID: $clauseID, comment: $comment}
  ) {
    ... on Clause {
      _id
      comments {
        _id
        actor {
          _id
          firstName
          lastName
        }
        date
        type
        message
      }
      approved
      rejected
      history {
        _id
        type
        actor {
          _id
          firstName
          lastName
        }
        date
        change {
          new {
            value
            valueSuffix
            shortForm
            longForm
            approved
            rejected
          }
          old {
            value
            valueSuffix
            shortForm
            longForm
            approved
            rejected
          }
        }
      }
    }
  }
}
    `;
export type CommentClauseMutationFn = Apollo.MutationFunction<CommentClauseMutation, CommentClauseMutationVariables>;

/**
 * __useCommentClauseMutation__
 *
 * To run a mutation, you first call `useCommentClauseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCommentClauseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [commentClauseMutation, { data, loading, error }] = useCommentClauseMutation({
 *   variables: {
 *      dealID: // value for 'dealID'
 *      parentID: // value for 'parentID'
 *      clauseID: // value for 'clauseID'
 *      comment: // value for 'comment'
 *   },
 * });
 */
export function useCommentClauseMutation(baseOptions?: Apollo.MutationHookOptions<CommentClauseMutation, CommentClauseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CommentClauseMutation, CommentClauseMutationVariables>(CommentClauseDocument, options);
      }
export type CommentClauseMutationHookResult = ReturnType<typeof useCommentClauseMutation>;
export type CommentClauseMutationResult = Apollo.MutationResult<CommentClauseMutation>;
export type CommentClauseMutationOptions = Apollo.BaseMutationOptions<CommentClauseMutation, CommentClauseMutationVariables>;
export const CompleteDealDocument = gql`
    mutation completeDeal($dealID: ID!) {
  completeDeal(id: $dealID) {
    _id
  }
}
    `;
export type CompleteDealMutationFn = Apollo.MutationFunction<CompleteDealMutation, CompleteDealMutationVariables>;

/**
 * __useCompleteDealMutation__
 *
 * To run a mutation, you first call `useCompleteDealMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteDealMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeDealMutation, { data, loading, error }] = useCompleteDealMutation({
 *   variables: {
 *      dealID: // value for 'dealID'
 *   },
 * });
 */
export function useCompleteDealMutation(baseOptions?: Apollo.MutationHookOptions<CompleteDealMutation, CompleteDealMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CompleteDealMutation, CompleteDealMutationVariables>(CompleteDealDocument, options);
      }
export type CompleteDealMutationHookResult = ReturnType<typeof useCompleteDealMutation>;
export type CompleteDealMutationResult = Apollo.MutationResult<CompleteDealMutation>;
export type CompleteDealMutationOptions = Apollo.BaseMutationOptions<CompleteDealMutation, CompleteDealMutationVariables>;
export const DealSeenDocument = gql`
    mutation dealSeen($dealID: ID!) {
  dealSeen(dealID: $dealID) {
    dealID
  }
}
    `;
export type DealSeenMutationFn = Apollo.MutationFunction<DealSeenMutation, DealSeenMutationVariables>;

/**
 * __useDealSeenMutation__
 *
 * To run a mutation, you first call `useDealSeenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDealSeenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [dealSeenMutation, { data, loading, error }] = useDealSeenMutation({
 *   variables: {
 *      dealID: // value for 'dealID'
 *   },
 * });
 */
export function useDealSeenMutation(baseOptions?: Apollo.MutationHookOptions<DealSeenMutation, DealSeenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DealSeenMutation, DealSeenMutationVariables>(DealSeenDocument, options);
      }
export type DealSeenMutationHookResult = ReturnType<typeof useDealSeenMutation>;
export type DealSeenMutationResult = Apollo.MutationResult<DealSeenMutation>;
export type DealSeenMutationOptions = Apollo.BaseMutationOptions<DealSeenMutation, DealSeenMutationVariables>;
export const UndoClauseDocument = gql`
    mutation undoClause($dealID: ID!, $parentID: ID!, $clauseID: ID!) {
  dealClauseUndo(dealID: $dealID, parentID: $parentID, clauseID: $clauseID) {
    ... on Clause {
      _id
      index
      value
      visible
      valueSuffix
      shortForm
      longForm
      approved
      rejected
      deleted
      comments {
        _id
        actor {
          _id
          firstName
          lastName
        }
        date
        type
        message
      }
      history {
        _id
        type
        actor {
          _id
          firstName
          lastName
        }
        date
        party
        change {
          new {
            value
            valueSuffix
            shortForm
            longForm
            approved
            rejected
          }
          old {
            value
            valueSuffix
            shortForm
            longForm
            approved
            rejected
          }
        }
      }
    }
  }
}
    `;
export type UndoClauseMutationFn = Apollo.MutationFunction<UndoClauseMutation, UndoClauseMutationVariables>;

/**
 * __useUndoClauseMutation__
 *
 * To run a mutation, you first call `useUndoClauseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUndoClauseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [undoClauseMutation, { data, loading, error }] = useUndoClauseMutation({
 *   variables: {
 *      dealID: // value for 'dealID'
 *      parentID: // value for 'parentID'
 *      clauseID: // value for 'clauseID'
 *   },
 * });
 */
export function useUndoClauseMutation(baseOptions?: Apollo.MutationHookOptions<UndoClauseMutation, UndoClauseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UndoClauseMutation, UndoClauseMutationVariables>(UndoClauseDocument, options);
      }
export type UndoClauseMutationHookResult = ReturnType<typeof useUndoClauseMutation>;
export type UndoClauseMutationResult = Apollo.MutationResult<UndoClauseMutation>;
export type UndoClauseMutationOptions = Apollo.BaseMutationOptions<UndoClauseMutation, UndoClauseMutationVariables>;
export const UpdateAgreementSeenDocument = gql`
    mutation updateAgreementSeen($dealID: ID!, $currentUserParty: DealUserParty!) {
  updateAgreementSeen(id: $dealID, currentUserParty: $currentUserParty) {
    _id
  }
}
    `;
export type UpdateAgreementSeenMutationFn = Apollo.MutationFunction<UpdateAgreementSeenMutation, UpdateAgreementSeenMutationVariables>;

/**
 * __useUpdateAgreementSeenMutation__
 *
 * To run a mutation, you first call `useUpdateAgreementSeenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAgreementSeenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAgreementSeenMutation, { data, loading, error }] = useUpdateAgreementSeenMutation({
 *   variables: {
 *      dealID: // value for 'dealID'
 *      currentUserParty: // value for 'currentUserParty'
 *   },
 * });
 */
export function useUpdateAgreementSeenMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAgreementSeenMutation, UpdateAgreementSeenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAgreementSeenMutation, UpdateAgreementSeenMutationVariables>(UpdateAgreementSeenDocument, options);
      }
export type UpdateAgreementSeenMutationHookResult = ReturnType<typeof useUpdateAgreementSeenMutation>;
export type UpdateAgreementSeenMutationResult = Apollo.MutationResult<UpdateAgreementSeenMutation>;
export type UpdateAgreementSeenMutationOptions = Apollo.BaseMutationOptions<UpdateAgreementSeenMutation, UpdateAgreementSeenMutationVariables>;
export const GetDealMessagesDocument = gql`
    query getDealMessages($id: ID!) {
  deal(id: $id) {
    ... on Deal {
      _id
      currentUserParty
      unit {
        straightToLease
        organisation {
          avatar
          name
          straightToLease {
            start
            end
          }
        }
      }
      messages {
        _id
        message
        user {
          _id
          firstName
          lastName
        }
        party
        sent
      }
    }
  }
}
    `;

/**
 * __useGetDealMessagesQuery__
 *
 * To run a query within a React component, call `useGetDealMessagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDealMessagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDealMessagesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetDealMessagesQuery(baseOptions: Apollo.QueryHookOptions<GetDealMessagesQuery, GetDealMessagesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDealMessagesQuery, GetDealMessagesQueryVariables>(GetDealMessagesDocument, options);
      }
export function useGetDealMessagesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDealMessagesQuery, GetDealMessagesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDealMessagesQuery, GetDealMessagesQueryVariables>(GetDealMessagesDocument, options);
        }
export type GetDealMessagesQueryHookResult = ReturnType<typeof useGetDealMessagesQuery>;
export type GetDealMessagesLazyQueryHookResult = ReturnType<typeof useGetDealMessagesLazyQuery>;
export type GetDealMessagesQueryResult = Apollo.QueryResult<GetDealMessagesQuery, GetDealMessagesQueryVariables>;
export const GetNegotiationSummaryDocument = gql`
    query getNegotiationSummary($id: ID!) {
  deal(id: $id) {
    ... on Deal {
      _id
      clauseSummary {
        complete
        incomplete
      }
      sections {
        _id
        clauseSummary {
          complete
          incomplete
        }
        groups {
          _id
          clauseSummary {
            complete
            incomplete
          }
          clauses {
            _id
            groups {
              _id
              clauseSummary {
                complete
                incomplete
              }
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetNegotiationSummaryQuery__
 *
 * To run a query within a React component, call `useGetNegotiationSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNegotiationSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNegotiationSummaryQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetNegotiationSummaryQuery(baseOptions: Apollo.QueryHookOptions<GetNegotiationSummaryQuery, GetNegotiationSummaryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetNegotiationSummaryQuery, GetNegotiationSummaryQueryVariables>(GetNegotiationSummaryDocument, options);
      }
export function useGetNegotiationSummaryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetNegotiationSummaryQuery, GetNegotiationSummaryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetNegotiationSummaryQuery, GetNegotiationSummaryQueryVariables>(GetNegotiationSummaryDocument, options);
        }
export type GetNegotiationSummaryQueryHookResult = ReturnType<typeof useGetNegotiationSummaryQuery>;
export type GetNegotiationSummaryLazyQueryHookResult = ReturnType<typeof useGetNegotiationSummaryLazyQuery>;
export type GetNegotiationSummaryQueryResult = Apollo.QueryResult<GetNegotiationSummaryQuery, GetNegotiationSummaryQueryVariables>;
export const DealBulkAddActorsDocument = gql`
    mutation dealBulkAddActors($dealIDs: [ID!]!, $emails: [String!]!, $all: Boolean!, $filter: DealFilter!) {
  dealBulkAddActors(
    dealIDs: $dealIDs
    emails: $emails
    all: $all
    filter: $filter
  ) {
    _id
  }
}
    `;
export type DealBulkAddActorsMutationFn = Apollo.MutationFunction<DealBulkAddActorsMutation, DealBulkAddActorsMutationVariables>;

/**
 * __useDealBulkAddActorsMutation__
 *
 * To run a mutation, you first call `useDealBulkAddActorsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDealBulkAddActorsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [dealBulkAddActorsMutation, { data, loading, error }] = useDealBulkAddActorsMutation({
 *   variables: {
 *      dealIDs: // value for 'dealIDs'
 *      emails: // value for 'emails'
 *      all: // value for 'all'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useDealBulkAddActorsMutation(baseOptions?: Apollo.MutationHookOptions<DealBulkAddActorsMutation, DealBulkAddActorsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DealBulkAddActorsMutation, DealBulkAddActorsMutationVariables>(DealBulkAddActorsDocument, options);
      }
export type DealBulkAddActorsMutationHookResult = ReturnType<typeof useDealBulkAddActorsMutation>;
export type DealBulkAddActorsMutationResult = Apollo.MutationResult<DealBulkAddActorsMutation>;
export type DealBulkAddActorsMutationOptions = Apollo.BaseMutationOptions<DealBulkAddActorsMutation, DealBulkAddActorsMutationVariables>;
export const RemoveTenantDocument = gql`
    mutation removeTenant($dealID: ID!, $tenantID: ID!) {
  dealRemoveTenant(dealID: $dealID, tenantID: $tenantID) {
    ... on Deal {
      _id
      tenant {
        _id
      }
    }
  }
}
    `;
export type RemoveTenantMutationFn = Apollo.MutationFunction<RemoveTenantMutation, RemoveTenantMutationVariables>;

/**
 * __useRemoveTenantMutation__
 *
 * To run a mutation, you first call `useRemoveTenantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveTenantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeTenantMutation, { data, loading, error }] = useRemoveTenantMutation({
 *   variables: {
 *      dealID: // value for 'dealID'
 *      tenantID: // value for 'tenantID'
 *   },
 * });
 */
export function useRemoveTenantMutation(baseOptions?: Apollo.MutationHookOptions<RemoveTenantMutation, RemoveTenantMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveTenantMutation, RemoveTenantMutationVariables>(RemoveTenantDocument, options);
      }
export type RemoveTenantMutationHookResult = ReturnType<typeof useRemoveTenantMutation>;
export type RemoveTenantMutationResult = Apollo.MutationResult<RemoveTenantMutation>;
export type RemoveTenantMutationOptions = Apollo.BaseMutationOptions<RemoveTenantMutation, RemoveTenantMutationVariables>;
export const SendDealDocument = gql`
    mutation sendDeal($dealID: ID!, $emails: [String!], $otherEmails: [String!], $message: String!, $type: DealSendType!, $accountType: UserType!) {
  dealSend(
    dealID: $dealID
    input: {emails: $emails, otherEmails: $otherEmails, message: $message, type: $type, accountType: $accountType}
  ) {
    ... on Deal {
      _id
      state
      currentUserParty
      tenant {
        _id
        deals {
          _id
        }
      }
    }
  }
}
    `;
export type SendDealMutationFn = Apollo.MutationFunction<SendDealMutation, SendDealMutationVariables>;

/**
 * __useSendDealMutation__
 *
 * To run a mutation, you first call `useSendDealMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendDealMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendDealMutation, { data, loading, error }] = useSendDealMutation({
 *   variables: {
 *      dealID: // value for 'dealID'
 *      emails: // value for 'emails'
 *      otherEmails: // value for 'otherEmails'
 *      message: // value for 'message'
 *      type: // value for 'type'
 *      accountType: // value for 'accountType'
 *   },
 * });
 */
export function useSendDealMutation(baseOptions?: Apollo.MutationHookOptions<SendDealMutation, SendDealMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendDealMutation, SendDealMutationVariables>(SendDealDocument, options);
      }
export type SendDealMutationHookResult = ReturnType<typeof useSendDealMutation>;
export type SendDealMutationResult = Apollo.MutationResult<SendDealMutation>;
export type SendDealMutationOptions = Apollo.BaseMutationOptions<SendDealMutation, SendDealMutationVariables>;
export const SendDealApprovalDocument = gql`
    mutation sendDealApproval($dealID: ID!, $emails: [String!], $message: String!, $approvalID: ID, $additionalApproval: Boolean, $currentUserParty: DealUserParty!, $currentUserEmail: String, $type: DealApprovalSendType) {
  dealApprovalSend(
    dealID: $dealID
    input: {emails: $emails, message: $message, type: $type, currentUserParty: $currentUserParty, currentUserEmail: $currentUserEmail, approvalID: $approvalID, additionalApproval: $additionalApproval}
  ) {
    ... on DealApprovalSendResponse {
      emails
      type
    }
  }
}
    `;
export type SendDealApprovalMutationFn = Apollo.MutationFunction<SendDealApprovalMutation, SendDealApprovalMutationVariables>;

/**
 * __useSendDealApprovalMutation__
 *
 * To run a mutation, you first call `useSendDealApprovalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendDealApprovalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendDealApprovalMutation, { data, loading, error }] = useSendDealApprovalMutation({
 *   variables: {
 *      dealID: // value for 'dealID'
 *      emails: // value for 'emails'
 *      message: // value for 'message'
 *      approvalID: // value for 'approvalID'
 *      additionalApproval: // value for 'additionalApproval'
 *      currentUserParty: // value for 'currentUserParty'
 *      currentUserEmail: // value for 'currentUserEmail'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useSendDealApprovalMutation(baseOptions?: Apollo.MutationHookOptions<SendDealApprovalMutation, SendDealApprovalMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendDealApprovalMutation, SendDealApprovalMutationVariables>(SendDealApprovalDocument, options);
      }
export type SendDealApprovalMutationHookResult = ReturnType<typeof useSendDealApprovalMutation>;
export type SendDealApprovalMutationResult = Apollo.MutationResult<SendDealApprovalMutation>;
export type SendDealApprovalMutationOptions = Apollo.BaseMutationOptions<SendDealApprovalMutation, SendDealApprovalMutationVariables>;
export const VerifyDealDocument = gql`
    mutation verifyDeal($token: String!) {
  dealVerify(token: $token) {
    ... on Deal {
      _id
      pending
    }
  }
}
    `;
export type VerifyDealMutationFn = Apollo.MutationFunction<VerifyDealMutation, VerifyDealMutationVariables>;

/**
 * __useVerifyDealMutation__
 *
 * To run a mutation, you first call `useVerifyDealMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyDealMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyDealMutation, { data, loading, error }] = useVerifyDealMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useVerifyDealMutation(baseOptions?: Apollo.MutationHookOptions<VerifyDealMutation, VerifyDealMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<VerifyDealMutation, VerifyDealMutationVariables>(VerifyDealDocument, options);
      }
export type VerifyDealMutationHookResult = ReturnType<typeof useVerifyDealMutation>;
export type VerifyDealMutationResult = Apollo.MutationResult<VerifyDealMutation>;
export type VerifyDealMutationOptions = Apollo.BaseMutationOptions<VerifyDealMutation, VerifyDealMutationVariables>;
export const GetDealOrganisationNamesDocument = gql`
    query getDealOrganisationNames {
  deals {
    results {
      unit {
        organisation {
          name
        }
      }
    }
  }
}
    `;

/**
 * __useGetDealOrganisationNamesQuery__
 *
 * To run a query within a React component, call `useGetDealOrganisationNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDealOrganisationNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDealOrganisationNamesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDealOrganisationNamesQuery(baseOptions?: Apollo.QueryHookOptions<GetDealOrganisationNamesQuery, GetDealOrganisationNamesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDealOrganisationNamesQuery, GetDealOrganisationNamesQueryVariables>(GetDealOrganisationNamesDocument, options);
      }
export function useGetDealOrganisationNamesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDealOrganisationNamesQuery, GetDealOrganisationNamesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDealOrganisationNamesQuery, GetDealOrganisationNamesQueryVariables>(GetDealOrganisationNamesDocument, options);
        }
export type GetDealOrganisationNamesQueryHookResult = ReturnType<typeof useGetDealOrganisationNamesQuery>;
export type GetDealOrganisationNamesLazyQueryHookResult = ReturnType<typeof useGetDealOrganisationNamesLazyQuery>;
export type GetDealOrganisationNamesQueryResult = Apollo.QueryResult<GetDealOrganisationNamesQuery, GetDealOrganisationNamesQueryVariables>;
export const GetDealDetailsDocument = gql`
    query getDealDetails($dealID: ID, $token: String) {
  deal(id: $dealID, token: $token) {
    ... on Deal {
      ...DealDetails
    }
  }
}
    ${DealDetailsFragmentDoc}`;

/**
 * __useGetDealDetailsQuery__
 *
 * To run a query within a React component, call `useGetDealDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDealDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDealDetailsQuery({
 *   variables: {
 *      dealID: // value for 'dealID'
 *      token: // value for 'token'
 *   },
 * });
 */
export function useGetDealDetailsQuery(baseOptions?: Apollo.QueryHookOptions<GetDealDetailsQuery, GetDealDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDealDetailsQuery, GetDealDetailsQueryVariables>(GetDealDetailsDocument, options);
      }
export function useGetDealDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDealDetailsQuery, GetDealDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDealDetailsQuery, GetDealDetailsQueryVariables>(GetDealDetailsDocument, options);
        }
export type GetDealDetailsQueryHookResult = ReturnType<typeof useGetDealDetailsQuery>;
export type GetDealDetailsLazyQueryHookResult = ReturnType<typeof useGetDealDetailsLazyQuery>;
export type GetDealDetailsQueryResult = Apollo.QueryResult<GetDealDetailsQuery, GetDealDetailsQueryVariables>;
export const GetDealDetailsNoHistoryDocument = gql`
    query getDealDetailsNoHistory($dealID: ID, $token: String) {
  deal(id: $dealID, token: $token) {
    ... on Deal {
      ...DealDetailsNoHistory
    }
  }
}
    ${DealDetailsNoHistoryFragmentDoc}`;

/**
 * __useGetDealDetailsNoHistoryQuery__
 *
 * To run a query within a React component, call `useGetDealDetailsNoHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDealDetailsNoHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDealDetailsNoHistoryQuery({
 *   variables: {
 *      dealID: // value for 'dealID'
 *      token: // value for 'token'
 *   },
 * });
 */
export function useGetDealDetailsNoHistoryQuery(baseOptions?: Apollo.QueryHookOptions<GetDealDetailsNoHistoryQuery, GetDealDetailsNoHistoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDealDetailsNoHistoryQuery, GetDealDetailsNoHistoryQueryVariables>(GetDealDetailsNoHistoryDocument, options);
      }
export function useGetDealDetailsNoHistoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDealDetailsNoHistoryQuery, GetDealDetailsNoHistoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDealDetailsNoHistoryQuery, GetDealDetailsNoHistoryQueryVariables>(GetDealDetailsNoHistoryDocument, options);
        }
export type GetDealDetailsNoHistoryQueryHookResult = ReturnType<typeof useGetDealDetailsNoHistoryQuery>;
export type GetDealDetailsNoHistoryLazyQueryHookResult = ReturnType<typeof useGetDealDetailsNoHistoryLazyQuery>;
export type GetDealDetailsNoHistoryQueryResult = Apollo.QueryResult<GetDealDetailsNoHistoryQuery, GetDealDetailsNoHistoryQueryVariables>;
export const OrganisationActivateDocument = gql`
    mutation organisationActivate($organisationID: ID!, $name: String!, $email: String, $sendInvite: Boolean!) {
  activateOrganisation(
    input: {organisationID: $organisationID, name: $name, email: $email, sendInvite: $sendInvite}
  ) {
    _id
  }
}
    `;
export type OrganisationActivateMutationFn = Apollo.MutationFunction<OrganisationActivateMutation, OrganisationActivateMutationVariables>;

/**
 * __useOrganisationActivateMutation__
 *
 * To run a mutation, you first call `useOrganisationActivateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrganisationActivateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [organisationActivateMutation, { data, loading, error }] = useOrganisationActivateMutation({
 *   variables: {
 *      organisationID: // value for 'organisationID'
 *      name: // value for 'name'
 *      email: // value for 'email'
 *      sendInvite: // value for 'sendInvite'
 *   },
 * });
 */
export function useOrganisationActivateMutation(baseOptions?: Apollo.MutationHookOptions<OrganisationActivateMutation, OrganisationActivateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<OrganisationActivateMutation, OrganisationActivateMutationVariables>(OrganisationActivateDocument, options);
      }
export type OrganisationActivateMutationHookResult = ReturnType<typeof useOrganisationActivateMutation>;
export type OrganisationActivateMutationResult = Apollo.MutationResult<OrganisationActivateMutation>;
export type OrganisationActivateMutationOptions = Apollo.BaseMutationOptions<OrganisationActivateMutation, OrganisationActivateMutationVariables>;
export const DealBulkHideDocument = gql`
    mutation dealBulkHide($dealIDs: [ID!]!, $all: Boolean!, $filter: DealFilter!, $removeOnActive: Boolean) {
  dealBulkHide(
    dealIDs: $dealIDs
    filter: $filter
    all: $all
    removeOnActive: $removeOnActive
  ) {
    _id
  }
}
    `;
export type DealBulkHideMutationFn = Apollo.MutationFunction<DealBulkHideMutation, DealBulkHideMutationVariables>;

/**
 * __useDealBulkHideMutation__
 *
 * To run a mutation, you first call `useDealBulkHideMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDealBulkHideMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [dealBulkHideMutation, { data, loading, error }] = useDealBulkHideMutation({
 *   variables: {
 *      dealIDs: // value for 'dealIDs'
 *      all: // value for 'all'
 *      filter: // value for 'filter'
 *      removeOnActive: // value for 'removeOnActive'
 *   },
 * });
 */
export function useDealBulkHideMutation(baseOptions?: Apollo.MutationHookOptions<DealBulkHideMutation, DealBulkHideMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DealBulkHideMutation, DealBulkHideMutationVariables>(DealBulkHideDocument, options);
      }
export type DealBulkHideMutationHookResult = ReturnType<typeof useDealBulkHideMutation>;
export type DealBulkHideMutationResult = Apollo.MutationResult<DealBulkHideMutation>;
export type DealBulkHideMutationOptions = Apollo.BaseMutationOptions<DealBulkHideMutation, DealBulkHideMutationVariables>;
export const DealBulkUnhideDocument = gql`
    mutation dealBulkUnhide($dealIDs: [ID!]!, $all: Boolean!, $filter: DealFilter!, $unhideConnectedUnit: Boolean) {
  dealBulkUnhide(
    dealIDs: $dealIDs
    filter: $filter
    all: $all
    unhideConnectedUnit: $unhideConnectedUnit
  ) {
    _id
  }
}
    `;
export type DealBulkUnhideMutationFn = Apollo.MutationFunction<DealBulkUnhideMutation, DealBulkUnhideMutationVariables>;

/**
 * __useDealBulkUnhideMutation__
 *
 * To run a mutation, you first call `useDealBulkUnhideMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDealBulkUnhideMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [dealBulkUnhideMutation, { data, loading, error }] = useDealBulkUnhideMutation({
 *   variables: {
 *      dealIDs: // value for 'dealIDs'
 *      all: // value for 'all'
 *      filter: // value for 'filter'
 *      unhideConnectedUnit: // value for 'unhideConnectedUnit'
 *   },
 * });
 */
export function useDealBulkUnhideMutation(baseOptions?: Apollo.MutationHookOptions<DealBulkUnhideMutation, DealBulkUnhideMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DealBulkUnhideMutation, DealBulkUnhideMutationVariables>(DealBulkUnhideDocument, options);
      }
export type DealBulkUnhideMutationHookResult = ReturnType<typeof useDealBulkUnhideMutation>;
export type DealBulkUnhideMutationResult = Apollo.MutationResult<DealBulkUnhideMutation>;
export type DealBulkUnhideMutationOptions = Apollo.BaseMutationOptions<DealBulkUnhideMutation, DealBulkUnhideMutationVariables>;
export const DeleteDealDocument = gql`
    mutation deleteDeal($dealID: ID!) {
  dealDelete(id: $dealID) {
    deletedDealID
  }
}
    `;
export type DeleteDealMutationFn = Apollo.MutationFunction<DeleteDealMutation, DeleteDealMutationVariables>;

/**
 * __useDeleteDealMutation__
 *
 * To run a mutation, you first call `useDeleteDealMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDealMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDealMutation, { data, loading, error }] = useDeleteDealMutation({
 *   variables: {
 *      dealID: // value for 'dealID'
 *   },
 * });
 */
export function useDeleteDealMutation(baseOptions?: Apollo.MutationHookOptions<DeleteDealMutation, DeleteDealMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteDealMutation, DeleteDealMutationVariables>(DeleteDealDocument, options);
      }
export type DeleteDealMutationHookResult = ReturnType<typeof useDeleteDealMutation>;
export type DeleteDealMutationResult = Apollo.MutationResult<DeleteDealMutation>;
export type DeleteDealMutationOptions = Apollo.BaseMutationOptions<DeleteDealMutation, DeleteDealMutationVariables>;
export const HideDealDocument = gql`
    mutation HideDeal($dealID: ID!, $removeOnActive: Boolean) {
  dealHide(dealID: $dealID, removeOnActive: $removeOnActive) {
    _id
  }
}
    `;
export type HideDealMutationFn = Apollo.MutationFunction<HideDealMutation, HideDealMutationVariables>;

/**
 * __useHideDealMutation__
 *
 * To run a mutation, you first call `useHideDealMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useHideDealMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [hideDealMutation, { data, loading, error }] = useHideDealMutation({
 *   variables: {
 *      dealID: // value for 'dealID'
 *      removeOnActive: // value for 'removeOnActive'
 *   },
 * });
 */
export function useHideDealMutation(baseOptions?: Apollo.MutationHookOptions<HideDealMutation, HideDealMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<HideDealMutation, HideDealMutationVariables>(HideDealDocument, options);
      }
export type HideDealMutationHookResult = ReturnType<typeof useHideDealMutation>;
export type HideDealMutationResult = Apollo.MutationResult<HideDealMutation>;
export type HideDealMutationOptions = Apollo.BaseMutationOptions<HideDealMutation, HideDealMutationVariables>;
export const NewDealDocument = gql`
    mutation newDeal($unitID: ID!, $templateID: ID) {
  dealCreate(unitID: $unitID, templateID: $templateID) {
    ... on Deal {
      _id
      pending
      state
      score
      clauseSummary {
        incomplete
        complete
      }
      unit {
        _id
        url
        name
        buildingName
        rating
        actors {
          _id
        }
        address {
          line1
        }
        organisation {
          _id
          name
          members {
            _id
          }
        }
      }
    }
  }
}
    `;
export type NewDealMutationFn = Apollo.MutationFunction<NewDealMutation, NewDealMutationVariables>;

/**
 * __useNewDealMutation__
 *
 * To run a mutation, you first call `useNewDealMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNewDealMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [newDealMutation, { data, loading, error }] = useNewDealMutation({
 *   variables: {
 *      unitID: // value for 'unitID'
 *      templateID: // value for 'templateID'
 *   },
 * });
 */
export function useNewDealMutation(baseOptions?: Apollo.MutationHookOptions<NewDealMutation, NewDealMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<NewDealMutation, NewDealMutationVariables>(NewDealDocument, options);
      }
export type NewDealMutationHookResult = ReturnType<typeof useNewDealMutation>;
export type NewDealMutationResult = Apollo.MutationResult<NewDealMutation>;
export type NewDealMutationOptions = Apollo.BaseMutationOptions<NewDealMutation, NewDealMutationVariables>;
export const DealNudgeDocument = gql`
    mutation dealNudge($dealID: ID!, $message: String!) {
  dealNudge(dealID: $dealID, message: $message) {
    dealID
  }
}
    `;
export type DealNudgeMutationFn = Apollo.MutationFunction<DealNudgeMutation, DealNudgeMutationVariables>;

/**
 * __useDealNudgeMutation__
 *
 * To run a mutation, you first call `useDealNudgeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDealNudgeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [dealNudgeMutation, { data, loading, error }] = useDealNudgeMutation({
 *   variables: {
 *      dealID: // value for 'dealID'
 *      message: // value for 'message'
 *   },
 * });
 */
export function useDealNudgeMutation(baseOptions?: Apollo.MutationHookOptions<DealNudgeMutation, DealNudgeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DealNudgeMutation, DealNudgeMutationVariables>(DealNudgeDocument, options);
      }
export type DealNudgeMutationHookResult = ReturnType<typeof useDealNudgeMutation>;
export type DealNudgeMutationResult = Apollo.MutationResult<DealNudgeMutation>;
export type DealNudgeMutationOptions = Apollo.BaseMutationOptions<DealNudgeMutation, DealNudgeMutationVariables>;
export const PinDealDocument = gql`
    mutation pinDeal($dealID: ID!) {
  userAddPinnedDeal(dealID: $dealID) {
    ... on User {
      ...UserDetails
    }
  }
}
    ${UserDetailsFragmentDoc}`;
export type PinDealMutationFn = Apollo.MutationFunction<PinDealMutation, PinDealMutationVariables>;

/**
 * __usePinDealMutation__
 *
 * To run a mutation, you first call `usePinDealMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePinDealMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [pinDealMutation, { data, loading, error }] = usePinDealMutation({
 *   variables: {
 *      dealID: // value for 'dealID'
 *   },
 * });
 */
export function usePinDealMutation(baseOptions?: Apollo.MutationHookOptions<PinDealMutation, PinDealMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PinDealMutation, PinDealMutationVariables>(PinDealDocument, options);
      }
export type PinDealMutationHookResult = ReturnType<typeof usePinDealMutation>;
export type PinDealMutationResult = Apollo.MutationResult<PinDealMutation>;
export type PinDealMutationOptions = Apollo.BaseMutationOptions<PinDealMutation, PinDealMutationVariables>;
export const UnhideDealDocument = gql`
    mutation UnhideDeal($dealID: ID!, $unhideConnectedUnit: Boolean) {
  dealUnhide(dealID: $dealID, unhideConnectedUnit: $unhideConnectedUnit) {
    _id
  }
}
    `;
export type UnhideDealMutationFn = Apollo.MutationFunction<UnhideDealMutation, UnhideDealMutationVariables>;

/**
 * __useUnhideDealMutation__
 *
 * To run a mutation, you first call `useUnhideDealMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnhideDealMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unhideDealMutation, { data, loading, error }] = useUnhideDealMutation({
 *   variables: {
 *      dealID: // value for 'dealID'
 *      unhideConnectedUnit: // value for 'unhideConnectedUnit'
 *   },
 * });
 */
export function useUnhideDealMutation(baseOptions?: Apollo.MutationHookOptions<UnhideDealMutation, UnhideDealMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UnhideDealMutation, UnhideDealMutationVariables>(UnhideDealDocument, options);
      }
export type UnhideDealMutationHookResult = ReturnType<typeof useUnhideDealMutation>;
export type UnhideDealMutationResult = Apollo.MutationResult<UnhideDealMutation>;
export type UnhideDealMutationOptions = Apollo.BaseMutationOptions<UnhideDealMutation, UnhideDealMutationVariables>;
export const UnpinDealDocument = gql`
    mutation unpinDeal($dealID: ID!) {
  userRemovePinnedDeal(dealID: $dealID) {
    ... on User {
      ...UserDetails
    }
  }
}
    ${UserDetailsFragmentDoc}`;
export type UnpinDealMutationFn = Apollo.MutationFunction<UnpinDealMutation, UnpinDealMutationVariables>;

/**
 * __useUnpinDealMutation__
 *
 * To run a mutation, you first call `useUnpinDealMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnpinDealMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unpinDealMutation, { data, loading, error }] = useUnpinDealMutation({
 *   variables: {
 *      dealID: // value for 'dealID'
 *   },
 * });
 */
export function useUnpinDealMutation(baseOptions?: Apollo.MutationHookOptions<UnpinDealMutation, UnpinDealMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UnpinDealMutation, UnpinDealMutationVariables>(UnpinDealDocument, options);
      }
export type UnpinDealMutationHookResult = ReturnType<typeof useUnpinDealMutation>;
export type UnpinDealMutationResult = Apollo.MutationResult<UnpinDealMutation>;
export type UnpinDealMutationOptions = Apollo.BaseMutationOptions<UnpinDealMutation, UnpinDealMutationVariables>;
export const GetDealCardDetailsDocument = gql`
    query getDealCardDetails($query: String, $limit: Int, $offset: Int, $filter: DealFilter, $sort: DealSort) {
  deals(
    query: $query
    limit: $limit
    offset: $offset
    filter: $filter
    sort: $sort
  ) {
    results {
      ...DealCard
    }
    pageInfo {
      total
      hasResults
      hasNextPage
    }
  }
}
    ${DealCardFragmentDoc}`;

/**
 * __useGetDealCardDetailsQuery__
 *
 * To run a query within a React component, call `useGetDealCardDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDealCardDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDealCardDetailsQuery({
 *   variables: {
 *      query: // value for 'query'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useGetDealCardDetailsQuery(baseOptions?: Apollo.QueryHookOptions<GetDealCardDetailsQuery, GetDealCardDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDealCardDetailsQuery, GetDealCardDetailsQueryVariables>(GetDealCardDetailsDocument, options);
      }
export function useGetDealCardDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDealCardDetailsQuery, GetDealCardDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDealCardDetailsQuery, GetDealCardDetailsQueryVariables>(GetDealCardDetailsDocument, options);
        }
export type GetDealCardDetailsQueryHookResult = ReturnType<typeof useGetDealCardDetailsQuery>;
export type GetDealCardDetailsLazyQueryHookResult = ReturnType<typeof useGetDealCardDetailsLazyQuery>;
export type GetDealCardDetailsQueryResult = Apollo.QueryResult<GetDealCardDetailsQuery, GetDealCardDetailsQueryVariables>;
export const ExportDealsDocument = gql`
    query exportDeals {
  exportDeals {
    url
  }
}
    `;

/**
 * __useExportDealsQuery__
 *
 * To run a query within a React component, call `useExportDealsQuery` and pass it any options that fit your needs.
 * When your component renders, `useExportDealsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExportDealsQuery({
 *   variables: {
 *   },
 * });
 */
export function useExportDealsQuery(baseOptions?: Apollo.QueryHookOptions<ExportDealsQuery, ExportDealsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExportDealsQuery, ExportDealsQueryVariables>(ExportDealsDocument, options);
      }
export function useExportDealsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExportDealsQuery, ExportDealsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExportDealsQuery, ExportDealsQueryVariables>(ExportDealsDocument, options);
        }
export type ExportDealsQueryHookResult = ReturnType<typeof useExportDealsQuery>;
export type ExportDealsLazyQueryHookResult = ReturnType<typeof useExportDealsLazyQuery>;
export type ExportDealsQueryResult = Apollo.QueryResult<ExportDealsQuery, ExportDealsQueryVariables>;
export const GetDealBuildingNamesDocument = gql`
    query getDealBuildingNames {
  deals {
    results {
      unit {
        buildingName
      }
    }
  }
}
    `;

/**
 * __useGetDealBuildingNamesQuery__
 *
 * To run a query within a React component, call `useGetDealBuildingNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDealBuildingNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDealBuildingNamesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDealBuildingNamesQuery(baseOptions?: Apollo.QueryHookOptions<GetDealBuildingNamesQuery, GetDealBuildingNamesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDealBuildingNamesQuery, GetDealBuildingNamesQueryVariables>(GetDealBuildingNamesDocument, options);
      }
export function useGetDealBuildingNamesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDealBuildingNamesQuery, GetDealBuildingNamesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDealBuildingNamesQuery, GetDealBuildingNamesQueryVariables>(GetDealBuildingNamesDocument, options);
        }
export type GetDealBuildingNamesQueryHookResult = ReturnType<typeof useGetDealBuildingNamesQuery>;
export type GetDealBuildingNamesLazyQueryHookResult = ReturnType<typeof useGetDealBuildingNamesLazyQuery>;
export type GetDealBuildingNamesQueryResult = Apollo.QueryResult<GetDealBuildingNamesQuery, GetDealBuildingNamesQueryVariables>;
export const GetDealCitiesDocument = gql`
    query getDealCities {
  deals {
    results {
      unit {
        address {
          city
        }
      }
    }
  }
}
    `;

/**
 * __useGetDealCitiesQuery__
 *
 * To run a query within a React component, call `useGetDealCitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDealCitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDealCitiesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDealCitiesQuery(baseOptions?: Apollo.QueryHookOptions<GetDealCitiesQuery, GetDealCitiesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDealCitiesQuery, GetDealCitiesQueryVariables>(GetDealCitiesDocument, options);
      }
export function useGetDealCitiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDealCitiesQuery, GetDealCitiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDealCitiesQuery, GetDealCitiesQueryVariables>(GetDealCitiesDocument, options);
        }
export type GetDealCitiesQueryHookResult = ReturnType<typeof useGetDealCitiesQuery>;
export type GetDealCitiesLazyQueryHookResult = ReturnType<typeof useGetDealCitiesLazyQuery>;
export type GetDealCitiesQueryResult = Apollo.QueryResult<GetDealCitiesQuery, GetDealCitiesQueryVariables>;
export const GetDealOrganisationsDocument = gql`
    query getDealOrganisations {
  deals {
    results {
      client {
        _id
        name
      }
    }
  }
}
    `;

/**
 * __useGetDealOrganisationsQuery__
 *
 * To run a query within a React component, call `useGetDealOrganisationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDealOrganisationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDealOrganisationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDealOrganisationsQuery(baseOptions?: Apollo.QueryHookOptions<GetDealOrganisationsQuery, GetDealOrganisationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDealOrganisationsQuery, GetDealOrganisationsQueryVariables>(GetDealOrganisationsDocument, options);
      }
export function useGetDealOrganisationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDealOrganisationsQuery, GetDealOrganisationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDealOrganisationsQuery, GetDealOrganisationsQueryVariables>(GetDealOrganisationsDocument, options);
        }
export type GetDealOrganisationsQueryHookResult = ReturnType<typeof useGetDealOrganisationsQuery>;
export type GetDealOrganisationsLazyQueryHookResult = ReturnType<typeof useGetDealOrganisationsLazyQuery>;
export type GetDealOrganisationsQueryResult = Apollo.QueryResult<GetDealOrganisationsQuery, GetDealOrganisationsQueryVariables>;
export const GetDealTemplatesDocument = gql`
    query getDealTemplates {
  deals {
    results {
      template {
        _id
        name
      }
    }
  }
}
    `;

/**
 * __useGetDealTemplatesQuery__
 *
 * To run a query within a React component, call `useGetDealTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDealTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDealTemplatesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDealTemplatesQuery(baseOptions?: Apollo.QueryHookOptions<GetDealTemplatesQuery, GetDealTemplatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDealTemplatesQuery, GetDealTemplatesQueryVariables>(GetDealTemplatesDocument, options);
      }
export function useGetDealTemplatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDealTemplatesQuery, GetDealTemplatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDealTemplatesQuery, GetDealTemplatesQueryVariables>(GetDealTemplatesDocument, options);
        }
export type GetDealTemplatesQueryHookResult = ReturnType<typeof useGetDealTemplatesQuery>;
export type GetDealTemplatesLazyQueryHookResult = ReturnType<typeof useGetDealTemplatesLazyQuery>;
export type GetDealTemplatesQueryResult = Apollo.QueryResult<GetDealTemplatesQuery, GetDealTemplatesQueryVariables>;
export const GetDealCreatorDocument = gql`
    query getDealCreator {
  deals {
    results {
      creator {
        _id
        firstName
        lastName
      }
    }
  }
}
    `;

/**
 * __useGetDealCreatorQuery__
 *
 * To run a query within a React component, call `useGetDealCreatorQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDealCreatorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDealCreatorQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDealCreatorQuery(baseOptions?: Apollo.QueryHookOptions<GetDealCreatorQuery, GetDealCreatorQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDealCreatorQuery, GetDealCreatorQueryVariables>(GetDealCreatorDocument, options);
      }
export function useGetDealCreatorLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDealCreatorQuery, GetDealCreatorQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDealCreatorQuery, GetDealCreatorQueryVariables>(GetDealCreatorDocument, options);
        }
export type GetDealCreatorQueryHookResult = ReturnType<typeof useGetDealCreatorQuery>;
export type GetDealCreatorLazyQueryHookResult = ReturnType<typeof useGetDealCreatorLazyQuery>;
export type GetDealCreatorQueryResult = Apollo.QueryResult<GetDealCreatorQuery, GetDealCreatorQueryVariables>;
export const DealFilterCountsDocument = gql`
    query DealFilterCounts($query: String, $filter: DealFilter) {
  dealFilterCounts(query: $query, filter: $filter) {
    live
    complete
    hidden
  }
}
    `;

/**
 * __useDealFilterCountsQuery__
 *
 * To run a query within a React component, call `useDealFilterCountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDealFilterCountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDealFilterCountsQuery({
 *   variables: {
 *      query: // value for 'query'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useDealFilterCountsQuery(baseOptions?: Apollo.QueryHookOptions<DealFilterCountsQuery, DealFilterCountsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DealFilterCountsQuery, DealFilterCountsQueryVariables>(DealFilterCountsDocument, options);
      }
export function useDealFilterCountsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DealFilterCountsQuery, DealFilterCountsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DealFilterCountsQuery, DealFilterCountsQueryVariables>(DealFilterCountsDocument, options);
        }
export type DealFilterCountsQueryHookResult = ReturnType<typeof useDealFilterCountsQuery>;
export type DealFilterCountsLazyQueryHookResult = ReturnType<typeof useDealFilterCountsLazyQuery>;
export type DealFilterCountsQueryResult = Apollo.QueryResult<DealFilterCountsQuery, DealFilterCountsQueryVariables>;
export const GetStagesCompletedDocument = gql`
    query getStagesCompleted($dealID: ID!, $token: String) {
  deal(id: $dealID, token: $token) {
    _id
    details {
      ...CompletedStages
    }
  }
}
    ${CompletedStagesFragmentDoc}`;

/**
 * __useGetStagesCompletedQuery__
 *
 * To run a query within a React component, call `useGetStagesCompletedQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStagesCompletedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStagesCompletedQuery({
 *   variables: {
 *      dealID: // value for 'dealID'
 *      token: // value for 'token'
 *   },
 * });
 */
export function useGetStagesCompletedQuery(baseOptions: Apollo.QueryHookOptions<GetStagesCompletedQuery, GetStagesCompletedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStagesCompletedQuery, GetStagesCompletedQueryVariables>(GetStagesCompletedDocument, options);
      }
export function useGetStagesCompletedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStagesCompletedQuery, GetStagesCompletedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStagesCompletedQuery, GetStagesCompletedQueryVariables>(GetStagesCompletedDocument, options);
        }
export type GetStagesCompletedQueryHookResult = ReturnType<typeof useGetStagesCompletedQuery>;
export type GetStagesCompletedLazyQueryHookResult = ReturnType<typeof useGetStagesCompletedLazyQuery>;
export type GetStagesCompletedQueryResult = Apollo.QueryResult<GetStagesCompletedQuery, GetStagesCompletedQueryVariables>;
export const ActivateUserDocument = gql`
    mutation activateUser($token: String!) {
  userActivate(userActivationToken: $token) {
    ... on User {
      email
    }
  }
}
    `;
export type ActivateUserMutationFn = Apollo.MutationFunction<ActivateUserMutation, ActivateUserMutationVariables>;

/**
 * __useActivateUserMutation__
 *
 * To run a mutation, you first call `useActivateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateUserMutation, { data, loading, error }] = useActivateUserMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useActivateUserMutation(baseOptions?: Apollo.MutationHookOptions<ActivateUserMutation, ActivateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ActivateUserMutation, ActivateUserMutationVariables>(ActivateUserDocument, options);
      }
export type ActivateUserMutationHookResult = ReturnType<typeof useActivateUserMutation>;
export type ActivateUserMutationResult = Apollo.MutationResult<ActivateUserMutation>;
export type ActivateUserMutationOptions = Apollo.BaseMutationOptions<ActivateUserMutation, ActivateUserMutationVariables>;
export const NewInvitedUserDocument = gql`
    mutation newInvitedUser($token: String!, $firstName: String!, $lastName: String!, $organisation: String, $practiceType: PracticeType, $mobile: String!, $password: String!) {
  userInviteCreate(
    token: $token
    input: {firstName: $firstName, lastName: $lastName, organisation: $organisation, mobile: $mobile, practiceType: $practiceType, password: $password}
  ) {
    ... on User {
      _id
      firstName
      lastName
      organisation {
        name
      }
    }
  }
}
    `;
export type NewInvitedUserMutationFn = Apollo.MutationFunction<NewInvitedUserMutation, NewInvitedUserMutationVariables>;

/**
 * __useNewInvitedUserMutation__
 *
 * To run a mutation, you first call `useNewInvitedUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNewInvitedUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [newInvitedUserMutation, { data, loading, error }] = useNewInvitedUserMutation({
 *   variables: {
 *      token: // value for 'token'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      organisation: // value for 'organisation'
 *      practiceType: // value for 'practiceType'
 *      mobile: // value for 'mobile'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useNewInvitedUserMutation(baseOptions?: Apollo.MutationHookOptions<NewInvitedUserMutation, NewInvitedUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<NewInvitedUserMutation, NewInvitedUserMutationVariables>(NewInvitedUserDocument, options);
      }
export type NewInvitedUserMutationHookResult = ReturnType<typeof useNewInvitedUserMutation>;
export type NewInvitedUserMutationResult = Apollo.MutationResult<NewInvitedUserMutation>;
export type NewInvitedUserMutationOptions = Apollo.BaseMutationOptions<NewInvitedUserMutation, NewInvitedUserMutationVariables>;
export const ResetUserPasswordDocument = gql`
    mutation resetUserPassword($token: String!, $password: String!) {
  userResetPassword(input: {password: $password, passwordResetToken: $token}) {
    ... on User {
      email
    }
  }
}
    `;
export type ResetUserPasswordMutationFn = Apollo.MutationFunction<ResetUserPasswordMutation, ResetUserPasswordMutationVariables>;

/**
 * __useResetUserPasswordMutation__
 *
 * To run a mutation, you first call `useResetUserPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetUserPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetUserPasswordMutation, { data, loading, error }] = useResetUserPasswordMutation({
 *   variables: {
 *      token: // value for 'token'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useResetUserPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ResetUserPasswordMutation, ResetUserPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResetUserPasswordMutation, ResetUserPasswordMutationVariables>(ResetUserPasswordDocument, options);
      }
export type ResetUserPasswordMutationHookResult = ReturnType<typeof useResetUserPasswordMutation>;
export type ResetUserPasswordMutationResult = Apollo.MutationResult<ResetUserPasswordMutation>;
export type ResetUserPasswordMutationOptions = Apollo.BaseMutationOptions<ResetUserPasswordMutation, ResetUserPasswordMutationVariables>;
export const GetInviteDetailsDocument = gql`
    query getInviteDetails($token: String!) {
  invite(token: $token) {
    ... on InviteDetails {
      user {
        _id
        email
        type
      }
      organisation {
        _id
        avatar
        name
        members {
          _id
        }
      }
    }
  }
}
    `;

/**
 * __useGetInviteDetailsQuery__
 *
 * To run a query within a React component, call `useGetInviteDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInviteDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInviteDetailsQuery({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useGetInviteDetailsQuery(baseOptions: Apollo.QueryHookOptions<GetInviteDetailsQuery, GetInviteDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetInviteDetailsQuery, GetInviteDetailsQueryVariables>(GetInviteDetailsDocument, options);
      }
export function useGetInviteDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInviteDetailsQuery, GetInviteDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetInviteDetailsQuery, GetInviteDetailsQueryVariables>(GetInviteDetailsDocument, options);
        }
export type GetInviteDetailsQueryHookResult = ReturnType<typeof useGetInviteDetailsQuery>;
export type GetInviteDetailsLazyQueryHookResult = ReturnType<typeof useGetInviteDetailsLazyQuery>;
export type GetInviteDetailsQueryResult = Apollo.QueryResult<GetInviteDetailsQuery, GetInviteDetailsQueryVariables>;
export const GetOrganisationAvatarDocument = gql`
    query getOrganisationAvatar($id: ID!) {
  organisation(id: $id) {
    ... on Organisation {
      avatar
    }
  }
}
    `;

/**
 * __useGetOrganisationAvatarQuery__
 *
 * To run a query within a React component, call `useGetOrganisationAvatarQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrganisationAvatarQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrganisationAvatarQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetOrganisationAvatarQuery(baseOptions: Apollo.QueryHookOptions<GetOrganisationAvatarQuery, GetOrganisationAvatarQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOrganisationAvatarQuery, GetOrganisationAvatarQueryVariables>(GetOrganisationAvatarDocument, options);
      }
export function useGetOrganisationAvatarLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrganisationAvatarQuery, GetOrganisationAvatarQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOrganisationAvatarQuery, GetOrganisationAvatarQueryVariables>(GetOrganisationAvatarDocument, options);
        }
export type GetOrganisationAvatarQueryHookResult = ReturnType<typeof useGetOrganisationAvatarQuery>;
export type GetOrganisationAvatarLazyQueryHookResult = ReturnType<typeof useGetOrganisationAvatarLazyQuery>;
export type GetOrganisationAvatarQueryResult = Apollo.QueryResult<GetOrganisationAvatarQuery, GetOrganisationAvatarQueryVariables>;
export const UpdateAdminClauseDocument = gql`
    mutation updateAdminClause($id: ID!, $updateDeals: Boolean!, $updateTemplates: Boolean!) {
  adminClauseUpdate(
    id: $id
    updateDeals: $updateDeals
    updateTemplates: $updateTemplates
  ) {
    _id
    updatedDealsAt
    updatedTemplatesAt
  }
}
    `;
export type UpdateAdminClauseMutationFn = Apollo.MutationFunction<UpdateAdminClauseMutation, UpdateAdminClauseMutationVariables>;

/**
 * __useUpdateAdminClauseMutation__
 *
 * To run a mutation, you first call `useUpdateAdminClauseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAdminClauseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAdminClauseMutation, { data, loading, error }] = useUpdateAdminClauseMutation({
 *   variables: {
 *      id: // value for 'id'
 *      updateDeals: // value for 'updateDeals'
 *      updateTemplates: // value for 'updateTemplates'
 *   },
 * });
 */
export function useUpdateAdminClauseMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAdminClauseMutation, UpdateAdminClauseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAdminClauseMutation, UpdateAdminClauseMutationVariables>(UpdateAdminClauseDocument, options);
      }
export type UpdateAdminClauseMutationHookResult = ReturnType<typeof useUpdateAdminClauseMutation>;
export type UpdateAdminClauseMutationResult = Apollo.MutationResult<UpdateAdminClauseMutation>;
export type UpdateAdminClauseMutationOptions = Apollo.BaseMutationOptions<UpdateAdminClauseMutation, UpdateAdminClauseMutationVariables>;
export const UpdateOrganisationClauseDocument = gql`
    mutation updateOrganisationClause($id: ID!, $organisationID: ID!, $updateDeals: Boolean!, $updateTemplates: Boolean!) {
  organisationClauseUpdate(
    id: $id
    organisationID: $organisationID
    updateDeals: $updateDeals
    updateTemplates: $updateTemplates
  ) {
    _id
    updatedDealsAt
    updatedTemplatesAt
  }
}
    `;
export type UpdateOrganisationClauseMutationFn = Apollo.MutationFunction<UpdateOrganisationClauseMutation, UpdateOrganisationClauseMutationVariables>;

/**
 * __useUpdateOrganisationClauseMutation__
 *
 * To run a mutation, you first call `useUpdateOrganisationClauseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrganisationClauseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrganisationClauseMutation, { data, loading, error }] = useUpdateOrganisationClauseMutation({
 *   variables: {
 *      id: // value for 'id'
 *      organisationID: // value for 'organisationID'
 *      updateDeals: // value for 'updateDeals'
 *      updateTemplates: // value for 'updateTemplates'
 *   },
 * });
 */
export function useUpdateOrganisationClauseMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOrganisationClauseMutation, UpdateOrganisationClauseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOrganisationClauseMutation, UpdateOrganisationClauseMutationVariables>(UpdateOrganisationClauseDocument, options);
      }
export type UpdateOrganisationClauseMutationHookResult = ReturnType<typeof useUpdateOrganisationClauseMutation>;
export type UpdateOrganisationClauseMutationResult = Apollo.MutationResult<UpdateOrganisationClauseMutation>;
export type UpdateOrganisationClauseMutationOptions = Apollo.BaseMutationOptions<UpdateOrganisationClauseMutation, UpdateOrganisationClauseMutationVariables>;
export const OrganisationCreateDocument = gql`
    mutation organisationCreate($name: String!, $type: UserType!, $active: Boolean, $address: AddressCreateInput, $website: String, $avatar: String, $emails: [String!]!, $practiceType: PracticeType) {
  organisationCreate(
    input: {name: $name, type: $type, active: $active, website: $website, avatar: $avatar, emails: $emails, address: $address, practiceType: $practiceType}
  ) {
    ... on OrganisationResult {
      organisation {
        _id
      }
      errors
    }
  }
}
    `;
export type OrganisationCreateMutationFn = Apollo.MutationFunction<OrganisationCreateMutation, OrganisationCreateMutationVariables>;

/**
 * __useOrganisationCreateMutation__
 *
 * To run a mutation, you first call `useOrganisationCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrganisationCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [organisationCreateMutation, { data, loading, error }] = useOrganisationCreateMutation({
 *   variables: {
 *      name: // value for 'name'
 *      type: // value for 'type'
 *      active: // value for 'active'
 *      address: // value for 'address'
 *      website: // value for 'website'
 *      avatar: // value for 'avatar'
 *      emails: // value for 'emails'
 *      practiceType: // value for 'practiceType'
 *   },
 * });
 */
export function useOrganisationCreateMutation(baseOptions?: Apollo.MutationHookOptions<OrganisationCreateMutation, OrganisationCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<OrganisationCreateMutation, OrganisationCreateMutationVariables>(OrganisationCreateDocument, options);
      }
export type OrganisationCreateMutationHookResult = ReturnType<typeof useOrganisationCreateMutation>;
export type OrganisationCreateMutationResult = Apollo.MutationResult<OrganisationCreateMutation>;
export type OrganisationCreateMutationOptions = Apollo.BaseMutationOptions<OrganisationCreateMutation, OrganisationCreateMutationVariables>;
export const OrganisationDeleteDocument = gql`
    mutation organisationDelete($id: ID!, $input: AdminOrganisationDeleteInput!) {
  organisationDelete(id: $id, input: $input)
}
    `;
export type OrganisationDeleteMutationFn = Apollo.MutationFunction<OrganisationDeleteMutation, OrganisationDeleteMutationVariables>;

/**
 * __useOrganisationDeleteMutation__
 *
 * To run a mutation, you first call `useOrganisationDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrganisationDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [organisationDeleteMutation, { data, loading, error }] = useOrganisationDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOrganisationDeleteMutation(baseOptions?: Apollo.MutationHookOptions<OrganisationDeleteMutation, OrganisationDeleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<OrganisationDeleteMutation, OrganisationDeleteMutationVariables>(OrganisationDeleteDocument, options);
      }
export type OrganisationDeleteMutationHookResult = ReturnType<typeof useOrganisationDeleteMutation>;
export type OrganisationDeleteMutationResult = Apollo.MutationResult<OrganisationDeleteMutation>;
export type OrganisationDeleteMutationOptions = Apollo.BaseMutationOptions<OrganisationDeleteMutation, OrganisationDeleteMutationVariables>;
export const GetAllOrganisationsDocument = gql`
    query getAllOrganisations($limit: Int, $offset: Int, $query: String, $sort: OrganisationSort, $filter: OrganisationFilter) {
  organisations(
    limit: $limit
    offset: $offset
    query: $query
    sort: $sort
    filter: $filter
  ) {
    results {
      ...OrganisationCard
    }
    pageInfo {
      total
      hasNextPage
    }
  }
}
    ${OrganisationCardFragmentDoc}`;

/**
 * __useGetAllOrganisationsQuery__
 *
 * To run a query within a React component, call `useGetAllOrganisationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllOrganisationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllOrganisationsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      query: // value for 'query'
 *      sort: // value for 'sort'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetAllOrganisationsQuery(baseOptions?: Apollo.QueryHookOptions<GetAllOrganisationsQuery, GetAllOrganisationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllOrganisationsQuery, GetAllOrganisationsQueryVariables>(GetAllOrganisationsDocument, options);
      }
export function useGetAllOrganisationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllOrganisationsQuery, GetAllOrganisationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllOrganisationsQuery, GetAllOrganisationsQueryVariables>(GetAllOrganisationsDocument, options);
        }
export type GetAllOrganisationsQueryHookResult = ReturnType<typeof useGetAllOrganisationsQuery>;
export type GetAllOrganisationsLazyQueryHookResult = ReturnType<typeof useGetAllOrganisationsLazyQuery>;
export type GetAllOrganisationsQueryResult = Apollo.QueryResult<GetAllOrganisationsQuery, GetAllOrganisationsQueryVariables>;
export const OrganisationFilterCountsDocument = gql`
    query OrganisationFilterCounts($filter: OrganisationFilter, $query: String) {
  organisationFilterCounts(filter: $filter, query: $query) {
    all
    landlord
    agent
    tenant
  }
}
    `;

/**
 * __useOrganisationFilterCountsQuery__
 *
 * To run a query within a React component, call `useOrganisationFilterCountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganisationFilterCountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganisationFilterCountsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      query: // value for 'query'
 *   },
 * });
 */
export function useOrganisationFilterCountsQuery(baseOptions?: Apollo.QueryHookOptions<OrganisationFilterCountsQuery, OrganisationFilterCountsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrganisationFilterCountsQuery, OrganisationFilterCountsQueryVariables>(OrganisationFilterCountsDocument, options);
      }
export function useOrganisationFilterCountsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrganisationFilterCountsQuery, OrganisationFilterCountsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrganisationFilterCountsQuery, OrganisationFilterCountsQueryVariables>(OrganisationFilterCountsDocument, options);
        }
export type OrganisationFilterCountsQueryHookResult = ReturnType<typeof useOrganisationFilterCountsQuery>;
export type OrganisationFilterCountsLazyQueryHookResult = ReturnType<typeof useOrganisationFilterCountsLazyQuery>;
export type OrganisationFilterCountsQueryResult = Apollo.QueryResult<OrganisationFilterCountsQuery, OrganisationFilterCountsQueryVariables>;
export const DeleteTaskDocument = gql`
    mutation deleteTask($id: ID!) {
  adminTaskDelete(id: $id)
}
    `;
export type DeleteTaskMutationFn = Apollo.MutationFunction<DeleteTaskMutation, DeleteTaskMutationVariables>;

/**
 * __useDeleteTaskMutation__
 *
 * To run a mutation, you first call `useDeleteTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTaskMutation, { data, loading, error }] = useDeleteTaskMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteTaskMutation(baseOptions?: Apollo.MutationHookOptions<DeleteTaskMutation, DeleteTaskMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteTaskMutation, DeleteTaskMutationVariables>(DeleteTaskDocument, options);
      }
export type DeleteTaskMutationHookResult = ReturnType<typeof useDeleteTaskMutation>;
export type DeleteTaskMutationResult = Apollo.MutationResult<DeleteTaskMutation>;
export type DeleteTaskMutationOptions = Apollo.BaseMutationOptions<DeleteTaskMutation, DeleteTaskMutationVariables>;
export const NewTaskDocument = gql`
    mutation newTask($title: String!, $index: Int!, $weight: Int!, $tags: [String!], $optional: Boolean!, $type: AdminTaskType!, $associatedUses: [UnitUse!]) {
  adminTaskCreate(
    input: {title: $title, index: $index, weight: $weight, tags: $tags, optional: $optional, type: $type, associatedUses: $associatedUses}
  ) {
    ... on AdminTask {
      _id
      title
      index
      weight
      tags
      optional
      type
      associatedUses
    }
  }
}
    `;
export type NewTaskMutationFn = Apollo.MutationFunction<NewTaskMutation, NewTaskMutationVariables>;

/**
 * __useNewTaskMutation__
 *
 * To run a mutation, you first call `useNewTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNewTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [newTaskMutation, { data, loading, error }] = useNewTaskMutation({
 *   variables: {
 *      title: // value for 'title'
 *      index: // value for 'index'
 *      weight: // value for 'weight'
 *      tags: // value for 'tags'
 *      optional: // value for 'optional'
 *      type: // value for 'type'
 *      associatedUses: // value for 'associatedUses'
 *   },
 * });
 */
export function useNewTaskMutation(baseOptions?: Apollo.MutationHookOptions<NewTaskMutation, NewTaskMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<NewTaskMutation, NewTaskMutationVariables>(NewTaskDocument, options);
      }
export type NewTaskMutationHookResult = ReturnType<typeof useNewTaskMutation>;
export type NewTaskMutationResult = Apollo.MutationResult<NewTaskMutation>;
export type NewTaskMutationOptions = Apollo.BaseMutationOptions<NewTaskMutation, NewTaskMutationVariables>;
export const UpdateTaskDocument = gql`
    mutation updateTask($id: ID!, $title: String, $index: Int, $weight: Int, $tags: [String!], $optional: Boolean, $type: AdminTaskType, $associatedUses: [UnitUse!]) {
  adminTaskUpdate(
    id: $id
    input: {title: $title, index: $index, weight: $weight, tags: $tags, optional: $optional, type: $type, associatedUses: $associatedUses}
  ) {
    ... on AdminTask {
      _id
      title
      index
      weight
      tags
      optional
      type
      associatedUses
    }
  }
}
    `;
export type UpdateTaskMutationFn = Apollo.MutationFunction<UpdateTaskMutation, UpdateTaskMutationVariables>;

/**
 * __useUpdateTaskMutation__
 *
 * To run a mutation, you first call `useUpdateTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTaskMutation, { data, loading, error }] = useUpdateTaskMutation({
 *   variables: {
 *      id: // value for 'id'
 *      title: // value for 'title'
 *      index: // value for 'index'
 *      weight: // value for 'weight'
 *      tags: // value for 'tags'
 *      optional: // value for 'optional'
 *      type: // value for 'type'
 *      associatedUses: // value for 'associatedUses'
 *   },
 * });
 */
export function useUpdateTaskMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTaskMutation, UpdateTaskMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTaskMutation, UpdateTaskMutationVariables>(UpdateTaskDocument, options);
      }
export type UpdateTaskMutationHookResult = ReturnType<typeof useUpdateTaskMutation>;
export type UpdateTaskMutationResult = Apollo.MutationResult<UpdateTaskMutation>;
export type UpdateTaskMutationOptions = Apollo.BaseMutationOptions<UpdateTaskMutation, UpdateTaskMutationVariables>;
export const GetTasksDocument = gql`
    query getTasks {
  tasks {
    _id
    title
    index
    weight
    optional
    tags
    type
    associatedUses
  }
}
    `;

/**
 * __useGetTasksQuery__
 *
 * To run a query within a React component, call `useGetTasksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTasksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTasksQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTasksQuery(baseOptions?: Apollo.QueryHookOptions<GetTasksQuery, GetTasksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTasksQuery, GetTasksQueryVariables>(GetTasksDocument, options);
      }
export function useGetTasksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTasksQuery, GetTasksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTasksQuery, GetTasksQueryVariables>(GetTasksDocument, options);
        }
export type GetTasksQueryHookResult = ReturnType<typeof useGetTasksQuery>;
export type GetTasksLazyQueryHookResult = ReturnType<typeof useGetTasksLazyQuery>;
export type GetTasksQueryResult = Apollo.QueryResult<GetTasksQuery, GetTasksQueryVariables>;
export const AdminDeleteUserDocument = gql`
    mutation adminDeleteUser($id: ID!, $input: AdminUserDeleteInput!) {
  userDelete(id: $id, input: $input)
}
    `;
export type AdminDeleteUserMutationFn = Apollo.MutationFunction<AdminDeleteUserMutation, AdminDeleteUserMutationVariables>;

/**
 * __useAdminDeleteUserMutation__
 *
 * To run a mutation, you first call `useAdminDeleteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminDeleteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminDeleteUserMutation, { data, loading, error }] = useAdminDeleteUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdminDeleteUserMutation(baseOptions?: Apollo.MutationHookOptions<AdminDeleteUserMutation, AdminDeleteUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdminDeleteUserMutation, AdminDeleteUserMutationVariables>(AdminDeleteUserDocument, options);
      }
export type AdminDeleteUserMutationHookResult = ReturnType<typeof useAdminDeleteUserMutation>;
export type AdminDeleteUserMutationResult = Apollo.MutationResult<AdminDeleteUserMutation>;
export type AdminDeleteUserMutationOptions = Apollo.BaseMutationOptions<AdminDeleteUserMutation, AdminDeleteUserMutationVariables>;
export const AdminEditUserDocument = gql`
    mutation adminEditUser($id: ID!, $input: UserUpdateInput!) {
  userUpdate(id: $id, input: $input) {
    ... on User {
      _id
      firstName
      lastName
      email
      mobile
      locked
    }
  }
}
    `;
export type AdminEditUserMutationFn = Apollo.MutationFunction<AdminEditUserMutation, AdminEditUserMutationVariables>;

/**
 * __useAdminEditUserMutation__
 *
 * To run a mutation, you first call `useAdminEditUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminEditUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminEditUserMutation, { data, loading, error }] = useAdminEditUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdminEditUserMutation(baseOptions?: Apollo.MutationHookOptions<AdminEditUserMutation, AdminEditUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdminEditUserMutation, AdminEditUserMutationVariables>(AdminEditUserDocument, options);
      }
export type AdminEditUserMutationHookResult = ReturnType<typeof useAdminEditUserMutation>;
export type AdminEditUserMutationResult = Apollo.MutationResult<AdminEditUserMutation>;
export type AdminEditUserMutationOptions = Apollo.BaseMutationOptions<AdminEditUserMutation, AdminEditUserMutationVariables>;
export const AdminMakeAdminDocument = gql`
    mutation adminMakeAdmin($id: ID!, $password: String!) {
  adminMakeAdmin(id: $id, password: $password) {
    ... on User {
      _id
      type
    }
  }
}
    `;
export type AdminMakeAdminMutationFn = Apollo.MutationFunction<AdminMakeAdminMutation, AdminMakeAdminMutationVariables>;

/**
 * __useAdminMakeAdminMutation__
 *
 * To run a mutation, you first call `useAdminMakeAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminMakeAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminMakeAdminMutation, { data, loading, error }] = useAdminMakeAdminMutation({
 *   variables: {
 *      id: // value for 'id'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useAdminMakeAdminMutation(baseOptions?: Apollo.MutationHookOptions<AdminMakeAdminMutation, AdminMakeAdminMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdminMakeAdminMutation, AdminMakeAdminMutationVariables>(AdminMakeAdminDocument, options);
      }
export type AdminMakeAdminMutationHookResult = ReturnType<typeof useAdminMakeAdminMutation>;
export type AdminMakeAdminMutationResult = Apollo.MutationResult<AdminMakeAdminMutation>;
export type AdminMakeAdminMutationOptions = Apollo.BaseMutationOptions<AdminMakeAdminMutation, AdminMakeAdminMutationVariables>;
export const AdminUserImpersonateDocument = gql`
    mutation adminUserImpersonate($id: ID!, $input: AdminUserAccessTokenCreateInput!) {
  adminUserAccessTokenCreate(id: $id, input: $input) {
    ... on User {
      _id
    }
  }
}
    `;
export type AdminUserImpersonateMutationFn = Apollo.MutationFunction<AdminUserImpersonateMutation, AdminUserImpersonateMutationVariables>;

/**
 * __useAdminUserImpersonateMutation__
 *
 * To run a mutation, you first call `useAdminUserImpersonateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminUserImpersonateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminUserImpersonateMutation, { data, loading, error }] = useAdminUserImpersonateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdminUserImpersonateMutation(baseOptions?: Apollo.MutationHookOptions<AdminUserImpersonateMutation, AdminUserImpersonateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdminUserImpersonateMutation, AdminUserImpersonateMutationVariables>(AdminUserImpersonateDocument, options);
      }
export type AdminUserImpersonateMutationHookResult = ReturnType<typeof useAdminUserImpersonateMutation>;
export type AdminUserImpersonateMutationResult = Apollo.MutationResult<AdminUserImpersonateMutation>;
export type AdminUserImpersonateMutationOptions = Apollo.BaseMutationOptions<AdminUserImpersonateMutation, AdminUserImpersonateMutationVariables>;
export const ClauseAddAdminDocument = gql`
    mutation clauseAddAdmin($value: String, $valueSuffix: String, $shortForm: String, $longForm: String, $tags: [String!], $section: String!, $level1Group: String!, $level2Group: String, $linked: LinkClauseTemplateInput, $associatedUses: [UnitUse!]) {
  adminClauseAdd(
    input: {value: $value, valueSuffix: $valueSuffix, shortForm: $shortForm, longForm: $longForm, tags: $tags, section: $section, level1Group: $level1Group, level2Group: $level2Group, linked: $linked, associatedUses: $associatedUses}
  ) {
    ... on ClauseTemplate {
      _id
      value
      valueSuffix
      shortForm
      longForm
      tags
      associatedUses
      linked {
        results {
          _id
          value
          valueSuffix
          shortForm
          longForm
        }
      }
      section {
        title
        group {
          title
          group {
            title
          }
        }
      }
    }
  }
}
    `;
export type ClauseAddAdminMutationFn = Apollo.MutationFunction<ClauseAddAdminMutation, ClauseAddAdminMutationVariables>;

/**
 * __useClauseAddAdminMutation__
 *
 * To run a mutation, you first call `useClauseAddAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClauseAddAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [clauseAddAdminMutation, { data, loading, error }] = useClauseAddAdminMutation({
 *   variables: {
 *      value: // value for 'value'
 *      valueSuffix: // value for 'valueSuffix'
 *      shortForm: // value for 'shortForm'
 *      longForm: // value for 'longForm'
 *      tags: // value for 'tags'
 *      section: // value for 'section'
 *      level1Group: // value for 'level1Group'
 *      level2Group: // value for 'level2Group'
 *      linked: // value for 'linked'
 *      associatedUses: // value for 'associatedUses'
 *   },
 * });
 */
export function useClauseAddAdminMutation(baseOptions?: Apollo.MutationHookOptions<ClauseAddAdminMutation, ClauseAddAdminMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ClauseAddAdminMutation, ClauseAddAdminMutationVariables>(ClauseAddAdminDocument, options);
      }
export type ClauseAddAdminMutationHookResult = ReturnType<typeof useClauseAddAdminMutation>;
export type ClauseAddAdminMutationResult = Apollo.MutationResult<ClauseAddAdminMutation>;
export type ClauseAddAdminMutationOptions = Apollo.BaseMutationOptions<ClauseAddAdminMutation, ClauseAddAdminMutationVariables>;
export const ClauseDeleteAdminDocument = gql`
    mutation clauseDeleteAdmin($_id: ID!) {
  adminClauseDelete(id: $_id)
}
    `;
export type ClauseDeleteAdminMutationFn = Apollo.MutationFunction<ClauseDeleteAdminMutation, ClauseDeleteAdminMutationVariables>;

/**
 * __useClauseDeleteAdminMutation__
 *
 * To run a mutation, you first call `useClauseDeleteAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClauseDeleteAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [clauseDeleteAdminMutation, { data, loading, error }] = useClauseDeleteAdminMutation({
 *   variables: {
 *      _id: // value for '_id'
 *   },
 * });
 */
export function useClauseDeleteAdminMutation(baseOptions?: Apollo.MutationHookOptions<ClauseDeleteAdminMutation, ClauseDeleteAdminMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ClauseDeleteAdminMutation, ClauseDeleteAdminMutationVariables>(ClauseDeleteAdminDocument, options);
      }
export type ClauseDeleteAdminMutationHookResult = ReturnType<typeof useClauseDeleteAdminMutation>;
export type ClauseDeleteAdminMutationResult = Apollo.MutationResult<ClauseDeleteAdminMutation>;
export type ClauseDeleteAdminMutationOptions = Apollo.BaseMutationOptions<ClauseDeleteAdminMutation, ClauseDeleteAdminMutationVariables>;
export const ClauseDeleteOrganisationDocument = gql`
    mutation clauseDeleteOrganisation($organisationID: ID!, $_id: ID!) {
  organisationClauseDelete(organisationID: $organisationID, _id: $_id)
}
    `;
export type ClauseDeleteOrganisationMutationFn = Apollo.MutationFunction<ClauseDeleteOrganisationMutation, ClauseDeleteOrganisationMutationVariables>;

/**
 * __useClauseDeleteOrganisationMutation__
 *
 * To run a mutation, you first call `useClauseDeleteOrganisationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClauseDeleteOrganisationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [clauseDeleteOrganisationMutation, { data, loading, error }] = useClauseDeleteOrganisationMutation({
 *   variables: {
 *      organisationID: // value for 'organisationID'
 *      _id: // value for '_id'
 *   },
 * });
 */
export function useClauseDeleteOrganisationMutation(baseOptions?: Apollo.MutationHookOptions<ClauseDeleteOrganisationMutation, ClauseDeleteOrganisationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ClauseDeleteOrganisationMutation, ClauseDeleteOrganisationMutationVariables>(ClauseDeleteOrganisationDocument, options);
      }
export type ClauseDeleteOrganisationMutationHookResult = ReturnType<typeof useClauseDeleteOrganisationMutation>;
export type ClauseDeleteOrganisationMutationResult = Apollo.MutationResult<ClauseDeleteOrganisationMutation>;
export type ClauseDeleteOrganisationMutationOptions = Apollo.BaseMutationOptions<ClauseDeleteOrganisationMutation, ClauseDeleteOrganisationMutationVariables>;
export const ClauseEditAdminDocument = gql`
    mutation clauseEditAdmin($_id: ID!, $value: String, $valueSuffix: String, $shortForm: String, $longForm: String, $tags: [String!], $section: String, $level1Group: String, $level2Group: String, $linked: LinkClauseTemplateInput, $associatedUses: [UnitUse!]) {
  adminClauseEdit(
    id: $_id
    input: {value: $value, valueSuffix: $valueSuffix, shortForm: $shortForm, longForm: $longForm, tags: $tags, section: $section, level1Group: $level1Group, level2Group: $level2Group, linked: $linked, associatedUses: $associatedUses}
  ) {
    ... on ClauseTemplate {
      _id
      value
      valueSuffix
      shortForm
      longForm
      tags
      linked {
        results {
          _id
          value
          valueSuffix
          shortForm
          longForm
        }
      }
      section {
        title
        description
        rics
        group {
          title
          description
          rics
          group {
            title
          }
        }
      }
    }
  }
}
    `;
export type ClauseEditAdminMutationFn = Apollo.MutationFunction<ClauseEditAdminMutation, ClauseEditAdminMutationVariables>;

/**
 * __useClauseEditAdminMutation__
 *
 * To run a mutation, you first call `useClauseEditAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClauseEditAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [clauseEditAdminMutation, { data, loading, error }] = useClauseEditAdminMutation({
 *   variables: {
 *      _id: // value for '_id'
 *      value: // value for 'value'
 *      valueSuffix: // value for 'valueSuffix'
 *      shortForm: // value for 'shortForm'
 *      longForm: // value for 'longForm'
 *      tags: // value for 'tags'
 *      section: // value for 'section'
 *      level1Group: // value for 'level1Group'
 *      level2Group: // value for 'level2Group'
 *      linked: // value for 'linked'
 *      associatedUses: // value for 'associatedUses'
 *   },
 * });
 */
export function useClauseEditAdminMutation(baseOptions?: Apollo.MutationHookOptions<ClauseEditAdminMutation, ClauseEditAdminMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ClauseEditAdminMutation, ClauseEditAdminMutationVariables>(ClauseEditAdminDocument, options);
      }
export type ClauseEditAdminMutationHookResult = ReturnType<typeof useClauseEditAdminMutation>;
export type ClauseEditAdminMutationResult = Apollo.MutationResult<ClauseEditAdminMutation>;
export type ClauseEditAdminMutationOptions = Apollo.BaseMutationOptions<ClauseEditAdminMutation, ClauseEditAdminMutationVariables>;
export const OrganisationClauseEditDocument = gql`
    mutation organisationClauseEdit($_id: ID!, $organisationID: ID!, $value: String, $valueSuffix: String, $shortForm: String, $longForm: String, $tags: [String!], $section: String, $level1Group: String, $level2Group: String, $linked: LinkClauseTemplateInput, $associatedUses: [UnitUse!]) {
  organisationClauseEdit(
    _id: $_id
    organisationID: $organisationID
    input: {value: $value, valueSuffix: $valueSuffix, shortForm: $shortForm, longForm: $longForm, tags: $tags, section: $section, level1Group: $level1Group, level2Group: $level2Group, linked: $linked, associatedUses: $associatedUses}
  ) {
    _id
    value
    valueSuffix
    associatedUses
    section {
      title
      group {
        title
        group {
          title
        }
      }
    }
  }
}
    `;
export type OrganisationClauseEditMutationFn = Apollo.MutationFunction<OrganisationClauseEditMutation, OrganisationClauseEditMutationVariables>;

/**
 * __useOrganisationClauseEditMutation__
 *
 * To run a mutation, you first call `useOrganisationClauseEditMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrganisationClauseEditMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [organisationClauseEditMutation, { data, loading, error }] = useOrganisationClauseEditMutation({
 *   variables: {
 *      _id: // value for '_id'
 *      organisationID: // value for 'organisationID'
 *      value: // value for 'value'
 *      valueSuffix: // value for 'valueSuffix'
 *      shortForm: // value for 'shortForm'
 *      longForm: // value for 'longForm'
 *      tags: // value for 'tags'
 *      section: // value for 'section'
 *      level1Group: // value for 'level1Group'
 *      level2Group: // value for 'level2Group'
 *      linked: // value for 'linked'
 *      associatedUses: // value for 'associatedUses'
 *   },
 * });
 */
export function useOrganisationClauseEditMutation(baseOptions?: Apollo.MutationHookOptions<OrganisationClauseEditMutation, OrganisationClauseEditMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<OrganisationClauseEditMutation, OrganisationClauseEditMutationVariables>(OrganisationClauseEditDocument, options);
      }
export type OrganisationClauseEditMutationHookResult = ReturnType<typeof useOrganisationClauseEditMutation>;
export type OrganisationClauseEditMutationResult = Apollo.MutationResult<OrganisationClauseEditMutation>;
export type OrganisationClauseEditMutationOptions = Apollo.BaseMutationOptions<OrganisationClauseEditMutation, OrganisationClauseEditMutationVariables>;
export const OrganisationSectionsEditDocument = gql`
    mutation organisationSectionsEdit($organisationID: ID!, $fromSection: String!, $toSection: String, $fromGroup: String, $toGroup: String, $sectionDescription: String, $sectionRics: String, $groupDescription: String, $groupRics: String, $deleteSection: Boolean, $deleteGroup: Boolean) {
  organisationSectionsEdit(
    organisationID: $organisationID
    fromSection: $fromSection
    toSection: $toSection
    fromGroup: $fromGroup
    toGroup: $toGroup
    deleteGroup: $deleteGroup
    groupDescription: $groupDescription
    groupRics: $groupRics
    sectionDescription: $sectionDescription
    sectionRics: $sectionRics
    deleteSection: $deleteSection
  )
}
    `;
export type OrganisationSectionsEditMutationFn = Apollo.MutationFunction<OrganisationSectionsEditMutation, OrganisationSectionsEditMutationVariables>;

/**
 * __useOrganisationSectionsEditMutation__
 *
 * To run a mutation, you first call `useOrganisationSectionsEditMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrganisationSectionsEditMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [organisationSectionsEditMutation, { data, loading, error }] = useOrganisationSectionsEditMutation({
 *   variables: {
 *      organisationID: // value for 'organisationID'
 *      fromSection: // value for 'fromSection'
 *      toSection: // value for 'toSection'
 *      fromGroup: // value for 'fromGroup'
 *      toGroup: // value for 'toGroup'
 *      sectionDescription: // value for 'sectionDescription'
 *      sectionRics: // value for 'sectionRics'
 *      groupDescription: // value for 'groupDescription'
 *      groupRics: // value for 'groupRics'
 *      deleteSection: // value for 'deleteSection'
 *      deleteGroup: // value for 'deleteGroup'
 *   },
 * });
 */
export function useOrganisationSectionsEditMutation(baseOptions?: Apollo.MutationHookOptions<OrganisationSectionsEditMutation, OrganisationSectionsEditMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<OrganisationSectionsEditMutation, OrganisationSectionsEditMutationVariables>(OrganisationSectionsEditDocument, options);
      }
export type OrganisationSectionsEditMutationHookResult = ReturnType<typeof useOrganisationSectionsEditMutation>;
export type OrganisationSectionsEditMutationResult = Apollo.MutationResult<OrganisationSectionsEditMutation>;
export type OrganisationSectionsEditMutationOptions = Apollo.BaseMutationOptions<OrganisationSectionsEditMutation, OrganisationSectionsEditMutationVariables>;
export const ClauseBankEditSectionsDocument = gql`
    mutation clauseBankEditSections($fromSection: String!, $toSection: String, $fromGroup: String, $toGroup: String, $sectionDescription: String, $sectionRics: String, $groupDescription: String, $groupRics: String, $deleteSection: Boolean) {
  adminClauseSectionsEdit(
    fromSection: $fromSection
    toSection: $toSection
    fromGroup: $fromGroup
    toGroup: $toGroup
    groupDescription: $groupDescription
    groupRics: $groupRics
    sectionDescription: $sectionDescription
    sectionRics: $sectionRics
    deleteSection: $deleteSection
  )
}
    `;
export type ClauseBankEditSectionsMutationFn = Apollo.MutationFunction<ClauseBankEditSectionsMutation, ClauseBankEditSectionsMutationVariables>;

/**
 * __useClauseBankEditSectionsMutation__
 *
 * To run a mutation, you first call `useClauseBankEditSectionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClauseBankEditSectionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [clauseBankEditSectionsMutation, { data, loading, error }] = useClauseBankEditSectionsMutation({
 *   variables: {
 *      fromSection: // value for 'fromSection'
 *      toSection: // value for 'toSection'
 *      fromGroup: // value for 'fromGroup'
 *      toGroup: // value for 'toGroup'
 *      sectionDescription: // value for 'sectionDescription'
 *      sectionRics: // value for 'sectionRics'
 *      groupDescription: // value for 'groupDescription'
 *      groupRics: // value for 'groupRics'
 *      deleteSection: // value for 'deleteSection'
 *   },
 * });
 */
export function useClauseBankEditSectionsMutation(baseOptions?: Apollo.MutationHookOptions<ClauseBankEditSectionsMutation, ClauseBankEditSectionsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ClauseBankEditSectionsMutation, ClauseBankEditSectionsMutationVariables>(ClauseBankEditSectionsDocument, options);
      }
export type ClauseBankEditSectionsMutationHookResult = ReturnType<typeof useClauseBankEditSectionsMutation>;
export type ClauseBankEditSectionsMutationResult = Apollo.MutationResult<ClauseBankEditSectionsMutation>;
export type ClauseBankEditSectionsMutationOptions = Apollo.BaseMutationOptions<ClauseBankEditSectionsMutation, ClauseBankEditSectionsMutationVariables>;
export const DeleteUserDocument = gql`
    mutation deleteUser {
  userDelete
}
    `;
export type DeleteUserMutationFn = Apollo.MutationFunction<DeleteUserMutation, DeleteUserMutationVariables>;

/**
 * __useDeleteUserMutation__
 *
 * To run a mutation, you first call `useDeleteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserMutation, { data, loading, error }] = useDeleteUserMutation({
 *   variables: {
 *   },
 * });
 */
export function useDeleteUserMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUserMutation, DeleteUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteUserMutation, DeleteUserMutationVariables>(DeleteUserDocument, options);
      }
export type DeleteUserMutationHookResult = ReturnType<typeof useDeleteUserMutation>;
export type DeleteUserMutationResult = Apollo.MutationResult<DeleteUserMutation>;
export type DeleteUserMutationOptions = Apollo.BaseMutationOptions<DeleteUserMutation, DeleteUserMutationVariables>;
export const UserNotificationsDisableDocument = gql`
    mutation userNotificationsDisable($userEmail: Boolean, $userNotification: Boolean, $documentEmail: Boolean, $documentNotification: Boolean, $approvalEmail: Boolean, $approvalNotification: Boolean, $dealEmail: Boolean, $dealNotification: Boolean, $unitEmail: Boolean, $unitNotification: Boolean, $seenEmail: Boolean, $seenNotification: Boolean) {
  userNotificationsDisable(
    input: {users: {email: $userEmail, notification: $userNotification}, approvals: {email: $approvalEmail, notification: $approvalNotification}, documents: {email: $documentEmail, notification: $documentNotification}, deals: {email: $dealEmail, notification: $dealNotification}, units: {email: $unitEmail, notification: $unitNotification}, seen: {email: $seenEmail, notification: $seenNotification}}
  ) {
    deals {
      email
      notification
    }
    users {
      email
      notification
    }
    seen {
      email
      notification
    }
    documents {
      email
      notification
    }
    units {
      email
      notification
    }
    approvals {
      email
      notification
    }
  }
}
    `;
export type UserNotificationsDisableMutationFn = Apollo.MutationFunction<UserNotificationsDisableMutation, UserNotificationsDisableMutationVariables>;

/**
 * __useUserNotificationsDisableMutation__
 *
 * To run a mutation, you first call `useUserNotificationsDisableMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserNotificationsDisableMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userNotificationsDisableMutation, { data, loading, error }] = useUserNotificationsDisableMutation({
 *   variables: {
 *      userEmail: // value for 'userEmail'
 *      userNotification: // value for 'userNotification'
 *      documentEmail: // value for 'documentEmail'
 *      documentNotification: // value for 'documentNotification'
 *      approvalEmail: // value for 'approvalEmail'
 *      approvalNotification: // value for 'approvalNotification'
 *      dealEmail: // value for 'dealEmail'
 *      dealNotification: // value for 'dealNotification'
 *      unitEmail: // value for 'unitEmail'
 *      unitNotification: // value for 'unitNotification'
 *      seenEmail: // value for 'seenEmail'
 *      seenNotification: // value for 'seenNotification'
 *   },
 * });
 */
export function useUserNotificationsDisableMutation(baseOptions?: Apollo.MutationHookOptions<UserNotificationsDisableMutation, UserNotificationsDisableMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UserNotificationsDisableMutation, UserNotificationsDisableMutationVariables>(UserNotificationsDisableDocument, options);
      }
export type UserNotificationsDisableMutationHookResult = ReturnType<typeof useUserNotificationsDisableMutation>;
export type UserNotificationsDisableMutationResult = Apollo.MutationResult<UserNotificationsDisableMutation>;
export type UserNotificationsDisableMutationOptions = Apollo.BaseMutationOptions<UserNotificationsDisableMutation, UserNotificationsDisableMutationVariables>;
export const TrustedUsersInviteDocument = gql`
    mutation trustedUsersInvite($organisationID: ID!, $emails: [String!]!) {
  trustedUsersInvite(organisationID: $organisationID, input: {emails: $emails}) {
    ... on MultiInviteDetails {
      users {
        ...InvitedUser
      }
      organisation {
        _id
        name
      }
    }
  }
}
    ${InvitedUserFragmentDoc}`;
export type TrustedUsersInviteMutationFn = Apollo.MutationFunction<TrustedUsersInviteMutation, TrustedUsersInviteMutationVariables>;

/**
 * __useTrustedUsersInviteMutation__
 *
 * To run a mutation, you first call `useTrustedUsersInviteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTrustedUsersInviteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [trustedUsersInviteMutation, { data, loading, error }] = useTrustedUsersInviteMutation({
 *   variables: {
 *      organisationID: // value for 'organisationID'
 *      emails: // value for 'emails'
 *   },
 * });
 */
export function useTrustedUsersInviteMutation(baseOptions?: Apollo.MutationHookOptions<TrustedUsersInviteMutation, TrustedUsersInviteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TrustedUsersInviteMutation, TrustedUsersInviteMutationVariables>(TrustedUsersInviteDocument, options);
      }
export type TrustedUsersInviteMutationHookResult = ReturnType<typeof useTrustedUsersInviteMutation>;
export type TrustedUsersInviteMutationResult = Apollo.MutationResult<TrustedUsersInviteMutation>;
export type TrustedUsersInviteMutationOptions = Apollo.BaseMutationOptions<TrustedUsersInviteMutation, TrustedUsersInviteMutationVariables>;
export const InviteUserDocument = gql`
    mutation inviteUser($organisationID: ID!, $emails: [String!]!, $type: UserType!, $resend: Boolean) {
  userInvite(
    organisationID: $organisationID
    input: {emails: $emails, type: $type}
    resend: $resend
  ) {
    ... on MultiInviteDetails {
      users {
        ...InvitedUser
      }
      organisation {
        _id
        name
      }
    }
  }
}
    ${InvitedUserFragmentDoc}`;
export type InviteUserMutationFn = Apollo.MutationFunction<InviteUserMutation, InviteUserMutationVariables>;

/**
 * __useInviteUserMutation__
 *
 * To run a mutation, you first call `useInviteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInviteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inviteUserMutation, { data, loading, error }] = useInviteUserMutation({
 *   variables: {
 *      organisationID: // value for 'organisationID'
 *      emails: // value for 'emails'
 *      type: // value for 'type'
 *      resend: // value for 'resend'
 *   },
 * });
 */
export function useInviteUserMutation(baseOptions?: Apollo.MutationHookOptions<InviteUserMutation, InviteUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InviteUserMutation, InviteUserMutationVariables>(InviteUserDocument, options);
      }
export type InviteUserMutationHookResult = ReturnType<typeof useInviteUserMutation>;
export type InviteUserMutationResult = Apollo.MutationResult<InviteUserMutation>;
export type InviteUserMutationOptions = Apollo.BaseMutationOptions<InviteUserMutation, InviteUserMutationVariables>;
export const RemoveMemberDocument = gql`
    mutation removeMember($organisationID: ID!, $userID: ID!) {
  organisationMemberRemove(organisationID: $organisationID, userID: $userID) {
    ... on Organisation {
      _id
      members {
        _id
        firstName
        lastName
        email
        pending
      }
    }
  }
}
    `;
export type RemoveMemberMutationFn = Apollo.MutationFunction<RemoveMemberMutation, RemoveMemberMutationVariables>;

/**
 * __useRemoveMemberMutation__
 *
 * To run a mutation, you first call `useRemoveMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeMemberMutation, { data, loading, error }] = useRemoveMemberMutation({
 *   variables: {
 *      organisationID: // value for 'organisationID'
 *      userID: // value for 'userID'
 *   },
 * });
 */
export function useRemoveMemberMutation(baseOptions?: Apollo.MutationHookOptions<RemoveMemberMutation, RemoveMemberMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveMemberMutation, RemoveMemberMutationVariables>(RemoveMemberDocument, options);
      }
export type RemoveMemberMutationHookResult = ReturnType<typeof useRemoveMemberMutation>;
export type RemoveMemberMutationResult = Apollo.MutationResult<RemoveMemberMutation>;
export type RemoveMemberMutationOptions = Apollo.BaseMutationOptions<RemoveMemberMutation, RemoveMemberMutationVariables>;
export const RemovePendingMemberDocument = gql`
    mutation removePendingMember($organisationID: ID!, $email: String!) {
  organisationPendingMemberRemove(organisationID: $organisationID, email: $email) {
    ... on Organisation {
      _id
      pendingMembers
    }
  }
}
    `;
export type RemovePendingMemberMutationFn = Apollo.MutationFunction<RemovePendingMemberMutation, RemovePendingMemberMutationVariables>;

/**
 * __useRemovePendingMemberMutation__
 *
 * To run a mutation, you first call `useRemovePendingMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemovePendingMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removePendingMemberMutation, { data, loading, error }] = useRemovePendingMemberMutation({
 *   variables: {
 *      organisationID: // value for 'organisationID'
 *      email: // value for 'email'
 *   },
 * });
 */
export function useRemovePendingMemberMutation(baseOptions?: Apollo.MutationHookOptions<RemovePendingMemberMutation, RemovePendingMemberMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemovePendingMemberMutation, RemovePendingMemberMutationVariables>(RemovePendingMemberDocument, options);
      }
export type RemovePendingMemberMutationHookResult = ReturnType<typeof useRemovePendingMemberMutation>;
export type RemovePendingMemberMutationResult = Apollo.MutationResult<RemovePendingMemberMutation>;
export type RemovePendingMemberMutationOptions = Apollo.BaseMutationOptions<RemovePendingMemberMutation, RemovePendingMemberMutationVariables>;
export const UpdateOrganisationDocument = gql`
    mutation updateOrganisation($id: ID!, $input: OrganisationUpdateInput!) {
  organisationUpdate(id: $id, input: $input) {
    ... on Organisation {
      _id
      name
      website
      address {
        line1
        line2
        city
        postCode
      }
      practiceType
    }
  }
}
    `;
export type UpdateOrganisationMutationFn = Apollo.MutationFunction<UpdateOrganisationMutation, UpdateOrganisationMutationVariables>;

/**
 * __useUpdateOrganisationMutation__
 *
 * To run a mutation, you first call `useUpdateOrganisationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrganisationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrganisationMutation, { data, loading, error }] = useUpdateOrganisationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOrganisationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOrganisationMutation, UpdateOrganisationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOrganisationMutation, UpdateOrganisationMutationVariables>(UpdateOrganisationDocument, options);
      }
export type UpdateOrganisationMutationHookResult = ReturnType<typeof useUpdateOrganisationMutation>;
export type UpdateOrganisationMutationResult = Apollo.MutationResult<UpdateOrganisationMutation>;
export type UpdateOrganisationMutationOptions = Apollo.BaseMutationOptions<UpdateOrganisationMutation, UpdateOrganisationMutationVariables>;
export const UpdateUserDocument = gql`
    mutation updateUser($firstName: String, $lastName: String, $email: String, $mobile: String, $password: String, $currentPassword: String, $is2FAEnabled: Boolean) {
  userUpdate(
    input: {firstName: $firstName, lastName: $lastName, email: $email, mobile: $mobile, password: $password, currentPassword: $currentPassword, is2FAEnabled: $is2FAEnabled}
  ) {
    ... on User {
      _id
      firstName
      lastName
      email
      mobile
    }
  }
}
    `;
export type UpdateUserMutationFn = Apollo.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      email: // value for 'email'
 *      mobile: // value for 'mobile'
 *      password: // value for 'password'
 *      currentPassword: // value for 'currentPassword'
 *      is2FAEnabled: // value for 'is2FAEnabled'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, options);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;
export const GetAllUsersDocument = gql`
    query getAllUsers($query: String, $filter: UserFilter, $sort: UserSort) {
  users(query: $query, filter: $filter, sort: $sort) {
    _id
    type
    firstName
    lastName
    email
    mobile
    locked
    created
    lastLogin
    activated
    organisation {
      name
      avatar
      name
      _id
      address {
        line1
        line2
        city
        country
        postCode
      }
      created
      website
    }
  }
}
    `;

/**
 * __useGetAllUsersQuery__
 *
 * To run a query within a React component, call `useGetAllUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllUsersQuery({
 *   variables: {
 *      query: // value for 'query'
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useGetAllUsersQuery(baseOptions?: Apollo.QueryHookOptions<GetAllUsersQuery, GetAllUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllUsersQuery, GetAllUsersQueryVariables>(GetAllUsersDocument, options);
      }
export function useGetAllUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllUsersQuery, GetAllUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllUsersQuery, GetAllUsersQueryVariables>(GetAllUsersDocument, options);
        }
export type GetAllUsersQueryHookResult = ReturnType<typeof useGetAllUsersQuery>;
export type GetAllUsersLazyQueryHookResult = ReturnType<typeof useGetAllUsersLazyQuery>;
export type GetAllUsersQueryResult = Apollo.QueryResult<GetAllUsersQuery, GetAllUsersQueryVariables>;
export const UserFilterCountsDocument = gql`
    query UserFilterCounts($filter: UserFilter, $query: String) {
  userFilterCounts(filter: $filter, query: $query) {
    all
    admin
    landlord
    agent
    tenant
  }
}
    `;

/**
 * __useUserFilterCountsQuery__
 *
 * To run a query within a React component, call `useUserFilterCountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserFilterCountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserFilterCountsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      query: // value for 'query'
 *   },
 * });
 */
export function useUserFilterCountsQuery(baseOptions?: Apollo.QueryHookOptions<UserFilterCountsQuery, UserFilterCountsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserFilterCountsQuery, UserFilterCountsQueryVariables>(UserFilterCountsDocument, options);
      }
export function useUserFilterCountsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserFilterCountsQuery, UserFilterCountsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserFilterCountsQuery, UserFilterCountsQueryVariables>(UserFilterCountsDocument, options);
        }
export type UserFilterCountsQueryHookResult = ReturnType<typeof useUserFilterCountsQuery>;
export type UserFilterCountsLazyQueryHookResult = ReturnType<typeof useUserFilterCountsLazyQuery>;
export type UserFilterCountsQueryResult = Apollo.QueryResult<UserFilterCountsQuery, UserFilterCountsQueryVariables>;
export const GetUserOrganisationDocument = gql`
    query getUserOrganisation($filter: UserFilter, $sort: UserSort) {
  user {
    ... on User {
      _id
      locked
      organisation {
        _id
        name
        website
        avatar
        straightToLease {
          start
          end
        }
        address {
          line1
          line2
          city
          postCode
        }
        type
        practiceType
        members(sort: $sort) {
          ...OrganisationMember
        }
        pendingMembers
        trustedUsers(filter: $filter, sort: $sort) {
          _id
          firstName
          lastName
          type
          email
          mobile
          pending
          organisation {
            _id
            name
          }
          lastLogin
          activated
        }
      }
      associated {
        _id
        name
        avatar
        members {
          ...OrganisationMember
        }
      }
      practiceInvites {
        _id
        organisation {
          _id
          name
        }
      }
      removed
    }
  }
}
    ${OrganisationMemberFragmentDoc}`;

/**
 * __useGetUserOrganisationQuery__
 *
 * To run a query within a React component, call `useGetUserOrganisationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserOrganisationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserOrganisationQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useGetUserOrganisationQuery(baseOptions?: Apollo.QueryHookOptions<GetUserOrganisationQuery, GetUserOrganisationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserOrganisationQuery, GetUserOrganisationQueryVariables>(GetUserOrganisationDocument, options);
      }
export function useGetUserOrganisationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserOrganisationQuery, GetUserOrganisationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserOrganisationQuery, GetUserOrganisationQueryVariables>(GetUserOrganisationDocument, options);
        }
export type GetUserOrganisationQueryHookResult = ReturnType<typeof useGetUserOrganisationQuery>;
export type GetUserOrganisationLazyQueryHookResult = ReturnType<typeof useGetUserOrganisationLazyQuery>;
export type GetUserOrganisationQueryResult = Apollo.QueryResult<GetUserOrganisationQuery, GetUserOrganisationQueryVariables>;
export const GetUserProfileDocument = gql`
    query getUserProfile {
  user {
    ... on User {
      ...UserDetails
      disabledNotifications {
        deals {
          email
          notification
        }
        seen {
          email
          notification
        }
        users {
          email
          notification
        }
        documents {
          email
          notification
        }
        approvals {
          email
          notification
        }
        units {
          email
          notification
        }
      }
    }
  }
}
    ${UserDetailsFragmentDoc}`;

/**
 * __useGetUserProfileQuery__
 *
 * To run a query within a React component, call `useGetUserProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserProfileQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserProfileQuery(baseOptions?: Apollo.QueryHookOptions<GetUserProfileQuery, GetUserProfileQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserProfileQuery, GetUserProfileQueryVariables>(GetUserProfileDocument, options);
      }
export function useGetUserProfileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserProfileQuery, GetUserProfileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserProfileQuery, GetUserProfileQueryVariables>(GetUserProfileDocument, options);
        }
export type GetUserProfileQueryHookResult = ReturnType<typeof useGetUserProfileQuery>;
export type GetUserProfileLazyQueryHookResult = ReturnType<typeof useGetUserProfileLazyQuery>;
export type GetUserProfileQueryResult = Apollo.QueryResult<GetUserProfileQuery, GetUserProfileQueryVariables>;
export const SaveTemplateDetailsDocument = gql`
    mutation saveTemplateDetails($templateID: ID!, $name: String, $description: String, $global: Boolean, $associatedUses: [UnitUse!]) {
  templateUpdate(
    id: $templateID
    input: {name: $name, description: $description, global: $global, associatedUses: $associatedUses}
  ) {
    ... on Template {
      _id
      name
      description
      associatedUses
      global
    }
  }
}
    `;
export type SaveTemplateDetailsMutationFn = Apollo.MutationFunction<SaveTemplateDetailsMutation, SaveTemplateDetailsMutationVariables>;

/**
 * __useSaveTemplateDetailsMutation__
 *
 * To run a mutation, you first call `useSaveTemplateDetailsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveTemplateDetailsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveTemplateDetailsMutation, { data, loading, error }] = useSaveTemplateDetailsMutation({
 *   variables: {
 *      templateID: // value for 'templateID'
 *      name: // value for 'name'
 *      description: // value for 'description'
 *      global: // value for 'global'
 *      associatedUses: // value for 'associatedUses'
 *   },
 * });
 */
export function useSaveTemplateDetailsMutation(baseOptions?: Apollo.MutationHookOptions<SaveTemplateDetailsMutation, SaveTemplateDetailsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveTemplateDetailsMutation, SaveTemplateDetailsMutationVariables>(SaveTemplateDetailsDocument, options);
      }
export type SaveTemplateDetailsMutationHookResult = ReturnType<typeof useSaveTemplateDetailsMutation>;
export type SaveTemplateDetailsMutationResult = Apollo.MutationResult<SaveTemplateDetailsMutation>;
export type SaveTemplateDetailsMutationOptions = Apollo.BaseMutationOptions<SaveTemplateDetailsMutation, SaveTemplateDetailsMutationVariables>;
export const GetTemplateDetailsDocument = gql`
    query getTemplateDetails($templateID: ID!) {
  template(id: $templateID) {
    _id
    name
    description
    associatedUses
    global
    organisation {
      _id
      name
    }
    deal {
      ...DealDetails
    }
    created
    creator {
      firstName
      lastName
    }
  }
}
    ${DealDetailsFragmentDoc}`;

/**
 * __useGetTemplateDetailsQuery__
 *
 * To run a query within a React component, call `useGetTemplateDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTemplateDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTemplateDetailsQuery({
 *   variables: {
 *      templateID: // value for 'templateID'
 *   },
 * });
 */
export function useGetTemplateDetailsQuery(baseOptions: Apollo.QueryHookOptions<GetTemplateDetailsQuery, GetTemplateDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTemplateDetailsQuery, GetTemplateDetailsQueryVariables>(GetTemplateDetailsDocument, options);
      }
export function useGetTemplateDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTemplateDetailsQuery, GetTemplateDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTemplateDetailsQuery, GetTemplateDetailsQueryVariables>(GetTemplateDetailsDocument, options);
        }
export type GetTemplateDetailsQueryHookResult = ReturnType<typeof useGetTemplateDetailsQuery>;
export type GetTemplateDetailsLazyQueryHookResult = ReturnType<typeof useGetTemplateDetailsLazyQuery>;
export type GetTemplateDetailsQueryResult = Apollo.QueryResult<GetTemplateDetailsQuery, GetTemplateDetailsQueryVariables>;
export const DeleteTemplateDocument = gql`
    mutation deleteTemplate($templateID: ID!, $permanent: Boolean!) {
  templateDelete(id: $templateID, permanent: $permanent) {
    ... on TemplateDelete {
      deletedTemplateID
    }
  }
}
    `;
export type DeleteTemplateMutationFn = Apollo.MutationFunction<DeleteTemplateMutation, DeleteTemplateMutationVariables>;

/**
 * __useDeleteTemplateMutation__
 *
 * To run a mutation, you first call `useDeleteTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTemplateMutation, { data, loading, error }] = useDeleteTemplateMutation({
 *   variables: {
 *      templateID: // value for 'templateID'
 *      permanent: // value for 'permanent'
 *   },
 * });
 */
export function useDeleteTemplateMutation(baseOptions?: Apollo.MutationHookOptions<DeleteTemplateMutation, DeleteTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteTemplateMutation, DeleteTemplateMutationVariables>(DeleteTemplateDocument, options);
      }
export type DeleteTemplateMutationHookResult = ReturnType<typeof useDeleteTemplateMutation>;
export type DeleteTemplateMutationResult = Apollo.MutationResult<DeleteTemplateMutation>;
export type DeleteTemplateMutationOptions = Apollo.BaseMutationOptions<DeleteTemplateMutation, DeleteTemplateMutationVariables>;
export const TemplateDuplicateDocument = gql`
    mutation templateDuplicate($organisation: String!, $templateID: ID!, $name: String, $description: String, $associatedUses: [UnitUse!]) {
  templateDuplicate(
    organisation: $organisation
    id: $templateID
    name: $name
    description: $description
    associatedUses: $associatedUses
  ) {
    ... on Template {
      _id
      name
      description
      associatedUses
    }
  }
}
    `;
export type TemplateDuplicateMutationFn = Apollo.MutationFunction<TemplateDuplicateMutation, TemplateDuplicateMutationVariables>;

/**
 * __useTemplateDuplicateMutation__
 *
 * To run a mutation, you first call `useTemplateDuplicateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTemplateDuplicateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [templateDuplicateMutation, { data, loading, error }] = useTemplateDuplicateMutation({
 *   variables: {
 *      organisation: // value for 'organisation'
 *      templateID: // value for 'templateID'
 *      name: // value for 'name'
 *      description: // value for 'description'
 *      associatedUses: // value for 'associatedUses'
 *   },
 * });
 */
export function useTemplateDuplicateMutation(baseOptions?: Apollo.MutationHookOptions<TemplateDuplicateMutation, TemplateDuplicateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TemplateDuplicateMutation, TemplateDuplicateMutationVariables>(TemplateDuplicateDocument, options);
      }
export type TemplateDuplicateMutationHookResult = ReturnType<typeof useTemplateDuplicateMutation>;
export type TemplateDuplicateMutationResult = Apollo.MutationResult<TemplateDuplicateMutation>;
export type TemplateDuplicateMutationOptions = Apollo.BaseMutationOptions<TemplateDuplicateMutation, TemplateDuplicateMutationVariables>;
export const NewTemplateDocument = gql`
    mutation newTemplate($organisationID: ID, $name: String!, $description: String!, $associatedUses: [UnitUse!]) {
  templateCreate(
    organisationID: $organisationID
    input: {name: $name, description: $description, associatedUses: $associatedUses}
  ) {
    ... on Template {
      _id
      name
      associatedUses
      deal {
        _id
        sections {
          _id
          title
          index
          groups {
            _id
            title
            index
            clauses {
              _id
              index
              value
              visible
              valueSuffix
              shortForm
              longForm
              dealBreaker {
                isDealBreaker
                message
              }
              groups {
                _id
                title
                index
                clauses {
                  _id
                  index
                  value
                  visible
                  valueSuffix
                  shortForm
                  longForm
                  dealBreaker {
                    isDealBreaker
                    message
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
    `;
export type NewTemplateMutationFn = Apollo.MutationFunction<NewTemplateMutation, NewTemplateMutationVariables>;

/**
 * __useNewTemplateMutation__
 *
 * To run a mutation, you first call `useNewTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNewTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [newTemplateMutation, { data, loading, error }] = useNewTemplateMutation({
 *   variables: {
 *      organisationID: // value for 'organisationID'
 *      name: // value for 'name'
 *      description: // value for 'description'
 *      associatedUses: // value for 'associatedUses'
 *   },
 * });
 */
export function useNewTemplateMutation(baseOptions?: Apollo.MutationHookOptions<NewTemplateMutation, NewTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<NewTemplateMutation, NewTemplateMutationVariables>(NewTemplateDocument, options);
      }
export type NewTemplateMutationHookResult = ReturnType<typeof useNewTemplateMutation>;
export type NewTemplateMutationResult = Apollo.MutationResult<NewTemplateMutation>;
export type NewTemplateMutationOptions = Apollo.BaseMutationOptions<NewTemplateMutation, NewTemplateMutationVariables>;
export const RestoreTemplateDocument = gql`
    mutation restoreTemplate($templateID: ID!) {
  templateRestore(id: $templateID) {
    ... on Template {
      _id
    }
  }
}
    `;
export type RestoreTemplateMutationFn = Apollo.MutationFunction<RestoreTemplateMutation, RestoreTemplateMutationVariables>;

/**
 * __useRestoreTemplateMutation__
 *
 * To run a mutation, you first call `useRestoreTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRestoreTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [restoreTemplateMutation, { data, loading, error }] = useRestoreTemplateMutation({
 *   variables: {
 *      templateID: // value for 'templateID'
 *   },
 * });
 */
export function useRestoreTemplateMutation(baseOptions?: Apollo.MutationHookOptions<RestoreTemplateMutation, RestoreTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RestoreTemplateMutation, RestoreTemplateMutationVariables>(RestoreTemplateDocument, options);
      }
export type RestoreTemplateMutationHookResult = ReturnType<typeof useRestoreTemplateMutation>;
export type RestoreTemplateMutationResult = Apollo.MutationResult<RestoreTemplateMutation>;
export type RestoreTemplateMutationOptions = Apollo.BaseMutationOptions<RestoreTemplateMutation, RestoreTemplateMutationVariables>;
export const TemplateHideDocument = gql`
    mutation templateHide($templateID: ID!) {
  templateHide(templateID: $templateID) {
    _id
  }
}
    `;
export type TemplateHideMutationFn = Apollo.MutationFunction<TemplateHideMutation, TemplateHideMutationVariables>;

/**
 * __useTemplateHideMutation__
 *
 * To run a mutation, you first call `useTemplateHideMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTemplateHideMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [templateHideMutation, { data, loading, error }] = useTemplateHideMutation({
 *   variables: {
 *      templateID: // value for 'templateID'
 *   },
 * });
 */
export function useTemplateHideMutation(baseOptions?: Apollo.MutationHookOptions<TemplateHideMutation, TemplateHideMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TemplateHideMutation, TemplateHideMutationVariables>(TemplateHideDocument, options);
      }
export type TemplateHideMutationHookResult = ReturnType<typeof useTemplateHideMutation>;
export type TemplateHideMutationResult = Apollo.MutationResult<TemplateHideMutation>;
export type TemplateHideMutationOptions = Apollo.BaseMutationOptions<TemplateHideMutation, TemplateHideMutationVariables>;
export const TemplateUnhideDocument = gql`
    mutation templateUnhide($templateID: ID!) {
  templateUnhide(templateID: $templateID) {
    _id
  }
}
    `;
export type TemplateUnhideMutationFn = Apollo.MutationFunction<TemplateUnhideMutation, TemplateUnhideMutationVariables>;

/**
 * __useTemplateUnhideMutation__
 *
 * To run a mutation, you first call `useTemplateUnhideMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTemplateUnhideMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [templateUnhideMutation, { data, loading, error }] = useTemplateUnhideMutation({
 *   variables: {
 *      templateID: // value for 'templateID'
 *   },
 * });
 */
export function useTemplateUnhideMutation(baseOptions?: Apollo.MutationHookOptions<TemplateUnhideMutation, TemplateUnhideMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TemplateUnhideMutation, TemplateUnhideMutationVariables>(TemplateUnhideDocument, options);
      }
export type TemplateUnhideMutationHookResult = ReturnType<typeof useTemplateUnhideMutation>;
export type TemplateUnhideMutationResult = Apollo.MutationResult<TemplateUnhideMutation>;
export type TemplateUnhideMutationOptions = Apollo.BaseMutationOptions<TemplateUnhideMutation, TemplateUnhideMutationVariables>;
export const GetTemplateCardDetailsDocument = gql`
    query getTemplateCardDetails($filter: TemplateFilter, $sort: TemplateSort, $query: String) {
  templates(filter: $filter, sort: $sort, query: $query) {
    results {
      _id
      name
      description
      global
      hasAccess
      creator {
        _id
        firstName
        lastName
      }
      organisation {
        _id
        name
      }
      created
      associatedUses
    }
    pageInfo {
      total
    }
  }
}
    `;

/**
 * __useGetTemplateCardDetailsQuery__
 *
 * To run a query within a React component, call `useGetTemplateCardDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTemplateCardDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTemplateCardDetailsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *      query: // value for 'query'
 *   },
 * });
 */
export function useGetTemplateCardDetailsQuery(baseOptions?: Apollo.QueryHookOptions<GetTemplateCardDetailsQuery, GetTemplateCardDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTemplateCardDetailsQuery, GetTemplateCardDetailsQueryVariables>(GetTemplateCardDetailsDocument, options);
      }
export function useGetTemplateCardDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTemplateCardDetailsQuery, GetTemplateCardDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTemplateCardDetailsQuery, GetTemplateCardDetailsQueryVariables>(GetTemplateCardDetailsDocument, options);
        }
export type GetTemplateCardDetailsQueryHookResult = ReturnType<typeof useGetTemplateCardDetailsQuery>;
export type GetTemplateCardDetailsLazyQueryHookResult = ReturnType<typeof useGetTemplateCardDetailsLazyQuery>;
export type GetTemplateCardDetailsQueryResult = Apollo.QueryResult<GetTemplateCardDetailsQuery, GetTemplateCardDetailsQueryVariables>;
export const GetTemplateOrganisationsDocument = gql`
    query getTemplateOrganisations {
  templates(filter: {type: CUSTOM}) {
    results {
      organisation {
        _id
        name
      }
    }
    pageInfo {
      total
    }
  }
}
    `;

/**
 * __useGetTemplateOrganisationsQuery__
 *
 * To run a query within a React component, call `useGetTemplateOrganisationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTemplateOrganisationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTemplateOrganisationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTemplateOrganisationsQuery(baseOptions?: Apollo.QueryHookOptions<GetTemplateOrganisationsQuery, GetTemplateOrganisationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTemplateOrganisationsQuery, GetTemplateOrganisationsQueryVariables>(GetTemplateOrganisationsDocument, options);
      }
export function useGetTemplateOrganisationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTemplateOrganisationsQuery, GetTemplateOrganisationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTemplateOrganisationsQuery, GetTemplateOrganisationsQueryVariables>(GetTemplateOrganisationsDocument, options);
        }
export type GetTemplateOrganisationsQueryHookResult = ReturnType<typeof useGetTemplateOrganisationsQuery>;
export type GetTemplateOrganisationsLazyQueryHookResult = ReturnType<typeof useGetTemplateOrganisationsLazyQuery>;
export type GetTemplateOrganisationsQueryResult = Apollo.QueryResult<GetTemplateOrganisationsQuery, GetTemplateOrganisationsQueryVariables>;
export const GetTemplateCreatorsDocument = gql`
    query getTemplateCreators {
  templates(filter: {type: CUSTOM}) {
    results {
      creator {
        _id
        firstName
        lastName
      }
    }
    pageInfo {
      total
    }
  }
}
    `;

/**
 * __useGetTemplateCreatorsQuery__
 *
 * To run a query within a React component, call `useGetTemplateCreatorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTemplateCreatorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTemplateCreatorsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTemplateCreatorsQuery(baseOptions?: Apollo.QueryHookOptions<GetTemplateCreatorsQuery, GetTemplateCreatorsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTemplateCreatorsQuery, GetTemplateCreatorsQueryVariables>(GetTemplateCreatorsDocument, options);
      }
export function useGetTemplateCreatorsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTemplateCreatorsQuery, GetTemplateCreatorsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTemplateCreatorsQuery, GetTemplateCreatorsQueryVariables>(GetTemplateCreatorsDocument, options);
        }
export type GetTemplateCreatorsQueryHookResult = ReturnType<typeof useGetTemplateCreatorsQuery>;
export type GetTemplateCreatorsLazyQueryHookResult = ReturnType<typeof useGetTemplateCreatorsLazyQuery>;
export type GetTemplateCreatorsQueryResult = Apollo.QueryResult<GetTemplateCreatorsQuery, GetTemplateCreatorsQueryVariables>;
export const TemplateFilterCountsDocument = gql`
    query TemplateFilterCounts($filter: TemplateFilter) {
  templateFilterCounts(filter: $filter) {
    live
    bin
    hidden
  }
}
    `;

/**
 * __useTemplateFilterCountsQuery__
 *
 * To run a query within a React component, call `useTemplateFilterCountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTemplateFilterCountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTemplateFilterCountsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useTemplateFilterCountsQuery(baseOptions?: Apollo.QueryHookOptions<TemplateFilterCountsQuery, TemplateFilterCountsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TemplateFilterCountsQuery, TemplateFilterCountsQueryVariables>(TemplateFilterCountsDocument, options);
      }
export function useTemplateFilterCountsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TemplateFilterCountsQuery, TemplateFilterCountsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TemplateFilterCountsQuery, TemplateFilterCountsQueryVariables>(TemplateFilterCountsDocument, options);
        }
export type TemplateFilterCountsQueryHookResult = ReturnType<typeof useTemplateFilterCountsQuery>;
export type TemplateFilterCountsLazyQueryHookResult = ReturnType<typeof useTemplateFilterCountsLazyQuery>;
export type TemplateFilterCountsQueryResult = Apollo.QueryResult<TemplateFilterCountsQuery, TemplateFilterCountsQueryVariables>;
export const ActionTaskDocument = gql`
    mutation actionTask($unitID: ID!, $taskID: ID!, $applicable: Boolean, $stringValue: String, $floatValue: Float, $booleanValue: Boolean, $fileValue: [FileInput!]) {
  unitTaskAction(
    unitID: $unitID
    input: {taskID: $taskID, applicable: $applicable, stringValue: $stringValue, floatValue: $floatValue, booleanValue: $booleanValue, fileValue: $fileValue}
  ) {
    _id
    value {
      ... on StringValue {
        stringValue
      }
      ... on FloatValue {
        floatValue
      }
      ... on BooleanValue {
        booleanValue
      }
      ... on File {
        _id
        name
        url
      }
      ... on FilesValue {
        filesValue {
          _id
          name
          url
        }
      }
    }
    applicable
  }
}
    `;
export type ActionTaskMutationFn = Apollo.MutationFunction<ActionTaskMutation, ActionTaskMutationVariables>;

/**
 * __useActionTaskMutation__
 *
 * To run a mutation, you first call `useActionTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActionTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [actionTaskMutation, { data, loading, error }] = useActionTaskMutation({
 *   variables: {
 *      unitID: // value for 'unitID'
 *      taskID: // value for 'taskID'
 *      applicable: // value for 'applicable'
 *      stringValue: // value for 'stringValue'
 *      floatValue: // value for 'floatValue'
 *      booleanValue: // value for 'booleanValue'
 *      fileValue: // value for 'fileValue'
 *   },
 * });
 */
export function useActionTaskMutation(baseOptions?: Apollo.MutationHookOptions<ActionTaskMutation, ActionTaskMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ActionTaskMutation, ActionTaskMutationVariables>(ActionTaskDocument, options);
      }
export type ActionTaskMutationHookResult = ReturnType<typeof useActionTaskMutation>;
export type ActionTaskMutationResult = Apollo.MutationResult<ActionTaskMutation>;
export type ActionTaskMutationOptions = Apollo.BaseMutationOptions<ActionTaskMutation, ActionTaskMutationVariables>;
export const UnitTaskRemoveFileDocument = gql`
    mutation unitTaskRemoveFile($unitID: ID!, $taskID: ID!, $fileID: ID!) {
  unitTaskRemoveFile(unitID: $unitID, taskID: $taskID, fileID: $fileID) {
    _id
    value {
      ... on StringValue {
        stringValue
      }
      ... on FloatValue {
        floatValue
      }
      ... on BooleanValue {
        booleanValue
      }
      ... on File {
        _id
        name
        url
      }
      ... on FilesValue {
        filesValue {
          _id
          name
          url
        }
      }
    }
    applicable
  }
}
    `;
export type UnitTaskRemoveFileMutationFn = Apollo.MutationFunction<UnitTaskRemoveFileMutation, UnitTaskRemoveFileMutationVariables>;

/**
 * __useUnitTaskRemoveFileMutation__
 *
 * To run a mutation, you first call `useUnitTaskRemoveFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnitTaskRemoveFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unitTaskRemoveFileMutation, { data, loading, error }] = useUnitTaskRemoveFileMutation({
 *   variables: {
 *      unitID: // value for 'unitID'
 *      taskID: // value for 'taskID'
 *      fileID: // value for 'fileID'
 *   },
 * });
 */
export function useUnitTaskRemoveFileMutation(baseOptions?: Apollo.MutationHookOptions<UnitTaskRemoveFileMutation, UnitTaskRemoveFileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UnitTaskRemoveFileMutation, UnitTaskRemoveFileMutationVariables>(UnitTaskRemoveFileDocument, options);
      }
export type UnitTaskRemoveFileMutationHookResult = ReturnType<typeof useUnitTaskRemoveFileMutation>;
export type UnitTaskRemoveFileMutationResult = Apollo.MutationResult<UnitTaskRemoveFileMutation>;
export type UnitTaskRemoveFileMutationOptions = Apollo.BaseMutationOptions<UnitTaskRemoveFileMutation, UnitTaskRemoveFileMutationVariables>;
export const TenantActionStageFourDocument = gql`
    mutation tenantActionStageFour($dealID: ID!, $completed: Boolean, $kyc: Boolean, $noLawyer: Boolean) {
  dealTenantDetailsSet(
    dealID: $dealID
    four: {completed: $completed, kyc: $kyc, noLawyer: $noLawyer}
  ) {
    ... on TenantDetails {
      four {
        edited
        completed
        kyc
        noLawyer
        files {
          _id
          created
          name
          url
        }
      }
    }
  }
}
    `;
export type TenantActionStageFourMutationFn = Apollo.MutationFunction<TenantActionStageFourMutation, TenantActionStageFourMutationVariables>;

/**
 * __useTenantActionStageFourMutation__
 *
 * To run a mutation, you first call `useTenantActionStageFourMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTenantActionStageFourMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [tenantActionStageFourMutation, { data, loading, error }] = useTenantActionStageFourMutation({
 *   variables: {
 *      dealID: // value for 'dealID'
 *      completed: // value for 'completed'
 *      kyc: // value for 'kyc'
 *      noLawyer: // value for 'noLawyer'
 *   },
 * });
 */
export function useTenantActionStageFourMutation(baseOptions?: Apollo.MutationHookOptions<TenantActionStageFourMutation, TenantActionStageFourMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TenantActionStageFourMutation, TenantActionStageFourMutationVariables>(TenantActionStageFourDocument, options);
      }
export type TenantActionStageFourMutationHookResult = ReturnType<typeof useTenantActionStageFourMutation>;
export type TenantActionStageFourMutationResult = Apollo.MutationResult<TenantActionStageFourMutation>;
export type TenantActionStageFourMutationOptions = Apollo.BaseMutationOptions<TenantActionStageFourMutation, TenantActionStageFourMutationVariables>;
export const TenantActionFinalStageDocument = gql`
    mutation tenantActionFinalStage($dealID: ID!, $input: TenantStageFinalInput!) {
  dealTenantDetailsSet(dealID: $dealID, final: $input) {
    ... on TenantDetails {
      final {
        completed
        edited
        informLandlord
      }
    }
  }
}
    `;
export type TenantActionFinalStageMutationFn = Apollo.MutationFunction<TenantActionFinalStageMutation, TenantActionFinalStageMutationVariables>;

/**
 * __useTenantActionFinalStageMutation__
 *
 * To run a mutation, you first call `useTenantActionFinalStageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTenantActionFinalStageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [tenantActionFinalStageMutation, { data, loading, error }] = useTenantActionFinalStageMutation({
 *   variables: {
 *      dealID: // value for 'dealID'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTenantActionFinalStageMutation(baseOptions?: Apollo.MutationHookOptions<TenantActionFinalStageMutation, TenantActionFinalStageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TenantActionFinalStageMutation, TenantActionFinalStageMutationVariables>(TenantActionFinalStageDocument, options);
      }
export type TenantActionFinalStageMutationHookResult = ReturnType<typeof useTenantActionFinalStageMutation>;
export type TenantActionFinalStageMutationResult = Apollo.MutationResult<TenantActionFinalStageMutation>;
export type TenantActionFinalStageMutationOptions = Apollo.BaseMutationOptions<TenantActionFinalStageMutation, TenantActionFinalStageMutationVariables>;
export const TenantActionStageFiveDocument = gql`
    mutation tenantActionStageFive($dealID: ID!, $instructions: Boolean, $signed: Boolean, $completed: Boolean, $noLawyer: Boolean) {
  dealTenantDetailsSet(
    dealID: $dealID
    five: {completed: $completed, instructions: $instructions, signed: $signed, noLawyer: $noLawyer}
  ) {
    ... on TenantDetails {
      five {
        completed
        edited
        noLawyer
        instructions
        signed
      }
    }
  }
}
    `;
export type TenantActionStageFiveMutationFn = Apollo.MutationFunction<TenantActionStageFiveMutation, TenantActionStageFiveMutationVariables>;

/**
 * __useTenantActionStageFiveMutation__
 *
 * To run a mutation, you first call `useTenantActionStageFiveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTenantActionStageFiveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [tenantActionStageFiveMutation, { data, loading, error }] = useTenantActionStageFiveMutation({
 *   variables: {
 *      dealID: // value for 'dealID'
 *      instructions: // value for 'instructions'
 *      signed: // value for 'signed'
 *      completed: // value for 'completed'
 *      noLawyer: // value for 'noLawyer'
 *   },
 * });
 */
export function useTenantActionStageFiveMutation(baseOptions?: Apollo.MutationHookOptions<TenantActionStageFiveMutation, TenantActionStageFiveMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TenantActionStageFiveMutation, TenantActionStageFiveMutationVariables>(TenantActionStageFiveDocument, options);
      }
export type TenantActionStageFiveMutationHookResult = ReturnType<typeof useTenantActionStageFiveMutation>;
export type TenantActionStageFiveMutationResult = Apollo.MutationResult<TenantActionStageFiveMutation>;
export type TenantActionStageFiveMutationOptions = Apollo.BaseMutationOptions<TenantActionStageFiveMutation, TenantActionStageFiveMutationVariables>;
export const TenantActionStageFourFileDocument = gql`
    mutation tenantActionStageFourFile($dealID: ID!, $file: FileInput!) {
  dealTenantDetailsSet(dealID: $dealID, four: {file: $file}) {
    ... on TenantDetails {
      four {
        files {
          _id
          name
          created
          url
        }
      }
    }
  }
}
    `;
export type TenantActionStageFourFileMutationFn = Apollo.MutationFunction<TenantActionStageFourFileMutation, TenantActionStageFourFileMutationVariables>;

/**
 * __useTenantActionStageFourFileMutation__
 *
 * To run a mutation, you first call `useTenantActionStageFourFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTenantActionStageFourFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [tenantActionStageFourFileMutation, { data, loading, error }] = useTenantActionStageFourFileMutation({
 *   variables: {
 *      dealID: // value for 'dealID'
 *      file: // value for 'file'
 *   },
 * });
 */
export function useTenantActionStageFourFileMutation(baseOptions?: Apollo.MutationHookOptions<TenantActionStageFourFileMutation, TenantActionStageFourFileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TenantActionStageFourFileMutation, TenantActionStageFourFileMutationVariables>(TenantActionStageFourFileDocument, options);
      }
export type TenantActionStageFourFileMutationHookResult = ReturnType<typeof useTenantActionStageFourFileMutation>;
export type TenantActionStageFourFileMutationResult = Apollo.MutationResult<TenantActionStageFourFileMutation>;
export type TenantActionStageFourFileMutationOptions = Apollo.BaseMutationOptions<TenantActionStageFourFileMutation, TenantActionStageFourFileMutationVariables>;
export const TenantActionStageOneDocument = gql`
    mutation tenantActionStageOne($dealID: ID!, $completed: Boolean, $entity: TenantEntityInput!, $building: TenantEntityInput, $isSameAddress: Boolean) {
  dealTenantDetailsSet(
    dealID: $dealID
    one: {completed: $completed, entity: $entity, building: $building, isSameAddress: $isSameAddress}
  ) {
    ... on TenantDetails {
      one {
        isSameAddress
        edited
        completed
        entity {
          name
          companyNumber
          address {
            line1
            line2
            city
            postCode
          }
        }
        building {
          visible
          address {
            line1
            line2
            city
            postCode
          }
        }
      }
    }
  }
}
    `;
export type TenantActionStageOneMutationFn = Apollo.MutationFunction<TenantActionStageOneMutation, TenantActionStageOneMutationVariables>;

/**
 * __useTenantActionStageOneMutation__
 *
 * To run a mutation, you first call `useTenantActionStageOneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTenantActionStageOneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [tenantActionStageOneMutation, { data, loading, error }] = useTenantActionStageOneMutation({
 *   variables: {
 *      dealID: // value for 'dealID'
 *      completed: // value for 'completed'
 *      entity: // value for 'entity'
 *      building: // value for 'building'
 *      isSameAddress: // value for 'isSameAddress'
 *   },
 * });
 */
export function useTenantActionStageOneMutation(baseOptions?: Apollo.MutationHookOptions<TenantActionStageOneMutation, TenantActionStageOneMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TenantActionStageOneMutation, TenantActionStageOneMutationVariables>(TenantActionStageOneDocument, options);
      }
export type TenantActionStageOneMutationHookResult = ReturnType<typeof useTenantActionStageOneMutation>;
export type TenantActionStageOneMutationResult = Apollo.MutationResult<TenantActionStageOneMutation>;
export type TenantActionStageOneMutationOptions = Apollo.BaseMutationOptions<TenantActionStageOneMutation, TenantActionStageOneMutationVariables>;
export const TenantActionStageSixDocument = gql`
    mutation tenantActionStageSix($dealID: ID!, $fitted: Boolean, $company: TenantCompanyInput, $projectManager: TenantPersonInput, $completed: Boolean) {
  dealTenantDetailsSet(
    dealID: $dealID
    six: {completed: $completed, fitted: $fitted, company: $company, projectManager: $projectManager}
  ) {
    ... on TenantDetails {
      six {
        edited
        completed
        fitted
        company {
          type
          name
          contactName
          email
          phone
        }
        projectManager {
          name
          company
          email
          phone
          visible
        }
      }
    }
  }
}
    `;
export type TenantActionStageSixMutationFn = Apollo.MutationFunction<TenantActionStageSixMutation, TenantActionStageSixMutationVariables>;

/**
 * __useTenantActionStageSixMutation__
 *
 * To run a mutation, you first call `useTenantActionStageSixMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTenantActionStageSixMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [tenantActionStageSixMutation, { data, loading, error }] = useTenantActionStageSixMutation({
 *   variables: {
 *      dealID: // value for 'dealID'
 *      fitted: // value for 'fitted'
 *      company: // value for 'company'
 *      projectManager: // value for 'projectManager'
 *      completed: // value for 'completed'
 *   },
 * });
 */
export function useTenantActionStageSixMutation(baseOptions?: Apollo.MutationHookOptions<TenantActionStageSixMutation, TenantActionStageSixMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TenantActionStageSixMutation, TenantActionStageSixMutationVariables>(TenantActionStageSixDocument, options);
      }
export type TenantActionStageSixMutationHookResult = ReturnType<typeof useTenantActionStageSixMutation>;
export type TenantActionStageSixMutationResult = Apollo.MutationResult<TenantActionStageSixMutation>;
export type TenantActionStageSixMutationOptions = Apollo.BaseMutationOptions<TenantActionStageSixMutation, TenantActionStageSixMutationVariables>;
export const TenantActionStageThreeDocument = gql`
    mutation tenantActionStageThree($dealID: ID!, $audited: Boolean, $guarantor: TenantEntityInput, $completed: Boolean, $noAccountsToShare: Boolean, $nominateGuarantor: Boolean) {
  dealTenantDetailsSet(
    dealID: $dealID
    three: {completed: $completed, audited: $audited, guarantor: $guarantor, noAccountsToShare: $noAccountsToShare, nominateGuarantor: $nominateGuarantor}
  ) {
    ... on TenantDetails {
      three {
        edited
        audited
        completed
        files {
          _id
          name
          created
          url
        }
        noAccountsToShare
        nominateGuarantor
        guarantor {
          name
          company
          jurisdiction
          visible
          address {
            line1
            line2
            city
            postCode
          }
        }
      }
    }
  }
}
    `;
export type TenantActionStageThreeMutationFn = Apollo.MutationFunction<TenantActionStageThreeMutation, TenantActionStageThreeMutationVariables>;

/**
 * __useTenantActionStageThreeMutation__
 *
 * To run a mutation, you first call `useTenantActionStageThreeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTenantActionStageThreeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [tenantActionStageThreeMutation, { data, loading, error }] = useTenantActionStageThreeMutation({
 *   variables: {
 *      dealID: // value for 'dealID'
 *      audited: // value for 'audited'
 *      guarantor: // value for 'guarantor'
 *      completed: // value for 'completed'
 *      noAccountsToShare: // value for 'noAccountsToShare'
 *      nominateGuarantor: // value for 'nominateGuarantor'
 *   },
 * });
 */
export function useTenantActionStageThreeMutation(baseOptions?: Apollo.MutationHookOptions<TenantActionStageThreeMutation, TenantActionStageThreeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TenantActionStageThreeMutation, TenantActionStageThreeMutationVariables>(TenantActionStageThreeDocument, options);
      }
export type TenantActionStageThreeMutationHookResult = ReturnType<typeof useTenantActionStageThreeMutation>;
export type TenantActionStageThreeMutationResult = Apollo.MutationResult<TenantActionStageThreeMutation>;
export type TenantActionStageThreeMutationOptions = Apollo.BaseMutationOptions<TenantActionStageThreeMutation, TenantActionStageThreeMutationVariables>;
export const TenantActionStageThreeFileDocument = gql`
    mutation tenantActionStageThreeFile($dealID: ID!, $file: FileInput!) {
  dealTenantDetailsSet(dealID: $dealID, three: {file: $file}) {
    ... on TenantDetails {
      three {
        files {
          _id
          name
          created
          url
        }
      }
    }
  }
}
    `;
export type TenantActionStageThreeFileMutationFn = Apollo.MutationFunction<TenantActionStageThreeFileMutation, TenantActionStageThreeFileMutationVariables>;

/**
 * __useTenantActionStageThreeFileMutation__
 *
 * To run a mutation, you first call `useTenantActionStageThreeFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTenantActionStageThreeFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [tenantActionStageThreeFileMutation, { data, loading, error }] = useTenantActionStageThreeFileMutation({
 *   variables: {
 *      dealID: // value for 'dealID'
 *      file: // value for 'file'
 *   },
 * });
 */
export function useTenantActionStageThreeFileMutation(baseOptions?: Apollo.MutationHookOptions<TenantActionStageThreeFileMutation, TenantActionStageThreeFileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TenantActionStageThreeFileMutation, TenantActionStageThreeFileMutationVariables>(TenantActionStageThreeFileDocument, options);
      }
export type TenantActionStageThreeFileMutationHookResult = ReturnType<typeof useTenantActionStageThreeFileMutation>;
export type TenantActionStageThreeFileMutationResult = Apollo.MutationResult<TenantActionStageThreeFileMutation>;
export type TenantActionStageThreeFileMutationOptions = Apollo.BaseMutationOptions<TenantActionStageThreeFileMutation, TenantActionStageThreeFileMutationVariables>;
export const TenantActionStageTwoDocument = gql`
    mutation tenantActionStageTwo($dealID: ID!, $completed: Boolean, $lawyer: TenantPersonInput, $agent: TenantPersonInput) {
  dealTenantDetailsSet(
    dealID: $dealID
    two: {lawyer: $lawyer, agent: $agent, completed: $completed}
  ) {
    ... on TenantDetails {
      two {
        completed
        edited
        lawyer {
          name
          company
          email
          phone
          invite
        }
        agent {
          name
          company
          email
          phone
          invite
          visible
        }
      }
    }
  }
}
    `;
export type TenantActionStageTwoMutationFn = Apollo.MutationFunction<TenantActionStageTwoMutation, TenantActionStageTwoMutationVariables>;

/**
 * __useTenantActionStageTwoMutation__
 *
 * To run a mutation, you first call `useTenantActionStageTwoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTenantActionStageTwoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [tenantActionStageTwoMutation, { data, loading, error }] = useTenantActionStageTwoMutation({
 *   variables: {
 *      dealID: // value for 'dealID'
 *      completed: // value for 'completed'
 *      lawyer: // value for 'lawyer'
 *      agent: // value for 'agent'
 *   },
 * });
 */
export function useTenantActionStageTwoMutation(baseOptions?: Apollo.MutationHookOptions<TenantActionStageTwoMutation, TenantActionStageTwoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TenantActionStageTwoMutation, TenantActionStageTwoMutationVariables>(TenantActionStageTwoDocument, options);
      }
export type TenantActionStageTwoMutationHookResult = ReturnType<typeof useTenantActionStageTwoMutation>;
export type TenantActionStageTwoMutationResult = Apollo.MutationResult<TenantActionStageTwoMutation>;
export type TenantActionStageTwoMutationOptions = Apollo.BaseMutationOptions<TenantActionStageTwoMutation, TenantActionStageTwoMutationVariables>;
export const TenantSendInviteDocument = gql`
    mutation tenantSendInvite($dealID: ID!, $type: InviteFromStageType!, $email: String!) {
  dealInviteFromStage(dealID: $dealID, type: $type, email: $email) {
    email
  }
}
    `;
export type TenantSendInviteMutationFn = Apollo.MutationFunction<TenantSendInviteMutation, TenantSendInviteMutationVariables>;

/**
 * __useTenantSendInviteMutation__
 *
 * To run a mutation, you first call `useTenantSendInviteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTenantSendInviteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [tenantSendInviteMutation, { data, loading, error }] = useTenantSendInviteMutation({
 *   variables: {
 *      dealID: // value for 'dealID'
 *      type: // value for 'type'
 *      email: // value for 'email'
 *   },
 * });
 */
export function useTenantSendInviteMutation(baseOptions?: Apollo.MutationHookOptions<TenantSendInviteMutation, TenantSendInviteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TenantSendInviteMutation, TenantSendInviteMutationVariables>(TenantSendInviteDocument, options);
      }
export type TenantSendInviteMutationHookResult = ReturnType<typeof useTenantSendInviteMutation>;
export type TenantSendInviteMutationResult = Apollo.MutationResult<TenantSendInviteMutation>;
export type TenantSendInviteMutationOptions = Apollo.BaseMutationOptions<TenantSendInviteMutation, TenantSendInviteMutationVariables>;
export const AddActorToUnitDocument = gql`
    mutation addActorToUnit($unitID: ID!, $emails: [String!]!, $type: UserType!, $asMember: Boolean) {
  unitAddActor(
    unitID: $unitID
    input: {emails: $emails, type: $type, asMember: $asMember}
  ) {
    ... on Unit {
      _id
      actors {
        _id
        firstName
        lastName
        email
        mobile
        type
        pending
        organisation {
          _id
          name
        }
      }
    }
  }
}
    `;
export type AddActorToUnitMutationFn = Apollo.MutationFunction<AddActorToUnitMutation, AddActorToUnitMutationVariables>;

/**
 * __useAddActorToUnitMutation__
 *
 * To run a mutation, you first call `useAddActorToUnitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddActorToUnitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addActorToUnitMutation, { data, loading, error }] = useAddActorToUnitMutation({
 *   variables: {
 *      unitID: // value for 'unitID'
 *      emails: // value for 'emails'
 *      type: // value for 'type'
 *      asMember: // value for 'asMember'
 *   },
 * });
 */
export function useAddActorToUnitMutation(baseOptions?: Apollo.MutationHookOptions<AddActorToUnitMutation, AddActorToUnitMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddActorToUnitMutation, AddActorToUnitMutationVariables>(AddActorToUnitDocument, options);
      }
export type AddActorToUnitMutationHookResult = ReturnType<typeof useAddActorToUnitMutation>;
export type AddActorToUnitMutationResult = Apollo.MutationResult<AddActorToUnitMutation>;
export type AddActorToUnitMutationOptions = Apollo.BaseMutationOptions<AddActorToUnitMutation, AddActorToUnitMutationVariables>;
export const RemoveUserFromUnitDocument = gql`
    mutation removeUserFromUnit($unitID: ID!, $userID: ID!) {
  unitRemoveActor(unitID: $unitID, userID: $userID) {
    _id
    actors {
      _id
      firstName
      lastName
      email
      mobile
      type
      pending
      organisation {
        _id
        name
      }
    }
  }
}
    `;
export type RemoveUserFromUnitMutationFn = Apollo.MutationFunction<RemoveUserFromUnitMutation, RemoveUserFromUnitMutationVariables>;

/**
 * __useRemoveUserFromUnitMutation__
 *
 * To run a mutation, you first call `useRemoveUserFromUnitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveUserFromUnitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeUserFromUnitMutation, { data, loading, error }] = useRemoveUserFromUnitMutation({
 *   variables: {
 *      unitID: // value for 'unitID'
 *      userID: // value for 'userID'
 *   },
 * });
 */
export function useRemoveUserFromUnitMutation(baseOptions?: Apollo.MutationHookOptions<RemoveUserFromUnitMutation, RemoveUserFromUnitMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveUserFromUnitMutation, RemoveUserFromUnitMutationVariables>(RemoveUserFromUnitDocument, options);
      }
export type RemoveUserFromUnitMutationHookResult = ReturnType<typeof useRemoveUserFromUnitMutation>;
export type RemoveUserFromUnitMutationResult = Apollo.MutationResult<RemoveUserFromUnitMutation>;
export type RemoveUserFromUnitMutationOptions = Apollo.BaseMutationOptions<RemoveUserFromUnitMutation, RemoveUserFromUnitMutationVariables>;
export const UnitBulkAddActorsDocument = gql`
    mutation unitBulkAddActors($dealIDs: [ID!]!, $emails: [String!]!, $all: Boolean!, $filter: UnitFilter!) {
  unitBulkAddActors(
    dealIDs: $dealIDs
    emails: $emails
    all: $all
    filter: $filter
  ) {
    _id
  }
}
    `;
export type UnitBulkAddActorsMutationFn = Apollo.MutationFunction<UnitBulkAddActorsMutation, UnitBulkAddActorsMutationVariables>;

/**
 * __useUnitBulkAddActorsMutation__
 *
 * To run a mutation, you first call `useUnitBulkAddActorsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnitBulkAddActorsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unitBulkAddActorsMutation, { data, loading, error }] = useUnitBulkAddActorsMutation({
 *   variables: {
 *      dealIDs: // value for 'dealIDs'
 *      emails: // value for 'emails'
 *      all: // value for 'all'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useUnitBulkAddActorsMutation(baseOptions?: Apollo.MutationHookOptions<UnitBulkAddActorsMutation, UnitBulkAddActorsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UnitBulkAddActorsMutation, UnitBulkAddActorsMutationVariables>(UnitBulkAddActorsDocument, options);
      }
export type UnitBulkAddActorsMutationHookResult = ReturnType<typeof useUnitBulkAddActorsMutation>;
export type UnitBulkAddActorsMutationResult = Apollo.MutationResult<UnitBulkAddActorsMutation>;
export type UnitBulkAddActorsMutationOptions = Apollo.BaseMutationOptions<UnitBulkAddActorsMutation, UnitBulkAddActorsMutationVariables>;
export const UnitBulkHideDocument = gql`
    mutation unitBulkHide($unitIDs: [ID!]!, $all: Boolean!, $filter: UnitFilter!, $hideConnectedDeals: Boolean, $showHiddenDealsOnActive: Boolean) {
  unitBulkHide(
    unitIDs: $unitIDs
    all: $all
    filter: $filter
    hideConnectedDeals: $hideConnectedDeals
    showHiddenDealsOnActive: $showHiddenDealsOnActive
  ) {
    _id
  }
}
    `;
export type UnitBulkHideMutationFn = Apollo.MutationFunction<UnitBulkHideMutation, UnitBulkHideMutationVariables>;

/**
 * __useUnitBulkHideMutation__
 *
 * To run a mutation, you first call `useUnitBulkHideMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnitBulkHideMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unitBulkHideMutation, { data, loading, error }] = useUnitBulkHideMutation({
 *   variables: {
 *      unitIDs: // value for 'unitIDs'
 *      all: // value for 'all'
 *      filter: // value for 'filter'
 *      hideConnectedDeals: // value for 'hideConnectedDeals'
 *      showHiddenDealsOnActive: // value for 'showHiddenDealsOnActive'
 *   },
 * });
 */
export function useUnitBulkHideMutation(baseOptions?: Apollo.MutationHookOptions<UnitBulkHideMutation, UnitBulkHideMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UnitBulkHideMutation, UnitBulkHideMutationVariables>(UnitBulkHideDocument, options);
      }
export type UnitBulkHideMutationHookResult = ReturnType<typeof useUnitBulkHideMutation>;
export type UnitBulkHideMutationResult = Apollo.MutationResult<UnitBulkHideMutation>;
export type UnitBulkHideMutationOptions = Apollo.BaseMutationOptions<UnitBulkHideMutation, UnitBulkHideMutationVariables>;
export const UnitBulkUnhideDocument = gql`
    mutation unitBulkUnhide($unitIDs: [ID!]!, $all: Boolean!, $filter: UnitFilter!, $unhideConnectedDeals: Boolean) {
  unitBulkUnhide(
    unitIDs: $unitIDs
    all: $all
    filter: $filter
    unhideConnectedDeals: $unhideConnectedDeals
  ) {
    _id
  }
}
    `;
export type UnitBulkUnhideMutationFn = Apollo.MutationFunction<UnitBulkUnhideMutation, UnitBulkUnhideMutationVariables>;

/**
 * __useUnitBulkUnhideMutation__
 *
 * To run a mutation, you first call `useUnitBulkUnhideMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnitBulkUnhideMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unitBulkUnhideMutation, { data, loading, error }] = useUnitBulkUnhideMutation({
 *   variables: {
 *      unitIDs: // value for 'unitIDs'
 *      all: // value for 'all'
 *      filter: // value for 'filter'
 *      unhideConnectedDeals: // value for 'unhideConnectedDeals'
 *   },
 * });
 */
export function useUnitBulkUnhideMutation(baseOptions?: Apollo.MutationHookOptions<UnitBulkUnhideMutation, UnitBulkUnhideMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UnitBulkUnhideMutation, UnitBulkUnhideMutationVariables>(UnitBulkUnhideDocument, options);
      }
export type UnitBulkUnhideMutationHookResult = ReturnType<typeof useUnitBulkUnhideMutation>;
export type UnitBulkUnhideMutationResult = Apollo.MutationResult<UnitBulkUnhideMutation>;
export type UnitBulkUnhideMutationOptions = Apollo.BaseMutationOptions<UnitBulkUnhideMutation, UnitBulkUnhideMutationVariables>;
export const UpdateUnitDocument = gql`
    mutation updateUnit($unitID: ID!, $name: String, $buildingName: String, $area: Int, $line1: String, $line2: String, $city: String, $postCode: String, $url: String, $avatar: String, $straightToLease: Boolean, $condition: UnitCondition, $available: UnitAvailability) {
  unitUpdate(
    unitID: $unitID
    input: {name: $name, area: $area, url: $url, avatar: $avatar, condition: $condition, straightToLease: $straightToLease, buildingName: $buildingName, available: $available, address: {line1: $line1, line2: $line2, city: $city, postCode: $postCode}}
  ) {
    ... on Unit {
      _id
      name
      area
      url
      avatar
      use
      condition
      straightToLease
      available
      buildingName
    }
  }
}
    `;
export type UpdateUnitMutationFn = Apollo.MutationFunction<UpdateUnitMutation, UpdateUnitMutationVariables>;

/**
 * __useUpdateUnitMutation__
 *
 * To run a mutation, you first call `useUpdateUnitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUnitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUnitMutation, { data, loading, error }] = useUpdateUnitMutation({
 *   variables: {
 *      unitID: // value for 'unitID'
 *      name: // value for 'name'
 *      buildingName: // value for 'buildingName'
 *      area: // value for 'area'
 *      line1: // value for 'line1'
 *      line2: // value for 'line2'
 *      city: // value for 'city'
 *      postCode: // value for 'postCode'
 *      url: // value for 'url'
 *      avatar: // value for 'avatar'
 *      straightToLease: // value for 'straightToLease'
 *      condition: // value for 'condition'
 *      available: // value for 'available'
 *   },
 * });
 */
export function useUpdateUnitMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUnitMutation, UpdateUnitMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUnitMutation, UpdateUnitMutationVariables>(UpdateUnitDocument, options);
      }
export type UpdateUnitMutationHookResult = ReturnType<typeof useUpdateUnitMutation>;
export type UpdateUnitMutationResult = Apollo.MutationResult<UpdateUnitMutation>;
export type UpdateUnitMutationOptions = Apollo.BaseMutationOptions<UpdateUnitMutation, UpdateUnitMutationVariables>;
export const VerifyUnitDocument = gql`
    mutation verifyUnit($token: String!) {
  unitVerify(token: $token) {
    ... on Unit {
      _id
      pending
    }
  }
}
    `;
export type VerifyUnitMutationFn = Apollo.MutationFunction<VerifyUnitMutation, VerifyUnitMutationVariables>;

/**
 * __useVerifyUnitMutation__
 *
 * To run a mutation, you first call `useVerifyUnitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyUnitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyUnitMutation, { data, loading, error }] = useVerifyUnitMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useVerifyUnitMutation(baseOptions?: Apollo.MutationHookOptions<VerifyUnitMutation, VerifyUnitMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<VerifyUnitMutation, VerifyUnitMutationVariables>(VerifyUnitDocument, options);
      }
export type VerifyUnitMutationHookResult = ReturnType<typeof useVerifyUnitMutation>;
export type VerifyUnitMutationResult = Apollo.MutationResult<VerifyUnitMutation>;
export type VerifyUnitMutationOptions = Apollo.BaseMutationOptions<VerifyUnitMutation, VerifyUnitMutationVariables>;
export const GetBuildingUpdateDetailsDocument = gql`
    query getBuildingUpdateDetails($unitID: ID!) {
  unit(id: $unitID) {
    ... on Unit {
      _id
      name
      url
      buildingName
      avatar
      address {
        line1
        line2
        city
        postCode
      }
      area
      use
      condition
      straightToLease
    }
  }
}
    `;

/**
 * __useGetBuildingUpdateDetailsQuery__
 *
 * To run a query within a React component, call `useGetBuildingUpdateDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBuildingUpdateDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBuildingUpdateDetailsQuery({
 *   variables: {
 *      unitID: // value for 'unitID'
 *   },
 * });
 */
export function useGetBuildingUpdateDetailsQuery(baseOptions: Apollo.QueryHookOptions<GetBuildingUpdateDetailsQuery, GetBuildingUpdateDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBuildingUpdateDetailsQuery, GetBuildingUpdateDetailsQueryVariables>(GetBuildingUpdateDetailsDocument, options);
      }
export function useGetBuildingUpdateDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBuildingUpdateDetailsQuery, GetBuildingUpdateDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBuildingUpdateDetailsQuery, GetBuildingUpdateDetailsQueryVariables>(GetBuildingUpdateDetailsDocument, options);
        }
export type GetBuildingUpdateDetailsQueryHookResult = ReturnType<typeof useGetBuildingUpdateDetailsQuery>;
export type GetBuildingUpdateDetailsLazyQueryHookResult = ReturnType<typeof useGetBuildingUpdateDetailsLazyQuery>;
export type GetBuildingUpdateDetailsQueryResult = Apollo.QueryResult<GetBuildingUpdateDetailsQuery, GetBuildingUpdateDetailsQueryVariables>;
export const GetDealTenantDetailsDocument = gql`
    query getDealTenantDetails($dealID: ID!, $token: String) {
  deal(id: $dealID, token: $token) {
    ... on Deal {
      _id
      unit {
        _id
      }
      tenant {
        members {
          _id
          type
          firstName
          lastName
          email
          mobile
          organisation {
            name
          }
        }
      }
      details {
        one {
          edited
          completed
          entity {
            name
            companyNumber
            address {
              line1
              line2
              city
              postCode
            }
          }
          isSameAddress
          building {
            visible
            address {
              line1
              line2
              city
              postCode
            }
          }
        }
        two {
          edited
          completed
          lawyer {
            name
            company
            email
            invite
            phone
            visible
          }
          agent {
            name
            company
            email
            invite
            visible
          }
        }
        three {
          edited
          audited
          completed
          noAccountsToShare
          nominateGuarantor
          files {
            _id
            created
            name
            url
          }
          guarantor {
            name
            company
            jurisdiction
            visible
            address {
              line1
              line2
              city
              postCode
            }
          }
        }
        four {
          edited
          noLawyer
          completed
          kyc
          files {
            _id
            created
            name
            url
          }
        }
        five {
          edited
          noLawyer
          completed
          instructions
          signed
        }
        six {
          edited
          completed
          fitted
          company {
            type
            name
            contactName
            email
            phone
          }
          projectManager {
            name
            company
            email
            phone
            visible
          }
        }
        team {
          edited
          completed
          agent {
            name
            company
            email
            phone
            visible
          }
          tenant {
            name
            company
            email
            phone
          }
        }
        aml {
          completed
          edited
          companyName
          companyJurisdiction
          companyRegistrationNumber
          personalDetails {
            name
            phone
          }
        }
        final {
          edited
          completed
          informLandlord
        }
      }
    }
  }
}
    `;

/**
 * __useGetDealTenantDetailsQuery__
 *
 * To run a query within a React component, call `useGetDealTenantDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDealTenantDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDealTenantDetailsQuery({
 *   variables: {
 *      dealID: // value for 'dealID'
 *      token: // value for 'token'
 *   },
 * });
 */
export function useGetDealTenantDetailsQuery(baseOptions: Apollo.QueryHookOptions<GetDealTenantDetailsQuery, GetDealTenantDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDealTenantDetailsQuery, GetDealTenantDetailsQueryVariables>(GetDealTenantDetailsDocument, options);
      }
export function useGetDealTenantDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDealTenantDetailsQuery, GetDealTenantDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDealTenantDetailsQuery, GetDealTenantDetailsQueryVariables>(GetDealTenantDetailsDocument, options);
        }
export type GetDealTenantDetailsQueryHookResult = ReturnType<typeof useGetDealTenantDetailsQuery>;
export type GetDealTenantDetailsLazyQueryHookResult = ReturnType<typeof useGetDealTenantDetailsLazyQuery>;
export type GetDealTenantDetailsQueryResult = Apollo.QueryResult<GetDealTenantDetailsQuery, GetDealTenantDetailsQueryVariables>;
export const GetDealUnitDetailsDocument = gql`
    query getDealUnitDetails($dealID: ID!) {
  deal(id: $dealID) {
    ... on Deal {
      _id
      pending
      state
      isPinned
      currentUserParty
      round {
        count
        nudge
        date
      }
      clauseSummary {
        incomplete
        complete
      }
      details {
        ...TenantDetails
      }
      unit {
        ...SingleUnit
      }
    }
  }
}
    ${TenantDetailsFragmentDoc}
${SingleUnitFragmentDoc}`;

/**
 * __useGetDealUnitDetailsQuery__
 *
 * To run a query within a React component, call `useGetDealUnitDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDealUnitDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDealUnitDetailsQuery({
 *   variables: {
 *      dealID: // value for 'dealID'
 *   },
 * });
 */
export function useGetDealUnitDetailsQuery(baseOptions: Apollo.QueryHookOptions<GetDealUnitDetailsQuery, GetDealUnitDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDealUnitDetailsQuery, GetDealUnitDetailsQueryVariables>(GetDealUnitDetailsDocument, options);
      }
export function useGetDealUnitDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDealUnitDetailsQuery, GetDealUnitDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDealUnitDetailsQuery, GetDealUnitDetailsQueryVariables>(GetDealUnitDetailsDocument, options);
        }
export type GetDealUnitDetailsQueryHookResult = ReturnType<typeof useGetDealUnitDetailsQuery>;
export type GetDealUnitDetailsLazyQueryHookResult = ReturnType<typeof useGetDealUnitDetailsLazyQuery>;
export type GetDealUnitDetailsQueryResult = Apollo.QueryResult<GetDealUnitDetailsQuery, GetDealUnitDetailsQueryVariables>;
export const GetUnitDetailsDocument = gql`
    query getUnitDetails($unitID: ID!) {
  unit(id: $unitID) {
    ...SingleUnit
  }
}
    ${SingleUnitFragmentDoc}`;

/**
 * __useGetUnitDetailsQuery__
 *
 * To run a query within a React component, call `useGetUnitDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUnitDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUnitDetailsQuery({
 *   variables: {
 *      unitID: // value for 'unitID'
 *   },
 * });
 */
export function useGetUnitDetailsQuery(baseOptions: Apollo.QueryHookOptions<GetUnitDetailsQuery, GetUnitDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUnitDetailsQuery, GetUnitDetailsQueryVariables>(GetUnitDetailsDocument, options);
      }
export function useGetUnitDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUnitDetailsQuery, GetUnitDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUnitDetailsQuery, GetUnitDetailsQueryVariables>(GetUnitDetailsDocument, options);
        }
export type GetUnitDetailsQueryHookResult = ReturnType<typeof useGetUnitDetailsQuery>;
export type GetUnitDetailsLazyQueryHookResult = ReturnType<typeof useGetUnitDetailsLazyQuery>;
export type GetUnitDetailsQueryResult = Apollo.QueryResult<GetUnitDetailsQuery, GetUnitDetailsQueryVariables>;
export const GetUnitFilesTasksDocument = gql`
    query getUnitFilesTasks($unitID: ID!) {
  unit(id: $unitID) {
    ... on Unit {
      _id
      files {
        _id
        name
        created
        url
      }
      tasks {
        _id
        value {
          ... on StringValue {
            stringValue
          }
          ... on FloatValue {
            floatValue
          }
          ... on BooleanValue {
            booleanValue
          }
          ... on File {
            _id
            name
            url
          }
          ... on FilesValue {
            filesValue {
              _id
              name
              url
            }
          }
        }
        applicable
        edited
        task {
          _id
          title
          index
          weight
          optional
          type
        }
      }
    }
  }
}
    `;

/**
 * __useGetUnitFilesTasksQuery__
 *
 * To run a query within a React component, call `useGetUnitFilesTasksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUnitFilesTasksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUnitFilesTasksQuery({
 *   variables: {
 *      unitID: // value for 'unitID'
 *   },
 * });
 */
export function useGetUnitFilesTasksQuery(baseOptions: Apollo.QueryHookOptions<GetUnitFilesTasksQuery, GetUnitFilesTasksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUnitFilesTasksQuery, GetUnitFilesTasksQueryVariables>(GetUnitFilesTasksDocument, options);
      }
export function useGetUnitFilesTasksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUnitFilesTasksQuery, GetUnitFilesTasksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUnitFilesTasksQuery, GetUnitFilesTasksQueryVariables>(GetUnitFilesTasksDocument, options);
        }
export type GetUnitFilesTasksQueryHookResult = ReturnType<typeof useGetUnitFilesTasksQuery>;
export type GetUnitFilesTasksLazyQueryHookResult = ReturnType<typeof useGetUnitFilesTasksLazyQuery>;
export type GetUnitFilesTasksQueryResult = Apollo.QueryResult<GetUnitFilesTasksQuery, GetUnitFilesTasksQueryVariables>;
export const GetUnitRatingDocument = gql`
    query getUnitRating($unitID: ID!) {
  unit(id: $unitID) {
    ... on Unit {
      _id
      rating
    }
  }
}
    `;

/**
 * __useGetUnitRatingQuery__
 *
 * To run a query within a React component, call `useGetUnitRatingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUnitRatingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUnitRatingQuery({
 *   variables: {
 *      unitID: // value for 'unitID'
 *   },
 * });
 */
export function useGetUnitRatingQuery(baseOptions: Apollo.QueryHookOptions<GetUnitRatingQuery, GetUnitRatingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUnitRatingQuery, GetUnitRatingQueryVariables>(GetUnitRatingDocument, options);
      }
export function useGetUnitRatingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUnitRatingQuery, GetUnitRatingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUnitRatingQuery, GetUnitRatingQueryVariables>(GetUnitRatingDocument, options);
        }
export type GetUnitRatingQueryHookResult = ReturnType<typeof useGetUnitRatingQuery>;
export type GetUnitRatingLazyQueryHookResult = ReturnType<typeof useGetUnitRatingLazyQuery>;
export type GetUnitRatingQueryResult = Apollo.QueryResult<GetUnitRatingQuery, GetUnitRatingQueryVariables>;
export const GetUserCompanyDocument = gql`
    query getUserCompany($unitID: ID!) {
  user {
    ... on User {
      organisation {
        name
      }
    }
  }
}
    `;

/**
 * __useGetUserCompanyQuery__
 *
 * To run a query within a React component, call `useGetUserCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserCompanyQuery({
 *   variables: {
 *      unitID: // value for 'unitID'
 *   },
 * });
 */
export function useGetUserCompanyQuery(baseOptions: Apollo.QueryHookOptions<GetUserCompanyQuery, GetUserCompanyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserCompanyQuery, GetUserCompanyQueryVariables>(GetUserCompanyDocument, options);
      }
export function useGetUserCompanyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserCompanyQuery, GetUserCompanyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserCompanyQuery, GetUserCompanyQueryVariables>(GetUserCompanyDocument, options);
        }
export type GetUserCompanyQueryHookResult = ReturnType<typeof useGetUserCompanyQuery>;
export type GetUserCompanyLazyQueryHookResult = ReturnType<typeof useGetUserCompanyLazyQuery>;
export type GetUserCompanyQueryResult = Apollo.QueryResult<GetUserCompanyQuery, GetUserCompanyQueryVariables>;
export const BulkUnitUpdateDocument = gql`
    mutation bulkUnitUpdate($unitIDs: [ID!]!, $all: Boolean!, $filter: UnitFilter!, $available: String) {
  bulkUnitUpdate(
    unitIDs: $unitIDs
    filter: $filter
    all: $all
    input: {available: $available}
  ) {
    ... on Unit {
      _id
      available
    }
  }
}
    `;
export type BulkUnitUpdateMutationFn = Apollo.MutationFunction<BulkUnitUpdateMutation, BulkUnitUpdateMutationVariables>;

/**
 * __useBulkUnitUpdateMutation__
 *
 * To run a mutation, you first call `useBulkUnitUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkUnitUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkUnitUpdateMutation, { data, loading, error }] = useBulkUnitUpdateMutation({
 *   variables: {
 *      unitIDs: // value for 'unitIDs'
 *      all: // value for 'all'
 *      filter: // value for 'filter'
 *      available: // value for 'available'
 *   },
 * });
 */
export function useBulkUnitUpdateMutation(baseOptions?: Apollo.MutationHookOptions<BulkUnitUpdateMutation, BulkUnitUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BulkUnitUpdateMutation, BulkUnitUpdateMutationVariables>(BulkUnitUpdateDocument, options);
      }
export type BulkUnitUpdateMutationHookResult = ReturnType<typeof useBulkUnitUpdateMutation>;
export type BulkUnitUpdateMutationResult = Apollo.MutationResult<BulkUnitUpdateMutation>;
export type BulkUnitUpdateMutationOptions = Apollo.BaseMutationOptions<BulkUnitUpdateMutation, BulkUnitUpdateMutationVariables>;
export const DeleteUnitDocument = gql`
    mutation deleteUnit($unitID: ID!) {
  unitDelete(unitID: $unitID) {
    deletedUnitID
  }
}
    `;
export type DeleteUnitMutationFn = Apollo.MutationFunction<DeleteUnitMutation, DeleteUnitMutationVariables>;

/**
 * __useDeleteUnitMutation__
 *
 * To run a mutation, you first call `useDeleteUnitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUnitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUnitMutation, { data, loading, error }] = useDeleteUnitMutation({
 *   variables: {
 *      unitID: // value for 'unitID'
 *   },
 * });
 */
export function useDeleteUnitMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUnitMutation, DeleteUnitMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteUnitMutation, DeleteUnitMutationVariables>(DeleteUnitDocument, options);
      }
export type DeleteUnitMutationHookResult = ReturnType<typeof useDeleteUnitMutation>;
export type DeleteUnitMutationResult = Apollo.MutationResult<DeleteUnitMutation>;
export type DeleteUnitMutationOptions = Apollo.BaseMutationOptions<DeleteUnitMutation, DeleteUnitMutationVariables>;
export const UnitDuplicateDocument = gql`
    mutation unitDuplicate($unitID: ID!, $name: String, $emails: [String!], $organisation: ID!, $use: UnitUse, $condition: UnitCondition, $area: Int) {
  unitDuplicate(
    unitID: $unitID
    name: $name
    emails: $emails
    organisation: $organisation
    use: $use
    condition: $condition
    area: $area
  ) {
    ... on Unit {
      _id
    }
  }
}
    `;
export type UnitDuplicateMutationFn = Apollo.MutationFunction<UnitDuplicateMutation, UnitDuplicateMutationVariables>;

/**
 * __useUnitDuplicateMutation__
 *
 * To run a mutation, you first call `useUnitDuplicateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnitDuplicateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unitDuplicateMutation, { data, loading, error }] = useUnitDuplicateMutation({
 *   variables: {
 *      unitID: // value for 'unitID'
 *      name: // value for 'name'
 *      emails: // value for 'emails'
 *      organisation: // value for 'organisation'
 *      use: // value for 'use'
 *      condition: // value for 'condition'
 *      area: // value for 'area'
 *   },
 * });
 */
export function useUnitDuplicateMutation(baseOptions?: Apollo.MutationHookOptions<UnitDuplicateMutation, UnitDuplicateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UnitDuplicateMutation, UnitDuplicateMutationVariables>(UnitDuplicateDocument, options);
      }
export type UnitDuplicateMutationHookResult = ReturnType<typeof useUnitDuplicateMutation>;
export type UnitDuplicateMutationResult = Apollo.MutationResult<UnitDuplicateMutation>;
export type UnitDuplicateMutationOptions = Apollo.BaseMutationOptions<UnitDuplicateMutation, UnitDuplicateMutationVariables>;
export const HideUnitDocument = gql`
    mutation HideUnit($unitID: ID!, $hideConnectedDeals: Boolean, $showHiddenDealsOnActive: Boolean) {
  unitHide(
    unitID: $unitID
    hideConnectedDeals: $hideConnectedDeals
    showHiddenDealsOnActive: $showHiddenDealsOnActive
  ) {
    _id
  }
}
    `;
export type HideUnitMutationFn = Apollo.MutationFunction<HideUnitMutation, HideUnitMutationVariables>;

/**
 * __useHideUnitMutation__
 *
 * To run a mutation, you first call `useHideUnitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useHideUnitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [hideUnitMutation, { data, loading, error }] = useHideUnitMutation({
 *   variables: {
 *      unitID: // value for 'unitID'
 *      hideConnectedDeals: // value for 'hideConnectedDeals'
 *      showHiddenDealsOnActive: // value for 'showHiddenDealsOnActive'
 *   },
 * });
 */
export function useHideUnitMutation(baseOptions?: Apollo.MutationHookOptions<HideUnitMutation, HideUnitMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<HideUnitMutation, HideUnitMutationVariables>(HideUnitDocument, options);
      }
export type HideUnitMutationHookResult = ReturnType<typeof useHideUnitMutation>;
export type HideUnitMutationResult = Apollo.MutationResult<HideUnitMutation>;
export type HideUnitMutationOptions = Apollo.BaseMutationOptions<HideUnitMutation, HideUnitMutationVariables>;
export const NewUnitDocument = gql`
    mutation newUnit($emails: [String!], $name: String!, $buildingName: String!, $area: Int!, $line1: String, $line2: String, $url: String, $city: String, $postCode: String, $use: UnitUse!, $straightToLease: Boolean, $condition: UnitCondition!, $organisation: String!) {
  unitCreate(
    emails: $emails
    input: {name: $name, area: $area, use: $use, url: $url, condition: $condition, buildingName: $buildingName, address: {line1: $line1, line2: $line2, city: $city, postCode: $postCode}, straightToLease: $straightToLease, organisation: $organisation}
  ) {
    ... on Unit {
      _id
      name
      url
      buildingName
      address {
        line1
      }
    }
  }
}
    `;
export type NewUnitMutationFn = Apollo.MutationFunction<NewUnitMutation, NewUnitMutationVariables>;

/**
 * __useNewUnitMutation__
 *
 * To run a mutation, you first call `useNewUnitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNewUnitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [newUnitMutation, { data, loading, error }] = useNewUnitMutation({
 *   variables: {
 *      emails: // value for 'emails'
 *      name: // value for 'name'
 *      buildingName: // value for 'buildingName'
 *      area: // value for 'area'
 *      line1: // value for 'line1'
 *      line2: // value for 'line2'
 *      url: // value for 'url'
 *      city: // value for 'city'
 *      postCode: // value for 'postCode'
 *      use: // value for 'use'
 *      straightToLease: // value for 'straightToLease'
 *      condition: // value for 'condition'
 *      organisation: // value for 'organisation'
 *   },
 * });
 */
export function useNewUnitMutation(baseOptions?: Apollo.MutationHookOptions<NewUnitMutation, NewUnitMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<NewUnitMutation, NewUnitMutationVariables>(NewUnitDocument, options);
      }
export type NewUnitMutationHookResult = ReturnType<typeof useNewUnitMutation>;
export type NewUnitMutationResult = Apollo.MutationResult<NewUnitMutation>;
export type NewUnitMutationOptions = Apollo.BaseMutationOptions<NewUnitMutation, NewUnitMutationVariables>;
export const UnhideUnitDocument = gql`
    mutation UnhideUnit($unitID: ID!, $unhideConnectedDeals: Boolean) {
  unitUnhide(unitID: $unitID, unhideConnectedDeals: $unhideConnectedDeals) {
    _id
  }
}
    `;
export type UnhideUnitMutationFn = Apollo.MutationFunction<UnhideUnitMutation, UnhideUnitMutationVariables>;

/**
 * __useUnhideUnitMutation__
 *
 * To run a mutation, you first call `useUnhideUnitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnhideUnitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unhideUnitMutation, { data, loading, error }] = useUnhideUnitMutation({
 *   variables: {
 *      unitID: // value for 'unitID'
 *      unhideConnectedDeals: // value for 'unhideConnectedDeals'
 *   },
 * });
 */
export function useUnhideUnitMutation(baseOptions?: Apollo.MutationHookOptions<UnhideUnitMutation, UnhideUnitMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UnhideUnitMutation, UnhideUnitMutationVariables>(UnhideUnitDocument, options);
      }
export type UnhideUnitMutationHookResult = ReturnType<typeof useUnhideUnitMutation>;
export type UnhideUnitMutationResult = Apollo.MutationResult<UnhideUnitMutation>;
export type UnhideUnitMutationOptions = Apollo.BaseMutationOptions<UnhideUnitMutation, UnhideUnitMutationVariables>;
export const GetAllUserTypeDocument = gql`
    query getAllUserType {
  user {
    _id
    type
    firstName
    lastName
    hasMaxPinnedDeals
    email
    mobile
    subscribers
    trustedOrganisations {
      _id
      name
      website
      address {
        line1
        line2
        city
        postCode
      }
      type
      avatar
    }
    organisation {
      _id
      name
      website
    }
  }
}
    `;

/**
 * __useGetAllUserTypeQuery__
 *
 * To run a query within a React component, call `useGetAllUserTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllUserTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllUserTypeQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllUserTypeQuery(baseOptions?: Apollo.QueryHookOptions<GetAllUserTypeQuery, GetAllUserTypeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllUserTypeQuery, GetAllUserTypeQueryVariables>(GetAllUserTypeDocument, options);
      }
export function useGetAllUserTypeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllUserTypeQuery, GetAllUserTypeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllUserTypeQuery, GetAllUserTypeQueryVariables>(GetAllUserTypeDocument, options);
        }
export type GetAllUserTypeQueryHookResult = ReturnType<typeof useGetAllUserTypeQuery>;
export type GetAllUserTypeLazyQueryHookResult = ReturnType<typeof useGetAllUserTypeLazyQuery>;
export type GetAllUserTypeQueryResult = Apollo.QueryResult<GetAllUserTypeQuery, GetAllUserTypeQueryVariables>;
export const GetBuildingsAutofillDetailsDocument = gql`
    query getBuildingsAutofillDetails {
  units {
    results {
      _id
      name
      use
      buildingName
      pending
      organisation {
        _id
        name
      }
      address {
        line1
        line2
        city
        postCode
      }
      pending
    }
    pageInfo {
      total
    }
  }
}
    `;

/**
 * __useGetBuildingsAutofillDetailsQuery__
 *
 * To run a query within a React component, call `useGetBuildingsAutofillDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBuildingsAutofillDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBuildingsAutofillDetailsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetBuildingsAutofillDetailsQuery(baseOptions?: Apollo.QueryHookOptions<GetBuildingsAutofillDetailsQuery, GetBuildingsAutofillDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBuildingsAutofillDetailsQuery, GetBuildingsAutofillDetailsQueryVariables>(GetBuildingsAutofillDetailsDocument, options);
      }
export function useGetBuildingsAutofillDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBuildingsAutofillDetailsQuery, GetBuildingsAutofillDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBuildingsAutofillDetailsQuery, GetBuildingsAutofillDetailsQueryVariables>(GetBuildingsAutofillDetailsDocument, options);
        }
export type GetBuildingsAutofillDetailsQueryHookResult = ReturnType<typeof useGetBuildingsAutofillDetailsQuery>;
export type GetBuildingsAutofillDetailsLazyQueryHookResult = ReturnType<typeof useGetBuildingsAutofillDetailsLazyQuery>;
export type GetBuildingsAutofillDetailsQueryResult = Apollo.QueryResult<GetBuildingsAutofillDetailsQuery, GetBuildingsAutofillDetailsQueryVariables>;
export const GetUnitCardDetailsDocument = gql`
    query getUnitCardDetails($query: String, $limit: Int, $offset: Int, $filter: UnitFilter, $sort: UnitSort) {
  units(
    query: $query
    limit: $limit
    offset: $offset
    filter: $filter
    sort: $sort
  ) {
    results {
      ...SingleUnitCard
    }
    pageInfo {
      total
      hasResults
      hasNextPage
    }
  }
}
    ${SingleUnitCardFragmentDoc}`;

/**
 * __useGetUnitCardDetailsQuery__
 *
 * To run a query within a React component, call `useGetUnitCardDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUnitCardDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUnitCardDetailsQuery({
 *   variables: {
 *      query: // value for 'query'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useGetUnitCardDetailsQuery(baseOptions?: Apollo.QueryHookOptions<GetUnitCardDetailsQuery, GetUnitCardDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUnitCardDetailsQuery, GetUnitCardDetailsQueryVariables>(GetUnitCardDetailsDocument, options);
      }
export function useGetUnitCardDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUnitCardDetailsQuery, GetUnitCardDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUnitCardDetailsQuery, GetUnitCardDetailsQueryVariables>(GetUnitCardDetailsDocument, options);
        }
export type GetUnitCardDetailsQueryHookResult = ReturnType<typeof useGetUnitCardDetailsQuery>;
export type GetUnitCardDetailsLazyQueryHookResult = ReturnType<typeof useGetUnitCardDetailsLazyQuery>;
export type GetUnitCardDetailsQueryResult = Apollo.QueryResult<GetUnitCardDetailsQuery, GetUnitCardDetailsQueryVariables>;
export const GetUnitBuildingNamesDocument = gql`
    query getUnitBuildingNames {
  units {
    results {
      buildingName
    }
    pageInfo {
      total
    }
  }
}
    `;

/**
 * __useGetUnitBuildingNamesQuery__
 *
 * To run a query within a React component, call `useGetUnitBuildingNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUnitBuildingNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUnitBuildingNamesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUnitBuildingNamesQuery(baseOptions?: Apollo.QueryHookOptions<GetUnitBuildingNamesQuery, GetUnitBuildingNamesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUnitBuildingNamesQuery, GetUnitBuildingNamesQueryVariables>(GetUnitBuildingNamesDocument, options);
      }
export function useGetUnitBuildingNamesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUnitBuildingNamesQuery, GetUnitBuildingNamesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUnitBuildingNamesQuery, GetUnitBuildingNamesQueryVariables>(GetUnitBuildingNamesDocument, options);
        }
export type GetUnitBuildingNamesQueryHookResult = ReturnType<typeof useGetUnitBuildingNamesQuery>;
export type GetUnitBuildingNamesLazyQueryHookResult = ReturnType<typeof useGetUnitBuildingNamesLazyQuery>;
export type GetUnitBuildingNamesQueryResult = Apollo.QueryResult<GetUnitBuildingNamesQuery, GetUnitBuildingNamesQueryVariables>;
export const GetUnitCitiesDocument = gql`
    query getUnitCities {
  units {
    results {
      address {
        city
      }
    }
    pageInfo {
      total
    }
  }
}
    `;

/**
 * __useGetUnitCitiesQuery__
 *
 * To run a query within a React component, call `useGetUnitCitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUnitCitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUnitCitiesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUnitCitiesQuery(baseOptions?: Apollo.QueryHookOptions<GetUnitCitiesQuery, GetUnitCitiesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUnitCitiesQuery, GetUnitCitiesQueryVariables>(GetUnitCitiesDocument, options);
      }
export function useGetUnitCitiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUnitCitiesQuery, GetUnitCitiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUnitCitiesQuery, GetUnitCitiesQueryVariables>(GetUnitCitiesDocument, options);
        }
export type GetUnitCitiesQueryHookResult = ReturnType<typeof useGetUnitCitiesQuery>;
export type GetUnitCitiesLazyQueryHookResult = ReturnType<typeof useGetUnitCitiesLazyQuery>;
export type GetUnitCitiesQueryResult = Apollo.QueryResult<GetUnitCitiesQuery, GetUnitCitiesQueryVariables>;
export const GetUnitOrganisationsDocument = gql`
    query getUnitOrganisations {
  units {
    results {
      organisation {
        _id
        name
      }
    }
    pageInfo {
      total
    }
  }
}
    `;

/**
 * __useGetUnitOrganisationsQuery__
 *
 * To run a query within a React component, call `useGetUnitOrganisationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUnitOrganisationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUnitOrganisationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUnitOrganisationsQuery(baseOptions?: Apollo.QueryHookOptions<GetUnitOrganisationsQuery, GetUnitOrganisationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUnitOrganisationsQuery, GetUnitOrganisationsQueryVariables>(GetUnitOrganisationsDocument, options);
      }
export function useGetUnitOrganisationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUnitOrganisationsQuery, GetUnitOrganisationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUnitOrganisationsQuery, GetUnitOrganisationsQueryVariables>(GetUnitOrganisationsDocument, options);
        }
export type GetUnitOrganisationsQueryHookResult = ReturnType<typeof useGetUnitOrganisationsQuery>;
export type GetUnitOrganisationsLazyQueryHookResult = ReturnType<typeof useGetUnitOrganisationsLazyQuery>;
export type GetUnitOrganisationsQueryResult = Apollo.QueryResult<GetUnitOrganisationsQuery, GetUnitOrganisationsQueryVariables>;
export const GetUnitCreatorDocument = gql`
    query getUnitCreator {
  units {
    results {
      creator {
        _id
        firstName
        lastName
      }
    }
    pageInfo {
      total
    }
  }
}
    `;

/**
 * __useGetUnitCreatorQuery__
 *
 * To run a query within a React component, call `useGetUnitCreatorQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUnitCreatorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUnitCreatorQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUnitCreatorQuery(baseOptions?: Apollo.QueryHookOptions<GetUnitCreatorQuery, GetUnitCreatorQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUnitCreatorQuery, GetUnitCreatorQueryVariables>(GetUnitCreatorDocument, options);
      }
export function useGetUnitCreatorLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUnitCreatorQuery, GetUnitCreatorQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUnitCreatorQuery, GetUnitCreatorQueryVariables>(GetUnitCreatorDocument, options);
        }
export type GetUnitCreatorQueryHookResult = ReturnType<typeof useGetUnitCreatorQuery>;
export type GetUnitCreatorLazyQueryHookResult = ReturnType<typeof useGetUnitCreatorLazyQuery>;
export type GetUnitCreatorQueryResult = Apollo.QueryResult<GetUnitCreatorQuery, GetUnitCreatorQueryVariables>;
export const ExportUnitsDocument = gql`
    query exportUnits {
  exportUnits {
    url
  }
}
    `;

/**
 * __useExportUnitsQuery__
 *
 * To run a query within a React component, call `useExportUnitsQuery` and pass it any options that fit your needs.
 * When your component renders, `useExportUnitsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExportUnitsQuery({
 *   variables: {
 *   },
 * });
 */
export function useExportUnitsQuery(baseOptions?: Apollo.QueryHookOptions<ExportUnitsQuery, ExportUnitsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExportUnitsQuery, ExportUnitsQueryVariables>(ExportUnitsDocument, options);
      }
export function useExportUnitsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExportUnitsQuery, ExportUnitsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExportUnitsQuery, ExportUnitsQueryVariables>(ExportUnitsDocument, options);
        }
export type ExportUnitsQueryHookResult = ReturnType<typeof useExportUnitsQuery>;
export type ExportUnitsLazyQueryHookResult = ReturnType<typeof useExportUnitsLazyQuery>;
export type ExportUnitsQueryResult = Apollo.QueryResult<ExportUnitsQuery, ExportUnitsQueryVariables>;
export const UnitFilterCountsDocument = gql`
    query UnitFilterCounts($query: String, $filter: UnitFilter) {
  unitFilterCounts(query: $query, filter: $filter) {
    hidden
    live
    availableToLet
    unavailableToLet
  }
}
    `;

/**
 * __useUnitFilterCountsQuery__
 *
 * To run a query within a React component, call `useUnitFilterCountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUnitFilterCountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUnitFilterCountsQuery({
 *   variables: {
 *      query: // value for 'query'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useUnitFilterCountsQuery(baseOptions?: Apollo.QueryHookOptions<UnitFilterCountsQuery, UnitFilterCountsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UnitFilterCountsQuery, UnitFilterCountsQueryVariables>(UnitFilterCountsDocument, options);
      }
export function useUnitFilterCountsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UnitFilterCountsQuery, UnitFilterCountsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UnitFilterCountsQuery, UnitFilterCountsQueryVariables>(UnitFilterCountsDocument, options);
        }
export type UnitFilterCountsQueryHookResult = ReturnType<typeof useUnitFilterCountsQuery>;
export type UnitFilterCountsLazyQueryHookResult = ReturnType<typeof useUnitFilterCountsLazyQuery>;
export type UnitFilterCountsQueryResult = Apollo.QueryResult<UnitFilterCountsQuery, UnitFilterCountsQueryVariables>;
export const GetUserTypeDocument = gql`
    query getUserType {
  user {
    _id
    type
    firstName
    lastName
    hasMaxPinnedDeals
    email
    mobile
    subscribers
    isTenantSide
    hiddenDeals {
      _id
    }
    hiddenTemplates
    hiddenUnits {
      _id
    }
    trustedOrganisations(types: [ADMIN, LANDLORD]) {
      _id
      name
      website
      address {
        line1
        line2
        city
        postCode
      }
      type
      avatar
      active
    }
    organisation {
      _id
      name
      website
    }
  }
}
    `;

/**
 * __useGetUserTypeQuery__
 *
 * To run a query within a React component, call `useGetUserTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserTypeQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserTypeQuery(baseOptions?: Apollo.QueryHookOptions<GetUserTypeQuery, GetUserTypeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserTypeQuery, GetUserTypeQueryVariables>(GetUserTypeDocument, options);
      }
export function useGetUserTypeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserTypeQuery, GetUserTypeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserTypeQuery, GetUserTypeQueryVariables>(GetUserTypeDocument, options);
        }
export type GetUserTypeQueryHookResult = ReturnType<typeof useGetUserTypeQuery>;
export type GetUserTypeLazyQueryHookResult = ReturnType<typeof useGetUserTypeLazyQuery>;
export type GetUserTypeQueryResult = Apollo.QueryResult<GetUserTypeQuery, GetUserTypeQueryVariables>;
export const SubscribeEndpointDocument = gql`
    mutation subscribeEndpoint($endpoint: String!) {
  userSubscribe(endpoint: $endpoint) {
    ... on User {
      _id
      subscribers
    }
  }
}
    `;
export type SubscribeEndpointMutationFn = Apollo.MutationFunction<SubscribeEndpointMutation, SubscribeEndpointMutationVariables>;

/**
 * __useSubscribeEndpointMutation__
 *
 * To run a mutation, you first call `useSubscribeEndpointMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubscribeEndpointMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [subscribeEndpointMutation, { data, loading, error }] = useSubscribeEndpointMutation({
 *   variables: {
 *      endpoint: // value for 'endpoint'
 *   },
 * });
 */
export function useSubscribeEndpointMutation(baseOptions?: Apollo.MutationHookOptions<SubscribeEndpointMutation, SubscribeEndpointMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SubscribeEndpointMutation, SubscribeEndpointMutationVariables>(SubscribeEndpointDocument, options);
      }
export type SubscribeEndpointMutationHookResult = ReturnType<typeof useSubscribeEndpointMutation>;
export type SubscribeEndpointMutationResult = Apollo.MutationResult<SubscribeEndpointMutation>;
export type SubscribeEndpointMutationOptions = Apollo.BaseMutationOptions<SubscribeEndpointMutation, SubscribeEndpointMutationVariables>;
export const AddClauseDocument = gql`
    mutation addClause($dealID: ID!, $parentID: ID!, $index: Int!, $value: String!, $valueSuffix: String!, $shortForm: String!, $longForm: String!) {
  dealClauseCreate(
    input: {dealID: $dealID, parentID: $parentID, index: $index, value: $value, valueSuffix: $valueSuffix, shortForm: $shortForm, longForm: $longForm, visible: true}
  ) {
    ... on Clause {
      _id
      index
      value
      valueSuffix
      shortForm
      longForm
    }
  }
}
    `;
export type AddClauseMutationFn = Apollo.MutationFunction<AddClauseMutation, AddClauseMutationVariables>;

/**
 * __useAddClauseMutation__
 *
 * To run a mutation, you first call `useAddClauseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddClauseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addClauseMutation, { data, loading, error }] = useAddClauseMutation({
 *   variables: {
 *      dealID: // value for 'dealID'
 *      parentID: // value for 'parentID'
 *      index: // value for 'index'
 *      value: // value for 'value'
 *      valueSuffix: // value for 'valueSuffix'
 *      shortForm: // value for 'shortForm'
 *      longForm: // value for 'longForm'
 *   },
 * });
 */
export function useAddClauseMutation(baseOptions?: Apollo.MutationHookOptions<AddClauseMutation, AddClauseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddClauseMutation, AddClauseMutationVariables>(AddClauseDocument, options);
      }
export type AddClauseMutationHookResult = ReturnType<typeof useAddClauseMutation>;
export type AddClauseMutationResult = Apollo.MutationResult<AddClauseMutation>;
export type AddClauseMutationOptions = Apollo.BaseMutationOptions<AddClauseMutation, AddClauseMutationVariables>;
export const NewGroupDocument = gql`
    mutation newGroup($dealID: ID!, $parentID: ID!, $title: String!, $index: Int!) {
  dealGroupCreate(
    input: {dealID: $dealID, parentID: $parentID, title: $title, index: $index}
  ) {
    ... on Group {
      _id
      title
    }
  }
}
    `;
export type NewGroupMutationFn = Apollo.MutationFunction<NewGroupMutation, NewGroupMutationVariables>;

/**
 * __useNewGroupMutation__
 *
 * To run a mutation, you first call `useNewGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNewGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [newGroupMutation, { data, loading, error }] = useNewGroupMutation({
 *   variables: {
 *      dealID: // value for 'dealID'
 *      parentID: // value for 'parentID'
 *      title: // value for 'title'
 *      index: // value for 'index'
 *   },
 * });
 */
export function useNewGroupMutation(baseOptions?: Apollo.MutationHookOptions<NewGroupMutation, NewGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<NewGroupMutation, NewGroupMutationVariables>(NewGroupDocument, options);
      }
export type NewGroupMutationHookResult = ReturnType<typeof useNewGroupMutation>;
export type NewGroupMutationResult = Apollo.MutationResult<NewGroupMutation>;
export type NewGroupMutationOptions = Apollo.BaseMutationOptions<NewGroupMutation, NewGroupMutationVariables>;
export const NewInvisbleClauseDocument = gql`
    mutation newInvisbleClause($dealID: ID!, $parentID: ID!, $index: Int!) {
  dealClauseCreate(
    input: {dealID: $dealID, parentID: $parentID, index: $index, visible: false, value: "hidden"}
  ) {
    ... on Clause {
      _id
      index
      value
      visible
    }
  }
}
    `;
export type NewInvisbleClauseMutationFn = Apollo.MutationFunction<NewInvisbleClauseMutation, NewInvisbleClauseMutationVariables>;

/**
 * __useNewInvisbleClauseMutation__
 *
 * To run a mutation, you first call `useNewInvisbleClauseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNewInvisbleClauseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [newInvisbleClauseMutation, { data, loading, error }] = useNewInvisbleClauseMutation({
 *   variables: {
 *      dealID: // value for 'dealID'
 *      parentID: // value for 'parentID'
 *      index: // value for 'index'
 *   },
 * });
 */
export function useNewInvisbleClauseMutation(baseOptions?: Apollo.MutationHookOptions<NewInvisbleClauseMutation, NewInvisbleClauseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<NewInvisbleClauseMutation, NewInvisbleClauseMutationVariables>(NewInvisbleClauseDocument, options);
      }
export type NewInvisbleClauseMutationHookResult = ReturnType<typeof useNewInvisbleClauseMutation>;
export type NewInvisbleClauseMutationResult = Apollo.MutationResult<NewInvisbleClauseMutation>;
export type NewInvisbleClauseMutationOptions = Apollo.BaseMutationOptions<NewInvisbleClauseMutation, NewInvisbleClauseMutationVariables>;
export const NewSectionDocument = gql`
    mutation newSection($dealID: ID!, $title: String!, $index: Int!) {
  dealSectionCreate(input: {dealID: $dealID, title: $title, index: $index}) {
    ... on Section {
      _id
      title
    }
  }
}
    `;
export type NewSectionMutationFn = Apollo.MutationFunction<NewSectionMutation, NewSectionMutationVariables>;

/**
 * __useNewSectionMutation__
 *
 * To run a mutation, you first call `useNewSectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNewSectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [newSectionMutation, { data, loading, error }] = useNewSectionMutation({
 *   variables: {
 *      dealID: // value for 'dealID'
 *      title: // value for 'title'
 *      index: // value for 'index'
 *   },
 * });
 */
export function useNewSectionMutation(baseOptions?: Apollo.MutationHookOptions<NewSectionMutation, NewSectionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<NewSectionMutation, NewSectionMutationVariables>(NewSectionDocument, options);
      }
export type NewSectionMutationHookResult = ReturnType<typeof useNewSectionMutation>;
export type NewSectionMutationResult = Apollo.MutationResult<NewSectionMutation>;
export type NewSectionMutationOptions = Apollo.BaseMutationOptions<NewSectionMutation, NewSectionMutationVariables>;
export const NewSectionGroupClauseDocument = gql`
    mutation newSectionGroupClause($dealID: ID!, $sectionTitle: String!, $sectionDescription: String, $sectionRics: String, $sectionIndex: Int, $groupTitle: String!, $groupDescription: String, $groupRics: String, $groupIndex: Int, $clauseIndex: Int!, $value: String, $valueSuffix: String, $shortForm: String, $longForm: String, $tags: [String!], $linkedClauses: [LinkedClauseInput!], $clauseRef: ID) {
  dealSectionGroupClauseCreate(
    input: {dealID: $dealID, section: {title: $sectionTitle, index: $sectionIndex, description: $sectionDescription, rics: $sectionRics}, group: {title: $groupTitle, index: $groupIndex, description: $groupDescription, rics: $groupRics}, clause: {index: $clauseIndex, value: $value, valueSuffix: $valueSuffix, shortForm: $shortForm, longForm: $longForm, tags: $tags, linked: $linkedClauses, clauseRef: $clauseRef}}
  ) {
    ... on Clause {
      _id
      index
      value
      valueSuffix
      shortForm
      longForm
    }
  }
}
    `;
export type NewSectionGroupClauseMutationFn = Apollo.MutationFunction<NewSectionGroupClauseMutation, NewSectionGroupClauseMutationVariables>;

/**
 * __useNewSectionGroupClauseMutation__
 *
 * To run a mutation, you first call `useNewSectionGroupClauseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNewSectionGroupClauseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [newSectionGroupClauseMutation, { data, loading, error }] = useNewSectionGroupClauseMutation({
 *   variables: {
 *      dealID: // value for 'dealID'
 *      sectionTitle: // value for 'sectionTitle'
 *      sectionDescription: // value for 'sectionDescription'
 *      sectionRics: // value for 'sectionRics'
 *      sectionIndex: // value for 'sectionIndex'
 *      groupTitle: // value for 'groupTitle'
 *      groupDescription: // value for 'groupDescription'
 *      groupRics: // value for 'groupRics'
 *      groupIndex: // value for 'groupIndex'
 *      clauseIndex: // value for 'clauseIndex'
 *      value: // value for 'value'
 *      valueSuffix: // value for 'valueSuffix'
 *      shortForm: // value for 'shortForm'
 *      longForm: // value for 'longForm'
 *      tags: // value for 'tags'
 *      linkedClauses: // value for 'linkedClauses'
 *      clauseRef: // value for 'clauseRef'
 *   },
 * });
 */
export function useNewSectionGroupClauseMutation(baseOptions?: Apollo.MutationHookOptions<NewSectionGroupClauseMutation, NewSectionGroupClauseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<NewSectionGroupClauseMutation, NewSectionGroupClauseMutationVariables>(NewSectionGroupClauseDocument, options);
      }
export type NewSectionGroupClauseMutationHookResult = ReturnType<typeof useNewSectionGroupClauseMutation>;
export type NewSectionGroupClauseMutationResult = Apollo.MutationResult<NewSectionGroupClauseMutation>;
export type NewSectionGroupClauseMutationOptions = Apollo.BaseMutationOptions<NewSectionGroupClauseMutation, NewSectionGroupClauseMutationVariables>;
export const NewSectionGroupGroupClauseDocument = gql`
    mutation newSectionGroupGroupClause($dealID: ID!, $sectionTitle: String!, $sectionIndex: Int, $sectionDescription: String, $sectionRics: String, $groupDescription: String, $groupRics: String, $groupTitle: String!, $groupIndex: Int, $clauseGroupTitle: String!, $clauseGroupIndex: Int, $clauseIndex: Int!, $value: String, $valueSuffix: String, $shortForm: String, $longForm: String, $tags: [String!], $linked: [LinkedClauseInput!], $clauseRef: ID) {
  dealSectionGroupClauseCreate(
    input: {dealID: $dealID, section: {title: $sectionTitle, index: $sectionIndex, description: $sectionDescription, rics: $sectionRics}, group: {title: $groupTitle, index: $groupIndex, description: $groupDescription, rics: $groupRics}, clauseGroup: {title: $clauseGroupTitle, index: $clauseGroupIndex}, clause: {index: $clauseIndex, value: $value, valueSuffix: $valueSuffix, shortForm: $shortForm, longForm: $longForm, tags: $tags, linked: $linked, clauseRef: $clauseRef}}
  ) {
    ... on Clause {
      _id
      index
      value
      valueSuffix
      shortForm
      longForm
    }
  }
}
    `;
export type NewSectionGroupGroupClauseMutationFn = Apollo.MutationFunction<NewSectionGroupGroupClauseMutation, NewSectionGroupGroupClauseMutationVariables>;

/**
 * __useNewSectionGroupGroupClauseMutation__
 *
 * To run a mutation, you first call `useNewSectionGroupGroupClauseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNewSectionGroupGroupClauseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [newSectionGroupGroupClauseMutation, { data, loading, error }] = useNewSectionGroupGroupClauseMutation({
 *   variables: {
 *      dealID: // value for 'dealID'
 *      sectionTitle: // value for 'sectionTitle'
 *      sectionIndex: // value for 'sectionIndex'
 *      sectionDescription: // value for 'sectionDescription'
 *      sectionRics: // value for 'sectionRics'
 *      groupDescription: // value for 'groupDescription'
 *      groupRics: // value for 'groupRics'
 *      groupTitle: // value for 'groupTitle'
 *      groupIndex: // value for 'groupIndex'
 *      clauseGroupTitle: // value for 'clauseGroupTitle'
 *      clauseGroupIndex: // value for 'clauseGroupIndex'
 *      clauseIndex: // value for 'clauseIndex'
 *      value: // value for 'value'
 *      valueSuffix: // value for 'valueSuffix'
 *      shortForm: // value for 'shortForm'
 *      longForm: // value for 'longForm'
 *      tags: // value for 'tags'
 *      linked: // value for 'linked'
 *      clauseRef: // value for 'clauseRef'
 *   },
 * });
 */
export function useNewSectionGroupGroupClauseMutation(baseOptions?: Apollo.MutationHookOptions<NewSectionGroupGroupClauseMutation, NewSectionGroupGroupClauseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<NewSectionGroupGroupClauseMutation, NewSectionGroupGroupClauseMutationVariables>(NewSectionGroupGroupClauseDocument, options);
      }
export type NewSectionGroupGroupClauseMutationHookResult = ReturnType<typeof useNewSectionGroupGroupClauseMutation>;
export type NewSectionGroupGroupClauseMutationResult = Apollo.MutationResult<NewSectionGroupGroupClauseMutation>;
export type NewSectionGroupGroupClauseMutationOptions = Apollo.BaseMutationOptions<NewSectionGroupGroupClauseMutation, NewSectionGroupGroupClauseMutationVariables>;
export const ClauseSectionsDocument = gql`
    query clauseSections($organisationID: [ID!]) {
  clauseBank(organisationID: $organisationID) {
    ...SingleTemplateClause
  }
}
    ${SingleTemplateClauseFragmentDoc}`;

/**
 * __useClauseSectionsQuery__
 *
 * To run a query within a React component, call `useClauseSectionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useClauseSectionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClauseSectionsQuery({
 *   variables: {
 *      organisationID: // value for 'organisationID'
 *   },
 * });
 */
export function useClauseSectionsQuery(baseOptions?: Apollo.QueryHookOptions<ClauseSectionsQuery, ClauseSectionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ClauseSectionsQuery, ClauseSectionsQueryVariables>(ClauseSectionsDocument, options);
      }
export function useClauseSectionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ClauseSectionsQuery, ClauseSectionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ClauseSectionsQuery, ClauseSectionsQueryVariables>(ClauseSectionsDocument, options);
        }
export type ClauseSectionsQueryHookResult = ReturnType<typeof useClauseSectionsQuery>;
export type ClauseSectionsLazyQueryHookResult = ReturnType<typeof useClauseSectionsLazyQuery>;
export type ClauseSectionsQueryResult = Apollo.QueryResult<ClauseSectionsQuery, ClauseSectionsQueryVariables>;
export const GetOrganisationClausesDocument = gql`
    query getOrganisationClauses {
  user {
    ... on User {
      organisation {
        _id
        name
        clauses {
          _id
          value
          valueSuffix
          shortForm
          longForm
        }
      }
      associated {
        _id
        name
        clauses {
          _id
          value
          valueSuffix
          shortForm
          longForm
        }
      }
    }
  }
}
    `;

/**
 * __useGetOrganisationClausesQuery__
 *
 * To run a query within a React component, call `useGetOrganisationClausesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrganisationClausesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrganisationClausesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetOrganisationClausesQuery(baseOptions?: Apollo.QueryHookOptions<GetOrganisationClausesQuery, GetOrganisationClausesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOrganisationClausesQuery, GetOrganisationClausesQueryVariables>(GetOrganisationClausesDocument, options);
      }
export function useGetOrganisationClausesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrganisationClausesQuery, GetOrganisationClausesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOrganisationClausesQuery, GetOrganisationClausesQueryVariables>(GetOrganisationClausesDocument, options);
        }
export type GetOrganisationClausesQueryHookResult = ReturnType<typeof useGetOrganisationClausesQuery>;
export type GetOrganisationClausesLazyQueryHookResult = ReturnType<typeof useGetOrganisationClausesLazyQuery>;
export type GetOrganisationClausesQueryResult = Apollo.QueryResult<GetOrganisationClausesQuery, GetOrganisationClausesQueryVariables>;
export const SearchClauseBankDocument = gql`
    query searchClauseBank($organisationID: [ID!], $query: String, $only: Boolean, $options: ClauseBankOptions, $filter: TemplateFilter) {
  clauseBank(
    organisationID: $organisationID
    query: $query
    only: $only
    options: $options
    filter: $filter
  ) {
    ...SingleTemplateClause
  }
}
    ${SingleTemplateClauseFragmentDoc}`;

/**
 * __useSearchClauseBankQuery__
 *
 * To run a query within a React component, call `useSearchClauseBankQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchClauseBankQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchClauseBankQuery({
 *   variables: {
 *      organisationID: // value for 'organisationID'
 *      query: // value for 'query'
 *      only: // value for 'only'
 *      options: // value for 'options'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useSearchClauseBankQuery(baseOptions?: Apollo.QueryHookOptions<SearchClauseBankQuery, SearchClauseBankQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchClauseBankQuery, SearchClauseBankQueryVariables>(SearchClauseBankDocument, options);
      }
export function useSearchClauseBankLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchClauseBankQuery, SearchClauseBankQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchClauseBankQuery, SearchClauseBankQueryVariables>(SearchClauseBankDocument, options);
        }
export type SearchClauseBankQueryHookResult = ReturnType<typeof useSearchClauseBankQuery>;
export type SearchClauseBankLazyQueryHookResult = ReturnType<typeof useSearchClauseBankLazyQuery>;
export type SearchClauseBankQueryResult = Apollo.QueryResult<SearchClauseBankQuery, SearchClauseBankQueryVariables>;
export const DeleteClauseDocument = gql`
    mutation deleteClause($dealID: ID!, $parentID: ID!, $clauseID: ID!) {
  dealClauseDelete(dealID: $dealID, parentID: $parentID, clauseID: $clauseID) {
    ... on DeletedObject {
      deletedObjectID
    }
  }
}
    `;
export type DeleteClauseMutationFn = Apollo.MutationFunction<DeleteClauseMutation, DeleteClauseMutationVariables>;

/**
 * __useDeleteClauseMutation__
 *
 * To run a mutation, you first call `useDeleteClauseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteClauseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteClauseMutation, { data, loading, error }] = useDeleteClauseMutation({
 *   variables: {
 *      dealID: // value for 'dealID'
 *      parentID: // value for 'parentID'
 *      clauseID: // value for 'clauseID'
 *   },
 * });
 */
export function useDeleteClauseMutation(baseOptions?: Apollo.MutationHookOptions<DeleteClauseMutation, DeleteClauseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteClauseMutation, DeleteClauseMutationVariables>(DeleteClauseDocument, options);
      }
export type DeleteClauseMutationHookResult = ReturnType<typeof useDeleteClauseMutation>;
export type DeleteClauseMutationResult = Apollo.MutationResult<DeleteClauseMutation>;
export type DeleteClauseMutationOptions = Apollo.BaseMutationOptions<DeleteClauseMutation, DeleteClauseMutationVariables>;
export const DeleteGroupDocument = gql`
    mutation deleteGroup($dealID: ID!, $parentID: ID!, $groupID: ID!) {
  dealGroupDelete(dealID: $dealID, parentID: $parentID, groupID: $groupID) {
    ... on DeletedObject {
      deletedObjectID
    }
  }
}
    `;
export type DeleteGroupMutationFn = Apollo.MutationFunction<DeleteGroupMutation, DeleteGroupMutationVariables>;

/**
 * __useDeleteGroupMutation__
 *
 * To run a mutation, you first call `useDeleteGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteGroupMutation, { data, loading, error }] = useDeleteGroupMutation({
 *   variables: {
 *      dealID: // value for 'dealID'
 *      parentID: // value for 'parentID'
 *      groupID: // value for 'groupID'
 *   },
 * });
 */
export function useDeleteGroupMutation(baseOptions?: Apollo.MutationHookOptions<DeleteGroupMutation, DeleteGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteGroupMutation, DeleteGroupMutationVariables>(DeleteGroupDocument, options);
      }
export type DeleteGroupMutationHookResult = ReturnType<typeof useDeleteGroupMutation>;
export type DeleteGroupMutationResult = Apollo.MutationResult<DeleteGroupMutation>;
export type DeleteGroupMutationOptions = Apollo.BaseMutationOptions<DeleteGroupMutation, DeleteGroupMutationVariables>;
export const SetDealBreakerDocument = gql`
    mutation setDealBreaker($id: ID!, $dealID: ID!, $parentID: ID!, $dealBreaker: Boolean!, $message: String) {
  dealClauseUpdate(
    input: {dealBreaker: {isDealBreaker: $dealBreaker, message: $message}, dealID: $dealID, parentID: $parentID, clauseID: $id}
  ) {
    ... on Clause {
      _id
      dealBreaker {
        isDealBreaker
        message
      }
    }
  }
}
    `;
export type SetDealBreakerMutationFn = Apollo.MutationFunction<SetDealBreakerMutation, SetDealBreakerMutationVariables>;

/**
 * __useSetDealBreakerMutation__
 *
 * To run a mutation, you first call `useSetDealBreakerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetDealBreakerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setDealBreakerMutation, { data, loading, error }] = useSetDealBreakerMutation({
 *   variables: {
 *      id: // value for 'id'
 *      dealID: // value for 'dealID'
 *      parentID: // value for 'parentID'
 *      dealBreaker: // value for 'dealBreaker'
 *      message: // value for 'message'
 *   },
 * });
 */
export function useSetDealBreakerMutation(baseOptions?: Apollo.MutationHookOptions<SetDealBreakerMutation, SetDealBreakerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetDealBreakerMutation, SetDealBreakerMutationVariables>(SetDealBreakerDocument, options);
      }
export type SetDealBreakerMutationHookResult = ReturnType<typeof useSetDealBreakerMutation>;
export type SetDealBreakerMutationResult = Apollo.MutationResult<SetDealBreakerMutation>;
export type SetDealBreakerMutationOptions = Apollo.BaseMutationOptions<SetDealBreakerMutation, SetDealBreakerMutationVariables>;
export const NewClauseDocument = gql`
    mutation newClause($organisationID: ID!, $value: String, $valueSuffix: String, $shortForm: String, $longForm: String, $section: String!, $level1Group: String!, $level2Group: String, $linked: LinkClauseTemplateInput, $associatedUses: [UnitUse!]) {
  organisationClauseAdd(
    organisationID: $organisationID
    input: {value: $value, valueSuffix: $valueSuffix, shortForm: $shortForm, longForm: $longForm, section: $section, level1Group: $level1Group, level2Group: $level2Group, linked: $linked, associatedUses: $associatedUses}
  ) {
    _id
    value
    valueSuffix
    associatedUses
    section {
      title
      group {
        title
        group {
          title
        }
      }
    }
    linked {
      results {
        _id
        value
        valueSuffix
        shortForm
        longForm
      }
    }
  }
}
    `;
export type NewClauseMutationFn = Apollo.MutationFunction<NewClauseMutation, NewClauseMutationVariables>;

/**
 * __useNewClauseMutation__
 *
 * To run a mutation, you first call `useNewClauseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNewClauseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [newClauseMutation, { data, loading, error }] = useNewClauseMutation({
 *   variables: {
 *      organisationID: // value for 'organisationID'
 *      value: // value for 'value'
 *      valueSuffix: // value for 'valueSuffix'
 *      shortForm: // value for 'shortForm'
 *      longForm: // value for 'longForm'
 *      section: // value for 'section'
 *      level1Group: // value for 'level1Group'
 *      level2Group: // value for 'level2Group'
 *      linked: // value for 'linked'
 *      associatedUses: // value for 'associatedUses'
 *   },
 * });
 */
export function useNewClauseMutation(baseOptions?: Apollo.MutationHookOptions<NewClauseMutation, NewClauseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<NewClauseMutation, NewClauseMutationVariables>(NewClauseDocument, options);
      }
export type NewClauseMutationHookResult = ReturnType<typeof useNewClauseMutation>;
export type NewClauseMutationResult = Apollo.MutationResult<NewClauseMutation>;
export type NewClauseMutationOptions = Apollo.BaseMutationOptions<NewClauseMutation, NewClauseMutationVariables>;
export const GetDealClauseGroupsDocument = gql`
    query getDealClauseGroups($dealID: ID!) {
  deal(id: $dealID) {
    ... on Deal {
      _id
      sections {
        _id
        title
        groups {
          _id
          title
          clauses {
            _id
            visible
            groups {
              _id
              title
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetDealClauseGroupsQuery__
 *
 * To run a query within a React component, call `useGetDealClauseGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDealClauseGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDealClauseGroupsQuery({
 *   variables: {
 *      dealID: // value for 'dealID'
 *   },
 * });
 */
export function useGetDealClauseGroupsQuery(baseOptions: Apollo.QueryHookOptions<GetDealClauseGroupsQuery, GetDealClauseGroupsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDealClauseGroupsQuery, GetDealClauseGroupsQueryVariables>(GetDealClauseGroupsDocument, options);
      }
export function useGetDealClauseGroupsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDealClauseGroupsQuery, GetDealClauseGroupsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDealClauseGroupsQuery, GetDealClauseGroupsQueryVariables>(GetDealClauseGroupsDocument, options);
        }
export type GetDealClauseGroupsQueryHookResult = ReturnType<typeof useGetDealClauseGroupsQuery>;
export type GetDealClauseGroupsLazyQueryHookResult = ReturnType<typeof useGetDealClauseGroupsLazyQuery>;
export type GetDealClauseGroupsQueryResult = Apollo.QueryResult<GetDealClauseGroupsQuery, GetDealClauseGroupsQueryVariables>;
export const SwapClauseDocument = gql`
    mutation swapClause($dealID: ID!, $parentID: ID!, $clauseID: ID!, $value: String, $valueSuffix: String, $shortForm: String, $longForm: String, $tags: [String!]) {
  dealClauseUpdate(
    input: {dealID: $dealID, parentID: $parentID, clauseID: $clauseID, value: $value, valueSuffix: $valueSuffix, shortForm: $shortForm, longForm: $longForm, swapping: true, tags: $tags}
  ) {
    ... on Clause {
      _id
      value
      valueSuffix
      shortForm
      longForm
      tags
      approved
      rejected
      history {
        _id
        type
        actor {
          _id
          firstName
          lastName
        }
        date
        change {
          new {
            value
            valueSuffix
            shortForm
            longForm
            approved
            rejected
          }
          old {
            value
            valueSuffix
            shortForm
            longForm
            approved
            rejected
          }
        }
      }
    }
  }
}
    `;
export type SwapClauseMutationFn = Apollo.MutationFunction<SwapClauseMutation, SwapClauseMutationVariables>;

/**
 * __useSwapClauseMutation__
 *
 * To run a mutation, you first call `useSwapClauseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSwapClauseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [swapClauseMutation, { data, loading, error }] = useSwapClauseMutation({
 *   variables: {
 *      dealID: // value for 'dealID'
 *      parentID: // value for 'parentID'
 *      clauseID: // value for 'clauseID'
 *      value: // value for 'value'
 *      valueSuffix: // value for 'valueSuffix'
 *      shortForm: // value for 'shortForm'
 *      longForm: // value for 'longForm'
 *      tags: // value for 'tags'
 *   },
 * });
 */
export function useSwapClauseMutation(baseOptions?: Apollo.MutationHookOptions<SwapClauseMutation, SwapClauseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SwapClauseMutation, SwapClauseMutationVariables>(SwapClauseDocument, options);
      }
export type SwapClauseMutationHookResult = ReturnType<typeof useSwapClauseMutation>;
export type SwapClauseMutationResult = Apollo.MutationResult<SwapClauseMutation>;
export type SwapClauseMutationOptions = Apollo.BaseMutationOptions<SwapClauseMutation, SwapClauseMutationVariables>;
export const DeleteSectionDocument = gql`
    mutation deleteSection($dealID: ID!, $sectionID: ID!) {
  dealSectionDelete(dealID: $dealID, sectionID: $sectionID) {
    ... on DeletedObject {
      deletedObjectID
    }
  }
}
    `;
export type DeleteSectionMutationFn = Apollo.MutationFunction<DeleteSectionMutation, DeleteSectionMutationVariables>;

/**
 * __useDeleteSectionMutation__
 *
 * To run a mutation, you first call `useDeleteSectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSectionMutation, { data, loading, error }] = useDeleteSectionMutation({
 *   variables: {
 *      dealID: // value for 'dealID'
 *      sectionID: // value for 'sectionID'
 *   },
 * });
 */
export function useDeleteSectionMutation(baseOptions?: Apollo.MutationHookOptions<DeleteSectionMutation, DeleteSectionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteSectionMutation, DeleteSectionMutationVariables>(DeleteSectionDocument, options);
      }
export type DeleteSectionMutationHookResult = ReturnType<typeof useDeleteSectionMutation>;
export type DeleteSectionMutationResult = Apollo.MutationResult<DeleteSectionMutation>;
export type DeleteSectionMutationOptions = Apollo.BaseMutationOptions<DeleteSectionMutation, DeleteSectionMutationVariables>;
export const MoveClauseDocument = gql`
    mutation moveClause($dealID: ID!, $parentID: ID!, $clauseID: ID!, $indexFrom: Int!, $indexTo: Int!) {
  dealClauseUpdate(
    input: {dealID: $dealID, parentID: $parentID, clauseID: $clauseID, index: {from: $indexFrom, to: $indexTo}}
  ) {
    ... on Clause {
      _id
      index
    }
  }
}
    `;
export type MoveClauseMutationFn = Apollo.MutationFunction<MoveClauseMutation, MoveClauseMutationVariables>;

/**
 * __useMoveClauseMutation__
 *
 * To run a mutation, you first call `useMoveClauseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMoveClauseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [moveClauseMutation, { data, loading, error }] = useMoveClauseMutation({
 *   variables: {
 *      dealID: // value for 'dealID'
 *      parentID: // value for 'parentID'
 *      clauseID: // value for 'clauseID'
 *      indexFrom: // value for 'indexFrom'
 *      indexTo: // value for 'indexTo'
 *   },
 * });
 */
export function useMoveClauseMutation(baseOptions?: Apollo.MutationHookOptions<MoveClauseMutation, MoveClauseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MoveClauseMutation, MoveClauseMutationVariables>(MoveClauseDocument, options);
      }
export type MoveClauseMutationHookResult = ReturnType<typeof useMoveClauseMutation>;
export type MoveClauseMutationResult = Apollo.MutationResult<MoveClauseMutation>;
export type MoveClauseMutationOptions = Apollo.BaseMutationOptions<MoveClauseMutation, MoveClauseMutationVariables>;
export const UpdateClauseDocument = gql`
    mutation updateClause($dealID: ID!, $parentID: ID!, $clauseID: ID!, $value: String, $valueSuffix: String, $shortForm: String, $longForm: String) {
  dealClauseUpdate(
    input: {dealID: $dealID, parentID: $parentID, clauseID: $clauseID, value: $value, valueSuffix: $valueSuffix, shortForm: $shortForm, longForm: $longForm}
  ) {
    ... on Clause {
      _id
      parentID
      value
      valueSuffix
      shortForm
      longForm
      approved
      rejected
      history {
        _id
        type
        actor {
          _id
          firstName
          lastName
        }
        date
        change {
          new {
            value
            valueSuffix
            shortForm
            longForm
            approved
            rejected
          }
          old {
            value
            valueSuffix
            shortForm
            longForm
            approved
            rejected
          }
        }
      }
    }
  }
}
    `;
export type UpdateClauseMutationFn = Apollo.MutationFunction<UpdateClauseMutation, UpdateClauseMutationVariables>;

/**
 * __useUpdateClauseMutation__
 *
 * To run a mutation, you first call `useUpdateClauseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateClauseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateClauseMutation, { data, loading, error }] = useUpdateClauseMutation({
 *   variables: {
 *      dealID: // value for 'dealID'
 *      parentID: // value for 'parentID'
 *      clauseID: // value for 'clauseID'
 *      value: // value for 'value'
 *      valueSuffix: // value for 'valueSuffix'
 *      shortForm: // value for 'shortForm'
 *      longForm: // value for 'longForm'
 *   },
 * });
 */
export function useUpdateClauseMutation(baseOptions?: Apollo.MutationHookOptions<UpdateClauseMutation, UpdateClauseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateClauseMutation, UpdateClauseMutationVariables>(UpdateClauseDocument, options);
      }
export type UpdateClauseMutationHookResult = ReturnType<typeof useUpdateClauseMutation>;
export type UpdateClauseMutationResult = Apollo.MutationResult<UpdateClauseMutation>;
export type UpdateClauseMutationOptions = Apollo.BaseMutationOptions<UpdateClauseMutation, UpdateClauseMutationVariables>;
export const UpdateGroupDocument = gql`
    mutation updateGroup($title: String, $parentID: ID!, $groupID: ID!, $dealID: ID!, $from: Int!, $to: Int!) {
  dealGroupUpdate(
    input: {title: $title, groupID: $groupID, parentID: $parentID, dealID: $dealID, index: {to: $to, from: $from}}
  ) {
    ... on Group {
      _id
      title
      index
    }
  }
}
    `;
export type UpdateGroupMutationFn = Apollo.MutationFunction<UpdateGroupMutation, UpdateGroupMutationVariables>;

/**
 * __useUpdateGroupMutation__
 *
 * To run a mutation, you first call `useUpdateGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateGroupMutation, { data, loading, error }] = useUpdateGroupMutation({
 *   variables: {
 *      title: // value for 'title'
 *      parentID: // value for 'parentID'
 *      groupID: // value for 'groupID'
 *      dealID: // value for 'dealID'
 *      from: // value for 'from'
 *      to: // value for 'to'
 *   },
 * });
 */
export function useUpdateGroupMutation(baseOptions?: Apollo.MutationHookOptions<UpdateGroupMutation, UpdateGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateGroupMutation, UpdateGroupMutationVariables>(UpdateGroupDocument, options);
      }
export type UpdateGroupMutationHookResult = ReturnType<typeof useUpdateGroupMutation>;
export type UpdateGroupMutationResult = Apollo.MutationResult<UpdateGroupMutation>;
export type UpdateGroupMutationOptions = Apollo.BaseMutationOptions<UpdateGroupMutation, UpdateGroupMutationVariables>;
export const UpdateGroupNoIndexesDocument = gql`
    mutation updateGroupNoIndexes($dealID: ID!, $parentID: ID!, $id: ID!, $description: String, $rics: String, $title: String) {
  dealGroupUpdate(
    input: {groupID: $id, parentID: $parentID, dealID: $dealID, description: $description, rics: $rics, title: $title}
  ) {
    ... on Group {
      title
      description
      rics
    }
  }
}
    `;
export type UpdateGroupNoIndexesMutationFn = Apollo.MutationFunction<UpdateGroupNoIndexesMutation, UpdateGroupNoIndexesMutationVariables>;

/**
 * __useUpdateGroupNoIndexesMutation__
 *
 * To run a mutation, you first call `useUpdateGroupNoIndexesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateGroupNoIndexesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateGroupNoIndexesMutation, { data, loading, error }] = useUpdateGroupNoIndexesMutation({
 *   variables: {
 *      dealID: // value for 'dealID'
 *      parentID: // value for 'parentID'
 *      id: // value for 'id'
 *      description: // value for 'description'
 *      rics: // value for 'rics'
 *      title: // value for 'title'
 *   },
 * });
 */
export function useUpdateGroupNoIndexesMutation(baseOptions?: Apollo.MutationHookOptions<UpdateGroupNoIndexesMutation, UpdateGroupNoIndexesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateGroupNoIndexesMutation, UpdateGroupNoIndexesMutationVariables>(UpdateGroupNoIndexesDocument, options);
      }
export type UpdateGroupNoIndexesMutationHookResult = ReturnType<typeof useUpdateGroupNoIndexesMutation>;
export type UpdateGroupNoIndexesMutationResult = Apollo.MutationResult<UpdateGroupNoIndexesMutation>;
export type UpdateGroupNoIndexesMutationOptions = Apollo.BaseMutationOptions<UpdateGroupNoIndexesMutation, UpdateGroupNoIndexesMutationVariables>;
export const UpdateSectionDocument = gql`
    mutation updateSection($dealID: ID!, $id: ID!, $from: Int!, $to: Int!) {
  dealSectionUpdate(
    input: {dealID: $dealID, sectionID: $id, index: {from: $from, to: $to}}
  ) {
    ... on Section {
      index
    }
  }
}
    `;
export type UpdateSectionMutationFn = Apollo.MutationFunction<UpdateSectionMutation, UpdateSectionMutationVariables>;

/**
 * __useUpdateSectionMutation__
 *
 * To run a mutation, you first call `useUpdateSectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSectionMutation, { data, loading, error }] = useUpdateSectionMutation({
 *   variables: {
 *      dealID: // value for 'dealID'
 *      id: // value for 'id'
 *      from: // value for 'from'
 *      to: // value for 'to'
 *   },
 * });
 */
export function useUpdateSectionMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSectionMutation, UpdateSectionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSectionMutation, UpdateSectionMutationVariables>(UpdateSectionDocument, options);
      }
export type UpdateSectionMutationHookResult = ReturnType<typeof useUpdateSectionMutation>;
export type UpdateSectionMutationResult = Apollo.MutationResult<UpdateSectionMutation>;
export type UpdateSectionMutationOptions = Apollo.BaseMutationOptions<UpdateSectionMutation, UpdateSectionMutationVariables>;
export const UpdateSectionNoIndexesDocument = gql`
    mutation updateSectionNoIndexes($dealID: ID!, $id: ID!, $description: String, $rics: String, $title: String) {
  dealSectionUpdate(
    input: {dealID: $dealID, sectionID: $id, description: $description, rics: $rics, title: $title}
  ) {
    ... on Section {
      description
      rics
      title
    }
  }
}
    `;
export type UpdateSectionNoIndexesMutationFn = Apollo.MutationFunction<UpdateSectionNoIndexesMutation, UpdateSectionNoIndexesMutationVariables>;

/**
 * __useUpdateSectionNoIndexesMutation__
 *
 * To run a mutation, you first call `useUpdateSectionNoIndexesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSectionNoIndexesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSectionNoIndexesMutation, { data, loading, error }] = useUpdateSectionNoIndexesMutation({
 *   variables: {
 *      dealID: // value for 'dealID'
 *      id: // value for 'id'
 *      description: // value for 'description'
 *      rics: // value for 'rics'
 *      title: // value for 'title'
 *   },
 * });
 */
export function useUpdateSectionNoIndexesMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSectionNoIndexesMutation, UpdateSectionNoIndexesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSectionNoIndexesMutation, UpdateSectionNoIndexesMutationVariables>(UpdateSectionNoIndexesDocument, options);
      }
export type UpdateSectionNoIndexesMutationHookResult = ReturnType<typeof useUpdateSectionNoIndexesMutation>;
export type UpdateSectionNoIndexesMutationResult = Apollo.MutationResult<UpdateSectionNoIndexesMutation>;
export type UpdateSectionNoIndexesMutationOptions = Apollo.BaseMutationOptions<UpdateSectionNoIndexesMutation, UpdateSectionNoIndexesMutationVariables>;
export const DeleteDocumentDocument = gql`
    mutation deleteDocument($id: ID!, $type: DocType!, $unitID: ID) {
  docDelete(id: $id, type: $type, unitID: $unitID)
}
    `;
export type DeleteDocumentMutationFn = Apollo.MutationFunction<DeleteDocumentMutation, DeleteDocumentMutationVariables>;

/**
 * __useDeleteDocumentMutation__
 *
 * To run a mutation, you first call `useDeleteDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDocumentMutation, { data, loading, error }] = useDeleteDocumentMutation({
 *   variables: {
 *      id: // value for 'id'
 *      type: // value for 'type'
 *      unitID: // value for 'unitID'
 *   },
 * });
 */
export function useDeleteDocumentMutation(baseOptions?: Apollo.MutationHookOptions<DeleteDocumentMutation, DeleteDocumentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteDocumentMutation, DeleteDocumentMutationVariables>(DeleteDocumentDocument, options);
      }
export type DeleteDocumentMutationHookResult = ReturnType<typeof useDeleteDocumentMutation>;
export type DeleteDocumentMutationResult = Apollo.MutationResult<DeleteDocumentMutation>;
export type DeleteDocumentMutationOptions = Apollo.BaseMutationOptions<DeleteDocumentMutation, DeleteDocumentMutationVariables>;
export const UploadAvatarDocument = gql`
    mutation uploadAvatar($type: AvatarType!, $filetype: String!, $id: ID!) {
  avatarCreate(type: $type, filetype: $filetype, id: $id) {
    ... on S3Payload {
      signedUrl
      url
    }
  }
}
    `;
export type UploadAvatarMutationFn = Apollo.MutationFunction<UploadAvatarMutation, UploadAvatarMutationVariables>;

/**
 * __useUploadAvatarMutation__
 *
 * To run a mutation, you first call `useUploadAvatarMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadAvatarMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadAvatarMutation, { data, loading, error }] = useUploadAvatarMutation({
 *   variables: {
 *      type: // value for 'type'
 *      filetype: // value for 'filetype'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUploadAvatarMutation(baseOptions?: Apollo.MutationHookOptions<UploadAvatarMutation, UploadAvatarMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UploadAvatarMutation, UploadAvatarMutationVariables>(UploadAvatarDocument, options);
      }
export type UploadAvatarMutationHookResult = ReturnType<typeof useUploadAvatarMutation>;
export type UploadAvatarMutationResult = Apollo.MutationResult<UploadAvatarMutation>;
export type UploadAvatarMutationOptions = Apollo.BaseMutationOptions<UploadAvatarMutation, UploadAvatarMutationVariables>;
export const UploadDocumentDocument = gql`
    mutation uploadDocument($type: DocType!, $filename: String!, $filetype: String!, $id: ID!, $taskID: ID, $frontend: Boolean) {
  docCreate(
    type: $type
    filetype: $filetype
    filename: $filename
    id: $id
    taskID: $taskID
    frontend: $frontend
  ) {
    ... on S3Payload {
      signedUrl
      url
    }
  }
}
    `;
export type UploadDocumentMutationFn = Apollo.MutationFunction<UploadDocumentMutation, UploadDocumentMutationVariables>;

/**
 * __useUploadDocumentMutation__
 *
 * To run a mutation, you first call `useUploadDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadDocumentMutation, { data, loading, error }] = useUploadDocumentMutation({
 *   variables: {
 *      type: // value for 'type'
 *      filename: // value for 'filename'
 *      filetype: // value for 'filetype'
 *      id: // value for 'id'
 *      taskID: // value for 'taskID'
 *      frontend: // value for 'frontend'
 *   },
 * });
 */
export function useUploadDocumentMutation(baseOptions?: Apollo.MutationHookOptions<UploadDocumentMutation, UploadDocumentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UploadDocumentMutation, UploadDocumentMutationVariables>(UploadDocumentDocument, options);
      }
export type UploadDocumentMutationHookResult = ReturnType<typeof useUploadDocumentMutation>;
export type UploadDocumentMutationResult = Apollo.MutationResult<UploadDocumentMutation>;
export type UploadDocumentMutationOptions = Apollo.BaseMutationOptions<UploadDocumentMutation, UploadDocumentMutationVariables>;
export const UnitOtherFileDocument = gql`
    mutation unitOtherFile($unitID: ID!, $url: String!, $name: String!) {
  unitOtherFile(unitID: $unitID, url: $url, name: $name) {
    ... on File {
      _id
      name
      url
      other
    }
  }
}
    `;
export type UnitOtherFileMutationFn = Apollo.MutationFunction<UnitOtherFileMutation, UnitOtherFileMutationVariables>;

/**
 * __useUnitOtherFileMutation__
 *
 * To run a mutation, you first call `useUnitOtherFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnitOtherFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unitOtherFileMutation, { data, loading, error }] = useUnitOtherFileMutation({
 *   variables: {
 *      unitID: // value for 'unitID'
 *      url: // value for 'url'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useUnitOtherFileMutation(baseOptions?: Apollo.MutationHookOptions<UnitOtherFileMutation, UnitOtherFileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UnitOtherFileMutation, UnitOtherFileMutationVariables>(UnitOtherFileDocument, options);
      }
export type UnitOtherFileMutationHookResult = ReturnType<typeof useUnitOtherFileMutation>;
export type UnitOtherFileMutationResult = Apollo.MutationResult<UnitOtherFileMutation>;
export type UnitOtherFileMutationOptions = Apollo.BaseMutationOptions<UnitOtherFileMutation, UnitOtherFileMutationVariables>;
export const DeleteNotificationDocument = gql`
    mutation deleteNotification($notificationID: ID!) {
  userNotificationDelete(notificationID: $notificationID)
}
    `;
export type DeleteNotificationMutationFn = Apollo.MutationFunction<DeleteNotificationMutation, DeleteNotificationMutationVariables>;

/**
 * __useDeleteNotificationMutation__
 *
 * To run a mutation, you first call `useDeleteNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteNotificationMutation, { data, loading, error }] = useDeleteNotificationMutation({
 *   variables: {
 *      notificationID: // value for 'notificationID'
 *   },
 * });
 */
export function useDeleteNotificationMutation(baseOptions?: Apollo.MutationHookOptions<DeleteNotificationMutation, DeleteNotificationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteNotificationMutation, DeleteNotificationMutationVariables>(DeleteNotificationDocument, options);
      }
export type DeleteNotificationMutationHookResult = ReturnType<typeof useDeleteNotificationMutation>;
export type DeleteNotificationMutationResult = Apollo.MutationResult<DeleteNotificationMutation>;
export type DeleteNotificationMutationOptions = Apollo.BaseMutationOptions<DeleteNotificationMutation, DeleteNotificationMutationVariables>;
export const GetDealStateDocument = gql`
    query getDealState($dealID: ID!) {
  deal(id: $dealID) {
    ... on Deal {
      _id
      state
      currentUserParty
      unit {
        organisation {
          name
        }
      }
      clauseSummary {
        complete
        incomplete
      }
    }
  }
}
    `;

/**
 * __useGetDealStateQuery__
 *
 * To run a query within a React component, call `useGetDealStateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDealStateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDealStateQuery({
 *   variables: {
 *      dealID: // value for 'dealID'
 *   },
 * });
 */
export function useGetDealStateQuery(baseOptions: Apollo.QueryHookOptions<GetDealStateQuery, GetDealStateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDealStateQuery, GetDealStateQueryVariables>(GetDealStateDocument, options);
      }
export function useGetDealStateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDealStateQuery, GetDealStateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDealStateQuery, GetDealStateQueryVariables>(GetDealStateDocument, options);
        }
export type GetDealStateQueryHookResult = ReturnType<typeof useGetDealStateQuery>;
export type GetDealStateLazyQueryHookResult = ReturnType<typeof useGetDealStateLazyQuery>;
export type GetDealStateQueryResult = Apollo.QueryResult<GetDealStateQuery, GetDealStateQueryVariables>;
export const GetNotificationsDocument = gql`
    query getNotifications {
  user {
    ... on User {
      _id
      notifications {
        _id
        url
        type
        title
        text
        read
        sent
      }
    }
  }
}
    `;

/**
 * __useGetNotificationsQuery__
 *
 * To run a query within a React component, call `useGetNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNotificationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetNotificationsQuery(baseOptions?: Apollo.QueryHookOptions<GetNotificationsQuery, GetNotificationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetNotificationsQuery, GetNotificationsQueryVariables>(GetNotificationsDocument, options);
      }
export function useGetNotificationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetNotificationsQuery, GetNotificationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetNotificationsQuery, GetNotificationsQueryVariables>(GetNotificationsDocument, options);
        }
export type GetNotificationsQueryHookResult = ReturnType<typeof useGetNotificationsQuery>;
export type GetNotificationsLazyQueryHookResult = ReturnType<typeof useGetNotificationsLazyQuery>;
export type GetNotificationsQueryResult = Apollo.QueryResult<GetNotificationsQuery, GetNotificationsQueryVariables>;
export const GetOrganisationFromIdDocument = gql`
    query getOrganisationFromId($id: ID!, $sort: UserSort, $filter: UserFilter, $query: String) {
  organisation(id: $id) {
    _id
    name
    members(sort: $sort, filter: $filter, query: $query) {
      _id
      firstName
      lastName
      email
      type
      mobile
      locked
      lastLogin
      activated
    }
    address {
      line1
      line2
      city
      country
      postCode
    }
    units {
      _id
      use
      rating
    }
    clauses {
      _id
    }
    deals {
      _id
      state
      round {
        date
      }
      created
    }
    avatar
    website
    type
    created
    templates {
      _id
    }
  }
}
    `;

/**
 * __useGetOrganisationFromIdQuery__
 *
 * To run a query within a React component, call `useGetOrganisationFromIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrganisationFromIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrganisationFromIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *      sort: // value for 'sort'
 *      filter: // value for 'filter'
 *      query: // value for 'query'
 *   },
 * });
 */
export function useGetOrganisationFromIdQuery(baseOptions: Apollo.QueryHookOptions<GetOrganisationFromIdQuery, GetOrganisationFromIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOrganisationFromIdQuery, GetOrganisationFromIdQueryVariables>(GetOrganisationFromIdDocument, options);
      }
export function useGetOrganisationFromIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrganisationFromIdQuery, GetOrganisationFromIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOrganisationFromIdQuery, GetOrganisationFromIdQueryVariables>(GetOrganisationFromIdDocument, options);
        }
export type GetOrganisationFromIdQueryHookResult = ReturnType<typeof useGetOrganisationFromIdQuery>;
export type GetOrganisationFromIdLazyQueryHookResult = ReturnType<typeof useGetOrganisationFromIdLazyQuery>;
export type GetOrganisationFromIdQueryResult = Apollo.QueryResult<GetOrganisationFromIdQuery, GetOrganisationFromIdQueryVariables>;
export const GetUserOrganisationsDocument = gql`
    query getUserOrganisations {
  user {
    ... on User {
      type
      trustedOrganisations {
        _id
        name
        active
      }
      organisation {
        _id
        name
      }
      associated {
        _id
        name
      }
    }
  }
}
    `;

/**
 * __useGetUserOrganisationsQuery__
 *
 * To run a query within a React component, call `useGetUserOrganisationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserOrganisationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserOrganisationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserOrganisationsQuery(baseOptions?: Apollo.QueryHookOptions<GetUserOrganisationsQuery, GetUserOrganisationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserOrganisationsQuery, GetUserOrganisationsQueryVariables>(GetUserOrganisationsDocument, options);
      }
export function useGetUserOrganisationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserOrganisationsQuery, GetUserOrganisationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserOrganisationsQuery, GetUserOrganisationsQueryVariables>(GetUserOrganisationsDocument, options);
        }
export type GetUserOrganisationsQueryHookResult = ReturnType<typeof useGetUserOrganisationsQuery>;
export type GetUserOrganisationsLazyQueryHookResult = ReturnType<typeof useGetUserOrganisationsLazyQuery>;
export type GetUserOrganisationsQueryResult = Apollo.QueryResult<GetUserOrganisationsQuery, GetUserOrganisationsQueryVariables>;
export const NotificationsDocument = gql`
    query notifications($page: Int, $compact: Boolean) {
  notifications(page: $page, compact: $compact) {
    total
    pages
    notifications {
      ...SingleNotification
    }
  }
}
    ${SingleNotificationFragmentDoc}`;

/**
 * __useNotificationsQuery__
 *
 * To run a query within a React component, call `useNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotificationsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      compact: // value for 'compact'
 *   },
 * });
 */
export function useNotificationsQuery(baseOptions?: Apollo.QueryHookOptions<NotificationsQuery, NotificationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NotificationsQuery, NotificationsQueryVariables>(NotificationsDocument, options);
      }
export function useNotificationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NotificationsQuery, NotificationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NotificationsQuery, NotificationsQueryVariables>(NotificationsDocument, options);
        }
export type NotificationsQueryHookResult = ReturnType<typeof useNotificationsQuery>;
export type NotificationsLazyQueryHookResult = ReturnType<typeof useNotificationsLazyQuery>;
export type NotificationsQueryResult = Apollo.QueryResult<NotificationsQuery, NotificationsQueryVariables>;
export const DownloadFilesDocument = gql`
    mutation downloadFiles($ids: [ID!]!) {
  downloadDoc(ids: $ids) {
    ... on File {
      _id
      url
      name
      downloads {
        user
        timestamp
      }
    }
  }
}
    `;
export type DownloadFilesMutationFn = Apollo.MutationFunction<DownloadFilesMutation, DownloadFilesMutationVariables>;

/**
 * __useDownloadFilesMutation__
 *
 * To run a mutation, you first call `useDownloadFilesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDownloadFilesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [downloadFilesMutation, { data, loading, error }] = useDownloadFilesMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useDownloadFilesMutation(baseOptions?: Apollo.MutationHookOptions<DownloadFilesMutation, DownloadFilesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DownloadFilesMutation, DownloadFilesMutationVariables>(DownloadFilesDocument, options);
      }
export type DownloadFilesMutationHookResult = ReturnType<typeof useDownloadFilesMutation>;
export type DownloadFilesMutationResult = Apollo.MutationResult<DownloadFilesMutation>;
export type DownloadFilesMutationOptions = Apollo.BaseMutationOptions<DownloadFilesMutation, DownloadFilesMutationVariables>;
export const CheckEmailDocument = gql`
    query checkEmail($email: String!, $dealID: String!, $type: UserType!) {
  checkEmail(email: $email, dealID: $dealID, type: $type)
}
    `;

/**
 * __useCheckEmailQuery__
 *
 * To run a query within a React component, call `useCheckEmailQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckEmailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckEmailQuery({
 *   variables: {
 *      email: // value for 'email'
 *      dealID: // value for 'dealID'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useCheckEmailQuery(baseOptions: Apollo.QueryHookOptions<CheckEmailQuery, CheckEmailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CheckEmailQuery, CheckEmailQueryVariables>(CheckEmailDocument, options);
      }
export function useCheckEmailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CheckEmailQuery, CheckEmailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CheckEmailQuery, CheckEmailQueryVariables>(CheckEmailDocument, options);
        }
export type CheckEmailQueryHookResult = ReturnType<typeof useCheckEmailQuery>;
export type CheckEmailLazyQueryHookResult = ReturnType<typeof useCheckEmailLazyQuery>;
export type CheckEmailQueryResult = Apollo.QueryResult<CheckEmailQuery, CheckEmailQueryVariables>;
export const TenantActionStageAmlDocument = gql`
    mutation tenantActionStageAML($dealID: ID!, $completed: Boolean, $companyJurisdiction: String, $companyName: String, $companyRegistrationNumber: String, $personalDetails: [TenantPersonInput!]) {
  dealTenantDetailsSet(
    dealID: $dealID
    aml: {companyJurisdiction: $companyJurisdiction, companyName: $companyName, companyRegistrationNumber: $companyRegistrationNumber, personalDetails: $personalDetails, completed: $completed}
  ) {
    ... on TenantDetails {
      aml {
        completed
        edited
        companyName
        companyJurisdiction
        companyRegistrationNumber
        personalDetails {
          name
          phone
        }
      }
    }
  }
}
    `;
export type TenantActionStageAmlMutationFn = Apollo.MutationFunction<TenantActionStageAmlMutation, TenantActionStageAmlMutationVariables>;

/**
 * __useTenantActionStageAmlMutation__
 *
 * To run a mutation, you first call `useTenantActionStageAmlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTenantActionStageAmlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [tenantActionStageAmlMutation, { data, loading, error }] = useTenantActionStageAmlMutation({
 *   variables: {
 *      dealID: // value for 'dealID'
 *      completed: // value for 'completed'
 *      companyJurisdiction: // value for 'companyJurisdiction'
 *      companyName: // value for 'companyName'
 *      companyRegistrationNumber: // value for 'companyRegistrationNumber'
 *      personalDetails: // value for 'personalDetails'
 *   },
 * });
 */
export function useTenantActionStageAmlMutation(baseOptions?: Apollo.MutationHookOptions<TenantActionStageAmlMutation, TenantActionStageAmlMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TenantActionStageAmlMutation, TenantActionStageAmlMutationVariables>(TenantActionStageAmlDocument, options);
      }
export type TenantActionStageAmlMutationHookResult = ReturnType<typeof useTenantActionStageAmlMutation>;
export type TenantActionStageAmlMutationResult = Apollo.MutationResult<TenantActionStageAmlMutation>;
export type TenantActionStageAmlMutationOptions = Apollo.BaseMutationOptions<TenantActionStageAmlMutation, TenantActionStageAmlMutationVariables>;
export const TenantActionStageTeamDocument = gql`
    mutation tenantActionStageTeam($dealID: ID!, $completed: Boolean, $tenant: TenantPersonInput, $agent: TenantPersonInput) {
  dealTenantDetailsSet(
    dealID: $dealID
    team: {tenant: $tenant, agent: $agent, completed: $completed}
  ) {
    ... on TenantDetails {
      team {
        completed
        edited
        tenant {
          name
          company
          email
          phone
          invite
        }
        agent {
          name
          company
          email
          phone
          invite
          visible
        }
      }
    }
  }
}
    `;
export type TenantActionStageTeamMutationFn = Apollo.MutationFunction<TenantActionStageTeamMutation, TenantActionStageTeamMutationVariables>;

/**
 * __useTenantActionStageTeamMutation__
 *
 * To run a mutation, you first call `useTenantActionStageTeamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTenantActionStageTeamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [tenantActionStageTeamMutation, { data, loading, error }] = useTenantActionStageTeamMutation({
 *   variables: {
 *      dealID: // value for 'dealID'
 *      completed: // value for 'completed'
 *      tenant: // value for 'tenant'
 *      agent: // value for 'agent'
 *   },
 * });
 */
export function useTenantActionStageTeamMutation(baseOptions?: Apollo.MutationHookOptions<TenantActionStageTeamMutation, TenantActionStageTeamMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TenantActionStageTeamMutation, TenantActionStageTeamMutationVariables>(TenantActionStageTeamDocument, options);
      }
export type TenantActionStageTeamMutationHookResult = ReturnType<typeof useTenantActionStageTeamMutation>;
export type TenantActionStageTeamMutationResult = Apollo.MutationResult<TenantActionStageTeamMutation>;
export type TenantActionStageTeamMutationOptions = Apollo.BaseMutationOptions<TenantActionStageTeamMutation, TenantActionStageTeamMutationVariables>;
export const DownloadDealPackDocument = gql`
    mutation downloadDealPack($dealID: ID!) {
  downloadDealPack(dealID: $dealID) {
    _id
  }
}
    `;
export type DownloadDealPackMutationFn = Apollo.MutationFunction<DownloadDealPackMutation, DownloadDealPackMutationVariables>;

/**
 * __useDownloadDealPackMutation__
 *
 * To run a mutation, you first call `useDownloadDealPackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDownloadDealPackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [downloadDealPackMutation, { data, loading, error }] = useDownloadDealPackMutation({
 *   variables: {
 *      dealID: // value for 'dealID'
 *   },
 * });
 */
export function useDownloadDealPackMutation(baseOptions?: Apollo.MutationHookOptions<DownloadDealPackMutation, DownloadDealPackMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DownloadDealPackMutation, DownloadDealPackMutationVariables>(DownloadDealPackDocument, options);
      }
export type DownloadDealPackMutationHookResult = ReturnType<typeof useDownloadDealPackMutation>;
export type DownloadDealPackMutationResult = Apollo.MutationResult<DownloadDealPackMutation>;
export type DownloadDealPackMutationOptions = Apollo.BaseMutationOptions<DownloadDealPackMutation, DownloadDealPackMutationVariables>;
export const DownloadHoTsDocument = gql`
    mutation downloadHoTs($dealID: ID!) {
  downloadHoTs(dealID: $dealID) {
    _id
  }
}
    `;
export type DownloadHoTsMutationFn = Apollo.MutationFunction<DownloadHoTsMutation, DownloadHoTsMutationVariables>;

/**
 * __useDownloadHoTsMutation__
 *
 * To run a mutation, you first call `useDownloadHoTsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDownloadHoTsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [downloadHoTsMutation, { data, loading, error }] = useDownloadHoTsMutation({
 *   variables: {
 *      dealID: // value for 'dealID'
 *   },
 * });
 */
export function useDownloadHoTsMutation(baseOptions?: Apollo.MutationHookOptions<DownloadHoTsMutation, DownloadHoTsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DownloadHoTsMutation, DownloadHoTsMutationVariables>(DownloadHoTsDocument, options);
      }
export type DownloadHoTsMutationHookResult = ReturnType<typeof useDownloadHoTsMutation>;
export type DownloadHoTsMutationResult = Apollo.MutationResult<DownloadHoTsMutation>;
export type DownloadHoTsMutationOptions = Apollo.BaseMutationOptions<DownloadHoTsMutation, DownloadHoTsMutationVariables>;
export const GetReadinessPackDocument = gql`
    mutation getReadinessPack($dealID: ID!, $shouldLog: Boolean!) {
  getReadinessPack(dealID: $dealID, shouldLog: $shouldLog) {
    ... on S3Payload {
      fileName
      signedUrl
      url
    }
  }
}
    `;
export type GetReadinessPackMutationFn = Apollo.MutationFunction<GetReadinessPackMutation, GetReadinessPackMutationVariables>;

/**
 * __useGetReadinessPackMutation__
 *
 * To run a mutation, you first call `useGetReadinessPackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetReadinessPackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getReadinessPackMutation, { data, loading, error }] = useGetReadinessPackMutation({
 *   variables: {
 *      dealID: // value for 'dealID'
 *      shouldLog: // value for 'shouldLog'
 *   },
 * });
 */
export function useGetReadinessPackMutation(baseOptions?: Apollo.MutationHookOptions<GetReadinessPackMutation, GetReadinessPackMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GetReadinessPackMutation, GetReadinessPackMutationVariables>(GetReadinessPackDocument, options);
      }
export type GetReadinessPackMutationHookResult = ReturnType<typeof useGetReadinessPackMutation>;
export type GetReadinessPackMutationResult = Apollo.MutationResult<GetReadinessPackMutation>;
export type GetReadinessPackMutationOptions = Apollo.BaseMutationOptions<GetReadinessPackMutation, GetReadinessPackMutationVariables>;