import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import ErrorMessage from './ErrorMessage';
import { useActivateUserMutation } from '../../generated/graphql';
import Loading from '../../shared/Loading';
import SuccessIcon from '../../shared/SuccessIcon';

type ActivateProps = {
    token: string;
};

const Activate = ({ token }: ActivateProps) => {
    const [activateUser, { loading, error }] = useActivateUserMutation();
    const [errorMessage, setErrorMessage] = useState<string | undefined>();
    // Set to loading if need to verify token

    useEffect(() => {
        const activate = async (token?: string) => {
            if (token) {
                await activateUser({
                    variables: {
                        token: token
                    }
                });
                if (error) {
                    setErrorMessage(error.graphQLErrors[0].extensions?.message);
                    // handleFetchError(
                    //     "An error occured",
                    //     error,
                    //     data?.userActivate
                    // )
                    return;
                }
            }
        };
        activate(token);
    }, [activateUser, error, token]);

    return (
        <>
            {loading && <Loading />}
            {!loading && !errorMessage && (
                <>
                    <SuccessIcon />
                    <h1 className="text-xl font-bold text-center mt-5">Account Activated</h1>
                    <p className="mt-2">
                        You can now log into{' '}
                        <Link to="/login" className="underline text-blue-500 hover:text-blue-800">
                            Kato
                        </Link>
                    </p>
                </>
            )}
            {!loading && errorMessage ? <ErrorMessage message={errorMessage} /> : <></>}
        </>
    );
};

export default Activate;
