import React from 'react';

import { ReactComponent as Envelope } from '../../../../../../assets/images/envelope/black.svg';
import { Button } from '../../../../../../shared/button';

export type TenantPendingMembersListProps = {
    inWindow?: boolean;
    emails: string[];
    setUserEmail: React.Dispatch<React.SetStateAction<string | null>>;
    setShowAddUserWindow: React.Dispatch<React.SetStateAction<boolean>>;
};

const TenantPendingMembersList = ({
    inWindow,
    emails,
    setUserEmail,
    setShowAddUserWindow
}: TenantPendingMembersListProps) => {
    return (
        <>
            {!inWindow && <span className="bg-navy-lightest w-full p-2 text-gray-800">Pending Members</span>}
            {emails.map((email, index) => (
                <div
                    key={index}
                    className="flex flex-row relative items-center justify-start border-navy-light border-b"
                >
                    <div className="w-100 flex flex-col justify-start relative p-2 ml-1">
                        <div className="w-100 flex justify-between items-center">
                            <p className="text-sm">{email}</p>
                            <Button
                                onClick={() => {
                                    setShowAddUserWindow(true);
                                    setUserEmail(email);
                                }}
                                height="9"
                                className="border border-newGray-main hover:border-black rounded text-newGray-main hover:text-black w-9 p-2 transition duration-150"
                            >
                                <Envelope />
                            </Button>
                        </div>
                    </div>
                </div>
            ))}
        </>
    );
};

export default TenantPendingMembersList;
