import React from 'react';

type ClauseActionProps = {
    text: string;
    className?: string;
    icon: React.ReactNode;
    onClick?: () => void;
};

const ClauseAction = ({ text, className, icon, onClick }: ClauseActionProps) => {
    return (
        <div
            className={`${className} sm:w-28 lg:w-24 h-11 text-xxs border-r text-center border-newGray-main font-semibold bg-navy-light flex flex-col content-center items-center justify-center px-1 py-1 cursor-pointer hover:bg-gray-200 focus:outline-none`}
            onClick={onClick}
        >
            {icon}
            {text}
        </div>
    );
};

export default ClauseAction;
