import axios from 'axios';
import { saveAs } from 'file-saver';
import fileDownload from 'js-file-download';
import React, { FC, useContext, useState } from 'react';

import { ReactComponent as DownloadIcon } from '../../../../assets/images/download/teal.svg';
import {
    Deal,
    File,
    GetDealDetailsNoHistoryDocument,
    useDownloadDealPackMutation,
    useDownloadHoTsMutation,
    useGetReadinessPackMutation
} from '../../../../generated/graphql';
import { DealContext } from '../../../../pages/Deals/Deal/context';
import Loading from '../../../../shared/Loading';
import useDownloadData, { getCSVBlob } from '../../../../shared/Negotiator/hooks/useDownloadData';
import useDownloadPDF, { getPDFBlob } from '../../../../shared/Negotiator/hooks/useDownloadPDF';

type DownloadProps = {
    files: File[];
    tenantFiles: File[];
};

const Download: FC<DownloadProps> = ({ files, tenantFiles }) => {
    const { deal } = useContext(DealContext);
    const [downloadData] = useDownloadData();
    const [downloadPDF] = useDownloadPDF();

    const [readinessPack] = useGetReadinessPackMutation({
        refetchQueries: () => [GetDealDetailsNoHistoryDocument]
    });
    const [downloadHoTs] = useDownloadHoTsMutation({
        refetchQueries: () => [GetDealDetailsNoHistoryDocument]
    });
    const [downloadDealPack] = useDownloadDealPackMutation({
        refetchQueries: () => [GetDealDetailsNoHistoryDocument]
    });

    const [isDealPackLoading, setIsDealPackLoading] = useState(false);

    const handleDealPack = async () => {
        setIsDealPackLoading(true);

        const JSZip = require('jszip');
        let zip = new JSZip();

        // get readiness .csv
        const { data } = await readinessPack({
            variables: {
                dealID: deal?._id!,
                shouldLog: false
            },
            refetchQueries: () => [GetDealDetailsNoHistoryDocument]
        });
        const response = await axios.get(data?.getReadinessPack.url!, {
            responseType: 'blob'
        });
        zip.file(`Readiness spreadsheet - ${deal?.unit?.name}, ${deal?.unit?.buildingName}.csv`, response.data);
        // get deal/unit files
        await Promise.all(
            files.map(async (file) => {
                const response = await axios.get(file.url, {
                    responseType: 'blob'
                });
                zip.file(
                    `Readiness documents - ${deal?.unit?.name}, ${deal?.unit?.buildingName}/${file.name}`,
                    response.data
                );
            })
        );

        // get tenant files
        await Promise.all(
            tenantFiles.map(async (file) => {
                const response = await axios.get(file.url, {
                    responseType: 'blob'
                });
                zip.file(
                    `Tenant documents - ${deal?.unit?.name}, ${deal?.unit?.buildingName}/${file.name}`,
                    response.data
                );
            })
        );

        // get hots
        const pdf = await getPDFBlob(deal as Deal);
        zip.file(`Heads of Terms - ${deal?.unit?.name}, ${deal?.unit?.buildingName}.pdf`, pdf);

        const csv = await getCSVBlob(deal as Deal);
        zip.file(`Heads of Terms spreadsheet - ${deal?.unit?.name}, ${deal?.unit?.buildingName}.csv`, csv);

        zip.generateAsync({ type: 'blob' }).then((content: any) => {
            saveAs(content, `${deal?.unit?.name}, ${deal?.unit?.buildingName} - Deal Pack.zip`);
        });
        await downloadDealPack({
            variables: { dealID: deal?._id ?? '' }
        });

        setIsDealPackLoading(false);
    };

    const handleGetReadinessPack = async (shouldLog = true) => {
        const { data } = await readinessPack({
            variables: {
                dealID: deal?._id!,
                shouldLog
            },
            refetchQueries: () => [GetDealDetailsNoHistoryDocument]
        });

        const response = await axios.get(data?.getReadinessPack.url ?? '', {
            responseType: 'blob'
        });
        fileDownload(response.data, data?.getReadinessPack.fileName!);
    };

    return (
        <div className="flex flex-col border-b border-gray-600 pb-4">
            <div className="flex flex-row mb-2 items-center">
                <DownloadIcon className="w-5 h-5 text-newTeal-main" />
                <p className="font-semibold ml-4 text-xl">Download</p>
            </div>
            <div className="flex sm:flex-row xs:flex-col sm:w-92 xs:w-full">
                <div className="flex flex-row sm:w-5/12 xs:w-full items-center">
                    <button
                        type="button"
                        className="bg-newTeal-light hover:bg-newTeal-main text-black hover:text-white xs:h-20 sm:h-18 w-full mr-3 rounded-lg items-center flex flex-col justify-center  cursor-pointer"
                        onClick={() => handleDealPack()}
                    >
                        {isDealPackLoading ? (
                            <Loading />
                        ) : (
                            <>
                                <p className="font-semibold ">Deal Pack</p>
                                <p className="text-xxs sm:px-3 xs:px-6">
                                    Download full deal data including Heads of Terms, Landlord and Tenant Readiness
                                    Packs and all relevant Files/Documents to this Deal
                                </p>
                            </>
                        )}
                    </button>
                </div>
                <div className="sm:w-7/12 xs:w-full flex flex-row items-center">
                    <div className="border-r border-gray-600  mr-3 h-11 xs:hidden" />
                    <div className="flex flex-col sm:h-16 xs:h-auto justify-between w-full">
                        <p className="text-xxs xs:mt-2 xs:mb-1">
                            If you don't need the full deal pack you may download an individual dataset below
                        </p>
                        <div className="flex sm:flex-row xs:flex-col w-full xs:gap-2 sm:gap-4">
                            <button
                                type="button"
                                className="sm:h-11 hover:bg-navy-light xs:h-20 xs:w-full sm:w-1/2 bg-navy-lightest rounded-lg flex flex-col items-center justify-center sm:leading-tight cursor-pointer"
                                onClick={() => [
                                    downloadPDF(deal?._id ?? ''),
                                    downloadData(deal?._id ?? ''),
                                    downloadHoTs({
                                        variables: { dealID: deal?._id ?? '' }
                                    })
                                ]}
                            >
                                <p className="font-semibold">Heads of Terms</p>
                                <p className="text-xxs text-newGray-darkish">Includes pdf & csv versions</p>
                            </button>
                            <button
                                type="button"
                                className="sm:h-11 hover:bg-navy-light xs:h-20 xs:w-full sm:w-1/2 bg-navy-lightest rounded-lg flex flex-col items-center justify-center sm:leading-tight cursor-pointer"
                                onClick={() => handleGetReadinessPack()}
                            >
                                <p className="font-semibold">Readiness Pack</p>
                                <p className="text-xxs text-newGray-darkish">csv</p>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Download;
