import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import ApproveDealWindow from './ApproveDealWindow';
import {
    DealApprovalSendType,
    DealDetailsFragment,
    DealSendType,
    GetDealDetailsQuery,
    useGetDealDetailsNoHistoryQuery,
    useGetLayoutUserInfoQuery
} from '../../../../generated/graphql';
import Sidebar from '../../../../layout/SideBar';
import { useUpdateUrl } from '../../../../shared/Filter/helpers';
import { handleFetchError } from '../../../../shared/helpers';
import useMobileScreen from '../../../../shared/hooks/useMobileScreen';
import Loading from '../../../../shared/Loading';
import { useUserType } from '../../../../v2/common/helpers';
import { LandlordFilesContext } from '../../../../v2/components/LandlordsFileModal/LandlordFilesContext';
import { LandlordsFileModal } from '../../../../v2/components/LandlordsFileModal/LandlordsFileModal';
import TenantDeal from '../../../../v2/components/Sidebar/Deal/Tenant';
import SidebarWrapper from '../../../../v2/components/Sidebar/SidebarWrapper';
import DealHeader, { DealHeaderPage } from '../../../../v2/pages/Deals/DealHeader';
import DealSendWindow from '../DealSendWindow';
import OldDealHeader from '../Header';
import useSortDeal from '../hooks/useSortDeal';
import PreviewContent, { DealUnit } from '../Preview/PreviewContent';

type AproveDealProps = {
    setHeaderContent: React.Dispatch<React.SetStateAction<React.ReactChild | null>>;
};

const ApproveDeal = ({ setHeaderContent }: AproveDealProps) => {
    const history = useHistory();
    const { id, token } = useParams<{ id: string; token: string }>();
    const {
        loading: detailsLoading,
        error: detailsError,
        data: detailsData
    } = useGetDealDetailsNoHistoryQuery({
        variables: {
            dealID: id,
            token
        }
    });

    const { showLandlordFilesWindow, setShowLandlordFilesWindow } = useContext(LandlordFilesContext);

    const isMobile = useMobileScreen();

    const [deal, setDeal] = useState<DealDetailsFragment>();
    const sortDeal = useSortDeal();

    const [showApproveDealWindow, setShowApproveDealWindow] = useState<boolean>(false);
    const [showRejectDealWindow, setShowRejectDealWindow] = useState<boolean>(false);

    const { isTenantSide, isOther } = useUserType(undefined, detailsData?.deal.currentUserParty);

    const [visible, setVisible] = useState(true);

    const { updateUrl } = useUpdateUrl();

    const { data: userData, loading: userLoading } = useGetLayoutUserInfoQuery({
        fetchPolicy: 'network-only'
    });
    const isAuthenticated = !userLoading && !!userData;

    useEffect(() => {
        // Check for errors
        handleFetchError('Could not load the deal', detailsError, detailsData?.deal);
        // Redirect to templates page if error e.g. ID in url is not valid
        if (detailsError) return history.push('/deals');

        // Sort the deal by it's clauses
        const sortedDeal = sortDeal(detailsData as GetDealDetailsQuery);
        sortedDeal && setDeal(sortedDeal);
        const tenant = isTenantSide ? { tenant: isTenantSide } : {};
        updateUrl({ ...tenant });
        // eslint-disable-next-line
    }, [detailsData, detailsError, history]);

    if (detailsLoading) return <Loading />;

    const getSidebar = () => {
        // if it's user outside platform, use old sidebar
        if (!isAuthenticated) return <Sidebar visible={visible} setVisible={setVisible} />;

        return isTenantSide && <TenantDeal />;
    };

    return (
        <SidebarWrapper wrap={!isTenantSide} sidebar={getSidebar()} className={'overflow-y-hidden'}>
            <main className="least-app-main min-h-screen md:flex">
                <div className={`least-app-content w-full max-h-screen transition-all ease-in-out duration-500`}>
                    <div className="bg-navy-lightest min-h-full-header">
                        {/* HEADER */}
                        {!isMobile && !isOther ? (
                            <DealHeader
                                setShowApproveDealWindow={setShowApproveDealWindow}
                                setShowRejectDealWindow={setShowRejectDealWindow}
                                page={DealHeaderPage.APPROVE}
                            >
                                {detailsData as GetDealDetailsQuery}
                            </DealHeader>
                        ) : (
                            <OldDealHeader
                                setShowApproveDealWindow={setShowApproveDealWindow}
                                setShowRejectDealWindow={setShowRejectDealWindow}
                                page={DealHeaderPage.APPROVE}
                                setHeaderContent={setHeaderContent}
                            >
                                {detailsData as GetDealDetailsQuery}
                            </OldDealHeader>
                        )}

                        {/* Preview Content */}
                        <PreviewContent deal={deal} unit={(detailsData?.deal.unit as DealUnit) ?? null} />
                    </div>
                </div>
            </main>
            {/* Windows */}
            {showApproveDealWindow &&
                detailsData?.deal.__typename === 'Deal' &&
                detailsData.deal.approverReturnEmail && (
                    <ApproveDealWindow
                        currentUserParty={detailsData.deal.currentUserParty}
                        approvalID={detailsData.deal.approvalID}
                        dealID={detailsData.deal._id}
                        emails={detailsData.deal.approverReturnEmail}
                        redirect={`/deals/${detailsData.deal._id}/preview/${token}`}
                        setShow={setShowApproveDealWindow}
                    />
                )}
            {showRejectDealWindow &&
                detailsData?.deal.__typename === 'Deal' &&
                detailsData.deal.approverReturnEmail && (
                    <DealSendWindow
                        currentUserParty={detailsData.deal.currentUserParty}
                        approvalID={detailsData.deal.approvalID}
                        dealID={detailsData?.deal.__typename === 'Deal' ? detailsData.deal._id : ''}
                        type={DealSendType.Approver}
                        email={detailsData.deal.approverReturnEmail}
                        redirect={`/deals/${detailsData.deal._id}/preview/${token}`}
                        approvalResponse={DealApprovalSendType.Reject}
                        setShow={setShowRejectDealWindow}
                    />
                )}
            {showLandlordFilesWindow && (
                <LandlordsFileModal
                    setShow={setShowLandlordFilesWindow}
                    dealId={id}
                    organisationName={detailsData?.deal.unit?.organisation.name}
                />
            )}
        </SidebarWrapper>
    );
};

export default ApproveDeal;
