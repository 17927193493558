import { format } from 'date-fns';
import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';

import { DealCardContext } from '..';
import { ReactComponent as Tick } from '../../../../assets/images/check/circle/white.svg';
import { DealState } from '../../../../generated/graphql';
import { BulkSelectContext } from '../../../../shared/bulk/context';
import { getOrdinal } from '../../../../shared/helpers';

type DealCardBarContainerProps = {
    dealURL: string;
    dealHover: boolean;
};

const DealCardBarContainer = ({ dealURL, dealHover }: DealCardBarContainerProps) => {
    const { selectMode } = useContext(BulkSelectContext);
    const { complete, clauseSummary, round, state, deal } = useContext(DealCardContext);
    const history = useHistory();

    const days = Math.ceil((new Date(round?.date).getTime() - new Date(deal?.created).getTime()) / (1000 * 3600 * 24));

    const roundsOrdinal = clauseSummary && state !== DealState.Edit && round && getOrdinal(round.count);

    const date = format(new Date(round?.date), 'dd/MM/yyyy');

    return (
        <>
            {clauseSummary && (
                <div
                    className={`w-full ${complete ? 'pb-4' : 'pb-2'} px-4`}
                    onClick={() => {
                        !selectMode && history.push(dealURL);
                    }}
                >
                    <div className="rounded bg-navy-lightest">
                        {!complete ? (
                            <div className={`flex flex-col items-center text-sm pl-4 py-1 transitionAll`}>
                                <div className="flex flex-row justify-between w-full">
                                    <span className="text-xxs mt-1 font-semibold">Clauses Agreed</span>
                                    <span
                                        className={`text-xxs text-white bg-newGray-darkish py-1 px-2 -mt-1 rounded-l-xl rounded-tr-lg`}
                                    >
                                        {roundsOrdinal} Round Negs
                                    </span>
                                </div>
                                <span className="flex flex-row items-center -mt-2">
                                    <span className="text-4xl font-semibold text-newGray-dark ">
                                        {clauseSummary.complete}
                                    </span>
                                    <span className={`text-3xl text-gray-600 mt-1`}>
                                        /{clauseSummary.complete + clauseSummary.incomplete}
                                    </span>{' '}
                                </span>
                            </div>
                        ) : (
                            <div className="max-h-full">
                                <div className="flex flex-col justify-center items-center py-8">
                                    <Tick className="w-6 h-6 text-newTeal-main mb-1" />
                                    <div className="flex text-xs">
                                        <span className="font-medium text-gray-600 mr-1">Deal Agreed -</span>
                                        <span className="font-medium text-gray-600">{date}</span>
                                    </div>
                                    {deal?.created && (
                                        <div className="flex text-xs font-medium text-gray-600 ">
                                            <span className=" mr-1">Time Taken -</span>
                                            <span className="mr-1">{days}</span>
                                            <span>Days</span>
                                        </div>
                                    )}
                                </div>
                            </div>
                        )}
                        {/* Bar */}
                        <div className="bg-navy-light w-full h-3 relative overflow-hidden rounded-bl rounded-br">
                            <span
                                className={`h-full ${
                                    !complete ? 'bg-newGray-darkish' : 'bg-gray-600'
                                } absolute top-0 left-0`}
                                style={{
                                    width: `${
                                        (clauseSummary.complete / (clauseSummary.complete + clauseSummary.incomplete)) *
                                        100
                                    }%`
                                }}
                            ></span>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default DealCardBarContainer;
