import React, { useContext, useEffect, useState } from 'react';

import CardCreatedAt from './CardCreatedAt';
import CardDropdown, { CardDropdownOptions } from './CardDropdown';
import { ReactComponent as Check } from '../../assets/images/check/regularCheck/check.svg';
import { BulkSelectContext } from '../bulk/context';

type CardProps = {
    created?: string | Date | undefined;
    options?: CardDropdownOptions | undefined;
    children: React.ReactNode;
    className?: string;
    createdAtClassName?: string;
    select: boolean;
    setSelect?: React.Dispatch<React.SetStateAction<boolean>>;
};

const Card = ({ created, options, children, className, createdAtClassName, select, setSelect }: CardProps) => {
    const { selectMode } = useContext(BulkSelectContext);
    const [dropdownVisible, setDropdownVisible] = useState<boolean>(false);

    useEffect(() => {
        selectMode === false && setSelect && setSelect(false);
    }, [selectMode]);

    return (
        <>
            {dropdownVisible && (
                <div
                    className="absolute w-full h-full left-0 top-0 z-10 transparent"
                    onClick={() => setDropdownVisible(false)}
                ></div>
            )}

            <div
                className={`w-100 relative bg-white rounded hover:shadow-md transition duration-200 ${className}  ${
                    select && 'border border-newTeal-main'
                }`}
                onClick={() => selectMode && setSelect && setSelect(!select)}
            >
                {' '}
                {selectMode && (
                    <div
                        className={`w-5 h-5 rounded absolute top-0 right-4 mt-4 z-20  ${
                            select ? 'bg-newTeal-main' : 'bg-navy-light'
                        } `}
                        onClick={() => setSelect && setSelect(!select)}
                    >
                        {select && <Check className="w-5 h-5" />}
                    </div>
                )}
                {created && (
                    <CardCreatedAt
                        className={options && options.length === 0 ? createdAtClassName : ''}
                        created={created}
                    />
                )}
                {options && options.length > 0 && !selectMode && (
                    <CardDropdown
                        options={options}
                        dropdownVisible={dropdownVisible}
                        setDropdownVisible={(bool) => setDropdownVisible(bool)}
                    />
                )}
                {children}
            </div>
        </>
    );
};

export default Card;
