import { noop } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import LowSendWindow from './LowSendWindow';
import {
    DealSendType,
    DealState,
    DealUserParty,
    GetDealDetailsNoHistoryDocument,
    GetDealDetailsQuery,
    useGetDealTenantDetailsLazyQuery,
    useUpdateAgreementSeenMutation
} from '../../../../generated/graphql';
import { Button } from '../../../../shared/button';
import Window from '../../../../shared/Window';
import useLowReadinessWarning from '../../Deal/hooks/useLowReadinessWarning';
import { DealContext } from '../context';
import DealSendWindow from '../DealSendWindow';

type DealCompleteWindowProps = {
    dealComplete: any;
    userType?: string | null;
    deal: GetDealDetailsQuery | undefined;
};

type WindowProps = {
    deal: GetDealDetailsQuery | undefined;
    userType?: string | null;
};

const DealCompleteWindow = ({ dealComplete, deal }: DealCompleteWindowProps) => {
    return (
        <>
            {dealComplete.type === 'manual' && <ManualCompleteWindow deal={deal} />}
            {dealComplete.type === 'auto' && <AutoCompleteWindow deal={deal} />}
        </>
    );
};

const ManualCompleteWindow = ({ deal }: WindowProps) => {
    const checkIfLowReadinessWarning = useLowReadinessWarning(deal?.deal.__typename === 'Deal' ? deal.deal : undefined);
    const { id } = useParams();
    const [showWindow, setShowWindow] = useState<boolean>(false);
    const [showWarning, setShowWarning] = useState<boolean>(false);
    const [showSendWindow, setShowShareWindow] = useState<boolean>(false);
    const [lowReadinessCheck, setLowReadinessCheck] = useState<{
        show: boolean;
        totalStages?: number;
        rounds?: number;
        stages?: number;
    }>({ show: false });

    const [tenantDetails, { data: tenantDetailsData }] = useGetDealTenantDetailsLazyQuery();

    useEffect(() => {
        if (!deal || deal.deal.__typename !== 'Deal') return;
        if (deal.deal.currentUserParty === 'TENANT') {
            tenantDetails({
                variables: {
                    dealID: deal.deal._id
                }
            });
        }
        // eslint-disable-next-line
    }, [deal]);

    const checkToShowWarning = async () => {
        const needsReadinessWarning = checkIfLowReadinessWarning(
            tenantDetailsData?.deal.__typename === 'Deal' ? tenantDetailsData.deal : undefined
        );
        setLowReadinessCheck(needsReadinessWarning);
        if (!needsReadinessWarning.show) {
            setShowWarning(false);
            shareNow();
        } else {
            setShowWarning(true);
        }
    };

    const shareNow = () => {
        setShowWarning(false);
        setShowShareWindow(true);
    };

    if (!deal || deal.deal.__typename !== 'Deal') return null;

    const isCompleted = deal.deal.state === DealState.Complete;

    if (showWarning) {
        return (
            <LowSendWindow
                setShow={setShowWarning}
                shareNow={shareNow}
                stages={lowReadinessCheck.stages ?? 0}
                totalStages={lowReadinessCheck.totalStages ?? 0}
                dealComplete={true}
                id={deal.deal.currentUserParty !== 'TENANT' && deal.deal.unit ? deal.deal.unit._id : deal.deal._id}
                round={deal.deal.round?.count}
                rating={deal.deal.currentUserParty !== 'TENANT' && deal.deal.unit ? deal.deal.unit?.rating : undefined}
            />
        );
    }

    if (!deal || deal.deal.__typename !== 'Deal') return null;

    if (showWindow) {
        return (
            <>
                {!showSendWindow ? (
                    <Window
                        title="Congratulations!"
                        setShow={setShowWindow}
                        titleCenter
                        emoji={'🎉'}
                        primaryBtn
                        primaryBtnText="Share Now"
                        onPrimaryBtnClick={deal.deal.state === DealState.Complete ? shareNow : checkToShowWarning}
                        secondaryBtn
                        secondaryBtnText="Share Later"
                        onSecondaryBtnClick={() => setShowWindow(false)}
                    >
                        <div className="text-center w-full mt-6">
                            <p className="font-bold text-lg">All clauses have now been agreed.</p>
                            <p>Share to confirm agreement of terms</p>
                            <p>
                                with the {deal.deal.currentUserParty === DealUserParty.Landlord ? 'Tenant' : 'Landlord'}
                            </p>
                            {deal.deal.unit?.straightToLease && deal.deal.unit?.organisation?.straightToLease?.end && (
                                <div className="flex flex-col items-center">
                                    {deal.deal.currentUserParty === DealUserParty.Landlord && (
                                        <div className="w-1/2">
                                            <p className="text-gray-600 font-medium text-center">
                                                Reminder: On agreement of the HoTs, this message was sent to the tenant.
                                            </p>
                                        </div>
                                    )}
                                    <div className="flex justify-center my-4">
                                        <p className="md:max-w-lg bg-white p-2 rounded mx-2 md:mx-0 border-newTeal-main text-black">
                                            {deal.deal.unit?.organisation.straightToLease.end}
                                        </p>
                                    </div>
                                </div>
                            )}
                        </div>
                    </Window>
                ) : (
                    <DealSendWindow
                        isCompleted={isCompleted}
                        dealID={id}
                        type={
                            deal.deal.currentUserParty === DealUserParty.Landlord
                                ? DealSendType.Tenant
                                : DealSendType.Landlord
                        }
                        currentUserParty={deal.deal.currentUserParty}
                        showEmail={false}
                        setShow={setShowShareWindow}
                        onSend={() => {
                            setShowWindow(false);
                        }}
                    />
                )}
            </>
        );
    } else return <AutoCompleteWindow deal={deal} />;
};

const AutoCompleteWindow = ({ deal }: WindowProps) => {
    const { id } = useParams<{ id: string }>();
    const [warningShowing] = useState<boolean>(false);
    const [showWindow] = useState<boolean>(true);
    const [showWarning, setShowWarning] = useState<boolean>(false);
    const [showSendWindow, setShowShareWindow] = useState<boolean>(false);
    const [shareWindowType, setShareWindowType] = useState<DealSendType>(DealSendType.Other);

    const [updateAgreementSeen] = useUpdateAgreementSeenMutation();
    const { downloadOpen = noop } = useContext(DealContext);

    const [lowReadinessCheck] = useState<
        | {
              show: boolean;
              totalStages?: number;
              rounds?: number;
              stages?: number;
          }
        | undefined
    >({ show: false });

    const [tenantDetails] = useGetDealTenantDetailsLazyQuery();

    useEffect(() => {
        if (!deal || deal.deal.__typename !== 'Deal') return;
        if (deal.deal.currentUserParty === 'TENANT') {
            tenantDetails({
                variables: {
                    dealID: deal.deal._id
                }
            });
        }
    }, [deal, tenantDetails]);

    const shareNow = (type: DealSendType) => {
        setShowWarning(false);
        setShareWindowType(type);
        setShowShareWindow(true);
    };

    if (!deal || deal.deal.__typename !== 'Deal') return null;

    const isCompleted = deal.deal.state === DealState.Complete;

    if (showWarning && lowReadinessCheck) {
        return (
            <LowSendWindow
                setShow={setShowWarning}
                shareNow={() => {
                    shareNow(shareWindowType);
                }}
                stages={lowReadinessCheck.stages ?? 0}
                totalStages={lowReadinessCheck.totalStages ?? 0}
                dealComplete={true}
                id={deal.deal.currentUserParty !== 'TENANT' && deal.deal.unit ? deal.deal.unit._id : deal.deal._id}
                round={deal.deal.round?.count}
                rating={deal.deal.currentUserParty !== 'TENANT' && deal.deal.unit ? deal.deal.unit?.rating : undefined}
            />
        );
    }

    if (showWindow) {
        return (
            <>
                {!showSendWindow ? (
                    <Window
                        width={warningShowing ? 'w-2/5' : undefined}
                        setShow={() => {}}
                        className="p-6 md:overflow-visible"
                        header={
                            <div className="flex flex-row text-xl justify-center mb-4">
                                <p className="font-bold border-b-3 border-newTeal-main">Congratulations!🎉</p>
                            </div>
                        }
                        footer={
                            <div className="flex flex-row items-center w-full justify-center">
                                <Button
                                    onClick={async () => {
                                        await updateAgreementSeen({
                                            variables: {
                                                dealID: id,
                                                currentUserParty: deal?.deal.currentUserParty
                                            },
                                            refetchQueries: () => [GetDealDetailsNoHistoryDocument]
                                        });
                                        downloadOpen();
                                    }}
                                    background="newTeal-main"
                                    text="white"
                                    className="h-20 mb-5 ml-5 mr-5"
                                >
                                    <div className="w-72 m-4 mx-10 flex flex-col justify-center items-center">
                                        <p className="text-white font-bold text-xl">Download Deal Data</p>
                                        <p className="text-white font-medium text-xs text-left">
                                            Now the terms are agreed you can Download Deal Data or Share it with
                                            colleagues/colabolators
                                        </p>
                                    </div>
                                </Button>
                            </div>
                        }
                    >
                        <div className="text-center w-full mb-5">
                            <p className="text-gray-800 font-medium">Heads of Terms are now agreed.</p>

                            <p className="text-gray-800 font-medium">
                                The {deal.deal.currentUserParty === DealUserParty.Landlord ? 'Tenant' : 'Landlord'} has
                                been informed
                            </p>
                        </div>
                    </Window>
                ) : (
                    <DealSendWindow
                        isCompleted={isCompleted}
                        dealID={id}
                        type={shareWindowType}
                        currentUserParty={deal.deal.currentUserParty}
                        showEmail={shareWindowType !== DealSendType.Landlord && shareWindowType !== DealSendType.Tenant}
                        setShow={setShowShareWindow}
                    />
                )}
            </>
        );
    } else return <></>;
};

export default DealCompleteWindow;
