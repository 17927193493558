import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import WindowFooter from './layout/WindowFooter';
import WindowHeader from './layout/WindowHeader';
import StageCompleted from './StageCompleted';
import StageContext from './StageContext';
import { ReactComponent as Cross } from '../../../../../assets/images/cross/black.svg';
import { ReactComponent as External } from '../../../../../assets/images/external/black.svg';
import {
    DocType,
    GetDealTenantDetailsDocument,
    useTenantActionStageThreeMutation
} from '../../../../../generated/graphql';
import { Button } from '../../../../../shared/button';
import { FileUploadModal } from '../../../../../shared/file';
import { handleFetchError } from '../../../../../shared/helpers';
import Input, { InputType } from '../../../../../shared/Inputs/index';
import Loading from '../../../../../shared/Loading';
import Window from '../../../../../shared/Window';
import ConfirmDeleteFileWindow from '../../ConfirmDeleteFileWindow';

const Stage3Window = () => {
    const history = useHistory();

    const { dealID, details, backTo, unitID, setShowStage3Window: setShow } = React.useContext(StageContext);

    // Checks
    const [noAuditedAccounts, setNoAuditedAccounts] = useState<boolean>(details?.three?.audited ?? false);
    const [managementAccounts, setManagementAccounts] = useState<boolean>(details?.three?.audited ?? false);
    const [nominateGuarantor, setNominateGuarantor] = useState<boolean>(details?.three?.guarantor?.visible ?? false);

    // File
    const [showFileUploadWindow, setShowFileUploadWindow] = useState<boolean>(false);
    const [showFileDeleteWindow, setShowFileDeleteWindow] = useState<boolean>(false);
    const [fileID, setFileID] = useState<string | null>(null);

    // Guarantor Details
    const [guarantorName, setGuarantorName] = useState<string>(details?.three?.guarantor?.name ?? '');
    const [guarantorCompanyNumber, setGuarantorCompanyNumber] = useState<string>(
        details?.three?.guarantor?.company ?? ''
    );
    const [guarantorJurisdiction, setGuarantorJurisdiction] = useState<string>(
        details?.three?.guarantor?.jurisdiction ?? ''
    );
    const [guarantorAddressLine1, setGuarantorAddressLine1] = useState<string>(
        details?.three?.guarantor?.address?.line1 ?? ''
    );
    const [guarantorAddressLine2, setGuarantorAddressLine2] = useState<string>(
        details?.three?.guarantor?.address?.line2 ?? ''
    );
    const [guarantorCity, setGuarantorCity] = useState<string>(details?.three?.guarantor?.address?.city ?? '');
    const [guarantorPostcode, setGuarantorPostcode] = useState<string>(
        details?.three?.guarantor?.address?.postCode ?? ''
    );

    // Set stage three
    const [actionStage, { loading: actionStageLoading, error: actionStageError }] = useTenantActionStageThreeMutation();

    const saveToDB = async (completed?: boolean) => {
        const { data } = await actionStage({
            variables: {
                dealID: dealID!,
                audited: !noAuditedAccounts,
                completed: completed ?? false,
                guarantor: {
                    name: guarantorName,
                    company: guarantorCompanyNumber,
                    jurisdiction: guarantorJurisdiction,
                    visible: nominateGuarantor,
                    address: {
                        line1: guarantorAddressLine1,
                        line2: guarantorAddressLine2,
                        city: guarantorCity,
                        postCode: guarantorPostcode
                    }
                }
            },
            refetchQueries: [
                {
                    query: GetDealTenantDetailsDocument,
                    variables: {
                        dealID: dealID!
                    }
                }
            ]
        });
        if (actionStageError) {
            return handleFetchError('Could not set your details', actionStageError, data?.dealTenantDetailsSet);
        }
    };

    const handleCompleteStage = async () => {
        // Local fields error Obj
        let fieldsErr = {
            file: false,
            guarantorName: false,
            guarantorCompanyNumber: false,
            guarantorJurisdiction: false,
            guarantorAddressLine1: false,
            guarantorCity: false,
            guarantorPostcode: false
        };
        // // Validate Files
        // if (!file && !noAuditedAccounts) {
        //     fieldsErr = {
        //         ...fieldsErr,
        //         file: true
        //     }
        //     setErrorMessage(
        //         "Please provide the Landlord with audited accounts or Check that you don't have them."
        //     )
        //     return
        // }

        // Validate empty fields
        const checkEmptyField = (field: string, fieldValue: string | undefined) => {
            !fieldValue
                ? (fieldsErr = {
                      ...fieldsErr,
                      [field]: true
                  })
                : (fieldsErr = {
                      ...fieldsErr,
                      [field]: false
                  });
        };

        // Guarantor Details
        if (nominateGuarantor) {
            checkEmptyField('guarantorName', guarantorName);
            checkEmptyField('guarantorCompanyNumber', guarantorCompanyNumber);
            checkEmptyField('guarantorJurisdiction', guarantorJurisdiction);
            checkEmptyField('guarantorAddressLine1', guarantorAddressLine1);
            checkEmptyField('guarantorCity', guarantorCity);
            checkEmptyField('guarantorPostcode', guarantorPostcode);
        }

        // Send to backend
        const noErrors = Object.values(fieldsErr).every((v) => v === false);
        saveToDB(noErrors && ((details?.three?.files?.length ?? 0) > 0 || noAuditedAccounts || nominateGuarantor));

        // Go to the next stage
        history.push('#readiness-stage4');
    };

    return (
        <>
            {showFileUploadWindow && dealID && (
                <FileUploadModal
                    id={dealID}
                    taskID="3"
                    multiple
                    type={DocType.Deal}
                    setShow={setShowFileUploadWindow}
                />
            )}
            {showFileDeleteWindow && fileID && (
                <ConfirmDeleteFileWindow
                    type={DocType.Deal}
                    fileId={fileID}
                    unitId={unitID}
                    dealId={dealID}
                    setWindow={setShowFileDeleteWindow}
                />
            )}
            <Window
                width="w-4/5"
                scrollContent={true}
                className="md:max-h-11/12"
                contentBackground="navy-light"
                header={
                    <WindowHeader
                        setShow={setShow!}
                        backTo={backTo!}
                        stage="3"
                        title="Confirm Your Covenant"
                        description="Before we can get this deal agreed, the Landlord will need to see some financial information. Please provide as many years’ (ideally 3 years) audited/management accounts as possible for the entity signing the lease."
                    />
                }
                footer={
                    <WindowFooter
                        backTo="#readiness-stage2"
                        nextTo="#readiness-stage4"
                        onNextClick={async () => await handleCompleteStage()}
                    />
                }
                setShow={setShow!}
                onCloseClick={() => {
                    setShow!(false);
                    history.push(backTo!);
                }}
            >
                <div className="flex flex-col min-h-full">
                    <div className="w-full bg-navy-light flex-1">
                        {actionStageLoading && <Loading className="mt-20" />}
                        <div className={`${actionStageLoading && 'opacity-0'}`}>
                            {!noAuditedAccounts && (
                                <div className="border-b border-gray-450 py-4">
                                    <div className="container lg:w-4/5 md:w-5/5 mx-auto">
                                        <p className="font-medium text-gray-600 text-center max-w-md mx-auto px-2 mb-2">
                                            Please provide as many years audited and management accounts for the entity
                                            signing the lease as possible.
                                        </p>
                                        <div className="flex flex-col gap-2 w-1/2 mx-auto mt-2 max-h-60 overflow-y-auto">
                                            {details?.three?.files?.map((file) => (
                                                <div
                                                    key={file._id}
                                                    className="bg-white rounded h-12 p-2 flex items-center justify-between w-full"
                                                >
                                                    <a
                                                        href={file.url}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        className="flex-1 truncate"
                                                    >
                                                        <External className="w-4 h-5 mr-4 inline" />
                                                        <span className="text-sm text-newGray-darkish pr-2 mr-2 border-r border-gray-600">
                                                            {new Date(file.created).toLocaleDateString()}
                                                        </span>
                                                        {file.name}
                                                    </a>
                                                    <Cross
                                                        onClick={() => {
                                                            setFileID(file._id);
                                                            setShowFileDeleteWindow(true);
                                                        }}
                                                        className="w-5 h-5 cursor-pointer z-60"
                                                    />
                                                </div>
                                            ))}
                                        </div>
                                        {/* File upload button */}
                                        <div className="w-full text-center">
                                            <Button
                                                text="black"
                                                background="navy-light"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    setShowFileUploadWindow(true);
                                                }}
                                                className="w-1/2 font-semibold bg-gray-450 mt-2"
                                            >
                                                Upload
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            )}
                            <div className="container px-4 mx-auto sm:pt-4 sm:pb-4 pb-96 pt-0">
                                <div className="lg:max-w-md md:w-3/5 sm:w-5/5 mx-auto">
                                    {/* Check 1 */}
                                    <label className="block my-5">
                                        <input
                                            type="checkbox"
                                            id="optional"
                                            checked={noAuditedAccounts}
                                            onChange={() => {
                                                setNoAuditedAccounts(!noAuditedAccounts);
                                            }}
                                            className="leading-tight w-8 h-8 form-checkbox p-2 rounded bg-white text-newTeal-main focus:shadow-none focus:outline-none"
                                        />
                                        <span className="text-sm font-medium text-gray-600 ml-3">
                                            We do not have any accounts to share
                                        </span>
                                    </label>
                                </div>
                                <>
                                    {noAuditedAccounts && (
                                        <>
                                            <div className="lg:max-w-md md:w-3/5 sm:w-5/5 mx-auto">
                                                {/* Check 1 */}
                                                <label className="block my-5">
                                                    <input
                                                        type="checkbox"
                                                        id="optional"
                                                        checked={!managementAccounts}
                                                        onChange={() => {
                                                            setManagementAccounts(!managementAccounts);
                                                        }}
                                                        className="leading-tight w-8 h-8 form-checkbox p-2 rounded bg-white text-newTeal-main focus:shadow-none focus:outline-none"
                                                    />
                                                    <span className="text-sm font-medium text-gray-600 ml-3">
                                                        I have nothing I can share
                                                    </span>
                                                </label>
                                            </div>
                                            {managementAccounts && (
                                                <div className="border-b border-gray-450 py-4">
                                                    <div className="container w-full items-center flex flex-col">
                                                        <p className="font-medium text-gray-600 text-center max-w-md mx-auto px-2 mb-2">
                                                            Please provide as many years management accounts for the
                                                            entity signing the lease as possible.
                                                        </p>
                                                        <div className="flex flex-col gap-2 w-1/2 mx-auto mt-2 max-h-60 overflow-y-auto">
                                                            {details?.three?.files?.map((file) => (
                                                                <div
                                                                    key={file._id}
                                                                    className="bg-white rounded h-12 p-2 flex items-center justify-between w-full"
                                                                >
                                                                    <a
                                                                        href={file.url}
                                                                        target="_blank"
                                                                        rel="noopener noreferrer"
                                                                        className="flex-1 truncate"
                                                                    >
                                                                        <External className="w-4 h-5 mr-4 inline" />
                                                                        <span className="text-sm text-newGray-darkish pr-2 mr-2 border-r border-gray-600">
                                                                            {new Date(
                                                                                file.created
                                                                            ).toLocaleDateString()}
                                                                        </span>
                                                                        {file.name}
                                                                    </a>
                                                                    <Cross
                                                                        onClick={() => {
                                                                            setFileID(file._id);
                                                                            setShowFileDeleteWindow(true);
                                                                        }}
                                                                        className="w-5 h-5 cursor-pointer z-60"
                                                                    />
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                    {/* File upload button */}
                                                    <div className="w-full text-center">
                                                        <Button
                                                            text="black"
                                                            background="navy-light"
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                setShowFileUploadWindow(true);
                                                            }}
                                                            className="w-1/2 font-semibold bg-gray-450 mt-2"
                                                        >
                                                            Upload
                                                        </Button>
                                                    </div>
                                                </div>
                                            )}
                                        </>
                                    )}
                                </>
                                <div className="lg:max-w-md md:w-3/5 sm:w-5/5 mx-auto">
                                    {/* Check 2 */}
                                    <label className="block my-5">
                                        <input
                                            type="checkbox"
                                            id="optional"
                                            checked={nominateGuarantor}
                                            onChange={() => {
                                                setNominateGuarantor(!nominateGuarantor);
                                            }}
                                            className="leading-tight w-8 h-8 form-checkbox p-2 rounded bg-white text-newTeal-main focus:shadow-none focus:outline-none"
                                        />
                                        <span className="text-sm font-medium text-gray-600 ml-3">
                                            We wish to nominate a Guarantor for our Lease
                                        </span>
                                    </label>
                                    {/* Hidden Inputs */}
                                    {nominateGuarantor && (
                                        <div className="border-t border-b border-gray-450 py-6 w-full">
                                            <p className="text-gray-600 font-medium text-sm mb-0.5">
                                                <span className="text-newTeal-main mr-1">*</span>
                                                Guarantor Name
                                            </p>
                                            <Input
                                                type={InputType.text}
                                                value={guarantorName}
                                                onChange={(e) => {
                                                    setGuarantorName(e.target.value);
                                                }}
                                                placeholder="Guarantor Name"
                                                className={`w-full mb-2`}
                                            />
                                            <p className="text-gray-600 font-medium text-sm mb-0.5">
                                                <span className="text-newTeal-main mr-1">*</span>
                                                Guarantor Company Number
                                            </p>
                                            <Input
                                                type={InputType.text}
                                                value={guarantorCompanyNumber}
                                                onChange={(e) => {
                                                    setGuarantorCompanyNumber(e.target.value);
                                                }}
                                                placeholder="Guarantor Company Number"
                                                className={`w-full mb-1`}
                                            />
                                            <p className="text-gray-600 font-medium text-sm mb-0.5">
                                                <span className="text-newTeal-main mr-1">*</span>
                                                Guarantor Jurisdiction
                                            </p>
                                            <Input
                                                type={InputType.text}
                                                value={guarantorJurisdiction}
                                                onChange={(e) => {
                                                    setGuarantorJurisdiction(e.target.value);
                                                }}
                                                placeholder="Guarantor Jurisdiction"
                                                className={`w-full`}
                                            />
                                            <h3 className="font-semibold text-gray-600 mt-4 mb-3">
                                                Guarantor Registered Address
                                            </h3>
                                            <p className="text-gray-600 font-medium text-sm mb-0.5">
                                                <span className="text-newTeal-main mr-1">*</span>
                                                Address Line 1
                                            </p>
                                            <Input
                                                type={InputType.text}
                                                value={guarantorAddressLine1}
                                                onChange={(e) => {
                                                    setGuarantorAddressLine1(e.target.value);
                                                }}
                                                placeholder="Address Line 1"
                                                className={`w-full mb-1`}
                                            />
                                            <Input
                                                type={InputType.text}
                                                value={guarantorAddressLine2}
                                                onChange={(e) => {
                                                    setGuarantorAddressLine2(e.target.value);
                                                }}
                                                placeholder="Address Line 2"
                                                label="Address Line 2"
                                                className={`w-full mb-1`}
                                            />
                                            <div className="flex">
                                                <div className="w-full md:w-3/5 mr-2">
                                                    <p className="text-gray-600 font-medium text-sm mb-0.5">
                                                        <span className="text-newTeal-main mr-1">*</span>
                                                        City
                                                    </p>
                                                    <Input
                                                        type={InputType.text}
                                                        value={guarantorCity}
                                                        onChange={(e) => {
                                                            setGuarantorCity(e.target.value);
                                                        }}
                                                        placeholder="City"
                                                        className={`w-full`}
                                                    />
                                                </div>
                                                <div className="w-full md:w-2/5">
                                                    <p className="text-gray-600 font-medium text-sm mb-0.5">
                                                        <span className="text-newTeal-main mr-1">*</span>
                                                        Postcode
                                                    </p>
                                                    <Input
                                                        type={InputType.text}
                                                        value={guarantorPostcode}
                                                        onChange={(e) => {
                                                            setGuarantorPostcode(e.target.value);
                                                        }}
                                                        placeholder="Postcode"
                                                        className={`w-full`}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                                {/* Bottom text */}
                                <p className="text-gray-600 font-medium text-sm mt-2 mb-4 text-center">
                                    <span className="text-newTeal-main mr-1">*</span>
                                    All the fields are required to complete the stage
                                </p>
                                {details?.three?.completed && <StageCompleted />}
                            </div>
                        </div>
                    </div>
                </div>
            </Window>
        </>
    );
};

export default React.memo(Stage3Window);
