import React from 'react';

import { ReactComponent as Cross } from '../../../assets/images/cross/black.svg';

type MobileWindowProps = {
    children: React.ReactNode;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
};

const MobileWindow = ({ children, setShow }: MobileWindowProps) => {
    return (
        <div className="w-screen h-screen z-80 fixed flex items-center justify-center">
            <div className="z-50 bg-white w-9/12  mb-24  flex flex-col justify-center items-center rounded-lg border border-newTeal-main pb-4">
                <Cross className="w-5 h-5 self-end flex mr-2 mb-4 mt-2" onClick={() => setShow(false)} />
                {children}
            </div>
            <div
                onClick={() => setShow(false)}
                className={`fixed top-0 left-0 w-full h-screen bg-black z-40`}
                style={{ backgroundColor: 'rgba(0,0,0,0.3)' }}
            />
        </div>
    );
};

export default MobileWindow;
