import { noop } from 'lodash';
import React, { useContext, useState } from 'react';

import DealPreventSendWindow from './Windows/DealPreventSendWindow';
import PreviewLinkWindow from './Windows/PreviewLinkWindow';
import { ReactComponent as Send } from '../../../../../assets/images/send/black.svg';
import { ReactComponent as Share } from '../../../../../assets/images/share/black.svg';
import { DealSendType, DealUserParty, GetDealDetailsQuery } from '../../../../../generated/graphql';
import { Button } from '../../../../../shared/button';
import useMobileScreen from '../../../../../shared/hooks/useMobileScreen';
import { useUserType } from '../../../../../v2/common/helpers';
import DealHeaderContext from '../../../../../v2/pages/Deals/DealHeader/DealHeaderContext';

type DealSendProps = {
    dealID: string;
    showOtherParty: boolean;
    edit?: boolean;
    deal?: GetDealDetailsQuery | undefined;
    checkWarning?: (type: DealSendType) => void;
    setShowWarningShowing?: React.Dispatch<React.SetStateAction<boolean>>;
    disabled: boolean;
    currentParty?: false | DealUserParty | undefined;
    showSendWindow: (type: DealSendType) => void;
    inWindow?: boolean;
    setHideToolbar?: React.Dispatch<React.SetStateAction<boolean>>;
    isCompleted?: boolean;
};

const DealSend = ({
    dealID,
    showOtherParty,
    disabled,
    currentParty,
    showSendWindow,
    inWindow,
    setHideToolbar = noop,
    isCompleted
}: DealSendProps) => {
    const { setShowShareWindow = noop } = useContext(DealHeaderContext);

    const doSendDeal = (type: DealSendType) => {
        setOpen(false);
        showSendWindow(type);
    };
    const isMobile = useMobileScreen();

    const { isTenantSide } = useUserType(undefined, currentParty as DealUserParty);

    const [isOpen, setOpen] = useState<boolean>(false);

    const [showLinkWindow, setShowLinkWindow] = useState<boolean>(false);

    const [showPreventSendWindow, setShowPreventSendWindow] = useState<boolean>(false);
    const [backTo, setBackTo] = useState<'Landlord' | 'Tenant' | undefined>(undefined);

    /**
     * Handle opening the deal send window to the other party
     * @description Only for sending to Tenant or Landlord
     * @param party Name of other party
     */
    const handleSendOtherParty = (party: DealSendType) => {
        if (disabled) {
            setHideToolbar(true);
            setBackTo(party === DealSendType.Tenant ? 'Tenant' : 'Landlord');
            return setShowPreventSendWindow(true);
        }
        setHideToolbar(false);
        doSendDeal(party);
    };

    const handleShareWith = () => {
        if (inWindow || isCompleted) {
            doSendDeal(DealSendType.Other);
        } else {
            setOpen((isOpen) => !isOpen);
        }
    };

    const headerShareButton = inWindow ? 'Share HoTs' : !isCompleted || isMobile ? 'Send' : 'Share with';

    const getWidth = () => {
        if (inWindow) return 'w-full md:w-auto';
        if (!isMobile) return 'w-40';
        return isCompleted ? 'w-1/2' : 'w-1/4';
    };

    return (
        <>
            {showLinkWindow && <PreviewLinkWindow dealID={dealID} setShow={setShowLinkWindow} />}
            {showPreventSendWindow && (
                <DealPreventSendWindow
                    setShow={setShowPreventSendWindow}
                    backTo={backTo}
                    setHideToolbar={setHideToolbar}
                    setMenuOpen={setOpen}
                />
            )}
            <div className={`relative ${getWidth()}`}>
                <Button
                    onClick={() => (!inWindow ? setShowShareWindow(true) : handleShareWith())}
                    background={inWindow ? 'navy-lightest' : 'newTeal-main'}
                    text={inWindow ? 'black' : 'white'}
                    className={`flex flex-col items-center w-full justify-center ${inWindow && 'w-auto'} ${
                        isCompleted && !isMobile && 'pr-4'
                    }`}
                >
                    {!inWindow ? (
                        <>
                            {isMobile ? <Send className="w-5 h-5" /> : <Share className="w-5 h-5" />}
                            <p className="text-xs">{headerShareButton}</p>
                        </>
                    ) : (
                        headerShareButton
                    )}
                    <div className="ml-2" />
                </Button>
                {/* {isHover && (
                <span
                    className="absolute flex w-40 p-2 bg-white rounded border border-newTeal-main z-30 right-0 -mt-px mr-2 text-sm text-center"
                    onMouseLeave={() => setHover(false)}
                >
                    You need to accept or reject all clauses before sending
                </span>
            )} */}
                {isOpen && (
                    <>
                        <div className="fixed w-full h-full z-10 left-0 top-0" onClick={() => setOpen(false)}></div>
                        <div
                            className={`${
                                !inWindow ? 'absolute w-40' : 'md:absolute relative w-full'
                            } bg-white border rounded border-newTeal-main z-20 right-0 flex flex-col mt-2`}
                        >
                            {showOtherParty &&
                                (currentParty === DealUserParty.Landlord ? (
                                    <span
                                        onClick={() => handleSendOtherParty(DealSendType.Tenant)}
                                        className={`border-navy-light font-bold border-b inline-flex items-center justify-center h-10 cursor-pointer hover:bg-navy-lightest`}
                                    >
                                        Tenant
                                    </span>
                                ) : (
                                    <span
                                        onClick={() => handleSendOtherParty(DealSendType.Landlord)}
                                        className={`border-navy-light font-bold border-b inline-flex items-center justify-center h-10 cursor-pointer hover:bg-navy-lightest`}
                                    >
                                        {isTenantSide ? 'Counterparty' : 'Landlord'}
                                    </span>
                                ))}
                            <span
                                onClick={() => {
                                    doSendDeal(DealSendType.Approver);
                                }}
                                className="border-navy-light border-b inline-flex items-center justify-center h-10 cursor-pointer hover:bg-navy-lightest"
                            >
                                {isTenantSide ? 'Your Approver' : 'Approver'}
                            </span>

                            <span
                                onClick={() => {
                                    doSendDeal(DealSendType.Other);
                                }}
                                className="border-newGray-darkish border-b inline-flex items-center justify-center h-10 cursor-pointer hover:bg-navy-lightest"
                            >
                                Other
                            </span>
                            <span
                                onClick={() => setShowLinkWindow(true)}
                                className="inline-flex items-center justify-center h-10 cursor-pointer hover:bg-navy-lightest"
                            >
                                Get Share Link
                            </span>
                        </div>
                    </>
                )}
            </div>
        </>
    );
};

export default DealSend;
