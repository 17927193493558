import _, { isEmpty } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { useHistory } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';

import BulkAddUsersWindow from './Deal/Header/components/Windows/BulkAddUsers';
import DealCard from './DealCard';
import DealsHeader from './DealsHeader';
import NewDealWindow from './NewDealWindow';
import NoDeals from './NoDeals';
import SearchDealCards from './SearchDealCards';
import {
    DealCardFragment,
    DealSortFields,
    DealStatusFilter,
    UnitCondition,
    UnitUse,
    useDealFilterCountsQuery,
    useExportDealsLazyQuery,
    useGetDealBuildingNamesQuery,
    useGetDealCardDetailsQuery,
    useGetDealCitiesQuery,
    useGetDealCreatorQuery,
    useGetDealOrganisationsQuery,
    useGetDealTemplatesQuery,
    useGetUserTypeQuery,
    UserType
} from '../../generated/graphql';
import { BulkSelectContext } from '../../shared/bulk/context';
import { FilterContext } from '../../shared/Filter/context';
import { FilterConfig, FilterType, FilterWindow } from '../../shared/Filter/FilterWindow';
import {
    transformFilters,
    transformToFilterUrlParams,
    transformToSortUrlParams,
    useUpdateUrl
} from '../../shared/Filter/helpers';
import Grid from '../../shared/Grid';
import { debounce, downloadFile, handleFetchError } from '../../shared/helpers';
import useMobileScreen from '../../shared/hooks/useMobileScreen';
import { RadioValue } from '../../shared/Inputs/Radio';
import Loading from '../../shared/Loading';

type DealsProps = {
    setHeaderContent: React.Dispatch<React.SetStateAction<React.ReactChild | null>>;
};

const Deals = ({ setHeaderContent }: DealsProps) => {
    const { saveSelectMode, selectAll, saveSelectAll, selectedItems, checkIsAllDeals } = useContext(BulkSelectContext);

    const limit = 9;
    const history = useHistory();

    const { values, isWindowOpen, sort, filters } = useContext(FilterContext);

    const [dealStatus, setDealStatus] = useState<DealStatusFilter[] | undefined>([DealStatusFilter.Live]);
    const [isSearching, setIsSearching] = useState<string | undefined>('');

    const { data: countsData, refetch: countsRefetch } = useDealFilterCountsQuery({
        fetchPolicy: 'cache-and-network',
        variables: {
            filter: {
                ...transformFilters(filters, values),
                status: dealStatus
            },
            query: isSearching
        }
    });

    const [deals, setDeals] = useState<DealCardFragment[] | undefined>([]);

    const [hasNextPage, setHasNextPage] = useState<boolean>(true);

    const [bulkAddUsersWindow, setBulkAddUsersWindow] = useState<boolean>(false);

    const { loading, data, refetch, fetchMore, error } = useGetDealCardDetailsQuery({
        fetchPolicy: 'cache-and-network',
        variables: {
            limit,
            offset: 0,
            sort: sort
                ? {
                      field: sort.field as DealSortFields,
                      order: sort.order
                  }
                : undefined,
            filter: {
                ...transformFilters(filters, values),
                ...(dealStatus ? { status: dealStatus } : {})
            },
            query: isSearching
        }
    });
    const { updateUrl } = useUpdateUrl();
    useEffect(() => {
        if (!_.isEmpty(values) || sort) {
            const sortParams = sort ? transformToSortUrlParams(sort) : {};
            const filterParams = !_.isEmpty(values) ? transformToFilterUrlParams(filters, values) : {};

            updateUrl({
                ...filterParams,
                ...sortParams
            });
        }
    }, [values, sort]);

    const [pinnedPresence, setPinnedPresence] = useState<boolean>(false);

    const [showNewDealWindow, setShowNewDealWindow] = useState<boolean>(false);

    // User Type Check
    const { data: typeData, loading: typeLoading } = useGetUserTypeQuery();
    const isTenant = typeData?.user.type === 'TENANT';

    const isLandlordOrAdmin =
        typeData?.user.type && [UserType.Admin, UserType.Landlord, UserType.Agent].includes(typeData?.user.type);

    useEffect(() => {
        // Tenant's should show all
        if (isTenant) {
            setDealStatus(undefined);
            loadMore();
            refetch({ limit, offset: 0 });
        }
    }, [isTenant]);

    useEffect(() => {
        setHasNextPage(true);
    }, [dealStatus]);

    useEffect(() => {
        setHeaderContent(<h1 className="text-xl font-bold p-2">Deals</h1>);
    }, [setHeaderContent]);

    useEffect(() => {
        saveSelectMode(false);
        saveSelectAll(false);
    }, [dealStatus]);

    useEffect(() => {
        const hasPinnedPresence = (data?.deals.results ?? []).find((deal) => deal.isPinned === true);
        setPinnedPresence(!!hasPinnedPresence);
    }, [data]);

    useEffect(() => {
        data?.deals.results && checkIsAllDeals(data?.deals.results);
    }, [selectAll]);

    useEffect(() => {
        // Check for errors
        if (error) return handleFetchError('Could not load your demises', error, data?.deals.results);

        sortDeals(data?.deals.results ?? []);
    }, [data, error]);

    useEffect(() => {
        if (
            data?.deals.results.length === 1 &&
            data?.deals.results[0].__typename === 'Deal' &&
            typeData?.user.type === UserType.Tenant
        ) {
            history.push(`/deals/${data.deals.results[0]._id}`);
        }
    }, [data]);

    const searchDeals = debounce((query?: string) => {
        setIsSearching(query);
        refetch({ query });
        countsRefetch({ query });
    }, 250);

    const sortDeals = (deals: DealCardFragment[]) => {
        let sortedDeals: DealCardFragment[] = [];
        const unhidenDeals = deals?.filter(
            (deal) => !typeData?.user.hiddenDeals?.find((hiddenUnit) => hiddenUnit._id === deal._id)
        );
        sortedDeals.push(...unhidenDeals);

        const hiddenDeals = deals?.filter((deal) =>
            typeData?.user.hiddenDeals?.find((hiddenUnit) => hiddenUnit._id === deal._id)
        );
        sortedDeals.push(...hiddenDeals);

        setDeals(sortedDeals);
    };

    const handleBulkClick = (id: string) => {
        //this is placeholder for api call
        if (id === 'hide' && selectedItems.length > 0) {
            saveSelectMode(true);
        } else if (id === 'addUser' && selectedItems.length > 0) {
            setBulkAddUsersWindow(true);
            saveSelectMode(true);
        }
        //  if (id==="addUser") {
        //     setNewTenant(true)
        //  }
    };

    const loadMore = async () => {
        if (!hasNextPage) return;

        const { data: newData } = await fetchMore({
            variables: {
                limit,
                offset: deals?.length ?? 0,
                filter: {
                    ...transformFilters(filters, values),
                    ...(dealStatus ? { status: dealStatus } : {})
                }
            }
        });
        sortDeals([...deals!, ...newData.deals.results]);
        setHasNextPage(newData?.deals.pageInfo.hasNextPage);
    };
    const isMobile = useMobileScreen();

    const [exportDeals] = useExportDealsLazyQuery({
        fetchPolicy: 'no-cache'
    });

    const handleExportDeals = async () => {
        const { data } = await exportDeals();

        downloadFile(data?.exportDeals.url!, `Kato - ${typeData?.user.organisation.name}, Deal Export.csv`);
    };

    const isLiveFilter = dealStatus?.includes(DealStatusFilter.Live);

    const isLoading = loading || typeLoading;

    const dealFilterCounts = countsData?.dealFilterCounts;

    return (
        <>
            {bulkAddUsersWindow && (
                <BulkAddUsersWindow
                    dealStatus={dealStatus}
                    deals={data?.deals.results}
                    setShow={setBulkAddUsersWindow}
                />
            )}
            {showNewDealWindow && (
                <NewDealWindow setShow={setShowNewDealWindow} refetch={refetch} countsRefetch={countsRefetch} />
            )}
            {data?.deals?.pageInfo?.hasResults ||
            (dealFilterCounts?.complete || dealFilterCounts?.live || dealFilterCounts?.hidden) !== 0 ? (
                <div className="md:px-32 mx-auto h-10/12 p-4 pb-12">
                    <DealsHeader
                        isSearching={isSearching}
                        isMobile={isMobile}
                        isTenant={isTenant}
                        searchDeals={searchDeals}
                        user={typeData?.user}
                        setShowNewDealWindow={setShowNewDealWindow}
                        setDealStatus={setDealStatus}
                        dealFilterCounts={countsData?.dealFilterCounts}
                        isLandlordOrAdmin={isLandlordOrAdmin}
                        dealStatus={dealStatus}
                        isLiveFilter={isLiveFilter}
                        getDealFields={getDealFields}
                        deals={deals}
                        handleBulkClick={handleBulkClick}
                        handleExportDeals={handleExportDeals}
                    />
                    {isSearching && <div className="border-t border-newGray-main z-20 w-100" />}
                    {isLoading ? (
                        <Loading className="mt-20" />
                    ) : (
                        data?.deals &&
                        data?.deals.results.length > 0 &&
                        (!isEmpty(isSearching) ? (
                            <SearchDealCards
                                deals={deals}
                                user={typeData?.user}
                                dealFilterCounts={countsData?.dealFilterCounts}
                                pinnedPresence={pinnedPresence}
                                isTenant={isTenant}
                                dealStatus={dealStatus}
                            />
                        ) : (
                            <InfiniteScroll
                                loadMore={loadMore}
                                useWindow={false}
                                getScrollParent={() => document.getElementById('layout-content')}
                                hasMore={hasNextPage}
                                loader={<div className="container mx-auto p-4">{loading && <Loading />}</div>}
                            >
                                <Grid className={`${pinnedPresence ? 'mt-9 gap-y-7' : 'mt-4'}`}>
                                    {deals?.map((deal) => {
                                        // Don't show if invitation is still pending
                                        if (deal.pending && typeData?.user.type !== 'AGENT') return null;

                                        return (
                                            <DealCard
                                                deal={deal}
                                                id={deal._id}
                                                key={deal._id}
                                                isPinned={
                                                    !dealStatus?.includes(DealStatusFilter.Hidden) && deal.isPinned
                                                }
                                                hasMaxPinnedDeals={typeData?.user.hasMaxPinnedDeals}
                                                userType={typeData?.user.type}
                                                approvals={deal.approvals}
                                                isHidden={typeData?.user.hiddenDeals?.some(
                                                    (hiddenDeal) => hiddenDeal._id === deal._id
                                                )}
                                                name={
                                                    !isTenant && deal.tenant?.name
                                                        ? deal.tenant?.name
                                                        : deal.unit?.organisation.name ?? ''
                                                }
                                                created={deal.created}
                                                clientName={
                                                    typeData?.user.type === 'AGENT'
                                                        ? deal.currentUserParty === 'TENANT'
                                                            ? deal.tenant?.name
                                                            : deal.unit?.organisation.name
                                                        : null
                                                }
                                                unitID={deal.unit?._id ?? ''}
                                                unitName={deal.unit?.name ?? 'Untitled Demise'}
                                                unitRating={deal.unit?.rating ?? 0}
                                                pending={deal.pending}
                                                buildingName={deal.unit?.buildingName ?? 'Untitled Building'}
                                                state={deal.state}
                                                currentUserParty={deal.currentUserParty}
                                                round={deal.round}
                                                clauseSummary={deal.clauseSummary}
                                                hasHiddenUnit={deal.hasHiddenUnit}
                                                agreedBy={deal.agreedBy}
                                                showSelectButton={true}
                                            />
                                        );
                                    })}
                                </Grid>
                            </InfiniteScroll>
                        ))
                    )}
                    {isWindowOpen && typeData?.user && (
                        <FilterWindow config={dealFilterConfig(typeData.user.type)} sortOptions={getDealFields()} />
                    )}
                </div>
            ) : (
                <NoDeals isTenant={isTenant} setShowNewDealWindow={setShowNewDealWindow} />
            )}
            <ReactTooltip
                backgroundColor="black"
                textColor="white"
                effect="solid"
                place="left"
                clickable={false}
                borderColor="white"
            />
        </>
    );
};

const USE_OPTIONS = [
    { label: 'Industrial', value: UnitUse.Industrial },
    { label: 'Medical', value: UnitUse.Medical },
    { label: 'Office', value: UnitUse.Office },
    { label: 'Office Flex', value: UnitUse.OfficeFlex },
    { label: 'Retail High Street', value: UnitUse.RetailHighStreet },
    { label: 'Retail Pop Up', value: UnitUse.RetailPopUp },
    { label: 'Retail Shopping Centre', value: UnitUse.RetailShoppingCentre },
    { label: 'Arch', value: UnitUse.Arch },
    { label: 'F&B', value: UnitUse.Fb },
    { label: 'Managed', value: UnitUse.Managed }
];

const CONDITION_OPTIONS = [
    { label: 'Category A', value: UnitCondition.CategoryA },
    { label: 'Category A plus', value: UnitCondition.CategoryAplus },
    { label: 'Category B', value: UnitCondition.CategoryB },
    { label: 'Existing Fit Out', value: UnitCondition.ExistingFitOut },
    { label: 'Flex', value: UnitCondition.Flex },
    { label: 'Plug nd Play', value: UnitCondition.PlugAndPlay },
    { label: 'Ready', value: UnitCondition.Ready },
    { label: 'Shell and Core', value: UnitCondition.ShellAndCore },
    { label: 'Development', value: UnitCondition.Development },
    { label: 'Warm Shell', value: UnitCondition.WarmShell },
    { label: 'White-Box', value: UnitCondition.WhiteBox }
];

export const dealFilterConfig = (userType?: UserType): FilterConfig => [
    {
        id: 'buildingName',
        label: 'Building Name',
        type: FilterType.AsyncList,
        query: useGetDealBuildingNamesQuery,
        path: '[$distinct(deals.results.{ "value": unit.buildingName, "label": unit.buildingName })]'
    },
    {
        id: 'city',
        label: 'City',
        type: FilterType.AsyncList,
        query: useGetDealCitiesQuery,
        path: '[$distinct(deals.results.{ "value": unit.address.city, "label": unit.address.city })]'
    },
    {
        id: 'template',
        label: 'Deal Template',
        type: FilterType.AsyncList,
        query: useGetDealTemplatesQuery,
        path: '[$distinct(deals.results[template!=null].{ "value": template._id, "label": template.name })]'
    },
    {
        id: 'use',
        label: 'Use',
        type: FilterType.List,
        options: USE_OPTIONS
    },
    {
        id: 'condition',
        label: 'Condition',
        type: FilterType.List,
        options: CONDITION_OPTIONS
    },
    {
        id: 'creator',
        label: 'Creator',
        type: FilterType.AsyncList,
        query: useGetDealCreatorQuery,
        path: '[$distinct(deals.results[creator!=null].{ "value": creator._id, "label": creator.firstName & " " & creator.lastName})]'
    },
    {
        id: 'created',
        label: 'Date Created',
        type: FilterType.RangeDate
    },
    ...(userType && userType === UserType.Agent
        ? [
              {
                  id: 'client',
                  label: 'Client',
                  type: FilterType.AsyncList,
                  query: useGetDealOrganisationsQuery,
                  path: '[$distinct(deals.results.{ "value": client._id, "label": client.name })]'
              }
          ]
        : [])
];

export const getDealFields = (): RadioValue[] => [
    {
        id: DealSortFields.BuildingName,
        label: 'Building Name'
    },
    {
        id: DealSortFields.RoundCount,
        label: 'Round Count'
    },
    {
        id: DealSortFields.RoundTime,
        label: 'Round Time'
    },
    {
        id: DealSortFields.TotalTimeNegotiating,
        label: 'Total Time Negotiating'
    },
    {
        id: DealSortFields.Created,
        label: 'Date Created'
    }
];

export default Deals;
