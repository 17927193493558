import * as d3 from 'd3';
import textures from 'textures';

const DoughnutChart = (element, data) => {
    const colors = ['#40ddd5', '#87e9e3', '#c4f4f1'];
    const boxSize = 560;

    d3.select(element).select('svg').remove(); // Remove the old svg
    // Create new svg
    const svg = d3
        .select(element)
        .append('svg')
        .style('height', '80%')
        .style('width', '90%')
        .attr('viewBox', `0 0 ${boxSize} ${boxSize}`)
        .append('g')
        .attr('transform', `translate(${boxSize / 1.8}, ${boxSize / 1.8})`);

    var t = textures.lines().heavier(4).thinner(1.2).stroke('#87e9e3');

    svg.call(t);

    const arcGenerator = d3
        .arc()
        .innerRadius(100)
        .outerRadius((d) => {
            return d.index === 0 ? 250 : d.index === 1 ? 220 : 180;
        });

    const pieGenerator = d3.pie().value((d) => d.value);

    const arcs = svg.selectAll().data(pieGenerator(data)).enter();
    arcs.append('path')
        .attr('d', arcGenerator)
        .style('fill', t.url())
        .style('fill', (d, i) => (i === 1 ? t.url() : colors[i % data.length]))
        .transition()
        .duration(700)
        .attrTween('d', function (d) {
            const i = d3.interpolate(d.startAngle, d.endAngle);
            return function (t) {
                d.endAngle = i(t);
                return arcGenerator(d);
            };
        });
};

export default DoughnutChart;
