import _ from 'lodash';
import React, { useContext } from 'react';

import { ReactComponent as Check } from '../../assets/images/check/regularCheck/check.svg';
import { ReactComponent as FiltersIcon } from '../../assets/images/filters/sliders.svg';
import { ReactComponent as Search } from '../../assets/images/search/currentColor.svg';
import { ReactComponent as UploadIcon } from '../../assets/images/upload/gray.svg';
import {
    DealCardFragment,
    DealFilterCount,
    DealStatusFilter,
    useGetBuildingsAutofillDetailsLazyQuery,
    useGetTemplateCardDetailsLazyQuery,
    User
} from '../../generated/graphql';
import { AddButton } from '../../shared/add-button';
import { BulkSelectContext } from '../../shared/bulk/context';
import { Button } from '../../shared/button';
import { FilterContext } from '../../shared/Filter/context';
import SortChip from '../../shared/Filter/SortChip';
import { IconButton } from '../../shared/icon-button';
import Input, { InputType } from '../../shared/Inputs';
import { RadioValue } from '../../shared/Inputs/Radio';
import Select from '../../shared/Inputs/Select';

type DealsHeaderProps = {
    isSearching?: string;
    isMobile?: boolean | null;
    isTenant: boolean;
    searchDeals: (search: string) => void;
    user?: User;
    setShowNewDealWindow: React.Dispatch<React.SetStateAction<boolean>>;
    setDealStatus: React.Dispatch<React.SetStateAction<DealStatusFilter[] | undefined>>;
    dealFilterCounts?: DealFilterCount;
    isLiveFilter?: boolean;
    isLandlordOrAdmin?: boolean;
    dealStatus?: DealStatusFilter[];
    getDealFields: () => RadioValue[];
    deals?: DealCardFragment[];
    handleBulkClick: (id: string) => void;
    handleExportDeals: () => void;
};

const DealsHeader = ({
    isSearching,
    isMobile,
    isTenant,
    searchDeals,
    user,
    setShowNewDealWindow,
    setDealStatus,
    dealFilterCounts,
    isLiveFilter,
    isLandlordOrAdmin,
    getDealFields,
    deals,
    handleBulkClick,
    handleExportDeals
}: DealsHeaderProps) => {
    const { values, openWindow, sort, chips } = useContext(FilterContext);
    const { selectMode, saveSelectMode, selectAll, saveSelectAll, selectedItems, saveSelectedItems, all, deselect } =
        useContext(BulkSelectContext);

    const [loadBuildings] = useGetBuildingsAutofillDetailsLazyQuery();
    const [loadTemplates] = useGetTemplateCardDetailsLazyQuery();

    return (
        <>
            {!isTenant && (
                <>
                    <div className={`justify-between flex ${isMobile ? 'flex-col' : 'flex-row'}`}>
                        <div className="w-100">
                            <div className="flex items-start w-full">
                                <div
                                    className={`relative flex items-center  justify-center ${
                                        isMobile ? 'w-full' : 'w-64'
                                    }`}
                                >
                                    <Search
                                        className="w-6 h-6 absolute right-6 text-newGray-main z-20"
                                        style={{ top: '16px' }}
                                    />
                                    <div className="border-r border-newGray-main absolute h-8 z-20 right-15 " />
                                    <Input
                                        type={InputType.text}
                                        placeholder="Search"
                                        className={`h-15 mr-2 w-full`}
                                        autoFocus
                                        onChange={(e) => searchDeals(e.target.value)}
                                    />
                                </div>
                                {!isSearching && (
                                    <>
                                        <div className="border-r border-newGray-main h-12 my-auto z-20 mr-4 ml-2" />
                                        <Button
                                            background="white"
                                            text="newGray-darkish"
                                            height="15"
                                            className="hover:bg-navy-lightest border-none w-15"
                                            onClick={() => openWindow()}
                                        >
                                            <FiltersIcon />
                                        </Button>
                                        {!isMobile && (
                                            <div className="flex flex-row w-9/12 flex-wrap justify-start gap-1 ml-3">
                                                {sort && user && <SortChip sortOptions={getDealFields()} />}
                                                {values && chips()}
                                            </div>
                                        )}
                                    </>
                                )}
                                {!isSearching && (
                                    <div className="flex">
                                        {' '}
                                        <IconButton
                                            id="export-deals"
                                            icon={UploadIcon}
                                            tooltip="Export all deals"
                                            className={isMobile ? 'mx-2' : 'mr-4'}
                                            onClick={handleExportDeals}
                                        />
                                        <AddButton
                                            onHover={() => {
                                                loadBuildings();
                                                loadTemplates();
                                            }}
                                            onClick={() => {
                                                setShowNewDealWindow(true);
                                            }}
                                            text="black hover:text-white"
                                            height="15"
                                            className={`px-4 bg-newTeal-light hover:bg-newTeal-main border-none w-15 ${
                                                isMobile ? 'mb-2' : ''
                                            }`}
                                            id="deal-add-button"
                                            tooltip="New Deal"
                                        />
                                    </div>
                                )}
                            </div>
                            {isMobile && (
                                <div className="flex flex-row w-9/12 flex-wrap justify-start gap-1">
                                    {sort && user && <SortChip sortOptions={getDealFields()} />}
                                    {values && chips()}
                                </div>
                            )}
                            <div
                                className={`mt-6 ${!isSearching && 'mb-4'} flex items-center justify-between flex-wrap`}
                            >
                                {!isTenant && !isSearching && (
                                    <>
                                        <div>
                                            <Button
                                                background={`${isLiveFilter ? 'newGray-darkish' : 'white'} mr-2`}
                                                text={`${isLiveFilter ? 'white' : 'black'}`}
                                                className={`w-10-3 font-semibold ${!isLiveFilter && 'hover:shadow-lg'}`}
                                                onClick={() => setDealStatus([DealStatusFilter.Live])}
                                            >
                                                Live ({dealFilterCounts?.live})
                                            </Button>
                                            <Button
                                                background={`${isLiveFilter ? 'white' : 'newGray-darkish'} mr-2`}
                                                text={`${isLiveFilter ? 'black' : 'white'}`}
                                                className={`w-10-3 font-semibold ${isLiveFilter && 'hover:shadow-lg'}`}
                                                onClick={() => setDealStatus([DealStatusFilter.Complete])}
                                            >
                                                Completed ({dealFilterCounts?.complete})
                                            </Button>
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>

                    {selectMode && !_.isEmpty(selectedItems) && (
                        <>
                            <div className="w-full border-b border-newGray-main h-auto mb-4" />
                            <div className={`flex flex-row ${isMobile ? 'mb-2' : ''}`}>
                                <div
                                    className="mr-2 font-semibold w-10-3 hover:shadow-lg px-4 h-12 text-black w-auto bg-white text-sm md:text-lg inline-flex rounded items-center justify-center text-center cursor-pointer"
                                    onClick={() => {
                                        saveSelectedItems([]);
                                        deselect();
                                        saveSelectAll(!selectAll);
                                        if (all) {
                                            saveSelectMode(false);
                                            saveSelectAll(false);
                                        }
                                    }}
                                >
                                    <div
                                        className={`w-5 h-5 rounded z-20 mr-2 ${
                                            all ? 'bg-newTeal-main' : 'bg-navy-light'
                                        } `}
                                    >
                                        {all && <Check className="w-5 h-5 text-white" />}
                                    </div>
                                    {all ? 'Deselect All' : 'Select All'}
                                </div>
                                <Select
                                    isBulk
                                    data={
                                        isLandlordOrAdmin
                                            ? [
                                                  {
                                                      id: 'addUser',
                                                      text: 'Add User'
                                                  }
                                              ]
                                            : []
                                    }
                                    searchable={false}
                                    onItemClick={(id) => handleBulkClick(id!)}
                                    value={{
                                        value: 'placeholder',
                                        label: `Bulk Action (${
                                            deals?.length === selectedItems.length
                                                ? deals?.length
                                                : selectedItems.length
                                        })`
                                    }}
                                    className="h-12 text-white flex items-center justify-center w-10-3 text-sm md:text-lg"
                                />
                            </div>
                        </>
                    )}
                </>
            )}
        </>
    );
};

export default DealsHeader;
