import _ from 'lodash';
import React, { useContext, useEffect, useState } from 'react';

import CreateUser from './CreateUser';
import User from './User';
import { ReactComponent as External } from '../../../assets/images/external/black.svg';
import { ReactComponent as FiltersIcon } from '../../../assets/images/filters/sliders.svg';
import { ReactComponent as Search } from '../../../assets/images/search/black.svg';
import {
    OrderBy,
    useGetAllUsersQuery,
    useGetUserTypeQuery,
    UserSortFields,
    UserType,
    useUserFilterCountsQuery
} from '../../../generated/graphql';
import { Button } from '../../../shared/button';
import { FilterContext } from '../../../shared/Filter/context';
import { FilterConfig, FilterType, FilterWindow } from '../../../shared/Filter/FilterWindow';
import {
    transformFilters,
    transformToFilterUrlParams,
    transformToSortUrlParams,
    useUpdateUrl
} from '../../../shared/Filter/helpers';
import SortChip from '../../../shared/Filter/SortChip';
import { debounce, handleFetchError } from '../../../shared/helpers';
import useMobileScreen from '../../../shared/hooks/useMobileScreen';
import Input, { InputType } from '../../../shared/Inputs';
import { RadioValue } from '../../../shared/Inputs/Radio';
import Select from '../../../shared/Inputs/Select';
import Loading from '../../../shared/Loading';

export enum AccountType {
    ALL = 'All',
    ADMIN = 'Admin',
    AGENT = 'Agent',
    TENANT = 'Tenant',
    LANDLORD = 'Landlord'
}

const Users = () => {
    const { values, isWindowOpen, openWindow, sort, filters, chips } = useContext(FilterContext);

    const [usersData, setUsersData] = useState<UserType[] | undefined>();
    const [showCreateWindow, setShowCreateWindow] = useState<boolean>(false);
    const [onSuccess, setOnSuccess] = useState<boolean>(false);
    const {
        data,
        error,
        loading,
        refetch: searchUsers
    } = useGetAllUsersQuery({
        fetchPolicy: 'cache-and-network',
        variables: {
            sort: sort
                ? {
                      field: sort.field as UserSortFields,
                      order: sort.order ?? OrderBy.Desc
                  }
                : undefined,
            filter: {
                ...transformFilters(filters, values),
                ...(usersData ? { type: usersData } : {})
            }
        }
    });

    const { data: countsData, refetch: refetchCounts } = useUserFilterCountsQuery({
        fetchPolicy: 'cache-and-network',
        variables: {
            filter: {
                ...transformFilters(filters, values)
            }
        }
    });

    const isMobile = useMobileScreen();

    const options = [
        {
            value: AccountType.ALL,
            label: `${AccountType.ALL} (${countsData?.userFilterCounts.all ?? '...'})`
        },
        {
            value: AccountType.ADMIN,
            label: `${AccountType.ADMIN} (${countsData?.userFilterCounts.admin ?? '...'})`
        },
        {
            value: AccountType.LANDLORD,
            label: `${AccountType.LANDLORD} (${countsData?.userFilterCounts.landlord ?? '...'})`
        },
        {
            value: AccountType.AGENT,
            label: `${AccountType.AGENT} (${countsData?.userFilterCounts.agent ?? '...'})`
        },
        {
            value: AccountType.TENANT,
            label: `${AccountType.TENANT} (${countsData?.userFilterCounts.tenant ?? '...'})`
        }
    ];

    const [userTypeFilter, setUserTypeFilter] = useState<{
        value: string;
        label: string;
    }>({
        value: AccountType.ALL,
        label: `${AccountType.ALL} (...)`
    });

    useEffect(() => {
        setUserTypeFilter(options.find((option) => option.value === userTypeFilter?.value)!);
    }, [countsData]);

    const { updateUrl } = useUpdateUrl();
    useEffect(() => {
        const sortParams = sort ? transformToSortUrlParams(sort) : {};
        const filterParams = !_.isEmpty(values) ? transformToFilterUrlParams(filters, values) : {};

        updateUrl({
            ...filterParams,
            ...sortParams
        });
    }, [values, sort]);

    // Handle API response
    useEffect(() => {
        // Check for errors
        handleFetchError('Could not get all users', error, data?.users);
    }, [data, error]);

    useEffect(() => {
        switch (userTypeFilter.value) {
            case AccountType.ADMIN:
                setUsersData([UserType.Admin]);
                break;
            case AccountType.AGENT:
                setUsersData([UserType.Agent]);
                break;
            case AccountType.ALL:
                setUsersData(undefined);
                break;
            case AccountType.LANDLORD:
                setUsersData([UserType.Landlord]);
                break;
            case AccountType.TENANT:
                setUsersData([UserType.Tenant]);
        }
    }, [data, error, userTypeFilter]);

    const { data: typeData } = useGetUserTypeQuery();

    // Search users
    const handleChange = debounce((query?: string) => {
        searchUsers({ query });
        refetchCounts({ query });
    }, 250);

    useEffect(() => {
        if (onSuccess) {
            searchUsers();
            refetchCreate();
        }
    }, [onSuccess]);

    const refetchCreate = async () => {
        await searchUsers();
        await refetchCounts();
        setOnSuccess(false);
    };

    return (
        <>
            {showCreateWindow && <CreateUser onSuccess={setOnSuccess} setShow={setShowCreateWindow} />}
            <div className={`w-full ${isMobile ? 'px-4 pb-2' : 'p-6'} `}>
                {isWindowOpen && (
                    <FilterWindow config={usersFilterConfig(typeData?.user.type)} sortOptions={getAllUserFields()} />
                )}

                <div
                    className={`flex ${
                        isMobile ? 'flex-col' : 'flex-row'
                    }  w-full items-center pb-2 border-b border-newGray-main`}
                >
                    <Button
                        onClick={() => setShowCreateWindow(true)}
                        background="newTeal-main"
                        text="white"
                        height="12"
                        border="transparent"
                        className={`${
                            isMobile ? 'w-full ml-2 mb-2' : 'w-40 justify-around'
                        } px-4 mr-2 my-2 whitespace-no-wrap font-semibold flex items-center `}
                    >
                        <External className="w-4 -h-4 mr-2" />
                        Create User
                    </Button>
                    <div className="flex flex-row w-full">
                        <div className={`relative ${isMobile ? 'w-full' : 'w-100'}`}>
                            <Search className="w-5 h-5 absolute left-0 top-0 ml-4 z-20" style={{ top: '13px' }} />
                            <Input
                                type={InputType.text}
                                placeholder="Search Users"
                                className="w-full h-12 pl-12"
                                autoFocus
                                onChange={(e) => handleChange(e.target.value)}
                            />
                        </div>
                        <Button
                            background="white"
                            text="black"
                            className="filter-button-size ml-2"
                            onClick={() => openWindow()}
                        >
                            <FiltersIcon />
                        </Button>
                    </div>
                </div>

                <div className="flex mb-2  pt-2 items-center flex-wrap  pr-4">
                    <div className=" w-48" style={{ marginTop: '-16px' }}>
                        <Select
                            label={AccountType.ALL}
                            value={userTypeFilter}
                            onChange={(value) => setUserTypeFilter(value)}
                            data={options.map((option) => ({
                                id: option.value,
                                text: option.label
                            }))}
                        />
                    </div>
                    {sort && typeData?.user && <SortChip sortOptions={getAllUserFields()} />}
                    {values && !loading && chips && FilterContext && chips()}
                </div>

                {/* User List */}
                {loading ? (
                    <Loading />
                ) : (
                    data?.users?.map((user) => (
                        <User
                            popup={false}
                            id={user._id}
                            key={user._id}
                            email={user.email}
                            firstName={user.firstName}
                            lastName={user.lastName}
                            type={user.type}
                            organisation={user.organisation}
                            organisationId={user.organisation._id}
                            mobile={user.mobile}
                            locked={user.locked}
                            lastLogin={user.lastLogin}
                            activated={user.activated}
                            created={user.created}
                        />
                    ))
                )}
            </div>
        </>
    );
};

const LOCKED_OPTIONS = [
    { label: 'Yes', value: true },
    { label: 'No', value: false }
];

export const usersFilterConfig = (userType?: UserType): FilterConfig => [
    ...(userType && userType !== UserType.Agent
        ? [
              {
                  id: 'lastLogin',
                  label: 'Last Activity',
                  type: FilterType.RangeDate
              },
              {
                  id: 'created',
                  label: 'Date Created',
                  type: FilterType.RangeDate
              },
              {
                  id: 'locked',
                  label: 'Locked',
                  type: FilterType.RadioList,
                  options: LOCKED_OPTIONS
              }
          ]
        : [])
];

export const getAllUserFields = (): RadioValue[] => [
    { id: UserSortFields.CompanyName, label: 'Company Name' },
    { id: UserSortFields.FirstName, label: 'Name' },
    { id: UserSortFields.LastLogin, label: 'Last Activity' },
    { id: UserSortFields.Created, label: 'Date Created' }
];

export default Users;
