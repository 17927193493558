import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';

type DropzoneProps = {
    callback: (files?: FileList) => any;
    children?: React.ReactNode;
};

const Dropzone = ({ callback, children }: DropzoneProps) => {
    const onDrop = useCallback((acceptedFiles) => {
        callback(acceptedFiles as FileList);
    }, []);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        noClick: true,
        onDrop
    });

    return (
        <div
            {...getRootProps()}
            className={`inline-block relative rounded border border-navy-light p-2 bg-gray-200 w-full h-56 hover:border-newTeal-main transition-colors duration-150`}
        >
            <input {...getInputProps()} />
            {isDragActive && (
                <div className="border-2 border-dashed border-navy-light bg-white opacity-75 absolute top-0 bottom-0 left-0 right-0 z-60">
                    <div className="absolute top-1/2 right-0 left-0 text-center text-gray-800 text-xl">
                        Drop files here
                    </div>
                </div>
            )}

            {children}
        </div>
    );
};

export default Dropzone;
