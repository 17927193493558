import React, { useRef } from 'react';

export type RadioValue = {
    label: string;
    id: string;
};

type RadioProps = {
    setChecked?: React.Dispatch<React.SetStateAction<RadioValue | undefined>> | ((value: RadioValue) => void);
    checked?: RadioValue;
    value: RadioValue;
};

const Radio = ({ setChecked, checked, value }: RadioProps) => {
    const isChecked = value.id === checked?.id;
    const inputRef = useRef<HTMLInputElement>(null);
    return (
        <div
            onClick={() => inputRef.current?.click()}
            className="flex justify-between items-center border-b px-3 py-4 cursor-pointer"
            style={{
                minWidth: 400
            }}
        >
            <p>{value.label}</p>
            <input
                className="hidden"
                ref={inputRef}
                type="radio"
                checked={isChecked}
                onChange={() => setChecked && setChecked(value)}
            />
            <div
                className={`w-5 h-5 rounded-full border transition-all duration-300 flex justify-center items-center  ${
                    isChecked ? 'border-newTeal-main' : ''
                }`}
            >
                <div
                    className={`w-4 h-4 rounded-full transition-all duration-300 ${
                        isChecked ? 'bg-newTeal-main' : 'bg-transparent'
                    }`}
                ></div>
            </div>
        </div>
    );
};

export default Radio;
