import { isNil } from 'lodash';
import React, { useContext } from 'react';

import { ReadinessContext, STAGE } from './context';
import AMLCheck from './Stages/AML';
import Covenant from './Stages/Covenant';
import Final from './Stages/Final';
import Lawyer from './Stages/Lawyer';
import Team from './Stages/Team';
import TenantDetails from './Stages/TenantDetails';

export const Readiness = () => {
    const { stage, clear } = useContext(ReadinessContext);

    const renderStage = () => {
        switch (stage) {
            case STAGE.TENANT_INFORMATION:
                return <TenantDetails />;
            case STAGE.LAWYER:
                return <Lawyer />;
            case STAGE.TEAM:
                return <Team />;
            case STAGE.COVENANT:
                return <Covenant />;
            case STAGE.FINAL_INFO:
                return <Final />;
            case STAGE.AML_CHECK:
                return <AMLCheck />;
            default:
                return null;
        }
    };

    return (
        <div className={`fixed top-0 w-full  h-screen z-50 flex flex-row ${!isNil(stage) ? '' : 'hidden'}`}>
            <div className="h-full  z-60 bg-white pl-2/12 -ml-6 opacity-100 ">
                <div className="flex w-23 h-full mx-auto overflow-y-auto justify-end py-0 pl-6 pr-8">
                    {renderStage()}
                </div>
            </div>
            <div
                onClick={clear}
                className={`fixed top-0 left-0 w-full h-screen bg-black z-40`}
                style={{ backgroundColor: 'rgba(0,0,0,0.3)' }}
            />
        </div>
    );
};
