import { useApolloClient } from '@apollo/client';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';

import { useResend2FaMutation, useTwoFaMutation } from '../../generated/graphql';
import { Button } from '../../shared/button';
import { useQuery } from '../../shared/helpers';
import Input, { InputType } from '../../shared/Inputs';

const TwoFA = () => {
    const history = useHistory();
    const client = useApolloClient();
    const query = useQuery();
    const email = sessionStorage.getItem('email') || '';
    const [error, setError] = useState<string | null>();

    const [token, setToken] = useState<string | undefined>();

    const [perform2FA] = useTwoFaMutation();
    const [resend2FACode] = useResend2FaMutation();

    const handleVerify = async () => {
        if (!token) {
            setError('Please enter the latest code received in your inbox');
            return;
        }

        if (token.length !== 6) {
            setError('Please enter a valid code');
            return;
        }

        try {
            const { data } = await perform2FA({
                variables: { email, token }
            });

            await client.resetStore();

            const previous = query.get('prev');
            if (!data?.user2FA?.activated) {
                return history.push(`/restricted${previous ? 'prev=' + previous : ''}`); //if account not activated, send them to the restricted screen
            }

            // Redirect to previous route if given

            if (previous) return history.push(previous);

            return history.push('/');
        } catch (e) {
            setError('This code has expired, select <b>Resend</b> to request a new code.');
        }
    };

    const handleResend = async () => {
        await resend2FACode({ variables: { email } });

        Swal.fire({
            title: 'Success!',
            text: 'A new verification code has been sent to your email.',
            icon: 'success',
            iconColor: '#3CA48E',
            confirmButtonText: 'Continue',
            confirmButtonColor: '#3CA48E'
        });
    };

    return (
        <div className="w-1/3">
            <p className="font-bold mb-2">Two Factor Authentication</p>
            <p>A verification code has been sent to your email. This code will be valid for 10 mins.</p>
            <Input
                type={InputType.text}
                placeholder="Verification Code"
                className="w-full bg-navy-lightest m-4"
                autoFocus
                value={token}
                onChange={(e) => setToken(e.target.value)}
            />
            {error && (
                <div className="bg-red-200 border border-red-600 rounded py-2 px-4 my-4 mt-2">
                    <span className="text-center text-red-600" dangerouslySetInnerHTML={{ __html: error }} />
                </div>
            )}
            <div className="flex items-center justify-center mt-2">
                <Button
                    background="navy-lightest border-navy-light mr-2"
                    text="black"
                    className="w-full font-semibold"
                    onClick={handleResend}
                >
                    Resend
                </Button>
                <Button background="newTeal-main" text="white" className="w-full font-semibold" onClick={handleVerify}>
                    Verify
                </Button>
            </div>
        </div>
    );
};

export default TwoFA;
