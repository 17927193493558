import React, { useState } from 'react';

import Action from './Action';
import { ReactComponent as Minus } from '../../../../../assets/images/minus/black.svg';
import { ReactComponent as Plus } from '../../../../../assets/images/plus/black.svg';
import {
    AdminTaskType,
    DocType,
    GetUnitRatingDocument,
    UnitValue,
    useActionTaskMutation
} from '../../../../../generated/graphql';
import { Button } from '../../../../../shared/button';
import { handleFetchError } from '../../../../../shared/helpers';
import ConfirmDeleteFileWindow from '../../ConfirmDeleteFileWindow';

import { TaskAction, TaskState } from '.';

type TaskProps = {
    id: string;
    optional: boolean;
    unitID: string;
    title: string;
    index: number;
    applicable: boolean | null | undefined;
    value?: UnitValue | null;
    action: AdminTaskType;
    expanded?: boolean;
    cardView?: boolean;
    taskValues: TaskState;
    setTaskValues: React.Dispatch<TaskAction>;
    onUploadClick: (id: string) => void;
};

const Task = ({
    id,
    unitID,
    title,
    value,
    index,
    action,
    applicable,
    optional,
    expanded,
    cardView,
    taskValues,
    onUploadClick,
    setTaskValues
}: TaskProps) => {
    const [actionTask, { error }] = useActionTaskMutation();
    const [editing, setEditing] = useState<boolean>(false);
    const [showDeleteFileWindow, setShowDeleteFileWindow] = useState(false);

    const [showMobileInput, setShowMobileInput] = useState<boolean>(false);

    const toggleApplicable = async () => {
        const { data } = await actionTask({
            variables: {
                taskID: id,
                unitID,
                applicable: !applicable
            },
            refetchQueries: [
                {
                    query: GetUnitRatingDocument,
                    variables: {
                        unitID
                    }
                }
            ]
        });

        if (error) return handleFetchError('Failed to action the task', error, data?.unitTaskAction);
    };

    return (
        <>
            {showDeleteFileWindow && value?.__typename === 'File' && (
                <ConfirmDeleteFileWindow
                    type={DocType.Unit}
                    fileId={value._id}
                    unitId={unitID}
                    setWindow={setShowDeleteFileWindow}
                />
            )}
            {/* List View */}
            {!cardView ? (
                <div
                    data-index={index}
                    className={`border-b-2 p-2 bg-white md:h-20 h-auto w-full flex md:flex-row flex-col justify-between md:items-center items-start
                ${index > 5 && !expanded ? `hidden` : 'flex'}
                ${(!applicable || (value != null && !editing)) && 'opacity-50'}`}
                >
                    <div className="flex justify-between items-center w-full">
                        <h3 className="font-semibold flex-1">{title}</h3>
                        <Button
                            onClick={() => setShowMobileInput(!showMobileInput)}
                            className="md:hidden mt-1 bg-gray-200 border border-white w-12"
                        >
                            {showMobileInput ? <Minus /> : <Plus />}
                        </Button>
                    </div>
                    <div className={`flex flex-row items-center md:mt-0 mt-3 md:w-1/4 w-full`}>
                        <Action
                            id={id}
                            unitID={unitID}
                            type={action}
                            value={value}
                            setEditing={setEditing}
                            onUploadClick={onUploadClick}
                            taskValues={taskValues}
                            setTaskValues={setTaskValues}
                        />
                        {optional && (
                            <Button
                                onClick={() => toggleApplicable()}
                                className="ml-3 font-bold p-3"
                                background={!applicable ? 'navy-light' : undefined}
                                text="black"
                            >
                                N/A
                            </Button>
                        )}
                    </div>
                </div>
            ) : (
                // Cards View
                <div
                    className={`flex-grow w-72 mb-2 sm:mr-2 p-3 bg-white rounded h-40 flex flex-col justify-between ${
                        (!applicable || (value != null && !editing)) && 'opacity-50'
                    }
                    `}
                >
                    <h3 className="font-medium md:h-1/2 w-3/4 h-auto overflow-hidden">{title}</h3>
                    <div className={`flex flex-row items-center md:mt-0 mt-3`}>
                        <Action
                            id={id}
                            unitID={unitID}
                            type={action}
                            value={value}
                            setEditing={setEditing}
                            onUploadClick={onUploadClick}
                            taskValues={taskValues}
                            setTaskValues={setTaskValues}
                        />
                        {optional && (
                            <Button
                                onClick={() => toggleApplicable()}
                                className="ml-3 font-semibold py-3 px-2 border border-navy-light rounded"
                                background={!applicable ? 'white' : undefined}
                                text="black"
                            >
                                N/A
                            </Button>
                        )}
                    </div>
                </div>
            )}
        </>
    );
};

export default Task;
