import React from 'react';

import useMobileScreen from '../../../shared/hooks/useMobileScreen';

type LayoutProps = {
    children: React.ReactNode;
    sidebar: React.ReactNode;
    show?: boolean;
    scrollable?: boolean;
    className?: string;
    wrap?: boolean;
};

const SidebarWrapper = ({ children, sidebar, show = true, scrollable = true, className, wrap }: LayoutProps) => {
    const isMobile = useMobileScreen();

    const contentColumns = show && !isMobile && !wrap ? 'col-span-6 ' : 'col-span-7';

    return (
        <div className={`grid h-full grid-cols-7 ${className}`}>
            {!isMobile && show && !wrap && <div className="col-span-1">{sidebar}</div>}
            <div
                className={`transition-all ease-in-out duration-500 ${contentColumns} max-h-full-content ${
                    scrollable ? 'overflow-y-auto' : ''
                }`}
                id="content"
            >
                {children}
            </div>
        </div>
    );
};

export default SidebarWrapper;
