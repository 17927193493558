import qs from 'qs';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import {
    useGetUserOrganisationQuery,
    useNotificationsQuery,
    useProcessPracticeInviteMutation,
    useReadNotificationMutation
} from '../../../generated/graphql';
import { useLogout } from '../../../shared/hooks/useLogout';
import Loading from '../../../shared/Loading';
import Window from '../../../shared/Window';

enum PracticeInviteResponse {
    ACCEPT = 'ACCEPT',
    DECLINE = 'DECLINE'
}

type SwitchPracticeWindowProps = {
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
    refetch: () => void;
};

const SwitchPracticeWindow = ({ setShow, refetch }: SwitchPracticeWindowProps) => {
    const history = useHistory();

    const { data: userOrganisationData, loading: userOrganisationLoading } = useGetUserOrganisationQuery({
        fetchPolicy: 'network-only'
    });

    const { data: notificationsData, loading: notificationsLoading } = useNotificationsQuery();

    const [processPracticeInvite] = useProcessPracticeInviteMutation();

    const { logout } = useLogout();

    const [twoStepConfirmation, setTwoStepConfirmation] = useState<boolean>(true);

    const unseenInvites = notificationsData?.notifications.notifications.filter(
        (notification) => notification.read === false && notification.type === 'INVITE'
    );

    const [readNotification] = useReadNotificationMutation();

    const handleProcessPracticeInvite = async (inviteID: string, response: string) => {
        await processPracticeInvite({
            variables: {
                inviteID,
                response
            },
            onCompleted: () => {
                unseenInvites && unseenInvites?.length > 0 && handleReadClick(unseenInvites[0]._id);
                if (response === PracticeInviteResponse.ACCEPT) {
                    logout();
                } else {
                    history.push('/');
                    refetch();
                }
                setShow(false);
            }
        });
    };

    const handleReadClick = async (notificationID: string) => {
        await readNotification({
            variables: {
                notificationID
            },
            optimisticResponse: {
                userReadNotification: {
                    __typename: 'Notification',
                    _id: notificationID,
                    read: true
                }
            }
        });
    };

    const onClose = () => {
        setShow(false);
        unseenInvites && unseenInvites?.length > 0 && handleReadClick(unseenInvites[0]._id);
        history.push('/');
    };

    const isLoading = userOrganisationLoading || notificationsLoading;

    const { practiceInvite } = qs.parse(window.location.search.slice(1));
    const invite = userOrganisationData?.user.practiceInvites?.find((inv) => inv._id === practiceInvite);

    return (
        <Window
            title="Switch Practice"
            setShow={setShow}
            width="w-1/3"
            titleCenter
            primaryBtnText={twoStepConfirmation ? 'Yes' : 'Confirm'}
            primaryBtn={!!invite}
            onPrimaryBtnClick={() =>
                twoStepConfirmation
                    ? setTwoStepConfirmation(false)
                    : handleProcessPracticeInvite(invite?._id!, PracticeInviteResponse.ACCEPT)
            }
            secondaryBtnText={twoStepConfirmation ? 'No' : 'Cancel'}
            secondaryBtn={!!invite}
            onSecondaryBtnClick={() => handleProcessPracticeInvite(invite?._id!, PracticeInviteResponse.DECLINE)}
            tertiaryBtn={!invite}
            onTertiaryBtnClick={() => onClose()}
            tertiaryBtnText="Close"
            onCloseClick={() => {
                onClose();
                refetch();
            }}
        >
            <div className="flex flex-center flex-wrap  justify-center px-12">
                {isLoading && <Loading />}
                {!isLoading && !invite && (
                    <div className="flex flex-wrap flex-center flex-col">
                        <p className="text-newGray-darkish py-3 mt-3 mb-2 text-center">
                            Invitation is not valid anymore
                        </p>
                    </div>
                )}
                {!isLoading && invite && !twoStepConfirmation && (
                    <div className="flex flex-wrap flex-center flex-col">
                        <p className="text-newGray-darkish py-3 mt-3 text-center">
                            By switching practice you will be removed from your current one
                        </p>
                        <p className="font-semibold py-1 text-center">
                            "{userOrganisationData?.user.organisation.name}"
                        </p>
                        <p className="text-newGray-darkish mb-7  pt-3 text-center">
                            and it will be deleted. Please confirm you are happy to switch?"
                        </p>
                    </div>
                )}
                {!isLoading && invite && twoStepConfirmation && (
                    <div className="flex flex-wrap flex-center flex-col">
                        <p className="text-newGray-darkish py-3 mt-3 text-center">You have been invited to join </p>
                        <p className="font-semibold py-1 text-center">
                            "
                            {userOrganisationData?.user.practiceInvites &&
                                userOrganisationData?.user.practiceInvites.find(
                                    (singlePracticeInvite) => singlePracticeInvite._id === practiceInvite
                                )?.organisation.name}
                            "
                        </p>
                        <p className="text-newGray-darkish mb-7  pt-3 text-center">
                            Would you like to leave your current practice?"
                        </p>
                    </div>
                )}
            </div>
        </Window>
    );
};

export default SwitchPracticeWindow;
