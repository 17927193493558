import React, { useState } from 'react';

import { AdminTaskType, UnitUse, useNewTaskMutation } from '../../../generated/graphql';
import { handleFetchError, humanizeString } from '../../../shared/helpers';
import Input, { InputType } from '../../../shared/Inputs/index';
import Select from '../../../shared/Inputs/Select';
import Loading from '../../../shared/Loading';
import { UseConditionToText } from '../../../shared/NewUnit/hooks/useGetUnitSelectData';
import { FixedTags } from '../../../shared/Tags';
import Window from '../../../shared/Window';

type AddTaskWindowProps = {
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
};

const AddTaskWindow = ({ setShow }: AddTaskWindowProps) => {
    const [taskName, setTaskName] = useState<string>('');
    const [taskWeight, setTaskWeight] = useState<string>('');
    const [taskIndex, setTaskIndex] = useState<string>('');
    const [taskTags, setTaskTags] = useState<string[]>([]);
    const [taskCategory, setTaskCategory] = useState<string>('Practical');
    const [taskType, setTaskType] = useState<AdminTaskType>(AdminTaskType.Boolean);
    const [associatedUses, setAssociatedUses] = useState<UnitUse[]>();
    const [taskOptional, setTaskOptional] = useState<boolean>(false);
    const [formError, setFormError] = useState<string>('');

    const handleChangeTaskType = (id: string) => {
        switch (id) {
            case 'confirm':
                setTaskType(AdminTaskType.Boolean);
                break;
            case 'file':
                setTaskType(AdminTaskType.File);
                break;
            case 'integer':
                setTaskType(AdminTaskType.Integer);
                break;
            case 'text':
                setTaskType(AdminTaskType.Text);
                break;
            default:
                setTaskType(AdminTaskType.Boolean);
        }
    };

    const [newTask, { loading, error }] = useNewTaskMutation();

    const handleSubmit = async () => {
        let activeErrors = false;

        if (taskName === '') {
            setFormError('Task name must not be empty');
            activeErrors = true;
        } else if (isNaN(+taskWeight) || taskWeight === '') {
            setFormError('Weight must be a valid number');
            activeErrors = true;
        } else if (isNaN(+taskIndex) || taskIndex === '') {
            setFormError('Index must be a valid number');
            activeErrors = true;
        } else if (+taskWeight > 10) {
            setFormError('Weight must not be bigger than 10');
            activeErrors = true;
        } else if (!taskCategory || taskCategory === '') {
            setFormError('A valid category must be selected');
            activeErrors = true;
        } else if (!associatedUses || associatedUses.length === 0) {
            setFormError('Associated uses must be selected');
            activeErrors = true;
        } else {
            setFormError('');
            activeErrors = false;
        }

        if (!activeErrors) {
            let mergedTags = [...taskTags, ...[`category:${taskCategory}`]];

            const { data } = await newTask({
                variables: {
                    title: taskName,
                    index: +taskIndex,
                    weight: +taskWeight,
                    type: taskType,
                    tags: mergedTags,
                    optional: taskOptional,
                    associatedUses
                },
                update(cache, { data }) {
                    if (!data) return;

                    cache.modify({
                        fields: {
                            tasks(existingTasks = []) {
                                return [...existingTasks, data.adminTaskCreate];
                            }
                        }
                    });
                }
            });

            if (error) {
                return handleFetchError('Failed to create task', error, data?.adminTaskCreate);
            }

            setShow(false);
        }
    };

    if (loading)
        return (
            <Window title="New Readiness Task" width="w-2/5" setShow={setShow}>
                <Loading />
            </Window>
        );

    return (
        <Window
            title="New Readiness Task"
            width="w-2/5"
            primaryBtn
            primaryBtnText="Create"
            onPrimaryBtnClick={handleSubmit}
            setShow={setShow}
        >
            <div>
                <form className="flex flex-col" onSubmit={(e) => e.preventDefault()}>
                    <div className="flex flex-row items-center content-start w-full p-2">
                        <Input
                            type={InputType.text}
                            autoFocus
                            className="md:mr-2 bg-navy-lightest w-full md:w-3/5 mb-2 md:mb-auto"
                            label="Name"
                            placeholder="Task name"
                            value={taskName}
                            onChange={(e) => setTaskName(e.target.value)}
                        />
                    </div>
                    <div className="flex flex-row items-center content-start w-full p-2">
                        <Select
                            data={[
                                { id: 'boolean', text: 'Confirm' },
                                { id: 'file', text: 'Upload' },
                                { id: 'text', text: 'Entry of text' },
                                { id: 'integer', text: 'Number' }
                                // { id: "date", text: "Date" }
                            ]}
                            defaultValue={{
                                label: 'Confirm',
                                value: 'boolean'
                            }}
                            label="Action"
                            className="md:mr-2 bg-navy-lightest w-full md:w-3/5 mb-2 md:mb-auto"
                            onItemClick={(id) => id && handleChangeTaskType(id)}
                        />
                    </div>
                    <div className="flex flex-row items-center content-start w-full p-2">
                        <Input
                            type={InputType.text}
                            className="md:mr-2 bg-navy-lightest w-full md:w-3/5 mb-2 md:mb-auto"
                            placeholder="Task weight (max 10)"
                            label="Weight"
                            value={taskWeight}
                            onChange={(e) => setTaskWeight(e.target.value)}
                        />
                    </div>
                    <div className="flex flex-row items-center content-start w-full p-2">
                        <Input
                            type={InputType.text}
                            className="md:mr-2 bg-navy-lightest w-full md:w-3/5 mb-2 md:mb-auto"
                            placeholder="Task index"
                            label="Index"
                            value={taskIndex}
                            onChange={(e) => setTaskIndex(e.target.value)}
                        />
                    </div>
                    <div className="flex flex-row items-center content-start w-full p-2">
                        <Select
                            data={Object.values(FixedTags)}
                            label="Tags"
                            placeholder="Select tags"
                            isMulti
                            createable
                            className="md:mr-2 bg-navy-lightest w-full md:w-3/5 mb-2 md:mb-auto"
                            onChange={(value) =>
                                setTaskTags(Array.isArray(value) ? value.map((task) => task.value) : [])
                            }
                        />
                    </div>

                    <div className="flex flex-row items-center content-start w-full p-2">
                        <Select
                            data={Object.values(UnitUse).map((use) => {
                                return {
                                    text: UseConditionToText(use),
                                    id: use
                                };
                            })}
                            label="Designated Uses"
                            placeholder="Select use cases"
                            isMulti
                            className="md:mr-2 bg-navy-lightest w-full md:w-3/5 mb-2 md:mb-auto"
                            onChange={(value: any) => {
                                if (!value || value.length === 0) {
                                    setAssociatedUses([]);
                                    return;
                                }

                                const uses = value.map((item: any) => {
                                    return UnitUse[humanizeString(item.value)];
                                });

                                setAssociatedUses(uses);
                            }}
                        />
                    </div>
                    <div className="flex flex-row items-center content-start w-full p-2">
                        <Select
                            data={[
                                { id: 'Practical', text: 'Practical' },
                                { id: 'Building', text: 'Building' },
                                { id: 'Cost', text: 'Cost' },
                                { id: 'Legals', text: 'Legals' }
                            ]}
                            label="Category"
                            placeholder="Select category"
                            createable
                            className="md:mr-2 bg-navy-lightest w-full md:w-3/5 mb-2 md:mb-auto"
                            onChange={(value: any) => {
                                setTaskCategory(value.value);
                            }}
                        />
                    </div>
                    <div className="flex flex-row items-center content-start w-full p-2">
                        <input
                            type="checkbox"
                            id="optional"
                            defaultChecked={taskOptional}
                            onChange={() => setTaskOptional(!taskOptional)}
                            className="form-checkbox p-2 rounded-sm bg-white text-newTeal-main border border-newGray-darkish focus:shadow-none focus:outline-none"
                        />
                        <label htmlFor="optional" className="text-sm text-gray-600 ml-2">
                            Provide a N/A option
                        </label>
                    </div>
                    {formError !== '' && (
                        <div className="bg-red-200 border border-red-600 rounded py-2 px-4 mx-2 mt-4">
                            <p className="text-red-600">{formError}</p>
                        </div>
                    )}
                </form>
            </div>
        </Window>
    );
};

export default AddTaskWindow;
