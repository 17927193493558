import { Field } from 'formik';
import React from 'react';

type CheckboxProps = {
    name: string;
    label: string;
    checked?: boolean;
    onChange?: () => void;
};

const Checkbox = ({ label, name, ...props }: CheckboxProps) => {
    return (
        <div className="flex flex-row items-center my-2 ">
            <Field
                name={name}
                className="form-checkbox p-2 mr-2 rounded-sm bg-gray-200 text-newTeal-main focus:outline-none"
                type="checkbox"
                {...props}
            />
            <p className="text-xxs font-medium">{label}</p>
        </div>
    );
};

export default Checkbox;
