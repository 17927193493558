import { format } from 'date-fns';
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';

import DeleteTemplateWindow from './DeleteTemplateWindow';
import DuplicateTemplateWindow from './DuplicateTemplateWindow';
import RestoreTemplateWindow from './RestoreTemplateWindow';
import { ReactComponent as Clock } from '../../assets/images/clock/gray.svg';
import { ReactComponent as Globe } from '../../assets/images/globe/black.svg';
import { ReactComponent as Info } from '../../assets/images/info/infoWhite.svg';
import {
    GetTemplateCardDetailsDocument,
    TemplateStatusFilter,
    UnitUse,
    useDeleteTemplateMutation,
    useGetUserTypeQuery,
    useRestoreTemplateMutation,
    UserType
} from '../../generated/graphql';
import { CardDropdownOptions } from '../../shared/Grid/CardDropdown';
import { handleFetchError } from '../../shared/helpers';
import useScreenWidth from '../../shared/hooks/useScreenWidth';
import Loading from '../../shared/Loading';
import { UseConditionToText } from '../../shared/NewUnit/hooks/useGetUnitSelectData';
import Window from '../../shared/Window';
import Card from '../../v2/components/Card';

type TemplateCardProps = {
    id: string;
    name?: string;
    userName?: string;
    description?: string;
    created?: Date | string;
    global?: boolean;
    associatedUses?: UnitUse[] | null;
    hasAccess?: boolean;
    refetch: () => void;
    organisation?: string;
    countFilterValue?: TemplateStatusFilter[];
    countsRefetch: () => void;
    isHidden?: boolean;
};

const TemplateCard = ({
    id,
    name,
    userName,
    description,
    associatedUses,
    created,
    global,
    hasAccess = true,
    refetch,
    organisation,
    countFilterValue,
    countsRefetch,
    isHidden
}: TemplateCardProps) => {
    const screenWidth = useScreenWidth();

    // Delete template mutation
    const [showDuplicateWindow, setShowDuplicateWindow] = useState<boolean>(false);
    const [hover, setHover] = useState<boolean>(false);

    const [showRestoreWindow, setShowRestoreWindow] = useState<boolean>(false);

    const [twoStepConfirmation, setTwoStepConfirmation] = useState<boolean>(true);

    const [permanentlyDeleteWindow, setPermanentlyDeleteWindow] = useState<boolean>(false);

    // Get User Type
    const { data: typeData } = useGetUserTypeQuery();

    const [deleteTemplate, { error, loading }] = useDeleteTemplateMutation();

    const [restoreTemplate, { error: restoreError, loading: restoreLoading }] = useRestoreTemplateMutation();

    const [deleteTemplateWindow, setDeleteTemplateWindow] = useState<boolean>(false);

    const handleRestoreClick = async () => {
        const { data } = await restoreTemplate({
            variables: {
                templateID: id
            },
            refetchQueries: [
                {
                    query: GetTemplateCardDetailsDocument
                }
            ]
        });
        if (restoreError) {
            handleFetchError('Failed to delete the template', restoreError, data?.templateRestore);
            return;
        }
        refetch();
        countsRefetch();
    };

    const handleDeleteClick = async () => {
        const { data } = await deleteTemplate({
            variables: {
                templateID: id,
                permanent: countFilterValue?.includes(TemplateStatusFilter.Bin) ?? false
            },
            refetchQueries: [
                {
                    query: GetTemplateCardDetailsDocument
                }
            ]
        });
        if (error) {
            handleFetchError('Failed to delete the template', error, data?.templateDelete);
            return;
        }
        refetch();
        countsRefetch();
    };

    const options: CardDropdownOptions = countFilterValue?.includes(TemplateStatusFilter.Live)
        ? [
              {
                  title: 'Duplicate',
                  onClick: async () => {
                      setShowDuplicateWindow(true);
                  }
              },
              {
                  title: 'Delete',
                  onClick: () => {
                      setDeleteTemplateWindow(true);
                  }
              }
          ]
        : [
              {
                  title: 'Restore',
                  onClick: async () => {
                      setShowRestoreWindow(true);
                  }
              },
              {
                  title: 'Permanently Delete',
                  onClick: () => {
                      setPermanentlyDeleteWindow(true);
                      setTwoStepConfirmation(true);
                  }
              }
          ];
    const isNameSingleLine = name && name?.length < 20;
    const [visibleUsesLength, setVisibleUsesLength] = useState<number>();
    const [hiddenUsesText, setHiddenUsesText] = useState<string[]>();

    useEffect(() => {
        if (!screenWidth) return;
        if (isNameSingleLine) {
            switch (true) {
                case screenWidth <= 1200 && screenWidth >= 768:
                    setVisibleUsesLength(5);
                    break;
                case screenWidth > 1200 && screenWidth <= 1460:
                    setVisibleUsesLength(6);
                    break;
                default:
                    setVisibleUsesLength(0);
                    break;
            }
        } else {
            switch (true) {
                case screenWidth >= 768 && screenWidth <= 830:
                    setVisibleUsesLength(3);
                    break;
                case screenWidth <= 1270 && screenWidth > 830:
                    setVisibleUsesLength(4);
                    break;
                case screenWidth > 1270 && screenWidth <= 1460:
                    setVisibleUsesLength(5);
                    break;
                case screenWidth > 1460 && screenWidth <= 1690:
                    setVisibleUsesLength(6);
                    break;
                default:
                    setVisibleUsesLength(0);
                    break;
            }
        }
    }, [screenWidth]);

    const visibleUses = visibleUsesLength ? associatedUses?.slice(0, visibleUsesLength) : associatedUses;
    const hiddenUses = visibleUsesLength ? associatedUses?.slice(visibleUsesLength) : undefined;

    useEffect(() => {
        let uses: string[] = [];
        hiddenUses?.map((use) => uses?.push(` ${UseConditionToText(use)}`));
        if (uses) {
            setHiddenUsesText(uses);
        }
    }, [visibleUsesLength]);

    return (
        <>
            {permanentlyDeleteWindow && (
                <Window
                    title="Permanently Delete"
                    setShow={setPermanentlyDeleteWindow}
                    width="w-1/3"
                    titleCenter
                    primaryBtnText={twoStepConfirmation ? 'Yes' : 'Confirm'}
                    primaryBtn
                    onPrimaryBtnClick={() =>
                        twoStepConfirmation
                            ? setTwoStepConfirmation(false)
                            : (setPermanentlyDeleteWindow(false), handleDeleteClick())
                    }
                    secondaryBtnText={twoStepConfirmation ? 'No' : 'Cancel'}
                    secondaryBtn
                    onSecondaryBtnClick={() => setPermanentlyDeleteWindow(false)}
                >
                    <div className="flex flex-center flex-wrap  justify-center px-12">
                        {twoStepConfirmation ? (
                            <div className="flex flex-wrap flex-center flex-col">
                                <p className="text-newGray-darkish py-3 mt-3 mb-2 text-center">
                                    Are you sure you want to permanently
                                    <span className="font-semibold text-uppercase"> delete </span> this template? If
                                    yes, this template will be
                                    <span className="font-semibold text-uppercase"> deleted </span> for you and all
                                    users with access to it?
                                </p>
                            </div>
                        ) : (
                            <div className="flex flex-wrap flex-center flex-col">
                                <p className="text-newGray-darkish py-3 mt-3 text-center">
                                    Select <span className="font-semibold text-uppercase"> Confirm </span> to
                                    permanently <span className="font-semibold text-uppercase"> delete </span> this
                                    template. This process is irreversible.
                                </p>
                            </div>
                        )}
                    </div>
                </Window>
            )}
            {deleteTemplateWindow && (
                <DeleteTemplateWindow
                    setDeleteTemplateWindow={setDeleteTemplateWindow}
                    handleDeleteClick={handleDeleteClick}
                />
            )}
            {showDuplicateWindow && (
                <DuplicateTemplateWindow
                    name={name ? 'Copy of ' + name : undefined}
                    description={description ? description : undefined}
                    refetch={refetch}
                    id={id}
                    setShow={setShowDuplicateWindow}
                />
            )}
            {showRestoreWindow && (
                <RestoreTemplateWindow
                    setRestoreTemplateWindow={setShowRestoreWindow}
                    handleRestoreClick={handleRestoreClick}
                />
            )}
            <Card height="60" isHidden={isHidden} options={hasAccess ? options : [options[0]]}>
                {loading || restoreLoading ? (
                    <Loading />
                ) : (
                    <Link
                        to={`/templates/${id}`}
                        onClick={(e) => !hasAccess && e.preventDefault()}
                        onMouseEnter={() => !hasAccess && setHover(true)}
                        onMouseLeave={() => setHover(false)}
                        className={`relative flex flex-col justify-between w-full h-60 ${
                            !hasAccess && 'cursor-auto opacity-50'
                        }`}
                    >
                        {global && (
                            <Globe className="w-5 h-5 ml-2 mt-2 stroke-current text-newTeal-main opacity-75 absolute top-0 left-0" />
                        )}
                        <div>
                            <div className={`px-4 pt-4 transition-opacity duration-200 ${hover && 'opacity-0'}`}>
                                <div className="flex gap-2">
                                    <p className={`truncate-second-line text-start text-xl font-semibold`}>{name}</p>
                                </div>

                                {global ? (
                                    <p className="text-center text-xs text-newGray-darkish">
                                        <span className="font-semibold">Kato Team</span>
                                    </p>
                                ) : (
                                    <p className="text-start text-sm text-newGray-darkish mt-2">
                                        {typeData?.user.__typename === 'User' && typeData.user.type === UserType.Agent
                                            ? 'Client: '
                                            : 'by '}
                                        <span className="font-semibold">
                                            {typeData?.user.__typename === 'User' &&
                                            typeData.user.type === UserType.Agent
                                                ? organisation
                                                : typeData?.user.__typename === 'User' &&
                                                  typeData.user.type !== UserType.Admin &&
                                                  global
                                                ? 'Kato Team'
                                                : userName}
                                        </span>{' '}
                                    </p>
                                )}
                            </div>
                            <div className={`${!isEmpty(associatedUses) ? 'pb-3' : 'pb-8'}`}></div>
                            {visibleUses && (
                                <div
                                    className={`flex flex-wrap justify-start md:px-4 px-2 overflow-hidden

                                items-center mb-2`}
                                >
                                    {visibleUses.map((use, i) => {
                                        return (
                                            <div className="mb-2 z-10">
                                                <span
                                                    className={`bg-newTeal-light py-1 px-2 rounded-lg text-xs px-4 mb-2 mr-2`}
                                                    data-tip={UseConditionToText(use)}
                                                    data-for={use}
                                                >
                                                    {UseConditionToText(use)}
                                                </span>
                                            </div>
                                        );
                                    })}
                                    {!isEmpty(hiddenUses) && (
                                        <div className="mb-2 z-10">
                                            <span
                                                className={`bg-newTeal-main py-1 px-2 rounded-lg text-xs px-4 mb-2 `}
                                                data-tip={hiddenUsesText}
                                                data-for={'overflow'}
                                            >
                                                ...
                                            </span>
                                            <ReactTooltip
                                                backgroundColor="#484444"
                                                textColor="white"
                                                effect="solid"
                                                place="top"
                                                clickable={false}
                                                multiline={true}
                                                scrollHide={true}
                                                className="-w "
                                                id={'overflow'}
                                            />
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                        <div
                            className={`px-4 py-8 absolute transition-opacity duration-200 opacity-0 ${
                                hover && 'opacity-100'
                            }`}
                        >
                            <h2 className="text-center">
                                This is a default Kato template. To make changes, duplicate this template.
                            </h2>
                        </div>
                        <div className="flex flex-row items-center justify-between">
                            {created && (
                                <div className="flex flex-row items-center mb-2">
                                    <Clock className="w-3 h-3 mx-2 ml-4" style={{ color: 'white' }} />
                                    <p className="text-xxs">
                                        {'Created on ' + format(new Date(created), 'dd MMM yyyy, h:mm a')}
                                    </p>
                                </div>
                            )}

                            {description && description.trim().length > 0 && (
                                <div
                                    className="bg-navy-light items-center sm:w-8 xl:w-28 h-5 rounded-bl-lg rounded-tl-lg mb-2 flex self-end"
                                    data-tip={description}
                                    data-for={'description'}
                                >
                                    <div className="flex flex-row w-full items-center ">
                                        <Info className="w-4 h-4 mr-2 xs:ml-2 xl:ml-3" />
                                        <p className="  w-full xl:flex sm:hidden text-xs ">Description</p>
                                    </div>
                                </div>
                            )}
                        </div>
                    </Link>
                )}
            </Card>
            <ReactTooltip
                backgroundColor="#484444"
                textColor="white"
                effect="solid"
                place="top"
                clickable={false}
                multiline={true}
                scrollHide={true}
                className="tooltip-w "
                id={'description'}
            />
        </>
    );
};

export default TemplateCard;
