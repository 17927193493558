import React, { useState } from 'react';

import {
    GetUserOrganisationDocument,
    useRemoveMemberMutation,
    useRemoveTrustedUserMutation
} from '../../../generated/graphql';
import Loading from '../../../shared/Loading';
import Window from '../../../shared/Window';

type RemoveUserWindowProps = {
    userID: string;
    organisationID: string;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
    isTrustedUser: boolean;
};

const RemoveUserWindow = ({ userID, organisationID, setShow, isTrustedUser }: RemoveUserWindowProps) => {
    const [twoStep, setTwoStep] = useState(isTrustedUser);

    const [removeTrustedUser, { loading: removeTrustedUserLoading }] = useRemoveTrustedUserMutation();

    const handleRemoveTrustedUser = async () => {
        await removeTrustedUser({
            variables: {
                organisationID,
                id: userID
            },
            refetchQueries: () => [GetUserOrganisationDocument]
        });
    };

    const [removeMember, { loading: removeMemberLoading }] = useRemoveMemberMutation();

    const handleRemoveMember = async () => {
        await removeMember({
            variables: {
                organisationID,
                userID
            },
            refetchQueries: () => [GetUserOrganisationDocument]
        });
    };

    if (twoStep) {
        return <RemoveAdvisorTwoStepWindow setShow={setTwoStep} onCancel={() => setShow(false)} />;
    }

    if (removeMemberLoading || removeTrustedUserLoading)
        return (
            <Window title="Remove User" width="w-1/4" setShow={setShow}>
                <Loading />
            </Window>
        );

    return (
        <Window
            title={'Remove User'}
            titleCenter
            width="w-1/4"
            contentMiddle={true}
            primaryBtn
            primaryBtnText={'Confirm'}
            onPrimaryBtnClick={() => {
                isTrustedUser ? handleRemoveTrustedUser() : handleRemoveMember();
                return setShow(false);
            }}
            secondaryBtn
            secondaryBtnText={'Cancel'}
            onSecondaryBtnClick={() => setShow(false)}
            setShow={setShow}
        >
            <p className="text-newGray-darkish px-4 py-6 text-center">
                {'Are you sure you want to remove this user from your '}
                {isTrustedUser ? 'Advisors' : 'Colleagues'}?
                {isTrustedUser
                    ? ' They will have all current access to your spaces and deals removed.'
                    : ' They will no longer have access to any of your company data.'}
            </p>
        </Window>
    );
};

type RemoveTrustedUserConfirmWindowProps = {
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
    onCancel: () => void;
};

export const RemoveAdvisorTwoStepWindow = ({ setShow, onCancel }: RemoveTrustedUserConfirmWindowProps) => (
    <Window
        title="Remove User"
        titleCenter
        width="w-1/4"
        contentMiddle={true}
        setShow={setShow}
        primaryBtn
        primaryBtnText="Yes"
        onPrimaryBtnClick={() => {
            setShow(false);
        }}
        secondaryBtn
        secondaryBtnText="No"
        onSecondaryBtnClick={() => {
            onCancel();
            setShow(false);
        }}
    >
        <p className="text-newGray-darkish px-4 py-6 text-center">
            By removing a user from your Advisors they will no longer be able to create any data for your company or
            have access to any of your existing company data. Do you want to continue?
        </p>
    </Window>
);

export default RemoveUserWindow;
