import React, { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';

import { ReactComponent as Cross } from '../../assets/images/cross/black.svg';

type FooterProps = {
    children: React.ReactNode | React.ReactNode[];
};

const Footer = ({ children }: FooterProps) => (
    <div className="modal-footer flex items-center justify-center mt-4">{children}</div>
);

type WindowProps = {
    isOpen: boolean;
    close: () => void;
    title: string;
    children: React.ReactNode | React.ReactNode[];
    className?: string;
};

const Window = ({ isOpen, ...rest }: WindowProps) =>
    isOpen ? ReactDOM.createPortal(<_Window isOpen={isOpen} {...rest} />, document.body) : null;

const _Window = ({ close, title, children, className }: WindowProps) => {
    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
        function handleClickOutside(event: any) {
            if (ref.current && !ref.current.contains(event.target)) {
                close();
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [ref]);

    return (
        <>
            {/* backdrop */}
            <div className="fade fixed top-0 left-0 w-full h-full outline-none overflow-x-hidden overflow-y-auto z-70 bg-gray-900 opacity-50" />
            {/* overlay */}
            <div className="modal fixed top-0 left-0 flex items-center w-full h-full outline-none overflow-x-hidden overflow-y-auto z-80">
                <div
                    className={`modal-content mx-auto border-none shadow-lg relative flex flex-col w-2/5 bg-white rounded outline-none px-8 py-4 ${className} z-90`}
                    aria-modal
                    aria-hidden
                    tabIndex={-1}
                    role="dialog"
                    ref={ref}
                >
                    <div className="modal-header flex items-center justify-between mb-4">
                        <div className="text-2xl font-semibold border-b-3 border-newTeal-main">{title}</div>
                        <button onClick={close}>
                            <Cross className="w-7 h-7" />
                        </button>
                    </div>
                    {children}
                </div>
            </div>
        </>
    );
};

export default {
    Window: Window,
    Footer: Footer
};
