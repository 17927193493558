import React, { useContext } from 'react';
import { useLocation } from 'react-router-dom';

import DealCardDaysButton from './DealCardDaysButton';
import DealCardTenantReadinessButton from './DealCardTenantReadinessButton';
import DealCardUnitReadinessButton from './DealCardUnitReadinessButton';
import { DealCardContext } from '..';
import { DealCardImportant, dealMostImportant } from '../../../../shared/helpers';

type DealCardButtonsContainerProps = {
    dealURL: string;
};

const DealCardButtonsContainer = ({ dealURL }: DealCardButtonsContainerProps) => {
    const { complete, currentUserParty, compact, round, state, unitRating } = useContext(DealCardContext);

    const days = Math.ceil((new Date().getTime() - new Date(round?.date).getTime()) / (1000 * 3600 * 24));

    const mostImportant =
        state && currentUserParty
            ? dealMostImportant(state, currentUserParty, days, unitRating)
            : DealCardImportant.READINESS;

    let location = useLocation();
    const currentPage = location.pathname;
    const unitsPage = ['/units'].some((page) => currentPage.startsWith(page));

    return (
        <>
            {!complete && (
                <div className="flex justify-between items-stretch rounded-b overflow-hidden relative px-4  gap-2">
                    {/* Days with you/them buttons */}
                    <DealCardDaysButton dealURL={dealURL} days={days} mostImportant={mostImportant} />

                    {/* Unit Readiness Button */}
                    {!compact && currentUserParty !== 'TENANT' && !unitsPage && (
                        <DealCardUnitReadinessButton mostImportant={mostImportant} />
                    )}

                    {/* Tenant Readiness Button */}
                    {(currentUserParty === 'TENANT' || unitsPage) && (
                        <DealCardTenantReadinessButton unitsPage={unitsPage} />
                    )}
                </div>
            )}
        </>
    );
};

export default DealCardButtonsContainer;
