import { ApolloQueryResult } from '@apollo/client';
import { createContext, Dispatch, SetStateAction } from 'react';

import { DealBreaker, DealUserParty, Exact, GetNegotiationSummaryQuery } from '../../../../../generated/graphql';
import { ClauseMode, ClauseType, Comments, History } from '../Types';

export type ClauseContextProps = {
    id: string;
    dealID: string;
    parentID: string;
    dealState: string;
    sectionID: string;
    groupID: string;
    type: ClauseType;
    value: string;
    initialValue: string;
    valueSuffix: string;
    shortForm: string;
    longForm: string;
    tags: string[];
    hidden: boolean;
    approved: boolean;
    deleted: boolean;
    visible: boolean;
    history: History;
    comments: Comments;
    dealBreaker: DealBreaker | null;
    setDealBreaker: Dispatch<SetStateAction<boolean>>;
    setShowDealBreakerReasonWindow: Dispatch<SetStateAction<boolean>>;
    isEditing: boolean;
    isBadged: boolean;
    isResponding: boolean;
    mode: ClauseMode;
    currentUserParty: DealUserParty;
    updateClauseLoading?: boolean;
    handleSaveClick: (currentValue: string, newValue: string) => {};
    setValue: Dispatch<SetStateAction<string>>;
    setType: Dispatch<SetStateAction<ClauseType>>;
    setEditing: Dispatch<SetStateAction<boolean>>;
    setBadged: Dispatch<SetStateAction<boolean>>;
    setSwapping: Dispatch<SetStateAction<boolean>>;
    setShowHistory: Dispatch<SetStateAction<boolean>>;
    setShowCantFind: Dispatch<SetStateAction<boolean>>;
    setDeleting: Dispatch<SetStateAction<boolean>>;
    setManualComplete: Dispatch<SetStateAction<boolean>>;
    setResponding: Dispatch<SetStateAction<boolean>>;
    isRejectOpen: boolean;
    setRejectOpen: Dispatch<SetStateAction<boolean>>;
    refetch: (
        variables?:
            | Partial<
                  Exact<{
                      id: string;
                  }>
              >
            | undefined
    ) => Promise<ApolloQueryResult<GetNegotiationSummaryQuery>>;
};

export default createContext<Partial<ClauseContextProps>>({
    mode: ClauseMode.VIEW
});
