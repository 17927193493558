import _ from 'lodash';
import React, { useContext } from 'react';

import { ReactComponent as Close } from '../../../assets/images/cross/black.svg';
import { ReactComponent as CloudDownload } from '../../../assets/images/download/cloudBlack.svg';
import {
    AdminTaskType,
    Deal,
    DealUserParty,
    File,
    useGetDealUnitDetailsQuery,
    useGetLayoutUserInfoQuery,
    UserDownload
} from '../../../generated/graphql';
import { Button } from '../../../shared/button';
import useMobileScreen from '../../../shared/hooks/useMobileScreen';
import Loading from '../../../shared/Loading';
import Window from '../../../shared/Window';
import { WindowContext } from '../../../shared/Window/WindowContext';
import FileCard from '../../common/FileCard';
import { useUserType } from '../../common/helpers';
import useDownloadFiles from '../../pages/Deals/hooks/useDownloadFiles';
import { ReadinessContext } from '../Tenant/Readiness/context';

type LandlordsFileModalProps = {
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
    dealId: string;
    organisationName: string | undefined;
};

type HeaderProps = {
    tenantFiles?: File[];
    files: UnitFile[];
    handleDownloadFiles: (ids: string[], deal: Deal) => Promise<void>;
    hasNewFiles: number;
    isTenantSide: boolean;
    deal?: Deal;
};

export type UnitFile = Pick<File, '_id' | 'name' | 'url' | 'downloads'> & {
    readinessTask?: string;
};

const Header = ({ tenantFiles, files, handleDownloadFiles, hasNewFiles, isTenantSide, deal }: HeaderProps) => {
    const { setShow } = useContext(WindowContext);

    const fileIDs = files.map((file) => file._id);
    const tenantFileIDs = tenantFiles?.map((tenantFile) => tenantFile._id);
    const hasFiles = fileIDs.length > 0 || (tenantFileIDs && tenantFileIDs.length > 0);

    const isMobile = useMobileScreen();

    return (
        <div className="flex flex-col">
            <div className="flex flex-row cursor-pointer mt-2 justify-end">
                <Close onClick={() => setShow(false)} className="w-12" />
            </div>
            <div className="md:flex flex-row justify-between items-center ml-10 mr-10">
                <div className={`flex flex-row items-center`}>
                    <div>
                        <p className="font-semibold text-xl border-b-3 border-newTeal-main">
                            {isTenantSide ? 'Landlord Files' : 'Files'}
                        </p>
                    </div>
                    {hasNewFiles > 0 && isTenantSide && (
                        <div className="bg-newRed-light rounded px-3 ml-8">
                            <p className="font-semibold text-sm">
                                {`${hasNewFiles} New ${!isMobile ? (hasNewFiles === 1 ? 'File' : 'Files') : ''}`}
                            </p>
                        </div>
                    )}
                </div>
                <div className="mt-2 md:mt-0">
                    <Button
                        background={`${hasFiles ? 'newTeal-main' : 'navy-light'}`}
                        text={hasFiles ? 'white' : 'black'}
                        height="10"
                        disabled={!hasFiles}
                        className={`px-4 my-2 whitespace-no-wrap rounded-md font-semibold flex items-center`}
                        onClick={() => deal && handleDownloadFiles([...fileIDs, ...(tenantFileIDs || [])], deal)}
                    >
                        <CloudDownload className="w-6 -h-6 mr-4" />
                        <p className="text-base">Download All {!isMobile ? 'Files' : ''}</p>
                    </Button>
                </div>
            </div>
        </div>
    );
};

export const LandlordsFileModal = ({ setShow, dealId }: LandlordsFileModalProps) => {
    const { data: userData } = useGetLayoutUserInfoQuery();
    const { data: detailsData, loading: detailsLoading } = useGetDealUnitDetailsQuery({
        variables: {
            dealID: dealId
        }
    });
    const deal = detailsData?.deal as Deal;
    const [downloadFiles] = useDownloadFiles();

    const { isTenantSide, isLandlordSide } = useUserType(undefined, deal?.currentUserParty as DealUserParty);

    const { details } = useContext(ReadinessContext);
    const three = details?.three;

    const isDownloaded = (downloads: UserDownload[]) => {
        const user = downloads?.find((download) => download.user === userData?.user._id);
        return user;
    };

    const handleNewFileCount = (files: UnitFile[]) => {
        const downloadedFiles = files.filter((file) =>
            file.downloads.some((download) => download.user === userData?.user._id)
        ).length;
        return files.length - downloadedFiles;
    };

    const tasks = deal?.unit?.tasks?.filter(
        (task) => task.task.type === AdminTaskType.File && task.value && task.value?.__typename === 'File'
    );

    const taskIDs = tasks
        ?.map((task) => task.value?.__typename === 'File' && task?.value._id)
        .filter((el) => el !== null);

    const taskFiles =
        tasks?.map((task) => ({
            ...(task.value as File),
            readinessTask: task.task.title
        })) || [];

    const files = deal?.unit?.files.filter((file) => !taskIDs?.includes(file._id)) || [];

    const unitFiles: UnitFile[] = files?.concat(taskFiles);

    if (detailsLoading)
        return (
            <Window setShow={setShow} width="w-3/5">
                <Loading />
            </Window>
        );

    return (
        <Window
            className="max-h-screen"
            width="w-3/5"
            header={
                <Header
                    tenantFiles={three?.files}
                    files={unitFiles}
                    handleDownloadFiles={downloadFiles}
                    hasNewFiles={handleNewFileCount(unitFiles)}
                    isTenantSide={isTenantSide}
                    deal={deal}
                />
            }
            setShow={setShow}
            scrollContent={true}
        >
            <div className="mb-10 ml-10 mr-10 overflow-y-auto">
                {!_.isEmpty(three?.files) && isLandlordSide && (
                    <div className="mb-4">
                        <div className="flex flex-row jusify-center items-center">
                            <div className="bg-gray-600 rounded-full w-2 h-2 mr-2" />
                            <p className="text-gray-600 text-sm font-semibold">Tenant files</p>
                        </div>
                        {three?.files.map((file) => {
                            return (
                                <FileCard
                                    name={file.name}
                                    handleClick={() => downloadFiles([file._id], deal)}
                                    url={file.url}
                                    user={isDownloaded(file.downloads)}
                                />
                            );
                        })}
                    </div>
                )}
                {isLandlordSide && (
                    <div className="flex flex-row jusify-center items-center">
                        <div className="bg-newGray-main  rounded-full w-2 h-2 mr-2" />
                        <p className="text-newGray-main text-sm font-semibold">Landlord files</p>
                    </div>
                )}
                {!_.isEmpty(unitFiles) ? (
                    unitFiles?.map((file) => {
                        return (
                            <FileCard
                                name={file.name}
                                description={file.readinessTask}
                                handleClick={() => downloadFiles([file._id], deal)}
                                url={file.url}
                                user={isDownloaded(file.downloads)}
                            />
                        );
                    })
                ) : (
                    <div className="border-2 rounded-lg border-dashed mt-2 justify-center border-navy-light h-56 flex items-center">
                        <p className="text-3xl text-navy-light font-medium ">No Files Uploaded</p>
                    </div>
                )}
            </div>
        </Window>
    );
};
