import { FieldInputProps, FormikProps } from 'formik';
import React from 'react';

interface InputProps {
    field: FieldInputProps<any>;
    form: FormikProps<any>;
    className?: string;
    containerStyle?: string;
    label?: string | boolean;
}

export const InputField = ({ field, form: { touched, errors }, ...props }: InputProps) => (
    <div className={props.containerStyle}>
        <input
            {...field}
            {...props}
            className={`p-3 border rounded focus:outline-none ${props.className} ${props.label && 'mt-6'}`}
        />
        {touched[field.name] && errors[field.name] && <div className="text-red-600">{errors[field.name]}</div>}
    </div>
);
