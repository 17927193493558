import './wdyr';
import { ApolloProvider } from '@apollo/client';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import 'react-toastify/dist/ReactToastify.css';
import { IntercomProvider } from 'react-use-intercom';

import { Client } from './apollo.config';
import App from './App';

const INTERCOM_APP_ID = 'j3v5xds3';

const client = new Client();

ReactDOM.render(
    // <React.StrictMode>
    <ApolloProvider client={client.apolloClient}>
        <IntercomProvider appId={INTERCOM_APP_ID}>
            <App />
        </IntercomProvider>
    </ApolloProvider>,
    // </React.StrictMode>,
    document.getElementById('root')
);
