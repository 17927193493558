import React, { useState } from 'react';
import Iframe from 'react-iframe';

import StageContext from './StageContext';
import Loading from '../../../../../shared/Loading';
import Window from '../../../../../shared/Window';

type IframeWindowType = {
    type: 'lawyer' | 'agent' | 'contractor' | 'dbcontractor' | 'manager' | 'specialist';
};

const IframeWindow = ({ type }: IframeWindowType) => {
    const {
        setShowStage2Window,
        setShowStage6Window,
        setShowLawyerWindow,
        setShowAgentWindow,
        setShowContractorWindow,
        setShowDBContractorWindow,
        setShowManagerWindow,
        setShowSpecialistWindow
    } = React.useContext(StageContext);

    const [loading, setLoading] = useState<boolean>(true);

    const handleSetShow = () => {
        switch (type) {
            case 'lawyer':
                return setShowLawyerWindow;
            case 'agent':
                return setShowAgentWindow;
            case 'contractor':
                return setShowContractorWindow;
            case 'dbcontractor':
                return setShowDBContractorWindow;
            case 'manager':
                return setShowManagerWindow;
            case 'specialist':
                return setShowSpecialistWindow;
        }
    };

    const handleClose = () => {
        if (
            setShowLawyerWindow &&
            setShowAgentWindow &&
            setShowContractorWindow &&
            setShowDBContractorWindow &&
            setShowManagerWindow &&
            setShowSpecialistWindow &&
            setShowStage2Window &&
            setShowStage6Window
        ) {
            switch (type) {
                case 'lawyer':
                    setShowLawyerWindow(false);
                    break;
                case 'agent':
                    setShowAgentWindow(false);
                    break;
                case 'contractor':
                    setShowContractorWindow(false);
                    break;
                case 'dbcontractor':
                    setShowDBContractorWindow(false);
                    break;
                case 'manager':
                    setShowManagerWindow(false);
                    break;
                case 'specialist':
                    setShowSpecialistWindow(false);
                    break;
            }
            if (type === 'lawyer' || type === 'agent' || type === 'contractor') {
                setShowStage2Window(true);
            } else {
                setShowStage6Window(true);
            }
        }
    };

    return (
        <Window
            width="w-4/5"
            className="md:max-h-11/12"
            contentBackground="navy-light"
            title=""
            footer={<></>}
            setShow={handleSetShow}
            onCloseClick={handleClose}
        >
            <>
                {loading && <Loading />}
                <Iframe
                    url={
                        type === 'lawyer'
                            ? 'https://www.getleast.com/find-a-lawyer'
                            : type === 'agent'
                            ? 'https://www.getleast.com/find-an-agent'
                            : type === 'contractor'
                            ? 'https://www.getleast.com/find-a-fit-out-contractor'
                            : type === 'dbcontractor'
                            ? 'https://www.getleast.com/find-a-db-contractor'
                            : type === 'manager'
                            ? 'https://www.getleast.com/find-a-project-manager'
                            : type === 'specialist'
                            ? 'https://www.getleast.com/find-a-fit-out-specialist'
                            : ''
                    }
                    width="100%"
                    height="600px"
                    scrolling="yes"
                    loading="lazy"
                    onLoad={() => setLoading(false)}
                />
            </>
        </Window>
    );
};

export default IframeWindow;
