import React from 'react';
import { useParams } from 'react-router-dom';

import Activate from './Activate';
import Invite from './Invite';
import ResetPassword from './ResetPassword';
import Logo from '../../assets/images/logo/kato.svg';
import Header from '../../layout/Header';

type LandingProps = {
    invite?: Boolean;
    activate?: Boolean;
    reset?: Boolean;
};

const Landing = ({ invite, activate, reset }: LandingProps) => {
    const { token } = useParams();

    return (
        <div className="least-app-content w-full overflow-hidden max-h-screen">
            <Header visible={true} setVisible={() => {}}>
                <img src={Logo} alt="Logo" className="w-24 ml-4" />
            </Header>
            <div className="overflow-scroll h-screen">
                <div className="w-full flex flex-col items-center justify-center min-h-full pb-20">
                    {invite && <Invite token={token} />}
                    {activate && <Activate token={token} />}
                    {reset && <ResetPassword token={token} />}
                </div>
            </div>
        </div>
    );
};

export default Landing;
