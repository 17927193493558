import { isNil, noop } from 'lodash';
import React, { ReactNode, useState } from 'react';
import { useParams } from 'react-router-dom';

import {
    Organisation,
    TenantDetails,
    useGetDealTenantDetailsQuery,
    User,
    UserType
} from '../../../../generated/graphql';

export enum STAGE {
    TENANT_INFORMATION = 'one',
    LAWYER = 'two',
    TEAM = 'team',
    COVENANT = 'three',
    AML_CHECK = 'aml',
    FINAL_INFO = 'final'
}

type ReadinessContextType = {
    dealID?: string;
    stage?: STAGE;
    isChanging: boolean;
    setStage: (stage: STAGE) => void;
    changeStage: () => void;
    details?: TenantDetails;
    loading: boolean;
    clear: () => void;
    tenant?: User;
    agent?: User;
    unitID?: string;
    numberOfCompleteStages: number;
    firstIncompleteStage?: STAGE;
};

export const ReadinessContext = React.createContext<ReadinessContextType>({
    dealID: undefined,
    stage: undefined,
    isChanging: false,
    setStage: noop,
    changeStage: noop,
    details: undefined,
    loading: true,
    clear: noop,
    tenant: undefined,
    agent: undefined,
    unitID: undefined,
    numberOfCompleteStages: 0,
    firstIncompleteStage: undefined
});

type ReadinessProviderProps = {
    children: ReactNode;
};

const getTenantUser = (tenant?: Organisation, type?: UserType) =>
    tenant?.members?.find((member) => member.type === type);

export const ReadinessProvider = ({ children }: ReadinessProviderProps) => {
    const { id, token } = useParams<{ id: string; token: string }>();
    const { data, loading } = useGetDealTenantDetailsQuery({
        variables: { dealID: id, token }
    });

    const [currentStage, setCurrentStage] = useState<STAGE | undefined>();
    const [nextStage, setNextStage] = useState<STAGE | undefined>();

    const requestChange = (stage: STAGE) => {
        if (isNil(currentStage)) setCurrentStage(stage);
        else setNextStage(stage);
    };

    const changeStage = () => {
        if (!isNil(nextStage)) {
            setCurrentStage(nextStage);
            setNextStage(undefined);
        }
    };

    const clear = () => setCurrentStage(undefined);

    const isChanging = !isNil(nextStage) && nextStage !== currentStage;

    const numberOfCompleteStages = Object.values(STAGE).reduce((acc, stage) => {
        if (data?.deal.details[stage]?.completed) {
            return (acc += 1);
        }

        return acc;
    }, 0);

    const firstIncompleteStage = Object.values(STAGE).find((stage) => !data?.deal.details[stage]?.completed);

    return (
        <ReadinessContext.Provider
            value={{
                dealID: data?.deal._id,
                stage: currentStage,
                setStage: requestChange,
                details: data?.deal.details as TenantDetails,
                loading,
                isChanging,
                changeStage,
                clear,
                tenant: getTenantUser(data?.deal.tenant as Organisation, UserType.Tenant),
                agent: getTenantUser(data?.deal.tenant as Organisation, UserType.Agent),
                unitID: data?.deal.unit?._id,
                numberOfCompleteStages,
                firstIncompleteStage
            }}
        >
            {children}
        </ReadinessContext.Provider>
    );
};
