import React, { useState } from 'react';

import ClauseAction from './ClauseAction';
import { ReactComponent as Remove } from '../../assets/images/delete/black.svg';
import { ReactComponent as Settings } from '../../assets/images/edit/black.svg';
import { ReactComponent as Edit } from '../../assets/images/hash/black.svg';
import { ReactComponent as Slash } from '../../assets/images/slash/black.svg';
import { ReactComponent as Swap } from '../../assets/images/swap/black.svg';
import Transition from '../Transition';

type ClauseSettingsProps = {
    setEditing: React.Dispatch<React.SetStateAction<boolean>>;
    setDeleting: React.Dispatch<React.SetStateAction<boolean>>;
    setSwap: React.Dispatch<React.SetStateAction<boolean>>;
    setDealBreaker: React.Dispatch<React.SetStateAction<boolean>>;
    value?: string | undefined;
    suffix?: string | undefined;
    side?: 'left';
};

const ClauseSettings = ({
    setEditing,
    setDeleting,
    setSwap,
    setDealBreaker,
    suffix,
    side,
    value
}: ClauseSettingsProps) => {
    const [open, setOpen] = useState<boolean>(false);

    const transitionProps = {
        enter: 'transition transform ease-out duration-100',
        enterFrom: 'translate-x-full opacity-0',
        enterTo: 'translate-x-0 opacity-100',
        leave: 'transition transform ease-in duration-100',
        leaveFrom: 'translate-x-0 opacity-100',
        leaveTo: 'translate-x-full opacity-0'
    };

    return (
        <div className="flex items-center overflow-hidden z-10">
            {/* Settings open - show buttons */}
            <Transition
                show={open}
                {...transitionProps}
                className={`${
                    side === 'left' ? 'ml-14 left-0' : 'mr-12 right-0'
                } flex flex-row rounded-l absolute max-w-full overflow-hidden z-0`}
            >
                {suffix !== '' && (
                    <ClauseAction
                        icon={<Edit className="w-4 h-4 mb-1" />}
                        text={'Edit Value'}
                        onClick={() => setEditing(true)}
                    />
                )}
                <ClauseAction
                    icon={<Swap className="w-4 h-4 mb-1" />}
                    text={'Edit Clause'}
                    onClick={() => setSwap(true)}
                />
                <ClauseAction
                    icon={<Slash className="w-4 h-4 mb-1" />}
                    text={'Lock Clause'}
                    onClick={() => {
                        value !== '*' && setDealBreaker(true);
                    }}
                    className={value === '*' ? 'opacity-50 hover:bg-white' : 'hover:bg-gray-200'}
                />
                <ClauseAction
                    icon={<Remove className="w-4 h-4 mb-1" />}
                    text={'Remove Clause'}
                    onClick={() => setDeleting(true)}
                />
            </Transition>

            <button
                className={`${
                    open && 'bg-navy-light hover:bg-gray-200 h-11 rounded-r'
                } sm:w-20 lg:w-12 text-3xs font-semibold flex flex-col content-center items-center justify-center px-1 py-2 cursor-pointer  focus:outline-none`}
                onClick={() => {
                    setOpen((open) => !open);
                }}
            >
                <Settings className="w-5 h-5" />
            </button>
        </div>
    );
};

export default ClauseSettings;
