import React, { useContext, useState } from 'react';

import ActivityLog from './ActivityLog';
import Download from './Download';
import Share from './Share';
import {
    ActivityLog as ActivityLogDocument,
    AdminTaskType,
    DealSendType,
    DealUserParty,
    File
} from '../../../../generated/graphql';
import { DealContext } from '../../../../pages/Deals/Deal/context';
import Loading from '../../../../shared/Loading';
import { isLandlordSide } from '../../../common/helpers';
import Modal from '../../../components/Modal';

type DownloadDataWindowProps = {
    isOpen: boolean;
    close: () => void;
    currentUserParty: DealUserParty;
    type: DealSendType;
};

const DownloadDataWindow = ({ isOpen, close, currentUserParty, type }: DownloadDataWindowProps) => {
    const { deal } = useContext(DealContext);

    const [loading, setLoading] = useState<boolean>(false);

    const isLandlordOrAdminSide = isLandlordSide(currentUserParty);

    const tasks = deal?.unit?.tasks?.filter(
        (task) => task.task.type === AdminTaskType.File && task.value && task.value?.__typename === 'File'
    );

    const taskIDs = tasks
        ?.map((task) => task.value?.__typename === 'File' && task?.value._id)
        .filter((el) => el !== null);

    const taskFiles =
        tasks?.map((task) => ({
            ...(task.value as File),
            readinessTask: task.task.title
        })) || [];

    const unitFiles = deal?.unit?.files.filter((file) => !taskIDs?.includes(file._id)) || [];

    const files: File[] = [...unitFiles, ...taskFiles] as File[];

    const tenantFiles: File[] = (deal?.details.three?.files || []) as File[];

    return (
        <Modal.Window
            isOpen={isOpen}
            close={close}
            title={'Download Deal Data'}
            className={'xl:w-1/2 lg:w-2/3 md:w-4/5 sm:w-full xs:w-full xs:h-full sm:h-full md:h-auto overflow-y-auto'}
        >
            {loading ? (
                <Loading />
            ) : (
                <>
                    <Download files={files} tenantFiles={tenantFiles} />
                    <Share currentUserParty={currentUserParty} type={type} setLoading={setLoading} close={close} />
                    {isLandlordOrAdminSide && <ActivityLog logs={deal?.logs as ActivityLogDocument[]} />}
                </>
            )}
        </Modal.Window>
    );
};

export default DownloadDataWindow;
