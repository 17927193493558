import { DealDetailsFragment, GetDealDetailsQuery } from '../../../../generated/graphql';

const useSortDeal = () => {
    return (detailsData: GetDealDetailsQuery | undefined): DealDetailsFragment | null => {
        if (!detailsData || detailsData.deal.__typename !== 'Deal') return null;

        return detailsData.deal;

        // Sorting is now handled by the backend from V2
        // return {
        //     ...detailsData.deal,
        //     sections: [...(detailsData.deal.sections ?? [])]
        //         ?.sort((a, b) => (a.index > b.index ? 1 : -1))
        //         .map((section) => {
        //             return {
        //                 ...section,
        //                 groups: [...(section.groups ?? [])]
        //                     ?.sort((a, b) => (a.index > b.index ? 1 : -1))
        //                     .map((group) => {
        //                         let clauses =
        //                             group.clauses &&
        //                             [...group.clauses]?.sort((a, b) =>
        //                                 a.index > b.index ? 1 : -1
        //                             )

        //                         return {
        //                             ...group,
        //                             clauses: clauses?.map((clause) => {
        //                                 return {
        //                                     ...clause,
        //                                     groups: clause.groups?.map(
        //                                         (level2Group) => {
        //                                             return {
        //                                                 ...level2Group,
        //                                                 clauses:
        //                                                     level2Group.clauses &&
        //                                                     [
        //                                                         ...level2Group.clauses
        //                                                     ]?.sort((a, b) =>
        //                                                         a.index >
        //                                                         b.index
        //                                                             ? 1
        //                                                             : -1
        //                                                     )
        //                                             }
        //                                         }
        //                                     )
        //                                 }
        //                             })
        //                         }
        //                     })
        //             }
        //         })
        // }
    };
};

export default useSortDeal;
