import { format } from 'date-fns';
import _ from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import FlipMove from 'react-flip-move';
import { useHistory, useParams } from 'react-router-dom';

import { ReactComponent as Clock } from '../../../../assets/images/clock/black.svg';
import {
    DealDetailsFragment,
    DealUserParty,
    useGetDealDetailsQuery,
    useGetUserTypeQuery
} from '../../../../generated/graphql';
import Accordion from '../../../../shared/Accordian';
import { getUserFullName, handleFetchError } from '../../../../shared/helpers';
import { isTenantSide } from '../../../../shared/helpers';
import useMobileScreen from '../../../../shared/hooks/useMobileScreen';
import Loading from '../../../../shared/Loading';
import AddClauseWindow from '../../../../shared/Negotiator/AddClauseWindow';
import Clause from '../../../../shared/Negotiator/Clause';
import { DealType } from '../../../../shared/Negotiator/ConfirmClauseDeleteWindow.tsx';
import Group from '../../../../shared/Negotiator/Group';
import NewClauseWindow from '../../../../shared/Negotiator/NewClauseWindow';
import Section from '../../../../shared/Negotiator/Section';
import CreatedWindow from '../../../../v2/common/MobileWindow/CreatedWindow';
import { LandlordFilesContext } from '../../../../v2/components/LandlordsFileModal/LandlordFilesContext';
import { LandlordsFileModal } from '../../../../v2/components/LandlordsFileModal/LandlordsFileModal';
import TenantDeal from '../../../../v2/components/Sidebar/Deal/Tenant';
import SidebarWrapper from '../../../../v2/components/Sidebar/SidebarWrapper';
import DealHeader from '../../../../v2/pages/Deals/DealHeader';
import { AddClauseGroupInput } from '../../../Templates/Template';
import OldDealHeader from '../Header';
import useSortDeal from '../hooks/useSortDeal';
import NoClauses from '../NoClauses';

type EditDealProps = {
    setHeaderContent: React.Dispatch<React.SetStateAction<React.ReactChild | null>>;
};

const EditDeal = ({ setHeaderContent }: EditDealProps) => {
    const isMobile = useMobileScreen();
    const history = useHistory();
    const { id } = useParams();
    const { showLandlordFilesWindow, setShowLandlordFilesWindow } = useContext(LandlordFilesContext);

    const {
        loading: detailsLoading,
        error: detailsError,
        data: detailsData,
        refetch
    } = useGetDealDetailsQuery({
        variables: {
            dealID: id
        }
    });
    const { data: typeData } = useGetUserTypeQuery();

    const [deal, setDeal] = useState<DealDetailsFragment>();
    const sortDeal = useSortDeal();

    const [showAddClauseWindow, setShowAddClauseWindow] = useState<boolean>(false);

    const [openDetails, setOpenDetails] = useState<{
        section?: string;
        level2Group?: string;
    } | null>(null);
    const [showFilterAddClauseWindow, setShowFilterAddClauseWindow] = useState<AddClauseGroupInput | undefined>(
        undefined
    );
    const [showNewClauseWindow, setShowNewClauseWindow] = useState<boolean>(false);
    const [isClauseReordering, setClauseReordering] = useState<boolean>(false);
    const [isTitlesReordering, setTitlesReordering] = useState<boolean>(false);

    const [showCreatedWindow, setShowCreatedWindow] = useState<boolean>(false);
    const [financialsOpen, setFinancialsOpen] = useState<boolean>(false);

    useEffect(() => {
        // Reset filter on window close
        !showAddClauseWindow && setOpenDetails(null);
    }, [showAddClauseWindow]);

    // Set the header to the building & unit name
    useEffect(() => {
        setHeaderContent(
            <div className="flex justify-between w-full items-center">
                <div className="flex flex-col p-2 w-2/3">
                    <h1 className="font-semibold text-xl truncate w-full">
                        {detailsData?.deal.__typename === 'Deal' && detailsData.deal.unit?.buildingName + ' - Edit'}
                    </h1>
                    <p className="md:text-sm text-xs -mt-1">
                        {detailsData?.deal.__typename === 'Deal' && detailsData.deal.unit?.name}
                    </p>
                    <p className="hidden md:flex text-sm -mt-1 truncate z-20">
                        {detailsData?.deal.__typename === 'Deal' && detailsData.deal.template?.name}
                    </p>
                </div>
                <div className="-mt-1 z-10 flex items-center md:flex-col-reverse md:items-end">
                    <Clock className="w-6 h-6 mt-2 mr-2 xs:flex md:hidden" onClick={() => setShowCreatedWindow(true)} />
                    <p className={`xs:hidden md:flex text-sm `}>
                        {detailsData?.deal.__typename === 'Deal' &&
                            (detailsData.deal.created ? 'by ' : 'Created by') +
                                getUserFullName(detailsData.deal.creator)}
                    </p>
                    <p className={`xs:hidden md:flex text-sm `}>
                        {detailsData?.deal.__typename === 'Deal' &&
                            detailsData.deal.created &&
                            'Created on ' + format(new Date(detailsData.deal.created), 'dd MMM yyyy, h:mm a')}
                    </p>
                </div>
            </div>
        );
    }, [setHeaderContent, detailsData, isMobile]);

    useEffect(() => {
        // Check for errors
        handleFetchError('Could not load the deal', detailsError, detailsData?.deal);
        // Redirect to templates page if error e.g. ID in url is not valid
        if (detailsError) return history.push('/deals');

        // Sort the deal by its clauses
        const sortedDeal = sortDeal(detailsData);
        sortedDeal && setDeal(sortedDeal);
        // eslint-disable-next-line
    }, [detailsData, detailsError, history]);

    const isTenant = detailsData?.deal.currentUserParty === DealUserParty.Tenant;

    if (detailsLoading) return <Loading />;

    return (
        <SidebarWrapper wrap={!isTenant} sidebar={isTenant && <TenantDeal />} className={'overflow-y-hidden'}>
            {showCreatedWindow && (
                <CreatedWindow
                    setShowCreatedWindow={setShowCreatedWindow}
                    date={format(new Date(detailsData?.deal.created), 'dd MMM yyyy, h:mm a')}
                    creator={getUserFullName(detailsData?.deal.creator)}
                />
            )}
            {/* Header */}
            {detailsData && !isMobile ? (
                <div className="top-0 sticky z-40">
                    <DealHeader
                        userType={typeData?.user.__typename === 'User' ? typeData.user.type : undefined}
                        deal={detailsData?.deal.__typename === 'Deal' ? detailsData?.deal : null}
                        setShowAddClauseWindow={setShowAddClauseWindow}
                        isClauseReordering={isClauseReordering}
                        isTitlesReordering={isTitlesReordering}
                        setClauseReordering={setClauseReordering}
                        setTitlesReordering={setTitlesReordering}
                        financialsOpen={financialsOpen}
                        setFinancialsOpen={setFinancialsOpen}
                    >
                        {detailsData}
                    </DealHeader>
                </div>
            ) : (
                <OldDealHeader
                    userType={typeData?.user.__typename === 'User' ? typeData.user.type : undefined}
                    deal={detailsData?.deal.__typename === 'Deal' ? detailsData?.deal : null}
                    setShowAddClauseWindow={setShowAddClauseWindow}
                    isClauseReordering={isClauseReordering}
                    isTitlesReordering={isTitlesReordering}
                    setClauseReordering={setClauseReordering}
                    setTitlesReordering={setTitlesReordering}
                    setHeaderContent={setHeaderContent}
                >
                    {detailsData}
                </OldDealHeader>
            )}
            <div
                className={`md:py-8 py-4 ${_.isEmpty(deal?.sections) && 'flex items-center justify-center'} ${
                    isMobile ? 'px-2' : 'md:px-10 lg:px-24'
                } relative`}
            >
                {deal?.sections && deal?.sections.length > 0 ? (
                    <div
                        className={`container mx-auto flex flex-col
                        ${isMobile ? 'pt-20 w-full px-2' : 'pt-4 px-4 sm:w-full lg:w-auto'}`}
                    >
                        {/* Sections */}
                        {deal?.sections?.map((section, index) => (
                            <div
                                className={`bg-white mb-4 rounded-xl actor-list-shadow py-8 px-10 pb-6`}
                                key={section._id}
                            >
                                <Section
                                    setClauseReordering={setClauseReordering}
                                    setTitlesReordering={setTitlesReordering}
                                    isReordering={isTitlesReordering}
                                    length={deal?.sections?.length}
                                    index={index}
                                    setShow={setShowAddClauseWindow}
                                    setOpenDetails={setOpenDetails}
                                    id={section._id}
                                    description={section.description}
                                    rics={section.rics}
                                    deal={detailsData?.deal.__typename === 'Deal' ? detailsData?.deal : null}
                                    key={section._id}
                                    title={section.title}
                                >
                                    {' '}
                                </Section>
                                {section.groups?.map((group, groupIndex) => (
                                    <div className="mt-0" key={group._id}>
                                        <Accordion
                                            isReordering={isTitlesReordering}
                                            sectionID={section._id}
                                            parentID={group.parentID}
                                            id={group._id}
                                            deal={detailsData?.deal.__typename === 'Deal' ? detailsData?.deal : null}
                                            description={group.description}
                                            rics={group.rics}
                                            length={section.groups?.length}
                                            index={groupIndex}
                                            title={group.title}
                                            className="bg-white py-4"
                                            isOpen={group.clauseSummary.incomplete === 0 ? false : true}
                                            isCompleted={group.clauseSummary.incomplete === 0 ? true : false}
                                            smallHeight={true}
                                        >
                                            <FlipMove
                                                leaveAnimation="accordionVertical"
                                                className={`${isMobile && '-mr-1'}`}
                                            >
                                                {group.clauses?.map((clause, index) => (
                                                    <Clause
                                                        id={clause._id}
                                                        parentID={clause.parentID}
                                                        type={DealType.DEAL}
                                                        deal={deal ?? null}
                                                        key={clause._id}
                                                        value={clause.value ?? ''}
                                                        valueSuffix={clause.valueSuffix ?? ''}
                                                        shortForm={clause.shortForm ?? ''}
                                                        longForm={clause.longForm ?? ''}
                                                        tags={clause.tags ?? []}
                                                        visible={!!clause.visible}
                                                        isReordering={isClauseReordering}
                                                        index={index}
                                                        linkedClauses={clause.linked}
                                                        dealBreaker={clause.dealBreaker}
                                                        clauses={group.clauses?.length}
                                                        sectionName={section.title}
                                                        groupName={group.title}
                                                        groupID={group._id}
                                                    >
                                                        <>
                                                            {clause.groups?.map((level2Group, level2GroupIndex) => (
                                                                <Group
                                                                    id={level2Group._id}
                                                                    parentID={level2Group.parentID}
                                                                    deal={deal ?? null}
                                                                    key={level2Group._id}
                                                                    title={level2Group.title}
                                                                    level={2}
                                                                    isReordering={isClauseReordering}
                                                                    clauseID={clause._id}
                                                                    index={level2GroupIndex}
                                                                    clauses={group.clauses?.length}
                                                                    setShow={setShowAddClauseWindow}
                                                                    setOpenDetails={setOpenDetails}
                                                                    clauseGroupInput={{
                                                                        section: section.title,
                                                                        group: group.title,
                                                                        groupLength: level2Group.clauses
                                                                            ? level2Group.clauses.length
                                                                            : 0
                                                                    }}
                                                                    type={DealType.DEAL}
                                                                >
                                                                    <FlipMove leaveAnimation="fade">
                                                                        {level2Group.clauses?.map(
                                                                            (level2Clause, index) => (
                                                                                <Clause
                                                                                    id={level2Clause._id}
                                                                                    parentID={level2Clause.parentID}
                                                                                    type={DealType.DEAL}
                                                                                    deal={deal ?? null}
                                                                                    key={level2Clause._id}
                                                                                    value={level2Clause.value ?? ''}
                                                                                    valueSuffix={
                                                                                        level2Clause.valueSuffix ?? ''
                                                                                    }
                                                                                    shortForm={
                                                                                        level2Clause.shortForm ?? ''
                                                                                    }
                                                                                    longForm={
                                                                                        level2Clause.longForm ?? ''
                                                                                    }
                                                                                    tags={level2Clause.tags ?? []}
                                                                                    isReordering={isClauseReordering}
                                                                                    index={index}
                                                                                    linkedClauses={level2Clause.linked}
                                                                                    dealBreaker={
                                                                                        level2Clause.dealBreaker
                                                                                    }
                                                                                    clauses={
                                                                                        level2Group.clauses?.length
                                                                                    }
                                                                                    sectionName={section.title}
                                                                                    groupName={group.title}
                                                                                    clauseGroupName={level2Group.title}
                                                                                    groupID={level2Group._id}
                                                                                />
                                                                            )
                                                                        )}
                                                                    </FlipMove>
                                                                </Group>
                                                            ))}
                                                        </>
                                                    </Clause>
                                                ))}
                                            </FlipMove>
                                        </Accordion>
                                    </div>
                                ))}
                            </div>
                        ))}
                    </div>
                ) : (
                    <NoClauses setShowAddClauseWindow={setShowAddClauseWindow} financialsOpen={financialsOpen} />
                )}
            </div>
            {/* WINDOWS */}
            {(showAddClauseWindow || showFilterAddClauseWindow) && detailsData?.deal.__typename === 'Deal' && (
                <AddClauseWindow
                    dealID={detailsData?.deal._id}
                    deal={detailsData.deal}
                    organisationID={detailsData.deal.unit?.organisation._id ?? ''}
                    isTemplate={true}
                    refetch={refetch}
                    setShowNewClause={setShowNewClauseWindow}
                    setShow={(input) => {
                        showFilterAddClauseWindow
                            ? setShowFilterAddClauseWindow(undefined)
                            : setShowAddClauseWindow(input);
                    }}
                    include={!isTenantSide(detailsData.deal) ? openDetails : null}
                    selectedAll={true}
                />
            )}
            {showNewClauseWindow && detailsData?.deal.__typename === 'Deal' && (
                <NewClauseWindow
                    sections={detailsData?.deal?.sections ?? []}
                    dealID={detailsData.deal._id}
                    organisationID={detailsData.deal.unit?.organisation._id ?? ''}
                    refetch={refetch}
                    setShow={setShowNewClauseWindow}
                />
            )}
            {showLandlordFilesWindow && (
                <LandlordsFileModal
                    setShow={setShowLandlordFilesWindow}
                    dealId={id}
                    organisationName={detailsData?.deal.unit?.organisation.name}
                />
            )}
        </SidebarWrapper>
    );
};

export default EditDeal;
