import React, { useContext } from 'react';

import { Button } from '../../shared/button';

type OptionProps = {
    label: string;
    value: string;
};

const Option = ({ label, value }: OptionProps) => {
    const { value: contextValue, setValue } = useContext(RadioGroupContext);

    const checked = value === contextValue;

    return (
        <Button
            className="text-white p-2 hover:bg-gray-600"
            background={`${checked ? 'newGray-darkish' : 'gray-v2-200'}`}
            onClick={() => setValue(value)}
        >
            {label}
        </Button>
    );
};

const RadioGroupContext = React.createContext({
    value: undefined,
    setValue: (value: any) => {}
});

type RadioGroupProps = {
    value: any;
    setValue: (value: any) => void;
    label: string;
    description?: string;
    children: React.ReactNode[];
};

const RadioGroup = ({ value, setValue, label, description, children }: RadioGroupProps) => {
    return (
        <RadioGroupContext.Provider value={{ value, setValue }}>
            <div className="space-y-1">
                <div className="flex space-x-2 items-end">
                    <div className="text-gray-v2-400 font-bold text-xl">{label}</div>
                    <div className="text-gray-v2-400 text-sm">{description}</div>
                </div>
                <div className="space-x-4">{children}</div>
            </div>
        </RadioGroupContext.Provider>
    );
};

export { RadioGroup, Option };
