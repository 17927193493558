import React, { useEffect, useRef } from 'react';

import { useUploadCompanyLogo } from './hooks';
import DefaultProfile from '../../layout/SideBar/components/DefaultProfile';
import { Button } from '../button';
import { proxyImage } from '../helpers';
import Loading from '../Loading';

type OrganisationAvatarUploadProps = {
    id: string;
    avatar?: string | null;
    companyName: string;
};

const OrganisationAvatarUpload = ({ id, avatar, companyName }: OrganisationAvatarUploadProps) => {
    const { handleFileUpload, imageUrl, setImageUrl, isLoading } = useUploadCompanyLogo();

    const uploadRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (avatar) setImageUrl(avatar);
    }, [avatar]);

    return (
        <CompanyLogo
            name={companyName}
            imageUrl={proxyImage(imageUrl, 200, 200, 80)}
            onChange={(e) => {
                const file: any = uploadRef?.current?.files ? uploadRef?.current?.files[0] : null;
                handleFileUpload(file, id);
            }}
            hasLoader
            isLoading={isLoading}
            ref={uploadRef}
        />
    );
};

type CompanyLogoProps = {
    onChange: React.ChangeEventHandler<HTMLInputElement>;
    name: string;
    imageUrl: string;
    hasLoader?: boolean;
    isLoading?: boolean;
};

export const CompanyLogo = React.forwardRef(
    ({ onChange, name, imageUrl, hasLoader, isLoading }: CompanyLogoProps, ref: any) => {
        if (hasLoader && isLoading) return <Loading />;

        return (
            <div className="flex items-center mb-10">
                <div className="md:w-32 md:h-32 w-24 h-24 rounded">
                    {imageUrl && imageUrl.length > 0 ? (
                        <img src={imageUrl} alt="Company" className="object-cover md:w-32 w-24 h-full rounded" />
                    ) : (
                        <DefaultProfile name={name} size="100%" />
                    )}
                </div>
                <div className="ml-4 w-3/5 md:w-auto">
                    <Button
                        onClick={() => ref.current?.click()}
                        text="black"
                        className="font-semibold underline cursor-pointer mr-6 border-none"
                    >
                        {imageUrl ? 'Replace Company Logo' : 'Upload Company Logo'}
                    </Button>
                    <p className="text-newGray-darkish mb-4 text-sm">Must be a JPG | PNG | GIF square of any size.</p>
                </div>
                {/* Hidden form to open file selector */}
                <form className="hidden">
                    <input
                        type="file"
                        name="file"
                        accept="image/x-png,image/gif,image/jpeg"
                        ref={ref}
                        onChange={onChange}
                    />
                </form>
            </div>
        );
    }
);

export default OrganisationAvatarUpload;
