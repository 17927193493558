import React, { useContext } from 'react';

import { WindowContext } from './WindowContext';
import { Button } from '../button';

const WindowFooter = () => {
    const {
        footer,
        buttons,
        primaryBtn,
        primaryBtnText,
        primaryBtnColor,
        onPrimaryBtnClick,
        secondaryBtn,
        secondaryBtnText,
        onSecondaryBtnClick,
        tertiaryBtn,
        tertiaryBtnText,
        onTertiaryBtnClick
    } = useContext(WindowContext);

    if (buttons) return buttons;

    const defaultFooter = () => (
        <div
            className={`${
                (primaryBtn || secondaryBtn || tertiaryBtn) && 'p-2'
            }w-full pb-0 flex items-center justify-center flex-wrap`}
        >
            {tertiaryBtn && (
                <Button
                    background="navy-lightest"
                    text="black"
                    onClick={() => (onTertiaryBtnClick ? onTertiaryBtnClick() : true)}
                    className={`px-10 mr-2 font-semibold w-full md:w-auto ml-2 md:mr-2 mb-2`}
                >
                    {tertiaryBtnText ? tertiaryBtnText : 'Third button'}
                </Button>
            )}
            {secondaryBtn && (
                <Button
                    background="navy-lightest"
                    text="black"
                    onClick={() => (onSecondaryBtnClick ? onSecondaryBtnClick() : true)}
                    className={`px-10 mr-2 font-semibold w-full md:w-auto ml-2 md:mr-2 mb-2`}
                >
                    {secondaryBtnText ? secondaryBtnText : 'Cancel'}
                </Button>
            )}
            {primaryBtn && (
                <Button
                    background={primaryBtnColor ? primaryBtnColor : 'newTeal-main'}
                    text="white"
                    onClick={() => (onPrimaryBtnClick ? onPrimaryBtnClick() : true)}
                    className={`px-10 mr-2 font-semibold w-full md:w-auto ml-2 md:mr-2 mb-2`}
                >
                    {primaryBtnText || 'Confirm'}
                </Button>
            )}
        </div>
    );

    return (
        <div className="md:relative fixed bottom-0 left-0 right-0 pb-0 z-0 bg-white border-t border-navy-lightest rounded h-auto md:border-none">
            {footer ? footer : defaultFooter()}
        </div>
    );
};

export default WindowFooter;
