import React, { FC } from 'react';
import ReactTooltip from 'react-tooltip';

import { ReactComponent as BlackPlus } from '../../assets/images/plus/black.svg';
import { ReactComponent as WhitePlus } from '../../assets/images/plus/white.svg';
import { Button, ButtonProps } from '../button';

interface AddButtonProps extends ButtonProps {
    primary?: boolean;
    secondary?: boolean;
    id: string;
    tooltip?: string;
}

export const AddButton: FC<AddButtonProps> = ({ primary, secondary, children, id, tooltip, ...props }) => {
    return (
        <div data-for={id} data-tip={tooltip}>
            <Button text={primary ? 'black' : 'white'} background={primary ? 'white' : 'black'} {...props}>
                {primary ? (
                    <BlackPlus data-testid="add-button--black" className="w-6" />
                ) : (
                    <WhitePlus data-testid="add-button--white" className="w-6" />
                )}
                {children && <span className="ml-1">{children}</span>}
            </Button>
            <ReactTooltip
                id={id}
                backgroundColor="black"
                textColor="white"
                effect="solid"
                place="left"
                clickable={false}
                borderColor="white"
            />
        </div>
    );
};
