import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { ReactComponent as Close } from '../../../../../../assets/images/cross/black.svg';
import { ReactComponent as Info } from '../../../../../../assets/images/info/gray.svg';

export type WindowHeaderProps = {
    stage?: string;
    title?: string;
    description?: string;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
    backTo: string;
    content?: React.ReactNode;
    infoIcon?: boolean;
    infoText?: string;
    className?: string;
};
const WindowHeader = ({
    stage,
    title,
    description,
    setShow,
    backTo,
    content,
    infoIcon,
    infoText,
    className
}: WindowHeaderProps) => {
    const history = useHistory();

    const [showInfoText, setShowInfoText] = useState<boolean>(false);

    return (
        <div className={`relative ${className}`}>
            {/* Cross */}
            <Close
                className="w-6 h-6 absolute top-0 right-0 mr-6 mt-6 cursor-pointer"
                onClick={() => {
                    setShow(false);
                    history.push(backTo!);
                }}
            />
            {!content ? (
                <div className={`pt-6 ${description ? 'pb-8' : 'pb-2'} border-b border-newTeal-main`}>
                    <h1 className="text-center font-semibold text-3xl mb-8">
                        Stage {stage}
                        <span className="font-normal text-sm">/6</span>
                    </h1>
                    <h3 className="text-center font-semibold text-lg mb-4">{title}</h3>
                    {description && (
                        <>
                            <div className="relative flex justify-center">
                                <p className="text-center max-w-3xl mx-auto px-2">{description}</p>
                            </div>
                            {infoIcon && (
                                <div className="relative flex justify-center">
                                    <Info
                                        className="w-6 h-6 mt-2"
                                        onMouseOver={() => setShowInfoText(true)}
                                        onMouseLeave={() => setShowInfoText(false)}
                                    />
                                    {showInfoText && (
                                        <p className="absolute top-0 mt-8 -ml-3 bg-white rounded text-xs text-gray-800 shadow-sm px-2">
                                            {infoText ?? ''}
                                        </p>
                                    )}
                                </div>
                            )}
                        </>
                    )}
                </div>
            ) : (
                content
            )}
        </div>
    );
};

export default WindowHeader;
