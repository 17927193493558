import React, { useContext } from 'react';

import { FilterContext } from './context';
import { ReactComponent as CloseIcon } from '../../assets/images/cross/white.svg';
import { OrderBy } from '../../generated/graphql';
import { RadioValue } from '../Inputs/Radio';

type SortChipProps = {
    sortOptions: RadioValue[];
};

const SortChip = ({ sortOptions }: SortChipProps) => {
    const { saveSort, sort } = useContext(FilterContext);
    return (
        <div
            className={
                'bg-newGray-main text-white flex items-center pr-2 cursor-pointer rounded-l ml-1 h-7 text-xs rounded-r'
            }
            style={{
                minWidth: '7rem'
            }}
        >
            {sort ? (
                <div
                    className="flex items-center justify-center cursor-pointer"
                    style={{
                        width: '2rem'
                    }}
                    onClick={() => saveSort(undefined)}
                >
                    <CloseIcon width={13} height={13} />
                </div>
            ) : (
                <></>
            )}
            <p>
                Sort {sort ? `- ${sortOptions?.find((s) => s.id === sort.field)?.label}` : 'by '}
                {sort ? (sort.order === OrderBy.Asc ? ' (Asc)' : ' (Desc)') : ''}
            </p>
        </div>
    );
};

export default SortChip;
