import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';
import FlipMove from 'react-flip-move';
import { useParams } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';

import SaveTemplateWindow from './SaveTemplateWindow';
import { ReactComponent as Globe } from '../../../assets/images/globe/black.svg';
import { ReactComponent as Info } from '../../../assets/images/info/black_old.svg';
import { ReactComponent as Plus } from '../../../assets/images/plus/black.svg';
import { useGetTemplateDetailsQuery } from '../../../generated/graphql';
import Accordion from '../../../shared/Accordian';
import { Button } from '../../../shared/button';
import { getUserFullName } from '../../../shared/helpers';
import { isTenantSide } from '../../../shared/helpers';
import useMobileScreen from '../../../shared/hooks/useMobileScreen';
import Loading from '../../../shared/Loading';
import AddClauseWindow from '../../../shared/Negotiator/AddClauseWindow';
import Clause from '../../../shared/Negotiator/Clause';
import { DealType } from '../../../shared/Negotiator/ConfirmClauseDeleteWindow.tsx';
import Group from '../../../shared/Negotiator/Group';
import NewClauseWindow from '../../../shared/Negotiator/NewClauseWindow';
import Section from '../../../shared/Negotiator/Section';

type TemplateProps = {
    setHeaderContent: React.Dispatch<React.SetStateAction<React.ReactChild | null>>;
};

export type AddClauseGroupInput = {
    section: string;
    group: string;
    clauseGroupID: string;
    clauseGroupTitle: string;
    clauseLength: number;
};

const Template = ({ setHeaderContent }: TemplateProps) => {
    const mobileScreen = useMobileScreen();
    const { id }: { id: string } = useParams();
    const {
        loading: detailsLoading,
        data: detailsData,
        refetch
    } = useGetTemplateDetailsQuery({
        fetchPolicy: 'network-only',
        variables: {
            templateID: id
        }
    });

    const [openDetails, setOpenDetails] = useState<{
        section?: string;
        level2Group?: string;
    } | null>(null);

    const [showSaveTemplateWindow, setShowSaveTemplateWindow] = useState<boolean>(false);
    const [showAddClauseWindow, setShowAddClauseWindow] = useState<boolean>(false);
    const [showFilterAddClauseWindow, setShowFilterAddClauseWindow] = useState<AddClauseGroupInput | undefined>(
        undefined
    );
    const [showNewClauseWindow, setShowNewClauseWindow] = useState<boolean>(false);
    const [isClauseReordering, setIsClauseReordering] = useState<boolean>(false);
    const [isTitlesReordering, setTitlesReordering] = useState<boolean>(false);
    useEffect(() => {
        // Reset filter on window close
        !showAddClauseWindow && setOpenDetails(null);
    }, [showAddClauseWindow]);

    // Set the header to the building & unit name
    useEffect(() => {
        const title =
            detailsData?.template.__typename === 'Template' && detailsData.template.name
                ? detailsData.template.name
                : '';
        setHeaderContent(
            <div className="flex justify-between w-full items-center">
                <h1 className="text-xl font-bold p-2 flex items-center justify-center gap-2">
                    {detailsData?.template.__typename === 'Template' ? (
                        <>
                            {detailsData.template.global && (
                                <Globe className="w-6 h-6 stroke-current text-newTeal-main" />
                            )}
                            {mobileScreen && title.length > 22 ? title.substr(0, 22) + '...' : title}
                        </>
                    ) : (
                        'Untitled Template'
                    )}
                </h1>
                <p className="text-sm -mt-1 z-10 flex flex-col-reverse items-end">
                    <p className="font-light text-sm">
                        {(detailsData?.template.created ? 'by ' : 'Created by ') +
                            getUserFullName(detailsData?.template.creator)}
                    </p>
                    {detailsData?.template.created &&
                        detailsData?.template.created &&
                        'Created on ' + format(new Date(detailsData?.template.created), 'dd MMM yyyy, h:mm a')}
                </p>
            </div>
        );
    }, [setHeaderContent, detailsData, mobileScreen]);

    if (detailsLoading) return <Loading />;

    return (
        <div>
            {/* WINDOWS */}
            {showSaveTemplateWindow && detailsData?.template.__typename === 'Template' && (
                <SaveTemplateWindow
                    templateID={detailsData?.template._id}
                    name={detailsData.template.name}
                    organisationName={detailsData.template.organisation.name}
                    description={detailsData.template.description}
                    global={detailsData.template.global}
                    setShow={setShowSaveTemplateWindow}
                    refetch={refetch}
                    uses={detailsData.template.associatedUses}
                />
            )}
            {(showAddClauseWindow || showFilterAddClauseWindow) && detailsData?.template.__typename === 'Template' && (
                <AddClauseWindow
                    dealID={detailsData?.template.deal._id}
                    templateUses={detailsData.template.associatedUses ?? undefined}
                    deal={detailsData.template.deal}
                    organisationID={detailsData.template.organisation._id}
                    isTemplate={true}
                    refetch={refetch}
                    setShowNewClause={setShowNewClauseWindow}
                    setShow={(input) => {
                        showFilterAddClauseWindow
                            ? setShowFilterAddClauseWindow(undefined)
                            : setShowAddClauseWindow(input);
                    }}
                    include={!isTenantSide(detailsData.template.deal) ? openDetails : null}
                    selectedAll={true}
                />
            )}
            {showNewClauseWindow && detailsData?.template.__typename === 'Template' && (
                <NewClauseWindow
                    sections={detailsData?.template.deal?.sections ?? []}
                    dealID={detailsData?.template.deal._id}
                    organisationID={detailsData.template.organisation._id}
                    refetch={refetch}
                    setShow={setShowNewClauseWindow}
                />
            )}

            {/* Template controls header bar */}
            <div className="sticky z-10 h-20 top-0 border-b border-navy-light sm:px-4 md:px-7 py-2 flex flex-row justify-between items-center bg-white actor-list-shadow mb-2">
                <div className="flex flex-row items-center w-2/3">
                    <div
                        className=" sm:hidden md:flex flex-row items-center font-semibold w-52 border-r border-gray-600 h-12 pr-4 mr-4 "
                        data-for={'templateName'}
                        data-tip={`${detailsData?.template.name} - ${`Created by ${getUserFullName(
                            detailsData?.template.creator
                        )} -
                                Created on
                                ${format(new Date(detailsData?.template.created), 'dd MMM yyyy, h:mm a')}`}`}
                    >
                        <Info className="w-4 h-4 mr-4 " />
                        <div className="w-44 truncate">{detailsData?.template.name}</div>
                    </div>
                    <div className="flex flex-col">
                        <div className="text-newGray-darkish text-xs">Description:</div>
                        <div className="text-xs font-semibold truncate-second-line">
                            {detailsData?.template.description}
                        </div>
                    </div>
                </div>
                <div>
                    {isClauseReordering || isTitlesReordering ? (
                        <Button
                            onClick={() => {
                                if (isClauseReordering) {
                                    setIsClauseReordering(false);
                                } else {
                                    setTitlesReordering(false);
                                }
                            }}
                            background="newTeal-main"
                            className="font-semibold text-white rounded-lg sm:w-14 md:w-20 lg:w-36 xl:w-48 bg-navy-main px-11"
                            height="11"
                            text="sm"
                        >
                            Done
                        </Button>
                    ) : (
                        <div className="flex flex-row items-center">
                            <Button
                                onClick={() => setShowAddClauseWindow(true)}
                                text="sm"
                                height="11"
                                background="navy-main"
                                className="mr-2 font-semibold rounded-lg sm:w-14 md:w-20 lg:w-36 xl:w-48 text-white sm:px-5 md:px-8 xl:px-14 justify-between items-center"
                            >
                                <Plus className="w-4 h-4" />
                                <p className="sm:flex sm:hidden lg:flex">Clause</p>
                            </Button>
                            <Button
                                onClick={() => setShowSaveTemplateWindow(true)}
                                background="newTeal-main"
                                className="font-semibold rounded-lg sm:w-14 md:w-20 lg:w-36 xl:w-48 bg-navy-main md:px-11 text-white"
                                height="11"
                                text="sm"
                            >
                                Save
                            </Button>
                        </div>
                    )}
                </div>
            </div>
            {/* CONTAINER */}
            <div
                className={`container  flex flex-col
                    ${mobileScreen ? 'pt-20 w-full' : 'pt-4 mx-auto px-4 md:px-0 '}`}
            >
                {/* Sections */}
                {detailsData?.template.deal?.sections?.map((section, index) => (
                    <div className="bg-white mb-4 rounded-xl actor-list-shadow py-8 px-10 pb-8" key={section._id}>
                        <Section
                            description={section.description}
                            rics={section.rics}
                            isReordering={isTitlesReordering}
                            length={detailsData?.template?.deal?.sections?.length}
                            index={index}
                            deal={detailsData.template.deal}
                            setShow={setShowAddClauseWindow}
                            setOpenDetails={setOpenDetails}
                            id={section._id}
                            key={section._id}
                            title={section.title}
                            setTitlesReordering={setTitlesReordering}
                            setClauseReordering={setIsClauseReordering}
                        >
                            {' '}
                        </Section>
                        {section.groups?.map((group, groupIndex) => (
                            <div className="mt-0" key={group._id}>
                                <Accordion
                                    rics={group.rics}
                                    isReordering={isTitlesReordering}
                                    parentID={group.parentID}
                                    sectionID={section._id}
                                    description={group.description}
                                    id={group._id}
                                    deal={detailsData.template.deal}
                                    length={section.groups?.length}
                                    index={groupIndex}
                                    title={group.title}
                                    className="bg-white py-4"
                                    isOpen={true}
                                    smallHeight={true}
                                >
                                    <FlipMove leaveAnimation="accordionVertical">
                                        {group.clauses?.map((clause, index) => (
                                            <Clause
                                                id={clause._id}
                                                parentID={clause.parentID}
                                                type={DealType.TEMPLATE}
                                                deal={detailsData.template.deal}
                                                templateID={
                                                    detailsData?.template.__typename === 'Template'
                                                        ? detailsData.template._id
                                                        : undefined
                                                }
                                                key={clause._id}
                                                value={clause.value ?? ''}
                                                valueSuffix={clause.valueSuffix ?? ''}
                                                shortForm={clause.shortForm ?? ''}
                                                longForm={clause.longForm ?? ''}
                                                tags={clause.tags ?? []}
                                                visible={!!clause.visible}
                                                isReordering={isClauseReordering}
                                                index={index}
                                                linkedClauses={clause.linked}
                                                dealBreaker={clause.dealBreaker}
                                                clauses={group.clauses?.length}
                                                sectionName={section.title}
                                                groupName={group.title}
                                                groupID={group._id}
                                            >
                                                <>
                                                    {clause.groups?.map((level2Group, level2GroupIndex) => (
                                                        <Group
                                                            id={level2Group._id}
                                                            parentID={level2Group.parentID}
                                                            deal={detailsData.template.deal}
                                                            key={level2Group._id}
                                                            title={level2Group.title}
                                                            level={2}
                                                            isReordering={isClauseReordering}
                                                            clauseID={clause._id}
                                                            index={level2GroupIndex}
                                                            clauses={group.clauses?.length}
                                                            setShow={setShowAddClauseWindow}
                                                            setOpenDetails={setOpenDetails}
                                                            clauseGroupInput={{
                                                                section: section.title,
                                                                group: group.title,
                                                                groupLength: level2Group.clauses
                                                                    ? level2Group.clauses.length
                                                                    : 0
                                                            }}
                                                            type={DealType.TEMPLATE}
                                                            templateID={
                                                                detailsData?.template.__typename === 'Template'
                                                                    ? detailsData.template._id
                                                                    : undefined
                                                            }
                                                        >
                                                            <FlipMove leaveAnimation="accordionVertical">
                                                                {level2Group.clauses?.map((level2Clause, index) => (
                                                                    <Clause
                                                                        id={level2Clause._id}
                                                                        parentID={level2Clause.parentID}
                                                                        type={DealType.TEMPLATE}
                                                                        deal={detailsData.template.deal}
                                                                        templateID={
                                                                            detailsData?.template.__typename ===
                                                                            'Template'
                                                                                ? detailsData.template._id
                                                                                : undefined
                                                                        }
                                                                        key={level2Clause._id}
                                                                        value={level2Clause.value ?? ''}
                                                                        valueSuffix={level2Clause.valueSuffix ?? ''}
                                                                        shortForm={level2Clause.shortForm ?? ''}
                                                                        longForm={level2Clause.longForm ?? ''}
                                                                        tags={level2Clause.tags ?? []}
                                                                        isReordering={isClauseReordering}
                                                                        index={index}
                                                                        clauses={level2Group.clauses?.length}
                                                                        linkedClauses={level2Clause.linked}
                                                                        dealBreaker={level2Clause.dealBreaker}
                                                                        sectionName={section.title}
                                                                        groupName={group.title}
                                                                        groupID={level2Group._id}
                                                                        clauseGroupName={level2Group.title}
                                                                    />
                                                                ))}
                                                            </FlipMove>
                                                        </Group>
                                                    ))}
                                                </>
                                            </Clause>
                                        ))}
                                    </FlipMove>
                                </Accordion>
                            </div>
                        ))}
                    </div>
                ))}
            </div>
            <ReactTooltip
                id="templateName"
                backgroundColor="black"
                textColor="white"
                effect="solid"
                place="bottom"
                clickable={false}
                borderColor="white"
            />
        </div>
    );
};

export default Template;
