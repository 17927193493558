import React, { useState } from 'react';

import { DealDetailsFragment, GetDealDetailsDocument, useOrganisationActivateMutation } from '../../generated/graphql';
import Input, { InputType } from '../../shared/Inputs';
import Window from '../../shared/Window';

type NewTenantCompanyWindowProps = {
    deal: DealDetailsFragment;
    setNewTenantCompanyWindow: React.Dispatch<React.SetStateAction<boolean>>;
};

const NewTenantCompanyWindow = ({ deal, setNewTenantCompanyWindow }: NewTenantCompanyWindowProps) => {
    const [name, setName] = useState<string>();
    const [email, setEmail] = useState<string>();
    const [sendInvitation, setSendInvitation] = useState<boolean>(false);

    const [nameError, setNameError] = useState<string>();

    const [activateOrganisation] = useOrganisationActivateMutation();
    const handleActivateTenant = () => {
        if (!name) {
            setNameError('required');
            return;
        }

        activateOrganisation({
            variables: {
                name,
                organisationID: deal?.tenant?._id!,
                email: email,
                sendInvite: sendInvitation
            },
            refetchQueries: [GetDealDetailsDocument],
            onCompleted: () => {
                setNewTenantCompanyWindow(false);
            }
        });
    };

    return (
        <Window
            title={`New Tenant`}
            titleCenter
            width="w-2/5"
            setShow={setNewTenantCompanyWindow}
            primaryBtn
            primaryBtnText={'Create Tenant Company'}
            onPrimaryBtnClick={handleActivateTenant}
            disableClose
        >
            <>
                <div className="px-40 py-4 bg-gray-200 text-xs flex text-center items-center">
                    <p>
                        {`You have been invited to negotiate for space ${deal.unit?.name} by ${deal.unit?.organisation.name}.
                        Use this form to set up the tenant company you are representing.`}
                    </p>
                </div>
                <div className="flex flex-col items-center px-4 pt-4">
                    <div className="border-b pb-4 w-full">
                        <Input
                            type={InputType.text}
                            placeholder="Tenant Company Name"
                            label="Tenant Company Name"
                            className="w-full"
                            required
                            showRequiredAsterisk
                            value={name}
                            onChange={(e) => {
                                if (e.target.value) setNameError(undefined);
                                setName(e.target.value);
                            }}
                        />
                        {nameError && <span className="text-center text-red-600">{nameError}</span>}
                    </div>
                    <Input
                        type={InputType.text}
                        placeholder="Email"
                        label="Email"
                        className="w-full"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                </div>
                <div className="flex border-b mb-4 p-4">
                    <label className="block mb-1 pt-1">
                        <input
                            type="checkbox"
                            id="optional"
                            checked={sendInvitation}
                            onChange={() => setSendInvitation(!sendInvitation)}
                            className="leading-tight w-6 h-6 form-checkbox p-2 rounded bg-white text-newTeal-main focus:shadow-none focus:outline-none"
                        />
                        <span className="text-sm text-newGray-darkish ml-2">
                            Select this box if you don't want the Tenant User to receive an Invite
                        </span>
                    </label>
                </div>
            </>
        </Window>
    );
};

export default NewTenantCompanyWindow;
