import { useEffect, useRef } from 'react';

/**
 * Hook that alerts clicks outside of the passed ref
 */
export const useOuterClick = (callback: (e: MouseEvent) => void) => {
    const callbackRef = useRef<((e: MouseEvent) => void) | null>(null);
    const innerRef = useRef<any>(null);

    useEffect(() => {
        callbackRef.current = callback;
    });

    useEffect(() => {
        const handleClick = (e: MouseEvent) => {
            if (innerRef.current && callbackRef.current && !innerRef.current.contains(e.target)) callbackRef.current(e);
        };

        document.addEventListener('click', handleClick);

        return () => document.removeEventListener('click', handleClick);
    }, []);

    return innerRef;
};
