import { useApolloClient } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { useIntercom } from 'react-use-intercom';

import { useLogoutMutation } from '../../generated/graphql';

/**
 * Log the user out
 * @returns Function to log out, loading state
 */
export const useLogout = () => {
    // SECTION External hooks
    const history = useHistory();
    const client = useApolloClient();
    const { shutdown } = useIntercom();

    // SECTION Mutations
    const [logout, { loading: logoutLoading }] = useLogoutMutation();

    const handleLogout = async () => {
        const { errors } = await logout();

        // Check for errors logging out
        if (errors) {
            return;
        }

        // Remove any cached data for the current user
        await client.clearStore();

        shutdown();

        history.push('/login');
        // window.location.reload(true)
    };

    return { logout: handleLogout, loading: logoutLoading };
};
