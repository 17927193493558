import { createContext, Dispatch, SetStateAction } from 'react';

import { TenantDetailsFragment } from '../../../../../generated/graphql';

export type TenantDetails = TenantDetailsFragment;

export type StageContextProps = {
    setShowStage1Window: Dispatch<SetStateAction<boolean>>;
    setShowStage2Window: Dispatch<SetStateAction<boolean>>;
    setShowStage3Window: Dispatch<SetStateAction<boolean>>;
    setShowStage4Window: Dispatch<SetStateAction<boolean>>;
    setShowStage5Window: Dispatch<SetStateAction<boolean>>;
    setShowStage6Window: Dispatch<SetStateAction<boolean>>;
    setShowFinalStageWindow: Dispatch<SetStateAction<boolean>>;
    setShowLawyerWindow: Dispatch<SetStateAction<boolean>>;
    setShowAgentWindow: Dispatch<SetStateAction<boolean>>;
    setShowContractorWindow: Dispatch<SetStateAction<boolean>>;
    setShowDBContractorWindow: Dispatch<SetStateAction<boolean>>;
    setShowManagerWindow: Dispatch<SetStateAction<boolean>>;
    setShowSpecialistWindow: Dispatch<SetStateAction<boolean>>;
    backTo: string;
    unitID?: string | undefined;
    dealID: string;
    details: TenantDetails;
};

export default createContext<Partial<StageContextProps>>({});
