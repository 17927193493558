import React from 'react';

type CheckboxProps = {
    label: string;
    setValue: React.Dispatch<React.SetStateAction<boolean>> | ((e: boolean) => void);
    value: boolean;
    className?: string;
    inputClassName?: string;
    style?: object;
    justifyEnd?: boolean;
    reverse?: boolean;
};

const Checkbox = ({ label, value, setValue, className, inputClassName, style, justifyEnd, reverse }: CheckboxProps) => {
    return (
        <div
            onClick={() => setValue(!value)}
            className={`flex ${
                justifyEnd ? 'justify-end' : 'justify-between'
            } items-center p-3 rounded cursor-pointer ${className}`}
            style={style}
        >
            <p>{label}</p>
            <input
                type="checkbox"
                checked={value}
                onChange={(e) => setValue(e.target.checked)}
                className={`${
                    reverse ? 'form-checkbox-reverse' : 'form-checkbox'
                } p-2 cursor-pointer rounded-sm outline-none bg-white text-newTeal-main focus:shadow-none focus:outline-none ${inputClassName}`}
            />
        </div>
    );
};

export default Checkbox;
