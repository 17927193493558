import React from 'react';
import { NavLink } from 'react-router-dom';

import pkg from '../../../package.json';
import { ReactComponent as Bell } from '../../assets/images/bell/black.svg';
import { ReactComponent as Briefcase } from '../../assets/images/briefcase/black.svg';
import { ReactComponent as Company } from '../../assets/images/company/company.svg';
import { ReactComponent as CPU } from '../../assets/images/cpu/black.svg';
import { ReactComponent as Server } from '../../assets/images/server/black.svg';
import { ReactComponent as User } from '../../assets/images/user/black.svg';
import { ReactComponent as UserChecked } from '../../assets/images/userCheck/user-check.svg';
import { ReactComponent as Users } from '../../assets/images/users/black.svg';
import { useGetLayoutUserInfoQuery } from '../../generated/graphql';
import { urlToQsInfo } from '../../shared/helpers';
import useMobileScreen from '../../shared/hooks/useMobileScreen';

export type SidebarProps = {
    showMobile: boolean;
};

const Sidebar = ({ showMobile }: SidebarProps) => {
    const { data } = useGetLayoutUserInfoQuery();

    const isMobile = useMobileScreen();

    return (
        <div
            className={`${
                !showMobile && 'hidden'
            } lg:flex bg-white fixed md:max-w-xs w-full flex-col py-4 border-r border-navy-light z-10 min-h-full-header-mobile md:min-h-full-header`}
        >
            <span className="text-newGray-darkish text-xs mb-2 ml-6">Account</span>
            <NavLink
                to={urlToQsInfo('/settings/profile', data?.user.type, data?.user.organisation.name)}
                className={`p-2 flex items-center px-6 ${showMobile && 'pt-4 mb-4'}`}
                activeClassName="px-6 bg-navy-lightest border-l-2 border-newTeal-main font-semibold"
            >
                <User className="w-5 h-5 mr-2" />
                <span>Account</span>
            </NavLink>
            <NavLink
                to={urlToQsInfo('/settings/notifications', data?.user.type, data?.user.organisation.name)}
                className={`p-2 flex items-center px-6 ${showMobile && 'pt-4 mb-4'}`}
                activeClassName="px-6 bg-navy-lightest border-l-2 border-newTeal-main font-semibold"
            >
                <Bell className="w-5 h-5 mr-2 text-black" />
                <span>Notifications</span>
            </NavLink>
            <span className={`text-newGray-darkish text-xs mb-2 ml-6 mt-8`}>
                {data?.user.type === 'AGENT' ? 'Practice' : 'Company'}
            </span>
            <NavLink
                to={urlToQsInfo('/settings/company', data?.user.type, data?.user.organisation.name)}
                className={`p-2 flex items-center px-6 ${showMobile && 'py-4'}`}
                activeClassName={`px-6 bg-navy-lightest border-l-2 border-newTeal-main font-semibold`}
            >
                <Briefcase className="w-5 h-5 mr-2" />
                <span>{data?.user.type === 'AGENT' ? 'Practice Details' : 'Company Details'}</span>
            </NavLink>
            <NavLink
                to={urlToQsInfo('/settings/team', data?.user.type, data?.user.organisation.name)}
                className={`p-2 flex items-center px-6 ${showMobile && 'py-4'}`}
                activeClassName="bg-navy-lightest border-l-2 border-newTeal-main font-semibold"
            >
                <Users className="w-5 h-5 mr-2" />
                <span>{data?.user.type === 'AGENT' ? 'Colleagues' : 'Users'}</span>
            </NavLink>
            {data?.user.__typename === 'User' && data.user.type === 'AGENT' && (
                <NavLink
                    to={urlToQsInfo('/settings/clients', data?.user.type, data?.user.organisation.name)}
                    className={`p-2 flex items-center px-6 ${showMobile && 'py-4'}`}
                    activeClassName={`px-6 bg-navy-lightest border-l-2 border-newTeal-main font-semibold`}
                >
                    <UserChecked className="w-5 h-5 mr-2" />
                    <span>Clients</span>
                </NavLink>
            )}

            {/* ensure loading before sending to null route */}
            {data?.user.__typename === 'User' && data.user.type !== 'TENANT' && (
                <NavLink
                    exact
                    to={urlToQsInfo(
                        `/settings/clausebank/${data?.user.__typename === 'User' ? data?.user.organisation?._id : ''}`,
                        data?.user.type,
                        data?.user.organisation.name
                    )}
                    className={`p-2 flex items-center px-6 ${showMobile && 'py-4 mb-4'}`}
                    activeClassName="bg-navy-lightest border-l-2 border-newTeal-main font-semibold"
                >
                    <Server className="w-5 h-5 mr-2" />
                    <span>Clause Bank</span>
                </NavLink>
            )}
            {data?.user.__typename === 'User' && data.user?.type === 'ADMIN' && !isMobile && (
                <>
                    <span className="text-newGray-darkish text-xs mb-2 ml-6 mt-8">Admin</span>
                    <NavLink
                        to={urlToQsInfo('/settings/clausebank', data?.user.type, data?.user.organisation.name)}
                        exact
                        className={`p-2 flex items-center px-6 ${showMobile && 'py-4'}`}
                        activeClassName="bg-navy-lightest border-l-2 border-newTeal-main font-semibold"
                    >
                        <Server className="w-5 h-5 mr-2" />
                        <span>Clause Bank</span>
                    </NavLink>
                    <NavLink
                        to={urlToQsInfo('/settings/landlordreadiness', data?.user.type, data?.user.organisation.name)}
                        exact
                        className={`p-2 flex items-center px-6 ${showMobile && 'py-4'}`}
                        activeClassName="bg-navy-lightest border-l-2 border-newTeal-main font-semibold"
                    >
                        <Server className="w-5 h-5 mr-2" />
                        <span>Landlord Readiness</span>
                    </NavLink>
                    <NavLink
                        exact
                        to={urlToQsInfo('/settings/companies', data?.user.type, data?.user.organisation.name)}
                        className={`p-2 flex items-center px-6 ${showMobile && 'py-4'}`}
                        activeClassName="bg-navy-lightest border-l-2 border-newTeal-main font-semibold"
                    >
                        {' '}
                        <Company className="w-6 h-5 mr-1" />
                        <span>Companies</span>
                    </NavLink>
                    <NavLink
                        exact
                        to={urlToQsInfo('/settings/users', data?.user.type, data?.user.organisation.name)}
                        className={`p-2 flex items-center px-6 ${showMobile && 'py-4'}`}
                        activeClassName="bg-navy-lightest border-l-2 border-newTeal-main font-semibold"
                    >
                        <Users className="w-5 h-5 mr-2" />
                        <span>Users</span>
                    </NavLink>

                    {process.env.REACT_APP_MONITORING_URL && (
                        <a
                            href={process.env.REACT_APP_MONITORING_URL}
                            target="_blank"
                            rel="noreferrer"
                            className={`p-2 flex items-center px-6 ${showMobile && 'py-4'}`}
                            // activeClassName="bg-navy-lightest border-l-2 border-newTeal-main font-semibold"
                        >
                            <CPU className="w-5 h-5 mr-2" />
                            <span>Monitoring</span>
                        </a>
                    )}
                </>
            )}

            {/* Version Number */}
            <div className="absolute bottom-2 left-0 right-0 w-full flex justify-center">
                <p className="text-sm text-gray-600">
                    Kato v{pkg.version}
                    {process.env.NODE_ENV === 'development' && ' Beta'}
                </p>
            </div>
        </div>
    );
};

export default Sidebar;
