import React, { RefObject, useEffect, useRef, useState } from 'react';

import ClauseSettings from './ClauseSettings';
import ClauseValue from './ClauseValue';
import ConfirmClauseDeleteWindow, { DealType } from './ConfirmClauseDeleteWindow.tsx';
import DealBreakerWindow from './DealBreakerWindow';
import useMoveClause from './hooks/useMoveClause';
import SwapClauseWindow from './SwapClauseWindow';
import { ReactComponent as Down } from '../../assets/images/arrow/down/black.svg';
import { ReactComponent as Up } from '../../assets/images/arrow/up/black.svg';
import { ReactComponent as Lock } from '../../assets/images/lock/black.svg';
import {
    DealBreaker,
    DealDetailsFragment,
    useSetDealBreakerMutation,
    useUpdateClauseMutation
} from '../../generated/graphql';
import EditClauseWindow from '../../pages/Deals/Deal/Negotiate/EditClauseWindow';
import { Button } from '../button';
import { formatClause } from '../helpers';
import useMobileScreen from '../hooks/useMobileScreen';
import Transition from '../Transition';

export type LinkedClauses = {
    value?: string | null;
    valueSuffix?: string | null;
    shortForm?: string | null;
    longForm?: string | null;
    tags?: string[] | null;
}[];

type ClauseProps = {
    id: string;
    parentID: string;
    type: DealType;
    deal: DealDetailsFragment | null;
    templateID?: string;
    value?: string;
    valueSuffix?: string;
    shortForm?: string;
    longForm?: string;
    tags: string[];
    visible?: boolean;
    sectionDescription?: string | null;
    sectionRics?: string | null;
    groupDescription?: string | null;
    isReordering?: boolean;
    index?: number;
    clauses?: number;
    sectionName: string;
    groupName: string;
    groupID: string;
    clauseGroupName?: string;
    linkedClauses: LinkedClauses | [] | null | undefined;
    dealBreaker?: DealBreaker | null;
    children?: React.ReactChild;
};

const Clause = React.forwardRef(
    (
        {
            id,
            parentID,
            type,
            deal,
            templateID,
            value,
            valueSuffix,
            shortForm,
            longForm,
            tags,
            visible = true,
            isReordering = false,
            clauses = 0,
            index = 0,
            sectionName,
            groupName,
            groupID,
            clauseGroupName,
            linkedClauses,
            dealBreaker,
            children
        }: ClauseProps,
        ref: string | ((instance: HTMLDivElement | null) => void) | RefObject<HTMLDivElement> | null | undefined
    ) => {
        const mobileScreen = useMobileScreen();

        const [setDealBreaker] = useSetDealBreakerMutation();

        const formattedLongForm = {
            __html: formatClause(longForm, value, valueSuffix, tags) ?? ''
        };

        const [isDeleting, setDeleting] = useState<boolean>(false);
        const [showSwapClauseWindow, setShowSwapClauseWindow] = useState<boolean>(false);
        const [showDealBreakerWindow, setShowDealBreakerWindow] = useState<boolean>(false);
        const [showCantFind, setShowCantFind] = useState<boolean>(false);

        const moveClause = useMoveClause(deal, id, groupID, parentID);

        // Edit clause
        const [updateClause, { error: updateClauseError, loading: updateClauseLoading }] = useUpdateClauseMutation();
        const [isEditing, setEditing] = useState<boolean>(false);
        const valueRef = useRef<HTMLInputElement>();

        useEffect(() => {
            if (isEditing) {
                valueRef.current?.classList.remove('border-none');
                valueRef.current?.focus();
            } else {
                valueRef.current?.classList.add('border-none');
            }
        }, [isEditing]);

        const [isSaving, setIsSaving] = useState(false);

        const handleSaveClick = async () => {
            // check if clause is being saved to avoid race condition
            // between onBlur submit and Save click
            if (!isSaving) setIsSaving(true);
            else return;

            // Setup Value as Old Value
            const oldValue = value;

            if (valueRef.current?.value === '*') {
                await setDealBreaker({
                    variables: {
                        id,
                        parentID,
                        dealID: deal?._id ?? '',
                        dealBreaker: false
                    }
                });
            }

            await updateClause({
                variables: {
                    dealID: deal?._id ?? '',
                    parentID,
                    clauseID: id,
                    value:
                        oldValue !== '' && valueRef.current?.value === ''
                            ? '*'
                            : oldValue === '*' && valueRef.current?.value === ''
                            ? '*'
                            : valueRef.current?.value
                }
            });

            if (!updateClauseError) setEditing(false);

            setIsSaving(false);
        };

        return (
            <>
                {/* Clause delete confirm window */}
                {isDeleting && (
                    <ConfirmClauseDeleteWindow
                        type={type}
                        clauseID={id}
                        groupID={groupID}
                        parentID={parentID}
                        templateID={templateID}
                        deal={deal}
                        setShow={setDeleting}
                    />
                )}
                {/* Clause swap window */}
                {showSwapClauseWindow && (
                    <SwapClauseWindow
                        clauseID={id}
                        parentID={parentID}
                        dealID={deal?._id ?? ''}
                        currentValue={value}
                        currentValueSuffix={valueSuffix}
                        currentShortForm={shortForm}
                        currentLongForm={longForm}
                        currentTags={tags}
                        setShow={setShowSwapClauseWindow}
                        setShowCantFind={setShowCantFind}
                        linkedClauses={linkedClauses ?? []}
                        section={sectionName}
                        group={groupName}
                        clauseGroup={clauseGroupName}
                        isTemplate
                    />
                )}
                {/* Set deal breaker window */}
                {showDealBreakerWindow && (
                    <DealBreakerWindow
                        clauseID={id}
                        parentID={parentID}
                        dealID={deal?._id ?? ''}
                        dealBreaker={dealBreaker}
                        setShow={setShowDealBreakerWindow}
                    />
                )}

                {/* Clause edit window */}
                {showCantFind && deal?._id && (
                    <EditClauseWindow
                        setShow={setShowCantFind}
                        dealID={deal._id}
                        parentID={parentID}
                        clauseID={id}
                        section={sectionName}
                        group={groupName}
                        shortForm={shortForm}
                        value={value}
                        suffix={valueSuffix}
                        longForm={longForm}
                    />
                )}

                {/* Clause */}
                <div id={id} key={id} ref={ref} className={`w-full rounded border mb-2`}>
                    {/* Header */}
                    {mobileScreen && (
                        <div className={`flex bg-navy-lightest h-14 rounded-tl relative mb-1 headerVisible`}>
                            {isEditing ? (
                                <>
                                    <Button
                                        onClick={handleSaveClick}
                                        background="newTeal-main"
                                        text="white"
                                        className="px-4 w-20 font-semibold mt-1 ml-4"
                                    >
                                        {updateClauseLoading ? 'Saving' : 'Save'}
                                    </Button>
                                    <Button
                                        onClick={() => setEditing(false)}
                                        background="navy-light"
                                        text="black"
                                        className="px-4 w-20 font-semibold mt-1 ml-1"
                                    >
                                        Cancel
                                    </Button>
                                </>
                            ) : (
                                <ClauseSettings
                                    setEditing={setEditing}
                                    setDeleting={setDeleting}
                                    setSwap={setShowSwapClauseWindow}
                                    setDealBreaker={setShowDealBreakerWindow}
                                    suffix={valueSuffix}
                                    side="left"
                                />
                            )}
                        </div>
                    )}
                    {visible && (
                        <div className="relative">
                            <div
                                className={`w-full p-4 pr-0 flex flex-row justify-between items-center relative bg-navy-lightest z-0 overflow-hidden`}
                                style={{ height: '56px' }}
                            >
                                <div className="flex flex-row items-center w-full">
                                    {isReordering && (
                                        <div className="flex flex-row items-center content-center justify-center  border-r border-newGray-main pr-5 mr-5">
                                            <button
                                                className={`cursor-pointer mr-4 focus:outline-none ${
                                                    (clauses <= 1 || index === 0) &&
                                                    'text-newGray-main cursor-not-allowed'
                                                }`}
                                                disabled={clauses <= 1 || index === 0}
                                                onClick={() => moveClause(index, index - 1)}
                                            >
                                                <Up className="w-6 h-6" />
                                            </button>
                                            <button
                                                className={`cursor-pointer mr-px focus:outline-none ${
                                                    (clauses <= 1 || index === clauses - 1) &&
                                                    'text-newGray-main cursor-not-allowed'
                                                }`}
                                                disabled={clauses <= 1 || index === clauses - 1}
                                                onClick={() => moveClause(index, index + 1)}
                                            >
                                                <Down className="w-6 h-6" />
                                            </button>
                                        </div>
                                    )}
                                    {/* Title */}
                                    <div
                                        className={`cursor-pointer flex items-center ${
                                            mobileScreen ? 'w-full' : 'w-1/2'
                                        } py-4 ${!isEditing && 'ml-0 transitionAll'}`}
                                        onClick={() => {
                                            setEditing(false);
                                        }}
                                    >
                                        <div className="flex flex-col gap-1">
                                            <span className={`font-medium transitionAll text-gray-800`}>
                                                {shortForm}
                                            </span>
                                        </div>
                                        <Transition
                                            show={dealBreaker?.isDealBreaker ?? false}
                                            enter="transition-opacity ease-linear duration-200"
                                            enterFrom="opacity-0"
                                            enterTo="opacity-100"
                                            leave="transition-opacity ease-linear duration-200"
                                            leaveFrom="opacity-100"
                                            leaveTo="opacity-0"
                                            className="absolute bottom-0 left-0 ml-4 mb-0.5 flex"
                                        >
                                            <button
                                                className="bg-white text-newTeal-main rounded text-xs px-1 text-center font-semibold flex items-center justify-between gap-1 focus:outline-none"
                                                onClick={(e) => setShowDealBreakerWindow(true)}
                                            >
                                                <Lock className="w-3 h-3" />
                                                Locked Clause
                                            </button>
                                            <span className="text-xs text-newGray-darkish">
                                                (Only you can see this)
                                            </span>
                                        </Transition>
                                    </div>
                                    {!mobileScreen && (
                                        <div className="flex justify-between items-center w-1/2">
                                            <ClauseValue
                                                isEditing={isEditing}
                                                setEditing={setEditing}
                                                valueRef={valueRef}
                                                value={value}
                                                valueSuffix={valueSuffix}
                                                tags={tags}
                                                handleSave={handleSaveClick}
                                            />
                                            {isEditing ? (
                                                <Button
                                                    onClick={handleSaveClick}
                                                    background="newTeal-main"
                                                    text="white"
                                                    className="px-1 w-16 mr-4 text-sm font-semibold rounded"
                                                    height="9"
                                                >
                                                    {updateClauseLoading ? 'Saving' : 'Save'}
                                                </Button>
                                            ) : (
                                                <ClauseSettings
                                                    value={value}
                                                    setEditing={setEditing}
                                                    setDeleting={setDeleting}
                                                    setSwap={setShowSwapClauseWindow}
                                                    setDealBreaker={setShowDealBreakerWindow}
                                                    suffix={valueSuffix}
                                                />
                                            )}
                                        </div>
                                    )}
                                </div>
                            </div>
                            {/* Long form */}
                            <div className="bg-navy-lightest rounded-bl">
                                {mobileScreen && value && (
                                    <div className="border-t-2 border-white">
                                        <ClauseValue
                                            isEditing={isEditing}
                                            setEditing={setEditing}
                                            valueRef={valueRef}
                                            value={value}
                                            valueSuffix={valueSuffix}
                                            tags={tags}
                                            handleSave={handleSaveClick}
                                        />
                                    </div>
                                )}
                                <div
                                    className={`bg-navy-lightest px-4 py-2 text-sm longFormVisible border-t rounded-bl`}
                                    dangerouslySetInnerHTML={formattedLongForm}
                                ></div>
                            </div>
                        </div>
                    )}
                    <div>{children}</div>
                </div>
            </>
        );
    }
);

export default Clause;
