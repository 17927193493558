import { useState } from 'react';

import { Deal, Task, useGetDealDetailsNoHistoryLazyQuery } from '../../../generated/graphql';
import { getLandlordContactInfo, handleFetchError } from '../../helpers';

const useDownloadData = (): [(dealID: string) => Promise<void>, boolean] => {
    const [getDetails] = useGetDealDetailsNoHistoryLazyQuery();
    const [loading, setLoading] = useState<boolean>(false);

    return [
        async (dealID: string) => {
            setLoading(true);

            // Get full deal data from API
            const { data } = await getDetails({
                variables: { dealID }
            });

            const blob = await getCSVBlob(data?.deal as Deal);

            if (!blob || !data) return;

            // Use blob object to create a local URL
            const url = URL.createObjectURL(blob);

            // Create a hidden <a> tag to download
            const a = document.createElement('a');
            /// Point <a> href to blob URL
            a.href = url;
            /// Set file name to the unit's name, or address, or the deal ID
            a.download = `${data.deal.unit?.buildingName ?? data.deal.unit?.address.line1 ?? data.deal._id}.csv`;

            // Release object URL to free up RAM after download
            const clickHandler = () => {
                setTimeout(() => {
                    URL.revokeObjectURL(url);
                    a.removeEventListener('click', clickHandler);
                }, 150);
            };

            a.addEventListener('click', clickHandler, false);

            // Programatically click the <a> element to trigger download
            a.click();

            setLoading(false);
        },
        loading
    ];
};

export const getCSVBlob = async (deal: Deal) => {
    if (deal.__typename !== 'Deal') return handleFetchError('Could not load the deal', undefined, deal);

    const pivot = (arr: any) => {
        let mp = new Map();

        const setValue = (a: any, path: any, val: any) => {
            if (Object(val) !== val) {
                let pathStr = path.join('.');
                let i = (mp.has(pathStr) ? mp : mp.set(pathStr, mp.size)).get(pathStr);
                a[i] = val;
            } else {
                for (let key in val) {
                    setValue(a, key === '0' ? path : path.concat(key), val[key]);
                }
            }
            return a;
        };

        let result = arr.map((obj: any) => setValue([], [], obj));
        return [[...mp.keys()], ...result];
    };

    const toCsv = (arr: any) => {
        return arr
            .map((row: any) =>
                row
                    .map((val: any) => {
                        return isNaN(val) ? JSON.stringify(val) : +val;
                    })
                    .join(',')
            )
            .join('\n');
    };

    type csvType = Record<
        | 'CLAUSE GROUP'
        | 'CLAUSE HEADING'
        | 'CLAUSE SUB-LIST HEADING'
        | 'SHORT FORM'
        | 'LONG FORM'
        | 'VALUE'
        | 'CLAUSE ID',
        string | null | undefined
    >;

    const csvArr: csvType[] = [];

    const sectionToCsvColumn = (unformatted: {
        section: string;
        group: string;
        clauseGroup?: string | null;
        value?: string | null | undefined;
        valueSuffix?: string | null | undefined;
        shortForm?: string | null | undefined;
        longForm?: string | null | undefined;
        clauseRef?: string | null | undefined;
    }) => {
        const { section, group, value, shortForm, longForm, valueSuffix, clauseRef } = unformatted;
        const valuePrefix =
            (valueSuffix && valueSuffix === '£') || valueSuffix === '$' || valueSuffix === '€' ? valueSuffix : null;
        return {
            'CLAUSE GROUP': section,
            'CLAUSE HEADING': group,
            'CLAUSE SUB-LIST HEADING': unformatted.clauseGroup ?? '-',
            'SHORT FORM': shortForm ?? '-',
            'LONG FORM': longForm?.replace('{$v}', value || ' ').replace(/<br>/gm, ''),
            VALUE: value
                ? `${valuePrefix ? valuePrefix : ''}${value}${valueSuffix && !valuePrefix ? ' ' + valueSuffix : ' '}`
                : '-',
            'CLAUSE ID': clauseRef
        };
    };

    if (deal.unit?.organisation) {
        const base = {
            'CLAUSE GROUP': 'The Parties',
            'CLAUSE HEADING': 'Landlord',
            'CLAUSE SUB-LIST HEADING': null,
            'SHORT FORM': null,
            'LONG FORM': '-',
            VALUE: null
        } as csvType;
        const organisation = deal.unit?.organisation;
        const address = organisation.address;
        csvArr.push({
            ...base,
            ...{
                'SHORT FORM': 'Landlord Name',
                'CLAUSE SUB-LIST HEADING': 'Name',
                VALUE: organisation.name || 'N/A'
            }
        });
        csvArr.push({
            ...base,
            ...{
                'SHORT FORM': 'Landlord Address Line 1',
                'CLAUSE SUB-LIST HEADING': 'Address Line 1',
                VALUE: address?.line1 || 'N/A'
            }
        });
        csvArr.push({
            ...base,
            ...{
                'SHORT FORM': 'Landlord Address Line 2',
                'CLAUSE SUB-LIST HEADING': 'Address Line 2',
                VALUE: address?.line2 || 'N/A'
            }
        });
        csvArr.push({
            ...base,
            ...{
                'SHORT FORM': 'Landlord City',
                'CLAUSE SUB-LIST HEADING': 'City',
                VALUE: address?.city || 'N/A'
            }
        });
        csvArr.push({
            ...base,
            ...{
                'SHORT FORM': 'Landlord Postal Code',
                'CLAUSE SUB-LIST HEADING': 'Postal Code',
                VALUE: address?.postCode || 'N/A'
            }
        });
        csvArr.push({
            ...base,
            ...{
                'SHORT FORM': 'Landlord Website',
                'CLAUSE SUB-LIST HEADING': 'Website',
                VALUE: organisation.website || 'N/A'
            }
        });
    }

    if (deal.details?.one?.building?.address) {
        const base = {
            'CLAUSE GROUP': 'The Parties',
            'CLAUSE HEADING': 'Tenant',
            'CLAUSE SUB-LIST HEADING': null,
            'SHORT FORM': null,
            'LONG FORM': '-',
            VALUE: null
        } as csvType;
        const building = deal.details?.one?.building?.address;

        const tenant = deal.tenant;
        csvArr.push({
            ...base,
            ...{
                'SHORT FORM': 'Tenant Name',
                'CLAUSE SUB-LIST HEADING': 'Name',
                VALUE: tenant?.name || 'N/A'
            }
        });

        csvArr.push({
            ...base,
            ...{
                'SHORT FORM': 'Tenant Address Line 1',
                'CLAUSE SUB-LIST HEADING': 'Address Line 1',
                VALUE: building.line1 || 'N/A'
            }
        });
        csvArr.push({
            ...base,
            ...{
                'SHORT FORM': 'Tenant Address Line 2',
                'CLAUSE SUB-LIST HEADING': 'Address Line 2',
                VALUE: building.line2 || 'N/A'
            }
        });
        csvArr.push({
            ...base,
            ...{
                'SHORT FORM': 'Tenant City',
                'CLAUSE SUB-LIST HEADING': 'City',
                VALUE: building.city || 'N/A'
            }
        });
        csvArr.push({
            ...base,
            ...{
                'SHORT FORM': 'Tenant Postal Code',
                'CLAUSE SUB-LIST HEADING': 'Postal Code',
                VALUE: building.postCode || 'N/A'
            }
        });
    }

    deal?.sections?.map((section) => {
        section?.groups?.map((group) => {
            group?.clauses?.map((clause) => {
                if (clause.longForm || clause.shortForm) {
                    csvArr.push(
                        sectionToCsvColumn({
                            section: section.title,
                            group: group.title,
                            ...clause
                        })
                    );
                } else {
                    clause?.groups?.map((clauseGroup) => {
                        clauseGroup?.clauses?.map((clauseGroupClause) => {
                            csvArr.push(
                                sectionToCsvColumn({
                                    section: section.title,
                                    group: group.title,
                                    clauseGroup: clauseGroup.title,
                                    ...clauseGroupClause
                                })
                            );
                        });
                    });
                }
            });
        });
    });

    // if (
    //     data.deal.tenant?.members.results &&
    //     data.deal.tenant?.members.results.length > 0
    // ) {
    //     const base = {
    //         "CLAUSE GROUP": "Contacts",
    //         "CLAUSE HEADING": "Tenant",
    //         "CLAUSE SUB-LIST HEADING": null,
    //         "SHORT FORM": "-",
    //         "LONG FORM": "-",
    //         VALUE: null
    //     } as csvType
    //     data.deal.tenant?.members.results.map((member) => {
    //         csvArr.push({
    //             ...base,
    //             ...{
    //                 "CLAUSE SUB-LIST HEADING": "First Name",
    //                 VALUE: member.lastName
    //             }
    //         })
    //         csvArr.push({
    //             ...base,
    //             ...{
    //                 "CLAUSE SUB-LIST HEADING": "Last Name",
    //                 VALUE: member.lastName
    //             }
    //         })
    //         csvArr.push({
    //             ...base,
    //             ...{
    //                 "CLAUSE SUB-LIST HEADING": "Email",
    //                 VALUE: member.email
    //             }
    //         })
    //         csvArr.push({
    //             ...base,
    //             ...{
    //                 "CLAUSE SUB-LIST HEADING": "User Type",
    //                 VALUE: member.type
    //             }
    //         })
    //     })
    // }

    // if (
    //     data.deal.actors.results &&
    //     data.deal.actors.results.length > 0
    // ) {
    //     const base = {
    //         "CLAUSE GROUP": "Contacts",
    //         "CLAUSE HEADING": "Landlord",
    //         "CLAUSE SUB-LIST HEADING": null,
    //         "SHORT FORM": "-",
    //         "LONG FORM": "-",
    //         VALUE: null
    //     } as csvType

    //     data.deal.actors.results.map((member) => {
    //         csvArr.push({
    //             ...base,
    //             ...{
    //                 "CLAUSE SUB-LIST HEADING": "First Name",
    //                 VALUE: member.lastName
    //             }
    //         })
    //         csvArr.push({
    //             ...base,
    //             ...{
    //                 "CLAUSE SUB-LIST HEADING": "Last Name",
    //                 VALUE: member.lastName
    //             }
    //         })
    //         csvArr.push({
    //             ...base,
    //             ...{
    //                 "CLAUSE SUB-LIST HEADING": "Email",
    //                 VALUE: member.email
    //             }
    //         })
    //         return csvArr.push({
    //             ...base,
    //             ...{
    //                 "CLAUSE SUB-LIST HEADING": "User Type",
    //                 VALUE: member.type
    //             }
    //         })
    //     })
    // }

    if (deal.details?.two?.lawyer) {
        const base = {
            'CLAUSE GROUP': 'Contacts',
            'CLAUSE HEADING': "Tenant's Legal Contact",
            'CLAUSE SUB-LIST HEADING': null,
            'SHORT FORM': null,
            'LONG FORM': '-',
            VALUE: null
        } as csvType;
        const lawyer = deal.details?.two?.lawyer;
        csvArr.push({
            ...base,
            ...{
                'SHORT FORM': "Tenant's Legal Contact Name",
                'CLAUSE SUB-LIST HEADING': 'Name',
                VALUE: lawyer.name || 'N/A'
            }
        });
        csvArr.push({
            ...base,
            ...{
                'SHORT FORM': "Tenant's Legal Contact Phone",
                'CLAUSE SUB-LIST HEADING': 'Phone',
                VALUE: lawyer.phone || 'N/A'
            }
        });
        csvArr.push({
            ...base,
            ...{
                'SHORT FORM': "Tenant's Legal Contact Email",
                'CLAUSE SUB-LIST HEADING': 'Email',
                VALUE: lawyer.email || 'N/A'
            }
        });
    }

    if (deal.unit?.tasks) {
        const contactInfo = getLandlordContactInfo((deal.unit?.tasks as Task[]) || undefined);
        if (contactInfo.landlord) {
            const landlord = contactInfo.landlord;
            const base = {
                'CLAUSE GROUP': 'Contacts',
                'CLAUSE HEADING': "Landlord's Deal Contact",
                'CLAUSE SUB-LIST HEADING': null,
                'SHORT FORM': null,
                'LONG FORM': '-',
                VALUE: null
            } as csvType;
            csvArr.push({
                ...base,
                ...{
                    'SHORT FORM': "Landlord's Deal Contact Name",
                    'CLAUSE SUB-LIST HEADING': 'Name',
                    VALUE: landlord.name || 'N/A'
                }
            });
            csvArr.push({
                ...base,
                ...{
                    'SHORT FORM': "Landlord's Deal Contact Phone",
                    'CLAUSE SUB-LIST HEADING': 'Phone',
                    VALUE: landlord.phone || 'N/A'
                }
            });
            csvArr.push({
                ...base,
                ...{
                    'SHORT FORM': "Landlord's Deal Contact Email",
                    'CLAUSE SUB-LIST HEADING': 'Email',
                    VALUE: landlord.email || 'N/A'
                }
            });
        }
        if (contactInfo.lawyer) {
            const lawyer = contactInfo.lawyer;
            const base = {
                'CLAUSE GROUP': 'Contacts',
                'CLAUSE HEADING': "Landlord's Legal Contact",
                'CLAUSE SUB-LIST HEADING': null,
                'SHORT FORM': null,
                'LONG FORM': '-',
                VALUE: null
            } as csvType;
            csvArr.push({
                ...base,
                ...{
                    'SHORT FORM': "Landlord's Legal Contact Name",
                    'CLAUSE SUB-LIST HEADING': 'Name',
                    VALUE: lawyer.name || 'N/A'
                }
            });
            csvArr.push({
                ...base,
                ...{
                    'SHORT FORM': "Landlord's Legal Contact Phone",
                    'CLAUSE SUB-LIST HEADING': 'Phone',
                    VALUE: lawyer.phone || 'N/A'
                }
            });
            csvArr.push({
                ...base,
                ...{
                    'SHORT FORM': "Landlord's Legal Contact Email",
                    'CLAUSE SUB-LIST HEADING': 'Email',
                    VALUE: lawyer.email || 'N/A'
                }
            });
        }
    }

    if (deal.unit?.address) {
        const base = {
            'CLAUSE GROUP': 'Address',
            'CLAUSE HEADING': 'Unit',
            'CLAUSE SUB-LIST HEADING': null,
            'SHORT FORM': null,
            'LONG FORM': '-',
            VALUE: null
        } as csvType;

        const address = deal.unit.address;

        csvArr.push({
            ...base,
            ...{
                'SHORT FORM': 'Unit Reference',
                'CLAUSE SUB-LIST HEADING': 'Reference',
                VALUE: deal.unit.name
            }
        });
        csvArr.push({
            ...base,
            ...{
                'SHORT FORM': 'Unit Building Name',
                'CLAUSE SUB-LIST HEADING': 'Building Name',
                VALUE: deal.unit.buildingName
            }
        });
        csvArr.push({
            ...base,
            ...{
                'SHORT FORM': 'Unit Address Line 1',
                'CLAUSE SUB-LIST HEADING': 'Line 1',
                VALUE: address.line1
            }
        });
        csvArr.push({
            ...base,
            ...{
                'SHORT FORM': 'Unit Address Line 2',
                'CLAUSE SUB-LIST HEADING': 'Line 2',
                VALUE: address.line2
            }
        });
        csvArr.push({
            ...base,
            ...{
                'SHORT FORM': 'Unit Address City',
                'CLAUSE SUB-LIST HEADING': 'City',
                VALUE: address.city
            }
        });
        csvArr.push({
            ...base,
            ...{
                'SHORT FORM': 'Unit Website',
                'CLAUSE SUB-LIST HEADING': 'Postal Code',
                VALUE: address.postCode
            }
        });
    }

    let csv = toCsv(pivot(csvArr));

    // Stringify data and create Blob object from it
    const blob = new Blob([csv], {
        type: 'text/csv; charset=utf-8,%EF%BB%BF'
    });

    return blob;
};

export default useDownloadData;
