import React, { useEffect, useMemo, useRef, useState } from 'react';

import { useUpdateClauseMutation } from '../../../../generated/graphql';
import { Button } from '../../../../shared/button';
import { handleFetchError } from '../../../../shared/helpers';
import Input, { InputType } from '../../../../shared/Inputs';
import Loading from '../../../../shared/Loading';
import useLongFormBox from '../../../../shared/Negotiator/NewClauseWindow/LongFormBox';
import Window from '../../../../shared/Window';

type EditClauseWindowProps = {
    dealID: string;
    parentID: string;
    clauseID: string;
    section?: string;
    group?: string;
    value?: string;
    suffix?: string;
    shortForm?: string;
    longForm?: string;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
};

const EditClauseWindow = ({
    dealID,
    parentID,
    clauseID,
    section,
    group,
    value,
    suffix,
    shortForm,
    longForm,
    setShow
}: EditClauseWindowProps) => {
    // Queries
    const [editClause, { error: editClauseError, loading: editClauseLoading }] = useUpdateClauseMutation();

    // Hooks
    const { insertValue, updateClauseValue, formatClauseValue, LongFormBox } = useLongFormBox({
        label: 'Long Form',
        defaultValue: longForm,
        value,
        suffix
    });

    // States
    const [internalValue, setInternalValue] = useState(value);
    const [internalSuffix, setInternalSuffix] = useState(suffix);

    // Effects
    useEffect(() => {
        // formatClauseValue()
    }, []);

    // Handlers
    const handleValueChange = (e: any) => {
        setInternalValue(e.target.value);
        if (!e.target.value) {
            updateClauseValue('*', internalSuffix);
        } else {
            updateClauseValue(e.target.value, internalSuffix);
        }
    };

    const handleSuffixChange = (e: any) => {
        setInternalSuffix(e.target.value);
        if (!value) {
            updateClauseValue('*', e.target.value);
        } else {
            updateClauseValue(internalValue, e.target.value);
        }
    };

    const shortFormRef = useRef<HTMLInputElement>(null);

    const longFormValue = useMemo(() => formatClauseValue(), [formatClauseValue]);

    const handleSubmit = async () => {
        const { data } = await editClause({
            variables: {
                dealID,
                parentID,
                clauseID,
                value: internalValue,
                valueSuffix: internalSuffix,
                shortForm: shortFormRef.current?.value,
                longForm: longFormValue
            },
            optimisticResponse: {
                dealClauseUpdate: {
                    __typename: 'Clause',
                    _id: clauseID,
                    value: internalValue,
                    valueSuffix: internalSuffix,
                    shortForm: shortFormRef.current?.value,
                    longForm: longFormValue
                } as any
            }
        });

        if (editClauseError || data?.dealClauseUpdate.__typename !== 'Clause')
            return handleFetchError('Failed to update the clause', editClauseError, data?.dealClauseUpdate);

        setShow(false);
    };

    if (editClauseLoading)
        return (
            <Window title="Edit Clause" width="w-2/5" setShow={setShow}>
                <Loading />
            </Window>
        );

    return (
        <Window
            title="Edit Clause"
            width="w-2/5"
            setShow={setShow}
            primaryBtn
            primaryBtnText="Change Clause"
            onPrimaryBtnClick={handleSubmit}
        >
            <div>
                <form className="flex flex-col" onSubmit={(e) => e.preventDefault()}>
                    <div className="flex flex-row items-center content-start w-full p-2">
                        <Input
                            type={InputType.text}
                            placeholder="Clause Group"
                            label="Clause Group"
                            value={section}
                            disabled
                            className="w-auto bg-navy-lightest"
                        />
                    </div>
                    <div className="flex flex-row items-center content-start w-full p-2">
                        <Input
                            type={InputType.text}
                            placeholder="Clause Heading"
                            label="Clause Heading"
                            value={group}
                            disabled
                            className="w-auto bg-navy-lightest"
                        />
                    </div>
                    <div className="flex flex-row items-center content-start w-full p-2">
                        <Input
                            type={InputType.text}
                            placeholder="Clause Short Form"
                            label="Short Form"
                            defaultValue={shortForm}
                            ref={shortFormRef}
                            className="w-auto bg-navy-lightest"
                        />
                    </div>
                    <div className="float-right flex flex-col gap-4 items-end md:flex-row mr-0 p-2 w-full">
                        <div className="flex flex-col md:flex-row items-center gap-1 w-full">
                            <Input
                                type={InputType.text}
                                value={internalValue}
                                onChange={handleValueChange}
                                placeholder="Value"
                                className="bg-navy-lightest text-center w-full md:width-auto"
                                label="Default Value"
                            />
                            <Input
                                type={InputType.text}
                                value={internalSuffix}
                                onChange={handleSuffixChange}
                                placeholder="Unit"
                                className="bg-navy-lightest text-center w-full md:width-auto"
                                label="Unit of Measurements"
                            />
                        </div>
                        <Button
                            background={`${!suffix && !value ? 'newGray-main' : 'newTeal-main'}`}
                            text="white"
                            onClick={insertValue}
                            className="p-4 font-semibold w-full md:width-auto"
                            disabled={!suffix && !value}
                        >
                            Insert
                        </Button>
                    </div>
                    <div className="p-2">{LongFormBox}</div>
                </form>
            </div>
        </Window>
    );
};

export default EditClauseWindow;
