import React, { useState } from 'react';

import { ReactComponent as ChevronDown } from '../../../assets/images/chevron/down/black.svg';
import { ReactComponent as ChevronUp } from '../../../assets/images/chevron/up/black.svg';
import { LinkedClauseFragment } from '../../../generated/graphql';
import { AddButton } from '../../add-button';
import { formatClause } from '../../helpers';
import Input, { InputType } from '../../Inputs';

import { AddClauseItem } from '.';

interface ClauseProps {
    parentId: string;
    id: string;
    isLandlordClause: boolean;
    value?: string | null;
    admin?: boolean | null;
    valueSuffix?: string;
    shortForm?: string;
    longForm?: string;
    tags: string[];
    section?: string;
    level1Group?: string;
    sectionDescription?: string | undefined;
    sectionRics?: string | undefined;
    groupDescription?: string | undefined;
    groupRics?: string | undefined;
    level2Group?: string;
    linkedClauses?: LinkedClauseFragment[];
    setEditObj?: React.Dispatch<React.SetStateAction<object>>;
    setDeleteObj?: React.Dispatch<React.SetStateAction<object>>;
    showEdit?: React.Dispatch<React.SetStateAction<boolean>>;
    onClick: (
        parentId: string,
        clause: AddClauseItem,
        section?: string,
        sectionDescription?: string | undefined,
        sectionRics?: string | undefined,
        groupDescription?: string | undefined,
        groupRics?: string | undefined,
        level1Group?: string,
        level2Group?: string,
        linkedClauses?: {
            value: string;
            valueSuffix: string;
            shortForm: string;
            longForm: string;
            tags: string[];
        }[]
    ) => void;
}

const Clause = ({
    parentId,
    id,
    isLandlordClause,
    setEditObj,
    setDeleteObj,
    value,
    valueSuffix,
    shortForm,
    sectionDescription,
    sectionRics,
    groupDescription,
    groupRics,
    longForm,
    tags,
    section,
    level1Group,
    level2Group,
    linkedClauses,
    admin,
    onClick,
    showEdit
}: ClauseProps) => {
    const [open, setOpen] = useState<boolean>(false);
    const [inputValue, setInputValue] = useState<number | string | null | undefined>(value);

    const formattedLongClause = {
        __html: formatClause(longForm, inputValue, valueSuffix, tags) ?? ''
    };

    const notNullValue = !(value !== '' && value === null);

    return (
        <div
            id={id}
            className={
                isLandlordClause
                    ? 'w-full bg-navy-lightest border-b-2 border-white rounded-tr rounded-tl p-4 py-2 relative'
                    : 'w-full border-b border-navy-light bg-white relative'
            }
        >
            {!isLandlordClause && (
                <p style={{ fontSize: '0.6875rem' }} className="text-gray-600 absolute top-0 left-0 ml-4 mt-2">
                    {section && section}
                    {level1Group && ` > ${level1Group}`}
                    {level2Group && ` > ${level2Group}`}
                </p>
            )}
            <div
                className={`w-full flex flex-row items-center justify-between px-4 ${
                    !isLandlordClause && 'pt-6 pb-3'
                } cursor-pointer`}
                onClick={() => (open ? setOpen(false) : setOpen(true))}
            >
                {shortForm}
                <button className="focus:outline-none">
                    {open ? (
                        <ChevronUp className={`w-8 h-6 cursor-pointer ${!isLandlordClause && '-mr-2 -mt-2'}`} />
                    ) : (
                        <ChevronDown className={`w-8 h-6 cursor-pointer ${!isLandlordClause && '-mr-2 -mt-2'}`} />
                    )}
                </button>
            </div>
            {open && (
                <div className="w-full bg-navy-lightest">
                    <p className="px-6 py-3 text-sm" dangerouslySetInnerHTML={formattedLongClause}></p>
                    <div className="w-full flex md:flex-row flex-col items-center justify-between px-6 py-2">
                        <div className="flex flex-row justify-center items-center">
                            {valueSuffix && (valueSuffix === '£' || valueSuffix === '$') && (
                                <span className="mr-2">{valueSuffix}</span>
                            )}
                            {notNullValue && (
                                <Input
                                    type={InputType.text}
                                    placeholder="Value"
                                    value={inputValue}
                                    onChange={(e) => {
                                        setInputValue(e.target.value);
                                    }}
                                    className="w-32 text-center mr-4"
                                />
                            )}
                            {valueSuffix && valueSuffix !== '£' && valueSuffix !== '$' && (
                                <span className="mr-2">{valueSuffix}</span>
                            )}
                        </div>

                        <AddButton
                            onClick={() => {
                                onClick(
                                    parentId,
                                    {
                                        value: inputValue?.toString() ?? '',
                                        valueSuffix: valueSuffix ?? '',
                                        shortForm: shortForm ?? '',
                                        longForm: longForm ?? '',
                                        clauseRef: id,
                                        tags
                                    },
                                    section,
                                    sectionDescription,
                                    sectionRics,
                                    groupDescription,
                                    groupRics,
                                    level1Group,
                                    level2Group,
                                    linkedClauses?.map((clause) => ({
                                        _id: clause._id ?? '',
                                        value: clause.value ?? '',
                                        valueSuffix: clause.valueSuffix ?? '',
                                        shortForm: clause.shortForm ?? '',
                                        longForm: clause.longForm ?? '',
                                        tags: clause.tags ?? []
                                    }))
                                );
                            }}
                            primary
                            className="bg-newTeal-main px-4 text-sm font-semibold xs:mt-2 md:mt-0"
                        >
                            Add Clause
                        </AddButton>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Clause;
