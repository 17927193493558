import React, { ChangeEvent, useEffect, useState } from 'react';

import useGetUnitSelectData from './hooks/useGetUnitSelectData';
import {
    UnitCondition,
    UnitUse,
    useGetUserOrganisationQuery,
    useGetUserTypeQuery,
    UserType
} from '../../generated/graphql';
import UnitAvatarUpload from '../file/UnitAvatarUpload';
import Input, { InputType } from '../Inputs';
import Select from '../Inputs/Select';

type UseNewUnitProps = {
    unitNameRef: React.RefObject<HTMLInputElement>;
    urlRef: React.RefObject<HTMLInputElement>;
    addressLine1Ref: React.RefObject<HTMLInputElement>;
    addressLine2Ref: React.RefObject<HTMLInputElement>;
    addressCityRef: React.RefObject<HTMLInputElement>;
    addressPostCodeRef: React.RefObject<HTMLInputElement>;
    areaRef: React.RefObject<HTMLInputElement>;
    emails: string[] | null | undefined;
    setEmails: React.Dispatch<React.SetStateAction<string[]>>;
    userType: UserType | false;
    setUse: React.Dispatch<React.SetStateAction<UnitUse | null>>;
    setCondition: React.Dispatch<React.SetStateAction<UnitCondition | null>>;
    className?: string;
    setNewAvatar: any;
    setAssociatedCompany: React.Dispatch<React.SetStateAction<string>>;
    associatedCompany: string;
};

type NewUnitProps = UseNewUnitProps & {
    addressLine1Value: string;
    setAddressLine1Value: React.Dispatch<React.SetStateAction<string>>;
    addressLine2Value: string;
    setAddressLine2Value: React.Dispatch<React.SetStateAction<string>>;
    addressCityValue: string;
    setAddressCityValue: React.Dispatch<React.SetStateAction<string>>;
    addressPostCodeValue: string;
    setAddressPostCodeValue: React.Dispatch<React.SetStateAction<string>>;
    setNewAvatar: any;
    setAssociatedCompany: React.Dispatch<React.SetStateAction<string>>;
    associatedCompany: string;
};

const useNewUnitComponent = (props: UseNewUnitProps) => {
    // Update values with user input
    const [addressLine1Value, setAddressLine1Value] = useState<string>('');
    const [addressLine2Value, setAddressLine2Value] = useState<string>('');
    const [addressCityValue, setAddressCityValue] = useState<string>('');
    const [addressPostCodeValue, setAddressPostCodeValue] = useState<string>('');

    return {
        setAddressLine1Value,
        setAddressLine2Value,
        setAddressCityValue,
        setAddressPostCodeValue,
        NewUnit: (
            <NewUnit
                {...props}
                addressLine1Value={addressLine1Value}
                setAddressLine1Value={setAddressLine1Value}
                addressLine2Value={addressLine2Value}
                setAddressLine2Value={setAddressLine2Value}
                addressCityValue={addressCityValue}
                setAddressCityValue={setAddressCityValue}
                addressPostCodeValue={addressPostCodeValue}
                setAddressPostCodeValue={setAddressPostCodeValue}
                setNewAvatar={props.setNewAvatar}
            />
        )
    };
};

const NewUnit = ({
    urlRef,
    unitNameRef,
    addressLine1Ref,
    addressLine2Ref,
    addressCityRef,
    addressPostCodeRef,
    areaRef,
    emails,
    setEmails,
    userType,
    addressLine1Value,
    setAddressLine1Value,
    addressLine2Value,
    setAddressLine2Value,
    addressCityValue,
    setAddressCityValue,
    addressPostCodeValue,
    setAddressPostCodeValue,
    setUse,
    setCondition,
    setNewAvatar,
    className,
    setAssociatedCompany
}: NewUnitProps) => {
    const [useData, conditionData] = useGetUnitSelectData();
    const { data, refetch } = useGetUserTypeQuery();
    const { data: userOrganisationData } = useGetUserOrganisationQuery();

    useEffect(() => {
        userType !== 'AGENT' && data?.user.organisation && setAssociatedCompany(data?.user.organisation._id);
    }, [userType, data]);

    const handleAddressChange = (event: ChangeEvent<HTMLInputElement>) => {
        switch (event.target.name) {
            case addressLine1Ref.current?.name:
                setAddressLine1Value(event.target.value);
                break;
            case addressLine2Ref.current?.name:
                setAddressLine2Value(event.target.value);
                break;
            case addressCityRef.current?.name:
                setAddressCityValue(event.target.value);
                break;
            case addressPostCodeRef.current?.name:
                setAddressPostCodeValue(event.target.value);
                break;
        }
    };

    return (
        <div className={className}>
            <form id="newUnit-form">
                <div className="flex flex-row flex-wrap md:flex-no-wrap w-full gap-4 mb-4">
                    <Input
                        type={InputType.text}
                        required
                        showRequiredAsterisk
                        ref={unitNameRef}
                        placeholder="Demise Reference"
                        label="Demise Reference"
                        className="w-full"
                    />
                    <Input
                        type={InputType.number}
                        required
                        showRequiredAsterisk
                        ref={areaRef}
                        placeholder="Demise Area (sq ft)"
                        label="Demise Area"
                        className="w-full"
                    />
                </div>
                <div className="flex flex-row flex-wrap md:flex-no-wrap w-full gap-4 mb-4">
                    <Input
                        type={InputType.text}
                        required
                        showRequiredAsterisk
                        name="address-line-1"
                        ref={addressLine1Ref}
                        value={addressLine1Value}
                        onChange={handleAddressChange}
                        placeholder="Address Line 1"
                        label="Address Line 1"
                        className="w-full"
                    />
                    <Input
                        type={InputType.text}
                        name="address-line-2"
                        ref={addressLine2Ref}
                        value={addressLine2Value}
                        onChange={handleAddressChange}
                        placeholder="Address Line 2"
                        label="Address Line 2"
                        className="w-full"
                    />
                </div>
                <div className="flex flex-row flex-wrap md:flex-no-wrap w-full gap-4 mb-4">
                    <Input
                        type={InputType.text}
                        required
                        showRequiredAsterisk
                        name="address-city"
                        ref={addressCityRef}
                        value={addressCityValue}
                        onChange={handleAddressChange}
                        placeholder="City"
                        label="City"
                        className="w-full"
                    />
                    <Input
                        type={InputType.text}
                        required
                        showRequiredAsterisk
                        name="address-post-code"
                        ref={addressPostCodeRef}
                        value={addressPostCodeValue}
                        onChange={handleAddressChange}
                        placeholder="Post Code"
                        label="Post Code"
                        className="w-full"
                    />
                </div>
                <div className="flex flex-row flex-wrap md:flex-no-wrap w-full gap-4 mb-4">
                    <Select
                        data={useData}
                        required
                        onItemClick={(use) => {
                            if (!use) return;
                            setUse(use as UnitUse);
                        }}
                        placeholder="Select Use"
                        label="Select Use"
                        className="w-full"
                    />
                    <Select
                        data={conditionData}
                        required
                        onItemClick={(condition) => {
                            if (!condition) return;
                            setCondition(condition as UnitCondition);
                        }}
                        placeholder="Condition"
                        label="Condition"
                        className="w-full"
                    />
                </div>
                <div className="flex flex-row flex-wrap md:flex-no-wrap w-full gap-4 mb-4 items-center">
                    <Input
                        type={InputType.url}
                        name="url"
                        ref={urlRef}
                        placeholder="Website URL"
                        label="Website URL (optional)"
                        className="w-full"
                    />
                </div>

                <div className="flex flex-row flex-wrap md:flex-no-wrap w-full gap-4 mb-4"></div>
                <UnitAvatarUpload setNewAvatar={setNewAvatar} />
                <div className="flex flex-row flex-wrap w-full mb-4">
                    {userType === 'AGENT' ? (
                        <>
                            <div className="w-full flex items-center " onClick={() => refetch()}>
                                <Select
                                    maxMenuHeight={120}
                                    data={
                                        data?.user.trustedOrganisations
                                            .filter((organisation) => organisation.active)
                                            .map((trustedOrganisation) => ({
                                                id: trustedOrganisation._id,
                                                text: trustedOrganisation.name
                                            })) ?? []
                                    }
                                    required
                                    onItemClick={(trustedOrganisation) => {
                                        trustedOrganisation && setAssociatedCompany(trustedOrganisation);
                                    }}
                                    placeholder="Associated Company"
                                    label="Associated Company"
                                    className="w-full"
                                />
                            </div>
                        </>
                    ) : (
                        <>
                            {userOrganisationData && (
                                <>
                                    <div className="w-full flex flex-row items-center justify-between px-1 mb-1">
                                        <span className="font-semibold text-sm">
                                            Advisor Access (Agent, Managing Agents ando/or Lawyers)
                                        </span>
                                    </div>
                                    <div className="flex flex-col gap-2  w-full">
                                        <Select
                                            maxMenuHeight={120}
                                            data={Object.values(
                                                userOrganisationData?.user.organisation.trustedUsers
                                            ).map((trustedUser) => {
                                                return {
                                                    text: trustedUser.activated
                                                        ? trustedUser.firstName +
                                                          ' ' +
                                                          trustedUser.lastName +
                                                          ' (' +
                                                          trustedUser.organisation.name +
                                                          ')'
                                                        : trustedUser.email,
                                                    id: trustedUser.email
                                                };
                                            })}
                                            label="Email"
                                            placeholder={
                                                emails && emails.length > 0
                                                    ? 'Type to add another email'
                                                    : 'Enter email'
                                            }
                                            isMulti
                                            blackStyle
                                            createable
                                            color="black"
                                            chipBackground="white"
                                            className="md:mr-2 bg-navy-lightest w-full mb-4"
                                            onChange={(value: any) => {
                                                if (!value || value.length === 0) {
                                                    setEmails([]);
                                                    return;
                                                }

                                                const users = value.map((item: any) => {
                                                    return item.value;
                                                });

                                                setEmails(users);
                                            }}
                                        />
                                    </div>
                                </>
                            )}
                        </>
                    )}
                </div>
            </form>
        </div>
    );
};

export default useNewUnitComponent;
