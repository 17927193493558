import React from 'react';

import { ReactComponent as Location } from '../../assets/images/location/locationGray.svg';
import { ReactComponent as Web } from '../../assets/images/web/webGray.svg';
import { UserType } from '../../generated/graphql';
import DefaultProfile from '../../layout/SideBar/components/DefaultProfile';

type ClientCardProps = {
    id: string;
    type?: UserType | null;
    organisation: {
        name: string;
        avatar?: string | null;
        website?: string | null;
        address: {
            line1?: string | null;
            city?: string | null;
            postCode?: string | null;
            line2?: string | null;
        };
    };
};

const ClientCard = ({ id, type, organisation }: ClientCardProps) => {
    return (
        <>
            <div key={id} className="flex justify-between h-24 w-full bg-white rounded px-3 py-4 mb-2">
                <div className="flex gap-2 items-center justify-start">
                    {organisation.avatar ? (
                        <img
                            src={organisation.avatar}
                            alt={organisation.name}
                            className="w-10 h-10 rounded object-cover"
                        />
                    ) : (
                        <DefaultProfile name={organisation.name} size="2.5rem" fontSize="1rem" />
                    )}
                    <div className="flex flex-row items-center ml-4">
                        <div className="flex flex-col h-16 w-48  justify-center border-r-2 mr-4">
                            <p className="font-medium flex items-center justify-start">{organisation.name}</p>
                            <p className="text-sm">
                                {type && `${type.slice(0, 1)}${type.slice(1).toLowerCase()}`} Company
                            </p>
                        </div>
                        <div className="flex flex-col">
                            {organisation.website && (
                                <p className="text-sm">
                                    {' '}
                                    <Web className="w-3 h-3 mr-2 text-sm inline " />
                                    {organisation.website}
                                </p>
                            )}
                            {organisation.address && (organisation.address.line1 || organisation.address.city) && (
                                <p className="text-sm">
                                    {' '}
                                    <Location className="w-3 h-3 mr-2 text-sm inline " />
                                    {organisation.address.line1 && `${organisation.address.line1}, `}
                                    {organisation.address.line2 && `${organisation.address.line2}, `}
                                    {organisation.address.city && `${organisation.address.city}, `}
                                    {organisation.address.postCode && `${organisation.address.postCode}`}
                                </p>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ClientCard;
