import React, { useContext } from 'react';

import { FilterContext } from './context';
import { ReactComponent as CloseIcon } from '../../assets/images/cross/white.svg';

type FilterChipProps = {
    value: string;
    label: string;
    filterId: string;
    filterLabel: string;
};

const FilterChip = ({ value, label, filterId, filterLabel }: FilterChipProps) => {
    const { removeListValue } = useContext(FilterContext);

    return (
        <div
            className={'bg-newGray-main flex items-center pr-2 cursor-pointer text-white rounded-l ml-1 h-7 rounded-r'}
            style={{
                minWidth: '7rem'
            }}
        >
            <div
                className="flex items-center justify-center cursor-pointer"
                style={{
                    width: '2rem'
                }}
                onClick={() => removeListValue(filterId, value)}
            >
                <CloseIcon width={13} height={13} />
            </div>
            <p className="text-xs">
                {filterLabel} ({label})
            </p>
        </div>
    );
};

export default FilterChip;
