import React from 'react';

import { ReactComponent as Settings } from '../../../assets/images/edit/black.svg';

type ClauseSettingsProps = {
    setShowEditWindow: React.Dispatch<React.SetStateAction<boolean>>;
};

const ClauseSettings = ({ setShowEditWindow }: ClauseSettingsProps) => {
    return (
        <div className="flex items-center">
            <button
                className="cursor-pointer focus:outline-none bg-transparent   z-10 py-4 px-3"
                onClick={() => {
                    setShowEditWindow(true);
                }}
            >
                <Settings className="w-8 h-6 " />
            </button>
        </div>
    );
};

export default ClauseSettings;
