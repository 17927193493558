import React from 'react';
import { SubmitHandler, useForm, UseFormReturn } from 'react-hook-form';

import { useShowSaveButton } from './hooks/useShowSaveButton';

type FormProps<TFormValues> = {
    className?: string;
    onSubmit: SubmitHandler<TFormValues>;
    setShowButton?: React.Dispatch<React.SetStateAction<boolean>>;
    children: (methods: UseFormReturn<TFormValues>) => React.ReactNode;
};

const Form = <TFormValues extends Record<string, any> = Record<string, any>>({
    className,
    onSubmit,
    setShowButton,
    children
}: FormProps<TFormValues>) => {
    const methods = useForm<TFormValues>();
    useShowSaveButton(methods.formState, setShowButton);

    return (
        <form onSubmit={methods.handleSubmit(onSubmit)} className={className}>
            {children(methods)}
        </form>
    );
};
export default Form;
