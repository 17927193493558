import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import WindowFooter from './layout/WindowFooter';
import WindowHeader from './layout/WindowHeader';
import StageCompleted from './StageCompleted';
import StageContext from './StageContext';
import { ReactComponent as Cross } from '../../../../../assets/images/cross/black.svg';
import { ReactComponent as External } from '../../../../../assets/images/external/black.svg';
import {
    DocType,
    GetDealTenantDetailsDocument,
    useTenantActionStageFourMutation
} from '../../../../../generated/graphql';
import { Button } from '../../../../../shared/button';
import { FileUploadModal } from '../../../../../shared/file';
import { handleFetchError } from '../../../../../shared/helpers';
import Loading from '../../../../../shared/Loading';
import Window from '../../../../../shared/Window';
import ConfirmDeleteFileWindow from '../../ConfirmDeleteFileWindow';

const Stage4Window = () => {
    const { dealID, details, backTo, unitID, setShowStage4Window: setShow } = React.useContext(StageContext);

    const [noLawyer, setNoLawyer] = useState<boolean>(details?.four?.noLawyer ?? false);

    const [kycCompleted, setKYCCompleted] = useState<boolean>(details?.four?.kyc ?? false);

    // File
    const [isFileUploadOpen, setFileUploadOpen] = useState<boolean>(false);
    const [showFileDeleteWindow, setShowFileDeleteWindow] = useState<boolean>(false);
    const [fileID, setFileID] = useState<string | null>(null);

    const history = useHistory();

    // Set phase 4

    const [actionStage, { error: actionStageError, loading: actionStageLoading }] = useTenantActionStageFourMutation();

    const saveToDB = async (completed?: boolean) => {
        const { data } = await actionStage({
            variables: {
                dealID: dealID!,
                completed: completed ?? false,
                kyc: kycCompleted ?? false,
                noLawyer
            },
            refetchQueries: [
                {
                    query: GetDealTenantDetailsDocument,
                    variables: {
                        dealID: dealID!
                    }
                }
            ]
        });

        if (actionStageError) {
            return handleFetchError('Could not set your details', actionStageError, data?.dealTenantDetailsSet);
        }
    };

    const handleCompleteStage = async () => {
        saveToDB(noLawyer ? true : kycCompleted);
        history.push('#readiness-stage5');
    };

    return (
        <>
            {isFileUploadOpen && dealID && (
                <FileUploadModal id={dealID} taskID="4" multiple type={DocType.Deal} setShow={setFileUploadOpen} />
            )}
            {showFileDeleteWindow && fileID && (
                <ConfirmDeleteFileWindow
                    type={DocType.Deal}
                    fileId={fileID}
                    dealId={dealID}
                    unitId={unitID}
                    setWindow={setShowFileDeleteWindow}
                />
            )}
            <Window
                width="w-4/5"
                scrollContent={true}
                className="md:max-h-11/12"
                contentBackground="navy-light"
                header={
                    <WindowHeader
                        setShow={setShow!}
                        backTo={backTo!}
                        stage="4"
                        title="Complete Your KYC (Know Your Customer)"
                        description="KYC is now part of everyday business. We know it can
                            be a pain, but here at Kato we want to make the
                            process as simple as possible."
                    />
                }
                footer={
                    <WindowFooter
                        backTo="#readiness-stage3"
                        nextTo="#readiness-stage5"
                        onNextClick={async () => await handleCompleteStage()}
                    />
                }
                setShow={setShow!}
                onCloseClick={() => {
                    setShow!(false);
                    history.push(backTo!);
                }}
            >
                <div className="flex flex-col min-h-full">
                    <div className="w-full bg-navy-light flex-1">
                        {actionStageLoading && <Loading className="mt-20" />}
                        <div
                            className={`container lg:w-4/5 md:w-5/5 mx-auto py-16 ${actionStageLoading && 'opacity-0'}`}
                        >
                            <div className="lg:max-w-md md:w-3/5 sm:w-5/5 mx-auto -mt-4 text-center flex flex-col items-start">
                                {/* Check 1 */}
                                <label className="block my-5">
                                    <input
                                        type="checkbox"
                                        id="optional"
                                        checked={noLawyer}
                                        onChange={() => setNoLawyer(!noLawyer)}
                                        className="leading-tight w-8 h-8 form-checkbox p-2 rounded bg-white text-newTeal-main focus:shadow-none focus:outline-none"
                                    />
                                    <span className="text-sm font-medium text-gray-600 ml-3">
                                        I'm not using a Lawyer
                                    </span>
                                </label>

                                {/* Check 2 */}
                                {!noLawyer && (
                                    <label className="block my-5">
                                        <input
                                            type="checkbox"
                                            id="optional"
                                            checked={kycCompleted}
                                            onChange={() => setKYCCompleted((kycCompleted) => !kycCompleted)}
                                            className="leading-tight w-8 h-8 form-checkbox p-2 rounded bg-white text-newTeal-main focus:shadow-none focus:outline-none"
                                        />
                                        <span className="text-sm font-medium text-gray-600 ml-3">
                                            <span className="text-newTeal-main mr-1">*</span>
                                            Have you completed your KYC with your lawyer?
                                        </span>
                                    </label>
                                )}
                            </div>
                            {!noLawyer && (
                                <>
                                    {/* File upload button */}
                                    <div className="w-full mt-10">
                                        <p className="font-medium text-center text-gray-600 text-sm">
                                            Optional - Upload confirmation from your Lawyer
                                        </p>
                                        <div className="flex flex-col gap-2 w-1/2 mx-auto mt-2 max-h-60 overflow-y-auto">
                                            {details?.four?.files?.map((file) => (
                                                <div
                                                    key={file._id}
                                                    className="bg-white rounded h-12 p-2 flex items-center justify-between w-full"
                                                >
                                                    <a
                                                        href={file.url}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        className="flex-1 truncate"
                                                    >
                                                        <External className="w-4 h-5 mr-4 inline" />
                                                        <span className="text-sm text-newGray-darkish pr-2 mr-2 border-r border-gray-600">
                                                            {new Date(file.created).toLocaleDateString()}
                                                        </span>
                                                        {file.name}
                                                    </a>
                                                    <Cross
                                                        onClick={() => {
                                                            setFileID(file._id);
                                                            setShowFileDeleteWindow(true);
                                                        }}
                                                        className="w-5 h-5 cursor-pointer z-60"
                                                    />
                                                </div>
                                            ))}
                                        </div>
                                        {/* File upload button */}
                                        <div className="w-full text-center">
                                            <Button
                                                text="black"
                                                background="navy-light"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    setFileUploadOpen(true);
                                                }}
                                                className="w-1/2 font-semibold bg-gray-450 mt-2"
                                            >
                                                Upload
                                            </Button>
                                        </div>
                                    </div>
                                    {/* Bottom text */}
                                    <p className="text-gray-600 font-medium text-sm mt-8 mb-4 text-center">
                                        <span className="text-newTeal-main mr-1">*</span>
                                        Required to complete the stage
                                    </p>
                                </>
                            )}
                            {details?.four?.completed && <StageCompleted className="mt-6" />}
                        </div>
                    </div>
                </div>
            </Window>
        </>
    );
};

export default Stage4Window;
