import {
    AdminTaskType,
    GetUnitDetailsDocument,
    GetUnitRatingDocument,
    useActionTaskMutation
} from '../../../../../generated/graphql';
import { handleFetchError } from '../../../../../shared/helpers';

const useActionTask = (
    id: string,
    unitID: string,
    setEditing?: React.Dispatch<React.SetStateAction<boolean>>,
    setSubmit?: React.Dispatch<React.SetStateAction<boolean>>,
    type?: AdminTaskType | null
) => {
    // Action task mutation
    const [actionTask, { loading, error }] = useActionTaskMutation();

    return {
        handleSubmit: async (value: boolean | number | string | null, forceFloat: boolean = false) => {
            let boolean: boolean | null | undefined = undefined;
            let string: string | null | undefined = undefined;
            let number: number | null | undefined = undefined;

            let optimisticResponse: any = {
                unitTaskAction: {
                    __typename: 'UnitTask',
                    _id: id
                }
            };

            if (((!type && forceFloat) || type?.toString() === 'INTEGER') && typeof value === 'string') {
                let integerVal = parseFloat(value);
                if (integerVal != null) value = integerVal;
            }

            switch (typeof value) {
                case 'string':
                    string = value !== '' ? value : null;
                    optimisticResponse.value = {
                        __typename: 'StringValue',
                        stringValue: string
                    };
                    break;
                case 'number':
                    number = value;
                    optimisticResponse.value = {
                        __typename: 'FloatValue',
                        floatValue: number
                    };
                    break;
                case 'boolean':
                    boolean = value;
                    optimisticResponse.value = {
                        __typename: 'BooleanValue',
                        booleanValue: boolean
                    };
                    break;
                default:
                    return;
            }

            const { data } = await actionTask({
                variables: {
                    taskID: id,
                    unitID,
                    booleanValue: boolean,
                    stringValue: string,
                    floatValue: number
                },
                optimisticResponse,
                refetchQueries: [
                    {
                        query: GetUnitRatingDocument,
                        variables: {
                            unitID
                        }
                    },
                    {
                        query: GetUnitDetailsDocument,
                        variables: {
                            unitID
                        }
                    }
                ]
            });

            if (error) return handleFetchError('Failed to action the task', error, data?.unitTaskAction);
            else {
                if (setEditing) setEditing(false); //force some states when editing
                if (setSubmit) setSubmit(false);
            }
        },
        loading
    };
};

export default useActionTask;
