import React, { useEffect } from 'react';

import { GetUnitDetailsQuery, useGetUserTypeQuery } from '../../../generated/graphql';
import { handleFetchError } from '../../../shared/helpers';
import DealCard from '../../Deals/DealCard';
import NegotiationsCard from '../../Deals/NegotiationsCard';

type UnitDealsProps = {
    data: GetUnitDetailsQuery | undefined;
    negotiationDeals?: boolean;
    setShowTenantDetails: React.Dispatch<React.SetStateAction<boolean>>;
    setSelectedDealId: React.Dispatch<React.SetStateAction<string>>;
};

const UnitDeals = ({ data, negotiationDeals, setShowTenantDetails, setSelectedDealId }: UnitDealsProps) => {
    // User Type Check
    const { data: typeData, error: typeError } = useGetUserTypeQuery();
    useEffect(() => {
        // Check for errors
        handleFetchError('Could not load user', typeError, typeData?.user);
    }, [typeData, typeError]);

    const isTenant = typeData?.user.type === 'TENANT';
    return (
        <div>
            {data?.unit.__typename === 'Unit' &&
                data.unit.deals?.map((deal) => {
                    // Don't show if invitation is still pending
                    if (deal.pending && typeData?.user.__typename === 'User' && typeData.user.type !== 'AGENT')
                        return null;

                    return (
                        <>
                            {!negotiationDeals ? (
                                <DealCard
                                    id={deal._id}
                                    key={deal._id}
                                    isPinned={deal.isPinned}
                                    hasMaxPinnedDeals={typeData?.user.hasMaxPinnedDeals}
                                    name={
                                        !isTenant && deal.tenant?.name
                                            ? deal.tenant?.name
                                            : deal.unit?.organisation.name ?? ''
                                    }
                                    unitName={deal.unit?.name ?? 'Untitled Demise'}
                                    unitID={data.unit.__typename === 'Unit' ? data.unit._id : ''}
                                    unitRating={data.unit.__typename === 'Unit' ? data.unit.rating ?? 0 : 0}
                                    pending={deal.pending}
                                    state={deal.state}
                                    currentUserParty={deal.currentUserParty}
                                    round={deal.round}
                                    clauseSummary={deal.clauseSummary}
                                    compact={true}
                                    className="mb-2"
                                    hasHiddenUnit={false}
                                />
                            ) : (
                                <NegotiationsCard
                                    id={deal._id}
                                    key={deal._id}
                                    name={
                                        !isTenant && deal.tenant?.name
                                            ? deal.tenant?.name
                                            : deal.unit?.organisation.name ?? ''
                                    }
                                    unitID={data.unit.__typename === 'Unit' ? data.unit._id : ''}
                                    unitRating={data.unit.__typename === 'Unit' ? data.unit.rating ?? 0 : 0}
                                    pending={deal.pending}
                                    state={deal.state}
                                    currentUserParty={deal.currentUserParty}
                                    round={deal.round}
                                    clauseSummary={deal.clauseSummary}
                                    className="mb-2"
                                    setShowTenantDetails={setShowTenantDetails}
                                    setSelectedDealId={setSelectedDealId}
                                />
                            )}
                        </>
                    );
                })}
        </div>
    );
};

export default UnitDeals;
