import React, { useState } from 'react';

import ClauseContext from './Context';
import { ReactComponent as Cross } from '../../../../../assets/images/cross/black.svg';
import { ReactComponent as Remove } from '../../../../../assets/images/delete/black.svg';
import { ReactComponent as Edit } from '../../../../../assets/images/hash/black.svg';
import { ReactComponent as More } from '../../../../../assets/images/more/horizontal/black.svg';
import ClauseAction from '../../../../../shared/Negotiator/ClauseAction';

type ApprovedActionProps = {
    side: undefined | 'left' | 'right';
};

const ApprovedAction = ({ side }: ApprovedActionProps) => {
    const [menuOpen, setMenuOpen] = useState(false);

    const { value, valueSuffix, setEditing, setDeleting } = React.useContext(ClauseContext);

    return (
        <div className={`${side === 'left' && 'm-2'} flex justify-center items-center`}>
            {menuOpen && (
                <div
                    className={` flex flex-row max-w-full rounded overflow-hidden opacity-100 absolute ml-40 ${
                        side === 'left' ? 'left-0 ml-16' : 'right-0 mr-20'
                    }`}
                >
                    {!valueSuffix ||
                        (!value && (
                            <ClauseAction
                                icon={<Edit className="w-4 h-4 mb-1" />}
                                text={'Edit Value'}
                                onClick={() => setEditing!(true)}
                            />
                        ))}
                    <ClauseAction
                        icon={<Remove className="w-4 h-4 mb-1" />}
                        text={'Remove Clause'}
                        onClick={() => setDeleting!(true)}
                    />
                    <button
                        onClick={() => setMenuOpen(!menuOpen)}
                        className={`hover:bg-gray-200 ${
                            menuOpen && 'bg-navy-light'
                        } cursor-pointer mr-4 rounded-r p-2 focus:outline-none`}
                    >
                        <Cross className="w-6 h-6" />
                    </button>
                </div>
            )}
            <button
                onClick={() => setMenuOpen(!menuOpen)}
                className={`hover:bg-navy-lightest cursor-pointer mr-4 rounded-r p-2 focus:outline-none`}
            >
                <More className="w-6 h-6" />
            </button>
        </div>
    );
};

export default ApprovedAction;
