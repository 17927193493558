import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';

import Landlord from './Landlord';
import LandlordTeamWindow from './LandlordTeamWindow';
import Tenant from './Tenant/index';
import { ReactComponent as Info } from '../../../assets/images/info/black_old.svg';
import {
    GetUnitDetailsDocument,
    Organisation,
    UnitAvailability,
    useGetUnitDetailsQuery,
    User,
    useUpdateUnitMutation
} from '../../../generated/graphql';
import { getUserFullName } from '../../../shared/helpers';
import useMobileScreen from '../../../shared/hooks/useMobileScreen';
import Loading from '../../../shared/Loading';

type UnitProps = {
    tenant?: boolean;
    setHeaderContent: React.Dispatch<React.SetStateAction<React.ReactChild | null>>;
};

const tabItems = ['Readiness', 'More Information'];

const Unit = ({ tenant = false, setHeaderContent }: UnitProps) => {
    const [selectedTab, setSelectedTab] = useState({
        index: 0,
        tab: tabItems[0]
    });
    const [showTeamList, setShowTeamList] = useState<boolean>(false);

    const { id } = useParams();
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const dealId = params.get('deal');

    const { data: detailsData, loading } = useGetUnitDetailsQuery({
        variables: {
            unitID: id
        }
    });

    useEffect(() => {
        if (dealId && dealId.length > 0) setSelectedTab({ index: 1, tab: tabItems[1] });
    }, [dealId]);

    const [updateUnit] = useUpdateUnitMutation();

    const handleChangeAvailability = async (availability: UnitAvailability) => {
        await updateUnit({
            variables: {
                unitID: id,
                available: availability
            },
            refetchQueries: [GetUnitDetailsDocument]
        });
    };
    const landlordTeam = detailsData?.unit.organisation.members || [];
    const isAvailable = detailsData?.unit.available === UnitAvailability.AvailableToLet;

    const isMobile = useMobileScreen();

    if (loading) return <Loading />;

    return (
        <>
            {showTeamList && (
                <LandlordTeamWindow
                    landlordTeam={landlordTeam as User[]}
                    setShowTeamList={setShowTeamList}
                    organisation={detailsData?.unit.organisation as Organisation}
                />
            )}
            <div
                className={`bg-white ${
                    !isMobile && 'h-36'
                } sticky md:px-8 z-40 w-full flex flex-col top-0 right-0 left-0 justify-between actor-list-shadow`}
            >
                {!isMobile && (
                    <div className="flex h-2/3 w-full justify-start items-center">
                        <div className="flex items-center h-full w-full mt-8">
                            <div
                                data-for="space-info"
                                data-tip={
                                    detailsData?.unit &&
                                    `Created by ${getUserFullName(detailsData?.unit.creator)} -
                                    Created on
                                    ${format(new Date(detailsData.unit.created), 'dd MMM yyyy, h:mm a')}`
                                }
                                className={`flex flex-row justify-start items-center sm:w-3/6 lg:w-2/12 mb-2`}
                            >
                                <div>
                                    <Info className="h-4 w-4 mr-4" />
                                </div>
                                <div className="flex flex-col w-11/12 sm:mr-0 md:mr-1 lg:mr-2 border-r border-newGray-main">
                                    <p className={`font-semibold text-09 truncate`}>{detailsData?.unit?.name}</p>
                                    <p className={`font-semibold text-09 truncate`}>
                                        {detailsData?.unit?.buildingName}
                                    </p>
                                </div>
                            </div>
                            <div
                                className={`px-2 h-14 mb-2 w-56 border actor-list-shadow ml-4 justify-center flex flex-col rounded-lg cursor-pointer bg-navy-light `}
                                onClick={() => {
                                    setShowTeamList(true);
                                }}
                            >
                                <div className="flex flex-row justify-between text-navy-main items-center w-full pl-4">
                                    <p className="font-semibold text-sm">Landlord</p>
                                    <p className="text-xxs text-gray-600">View Team</p>
                                </div>
                                <div className="text-sm pl-4">{detailsData?.unit.organisation.name}</div>
                            </div>
                        </div>
                        <div
                            onClick={() =>
                                handleChangeAvailability(
                                    isAvailable ? UnitAvailability.UnavailableToLet : UnitAvailability.AvailableToLet
                                )
                            }
                            className="bg-navy-lightest w-52 h-20 flex items-center flex-col mt-4 text-09 z-10 relative cursor-pointer rounded-lg"
                            style={{ fontWeight: '500' }}
                        >
                            <div
                                className={`w-full h-10 z-20 absolute transition-all duration-300 transform rounded-lg actor-list-shadow ${
                                    isAvailable ? 'bg-newTeal-main' : 'mt-10 bg-gray-600'
                                }`}
                            />
                            <p
                                className={`py-auto z-30 h-1/2 flex items-center relative ${
                                    isAvailable ? 'text-white' : 'text-newGray-main'
                                }`}
                            >
                                Available to let
                            </p>
                            <p
                                className={`py-auto z-30 h-1/2 flex items-center relative ${
                                    isAvailable ? 'text-newGray-main' : 'text-white'
                                }`}
                            >
                                Archive
                            </p>
                        </div>
                    </div>
                )}
                <div className={`flex w-full justify-between md:justify-start items-center ${isMobile ? 'mt-4' : ''}`}>
                    {tabItems.map((item, i) => (
                        <div
                            key={i}
                            onClick={() => setSelectedTab({ index: i, tab: item })}
                            className={`tab-element md:flex-none font-semibold flex-1 text-center px-8 cursor-pointer text-09`}
                        >
                            <p className="pb-3">{item}</p>
                            <div
                                className={`${isMobile ? 'w-32' : 'w-36'} ${
                                    selectedTab.index === i ? 'border-b-4 border-newTeal-main font-bold ' : ''
                                }`}
                            />
                        </div>
                    ))}
                </div>
            </div>
            <div
                className={`${tenant && 'container'} ${isMobile ? 'py-4' : 'pb-4 overflow-y-auto'}  mx-auto px-4`}
                style={!isMobile ? { height: 'calc(100vh - 11.5rem)' } : { height: '100%' }}
            >
                <>
                    {tenant ? (
                        <Tenant id={id} selectedTab={selectedTab} setHeaderContent={setHeaderContent} />
                    ) : (
                        <Landlord id={id} selectedTab={selectedTab} setHeaderContent={setHeaderContent} />
                    )}
                </>
            </div>
            <ReactTooltip
                id="space-info"
                backgroundColor="black"
                textColor="white"
                effect="solid"
                place="bottom"
                clickable={false}
                borderColor="white"
            />
        </>
    );
};

export default React.memo(Unit);
