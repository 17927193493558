import React, { useRef, useState } from 'react';

import { useRequestResetPasswordMutation } from '../../generated/graphql';
import { Button } from '../../shared/button';
import Input, { InputType } from '../../shared/Inputs';
import Loading from '../../shared/Loading';

type ResetPasswordFormProps = {
    setResetPasswordResult: React.Dispatch<React.SetStateAction<boolean>>;
};

const ResetPasswordForm = ({ setResetPasswordResult }: ResetPasswordFormProps) => {
    const [error, setError] = useState<string | null>(null);

    const emailRef = useRef<HTMLInputElement>(null);

    const [requestResetPassword, { loading }] = useRequestResetPasswordMutation();

    const handleSubmit = async () => {
        const email = emailRef.current?.value;

        if (!email) {
            setError('Please enter an email and password');
            return;
        } else {
            await requestResetPassword({
                variables: { email },
                optimisticResponse: {
                    userRequestResetPassword: true
                }
            });
            setResetPasswordResult(true);
        }
    };

    if (loading)
        return (
            <div className="bg-white px-8 pt-6 pb-8 mb-4 sm:h-auto max-w-sm md:max-w-sm w-full">
                <Loading />
            </div>
        );

    return (
        <form
            className="bg-white px-8 pt-6 pb-8 mb-4 sm:h-auto max-w-sm md:max-w-sm w-full"
            onSubmit={(e) => e.preventDefault()}
        >
            <div className="mb-4">
                <Input
                    type={InputType.email}
                    className="bg-navy-lightest mb-4 w-full"
                    placeholder="Your email"
                    label="Email"
                    ref={emailRef}
                />
            </div>

            <div className="flex items-center justify-center">
                <Button
                    background="newTeal-main"
                    text="white"
                    onClick={handleSubmit}
                    className="px-4 w-full font-semibold"
                >
                    Request Reset
                </Button>
            </div>
            {error && (
                <div className="bg-red-200 border border-red-600 rounded w-full py-2 px-4 mt-4">
                    <span className="text-center text-red-600">{error}</span>
                </div>
            )}
        </form>
    );
};

export default ResetPasswordForm;
