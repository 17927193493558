import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import {
    DealDetailsFragment,
    DealSendType,
    DealState,
    DealUserParty,
    GetDealDetailsDocument,
    useCompleteDealMutation,
    useGetDealTenantDetailsLazyQuery
} from '../../../../generated/graphql';
import Emoji from '../../../../shared/Emoji';
import Window from '../../../../shared/Window';
import { WindowProps } from '../../../../shared/Window/WindowContext';
import { DealContext } from '../context';

type DealRoundCompleteWindowProps = {
    deal: DealDetailsFragment;
    currentUserParty: DealUserParty | null | undefined;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
    setShareWindowType: React.Dispatch<React.SetStateAction<DealSendType>>;
    setShowSendWindow: React.Dispatch<React.SetStateAction<boolean>>;
    setForceReadinessClose: React.Dispatch<React.SetStateAction<boolean>>;
};

const DealAgreedWindow = ({
    currentUserParty,
    deal,
    setShow,
    setShareWindowType,
    setShowSendWindow,
    setForceReadinessClose
}: DealRoundCompleteWindowProps) => {
    const history = useHistory();

    const [tenantDetails] = useGetDealTenantDetailsLazyQuery();

    const { setSeenDealAgreed } = useContext(DealContext);

    useEffect(() => {
        if (!deal || deal.__typename !== 'Deal') return;
        setSeenDealAgreed && setSeenDealAgreed(true);
        if (currentUserParty === 'TENANT') {
            tenantDetails({
                variables: {
                    dealID: deal._id
                }
            });
        }
    }, [deal, currentUserParty, tenantDetails, setSeenDealAgreed]);

    const shareNow = () => {
        setShow(false);
        setShareWindowType(currentUserParty === DealUserParty.Landlord ? DealSendType.Tenant : DealSendType.Landlord);
        setShowSendWindow(true);
        setForceReadinessClose(true);
    };

    const [completeDeal] = useCompleteDealMutation();

    const primaryBtnText = currentUserParty === DealUserParty.Landlord ? 'Agree Terms' : 'Share Now';
    const primaryBtnClick =
        currentUserParty === DealUserParty.Landlord
            ? async () => {
                  await completeDeal({
                      variables: { dealID: deal._id },
                      refetchQueries: [GetDealDetailsDocument],
                      onCompleted: () => {
                          setShow(false);
                          history.push(`/deals/${deal._id}/preview`);
                      }
                  });
              }
            : shareNow;

    const { setIsEdit } = useContext(DealContext);
    const secondaryButton: Partial<WindowProps> =
        currentUserParty === DealUserParty.Landlord
            ? {
                  secondaryBtn: true,
                  secondaryBtnText: 'Make Changes',
                  onSecondaryBtnClick: () => {
                      setIsEdit(true);
                      setShow(false);
                  }
              }
            : {};

    return (
        <Window
            title={currentUserParty === DealUserParty.Landlord ? 'Terms Agreed!' : 'Great News!'}
            width="w-2/5"
            setShow={setShow}
            titleCenter
            primaryBtn
            primaryBtnText={primaryBtnText}
            onPrimaryBtnClick={primaryBtnClick}
            {...secondaryButton}
            disableClose
        >
            <div className="pb-4 pt-8 md:pt-4">
                <p style={{ fontSize: '4rem' }} className="text-center">
                    <Emoji
                        symbol={
                            currentUserParty === DealUserParty.Landlord && deal?.state === DealState.Agreed
                                ? '👍'
                                : '🤝'
                        }
                    />
                </p>
                <p className="text-newGray-darkish text-center text-medium mx-auto mt-3">
                    {currentUserParty === DealUserParty.Landlord
                        ? deal?.state === DealState.Agreed
                            ? 'Great news! All clauses have now been agreed.'
                            : 'Your heads of terms look like they are now agreed!'
                        : 'Our heads of terms look like they are now agreed!'}
                </p>
                <p className="text-newGray-darkish text-center text-medium mx-auto mt-3 max-w-sm">
                    {currentUserParty === DealUserParty.Landlord && deal?.state === DealState.Agreed ? (
                        <>
                            To lock in the deal, select <b>Agree Terms</b>. <br />
                            Or, you may <b>Make Changes</b> to the terms then resend them to the Tenant to negotiate.
                        </>
                    ) : (
                        `Click "share now" to let the ${
                            currentUserParty === DealUserParty.Landlord ? 'tenant' : 'landlord'
                        } know (and they will come back to you soon with the next steps), or click "X" to go back and edit/add clauses.`
                    )}
                </p>
            </div>
        </Window>
    );
};

export default DealAgreedWindow;
