import { debounce, isEmpty } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import ReactTooltip from 'react-tooltip';

import NewTemplateWindow from './NewTemplateWindow';
import NoTemplates from './NoTemplates';
import TemplateCard from './TemplateCard';
import { ReactComponent as Alert } from '../../assets/images/alert-circle/alertCircle.svg';
import { ReactComponent as FiltersIcon } from '../../assets/images/filters/sliders.svg';
import { ReactComponent as BinGray } from '../../assets/images/recycle-bin/gray.svg';
import { ReactComponent as BinWhite } from '../../assets/images/recycle-bin/white.svg';
import { ReactComponent as Search } from '../../assets/images/search/currentColor.svg';
import {
    Template,
    TemplateSortFields,
    TemplateStatusFilter,
    TemplateTypeFilter,
    UnitUse,
    useGetTemplateCardDetailsQuery,
    useGetTemplateCreatorsQuery,
    useGetTemplateOrganisationsQuery,
    useGetUserTypeQuery,
    User,
    UserType,
    useTemplateFilterCountsQuery
} from '../../generated/graphql';
import { AddButton } from '../../shared/add-button';
import { Button } from '../../shared/button';
import { FilterContext, FilterValues } from '../../shared/Filter/context';
import { FilterConfig, FilterType, FilterWindow } from '../../shared/Filter/FilterWindow';
import { transformFilters } from '../../shared/Filter/helpers';
import SortChip from '../../shared/Filter/SortChip';
import Grid from '../../shared/Grid';
import { handleFetchError } from '../../shared/helpers';
import useMobileScreen from '../../shared/hooks/useMobileScreen';
import Input, { InputType } from '../../shared/Inputs';
import { RadioValue } from '../../shared/Inputs/Radio';
import Loading from '../../shared/Loading';

type TemplatesProps = {
    setHeaderContent: React.Dispatch<React.SetStateAction<React.ReactChild | null>>;
};

const getUserName = (user?: User, currentUser?: User) => {
    if (!user) return 'Deleted User';

    const userName = `${user.firstName} ${user.lastName}`;

    return String(user._id) === String(currentUser?._id) ? `YOU (${userName})` : userName;
};

const Templates = ({ setHeaderContent }: TemplatesProps) => {
    const { values, pendingValues, sort, filters, openWindow, isWindowOpen, chips } = useContext(FilterContext);
    const [templates, setTemplates] = useState<Template[]>([]);
    const [showNewTemplate, setShowNewTemplate] = useState<boolean>(false);
    const [countFilterValue, setCountFilterValue] = useState<TemplateStatusFilter[] | undefined>([
        TemplateStatusFilter.Live
    ]);
    const isMobile = useMobileScreen();

    // QUERIES
    const { loading, error, data, refetch } = useGetTemplateCardDetailsQuery({
        fetchPolicy: 'cache-and-network',
        variables: {
            filter: {
                ...transformFilters(filters, values),
                ...(countFilterValue ? { status: countFilterValue } : {})
            },
            sort: sort
                ? {
                      field: sort.field as TemplateSortFields,
                      order: sort.order
                  }
                : undefined
        }
    });
    const { data: typeData } = useGetUserTypeQuery();

    const { data: countsData, refetch: countsRefetch } = useTemplateFilterCountsQuery({
        fetchPolicy: 'cache-and-network',
        variables: {
            filter: {
                ...transformFilters(filters, values),
                status: countFilterValue
            }
        }
    });

    // Search users
    const handleChange = debounce((value?: string) => {
        refetch({
            query: value
        });
    }, 250);

    useEffect(() => {
        setHeaderContent(<h1 className="text-xl font-bold p-2">Templates</h1>);
    }, [setHeaderContent]);

    useEffect(() => {
        // Check for errors
        handleFetchError('Could not load your templates', error, data?.templates);
        if (isEmpty(data?.templates)) return;
        sortTemplates((data?.templates.results as Template[]) ?? []);
    }, [data, error]);

    const sortTemplates = (templates: Template[]) => {
        let sortedTemplates: Template[] = [];
        const hiddenTemplates = templates?.filter((template) => typeData?.user.hiddenTemplates?.includes(template._id));
        sortedTemplates.push(...hiddenTemplates);
        const unhidenunits = templates?.filter((template) => !typeData?.user.hiddenTemplates?.includes(template._id));
        sortedTemplates.push(...unhidenunits);
        setTemplates(sortedTemplates);
    };

    const isLiveFilter = countFilterValue?.includes(TemplateStatusFilter.Live);

    if (!loading && data?.templates.pageInfo.total && data?.templates?.pageInfo?.total === 0) {
        return <NoTemplates setShowNewTemplate={setShowNewTemplate} />;
    }

    return (
        <>
            {showNewTemplate && (
                <NewTemplateWindow refetch={refetch} countsRefetch={countsRefetch} setShow={setShowNewTemplate} />
            )}
            <div className="md:px-32 mx-auto p-4">
                <div className={` pb-4 flex justify-between`}>
                    <div className="flex items-start w-full">
                        <div className={`relative ${isMobile ? 'w-full' : 'w-64'} flex items-center  justify-center`}>
                            <Search
                                className="w-6 h-6 absolute right-6 bg-white text-newGray-main z-20"
                                style={{ top: '16px' }}
                            />
                            <div className="border-r border-newGray-main absolute h-8 z-20 right-15 " />
                            <Input
                                type={InputType.text}
                                placeholder="Search"
                                className="h-15 mr-2 w-full"
                                autoFocus
                                onChange={(e) => handleChange(e.target.value)}
                            />
                        </div>

                        <div className="border-r border-newGray-main h-12 z-20 mr-4 ml-2" />
                        <Button
                            background="white"
                            text="newGray-darkish"
                            height="15"
                            className="hover:bg-navy-lightest border-none w-15"
                            onClick={() => openWindow()}
                        >
                            <FiltersIcon />
                        </Button>
                        {!isMobile && (
                            <div className="flex flex-row w-9/12 flex-wrap justify-start gap-1 ml-3">
                                {values && chips()}
                                {sort && <SortChip sortOptions={getTemplateSortFields()} />}
                            </div>
                        )}
                    </div>
                    <div data-tip={'New Template'}>
                        <AddButton
                            onClick={() => setShowNewTemplate((showNewTemplate) => !showNewTemplate)}
                            height="15"
                            text="black hover:text-white"
                            className={`${
                                isMobile ? 'mb-2 ml-2' : ''
                            } px-4 bg-newTeal-light hover:bg-newTeal-main border-none w-15`}
                        />
                    </div>
                </div>
                {isMobile && (
                    <div className="flex flex-row w-full -mt-3 flex-wrap justify-start gap-1">
                        {values && chips()}
                        {sort && <SortChip sortOptions={getTemplateSortFields()} />}
                    </div>
                )}
                {!countFilterValue?.includes(TemplateStatusFilter.Live) && (
                    <div className="flex w-full bg-newTeal-light h-12 items-center rounded-md my-3 shadow ">
                        <div
                            className="w-4 bg-newTeal-main h-full"
                            style={{
                                borderRadius: '0.375rem 0 0 0.375rem '
                            }}
                        />
                        <Alert className="w-6 h-6 mr-5 ml-3" />
                        <div>
                            {countFilterValue?.includes(TemplateStatusFilter.Hidden)
                                ? 'Hidden Templates are unable to be selected when starting a new deal'
                                : 'Templates in the bin are unable to be selected when starting a new deal'}
                        </div>
                    </div>
                )}
                <div>
                    <div className="flex mt-2 mb-8 items-center justify-between">
                        <div>
                            <Button
                                background={`${isLiveFilter ? 'newGray-darkish' : 'white'} mr-2`}
                                text={`${isLiveFilter ? 'white' : 'black'}`}
                                className={`${!isLiveFilter && 'hover:shadow-lg'} w-10-3 font-semibold`}
                                onClick={() => setCountFilterValue([TemplateStatusFilter.Live])}
                            >
                                <span className={`${!isLiveFilter && 'text-newGray-darkish'}`}>
                                    Live ({countsData?.templateFilterCounts.live})
                                </span>
                            </Button>
                        </div>
                        <div className="flex">
                            <Button
                                background={`${isLiveFilter ? 'white' : 'newGray-darkish'}`}
                                text={`${isLiveFilter ? 'black' : 'white'}`}
                                className={`${isLiveFilter && 'hover:shadow-lg'} ml-2 w-24 font-semibold`}
                                onClick={() => setCountFilterValue([TemplateStatusFilter.Bin])}
                            >
                                {isLiveFilter ? (
                                    <BinGray
                                        style={{
                                            width: '30px'
                                        }}
                                    />
                                ) : (
                                    <BinWhite
                                        style={{
                                            width: '30px'
                                        }}
                                    />
                                )}
                                <span className={`${isLiveFilter && 'text-newGray-darkish'} ml-1`}>Bin</span>
                            </Button>
                        </div>
                    </div>
                    <Grid className="mt-4">
                        {
                            // Pull out templates
                            loading ? (
                                <Loading className="mt-20" />
                            ) : (
                                templates?.map((template) => (
                                    <TemplateCard
                                        key={template._id}
                                        id={template._id}
                                        name={template.name ?? 'Untitled Template'}
                                        isHidden={typeData?.user.hiddenTemplates?.includes(template._id)}
                                        associatedUses={template.associatedUses}
                                        userName={getUserName(template.creator as User, typeData?.user as User)}
                                        created={template.created}
                                        description={template.description ?? ''}
                                        global={template.global}
                                        hasAccess={template.hasAccess}
                                        refetch={refetch}
                                        organisation={template.organisation.name}
                                        countFilterValue={countFilterValue}
                                        countsRefetch={countsRefetch}
                                    />
                                ))
                            )
                        }
                    </Grid>
                    {isWindowOpen && typeData?.user && (
                        <FilterWindow
                            config={templateFilterConfig(
                                typeData.user.type,
                                pendingValues['type'].includes(TemplateTypeFilter.Global) &&
                                    !pendingValues['type'].includes(TemplateTypeFilter.Custom)
                            )}
                            sortOptions={getTemplateSortFields()}
                        />
                    )}
                </div>
            </div>
            <ReactTooltip
                backgroundColor="black"
                textColor="white"
                effect="solid"
                place="left"
                clickable={false}
                borderColor="white"
            />
        </>
    );
};

const TYPE_OPTIONS = [
    { label: 'Global', value: TemplateTypeFilter.Global },
    { label: 'Custom', value: TemplateTypeFilter.Custom }
];

const UNIT_USE_OPTIONS = [
    { label: 'Office', value: UnitUse.Office },
    { label: 'Medical', value: UnitUse.Medical },
    { label: 'Industrial', value: UnitUse.Industrial },
    { label: 'Retail High Street', value: UnitUse.RetailHighStreet },
    { label: 'Retail Shopping Centre', value: UnitUse.RetailShoppingCentre },
    { label: 'Retail Pop Up', value: UnitUse.RetailPopUp },
    { label: 'Office Flex', value: UnitUse.OfficeFlex },
    { label: 'Arch', value: UnitUse.Arch },
    { label: 'F&B', value: UnitUse.Fb },
    { label: 'Managed', value: UnitUse.Managed }
];

export const saveValuesOverride = (values: FilterValues, userType?: UserType) => {
    const isLandlord = userType === UserType.Landlord || userType === UserType.Admin;
    const isAgent = userType === UserType.Agent;

    const type = (values['type'] as string[]) ?? [];

    if (type.includes(TemplateTypeFilter.Custom) || !type.includes(TemplateTypeFilter.Global)) return values;

    if (isLandlord) return { ...values, creator: [] };

    if (isAgent) return { ...values, organisation: [] };

    return values;
};

export const templateFilterConfig = (userType?: UserType, global = false): FilterConfig => {
    const isLandlord = userType === UserType.Landlord || userType === UserType.Admin;
    const isAgent = userType === UserType.Agent;

    let filters = [
        {
            id: 'type',
            label: 'Type',
            type: FilterType.List,
            options: TYPE_OPTIONS
        },
        {
            id: 'uses',
            label: 'Designated Use',
            type: FilterType.List,
            options: UNIT_USE_OPTIONS
        },
        ...(isLandlord
            ? [
                  {
                      id: 'creator',
                      label: 'Creator',
                      type: FilterType.AsyncList,
                      query: useGetTemplateCreatorsQuery,
                      path: '[$distinct(templates.{ "value": creator._id, "label": creator.firstName & " " & creator.lastName })]',
                      disabled: global,
                      disabledText: 'Kato Team'
                  }
              ]
            : []),
        ...(isAgent
            ? [
                  {
                      id: 'organisation',
                      label: 'Company',
                      type: FilterType.AsyncList,
                      query: useGetTemplateOrganisationsQuery,
                      path: '[$distinct(templates.{ "value": organisation._id, "label": organisation.name })]',
                      disabled: global,
                      disabledText: 'Kato Team'
                  }
              ]
            : [])
    ];

    return filters;
};

export const getTemplateSortFields = (): RadioValue[] => [
    {
        id: TemplateSortFields.Name,
        label: 'Template Name'
    },
    {
        id: TemplateSortFields.Created,
        label: 'Date Created'
    }
];

export default Templates;
