import React, { Dispatch, SetStateAction } from 'react';

export type FilterContextType = {
    showLandlordFilesWindow: boolean;
    setShowLandlordFilesWindow: Dispatch<SetStateAction<boolean>>;
};

export const LandlordFilesContext = React.createContext<FilterContextType>({
    showLandlordFilesWindow: false,
    setShowLandlordFilesWindow: () => {}
});

type FilterProviderProps = {
    children: React.ReactNode;
    showLandlordFilesWindow: boolean;
    setShowLandlordFilesWindow: Dispatch<SetStateAction<boolean>>;
};

export const LandlordFilesProvider = ({
    children,
    showLandlordFilesWindow,
    setShowLandlordFilesWindow
}: FilterProviderProps) => {
    return (
        <LandlordFilesContext.Provider value={{ showLandlordFilesWindow, setShowLandlordFilesWindow }}>
            {children}
        </LandlordFilesContext.Provider>
    );
};
