import axios from 'axios';
import { format } from 'date-fns';
import { saveAs } from 'file-saver';
import fileDownload from 'js-file-download';
import React, { useEffect } from 'react';

import { ReactComponent as Check } from '../../../../assets/images/check/black.svg';
import { useGetDealTenantDetailsQuery } from '../../../../generated/graphql';
import { Button } from '../../../../shared/button';
import { handleFetchError } from '../../../../shared/helpers';
import Window from '../../../../shared/Window';

type TenantDetailsWindowProps = {
    dealID: string;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
};

type DonwloadFIlesProps = {
    url?: string;
    name?: string;
};

const TenantDetailsWindow = ({ dealID, setShow }: TenantDetailsWindowProps) => {
    const { data, error } = useGetDealTenantDetailsQuery({
        variables: {
            dealID
        }
    });

    // Check for errors
    useEffect(() => {
        handleFetchError("Failed to load the tenant's details", error, data?.deal);
    }, [data, error]);

    const handleDownloadFiles = async (files: DonwloadFIlesProps[]) => {
        if (files && files.length > 1) {
            const JSZip = require('jszip');
            let zip = new JSZip();

            await Promise.all(
                files.map(async (file) => {
                    const response = await axios.get(file.url!, {
                        responseType: 'blob'
                    });
                    zip.file(file.name!, response.data);
                })
            );

            zip.generateAsync({ type: 'blob' }).then((content: any) => {
                const timestamp = format(new Date(), 'dd MMM yyyy');
                saveAs(content, `Convenant Documents - ${timestamp}.zip`);
            });
        } else {
            const response = await axios.get(files[0].url!, {
                responseType: 'blob'
            });
            fileDownload(response.data, files[0].name!);
        }
    };

    if (data?.deal.__typename !== 'Deal') return null;

    const files = data?.deal?.details?.three?.files;

    return (
        <Window title="Tenant Readiness" scrollContent setShow={setShow}>
            <div className="bg-navy-light">
                {/* Stage 1 */}
                <h1
                    className={`px-4 py-2 bg-white w-full text-lg font-medium shadow-xs ${
                        !data.deal.details?.one?.completed && 'text-newGray-main'
                    }`}
                    style={{
                        minWidth: '45vw'
                    }}
                >
                    {data.deal.details?.one?.completed && <Check className="text-newTeal-main w-6 h-6 mr-2 inline" />}
                    Stage 1 - Tenant Details
                </h1>
                {data.deal.details?.one?.completed ? (
                    <div className="grid grid-cols-3 grid-flow-col p-4 gap-4">
                        <div className="bg-white rounded p-4">
                            <p className="text-newGray-darkish font-semibold mb-1">Tenant Entity Registered Address</p>
                            <p>{data.deal.details.one.entity?.address?.line1}</p>
                            <p>{data.deal.details.one.entity?.address?.line2}</p>
                            <p>{data.deal.details.one.entity?.address?.city}</p>
                            <p>{data.deal.details.one.entity?.address?.postCode}</p>
                        </div>
                        {data.deal.details.one.isSameAddress && (
                            <div className="bg-white rounded p-4">
                                <p className="text-newGray-darkish font-semibold mb-1">Current Business Address</p>
                                <p>{data.deal.details.one.building?.address?.line1}</p>
                                <p>{data.deal.details.one.building?.address?.line2}</p>
                                <p>{data.deal.details.one.building?.address?.city}</p>
                                <p>{data.deal.details.one.building?.address?.postCode}</p>
                            </div>
                        )}
                    </div>
                ) : (
                    <h2 className="text-newGray-darkish font-medium text-center py-6">Stage not yet complete</h2>
                )}

                {/* Stage 2 */}
                <h1
                    className={`px-4 py-2 bg-white w-full text-lg font-medium shadow-xs ${
                        !data.deal.details?.two?.completed && 'text-newGray-main'
                    }`}
                >
                    {data.deal.details?.two?.completed && <Check className="text-newTeal-main w-6 h-6 mr-2 inline" />}
                    Stage 2 - Lawyer Details
                </h1>
                {data.deal.details?.two?.completed ? (
                    <div className="grid grid-cols-3 p-4 gap-4">
                        {data.deal.details?.two?.lawyer?.visible ? (
                            <div className="bg-white rounded p-4">
                                <p className="text-newGray-darkish font-semibold mb-1">Lawyer</p>
                                <p>{data.deal.details.two?.lawyer?.name}</p>
                                <p>{data.deal.details.two?.lawyer?.company}</p>
                                <p>{data.deal.details.two?.lawyer?.email}</p>
                            </div>
                        ) : (
                            <label>
                                <input type="checkbox" className="form-checkbox mr-2" checked={true} disabled />
                                Not using a Lawyer
                            </label>
                        )}
                    </div>
                ) : (
                    <h2 className="text-newGray-darkish font-medium text-center py-6">Stage not yet complete</h2>
                )}

                {/* Stage 3 */}
                <h1
                    className={`px-4 py-2 bg-white w-full text-lg font-medium shadow-xs ${
                        !data.deal.details?.team?.completed && 'text-newGray-main'
                    }`}
                >
                    {data.deal.details?.team?.completed && <Check className="text-newTeal-main w-6 h-6 mr-2 inline" />}
                    Stage 3 - Team
                </h1>
                {data.deal.details?.team?.completed ? (
                    <div className="grid grid-cols-3 p-4 gap-4">
                        <div className="bg-white rounded p-4">
                            <p className="text-newGray-darkish font-semibold mb-1">Tenant</p>
                            <p>{data.deal.details.team?.tenant?.name}</p>
                            <p>{data.deal.details.team?.tenant?.company}</p>
                            <p>{data.deal.details.team?.tenant?.email}</p>
                        </div>
                        {data.deal.details?.team?.agent?.visible ? (
                            <div className="bg-white rounded p-4">
                                <p className="text-newGray-darkish font-semibold mb-1">Agent</p>
                                <p>{data.deal.details.team?.agent?.name}</p>
                                <p>{data.deal.details.team?.agent?.company}</p>
                                <p>{data.deal.details.team?.agent?.email}</p>
                            </div>
                        ) : (
                            <label>
                                <input type="checkbox" className="form-checkbox mr-2" checked={true} disabled />
                                Not using an Agent
                            </label>
                        )}
                    </div>
                ) : (
                    <h2 className="text-newGray-darkish font-medium text-center py-6">Stage not yet complete</h2>
                )}

                {/* Stage 4 */}
                <h1
                    className={`px-4 py-2 bg-white w-full text-lg font-medium shadow-xs ${
                        !data.deal.details?.three?.completed && 'text-newGray-main'
                    }`}
                >
                    {data.deal.details?.three?.completed && <Check className="text-newTeal-main w-6 h-6 mr-2 inline" />}
                    Stage 4 - Covenant
                </h1>
                {data.deal.details?.three?.completed ? (
                    <div className="grid grid-cols-3 p-4 gap-4">
                        {data.deal.details?.three?.nominateGuarantor && (
                            <>
                                <div className="bg-white rounded p-4">
                                    <p className="text-newGray-darkish font-semibold mb-1">Guarantor Details</p>
                                    <p>{data.deal.details.three?.guarantor?.name}</p>
                                    <p>{data.deal.details.three?.guarantor?.company}</p>
                                    <p>{data.deal.details.three?.guarantor?.jurisdiction}</p>
                                </div>
                                <div className="bg-white rounded p-4">
                                    <p className="text-newGray-darkish font-semibold mb-1">
                                        Guarantor Registered Address
                                    </p>
                                    <p>{data.deal.details.three?.guarantor?.address?.line1}</p>
                                    <p>{data.deal.details.three?.guarantor?.address?.line2}</p>
                                    <p>{data.deal.details.three?.guarantor?.address?.city}</p>
                                    <p>{data.deal.details.three?.guarantor?.address?.postCode}</p>
                                </div>
                            </>
                        )}
                        {data.deal.details?.three?.noAccountsToShare ? (
                            <label>
                                <input type="checkbox" className="form-checkbox mr-2" checked={true} disabled />
                                Do not have any accounts to share
                            </label>
                        ) : (
                            files &&
                            files.length > 0 && (
                                <Button
                                    onClick={() => handleDownloadFiles(files)}
                                    className="bg-newTeal-main font-semibold rounded p-2 h-12 flex items-center justify-center"
                                >
                                    Download Covenant Documents
                                </Button>
                            )
                        )}
                    </div>
                ) : (
                    <h2 className="text-newGray-darkish font-medium text-center py-6">Stage not yet complete</h2>
                )}

                {/* Stage 5 */}
                <h1
                    className={`px-4 py-2 bg-white w-full text-lg font-medium shadow-xs ${
                        !data.deal.details?.aml?.completed && 'text-newGray-main'
                    }`}
                >
                    {data.deal.details?.aml?.completed && <Check className="text-newTeal-main w-6 h-6 mr-2 inline" />}
                    Stage 5 - AML Checks
                </h1>
                {data.deal.details?.aml?.completed ? (
                    <div className="grid grid-cols-3 p-4 gap-4">
                        <div className="bg-white rounded p-4">
                            <p className="text-newGray-darkish font-semibold mb-1">Company Details</p>
                            <p>{data.deal.details.aml?.companyJurisdiction}</p>
                            <p>{data.deal.details.aml?.companyName}</p>
                            <p>{data.deal.details.aml?.companyRegistrationNumber}</p>
                        </div>
                        {data.deal.details?.aml?.personalDetails?.map((person) => {
                            return (
                                <div className="bg-white rounded p-4">
                                    <p className="text-newGray-darkish font-semibold mb-1">Person Details</p>
                                    <p>{person.name}</p>
                                    <p>{person.phone}</p>
                                </div>
                            );
                        })}
                    </div>
                ) : (
                    <h2 className="text-newGray-darkish font-medium text-center py-6">Stage not yet complete</h2>
                )}

                {/* Stage 6 */}
                <h1
                    className={`px-4 py-2 bg-white w-full text-lg font-medium shadow-xs ${
                        !data.deal.details?.final?.completed && 'text-newGray-main'
                    }`}
                >
                    {data.deal.details?.final?.completed && <Check className="text-newTeal-main w-6 h-6 mr-2 inline" />}
                    Stage 6 - Final Info
                </h1>
                {data.deal.details?.final?.completed ? (
                    <h2 className="text-newGray-darkish font-medium text-center py-6">
                        The tenant has confirmed they have read the information
                    </h2>
                ) : (
                    <h2 className="text-newGray-darkish font-medium text-center py-6">Stage not yet complete</h2>
                )}
            </div>
        </Window>
    );
};

export default TenantDetailsWindow;
