import React, { useContext, useEffect, useState } from 'react';

import AddUserAsAgentWindow from './AddUserAsAgent';
import AddUsersInput from './AddUsersInput';
import ConfirmUserRemoveWindow from './Landlord/ConfirmUserRemoveWindow';
import { UnitFileModal } from './UnitFilesModal';
import { ReactComponent as AddUser } from '../../../assets/images/addUser/addUser.svg';
import { ReactComponent as Archive } from '../../../assets/images/archive/black.svg';
import { ReactComponent as Remove } from '../../../assets/images/delete/black.svg';
import { ReactComponent as Edit } from '../../../assets/images/edit/box/black.svg';
import { ReactComponent as Envelope } from '../../../assets/images/envelope/black.svg';
import { ReactComponent as Phone } from '../../../assets/images/phone/black.svg';
import {
    Unit,
    UnitAddressFragment,
    UnitCondition,
    UnitSingleActorFragment,
    UnitUse,
    useGetUserCompanyQuery,
    useGetUserOrganisationQuery,
    useGetUserProfileQuery
} from '../../../generated/graphql';
import DefaultProfile from '../../../layout/SideBar/components/DefaultProfile';
import { handleFetchError } from '../../../shared/helpers';
import { UseConditionToText } from '../../../shared/NewUnit/hooks/useGetUnitSelectData';
import { isAgent } from '../../../v2/common/helpers';
import { LandlordFilesContext } from '../../../v2/components/LandlordsFileModal/LandlordFilesContext';

type UnitOverviewDesktopProps = {
    _id: string;
    tenant?: boolean;
    address: UnitAddressFragment;
    area: number | undefined;
    use?: UnitUse | false | null;
    condition: UnitCondition | undefined;
    actors?: UnitSingleActorFragment[];
    avatar?: string | null;
    url?: string | null;
    setShowAddUser: React.Dispatch<React.SetStateAction<boolean>>;
    setShowEditUnitWindow: React.Dispatch<React.SetStateAction<boolean>>;
    unitDetails?: Unit;
};

const UnitOverviewDesktop = ({
    _id,
    tenant = false,
    address,
    area,
    use,
    condition,
    actors,
    avatar,
    url,
    setShowAddUser,
    setShowEditUnitWindow,
    unitDetails
}: UnitOverviewDesktopProps) => {
    const [showConfirmRemoveUserWindow, setShowConfirmRemoveUserWindow] = useState<boolean>(false);
    const [userID, setUserID] = useState<string>('');

    const [showAddAdvisor, setShowAddAdvisor] = useState<boolean>(false);
    const [dataActors, setDataActors] = useState<UnitSingleActorFragment[]>(actors ?? []);
    const { data: typeData } = useGetUserProfileQuery();

    const { data: companyData, error: companyError } = useGetUserCompanyQuery({
        variables: {
            unitID: _id
        }
    });

    const { showLandlordFilesWindow, setShowLandlordFilesWindow } = useContext(LandlordFilesContext);

    useEffect(() => {
        handleFetchError('Could not load user company', companyError, companyData?.user);
    }, [companyData, companyError]);

    const { data: userOrganisationData } = useGetUserOrganisationQuery();

    const isUserAgent = isAgent(typeData?.user.type);

    const userOptions =
        userOrganisationData &&
        userOrganisationData?.user.organisation.trustedUsers.filter((trustedUser) => {
            const actorsEmails = dataActors?.filter((actor) => isAgent(actor.type)).map((actor) => actor.email);
            const agentsSet = new Set(actorsEmails);
            return !agentsSet.has(trustedUser.email);
        });

    return (
        <>
            {showLandlordFilesWindow && (
                <UnitFileModal
                    setShow={setShowLandlordFilesWindow}
                    organisationName={unitDetails?.organisation.name}
                    unitDetails={unitDetails}
                />
            )}
            {showAddAdvisor && isUserAgent && (
                <AddUserAsAgentWindow
                    unitID={_id}
                    setDataActors={setDataActors}
                    agentArray={dataActors?.filter((actor) => isAgent(actor.type))}
                    setShow={setShowAddAdvisor}
                />
            )}
            {showConfirmRemoveUserWindow && (
                <ConfirmUserRemoveWindow
                    setShow={setShowConfirmRemoveUserWindow}
                    unitID={_id}
                    userID={userID}
                    actors={dataActors}
                    setDataActors={setDataActors}
                />
            )}
            <div className="bg-white rounded h-52 space-details-width p-4">
                {/* Address & Profile */}
                <div className="flex flex-row">
                    <div className="flex items-center mr-2">
                        {avatar ? (
                            <img src={avatar} alt="Building" className="object-cover rounded self-center h-40 w-40" />
                        ) : (
                            <DefaultProfile
                                name={
                                    companyData?.user.__typename === 'User' ? companyData.user.organisation?.name : ''
                                }
                                size={'11rem'}
                            />
                        )}
                    </div>
                    <div className="flex flex-col w-full justify-between">
                        <div className="flex flex-row gap-3 justify-end">
                            <div
                                className={`px-2 h-11 mb-2 border justify-center bg-navy-light  owner-button-shadow w-28 text-newGray-darkish flex flex-row items-center rounded cursor-pointer`}
                                onClick={() => setShowLandlordFilesWindow(true)}
                            >
                                <Archive className={`w-4 h-4 lg:mr-4`} />
                                <p className={`md:hidden lg:flex text-sm`}>Files</p>
                            </div>
                            <div
                                onClick={() => setShowEditUnitWindow(true)}
                                className="h-11 w-7 rounded bg-navy-light flex items-center text-newGray-darkish justify-center cursor-pointer owner-button-shadow"
                            >
                                <Edit className="w-4 h-4" />
                            </div>
                        </div>
                        <div className="flex flex-row justify-between">
                            <div className="ml-3 flex flex-col text-xs w-64 truncate">
                                <p>{address.line1 && address.line1}</p>
                                <p>{address.line2 && address.line2}</p>
                                <p>{address.city && address.city}</p>
                                <p>{address.county && address.county}</p>
                                <p>{address.postCode && address.postCode}</p>
                            </div>
                        </div>
                        {/* Demise Area / Use Cause / Condition */}
                        <div className="w-full flex items-start pt-2 px-2 ">
                            <div className="w-1/3 text-center">
                                <p className="text-xxs text-gray-600">Use Clause</p>
                                <p className="text-xs font-semibold">{use && UseConditionToText(use)}</p>
                            </div>
                            <div className="border-r h-2/3 my-auto mx-1 border-gray-600" />
                            <div className="w-1/3 text-center">
                                <p className="text-xxs text-gray-600">Condition</p>
                                <p className="text-xs font-semibold">{condition && UseConditionToText(condition)}</p>
                            </div>
                            <div className="border-r h-2/3 my-auto mx-1 border-gray-600" />

                            <div className="w-1/3 flex flex-col text-center">
                                <p className="text-xxs text-gray-600">Demise Area</p>
                                <p className="text-xs font-semibold">{area && area.toLocaleString()}</p>
                                <p className="text-xxs text-gray-600">sq ft</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Actors */}
            <div className={`w-full bg-navy-light rounded pt-3`}>
                <div className="flex flex-row">
                    <p className="text-xl font-semibold text-gray-800 mb-2 mr-2">Advisors</p>
                    {!showAddAdvisor && (
                        <div
                            className="flex justify-center rounded items-center cursor-pointer bg-navy-lightest w-8 h-6 owner-button-shadow cursor-pointer"
                            onClick={() => {
                                isUserAgent ? setShowAddAdvisor(true) : setShowAddAdvisor(!showAddAdvisor);
                            }}
                        >
                            <AddUser className="w-4 h-4 text-newGray-darkish" />
                        </div>
                    )}
                    {showAddAdvisor && !isUserAgent && (
                        <div className="h-10">
                            <AddUsersInput
                                userOptions={userOptions}
                                unitId={_id}
                                addAdvisors={true}
                                orgID={typeData?.user.organisation?._id}
                                setShowAddUser={setShowAddAdvisor}
                            />
                        </div>
                    )}
                </div>
                <div className="w-full flex flex-row flex-wrap gap-3">
                    {dataActors
                        ?.filter((actor) => isAgent(actor.type))
                        .map(
                            (actor) =>
                                isAgent(actor.type) && (
                                    <div
                                        key={actor._id}
                                        className="flex flex-row rounded-r-lg bg-white py-2 items-center px-2 border "
                                        style={
                                            actor.organisation?._id === typeData?.user.organisation?._id
                                                ? {
                                                      borderLeft: '6px solid #3CA48E',
                                                      width: '32.5%'
                                                  }
                                                : {
                                                      borderLeft: '6px solid white',
                                                      width: '32.5%'
                                                  }
                                        }
                                    >
                                        <div className="w-1/4  mr-4 flex content-start self-center flex-wrap flex-col">
                                            {!actor.pending && (
                                                <p className="font-semibold text-xs mt-1">{`${actor.firstName} ${
                                                    actor.lastName ? actor.lastName : ''
                                                }`}</p>
                                            )}
                                            <p className={`text-xs text-gray-600 ${actor.pending && 'mt-4 mb-4'}`}>
                                                {actor.organisation?.name}
                                            </p>
                                        </div>
                                        <div className="w-2/3 h-11 rounded-tr rounded-br flex flex-row items-center justify-between overflow-x-hidden ">
                                            <div
                                                className="flex flex-wrap items-center text-newGray-darkish"
                                                style={{ maxWidth: '90%' }}
                                            >
                                                <div
                                                    className="flex flex-row items-center"
                                                    style={{
                                                        minWidth: '90%',
                                                        maxWidth: '100%'
                                                    }}
                                                >
                                                    <Envelope className="w-3 h-3 mr-2 text-sm text-newGray-darkish" />
                                                    <p
                                                        className="text-xs overflow-x-hidden"
                                                        style={{
                                                            textOverflow: 'ellipsis'
                                                        }}
                                                    >
                                                        {actor.email}
                                                    </p>
                                                </div>
                                                {!actor.pending ? (
                                                    <p className="text-xs flex items-center">
                                                        {' '}
                                                        <Phone className="w-2 h-2 mr-2 text-sm text-newGray-darkish" />
                                                        {actor.mobile}
                                                    </p>
                                                ) : (
                                                    <>
                                                        <br />
                                                        <p className="text-xs font-light">Invitation sent</p>
                                                    </>
                                                )}
                                            </div>
                                            {!tenant && (
                                                <button
                                                    className="focus:outline-none mr-1"
                                                    onClick={() => {
                                                        setUserID(actor._id);
                                                        setShowConfirmRemoveUserWindow(true);
                                                    }}
                                                >
                                                    <Remove className="w-4 h-4 text-newGray-darkish" />
                                                </button>
                                            )}
                                        </div>
                                    </div>
                                )
                        )}
                </div>
            </div>
        </>
    );
};

export default UnitOverviewDesktop;
