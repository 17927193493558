import { useApolloClient } from '@apollo/client';
import { useState } from 'react';

import { DealDetailsFragment, useDealSeenMutation } from '../../../generated/graphql';

const useSeenDeal = () => {
    const client = useApolloClient();
    const [seenDeal] = useDealSeenMutation();
    const [triedSeen, setTriedSeen] = useState<boolean>(false);
    const [seenRunner, setSeenRunner] = useState<Promise<void> | null>(null);

    return async (deal: DealDetailsFragment | undefined) => {
        if (seenRunner) {
            return await seenRunner;
        }

        if (
            !triedSeen &&
            deal &&
            deal.__typename === 'Deal' &&
            deal.round &&
            !deal.round.seen &&
            deal.currentUserParty === deal.state.toString()
        ) {
            const _seenRunner = seenDeal({
                variables: { dealID: deal._id }
            }).then(({ data }) => {
                if (data && deal) {
                    let round = deal.round;
                    client.cache.modify({
                        id: client.cache.identify({
                            __typename: 'Deal',
                            _id: deal._id
                        }),
                        fields: {
                            round() {
                                return {
                                    ...round,
                                    ...{ seen: true }
                                };
                            }
                        }
                    });
                }
                //yes this executes regardless of if the request fails or notit works fine without this: UNLESS some reason the request fails. If it fails,
                // the cache won't be updated and this can cause an infinite spiral of requests.
                //so, yeah, if it fails, it won't retry again until next refresh. however, it's better than spamming the backend and lagging the user's machine up
                setTriedSeen(true);
            });

            setSeenRunner(_seenRunner);

            return await _seenRunner;
        }
    };
};

export default useSeenDeal;
