import React from 'react';

import Window from '../../shared/Window';

type RestoreTemplateWindowProps = {
    setRestoreTemplateWindow: React.Dispatch<React.SetStateAction<boolean>>;
    handleRestoreClick: () => void;
};

const RestoreTemplateWindow = ({ setRestoreTemplateWindow, handleRestoreClick }: RestoreTemplateWindowProps) => {
    return (
        <Window
            title="Restore Template"
            titleCenter
            width="w-2/5"
            setShow={setRestoreTemplateWindow}
            primaryBtn
            primaryBtnText={'Confirm'}
            onPrimaryBtnClick={() => handleRestoreClick()}
            secondaryBtn
            secondaryBtnText={'Cancel'}
            onSecondaryBtnClick={() => setRestoreTemplateWindow(false)}
        >
            <div className="flex flex-col items-center">
                <h2 className="text-center text-lg my-8 w-4/5">Are you sure you want to restore this template?</h2>
            </div>
        </Window>
    );
};

export default RestoreTemplateWindow;
