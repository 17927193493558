import React, { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

import AllActionCards from './AllActionCards';
import { ReactComponent as CheckCircle } from '../../../assets/images/check/circle/gray.svg';
import {
    DealState,
    GetDealCardDetailsQuery,
    GetUnitCardDetailsQuery,
    useGetUserTypeQuery
} from '../../../generated/graphql';
import { handleFetchError } from '../../../shared/helpers';
import Loading from '../../../shared/Loading';

type ActionsRequiredProps = {
    data?: GetDealCardDetailsQuery;
    unitData?: GetUnitCardDetailsQuery;
};

const ActionsRequired = ({ data, unitData }: ActionsRequiredProps) => {
    const history = useHistory();
    const [showAllActions, setShowAllActions] = useState<boolean>(false);
    const [actionsNumber, setActionsNumber] = useState<number>(3);
    const [unitShowNumber, setUnitShowNumber] = useState<number>(0);

    let shownIds: (string | undefined)[] = useMemo(() => [], []);

    // Remove duplicate units using ID
    let units: (string | undefined)[] = [];

    const noDealUnits =
        unitData?.units &&
        unitData.units.results
            .filter((el) => el.deals && el.deals?.length === 0)
            .sort((a, b) => (a.rating > b.rating ? 1 : -1));

    useEffect(() => {
        if (actionsNumber !== 3) return;
        setUnitShowNumber(actionsNumber - shownIds.length);
    }, [shownIds, actionsNumber]);

    // User Type Check
    const { data: typeData, loading: typeLoading, error: typeError } = useGetUserTypeQuery();
    useEffect(() => {
        // Check for errors
        handleFetchError('Could not load user', typeError, typeData?.user);
    }, [typeData, typeError]);

    const deals =
        data?.deals.results &&
        data.deals.results
            .filter(
                (el) =>
                    !(
                        typeData?.user.__typename === 'User' &&
                        typeData.user.type !== 'AGENT' &&
                        el.pending &&
                        el.state !== DealState.Complete &&
                        el.state !== DealState.Edit
                    )
            )
            .sort((a, b) => (a.score < b.score ? 1 : -1));

    const isTenant = typeData?.user.__typename === 'User' && typeData?.user.type === 'TENANT';
    if (isTenant) history.push('/');

    const viewAll = () => {
        if (!showAllActions) {
            setActionsNumber(deals ? deals.length + 1 : 3);
            setUnitShowNumber(noDealUnits ? noDealUnits.length : 3);
            setShowAllActions(true);
        } else {
            setActionsNumber(3);
            setShowAllActions(false);
        }
    };

    if (typeLoading)
        return (
            <div className="container mx-auto p-4">
                <Loading />
            </div>
        );

    return (
        <div className="w-full">
            <p className="my-4 font-bold text-gray-600 flex items-center">
                <CheckCircle className="w-4 h-4 mr-1" />
                Actions Required
            </p>
            {(!noDealUnits || noDealUnits.length === 0) && (!deals || deals.length === 0) ? (
                <NoActionsRequired />
            ) : (
                <AllActionCards
                    deals={deals}
                    actionsNumber={actionsNumber}
                    noDealUnits={noDealUnits}
                    unitShowNumber={unitShowNumber}
                    viewAll={viewAll}
                    showAllActions={showAllActions}
                    shownIds={shownIds}
                    units={units}
                />
            )}
        </div>
    );
};
export default ActionsRequired;

const NoActionsRequired = () => (
    <div className="w-full rounded bg-navy-light border-2 border-gray-450 h-96 flex items-center justify-center text-center font-medium text-gray-600">
        Actions will show here once you have created a New Space and started a New Deal
    </div>
);
