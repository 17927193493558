import React, { useEffect, useRef, useState } from 'react';
import { isIOS } from 'react-device-detect';
import Div100vh from 'react-div-100vh';
import { useHistory } from 'react-router-dom';

import Header from './Header';
import MainProvider from './main.provider';
import SideBar from './SideBar';
import { useGetLayoutUserInfoQuery } from '../generated/graphql';
import Sidebar from '../pages/Settings/Sidebar';
import usePushNotifications from '../push/usePushNotifications';
import useMobileScreen from '../shared/hooks/useMobileScreen';
import { useUserType } from '../v2/common/helpers';
import ScrollUpWrapper from '../v2/common/layout/ScrollUpWrapper';
import LandlordNavbar from '../v2/common/Navbar/Landlord';
import TenantNavbar from '../v2/common/Navbar/Tenant';

type LayoutProps = {
    headerContent: React.ReactChild | null;
    children: React.ReactNode;
};

const Layout = ({ headerContent, children }: LayoutProps) => {
    const history = useHistory();
    const [visible, setVisible] = useState<boolean>(false);
    const { loading, data } = useGetLayoutUserInfoQuery();

    const isMobile = useMobileScreen();

    const isScrolling = useRef<HTMLInputElement>(null);

    // Temporarily ask user for notification permissions on page load
    const { userConsent, userPermissionClick } = usePushNotifications();
    useEffect(() => {
        if (userConsent === 'default') userPermissionClick();
    }, [userConsent, userPermissionClick]);

    useEffect(() => {
        if (data?.user.__typename === 'User') if (!data.user.activated) history.push('/restricted');
    }, [data, history]);

    const isAuthenticated = !loading && !!data;
    const { isTenant } = useUserType(data?.user.type);

    return (
        <MainProvider>
            <Div100vh className="least-app-main md:flex">
                <Div100vh className={`least-app-content w-full bg-navy-light`}>
                    {visible && <SideBar visible={visible} setVisible={setVisible} data={data} />}
                    {data &&
                        !isMobile &&
                        (isTenant ? (
                            <TenantNavbar data={data} />
                        ) : (
                            !isMobile && <LandlordNavbar data={data} loading={loading} />
                        ))}
                    {headerContent && isMobile && !visible && (
                        <Header setVisible={setVisible} visible={visible}>
                            {headerContent}
                        </Header>
                    )}
                    {visible && isMobile && <Sidebar setVisible={setVisible} visible={visible} data={data} />}
                    <ScrollUpWrapper isScrolling={isScrolling}>
                        <div
                            className={`${
                                isMobile
                                    ? isIOS
                                        ? 'h-full-content-mobile-ios'
                                        : 'h-full-content-mobile-android'
                                    : ' max-h-full-content h-full-content'
                            } ${isAuthenticated ? 'overflow-y-auto' : ''}`}
                            id="layout-content"
                            ref={isScrolling}
                        >
                            {children}
                        </div>
                    </ScrollUpWrapper>
                </Div100vh>
            </Div100vh>
        </MainProvider>
    );
};

export default Layout;
