import React, { useRef, useState } from 'react';

import { allClausesID } from './Clause';
import { CommentType, useCommentClauseMutation } from '../../../../generated/graphql';
import { handleFetchError } from '../../../../shared/helpers';
import Select from '../../../../shared/Inputs/Select';
import Loading from '../../../../shared/Loading';
import Window from '../../../../shared/Window';

type RequestChangeWindowProps = {
    dealID: string;
    parentID: string;
    clauseID: string;
    shortForm?: string;
    longForm?: string;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
    allClausesID: allClausesID[] | undefined;
    currentTags?: string[];
    group: string;
};

const RequestChangeWindow = ({
    dealID,
    parentID,
    clauseID,
    shortForm,
    longForm,
    allClausesID,
    currentTags,
    group,
    setShow
}: RequestChangeWindowProps) => {
    // Error message
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    const [checkRejectAll, setCheckRejectAll] = useState<boolean>(false);

    const [type, setType] = useState<string>();
    const messageRef = useRef<HTMLTextAreaElement>(null);

    const formattedLongForm = { __html: longForm ?? '' };

    const [commentClause, { error: commentClauseError, loading: commentClauseLoading }] = useCommentClauseMutation();

    const handleReject = async (message: string, messageType: CommentType, clauseID: string) => {
        const { data } = await commentClause({
            variables: {
                dealID,
                parentID,
                clauseID,
                comment: {
                    message,
                    type: messageType
                }
            }
        });

        if (commentClauseError || data?.dealClauseUpdate.__typename !== 'Clause')
            return handleFetchError('Failed to request the change', commentClauseError, data?.dealClauseUpdate);
    };

    const handleSubmit = async () => {
        let commentType: CommentType;

        if (currentTags?.indexOf('comment_clause') !== -1) {
            commentType = CommentType.Edit;
        } else {
            switch (type) {
                case 'EDIT':
                    commentType = CommentType.Edit;
                    break;
                case 'REMOVE':
                    commentType = CommentType.Removal;
                    break;
                default:
                    return setErrorMessage('Please select a change type');
            }
        }

        if (!messageRef.current?.value || messageRef.current.value.trim().length === 0)
            return setErrorMessage('Please enter a message');

        const message = messageRef.current.value;

        if (checkRejectAll && allClausesID) {
            for (const clause of allClausesID) {
                await handleReject(message, commentType, clause._id);
            }
        } else {
            await handleReject(message, commentType, clauseID);
        }

        setShow(false);
    };

    if (commentClauseLoading)
        return (
            <Window title="Request Change" width="w-2/5" setShow={setShow}>
                <Loading />
            </Window>
        );

    return (
        <Window
            title={currentTags?.indexOf('comment_clause') === -1 ? 'Request Change' : 'Comment'}
            width="w-2/5"
            setShow={setShow}
            primaryBtn
            onPrimaryBtnClick={handleSubmit}
        >
            <div className="p-2 md:mb-6">
                {/* Clause */}
                <div className="w-full mb-2 bg-navy-lightest rounded-tl rounded-b -my-px">
                    <div className="w-full p-4 py-2">{shortForm}</div>
                    <div
                        className="bg-navy-lightest pl-4 py-2 text-sm  border-t-2 rounded-bl rounded-br border-white"
                        dangerouslySetInnerHTML={formattedLongForm}
                    ></div>
                </div>

                {/* Comment */}
                <form className="flex flex-col" onSubmit={(e) => e.preventDefault()}>
                    {currentTags?.indexOf('comment_clause') === -1 && (
                        <>
                            <p className="mt-2 text-gray-600 font-medium text-sm mb-0.5">Action</p>
                            <Select
                                data={[
                                    {
                                        id: 'REMOVE',
                                        text: 'Removal'
                                    },
                                    {
                                        id: 'EDIT',
                                        text: 'Edit Clause'
                                    }
                                ]}
                                onItemClick={(id) => id && setType(id)}
                                placeholder="Action"
                                className="w-1/3"
                            />
                        </>
                    )}
                    <p className="mt-2 text-gray-600 font-medium text-sm mb-0.5">Comment</p>
                    <textarea
                        className="w-full rounded bg-navy-lightest p-2 border border-transparent focus:outline-none focus:border-newTeal-main"
                        rows={6}
                        autoFocus
                        placeholder="Comment"
                        ref={messageRef}
                    ></textarea>
                    {currentTags?.indexOf('comment_clause') === -1 && (
                        <label className="block mt-4">
                            <input
                                type="checkbox"
                                id="optional"
                                checked={checkRejectAll}
                                onChange={() => setCheckRejectAll(!checkRejectAll)}
                                className="leading-tight w-6 h-6 form-checkbox p-2 rounded bg-white text-newTeal-main focus:shadow-none focus:outline-none"
                            />
                            <span className="text-sm font-medium text-gray-600 ml-2">
                                Reject all clauses from {group} ?
                            </span>
                        </label>
                    )}
                    {errorMessage && (
                        <div className="bg-red-200 border border-red-600 rounded py-2 px-4 my-4 mt-2">
                            <span className="text-center text-red-600">{errorMessage}</span>
                        </div>
                    )}
                </form>
            </div>
        </Window>
    );
};

export default RequestChangeWindow;
