import React, { useContext, useEffect, useState } from 'react';
import Modal from 'react-modal';

import WindowContent from './WindowContent';
import { WindowContext, WindowProps } from './WindowContext';
import WindowFooter from './WindowFooter';
import WindowHeader from './WindowHeader';
import { MainContext } from '../../layout/main.provider';
import ExternalWindow from '../ExternalWindow';
import useMobileScreen from '../hooks/useMobileScreen';

const Window = ({
    setShow,
    title,
    emoji,
    titleCenter,
    children,
    header,
    footer,
    width,
    height,
    buttons,
    primaryBtn,
    primaryBtnText,
    onPrimaryBtnClick,
    secondaryBtn,
    secondaryBtnText,
    onSecondaryBtnClick,
    onCloseClick,
    tertiaryBtn,
    tertiaryBtnText,
    onTertiaryBtnClick,
    scrollContent,
    zIndex = '50',
    allowExternal = false,
    className,
    contentClassName,
    contentMiddle,
    contentBackground,
    disableClose,
    noScroll,
    backgroundColor,
    underlined
}: WindowProps) => {
    const mobileScreen = useMobileScreen();

    const { setWindowOpen } = useContext(MainContext);
    const [externalWindow, setExternalWindow] = useState<boolean>(false);

    useEffect(() => {
        setWindowOpen(true);
    }, [setWindowOpen]);

    const modalStyles = {
        content: {
            background: 'none',
            border: 'none'
        },
        overlay: {
            zIndex: 100
        }
    };

    return (
        <Modal isOpen={true} style={modalStyles} ariaHideApp={false}>
            <WindowContext.Provider
                value={{
                    setShow,
                    title,
                    emoji,
                    titleCenter,
                    children,
                    header,
                    footer,
                    width,
                    height,
                    buttons,
                    primaryBtn,
                    primaryBtnText,
                    onPrimaryBtnClick,
                    secondaryBtn,
                    secondaryBtnText,
                    onSecondaryBtnClick,
                    onCloseClick,
                    tertiaryBtn,
                    tertiaryBtnText,
                    onTertiaryBtnClick,
                    scrollContent,
                    zIndex,
                    allowExternal,
                    className,
                    contentClassName,
                    contentMiddle,
                    contentBackground,
                    externalWindow,
                    setExternalWindow,
                    disableClose,
                    noScroll,
                    underlined
                }}
            >
                {externalWindow ? (
                    <ExternalWindow title={title ?? ''} setShow={setShow} setShowExternalWindow={setExternalWindow}>
                        <WindowContent backgroundColor={backgroundColor} />
                    </ExternalWindow>
                ) : (
                    // Outside Container
                    <div
                        className={`fixed w-screen h-screen top-0 left-0 flex items-center justify-center z-${zIndex} md:mt-0 md:pb-0`}
                        data-testid="window"
                    >
                        {/* Backdrop */}
                        <div
                            className="absolute w-full h-full bg-gray-900 opacity-50 hidden md:block"
                            onClick={() => {
                                !disableClose && setWindowOpen(false);
                                !onCloseClick ? !disableClose && setShow(false) : !disableClose && onCloseClick();
                            }}
                        ></div>

                        {/* Main Container */}
                        <div
                            className={`${height ? 'md:' + height + ' ' : 'md:max-h-11/12 md:h-auto '}
                        ${noScroll ? '' : 'overflow-auto '}
                         bg-white flex flex-col w-screen h-screen  md:${width ? width : 'w-auto'} ${
                                scrollContent && 'md:overflow-y-hidden'
                            } mx-auto shadow-lg z-${zIndex} flex flex-col ${
                                mobileScreen ? 'pt-3' : ''
                            } content-between md:rounded mb:pb-0 md:pb-0 md:-mt-0 ${className}`}
                        >
                            <WindowHeader />
                            <WindowContent backgroundColor={backgroundColor} />
                            <WindowFooter />
                        </div>
                    </div>
                )}
            </WindowContext.Provider>
        </Modal>
    );
};

export default Window;
