import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import { ReactComponent as Eye } from '../../../../assets/images/eye/black.svg';
import { DealState, useGetDealDetailsNoHistoryQuery, useGetStagesCompletedQuery } from '../../../../generated/graphql';
import TenantDetailsWindow from '../../../../pages/Units/Unit/Landlord/TenantDetailsWindow';
import { isCompletedDeal, isDealEdit, isDealInNegotiate } from '../../../common/helpers';

type StageProps = {
    label: string;
    className?: string;
    isActive: boolean;
    circleClassName?: string;
};

const Stage = ({ label, className, isActive, circleClassName }: StageProps) => {
    return (
        <div
            className={`flex flex-row items-center  ${
                isActive ? 'sm:text-sm xl:text-lg font-semibold sm:flex' : 'sm:hidden lg:flex'
            } ${className}`}
        >
            <div className={`sm:hidden lg:flex ${circleClassName}`} />
            <div className="lg:ml-2">{label}</div>
        </div>
    );
};
type StageLineProps = {
    className: string;
    isActive: boolean;
};

const StageLine = ({ className, isActive }: StageLineProps) => {
    return (
        <div
            className={`sm:h-8 lg:h-7 border-l-2 sm:hidden lg:flex ${
                isActive ? 'border-newTeal-main' : 'border-white'
            } ml-0.2 ${className} `}
        />
    );
};

type StagesProps = {
    state?: DealState;
};

const Stages = ({ state }: StagesProps) => {
    const styles = 'border rounded-full h-2 w-2 mr-2 bg-navy-main z-10';

    const activeStyles = 'border-4 border-newTeal-main bg-white rounded-full h-2 w-2 mr-2 z-10';

    const isEditState = isDealEdit(state);
    const isCompletedState = isCompletedDeal(state);
    const isNegotiationState = isDealInNegotiate(state);

    return (
        <div className="flex flex-row w-full h-28">
            <div className="flex flex-col w-full m-4 sm:justify-center lg:items-start sm:items-center lg:left-3 top-2 my-5 lg:ml-5 xl:ml-8 text-xxs ">
                <Stage isActive={isEditState} label={'Draft'} className={'text-white'} circleClassName={activeStyles} />
                <StageLine className={'-mt-2'} isActive={isNegotiationState || isCompletedState} />
                <Stage
                    isActive={isNegotiationState}
                    label={'In Negotiation'}
                    className={`${isEditState ? 'text-gray-600' : 'text-white'}   ${
                        isNegotiationState ? 'text-sm sm:-mt-4 xl:-mt-2' : 'sm:-mt-3 lg:-mt-1'
                    }`}
                    circleClassName={isNegotiationState || isCompletedState ? activeStyles : styles}
                />
                <StageLine
                    className={isNegotiationState ? 'sm:-mt-4 xl:-mt-2' : ' sm:-mt-3 lg:-mt-1'}
                    isActive={isCompletedState}
                />
                <Stage
                    isActive={isCompletedState}
                    label={'Terms Agreed'}
                    className={`${
                        isEditState || isNegotiationState
                            ? 'text-gray-600 sm:-mt-3 lg:-mt-1'
                            : 'text-white sm:-mt-4 lg:-mt-2'
                    }`}
                    circleClassName={isCompletedState ? activeStyles : styles}
                />
            </div>
        </div>
    );
};

type TenantReadinessProps = {
    id: string;
    token: string;
    setTenantReadinessWindow: React.Dispatch<React.SetStateAction<boolean>>;
};

const TenantReadiness = ({ id, token, setTenantReadinessWindow }: TenantReadinessProps) => {
    const { data: stages } = useGetStagesCompletedQuery({
        variables: {
            dealID: id,
            token
        }
    });

    const stage = stages?.deal.details;
    const stageStates = [
        stage?.one?.completed,
        stage?.two?.completed,
        stage?.team?.completed,
        stage?.three?.completed,
        stage?.aml?.completed,
        stage?.final?.completed
    ];
    const completedStages = stageStates.filter((isCompleted) => isCompleted).length;

    return (
        <div
            className="flex flex-col w-full h-32 bg-newGray-darkish cursor-pointer"
            onClick={() => setTenantReadinessWindow(true)}
        >
            <div className="flex flex-row text-white mt-4 sm:justify-center lg:justify-start lg:ml-4 xl:ml-7 ">
                <div className="border-2 rounded-full items-center justify-center sm:hidden xl:flex border-white mt-1 w-4 h-4">
                    <div className="rounded-full bg-white w-2 h-2 " />
                </div>
                <div className="flex flex-col lg:ml-3">
                    <p className=" lg:mr-6 text-sm">Tenant Readiness</p>
                    <div className=" flex-row sm:hidden lg:flex mt-2 gap-2">
                        {stageStates.map((completed) => (
                            <div className={`rounded-full ${completed ? 'bg-newTeal-main' : 'bg-white'} w-3 h-3`} />
                        ))}
                    </div>
                    <div className="text-white text-xl sm:mt-6 sm:ml-2 lg:ml-0 lg:mt-4 ">
                        <span className="text-3xl font-semibold">{completedStages}</span>
                        /6
                    </div>
                </div>
            </div>

            <div className="w-full flex flex-row w-full justify-end ">
                <div className="flex items-center w-1/2 bg-teal-6 text-xs h-8 font-semibold justify-center -mt-8 rounded-l-lg ">
                    <p className="sm:hidden lg:flex">Click to view </p>
                    <Eye className="h-5 w-5 sm:flex lg:hidden" />
                </div>
            </div>
        </div>
    );
};

const LandlordDeal = () => {
    const { id, token } = useParams<{ id: string; token: string }>();
    const { data } = useGetDealDetailsNoHistoryQuery({
        variables: {
            dealID: id,
            token
        }
    });

    const [tenantReadinessWindow, setTenantReadinessWindow] = useState<boolean>(false);

    return (
        <>
            {tenantReadinessWindow && <TenantDetailsWindow dealID={id} setShow={setTenantReadinessWindow} />}
            <div
                data-testid="layout--sidebar"
                className={`bg-navy-light md:pb-6 overflow-y-auto overflow-x-hidden sidebar-height sticky top-10`}
            >
                <Stages state={data?.deal.state} />
                <TenantReadiness id={id} token={token} setTenantReadinessWindow={setTenantReadinessWindow} />
            </div>
        </>
    );
};

export default LandlordDeal;
