import React, { forwardRef, RefObject } from 'react';

import useMobileScreen from '../hooks/useMobileScreen';
import Input, { InputType } from '../Inputs';

type ClauseValueProps = {
    value?: string | null;
    valueSuffix?: string | null;
    tags?: string[] | null;
    isEditing: boolean;
    setEditing: React.Dispatch<React.SetStateAction<boolean>>;
    valueRef: ((instance: any) => void) | RefObject<any> | null | undefined;
    handleSave: () => void;
};

const ClauseValue = forwardRef(
    ({ valueSuffix, value, tags, isEditing, setEditing, valueRef, handleSave }: ClauseValueProps) => {
        const mobileScreen = useMobileScreen();

        const numeric_thousands_value =
            !isNaN(Number(value)) && !tags?.includes('no_format') ? Number(value).toLocaleString() : value;

        return (
            <>
                {value !== undefined && (
                    <form
                        onSubmit={(e) => {
                            e.preventDefault();
                            value && handleSave();
                        }}
                        className={`flex flex-row justify-center items-center rounded bg-newTeal-light ${
                            mobileScreen && 'w-1/2 ml-4 my-1'
                        }`}
                    >
                        {((valueSuffix && valueSuffix.trim().length > 0) ||
                            (value != null && value.trim().length > 0)) && (
                            <>
                                {valueSuffix && (valueSuffix === '£' || valueSuffix === '$' || valueSuffix === '€') && (
                                    <span className="mx-4">{valueSuffix}</span>
                                )}
                                {isEditing ? (
                                    <Input
                                        type={InputType.text}
                                        defaultValue={value ? (value === '*' ? '' : value) : ''}
                                        readOnly={!isEditing}
                                        ref={valueRef}
                                        containerClassName="h-8"
                                        height="8"
                                        className={`mb-0 w-28 h-8 bg-white border border-newTeal-main text-center`}
                                        onBlur={handleSave}
                                        autoFocus={isEditing}
                                    />
                                ) : (
                                    <div
                                        className="h-9 p-4 rounded focus:outline-none mb-0 text-center flex flex-row items-center justify-center gap-2 clause-input-min-width"
                                        onClick={() => setEditing(true)}
                                    >
                                        <span className="font-semibold">{numeric_thousands_value ?? ''}</span>
                                    </div>
                                )}
                                {valueSuffix && valueSuffix !== '£' && valueSuffix !== '$' && valueSuffix !== '€' && (
                                    <span className="mx-2 text-newGray-darkish text-sm">{valueSuffix}</span>
                                )}
                            </>
                        )}
                    </form>
                )}
            </>
        );
    }
);

export default ClauseValue;
