import React, { ChangeEvent, useEffect, useState } from 'react';

import { useOuterClick } from '../hooks/useOutsideClick';

import Input, { InputProps } from '.';

type AutofillProps = InputProps & {
    data: {
        text: string;
        subtext?: string;
        id: string;
    }[];
    showOnClick?: boolean;
    onItemClick?: (id: string) => void;
    endElement?: React.ReactChild;
    label: string;
    setEmails?: React.Dispatch<React.SetStateAction<string[]>>;
};

const Autofill = React.forwardRef(
    ({ data, showOnClick, onItemClick, endElement, className, label, setEmails, ...props }: AutofillProps, ref) => {
        const [value, setValue] = useState<string>('');
        const [showDropdown, setShowDropdown] = useState<boolean>(false);
        const [matchingItems, setMatchingItems] = useState<typeof data>([]);
        const [highlight, setHighlight] = useState<number>(-1);
        const [keyPressed, setKeyPressed] = useState<KeyboardEvent>();

        // Hide dropdown on click outside
        const innerRef = useOuterClick(() => setShowDropdown(false));

        const onKeyup = (event: KeyboardEvent) => {
            // event.preventDefault()
            setKeyPressed(event);
        };

        const handleClick = (item: { text: string; id: string }, buttonPressed: string) => {
            // Fill out text field
            setValue(item.text);

            // Close dropdown
            setShowDropdown(false);

            if (buttonPressed === 'Tab') {
                // Tell parent component
                onItemClick && onItemClick(item.id);
            }
        };

        const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
            setValue(event.target.value);
            // TODO: Remove all email related stuff from Autofill, it does not belong here
            setEmails && setEmails([event.target.value]);

            const matchingItems = data.filter(
                (item) =>
                    item.text.toLowerCase().includes(event.target.value.toLowerCase()) ||
                    item.subtext?.toLowerCase().includes(event.target.value.toLowerCase())
            );
            setMatchingItems(matchingItems);
            // Only show the dropdown if needed
            setShowDropdown(endElement ? true : matchingItems.length > 0);

            // Tell parent component value is no longer selected
            onItemClick && onItemClick('');
        };

        const handleInputClick = () => {
            if (showOnClick) {
                setMatchingItems(data);
                setShowDropdown(true);
            }
        };

        useEffect(() => {
            if (keyPressed) {
                if (showDropdown) {
                    if (keyPressed.key === 'Enter' || keyPressed.key === 'Tab') {
                        if (highlight !== -1) {
                            if (keyPressed.key === 'Enter') {
                                handleClick(data[highlight], 'Enter');
                            } else {
                                handleClick(data[highlight], 'Tab');
                            }
                            setHighlight(-1);
                        }
                    } else if (keyPressed.key === 'ArrowDown') {
                        if (highlight === -1) {
                            setHighlight(0);
                        } else if (highlight < data.length - 1) {
                            setHighlight(highlight + 1);
                        }
                    } else if (keyPressed.key === 'ArrowUp') {
                        if (highlight === -1) {
                            setHighlight(data.length - 1);
                        } else if (highlight > 0) {
                            setHighlight(highlight - 1);
                        }
                    }
                }
                setKeyPressed(undefined);
            }

            window.addEventListener('keyup', onKeyup);
            return () => window.removeEventListener('keyup', onKeyup);
            // eslint-disable-next-line
        }, [keyPressed, data, highlight, showDropdown]);

        return (
            <div className={`relative ${className}`} ref={innerRef}>
                <Input
                    {...props}
                    ref={ref}
                    value={value}
                    onChange={handleChange}
                    onClick={handleInputClick}
                    className={` w-full -mb-px ${value !== '' && 'bg-navy-lightest'}`}
                    onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                            e.preventDefault();
                        }
                    }}
                    label={label}
                />
                {showDropdown && data.length > 0 && (
                    <div className="absolute left-0 right-0 top-auto flex flex-col bg-white border border-newTeal-main rounded items-center float-left shadow-lg w-full mb-4 overflow-x-hidden max-h-96 z-50 mt-2">
                        {matchingItems.map((item, i) => (
                            <span
                                onClick={() => handleClick(item, 'Tab')}
                                className={`${item.subtext ? 'pb-2 pt-4' : 'py-2'}
                                px-4 ${
                                    i < matchingItems.length - 1 && 'border-b'
                                } border-navy-light cursor-pointer hover:bg-navy-lightest w-full text-left relative`}
                                key={item.id}
                            >
                                {item.subtext && (
                                    <span className="text-xxs text-newGray-main absolute top-0 left-0 m-1 px-3">
                                        {item.subtext}
                                    </span>
                                )}
                                {item.text}
                            </span>
                        ))}
                        <div className="w-full" onClick={() => setShowDropdown(false)}>
                            {endElement}
                        </div>
                    </div>
                )}
            </div>
        );
    }
);

export default Autofill;
