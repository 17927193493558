import { useFormikContext } from 'formik';
import { debounce } from 'lodash';
import React, { useCallback, useContext, useState } from 'react';
import Swal from 'sweetalert2';
import * as Yup from 'yup';

import { LawyerValues } from './Lawyer';
import { TeamValues } from './Team';
import {
    CheckEmailDocument,
    DealUserParty,
    GetDealDetailsDocument,
    useAddActorToDealMutation,
    useCheckEmailQuery,
    UserType
} from '../../../../../generated/graphql';
import { Button } from '../../../../../shared/button';
import { InputType } from '../../../../../shared/Inputs';
import { isAgent, requiredIfVisible } from '../../../../common/helpers';
import { ReadinessContext } from '../context';
import InputField from '../InputField';

export type ContactValues = {
    name?: string | null;
    company?: string | null;
    email?: string | null;
    phone?: string | null;
};

export const ContactSchema = Yup.object().shape({
    name: requiredIfVisible(Yup.string().nullable(), ' ', 'visible'),
    company: requiredIfVisible(Yup.string().nullable(), ' ', 'visible'),
    email: requiredIfVisible(Yup.string().email().nullable(), ' ', 'visible'),
    phone: requiredIfVisible(Yup.string().nullable(), ' ', 'visible'),
    visible: Yup.boolean()
});

export enum ContactType {
    Agent = 'agent',
    Lawyer = 'lawyer',
    Tenant = 'tenant'
}

type ContactProps = {
    userType: UserType;
    contactType: ContactType;
};

const Contact = ({ userType, contactType }: ContactProps) => {
    const { dealID = '' } = useContext(ReadinessContext);
    const { values, setFieldValue } = useFormikContext<LawyerValues | TeamValues>();

    const formEmail: string = values[contactType].email || '';

    const [email, setEmail] = useState<string>(formEmail);

    const [addUser] = useAddActorToDealMutation();

    const { data } = useCheckEmailQuery({
        variables: {
            email,
            dealID,
            type: userType
        }
    });
    const isEmailRegistered = data?.checkEmail;

    const invite = () => {
        addUser({
            variables: {
                dealID: dealID!,
                type: isAgent(userType) ? UserType.Agent : UserType.Tenant,
                party: DealUserParty.Tenant,
                emails: [email]
            },
            refetchQueries: () => [GetDealDetailsDocument, CheckEmailDocument],
            onCompleted: () => {
                Swal.fire({
                    title: 'Success!',
                    text: 'User invited successfully.',
                    icon: 'success',
                    iconColor: '#3CA48E',
                    confirmButtonColor: '#3CA48E'
                });
            }
        });
    };

    const checkEmail = useCallback(
        debounce((email: string) => {
            setEmail(email);
        }, 1000),
        []
    );

    return (
        <>
            <InputField required name={`${contactType}.name`} label="Name" />
            <InputField required name={`${contactType}.company`} label="Company" />
            <div className="grid grid-cols-3">
                <div className="col-span-2">
                    <InputField
                        required
                        name={`${contactType}.email`}
                        label="Email"
                        value={formEmail}
                        onChange={(e) => {
                            setFieldValue(`${contactType}.email`, e.target.value);
                            checkEmail(e.target.value);
                        }}
                        width="fit-content"
                    />
                    <InputField
                        required
                        name={`${contactType}.phone`}
                        label="Phone"
                        type={InputType.number}
                        onChange={(e) => setFieldValue(`${contactType}.phone`, String(e.target.value))}
                        width="fit-content"
                    />
                </div>
                <Button
                    disabled={isEmailRegistered}
                    onClick={invite}
                    border={'none'}
                    background={isEmailRegistered ? 'navy-lightest' : 'newTeal-light hover:bg-newTeal-main'}
                    className={`self-end ml-4 mb-1 px-3 rounded-l-none rounded-r-3xl ${
                        !isEmailRegistered && 'text-black hover:text-white'
                    }`}
                    style={{ height: '85px', fontSize: '0.7rem' }}
                    textAlignment={'text-left'}
                >
                    Invite to Kato
                </Button>
            </div>
        </>
    );
};

export default Contact;
