import React, { useContext } from 'react';
import { NavLink, useLocation } from 'react-router-dom';

import { ReactComponent as Bell } from '../../../../../src/assets/images/bell/black.svg';
import { ReactComponent as Help } from '../../../../../src/assets/images/help/black.svg';
import Logo from '../../../../../src/assets/images/newLogo/pink.svg';
import { ReactComponent as Settings } from '../../../../../src/assets/images/settings/black.svg';
import { GetLayoutUserInfoQuery, useNotificationsQuery } from '../../../../generated/graphql';
import { MainContext } from '../../../../layout/main.provider';
import NavContainer from '../../../../layout/SideBar/components/NavContainer';
import Profile from '../../../../layout/SideBar/components/Profile';
import { useDisclosure } from '../../../../shared/hooks/useDisclosure';
import useMobileScreen from '../../../../shared/hooks/useMobileScreen';
import HelpDropdown from '../../../components/HelpDropdown';
import NotificationsPopup from '../../../components/NotificationsPopup';
import { Readiness } from '../../../components/Tenant/Readiness';
import { pageMatch, PAGES } from '../../helpers';
import Tab from '../../Tab/Tab';

type LandlordNavbarProps = {
    data?: GetLayoutUserInfoQuery;
    loading?: boolean;
};

const LandlordNavbar = ({ data, loading }: LandlordNavbarProps) => {
    const { windowOpen } = useContext(MainContext);
    const mobileScreen = useMobileScreen();

    const { data: notificationsData, loading: notificationsLoading } = useNotificationsQuery({
        variables: {
            page: 1,
            compact: false
        },
        fetchPolicy: 'network-only'
    });

    let location = useLocation();
    const { isOpen: isHelpOpen, close: closeHelp, toggle: toggleHelp, ref: helpRef } = useDisclosure();
    const {
        isOpen: isNotificationsOpen,
        close: closeNotifications,
        toggle: toggleNotifications,
        ref: notificationsRef
    } = useDisclosure();

    const hasUnseenNotifications = !notificationsData?.notifications.notifications.every(
        (notification) => notification.read
    );

    const currentPage = location.pathname;

    const showAdditionalTab = pageMatch(currentPage, [PAGES.NOTIFICATIONS, PAGES.SETTINGS, PAGES.GUIDANCE_VIDEOS]);

    const heightProps = 'h-10';
    const navLinkProps = `cursor-pointer header-tab-shadow md:flex flex-row justify-center
        font-semibold  pt-2 bg-newTeal-lightest hover:bg-newTeal-light content-between relative
        items-center ${heightProps}  ${
        showAdditionalTab ? 'w-1/5' : 'md:w-1/4 lg:w-1/5'
    }  md:text-base xxl:text-lg overflow-hidden whitespace-no-wrap `;

    const additonalTabProps = `z-60 md:w-3/4 lg:hidden h-full absolute -mt-2 inset-box-shadow`;

    const shadowColor = (path: string) => {
        return (location.pathname + location.search).includes(path) ? '#40ddd5' : '#c5f4f1';
    };

    const tabs = [
        {
            path: `/`,
            shadowColorPath: `/?`,
            exact: true,
            wording: `Dashboard`
        },
        {
            path: `/deals`,
            shadowColorPath: `/deals`,
            exact: false,
            wording: 'My Deals'
        },
        {
            path: `/units`,
            shadowColorPath: `/units`,
            exact: false,
            wording: `My Spaces`
        },
        {
            path: `/templates`,
            shadowColorPath: `/templates`,
            exact: false,
            wording: `Templates`
        }
    ];

    return (
        <>
            <div
                className={`w-full flex items-center fixed justify-between flex-row ${heightProps} top-0 sticky ${
                    windowOpen && mobileScreen ? 'z-0' : 'z-60'
                } bg-white`}
            >
                <div className="grid grid-cols-7 w-full">
                    <div
                        className={`col-span-1 hidden px-5  md:flex flex-row  justify-between bg-navy-main ${heightProps}
                            content-between  z-60 relative items-center left-0 border-b-2 border-newTeal-main`}
                    >
                        <img src={Logo} alt="Logo" className="md:h-6 xl:h-7 z-10" />
                    </div>
                    <div className="col-span-6 flex w-full">
                        <div className="flex flex-grow flex-initial flex-row h-10 border-b-2 border-newTeal-main">
                            {tabs.map((tab) => (
                                <NavLink
                                    to={tab.path}
                                    exact={tab.exact}
                                    className={`${navLinkProps} border-b-2 border-newTeal-main`}
                                    activeClassName="nav-active-bg hover-nav-active-bg text-white"
                                >
                                    <p className="font-semibold md:ml-2 lg:ml-0">
                                        <div
                                            className={additonalTabProps}
                                            style={{
                                                boxShadow: `inset -13px -1px 11px 0px ${shadowColor(
                                                    tab.shadowColorPath
                                                )}, 20px 3px 40px 0 rgb(0 0 0 / 2%)`
                                            }}
                                        />
                                        {tab.wording}
                                    </p>
                                </NavLink>
                            ))}
                            {showAdditionalTab && (
                                <div className={`${navLinkProps} nav-active-bg hover-nav-active-bg text-white`}>
                                    <div className="font-semibold flex flex-row ">
                                        {pageMatch(currentPage, [PAGES.NOTIFICATIONS]) ? (
                                            <Tab wording={'Notifications'} styles={'md:ml-5 lg:ml-0'} />
                                        ) : pageMatch(currentPage, [PAGES.SETTINGS]) ? (
                                            <Tab wording={'Settings'} styles={'pb-0.5'} />
                                        ) : (
                                            <Tab wording={'Guidance Videos'} styles={'md:ml-12 lg:ml-0 '} />
                                        )}
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className="border-b-2 border-newTeal-main h-10 flex md:col-span-1 xl:col-span-2 justify-end md:pr-8 xl:pr-32 pl-10 flex-row items-center">
                            {loading ? (
                                <div className="animate-pulse flex flex-row">
                                    <div className="w-8 h-8 md:hidden bg-navy-lightest lg:flex mr-1 rounded object-cover" />
                                    <div className="flex-col md:hidden xl:flex mx-3">
                                        <div className="bg-navy-lightest w-24 h-3 mb-2" />
                                        <div className="bg-navy-lightest w-24 h-3" />
                                    </div>
                                </div>
                            ) : (
                                <>
                                    {data?.user.organisation.avatar ? (
                                        <img
                                            src={data.user.organisation.avatar}
                                            alt={data.user.organisation.name}
                                            className="w-8 h-8 md:hidden lg:flex mr-1 rounded object-cover"
                                        />
                                    ) : (
                                        <Profile
                                            headerClass={'md:hidden lg:flex'}
                                            className="w-2 h-4 -ml-10 "
                                            size="35px"
                                            fontSize="17px"
                                            name={
                                                data?.user.__typename === 'User' && data.user?.organisation.name
                                                    ? data.user.organisation.name
                                                    : ''
                                            }
                                            src={data?.user.organisation.avatar}
                                        />
                                    )}
                                    <div
                                        className="flex-col md:hidden xl:flex mx-3"
                                        style={showAdditionalTab ? { maxWidth: '6rem' } : { maxWidth: '10rem' }}
                                    >
                                        <p
                                            className="font-semibold whitespace-no-wrap truncate overflow-hidden"
                                            style={{ fontSize: '13px' }}
                                        >
                                            {`${data?.user.firstName} ${data?.user.lastName}`}
                                        </p>
                                        <p
                                            className=" whitespace-no-wrap truncate overflow-hidden"
                                            style={{ fontSize: '13px' }}
                                            data-border-color={'white'}
                                            data-tip={data?.user.organisation.name}
                                        >
                                            {data?.user.organisation.name}
                                        </p>
                                    </div>
                                </>
                            )}
                            <NavContainer flexRow backgroundColor="white" className="cursor-pointer">
                                <div
                                    className={`md:ml-2  xxl:ml-13 hover:bg-newTeal-main text-black hover:text-white cursor-pointer ${heightProps} w-7 flex justify-center items-center`}
                                    onClick={toggleNotifications}
                                >
                                    <Bell className="w-5 h-5  " />
                                    {hasUnseenNotifications && !notificationsLoading && (
                                        <div className="bg-red-400 rounded-full w-2 h-2 -ml-2 -mt-4" />
                                    )}
                                </div>
                                <NavLink
                                    to={`/settings`}
                                    className={`ml-4 hover:bg-newTeal-main hover:text-white ${heightProps} w-7 flex justify-center items-center`}
                                >
                                    <Settings className="w-5 h-5 " />
                                </NavLink>
                                <div
                                    className={`ml-4 hover:bg-newTeal-main hover:text-white ${heightProps} w-7 flex justify-center items-center`}
                                    onClick={toggleHelp}
                                >
                                    <Help className="w-5 h-5" />
                                </div>
                            </NavContainer>
                        </div>
                    </div>
                </div>
                <Readiness />
            </div>
            {isHelpOpen && <HelpDropdown close={closeHelp} ref={helpRef} />}
            {isNotificationsOpen && notificationsData && (
                <NotificationsPopup data={notificationsData} close={closeNotifications} ref={notificationsRef} />
            )}
        </>
    );
};

export default LandlordNavbar;
