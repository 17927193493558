import _ from 'lodash';
import React, { useContext, useEffect, useState } from 'react';

// Mobile Shared Components
import DealActorsAddMemberButton from './DealActorsAddMemberButton';
import DealActorsLastActivity from './DealActorsLastActivity';
import DealActorsList from './DealActorsList';
import DealActorsRemoveTenantButton from './DealActorsRemoveTenantButton';
import DealActorsWaitingApproval from './DealActorsWaitingApproval';
import DealActorsWindows from './DealActorsWindows';
import TenantPendingMembersList from './TenantPendingMembersList';
import { ReactComponent as Down } from '../../../../../../assets/images/chevron/down/black.svg';
import { ReactComponent as Up } from '../../../../../../assets/images/chevron/up/black.svg';
import {
    AgreedBy,
    DealActorFragment,
    DealApprovalType,
    DealDetailsFragment,
    DealRoundType,
    DealState,
    DealUserParty,
    useGetUserOrganisationQuery,
    useGetUserProfileQuery,
    User
} from '../../../../../../generated/graphql';
import { Button } from '../../../../../../shared/button';
import useMobileScreen from '../../../../../../shared/hooks/useMobileScreen';
import DealHeaderContext from '../../../../../../v2/pages/Deals/DealHeader/DealHeaderContext';

type DealActorsProps = {
    approval?: DealApprovalType | null;
    dealID: string;
    deal?: DealDetailsFragment;
    organisationID?: string;
    organisationName: string;
    type: 'Landlord' | 'Tenant';
    currentUserParty: DealUserParty;
    state?: DealState;
    users: DealActorFragment[];
    tenantPendingMembers?: string[];
    dealRound?: DealRoundType | null;
    showTenantPendingMembersWindow?: boolean;
    setShowTenantPendingMembersWindow?: React.Dispatch<React.SetStateAction<boolean>>;
    setShowingTenantPending?: React.Dispatch<React.SetStateAction<boolean>>;
    agreedBy?: AgreedBy | null;
};

const DealActors = ({
    approval,
    dealID,
    deal,
    dealRound,
    organisationID,
    organisationName,
    type,
    currentUserParty,
    state,
    users,
    tenantPendingMembers,
    showTenantPendingMembersWindow,
    setShowTenantPendingMembersWindow,
    setShowingTenantPending,
    agreedBy
}: DealActorsProps) => {
    const { data: typeData } = useGetUserProfileQuery();
    const { data } = useGetUserOrganisationQuery();
    const [isOpen, setOpen] = useState<boolean>(false);
    const [showAddUserWindow, setShowAddUserWindow] = useState<boolean>(false);
    const [showRemoveUserWindow, setShowRemoveUserWindow] = useState<boolean>(false);
    const [user, setUser] = useState<User | null>(null);
    const [showEmailUserWindow, setShowEmailUserWindow] = useState<boolean>(false);
    const [userEmail, setUserEmail] = useState<string | null>(null);
    const [showRemoveTenantWindow, setShowRemoveTenantWindow] = useState<boolean>(false);
    const [landlordArray, setLandlordArray] = useState<DealActorFragment[]>([]);
    const [tenantArray, setTenantArray] = useState<DealActorFragment[]>([]);
    const [agentArray, setAgentArray] = useState<DealActorFragment[]>([]);

    const { completedDeal } = useContext(DealHeaderContext);

    useEffect(() => {
        if (users) {
            const unique = [...new Set(users.map((user) => user._id))];
            const uniqueUsers = unique.map((id) => users.find((user) => user._id === id));

            const landlords = uniqueUsers.filter((user) => user?.type === 'LANDLORD' || user?.type === 'ADMIN');
            setLandlordArray(landlords as DealActorFragment[]);
            const tenant = uniqueUsers.filter((user) => user?.type === 'TENANT');
            setTenantArray(tenant as DealActorFragment[]);
            const agent = uniqueUsers.filter((user) => user?.type === 'AGENT');
            setAgentArray(agent as DealActorFragment[]);
        }
    }, [users]);

    const mobileScreen = useMobileScreen();

    const agentsIds = agentArray.map((user) => user._id);
    const membersIds = data?.user.organisation.members.map((member) => member._id);
    const sameOrganisation = _.intersection(membersIds, agentsIds);
    const differentOrganisation = _.difference(agentsIds, membersIds!);
    const agentsInSameOrganisation = agentArray.filter((user) => sameOrganisation.find((id) => user._id === id));
    const agentsNotInSameOrganisation = agentArray.filter((user) =>
        differentOrganisation.find((id) => user._id === id)
    );

    return (
        <>
            <DealActorsWindows
                dealID={dealID}
                deal={deal}
                organisationID={organisationID}
                userID={user?._id ?? ''}
                activated={!!user?.activated}
                type={type}
                userEmail={userEmail}
                currentUserParty={currentUserParty}
                tenantPendingMembers={tenantPendingMembers ?? []}
                setUserEmail={setUserEmail}
                showAddUserWindow={showAddUserWindow}
                setShowAddUserWindow={setShowAddUserWindow}
                showRemoveUserWindow={showRemoveUserWindow}
                setShowRemoveUserWindow={setShowRemoveUserWindow}
                showEmailUserWindow={showEmailUserWindow}
                setShowEmailUserWindow={setShowEmailUserWindow}
                showRemoveTenantWindow={showRemoveTenantWindow}
                setShowRemoveTenantWindow={setShowRemoveTenantWindow}
                showTenantPendingMembersWindow={showTenantPendingMembersWindow}
                setShowTenantPendingMembersWindow={setShowTenantPendingMembersWindow}
                setShowingTenantPending={setShowingTenantPending}
                agentArray={agentArray}
            />
            <div className="relative">
                {/* Trigger */}
                <Button
                    onClick={() => setOpen((isOpen) => !isOpen)}
                    background={isOpen ? 'navy-light' : 'navy-lightest'}
                    className={`px-2 mr-2 h-12 border truncate ${
                        state?.toString().toLowerCase() === type.toLowerCase() && 'border-newTeal-main'
                    }`}
                >
                    {/* Party */}
                    <div className="flex flex-col items-start text-sm mr-4">
                        <span className="font-semibold">{type}</span>
                        <span
                            className="font-light"
                            style={{ fontSize: '15px' }} // text-base class would make it too big
                        >
                            {organisationName}
                        </span>
                    </div>

                    {approval && (
                        <div className="border-l-2 border-r-2 border-white px-3">
                            <DealActorsWaitingApproval state={state} approval={approval} />
                        </div>
                    )}
                    {(state?.toString().toLowerCase() === type.toLowerCase() ||
                        (agreedBy && agreedBy.valueOf() !== type)) && (
                        <div className="border-l-2 border-r-2 border-white px-3">
                            <DealActorsLastActivity
                                state={state}
                                type={type}
                                dealRound={dealRound}
                                agreedBy={agreedBy}
                            />
                        </div>
                    )}
                    {/* Icon */}
                    {isOpen ? <Up className="w-6 h-6 ml-2" /> : <Down className="w-6 h-6 ml-2" />}
                </Button>
                {isOpen && (
                    <>
                        {/* Backdrop */}
                        <div
                            className="fixed w-full h-full z-10 left-0 top-0"
                            onClick={() => {
                                setOpen(false);
                            }}
                        ></div>
                        {/* Window */}
                        <div
                            className="absolute bg-white border  border-white md:border-newTeal-main shadow-lg md:rounded z-20 left-0  md:mr-2 mr-40 md:mt-1 mt-5 flex flex-col text-sm max-h-96 overflow-hidden overflow-y-auto"
                            style={{
                                width: mobileScreen ? 'calc(100vw - 14px)' : '350px'
                            }}
                        >
                            {landlordArray && type === 'Landlord' && (
                                <>
                                    <DealActorsList
                                        uniqueUsers={landlordArray}
                                        currentParty={currentUserParty}
                                        type={type}
                                        setShowRemoveUserWindow={setShowRemoveUserWindow}
                                        setUser={setUser}
                                        setShowEmailUserWindow={setShowEmailUserWindow}
                                        typeOfArray={'LandlordArray'}
                                    />
                                    {typeData?.user.type === 'AGENT' && currentUserParty === 'LANDLORD' ? (
                                        <>
                                            {!_.isEmpty(agentsInSameOrganisation) && (
                                                <DealActorsList
                                                    uniqueUsers={agentsInSameOrganisation}
                                                    currentParty={currentUserParty}
                                                    type={type}
                                                    setShowRemoveUserWindow={setShowRemoveUserWindow}
                                                    setUser={setUser}
                                                    setShowEmailUserWindow={setShowEmailUserWindow}
                                                    typeOfArray={'AgentsInSameOrganisation'}
                                                />
                                            )}
                                            {!_.isEmpty(agentsNotInSameOrganisation) && (
                                                <DealActorsList
                                                    uniqueUsers={agentsNotInSameOrganisation}
                                                    currentParty={currentUserParty}
                                                    type={type}
                                                    setShowRemoveUserWindow={setShowRemoveUserWindow}
                                                    setUser={setUser}
                                                    setShowEmailUserWindow={setShowEmailUserWindow}
                                                    typeOfArray={'AgentsNotInSameOrganisation'}
                                                />
                                            )}
                                        </>
                                    ) : (
                                        !_.isEmpty(agentArray) && (
                                            <DealActorsList
                                                uniqueUsers={agentArray}
                                                currentParty={currentUserParty}
                                                type={type}
                                                setShowRemoveUserWindow={setShowRemoveUserWindow}
                                                setUser={setUser}
                                                setShowEmailUserWindow={setShowEmailUserWindow}
                                                typeOfArray={'AgentArray'}
                                            />
                                        )
                                    )}
                                </>
                            )}
                            {tenantArray && type === 'Tenant' && (
                                <>
                                    <DealActorsList
                                        uniqueUsers={tenantArray}
                                        currentParty={currentUserParty}
                                        type={type}
                                        setShowRemoveUserWindow={setShowRemoveUserWindow}
                                        setUser={setUser}
                                        setShowEmailUserWindow={setShowEmailUserWindow}
                                        typeOfArray={'TenantArray'}
                                    />
                                    {typeData?.user.type === 'AGENT' && currentUserParty === 'TENANT' ? (
                                        <>
                                            {!_.isEmpty(agentsInSameOrganisation) && (
                                                <DealActorsList
                                                    uniqueUsers={agentsInSameOrganisation}
                                                    currentParty={currentUserParty}
                                                    type={type}
                                                    setShowRemoveUserWindow={setShowRemoveUserWindow}
                                                    setUser={setUser}
                                                    setShowEmailUserWindow={setShowEmailUserWindow}
                                                    typeOfArray={'AgentsInSameOrganisation'}
                                                />
                                            )}
                                            {!_.isEmpty(agentsNotInSameOrganisation) && (
                                                <DealActorsList
                                                    uniqueUsers={agentsNotInSameOrganisation}
                                                    currentParty={currentUserParty}
                                                    type={type}
                                                    setShowRemoveUserWindow={setShowRemoveUserWindow}
                                                    setUser={setUser}
                                                    setShowEmailUserWindow={setShowEmailUserWindow}
                                                    typeOfArray={'AgentsNotInSameOrganisation'}
                                                />
                                            )}
                                        </>
                                    ) : (
                                        !_.isEmpty(agentArray) && (
                                            <DealActorsList
                                                uniqueUsers={agentArray}
                                                currentParty={currentUserParty}
                                                type={type}
                                                setShowRemoveUserWindow={setShowRemoveUserWindow}
                                                setUser={setUser}
                                                setShowEmailUserWindow={setShowEmailUserWindow}
                                                typeOfArray={'AgentArray'}
                                            />
                                        )
                                    )}
                                </>
                            )}

                            {tenantPendingMembers &&
                                tenantPendingMembers.length > 0 &&
                                currentUserParty === DealUserParty.Tenant && (
                                    <TenantPendingMembersList
                                        emails={tenantPendingMembers}
                                        setUserEmail={setUserEmail}
                                        setShowAddUserWindow={setShowAddUserWindow}
                                    />
                                )}
                            {!completedDeal && (
                                <DealActorsAddMemberButton
                                    type={type}
                                    currentParty={currentUserParty}
                                    setShowAddUserWindow={setShowAddUserWindow}
                                />
                            )}
                            {!completedDeal && (
                                <DealActorsRemoveTenantButton
                                    tenantID={organisationID}
                                    type={type}
                                    currentParty={currentUserParty}
                                    setShowRemoveTenantWindow={setShowRemoveTenantWindow}
                                />
                            )}
                        </div>
                    </>
                )}
            </div>
        </>
    );
};

export default DealActors;
