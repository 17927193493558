import React, { useEffect, useState } from 'react';

type ReadinessBarProps = {
    rating?: number;
    total?: number;
    rounded?: boolean;
    background?: string | null;
    className?: string;
};

const ReadinessBar = ({ rating, total, rounded, background, className }: ReadinessBarProps) => {
    const [ratingColor, setRatingColor] = useState<string>('bg-grey-400');

    useEffect(() => {
        const percentageRating = ((rating || 0) / (total || 100)) * 100;
        if (percentageRating === 100) {
            setRatingColor('bg-green-main');
        } else if (percentageRating >= 90) {
            setRatingColor('bg-green-main');
        } else if (percentageRating >= 80) {
            setRatingColor('bg-green-main');
        } else if (percentageRating >= 70) {
            setRatingColor('bg-yellow-main');
        } else if (percentageRating >= 50) {
            setRatingColor('bg-yellow-main');
        } else if (percentageRating >= 30) {
            setRatingColor('bg-yellow-main');
        } else if (percentageRating >= 10) {
            setRatingColor('bg-newRed-main');
        } else {
            setRatingColor('bg-newRed-main');
        }
    }, [rating, total]);

    return (
        <div
            className={`w-full h-4 bg-${background ? background : 'navy-light'} relative overflow-hidden${
                rounded ? ' rounded' : ''
            } ${className}`}
        >
            <span
                className={`${
                    ratingColor ?? 'bg-newRed-main'
                } absolute h-full top-0 left-0 transition-all duration-200`}
                style={{
                    width: `${((rating !== undefined ? rating : 0) / (total !== undefined ? total : 100)) * 100}%`
                }}
            ></span>
            <div className="absolute bottom-0 w-1/5">
                <div className="bg-white h-3 ml-auto" style={{ width: '2.5px' }}></div>
            </div>
            <div className="absolute bottom-0 w-2/5">
                <div className="bg-white h-3 ml-auto" style={{ width: '2.5px' }}></div>
            </div>
            <div className="absolute bottom-0 w-3/5">
                <div className="bg-white h-3 ml-auto" style={{ width: '2.5px' }}></div>
            </div>
            <div className="absolute bottom-0 w-4/5">
                <div className="bg-white h-3 ml-auto" style={{ width: '2.5px' }}></div>
            </div>
        </div>
    );
};

export default ReadinessBar;
