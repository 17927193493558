import React, { useState } from 'react';

import AddUsersInput from './AddUsersInput';
import { ReactComponent as Cross } from '../../../assets/images/cross/currentColor.svg';
import { ReactComponent as Envelope } from '../../../assets/images/envelope/black.svg';
import { ReactComponent as Phone } from '../../../assets/images/phone/black.svg';
import { ReactComponent as Plus } from '../../../assets/images/plus/black.svg';
import { Organisation, useGetUserTypeQuery, User } from '../../../generated/graphql';
import Window from '../../../shared/Window';
import { isAgent } from '../../../v2/common/helpers';

type LandlordTeamWindowProps = {
    landlordTeam?: User[];
    organisation?: Organisation;
    setShowTeamList: React.Dispatch<React.SetStateAction<boolean>>;
};

const LandlordTeamWindow = ({ landlordTeam, organisation, setShowTeamList }: LandlordTeamWindowProps) => {
    const { data: typeData } = useGetUserTypeQuery();
    const isAgentUser = isAgent(typeData?.user.type);

    const [showAddUser, setShowAddUser] = useState<boolean>(false);

    return (
        <Window
            scrollContent={true}
            header={
                <div className="flex p-2 ml-9 mr-2 mt-3 mb-2 place-items-center justify-between">
                    <div className="flex flex-row items-center">
                        <p className="text-xl font-semibold border-b-3 border-newTeal-main mr-4">Landlord Team</p>
                        {!isAgentUser && (
                            <div
                                className={`cursor-pointer text-xxs flex flex-row bg-navy-light hover:bg-newGray-main px-3 py-2 items-center rounded ${
                                    showAddUser && 'hidden'
                                }`}
                                onClick={() => setShowAddUser(true)}
                            >
                                <Plus className="w-3 h-3 mr-2" />
                                Add Colleague
                            </div>
                        )}
                        {showAddUser && <AddUsersInput orgID={organisation?._id} setShowAddUser={setShowAddUser} />}
                    </div>
                    <Cross
                        className="w-5 h-5 -mt-4"
                        onClick={() => {
                            setShowTeamList(false);
                        }}
                    />
                </div>
            }
            width="w-1/3"
            setShow={setShowTeamList}
        >
            <div className="flex items-center justify-center flex-col gap-2 mb-9">
                {landlordTeam?.map((member) => (
                    <div className="bg-white w-10/12 justify-center border border-newGray-main rounded-xl h-16 flex items-center">
                        <div className="flex flex-col items-center justify-center h-12 w-1/2 truncate ">
                            <p className="ml-3 mr-3 text-sm font-semibold ">
                                {member.firstName && member.lastName
                                    ? member.firstName + ' ' + member.lastName
                                    : 'Invitation sent'}
                            </p>
                            {organisation && <p className="text-xxs text-newGray-darkish">{organisation.name}</p>}
                        </div>
                        <div className="border-r-2 border-navy-light h-5 -ml-2" />
                        <div className=" text-newGray-darkish flex justify-center w-1/2">
                            <div className="flex flex-col justify-center w-11/12 -mr-3">
                                {member.mobile && (
                                    <p className="text-xs w-10/12">
                                        {' '}
                                        <Phone stroke="#a9a9a9" className="w-3 h-3 mr-2 text-sm inline -mt-1 " />
                                        {member.mobile}
                                    </p>
                                )}
                                <div className="flex flex-row items-center truncate mb-1 w-11/12">
                                    <Envelope stroke="#a9a9a9" className="w-3 h-3 mr-2 text-sm" />
                                    <p
                                        className="text-xs overflow-x-hidden"
                                        style={{
                                            textOverflow: 'ellipsis'
                                        }}
                                    >
                                        {member.email}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </Window>
    );
};

export default LandlordTeamWindow;
