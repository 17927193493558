import { format } from 'date-fns';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import PreviewContent, { DealUnit } from './PreviewContent';
import { ReactComponent as Clock } from '../../../../assets/images/clock/black.svg';
import {
    DealDetailsFragment,
    DealSendType,
    DealState,
    DealUserParty,
    GetDealDetailsQuery,
    useGetDealDetailsNoHistoryQuery,
    useGetLayoutUserInfoQuery
} from '../../../../generated/graphql';
import Sidebar from '../../../../layout/SideBar';
import { useUpdateUrl } from '../../../../shared/Filter/helpers';
import { getUserFullName } from '../../../../shared/helpers';
import useMobileScreen from '../../../../shared/hooks/useMobileScreen';
import Loading from '../../../../shared/Loading';
import { useUserType } from '../../../../v2/common/helpers';
import CreatedWindow from '../../../../v2/common/MobileWindow/CreatedWindow';
import { LandlordFilesContext } from '../../../../v2/components/LandlordsFileModal/LandlordFilesContext';
import { LandlordsFileModal } from '../../../../v2/components/LandlordsFileModal/LandlordsFileModal';
import LandlordSidebar from '../../../../v2/components/Sidebar/Deal/Landlord';
import TenantSidebar from '../../../../v2/components/Sidebar/Deal/Tenant';
import SidebarWrapper from '../../../../v2/components/Sidebar/SidebarWrapper';
import DealHeader, { DealHeaderPage } from '../../../../v2/pages/Deals/DealHeader';
import DownloadDataWindow from '../../../../v2/pages/Deals/DownloadData/DownloadDataWIndow';
import { DealContext } from '../context';
import OldDealHeader from '../Header';
import useSortDeal from '../hooks/useSortDeal';
import CoverNote from '../Negotiate/CoverNote/index';
import DealCompleteWindow from '../Negotiate/DealCompleteWindow';

type PreviewDealProps = {
    setHeaderContent: React.Dispatch<React.SetStateAction<React.ReactChild | null>>;
};

const PreviewDeal = ({ setHeaderContent }: PreviewDealProps) => {
    const history = useHistory();

    const { isDownloadOpen, downloadClose } = useContext(DealContext);

    const { showLandlordFilesWindow, setShowLandlordFilesWindow } = useContext(LandlordFilesContext);

    const { id, token } = useParams<{ id: string; token: string }>();
    const { loading: detailsLoading, data: detailsData } = useGetDealDetailsNoHistoryQuery({
        variables: {
            dealID: id,
            token
        }
    });
    const isMobile = useMobileScreen();

    const [deal, setDeal] = useState<DealDetailsFragment>();
    const sortDeal = useSortDeal();
    const [showCreatedWindow, setShowCreatedWindow] = useState<boolean>(false);

    const [visible, setVisible] = useState<boolean>(true);
    const { data: userData, loading: userLoading } = useGetLayoutUserInfoQuery({
        fetchPolicy: 'network-only'
    });

    const [completedDeal, setCompletedDeal] = useState({
        complete: false,
        type: ''
    });

    const { isLandlordAgent, isTenantSide, isOther } = useUserType(
        userData?.user.type,
        detailsData?.deal.currentUserParty
    );

    const isComplete = detailsData?.deal.state === DealState.Complete;

    const { addToUrl } = useUpdateUrl();
    const isAuthenticated = !userLoading && !!userData;

    const isSeenAgreement = isTenantSide
        ? !detailsData?.deal.agreementSeenTenant
        : !detailsData?.deal.agreementSeenLandlord;

    useEffect(() => {
        if (!isAuthenticated || isLandlordAgent || (isMobile && completedDeal))
            setHeaderContent(
                <div className="flex justify-between w-full items-center">
                    <div className="flex flex-col ml-2">
                        <h1 className="font-semibold text-xl">
                            {completedDeal.complete ? 'HoTs Agreed' : 'Deal Preview'}
                        </h1>
                        {detailsData?.deal.unit?.name && (
                            <p className={` sm:text-xs md:text-sm  -mt-1`}>
                                {detailsData.deal.unit?.organisation.name}
                            </p>
                        )}
                        {detailsData?.deal.template?.name && !isTenantSide && (
                            <p className="hidden md:flex text-sm -mt-1 truncate z-20">
                                {detailsData.deal.template?.name}
                            </p>
                        )}
                    </div>
                    <div className="-mt-1 z-10 flex flex-col-reverse  justify-center items-end">
                        <Clock
                            className="w-6 h-6 mt-2 mr-4 xs:flex md:hidden"
                            onClick={() => setShowCreatedWindow(true)}
                        />
                        <p className={`text-sm md:flex xs:hidden`}>
                            {(detailsData?.deal.created ? 'by ' : 'Created by') +
                                getUserFullName(detailsData?.deal.creator)}
                        </p>
                        <p className={`text-sm md:flex xs:hidden`}>
                            {detailsData?.deal.created &&
                                'Created on ' + format(new Date(detailsData.deal.created), 'dd MMM yyyy, h:mm a')}
                        </p>
                    </div>
                </div>
            );
    }, [setHeaderContent, detailsData, isMobile, isAuthenticated]);

    useEffect(() => {
        if (detailsData) {
            if (detailsData.deal.clauseSummary.incomplete === 0) {
                const alreadyActionedComplete: any = history.location.state;
                if (alreadyActionedComplete) {
                    setCompletedDeal(alreadyActionedComplete[0]);
                } else {
                    setCompletedDeal({ complete: true, type: 'auto' });
                }
            }
            const tenant = isTenantSide ? { tenant: isTenantSide } : {};
            const complete = isComplete ? { status: detailsData?.deal.state } : {};
            addToUrl({ ...complete, ...tenant });
        }
        // Sort the deal by it's clauses
        const sortedDeal = sortDeal(detailsData as GetDealDetailsQuery);
        sortedDeal && setDeal(sortedDeal);
        // eslint-disable-next-line
    }, [detailsData, history]);

    // Close sidebar on mobile
    useEffect(() => {
        const handleResize = () => {
            window.innerWidth < 800 ? setVisible(false) : setVisible(true);
        };
        window.addEventListener('resize', handleResize);
    });
    // Close sidebar when page first loads on mobile
    useEffect(() => (window.innerWidth < 800 ? setVisible(false) : setVisible(true)), []);

    const getSidebar = () => {
        // if it's user outside platform, use old sidebar
        if (!isAuthenticated) return <Sidebar visible={visible} setVisible={setVisible} />;

        return isTenantSide ? <TenantSidebar /> : <LandlordSidebar />;
    };

    if (detailsLoading) return <Loading />;

    return (
        <SidebarWrapper
            sidebar={getSidebar()}
            show={visible}
            scrollable={isAuthenticated}
            className={'overflow-y-hidden'}
            wrap={!isTenantSide}
        >
            {showCreatedWindow && detailsData && (
                <CreatedWindow
                    setShowCreatedWindow={setShowCreatedWindow}
                    date={format(new Date(detailsData.deal.created), 'dd MMM yyyy, h:mm a')}
                    creator={getUserFullName(detailsData.deal.creator)}
                />
            )}
            <main className="least-app-main min-h-screen overscroll-none flex-col" id="content">
                <div className={`bg-navy-light ${!isAuthenticated ? 'overflow-y-auto preview-content-height' : ''}`}>
                    {/* HEADER */}
                    {!isOther && !isMobile ? (
                        <div className="top-0 sticky z-40">
                            <DealHeader
                                deal={detailsData?.deal ?? null}
                                setShowAddClauseWindow={() => {}}
                                isClauseReordering={false}
                                setClauseReordering={() => {}}
                                page={DealHeaderPage.PREVIEW}
                                completedDeal={completedDeal.complete}
                            >
                                {detailsData as GetDealDetailsQuery}
                            </DealHeader>
                        </div>
                    ) : (
                        isMobile && (
                            <OldDealHeader
                                deal={detailsData?.deal ?? null}
                                setShowAddClauseWindow={() => {}}
                                isClauseReordering={false}
                                setClauseReordering={() => {}}
                                page={DealHeaderPage.PREVIEW}
                                completedDeal={completedDeal.complete}
                                setHeaderContent={setHeaderContent}
                            >
                                {detailsData as GetDealDetailsQuery}
                            </OldDealHeader>
                        )
                    )}
                    {/* Preview Content */}
                    <div className={isTenantSide ? 'transition-all duration-300' : 'mt-0'}>
                        <PreviewContent deal={deal} unit={detailsData?.deal.unit as DealUnit} />
                    </div>
                </div>
            </main>
            {isSeenAgreement && userData && isComplete && (
                <DealCompleteWindow
                    userType={userData?.user.__typename === 'User' ? userData?.user.type : null}
                    dealComplete={completedDeal}
                    deal={detailsData as GetDealDetailsQuery}
                />
            )}
            {showLandlordFilesWindow && (
                <LandlordsFileModal
                    setShow={setShowLandlordFilesWindow}
                    dealId={id}
                    organisationName={detailsData?.deal.unit?.organisation.name}
                />
            )}
            {deal && deal?.state !== 'EDIT' && (
                <CoverNote id={deal?._id ?? ''} currentUserParty={deal?.currentUserParty} visible />
            )}
            {!isOther && (
                <DownloadDataWindow
                    isOpen={isDownloadOpen}
                    close={downloadClose}
                    currentUserParty={
                        detailsData?.deal.currentUserParty ? detailsData?.deal.currentUserParty : DealUserParty.Tenant
                    }
                    type={
                        deal?.currentUserParty === DealUserParty.Landlord ? DealSendType.Other : DealSendType.Landlord
                    }
                />
            )}
        </SidebarWrapper>
    );
};

export default PreviewDeal;
