import React, { useContext } from 'react';

import ClauseContext from './Context';
import { ReactComponent as Check } from '../../../../../assets/images/check/black.svg';
import { ReactComponent as Comment } from '../../../../../assets/images/comment/black.svg';
import { ReactComponent as Cross } from '../../../../../assets/images/cross/black.svg';
import { ReactComponent as Remove } from '../../../../../assets/images/delete/black.svg';
import { ReactComponent as Edit } from '../../../../../assets/images/hash/black.svg';
import { ReactComponent as Question } from '../../../../../assets/images/help/black.svg';
import { ReactComponent as Swap } from '../../../../../assets/images/swap/black.svg';
import {
    ApproveClauseMutation,
    CommentType,
    DealUserParty,
    HistoryEventType,
    useApproveClauseMutation
} from '../../../../../generated/graphql';
import { handleFetchError } from '../../../../../shared/helpers';
import useMobileScreen from '../../../../../shared/hooks/useMobileScreen';
import { DealType } from '../../../../../shared/Negotiator/ConfirmClauseDeleteWindow.tsx';
import useDeleteClause from '../../../../../shared/Negotiator/ConfirmClauseDeleteWindow.tsx/useDeleteClause';
import ToolBarContext from '../ToolBar/Context';
import { ClauseType } from '../Types';

type ClauseActionProps = {
    noControls?: boolean;
};

const ClauseAction = ({ noControls }: ClauseActionProps) => {
    const {
        id,
        dealID,
        parentID,
        sectionID,
        groupID,
        value,
        valueSuffix,
        comments,
        history,
        hidden,
        dealBreaker,
        setShowDealBreakerReasonWindow,
        currentUserParty,
        setType,
        setEditing,
        setSwapping,
        tags,
        setDeleting,
        isRejectOpen,
        setRejectOpen,
        setManualComplete,
        setShowCantFind,
        setResponding,
        refetch
    } = useContext(ClauseContext);
    const { setCursor } = useContext(ToolBarContext);

    const mobileScreen = useMobileScreen();

    // const [isApproveOpen, setApproveOpen] = useState<boolean>(false)

    const [approveClause, { error: approveClauseError }] = useApproveClauseMutation();

    const updateCache = useDeleteClause(DealType.DEAL);

    const handleApprove = async () => {
        setType && setType(ClauseType.APPROVED);
        setCursor(id!);
        const { data } = await approveClause({
            variables: {
                clauseID: id!,
                parentID: parentID!,
                dealID: dealID!
            },
            // optimisticResponse: {
            //     dealClauseUpdate: {
            //         __typename: "Clause",
            //         _id: clauseID,
            //         approved: true
            //     }
            // },
            update(store, { data }) {
                if (!data || !hidden) return;

                updateCache<ApproveClauseMutation>(store, id!, dealID);

                // Update group
                store.modify({
                    id: store.identify({
                        __typename: 'Group',
                        _id: groupID
                    }),
                    fields: {
                        clauseSummary(currentSummary = {}) {
                            return {
                                complete: currentSummary.complete + 1,
                                incomplete: currentSummary.incomplete - 1
                            };
                        }
                    }
                });
                // Update section
                store.modify({
                    id: store.identify({
                        __typename: 'Section',
                        _id: sectionID
                    }),
                    fields: {
                        clauseSummary(currentSummary = {}) {
                            return {
                                complete: currentSummary.complete + 1,
                                incomplete: currentSummary.incomplete - 1
                            };
                        }
                    }
                });
                // Update deal total
                store.modify({
                    id: store.identify({
                        __typename: 'Deal',
                        _id: dealID
                    }),
                    fields: {
                        clauseSummary(currentSummary = {}) {
                            return {
                                complete: currentSummary.complete + 1,
                                incomplete: currentSummary.incomplete - 1
                            };
                        }
                    }
                });
            }
        });

        setManualComplete && setManualComplete(true);
        refetch && refetch();

        handleFetchError('Failed to approve the clause', approveClauseError, data?.dealClauseUpdate);
    };

    const handleDealBreakerCommentButton = () => {
        if (isEditRequested && !isEditRequestUs) {
            return setResponding!(true);
        }
        setShowCantFind!(true);
    };

    const lastComment = !!comments && comments.length > 0 && comments[comments.length - 1];

    const lastHistory = !!history && history.length > 0 && history[history.length - 1];

    // Is the most recent comment an edit/remove request?
    const isEditRequested =
        lastComment &&
        (lastComment.type === CommentType.Removal || lastComment.type === CommentType.Edit) &&
        lastHistory &&
        lastHistory.type === HistoryEventType.Comment;

    // Did we add the last comment?
    const isEditRequestUs = isEditRequested && lastHistory && lastHistory.party === currentUserParty;

    // Disable the approve button if value is a wildcard, or an edit/removal is requested and the user is not tenant
    const approveDisabled =
        (value === '*' && currentUserParty === DealUserParty.Tenant) ||
        (isEditRequested && currentUserParty !== DealUserParty.Tenant) ||
        isRejectOpen;

    // Disable the reject button if edit requested
    const rejectDisabled = isEditRequested && history![history!.length - 1].party === currentUserParty;

    // If both buttons are disabled, we don't need to show anything
    if (approveDisabled && rejectDisabled) return null;

    return (
        <>
            {/* Clause Actions */}
            <div className={`flex items-center relative pr-3 ${!isRejectOpen && 'mr-2'}`} style={{ height: '50px' }}>
                {!noControls && (
                    <div className="flex flex-row">
                        <>
                            {(!isRejectOpen || mobileScreen) && (
                                <button
                                    disabled={approveDisabled}
                                    className={`focus:outline-none mr-4 ${
                                        approveDisabled && 'opacity-25 cursor-not-allowed'
                                    }`}
                                    onClick={handleApprove}
                                >
                                    <Check className="w-5 h-5 -mt-px" />
                                </button>
                            )}

                            {(!isRejectOpen || mobileScreen) && (
                                <button
                                    disabled={rejectDisabled}
                                    className={`py-2 focus:outline-none ${
                                        rejectDisabled && 'opacity-25 cursor-not-allowed'
                                    } ${mobileScreen && 'mr-2'}`}
                                    onClick={() => setRejectOpen && setRejectOpen(!isRejectOpen)}
                                >
                                    <Cross className="w-5 h-5" />
                                </button>
                            )}
                        </>
                    </div>
                )}

                {/* Open Dialog */}
                {isRejectOpen && (
                    <div
                        className={`flex flex-row max-w-full bg-white rounded overflow-hidden ${
                            !mobileScreen && '-ml-1 -mt-px'
                        } opacity-100`}
                    >
                        {dealBreaker?.isDealBreaker && currentUserParty === DealUserParty.Tenant ? (
                            <>
                                <span className="px-2 sm:w-28 lg-w-24  text-xs flex flex-col content-center items-center justify-center text-center p-1">
                                    Lock Clause
                                </span>
                                <button
                                    className="sm:w-28 lg-w-24  border-grey-100 text-xxs flex flex-col content-center items-center justify-center p-1 cursor-pointer hover:bg-gray-200 focus:outline-none"
                                    onClick={() =>
                                        setShowDealBreakerReasonWindow && setShowDealBreakerReasonWindow(true)
                                    }
                                >
                                    <Question className="w-4 h-4 mb-1" />
                                    Why
                                </button>
                                <div className="separator bg-navy-lightest"></div>
                                <button
                                    className="px-2 sm:w-28 lg-w-24 text-xxs flex flex-col content-center items-center justify-center p-1 cursor-pointer hover:bg-gray-200 focus:outline-none"
                                    onClick={handleDealBreakerCommentButton}
                                >
                                    <Comment className="w-4 h-4 mb-1" />
                                    Comment
                                </button>
                                {!mobileScreen && <div className="separator bg-navy-lightest"></div>}
                            </>
                        ) : (
                            <>
                                {tags?.indexOf('comment_clause') === -1 ? (
                                    <>
                                        {((valueSuffix && valueSuffix !== '') || (value && value !== undefined)) && (
                                            <>
                                                <button
                                                    className="px-1 font-semibold bg-navy-light text-xxs sm:w-20 lg:w-24 text-xxs flex flex-col content-center items-center justify-center p-1 cursor-pointer hover:bg-gray-200 focus:outline-none"
                                                    onClick={() => setEditing!(true)}
                                                >
                                                    <Edit className="h-4 w-4 " />
                                                    <p>Edit Value</p>
                                                </button>
                                                <div className="separator bg-navy-lightest"></div>
                                            </>
                                        )}
                                        <button
                                            className="px-2 sm:w-28 lg:w-24 font-semibold text-xxs flex flex-col content-center bg-navy-light items-center justify-center p-1 cursor-pointer hover:bg-gray-200 focus:outline-none"
                                            onClick={() => setSwapping!(true)}
                                        >
                                            <Swap className="h-4 w-4 " />

                                            <p>Edit Clause</p>
                                        </button>
                                        {currentUserParty === DealUserParty.Landlord && (
                                            <>
                                                <div className="separator bg-navy-lightest"></div>
                                                <button
                                                    className="px-2 bg-navy-light font-semibold text-xxs sm:w-20 text-xxs flex flex-col content-center items-center justify-center p-1 cursor-pointer hover:bg-gray-200 focus:outline-none"
                                                    onClick={() => setDeleting!(true)}
                                                >
                                                    <Remove className="h-4 w-4 " />
                                                    Remove
                                                </button>
                                                {!mobileScreen && <div className="separator bg-navy-lightest"></div>}
                                            </>
                                        )}
                                    </>
                                ) : (
                                    <button
                                        className="px-2 font-semibold bg-navy-light text-xxs sm:w-28 lg:w-24  text-xxs flex flex-col content-center items-center justify-center p-1 cursor-pointer hover:bg-gray-200 focus:outline-none"
                                        onClick={() => setShowCantFind!(true)}
                                    >
                                        <Comment className="w-4 h-4 mb-1" />
                                        Comment
                                    </button>
                                )}
                            </>
                        )}
                        {!mobileScreen && (
                            <button
                                className="flex flex-col content-center bg-navy-light w-12 border-l border-newGray-main items-center justify-center p-1 cursor-pointer hover:bg-gray-200 focus:outline-none"
                                onClick={() => setRejectOpen && setRejectOpen(!isRejectOpen)}
                            >
                                <Cross className="w-5 h-5" />
                            </button>
                        )}
                    </div>
                )}
            </div>
        </>
    );
};

export default ClauseAction;
