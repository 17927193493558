import React, { useEffect, useState } from 'react';

import EditHelpWindowDeal from './Negotiator/EditHelpWindow';
import useMoveGroup from './Negotiator/hooks/useMoveGroup';
import InfoWindow from './Negotiator/InfoWindow';
import { ReactComponent as Down } from '../assets/images/arrow/down/black.svg';
import { ReactComponent as Up } from '../assets/images/arrow/up/black.svg';
import { ReactComponent as Checkmark } from '../assets/images/check/black.svg';
import { ReactComponent as ArrowDown } from '../assets/images/chevron/down/black.svg';
import { ReactComponent as Edit } from '../assets/images/edit/black.svg';
import { ReactComponent as InfoIcon } from '../assets/images/info/black.svg';
import { DealDetailsFragment, useGetUserTypeQuery, UserType } from '../generated/graphql';
import ConfirmClauseHeadingDeleteWindow from '../pages/Settings/ClauseBank/ConfirmClauseHeadingDeleteWindow';
import EditHelpWindow from '../pages/Settings/ClauseBank/EditHelpWindow';
export type WindowProps = {
    title: string;
    fromSection?: string;
    organisationID?: string | undefined;
    fromGroup?: string;
    black?: boolean;
    isOpen?: boolean;
    description?: string | null;
    rics?: string | null;
    className?: string;
    smallHeight?: boolean;
    isCompleted?: boolean;
    children?: React.ReactNode;
    sectionID?: string;
    parentID?: string;
    id?: string;
    isReordering?: boolean;
    deal?: DealDetailsFragment | null;
    length?: number;
    index?: number;
};

const Accordian = ({
    title,
    children,
    description,
    fromGroup,
    organisationID,
    fromSection,
    black,
    isOpen,
    id,
    isReordering,
    deal,
    length,
    sectionID,
    index,
    parentID,
    rics,
    smallHeight,
    isCompleted,
    className
}: WindowProps) => {
    const [open, setOpen] = useState<boolean>(isOpen || false);
    const [showHelpWindow, setShowHelpWindow] = useState<boolean>(false);
    const [showConfirmDeleteHeadingWindow, setShowConfirmDeleteHeadingWindow] = useState<boolean>(false);
    const [showHelpWindowDeal, setShowHelpWindowDeal] = useState<boolean>(false);
    const [showInfoWindow, setShowInfoWindow] = useState<boolean>(false);
    const [before, setBefore] = useState<boolean | undefined>(undefined); //set state before forced
    const moveGroup = useMoveGroup(deal, id ?? '', sectionID, parentID);
    // const [completed, setCompleted] = useState<boolean>(isCompleted || false)

    // Get User Type
    const [userType, setUserType] = useState<String | null>(null);
    const { data: typeData, loading: typeLoading, error: typeError } = useGetUserTypeQuery();
    useEffect(() => {
        if (typeData?.user.__typename === 'User') {
            setUserType(typeData.user.type);
        }
    }, [typeData, typeError, typeLoading]);

    //mouseclick type didnt let me access local type
    const toggle = (e?: any) => {
        if (
            e.target.classList.contains('info-icon') ||
            e.target.parentElement.parentElement.classList.contains('info-icon') ||
            e.target.parentElement.classList.contains('edit-info') ||
            e.target.parentElement.parentElement.classList.contains('edit-info')
        )
            return;

        setOpen(!open);
    };

    useEffect(() => {
        setOpen(!!isOpen);
    }, [isOpen]);

    useEffect(() => {
        if (isReordering) {
            if (open) {
                setBefore(true);
            }
            setOpen(false);
        } else {
            if (before !== undefined) {
                setOpen(true);
                setBefore(undefined);
            }
        }
    }, [isReordering, before, open]);

    const isLastSection = length && index !== null && length - 1 !== index;

    return (
        <>
            {showInfoWindow && (
                <InfoWindow title={title} description={description} rics={rics} setShow={setShowInfoWindow} />
            )}
            {showHelpWindow && (
                <EditHelpWindow
                    rics={rics}
                    organisationID={organisationID ? organisationID : undefined}
                    description={description}
                    fromSection={fromSection}
                    fromGroup={fromGroup}
                    setShow={setShowHelpWindow}
                    setShowConfirmDelete={setShowConfirmDeleteHeadingWindow}
                    children={children}
                />
            )}
            {showConfirmDeleteHeadingWindow && (
                <ConfirmClauseHeadingDeleteWindow
                    setShow={setShowConfirmDeleteHeadingWindow}
                    sectionID={sectionID}
                    organisationID={organisationID}
                    fromSection={fromSection}
                    fromGroup={fromGroup}
                />
            )}

            {showHelpWindowDeal && (
                <EditHelpWindowDeal
                    rics={rics}
                    setShow={setShowHelpWindowDeal}
                    dealID={deal?._id}
                    groupID={id}
                    parentID={parentID ?? ''}
                    title={title}
                    description={description}
                />
            )}

            <div>
                <div
                    className={`w-full rounded flex flex-row justify-between items-center relative cursor-pointer transition-all duration-200 ${className}`}
                    onClick={(e) => {
                        if (!isReordering) {
                            toggle(e);
                        }
                    }}
                >
                    <div className="flex items-center">
                        <span className={`${isCompleted ? 'text-gray-600' : 'text-gray-800'}`}>{title}</span>
                        {fromSection && (
                            <button
                                className="p-2 cursor-pointer mr-px focus:outline-none edit-info"
                                onClick={() => {
                                    setShowHelpWindow(true);
                                }}
                            >
                                <Edit className="h-5 w-6" />
                            </button>
                        )}
                        {deal && deal.state === 'EDIT' && userType === UserType.Admin ? (
                            <button
                                className="p-2 cursor-pointer mr-px focus:outline-none edit-info"
                                onClick={() => {
                                    setShowHelpWindowDeal(true);
                                }}
                            >
                                <Edit className="h-5 w-6" />
                            </button>
                        ) : (
                            !fromSection &&
                            description && (
                                <InfoIcon
                                    className="w-6 h-6 cursor-pointer ml-2 info-icon"
                                    onClick={() => {
                                        setShowInfoWindow(true);
                                    }}
                                />
                            )
                        )}
                    </div>
                    <div className="flex items-center">
                        {isCompleted && (
                            <Checkmark className="w-9 h-9 text-newTeal-main mr-3 pr-3 border-r-2 border-navy-light" />
                        )}
                        {isReordering && index !== undefined && length !== undefined ? (
                            <div className="flex flex-row items-center content-center justify-center">
                                <button
                                    className={`cursor-pointer focus:outline-none mr-4 ${
                                        index === 0 && 'text-newGray-darkish cursor-not-allowed'
                                    }`}
                                    disabled={index === 0}
                                    onClick={() => moveGroup(index, index - 1)}
                                >
                                    <Up className="w-6 h-6" />
                                </button>

                                <button
                                    className={`cursor-pointer mr-px focus:outline-none ${
                                        length === index + 1 && 'text-newGray-darkish cursor-not-allowed'
                                    }`}
                                    disabled={length === index + 1}
                                    onClick={() => moveGroup(index, index + 1)}
                                >
                                    <Down className="w-6 h-6" />
                                </button>
                            </div>
                        ) : (
                            <></>
                        )}
                        {isReordering === undefined ||
                            (!isReordering && (
                                <ArrowDown
                                    className={`transition duration-200 ease-in-out chevron-custom transform ${
                                        open ? 'rotate-180' : ''
                                    }`}
                                />
                            ))}
                    </div>
                </div>
                <div
                    className={`accordian rounded ${!open ? 'overflow-hidden' : ''}`}
                    style={{
                        maxHeight: open && smallHeight ? '8000px' : open && !smallHeight ? `8000px` : '0px'
                    }}
                    id={id}
                >
                    {children}
                </div>
                {isLastSection && <div className={`w-full border-black border-t ${open && 'mt-4'}`} />}
            </div>
        </>
    );
};

export default Accordian;
