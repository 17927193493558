import { useApolloClient } from '@apollo/client';
import React, { useEffect, useState } from 'react';

import { useGetUserOrganisationQuery, useUpdateOrganisationMutation } from '../../generated/graphql';
import { Button } from '../../shared/button';
import { handleFetchError } from '../../shared/helpers';
import Loading from '../../shared/Loading';

const StraightToLease = () => {
    const client = useApolloClient();
    const { data, loading, error } = useGetUserOrganisationQuery();

    const [straightToLeaseStart, setStraightToLeaseStart] = useState('');
    const [straightToLeaseEnd, setStraightToLeaseEnd] = useState('');

    const [isUpdating, setIsUpdating] = useState(false);

    useEffect(() => {
        // Check for errors
        handleFetchError('Could not load your company details', error, data?.user);
        if (data?.user.__typename === 'User') {
            setStraightToLeaseStart(data.user.organisation.straightToLease?.start ?? '');
            setStraightToLeaseEnd(data.user.organisation.straightToLease?.end ?? '');
        }
    }, [data, error]);

    const [updateCompany, { error: updateCompanyError, loading: updateCompanyLoading }] =
        useUpdateOrganisationMutation();

    const handleSaveClick = async () => {
        if (!data || data.user.__typename !== 'User') return;

        const input = {
            straightToLease: {
                start: straightToLeaseStart,
                end: straightToLeaseEnd
            }
        };

        const { data: updateCompanyData } = await updateCompany({
            variables: {
                id: data.user.organisation._id,
                input
            }
        });

        client.cache.modify({
            id: client.cache.identify({
                __typename: 'Organisation',
                _id: data.user.organisation._id
            }),
            fields: {
                straightToLease() {
                    return input.straightToLease;
                }
            }
        });

        if (updateCompanyError || updateCompanyData?.organisationUpdate.__typename !== 'Organisation')
            return handleFetchError(
                'Failed to update your company details',
                updateCompanyError,
                updateCompanyData?.organisationUpdate
            );

        setIsUpdating(false);
    };

    if (loading || updateCompanyLoading) return <Loading />;

    if (error) return null;

    return (
        <div className="w-full lg:w-4/5 p-6">
            <h1 className="mb-4 font-semibold text-xl">Digital Lease Signing</h1>
            <form onSubmit={(e) => e.preventDefault()}>
                <div className="flex flex-col md:flex-no-wrap w-full gap-4">
                    <div className="w-full">
                        <label className="block text-newGray-darkish mb-2 font-medium" htmlFor="welcome">
                            Welcome Message
                        </label>
                        <textarea
                            name="welcome"
                            className="w-full rounded bg-gray-200 p-2 border border-transparent focus:outline-none"
                            rows={6}
                            autoFocus
                            placeholder="This message is delivered to the Tenant, at the start, explaining that you offer Digital Lease Signing."
                            onChange={(e) => {
                                setStraightToLeaseStart(e.target.value);
                                setIsUpdating(true);
                            }}
                            value={straightToLeaseStart}
                        ></textarea>
                        <label className="block text-newGray-darkish mb-2 font-medium mt-3" htmlFor="welcome">
                            Closing Message
                        </label>
                        <textarea
                            className="w-full rounded bg-gray-200 p-2 border border-transparent focus:outline-none"
                            rows={6}
                            autoFocus
                            placeholder="The message will inform the Tenant when they can expect to receive the legal docs and that they will be guided through the process."
                            onChange={(e) => {
                                setStraightToLeaseEnd(e.target.value);
                                setIsUpdating(true);
                            }}
                            value={straightToLeaseEnd}
                        ></textarea>
                    </div>
                </div>
                {/* Save Button*/}
                {isUpdating && (
                    <div className="flex justify-end w-full mt-2">
                        <div className="w-full md:w-auto">
                            <Button
                                background="newTeal-main"
                                text="white"
                                onClick={handleSaveClick}
                                className={`w-full md:px-8 font-semibold`}
                            >
                                Save Changes
                            </Button>
                        </div>
                    </div>
                )}
            </form>
        </div>
    );
};

export default StraightToLease;
