import { Reference, StoreObject, useApolloClient } from '@apollo/client';
import React, { useState } from 'react';

import {
    DocType,
    GetUnitRatingDocument,
    useDeleteDocumentMutation,
    useGetDealTenantDetailsLazyQuery,
    useGetUnitFilesTasksLazyQuery
} from '../../../generated/graphql';
import { handleFetchError } from '../../../shared/helpers';
import Loading from '../../../shared/Loading';
import SuccessIcon from '../../../shared/SuccessIcon';
import Window from '../../../shared/Window';

type ConfirmDeleteFileWindowProps = {
    setWindow: React.Dispatch<React.SetStateAction<boolean>>;
    fileId: string;
    unitId?: string;
    dealId?: string;
    type: DocType;
};

const ConfirmDeleteFileWindow = ({ setWindow, fileId, unitId, dealId, type }: ConfirmDeleteFileWindowProps) => {
    const client = useApolloClient();

    const [success, setSuccess] = useState<boolean>(false);

    // Delete document mutation
    const [deleteDocument, { error, loading }] = useDeleteDocumentMutation();

    // Refetch unit files and tasks in the background
    const [refetchUnit] = useGetUnitFilesTasksLazyQuery({
        variables: {
            unitID: unitId ?? ''
        },
        fetchPolicy: 'cache-and-network'
    });

    // Refetch deal details stages and files in the background
    const [refetchDeal] = useGetDealTenantDetailsLazyQuery({
        variables: {
            dealID: dealId ?? ''
        },
        fetchPolicy: 'cache-and-network'
    });

    const handleDeleteClick = async () => {
        const { data } = await deleteDocument({
            variables: {
                id: fileId,
                unitID: unitId ?? undefined,
                type
            },
            refetchQueries:
                type === 'UNIT'
                    ? [
                          {
                              query: GetUnitRatingDocument,
                              variables: {
                                  unitID: unitId
                              }
                          }
                      ]
                    : []
        });

        if (error) {
            handleFetchError('Could not delete file', error, data?.docDelete);
        } else {
            if (type === 'UNIT') {
                client.cache.modify({
                    id: client.cache.identify({
                        __typename: 'Unit',
                        _id: unitId
                    }),
                    fields: {
                        files(existingFiles = [], { readField }) {
                            return existingFiles.filter(
                                (fileRef: Reference | StoreObject | undefined) => fileId !== readField('_id', fileRef)
                            );
                        }
                    }
                });
                refetchUnit();
            } else if (type === 'DEAL') {
                client.cache.modify({
                    id: client.cache.identify({
                        __typename: 'Deal',
                        _id: dealId
                    }),
                    fields: {
                        details(details = {}, { readField }) {
                            return {
                                ...details,
                                three: {
                                    ...details.three,
                                    files: details.three?.files.filter(
                                        (fileRef: Reference | StoreObject | undefined) =>
                                            fileId !== readField('_id', fileRef)
                                    )
                                }
                            };
                        }
                    }
                });
                refetchDeal();
            }

            setSuccess(true);
            setTimeout(() => setWindow(false), 1250);
        }
    };

    if (loading)
        return (
            <Window title="Delete File" titleCenter width="w-2/5" setShow={setWindow} zIndex="60">
                <Loading />
            </Window>
        );

    if (success)
        return (
            <Window title="Delete File" titleCenter width="w-2/5" zIndex="60" setShow={setWindow}>
                <div className="w-full flex flex-col items-center justify-center py-10">
                    <SuccessIcon />
                    <h1 className="text-xl font-bold text-center mt-5">File Deleted</h1>
                </div>
            </Window>
        );

    return (
        <Window
            title="Delete File"
            titleCenter
            width="w-2/5"
            setShow={setWindow}
            zIndex="60"
            primaryBtn
            onPrimaryBtnClick={handleDeleteClick}
            secondaryBtn
            onSecondaryBtnClick={() => setWindow(false)}
        >
            <div className="flex flex-col items-center">
                <h2 className="text-center text-lg my-8 w-4/5">
                    Are you sure you want to <span className="font-semibold text-uppercase">delete</span> this file?
                    This process is irreversible.
                </h2>
            </div>
        </Window>
    );
};

export default ConfirmDeleteFileWindow;
