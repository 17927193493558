import { useApolloClient } from '@apollo/client';
import axios from 'axios';
import { useState } from 'react';

import { AvatarType, useUpdateOrganisationMutation, useUploadAvatarMutation } from '../../generated/graphql';
import { handleFetchError } from '../helpers';

export const useUploadCompanyLogo = () => {
    const client = useApolloClient();
    const [imageUrl, setImageUrl] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [mediaCreate, { error }] = useUploadAvatarMutation();
    const [updateCompany] = useUpdateOrganisationMutation();

    const handleFileUpload = async (file: any, id: string) => {
        try {
            const data = new FormData();
            data.append('file', file);

            const isImage = file.type === 'image/png' || file.type === 'image/jpeg' || file.type === 'image/gif';
            if (!isImage) return;

            const res = await mediaCreate({
                variables: {
                    type: AvatarType.Organisation,
                    filetype: file.type,
                    id
                }
            });

            if (error) {
                // handleFetchError("Something went wrong", error, res?.data)
                return;
            }

            const imageUrl: string = res.data?.avatarCreate.__typename === 'S3Payload' ? res.data.avatarCreate.url : '';
            const signedUrl: string =
                res.data?.avatarCreate.__typename === 'S3Payload' ? res.data.avatarCreate.signedUrl : '';

            const fileParts = file.name.split('.');
            const fileType = fileParts[1];

            setIsLoading(true);
            await axios.put(signedUrl, file, {
                headers: {
                    'Content-Type': fileType,
                    'Access-Control-Allow-Origin': '*'
                }
            });

            setImageUrl(imageUrl);

            // Update company URL
            if (imageUrl.length > 0)
                await updateCompany({
                    variables: {
                        id,
                        input: {
                            avatar: imageUrl
                        }
                    }
                });

            client.cache.modify({
                id: client.cache.identify({
                    __typename: 'Organisation',
                    _id: id
                }),
                fields: {
                    avatar() {
                        setIsLoading(false);
                        return imageUrl;
                    }
                }
            });
        } catch (err: any) {
            handleFetchError('Something went wrong', err);
        }
    };

    return { handleFileUpload, imageUrl, setImageUrl, isLoading };
};
