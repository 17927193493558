import { format } from 'date-fns';
import React from 'react';
import ReactTooltip from 'react-tooltip';

import { ReactComponent as Clock } from '../../../../assets/images/clock/black.svg';
import { SingleUnitFragment } from '../../../../generated/graphql';
import { getUserFullName } from '../../../../shared/helpers';
import useMobileScreen from '../../../../shared/hooks/useMobileScreen';

const useSetHeaderContent = (setHeaderContent: React.Dispatch<React.SetStateAction<React.ReactChild | null>>) => {
    const isMobile = useMobileScreen();

    return (unit: SingleUnitFragment) => {
        setHeaderContent(
            <div className="flex flex-row  h-15 w-full justify-between">
                <div className=" flex flex-row items-center">
                    {unit.avatar && (
                        <img src={unit.avatar} alt="Building" className="h-full w-15 hidden md:block rounded" />
                    )}
                    <div className="ml-2">
                        <h1 className="font-bold">{unit.buildingName}</h1>
                        <p className="font-light text-gray-600 text-sm">{unit.name}</p>
                    </div>
                </div>
                {!isMobile ? (
                    <div className="ml-2 z-10 flex flex-col-reverse items-end">
                        <p className="font-light text-sm">
                            {(unit.created ? 'by ' : 'Created by') + getUserFullName(unit.creator)}
                        </p>
                        {unit.created && (
                            <p className="font-light text-sm">
                                {'Created on ' + format(new Date(unit.created), 'dd MMM yyyy, h:mm a')}
                            </p>
                        )}
                    </div>
                ) : (
                    <>
                        <Clock
                            data-border-color={'white'}
                            data-tip={unit.created && format(new Date(unit.created), 'dd MMM yyyy, h:mm a')}
                            className="w-5 h-5 z-30 absolute top-6 right-5 text-sm border-none"
                            style={{ outline: 'none' }}
                        />
                        <ReactTooltip
                            backgroundColor="#484444"
                            textColor="white"
                            effect="solid"
                            place="bottom"
                            clickable={false}
                            borderColor="white"
                        />
                    </>
                )}
            </div>
        );
    };
};

export default useSetHeaderContent;
