import React, { useContext } from 'react';

import { WindowContext } from './WindowContext';
import useMobileScreen from '../hooks/useMobileScreen';

type WindowContentType = {
    backgroundColor?: string;
};

const WindowContent = ({ backgroundColor }: WindowContentType) => {
    const { children, height, scrollContent, contentClassName, contentMiddle, contentBackground, externalWindow } =
        useContext(WindowContext);

    const mobileScreen = useMobileScreen();

    return (
        <div
            id="window-content"
            className={`h-full transition-all duration-200 ${backgroundColor} ${
                !contentMiddle && 'md:pt-0 pt-12'
            } md:pt-0 md:pb-0 h-full-window-content ${height && !externalWindow && `md:${height}`} ${
                mobileScreen && 'overflow-auto'
            } ${scrollContent ? 'md:overflow-y-auto' : ''} ${contentMiddle && 'h-full flex flex-col justify-center'} ${
                contentBackground && 'bg-' + contentBackground
            } ${contentClassName}`}
        >
            {children}
        </div>
    );
};

export default WindowContent;
