import { format } from 'date-fns';
import React, { useContext, useMemo } from 'react';
import { Link } from 'react-router-dom';

import DealHeaderActors from './DealHeaderActors';
import DealHeaderButtons from './DealHeaderButtons';
import DealHeaderContext from './DealHeaderContext';
import DealHeaderWindows from './DealHeaderWindows';
import { ReactComponent as Info } from '../../../../assets/images/info/black_old.svg';
import { DealContext } from '../../../../pages/Deals/Deal/context';
import { canReturn } from '../../../../pages/Deals/Deal/Negotiate';
import { getUserFullName } from '../../../../shared/helpers';
import { isLandlordSide, useUserType } from '../../../common/helpers';
import { isCompletedDeal, isDealEdit, isDealInNegotiate } from '../../../common/helpers';

const DealHeaderContent = () => {
    const { detailsData } = useContext(DealHeaderContext);
    const { isEdit } = useContext(DealContext);

    const isLandlordOrAdminSide = isLandlordSide(detailsData?.deal.currentUserParty);

    const isEditState = isDealEdit(detailsData?.deal.state);
    const isCompletedState = isCompletedDeal(detailsData?.deal.state);
    const isNegotiationState = isDealInNegotiate(detailsData?.deal.state);

    const { isTenantSide } = useUserType(undefined, detailsData?.deal.currentUserParty);

    const returnToAgreed = canReturn(detailsData?.deal, isEdit);

    const dealUnit = useMemo(() => {
        const header = (
            <>
                <p className={`font-semibold text-09 truncate`}>{detailsData?.deal.unit?.name}</p>
                <p className={`font-semibold text-09 truncate`}>{detailsData?.deal.unit?.buildingName}</p>
            </>
        );

        if (isLandlordOrAdminSide) {
            return (
                <Link to={`/units/${detailsData?.deal.unit?._id}`} className="hover:text-newTeal-main cursor-pointer">
                    {header}
                </Link>
            );
        }

        return header;
    }, [detailsData?.deal.unit]);

    return (
        <>
            <DealHeaderWindows />
            <div className={`right-0 left-0 z-10 sticky rounded-b-3xl flex flex-col`}>
                <div className="z-50">
                    <div className={`flex flex-row  items-center bg-white pr-0 pl-6  shadow-sm py-2 z-50`}>
                        <div className="md:px-10 lg:px-24 w-full">
                            <div className="container flex flex-row justify-around sm:px-2 lg:px-2 w-full mx-auto ">
                                <div
                                    className={`flex flex-row justify-start items-center ${
                                        returnToAgreed ? 'sm:w-2/6' : 'sm:w-3/6'
                                    } lg:w-3/12`}
                                >
                                    {isLandlordOrAdminSide && (
                                        <Info
                                            className="h-4 w-4 mr-4 -ml-5"
                                            data-tip={
                                                detailsData?.deal.creator &&
                                                `Created by ${getUserFullName(detailsData?.deal.creator)} -
                                        Created on
                                        ${format(new Date(detailsData.deal.created), 'dd MMM yyyy, h:mm a')} - ${
                                                    detailsData.deal.template?.name
                                                }`
                                            }
                                        />
                                    )}
                                    <div
                                        className="flex flex-col w-11/12 sm:mr-0 md:mr-1 lg:mr-2 border-r border-black"
                                        data-border-color={'white'}
                                        data-tip={
                                            detailsData?.deal.unit?.name +
                                            ' - ' +
                                            detailsData?.deal.unit?.buildingName +
                                            ' - ' +
                                            detailsData?.deal.unit?.organisation.name
                                        }
                                    >
                                        {dealUnit}
                                        <p className={`text-09 truncate`}>
                                            {detailsData?.deal.unit?.organisation.name}
                                        </p>
                                        {!isTenantSide && (
                                            <div
                                                className={`bg-newTeal-main  mt-0.5 text-white text-sm font-semibold ${
                                                    isEditState ? 'w-16' : 'w-32'
                                                } flex rounded items-center justify-center`}
                                            >
                                                {isEditState
                                                    ? 'Draft'
                                                    : isNegotiationState
                                                    ? 'In Negotiation'
                                                    : isCompletedState && 'Terms Agreed'}
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <DealHeaderActors />
                                <DealHeaderButtons />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default DealHeaderContent;
