import React from 'react';

import { DealUserParty } from '../../../../../generated/graphql';
import DefaultProfile from '../../../../../layout/SideBar/components/DefaultProfile';
import useMobileScreen from '../../../../../shared/hooks/useMobileScreen';

type MessageProps = {
    children: string | null | undefined;
    party?: DealUserParty | null;
    currentUserParty?: DealUserParty | null;
    organisationName?: string;
    user?: {
        firstName?: string | null;
        lastName?: string | null;
    } | null;
    sent?: Date;
};

const Message = ({ children: message, party, organisationName, currentUserParty, user, sent }: MessageProps) => {
    const isMobile = useMobileScreen();
    const isUs = currentUserParty === party;
    return (
        <div
            className={`w-full flex gap-2 justify-end items-start mb-5 md:mb-0 ${isUs && 'flex-row-reverse'} ${
                organisationName && 'md:mb-3'
            }`}
        >
            <div className="mt-2">
                <DefaultProfile
                    name={organisationName ? organisationName : `${user?.firstName} ${user?.lastName}`}
                    background={isUs ? 'black' : 'white'}
                    text={isUs ? 'white' : 'black'}
                    size="40px"
                    fontSize="20px"
                    round
                />
            </div>
            <div className={`flex-1 flex flex-col justify-center ${isUs ? 'items-end' : 'items-start'}`}>
                {
                    <span className="text-xs text-newGray-darkish">
                        <span className="font-bold">
                            {organisationName ? organisationName : `${user?.firstName} ${user?.lastName}`}
                        </span>
                        {sent && <> {`- ${sent.toLocaleString()}`}</>}
                    </span>
                }
                <div
                    className={`border border-navy-light rounded py-1 px-2 ${!isUs && 'bg-navy-lightest'}`}
                    style={{
                        maxWidth: isMobile ? '100%' : '70%'
                    }}
                    // dangerouslySetInnerHTML={{
                    //     __html: message?.replace(/(?:\r\n|\r|\n)/g, "<br>") ?? ""
                    // }}
                >
                    {/* Safely add line breaks back in */}
                    {message?.split(/(?:\r\n|\r|\n)/g).map((element, i) => (
                        <React.Fragment key={i}>
                            {element}
                            <br />
                        </React.Fragment>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Message;
