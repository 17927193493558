import _ from 'lodash';
import React, { useState } from 'react';

import { DealCardFragment, SingleUnitCardFragment } from '../../generated/graphql';

type BulkSelectProviderProps = {
    children: React.ReactNode;
};

type BulkSelectContextType = {
    selectMode: boolean;
    saveSelectMode: (selectMode: boolean) => void;
    selectAll: boolean;
    saveSelectAll: (selectAll: boolean) => void;
    selectedItems: string[];
    saveSelectedItems: (selectedItems: string[]) => void;
    checkIsAllUnits: (units: SingleUnitCardFragment[]) => void;
    checkIsAllDeals: (deals: DealCardFragment[]) => void;
    checkForDuplicates: (id: string) => void;
    manageSelectedItems: (select: boolean, id: string) => void;
    all: boolean;
    saveAll: (length: Number) => void;
    selectedUnits: SingleUnitCardFragment[];
    deals: DealCardFragment[];
    saveSelectedDeals: (select: boolean, id: string, deal: DealCardFragment) => void;
    saveSelectedUnits: (select: boolean, id: string, unit: SingleUnitCardFragment) => void;
    deselect: () => void;
    manageSelectState: () => void;
};

export const BulkSelectContext = React.createContext<BulkSelectContextType>({
    selectMode: false,
    saveSelectMode: () => {},
    selectAll: false,
    saveSelectAll: () => {},
    selectedItems: [],
    saveSelectedItems: () => {},
    checkIsAllUnits: () => {},
    checkIsAllDeals: () => {},
    checkForDuplicates: () => {},
    manageSelectedItems: () => {},
    all: false,
    saveAll: () => {},
    selectedUnits: [],
    deals: [],
    saveSelectedDeals: () => {},
    saveSelectedUnits: () => {},
    deselect: () => {},
    manageSelectState: () => {}
});

export const BulkSelectProvider = ({ children }: BulkSelectProviderProps) => {
    const [selectMode, setSelectMode] = useState<boolean>(false);
    const [selectAll, setSelectAll] = useState<boolean>(false);
    const [selectedItems, setSelectedItems] = useState<string[]>([]);
    const [all, setAll] = useState<boolean>(false);
    const [selectedUnits, setSelectedUnits] = useState<SingleUnitCardFragment[]>([]);
    const [deals, setDeals] = useState<DealCardFragment[]>([]);

    const saveSelectMode = (selectMode: boolean) => {
        setSelectMode(selectMode);
    };

    const saveSelectedDeals = (select: boolean, id: string, deal: DealCardFragment) => {
        if (!checkForDuplicates(id)) {
            if (select) {
                const newItems = [...(deals ?? [])];
                newItems.push(deal);
                setDeals && setDeals(newItems);
            }
        } else {
            const remove = deals && deals.findIndex((deal) => deal._id === id);

            const newItems = [...(deals ?? [])];

            if (remove === 0 && !select) {
                newItems.shift();
                setDeals && setDeals(newItems);
            } else if (!select) {
                remove && newItems.splice(remove, 1);
                setDeals && setDeals(newItems);
            }
        }
    };

    const deselect = () => {
        setSelectedUnits([]);
        setDeals([]);
    };

    const saveSelectedUnits = (select: boolean, id: string, unit: SingleUnitCardFragment) => {
        if (id && !checkForDuplicates(id)) {
            if (select) {
                const newItems = [...(selectedUnits ?? [])];
                unit && newItems.push(unit);
                setSelectedUnits(newItems);
            }
        } else {
            const remove = selectedUnits && selectedUnits.findIndex((unit) => unit._id === id);

            const newItems = [...(selectedUnits ?? [])];

            if (remove === 0 && !select) {
                newItems.shift();
                setSelectedUnits(newItems);
            } else if (!select) {
                remove && newItems.splice(remove, 1);
                setSelectedUnits(newItems);
            }
        }
    };

    const saveAll = (length: Number) => {
        if (length === selectedItems.length) {
            setAll(true);
        } else {
            setAll(false);
        }
    };

    const saveSelectAll = (selectAll: boolean) => {
        setSelectAll(selectAll);
    };

    const saveSelectedItems = (selectedItems: string[]) => {
        setSelectedItems(selectedItems);
    };

    const checkIsAllDeals = (deals: DealCardFragment[]) => {
        const newDealsArray = [...(selectedItems ?? [])];
        deals.map((deal) => newDealsArray.push(deal._id));
        selectAll ? setSelectedItems(newDealsArray) : setSelectedItems([]);
        selectAll ? setDeals(deals) : setDeals([]);
    };

    const checkIsAllUnits = (units: SingleUnitCardFragment[]) => {
        const newItems = [...(selectedItems ?? [])];
        units.map((unit) => newItems.push(unit._id));
        selectAll ? setSelectedItems(newItems) : setSelectedItems([]);
        selectAll ? setSelectedUnits(units) : setSelectedUnits([]);
    };

    const checkForDuplicates = (id: string) => {
        const result = selectedItems && selectedItems.find((selectedItem) => selectedItem === id);

        return result;
    };

    const manageSelectedItems = (select: boolean, id: string) => {
        if (!checkForDuplicates(id)) {
            if (select) {
                const newItems = [...(selectedItems ?? [])];
                newItems.push(id);
                saveSelectedItems(newItems);
            }
        } else {
            const remove = selectedItems && selectedItems.findIndex((selectedItem) => selectedItem === id);

            const newItems = [...(selectedItems ?? [])];

            if (remove === 0 && !select) {
                if (newItems.length === 1) setSelectMode(false);
                newItems.shift();
                saveSelectedItems(newItems);
            } else if (!select) {
                remove && newItems.splice(remove, 1);
                saveSelectedItems(newItems);
            }
        }
    };

    const manageSelectState = () => {
        if (_.isEmpty(selectedItems) && !selectMode) saveSelectMode(true);
        if (all) saveAll(selectedItems.length);
    };

    return (
        <BulkSelectContext.Provider
            value={{
                selectMode,
                saveSelectMode,
                selectAll,
                saveSelectAll,
                selectedItems,
                saveSelectedItems,
                checkIsAllUnits,
                checkIsAllDeals,
                checkForDuplicates,
                manageSelectedItems,
                all,
                saveAll,
                selectedUnits,
                deals,
                saveSelectedUnits,
                saveSelectedDeals,
                deselect,
                manageSelectState
            }}
        >
            {children}
        </BulkSelectContext.Provider>
    );
};
