import React, { CSSProperties } from 'react';

type DefaultProfileProps = {
    name?: string;
    text?: string;
    background?: string;
    long?: boolean;
    size?: string;
    fontSize?: string;
    round?: boolean;
};

const DefaultProfile = ({ name, text, background, long, size, fontSize, round }: DefaultProfileProps) => {
    const baseStyle: CSSProperties = {
        fill: background ?? '#fff'
    };

    const textStyle: CSSProperties = {
        lineHeight: 1,
        userSelect: 'none',
        fontWeight: 600,
        fontSize: fontSize ? fontSize : !long ? '32px' : 'inherit'
    };

    let firstLine;
    let secondLine;
    let splitName = name?.toUpperCase().split(' '); //is it two words (Derwent London) or one (Google)
    if (!long && splitName) {
        if (splitName.length > 1) {
            firstLine = `${splitName[0].toUpperCase().substr(0, 1)}${splitName[1].toUpperCase().substr(0, 1)}`;
        } else {
            firstLine = splitName[0].substr(0, 2);
        }
    } else {
        splitName = name?.split(' ');
        firstLine = splitName && splitName[0].toUpperCase();
        if (splitName && splitName.length > 1) {
            secondLine = splitName[1].toUpperCase();
        }
        textStyle.fontWeight = 300;
    }

    return (
        <svg
            width={size ? size : '66px'}
            height={size ? size : '66px'}
            xmlns="http://www.w3.org/2000/svg"
            className={`border border-black ${round ? 'rounded-full' : 'rounded'} ${size ? '' : ' sidebar-avatar'}`}
            style={{
                width: size ?? '66px',
                height: size ?? 'initial'
            }}
        >
            <rect x="0" y="0" width={size ? size : '66px'} height={size ? size : '66px'} style={baseStyle} />

            <text
                x="50%"
                y={long && secondLine ? '40%' : '50%'}
                dy=".1em"
                fill={text ?? '#000000'}
                textAnchor="middle"
                dominantBaseline="middle"
                style={textStyle}
            >
                {firstLine}
            </text>
            {secondLine ? (
                <text
                    x="50%"
                    y="50%"
                    dy="1.2em"
                    fill={text ?? '#000000'}
                    textAnchor="middle"
                    dominantBaseline="middle"
                    style={textStyle}
                >
                    {secondLine}
                </text>
            ) : (
                <></>
            )}
        </svg>
    );
};

export default DefaultProfile;
