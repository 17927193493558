import { QueryHookOptions } from '@apollo/client';
import { format } from 'date-fns';
import jsonata from 'jsonata';
import React, { useContext, useState } from 'react';
import DatePicker from 'react-datepicker';

import { FilterContext, FilterValues, Sort } from './context';
import { PageType, WindowType } from './hooks/useDisclosure';
import SortWindow from './SortWindow';
import { ReactComponent as ArrowDown } from '../../assets/images/arrow/arrow-down/arrow-down.svg';
import { ReactComponent as ArrowUp } from '../../assets/images/arrow/arrow-up/arrow-up.svg';
import { OrderBy } from '../../generated/graphql';
import { Button } from '../button';
import Input, { InputType } from '../Inputs';
import Checkbox from '../Inputs/Checkbox';
import Radio, { RadioValue } from '../Inputs/Radio';
import Loading from '../Loading';
import Window from '../Window';

import 'react-datepicker/dist/react-datepicker.css';
import './index.css';

type FilterWindowProps = {
    config: FilterConfig;
    sortOptions: RadioValue[];
    page?: PageType;
};

export const FilterWindow = ({ config, sortOptions, page }: FilterWindowProps) => {
    const {
        pendingValues: values,
        savePendingValues: saveValues,
        sort: contextSort,
        window
    } = useContext(FilterContext);
    const [sort, setSort] = useState<Sort | undefined>(contextSort);

    const addValue = (id: string, value: string[] | boolean | string | Range | undefined) => {
        values[id] = value;
        saveValues(values);
    };

    let renderComponent;
    switch (window?.type) {
        case WindowType.ALL:
            renderComponent = (
                <_FilterListWindow config={config} sortOptions={sortOptions} values={values} sort={sort} page={page} />
            );
            break;
        case WindowType.FILTER:
            renderComponent = <_FieldFilterWindow config={config} values={values} addValue={addValue} page={page} />;
            break;
        case WindowType.SORT:
            renderComponent = <SortWindow sort={sort} setSort={setSort} fields={sortOptions} page={page} />;
            break;
        default:
            return <></>;
    }

    return renderComponent;
};

type FilterListWindowProps = {
    config: FilterConfig;
    sortOptions: RadioValue[];
    values: FilterValues;
    sort: Sort | undefined;
    page?: PageType;
};

const _FilterListWindow = ({ config, sortOptions, values, sort, page }: FilterListWindowProps) => {
    const { openWindow, clear, close, saveValues, saveSort, options } = useContext(FilterContext);

    return (
        <Window
            setShow={close}
            title="Filters"
            className="overflow-x-hidden"
            footer={
                <div>
                    <div className="h-5 w-full bg-navy-lightest border-b" />
                    <div className="flex justify-center items-center mt-3">
                        <Button
                            text="black"
                            onClick={() => {
                                clear();
                                close();
                                saveSort(undefined);
                            }}
                            background="navy-light"
                            className="px-4 w-full md:w-4/12 font-semibold text-sm ml-2 md:mr-2 mb-3"
                        >
                            Clear All
                        </Button>
                        <Button
                            background="newTeal-main"
                            text="white"
                            onClick={() => {
                                saveValues(values);
                                saveSort(sort);
                                close();
                            }}
                            className="px-10 mr-2 font-semibold w-full md:w-auto ml-2 md:mr-2 mb-3"
                        >
                            Apply
                        </Button>
                    </div>
                </div>
            }
        >
            <div>
                <div className="h-5 w-full bg-navy-lightest border-b" />
                <div
                    onClick={() => openWindow({ type: WindowType.SORT, page: page })}
                    className="flex justify-between items-center border-b px-3 py-4 cursor-pointer"
                    style={{
                        minWidth: 400
                    }}
                >
                    <div>
                        <div className="inline-block flex items-center">
                            {sort?.order === OrderBy.Desc ? (
                                <ArrowDown className="w-6 h-6 mr-2" />
                            ) : (
                                <ArrowUp className="w-6 h-6 mr-2" />
                            )}
                            <div>
                                <p>Sort</p>
                                <p className="text-newGray-darkish text-sm">
                                    {sortOptions.find((s) => s.id === sort?.field)?.label}
                                    {sort ? (sort.order === OrderBy.Asc ? ' (Asc)' : ' (Desc)') : ''}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="mr-2 filter-list-item-arrow" />
                </div>
                {config.length > 0 && <div className={'h-5 w-full bg-navy-lightest border-b '} />}
                {config.map((filter) => {
                    let displayValue = '';

                    const _getDisplayValueForList = (values: string[], options: CheckboxListOption[]) => {
                        return values.map((v) => options?.find((f) => f.value === v)?.label).join(', ');
                    };

                    if (filter.type === FilterType.RadioList || values?.[filter.id]) {
                        switch (filter.type) {
                            case FilterType.List:
                                displayValue = _getDisplayValueForList(
                                    values[filter.id] as string[],
                                    (filter.options as CheckboxListOption[]) || []
                                );
                                break;
                            case FilterType.AsyncList:
                                if (options[filter.id].loading) return <Loading />;

                                const transformer = jsonata(filter.path!);
                                const data: CheckboxListOption[] = transformer.evaluate(options[filter.id].data);
                                displayValue = _getDisplayValueForList(values[filter.id] as string[], data);
                                break;
                            case FilterType.Range:
                                const value = values[filter.id] as Range;
                                if (value.from && value.to) {
                                    displayValue = `${(value as Range).from} - ${(value as Range).to} ${filter.suffix}`;
                                } else if (value.from) {
                                    displayValue = `More than ${value.from} ${filter.suffix}`;
                                } else if (value.to) {
                                    displayValue = `Less than ${value.to} ${filter.suffix}`;
                                }
                                break;
                            case FilterType.RangeDate:
                                const dateValue = values[filter.id] as RangeDate;
                                const from =
                                    dateValue.from && format(new Date(dateValue.from).getTime(), 'dd MMM yyyy');
                                const to = dateValue.to && format(new Date(dateValue.to).getTime(), 'dd MMM yyyy');
                                if (dateValue.from && dateValue.to) {
                                    displayValue = `${from} - ${to}`;
                                } else if (dateValue.from) {
                                    displayValue = `From ${from}`;
                                } else if (dateValue.to) {
                                    displayValue = `To ${to}`;
                                }
                                break;
                            case FilterType.RadioList:
                                const val = values[filter.id] as RadioValue;
                                displayValue = val !== undefined ? (val ? 'Yes' : 'No') : '';
                        }
                    }

                    return (
                        <div
                            key={filter.id}
                            onClick={() => {
                                if (!filter.disabled)
                                    openWindow({
                                        type: WindowType.FILTER,
                                        field: filter.id,
                                        page: page
                                    });
                            }}
                            className="flex justify-between items-center border-b px-3 py-4 cursor-pointer filter-list-item overflow-x-hidden"
                        >
                            <div>
                                <p>{filter.label}</p>
                                <p className="text-newGray-darkish text-sm">
                                    {!filter.disabled ? displayValue : filter.disabledText}
                                </p>
                            </div>
                            {!filter.disabled && <div className="mr-2 filter-list-item-arrow" />}
                        </div>
                    );
                })}
            </div>
        </Window>
    );
};

type FieldFilterWindowProps = {
    values: FilterValues;
    config: FilterConfig;
    addValue: (id: string, value: string[] | boolean | string | Range | undefined) => void;
    page?: PageType;
};

const _FieldFilterWindow = ({ values, config, addValue, page }: FieldFilterWindowProps) => {
    const { window, openWindow, close } = useContext(FilterContext);

    const field = config.find((f) => f.id === window?.field);
    const filterValue = values[window?.field!];
    const [value, setValue] = useState<string[] | string | Range | undefined | boolean>(filterValue);

    let renderComponent;

    switch (field?.type) {
        case FilterType.List:
            renderComponent = (
                <_CheckboxList
                    options={field.options! as CheckboxListOption[]}
                    value={value as string[]}
                    onChange={setValue}
                />
            );
            break;
        case FilterType.AsyncList:
            renderComponent = (
                <_AsyncCheckboxList field={field.id} path={field.path!} value={value as string[]} onChange={setValue} />
            );
            break;
        case FilterType.Range:
            renderComponent = <_RangeFilter values={value as Range} onChange={setValue} suffix={field.suffix!} />;
            break;
        case FilterType.RangeDate:
            renderComponent = <_RangeDateFilter values={value as RangeDate} onChange={setValue} />;
            break;
        case FilterType.RadioList:
            renderComponent = (
                <_RadioList fields={field.options as RadioValue[]} value={value as string} onChange={setValue} />
            );
            break;
        default:
            renderComponent = null;
    }

    return (
        <Window
            setShow={close}
            title={field?.label}
            className="overflow-x-hidden"
            footer={
                <div>
                    <div className="h-5 w-full bg-navy-lightest border-b" />
                    <div className="flex justify-center items-center mt-3">
                        <Button
                            text="black"
                            onClick={() => {
                                openWindow({ type: WindowType.ALL, page: page });
                                setValue({});
                            }}
                            background="navy-light"
                            className="px-4 w-full md:w-4/12 font-semibold text-sm ml-2 md:mr-2 mb-3"
                        >
                            Cancel
                        </Button>
                        <Button
                            background="newTeal-main"
                            text="white"
                            onClick={() => {
                                if (field?.type !== FilterType.RadioList && !value) return;
                                addValue(field?.id!, value);
                                setValue({});
                                openWindow({ type: WindowType.ALL, page });
                            }}
                            className="px-10 mr-2 font-semibold w-full md:w-auto ml-2 md:mr-2 mb-3"
                        >
                            Confirm
                        </Button>
                    </div>
                </div>
            }
        >
            <>
                <div className="h-5 w-full bg-navy-lightest border-b " />
                <div>{renderComponent}</div>
            </>
        </Window>
    );
};

export enum FilterType {
    List,
    AsyncList,
    Range,
    RangeDate,
    RadioList
}

export type FieldFilter = {
    id: string;
    label: string;
    type: FilterType;
    options?: CheckboxListOption[] | RadioValue[];
    query?: (baseOptions: QueryHookOptions<any, any>) => any;
    path?: string;
    suffix?: string;
    disabled?: boolean;
    disabledText?: string;
};

export type FilterConfig = FieldFilter[];

export type CheckboxListOption = {
    label: string;
    value: string | boolean;
};

type RadioListProps = {
    value: boolean;
    fields: RadioValue[] | undefined;
    onChange: React.Dispatch<React.SetStateAction<any>>;
};

const _RadioList = ({ fields, value, onChange }: RadioListProps) => {
    const [fieldValue, setFieldValue] = useState<boolean | undefined>(value ?? undefined);

    const setChecked = (value: RadioValue) => {
        value && setFieldValue(value.id);
        onChange(value.id);
    };

    return (
        <div>
            {fields &&
                fields.map((field) => (
                    <Radio
                        key={field.id}
                        checked={{ id: fieldValue, label: '' }}
                        setChecked={setChecked}
                        value={{ id: field.value, label: field.label }}
                    />
                ))}
        </div>
    );
};

type CheckboxListProps = {
    value: string[] | undefined;
    onChange: React.Dispatch<React.SetStateAction<any>>;
    options: CheckboxListOption[];
};

const _CheckboxList = ({ options, value = [], onChange }: CheckboxListProps) => (
    <div>
        {options?.map((option) => (
            <div
                key={option.value}
                className="flex justify-between items-center border-b px-3 py-4 cursor-pointer filter-checkbox-list"
            >
                <Checkbox
                    label={option.label}
                    setValue={(checked: boolean) => {
                        if (checked) {
                            onChange([...value, option.value]);
                        } else {
                            onChange(value.filter((item) => item !== option.value));
                        }
                    }}
                    value={!!value.find((item) => item === option.value)}
                    className="w-full"
                />
            </div>
        ))}
    </div>
);

type AsyncCheckboxListProps = {
    field: string;
    path: string;
    value: string[] | undefined;
    onChange: React.Dispatch<React.SetStateAction<any>>;
};

const _AsyncCheckboxList = ({ field, path, value = [], onChange }: AsyncCheckboxListProps) => {
    const { options: contextOptions } = useContext(FilterContext);
    const { data, loading } = contextOptions[field] || {};

    if (loading) return <Loading />;

    const transformer = jsonata(path);
    const options: CheckboxListOption[] = transformer.evaluate(data);

    return (
        <div>
            {options.map((option) => (
                <div
                    key={option.value}
                    className="flex justify-between items-center border-b px-3 py-4 cursor-pointer filter-checkbox-list"
                >
                    <Checkbox
                        label={option.label}
                        setValue={(checked: boolean) => {
                            if (checked) {
                                onChange([...value, option.value]);
                            } else {
                                onChange(value.filter((item) => item !== option.value));
                            }
                        }}
                        value={!!value.find((item) => item === option.value)}
                        className="w-full"
                    />
                </div>
            ))}
        </div>
    );
};

export type Range = {
    from?: number;
    to?: number;
};

type RangeFilterProps = {
    values: Range | undefined;
    onChange: React.Dispatch<React.SetStateAction<any>>;
    suffix: string;
};

const _RangeFilter = ({ values, onChange, suffix }: RangeFilterProps) => (
    <div className="flex justify-center p-4 gap-4">
        <Input
            type={InputType.number}
            label="Minimum"
            value={values?.from}
            onChange={(e) => {
                const from = parseInt(e.target.value);
                if (from !== NaN) onChange({ ...values, from });
            }}
            suffix={suffix}
        />
        <Input
            type={InputType.number}
            label="Maximum"
            value={values?.to}
            onChange={(e) => {
                const to = parseInt(e.target.value);
                if (to !== NaN) onChange({ ...values, to });
            }}
            suffix={suffix}
        />
    </div>
);
export type RangeDate = {
    from?: Date;
    to?: Date;
};

type RangeDateFilterProps = {
    values: RangeDate | undefined;
    onChange: React.Dispatch<React.SetStateAction<any>>;
};

const _RangeDateFilter = ({ values, onChange }: RangeDateFilterProps) => (
    <div className="flex justify-center p-4 gap-4">
        <div className={`w-full flex flex-col`}>
            <p className="top-0 left-0 text-gray-600 font-medium text-sm mb-0.5">Date from</p>
            <DatePicker
                selected={values?.from ? values?.from : new Date()}
                onChange={(value) => {
                    const from = value;
                    if (from) onChange({ ...values, from });
                }}
                calendarClassName="datepicker-custom"
                dateFormat="dd/MM/yyyy"
                maxDate={values?.to ?? new Date()}
            />
        </div>
        <div className={`w-full flex flex-col`}>
            <p className="top-0 left-0 text-gray-600 font-medium text-sm mb-0.5">Date to</p>
            <DatePicker
                selected={values?.to ? values?.to : new Date()}
                onChange={(value) => {
                    const to = value;
                    if (to) onChange({ ...values, to });
                }}
                calendarClassName="datepicker-custom"
                dateFormat="dd/MM/yyyy"
                minDate={values?.from ?? undefined}
                maxDate={new Date()}
            />
        </div>
    </div>
);
