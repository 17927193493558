import React, { useEffect, useState } from 'react';

import DealHeaderContent from './DealHeaderContent';
import DealHeaderContext, { ValuelessClause } from './DealHeaderContext';
import {
    DealApprovalType,
    DealDetailsNoHistoryFragment,
    DealSendType,
    DealState,
    GetDealDetailsQuery,
    useGetDealDetailsLazyQuery,
    useGetDealTenantDetailsLazyQuery,
    UserType
} from '../../../../generated/graphql';
import useObserveDeal from '../../../../pages/Deals/Deal/hooks/useObserveDeal';
import useSortDeal from '../../../../pages/Deals/Deal/hooks/useSortDeal';
import { WalkthroughClause } from '../../../../pages/Deals/Deal/Negotiate';
import useDownloadData from '../../../../shared/Negotiator/hooks/useDownloadData';
import useDownloadPDF from '../../../../shared/Negotiator/hooks/useDownloadPDF';

export enum DealHeaderPage {
    PREVIEW,
    APPROVE,
    DEFAULT
}

type DealHeaderProps = {
    userType?: string;
    deal?: DealDetailsNoHistoryFragment | null;
    setShowAddClauseWindow?: React.Dispatch<React.SetStateAction<boolean>>;
    setClauseReordering?: React.Dispatch<React.SetStateAction<boolean>>;
    setTitlesReordering?: React.Dispatch<React.SetStateAction<boolean>>;
    setShowApproveDealWindow?: React.Dispatch<React.SetStateAction<boolean>>;
    setShowRejectDealWindow?: React.Dispatch<React.SetStateAction<boolean>>;
    setWalkthroughClauses?: React.Dispatch<React.SetStateAction<WalkthroughClause[]>>;
    financialsOpen?: boolean;
    setFinancialsOpen?: React.Dispatch<React.SetStateAction<boolean>>;
    setHideToolbar?: React.Dispatch<React.SetStateAction<boolean>>;
    setShowingTenantPending?: React.Dispatch<React.SetStateAction<boolean>>;
    dealAgreedWindowOpen?: boolean;
    isClauseReordering?: boolean;
    isTitlesReordering?: boolean;
    children: GetDealDetailsQuery | undefined;
    page?: DealHeaderPage;
    completedDeal?: boolean;
    triggerOpen?: boolean;
};

const DealHeader = ({
    children: detailsData,
    userType,
    deal,
    setShowAddClauseWindow,
    isClauseReordering,
    dealAgreedWindowOpen,
    setClauseReordering,
    isTitlesReordering,
    setTitlesReordering,
    setShowApproveDealWindow,
    setShowRejectDealWindow,
    setWalkthroughClauses,
    financialsOpen,
    setFinancialsOpen,
    setHideToolbar,
    setShowingTenantPending,
    page = DealHeaderPage.DEFAULT,
    completedDeal,
    triggerOpen
}: DealHeaderProps) => {
    const [showSendWindow, setShowSendWindow] = useState<boolean>(false);
    const [showLandlordFiles, setShowLandlordFiles] = useState<boolean>(false);
    const [showRoundCompleteWindow, setShowRoundCompleteWindow] = useState<boolean>(false);
    const [shownRoundCompleteWindow, setShownRoundCompleteWindow] = useState<boolean>(false);
    const [shareWindowType, setShareWindowType] = useState<DealSendType>(DealSendType.Other);
    const [sendWindowSuccess, setSendWindowSuccess] = useState<boolean>(false);

    const [showTenantPendingMembersWindow, setShowTenantPendingMembersWindow] = useState<boolean>(false);

    /**
     * Logic Related State
     */

    const [forceReadinessClose, setForceReadinessClose] = useState<boolean>(false);
    const [valuelessClauses, setValuelessClauses] = useState<ValuelessClause[]>([]);
    const [lowReadinessWarning, setLowReadinessWarning] = useState<boolean>(false);
    const [downloadData, downloadDataLoading] = useDownloadData();
    const [downloadPDF, downloadPDFLoading] = useDownloadPDF();

    const [getDealDetails] = useGetDealDetailsLazyQuery({
        fetchPolicy: 'no-cache'
    });
    const [dealComplete, setDealComplete] = useState<boolean>(false);
    const [stages, setStages] = useState<number>(0);
    const [totalStages, setTotalStages] = useState<number>(0);
    const [canSend, setCanSend] = useState<boolean>(false);

    /**
     * Tenants / Landlord Related State
     */

    const [tenants, setTenants] = useState<any[]>();
    const [landlords, setLandlords] = useState<any[]>();
    const [tenantPendingMembers, setTenantPendingMembers] = useState<string[]>([]);
    const [oldestTenantApproval, setOldestTenantApproval] = useState<DealApprovalType | null>(null);
    const [oldestLandlordApproval, setOldestLandlordApproval] = useState<DealApprovalType | null>(null);
    const [tenantDetails, { data: tenantDetailsData }] = useGetDealTenantDetailsLazyQuery();
    const [tenantFirstView, setTenantFirstView] = useState<boolean>(false);

    /**
     * Global vars
     */

    const currentParty = detailsData?.deal.__typename === 'Deal' && detailsData.deal.currentUserParty;

    const shownWindow = !!sessionStorage.getItem('round_window_shown');

    // Sort Deal Data before sending it to observer
    const sortDeal = useSortDeal();
    const sortedDeal = sortDeal(detailsData);

    const observeDeal = useObserveDeal(
        setDealComplete,
        setValuelessClauses,
        setWalkthroughClauses,
        setLowReadinessWarning,
        setTenantFirstView
    );

    /**
     * Effects
     */

    useEffect(() => {
        setShownRoundCompleteWindow(shownWindow);
    }, [shownWindow]);

    useEffect(() => {
        if (triggerOpen) {
            setShowSendWindow(true);
        }
    }, [triggerOpen]);

    useEffect(() => {
        const sort = (approvalSort: any) => {
            return approvalSort
                ?.filter((approval: any) => {
                    return approval.status === 'WAITING';
                })
                .sort((a: any, b: any) => {
                    let newA = new Date(a.date).getTime();
                    let newB = new Date(b.date).getTime();
                    return newB - newA;
                });
        };
        if (
            detailsData?.deal.__typename === 'Deal' &&
            detailsData?.deal.currentUserParty &&
            detailsData.deal.approvals
        ) {
            let nestedApprovalsTenant = sort(detailsData.deal.approvals.tenant);
            let nestedApprovalsLandlord = sort(detailsData.deal.approvals.landlord);

            if (nestedApprovalsLandlord.length > 0) setOldestLandlordApproval(nestedApprovalsLandlord[0]);
            if (nestedApprovalsTenant.length > 0) setOldestTenantApproval(nestedApprovalsTenant[0]);
        }
    }, [detailsData]);

    useEffect(() => {
        if (detailsData) {
            const complete = observeDeal(sortedDeal);
            // Tell user if the current round is complete and they can send
            setShowRoundCompleteWindow(complete);

            setCanSend(detailsData.deal.state !== DealState.Edit ? complete : true);

            // Get tenants and landlords
            setLandlords(
                [
                    ...(detailsData?.deal.unit?.organisation.members.filter(
                        (member) => member.type !== UserType.Agent
                    ) ?? []),
                    ...detailsData?.deal.actors
                ].filter(
                    (user) =>
                        user.type === UserType.Landlord || user.type === UserType.Agent || user.type === UserType.Admin
                )
            );

            setTenants([
                ...(detailsData.deal.tenant?.members ?? []).filter(
                    (user) =>
                        user.type === UserType.Tenant || user.type === UserType.Agent || user.type === UserType.Admin
                )
            ]);

            setTenantPendingMembers([...(detailsData.deal.tenant?.pendingMembers ?? [])]);
        }
    }, [detailsData, showSendWindow]);

    useEffect(() => {
        if (!deal || currentParty !== 'TENANT') return;

        tenantDetails({
            variables: {
                dealID: deal._id
            }
        });
    }, [deal, currentParty, tenantDetails]);

    useEffect(() => {
        if (tenantDetailsData) {
            // Details
            const { ...details } = tenantDetailsData?.deal.__typename === 'Deal' && tenantDetailsData.deal.details;
            delete details['__typename'];

            // Get the total number of stages
            const total = 6;

            // Get the first stage that has not yet been actioned
            let stage = 0;
            for (const key in details) {
                if (details[key]?.completed) {
                    stage++;
                }
            }

            setTotalStages(total);
            setStages(stage);
        }
    }, [tenantDetailsData]);

    useEffect(() => {
        // Do some stuff when the tenant first lands on the deal
        if (tenantFirstView) {
            setShowTenantPendingMembersWindow(true);
            setShowingTenantPending && setShowingTenantPending(true);
        }
    }, [setShowingTenantPending, tenantFirstView]);

    return (
        <DealHeaderContext.Provider
            value={{
                // Show Landlor Files
                showLandlordFiles,
                setShowLandlordFiles,
                // Show Send Window
                showSendWindow,
                setShowSendWindow,
                // Round Complete Window
                showRoundCompleteWindow,
                setShowRoundCompleteWindow,
                // Shown Round Complete Window
                shownRoundCompleteWindow,
                // Deal Agreed Window
                dealAgreedWindowOpen,
                // Share Window Type
                shareWindowType,
                setShareWindowType,
                // Send Window Success
                sendWindowSuccess,
                setSendWindowSuccess,
                // Tenant Pending Members Window
                showTenantPendingMembersWindow,
                setShowTenantPendingMembersWindow,
                // Approve Deal Window
                setShowApproveDealWindow,
                // Reject Deal Window
                setShowRejectDealWindow,
                // Add Clause Window
                setShowAddClauseWindow,

                // Force Readiness Close
                forceReadinessClose,
                setForceReadinessClose,
                // Valueless Clauses
                valuelessClauses,
                setValuelessClauses,
                // Low Readiness Warning
                lowReadinessWarning,
                setLowReadinessWarning,
                // Is Clauses Reordering
                isClauseReordering,
                setClauseReordering,
                // Is Titles Reordering
                isTitlesReordering,
                setTitlesReordering,
                // Download Data
                downloadData,
                downloadDataLoading,
                // Download PDF
                downloadPDF,
                downloadPDFLoading,

                // Deal Details
                deal,
                dealComplete,
                detailsData,
                stages,
                totalStages,
                tenants,
                landlords,
                oldestTenantApproval,
                oldestLandlordApproval,
                completedDeal,
                currentParty,
                tenantPendingMembers,
                getDealDetails,
                userType,
                setShowingTenantPending,
                canSend,
                page,
                triggerOpen,

                // Misc
                setHideToolbar,
                financialsOpen,
                setFinancialsOpen
            }}
        >
            <DealHeaderContent />
        </DealHeaderContext.Provider>
    );
};

export default DealHeader;
