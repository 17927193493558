import React, { useState } from 'react';

import { ReactComponent as Cross } from '../../../assets/images/cross/currentColor.svg';
import {
    GetUnitDetailsDocument,
    GetUserOrganisationDocument,
    useAddActorToUnitMutation,
    useInviteUserMutation,
    User,
    UserType
} from '../../../generated/graphql';
import { Button } from '../../../shared/button';
import Select from '../../../shared/Inputs/Select';

type AddUsersInputProps = {
    orgID?: string;
    setShowAddUser: React.Dispatch<React.SetStateAction<boolean>>;
    addAdvisors?: boolean;
    unitId?: string;
    userOptions?: User[];
};

const AddUsersInput = ({ orgID, setShowAddUser, addAdvisors, unitId, userOptions }: AddUsersInputProps) => {
    const [email, setEmail] = useState<string>('');

    const [inviteUser, { loading: addUserLoading }] = useInviteUserMutation();
    const [addActor, { loading: addActorLoading }] = useAddActorToUnitMutation();

    const handleChange = (value: any) => {
        setEmail(value.value);
    };

    const handleAddUser = async () => {
        if (addAdvisors && unitId) {
            await addActor({
                variables: {
                    unitID: unitId,
                    emails: email,
                    type: UserType.Agent
                },
                refetchQueries: [GetUserOrganisationDocument, GetUnitDetailsDocument]
            });
        } else if (orgID) {
            await inviteUser({
                variables: {
                    organisationID: orgID,
                    emails: email,
                    type: UserType.Landlord
                },
                refetchQueries: [GetUserOrganisationDocument, GetUnitDetailsDocument]
            });
        }
        setEmail('');
        setShowAddUser(false);
    };

    const isLoading = addActorLoading || addUserLoading;

    return (
        <form
            className="flex flex-row w-52 rounded"
            onSubmit={(e) => {
                e.preventDefault();
            }}
        >
            <div
                className="bg-newGray-darkish rounded-l flex justify-center items-center cursor-pointer text-white hover:text-navy-light"
                onClick={() => setShowAddUser(false)}
            >
                <Cross className="w-4 h-4  mx-3" />
            </div>
            <div className="bg-white border-b-2 border-l-2 border-t-2 border-newTeal-main h-8">
                {!addAdvisors ? (
                    <input
                        type={'email'}
                        placeholder={'Enter address'}
                        defaultValue={email}
                        name={'email'}
                        onChange={(e) => setEmail(e.target.value)}
                        className={`px-2 mb-1 rounded focus:outline-none flex h-7 w-40 text-base`}
                        style={{
                            maxWidth: '100%',
                            minWidth: '30%',
                            flexGrow: '3'
                        }}
                    />
                ) : (
                    <Select
                        data={Object.values(userOptions).map((trustedUser) => {
                            return {
                                text: trustedUser.activated
                                    ? trustedUser.firstName +
                                      ' ' +
                                      trustedUser.lastName +
                                      ' (' +
                                      trustedUser.organisation.name +
                                      ')'
                                    : trustedUser.email,
                                id: trustedUser.email
                            };
                        })}
                        placeholder={'Enter address'}
                        createable
                        color="black"
                        height="1.8rem"
                        minHeight="1.5rem"
                        minWidth="11rem"
                        placeholderPaddingBottom="0.3rem"
                        paddingBottom="0.2rem"
                        paddingTop="0.3rem"
                        marginTop="4px"
                        className={` mb-1 rounded focus:outline-none flex text-base`}
                        alignItems="flex-start"
                        onChange={handleChange}
                    />
                )}
            </div>
            <div className="flex flex-row justify-between w-full m">
                <div />
                <Button
                    height="8"
                    type="button"
                    onClick={handleAddUser}
                    text="white"
                    border="newTeal-main"
                    background="newTeal-main"
                    className="pr-3 pl-4 font-semibold border-2 border-newTeal-main -ml-2 text-xs"
                    disabled={isLoading}
                >
                    {isLoading ? 'Adding...' : 'Add'}
                </Button>
            </div>
        </form>
    );
};

export default AddUsersInput;
