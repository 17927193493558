import React from 'react';
import Select from 'react-select';

export type Country = {
    label?: string;
    code?: string;
};

type TextFieldProps = {
    onChange: React.Dispatch<React.SetStateAction<any>>;
    placeholder?: string;
    options: Country[];
    defaultValue?: string;
};

const SelectField = ({ placeholder, options, onChange, defaultValue }: TextFieldProps) => {
    return (
        <>
            <Select
                placeholder={placeholder}
                options={options}
                defaultValue={options.find((option) => option.label === defaultValue)}
                onChange={onChange}
                styles={{
                    control: (styles: React.CSSProperties) => ({
                        ...styles,
                        minHeight: '1rem',
                        height: '1.65rem',
                        maxHeight: '1.75rem',
                        backgroundColor: '#f1f1f1',
                        borderRadius: '0.25rem 1rem 1rem 0.25rem',
                        borderColor: 'none',
                        '&:hover': {
                            borderColor: 'none',
                            boxShadow: 'none'
                        },
                        boxShadow: 'none'
                    }),
                    container: (styles: React.CSSProperties) => ({
                        ...styles,
                        fontSize: '0.75rem',
                        height: '2rem'
                    }),
                    valueContainer: (styles: React.CSSProperties) => ({
                        ...styles,
                        minHeight: '1rem',
                        height: '1.65rem',
                        maxHeight: '1.75rem'
                    }),
                    singleValue: (styles: React.CSSProperties) => ({
                        ...styles,
                        fontSize: '0.75rem'
                    }),
                    dropdownIndicator: (styles: React.CSSProperties) => ({
                        ...styles,
                        width: '2rem',
                        maxHeight: '1.75rem'
                    }),
                    indicatorsContainer: (styles: React.CSSProperties) => ({
                        ...styles,
                        height: '1rem'
                    }),
                    indicatorSeparator: (styles: React.CSSProperties) => ({
                        ...styles,
                        display: 'none'
                    }),
                    placeholder: (styles: React.CSSProperties) => ({
                        ...styles,
                        fontSize: '0.75rem',
                        color: 'black'
                    }),
                    option: (styles: React.CSSProperties, { isSelected, isFocused }) => ({
                        ...styles,
                        backgroundColor: isSelected ? '#40DDD5' : 'white',
                        fontSize: '0.75rem'
                    })
                }}
            />
        </>
    );
};

export default SelectField;
