import React, { useContext, useState } from 'react';

import DealActors from './DealActors/DealActors';
import DealActorsMobile from './DealActors/DealActorsMobile';
import ReadinessWindow from './Windows/ReadinessWindow';
import { ReactComponent as Clipboard } from '../../../../../assets/images/clipboard/lightTeal.svg';
import { DealUserParty } from '../../../../../generated/graphql';
import { Button } from '../../../../../shared/button';
import useMobileScreen from '../../../../../shared/hooks/useMobileScreen';
import { isCompletedDeal, isLandlordSide } from '../../../../../v2/common/helpers';
import DealHeaderContext from '../../../../../v2/pages/Deals/DealHeader/DealHeaderContext';
import TenantDetailsWindow from '../../../../Units/Unit/Landlord/TenantDetailsWindow';
import { DealContext } from '../../context';

type DealHeaderActorsType = {
    setHeaderContent: React.Dispatch<React.SetStateAction<React.ReactChild | null>>;
};

const DealHeaderActors = ({ setHeaderContent }: DealHeaderActorsType) => {
    const {
        detailsData,
        tenants,
        landlords,
        deal,
        oldestLandlordApproval,
        oldestTenantApproval,
        currentParty,
        tenantPendingMembers,
        setShowTenantPendingMembersWindow,
        showTenantPendingMembersWindow,
        setShowingTenantPending
    } = useContext(DealHeaderContext);
    const { deal: mobileDeal } = useContext(DealContext);

    const mobileScreen = useMobileScreen();
    const [showReadiness, setShowReadiness] = useState<boolean>(false);
    const [tenantReadinesWindow, setTenantReadinesWindow] = useState<boolean>(false);

    const isLandlordOrAdminSide = isLandlordSide(mobileDeal?.currentUserParty);
    const isCompleted = isCompletedDeal(deal?.state);

    const dealID = detailsData?.deal._id ?? '';

    const dealRound = detailsData?.deal.round;

    const organisationID = detailsData?.deal.unit?.organisation._id || '';

    const organisationName = detailsData?.deal.unit?.organisation.name || '';

    const tenantID = detailsData?.deal.tenant?.members ? detailsData.deal.tenant._id : '';

    const tenantOrganisationName =
        detailsData?.deal.tenant?.members && detailsData.deal.tenant.name ? detailsData.deal.tenant.name : '';

    const getContainerWidth = () => {
        if (!mobileScreen) return 'auto';
        if (!isCompleted) return '33.333333%';
        else return '50%';
    };

    return (
        <>
            <div
                className={`flex flex-row content-center items-center xl:justify-start md:justify-center xl:w-auto md:flex-1 md:mb-2 xl:mb-0 ${
                    mobileScreen && 'ml-2 gap-2'
                }`}
                style={{ width: getContainerWidth() }}
            >
                {!mobileScreen ? (
                    // Desktop Actors
                    <>
                        {/* Landlord */}
                        {detailsData?.deal.__typename === 'Deal' && (
                            <DealActors
                                approval={oldestLandlordApproval}
                                dealID={dealID}
                                deal={detailsData.deal}
                                dealRound={dealRound}
                                type="Landlord"
                                organisationID={organisationID}
                                organisationName={organisationName}
                                currentUserParty={currentParty || DealUserParty.Landlord}
                                state={deal?.state}
                                users={landlords ?? []}
                                agreedBy={deal?.agreedBy}
                            />
                        )}
                        {/* Tenant */}
                        {detailsData?.deal.tenant?.members && (
                            <DealActors
                                approval={oldestTenantApproval}
                                dealID={dealID}
                                dealRound={dealRound}
                                deal={detailsData.deal}
                                type="Tenant"
                                organisationID={tenantID}
                                organisationName={tenantOrganisationName}
                                currentUserParty={currentParty || DealUserParty.Tenant}
                                state={deal?.state}
                                users={tenants ?? []}
                                tenantPendingMembers={tenantPendingMembers}
                                showTenantPendingMembersWindow={showTenantPendingMembersWindow}
                                setShowTenantPendingMembersWindow={setShowTenantPendingMembersWindow}
                                setShowingTenantPending={setShowingTenantPending}
                            />
                        )}
                    </>
                ) : (
                    // Mobile Actors
                    <>
                        {showReadiness && (
                            <ReadinessWindow setHeaderContent={setHeaderContent} setShow={setShowReadiness} />
                        )}
                        {tenantReadinesWindow && (
                            <TenantDetailsWindow dealID={mobileDeal?._id} setShow={setTenantReadinesWindow} />
                        )}
                        {mobileScreen && (
                            <Button
                                onClick={() =>
                                    isLandlordOrAdminSide ? setTenantReadinesWindow(true) : setShowReadiness(true)
                                }
                                className="bg-navy-main flex flex-col justify-center rounded items-center w-1/2 h-12"
                                text={'xs'}
                            >
                                <Clipboard className="w-5 h-5" />
                                <p className="text-white">Readiness</p>
                            </Button>
                        )}
                        <DealActorsMobile
                            approvalLandlord={oldestLandlordApproval}
                            approvalTenant={oldestTenantApproval}
                            dealID={dealID}
                            deal={detailsData.deal}
                            dealRound={dealRound}
                            landlordOrganization={organisationName}
                            tenantOrganization={tenantOrganisationName}
                            currentParty={currentParty}
                            state={deal?.state}
                            landlords={landlords ?? []}
                            tenants={tenants ?? []}
                            organisationID={organisationID}
                            tenantID={tenantID}
                            tenantPendingMembers={tenantPendingMembers}
                            showTenantPendingMembersWindow={showTenantPendingMembersWindow}
                            setShowTenantPendingMembersWindow={setShowTenantPendingMembersWindow}
                            setShowingTenantPending={setShowingTenantPending}
                        />
                    </>
                )}
            </div>
        </>
    );
};

export default DealHeaderActors;
