import React from 'react';

interface GridProps {
    children: React.ReactNode;
    large?: string;
    className?: string;
}

const Grid = ({ children, large = '3', className }: GridProps) => {
    return (
        <div
            className={`grid grid-cols-1 md:grid-cols-2 lg:grid-cols-${large ?? '3'} gap-4 w-full mx-auto ${className}`}
        >
            {children}
        </div>
    );
};

export default Grid;
