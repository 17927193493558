import { QueryLazyOptions, Reference, StoreObject } from '@apollo/client';
import { format } from 'date-fns';
import React, { useContext, useEffect, useState } from 'react';

import DealCardContent from './components/DealCardContent';
import { ReactComponent as Clock } from '../../../assets/images/clock/gray.svg';
import {
    AgreedBy,
    ClauseSummary,
    DealApprovalsType,
    DealApprovalType,
    DealCardFragment,
    DealFilterCountsDocument,
    DealRoundType,
    DealState,
    DealStatusFilter,
    DealUserParty,
    Exact,
    GetDealCardDetailsDocument,
    useDeleteDealMutation,
    useGetDealStateLazyQuery,
    useGetStagesCompletedQuery,
    usePinDealMutation,
    UserType,
    useUnpinDealMutation
} from '../../../generated/graphql';
import { BulkSelectContext } from '../../../shared/bulk/context';
import { CardDropdownOptions } from '../../../shared/Grid/CardDropdown';
import Loading from '../../../shared/Loading';
import Card from '../../../v2/components/Card';
import DeleteDealWindow from '../DeleteDealWindow';
import MaximumPinnedWindow from '../MaximumPinnedWindow';

type DealCardContextType = {
    getDealState: (options?: QueryLazyOptions<Exact<{ dealID: string }>> | undefined) => void;
    compact?: boolean;
    isPinned?: boolean;
    id: string;
    name: string;
    unitName?: string;
    buildingName?: string;
    clientName?: string | null;
    currentUserParty?: DealUserParty | null;
    clauseSummary?: ClauseSummary;
    state?: DealState;
    round?: DealRoundType | null;
    complete: boolean;
    unitRating: number;
    total: number;
    totalCompleted: number;
    oldestApproval: DealApprovalType | null;
    tenantView?: boolean;
    unitID: string;
    hasHiddenUnit: boolean | null | undefined;
    deal?: DealCardFragment;
    agreedBy: AgreedBy | null | undefined;
    selectState: boolean;
    setSelectState: React.Dispatch<React.SetStateAction<boolean>>;
    showSelectButton?: boolean | null | undefined;
};

export const DealCardContext = React.createContext<DealCardContextType>({
    getDealState: () => {},
    compact: false,
    id: '',
    isPinned: false,
    name: '',
    unitName: undefined,
    buildingName: undefined,
    clientName: null,
    currentUserParty: null,
    clauseSummary: undefined,
    state: undefined,
    round: null,
    complete: false,
    unitRating: 0,
    total: 0,
    totalCompleted: 0,
    oldestApproval: null,
    tenantView: false,
    unitID: '',
    hasHiddenUnit: false,
    deal: undefined,
    agreedBy: null,
    selectState: false,
    setSelectState: () => {},
    showSelectButton: undefined
});

type DealCardProps = {
    id: string;
    name: string;
    hasMaxPinnedDeals?: boolean;
    isPinned?: boolean;
    unitName?: string;
    approvals?: DealApprovalsType | null;
    isHidden?: boolean;
    clientName?: string | null;
    unitID: string;
    buildingName?: string;
    unitRating: number;
    pending?: boolean;
    state?: DealState;
    currentUserParty?: DealUserParty | null;
    round?: DealRoundType | null;
    clauseSummary?: ClauseSummary;
    className?: string;
    tenantView?: boolean;
    compact?: boolean;
    userType?: UserType;
    hasHiddenUnit: boolean | null | undefined;
    created?: string;
    createdAtClassName?: string;
    deal: DealCardFragment;
    agreedBy?: AgreedBy | null;
    showSelectButton?: boolean | null | undefined;
};

const DealCard = ({
    id,
    name,
    unitName,
    unitID,
    clientName,
    buildingName,
    unitRating = 0,
    pending = false,
    state,
    currentUserParty,
    round,
    clauseSummary,
    className,
    tenantView,
    approvals,
    hasMaxPinnedDeals,
    isPinned,
    isHidden,
    compact = false,
    userType,
    hasHiddenUnit,
    created,
    deal,
    agreedBy,
    showSelectButton
}: DealCardProps) => {
    const { selectMode, selectAll, manageSelectedItems, saveSelectedDeals } = useContext(BulkSelectContext);

    const [oldestApproval, setOldestApproval] = useState<DealApprovalType | null>(null);

    useEffect(() => {
        if (approvals && currentUserParty) {
            let nestedApprovals = currentUserParty.toString() === 'LANDLORD' ? approvals.landlord : approvals.tenant;
            nestedApprovals = nestedApprovals
                ?.filter((approval) => {
                    return approval.status === 'WAITING';
                })
                .sort((a, b) => {
                    let newA = new Date(a.date).getTime();
                    let newB = new Date(b.date).getTime();
                    return newB - newA;
                });
            if (nestedApprovals && nestedApprovals.length > 0) {
                setOldestApproval(nestedApprovals[0]);
            }
        }
    }, [approvals, currentUserParty]);

    // Prefetch deal state for faster redirect
    const [getDealState] = useGetDealStateLazyQuery({
        variables: {
            dealID: id
        }
    });

    const [complete, setComplete] = useState<boolean>(false);

    const [deleteDealWindow, setDeleteDealWindow] = useState<boolean>(false);
    const [allAgreed, setAllAgreed] = useState<boolean>(false);

    const [showMaxPinnedWindow, setShowMaxPinnedWindow] = useState<boolean>(false);

    const [deleteDeal, { error, loading }] = useDeleteDealMutation();

    const [pinDeal, { loading: pinDealLoading }] = usePinDealMutation();
    const [unpinDeal, { loading: unpinDealLoading }] = useUnpinDealMutation();

    const [selectState, setSelectState] = useState<boolean>(selectAll && selectMode);

    // Get Tenant Readiness  Stage
    const { data } = useGetStagesCompletedQuery({
        variables: {
            dealID: id
        },
        fetchPolicy: 'cache-first'
    });

    useEffect(() => {
        if (selectAll === true) {
            setSelectState(true);
        } else setSelectState(false);
    }, [selectAll]);

    // Details
    const { ...details } = data?.deal.__typename === 'Deal' && data.deal.details;
    delete details['__typename'];

    // Get the total number of stages
    const total = Object.keys(details).length ?? 6;

    // Get the total number of completed stages
    let totalCompleted = 0;
    for (const key in details) {
        if (details[key]?.completed) {
            totalCompleted++;
        }
    }

    useEffect(() => {
        // if (clauseSummary && clauseSummary.incomplete === 0) setAllAgreed(true)
        setComplete(state === DealState.Complete);
    }, [state]);

    useEffect(() => {
        if (clauseSummary && clauseSummary.incomplete === 0) setAllAgreed(true);
    }, [clauseSummary]);

    useEffect(() => {
        manageSelectedItems(selectState, id);
        saveSelectedDeals(selectState, id, deal);
    }, [selectState]);

    const handlePinClick = async () => {
        await pinDeal({
            variables: {
                dealID: id
            },
            refetchQueries: [
                {
                    query: GetDealCardDetailsDocument,
                    variables: {
                        limit: 3,
                        filter: { status: [DealStatusFilter.Pinned] }
                    }
                }
            ],
            update(store, { data }) {
                if (!data) return;

                store.modify({
                    id: store.identify({
                        __typename: 'Deal',
                        _id: id
                    }),
                    fields: {
                        isPinned() {
                            return true;
                        }
                    }
                });
            }
        });
    };

    const handleUnpinClick = async () => {
        await unpinDeal({
            variables: {
                dealID: id
            },
            refetchQueries: [
                {
                    query: GetDealCardDetailsDocument,
                    variables: {
                        limit: 3,
                        filter: { status: [DealStatusFilter.Pinned] }
                    }
                }
            ],
            update(store, { data }) {
                if (!data) return;

                store.modify({
                    id: store.identify({
                        __typename: 'Deal',
                        _id: id
                    }),
                    fields: {
                        isPinned() {
                            return false;
                        }
                    }
                });
            }
        });
    };

    const handleDeleteClick = async () => {
        await deleteDeal({
            variables: {
                dealID: id
            },
            optimisticResponse: {
                dealDelete: {
                    deletedDealID: id
                }
            },
            update(store, { data }) {
                if (!data) return;

                const deletedDealID = data.dealDelete.deletedDealID;

                // Remove deleted deal from cache
                store.modify({
                    fields: {
                        deals({ results = [], ...deals }, { readField }) {
                            return {
                                ...deals,
                                results: results.filter(
                                    (dealRef: Reference | StoreObject) => deletedDealID !== readField('_id', dealRef)
                                )
                            };
                        }
                    }
                });

                // Remove deal from unit
                store.modify({
                    id: store.identify({
                        __typename: 'Unit',
                        _id: unitID
                    }),
                    fields: {
                        deals(deals = [], { readField }) {
                            return deals.filter(
                                (dealRef: Reference | StoreObject) => deletedDealID !== readField('_id', dealRef)
                            );
                        }
                    }
                });
            },
            refetchQueries: () => [GetDealCardDetailsDocument, DealFilterCountsDocument]
        });

        if (!error) setDeleteDealWindow(false);
    };

    const options: CardDropdownOptions = [
        ...(userType !== UserType.Tenant &&
        !isHidden &&
        ((!allAgreed && clauseSummary && state) || state === DealState.Edit)
            ? [
                  {
                      title: isPinned ? 'Unpin' : 'Pin',
                      onClick: () => {
                          if (!isPinned && hasMaxPinnedDeals) {
                              setShowMaxPinnedWindow(true);
                          } else {
                              isPinned ? handleUnpinClick() : handlePinClick();
                          }
                      },
                      disabled: !isPinned && hasMaxPinnedDeals
                  }
              ]
            : userType !== UserType.Tenant && isHidden
            ? []
            : userType !== UserType.Tenant && !isHidden && allAgreed
            ? [
                  {
                      title: isPinned ? 'Unpin' : 'Pin',
                      onClick: () => {
                          if (!isPinned && hasMaxPinnedDeals) {
                              setShowMaxPinnedWindow(true);
                          } else {
                              isPinned ? handleUnpinClick() : handlePinClick();
                          }
                      },
                      disabled: !isPinned && hasMaxPinnedDeals
                  }
              ]
            : []),

        {
            title: 'Delete',
            onClick: () => {
                setDeleteDealWindow(true);
            }
        }
    ];

    return (
        <>
            {deleteDealWindow && (
                <DeleteDealWindow setDeleteDealWindow={setDeleteDealWindow} handleDeleteClick={handleDeleteClick} />
            )}
            {showMaxPinnedWindow && <MaximumPinnedWindow setShow={setShowMaxPinnedWindow} />}
            <Card
                selectState={selectState}
                setSelectState={setSelectState}
                options={selectMode || tenantView || compact ? [] : options}
                height={'auto'}
                className={`${className} ${pending && `opacity-50`} ${
                    isPinned && 'border border-newTeal-main rounded relative rounded-tl-none'
                }`}
                userType={userType}
                isHidden={isHidden}
                clientName={clientName}
                currentUserParty={currentUserParty}
            >
                {loading || pinDealLoading || unpinDealLoading ? (
                    <Loading />
                ) : (
                    <DealCardContext.Provider
                        value={{
                            getDealState,
                            isPinned,
                            compact,
                            id,
                            name,
                            unitName,
                            buildingName,
                            clientName,
                            currentUserParty,
                            clauseSummary,
                            state,
                            round,
                            complete,
                            unitRating,
                            total,
                            totalCompleted,
                            oldestApproval,
                            tenantView,
                            unitID,
                            hasHiddenUnit,
                            agreedBy,
                            deal,
                            selectState,
                            setSelectState,
                            showSelectButton
                        }}
                    >
                        <DealCardContent />
                    </DealCardContext.Provider>
                )}
                {created ? (
                    <div className="text-xxs mx-4 flex flex-row items-center mb-2 pt-4">
                        <Clock className="w-3 h-3 mr-2" />
                        {'Created on ' + format(new Date(created), 'dd MMM yyyy, h:mm a')}
                    </div>
                ) : (
                    <div className="h-4" />
                )}
            </Card>
        </>
    );
};

export default DealCard;
