import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { ReactComponent as Chevron } from '../../../../assets/images/chevron/right/black.svg';
import ReadinessBar from '../../../../shared/ReadinessBar';
type UnitReadinessProps = {
    rating: number;
    total?: number;
    tenant?: boolean;
    card?: boolean;
    financialSummary?: boolean;
    unitID?: string;
    inFinancials?: boolean;
    className?: string;
};

const UnitReadiness = ({
    tenant = false,
    financialSummary = false,
    card = false,
    unitID,
    rating,
    total = 100,
    inFinancials,
    className
}: UnitReadinessProps) => {
    const [ratingText, setRatingText] = useState<string>('');
    const [headerCard, setHeaderCard] = useState<boolean>(false);

    useEffect(() => {
        const percentageRating = (rating / total) * 100;
        if (percentageRating === 100) {
            setRatingText('Amazing! A perfect rating!');
        } else if (percentageRating >= 90) {
            setRatingText('Congratulations! Your space is now ready to lease, but why not strive for perfection?');
        } else if (percentageRating >= 80) {
            setRatingText('You are almost there. A final push and you could perfect your rating.');
        } else if (percentageRating >= 70) {
            setRatingText('You are almost there. A final push and you could perfect your rating.');
        } else if (percentageRating >= 50) {
            setRatingText('You are getting there. Keep going. A quick deal is a good deal.');
        } else if (percentageRating >= 30) {
            setRatingText('Recommended tasks will increase your rating quicker.');
        } else if (percentageRating >= 10) {
            setRatingText('Your rating is low. Complete some of the tasks below to boost it.');
        } else {
            setRatingText('Welcome to Readiness. Complete some of the tasks below to get started.');
        }
    }, [rating, total]);

    useEffect(() => {
        !tenant && !financialSummary && setHeaderCard(true);
    }, [tenant, financialSummary]);

    return (
        <div
            className={`${!card && !inFinancials ? '' : !card && inFinancials ? 'pt-2 pb-1 bg-white' : ''} ${
                card && 'bg-white'
            } rounded ${className} `}
        >
            {card ? (
                <div className="bg-navy-lightest rounded mx-4 mb-4 mt-3 overflow-hidden hover:shadow-md transition duration-200">
                    <div className="px-3 pt-2 pb-1">
                        <p className="text-xs">Unit Readiness</p>
                        <div className="flex flex-row justify-end items-center mr-4">
                            <span className="text-4xl text-newGray-dark font-semibold leading-10">{rating}</span>
                            <span className="text-2xl text-gray-600">/100</span>
                        </div>
                    </div>

                    <ReadinessBar rating={rating} total={total} />
                </div>
            ) : (
                <div className={`${tenant && 'p-3'}`}>
                    <div
                        className={`${headerCard && 'bg-navy-light'} ${
                            !financialSummary && 'bg-navy-lightest rounded'
                        }`}
                    >
                        {/* Readiness number */}
                        <p>
                            {financialSummary && <p className="ml-4">Unit Readiness</p>}
                            <span
                                className={`font-semibold ${inFinancials ? 'text-2xl ml-4' : 'text-5xl'} ${
                                    !headerCard && 'ml-2'
                                } transition-all duration-200 ml-0`}
                            >
                                {rating}
                            </span>
                            <span className="text-sm font-light">/{total}</span>
                            {!tenant && !financialSummary && (
                                <p className="font-light text-base -mt-3">Unit Readiness Rating</p>
                            )}
                        </p>
                        {/* Bar */}
                        <ReadinessBar
                            rating={rating}
                            total={total}
                            rounded={!tenant && !financialSummary}
                            background={!tenant && !financialSummary ? 'navy-lightest' : null}
                        />
                    </div>
                    {/* Sub Text */}
                    {!card &&
                        (financialSummary && unitID ? (
                            <Link
                                to={`/units/${unitID}`}
                                className={`font-semibold px-4 ${
                                    inFinancials ? 'pt-2 mb-px' : 'pt-3'
                                } flex items-center justify-between`}
                            >
                                Improve Your Readiness
                                <Chevron className="w-5 h-5" />
                            </Link>
                        ) : (
                            <p
                                className={`${headerCard && 'text-sm text-newGray-darkish font-medium'}
                                ${inFinancials ? 'pt-1 mt-px' : 'pt-2'} text-newGray-darkish`}
                            >
                                {tenant ? 'Remind the landlord to increase their rating' : ratingText}
                            </p>
                        ))}
                </div>
            )}
        </div>
    );
};

export default UnitReadiness;
