import React, { ReactElement } from 'react';

export type WindowProps = {
    /**
     * Hide / Show Window
     */
    setShow: React.Dispatch<React.SetStateAction<boolean>> | ((args: any) => void);
    /**
     * Window Header Title
     */
    title?: string;
    /**
     * Adds an emoji at the right side of the title
     */
    emoji?: any;
    /**
     * Rule: If window contains inputs, the title should be on the left, otherwise center
     */
    titleCenter?: boolean;
    /**
     * Window Dynamic Content
     */
    children: React.ReactChild;
    /**
     * Window Custom Header
     */
    header?: React.ReactChild;
    /**
     * Window Custom Footer
     */
    footer?: React.ReactChild;
    /**
     * Overwrite Main container width. Ex: "w-4/5"
     */
    width?: string;
    /**
     * Overwrite Content height. Ex: "h-4/5"
     */
    height?: string;
    /**
     * Footer includes a Primary Button
     */
    buttons?: ReactElement;
    /**
     * Footer includes a Primary Button
     */
    primaryBtn?: boolean;
    /**
     * Change default "Confirm" text
     */
    primaryBtnText?: string;
    /**
     * Change default "teal-400" color
     */
    primaryBtnColor?: string;
    /**
     * Function to execute when secondary button is pressed
     */
    onPrimaryBtnClick?: () => void;
    /**
     * Footer includes a Secondary Button
     */
    secondaryBtn?: boolean;
    /**
     * Change default "Cancel" text
     */
    secondaryBtnText?: string;
    /**
     * Function to execute when secondary button is pressed
     */
    onSecondaryBtnClick?: () => void;
    /**
     * Function to execute on Window close or on pressing the Secondary Button if none other function provided
     */
    onCloseClick?: () => void;
    /**
     * Footer includes a Tertiary Button
     */
    tertiaryBtn?: boolean;
    /**
     * Change default "Tertiary" text
     */
    tertiaryBtnText?: string;
    /**
     * Function to execute when secondary button is pressed
     */
    onTertiaryBtnClick?: () => void;
    /**
     * Keeps the header & footer static while the content scrolls
     */
    scrollContent?: boolean;
    /**
     * Overwrites default zIndex for external & internal container
     */
    zIndex?: string;
    /**
     * Allows external window
     */
    allowExternal?: boolean;
    /**
     * Adds a className to the Main Container
     */
    className?: string;
    /**
     * Adds a className to the Content Container
     */
    contentClassName?: string;
    /**
     * Puts the content in the middle of the screen
     */
    contentMiddle?: boolean;
    /**
     * Changes the default white background of the content. ex: "navy-light"
     */
    contentBackground?: string;

    disableClose?: boolean;

    noScroll?: boolean;

    backgroundColor?: string;

    underlined?: boolean;
};

interface WindowContext extends WindowProps {
    externalWindow?: boolean;
    setExternalWindow?: React.Dispatch<React.SetStateAction<boolean>>;
}

/* eslint-disable */
export const WindowContext = React.createContext<WindowContext>({
    setShow: () => {},
    title: undefined,
    emoji: undefined,
    titleCenter: undefined,
    children: <></>,
    header: <></>,
    footer: <></>,
    width: undefined,
    height: undefined,
    buttons: undefined,
    primaryBtn: undefined,
    primaryBtnText: undefined,
    primaryBtnColor: undefined,
    onPrimaryBtnClick: undefined,
    secondaryBtn: undefined,
    secondaryBtnText: undefined,
    onSecondaryBtnClick: undefined,
    onCloseClick: () => {},
    tertiaryBtn: undefined,
    tertiaryBtnText: undefined,
    onTertiaryBtnClick: undefined,
    scrollContent: undefined,
    zIndex: '50',
    allowExternal: false,
    className: undefined,
    contentClassName: undefined,
    contentMiddle: undefined,
    contentBackground: undefined,
    externalWindow: false,
    setExternalWindow: () => {},
    disableClose: undefined,
    noScroll: false,
    backgroundColor: '',
    underlined: false
});
/* eslint-enable */
