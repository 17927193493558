import { formatDistance } from 'date-fns';
import React, { forwardRef } from 'react';
import { useHistory } from 'react-router-dom';

import { ReactComponent as Chevron } from '../../../src/assets/images/chevron/right/black.svg';
import { ReactComponent as User } from '../../../src/assets/images/user/black.svg';
import { NotificationsQuery } from '../../generated/graphql';

type NotiicationsPopupProps = {
    data: NotificationsQuery;
    close: () => void;
};

const NotiicationsPopup = forwardRef(({ data, close }: NotiicationsPopupProps, ref: any) => {
    const history = useHistory();

    const splitText = (text: string) => {
        const splitedTextArray = text.split(':');

        if (text && text.indexOf(':') === -1) {
            return <p className="text-xs">{text}</p>;
        } else {
            return (
                <div className="flex flex-row w-full items-center">
                    <div className="border border-black rounded-full w-3 h-3 bg-white mr-1 flex flex-row justify-center items-center">
                        <User className="w-2 h-2" />
                    </div>
                    <p className="text-xs font-semibold">{splitedTextArray[0]}</p>
                    <p>:{splitedTextArray[1]}</p>
                </div>
            );
        }
    };

    const unseenNotificationsCount = () => {
        return data.notifications.notifications.filter((notification) => !notification.read).length;
    };

    return (
        <>
            <div className="flex flex-col fixed xl:mr-24 right-13  z-60" ref={ref}>
                <div
                    style={{
                        top: 0,
                        left: '50%',
                        marginLeft: '18.5rem',
                        borderWidth: '2px 7px 10px',
                        borderBottomColor: 'white',
                        borderTopColor: 'rgba(0,0,0, 0.02)',
                        borderRightColor: 'rgba(0,0,0, 0.02)',
                        borderLeftColor: 'rgba(0,0,0, 0.02)',
                        width: '1rem'
                    }}
                />
                <div className="w-96 bg-white shadow rounded-lg py-2">
                    <div className="flex flex-row mt-2 items-center">
                        <h3 className="ml-4  font-semibold">Notifications</h3>
                        {unseenNotificationsCount() > 0 && (
                            <div className="ml-2 bg-newTeal-main rounded-full w-4 h-4 flex items-center text-white text-xs font-semibold justify-center">
                                {unseenNotificationsCount()}
                            </div>
                        )}
                    </div>
                    <div className="border-b-3 border-newTeal-main w-24 ml-4 -mt-1" />
                    <div
                        className="m-4 overflow-y-auto overflow-x-hidden border-b-2 border-newTeal-main"
                        style={{ height: '19rem' }}
                        onClick={() => {
                            history.push('/notifications');
                            close();
                        }}
                    >
                        {data?.notifications.notifications.map((notification) => (
                            <div
                                className={` mb-2 px-3 flex flex-row rounded-md cursor-pointer ${
                                    notification.read
                                        ? 'border-navy-lightest border'
                                        : 'border-newTeal-main bg-newTeal-lightest'
                                }  items-center p-2 h-20 `}
                                style={{ borderLeftWidth: '8px' }}
                            >
                                <div className="flex flex-col w-full h-full justify-around">
                                    <div
                                        className="flex flex-row justify-between w-full"
                                        style={{ fontSize: '0.6rem' }}
                                    >
                                        <p>
                                            {`${new Date(notification.sent).toLocaleDateString()} - ${new Date(
                                                notification.sent
                                            ).toLocaleTimeString()}`}
                                        </p>
                                        <p>{`${formatDistance(new Date(notification.sent), new Date())} ago`}</p>
                                    </div>
                                    <p className="text-xs font-semibold">{notification.title}</p>
                                    {notification.text && <p className="text-xs">{splitText(notification.text)}</p>}
                                </div>
                                <Chevron className="w-4 h-4 -mr-2" />
                            </div>
                        ))}
                    </div>
                    <div
                        className="ml-4 mb-4 pr-8 justify-between flex flex-row cursor-pointer"
                        onClick={() => {
                            history.push('/notifications');
                            close();
                        }}
                    >
                        <p className="font-semibold" style={{ fontSize: '0.8rem' }}>
                            View more notifications
                        </p>
                        <Chevron className="w-4 h-4" />
                    </div>
                </div>
            </div>
        </>
    );
});

export default NotiicationsPopup;
