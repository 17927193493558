import React, { useEffect } from 'react';

import Message from './Message';
import Logo from '../../../../../assets/images/logo/kato.svg';
import { DealUserParty, useGetDealMessagesQuery } from '../../../../../generated/graphql';
import Emoji from '../../../../../shared/Emoji';
import { handleFetchError } from '../../../../../shared/helpers';
import Loading from '../../../../../shared/Loading';
import Window from '../../../../../shared/Window';

type DealMessagesWindowProps = {
    id: string;
    currentUserParty?: DealUserParty | null;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
};

const DealMessagesWindow = ({ id, currentUserParty, setShow }: DealMessagesWindowProps) => {
    const { data, loading, error } = useGetDealMessagesQuery({
        variables: {
            id
        }
    });

    useEffect(() => {
        handleFetchError('Failed to load your messages', error, data?.deal);
    }, [data, error]);

    if (loading)
        return (
            <Window setShow={setShow} title="Messages" width="w-3/5" height="max-h-v-80">
                <Loading />
            </Window>
        );

    return (
        <Window setShow={setShow} title="Messages" width="w-3/5" height="max-h-v-80" scrollContent allowExternal>
            <>
                <div className="bg-gray-200 p-10 items-center flex flex-col w-full mt-8 md:mt-0">
                    {data?.deal.__typename === 'Deal' && data.deal.unit?.organisation?.avatar ? (
                        <img
                            className="w-32 h-32 rounded border-navy-light border"
                            alt={data.deal.unit.organisation.name}
                            src={data.deal.unit.organisation.avatar}
                        />
                    ) : (
                        <div className="h-32 w-32 rounded flex items-end p-2 pb-5 border-navy-light border">
                            <img className="w-32" src={Logo} alt="Kato Logo" />
                        </div>
                    )}
                    <div className="w-5/6 md:w-3/6 mb-2 mt-5">
                        <p className="text-center text-sm text-newGray-darkish">
                            Let's get this deal agreed <Emoji symbol="🤝" />
                        </p>
                    </div>
                    {data?.deal.__typename === 'Deal' &&
                        data.deal.unit?.straightToLease &&
                        data.deal.unit.organisation.straightToLease?.start && (
                            <p className="text-center text-sm text-black bg-white border border-newTeal-main p-2 rounded mt-3 xl:mx-20">
                                {data.deal.unit.organisation.straightToLease?.start}
                            </p>
                        )}
                </div>
                <div className="flex flex-col items-center justify-start content-center gap-2 px-4 pt-5 pb-2 overflow-y-auto sm:pt-10 md:pt-4">
                    {data?.deal.__typename === 'Deal' &&
                        data.deal.messages?.map((message) => (
                            <Message
                                key={message._id}
                                party={message.party}
                                currentUserParty={currentUserParty}
                                user={message.user}
                                sent={new Date(message.sent)}
                            >
                                {message.message}
                            </Message>
                        ))}
                </div>
            </>
        </Window>
    );
};

export default DealMessagesWindow;
