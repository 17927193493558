import React, { useState } from 'react';

import useMobileScreen from './hooks/useMobileScreen';
import Transition from './Transition';
import { ReactComponent as Settings } from '../assets/images/more/vertical/black.svg';

type OptionsProps = {
    popup?: boolean;
    className: string;
    children: {
        title: string;
        action: () => void;
        icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
    }[];
};

const Options = ({ popup, className, children }: OptionsProps) => {
    const [open, setOpen] = useState<boolean>(false);

    const mobileScreen = useMobileScreen();

    const transitionProps = {
        enter: 'transition transform ease-out duration-100',
        enterFrom: 'translate-x-full opacity-0',
        enterTo: 'translate-x-0 opacity-100',
        leave: 'transition transform ease-in duration-100',
        leaveFrom: 'translate-x-0 opacity-100',
        leaveTo: 'translate-x-full opacity-0'
    };

    return (
        <div className="flex items-center rounded overflow-hidden">
            {/* Settings open - show buttons */}
            <Transition
                show={open}
                {...transitionProps}
                className={`${
                    mobileScreen ? 'mr-12' : popup ? 'mr-16' : 'mr-5'
                } flex flex-row rounded max-w-full overflow-hidden right-0 shadow`}
            >
                <>
                    {children.map((option, index) => (
                        <div
                            key={index}
                            className={`bg-white ${
                                className ? className : 'sm:w-24'
                            } text-xxs flex flex-col content-center items-center justify-between p-1 cursor-pointer hover:bg-gray-200`}
                            onClick={option.action}
                        >
                            {React.createElement(option.icon, {
                                className: 'w-4 h-4  mb-1'
                            })}
                            {option.title}
                        </div>
                    ))}
                </>
            </Transition>

            <button
                className={`${mobileScreen ? '' : 'p-2'} cursor-pointer mr-px focus:outline-none`}
                onClick={() => {
                    setOpen((open) => !open);
                }}
            >
                <Settings className="w-8 h-6" />
            </button>
        </div>
    );
};

export default Options;
