import React from 'react';

import {
    SearchClauseBankDocument,
    useClauseBankEditSectionsMutation,
    useOrganisationSectionsEditMutation
} from '../../../generated/graphql';
import Window from '../../../shared/Window';

type EditHelpWindowProps = {
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
    sectionID?: string | null;
    organisationID?: string | null;
    fromSection?: string | null;
    rics?: string | null;
    fromGroup?: string | null;
    group?: boolean;
};
const EditHelpWindow = ({ setShow, sectionID, organisationID, rics, fromSection, fromGroup }: EditHelpWindowProps) => {
    const [editAdminClauseSections, { error: editAdminError, loading: editAdminLoading }] =
        useClauseBankEditSectionsMutation();

    const [editOrgClauseSections, { error: editOrgError, loading: editOrgLoading }] =
        useOrganisationSectionsEditMutation();

    const handleDelete = async () => {
        if (!fromSection) return;
        if (organisationID) {
            await editOrgClauseSections({
                variables: {
                    organisationID,
                    fromSection: fromSection,
                    fromGroup: fromGroup,
                    deleteSection: !fromGroup ? true : false,
                    deleteGroup: fromGroup ? false : true
                },
                refetchQueries: [
                    {
                        query: SearchClauseBankDocument,
                        variables: {
                            only: true,
                            organisationID: organisationID || '',
                            query: ''
                        }
                    }
                ]
            });

            if (editOrgError) {
                return;
                // handleFetchError(
                //     "Failed to update the entry",
                //     editOrgError,
                //     data?.organisationSectionsEdit
                // )
            } else {
                setShow(false);
            }
        } else {
            await editAdminClauseSections({
                variables: {
                    fromSection: fromSection,
                    fromGroup: fromGroup,
                    deleteSection: true
                },
                refetchQueries: [
                    {
                        query: SearchClauseBankDocument,
                        variables: {
                            only: true,
                            organisationID: organisationID || '',
                            query: ''
                        }
                    }
                ]
            });
            if (editAdminError) {
                return;
                // handleFetchError(
                //     "Failed to update the entry",
                //     editAdminError,
                //     data?.adminClauseSectionsEdit
                // )
            } else {
                setShow(false);
            }
        }
    };
    return (
        <Window
            width="w-2/5"
            title={`Delete Clause ${sectionID ? 'Group' : 'Heading'}`}
            titleCenter
            setShow={setShow}
            primaryBtn={!editAdminLoading && !editOrgLoading}
            onPrimaryBtnClick={handleDelete}
            secondaryBtn
            onSecondaryBtnClick={() => setShow(false)}
        >
            <div className="flex flex-col items-center">
                <h2 className="text-center text-lg my-8 w-4/5">
                    Are you sure you want to <span className="font-semibold text-uppercase">delete</span> this{' '}
                    {sectionID ? 'Group' : 'Heading'}? This process is irreversible.
                </h2>
            </div>
        </Window>
    );
};

export default EditHelpWindow;
