import React, { useContext } from 'react';

import DealActors from './DealActors';
import DealHeaderContext from './DealHeaderContext';
import { DealState, DealUserParty } from '../../../../generated/graphql';
import { DealContext } from '../../../../pages/Deals/Deal/context';
import { DealHeaderPage } from '../../../../pages/Deals/Deal/Header';
import { canReturn } from '../../../../pages/Deals/Deal/Negotiate';
import useMobileScreen from '../../../../shared/hooks/useMobileScreen';

const DealHeaderActors = () => {
    const {
        detailsData,
        tenants,
        landlords,
        deal,
        oldestLandlordApproval,
        oldestTenantApproval,
        currentParty,
        tenantPendingMembers,
        setShowTenantPendingMembersWindow,
        showTenantPendingMembersWindow,
        setShowingTenantPending,
        page
    } = useContext(DealHeaderContext);

    const mobileScreen = useMobileScreen();

    const dealID = detailsData?.deal._id ?? '';

    const dealRound = detailsData?.deal.round;

    const organisationID = detailsData?.deal.unit?.organisation._id || '';

    const organisationName = detailsData?.deal.unit?.organisation.name || '';

    const tenantID = detailsData?.deal.tenant?.members ? detailsData.deal.tenant._id : '';

    const tenantOrganisationName =
        detailsData?.deal.tenant?.members && detailsData.deal.tenant.name ? detailsData.deal.tenant.name : '';

    const days = Math.ceil((new Date().getTime() - new Date(dealRound?.date).getTime()) / (1000 * 3600 * 24));

    const isCompleted = detailsData?.deal.state === DealState.Complete;
    const isDraft = detailsData?.deal.state === DealState.Edit;
    const { isEdit } = useContext(DealContext);

    const returnToAgreed = canReturn(detailsData?.deal, isEdit);

    return (
        <>
            <div
                className={`flex flex-row  ${
                    isCompleted || isDraft ? 'items-end pb-0.5' : 'items-center'
                } justify-start md:-ml-2 ${
                    returnToAgreed ? 'md:w-5/12' : 'md:w-6/12'
                }  lg:w-4/12  xl:w-5/12 xl:-ml-2 h-24`}
            >
                {!mobileScreen && (
                    <div className="flex flex-col" style={{ width: '98%' }}>
                        {!isCompleted && !isDraft && page !== DealHeaderPage.APPROVE && (
                            <div
                                className={`flex ${
                                    detailsData?.deal.state !== DealState.Tenant ? 'flex-row-reverse' : 'flex-row'
                                } w-full  justify-between mt-2 mb-1 `}
                            >
                                <div style={{ width: '49%' }} />
                                <div
                                    data-border-color={'white'}
                                    data-tip={`HoTs ${
                                        detailsData?.deal.state !== DealState.Tenant ? 'Sent' : 'Recieved'
                                    } ${days === 1 ? 'Today' : days === 2 ? 'Yesterday' : `${days} Days Ago`} `}
                                    className={`${
                                        days <= 3 ? 'green-hots' : days > 3 && days <= 7 ? 'orange-hots' : 'red-hots'
                                    }  rounded-lg border-2 p-1 text-xs flex  items-center justify-center`}
                                    style={{ width: '49%' }}
                                >
                                    <p className="mr-1 md:hidden xl:flex">HoTs </p>
                                    <p className="mr-1">
                                        {' '}
                                        {detailsData?.deal.state === DealState.Tenant ? ' Recieved ' : ' Sent '}
                                    </p>{' '}
                                    {days === 1 ? (
                                        <p className="md:hidden xl:flex font-bold">Today</p>
                                    ) : days === 2 ? (
                                        <p className="md:hidden xl:flex font-bold">Yesterday</p>
                                    ) : (
                                        <p className="md:hidden xl:flex">
                                            <b className="mx-2">{days} </b> Days Ago
                                        </p>
                                    )}
                                </div>
                            </div>
                        )}
                        <div
                            className={`flex flex-row ${
                                !isCompleted && 'mb-2'
                            }  justify-between text-09 w-full cursor-pointer `}
                        >
                            {detailsData?.deal.__typename === 'Deal' && (
                                <DealActors
                                    approval={oldestLandlordApproval}
                                    dealID={dealID}
                                    deal={detailsData.deal}
                                    dealRound={dealRound}
                                    type="Landlord"
                                    organisationID={organisationID}
                                    organisationName={organisationName}
                                    currentUserParty={currentParty || DealUserParty.Landlord}
                                    state={deal?.state}
                                    users={landlords ?? []}
                                    agreedBy={deal?.agreedBy}
                                />
                            )}

                            {detailsData?.deal.tenant?.members && (
                                <DealActors
                                    approval={oldestTenantApproval}
                                    dealID={dealID}
                                    dealRound={dealRound}
                                    deal={detailsData.deal}
                                    type="Tenant"
                                    organisationID={tenantID}
                                    organisationName={tenantOrganisationName}
                                    currentUserParty={currentParty || DealUserParty.Tenant}
                                    state={deal?.state}
                                    users={tenants ?? []}
                                    tenantPendingMembers={tenantPendingMembers}
                                    showTenantPendingMembersWindow={showTenantPendingMembersWindow}
                                    setShowTenantPendingMembersWindow={setShowTenantPendingMembersWindow}
                                    setShowingTenantPending={setShowingTenantPending}
                                />
                            )}
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};

export default DealHeaderActors;
