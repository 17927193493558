import React, { useState } from 'react';

import { ReactComponent as ArrowDown } from '../../assets/images/chevron/down/black.svg';
import Window from '../Window';

type InfoWindowProps = {
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
    title: string | null;
    description?: string | null;
    rics?: string | null;
};
const InfoWindow = ({ setShow, title, description, rics }: InfoWindowProps) => {
    const [open, setOpen] = useState<boolean>(false);
    return (
        <Window
            width="w-2/5"
            title={title || 'Information'}
            titleCenter
            setShow={setShow}
            contentMiddle={true}
            footer={
                <div
                    className={`w-full md:flex ${
                        rics
                            ? 'p-4 bg-navy-lightest border-newGray-main md:flex-col'
                            : 'md:justify-end md:flex-row md:items-center'
                    }`}
                    style={{ borderTopWidth: rics ? '1px' : undefined }}
                >
                    <div
                        className={`acordian-slow ${!open ? 'overflow-hidden' : 'pb-2'}`}
                        style={{
                            maxHeight: open ? `8000px` : '0px'
                        }}
                    >
                        <p>{rics}</p>
                    </div>
                    {rics && (
                        <div
                            className="flex flex-row cursor-pointer"
                            onClick={() => {
                                setOpen(!open);
                            }}
                        >
                            <p className="font-bold">View {open ? 'Less' : 'More'}</p>
                            <ArrowDown
                                className={`transition duration-200 ease-in-out chevron-custom transform ${
                                    open ? 'rotate-180' : ''
                                }`}
                            />
                        </div>
                    )}
                </div>
            }
        >
            <p className="p-4 my-3">{description}</p>
        </Window>
    );
};

export default InfoWindow;
