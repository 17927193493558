// Specification for set tags - clauses must be tagged with these tags to be used in the financial calculations & to be pulled into readiness

interface ITag {
    id: string;
    text: string;
}

interface IFixedTags {
    businessRates: ITag;
    serviceCharge: ITag;
    rentFreeCompletion: ITag;
    rentFreeAccess: ITag;
    termMonths: ITag;
    termYears: ITag;
    rentSqFt: ITag;
    rentYear: ITag;
    measurement: ITag;
    demiseUse: ITag;
    demiseCondition: ITag;
    landlordContactName: ITag;
    landlordContactEmail: ITag;
    landlordContactPhone: ITag;
    landlordLawyerContactName: ITag;
    landlordLawyerContactEmail: ITag;
    landlordLawyerContactPhone: ITag;
    noFormat: ITag;
    landlordLawyerContactCompany: ITag;
    commentClause: ITag;
}

export const FixedTags: IFixedTags = {
    businessRates: {
        id: 'business_rates',
        text: 'Business Rates (£ per sq ft)'
    },
    serviceCharge: {
        id: 'service_charge',
        text: 'Service Charge (£ per sq ft)'
    },
    rentFreeCompletion: {
        id: 'rent_free_completion',
        text: 'Rent Free From Completion (months)'
    },
    rentFreeAccess: {
        id: 'rent_free_access',
        text: 'Rent Free From Occupation/Access (months)'
    },
    termMonths: { id: 'term_months', text: 'Term (Months)' },
    termYears: { id: 'term_years', text: 'Term (Years)' },
    rentSqFt: { id: 'rent_sqft', text: 'Rent (£ per sq ft per year)' },
    rentYear: { id: 'rent_year', text: 'Rent (£ per year)' },
    measurement: { id: 'measurement', text: 'Measurement (sq ft)' },

    // Other tags, not used in financials. Must go after financial tags
    demiseUse: { id: 'demise_use', text: 'Demise Use' },
    demiseCondition: { id: 'demise_condition', text: 'Demise Condition' },
    landlordContactName: {
        id: 'landlord_contact_name',
        text: 'Landlord Contact Name'
    },
    landlordContactEmail: {
        id: 'landlord_contact_email',
        text: 'Landlord Contact Email'
    },
    landlordContactPhone: {
        id: 'landlord_contact_phone',
        text: 'Landlord Contact Phone Number'
    },
    landlordLawyerContactName: {
        id: 'landlord_lawyer_contact_name',
        text: 'Lawyer Contact Name'
    },
    landlordLawyerContactEmail: {
        id: 'landlord_lawyer_contact_email',
        text: 'Lawyer Contact Email'
    },
    landlordLawyerContactPhone: {
        id: 'landlord_lawyer_contact_phone',
        text: 'Lawyer Contact Phone Number'
    },
    landlordLawyerContactCompany: {
        id: 'landlord_lawyer_contact_company',
        text: 'Lawyer Contact Company'
    },
    noFormat: { id: 'no_format', text: 'Disable Formatting' },
    commentClause: { id: 'comment_clause', text: 'Comment On Clause' }
};
