import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';

import {
    GetTemplateCardDetailsDocument,
    useGetUserOrganisationsQuery,
    useGetUserTypeQuery,
    useNewTemplateMutation,
    UserType
} from '../../generated/graphql';
import { handleFetchError } from '../../shared/helpers';
import { useDesignatedUseChange } from '../../shared/hooks/useDesignatedUseChange';
import Input, { InputType } from '../../shared/Inputs';
import Select, { SelectProps } from '../../shared/Inputs/Select';
import Loading from '../../shared/Loading';
import Window from '../../shared/Window';

type NewTemplateWindowProps = {
    refetch: () => void;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
    countsRefetch: () => void;
};

const NewTemplateWindow = ({ refetch, countsRefetch, ...props }: NewTemplateWindowProps) => {
    const history = useHistory();

    // Organisations dropdown select
    const {
        data: organisationsData,
        loading: organisationsLoading,
        error: organisationsError
    } = useGetUserOrganisationsQuery();

    const { data: userTypeData } = useGetUserTypeQuery();

    const [organisationSelectData, setOrganisationSelectData] = useState<SelectProps['data']>([]);

    useEffect(() => {
        if (organisationsError || organisationsData?.user.__typename !== 'User') {
            handleFetchError('Failed to fetch your organisations', organisationsError, organisationsData?.user);
            return;
        }

        if (organisationsData.user.type === UserType.Agent) {
            const organisations =
                userTypeData?.user.trustedOrganisations?.map((org) => ({
                    id: org._id,
                    text: org.name
                })) ?? [];

            setOrganisationSelectData(organisations);
        }

        if ([UserType.Admin, UserType.Landlord].includes(organisationsData.user.type)) {
            setOrganisationID(organisationsData.user.organisation._id);
        }
    }, [organisationsData, organisationsError]);

    // Form values
    const templateNameRef = useRef<HTMLInputElement>(null);
    const [organisationID, setOrganisationID] = useState<string | null>();
    const templateDescriptionRef = useRef<HTMLTextAreaElement>(null);

    const { onChange, designatedUseOptions, associatedUses, selectValue } = useDesignatedUseChange();

    // Errors
    const [errors, setErrors] = useState<any>();

    const clearError = (field: string) => {
        const newErrors = { ...errors };
        delete newErrors[field];

        setErrors(newErrors);
    };

    const [newTemplate, { error: newTemplateError }] = useNewTemplateMutation();

    const handleConfirmClick = async () => {
        if (!organisationID) {
            setErrors({ ...errors, organisation: 'required' });
            return;
        }

        const templateName = templateNameRef.current?.value;
        const templateDescription = templateDescriptionRef.current?.value;

        const { data } = await newTemplate({
            variables: {
                organisationID: organisationID,
                name: templateName ?? 'Untitled Template',
                description: templateDescription ?? '',
                associatedUses
            },
            refetchQueries: [
                {
                    query: GetTemplateCardDetailsDocument
                }
            ]
        });

        if (newTemplateError) {
            handleFetchError('Failed to create the template', newTemplateError, data?.templateCreate);
            return;
        }

        refetch();
        countsRefetch();
        props.setShow(false);
        data?.templateCreate.__typename === 'Template' && history.push(`/templates/${data?.templateCreate._id}`);
    };

    if (organisationsLoading)
        return (
            <Window title="New Template" width="w-1/3" {...props}>
                <Loading />
            </Window>
        );

    return (
        <Window
            title="New Template"
            underlined
            width="w-1/3"
            primaryBtn
            primaryBtnText="Save"
            onPrimaryBtnClick={handleConfirmClick}
            {...props}
        >
            <form className="flex flex-col px-8 py-2">
                <Input
                    type={InputType.text}
                    placeholder="Template Name"
                    label="Template Name"
                    className="w-full mb-4"
                    ref={templateNameRef}
                />
                <Select
                    data={designatedUseOptions}
                    label="Designated Uses"
                    value={selectValue}
                    placeholder="Select use cases"
                    isMulti
                    className="md:mr-2 bg-navy-lightest w-full mb-4"
                    onChange={(value: any) => onChange(value)}
                />

                <p className="text-gray-600 font-medium text-sm mb-0.5">Template Description</p>
                <textarea
                    cols={50}
                    rows={5}
                    placeholder="Template Description"
                    className="w-full border border-navy-light rounded mb-4 p-4 focus:outline-none"
                    ref={templateDescriptionRef}
                ></textarea>
                {organisationsData?.user.type === UserType.Agent && (
                    <Select
                        placeholder="Associated Company"
                        label="Associated Company"
                        data={organisationSelectData}
                        onItemClick={(id) => {
                            setOrganisationID(id);
                            clearError('organisation');
                        }}
                        required
                        error={errors?.organisation}
                        className="mb-4"
                    />
                )}
            </form>
        </Window>
    );
};

export default NewTemplateWindow;
