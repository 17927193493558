import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { Link, useHistory, useLocation } from 'react-router-dom';

import Files from './Files';
import Journey from './Journey/index';
import FinalStageWindow from './Readiness/FinalStageWindow';
import IframeWindow from './Readiness/IframeWindow';
import Stage1Window from './Readiness/Stage1Window';
import Stage2Window from './Readiness/Stage2Window';
import Stage3Window from './Readiness/Stage3Window';
import Stage4Window from './Readiness/Stage4Window';
import Stage5Window from './Readiness/Stage5Window';
import Stage6Window from './Readiness/Stage6Window';
import StageContext from './Readiness/StageContext';
import { ReactComponent as TodoIllustration } from '../../../../assets/images/illustrations/todo.svg';
import { useGetDealUnitDetailsQuery } from '../../../../generated/graphql';
import Loading from '../../../../shared/Loading';
import DealCard from '../../../Deals/DealCard';
import useSetHeaderContent from '../hooks/useSetHeaderContent';
import TenantReadiness from '../TenantReadiness';
import UnitOverview from '../UnitOverview';

type TenantProps = {
    id: string;
    selectedTab: {
        index: number;
        tab: string;
    };
    setHeaderContent?: React.Dispatch<React.SetStateAction<React.ReactChild | null>>;
};

const Tenant = ({ id, selectedTab, setHeaderContent: _setHeaderContent }: TenantProps) => {
    const history = useHistory();
    const setHeaderContent = useSetHeaderContent(_setHeaderContent);
    // const [detailsData, setDetailsData] = useState<GetUnitDetailsQuery | undefined>()

    const {
        data: detailsData,
        loading: detailsLoading,
        error: detailsError
    } = useGetDealUnitDetailsQuery({
        variables: {
            dealID: id
        }
    });

    // Set header content and handle errors
    useEffect(() => {
        detailsData && !isMobile && setHeaderContent(detailsData.deal.unit!);
        // eslint-disable-next-line
    }, [detailsData]);

    useEffect(() => {
        // Redirect to units page if error e.g. ID in url is not valid
        if (detailsError) history.push('/');
    }, [detailsData, detailsError, history]);

    // Readiness Stages
    const [showStage1Window, setShowStage1Window] = useState<boolean>(false);
    const [showStage2Window, setShowStage2Window] = useState<boolean>(false);
    const [showStage3Window, setShowStage3Window] = useState<boolean>(false);
    const [showStage4Window, setShowStage4Window] = useState<boolean>(false);
    const [showStage5Window, setShowStage5Window] = useState<boolean>(false);
    const [showStage6Window, setShowStage6Window] = useState<boolean>(false);
    const [showFinalStageWindow, setShowFinalStageWindow] = useState<boolean>(false);

    // Readiness Iframes
    const [showLawyerWindow, setShowLawyerWindow] = useState<boolean>(false);
    const [showAgentWindow, setShowAgentWindow] = useState<boolean>(false);
    const [showContractorWindow, setShowContractorWindow] = useState<boolean>(false);
    const [showDBContractorWindow, setShowDBContractorWindow] = useState<boolean>(false);
    const [showManagerWindow, setShowManagerWindow] = useState<boolean>(false);
    const [showSpecialistWindow, setShowSpecialistWindow] = useState<boolean>(false);

    const location = useLocation();

    useEffect(() => {
        const closeAllModals = () => {
            setShowStage1Window(false);
            setShowStage2Window(false);
            setShowStage3Window(false);
            setShowStage4Window(false);
            setShowStage5Window(false);
            setShowStage6Window(false);
            setShowFinalStageWindow(false);
            setShowLawyerWindow(false);
            setShowAgentWindow(false);
            setShowContractorWindow(false);
            setShowDBContractorWindow(false);
            setShowManagerWindow(false);
            setShowSpecialistWindow(false);
        };

        switch (location.hash) {
            case '#readiness-stage1':
                closeAllModals();
                setShowStage1Window(true);
                break;
            case '#readiness-stage2':
                closeAllModals();
                setShowStage2Window(true);
                break;
            case '#readiness-stage3':
                closeAllModals();
                setShowStage3Window(true);
                break;
            case '#readiness-stage4':
                closeAllModals();
                setShowStage4Window(true);
                break;
            case '#readiness-stage5':
                closeAllModals();
                setShowStage5Window(true);
                break;
            case '#readiness-stage6':
                closeAllModals();
                setShowStage6Window(true);
                break;
            case '#readiness-stage0':
                closeAllModals();
                setShowFinalStageWindow(true);
                break;
        }
    }, [location]);

    if (detailsLoading)
        return (
            <div className="col-span-5">
                <Loading />
            </div>
        );

    if (!detailsData) return null;

    return (
        <>
            <StageContext.Provider
                value={{
                    dealID: id,
                    unitID: detailsData?.deal.unit?._id,
                    details: detailsData.deal.details,
                    backTo: location.pathname,
                    setShowStage1Window,
                    setShowStage2Window,
                    setShowStage3Window,
                    setShowStage4Window,
                    setShowStage5Window,
                    setShowStage6Window,
                    setShowFinalStageWindow,
                    setShowLawyerWindow,
                    setShowAgentWindow,
                    setShowContractorWindow,
                    setShowDBContractorWindow,
                    setShowManagerWindow,
                    setShowSpecialistWindow
                }}
            >
                {showStage1Window && <Stage1Window />}
                {showStage2Window && <Stage2Window />}
                {showStage3Window && <Stage3Window />}
                {showStage4Window && <Stage4Window />}
                {showStage5Window && <Stage5Window />}
                {showStage6Window && <Stage6Window />}
                {showFinalStageWindow && <FinalStageWindow />}
                {showLawyerWindow && <IframeWindow type="lawyer" />}
                {showAgentWindow && <IframeWindow type="agent" />}
                {showContractorWindow && <IframeWindow type="contractor" />}
                {showDBContractorWindow && <IframeWindow type="dbcontractor" />}
                {showManagerWindow && <IframeWindow type="manager" />}
                {showSpecialistWindow && <IframeWindow type="specialist" />}
            </StageContext.Provider>
            {/* CONTENT IN RELATION TO OPEN TAB */}
            {selectedTab.tab === 'Readiness' && (
                <div className="md:w-4/5 container mx-auto">
                    {/* Tenant Readiness */}
                    <div className="flex flex-col md:flex-row items-center justify-center md:mt-6">
                        <TodoIllustration className="w-60 hidden h-60 md:h-72 md:w-72 md:mr-8" />
                        <TenantReadiness data={detailsData} />
                    </div>

                    {/* My Journey */}
                    <div className="my-12">
                        <p className="text-base font-semibold text-newGray-darkish mb-2">My Journey</p>
                        <Journey dealID={detailsData.deal._id} data={detailsData} />
                    </div>

                    {/* Back to Deal button */}
                    <div className="w-full hidden text-center">
                        <Link
                            to={`/deals/${id}/negotiate`}
                            className="bg-newTeal-main w-36 h-12 font-semibold text-sm rounded inline-flex items-center justify-center text-center focus:outline-none"
                        >
                            Back to Deal
                        </Link>
                    </div>
                </div>
            )}
            {selectedTab.tab === 'More Information' && (
                <div className="md:w-4/5 container mx-auto flex flex-col md:flex-row">
                    <div className="md:mr-6 flex-1 mb-4">
                        {/* Details */}
                        <div className="flex flex-row justify-between items-end mb-3 mt-4">
                            <p className="text-base font-semibold text-newGray-darkish">Details</p>
                        </div>
                        <UnitOverview
                            _id={detailsData?.deal.unit?._id ?? ''}
                            tenant
                            address={detailsData?.deal.unit?.address!}
                            area={detailsData?.deal.unit?.area}
                            condition={detailsData?.deal.unit?.condition}
                            actors={detailsData?.deal.unit?.actors}
                            avatar={detailsData?.deal.unit?.avatar ?? ''}
                            url={detailsData?.deal.unit?.url ?? null}
                        />
                    </div>
                    <div className="flex-1">
                        {/* Unit Negotiation */}
                        <div className="flex flex-row justify-between items-end mb-3 mt-4">
                            <p className="text-base font-semibold text-newGray-darkish">Unit Negotiation</p>
                        </div>
                        <DealCard
                            id={detailsData.deal._id}
                            tenantView
                            key={detailsData.deal._id}
                            name={detailsData.deal.unit?.organisation.name ?? ''}
                            unitID={detailsData.deal.unit?._id ?? ''}
                            unitName={detailsData.deal.unit?.name ?? 'Untitled Demise'}
                            unitRating={detailsData.deal.unit?.rating ?? 0}
                            pending={detailsData.deal.pending}
                            state={detailsData.deal.state}
                            currentUserParty={detailsData.deal.currentUserParty}
                            round={detailsData.deal.round}
                            clauseSummary={detailsData.deal.clauseSummary}
                            compact={true}
                            className="mb-2"
                        />
                        {/* Landlord Files */}
                        {detailsData?.deal.unit?.tasks && (
                            <div className="mt-6">
                                <p className="text-base font-semibold text-newGray-darkish mb-3">
                                    Information from Landlord
                                </p>
                                <Files data={detailsData} />
                            </div>
                        )}
                    </div>
                </div>
            )}
        </>
    );
};

export default React.memo(Tenant);
