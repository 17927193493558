import React from 'react';

import Window from '../../../../shared/Window';

type ValueNotEnteredWindowProps = {
    clauses: {
        id: string;
        shortForm: string;
    }[];
    setShow: React.Dispatch<
        React.SetStateAction<
            {
                id: string;
                shortForm: string;
            }[]
        >
    >;
    setShowSendWindow: React.Dispatch<React.SetStateAction<boolean>>;
    setShowLowReadiness?: React.Dispatch<React.SetStateAction<boolean>>;
    lowReadinessWarning?: boolean;
};

const ValueNotEnteredWindow = ({
    clauses,
    setShow,
    setShowSendWindow,
    setShowLowReadiness = () => {},
    lowReadinessWarning
}: ValueNotEnteredWindowProps) => {
    return (
        <Window
            title="Clause Value Not Entered"
            width="w-1/4"
            setShow={() => {
                setShow([]);
                setShowLowReadiness(false);
                setShowSendWindow(false);
            }}
            primaryBtn
            primaryBtnText="Share Now"
            onPrimaryBtnClick={() => {
                setShow([]);
                setShowLowReadiness(true);
                !lowReadinessWarning && setShowSendWindow(true);
            }}
            secondaryBtn
            secondaryBtnText="Edit Values"
            onSecondaryBtnClick={() => setShowSendWindow(false)}
        >
            <div className="w-full flex flex-col items-center justify-around gap-4 text-center py-4 text-sm text-newGray-darkish">
                <p className="w-3/4">Leaving clauses with no value will allow the Tenant to make the first offer.</p>
                <p className="w-3/4">Are you sure you want to leave these clauses empty?</p>
                <div className="w-full pt-4 pb-2 bg-navy-light text-lg font-medium text-black overflow-y-auto h-40">
                    <div className="min-h-full">
                        {clauses.map((clause, index) => (
                            <button
                                className="font-medium focus:outline-none w-full block text-center mb-2"
                                onClick={() => {
                                    const clauseElement = document.getElementById(clause.id);
                                    clauseElement?.scrollIntoView({
                                        behavior: 'smooth',
                                        block: 'center'
                                    });
                                    clauseElement?.classList.add('border');
                                    clauseElement?.classList.add('border-newTeal-main');
                                    setShowSendWindow(false);
                                }}
                                key={index}
                            >
                                {clause.shortForm}
                            </button>
                        ))}
                    </div>
                </div>
            </div>
        </Window>
    );
};

export default ValueNotEnteredWindow;
