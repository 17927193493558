import React from 'react';

type TabProps = {
    wording: string;
    styles?: string;
};

const Tab = ({ wording, styles }: TabProps) => {
    return (
        <p className={styles}>
            <div className={`md:w-3/4 lg:w-5/6 xl:w-11/12 md:text-base xxl:text-lg h-full absolute -mt-2`} />
            {wording}
        </p>
    );
};

export default Tab;
