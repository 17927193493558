import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

import { DealCardContext } from '..';
import { ReactComponent as Chevron } from '../../../../assets/images/chevron/right/black.svg';
import { BulkSelectContext } from '../../../../shared/bulk/context';
import { DealCardImportant } from '../../../../shared/helpers';

type DealCardUnitReadinessButtonProps = {
    mostImportant: DealCardImportant;
};

const DealCardUnitReadinessButton = ({ mostImportant }: DealCardUnitReadinessButtonProps) => {
    const { selectMode } = useContext(BulkSelectContext);
    const { compact, unitRating, tenantView, unitID, id } = useContext(DealCardContext);
    return (
        <Link
            to={tenantView ? !selectMode && `/units/deal/${id}` : !selectMode && `/units/${unitID}`}
            className={`flex-1 w-1/2 flex items-center justify-between px-1 py-2 rounded-lg quickTransitionAll hover:shadow-md bg-navy-lightest`}
        >
            <div className={`${compact ? 'pl-3 border-l' : 'ml-3'} border-navy-light`}>
                <p className="font-light sm:text-xxs xl:text-xs">Unit Readiness</p>
                <p className="font-bold text-newGray-dark sm:text-base xl:text-xl">
                    {unitRating < 30
                        ? 'Very Poor'
                        : unitRating < 45
                        ? 'Poor'
                        : unitRating < 60
                        ? 'Fair'
                        : unitRating < 85
                        ? 'Good'
                        : 'Excellent'}
                </p>
            </div>
            {!compact && <Chevron className="xl:w-5 xl:h-5 sm:h-3 sm:w-3 sm:mr-0 xl:mr-2 text-newGray-darkish" />}
        </Link>
    );
};

export default DealCardUnitReadinessButton;
