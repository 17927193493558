import React from 'react';

import { AddedEventProps } from './AddedEvent';
import { ReactComponent as Swap } from '../../../../../assets/images/swap/black.svg';
import { formatClause } from '../../../../../shared/helpers';

type SwappedEventProps = AddedEventProps & {};

const SwappedEvent = ({ event }: SwappedEventProps) => {
    return (
        <div>
            <h3 className="font-semibold text-sm mb-2">
                {event.party.charAt(0) + event.party.slice(1).toLocaleLowerCase()} swapped the clause to
            </h3>
            <div className="border border-navy-light rounded py-1 px-2 flex flex-row items-center justify-between gap-2">
                <Swap className="w-4 h-4 text-newTeal-main" />
                <span
                    className="border-b border-newTeal-main"
                    dangerouslySetInnerHTML={{
                        __html:
                            formatClause(
                                event.change?.new.longForm ?? '',
                                event.change?.new.value,
                                event.change?.new.valueSuffix,
                                []
                            ) ?? ''
                    }}
                ></span>
            </div>
        </div>
    );
};

export default SwappedEvent;
