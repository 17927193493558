import React, { useContext, useEffect, useState } from 'react';

import { ReactComponent as ArrowDown } from '../../../../../../assets/images/chevron/down/black.svg';
import { ReactComponent as Delete } from '../../../../../../assets/images/delete/user/black.svg';
import { DealActorFragment, DealUserParty, User } from '../../../../../../generated/graphql';
import { Button } from '../../../../../../shared/button';
import DealHeaderContext from '../../DealHeaderContext';

type DealActorsListProps = {
    uniqueUsers: DealActorFragment[];
    currentParty?: false | DealUserParty | null | undefined;
    type: 'Landlord' | 'Tenant' | 'Agent' | 'Admin';
    setShowRemoveUserWindow: React.Dispatch<React.SetStateAction<boolean>>;
    setUser: React.Dispatch<React.SetStateAction<User | null>>;
    setShowEmailUserWindow: React.Dispatch<React.SetStateAction<boolean>>;
    typeOfArray?: string;
};

const DealActorsList = ({
    uniqueUsers,
    currentParty,
    type,
    setShowRemoveUserWindow,
    setUser,
    typeOfArray
}: DealActorsListProps) => {
    const { detailsData, completedDeal } = useContext(DealHeaderContext);
    const [arrayName, setArrayName] = useState<string>();
    const [collapse, setcollapse] = useState<boolean>(typeOfArray === 'LandlordArray');

    const setHeaderString = (typeOfArray: string) => {
        switch (typeOfArray) {
            case 'LandlordArray':
                return setArrayName(detailsData?.deal.unit?.organisation.name);
            case 'TenantArray':
                return setArrayName(detailsData?.deal.tenant?.name);
            case 'AgentArray':
                return setArrayName('Advisors');
            case 'AgentsInSameOrganisation':
                return setArrayName('Your Company');
            case 'AgentsNotInSameOrganisation':
                return setArrayName('Other Advisors');
        }
    };
    useEffect(() => {
        setHeaderString(typeOfArray || '');
    }, [typeOfArray]);

    return (
        <>
            <div
                className="bg-navy-light w-full h-12 p-2 inline-flex items-center justify-between"
                style={collapse ? { borderBottom: '1px white solid' } : {}}
                onClick={() => setcollapse(!collapse)}
            >
                <p className="text-base">{arrayName}</p>
                <ArrowDown
                    className={`transition duration-200 ease-in-out chevron-custom transform mr-1 ${
                        !collapse && 'rotate-180'
                    }`}
                />
            </div>
            {!collapse &&
                uniqueUsers?.map((user) => {
                    const isPending = user.pending || (!user.firstName && !user.lastName);

                    return (
                        <div
                            key={user._id}
                            className="flex flex-row relative items-center justify-start border-navy-light border-b"
                        >
                            <div className="w-100 flex flex-col justify-start relative">
                                <div className="w-100 flex justify-between items-center p-2">
                                    <div className="overflow-x-hidden" style={{ maxWidth: '280px' }}>
                                        {user.pending ? (
                                            <p className="text-gray-800 text-sm font-light">Invitation sent</p>
                                        ) : !user.activated && !user.pending ? (
                                            <p className="text-gray-800 text-sm font-light">User not invited</p>
                                        ) : (
                                            <h3
                                                style={{
                                                    textOverflow: 'ellipsis'
                                                }}
                                                className="text-base"
                                            >{`${user.firstName} ${user.lastName}`}</h3>
                                        )}
                                        <p
                                            style={{
                                                textOverflow: 'ellipsis',
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden'
                                            }}
                                            className="text-black text-base"
                                        >
                                            {user.email}
                                        </p>
                                    </div>
                                    {(['LandlordArray', 'TenantArray'].includes(typeOfArray!) && !isPending) ||
                                    completedDeal ? (
                                        <></>
                                    ) : (
                                        currentParty &&
                                        currentParty.toLowerCase() === type.toLowerCase() && (
                                            <Button
                                                onClick={() => {
                                                    setShowRemoveUserWindow(true);
                                                    setUser(user as User);
                                                }}
                                                height="9"
                                                className="border border-newGray-main hover:border-black rounded text-newGray-main hover:text-black w-9 p-2 transition duration-150 ml-2"
                                            >
                                                <Delete />
                                            </Button>
                                        )
                                    )}
                                </div>
                            </div>
                        </div>
                    );
                })}
        </>
    );
};

export default DealActorsList;
