import { isEmpty } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroller';

import CompanyCard from './CompanyCard';
import CompanyDetailsWindow, { userFilterConfig } from './CompanyDetailsWindow';
import CreateCompany from './CreateCompany';
import DeleteCompanyWindow from './DeleteCompanyWindow';
import EditCompanyWindow from './EditCompanyWindow';
import { ReactComponent as External } from '../../../assets/images/external/black.svg';
import { ReactComponent as FiltersIcon } from '../../../assets/images/filters/sliders.svg';
import { ReactComponent as Search } from '../../../assets/images/search/black.svg';
import {
    OrganisationCardFragment,
    OrganisationSortFields,
    useGetAllOrganisationsQuery,
    useOrganisationFilterCountsQuery,
    UserType
} from '../../../generated/graphql';
import { Button } from '../../../shared/button';
import { FilterContext, FilterProvider } from '../../../shared/Filter/context';
import { FilterConfig, FilterType, FilterWindow } from '../../../shared/Filter/FilterWindow';
import { transformFilters } from '../../../shared/Filter/helpers';
import { PageType, WindowType } from '../../../shared/Filter/hooks/useDisclosure';
import SortChip from '../../../shared/Filter/SortChip';
import { debounce, handleFetchError } from '../../../shared/helpers';
import useMobileScreen from '../../../shared/hooks/useMobileScreen';
import Input, { InputType } from '../../../shared/Inputs';
import { RadioValue } from '../../../shared/Inputs/Radio';
import Select from '../../../shared/Inputs/Select';
import Loading from '../../../shared/Loading';
import { AccountType } from '../Users';

export enum CompanyType {
    ALL = 'All',
    LANDLORD = 'Landlord',
    PRACTICE = 'Practice',
    TENANT = 'Tenant'
}

const limit = 35;

const Companies = () => {
    const [organisations, setOrganisations] = useState<OrganisationCardFragment[]>([]);
    const [hasNextPage, setNextPage] = useState<boolean>(false);
    const [showCreateWindow, setShowCreateWindow] = useState<boolean>(false);
    const [refetchOrganisations, setRefetchOrganisations] = useState<boolean>(false);
    const [onSuccess, setOnSuccess] = useState<boolean>(false);
    const [showAdminEditOrganisationWindow, setShowAdminEditOrganisationrWindow] = useState<boolean>(false);
    const [showAdminDeleteCompanyWindow, setShowAdminDeleteCompanyWindow] = useState<boolean>(false);
    const [companyDetailsWindow, setCompanyDetailsWindow] = useState<boolean>(false);
    const [organisation, setOrganisation] = useState<any>();
    const { values, window, openWindow, sort, chips, filters } = useContext(FilterContext);
    const [usersData, setUsersData] = useState<UserType[] | undefined>();
    const {
        loading,
        data,
        fetchMore,
        error,
        refetch: searchOrganisations
    } = useGetAllOrganisationsQuery({
        variables: {
            limit,
            offset: 0,
            sort: sort
                ? {
                      field: sort.field as OrganisationSortFields,
                      order: sort.order
                  }
                : undefined,
            filter: {
                ...transformFilters(filters, values),
                ...(usersData ? { type: usersData } : {})
            }
        }
    });

    const { data: countsData, refetch: refetchCounts } = useOrganisationFilterCountsQuery({
        fetchPolicy: 'cache-and-network',
        variables: {
            filter: {
                ...transformFilters(filters, values)
            }
        }
    });

    useEffect(() => {
        handleFetchError('Could not get all companies', error, data?.organisations);
        searchOrganisations();
        refetchCounts();
    }, [data, error]);

    useEffect(() => {
        // Check for errors
        if (error) return handleFetchError('Could not load your demises', error, data?.organisations.results);
        searchOrganisations();
        refetchCounts();
        setOrganisations(data?.organisations.results ?? []);
    }, [data, error]);

    const options = [
        {
            value: CompanyType.ALL,
            label: `${CompanyType.ALL} (${countsData?.organisationFilterCounts.all ?? '...'})`
        },
        {
            value: CompanyType.LANDLORD,
            label: `${CompanyType.LANDLORD} (${countsData?.organisationFilterCounts.landlord ?? '...'})`
        },
        {
            value: CompanyType.PRACTICE,
            label: `${CompanyType.PRACTICE} (${countsData?.organisationFilterCounts.agent ?? '...'})`
        },
        {
            value: CompanyType.TENANT,
            label: `${CompanyType.TENANT} (${countsData?.organisationFilterCounts.tenant ?? '...'})`
        }
    ];

    const [companyTypeFilter, setCompanyTypeFilter] = useState<{
        value: string;
        label: string;
    }>({
        value: AccountType.ALL,
        label: `${AccountType.ALL} (...)`
    });

    useEffect(() => {
        setCompanyTypeFilter(options.find((option) => option.value === companyTypeFilter.value)!);
    }, [countsData]);

    useEffect(() => {
        switch (companyTypeFilter.value) {
            case CompanyType.LANDLORD:
                setUsersData([UserType.Admin, UserType.Landlord]);
                break;
            case CompanyType.PRACTICE:
                setUsersData([UserType.Agent]);
                break;
            case CompanyType.ALL:
                setUsersData(undefined);
                break;
            case CompanyType.TENANT:
                setUsersData([UserType.Tenant]);
        }
    }, [data, error, companyTypeFilter]);

    const refetchCreate = async () => {
        await searchOrganisations();
        await refetchCounts();
        setOrganisations(data?.organisations.results ?? []);
        setOnSuccess(false);
    };

    useEffect(() => {
        if (refetchOrganisations || onSuccess) {
            searchOrganisations();
            refetchCounts();
            refetchCreate();
            setRefetchOrganisations(false);
        }
    }, [refetchOrganisations, onSuccess]);

    useEffect(() => {
        setOrganisations(data?.organisations.results ?? []);
        setNextPage(!!data?.organisations.pageInfo.hasNextPage);
    }, [data]);

    const loadMore = async () => {
        if (!hasNextPage) return;

        const { data: newData } = await fetchMore({
            variables: {
                limit,
                offset: organisations.length ?? 0
            }
        });

        setOrganisations((organisations) => [...organisations, ...newData.organisations.results]);

        setNextPage(newData.organisations.pageInfo.hasNextPage);
    };

    const handleChange = debounce((query?: string) => {
        searchOrganisations({ query });
        refetchCounts({ query });
    }, 250);

    const isMobile = useMobileScreen();

    return (
        <>
            {showCreateWindow && <CreateCompany onSuccess={setOnSuccess} setShow={setShowCreateWindow} />}
            {companyDetailsWindow && organisation && (
                <FilterProvider key="companyDetails" page="companyDetails" filters={userFilterConfig()}>
                    <CompanyDetailsWindow organisationId={organisation._id} setShow={setCompanyDetailsWindow} />
                </FilterProvider>
            )}
            {showAdminDeleteCompanyWindow && organisation && (
                <DeleteCompanyWindow
                    organisation={organisation}
                    setShow={setShowAdminDeleteCompanyWindow}
                    setRefetchOrganisations={setRefetchOrganisations}
                />
            )}
            {showAdminEditOrganisationWindow && (
                <EditCompanyWindow organisation={organisation} setShow={setShowAdminEditOrganisationrWindow} />
            )}
            <div className={`w-full ${isMobile ? 'px-4 pb-2 ' : 'p-6'} `}>
                <div
                    className={`flex ${
                        isMobile ? 'flex-col' : 'flex-row'
                    } w-full border-newGray-main border-b items-center pb-2`}
                >
                    <Button
                        onClick={() => setShowCreateWindow(true)}
                        background="newTeal-main"
                        text="white"
                        height="12"
                        border="transparent"
                        className={`px-4 mr-2 my-2 ${
                            isMobile ? 'w-full ml-2 mb-2' : ' w-50 justify-around'
                        } whitespace-no-wrap font-semibold flex items-center`}
                    >
                        <External className="w-4 -h-4 mr-2" />
                        Create Company
                    </Button>
                    <div className="flex flex-row w-full">
                        <div className={`relative ${isMobile ? 'w-full' : 'w-100'}`}>
                            <Search className="w-5 h-5 absolute left-0 top-0 ml-4 z-20" style={{ top: '13px' }} />
                            <Input
                                type={InputType.text}
                                placeholder="Search Companies"
                                className="w-full h-12 pl-12"
                                autoFocus
                                onChange={(e) => handleChange(e.target.value)}
                            />
                        </div>
                        <Button
                            background="white"
                            text="black"
                            className="filter-button-size ml-2"
                            onClick={() =>
                                openWindow({
                                    type: WindowType.ALL,
                                    page: PageType.COMPANIES
                                })
                            }
                        >
                            <FiltersIcon />
                        </Button>
                    </div>
                </div>
                <div className="mb-4 flex mt-1 items-center flex-wrap">
                    <div className="w-48" style={{ marginTop: '-16px' }}>
                        <Select
                            label={CompanyType.ALL}
                            value={companyTypeFilter}
                            onChange={(value) => setCompanyTypeFilter(value)}
                            data={options.map((option) => ({
                                id: option.value,
                                text: option.label
                            }))}
                        />
                    </div>
                    {sort && <SortChip sortOptions={getOrganisationFields()} />}
                    {values && !loading && chips && FilterContext && chips()}
                </div>
                {isEmpty(organisations) && loading && (
                    <div className="container mx-auto p-4">
                        <Loading />
                    </div>
                )}
                {data?.organisations && data?.organisations.results.length > 0 && (
                    <InfiniteScroll
                        loadMore={loadMore}
                        useWindow={false}
                        getScrollParent={() => document.getElementById('content')}
                        hasMore={data?.organisations.pageInfo.hasNextPage ?? false}
                        loader={<div className="container mx-auto p-4">{loading && <Loading />}</div>}
                    >
                        {companyTypeFilter &&
                            organisations?.map((organisation) => (
                                <CompanyCard
                                    id={organisation._id}
                                    key={organisation._id}
                                    name={organisation.name}
                                    avatar={organisation.avatar}
                                    members={organisation.membersSize}
                                    created={organisation.created}
                                    website={organisation.website ?? undefined}
                                    type={organisation.type ?? undefined}
                                    organisation={organisation}
                                    practiceType={organisation.practiceType}
                                    setOrganisation={setOrganisation}
                                    setCompanyDetailsWindow={setCompanyDetailsWindow}
                                    setShowAdminDeleteCompanyWindow={setShowAdminDeleteCompanyWindow}
                                    setShowAdminEditOrganisationWindow={setShowAdminEditOrganisationrWindow}
                                />
                            ))}
                    </InfiniteScroll>
                )}
                {window?.page === PageType.COMPANIES && (
                    <FilterWindow
                        page={PageType.COMPANIES}
                        config={organisationFilterConfig()}
                        sortOptions={getOrganisationFields()}
                    />
                )}
            </div>
        </>
    );
};

export const organisationFilterConfig = (): FilterConfig => [
    {
        id: 'created',
        label: 'Date Created',
        type: FilterType.RangeDate
    },
    {
        id: 'member',
        label: 'Number Of Users',
        type: FilterType.Range,
        suffix: 'user(s)'
    }
];

export const getOrganisationFields = (): RadioValue[] => [
    {
        id: OrganisationSortFields.CompanyName,
        label: 'Company Name '
    },
    {
        id: OrganisationSortFields.NumberOfUsers,
        label: 'Number Of Users '
    },
    {
        id: OrganisationSortFields.CompanyCreatedDate,
        label: 'Date Created'
    }
];

export default Companies;
