import React, { useEffect, useState } from 'react';

import LowSendWindow from './LowSendWindow';
import {
    DealDetailsFragment,
    DealSendType,
    DealState,
    DealUserParty,
    useGetDealTenantDetailsLazyQuery
} from '../../../../generated/graphql';
import Window from '../../../../shared/Window';
import useLowReadinessWarning from '../../Deal/hooks/useLowReadinessWarning';

type DealRoundCompleteWindowProps = {
    deal: DealDetailsFragment;
    currentUserParty: DealUserParty | null | undefined;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
    setShareWindowType: React.Dispatch<React.SetStateAction<DealSendType>>;
    setShowSendWindow: React.Dispatch<React.SetStateAction<boolean>>;
    setForceReadinessClose: React.Dispatch<React.SetStateAction<boolean>>;
};

const DealRoundCompleteWindow = ({
    currentUserParty,
    deal,
    setShow,
    setShareWindowType,
    setShowSendWindow,
    setForceReadinessClose
}: DealRoundCompleteWindowProps) => {
    const checkIfLowReadinessWarning = useLowReadinessWarning(deal.__typename === 'Deal' ? deal : undefined);
    const [lowReadinessCheck, setLowReadinessCheck] = useState<{
        show: boolean;
        totalStages?: number;
        rounds?: number;
        stages?: number;
    }>({ show: false });
    const [showWarning, setShowWarning] = useState<boolean>(false);

    const [tenantDetails, { data: tenantDetailsData }] = useGetDealTenantDetailsLazyQuery();

    useEffect(() => {
        if (!deal || deal.__typename !== 'Deal') return;
        if (currentUserParty === 'TENANT') {
            tenantDetails({
                variables: {
                    dealID: deal._id
                }
            });
        }
    }, [deal, currentUserParty, tenantDetails]);

    const checkToShowWarning = async () => {
        const needsReadinessWarning = checkIfLowReadinessWarning(
            tenantDetailsData?.deal.__typename === 'Deal' ? tenantDetailsData.deal : undefined
        );
        setLowReadinessCheck(needsReadinessWarning);
        if (!needsReadinessWarning.show) {
            setShowWarning(false);
            shareNow();
        } else {
            setShowWarning(true);
        }
        sessionStorage.setItem('round_window_shown', '1');
    };

    const shareNow = () => {
        setShowWarning(false);
        setShow(false);
        setShareWindowType(currentUserParty === DealUserParty.Landlord ? DealSendType.Tenant : DealSendType.Landlord);
        setShowSendWindow(true);
        setForceReadinessClose(true);
        sessionStorage.setItem('round_window_shown', '1');
    };

    return (
        <>
            {showWarning && (
                <LowSendWindow
                    setShow={setShowWarning}
                    shareNow={shareNow}
                    stages={lowReadinessCheck.stages}
                    totalStages={lowReadinessCheck.totalStages}
                    id={currentUserParty !== 'TENANT' && deal.unit ? deal.unit._id : deal._id}
                    round={deal.round?.count}
                    rating={currentUserParty !== 'TENANT' && deal.unit ? deal.unit?.rating : undefined}
                />
            )}
            {!showWarning && (
                <Window
                    title="HoTs Ready To Share"
                    width="w-2/5"
                    setShow={setShow}
                    titleCenter
                    onCloseClick={() => {
                        sessionStorage.setItem('round_window_shown', '1');
                        setShow(false);
                    }}
                    primaryBtn
                    primaryBtnText="Share Now"
                    onPrimaryBtnClick={deal.state === DealState.Complete ? shareNow : checkToShowWarning}
                    secondaryBtn
                    secondaryBtnText="Share Later"
                    onSecondaryBtnClick={() => {
                        sessionStorage.setItem('round_window_shown', '1');
                        setShow(false);
                    }}
                >
                    <div className="pb-4 pt-8 md:pt-4">
                        <h3 className="font-medium text-center text-lg">All clauses have now been actioned.</h3>
                        <p className="text-newGray-darkish text-center text-medium w-48 mx-auto mt-3">
                            Would you like to share with the{' '}
                            {currentUserParty === DealUserParty.Landlord ? 'Tenant' : 'Landlord'} now?
                        </p>
                    </div>
                </Window>
            )}
        </>
    );
};

export default DealRoundCompleteWindow;
