import React from 'react';
import { Link } from 'react-router-dom';

import { useGetDealTenantDetailsQuery } from '../../generated/graphql';
import Card from '../../shared/Grid/Card';
import Loading from '../../shared/Loading';
import ReadinessBar from '../../shared/ReadinessBar';
type TenantReadinessCardProps = {
    id: string;
    name: string;
    buildingName: string;
};

const TenantReadinessCard = ({ id, name, buildingName }: TenantReadinessCardProps) => {
    // Get Tenant Readiness  Stage
    const { data: detailsData, loading: detailsLoading } = useGetDealTenantDetailsQuery({
        variables: {
            dealID: id
        },
        fetchPolicy: 'cache-and-network'
    });

    // Details
    const { ...details } = detailsData?.deal.__typename === 'Deal' && detailsData.deal.details;
    delete details['__typename'];

    // Get the total number of stages
    const total = Object.keys(details).length ?? 6;

    // Get the total number of stages that's been actioned
    let totalCompleted = 0;
    for (const key in details) {
        if (details[key]?.completed) {
            totalCompleted++;
        }
    }
    if (detailsLoading) return <Loading />;

    return (
        <Card options={[]} className={`overflow-hidden`}>
            <Link to={`/units/deal/${id}`}>
                <div className="px-4 pt-4">
                    <h1 className="text-xl font-semibold leading-7">{name}</h1>
                    <h1 className="text-xl font-semibold leading-7 mb-1">{buildingName}</h1>
                </div>
                <div className="bg-navy-lightest rounded mx-4 mb-4 mt-3 overflow-hidden">
                    <div className="px-3 pt-2 pb-1">
                        <p className="text-sm">Tenant Readiness</p>
                        <span className="text-3xl font-semibold leading-10">Stages {totalCompleted}</span>
                        <span className="text-xs">/{total}</span>
                    </div>
                    <ReadinessBar rating={totalCompleted} total={total} />
                </div>
            </Link>
        </Card>
    );
};

export default TenantReadinessCard;
