import { Field, Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import * as Yup from 'yup';

import ErrorMessage from './ErrorMessage';
import { PracticeType, useGetInviteDetailsQuery, useNewInvitedUserMutation, UserType } from '../../generated/graphql';
import Profile from '../../layout/SideBar/components/Profile';
import { Button } from '../../shared/button';
import { InputField } from '../../shared/Formik/InputField';
import { InputType } from '../../shared/Inputs';
import Select from '../../shared/Inputs/Select';
import Loading from '../../shared/Loading';

type LoginProps = {
    token: string;
};

export type LoginValues = {
    firstName: string;
    lastName: string;
    company?: string;
    mobile: string;
    email?: string;
    password: string;
    confirmPassword: string;
    practiceType?: PracticeType;
};

const LoginForm = ({ token }: LoginProps) => {
    const history = useHistory();
    // Get details using the token
    const {
        loading: detailsLoading,
        error: detailsError,
        data: detailsData
    } = useGetInviteDetailsQuery({
        variables: {
            token
        }
    });

    const [createUser] = useNewInvitedUserMutation();

    const [isCompany, setIsCompany] = useState<boolean>(false);

    const handleSubmit = async (values: any) => {
        await createUser({
            variables: {
                ...values,
                organisation: values.company,
                mobile: values.mobile.replace(/\s/g, ''),
                token
            }
        });

        history.push('/');
    };

    useEffect(() => {
        setIsCompany(
            detailsData?.invite?.organisation.members
                ? detailsData.invite?.organisation.members.length <= 1
                    ? true
                    : false
                : true
        );
    }, [detailsData, detailsError]);

    if (detailsLoading) return <Loading />;

    if (detailsError) {
        if (
            detailsError.graphQLErrors[0].extensions?.code === 'INVALID_TOKEN' ||
            detailsError.graphQLErrors[0].extensions?.code === 'UNAUTHORISED'
        ) {
            history.push('/login');
        } else {
            return <ErrorMessage message={detailsError.message} />;
        }
    }

    const hasMoreMembers = detailsData?.invite?.organisation?.members?.length > 1;

    const practiceTypeOption = [
        {
            id: PracticeType.Agency,
            text: 'Agency Practice'
        },
        {
            id: PracticeType.Legal,
            text: 'Legal Practice'
        },
        {
            id: PracticeType.PropertyManagement,
            text: 'Property Management Practice'
        }
    ];

    const initialValues: LoginValues = {
        practiceType: undefined,
        firstName: '',
        lastName: '',
        company: undefined,
        email: detailsData?.invite.user.email,
        mobile: '',
        password: '',
        confirmPassword: ''
    };

    const LoginSchema = Yup.object().shape({
        practiceType: Yup.lazy((value) => {
            if (value !== undefined) {
                return Yup.string().required('Please select practice type');
            }
            return Yup.mixed().notRequired();
        }),
        firstName: Yup.string().required('Please enter an first name'),
        lastName: Yup.string().required('Please enter an last name'),
        company: Yup.lazy((value) => {
            if (value !== undefined) {
                return Yup.string().required('Please enter company');
            }
            return Yup.mixed().notRequired();
        }),
        mobile: Yup.string().required('Please enter an mobile'),
        email: Yup.string().email('Please enter a valid email').required('Please enter an email address'),
        password: Yup.string()
            .required('Password is required')
            .matches(
                /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
                "Password must be at least 8 characters and contain both at least one number and one special character (~`! @#$%^&*_-+={[}]|:;'<,>.?/)"
            ),
        confirmPassword: Yup.string()
            .required('Please confirm password')
            .oneOf([Yup.ref('password'), null], 'Password does not match')
    });

    const isAgent = detailsData?.invite?.user?.type.includes(UserType.Agent);

    return (
        <Formik initialValues={initialValues} validationSchema={LoginSchema} onSubmit={handleSubmit}>
            {({ setFieldValue }) => (
                <Form className="bg-white px-8 pt-6 mb-4 sm:h-auto max-w-sm md:max-w-sm w-full flex flex-col items-center">
                    <div>
                        <div className="text-2xl mb-4 flex justify-center">
                            {hasMoreMembers && (
                                <Profile
                                    name={
                                        detailsData?.invite?.organisation.name
                                            ? detailsData.invite.organisation.name
                                            : ''
                                    }
                                    src={detailsData?.invite.organisation.avatar}
                                    className="border border-black"
                                />
                            )}
                        </div>
                        {hasMoreMembers ? (
                            <p className="text-center mb-6">
                                You have been invited by{' '}
                                {detailsData ? (
                                    <span>
                                        {' '}
                                        <span className="font-bold">{detailsData?.invite.organisation.name}</span>
                                    </span>
                                ) : (
                                    'a new team'
                                )}{' '}
                                to Kato. Here you will be able to negotiate the terms for your new space in a fully
                                informed, user-friendly way.
                            </p>
                        ) : (
                            <p className="text-center mb-6">
                                You have been invited to sign up to Kato. Here you will be able to negotiate the terms
                                for your new space in a fully informed, user friendly way.
                            </p>
                        )}
                        <label className="text-gray-600 font-medium text-sm ">First Name</label>
                        <Field
                            name="firstName"
                            component={InputField}
                            containerStyle={`h-20`}
                            className={`mb-2 bg-navy-lightest h-12 w-full`}
                            placeholder="First Name"
                            type={InputType.text}
                        />
                        <label className="text-gray-600 font-medium text-sm ">Last Name</label>
                        <Field
                            name="lastName"
                            component={InputField}
                            containerStyle={`h-20`}
                            className={`mb-2 bg-navy-lightest h-12 w-full`}
                            placeholder="Last Name"
                            type={InputType.text}
                        />
                        {isCompany && (
                            <>
                                <label className="text-gray-600 font-medium text-sm ">
                                    {isAgent ? 'Practice Name' : 'Company'}
                                </label>
                                <Field
                                    name="company"
                                    component={InputField}
                                    containerStyle={`h-20`}
                                    className={`mb-2 bg-navy-lightest h-12 w-full`}
                                    placeholder={isAgent ? 'Practice Name' : 'Company'}
                                    type={InputType.text}
                                />
                            </>
                        )}
                        {!hasMoreMembers && isAgent && (
                            <>
                                <label className="text-gray-600 font-medium text-sm ">Practice Type</label>
                                <Field
                                    name="practiceType"
                                    containerClassName="mt-0 h-20"
                                    component={Select}
                                    className="mb-2 w-full"
                                    data={practiceTypeOption}
                                    placeholder="Select Practice Type"
                                    graySelect={true}
                                    controlNoBorder={true}
                                    onChange={(e) => setFieldValue('practiceType', e.value)}
                                />
                            </>
                        )}
                        <label className="text-gray-600 font-medium text-sm ">Email</label>
                        <Field
                            disabled={true}
                            name="email"
                            component={InputField}
                            containerStyle={`h-20`}
                            className={`mb-2 bg-navy-lightest h-12 w-full`}
                            placeholder="Email"
                            type={InputType.email}
                        />
                        <label className="text-gray-600 font-medium text-sm ">Business Mobile</label>
                        <Field
                            name="mobile"
                            component={InputField}
                            containerStyle={`h-20`}
                            className={`mb-2 bg-navy-lightest h-12  w-full`}
                            placeholder="Business Mobile"
                            type={InputType.phone}
                        />
                        <label className="text-gray-600 font-medium text-sm ">Password</label>
                        <Field
                            name="password"
                            component={InputField}
                            containerStyle={`h-auto mb-7`}
                            className={`mb-2 bg-navy-lightest h-12 w-full `}
                            placeholder="Password"
                            type={InputType.password}
                        />
                        <label className="text-gray-600 font-medium text-sm ">Confirm Password</label>
                        <Field
                            name="confirmPassword"
                            component={InputField}
                            containerStyle={`h-20`}
                            className={`mb-2 bg-navy-lightest h-12 w-full`}
                            placeholder="Confirm Password"
                            type={InputType.password}
                        />
                        <Button type="submit" background="newTeal-main" text="white" className="w-full">
                            Create Account
                        </Button>
                        <p className="text-center text-gray-600">
                            Already have an account? <Link to="/login">Login instead</Link>
                        </p>
                    </div>
                </Form>
            )}
        </Formik>
    );
};

export default LoginForm;
