import { Reference, StoreObject, useApolloClient } from '@apollo/client';
import React from 'react';

import useDeleteClause from './useDeleteClause';
import {
    DealDetailsFragment,
    DealUserParty,
    DeleteClauseMutation,
    GetDealDetailsDocument,
    GetDealDetailsQuery,
    HistoryEventType,
    useDeleteClauseMutation,
    useGetLayoutUserInfoQuery
} from '../../../generated/graphql';
import Window from '../../Window';

type ConfirmClauseDeleteProps = {
    deal: DealDetailsFragment | null;
    templateID?: string;
    clauseID: string;
    parentID: string;
    groupID: string;
    type: DealType;
    remove?: boolean;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
    setClauseDeleted?: React.Dispatch<React.SetStateAction<boolean>>;
};

export enum DealType {
    DEAL,
    DEAL_UNDO,
    TEMPLATE
}

const ConfirmClauseDelete = ({
    deal,
    clauseID,
    parentID,
    groupID,
    templateID,
    type,
    remove = true,
    setShow,
    setClauseDeleted
}: ConfirmClauseDeleteProps) => {
    const client = useApolloClient();
    const [deleteClause, { error: deleteClauseError }] = useDeleteClauseMutation();

    const { data: userData } = useGetLayoutUserInfoQuery();

    const updateCache = useDeleteClause(type);

    const handleDeleteClick = async () => {
        await deleteClause({
            variables: {
                dealID: deal?._id ?? '',
                parentID,
                clauseID
            },
            update(store, { data }) {
                if (!data || data.dealClauseDelete.__typename !== 'DeletedObject') return;

                if (remove) {
                    updateCache<DeleteClauseMutation>(
                        store,
                        data.dealClauseDelete.__typename === 'DeletedObject'
                            ? data.dealClauseDelete.deletedObjectID
                            : '',
                        templateID || deal?._id
                    );
                } else {
                    // Clause is not actually removed, just set to deleted awaiting confirmation

                    let dealData = store.readQuery<GetDealDetailsQuery>({
                        query: GetDealDetailsDocument,
                        variables: {
                            dealID: deal?._id
                        }
                    });

                    if (!dealData || dealData.deal.__typename !== 'Deal') return;

                    store.modify({
                        id: store.identify({
                            __typename: 'Clause',
                            _id: clauseID
                        }),
                        fields: {
                            history(currentHistory = []) {
                                return [
                                    ...currentHistory,
                                    {
                                        _id: 'new_history_event',
                                        type: HistoryEventType.Delete,
                                        party: deal?.currentUserParty ?? DealUserParty.Other,
                                        actor: {
                                            _id: userData?.user.__typename === 'User' ? userData.user._id : '',
                                            firstName:
                                                userData?.user.__typename === 'User' ? userData.user.firstName : '',
                                            lastName: userData?.user.__typename === 'User' ? userData.user.lastName : ''
                                        },
                                        change: {
                                            new: {},
                                            old: {}
                                        }
                                    }
                                ];
                            }
                        }
                    });
                }
            }
        });

        if (deleteClauseError) {
            return;
            // handleFetchError(
            //     "Could not delete the clause",
            //     deleteClauseError,
            //     deleteClauseData?.dealClauseDelete
            // )
        } else {
            client.cache.modify({
                id: client.cache.identify({
                    __typename: 'Group',
                    _id: groupID
                }),
                fields: {
                    clauses(existingClauses = [], { readField }) {
                        return existingClauses.filter(
                            (clauseRef: Reference | StoreObject | undefined) => clauseID !== readField('_id', clauseRef)
                        );
                    }
                }
            });
        }

        setClauseDeleted && setClauseDeleted(true);
        setShow(false);
    };

    return (
        <Window
            title="Remove Clause"
            titleCenter
            setShow={setShow}
            primaryBtn
            onPrimaryBtnClick={handleDeleteClick}
            secondaryBtn
            onSecondaryBtnClick={() => setShow(false)}
        >
            <div className="flex flex-col items-center">
                <h2 className="text-center text-lg my-8 w-4/5">
                    Are you sure you want to <span className="font-semibold text-uppercase">remove</span> this clause?
                    This process is irreversible.
                </h2>
            </div>
        </Window>
    );
};

export default ConfirmClauseDelete;
