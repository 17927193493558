import React, { useContext } from 'react';

import DealHeaderActors from './components/DealHeaderActors';
import DealHeaderButtons from './components/DealHeaderButtons';
import DealHeaderWindows from './components/DealHeaderWindows';
import useMobileScreen from '../../../../shared/hooks/useMobileScreen';
import SendDealWindow from '../../../../v2/common/MobileWindow/SendDealWindow';
import DealHeaderContext from '../../../../v2/pages/Deals/DealHeader/DealHeaderContext';

type DealHeaderContextType = {
    setHeaderContent: React.Dispatch<React.SetStateAction<React.ReactChild | null>>;
    showActors?: boolean;
};

const DealHeaderContent = ({ setHeaderContent, showActors = true }: DealHeaderContextType) => {
    const { showShareWindow, isTitlesReordering, isClauseReordering } = useContext(DealHeaderContext);
    const mobileScreen = useMobileScreen();

    return (
        <>
            {showShareWindow && <SendDealWindow />}
            <DealHeaderWindows />
            <div className={`top-0 z-10 sticky shadow-sm bg-navy-lightest `}>
                <div
                    className={`flex flex-row ${
                        mobileScreen ? '' : 'flex-wrap'
                    } content-center items-center lg:justify-between md:justify-center justify-between bg-white px-0 md:px-6 py-2 sticky z-20`}
                >
                    {!isTitlesReordering && !isClauseReordering && showActors ? (
                        <DealHeaderActors setHeaderContent={setHeaderContent} />
                    ) : (
                        <div />
                    )}
                    <DealHeaderButtons />
                </div>
            </div>
        </>
    );
};

export default DealHeaderContent;
