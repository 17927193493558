import { QueryLazyOptions } from '@apollo/client';
import React, { Dispatch, SetStateAction } from 'react';

import {
    DealApprovalType,
    DealDetailsFragment,
    DealSendType,
    DealUserParty,
    Exact,
    GetDealDetailsQuery,
    Maybe
} from '../../../../generated/graphql';

import { DealHeaderPage } from '.';

export type ValuelessClause = {
    id: string;
    shortForm: string;
};
export type DownloadData = (dealID: string) => Promise<void>;
export type DownloadPDF = (dealID: string) => Promise<void>;

type DealHeaderContextType = {
    // Show Send Window
    showSendWindow: boolean;
    setShowSendWindow: Dispatch<SetStateAction<boolean>>;
    // Round Complete Window
    showRoundCompleteWindow: boolean;
    setShowRoundCompleteWindow: Dispatch<SetStateAction<boolean>>;
    // Shown Round Complete Window
    shownRoundCompleteWindow: boolean;
    // Deal Agreed Window
    dealAgreedWindowOpen: boolean | undefined;
    // Share Window Type
    shareWindowType: DealSendType;
    setShareWindowType: Dispatch<SetStateAction<DealSendType>>;
    // Send Window Success
    sendWindowSuccess: boolean;
    setSendWindowSuccess: Dispatch<SetStateAction<boolean>>;
    // Tenant Pending Members Window
    showTenantPendingMembersWindow: boolean;
    setShowTenantPendingMembersWindow: Dispatch<SetStateAction<boolean>>;
    // Approve Deal Window
    setShowApproveDealWindow: Dispatch<SetStateAction<boolean>> | undefined;
    // Reject Deal Window
    setShowRejectDealWindow: Dispatch<SetStateAction<boolean>> | undefined;
    // Add Clause Window
    setShowAddClauseWindow: Dispatch<SetStateAction<boolean>> | undefined;

    // Force Readiness Close
    forceReadinessClose: boolean;
    setForceReadinessClose: Dispatch<SetStateAction<boolean>>;
    // Valueless Clauses
    valuelessClauses: ValuelessClause[];
    setValuelessClauses: Dispatch<SetStateAction<ValuelessClause[]>>;
    // Low Readiness Warning
    lowReadinessWarning: boolean;
    setLowReadinessWarning: Dispatch<SetStateAction<boolean>>;
    // Is Clauses Reordering
    isClauseReordering: boolean | undefined;
    setClauseReordering: Dispatch<SetStateAction<boolean>> | undefined;
    // Is Titles Reordering
    isTitlesReordering: boolean | undefined;
    setTitlesReordering: Dispatch<SetStateAction<boolean>> | undefined;
    // Download Data
    downloadData: any;
    downloadDataLoading: boolean;
    // Download PDF
    downloadPDF: any;
    downloadPDFLoading: boolean;

    // Deal Details
    deal: DealDetailsFragment | null | undefined;
    dealComplete: boolean;
    detailsData: GetDealDetailsQuery | undefined;
    stages: number;
    totalStages: number;
    tenants: any[] | undefined;
    landlords: any[] | undefined;
    oldestTenantApproval: DealApprovalType | null;
    oldestLandlordApproval: DealApprovalType | null;
    completedDeal: boolean | undefined;
    currentParty: false | Maybe<DealUserParty> | undefined;
    tenantPendingMembers: string[];
    getDealDetails: (
        options?:
            | QueryLazyOptions<
                  Exact<{
                      dealID?: Maybe<string> | undefined;
                      token?: Maybe<string> | undefined;
                  }>
              >
            | undefined
    ) => void;
    userType: string | undefined;
    setShowingTenantPending: Dispatch<SetStateAction<boolean>> | undefined;
    canSend: boolean;
    page: DealHeaderPage | undefined;
    triggerOpen: boolean | undefined;

    // Misc
    setHideToolbar: Dispatch<SetStateAction<boolean>> | undefined;
    financialsOpen: boolean;
    setFinancialsOpen: Dispatch<SetStateAction<boolean>> | undefined;

    setShowShareWindow?: Dispatch<SetStateAction<boolean>> | undefined;
    showShareWindow?: boolean;
};

const DealHeaderContext = React.createContext<DealHeaderContextType>({
    // Show Send Window
    showSendWindow: false,
    setShowSendWindow: () => {},
    // Round Complete Window
    showRoundCompleteWindow: false,
    setShowRoundCompleteWindow: () => {},
    // Shown Round Complete Window
    shownRoundCompleteWindow: false,
    // Deal Agreed Window
    dealAgreedWindowOpen: undefined,
    // Share Window Type
    shareWindowType: DealSendType.Landlord,
    setShareWindowType: () => {},
    // Send Window Success
    sendWindowSuccess: false,
    setSendWindowSuccess: () => {},
    // Tenant Pending Members Window
    showTenantPendingMembersWindow: false,
    setShowTenantPendingMembersWindow: () => {},
    // Approve Deal Window
    setShowApproveDealWindow: () => {},
    // Reject Deal Window
    setShowRejectDealWindow: () => {},
    // Add Clause Window
    setShowAddClauseWindow: () => {},

    // Force Readiness Close
    forceReadinessClose: false,
    setForceReadinessClose: () => {},
    // Valueless Clauses
    valuelessClauses: [],
    setValuelessClauses: () => {},
    // Low Readiness Warning
    lowReadinessWarning: false,
    setLowReadinessWarning: () => {},
    // Is Clauses Reordering
    isClauseReordering: undefined,
    setClauseReordering: undefined,
    // Is Titles Reordering
    isTitlesReordering: undefined,
    setTitlesReordering: undefined,
    // Download Data
    downloadData: () => {},
    downloadDataLoading: false,
    // Download PDF
    downloadPDF: () => {},
    downloadPDFLoading: false,

    // Deal Details
    deal: null,
    dealComplete: false,
    detailsData: undefined,
    stages: 0,
    totalStages: 0,
    tenants: undefined,
    landlords: undefined,
    oldestTenantApproval: null,
    oldestLandlordApproval: null,
    completedDeal: undefined,
    currentParty: undefined,
    tenantPendingMembers: [],
    getDealDetails: () => {},
    userType: undefined,
    setShowingTenantPending: undefined,
    canSend: false,
    page: undefined,
    triggerOpen: false,

    // Misc
    setHideToolbar: () => {},
    financialsOpen: false,
    setFinancialsOpen: () => {},
    setShowShareWindow: () => {},
    showShareWindow: false
});

export default DealHeaderContext;
