import React, { useContext, useState } from 'react';

import DealCardBarContainer from './DealCardBarContainer';
import DealCardButtonsContainer from './DealCardButtonsContainer';
import DealCardHeader from './DealCardHeader';
import DealCardNotSent from './DealCardNotSent';
import { DealCardContext } from '..';
import { DealState } from '../../../../generated/graphql';

const DealCardContent = ({}) => {
    const { getDealState, id, clauseSummary, state, round, isPinned } = useContext(DealCardContext);

    const [dealHover, setDealHover] = useState<boolean>(false);

    const dealURL = `/deals/${id}`;

    return (
        <div
            onMouseOver={() => getDealState()}
            onMouseEnter={() => setDealHover(true)}
            onMouseLeave={() => setDealHover(false)}
            className={`cursor-pointer`}
        >
            {isPinned && (
                <span className="absolute bg-newTeal-main px-3 rounded-t" style={{ top: '-24px', left: '-0.5px' }}>
                    Pinned
                </span>
            )}
            <DealCardHeader dealURL={dealURL} />
            {clauseSummary && state !== DealState.Edit && round ? (
                <>
                    <DealCardBarContainer dealURL={dealURL} dealHover={dealHover} />
                    <DealCardButtonsContainer dealURL={dealURL} />
                </>
            ) : (
                <DealCardNotSent />
            )}
        </div>
    );
};

export default DealCardContent;
