import React, { useEffect } from 'react';

import Notifications from '../../shared/Notifications';

type NotificationsPageProps = {
    userID?: string;
    setHeaderContent: React.Dispatch<React.SetStateAction<React.ReactChild | null>>;
};

const NotificationsPage = ({ setHeaderContent, userID }: NotificationsPageProps) => {
    useEffect(() => {
        setHeaderContent(<h1 className="text-xl font-bold p-2">Notifications</h1>);
    }, [setHeaderContent]);

    return (
        <div className="max-w-md m-auto">
            <Notifications showTitle={false} userID={userID} />
        </div>
    );
};

export default NotificationsPage;
