import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Button } from './button';
import Loading from './Loading';
import { ReactComponent as Arrow } from '../assets/images/arrow/right/black.svg';
import { ReactComponent as NotificationIcon } from '../assets/images/bell/gray.svg';
import { ReactComponent as Chevron } from '../assets/images/chevron/right/black.svg';
import { ReactComponent as Cross } from '../assets/images/cross/black.svg';
import { ReactComponent as Trash } from '../assets/images/trash/teal.svg';
import {
    SingleNotificationFragment,
    useDeleteNotificationMutation,
    useNotificationsQuery,
    useReadNotificationMutation
} from '../generated/graphql';
import usePushNotifications from '../push/usePushNotifications';
import { isPushNotificationSupported } from '../push/utils';

type NotificationsProps = {
    showTitle?: boolean;
    userID?: string;
    compact?: boolean;
};

const Notifications = ({ showTitle = true, userID, compact }: NotificationsProps) => {
    const history = useHistory();
    const [pageNo, setPageNo] = useState<number>(1);
    const { userConsent, userPermissionClick } = usePushNotifications();

    const {
        data: notificationsData,
        loading: notificationsLoading,
        startPolling,
        stopPolling,
        refetch: getNotifications
    } = useNotificationsQuery({
        variables: {
            page: compact ? undefined : 1,
            compact: compact ? true : false
        },
        fetchPolicy: 'network-only'
    });

    useEffect(() => {
        getNotifications({
            page: pageNo
        });
    }, [getNotifications, pageNo]);

    // When component is mounted, poll for notifications every 3 seconds
    useEffect(() => {
        startPolling(3000);
        return () => stopPolling();
    });

    // Mark a notification as read on click
    const [readNotification] = useReadNotificationMutation();

    const handleReadClick = async (notificationID: string) => {
        await readNotification({
            variables: {
                notificationID
            },
            optimisticResponse: {
                userReadNotification: {
                    __typename: 'Notification',
                    _id: notificationID,
                    read: true
                }
            }
        });
    };

    if (!notificationsData?.notifications) return null;

    return (
        <div className="w-full py-4">
            <div className="flex items-center justify-between">
                {showTitle && (
                    <p className="mb-4 font-bold text-gray-600 flex items-center">
                        <NotificationIcon className="w-4 h-4 mr-1" />
                        Notifications
                    </p>
                )}
                {userConsent === 'default' && isPushNotificationSupported() && (
                    <label className="mb-4">
                        Push Notifications?
                        <input
                            type="checkbox"
                            onChange={userPermissionClick}
                            className="leading-tight w-6 h-6 ml-2 form-checkbox p-2 rounded bg-white text-newTeal-main focus:shadow-none focus:outline-none"
                        />
                    </label>
                )}
            </div>
            {notificationsLoading ? (
                <Loading />
            ) : (
                <>
                    {!notificationsData?.notifications.notifications ||
                    notificationsData?.notifications.notifications?.length === 0 ? (
                        <div className={`w-full ${!showTitle && 'mt-4'}`}>
                            <NoNotifications />
                        </div>
                    ) : (
                        [...(notificationsData?.notifications.notifications ?? [])]
                            // .filter((notification) => !notification.read)
                            .sort((a, b) => {
                                const aSent = new Date(a.sent);
                                const bSent = new Date(b.sent);

                                if (aSent.getTime() === bSent.getTime()) return 1;

                                return aSent < bSent ? 1 : -1;
                            })
                            .map((notification) => (
                                <Notification
                                    refetch={(page: number) => {
                                        getNotifications({
                                            page: page
                                        });
                                    }}
                                    pageNo={pageNo}
                                    userID={userID}
                                    key={notification._id}
                                    notification={notification}
                                    handleReadClick={handleReadClick}
                                />
                            ))
                    )}
                </>
            )}
            {compact && (
                <div
                    className="py-2 mt-2font-bold text-gray-600 cursor-pointer w-full text-center font-bold"
                    onClick={() => {
                        window.scrollTo(0, 0);
                        history.push('/notifications');
                    }}
                >
                    <p>View All</p>
                </div>
            )}
            {!compact &&
                (pageNo > 1 ||
                    (notificationsData?.notifications && notificationsData?.notifications.pages > pageNo)) && (
                    <div className="flex flex-row justify-between mt-3 items-center">
                        <Button
                            background="newTeal-main"
                            className={`px-3 ${pageNo <= 1 ? 'opacity-50 disabled cursor-not-allowed' : ''}`}
                            style={{ width: '50px', height: '50px' }}
                            onClick={() => {
                                if (pageNo > 1) {
                                    setPageNo(pageNo - 1);
                                }
                            }}
                        >
                            <Arrow
                                className="h-5 text-white"
                                style={{
                                    width: '20px',
                                    transform: 'rotate(180deg)'
                                }}
                            />
                        </Button>
                        <p>{pageNo}</p>
                        <Button
                            background="newTeal-main"
                            className={`px-3 ${
                                notificationsData?.notifications && notificationsData?.notifications.pages <= pageNo
                                    ? 'opacity-50 disabled cursor-not-allowed'
                                    : ''
                            }`}
                            style={{ width: '50px', height: '50px' }}
                            onClick={() => {
                                if (notificationsData?.notifications && notificationsData?.notifications.pages > pageNo)
                                    setPageNo(pageNo + 1);
                            }}
                        >
                            <Arrow className="h-5 text-white" style={{ width: '20px' }} />
                        </Button>
                    </div>
                )}
        </div>
    );
};

export default Notifications;

type NotificationProps = {
    refetch: (page: number) => void;
    pageNo: number;
    userID?: string;
    notification: SingleNotificationFragment;
    handleReadClick: (notificationID: string) => Promise<void>;
};

const Notification = ({ notification, handleReadClick, userID, refetch, pageNo }: NotificationProps) => {
    const [isHovering, setisHovering] = useState<boolean>(false);
    // const [refetch] = useGetNotificationsLazyQuery({
    //     fetchPolicy: "cache-and-network"
    // })

    // const client = useApolloClient()
    const [deleteNotification, { error: deleteNotificationError }] = useDeleteNotificationMutation();

    const doDeleteNotification = async () => {
        if (!notification._id || !userID) return;
        await deleteNotification({
            variables: {
                notificationID: notification._id
            }
        });
        if (deleteNotificationError) {
            return;
            // handleFetchError(
            //     "Failed to delete notification",
            //     deleteNotificationError,
            //     data?.userNotificationDelete
            // )
        } else {
            //isn't working with pageNo, used to be user but obv no id for "notifciations"
            //  client.cache.modify({
            //      id: client.cache.identify({
            //          __typename: "Notifications",
            //          page: pageNo
            //      }),
            //      fields: {
            //          notifications(existingNotifications = [], { readField }) {
            //              return existingNotifications.filter(
            //                  (
            //                      notificationRef:
            //                          | Reference
            //                          | StoreObject
            //                          | undefined
            //                  ) =>
            //                      notification._id !==
            //                      readField("_id", notificationRef)
            //              )
            //          }
            //      }
            //  })
            refetch(pageNo);
        }
    };
    return (
        <div
            className="relative notification-container"
            onMouseEnter={() => {
                setisHovering(true);
            }}
            onMouseLeave={() => {
                setisHovering(false);
            }}
        >
            <div
                className={`bg-red-600 h-full absolute rounded-l z-10 hidden flex-row justify-center items-center transition-all duration-200 cursor-pointer sm:right-full sm:flex ${
                    isHovering ? 'w-7 cursor-pointer' : 'w-0'
                }`}
                onClick={() => {
                    doDeleteNotification();
                }}
            >
                <Trash
                    className={`w-5 h-5 delay-150 transition-all duration-200 text-white ${
                        isHovering ? 'opacity-100' : 'opacity-0'
                    }`}
                    style={{ stroke: '#fff' }}
                    color="white"
                />
            </div>
            <a
                href={notification.url}
                key={notification._id}
                // Set notification as read on click
                onClick={() => handleReadClick(notification._id)}
                className={`bg-white p-4 cursor-pointer flex justify-between items-center mb-2 ${
                    isHovering ? 'rounded-r' : 'rounded'
                } ${notification.read && 'opacity-50'}`}
            >
                <div>
                    <p className="text-xs text-gray-600 flex items-center justify-start">
                        {!notification.read && <span className="bg-red-500 rounded-full w-2 h-2 mr-1"></span>}
                        {`${new Date(notification.sent).toLocaleDateString()} - ${new Date(
                            notification.sent
                        ).toLocaleTimeString()}`}
                    </p>
                    <p className="font-bold text-sm">{notification.title}</p>
                    <p className="text-newGray-darkish text-sm">{notification.text}</p>
                </div>
                <Cross
                    className="w-5 h-12 block sm:hidden"
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        doDeleteNotification();
                    }}
                />
                <Chevron className="w-5 h-5 hidden sm:block" />
            </a>
        </div>
    );
};

const NoNotifications = () => (
    <div className="w-full rounded bg-navy-light border-2 border-gray-450 h-96 flex items-center justify-center text-center font-medium text-gray-600">
        Notifications will show here once you have created a New Space and started a New Deal
    </div>
);
