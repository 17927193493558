import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { GetDealOrganisationNamesQuery, GetUserTypeQuery, UserType } from './generated/graphql';
import { addQsInfo, removeDuplicatesFromArr } from './shared/helpers';

type QueryStringDataProps = {
    typeData?: GetUserTypeQuery | undefined;
    landlordOrganisationNameData?: GetDealOrganisationNamesQuery | undefined;
};

const QueryStringData = ({ typeData, landlordOrganisationNameData }: QueryStringDataProps) => {
    const history = useHistory();

    const [tenantLandlordNames, setTenantLandlordNames] = useState<string[]>();

    useEffect(() => {
        if (landlordOrganisationNameData && landlordOrganisationNameData.deals) {
            const formatted = landlordOrganisationNameData.deals.results.map((deal) => {
                return deal.unit?.organisation.name ?? '';
            });

            if (typeData?.user.type === UserType.Tenant)
                setTenantLandlordNames(removeDuplicatesFromArr(formatted.filter((value) => value.trim().length > 0)));
        }
    }, [landlordOrganisationNameData]);

    if (!typeData) return <></>;

    history.listen((location, action) => {
        if (action === 'REPLACE')
            addQsInfo(
                history,
                typeData.user.type,
                tenantLandlordNames && typeData?.user.type === UserType.Tenant
                    ? tenantLandlordNames
                    : typeData.user.organisation.name,
                location
            );
    });

    addQsInfo(
        history,
        typeData.user.type,
        tenantLandlordNames && typeData?.user.type === UserType.Tenant
            ? tenantLandlordNames
            : typeData.user.organisation.name
    );

    return <></>;
};

export default QueryStringData;
