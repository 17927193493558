import React, { useContext, useState } from 'react';

import { FilterContext, Sort } from './context';
import { PageType, WindowType } from './hooks/useDisclosure';
import { OrderBy } from '../../generated/graphql';
import { Button } from '../button';
import Radio, { RadioValue } from '../Inputs/Radio';
import Window from '../Window';

type SortWindowProps = {
    sort: Sort | undefined;
    setSort: (sort: Sort | undefined) => void;
    fields: RadioValue[];
    page?: PageType;
};

const SortWindow = ({ sort, setSort, fields, page }: SortWindowProps) => {
    const { openWindow, close } = useContext(FilterContext);
    const [sortField, setSortField] = useState<string | undefined>(sort?.field);
    const [order, setOrder] = useState<OrderBy>(sort?.order || OrderBy.Asc);

    const setChecked = (value: RadioValue) => setOrder(value.id as OrderBy);

    return (
        <Window
            setShow={close}
            title="Sorting"
            className="overflow-x-hidden"
            footer={
                <>
                    <div className="h-5 w-full bg-navy-lightest border-b" />
                    <div className="flex justify-center items-center">
                        <Button
                            text="black"
                            onClick={() => {
                                setSort(undefined);
                                openWindow({ type: WindowType.ALL, page: page });
                            }}
                            background="navy-light"
                            className="px-4 w-full md:w-4/12 font-semibold text-sm ml-2 md:mr-2 mb-3 mt-3"
                        >
                            Cancel
                        </Button>
                        <Button
                            background="newTeal-main"
                            text="white"
                            onClick={() => {
                                if (sortField && order) setSort({ field: sortField, order });
                                openWindow({ type: WindowType.ALL, page: page });
                            }}
                            className={`px-10 mr-2 font-semibold w-full md:w-auto ml-2 md:mr-2 mb-3 mt-3`}
                            disabled={!sortField}
                        >
                            Confirm
                        </Button>
                    </div>
                </>
            }
        >
            <>
                <div className="h-5 w-full bg-navy-lightest border-b" />
                <div>
                    {fields.map((field) => {
                        return (
                            <Radio
                                key={field.id}
                                checked={{ id: sortField || '', label: '' }}
                                setChecked={(value: RadioValue) => setSortField(value.id)}
                                value={field}
                            />
                        );
                    })}
                </div>
                <div className="h-5 w-full bg-navy-lightest border-b" />
                <div className="border-t">
                    <Radio
                        key={'asc'}
                        checked={{ id: order, label: '' }}
                        setChecked={setChecked}
                        value={{ id: OrderBy.Asc, label: 'Ascending' }}
                    />
                    <Radio
                        key={'desc'}
                        checked={{ id: order, label: '' }}
                        setChecked={setChecked}
                        value={{ id: OrderBy.Desc, label: 'Descending' }}
                    />
                </div>
            </>
        </Window>
    );
};

export default SortWindow;
