import { useContext } from 'react';

import { DealDetailsFragment } from '../../../../generated/graphql';
import { ReadinessContext } from '../../../../v2/components/Tenant/Readiness/context';

const useLowReadinessWarning = (deal: DealDetailsFragment | undefined) => {
    const { numberOfCompleteStages } = useContext(ReadinessContext);

    return (
        tenantDetails: any
    ): {
        show: boolean;
        stages?: number;
        totalStages?: number;
        rating?: number;
    } => {
        if (!deal && !tenantDetails) return { show: false };

        if (tenantDetails) {
            // Details
            const { ...details } = tenantDetails?.__typename === 'Deal' && tenantDetails.details;
            delete details['__typename'];

            // Get the total number of stages
            const total = 6;

            if (numberOfCompleteStages <= 3) {
                return {
                    show: true,
                    stages: numberOfCompleteStages,
                    totalStages: total
                };
            } else {
                return { show: false };
            }
        } else {
            if (!deal || deal.__typename !== 'Deal') return { show: false };
            if (deal.unit && deal.unit?.rating <= 40) {
                return { show: true, rating: deal.unit.rating };
            } else {
                return { show: false };
            }
        }
    };
};

export default useLowReadinessWarning;
