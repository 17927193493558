import { Field, Form, Formik, useFormikContext } from 'formik';
import { isEmpty } from 'lodash';
import React, { useRef, useState } from 'react';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import {
    GetAllOrganisationsDocument,
    PracticeType,
    useOrganisationCreateMutation,
    UserType
} from '../../../generated/graphql';
import ErrorPopup from '../../../shared/ErrorPopup';
import { useUploadCompanyLogo } from '../../../shared/file/hooks';
import { CompanyLogo } from '../../../shared/file/OrganisationAvatarUpload';
import { InputField } from '../../../shared/Formik/InputField';
import { InputType } from '../../../shared/Inputs';
import EmailMultiInput from '../../../shared/Inputs/EmailMultiInput';
import Select from '../../../shared/Inputs/Select';
import Window from '../../../shared/Window';

type CreateCompanyProps = {
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
    onSuccess: React.Dispatch<React.SetStateAction<boolean>>;
    setFieldValue: any;
};

export type CreateCompanyValues = {
    emails: string[];
    type?: UserType;
    name: string;
    website: string;
    avatar?: string;
    address: {
        line1: string;
        line2: string;
        city: string;
        postCode: string;
    };
    practiceType?: PracticeType;
};

const NewCompanyForm = ({ setShow, onSuccess, setFieldValue }: CreateCompanyProps) => {
    const { values, submitForm, validateForm } = useFormikContext<CreateCompanyValues>();
    const [organisationCreate] = useOrganisationCreateMutation();

    const [file, setFile] = useState<File | null>(null);
    const { handleFileUpload } = useUploadCompanyLogo();

    const handleInviteSubmit = async () => {
        submitForm();

        const validationErrors = await validateForm();
        if (isEmpty(validationErrors)) {
            const { data: organisationCreateData } = await organisationCreate({
                variables: {
                    ...values,
                    type: values.type!
                },
                refetchQueries: [{ query: GetAllOrganisationsDocument }]
            });

            if (!isEmpty(organisationCreateData?.organisationCreate.errors)) {
                organisationCreateData?.organisationCreate.errors.map((error) => {
                    toast(<ErrorPopup title={error} details={[]} />, {
                        hideProgressBar: true,
                        draggable: false,
                        autoClose: false,
                        closeOnClick: false,
                        closeButton: true
                    });
                });
            }

            if (organisationCreateData?.organisationCreate?.organisation) {
                const organisationID = organisationCreateData?.organisationCreate.organisation._id;

                await handleFileUpload(file, organisationID);

                setShow(false);
                onSuccess(true);
            }
        }
    };

    const companyTypeOptions = [
        {
            id: UserType.Landlord,
            text: 'Landlord Company'
        },
        {
            id: UserType.Agent,
            text: 'Practice'
        },
        {
            id: UserType.Tenant,
            text: 'Tenant Company'
        }
    ];

    const practiceTypeOptions = [
        {
            id: 'LEGAL',
            text: 'Legal Practice'
        },
        {
            id: 'AGENCY',
            text: 'Agency Practice'
        },
        {
            id: 'PROPERTY_MANAGEMENT',
            text: 'Property Management Practice'
        }
    ];

    return (
        <Window
            title="Create Company"
            width="w-1/2"
            primaryBtn
            onPrimaryBtnClick={() => handleInviteSubmit()}
            primaryBtnText={'Done'}
            secondaryBtn
            onSecondaryBtnClick={() => setShow(false)}
            secondaryBtnText="Cancel"
            setShow={setShow}
        >
            <>
                <div className="px-4 mt-4" style={{ marginBottom: '-40px' }}>
                    <label className="block text-newGray-darkish ">Logo</label>
                    <CreateCompanyLogo name={values.name} onChange={(file) => setFile(file)} />
                </div>
                <Form className="pt-10 md:pt-4 p-4 w-full flex flex-col gap-2 md:mb-8">
                    <div className="flex flex-wrap flex-col md:flex-no-wrap w-full gap-4 items-end">
                        <div className="w-full flex gap-4 mb-2 flex-row">
                            <div className="w-1/2 flex flex-col">
                                <label className="text-gray-600 font-medium text-sm ">Company Type</label>
                                <Field
                                    name="type"
                                    containerClassName="mt-0 h-20"
                                    component={Select}
                                    className={`mb-2 w-full ${values && values.type !== UserType.Agent ? 'pr-2' : ''} `}
                                    data={companyTypeOptions}
                                    onChange={(e: any) => setFieldValue('type', e.value)}
                                />
                            </div>
                            {values && values.type === UserType.Agent && (
                                <div className="flex flex-col w-1/2">
                                    <label htmlFor="practiceType" className="block text-gray-600 font-medium text-sm ">
                                        Practice Type
                                    </label>
                                    <Field
                                        name="practiceType"
                                        containerClassName="mt-0 h-20"
                                        component={Select}
                                        className={`w-full`}
                                        data={practiceTypeOptions}
                                        onChange={(e: any) => setFieldValue('practiceType', e.value)}
                                    />
                                </div>
                            )}
                        </div>
                        <div className="w-full flex  gap-4 flex-row">
                            <div className="w-1/2 flex flex-col">
                                <label htmlFor="name" className="block text-gray-600 font-medium text-sm ">
                                    Company Name
                                </label>
                                <Field
                                    name="name"
                                    component={InputField}
                                    containerStyle={`h-20`}
                                    className={`mb-2 h-12 w-full`}
                                    placeholder="Name"
                                    type={InputType.text}
                                />
                            </div>
                            <div className="w-1/2 flex flex-col">
                                <label htmlFor="website" className="block text-gray-600 font-medium text-sm ">
                                    Company Website
                                </label>
                                <Field
                                    name="website"
                                    component={InputField}
                                    containerStyle={`h-20`}
                                    className={`mb-8 mb-20 h-12 w-full`}
                                    placeholder="Website"
                                    type={InputType.text}
                                />
                            </div>
                        </div>
                        <div className="w-full flex -mt-4 gap-4 flex-row">
                            <div className="w-1/2 flex flex-col">
                                <label htmlFor="line1" className="block text-gray-600 mt-1 font-medium text-sm ">
                                    Company Address
                                </label>
                                <Field
                                    name="line1"
                                    containerStyle={`h-15`}
                                    component={InputField}
                                    className={`w-full h-12 `}
                                    placeholder="Line 1"
                                    type={InputType.text}
                                />
                            </div>
                            <div className="w-1/2 flex mt-7 flex-col">
                                <Field
                                    name="line2"
                                    component={InputField}
                                    containerStyle={`h-15`}
                                    className={`w-full h-12 `}
                                    placeholder="Line 2"
                                    type={InputType.text}
                                />
                            </div>
                        </div>
                        <div className="w-full flex -mt-4 gap-4 flex-row">
                            <div className="w-1/2 flex flex-col">
                                <Field
                                    name="city"
                                    component={InputField}
                                    containerStyle={`h-20`}
                                    className={`mb-4 w-full h-12 `}
                                    placeholder="City"
                                    type={InputType.text}
                                />
                            </div>
                            <div className="w-1/2 flex flex-col">
                                <Field
                                    name="postCode"
                                    containerStyle={`h-20`}
                                    component={InputField}
                                    className={`mb-4 w-full h-12 `}
                                    placeholder="Post Code"
                                    type={InputType.text}
                                />
                            </div>
                        </div>
                    </div>
                    <Field
                        name="emails"
                        component={EmailMultiInput}
                        label="Email"
                        className="border-b border-t border-gray-50"
                        placeholder={values.emails.length > 0 ? 'Type to add another email' : 'Enter email'}
                        setEmails={(e: React.FormEvent<HTMLInputElement>) => setFieldValue('emails', e)}
                        initialChips={values.emails}
                        type={InputType.text}
                    />
                </Form>
            </>
        </Window>
    );
};

const CreateCompany = ({ setShow, onSuccess }: CreateCompanyProps) => {
    const initialValues: CreateCompanyValues = {
        type: undefined,
        name: '',
        website: '',
        address: {
            line1: '',
            line2: '',
            city: '',
            postCode: ''
        },
        practiceType: undefined,
        emails: []
    };

    const createCompanySchema = Yup.object().shape({
        type: Yup.string().required('Please select company type'),
        name: Yup.string().required('Please enter an company name'),
        emails: Yup.array().min(1, 'Please enter an email address')
    });

    return (
        <>
            <Formik initialValues={initialValues} validationSchema={createCompanySchema} onSubmit={() => {}}>
                {({ setFieldValue }) => (
                    <NewCompanyForm setShow={setShow} onSuccess={onSuccess} setFieldValue={setFieldValue} />
                )}
            </Formik>
        </>
    );
};

type CompanyLogoProps = {
    name: string;
    onChange: (file: File) => void;
};

const CreateCompanyLogo = ({ name, onChange }: CompanyLogoProps, ref: any) => {
    const uploadRef = useRef<HTMLInputElement>(null);
    const [imageUrl, setImageUrl] = useState<string>('');

    const handleImageChange = (event: React.FormEvent<HTMLInputElement>) => {
        const target = event.target as HTMLInputElement;
        if (target.files && target.files[0]) {
            setImageUrl(URL.createObjectURL(target.files[0]));

            onChange(target.files[0]);
        }
    };

    return <CompanyLogo imageUrl={imageUrl} onChange={handleImageChange} name={name} ref={uploadRef} />;
};

export default CreateCompany;
