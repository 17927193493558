import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';

import ErrorMessage from './ErrorMessage';
import { useResetUserPasswordMutation } from '../../generated/graphql';
import Input, { InputType } from '../../shared/Inputs';
import Loading from '../../shared/Loading';
import SuccessIcon from '../../shared/SuccessIcon';
type LoginProps = {
    token: string;
};

const ResetPassword = ({ token }: LoginProps) => {
    const [resetPassword, { loading, error: detailsError }] = useResetUserPasswordMutation();
    const [error, setError] = useState<string | null>(null);
    const [errorCode, setErrorCode] = useState<number | null>(null);
    const passwordRef = useRef<HTMLInputElement>(null);
    const confirmPasswordRef = useRef<HTMLInputElement>(null); //other forms don't have this i know, perhaps just more important to make sure the user remembers it this time? also looks bar without another input

    const handleSubmit = async () => {
        const password = passwordRef.current?.value;
        const confirmPassword = confirmPasswordRef.current?.value;
        if (!password || !confirmPassword) return setError('Please enter the required details');
        if (password !== confirmPassword) return setError('Passwords do not match');

        if (!/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/.test(password))
            return setError('Password entered does not match the required criteria');

        if (token) {
            await resetPassword({
                variables: {
                    token: token,
                    password: password
                }
            });
            if (detailsError) {
                if (
                    detailsError.graphQLErrors[0].extensions?.code !== 'INVALID_TOKEN' //if it's 400, invalid token, no way that suddenly it will become valid so the form will disappear, showing a nicer animated error message
                ) {
                    return setError(detailsError.graphQLErrors[0].extensions?.message ?? 'An unknown error occured');
                }

                return setErrorCode(400);
            }
            return setErrorCode(0);
        }
    };

    if (loading) {
        return <Loading />; //might have this somewhere else, instead of the center, perhaps on the button, so the whole form doesn't disappear whilst loading?
    }

    if (errorCode === 0) {
        //TODO: these messages and such i will turn into a successmessage like error message component
        return (
            <>
                <SuccessIcon />
                <h1 className="text-xl font-bold text-center mt-5">Reset Password</h1>
                <p className="mt-2">
                    You can now head over to{' '}
                    <Link to="/login" className="underline text-blue-500 hover:text-blue-800">
                        login
                    </Link>
                </p>
            </>
        );
    } else if (errorCode) {
        return <ErrorMessage message={'Try requesting a new link'} />;
    }

    return (
        <form
            className="bg-white px-8 pt-6 mb-4 sm:h-auto max-w-sm md:max-w-sm w-full flex flex-col items-center"
            onSubmit={(e) => {
                e.preventDefault();
                handleSubmit();
            }}
        >
            <p className="text-center mb-4">Enter your memorable password.</p>
            <p className="text-center mb-6">
                Password must be at least 8 characters and contain both a number and special character.
            </p>

            <div className="my-6 w-full">
                <Input
                    type={InputType.password}
                    className="bg-navy-lightest mb-4 w-full"
                    placeholder="Password"
                    label="Password"
                    ref={passwordRef}
                />
                <Input
                    type={InputType.password}
                    className="bg-navy-lightest mb-4 w-full"
                    placeholder="Confirm Password"
                    label="Confirm Password"
                    ref={confirmPasswordRef}
                />
            </div>
            <div className="flex items-center justify-center w-full">
                <button
                    className="w-full h-12 text-black bg-newTeal-main border inline-flex items-center justify-center text-center focus:outline-none px-4 rounded"
                    type="submit"
                >
                    Reset Password
                </button>
            </div>
            {error && (
                <div className="bg-red-200 border border-red-600 rounded w-full py-2 px-4 mt-4 text-center">
                    <span className="text-red-600">{error}</span>
                </div>
            )}
        </form>
    );
};

export default ResetPassword;
