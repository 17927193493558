import React, { RefObject, useEffect, useState } from 'react';

import { ReactComponent as ArrowUp } from '../../../assets/images/arrow/arrow-up/white-arrow.svg';
import Transition from '../../../shared/Transition';

type ScrollUpWrapper = {
    isScrolling: RefObject<HTMLInputElement>;
    children: React.ReactNode;
};

const ScrollUpWrapper = ({ isScrolling, children }: ScrollUpWrapper) => {
    const [scrollUp, setScrollUp] = useState<boolean>();

    const scrollUpTransitionProps = {
        enter: 'transition transform ease-out duration-200',
        enterFrom: 'translate-y-full opacity-0',
        enterTo: 'translate-y-0 opacity-100',
        leave: 'transition transform ease-in duration-200',
        leaveFrom: 'translate-y-0 opacity-100',
        leaveTo: 'translate-y-full opacity-0 '
    };
    const onScroll = () => {
        if (isScrolling.current?.scrollTop) {
            setScrollUp(true);
        } else {
            setScrollUp(false);
        }
    };

    useEffect(() => {
        isScrolling.current?.addEventListener('scroll', onScroll);
        return () => {
            isScrolling.current?.removeEventListener('scroll', onScroll);
        };
    }, [isScrolling.current?.scrollTop]);

    return (
        <>
            {children}

            <Transition show={scrollUp} {...scrollUpTransitionProps} className={`flex fixed right-0 bottom-0`}>
                <div
                    className={`w-15 h-15 bg-newTeal-main z-40 justify-center flex items-center mr-16 mb-6 cursor-pointer hover:bg-opacity-100 bg-opacity-50  rounded-full `}
                    onClick={() =>
                        isScrolling.current?.scrollTo({
                            top: 0,
                            behavior: 'smooth'
                        })
                    }
                >
                    <ArrowUp className="w-8 h-8" />
                </div>
            </Transition>
        </>
    );
};

export default ScrollUpWrapper;
