import { useContext } from 'react';

import { DealDetailsFragment } from '../../../../generated/graphql';
import { ReadinessContext } from '../../../../v2/components/Tenant/Readiness/context';
import { WalkthroughClause } from '../Negotiate';

const useObserveDeal = (
    setDealComplete: React.Dispatch<React.SetStateAction<boolean>>,
    setValuelessClauses: React.Dispatch<
        React.SetStateAction<
            {
                id: string;
                shortForm: string;
            }[]
        >
    >,
    setWalkthroughClauses: React.Dispatch<React.SetStateAction<WalkthroughClause[]>> | undefined,
    setLowReadinessWarning?: React.Dispatch<React.SetStateAction<boolean>> | undefined,
    setTenantFirstView?: React.Dispatch<React.SetStateAction<boolean>> | undefined
) => {
    const { numberOfCompleteStages } = useContext(ReadinessContext);

    return (sortedDeal: DealDetailsFragment | null): boolean => {
        if (sortedDeal?.__typename !== 'Deal') return false;

        if (setLowReadinessWarning) {
            if (sortedDeal.currentUserParty === 'TENANT') {
                setLowReadinessWarning(numberOfCompleteStages <= 3);
            } else {
                if (sortedDeal.unit && sortedDeal.unit.rating <= 40) {
                    setLowReadinessWarning(true);
                }
            }
        }

        // Show tenant's first landing (pending members)
        if (setTenantFirstView) {
            setTenantFirstView(sortedDeal.currentUserParty === 'TENANT' && !sortedDeal.round?.seen);
        }

        // Determine if the deal can be sent
        let complete = true;
        // Determine if deal is finished
        let dealComplete = true;

        setValuelessClauses && setValuelessClauses([]);
        sortedDeal.sections?.map((section) =>
            [...(section.groups ?? [])]
                .sort((a, b) => (a.index > b.index ? 1 : -1))
                .map((group) =>
                    [...(group.clauses ?? [])]
                        .sort((a, b) => (a.index > b.index ? 1 : -1))
                        .map((clause) => {
                            // If value is "*" then no value has been entered - show window
                            if (clause.shortForm && clause.value === '*') {
                                setValuelessClauses((valuelessClauses) => [
                                    {
                                        id: clause._id,
                                        shortForm: clause.shortForm ?? ''
                                    },
                                    ...valuelessClauses
                                ]);
                            }

                            // If there has been a newly added clause, the deal is not complete for the round
                            const mostRecent =
                                clause.history &&
                                clause.history.length > 0 &&
                                clause.history[clause.history.length - 1];

                            if (mostRecent && mostRecent.type === 'NEW') {
                                dealComplete = false;
                            }

                            // Determine if the clause is complete
                            const level1ClauseComplete = !!(
                                clause.approved ||
                                (clause.rejected &&
                                    sortedDeal.__typename === 'Deal' &&
                                    clause.history &&
                                    clause.history.length > 0 &&
                                    sortedDeal.currentUserParty &&
                                    clause.history[clause.history?.length - 1].party === sortedDeal.currentUserParty)
                            );

                            if (dealComplete && clause.visible) dealComplete = !!clause.approved;

                            // Add to visible clauses list
                            if (clause.visible && setWalkthroughClauses) {
                                setWalkthroughClauses((walkthroughClauses) => [
                                    ...walkthroughClauses.filter((wClause) => wClause.id !== clause._id),
                                    {
                                        id: clause._id,
                                        accordianID: group._id,
                                        complete: level1ClauseComplete
                                    }
                                ]);
                            }

                            if (complete && clause.visible && !clause.approved) {
                                // If complete = false, then a failing clause has already been found, so we can stop
                                if (
                                    sortedDeal.__typename === 'Deal' &&
                                    sortedDeal.currentUserParty?.toString() === sortedDeal.state.toString()
                                )
                                    complete = level1ClauseComplete;
                            }

                            // Check level 2 clauses
                            [...(clause.groups ?? [])]
                                .sort((a, b) => (a.index > b.index ? 1 : -1))
                                .map((level2Group) =>
                                    [...(level2Group.clauses ?? [])]
                                        .sort((a, b) => (a.index > b.index ? 1 : -1))
                                        .map((level2Clause) => {
                                            // If value is "*" then no value has been entered - show window
                                            if (level2Clause.shortForm && level2Clause.value === '*') {
                                                setValuelessClauses((valuelessClauses) => [
                                                    {
                                                        id: level2Clause._id,
                                                        shortForm: level2Clause.shortForm ?? ''
                                                    },
                                                    ...valuelessClauses
                                                ]);
                                            }

                                            // Determine if clause is complete
                                            const level2ClauseComplete = !!(
                                                level2Clause.approved ||
                                                (level2Clause.rejected &&
                                                    sortedDeal.__typename === 'Deal' &&
                                                    level2Clause.history &&
                                                    level2Clause.history.length > 0 &&
                                                    sortedDeal.currentUserParty &&
                                                    level2Clause.history[level2Clause.history?.length - 1].party ===
                                                        sortedDeal.currentUserParty)
                                            );

                                            if (dealComplete && level2Clause.visible)
                                                dealComplete = !!level2Clause.approved;

                                            // Add to visible clauses list
                                            if (level2Clause.visible && setWalkthroughClauses) {
                                                setWalkthroughClauses((walkthroughClauses) => [
                                                    ...walkthroughClauses.filter(
                                                        (wClause) => wClause.id !== level2Clause._id
                                                    ),
                                                    {
                                                        id: level2Clause._id,
                                                        accordianID: group._id,
                                                        complete: level2ClauseComplete
                                                    }
                                                ]);
                                            }

                                            // If not complete then continue
                                            if (
                                                complete &&
                                                level2Clause.visible &&
                                                !level2Clause.approved &&
                                                sortedDeal.__typename === 'Deal' &&
                                                sortedDeal.currentUserParty?.toString() === sortedDeal.state.toString()
                                            ) {
                                                complete = level2ClauseComplete;
                                            }
                                        })
                                );
                        })
                )
        );
        setDealComplete(dealComplete);
        return complete;
    };
};

export default useObserveDeal;
