import React, { useState } from 'react';

import { GetAllOrganisationsDocument, useOrganisationDeleteMutation } from '../../../generated/graphql';
import { Button } from '../../../shared/button';
import { handleFetchError } from '../../../shared/helpers';
import Input, { InputType } from '../../../shared/Inputs';
import Loading from '../../../shared/Loading';
import Window from '../../../shared/Window';

type DeleteCompanyWindowProps = {
    organisation: any;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
    setRefetchOrganisations: React.Dispatch<React.SetStateAction<boolean>>;
};

const DeleteCompanyWindow = ({ setShow, organisation, setRefetchOrganisations }: DeleteCompanyWindowProps) => {
    const [twoStepConfirmation, setTwoStepConfirmation] = useState<boolean>(true);
    const [confirmation, setConfirmation] = useState<boolean>(false);
    const [adminPasswordWindow, setAdminPasswordWindow] = useState<boolean>(false);
    const [password, setPassword] = useState<string>('');

    const [deleteOrganisation, { error: deleteOrganisationError, loading: deleteOrganisationLoading }] =
        useOrganisationDeleteMutation();

    const handleAdminPassword = async (password: string) => {
        const { data: deleteOrganisationData } = await deleteOrganisation({
            variables: {
                id: organisation._id,
                input: {
                    password
                }
            },
            refetchQueries: () => [GetAllOrganisationsDocument]
        });
        if (deleteOrganisationData) {
            setRefetchOrganisations(true);
        }
        if (deleteOrganisationError)
            return handleFetchError(
                'Failed to delete the organisation',
                deleteOrganisationError,
                deleteOrganisationData?.organisationDelete
            );

        setShow(false);
    };

    if (deleteOrganisationLoading)
        return (
            <Window title="Delete Company" titleCenter width="w-1/4" setShow={setShow}>
                <Loading />
            </Window>
        );

    return (
        <Window
            title="Delete Company"
            setShow={setShow}
            width="w-1/3"
            titleCenter
            primaryBtnText={twoStepConfirmation ? 'Yes' : 'Confirm'}
            primaryBtn={!adminPasswordWindow}
            onPrimaryBtnClick={() =>
                twoStepConfirmation
                    ? (setTwoStepConfirmation(false), setConfirmation(true))
                    : confirmation && (setConfirmation(false), setAdminPasswordWindow(true))
            }
            secondaryBtnText={twoStepConfirmation ? 'No' : 'Cancel'}
            secondaryBtn={!adminPasswordWindow}
            onSecondaryBtnClick={() => {
                setShow(false);
                setAdminPasswordWindow(false);
                setConfirmation(false);
            }}
        >
            <div className="flex flex-center flex-wrap  justify-center px-12">
                {twoStepConfirmation && (
                    <div className="flex flex-wrap flex-center flex-col">
                        <p className="text-newGray-darkish mb-7  pt-3 text-center">
                            Are you sure you want to delete this company, all spaces, deals, templates and users
                            relating to this comapny will be permantely deleted and irrecoverable.
                        </p>
                    </div>
                )}
                {confirmation && (
                    <div className="flex flex-wrap flex-center flex-col">
                        <p className="text-newGray-darkish mb-7  pt-3 text-center">
                            Please confirm you would like to permanetly delete this company and all related data.
                        </p>
                    </div>
                )}
                {adminPasswordWindow && (
                    <div className="w-full flex flex-col items-center justify-around gap-4 text-center p-4 text-sm text-newGray-darkish">
                        <form className="flex gap-2 w-full flex-col items-center" onSubmit={(e) => e.preventDefault()}>
                            <p className="w-3/4 mb-4">
                                Please enter your admin password in order to delete this company.
                            </p>
                            <div className="flex gap-2 w-full">
                                <Input
                                    type={InputType.password}
                                    autoFocus
                                    autoComplete="off"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    placeholder="Your Password"
                                    className="w-full"
                                />
                                <Button
                                    onClick={() => handleAdminPassword(password)}
                                    text="white"
                                    background="newTeal-main"
                                    className="w-1/4"
                                >
                                    Confirm
                                </Button>
                            </div>
                        </form>
                    </div>
                )}
            </div>
        </Window>
    );
};

export default DeleteCompanyWindow;
