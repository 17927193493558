import React, { useEffect, useState } from 'react';

import ClientCard from './ClientCard';
import { useGetAllUserTypeQuery, useGetUserOrganisationQuery, UserType } from '../../generated/graphql';
import { handleFetchError } from '../../shared/helpers';
import Select from '../../shared/Inputs/Select';
import Loading from '../../shared/Loading';

export enum ClientType {
    TENANT = 'Tenant Companies',
    LANDLORD = 'Landlord Companies'
}

const Clients = () => {
    const { data, loading, error } = useGetUserOrganisationQuery();
    const { data: typeData } = useGetAllUserTypeQuery();

    const [optionId, setOptionId] = useState<ClientType | null>(ClientType.LANDLORD);

    useEffect(() => {
        // Check for errors
        handleFetchError('Could not load your company details', error, data?.user);
    }, [data, error]);

    if (loading) return <Loading />;

    if (error) return null;

    return (
        <div className="w-full lg:w-4/5 p-6">
            <div className="flex justify-between items-center mb-4 border-b w-full border-newGray-main">
                <div className="my-2 pb-2 w-52">
                    <Select
                        label={ClientType.LANDLORD}
                        defaultValue={{
                            value: ClientType.LANDLORD,
                            label: ClientType.LANDLORD
                        }}
                        onItemClick={(id) => id && setOptionId(id as ClientType)}
                        data={[
                            {
                                id: ClientType.LANDLORD,
                                text: ClientType.LANDLORD
                            },
                            {
                                id: ClientType.TENANT,
                                text: ClientType.TENANT
                            }
                        ]}
                    />
                </div>
            </div>

            {data?.user.__typename === 'User' && (
                <>
                    <div className="flex flex-col items-center content-center mb-10">
                        {optionId &&
                            [ClientType.LANDLORD].includes(optionId) &&
                            typeData?.user.trustedOrganisations?.map(
                                (trustedOrganisation) =>
                                    (trustedOrganisation.type === 'LANDLORD' ||
                                        trustedOrganisation.type === 'ADMIN') && (
                                        <ClientCard
                                            id={trustedOrganisation._id}
                                            type={UserType.Landlord}
                                            organisation={trustedOrganisation}
                                        />
                                    )
                            )}
                        {optionId &&
                            [ClientType.TENANT].includes(optionId) &&
                            typeData?.user.trustedOrganisations?.map(
                                (trustedOrganisation) =>
                                    trustedOrganisation.type === 'TENANT' && (
                                        <ClientCard
                                            id={trustedOrganisation._id}
                                            type={UserType.Tenant}
                                            organisation={trustedOrganisation}
                                        />
                                    )
                            )}
                    </div>
                </>
            )}
        </div>
    );
};

export default Clients;
