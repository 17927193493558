import React, { useState } from 'react';

import { ReactComponent as Cross } from '../../../assets/images/cross/black.svg';

import { LinkedClause as LinkedClauseType } from '.';

type LinkedClauseProps = {
    id: string;
    value?: string;
    valueSuffix?: string;
    longForm?: string;
    children: string;
    removeLinkedClauses: (clause: LinkedClauseType) => void;
};

const LinkedClause = ({ id, children: shortForm, removeLinkedClauses, ...props }: LinkedClauseProps) => {
    const [isHover, setHover] = useState<boolean>(false);

    return (
        <span
            key={id}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            className="flex flex-row items-center justify-around bg-navy-light rounded py-1 px-2 mr-2 gap-1 cursor-default transition-all duration-200 linked-clause-tag"
        >
            {shortForm}
            <button
                className="cursor-pointer focus:outline-none"
                onClick={() => removeLinkedClauses({ _id: id, shortForm, ...props })}
            >
                <Cross
                    className={`h-4 stroke-current transition-all duration-200 w-0 opacity-0 ${
                        isHover && 'opacity-100 w-4'
                    }`}
                />
            </button>
        </span>
    );
};

export default LinkedClause;
