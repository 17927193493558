import ProgressBar from '@ramonak/react-progress-bar';
import { isNil } from 'lodash';
import React, { useEffect, useState } from 'react';
import ReactTooltip from 'react-tooltip';

import { Clause, DealCardFragment, DealState, Template, Unit } from '../../../generated/graphql';
import { DoughnutChartComponent } from '../../../shared/DoughnutChart/DoughnutChartComponent';
import useMobileScreen from '../../../shared/hooks/useMobileScreen';
import useScreenWidth from '../../../shared/hooks/useScreenWidth';
import { UseConditionToText } from '../../../shared/NewUnit/hooks/useGetUnitSelectData';

type StatsProps = {
    units?: Unit[];
    clauses: Clause[];
    deals: DealCardFragment[];
    templates: Template[];
};

const Stats = ({ units, clauses, deals, templates }: StatsProps) => {
    const useCases = units?.map((unit) => unit.use);
    const mobileScreen = useMobileScreen();
    const screenWidth = useScreenWidth();

    const [smallScreen, setSmallScreen] = useState<boolean>(false);
    const [bigScreen, setBigScreen] = useState<boolean>(false);

    useEffect(() => {
        if (screenWidth && screenWidth < 1370 && screenWidth > 768) {
            setSmallScreen(true);
        } else setSmallScreen(false);

        if (screenWidth && screenWidth > 1700) {
            setBigScreen(true);
        } else setBigScreen(false);
    }, [screenWidth]);

    const onlyUnique = (value, index, self) => {
        return self.indexOf(value) === index;
    };

    const uniqueUseCases = useCases?.filter(onlyUnique);

    const draftDeals = deals.filter((deal) => deal.state === DealState.Edit);
    const completedDeals = deals.filter((deal) => deal.state === DealState.Complete && deal.created);

    const dealsWithLandlord = deals.filter((deal) => deal.state === DealState.Landlord);
    const dealsWithTenant = deals.filter((deal) => deal.state === DealState.Tenant);
    const activeDeals = dealsWithLandlord.length + dealsWithTenant.length;

    const dealsRoundDates = completedDeals.map((deal) => {
        return (new Date(deal?.round?.date).getTime() - new Date(deal.created).getTime()) / (1000 * 3600 * 24);
    });
    const dealsRoundDatesWithouNan = dealsRoundDates.filter(function (n) {
        return n || n === 0;
    });
    const sumOfDays = dealsRoundDatesWithouNan.reduce((a, v) => (a = a + v), 0);
    const sumOfDeals = completedDeals.length + activeDeals + draftDeals.length;

    const completedDealAverageDays = Math.round((sumOfDays / completedDeals.length) * 10) / 10;

    const data = [
        { value: sumOfDeals > 0 ? completedDeals.length : 50 },
        { value: sumOfDeals > 0 ? activeDeals : 30 },
        { value: sumOfDeals > 0 ? draftDeals.length : 20 }
    ];

    const spacesRating = (units: Unit[], range: string) => {
        switch (range) {
            case 'veryPoor':
                return units.filter((unit) => unit.rating < 20).length;
            case 'poor':
                return units.filter((unit) => unit.rating > 20 && unit.rating < 40).length;
            case 'fair':
                return units.filter((unit) => unit.rating > 40 && unit.rating < 60).length;
            case 'good':
                return units.filter((unit) => unit.rating > 60 && unit.rating < 80).length;
            case 'excellent':
                return units.filter((unit) => unit.rating > 80).length;
        }
    };

    return (
        <div
            className={` flex  ${
                smallScreen
                    ? 'flex-row flex-wrap justify-center items-center mt-2 h-84 w-full overflow-y-scroll'
                    : mobileScreen
                    ? 'flex-col justify-center items-center'
                    : 'flex-row'
            }`}
        >
            {/*spaces*/}

            <div
                className={`${
                    smallScreen ? ' mr-2 px-2' : mobileScreen ? ' mx-2 px-2 mt-2' : ''
                } h-64 bg-white  mb-4 rounded `}
                style={{
                    width: smallScreen ? '48%' : mobileScreen ? '91.666667%' : '33.333333%'
                }}
            >
                <div className="ml-3 pt-3 flex flex-row items-center">
                    <div className="h-6 w-2.5 bg-newTeal-main w-20" />
                    <p className="ml-2 text-2xl font-medium">Spaces</p>
                </div>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center'
                    }}
                >
                    <div className="w-1/3 h-25 ml-4 mr-6 flex flex-row-reverse items-center align-center">
                        <div className="text-gray-600 h-6 w-5 text-sm mb-4">
                            <p style={{ fontSize: '14px' }}>Total Spaces</p>
                        </div>
                        <div
                            className={`w-16 h-16 ml-2 mr-2 items-center  flex justify-center rounded-full`}
                            style={{ backgroundColor: '#c4f4f3' }}
                        >
                            <p
                                className="text-4xl font-semibold opacity-75"
                                style={{
                                    fontWeight: '900',
                                    textShadow: '0px 2px, 2px 0px, 2px 2px',
                                    letterSpacing: '1px'
                                }}
                            >
                                {units.length}
                            </p>
                        </div>
                    </div>
                    <div className="text-gray-600 border-r-2 ml-4 h-15 text-sm border-navy-light" />
                    <div className="w-1/3 h-25 ml-4">
                        <div className="text-gray-600 h-6 text-sm">
                            <p style={{ fontSize: '14px' }}>Use Cases</p>
                        </div>
                        <div className="flex flex-wrap ">
                            {uniqueUseCases &&
                                uniqueUseCases.slice(0, 3).map((useCase) => (
                                    <div
                                        className="flex text-black px-0.5 mr-1 mb-1 rounded-xl border-2 border-newTeal-main"
                                        style={{
                                            fontSize: mobileScreen ? '11px' : '9px'
                                        }}
                                    >
                                        {UseConditionToText(useCase)}{' '}
                                    </div>
                                ))}
                        </div>
                        <div>
                            {uniqueUseCases.length > 3 && (
                                <p
                                    data-tip
                                    data-for="viewMore"
                                    style={{
                                        fontSize: mobileScreen ? '11px' : '9px'
                                    }}
                                >
                                    View more
                                </p>
                            )}
                            <ReactTooltip id="viewMore" effect="solid">
                                <div className="flex flex-wrap">
                                    {uniqueUseCases &&
                                        uniqueUseCases.slice(3).map((useCase) => (
                                            <div
                                                className="flex text-black bg-white px-0.5 mr-1 mb-1 rounded-xl border-2 border-newTeal-main"
                                                style={{ fontSize: '9px' }}
                                            >
                                                {UseConditionToText(useCase)}{' '}
                                            </div>
                                        ))}
                                </div>
                            </ReactTooltip>
                        </div>
                    </div>
                </div>
                <div className={`${mobileScreen || smallScreen ? 'mt-1' : ''} flex flex-wrap`}>
                    <div className="text-black h-6 text-base font-medium pr-2 ml-4 mb-1 mr-2">Unit Readiness</div>
                    <div
                        className={`${
                            smallScreen ? 'h-20 pl-5 pr-5' : mobileScreen ? 'h-20 pl-6 pr-5' : 'h-16 pl-4 pr-3'
                        } w-full pl-4 pr-3 flex flex-row`}
                    >
                        <div
                            className="w-1/5 h-full mr-1 py-1 flex flex-col items-center rounded-md"
                            style={{ backgroundColor: '#ffc4c2' }}
                        >
                            <p
                                className={`${mobileScreen ? 'pt-1' : ''} font-bold h-3/5 mb-2  opacity-75`}
                                style={{ fontSize: '28px' }}
                            >
                                {spacesRating(units, 'veryPoor')}
                            </p>
                            <p
                                className=" font-bold "
                                style={{
                                    fontSize: mobileScreen ? '11px' : '9px'
                                }}
                            >
                                Very Poor
                            </p>
                        </div>
                        <div
                            className="w-1/5 h-full mr-1 py-1 flex flex-col items-center rounded-md"
                            style={{ backgroundColor: '#ffe3c2' }}
                        >
                            <p
                                className={`${mobileScreen ? 'pt-1' : ''} font-bold h-3/5 mb-2  opacity-75`}
                                style={{ fontSize: '28px' }}
                            >
                                {spacesRating(units, 'poor')}
                            </p>
                            <p
                                className=" font-bold"
                                style={{
                                    fontSize: mobileScreen ? '11px' : '9px'
                                }}
                            >
                                Poor
                            </p>
                        </div>
                        <div
                            className="w-1/5 h-full mr-1 py-1 flex flex-col items-center rounded-md"
                            style={{ backgroundColor: '#faf6b9' }}
                        >
                            <p
                                className={`${mobileScreen ? 'pt-1' : ''} font-bold h-3/5 mb-2  opacity-75`}
                                style={{ fontSize: '28px' }}
                            >
                                {spacesRating(units, 'fair')}
                            </p>
                            <p
                                className=" font-bold"
                                style={{
                                    fontSize: mobileScreen ? '11px' : '9px'
                                }}
                            >
                                Fair
                            </p>
                        </div>
                        <div
                            className="w-1/5 h-full mr-1 py-1 flex flex-col items-center rounded-md"
                            style={{ backgroundColor: '#e2e7bf' }}
                        >
                            <p
                                className={`${mobileScreen ? 'pt-1' : ''} font-bold h-3/5 mb-2  opacity-75`}
                                style={{ fontSize: '28px' }}
                            >
                                {spacesRating(units, 'good')}
                            </p>
                            <p
                                className=" font-bold"
                                style={{
                                    fontSize: mobileScreen ? '11px' : '9px'
                                }}
                            >
                                Good
                            </p>
                        </div>
                        <div
                            className="w-1/5 h-full mr-1 py-1 flex flex-col items-center rounded-md"
                            style={{ backgroundColor: '#bfe7d1' }}
                        >
                            <p
                                className={`${mobileScreen ? 'pt-1' : ''} font-bold h-3/5 mb-2  opacity-75`}
                                style={{ fontSize: '28px' }}
                            >
                                {spacesRating(units, 'excellent')}
                            </p>
                            <p
                                className=" font-bold"
                                style={{
                                    fontSize: mobileScreen ? '11px' : '9px'
                                }}
                            >
                                Excellent
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            {/*deals*/}

            <div
                className={`${
                    smallScreen ? ' ml-2' : mobileScreen ? 'mx-2 px-2' : ' ml-4'
                } h-64 bg-white  mb-4 rounded `}
                style={{
                    width: smallScreen ? '48%' : mobileScreen ? '91.666667%' : '33.333333%'
                }}
            >
                <div className="ml-3 pt-3 flex flex-row items-center">
                    <div className="h-6 w-2.5 bg-newTeal-main w-20" />
                    <p className="ml-2 text-2xl font-medium">Deals</p>
                </div>
                <div className="flex flex-row mt-2">
                    <div
                        style={{
                            width: mobileScreen ? '10rem' : smallScreen || bigScreen ? '9.5rem' : '12rem',
                            height: mobileScreen ? '5rem' : smallScreen || bigScreen ? '6rem' : '7rem',
                            marginBottom: mobileScreen ? '2rem' : smallScreen || bigScreen ? '2rem' : '1.5rem'
                        }}
                    >
                        <DoughnutChartComponent data={data} />
                    </div>
                    <div className={`${mobileScreen ? 'ml-2' : ''} flex flex-col`}>
                        <div className="flex flex-row ">
                            <div
                                className="rounded-1-m h-4 pr-1 mb-2 w-10 mr-1"
                                style={{
                                    backgroundColor: '#40ddd5',
                                    textAlign: 'right',
                                    fontSize: '13px',
                                    fontWeight: 'bold',
                                    borderRadius: '4px'
                                }}
                            >
                                {completedDeals.length}
                            </div>
                            <p
                                style={{
                                    fontSize: mobileScreen ? '12px' : '10px'
                                }}
                            >
                                Completed Deals
                            </p>
                        </div>
                        <div className="flex flex-row">
                            <div
                                className="rounded-1-m h-4 mb-2 w-5"
                                style={{
                                    borderRadius: '4px 0 0 4px',
                                    backgroundImage:
                                        'linear-gradient(135deg, #87e9e3 25%, transparent 25%, transparent 50%, #87e9e3 50%, #87e9e3 75%, transparent 75%, #fff)'
                                }}
                            />
                            <div
                                className="rounded-1-m h-4 pr-1 mb-2 w-5 mr-1"
                                style={{
                                    backgroundColor: '#87e9e3',
                                    textAlign: 'right',
                                    fontSize: '13px',
                                    fontWeight: 'bold',
                                    borderRadius: '0 4px 4px 0'
                                }}
                            >
                                {activeDeals}
                            </div>
                            <p
                                style={{
                                    fontSize: mobileScreen ? '12px' : '10px'
                                }}
                            >
                                Active Deals
                            </p>
                        </div>
                        <div className="flex flex-row">
                            <div
                                className="rounded-1-m h-4 pr-1 mb-2 w-10 mr-1"
                                style={{
                                    backgroundColor: '#c4f4f1',
                                    textAlign: 'right',
                                    fontSize: '13px',
                                    fontWeight: 'bold',
                                    borderRadius: '4px'
                                }}
                            >
                                {draftDeals.length}
                            </div>
                            <p
                                style={{
                                    fontSize: mobileScreen ? '12px' : '10px'
                                }}
                            >
                                Draft Deals
                            </p>
                        </div>
                        <div
                            className="flex flex-row items-center mt-4 mr-2 p-1 border-2 border-newTeal-main rounded-md"
                            style={{ width: mobileScreen ? '80%' : '90%' }}
                        >
                            <p
                                style={{
                                    fontSize: mobileScreen ? '11px' : '9px'
                                }}
                            >
                                Average time to complete deal{' '}
                            </p>
                            <div className="flex flex-row w-1/2 items-baseline">
                                {!(isNil(completedDealAverageDays) || isNaN(completedDealAverageDays)) ? (
                                    <>
                                        <p className="text-2xl font-semibold opacity-75">{completedDealAverageDays}</p>
                                        <p className="text-xs">days</p>
                                    </>
                                ) : (
                                    <p>No data</p>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`${mobileScreen ? 'mt-1' : 'mt-2'} w-full flex flex-row px-4 items-end `}>
                    <div className="flex w-1/2 rounded-lg pr-1 flex-col  ">
                        <p className="text-xs" style={{ fontSize: mobileScreen ? '11px' : '10px' }}>
                            Deals with them (Landlord)
                        </p>
                        <div>
                            <ProgressBar
                                completed={dealsWithLandlord.length.toString()}
                                maxCompleted={dealsWithLandlord.length + dealsWithTenant.length}
                                labelAlignment={'outside'}
                                labelColor="#000000"
                                bgColor="#40ddd5"
                                height="10px"
                                width={mobileScreen ? '85%' : '80%'}
                            />
                        </div>
                    </div>
                    <div className="flex w-1/2 rounded-lg flex-col">
                        <p className="text-xs" style={{ fontSize: mobileScreen ? '11px' : '10px' }}>
                            Deals with other party (Tenant)
                        </p>
                        <div>
                            <ProgressBar
                                completed={dealsWithTenant.length.toString()}
                                maxCompleted={dealsWithLandlord.length + dealsWithTenant.length}
                                labelAlignment={'outside'}
                                labelColor="#000000"
                                bgColor="#40ddd5"
                                height="10px"
                                width={mobileScreen ? '85%' : '80%'}
                            />
                        </div>
                    </div>
                </div>
            </div>

            {/*custom data*/}

            <div
                className={`${mobileScreen ? 'mx-2 px-2' : ' ml-4 '} h-64 bg-white  mb-4 rounded `}
                style={{
                    width: smallScreen ? '48%' : mobileScreen ? '91.666667%' : '33.333333%'
                }}
            >
                <div className="ml-3 pt-3 flex flex-row items-center">
                    <div className="h-6 w-2.5 bg-newTeal-main w-20" />
                    <p className="ml-2 text-2xl font-medium">Custom Data</p>
                </div>
                <div className="flex flex-row w-full h-52 px-4 py-4">
                    <div className="w-1/2 mx-3 mb-2 rounded-lg h-full relative" style={{ backgroundColor: '#c4f4f3' }}>
                        <p className="text-4xl font-semibold  opacity-75 ml-4 mt-2">{clauses.length}</p>
                        <div
                            className="w-full bg-newTeal-main bottom-0 rounded-b-lg absolute"
                            style={{ height: `${20}%` }}
                        />
                        <p
                            className="absolute bottom-0 right-0 mr-3 text-xs font-semibold mb-2"
                            style={{ color: 'white' }}
                        >
                            Clauses
                        </p>
                    </div>
                    <div className="w-1/2 mx-3 mb-2 rounded-lg h-full relative" style={{ backgroundColor: '#c4f4f3' }}>
                        <p className="text-4xl font-semibold  opacity-75 ml-4 mt-2">{templates.length}</p>
                        <div
                            className="w-full bg-newTeal-main bottom-0 rounded-b-lg absolute"
                            style={{ height: `${20}%` }}
                        />
                        <p
                            className="absolute bottom-0 right-0 mr-3 text-xs font-semibold mb-2"
                            style={{ color: 'white' }}
                        >
                            Templates
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Stats;
