import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import NudgeButton from './NudgeButton';
import { ReactComponent as Tick } from '../../assets/images/check/black.svg';
import { ReactComponent as Chevron } from '../../assets/images/chevron/right/black.svg';
import {
    ClauseSummary,
    DealRoundType,
    DealState,
    DealUserParty,
    useGetDealStateLazyQuery,
    useGetDealTenantDetailsQuery
} from '../../generated/graphql';
import { Button } from '../../shared/button';
import Card from '../../shared/Grid/Card';
import { DealCardImportant, dealMostImportant, getOrdinal } from '../../shared/helpers';
import Loading from '../../shared/Loading';

type NegotiationsCardProps = {
    id: string;
    name: string;
    unitID: string;
    unitRating: number;
    pending?: boolean;
    state?: DealState;
    currentUserParty?: DealUserParty | null;
    round?: DealRoundType | null;
    clauseSummary?: ClauseSummary;
    className?: string;
    tenantView?: boolean;
    setShowTenantDetails: React.Dispatch<React.SetStateAction<boolean>>;
    setSelectedDealId: React.Dispatch<React.SetStateAction<string>>;
};

const NegotiationsCard = ({
    id,
    name,
    unitRating = 0,
    pending = false,
    state,
    currentUserParty,
    round,
    clauseSummary,
    className,
    tenantView,
    setShowTenantDetails,
    setSelectedDealId
}: NegotiationsCardProps) => {
    const history = useHistory();

    // Prefetch deal state for faster redirect
    const [getDealState] = useGetDealStateLazyQuery({
        variables: {
            dealID: id
        }
    });

    // Get Tenant Readiness  Stage
    const { data: detailsData, loading: detailsLoading } = useGetDealTenantDetailsQuery({
        variables: {
            dealID: id
        },
        fetchPolicy: 'cache-and-network'
    });

    // Details
    const { ...details } = detailsData?.deal.__typename === 'Deal' && detailsData.deal.details;
    delete details['__typename'];

    // Get the total number of stages that's been actioned
    let totalCompleted = 0;
    for (const key in details) {
        if (details[key]?.completed) {
            totalCompleted++;
        }
    }

    const [allAgreed, setAllAgreed] = useState<boolean>(false);

    useEffect(() => {
        if (clauseSummary && clauseSummary.incomplete === 0) setAllAgreed(true);
    }, [clauseSummary]);

    const days = Math.ceil((new Date().getTime() - new Date(round?.date).getTime()) / (1000 * 3600 * 24));

    const mostImportant =
        state && currentUserParty
            ? dealMostImportant(state, currentUserParty, days, unitRating)
            : DealCardImportant.READINESS;

    const roundsOrdinal = clauseSummary && state !== DealState.Edit && round && getOrdinal(round.count);

    const rounds = (clauseSummary && state !== DealState.Edit && round && round.count) ?? 0;

    const getBorderColor = (days: number) => {
        if (currentUserParty === DealUserParty.Tenant) {
            return days <= 4 ? 'border-transparent' : days >= 5 && days <= 8 ? 'border-orange-400' : 'border-red-500';
        }
        if (currentUserParty === DealUserParty.Landlord) {
            return days <= 3 ? 'border-transparent' : days >= 4 && days <= 6 ? 'border-orange-400' : 'border-red-500';
        }
    };

    if (detailsLoading)
        return (
            <div className="col-span-5">
                <Loading />
            </div>
        );

    if (detailsData?.deal.__typename !== 'Deal') return null;

    return (
        <>
            <Card options={[]} className={`${pending && `opacity-50`} ${className}`}>
                <div onMouseOver={() => getDealState()} className="border border-newTeal-main rounded">
                    <>
                        {/* Title */}
                        <div
                            className="mb-4 p-4 pb-0 cursor-pointer"
                            onClick={() => {
                                history.push(tenantView ? `/units/deal/${id}` : `/deals/${id}`);
                            }}
                        >
                            <Chevron className="w-6 h-6 absolute right-4 top-7" />
                            <h2 className={`text-3xl font-semibold mb-1`}>
                                {clauseSummary && state !== DealState.Edit && round ? name : 'Draft Deal'}
                            </h2>
                        </div>
                        {clauseSummary && state !== DealState.Edit && round ? (
                            <>
                                <div
                                    onClick={() => {
                                        history.push(tenantView ? `/units/deal/${id}` : `/deals/${id}`);
                                    }}
                                    className="cursor-pointer"
                                >
                                    {/* Bar Container */}
                                    <div className={`w-full px-4`}>
                                        <div className="rounded bg-gray-200">
                                            {!allAgreed || state !== DealState.Complete ? (
                                                <div className={`flex items-center justify-between text-sm px-4 py-1`}>
                                                    {/* Nr Clauses */}
                                                    <span>
                                                        <span className="text-xl font-semibold">
                                                            {clauseSummary.complete}
                                                        </span>
                                                        <span
                                                            className={`
                                                                "text-gray-600 text-xs"
                                                            `}
                                                        >
                                                            /{clauseSummary.complete + clauseSummary.incomplete}
                                                        </span>{' '}
                                                        <span className="text-sm ml-2">Clauses Agreed</span>
                                                    </span>
                                                    {/* Rounds */}
                                                    <div className="flex items-center">
                                                        <div
                                                            className={`w-3 h-3 rounded-full ${
                                                                rounds > 2 && rounds < 5
                                                                    ? 'bg-orange-400'
                                                                    : rounds >= 5
                                                                    ? 'bg-red-500'
                                                                    : 'bg-transparent'
                                                            } mr-1`}
                                                        ></div>
                                                        <span
                                                            className={`text-sm
                                                                text-gray-600
                                                            `}
                                                        >
                                                            {roundsOrdinal} Round Negs
                                                        </span>
                                                    </div>
                                                </div>
                                            ) : (
                                                <div className="max-h-full">
                                                    <p className="flex flex-col justify-center items-center py-8">
                                                        <Tick className="w-6 h-6 text-newTeal-main" />
                                                        <span className="font-medium text-gray-600">Deal Agreed</span>
                                                    </p>
                                                </div>
                                            )}
                                            {/* Bar */}
                                            <div className="bg-navy-lightest w-full h-3 relative overflow-hidden rounded-bl rounded-br">
                                                <span
                                                    className={`h-full ${
                                                        !allAgreed ? 'bg-newGray-darkish' : 'bg-gray-600'
                                                    } absolute top-0 left-0`}
                                                    style={{
                                                        width: `${
                                                            (clauseSummary.complete /
                                                                (clauseSummary.complete + clauseSummary.incomplete)) *
                                                            100
                                                        }%`
                                                    }}
                                                ></span>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Buttons */}
                                    {!allAgreed && (
                                        <div className="flex justify-between items-stretch rounded-b overflow-hidden relative mt-2 px-4">
                                            {/* Days with you/them button */}
                                            <div
                                                className={`flex-1 flex items-center justify-between bg-navy-lightest rounded quickTransitionAll hover:shadow-md border ${
                                                    mostImportant === DealCardImportant.NEGOTIATIONS &&
                                                    'bg-navy-lightest'
                                                } ${getBorderColor(days)} px-1 py-2`}
                                            >
                                                <div className="ml-3 w-full">
                                                    <div className="flex flex-row items-center justify-between w-full">
                                                        <div>
                                                            {' '}
                                                            <p className={`font-light ${'text-gray-600 text-xs'}`}>
                                                                Negotiations
                                                            </p>
                                                            <p className="font-bold text-sm">
                                                                <span className={`${'text-xl'}`}>{days}</span> Day
                                                                {days !== 1 && 's'} with{' '}
                                                                {(state === DealState.Landlord &&
                                                                    currentUserParty === DealUserParty.Landlord) ||
                                                                (state === DealState.Tenant &&
                                                                    currentUserParty === DealUserParty.Tenant)
                                                                    ? 'You'
                                                                    : 'Them'}
                                                            </p>
                                                        </div>
                                                        {/* Nudge button */}

                                                        <div
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                            }}
                                                        >
                                                            <NudgeButton
                                                                dealID={id}
                                                                currentUserParty={currentUserParty}
                                                                state={state}
                                                                round={round}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                                {/* Tenant Readiness / View Information */}
                                <div className="border-t border-navy-light py-4 mt-3 flex justify-between items-center">
                                    {/* Readiness */}
                                    <div className="ml-4">
                                        <p>Tentant Readiness</p>
                                        <p className="-mt-1">
                                            <span className="font-semibold text-3xl">Stages {totalCompleted}</span>
                                            <span className="font-sm">/6</span>
                                        </p>
                                    </div>
                                    {/* Information */}
                                    <Button
                                        className="mr-4 px-10 py-3 rounded bg-white border border-navy-lightest cursor-pointer"
                                        onClick={() => {
                                            setShowTenantDetails(true);
                                            setSelectedDealId(id);
                                        }}
                                    >
                                        <p className="font-semibold">View Information</p>
                                    </Button>
                                </div>
                            </>
                        ) : (
                            // Cards that haven't been sent
                            <div
                                className="px-4 pb-4 cursor-pointer"
                                onClick={() => {
                                    history.push(tenantView ? `/units/deal/${id}` : `/deals/${id}`);
                                }}
                            >
                                <div className="max-h-full bg-navy-lightest rounded">
                                    <p className="flex flex-col justify-center items-center py-12">
                                        <span className="font-medium text-gray-600">
                                            Draft deal - Not sent to the tenant yet
                                        </span>
                                    </p>
                                </div>
                            </div>
                        )}
                    </>
                </div>
            </Card>
        </>
    );
};

export default NegotiationsCard;
