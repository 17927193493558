import { ClauseCommentFragment, ClauseHistoryFragment } from '../../../../generated/graphql';

export type LinkedClauses = {
    value?: string | null;
    valueSuffix?: string | null;
    shortForm?: string | null;
    longForm?: string | null;
}[];

export enum ClauseMode {
    // Can only see clause history & info
    VIEW,
    // Can see accept/reject
    EDIT,
    // Can see undo button
    MODIFIED
}

export enum ClauseType {
    DEFAULT,
    NEW,
    VALUE,
    CHANGED,
    REMOVED,
    SWAPPED,
    COMMENTED,
    APPROVED
}

export type History = ClauseHistoryFragment[];

export type Comments = ClauseCommentFragment[];
