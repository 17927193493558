import _ from 'lodash';
import React, { useContext } from 'react';

import { ReactComponent as Check } from '../../assets/images/check/regularCheck/check.svg';
import { ReactComponent as FiltersIcon } from '../../assets/images/filters/sliders.svg';
import { ReactComponent as Search } from '../../assets/images/search/currentColor.svg';
import { ReactComponent as UploadIcon } from '../../assets/images/upload/gray.svg';
import {
    SingleUnitCardFragment,
    UnitAvailability,
    UnitFilterCount,
    useGetBuildingsAutofillDetailsLazyQuery,
    User
} from '../../generated/graphql';
import { AddButton } from '../../shared/add-button';
import { BulkSelectContext } from '../../shared/bulk/context';
import { Button } from '../../shared/button';
import { FilterContext } from '../../shared/Filter/context';
import SortChip from '../../shared/Filter/SortChip';
import { IconButton } from '../../shared/icon-button';
import Input, { InputType } from '../../shared/Inputs';
import { RadioValue } from '../../shared/Inputs/Radio';
import Select from '../../shared/Inputs/Select';

type UnitsHeaderProps = {
    isSearching?: string;
    isMobile?: boolean | null;
    isAvailableToLetFilter: boolean;
    searchSpaces: (search: string) => void;
    user?: User;
    setUnitAvailability: React.Dispatch<React.SetStateAction<UnitAvailability>>;
    setShowNewUnitWindow: React.Dispatch<React.SetStateAction<boolean>>;
    unitFilterCounts?: UnitFilterCount;
    isLandlordOrAdmin?: boolean;
    getUnitFields: () => RadioValue[];
    units: SingleUnitCardFragment[];
    selectedHiddenUnits: number;
    selectedUnhidenUnits: number;
    unitAvailability: UnitAvailability;
    handleBulkClick: (id: string) => void;
    handleExportUnits: () => void;
};

const UnitsHeader = ({
    isSearching,
    isMobile,
    isAvailableToLetFilter,
    searchSpaces,
    user,
    setUnitAvailability,
    unitFilterCounts,
    setShowNewUnitWindow,
    getUnitFields,
    isLandlordOrAdmin,
    units,
    unitAvailability,
    handleBulkClick,
    handleExportUnits
}: UnitsHeaderProps) => {
    const { values, openWindow, sort, chips } = useContext(FilterContext);
    const { selectMode, saveSelectMode, selectAll, saveSelectAll, selectedItems, saveSelectedItems, all, deselect } =
        useContext(BulkSelectContext);

    const [loadBuildings] = useGetBuildingsAutofillDetailsLazyQuery();

    return (
        <>
            <div className={`w-full flex items-start justify-between flex-row`}>
                <div className="w-100">
                    <div className="flex items-start w-full">
                        <div className={`relative ${isMobile ? 'w-full' : 'w-64'} flex items-center  justify-center`}>
                            <Search
                                className="w-6 h-6 absolute right-6 text-newGray-main z-20"
                                style={{ top: '16px' }}
                            />
                            <div className="border-r border-newGray-main absolute h-8 z-20 right-15 " />
                            <Input
                                type={InputType.text}
                                placeholder="Search"
                                className="h-15 mr-2 w-full"
                                autoFocus
                                onChange={(e) => searchSpaces(e.target.value)}
                            />
                        </div>
                        {!isSearching && (
                            <>
                                <div className="border-r border-newGray-main h-12 z-20 mr-4 ml-2 my-auto" />
                                <Button
                                    background="white"
                                    text="newGray-darkish"
                                    height="15"
                                    className="border-white hover:bg-navy-lightest w-15"
                                    onClick={() => openWindow()}
                                >
                                    <FiltersIcon />
                                </Button>
                                {!isMobile && (
                                    <div className="flex flex-row w-9/12 flex-wrap justify-start gap-1 ml-3">
                                        {sort && user && <SortChip sortOptions={getUnitFields()} />}
                                        {values && chips()}
                                    </div>
                                )}
                            </>
                        )}
                    </div>
                </div>

                <div className="flex flex-col items-end">
                    {!isSearching && (
                        <div className="flex">
                            <IconButton
                                id="export-units"
                                icon={UploadIcon}
                                tooltip="Export all spaces"
                                className={isMobile ? 'mx-2' : 'mr-4'}
                                onClick={handleExportUnits}
                            />
                            <AddButton
                                id="unit-add-button"
                                tooltip="New Space"
                                onClick={() => {
                                    setShowNewUnitWindow(true);
                                }}
                                text="black hover:text-white"
                                onHover={() => loadBuildings()}
                                height="15"
                                className={`${
                                    isMobile ? 'mb-2' : ''
                                } px-4 bg-newTeal-light hover:bg-newTeal-main border-none w-15`}
                            />
                        </div>
                    )}
                </div>
            </div>
            {isMobile && (
                <div className="flex flex-row w-9/12 flex-wrap justify-start gap-1 ">
                    {sort && user && <SortChip sortOptions={getUnitFields()} />}
                    {values && chips()}
                </div>
            )}
            <div className="flex flex-row justify-between">
                <div className="mt-6 flex items-center flex-wrap justify-between">
                    {!isSearching && (
                        <div className="mb-4 flex items-center flex-wrap">
                            <Button
                                background={`${isAvailableToLetFilter ? 'newGray-darkish' : 'white'} mr-2`}
                                text={`${isAvailableToLetFilter ? 'white' : 'black'}`}
                                className={`${!isAvailableToLetFilter && 'hover:shadow-lg'} px-6 w-50 font-semibold`}
                                onClick={() => setUnitAvailability(UnitAvailability.AvailableToLet)}
                            >
                                Available to Let ({unitFilterCounts?.availableToLet})
                            </Button>
                            <Button
                                background={`${isAvailableToLetFilter ? 'white' : 'newGray-darkish'} mr-2`}
                                text={`${isAvailableToLetFilter ? 'black' : 'white'}`}
                                className={`${isAvailableToLetFilter && 'hover:shadow-lg'} px-6 w-50 font-semibold`}
                                onClick={() => setUnitAvailability(UnitAvailability.UnavailableToLet)}
                            >
                                Archive ({unitFilterCounts?.unavailableToLet})
                            </Button>
                        </div>
                    )}
                </div>
            </div>
            {selectMode && !_.isEmpty(selectedItems) && (
                <>
                    <div className="w-full border-b border-newGray-main h-auto mb-4" />
                    <div className="flex flex-row">
                        <div
                            className="mr-2 font-semibold px-4 h-12 w-10-3 hover:shadow-lg text-black w-auto bg-white text-sm md:text-lg inline-flex rounded items-center justify-center text-center cursor-pointer"
                            onClick={() => {
                                saveSelectedItems([]);
                                deselect();
                                saveSelectAll(!selectAll);
                                if (all) {
                                    saveSelectMode(false);
                                    saveSelectAll(false);
                                }
                            }}
                        >
                            <div className={`w-5 h-5 rounded z-20 mr-2 ${all ? 'bg-newTeal-main' : 'bg-navy-light'} `}>
                                {all && <Check className="w-5 h-5 text-white" />}
                            </div>
                            {all ? 'Deselect All' : 'Select All'}
                        </div>
                        <Select
                            isBulk
                            data={
                                isLandlordOrAdmin
                                    ? [
                                          {
                                              id: 'addUser',
                                              text: 'Add User'
                                          },
                                          {
                                              id: 'availability',
                                              text:
                                                  unitAvailability &&
                                                  unitAvailability === UnitAvailability.AvailableToLet
                                                      ? 'Archive'
                                                      : 'Available to Let'
                                          }
                                      ]
                                    : []
                            }
                            onItemClick={(id) => handleBulkClick(id)}
                            value={{
                                value: 'placeholder',
                                label: `Bulk Action (${
                                    units.length === selectedItems.length ? units.length : selectedItems.length
                                })`
                            }}
                            searchable={false}
                            borderRadius="0.25rem"
                            className="h-12 text-black text-sm md:text-lg w-10-3"
                        />
                    </div>
                </>
            )}
        </>
    );
};

export default UnitsHeader;
