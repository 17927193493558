import React, { useState } from 'react';

import Window from '../../shared/Window';

type DeleteTemplateWindowProps = {
    setDeleteTemplateWindow: React.Dispatch<React.SetStateAction<boolean>>;
    handleDeleteClick: () => void;
};

const DeleteTemplateWindow = ({ setDeleteTemplateWindow, handleDeleteClick }: DeleteTemplateWindowProps) => {
    const [twoStepConfirmation, setTwoStepConfirmation] = useState<boolean>(true);

    return (
        <Window
            title={'Delete Template'}
            titleCenter
            underlined
            width="w-2/5"
            setShow={setDeleteTemplateWindow}
            primaryBtn
            primaryBtnText={twoStepConfirmation ? 'Yes' : 'Confirm'}
            onPrimaryBtnClick={() =>
                twoStepConfirmation
                    ? setTwoStepConfirmation(false)
                    : (setDeleteTemplateWindow(false), handleDeleteClick())
            }
            secondaryBtn
            secondaryBtnText={twoStepConfirmation ? 'No' : 'Cancel'}
            onSecondaryBtnClick={() => setDeleteTemplateWindow(false)}
        >
            <div className="flex flex-col items-center">
                {twoStepConfirmation ? (
                    <h2 className="text-center text-lg my-8 w-4/5">
                        Are you sure you want to
                        <span className="font-semibold text-uppercase"> delete </span> this template? If yes, this
                        template will be
                        <span className="font-semibold text-uppercase"> deleted </span> for you and all users with
                        access to it.
                    </h2>
                ) : (
                    <h2 className="text-center text-lg my-8 w-4/5">
                        Select <span className="font-semibold text-uppercase"> Confirm </span> to
                        <span className="font-semibold text-uppercase"> delete </span> template.
                    </h2>
                )}
            </div>
        </Window>
    );
};

export default DeleteTemplateWindow;
