import React, { useState } from 'react';

import { ReactComponent as DownArrow } from '../../../assets/images/chevron/down/black.svg';
import { ReactComponent as UpArrow } from '../../../assets/images/chevron/up/black.svg';
import { Button } from '../../button';
import { formatClause } from '../../helpers';
import Input, { InputType } from '../../Inputs';

interface ClauseProps {
    valueIsRequired?: boolean | null;
    value?: string;
    valueSuffix?: string;
    shortForm?: string;
    longForm?: string;
    tags: string[];
    currentValue?: string;
    currentValueSuffix?: string;
    currentLongForm?: string;
    currentTags: string[];
    onClick: (value?: string, valueSuffix?: string, shortForm?: string, longForm?: string, tags?: string[]) => void;
}

const Clause = ({
    value,
    valueSuffix,
    shortForm,
    longForm,
    tags,
    currentValue,
    currentValueSuffix,
    currentLongForm,
    currentTags,
    valueIsRequired,
    onClick
}: ClauseProps) => {
    const [open, setOpen] = useState<boolean>(false);
    const [inputValue, setInputValue] = useState<number | string | null | undefined>(value);

    const formattedLongClause = {
        __html: formatClause(longForm, inputValue, valueSuffix, tags) ?? ''
    };
    const formattedCurrentLongClause = {
        __html: formatClause(currentLongForm, currentValue, currentValueSuffix, currentTags) ?? ''
    };

    const notNullValue = !(value === '' || value === null);

    return (
        <div className="w-full border-b border-navy-light bg-white relative">
            <div
                className="w-full flex flex-row items-center justify-between px-6 pt-4 pb-3 cursor-pointer"
                onClick={() => setOpen(!open)}
            >
                {shortForm}
                {open ? <UpArrow className="w-6 h-6" /> : <DownArrow className="w-6 h-6" />}
            </div>
            {open && (
                <div className="w-full bg-navy-lightest">
                    <div className="px-6 pt-1 pb-3 text-sm">
                        <span className="font-bold text-xxs">Old Clause</span>
                        <p dangerouslySetInnerHTML={formattedCurrentLongClause}></p>
                    </div>
                    {currentLongForm && (
                        <div className="px-6 pt-1 pb-3 text-sm bg-white">
                            <span className="font-bold text-xxs">New Clause</span>
                            <p dangerouslySetInnerHTML={formattedLongClause}></p>
                        </div>
                    )}
                    {valueIsRequired && (
                        <div className="text-center border border-red text-red-700">
                            <p>This clause requires a valid value</p>
                        </div>
                    )}
                    <div className="w-full flex flex-row items-center justify-between border-grey-400 border-t px-6 py-2">
                        <div className="flex flex-row justify-center items-center">
                            {valueSuffix && (valueSuffix === '£' || valueSuffix === '$' || valueSuffix === '€') && (
                                <span className="mr-2">{valueSuffix}</span>
                            )}
                            {notNullValue && (
                                <Input
                                    type={InputType.text}
                                    placeholder="Value"
                                    value={inputValue}
                                    onChange={(e) => {
                                        setInputValue(e.target.value);
                                    }}
                                    className="w-32 text-center mr-4"
                                />
                            )}
                            {valueSuffix && valueSuffix !== '£' && valueSuffix !== '$' && valueSuffix !== '€' && (
                                <span className="mr-2">{valueSuffix}</span>
                            )}
                        </div>
                        <Button
                            onClick={() => {
                                onClick(
                                    inputValue ? inputValue.toString() : '',
                                    valueSuffix ?? '',
                                    shortForm ?? '',
                                    longForm ?? '',
                                    tags ?? []
                                );
                            }}
                            background="newTeal-main"
                            text="white"
                            className="px-4"
                        >
                            Change Clause
                        </Button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Clause;
