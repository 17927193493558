import React, { useEffect, useRef, useState } from 'react';

import { ReactComponent as UploadIcon } from '../../assets/images/upload/black.svg';
import { Button } from '../button';

type UnitAvatarProps = {
    avatar?: string;
    setNewAvatar: (file: any) => void;
    editing?: boolean;
};

const UnitAvatarUpload = ({ setNewAvatar, avatar, editing }: UnitAvatarProps) => {
    const [tempImage, setTempImage] = useState<string>('');
    const uploadRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (avatar) setTempImage(avatar);
    }, [avatar]);

    const handleFileUpload = () => {
        const file: any = uploadRef?.current?.files ? uploadRef?.current?.files[0] : null;

        if (file) {
            const data = new FormData();
            data.append('file', file);

            const isImage = file.type === 'image/png' || file.type === 'image/jpeg' || file.type === 'image/gif';
            if (!isImage) return;

            setNewAvatar(file);
            setTempImage(URL.createObjectURL(file));
        } else return;
    };

    return (
        <div
            className={`flex-column ${!tempImage && editing && 'h-64 mt-auto'} ${
                editing ? 'bg-navy-light rounded overflow-hidden w-full' : 'w-full'
            }`}
        >
            {!tempImage ? (
                <Button
                    text="black"
                    background="navy-light"
                    onClick={(e) => {
                        e.preventDefault();
                        uploadRef.current?.click();
                    }}
                    className="px-4 w-full h-64 text-left mb-4 flex flex-col gap-2"
                >
                    <UploadIcon className="mr-2 w-8 text-black mt-2" />
                    <span>Upload Building Logo</span>
                </Button>
            ) : (
                <div
                    className="relative w-full h-64 cursor-pointer"
                    onClick={(e) => {
                        e.preventDefault();
                        uploadRef.current?.click();
                    }}
                >
                    <div className="opacity-0 hover:opacity-50 bg-black text-white transition duration-150 absolute top-0 left-0 right-0 bottom-0 flex items-center justify-center">
                        <UploadIcon className="w-10 h-10" />
                    </div>
                    <img src={tempImage} alt="" className="mx-auto object-cover p-2 w-full h-full" />
                </div>
            )}
            <input type="file" name="file" ref={uploadRef} onChange={handleFileUpload} className="hidden" />
        </div>
    );
};

export default UnitAvatarUpload;
