import React, { forwardRef } from 'react';
import { useIntercom } from 'react-use-intercom';

import { ReactComponent as Mail } from '../../../src/assets/images/envelope/black.svg';

type HelpDropdownProps = {
    close: () => void;
};

const HelpDropdown = forwardRef(({ close }: HelpDropdownProps, ref: any) => {
    const { show } = useIntercom();

    return (
        <>
            <div className="flex flex-col items-center fixed lg:mr-0 xl:mr-15 right-2 z-60" ref={ref}>
                <div
                    className="w-4"
                    style={{
                        borderWidth: '2px 7px 10px',
                        borderBottomColor: 'white',
                        borderTopColor: 'rgba(0,0,0, 0.02)',
                        borderRightColor: 'rgba(0,0,0, 0.02)',
                        borderLeftColor: 'rgba(0,0,0, 0.02)'
                    }}
                />
                <div className="w-auto bg-white shadow rounded-lg py-2">
                    {/* <NavContainer flexRow backgroundColor="white">
                        <NavLink
                            to={`/guidance-videos`}
                            className="px-4 py-2 font-semibold md:text-base xxl:text-lg flex flex-row items-center hover:bg-navy-lightest "
                        >
                            <Video className="w-4 h-4 mr-4" />
                            <p onClick={close}>Guidance Videos</p>
                        </NavLink>
                    </NavContainer> */}

                    <p
                        className="px-4 py-2 font-semibold  md:text-base xxl:text-lg flex items-center flex-row hover:bg-navy-lightest "
                        onClick={() => {
                            show();
                            close();
                        }}
                    >
                        <Mail className="w-4 h-4 mr-4" />
                        Contact Us
                    </p>
                </div>
            </div>
            <div className="absolute w-full h-full z-30  hidden md:block" onClick={close} />
        </>
    );
});

export default HelpDropdown;
