import { ApolloError } from '@apollo/client';
import { FormikErrors } from 'formik';
import { isEmpty } from 'lodash';
import * as Yup from 'yup';

import { DealState, DealUserParty, UserType } from '../../generated/graphql';

export const PAGES = {
    DASHBOARD: '/',
    DEALS: '/deals',
    DEAL: '/deals/',
    UNITS: '/units',
    TEMPLATES: '/templates',
    NOTIFICATIONS: '/notifications',
    SETTINGS: '/settings',
    GUIDANCE_VIDEOS: '/guidance-videos'
};

export const pageMatch = (current: string, selected: string[]) => selected.some((page) => current.startsWith(page));

export const hasErrors = <T>(errors: FormikErrors<T>): boolean => !isEmpty(errors);

export const isAdmin = (type?: UserType) => type === UserType.Admin;
export const isLandlord = (type?: UserType) => type === UserType.Landlord;
export const isTenant = (type?: UserType) => type === UserType.Tenant;
export const isAgent = (type?: UserType) => type === UserType.Agent;

export const useUserType = (type?: UserType, party?: DealUserParty) => {
    return {
        isAdmin: isAdmin(type),
        isLandlord: isLandlord(type),
        isLandlordOrAdmin: isAdmin(type) || isLandlord(type),
        isTenant: isTenant(type),
        isAgent: isAgent(type),
        isLandlordAgent: isAgent(type) && party === DealUserParty.Landlord,
        isTenantAgent: isAgent(type) && party === DealUserParty.Tenant,
        isTenantSide: party === DealUserParty.Tenant,
        isLandlordSide: party === DealUserParty.Landlord,
        isOther: party === DealUserParty.Other
    };
};

export const requiredIfVisible = (
    base: Yup.StringSchema<string | null | undefined>,
    error: string,
    fieldName: string
) => base.when(fieldName, { is: true, then: Yup.string().required(error) });

export const isErrorCode = (code: string, error?: ApolloError) =>
    error?.graphQLErrors[0] && error.graphQLErrors[0].extensions?.code === code;

export const isTenantSideMobileView = (currentParty?: DealUserParty, isMobile?: boolean) => {
    return currentParty === DealUserParty.Tenant && isMobile;
};

export const isCompletedDeal = (state?: DealState) => {
    return state === DealState.Complete;
};
export const isDealEdit = (state?: DealState) => {
    return state === DealState.Edit;
};
export const isDealInNegotiate = (state?: DealState) => {
    return state === DealState.Landlord || state === DealState.Tenant || state === DealState.Agreed;
};

export const isLandlordSide = (currentParty?: DealUserParty) => {
    return currentParty === DealUserParty.Landlord;
};
