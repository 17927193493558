const isPushNotificationSupported = () =>
    'Notification' in window && 'serviceWorker' in navigator && 'PushManager' in window;

const askUserPermission = async () => await Notification.requestPermission();

const sendNotification = () => {
    const title = 'Hello, World!';
    const text = 'Can you hear me?';
    const options = {
        body: text,
        tag: 'new',
        actions: [
            {
                action: 'Detail',
                title: 'View',
                icon: 'https://via.placeholder.com/128/ff0000'
            }
        ]
    };
    if (!navigator.serviceWorker) return;

    navigator.serviceWorker.ready.then((serviceWorker) => serviceWorker.showNotification(title, options));
};

const registerServiceWorker = () => navigator.serviceWorker.register('/sw.js');

const createNotificationSubscription = async () => {
    if (!navigator.serviceWorker) return;
    // Wait for SW to install
    const serviceWorker = await navigator.serviceWorker.ready;

    // Subscribe user
    return await serviceWorker.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: process.env.REACT_APP_VAPID_PUBLIC_KEY
    });
};

const getUserSubscription = () => {
    if (!navigator.serviceWorker) return;
    // Wait for SW to install
    return navigator.serviceWorker.ready
        .then((serviceWorker) => serviceWorker.pushManager.getSubscription())
        .then((pushSubscription) => pushSubscription);
};

export {
    isPushNotificationSupported,
    askUserPermission,
    registerServiceWorker,
    sendNotification,
    createNotificationSubscription,
    getUserSubscription
};
