import React from 'react';

import {
    GetDealDetailsDocument,
    GetDealDetailsNoHistoryDocument,
    useRemoveTenantMutation
} from '../../../../../../generated/graphql';
import Loading from '../../../../../../shared/Loading';
import Window from '../../../../../../shared/Window';

type ConfirmRemoveTenantWindowProps = {
    dealID: string;
    tenantID: string;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
};

const ConfirmRemoveTenantWindow = ({ dealID, tenantID, setShow }: ConfirmRemoveTenantWindowProps) => {
    const [removeTenant, { error: removeTenantError, loading: removeTenantLoading }] = useRemoveTenantMutation();

    const handleClick = async () => {
        await removeTenant({
            variables: {
                dealID,
                tenantID
            },
            refetchQueries: () => [GetDealDetailsDocument, GetDealDetailsNoHistoryDocument]
        });

        if (removeTenantError) return;

        setShow(false);
    };

    if (removeTenantLoading)
        return (
            <Window title="Remove Tenant" width="w-1/4" setShow={setShow}>
                <Loading />
            </Window>
        );

    return (
        <Window
            title="Remove Tenant"
            titleCenter
            contentMiddle={true}
            width="w-2/4"
            primaryBtn
            onPrimaryBtnClick={handleClick}
            secondaryBtn
            onSecondaryBtnClick={() => setShow(false)}
            setShow={setShow}
        >
            <div className="flex flex-col items-center">
                <h2 className="text-center text-lg my-8 w-4/5">
                    Are you sure you want to <span className="font-semibold text-red-500 text-uppercase">remove</span>{' '}
                    the Tenant?
                </h2>
            </div>
        </Window>
    );
};

export default ConfirmRemoveTenantWindow;
