import React, { useMemo } from 'react';

import ClauseContext from './Context';
import { DealState, DealUserParty } from '../../../../../generated/graphql';
import useMobileScreen from '../../../../../shared/hooks/useMobileScreen';
import Input, { InputType } from '../../../../../shared/Inputs';
import { ClauseMode, ClauseType } from '../Types';

const Value = () => {
    const {
        value,
        dealState,
        valueSuffix,
        tags,
        history,
        type,
        isEditing,
        mode,
        dealBreaker,
        currentUserParty,
        setValue,
        setEditing,
        handleSaveClick
    } = React.useContext(ClauseContext);
    const numeric_thousands_value =
        !isNaN(Number(value)) && !tags?.includes('no_format') ? Number(value).toLocaleString() : value;

    let previousValue = history && history.length > 0 ? history[history.length - 1].change?.old?.value : null;

    if (previousValue === '*') previousValue = null;

    const mobileScreen = useMobileScreen();

    const valueColor = useMemo(() => {
        if (!type) {
            return '';
        }

        if ([ClauseType.APPROVED, ClauseType.DEFAULT].includes(type)) {
            return 'bg-newTeal-light';
        }

        return 'bg-orange-50';
    }, [type]);

    return (
        <div
            className={`flex justify-start items-center w-1/3 ${
                type !== ClauseType.APPROVED ? 'md:mr-16' : 'md:mr-2'
            } ${type !== ClauseType.DEFAULT && 'valueHidden'} valueVisible`}
        >
            {/* Value */}
            {value !== undefined && (
                <form
                    className={`flex flex-row justify-center items-center rounded max-w-full my-2 ${valueColor} ${
                        isEditing && !mobileScreen && 'ml-8'
                    }`}
                    onSubmit={(e) => {
                        e.preventDefault();
                        value && handleSaveClick && handleSaveClick(value, value);
                    }}
                >
                    {((valueSuffix && valueSuffix.trim().length > 0) ||
                        (value != null && value.trim().length > 0) ||
                        isEditing) && (
                        <>
                            {valueSuffix && (valueSuffix === '£' || valueSuffix === '$' || valueSuffix === '€') && (
                                <span className="mx-4">{valueSuffix}</span>
                            )}
                            {isEditing ? (
                                <Input
                                    type={InputType.text}
                                    value={value === '*' ? '' : value}
                                    onChange={(e) => setValue!(e.target.value)}
                                    readOnly={!isEditing}
                                    autoFocus={true}
                                    containerClassName="h-8"
                                    height="8"
                                    className={`mb-0 w-28 h-8 bg-white border border-newTeal-main text-center`}
                                    onBlur={() => value && handleSaveClick && handleSaveClick(value, value)}
                                />
                            ) : (
                                <div
                                    className="h-8 rounded focus:outline-none mb-0 px-1 mr-2 flex flex-row items-center justify-center gap-2 clause-input-min-width max-w-inherit"
                                    onClick={() => {
                                        if (
                                            ([ClauseMode.EDIT, ClauseMode.MODIFIED].includes(mode!) &&
                                                (!dealBreaker?.isDealBreaker ||
                                                    currentUserParty === DealUserParty.Landlord)) ||
                                            dealState === DealState.Agreed
                                        ) {
                                            setEditing!(true);
                                        }
                                    }}
                                >
                                    <span className="font-semibold truncate text-center">
                                        {numeric_thousands_value ?? ''}
                                    </span>
                                    {type === ClauseType.VALUE && previousValue != null && (
                                        <span className="text-newGray-darkish line-through font-light">
                                            {!isNaN(Number(previousValue))
                                                ? Number(previousValue).toLocaleString()
                                                : previousValue}
                                        </span>
                                    )}
                                </div>
                            )}
                            {valueSuffix && valueSuffix !== '£' && valueSuffix !== '$' && valueSuffix !== '€' && (
                                <span className="px-2 text-newGray-darkish text-sm border-l border-gray-600">
                                    {valueSuffix}
                                </span>
                            )}
                        </>
                    )}
                </form>
            )}
        </div>
    );
};

export default Value;
