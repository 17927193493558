import React from 'react';

import ClauseActions from './Actions';
import Badge from './Badge';
import ClauseContext from './Context';
import Icon from './Icon';
import Info from './Info';
import Value from './Value';
import { ReactComponent as Down } from '../../../../../assets/images/arrow/down/black.svg';
import { ReactComponent as Up } from '../../../../../assets/images/arrow/up/black.svg';
import { CommentType, DealDetailsFragment } from '../../../../../generated/graphql';
import useMobileScreen from '../../../../../shared/hooks/useMobileScreen';
import useMoveClause from '../../../../../shared/Negotiator/hooks/useMoveClause';
import { ClauseType } from '../Types';

type BodyProps = {
    clauseID: string;
    parentID: string;
    deal: DealDetailsFragment | null;
    groupID: string;
    isReordering?: boolean;
    index?: number;
    clauses?: number;
};
const Body = ({ clauseID, deal, parentID, groupID, isReordering, index, clauses }: BodyProps) => {
    const { type, shortForm, comments, isEditing, setEditing } = React.useContext(ClauseContext);

    const mobileScreen = useMobileScreen();

    const moveClause = useMoveClause(deal, clauseID, groupID, parentID);

    return (
        <div className={`flex flex-row items-center w-full min-h-20 ${mobileScreen && 'justify-between'}`}>
            {/* Title */}
            <div
                className={`cursor-pointer flex items-end sm:w-1/2 xl:w-full
                ${!isEditing && 'ml-0 transitionAll'}`}
                onClick={() => {
                    document.getElementById(clauseID)?.children[0]?.classList.remove('walkthroughFocused');
                    setEditing!(false);
                }}
            >
                {isReordering && clauses && index && (
                    <div className="flex flex-row items-center content-center justify-center  border-r border-newGray-main pr-5 mr-5">
                        <button
                            className={`cursor-pointer mr-4 focus:outline-none ${
                                (clauses <= 1 || index === 0) && 'text-newGray-main cursor-not-allowed'
                            }`}
                            disabled={clauses <= 1 || index === 0}
                            onClick={() => moveClause(index, index - 1)}
                        >
                            <Up className="w-6 h-6" />
                        </button>
                        <button
                            className={`cursor-pointer mr-px focus:outline-none ${
                                (clauses <= 1 || index === clauses - 1) && 'text-newGray-main cursor-not-allowed'
                            }`}
                            disabled={clauses <= 1 || index === clauses - 1}
                            onClick={() => moveClause(index, index + 1)}
                        >
                            <Down className="w-6 h-6" />
                        </button>
                    </div>
                )}
                <div className="flex flex-row gap-1 truncate">
                    <span
                        // Required due to z index bug w/ clause action buttons
                        style={{
                            zIndex: -1
                        }}
                        className={`font-medium transitionAll text-gray-800 mr-1 ${mobileScreen && 'flex'} ${
                            (type === ClauseType.COMMENTED &&
                                comments &&
                                comments.length > 0 &&
                                comments[comments.length - 1].type === CommentType.Removal) ||
                            (type === ClauseType.REMOVED && 'line-through opacity-25 z-0')
                        }
                        ${type === ClauseType.APPROVED && 'opacity-25'} truncate`}
                    >
                        {/* Mobile Icon */}
                        {mobileScreen && type !== ClauseType.DEFAULT && (
                            <div
                                className={`mt-px quickFadeIn ${
                                    type !== ClauseType.APPROVED ? 'quickFadeOut -mr-7' : 'quickFadeIn'
                                }`}
                            >
                                <Icon />
                            </div>
                        )}
                        {''}
                        <span className="truncate font-semibold md:font-medium">{shortForm}</span>
                    </span>
                    <Badge />
                </div>
            </div>

            {/* Value */}
            {!mobileScreen && <Value />}

            {/* Right Side */}
            {!mobileScreen && (
                <div className="flex items-center">
                    {/* Actions */}
                    <ClauseActions />
                    {/* Icon */}
                    <div
                        className={`mr-10 quickFadeIn ${type !== ClauseType.APPROVED ? 'quickFadeOut' : 'quickFadeIn'}`}
                    >
                        <Icon />
                    </div>
                    {/* History */}
                    <Info />
                </div>
            )}
        </div>
    );
};

export default Body;
