import React from 'react';

import Comments from './Comments';
import ClauseContext from './Context';
import { formatClause } from '../../../../../shared/helpers';
import { ClauseType } from '../Types';

const LongForm = () => {
    const { value, valueSuffix, longForm, tags, type, history } = React.useContext(ClauseContext);

    const formattedLongForm = {
        __html: formatClause(longForm, value, valueSuffix, tags ?? []) ?? ''
    };

    switch (type) {
        case ClauseType.COMMENTED:
            return (
                <>
                    {/* Long Form */}
                    <div
                        className={`bg-white px-4 text-sm py-2 border-t-2 border-white`}
                        dangerouslySetInnerHTML={formattedLongForm}
                    ></div>
                    {/* Comments */}
                    <Comments />
                </>
            );
        // @ts-ignore
        case ClauseType.CHANGED:
            if (history && history.length > 0) {
                const mostRecentEvent = history[history.length - 1];
                const previousValue = mostRecentEvent.change.old?.value ?? '';
                const previousValueSuffix = mostRecentEvent.change.old?.valueSuffix ?? '';
                const previousLongForm = mostRecentEvent.change.old?.longForm ?? '';

                const formattedPreviousLongForm = {
                    __html:
                        formatClause(previousLongForm, previousValue, previousValueSuffix, [], false, false, false) ??
                        ''
                };
                return (
                    <>
                        {/* Long Form */}
                        <div
                            className={`bg-white px-4 py-2 text-sm border-t-2 rounded-bl rounded-br border-white longFormVisible`}
                            dangerouslySetInnerHTML={formattedLongForm}
                        ></div>
                        {/* Old Clause */}
                        <div
                            className={`bg-white px-4 py-2 text-sm border-t-2 rounded-bl rounded-br border-white longFormVisible`}
                        >
                            <span className="font-medium text-xs">Old Clause</span>
                            <div dangerouslySetInnerHTML={formattedPreviousLongForm}></div>
                        </div>
                    </>
                );
            }
        // Allow fallback to default
        // @ts-ignore
        // eslint-disable-next-line
        case ClauseType.SWAPPED:
            if (history && history.length > 0) {
                const mostRecentEvent = history[history.length - 1];
                const previousValue = mostRecentEvent.change.old?.value ?? '';
                const previousValueSuffix = mostRecentEvent.change.old?.valueSuffix ?? '';
                const previousLongForm = mostRecentEvent.change.old?.longForm ?? '';

                const formattedPreviousLongForm = {
                    __html: formatClause(previousLongForm, previousValue, previousValueSuffix, []) ?? ''
                };
                return (
                    <>
                        {/* Long Form */}
                        <div
                            className={`bg-white px-4 py-2 text-sm border-t-2 rounded-bl rounded-br border-white longFormVisible`}
                            dangerouslySetInnerHTML={formattedLongForm}
                        ></div>
                        {/* Old Clause */}
                        <div
                            className={`bg-white px-4 py-2 text-sm border-t-2 rounded-bl rounded-br border-white longFormVisible`}
                        >
                            <span className="text-xs font-medium">Old Clause</span>
                            <div dangerouslySetInnerHTML={formattedPreviousLongForm}></div>
                        </div>
                    </>
                );
            }
        // Allow fallback to default
        // eslint-disable-next-line
        default:
            return (
                <>
                    {/* Long Form */}
                    <div
                        className={`bg-navy-lightest px-4 py-2 text-sm longFormVisible border-t rounded-bl`}
                        dangerouslySetInnerHTML={formattedLongForm}
                    ></div>
                </>
            );
    }
};

export default LongForm;
