import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';

import Clause from './Clause';
import { SearchClauseBankQuery, useSearchClauseBankLazyQuery } from '../../../generated/graphql';
import { debounce, handleFetchError } from '../../helpers';
import Input, { InputType } from '../../Inputs';
import Loading from '../../Loading';
import Window from '../../Window';
import { LinkedClause } from '../NewClauseWindow';

type LinkClauseWindowProps = {
    organisationID: string;
    clauseID?: string;
    section: string;
    group: string;
    clauseGroup?: string | null;
    refetch: () => void;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
    linkedClauses: LinkedClause[];
    addLinkedClauses: (clause: LinkedClause) => void;
    removeLinkedClauses: (clause: LinkedClause) => void;
};

export type LinkClauseItem = {
    value: string;
    valueSuffix: string;
    shortForm: string;
    longForm: string;
};

const LinkClauseWindow = ({
    organisationID,
    clauseID,
    section,
    group,
    clauseGroup,
    linkedClauses,
    addLinkedClauses,
    removeLinkedClauses,
    refetch,
    ...props
}: LinkClauseWindowProps) => {
    const [searchClauses, { data: clauseData, loading: clauseLoading, error: clauseError }] =
        useSearchClauseBankLazyQuery();
    const [clauses, setClauses] = useState<SearchClauseBankQuery | undefined>();

    useEffect(() => {
        // Check for errors
        handleFetchError('Could not load your clauses', clauseError, clauseData?.clauseBank);
        clauseData &&
            setClauses({
                ...clauseData,
                clauseBank: clauseData.clauseBank?.filter(
                    (clause) =>
                        clause.section.title === section &&
                        clause.section.group.title === group &&
                        (clauseGroup ? clause.section.group.group?.title === clauseGroup : true)
                )
            });
    }, [clauseData, clauseError, section, group, clauseGroup]);

    const handleChange = debounce((value?: string) => {
        searchClauses({
            variables: {
                organisationID: !isEmpty(organisationID) ? [organisationID] : [],
                only: true,
                query: value
            }
        });
    }, 250);

    const handleAddLinkedClauseClick = async (
        _id: string,
        value?: string,
        valueSuffix?: string,
        shortForm?: string,
        longForm?: string
    ) => {
        const filteredLinkedClauses = linkedClauses?.filter((linkedClause) => linkedClause._id !== _id);
        // Clause is not yet linked, add it
        if ((filteredLinkedClauses?.length ?? 0) >= (linkedClauses?.length ?? 1))
            addLinkedClauses({ _id, value, valueSuffix, shortForm, longForm });
        else
            removeLinkedClauses({
                _id,
                value,
                valueSuffix,
                shortForm,
                longForm
            });

        refetch();
        props.setShow(false);
    };

    return (
        <Window title="Link Clause" width="w-2/5" scrollContent={true} {...props}>
            <div className="h-full md:h-96 bg-navy-lightest">
                {/* Search Input */}
                <form
                    className="flex flex-col bg-white border-navy-light border-b p-2"
                    onSubmit={(event) => {
                        event.preventDefault();
                    }}
                >
                    <div className="flex flex-row w-full items-center">
                        <Input
                            type={InputType.text}
                            placeholder="Search Clauses"
                            className="w-full"
                            autoFocus
                            onChange={(e) => handleChange(e.target.value)}
                        />
                    </div>
                    <div className="pt-3 pb-1">
                        <span className="bg-navy-light rounded py-2 px-4 mr-2 text-black text-sm text-center">
                            {section}
                        </span>
                        <span className="bg-navy-light rounded py-2 px-4 mr-2 text-black text-sm text-center">
                            {group}
                        </span>
                        {clauseGroup && (
                            <span className="bg-navy-light rounded py-2 px-4 text-black text-sm text-center">
                                {clauseGroup}
                            </span>
                        )}
                    </div>
                </form>

                {/* Search in progress */}
                {clauseLoading ? (
                    <Loading />
                ) : (
                    /* Search Results */
                    <div className="w-full">
                        {clauses?.clauseBank ? (
                            <>
                                {clauses.clauseBank?.map(
                                    (clause) =>
                                        clause &&
                                        clause._id !== clauseID && (
                                            <Clause
                                                id={clause._id}
                                                key={clause._id}
                                                linked={
                                                    !!linkedClauses?.find(
                                                        (linkedClause) => linkedClause._id === clause._id
                                                    )
                                                }
                                                value={clause.value ?? ''}
                                                valueSuffix={clause.valueSuffix ?? ''}
                                                shortForm={clause.shortForm ?? ''}
                                                longForm={clause.longForm ?? ''}
                                                tags={clause.tags ?? []}
                                                onClick={handleAddLinkedClauseClick}
                                            />
                                        )
                                )}
                            </>
                        ) : (
                            <p className="text-sm text-center w-full mt-2">Search to see clauses</p>
                        )}
                    </div>
                )}
            </div>
        </Window>
    );
};

export default LinkClauseWindow;
