export type Link = {
    title: any;
    type: 'main' | 'secondary';
    href: string;
    badge?: number;
    onHover?: () => any;
};

const useGetNavbarLinks = (
    userType: string,
    notificationCount: number,
    fetchNotifications: () => void,
    pluralDeal: () => 'My Deals' | 'My Deal' | undefined
): Link[] => {
    return userType !== 'TENANT'
        ? [
              {
                  title: 'Dashboard',
                  type: 'main',
                  href: '/',
                  badge: notificationCount ? notificationCount : undefined,
                  onHover: () => fetchNotifications()
              },
              {
                  title: 'Notifications',
                  type: 'main',
                  href: '/notifications'
              },
              {
                  title: 'Guidance Videos',
                  type: 'main',
                  href: '/guidance-videos'
              },
              { title: 'My Deals', type: 'main', href: '/deals' },
              { title: 'My Spaces', type: 'main', href: '/units' },

              { title: 'Templates', type: 'secondary', href: '/templates' },
              { title: 'Settings', type: 'secondary', href: '/settings' }
          ]
        : [
              {
                  title: pluralDeal() || 'My Deal',
                  type: 'main',
                  href: '/deals'
              },
              {
                  title: 'Notifications',
                  type: 'main',
                  badge: notificationCount ? notificationCount : undefined,
                  href: '/notifications'
              },
              {
                  title: 'Guidance Videos',
                  type: 'main',
                  href: '/guidance-videos'
              },
              { title: 'Settings', type: 'secondary', href: '/settings' }
          ];
};

export default useGetNavbarLinks;
