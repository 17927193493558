import React, { useContext } from 'react';

import { WindowContext } from './WindowContext';
import { ReactComponent as Cross } from '../../assets/images/cross/black.svg';
import { ReactComponent as External } from '../../assets/images/external/black.svg';
import { MainContext } from '../../layout/main.provider';
import Emoji from '../Emoji';
import useMobileScreen from '../hooks/useMobileScreen';

const WindowHeader = () => {
    const {
        setShow,
        header,
        title,
        emoji,
        titleCenter,
        onCloseClick,
        allowExternal,
        setExternalWindow,
        disableClose,
        underlined
    } = useContext(WindowContext);

    const { setWindowOpen } = useContext(MainContext);

    const mobileScreen = useMobileScreen();

    if (header) return <>{header}</>;

    return (
        <div
            className={`w-full border-b md:relative fixed top-0 left-0 right-0 bg-white flex flex-row items-center justify-between rounded-t px-4 py-4 z-10`}
        >
            <h2
                className={`flex items-center justify-start text-black font-semibold text-xl ${
                    titleCenter ? 'flex-1 justify-center text-center' : ''
                }`}
            >
                {allowExternal && !mobileScreen && (
                    <button className="mr-2" onClick={() => setExternalWindow && setExternalWindow(true)}>
                        <External className="w-5 h-5" />
                    </button>
                )}
                <span className={`${underlined ? 'border-b-3 border-newTeal-main' : ''}`}>{title}</span>

                {emoji && <Emoji symbol={emoji} />}
            </h2>
            {disableClose ? (
                <></>
            ) : (
                <Cross
                    data-testid="window--close"
                    className="cursor-pointer w-5"
                    onClick={
                        !onCloseClick
                            ? () => {
                                  setShow(false);
                                  setWindowOpen(false);
                              }
                            : onCloseClick
                    }
                />
            )}
        </div>
    );
};

export default WindowHeader;
