import React from 'react';
import Swal from 'sweetalert2';

import {
    ClauseTemplate,
    useClauseDeleteAdminMutation,
    useClauseDeleteOrganisationMutation
} from '../../../generated/graphql';
import Window from '../../../shared/Window';

type ConfirmClauseDeleteProps = {
    clauseID: string;
    organisationID?: string;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
    setShowEditWindow: React.Dispatch<React.SetStateAction<boolean>>;
};

const ConfirmClauseDelete = ({ clauseID, organisationID, setShow, setShowEditWindow }: ConfirmClauseDeleteProps) => {
    const [deleteClause] = useClauseDeleteAdminMutation();

    const [deleteOrgClause] = useClauseDeleteOrganisationMutation();

    const handleDeleteOrg = async () => {
        if (!clauseID || !organisationID) return;
        await deleteOrgClause({
            variables: {
                _id: clauseID,
                organisationID: organisationID
            },
            updateQueries: {
                searchClauseBank: ({ clauseBank }, { mutationResult }) => {
                    return {
                        clauseBank: clauseBank.filter(
                            (clause: ClauseTemplate) => clause._id !== mutationResult.data?.organisationClauseDelete
                        )
                    };
                }
            },
            onCompleted: () => {
                Swal.fire({
                    title: 'Success!',
                    text: 'Clause deleted.',
                    icon: 'success',
                    iconColor: '#3CA48E',
                    confirmButtonText: 'Continue',
                    confirmButtonColor: '#3CA48E'
                });
            }
        });
    };

    const handleDeleteAdmin = async () => {
        await deleteClause({
            variables: {
                _id: clauseID
            },
            updateQueries: {
                searchClauseBank: ({ clauseBank }, { mutationResult }) => {
                    return {
                        clauseBank: clauseBank.filter(
                            (clause: ClauseTemplate) => clause._id !== mutationResult.data?.adminClauseDelete
                        )
                    };
                }
            },
            onCompleted: () => {
                Swal.fire({
                    title: 'Success!',
                    text: 'Clause deleted.',
                    icon: 'success',
                    iconColor: '#3CA48E',
                    confirmButtonText: 'Continue',
                    confirmButtonColor: '#3CA48E'
                });
            }
        });
    };

    const handleDeleteClick = async () => {
        if (organisationID) {
            await handleDeleteOrg();
            setShowEditWindow(false);
        } else {
            await handleDeleteAdmin();
            setShowEditWindow(false);
        }
        setShow(false);
    };

    return (
        <Window
            title="Remove Clause"
            titleCenter
            setShow={setShow}
            primaryBtn
            onPrimaryBtnClick={handleDeleteClick}
            secondaryBtn
            onSecondaryBtnClick={() => setShow(false)}
        >
            <div className="flex flex-col items-center">
                <h2 className="text-center text-lg my-8 w-4/5">
                    Are you sure you want to <span className="font-semibold text-uppercase">delete</span> this clause?
                    This process is irreversible.
                </h2>
            </div>
        </Window>
    );
};

export default ConfirmClauseDelete;
