import React, { useContext, useEffect, useRef, useState } from 'react';
import { NavLink, useLocation, useParams } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';

import { ReactComponent as Bell } from '../../../../../src/assets/images/bell/black.svg';
import { ReactComponent as Help } from '../../../../../src/assets/images/help/black.svg';
import Logo from '../../../../../src/assets/images/newLogo/pink.png';
import { ReactComponent as Settings } from '../../../../../src/assets/images/settings/black.svg';
import {
    Deal,
    GetLayoutUserInfoQuery,
    useGetDealCardDetailsQuery,
    useNotificationsQuery
} from '../../../../generated/graphql';
import { MainContext } from '../../../../layout/main.provider';
import NavContainer from '../../../../layout/SideBar/components/NavContainer';
import Profile from '../../../../layout/SideBar/components/Profile';
import { useDisclosure } from '../../../../shared/hooks/useDisclosure';
import useMobileScreen from '../../../../shared/hooks/useMobileScreen';
import HelpDropdown from '../../../components/HelpDropdown';
import NotificationsPopup from '../../../components/NotificationsPopup';
import { Readiness } from '../../../components/Tenant/Readiness';
import { pageMatch, PAGES } from '../../helpers';
import Tab from '../../Tab/Tab';

export const DEMISE_MAX_LENGTH = 22;

type TenantNavbarProps = {
    data?: GetLayoutUserInfoQuery;
};

const TenantNavbar = ({ data }: TenantNavbarProps) => {
    const { windowOpen } = useContext(MainContext);
    const mobileScreen = useMobileScreen();

    const { data: dealsData, loading: dealsLoading } = useGetDealCardDetailsQuery({
        fetchPolicy: 'cache-and-network'
    });

    const { data: notificationsData, loading: notificationsLoading } = useNotificationsQuery({
        variables: {
            page: 1,
            compact: false
        },
        fetchPolicy: 'network-only'
    });
    const dealNameRef = useRef<HTMLDivElement>(null);

    let location = useLocation();
    const { id } = useParams();
    const [currentDeal, setCurrentDeal] = useState<Deal>();
    const { isOpen: isHelpOpen, close: closeHelp, toggle: toggleHelp, ref: helpRef } = useDisclosure();
    const {
        isOpen: isNotificationsOpen,
        close: closeNotifications,
        toggle: toggleNotifications,
        ref: notificationsRef
    } = useDisclosure();

    useEffect(() => {
        const findCurrentDeal = dealsData?.deals.results.find((deal) => deal._id === id);

        setCurrentDeal(findCurrentDeal as Deal);
    }, [dealsData]);

    const hasUnseenNotifications = !notificationsData?.notifications.notifications.every(
        (notification) => notification.read
    );

    const currentPage = location.pathname;

    const showAdditionalTab = pageMatch(currentPage, [PAGES.NOTIFICATIONS, PAGES.SETTINGS, PAGES.GUIDANCE_VIDEOS]);

    const heightProps = 'h-10';
    const navLinkProps = `md:flex flex-row justify-center font-semibold w-44 pt-2 md:text-base xxl:text-lg bg-newTeal-lightest
        content-between relative items-center overflow-hidden whitespace-no-wrap hover:bg-navy-light`;

    return (
        <>
            <div
                className={`w-full md:flex items-center fixed justify-between flex-row ${heightProps} top-0 sticky  ${
                    windowOpen && mobileScreen ? 'z-0' : 'z-60'
                } bg-white`}
            >
                <div className={`grid grid-cols-7 flex w-full flex-row justify-start h-full`}>
                    {/* Logo */}
                    <div
                        className={`col-span-1 hidden px-5 md:flex flex-row  justify-between bg-navy-main
                        content-between  z-60 sticky items-center left-0 border-b-2 border-newTeal-main`}
                    >
                        <img src={Logo} alt="Logo" className="h-7" />
                    </div>

                    <div className="flex flex-row h-10 col-span-6 border-b-2 border-newTeal-main">
                        <div className="flex flex-row flex-grow">
                            {dealsData?.deals.results.length === 1 && pageMatch(currentPage, [PAGES.DEAL]) ? (
                                <div className={`cursor-pointer ${navLinkProps} h-10 border-b-2 border-newTeal-main`}>
                                    <p className="font-semibold">My Deal</p>
                                </div>
                            ) : (
                                <NavLink
                                    to={`/deals`}
                                    exact={true}
                                    className={`cursor-pointer ${navLinkProps}  h-10 border-b-2 border-newTeal-main`}
                                    activeClassName="nav-active-bg hover-nav-active-bg text-white"
                                >
                                    <p className="font-semibold">My Deals</p>
                                </NavLink>
                            )}

                            {pageMatch(currentPage, [PAGES.DEAL]) && (!dealsLoading || (dealsLoading && dealsData)) && (
                                <div
                                    ref={dealNameRef}
                                    style={{
                                        maxWidth: '17rem',
                                        overflow: 'hidden'
                                    }}
                                    className={`hidden md:flex flex-row justify-between  font-semibold pt-2  md:text-base xxl:text-lg pb-0.5 pr-10 pl-14 text-white bg-newTeal-main ${heightProps} content-between items-center -ml-5`}
                                    data-border-color={'white'}
                                    data-tip={`${currentDeal?.unit?.name} - ${currentDeal?.unit?.buildingName}`}
                                >
                                    <div
                                        className="font-semibold flex flex-row"
                                        style={{
                                            maxWidth: '17rem',
                                            overflow: 'hidden'
                                        }}
                                    >
                                        <p className="truncate">
                                            {currentDeal?.unit?.name} - {currentDeal?.unit?.buildingName}{' '}
                                        </p>
                                    </div>
                                </div>
                            )}
                            {showAdditionalTab && (
                                <div className={`${navLinkProps} nav-active-bg hover-nav-active-bg text-white`}>
                                    <div className="font-semibold flex flex-row ">
                                        {pageMatch(currentPage, [PAGES.NOTIFICATIONS]) ? (
                                            <Tab wording={'Notifications'} />
                                        ) : pageMatch(currentPage, [PAGES.SETTINGS]) ? (
                                            <Tab wording={'Settings'} />
                                        ) : (
                                            <Tab wording={'Guidance Videos'} />
                                        )}
                                    </div>
                                </div>
                            )}
                            {(currentDeal?.unit?.name?.length ?? 0) + (currentDeal?.unit?.buildingName.length ?? 0) >
                                DEMISE_MAX_LENGTH && (
                                <ReactTooltip
                                    backgroundColor="black"
                                    textColor="white"
                                    effect="solid"
                                    place="bottom"
                                    clickable={false}
                                    borderColor="white"
                                />
                            )}
                        </div>
                        <div className="flex md:col-span-1 xl:col-span-2 justify-end md:pr-8 xl:pr-28 pl-10 flex-row items-center">
                            {data?.user.organisation.avatar ? (
                                <img
                                    src={data.user.organisation.avatar}
                                    alt={data.user.organisation.name}
                                    className="w-8 h-8 flex mr-1 rounded object-cover"
                                />
                            ) : (
                                <Profile
                                    headerClass={'flex'}
                                    className="w-2 h-4 -ml-10 "
                                    size="35px"
                                    fontSize="17px"
                                    name={
                                        data?.user.__typename === 'User' && data.user?.organisation.name
                                            ? data.user.organisation.name
                                            : ''
                                    }
                                    src={data?.user.organisation.avatar}
                                />
                            )}
                            <div className="flex-col md:hidden lg:flex mx-3">
                                <p className="font-semibold whitespace-no-wrap" style={{ fontSize: '13px' }}>
                                    {data?.user.firstName + ' ' + data?.user.lastName}
                                </p>
                                <p className="flex flex-row truncate" style={{ fontSize: '13px' }}>
                                    {data?.user.organisation.name}
                                </p>
                            </div>
                            <NavContainer flexRow backgroundColor="white" className="cursor-pointer">
                                <div
                                    className={`md:ml-2 xl:ml-13 hover:text-white
                                ${
                                    isNotificationsOpen || pageMatch(currentPage, [PAGES.NOTIFICATIONS])
                                        ? 'bg-newTeal-main text-white'
                                        : ''
                                        ? 'bg-newTeal-main'
                                        : 'hover:bg-newTeal-main '
                                } cursor-pointer ${heightProps} w-7 flex justify-center items-center`}
                                    onClick={toggleNotifications}
                                >
                                    <Bell className="w-5 h-5 " />
                                    {hasUnseenNotifications && !notificationsLoading && (
                                        <div className="bg-red-400 rounded-full w-2 h-2 -ml-2 -mt-4" />
                                    )}
                                </div>
                                <NavLink
                                    to={`/settings`}
                                    className={`ml-4 hover:bg-newTeal-main ${heightProps} w-7 flex justify-center items-center hover:text-white`}
                                    activeClassName={`ml-4 bg-newTeal-main text-white ${heightProps} w-7 flex justify-center items-center`}
                                >
                                    <Settings className="w-5 h-5 " />
                                </NavLink>
                                <div
                                    className={`ml-4 ${
                                        isHelpOpen || pageMatch(currentPage, [PAGES.GUIDANCE_VIDEOS])
                                            ? 'bg-newTeal-main text-white'
                                            : ''
                                    } hover:bg-newTeal-main ${heightProps} hover:text-white w-7 flex cursor-pointer justify-center items-center`}
                                    onClick={toggleHelp}
                                >
                                    <Help className="w-5 h-5" />
                                </div>
                            </NavContainer>
                        </div>
                    </div>
                </div>
                <Readiness />
            </div>
            {isHelpOpen && <HelpDropdown close={closeHelp} ref={helpRef} />}
            {isNotificationsOpen && notificationsData && (
                <NotificationsPopup data={notificationsData} close={closeNotifications} ref={notificationsRef} />
            )}
        </>
    );
};

export default TenantNavbar;
