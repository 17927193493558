import React, { ButtonHTMLAttributes, FC, useMemo } from 'react';

export enum ButtonColor {
    primary,
    secondary
}

export enum ButtonSize {
    small,
    medium,
    large
}

export interface ButtonProps extends Omit<ButtonHTMLAttributes<HTMLButtonElement>, 'color'> {
    color?: ButtonColor;
    className?: string;
    disabled?: boolean;
    leftComponent?: React.ReactNode;
    leftComponentClassname?: string;
    size?: ButtonSize;
    text?: React.ReactNode;
    textClassname?: string;
}

export const Button: FC<ButtonProps> = ({
    color = ButtonColor.secondary,
    className = '',
    disabled = false,
    leftComponent,
    leftComponentClassname,
    size = ButtonSize.medium,
    text,
    textClassname,
    ...buttonProps
}) => {
    const buttonColorStyles = useMemo(() => {
        switch (color) {
            case ButtonColor.primary:
                return 'bg-newTeal-light hover:bg-newTeal-main hover:text-white';
            case ButtonColor.secondary:
                return 'bg-navy-lightest hover:bg-navy-light';
            default:
                return '';
        }
    }, [color]);

    const buttonSizeStyles = useMemo(() => {
        switch (size) {
            case ButtonSize.small:
                return '';
            case ButtonSize.medium:
                return '';
            default:
                return 'h-11 rounded-lg';
        }
    }, [size]);

    return (
        <button
            className={`${buttonColorStyles} ${buttonSizeStyles} flex focus:outline-none items-center justify-center ${className}`}
            disabled={disabled}
            {...buttonProps}
        >
            <span className={leftComponentClassname}>{leftComponent}</span>
            <span className={textClassname}>{text}</span>
        </button>
    );
};
