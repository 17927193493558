import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';

import { DealCardContext } from '..';
import { ReactComponent as Chevron } from '../../../../assets/images/chevron/right/black.svg';
import { AgreedBy, DealState, DealUserParty } from '../../../../generated/graphql';
import { BulkSelectContext } from '../../../../shared/bulk/context';
import { DealCardImportant } from '../../../../shared/helpers';
import NudgeButton from '../../NudgeButton';

type DealCardDaysButtonProps = {
    mostImportant: DealCardImportant;
    dealURL: string;
    days: number;
};

const DealCardDaysButton = ({ mostImportant, dealURL, days }: DealCardDaysButtonProps) => {
    const { selectMode } = useContext(BulkSelectContext);
    const { currentUserParty, compact, round, state, oldestApproval, id, agreedBy } = useContext(DealCardContext);

    const history = useHistory();

    const getBorderColor = (days: number) => {
        if (currentUserParty === DealUserParty.Tenant) {
            return days <= 4 ? 'border-transparent' : days >= 5 && days <= 8 ? 'bg-yellow-light' : 'bg-newRed-light';
        }
        if (currentUserParty === DealUserParty.Landlord) {
            return days <= 3 ? 'border-transparent' : days >= 4 && days <= 6 ? 'bg-yellow-light' : 'bg-newRed-light';
        }
    };

    return (
        <div
            className={`flex-1 w-1/2 flex items-center justify-between bg-navy-lightest rounded-lg quickTransitionAll hover:shadow-md border ${
                !compact && mostImportant === DealCardImportant.NEGOTIATIONS && 'bg-navy-lightest'
            } ${getBorderColor(days)} px-1 py-2`}
        >
            <div className="ml-3 w-full">
                <div className="flex flex-row items-center justify-between w-full">
                    <div
                        onClick={() => {
                            !selectMode && history.push(dealURL);
                        }}
                    >
                        {' '}
                        <p className="font-light sm:text-xxs xl:text-xs">Negotiations</p>
                        <p className="text-newGray-dark text-xs">
                            {oldestApproval ? (
                                <span>Waiting Approval</span>
                            ) : (
                                <>
                                    <span className="sm:text-base xl:text-xl font-bold">{days}</span> Day
                                    {days !== 1 && 's'} with{' '}
                                    <span className="font-bold">
                                        {(state === DealState.Landlord &&
                                            currentUserParty === DealUserParty.Landlord) ||
                                        (state === DealState.Tenant && currentUserParty === DealUserParty.Tenant) ||
                                        (agreedBy === AgreedBy.Landlord && currentUserParty === DealUserParty.Tenant) ||
                                        (agreedBy === AgreedBy.Tenant && currentUserParty === DealUserParty.Landlord)
                                            ? 'You'
                                            : 'Them'}
                                    </span>
                                </>
                            )}
                        </p>
                    </div>
                    {compact && (
                        <div
                            onClick={(e) => {
                                e.stopPropagation();
                            }}
                        >
                            <NudgeButton dealID={id} currentUserParty={currentUserParty} state={state} round={round} />
                        </div>
                    )}
                </div>
            </div>
            {!compact && (
                <Chevron
                    className="sm:h-4 sm:w-4 xl:w-6 xl:h-6 sm:mr-0 xl:mr-2 text-newGray-darkish"
                    onClick={() => !selectMode && history.push(dealURL)}
                />
            )}
        </div>
    );
};

export default DealCardDaysButton;
