import React, { useState } from 'react';
import stc from 'string-to-color';

import ClauseBankEditWindow from './ClauseBankEditWindow';
import AdminClauseSettings from './ClauseSettings';
import { ReactComponent as ChevronDown } from '../../../assets/images/chevron/down/teal.svg';
import { SingleLinkedTemplateClauseFragment, UnitUse } from '../../../generated/graphql';
import { capitaliseAllWords, formatClause, underscoredToSpace } from '../../../shared/helpers';
import Input, { InputType } from '../../../shared/Inputs';

type ClauseProps = {
    _id: string;
    orgId?: string;
    value?: string | null;
    valueSuffix?: string | null;
    shortForm?: string | null;
    longForm?: string | null;
    tags?: string[] | null;
    section?: string | null;
    level1Group?: string;
    level2Group?: string;
    linkedClauses?: SingleLinkedTemplateClauseFragment[];
    dropdowns?: {
        title?: string | null;
        groups: {
            title?: string | null;
            groups: {
                title?: string | null;
            }[];
        }[];
    }[];
    uses?: UnitUse[] | undefined;
    updated?: Date | null;
    updatedDealsAt?: Date | null;
    updatedTemplatesAt?: Date | null;
};

export type AddClauseItem = {
    value: string;
    valueSuffix: string;
    shortForm: string;
    longForm: string;
};

const Clause = ({
    orgId,
    _id,
    value,
    valueSuffix,
    shortForm,
    longForm,
    tags,
    linkedClauses,
    section,
    level1Group,
    level2Group,
    dropdowns,
    uses,
    updated,
    updatedDealsAt,
    updatedTemplatesAt
}: ClauseProps) => {
    const [showEditWindow, setShowEditWindow] = useState<boolean>(false);
    const [showLongForm, setShowLongForm] = useState<boolean>(false);

    const formattedLongClause = {
        __html: formatClause(longForm ?? '', value, valueSuffix, tags ?? []) ?? ''
    };

    return (
        <>
            {showEditWindow && (
                <ClauseBankEditWindow
                    organisationID={orgId}
                    _id={_id}
                    value={value}
                    valueSuffix={valueSuffix}
                    shortForm={shortForm}
                    longForm={longForm}
                    tags={tags}
                    linkedClauses={linkedClauses}
                    section={section}
                    level1Group={level1Group}
                    level2Group={level2Group}
                    dropdowns={dropdowns}
                    setShow={setShowEditWindow}
                    uses={uses}
                    updated={updated}
                    updatedDealsAt={updatedDealsAt}
                    updatedTemplatesAt={updatedTemplatesAt}
                />
            )}

            <div className="w-full bg-white rounded -my-px mb-2 mt-3">
                <>
                    <div className="w-full bg-navy-lightest border-b-2 border-white rounded-tr rounded-tl p-4 py-2 flex flex-row justify-between items-center relative">
                        {/* Title */}
                        <div
                            className="cursor-pointer flex items-end w-1/3 py-4"
                            onClick={() => setShowLongForm(!showLongForm)}
                        >
                            <span className="font-medium">{shortForm}</span>
                            <ChevronDown
                                className={`w-6 h-6 ml-4 transition duration-100 transform ${
                                    showLongForm && 'rotate-180'
                                }`}
                            />
                        </div>
                        {/* Value */}
                        <div className="flex justify-between items-center w-2/3">
                            {value !== undefined && (
                                <div className="flex flex-row justify-center items-center">
                                    {valueSuffix &&
                                        (valueSuffix === '£' || valueSuffix === '$' || valueSuffix === '€') && (
                                            <span className="ml-4 mr-4">{valueSuffix}</span>
                                        )}
                                    {value && value.trim().length > 0 && (
                                        <Input
                                            type={InputType.text}
                                            defaultValue={value ? (value === '*' ? '' : value) : ''}
                                            readOnly={true}
                                            className="mb-0 w-32 border-none text-center mr-2 font-semibold bg-navy-lightest"
                                            key={value}
                                        />
                                    )}
                                    {valueSuffix &&
                                        valueSuffix !== '£' &&
                                        valueSuffix !== '$' &&
                                        valueSuffix !== '€' && (
                                            <span className="mrx2 text-newGray-darkish text-sm">{valueSuffix}</span>
                                        )}
                                </div>
                            )}
                            {uses && uses.length > 0 && (
                                <div className="sm:flex sm:flex-row md:justify-end justify-center w-full flex-wrap hidden">
                                    {uses?.map((use, index) => (
                                        <span
                                            key={index}
                                            className={`rounded-full py-1 px-2 cursor-default text-white text-xxs xl:text-xs transition-all duration-150 mb-1 ${
                                                index !== uses.length - 1 ? ' mr-1' : ''
                                            }`}
                                            style={{
                                                backgroundColor: stc(use),
                                                whiteSpace: 'nowrap',
                                                borderRadius: 12
                                            }}
                                        >
                                            {capitaliseAllWords(underscoredToSpace(use).toLowerCase())}
                                        </span>
                                    ))}
                                </div>
                            )}
                            <div className="h-10 bg-gray-450 ml-3 md:block hidden" style={{ width: '2px' }}></div>
                            <AdminClauseSettings setShowEditWindow={setShowEditWindow} />
                        </div>
                    </div>
                    {showLongForm && (
                        <div className="bg-navy-lightest px-6 py-2 rounded-bl rounded-br flex flex-col gap-1">
                            <div className="text-sm" dangerouslySetInnerHTML={formattedLongClause}></div>
                            <div className="flex items-center justify-start gap-2 overflow-x-auto">
                                {tags?.map((tag, index) => (
                                    <span
                                        key={index}
                                        className="rounded-full py-1 px-2 cursor-default text-white text-xs transition-all duration-150"
                                        style={{
                                            backgroundColor: stc(tag)
                                        }}
                                    >
                                        {tag}
                                    </span>
                                ))}
                            </div>
                        </div>
                    )}
                </>
            </div>
        </>
    );
};

export default Clause;
