import { format } from 'date-fns';
import React from 'react';

import AddedEvent from './AddedEvent';
import ApprovedEvent from './ApprovedEvent';
import ChangedEvent from './ChangedEvent';
import CommentedEvent from './CommentedEvent';
import CreatedEvent from './CreatedEvent';
import RemovedEvent from './RemovedEvent';
import SwappedEvent from './SwappedEvent';
import { DealUserParty, HistoryEventType } from '../../../../../generated/graphql';
import Window from '../../../../../shared/Window';
import { History } from '../Types';

type ClauseHistoryWindowProps = {
    history: History;
    currentUserParty?: DealUserParty | null;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
};

export const ClauseHistoryWindow = ({ history, currentUserParty, setShow }: ClauseHistoryWindowProps) => {
    return (
        <Window title="History" titleCenter width="w-3/5" setShow={setShow}>
            <div
                className="flex flex-col items-center justify-center content-center gap-2 px-4 py-2 max-h-9/12 overflow-y-auto"
                style={{ maxHeight: '80vh' }}
            >
                {history
                    ?.map((event) => (
                        <div
                            className={`flex flex-col justify-center content-center w-full py-2 ${
                                event.party === currentUserParty ? 'items-end text-right' : 'items-start text-left'
                            }`}
                        >
                            <span className="text-xs text-newGray-darkish text-center w-full">{`${
                                event.actor.firstName
                            } ${event.actor.lastName} - ${format(new Date(event.date), 'dd/MM/yyyy')}`}</span>
                            {event.type === HistoryEventType.Create && <CreatedEvent event={event} />}
                            {event.type === HistoryEventType.New && <AddedEvent event={event} />}
                            {event.type === HistoryEventType.Comment && <CommentedEvent event={event} />}
                            {event.type === HistoryEventType.Update && <ChangedEvent event={event} />}
                            {event.type === HistoryEventType.Swap && <SwappedEvent event={event} />}
                            {event.type === HistoryEventType.Approve && <ApprovedEvent event={event} />}
                            {event.type === HistoryEventType.Delete && <RemovedEvent event={event} />}
                        </div>
                    ))
                    .reverse()}
            </div>
        </Window>
    );
};
