import { Field, Form, Formik, useFormikContext } from 'formik';
import { isEmpty } from 'lodash';
import React from 'react';
import * as Yup from 'yup';

import { useCreateUserMutation, UserType } from '../../../generated/graphql';
import { InputField } from '../../../shared/Formik/InputField';
import { InputType } from '../../../shared/Inputs';
import Select from '../../../shared/Inputs/Select';
import Loading from '../../../shared/Loading';
import Window from '../../../shared/Window';

type CreateUserProps = {
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
    onSuccess: React.Dispatch<React.SetStateAction<boolean>>;
    setFieldValue: any;
};

export type CreateUserValues = {
    firstName: string;
    type?: UserType;
    lastName: string;
    company: string;
    email: string;
    password: string;
    confirmPassword: string;
};

const NewUserForm = ({ setShow, onSuccess, setFieldValue }: CreateUserProps) => {
    const { values, submitForm, validateForm } = useFormikContext<CreateUserValues>();
    const [createUser, { loading }] = useCreateUserMutation();

    const handleInviteSubmit = async () => {
        submitForm();

        const validationErrors = await validateForm();
        if (isEmpty(validationErrors)) {
            await createUser({
                variables: {
                    ...values,
                    type: values.type!,
                    login: false
                }
            });
        }

        if (!loading && isEmpty(validationErrors)) {
            setShow(false);
            onSuccess(true);
        }
        if (loading) {
            return (
                <Window title="Create User" width="w-2/5" setShow={setShow}>
                    <Loading />
                </Window>
            );
        }
    };

    const userTypeOptions = [
        {
            id: UserType.Landlord,
            text: 'Landlord'
        },
        {
            id: UserType.Agent,
            text: 'Agent'
        },
        {
            id: UserType.Tenant,
            text: 'Tenant'
        }
    ];

    return (
        <Window
            title="Create User"
            width="w-1/2"
            primaryBtn
            onPrimaryBtnClick={() => handleInviteSubmit()}
            primaryBtnText={'Done'}
            secondaryBtn
            secondaryBtnText="Cancel"
            onSecondaryBtnClick={() => setShow(false)}
            setShow={setShow}
        >
            <>
                <Form className="pt-10 md:pt-4 p-4 w-full flex flex-col gap-2 md:mb-8">
                    <div className="flex flex-wrap flex-col md:flex-no-wrap w-full gap-4 items-end">
                        <div className="w-full ">
                            <div className="w-1/2 flex flex-col">
                                <label className="text-gray-600 font-medium text-sm ">Account Type</label>
                                <Field
                                    name="type"
                                    containerClassName="mt-0 h-20"
                                    component={Select}
                                    className={`mb-2 pr-2 w-full`}
                                    data={userTypeOptions}
                                    onChange={(e) => setFieldValue('type', e.value)}
                                />
                            </div>
                        </div>
                        <div className="w-full flex  gap-4 flex-row">
                            <div className="w-1/2 flex flex-col">
                                <label className="text-gray-600 font-medium text-sm ">First Name</label>
                                <Field
                                    name="firstName"
                                    component={InputField}
                                    containerStyle={`h-20`}
                                    className={`mb-2 w-full h-12 `}
                                    placeholder="First Name"
                                    type={InputType.text}
                                />
                            </div>
                            <div className="w-1/2 flex flex-col">
                                <label className="text-gray-600 font-medium text-sm ">Last Name</label>
                                <Field
                                    name="lastName"
                                    component={InputField}
                                    containerStyle={`h-20`}
                                    className={`mb-2 w-full h-12 `}
                                    placeholder="Last Name"
                                    type={InputType.text}
                                />
                            </div>
                        </div>
                        <div className="w-full flex gap-4 flex-row">
                            <div className="w-1/2 flex flex-col">
                                <label className="text-gray-600 font-medium text-sm ">Company</label>
                                <Field
                                    name="company"
                                    component={InputField}
                                    containerStyle={`h-20`}
                                    className={`mb-2 w-full h-12 `}
                                    placeholder="Company"
                                    type={InputType.text}
                                />
                            </div>
                            <div className="w-1/2 flex flex-col">
                                <label className="text-gray-600 font-medium text-sm ">Email</label>
                                <Field
                                    name="email"
                                    component={InputField}
                                    containerStyle={`h-20`}
                                    className={`mb-2 w-full h-12 `}
                                    placeholder="Email"
                                    type={InputType.email}
                                />
                            </div>
                        </div>
                        <div className="w-full flex gap-4 flex-row">
                            <div className="w-1/2 flex flex-col">
                                <label className="text-gray-600 font-medium text-sm ">Password</label>
                                <Field
                                    name="password"
                                    component={InputField}
                                    containerStyle={`h-20`}
                                    className={`mb-2 w-full h-12 `}
                                    placeholder="Password"
                                    type={InputType.password}
                                />
                            </div>

                            <div className="w-1/2 flex flex-col">
                                <label className="text-gray-600 font-medium text-sm ">Confirm Password</label>
                                <Field
                                    name="confirmPassword"
                                    component={InputField}
                                    containerStyle={`h-20`}
                                    className={`mb-2 w-full h-12 `}
                                    placeholder="Confirm Password"
                                    type={InputType.password}
                                />
                            </div>
                        </div>
                    </div>
                </Form>
            </>
        </Window>
    );
};

const CreateUser = ({ setShow, onSuccess }: CreateUserProps) => {
    const initialValues: CreateUserValues = {
        type: undefined,
        firstName: '',
        lastName: '',
        company: '',
        email: '',
        password: '',
        confirmPassword: ''
    };

    const createUserSchema = Yup.object().shape({
        type: Yup.string().required('Please select account type'),
        firstName: Yup.string().required('Please enter an first name'),
        lastName: Yup.string().required('Please enter an last name'),
        company: Yup.string().required('Please enter an company'),
        email: Yup.string().email('Please enter a valid email').required('Please enter an email address'),
        password: Yup.string()
            .required('Password is required')
            .matches(
                /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
                'Password must be at least 8 characters and contain both a number and special character.'
            ),
        confirmPassword: Yup.string()
            .required('Please confirm password')
            .oneOf([Yup.ref('password'), null], 'Password does not match')
    });

    return (
        <Formik initialValues={initialValues} validationSchema={createUserSchema} onSubmit={() => {}}>
            {({ setFieldValue }) => (
                <NewUserForm setShow={setShow} onSuccess={onSuccess} setFieldValue={setFieldValue} />
            )}
        </Formik>
    );
};

export default CreateUser;
