import { Form, Formik, useFormikContext } from 'formik';
import { noop } from 'lodash';
import React, { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';

import Contact, { ContactSchema, ContactType, ContactValues } from './Contact';
import {
    GetDealTenantDetailsDocument,
    User,
    UserType,
    useTenantActionStageTeamMutation
} from '../../../../../generated/graphql';
import { hasErrors } from '../../../../common/helpers';
import Checkbox from '../Checkbox';
import { ReadinessContext, STAGE } from '../context';
import Stage from '../Stage';

export type TeamValues = {
    agent: ContactValues & { visible?: boolean };
    tenant: ContactValues & { visible?: boolean };
};

const getUserName = (user?: User) => (user ? `${user.firstName} ${user.lastName}` : '');

const Team = () => {
    const { details, tenant, agent } = useContext(ReadinessContext);

    const team = details?.team ?? {};

    const initialValues: TeamValues = {
        agent: {
            name: team.agent?.name ?? getUserName(agent),
            company: team.agent?.company ?? agent?.organisation.name,
            email: team.agent?.email ?? agent?.email,
            phone: team.agent?.phone ?? agent?.mobile,
            visible: team.agent?.visible ?? true
        },
        tenant: {
            name: team.tenant?.name ?? getUserName(tenant),
            company: team.tenant?.company ?? tenant?.organisation.name,
            email: team.tenant?.email ?? tenant?.email,
            phone: team.tenant?.phone ?? tenant?.mobile,
            visible: team.tenant?.visible ?? true
        }
    };

    const validationSchema = Yup.object().shape({
        agent: ContactSchema,
        tenant: ContactSchema
    });

    return (
        <Formik validationSchema={validationSchema} initialValues={initialValues} onSubmit={noop}>
            <TeamForm edited={!!details?.team?.edited} />
        </Formik>
    );
};

type TeamFormProps = {
    edited: boolean;
};

const TeamForm = ({ edited }: TeamFormProps) => {
    const { id } = useParams<{ id: string }>();
    const { isChanging, setStage, changeStage } = useContext(ReadinessContext);

    const { values, validateForm, setFieldValue, dirty } = useFormikContext<TeamValues>();

    const [actionStage] = useTenantActionStageTeamMutation();

    const saveValues = async () => {
        if (!dirty && edited) {
            changeStage();
            return;
        }

        const isValid = !hasErrors<TeamValues>(await validateForm(values));

        await actionStage({
            variables: {
                dealID: id,
                completed: isValid,
                agent: values.agent,
                tenant: values.tenant
            },
            onCompleted: changeStage,
            refetchQueries: () => [GetDealTenantDetailsDocument]
        });
    };

    useEffect(() => {
        if (isChanging) saveValues();
    }, [isChanging]);

    return (
        <Form className="bg-white pt-14 h-full w-full mx-auto flex flex-col items-start">
            <Stage
                stageNumber={3}
                title="Team"
                nextStage="Next: Stage 4 - Covenant"
                onNextClick={() => {
                    setStage(STAGE.COVENANT);
                }}
                requiredFieldsText
            >
                <p className="text-sm font-medium mt-2">Add Agents's contact details</p>
                <p className="text-xs mb-2">(or person reviewing the lease)</p>
                <Checkbox
                    name="agent.visible"
                    checked={!values.agent.visible}
                    onChange={() => setFieldValue('agent.visible', !values.agent.visible)}
                    label="Not using an Agent"
                />
                {values.agent.visible && <Contact contactType={ContactType.Agent} userType={UserType.Agent} />}
                <p className="text-sm font-medium mt-2">Add Tenant's deal contact details</p>
                <p className="text-xs mb-2">(or person reviewing the lease)</p>
                <Contact contactType={ContactType.Tenant} userType={UserType.Tenant} />
            </Stage>
        </Form>
    );
};

export default Team;
