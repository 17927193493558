import React from 'react';

import UnitCard from './UnitCard';
import { SingleUnitCardFragment, User } from '../../generated/graphql';
import Grid from '../../shared/Grid';

type UnitsListProps = {
    user?: User;
    units: SingleUnitCardFragment[];
    countsRefetch: () => void;
    refetch: () => void;
};

const UnitsList = ({ user, units, countsRefetch, refetch }: UnitsListProps) => {
    return (
        <Grid className="mt-4">
            {
                // Pull out units
                [...new Set(units)]?.map((unit) => {
                    // Don't show if invitation is still pending
                    if (unit.pending && user?.__typename === 'User' && user?.type !== 'AGENT') return null;
                    return (
                        <UnitCard
                            key={unit._id}
                            id={unit._id}
                            unit={unit}
                            name={unit.name ?? 'Untitled Demise'}
                            organisationName={unit.organisation.name}
                            pending={unit.pending}
                            buildingName={unit.buildingName}
                            address={unit.address.line1 || ''}
                            rating={unit.rating}
                            created={unit.created}
                            client={unit.organisation.name ?? null}
                            refetch={refetch}
                            isHidden={user?.hiddenUnits?.some((hiddenUnit) => hiddenUnit._id === unit._id)}
                            countsRefetch={countsRefetch}
                            hasHiddenDeals={unit.hasHiddenDeals}
                            hasConnectedDeals={unit?.deals?.length > 0}
                            available={unit?.available}
                        />
                    );
                })
            }
        </Grid>
    );
};

export default UnitsList;
