import React from 'react';

type ToolBarContextType = {
    // Current Clause ID
    cursor: string | null;
    setCursor: React.Dispatch<React.SetStateAction<string | null>>;
    // Handle Next Clause
    handleNextClauseClick: () => void;
};

export default React.createContext<ToolBarContextType>({
    // Current clause
    cursor: null,
    setCursor: () => {},
    // Move onto next clause
    handleNextClauseClick: () => {}
});
