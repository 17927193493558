import React, { useState } from 'react';

import useActionTask from './useActionTask';
import { ReactComponent as Tick } from '../../../../../assets/images/check/black.svg';
import { ReactComponent as Check } from '../../../../../assets/images/check/black.svg';
import { AdminTaskType, UnitValue } from '../../../../../generated/graphql';
import { Button } from '../../../../../shared/button';
import Input, { InputType } from '../../../../../shared/Inputs';
import Loading from '../../../../../shared/Loading';

import { TaskAction, TaskState } from '.';

type ActionProps = {
    id: string;
    taskValues: TaskState;
    type: AdminTaskType;
    unitID: string;
    value?: UnitValue | null;
    onUploadClick: (id: string) => void;
    setEditing: React.Dispatch<React.SetStateAction<boolean>>;
    setTaskValues: React.Dispatch<TaskAction>;
};

const Action = ({
    id,
    unitID,
    value: taskValue,
    setEditing,
    type,
    onUploadClick,
    value,
    taskValues,
    setTaskValues
}: ActionProps) => {
    const [showConfirm, setShowConfirm] = useState<boolean>(false);

    // Action task
    const { handleSubmit, loading } = useActionTask(id, unitID, setEditing, undefined, type);

    const handleLabel = (label: string) => {
        if (value) {
            return label;
        }
        return false;
    };

    if (loading)
        return (
            <div className="w-30">
                <Loading />
            </div>
        );

    switch (type) {
        case AdminTaskType.Boolean:
            if (taskValue?.__typename === 'BooleanValue' && taskValue.booleanValue)
                return <Tick className="w-5 h-5 stroke-current text-newTeal-main" />;
            return (
                <Button
                    onClick={() => {
                        handleSubmit(true);
                    }}
                    text="white"
                    background="newTeal-main"
                    height="12"
                    className="font-semibold w-full"
                >
                    Confirm
                </Button>
            );
        case AdminTaskType.File:
            return (
                <Button
                    onClick={() => onUploadClick(id)}
                    background="newTeal-main"
                    text="white"
                    height="12"
                    className="font-semibold w-full"
                >
                    {taskValue ? 'View Files' : 'Upload'}
                </Button>
            );
        case AdminTaskType.Integer:
            return (
                <div className={`transitionAll relative w-full ${showConfirm ? 'pr-16' : 'pr-0'}`}>
                    <Input
                        type={InputType.number}
                        value={taskValues[id]}
                        defaultValue={taskValue?.__typename === 'FloatValue' ? taskValue.floatValue : undefined}
                        onChange={(e) => {
                            setTaskValues({
                                id,
                                value: e.target.value
                            });
                        }}
                        onClick={() => setShowConfirm(true)}
                        className={`bg-navy-lightest w-full`}
                        placeholder="Enter numerical value"
                        label={handleLabel('Numerical value')}
                    />
                    <Button
                        onClick={() => {
                            const value = taskValues[id];

                            setShowConfirm(false);
                            handleSubmit(value, true);
                        }}
                        background="newTeal-main"
                        text="white"
                        height="12"
                        className={`font-semibold px-4 transitionAll absolute bottom-0 right-0 ${
                            showConfirm ? 'opacity-1' : 'opacity-0'
                        }`}
                        style={{ zIndex: showConfirm ? 20 : -1 }}
                    >
                        <Check className="w-6 h-6" />
                    </Button>
                </div>
            );
        case AdminTaskType.Text:
            return (
                <div className={`transitionAll relative w-full ${showConfirm ? 'pr-16' : 'pr-0'}`}>
                    <Input
                        type={InputType.text}
                        value={taskValues[id]}
                        defaultValue={taskValue?.__typename === 'StringValue' ? taskValue.stringValue : undefined}
                        onChange={(e) => {
                            setTaskValues({
                                id,
                                value: e.target.value
                            });
                        }}
                        onClick={() => setShowConfirm(true)}
                        className={`bg-navy-lightest w-full`}
                        placeholder="Enter value"
                        label={handleLabel('Value')}
                    />
                    <Button
                        onClick={() => {
                            const value = taskValues[id];

                            setShowConfirm(false);
                            handleSubmit(value);
                        }}
                        background="newTeal-main"
                        text="white"
                        height="12"
                        className={`font-semibold px-4 transitionAll absolute bottom-0 right-0 ${
                            showConfirm ? 'opacity-1' : 'opacity-0'
                        }`}
                        style={{ zIndex: showConfirm ? 20 : -1 }}
                    >
                        <Check className="w-6 h-6" />
                    </Button>
                </div>
            );
    }
};

export default Action;
