import React, { useContext } from 'react';

import { useUpdateClauseMutation } from '../../../../../../generated/graphql';
import useMobileScreen from '../../../../../../shared/hooks/useMobileScreen';
import ToolBarContext from '../../ToolBar/Context';

const useSaveClause = (
    id: string,
    parentID: string,
    dealID: string,
    setEditing: React.Dispatch<React.SetStateAction<boolean>>
) => {
    const mobileScreen = useMobileScreen();
    const { setCursor } = useContext(ToolBarContext);
    // Edit clause
    const [updateClause, { error: updateClauseError, loading: updateClauseLoading }] = useUpdateClauseMutation();

    return {
        handleSaveClick: async (currentValue: string, newValue: string) => {
            await updateClause({
                variables: {
                    dealID,
                    parentID,
                    clauseID: id,
                    value: currentValue === '*' && newValue === '' ? '*' : newValue
                },
                optimisticResponse: {
                    dealClauseUpdate: {
                        __typename: 'Clause',
                        _id: id,
                        value: newValue
                    } as any
                }
            });

            if (updateClauseError) {
                return;
                // handleFetchError(
                //     "Could not update the clause",
                //     updateClauseError,
                //     updateClauseData?.dealClauseUpdate
                // )
            }

            setCursor(id);
            if (mobileScreen) window.scrollBy(0, -400);
            setEditing(false);
        },
        updateClauseLoading
    };
};

export default useSaveClause;
