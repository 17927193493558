import React, { useEffect, useState } from 'react';

import Logo from '../../../../assets/images/newLogo/navy.png';
import { DealDetailsFragment, DealUnitFragment } from '../../../../generated/graphql';
import { formatClause, getLandlordContactInfo, proxyImage } from '../../../../shared/helpers';

type PreviewContentProps = {
    deal?: DealDetailsFragment;
    unit?: DealUnit;
};

export type DealUnit = DealUnitFragment;

const PreviewContent = ({ deal, unit }: PreviewContentProps) => {
    const [landlordContactDetails, setLandlordContactDetails] = useState<{
        name?: string | undefined | null;
        email?: string | undefined | null;
        phone?: string | undefined | null;
    }>({});
    const [landlordLawyerContactDetails, setLandlordLawyerContactDetails] = useState<{
        name?: string | undefined | null;
        email?: string | undefined | null;
        phone?: string | undefined | null;
        company?: string | undefined | null;
    }>({});

    useEffect(() => {
        if (unit?.tasks) {
            const contactInfo = getLandlordContactInfo(unit.tasks);
            setLandlordContactDetails(contactInfo?.landlord);
            setLandlordLawyerContactDetails(contactInfo.lawyer);
        }
    }, [unit]);

    const date = new Date(deal?.round?.date ?? Date.now()).toLocaleDateString();

    const stripHtml = (str: string) => {
        let tmp = document.createElement('DIV');
        tmp.innerHTML = str;
        return tmp.textContent || tmp.innerText || '';
    };

    return (
        <div className="flex-1 h-full-header overflow-auto bg-navy-lightest">
            <div className="bg-navy-light py-0 md:py-8 px-0 md:px-4 flex flex-col">
                {/* CONTAINER */}
                <div className="md:container relative mx-auto bg-white flex flex-col md:rounded-t pt-16 pb-12 pr-4 md:pl-16 pl-8">
                    {/* Heads of Temrs / Subject to Contract / Strictly Private */}
                    <div className="flex flex-col p-2">
                        <h1 className="font-bold text-lg">Heads of Terms</h1>
                        <p className="text-sm -mt-1">Subject to Contract</p>
                        <p className="text-sm -mt-1">Strictly Private and Confidential</p>
                    </div>

                    <hr className="border-newTeal-main mb-12 mt-4" />

                    {/* Demise / Landlord details */}
                    <div className="flex md:flex-row flex-col-reverse justify-between md:pr-16 pr-8">
                        <div className="font-semibold">
                            <p>{unit?.name}</p>
                            {unit?.buildingName && unit.buildingName !== unit.address.line1 && (
                                <p>{unit?.buildingName}</p>
                            )}
                            <p>{unit?.address.line1}</p>
                            <p>{unit?.address.line2}</p>
                            <p>{unit?.address.city}</p>
                            <p>{unit?.address.postCode}</p>
                            {unit?.url && (
                                <div className="mt-5">
                                    <p>
                                        {' '}
                                        Website:{' '}
                                        <a href={unit?.url} className="font-normal">
                                            {unit?.url}
                                        </a>
                                    </p>
                                </div>
                            )}
                            <p className="font-normal mt-8">{date}</p>
                        </div>

                        <div className="flex flex-row justify-between items-center gap-20 mb-6 md:mb-0">
                            {unit?.avatar && (
                                <div className="bg-navy-lightest p-3 rounded">
                                    <img
                                        src={proxyImage(unit.avatar, 150, 150, 100)}
                                        alt="Demise Logo"
                                        className="object-cover imagePreview rounded md:h-32 md:w-32 h-24 w-24"
                                    />
                                </div>
                            )}
                            {unit?.organisation.avatar && (
                                <div className="bg-navy-lightest p-3 rounded">
                                    <img
                                        src={proxyImage(unit?.organisation.avatar, 150, 150, 100)}
                                        alt="Landlord Logo"
                                        className="object-cover imagePreview rounded md:h-32 md:w-32 h-24 w-24"
                                    />
                                </div>
                            )}
                        </div>
                    </div>

                    <hr className="border-newTeal-main my-12" />

                    {/* Parties - Removed as now using clauses */}
                    <div>
                        <h3 className="font-semibold text-lg">1. The Parties</h3>
                        <div className="ml-6 flex flex-row w-full">
                            <div className="w-1/2 text-sm">
                                <p className="font-semibold">{unit?.organisation.name}</p>
                                <p>{unit?.organisation.address?.line1}</p>
                                <p>{unit?.organisation.address?.line2}</p>
                                <p>{unit?.organisation.address?.city}</p>
                                <p>{unit?.organisation.address?.postCode}</p>
                                <p>{unit?.organisation.website}</p>
                            </div>
                            <div className="1/2">
                                <p className="text-sm">
                                    <p className="font-semibold">{deal?.tenant?.name}</p>
                                    <p>{deal?.details.one?.entity?.address?.line1}</p>
                                    <p>{deal?.details.one?.entity?.address?.line2}</p>
                                    <p>{deal?.details.one?.entity?.address?.city}</p>
                                    <p>{deal?.details.one?.entity?.address?.postCode}</p>
                                </p>
                            </div>
                        </div>
                    </div>

                    <hr className="border-newTeal-main my-12" />

                    {/* Sections */}
                    {deal?.sections?.map((section, sectionIndex) => (
                        <React.Fragment key={section._id}>
                            <div key={section._id}>
                                <h3 className="font-semibold text-lg">{`${sectionIndex + 2}. ${section.title}`}</h3>
                                <div className="ml-6">
                                    {section.groups?.map((group) => (
                                        <React.Fragment key={group._id}>
                                            <h3 className="font-semibold text-base mt-4" key={group._id}>
                                                {group.title ?? ''}
                                            </h3>
                                            {group.clauses?.map((clause, index) =>
                                                clause.visible ? (
                                                    <div key={clause._id} className="text-sm">
                                                        <div
                                                            dangerouslySetInnerHTML={{
                                                                __html:
                                                                    formatClause(
                                                                        clause.longForm
                                                                            ? stripHtml(clause.longForm).replace(
                                                                                  /\p{Extended_Pictographic}/u,
                                                                                  ''
                                                                              )
                                                                            : '',
                                                                        clause.value
                                                                            ? stripHtml(clause.value).replace(
                                                                                  /\p{Extended_Pictographic}/u,
                                                                                  ''
                                                                              )
                                                                            : '',
                                                                        clause.valueSuffix
                                                                            ? stripHtml(clause.valueSuffix).replace(
                                                                                  /\p{Extended_Pictographic}/u,
                                                                                  ''
                                                                              )
                                                                            : '',
                                                                        clause.tags ?? []
                                                                    ) ?? ''
                                                            }}
                                                        />
                                                    </div>
                                                ) : (
                                                    clause.groups?.map((level2Group) => (
                                                        <div key={level2Group._id}>
                                                            <h4
                                                                className="font-semibold text-sm mt-2"
                                                                key={level2Group._id}
                                                            >
                                                                {level2Group.title ?? ''}
                                                            </h4>
                                                            {level2Group.clauses?.map((clause) => (
                                                                <div key={clause._id} className="ml-8 text-sm">
                                                                    <div
                                                                        dangerouslySetInnerHTML={{
                                                                            __html:
                                                                                formatClause(
                                                                                    clause.longForm
                                                                                        ? stripHtml(
                                                                                              clause.longForm
                                                                                          ).replace(
                                                                                              /\p{Extended_Pictographic}/u,
                                                                                              ''
                                                                                          )
                                                                                        : '',
                                                                                    clause.value
                                                                                        ? stripHtml(
                                                                                              clause.value
                                                                                          ).replace(
                                                                                              /\p{Extended_Pictographic}/u,
                                                                                              ''
                                                                                          )
                                                                                        : '',
                                                                                    clause.valueSuffix
                                                                                        ? stripHtml(
                                                                                              clause.valueSuffix
                                                                                          ).replace(
                                                                                              /\p{Extended_Pictographic}/u,
                                                                                              ''
                                                                                          )
                                                                                        : '',
                                                                                    clause.tags ?? []
                                                                                ) ?? ''
                                                                        }}
                                                                    />
                                                                    <br />
                                                                </div>
                                                            ))}
                                                        </div>
                                                    ))
                                                )
                                            )}
                                        </React.Fragment>
                                    ))}
                                </div>
                            </div>
                            {sectionIndex < (deal.sections?.length ?? 0) - 1 && (
                                <hr className="border-newGray-darkish my-12" />
                            )}
                        </React.Fragment>
                    ))}

                    <hr className="border-newTeal-main my-12" />

                    {/* Contact Details */}
                    <div>
                        <h3 className="font-semibold text-lg">{(deal?.sections?.length ?? 0) + 2}. Contacts</h3>

                        {/* Legal Contact Details */}
                        <div className="mt-2 ml-6 flex flex-row w-full">
                            <div className="w-1/2 text-sm">
                                <h3 className="font-bold text-base mt-2">Landlord's Legal Contact</h3>
                                <p>{landlordLawyerContactDetails.name}</p>
                                <p>{landlordLawyerContactDetails.company}</p>
                                <p>{landlordLawyerContactDetails.email}</p>
                                <p>{landlordLawyerContactDetails.phone}</p>
                            </div>
                            <div className="1/2 text-sm">
                                {deal?.tenant && <h3 className="font-bold text-base mt-2">Tenant's Legal Contact</h3>}
                                {deal?.details.two?.lawyer?.visible && (
                                    <>
                                        <p>{deal?.details?.two?.lawyer?.name}</p>
                                        <p>{deal?.details?.two?.lawyer?.company}</p>
                                        <p>{deal?.details?.two?.lawyer?.email}</p>
                                        <p>{deal?.details?.two?.lawyer?.phone}</p>
                                    </>
                                )}
                            </div>
                        </div>

                        {/* Deal Contact Details */}
                        <div className="mt-2 ml-6 flex flex-row w-full">
                            <div className="w-1/2 text-sm">
                                <h3 className="font-bold text-base mt-2">Landlord's Deal Contact</h3>
                                {landlordContactDetails.name && <p>{landlordContactDetails.name}</p>}
                                {landlordContactDetails.email && <p>{landlordContactDetails.email}</p>}
                                {landlordContactDetails.phone && <p>{landlordContactDetails.phone}</p>}
                            </div>
                            <div className="w-1/2 text-sm">
                                <h3 className="font-bold text-base mt-2">Tenant's Deal Contact</h3>
                                {deal?.details.team?.tenant?.visible && (
                                    <>
                                        <p>{deal?.details.team?.tenant?.name}</p>
                                        <p>{deal?.details.team?.tenant?.company}</p>
                                        <p>{deal?.details.team?.tenant?.email}</p>
                                        <p>{deal?.details.team?.tenant?.phone}</p>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="md:container relative mx-auto bg-navy-light flex flex-row justify-end md:rounded-b p-8">
                    <img src={Logo} alt="Logo" className="h-6" style={{ color: '#404040' }} />
                </div>
            </div>
        </div>
    );
};

export default PreviewContent;
