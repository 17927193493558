import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import WindowFooter from './layout/WindowFooter';
import WindowHeader from './layout/WindowHeader';
import StageCompleted from './StageCompleted';
import StageContext from './StageContext';
import { ReactComponent as CaretRight } from '../../../../../assets/images/chevron/right/black.svg';
import {
    GetDealTenantDetailsDocument,
    InviteFromStageType,
    useTenantActionStageTwoMutation,
    useTenantSendInviteMutation
} from '../../../../../generated/graphql';
import { Button } from '../../../../../shared/button';
import { handleFetchError } from '../../../../../shared/helpers';
import Input, { InputType } from '../../../../../shared/Inputs/index';
import Loading from '../../../../../shared/Loading';
import Window from '../../../../../shared/Window';

const Stage2Window = () => {
    const history = useHistory();

    const {
        dealID,
        details,
        backTo,
        setShowStage2Window: setShow,
        setShowLawyerWindow,
        setShowAgentWindow,
        setShowContractorWindow
    } = React.useContext(StageContext);

    // Add Lawyer
    const [lawyerName, setLawyerName] = useState<string>(details?.two?.lawyer?.name ?? '');
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [lawyerCompany, setLawyerCompany] = useState<string>(details?.two?.lawyer?.company ?? '');
    const [lawyerEmail, setLawyerEmail] = useState<string>(details?.two?.lawyer?.email ?? '');
    const [lawyerPhone, setLawyerPhone] = useState<string>(details?.two?.lawyer?.phone ?? '');
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [inviteLeastLawyer, setInviteLeastLawyer] = useState<boolean>(details?.two?.lawyer?.invite ?? false);

    // Add Agent
    const [agentName, setAgentName] = useState<string>(details?.two?.agent?.name ?? '');
    const [agentCompany, setAgentCompany] = useState<string>(details?.two?.agent?.company ?? '');
    const [agentEmail, setAgentEmail] = useState<string>(details?.two?.agent?.email ?? '');
    const [agentPhone, setAgentPhone] = useState<string>(details?.two?.agent?.phone ?? '');
    const [inviteLeastAgent, setInviteLeastAgent] = useState<boolean>(details?.two?.agent?.invite ?? false);

    // No Agent
    const [noAgent, setNoAgent] = useState<boolean>(
        details?.two?.agent?.visible === undefined ? false : details?.two?.agent?.visible ? false : true
    );

    // Set stage two
    const [actionStage, { error: actionStageError, loading: actionStageLoading }] = useTenantActionStageTwoMutation();

    const [inviteTeamMember, { error: inviteMemberError }] = useTenantSendInviteMutation();

    const saveToDB = async (completed?: boolean) => {
        const { data } = await actionStage({
            variables: {
                dealID: dealID!,
                completed: completed,
                lawyer: {
                    name: lawyerName,
                    company: lawyerCompany,
                    email: lawyerEmail,
                    phone: lawyerPhone,
                    invite: inviteLeastLawyer
                },
                agent: {
                    name: agentName,
                    company: agentCompany,
                    email: agentEmail,
                    phone: agentPhone,
                    invite: inviteLeastAgent,
                    visible: !noAgent
                }
            },
            refetchQueries: [
                {
                    query: GetDealTenantDetailsDocument,
                    variables: {
                        dealID: dealID!
                    }
                }
            ]
        });

        if (actionStageError) {
            return handleFetchError('Could not set your details', actionStageError, data?.dealTenantDetailsSet);
        }
    };

    const handleCompleteStage = async () => {
        // Local fields error Obj
        let fieldsErr = {
            lawyerName: false,
            lawyerCompany: false,
            lawyerEmail: false,
            lawyerPhone: false,
            agentName: false,
            agentCompany: false,
            agentEmail: false,
            agentPhone: false
        };

        // Validate empty fields
        const checkEmptyField = (field: string, fieldValue: string | undefined) => {
            !fieldValue
                ? (fieldsErr = {
                      ...fieldsErr,
                      [field]: true
                  })
                : (fieldsErr = {
                      ...fieldsErr,
                      [field]: false
                  });
        };

        // Empty Lawyer
        checkEmptyField('lawyerName', lawyerName);
        checkEmptyField('lawyerCompany', lawyerCompany);
        checkEmptyField('lawyerEmail', lawyerEmail);
        checkEmptyField('lawyerPhone', lawyerPhone);

        // Empty agent
        if (!noAgent) {
            checkEmptyField('agentName', agentName);
            checkEmptyField('agentEmail', agentEmail);
            checkEmptyField('agentCompany', agentCompany);
            checkEmptyField('agentPhone', agentPhone);
        }

        // Validate Emails
        const re = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

        const checkValidEmail = (field: string, fieldValue: string | undefined) => {
            if (fieldValue) {
                !re.test(fieldValue)
                    ? (fieldsErr = {
                          ...fieldsErr,
                          [field]: true
                      })
                    : (fieldsErr = {
                          ...fieldsErr,
                          [field]: false
                      });
            }
        };

        checkValidEmail('lawyerEmail', lawyerEmail);
        checkValidEmail('agentEmail', agentEmail);

        // Send to backend
        const noErrors = Object.values(fieldsErr).every((v) => v === false);
        noErrors ? saveToDB(true) : saveToDB(false);

        // Go to the next stage
        history.push('#readiness-stage3');
    };

    const handleNoAgent = () => {
        setNoAgent(!noAgent);
        setAgentName('');
        setAgentCompany('');
        setAgentEmail('');
        setInviteLeastAgent(false);
    };

    const handleInviteTeamMember = async (type: InviteFromStageType, email: string) => {
        if (email.trim().length === 0) return;

        await inviteTeamMember({
            variables: {
                dealID: dealID!,
                type,
                email
            },
            refetchQueries: [
                {
                    query: GetDealTenantDetailsDocument,
                    variables: {
                        dealID: dealID!
                    }
                }
            ]
        });

        if (inviteMemberError) {
            return setErrorMessage(inviteMemberError.message);
        }
    };

    const handleWindow = (type: string) => {
        if (setShow && setShowLawyerWindow && setShowAgentWindow && setShowContractorWindow) {
            setShow(false);
            switch (type) {
                case 'lawyer':
                    setShowLawyerWindow(true);
                    break;
                case 'agent':
                    setShowAgentWindow(true);
                    break;
                case 'contractor':
                    setShowContractorWindow(true);
                    break;
            }
        }
    };

    return (
        <>
            <Window
                width="w-4/5"
                scrollContent={true}
                className="md:max-h-11/12"
                contentBackground="navy-light"
                header={
                    <WindowHeader
                        setShow={setShow!}
                        backTo={backTo!}
                        stage="2"
                        title="Confirm Your Team"
                        description="Later, in Stage 6, we will ask you to confirm who will fit-out your space (if a fit-out is required). Whilst you are getting your team together, now is a good time to think about who that will be."
                    />
                }
                footer={
                    <WindowFooter
                        backTo="#readiness-stage1"
                        nextTo="#readiness-stage3"
                        onNextClick={async () => await handleCompleteStage()}
                    />
                }
                setShow={setShow!}
                onCloseClick={() => {
                    setShow!(false);
                    history.push(backTo!);
                }}
            >
                <div className="flex flex-col">
                    <div className="w-full px-2">
                        {actionStageLoading && <Loading className="mt-20" />}

                        <div
                            className={`container lg:w-4/5 md:w-5/5 mx-auto sm:pt-8 sm:pb-8 pb-64 pt-0 ${
                                actionStageLoading && 'opacity-0'
                            }`}
                        >
                            <div className="flex flex-col md:flex-row">
                                {/* Left */}
                                <div className="md:w-1/3 w-full mr-5">
                                    <h3 className="font-semibold text-newGray-darkish">
                                        Add Lawyer (or person reviewing the lease)
                                    </h3>
                                    <p className="mt-2 text-gray-600 font-medium text-sm mb-0.5">
                                        <span className="text-newTeal-main mr-1">*</span>
                                        Name
                                    </p>
                                    <Input
                                        type={InputType.text}
                                        value={lawyerName}
                                        onChange={(e) => {
                                            setLawyerName(e.target.value);
                                        }}
                                        placeholder="Enter Lawyer's Name"
                                        className={`w-full`}
                                        autoComplete="off"
                                    />
                                    <p className="mt-4 text-gray-600 font-medium text-sm mb-0.5">
                                        <span className="text-newTeal-main mr-1">*</span>
                                        Company
                                    </p>
                                    <Input
                                        type={InputType.text}
                                        value={lawyerCompany}
                                        onChange={(e) => {
                                            setLawyerCompany(e.target.value);
                                        }}
                                        placeholder="Enter Lawyer's Company"
                                        className={`w-full`}
                                        autoComplete="off"
                                    />
                                    <p className="mt-4 text-gray-600 font-medium text-sm mb-0.5">
                                        <span className="text-newTeal-main mr-1">*</span>
                                        Email
                                    </p>
                                    <Input
                                        type={InputType.email}
                                        value={lawyerEmail}
                                        onChange={(e) => {
                                            setLawyerEmail(e.target.value);
                                        }}
                                        placeholder="Enter Lawyer's Email"
                                        className={`w-full`}
                                        autoComplete="off"
                                    />
                                    <p className="mt-4 text-gray-600 font-medium text-sm mb-0.5">
                                        <span className="text-newTeal-main mr-1">*</span>
                                        Phone
                                    </p>
                                    <Input
                                        type={InputType.phone}
                                        value={lawyerPhone}
                                        onChange={(e) => {
                                            setLawyerPhone(e.target.value);
                                        }}
                                        placeholder="Enter Lawyer's Phone"
                                        className={`w-full`}
                                        autoComplete="off"
                                    />

                                    <label className="block my-5 w-full">
                                        <Button
                                            background="white"
                                            text="black"
                                            className={`px-10 font-semibold w-full mt-2 md:mt-0 hover:border-newTeal-main sm:flex-row md:flex-col lg:flex-row${
                                                !lawyerEmail ||
                                                details?.two?.lawyer?.email?.toLowerCase() === lawyerEmail.toLowerCase()
                                                    ? ' opacity-50 cursor-not-allowed'
                                                    : ''
                                            }`}
                                            onClick={() => {
                                                if (
                                                    !details?.two?.lawyer?.invite ||
                                                    details.two.lawyer.email?.toLowerCase() !==
                                                        lawyerEmail.toLowerCase()
                                                )
                                                    handleInviteTeamMember(InviteFromStageType.Lawyer, lawyerEmail);
                                            }}
                                        >
                                            {!details?.two?.lawyer?.invite ||
                                            details.two.lawyer.email?.toLowerCase() !== lawyerEmail.toLowerCase() ? (
                                                <>
                                                    {' '}
                                                    Send Invite To Kato
                                                    <span className="ml-2 text-xs text-newGray-main">(Optional)</span>
                                                </>
                                            ) : (
                                                <>User Invited</>
                                            )}
                                        </Button>
                                    </label>

                                    <div
                                        className="pl-3 pr-2 py-3 border border-newGray-main rounded flex justify-between items-center cursor-pointer text-gray-600 hover:bg-white hover:border-white hover:text-gray-800 transitionAll"
                                        onClick={() => handleWindow('lawyer')}
                                    >
                                        <p className="font-medium">Find a Lawyer</p>
                                        <CaretRight className="w-6 h-6 text-newGray-main" />
                                    </div>
                                </div>
                                {/* Middle */}
                                <div className="md:w-1/3 w-full mr-5">
                                    <div className="relative">
                                        <h3
                                            className={`font-semibold text-newGray-darkish mt-4 md:mt-0 ${
                                                noAgent && 'opacity-50'
                                            }`}
                                        >
                                            Add Agent
                                        </h3>
                                        <label className="absolute top-0 right-0">
                                            <span className="text-xs font-medium text-gray-600 mr-2">
                                                I'm not using an agent
                                            </span>
                                            <input
                                                type="checkbox"
                                                id="optional"
                                                checked={noAgent}
                                                onChange={handleNoAgent}
                                                className="leading-tight w-6 h-6 form-checkbox p-2 rounded bg-white text-newTeal-main focus:shadow-none focus:outline-none"
                                            />
                                        </label>
                                    </div>
                                    {/* Agent Fields */}
                                    <div>
                                        <p className="mt-2 text-gray-600 font-medium text-sm mb-0.5">
                                            <span className="text-newTeal-main mr-1">*</span>
                                            Name
                                        </p>
                                        <Input
                                            type={InputType.text}
                                            value={agentName}
                                            onChange={(e) => {
                                                setAgentName(e.target.value);
                                            }}
                                            disabled={noAgent ? true : false}
                                            placeholder="Enter Agent's name"
                                            className={`w-full`}
                                            autoComplete="off"
                                        />
                                        <p className="mt-4 text-gray-600 font-medium text-sm mb-0.5">
                                            <span className="text-newTeal-main mr-1">*</span>
                                            Company
                                        </p>
                                        <Input
                                            type={InputType.text}
                                            value={agentCompany}
                                            onChange={(e) => {
                                                setAgentCompany(e.target.value);
                                            }}
                                            disabled={noAgent ? true : false}
                                            placeholder="Enter Agent's Company"
                                            className={`w-full`}
                                            autoComplete="off"
                                        />
                                        <p className="mt-4 text-gray-600 font-medium text-sm mb-0.5">
                                            <span className="text-newTeal-main mr-1">*</span>
                                            Email
                                        </p>
                                        <Input
                                            type={InputType.email}
                                            value={agentEmail}
                                            onChange={(e) => {
                                                setAgentEmail(e.target.value);
                                            }}
                                            disabled={noAgent ? true : false}
                                            placeholder="Enter Agent's Email"
                                            className={`w-full`}
                                            autoComplete="off"
                                        />
                                        <p className="mt-4 text-gray-600 font-medium text-sm mb-0.5">
                                            <span className="text-newTeal-main mr-1">*</span>
                                            Phone
                                        </p>
                                        <Input
                                            type={InputType.phone}
                                            value={agentPhone}
                                            onChange={(e) => {
                                                setAgentPhone(e.target.value);
                                            }}
                                            disabled={noAgent ? true : false}
                                            placeholder="Enter Agent's Phone"
                                            className={`w-full`}
                                            autoComplete="off"
                                        />
                                        <label className="block my-5 w-full">
                                            <Button
                                                background="white"
                                                text="black"
                                                className={`px-10 font-semibold w-full mt-2 md:mt-0 hover:border-newTeal-main sm:flex-row md:flex-col lg:flex-row${
                                                    !agentEmail ||
                                                    details?.two?.agent?.email?.toLowerCase() ===
                                                        agentEmail.toLowerCase()
                                                        ? ' opacity-50 cursor-not-allowed'
                                                        : ''
                                                }`}
                                                onClick={() => {
                                                    if (
                                                        !details?.two?.agent?.invite ||
                                                        details.two.agent.email?.toLowerCase() !==
                                                            agentEmail.toLowerCase()
                                                    )
                                                        handleInviteTeamMember(InviteFromStageType.Agent, agentEmail);
                                                }}
                                            >
                                                {!details?.two?.agent?.invite ||
                                                details.two.agent?.email?.toLowerCase() !== agentEmail.toLowerCase() ? (
                                                    <>
                                                        {' '}
                                                        Send Invite To Kato
                                                        <span className="ml-2 text-xs text-newGray-main">
                                                            (Optional)
                                                        </span>
                                                    </>
                                                ) : (
                                                    <>User Invited</>
                                                )}
                                            </Button>
                                        </label>
                                        <div
                                            className={`pl-3 pr-2 py-3 border border-newGray-main rounded flex justify-between items-center ${
                                                noAgent
                                                    ? 'cursor-not-allowed opacity-50'
                                                    : 'cursor-pointer hover:bg-white hover:border-white hover:text-gray-800 transitionAll'
                                            } text-gray-600`}
                                            onClick={() => handleWindow('agent')}
                                        >
                                            <p className="font-medium">Find an Agent</p>
                                            <CaretRight className="w-6 h-6 text-newGray-main" />
                                        </div>
                                    </div>
                                </div>
                                {/* Right */}
                                <div className="md:w-1/3 w-full mt-4 md:mt-0">
                                    <h3 className="font-semibold text-newGray-darkish">Who will fit-out your space?</h3>
                                    <p className="mt-7 text-gray-800">
                                        Later in Stage 6, we will ask you to confirm the party who will fit-out your
                                        space. Whilst you are getting your team together it is now a good time to think
                                        about a team to carry out these works (if a fit-out is required).
                                    </p>
                                </div>
                            </div>
                            {/* Bottom text */}
                            <p className="text-gray-600 font-medium text-sm mb-6 mt-6">
                                <span className="text-newTeal-main mr-1">*</span>
                                All the fields are required to complete the stage
                            </p>
                            <div>
                                {errorMessage && (
                                    <div className="bg-red-200 border border-red-600 rounded py-2 px-4 my-4 mt-2">
                                        <span className="text-center text-red-600">{errorMessage}</span>
                                    </div>
                                )}
                            </div>
                            {details?.two?.completed && <StageCompleted />}
                        </div>
                    </div>
                </div>
            </Window>
        </>
    );
};

export default Stage2Window;
