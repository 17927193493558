import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { ReactComponent as Down } from '../../../../assets/images/chevron/down/black.svg';
import { ReactComponent as Up } from '../../../../assets/images/chevron/up/black.svg';
import { Button } from '../../../../shared/button';

type RejectDealDropdownProps = {
    dealID: string;
    showMakeChanges: boolean;
    setShowRejectDealWindow: React.Dispatch<React.SetStateAction<boolean>>;
    className?: string;
    background?: string;
    border?: string;
    fontWeight?: string;
    height?: string;
};

const RejectDealDropdown = ({
    dealID,
    setShowRejectDealWindow,
    showMakeChanges,
    className,
    background,
    border,
    fontWeight,
    height
}: RejectDealDropdownProps) => {
    const history = useHistory();
    const [isOpen, setOpen] = useState<boolean>(false);

    return (
        <div className="relative">
            <Button
                onClick={() => setOpen((isOpen) => !isOpen)}
                background={background ?? 'white'}
                text={'black'}
                border={border ?? 'black'}
                className={`pl-4 pr-2 mr-2 ${className}`}
                fontWeight={fontWeight}
                height={height}
            >
                Not Approved
                {!isOpen ? <Down className="ml-2 w-6 h-6" /> : <Up className="ml-2 w-6 h-6" />}
            </Button>
            {isOpen && (
                <>
                    <div className="fixed w-full h-full z-10 left-0 top-0" onClick={() => setOpen(false)}></div>
                    <div className="absolute w-40 bg-white border rounded border-newTeal-main z-20 right-0 mr-2 flex flex-col mt-2">
                        {showMakeChanges && (
                            <span
                                onClick={() => {
                                    setOpen(false);
                                    history.push(`/deals/${dealID}`);
                                }}
                                className="border-navy-light border-b inline-flex items-center justify-center h-10 cursor-pointer hover:bg-navy-lightest"
                            >
                                Make Changes
                            </span>
                        )}
                        <span
                            onClick={() => {
                                setOpen(false);
                                setShowRejectDealWindow(true);
                            }}
                            className="border-navy-light border-b inline-flex items-center justify-center h-10 cursor-pointer hover:bg-navy-lightest"
                        >
                            Send Note
                        </span>
                    </div>
                </>
            )}
        </div>
    );
};

export default RejectDealDropdown;
