import { useEffect, useState } from 'react';

const useMobileScreen = () => {
    const [mobileScreen, setMobileScreen] = useState<boolean | null>(null);
    // Check if mobile on resize
    useEffect(() => {
        const handleResize = () => {
            window.innerWidth < 768 ? setMobileScreen(true) : setMobileScreen(false);
        };
        window.addEventListener('resize', handleResize);
    }, []);
    // Check if mobile on load
    useEffect(() => (window.innerWidth < 768 ? setMobileScreen(true) : setMobileScreen(false)), []);
    return mobileScreen;
};

export default useMobileScreen;
