import React, { useEffect, useRef, useState } from 'react';

import { ReactComponent as Info } from '../../assets/images/info/black_new.svg';
import {
    GetUserOrganisationDocument,
    InvitedUserFragment,
    useGetUserTypeQuery,
    useTrustedUsersInviteMutation
} from '../../generated/graphql';
import { useGetUserOrganisationQuery, useInviteUserMutation, UserType } from '../../generated/graphql';
import { Button } from '../../shared/button';
import EmailMultiInput from '../../shared/Inputs/EmailMultiInput';
import Select from '../../shared/Inputs/Select';
import Loading from '../../shared/Loading';
import Window from '../../shared/Window';

export enum AccountType {
    COLLEAGUES = 'Colleague',
    TRUSTED = 'Advisor'
}

type UserInviteWindowProps = {
    email?: string;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
    setIsUserInvited: React.Dispatch<React.SetStateAction<boolean>>;
};

const UserInviteWindow = ({ email, setShow, setIsUserInvited }: UserInviteWindowProps) => {
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    const [inviteUserType, setInviteUserType] = useState<UserType | null>(null);

    const [userInvited, setUserInvited] = useState<InvitedUserFragment[]>();

    const [optionId, setOptionId] = useState<AccountType | null>(null);

    const [emails, setEmails] = useState<string[]>([]);

    const { data: typeData, loading: typeLoading, error: typeError } = useGetUserTypeQuery();
    const { data, loading, error } = useGetUserOrganisationQuery();
    const [inviteUser] = useInviteUserMutation();
    const [trustedUserInvite] = useTrustedUsersInviteMutation();

    const inviteUserEmailRef = useRef<HTMLInputElement>(null);

    const setInviteUserTypeString = (type: string) => {
        switch (type) {
            case 'ADMIN':
                setInviteUserType(UserType.Admin);
                break;
            case 'LANDLORD':
                setInviteUserType(UserType.Landlord);
                break;
            case 'TENANT':
                setInviteUserType(UserType.Tenant);
                break;
            case 'AGENT':
                setInviteUserType(UserType.Agent);
                break;
        }
    };

    useEffect(() => {
        const userType = typeData?.user.type ?? '';
        setInviteUserTypeString(userType);
    }, [typeData]);

    const handleInviteSubmit = async () => {
        if (!emails) return setErrorMessage('Please enter an email address');
        if (!inviteUserType) return null;

        if (optionId === AccountType.TRUSTED && typeData?.user.type !== UserType.Agent) {
            const { data: trustedUserInviteData, errors: trustedUserInviteErrors } = await trustedUserInvite({
                variables: {
                    organisationID: data?.user.__typename === 'User' ? data.user.organisation._id : '',
                    emails: emails
                },
                refetchQueries: [{ query: GetUserOrganisationDocument }]
            });
            if (trustedUserInviteErrors) {
                setErrorMessage(trustedUserInviteErrors[0].message);
                return;
            }
            setIsUserInvited(true);
            setUserInvited(trustedUserInviteData?.trustedUsersInvite.users);
        } else if (optionId === AccountType.COLLEAGUES || typeData?.user.type === UserType.Agent) {
            const { data: inviteUserData, errors: inviteUserErrors } = await inviteUser({
                variables: {
                    organisationID: data?.user.__typename === 'User' ? data.user.organisation._id : '',
                    emails: emails,
                    type: inviteUserType
                },
                refetchQueries: [{ query: GetUserOrganisationDocument }]
            });

            if (inviteUserErrors) {
                setErrorMessage(inviteUserErrors[0].message);
                return;
            }
            setUserInvited(inviteUserData?.userInvite.users);
        }

        setShow(false);
    };

    if (loading || typeLoading) return <Loading />;

    if (error || typeError) return null;

    return (
        <div>
            <Window
                title={typeData?.user.type === UserType.Agent ? 'Invite Colleague' : 'Invite User'}
                width="w-1/3"
                setShow={setShow}
                titleCenter
                footer={
                    <div className="w-full p-2 flex items-center justify-center flex-wrap border-t border-navy-lightest">
                        {!!userInvited && userInvited.map((user) => !user.existing) ? (
                            <Button
                                background="newTeal-main"
                                text="white"
                                onClick={() => setShow(false)}
                                className={`px-10 font-semibold text-sm w-full md:w-auto`}
                            >
                                Go back
                            </Button>
                        ) : (
                            <div className="flex flex-col md:flex-row w-full">
                                <Button
                                    background="navy-lightest"
                                    text="black"
                                    onClick={() => setShow(false)}
                                    className={`px-10 border-navy-light font-semibold w-full md:w-1/3 mt-2 md:mt-0 md:mr-2`}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    background="newTeal-main"
                                    text="white"
                                    onClick={handleInviteSubmit}
                                    className={`px-10 font-semibold w-full md:w-2/3`}
                                >
                                    Send Invite
                                </Button>
                            </div>
                        )}
                    </div>
                }
            >
                {optionId && (
                    <div className="flex inline-flex items-center w-full px-4 py-3 bg-navy-lightest">
                        <Info className="w-6 h-4" />
                        {optionId === AccountType.COLLEAGUES ? (
                            <p className="text-xs">Use this account type to invite members of your company</p>
                        ) : (
                            <p className="text-xs pl-2">
                                Use this account type to invite people in other companies that you work closely with,
                                such as Agents/Managing Agents and Lawyers
                            </p>
                        )}
                    </div>
                )}
                <div className="p-4">
                    {!!userInvited && userInvited.map((user) => !user.existing) ? (
                        userInvited.map((user) => (
                            <p className="text-center">
                                An email has been sent to <span className="underline text-gray-600">{user.email}</span>
                            </p>
                        ))
                    ) : (
                        <form
                            className="flex flex-col flex-wrap md:flex-no-wrap w-full gap-4 mb-4"
                            onSubmit={(e) => e.preventDefault()}
                        >
                            {typeData?.user.type !== UserType.Agent && (
                                <Select
                                    className="z-30"
                                    label="Account Type"
                                    onItemClick={(id) => id && setOptionId(id)}
                                    data={[
                                        {
                                            id: AccountType.COLLEAGUES,
                                            text: AccountType.COLLEAGUES
                                        },
                                        {
                                            id: AccountType.TRUSTED,
                                            text: AccountType.TRUSTED
                                        }
                                    ]}
                                />
                            )}
                            <EmailMultiInput
                                setEmails={setEmails}
                                ref={inviteUserEmailRef}
                                defaultValue={email}
                                name="email"
                                placeholder={emails.length > 0 ? 'Type to add another email' : 'Enter email'}
                                label="Email"
                                className="border-b border-t border-gray-50"
                            />
                        </form>
                    )}
                    {errorMessage && (
                        <div className="bg-red-200 border border-red-600 rounded w-full py-2 px-4 mt-4">
                            <span className="text-center text-red-600">{errorMessage}</span>
                        </div>
                    )}
                </div>
            </Window>
        </div>
    );
};

export default UserInviteWindow;
