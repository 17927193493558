import { useApolloClient } from '@apollo/client';

import { DealDetailsFragment, GetTemplateDetailsDocument, useUpdateGroupMutation } from '../../../generated/graphql';
import { move } from '../../helpers';

const useMoveGroup = (
    deal: DealDetailsFragment | null | undefined,
    id: string | undefined,
    sectionID: string | undefined,
    parentID: string | undefined
) => {
    const client = useApolloClient();
    const [moveGroup] = useUpdateGroupMutation();
    if (!deal || !id || !sectionID) {
        return () => {};
    }

    return async (from: number, to: number) => {
        if (!deal || !id || !sectionID) {
            return;
        }
        // Move in local cache first
        client.cache.modify({
            id: client.cache.identify({
                __typename: 'Section',
                _id: sectionID
            }),
            fields: {
                groups(groups = []) {
                    const newGroups = move(from, to, ...groups);

                    return newGroups;
                }
            }
        });

        // Then tell API
        await moveGroup({
            fetchPolicy: 'no-cache',
            variables: {
                dealID: deal?._id ?? '',
                parentID: parentID ?? '',
                groupID: id,
                from: from,
                to: to
            },
            refetchQueries: () => [GetTemplateDetailsDocument]
        });
    };
};

export default useMoveGroup;
