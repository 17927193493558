import React from 'react';

type NavContainerProps = {
    className?: string;
    children: React.ReactNode;
    backgroundColor?: string;
    flexRow?: boolean;
};

const NavContainer = ({ className, children, backgroundColor, flexRow }: NavContainerProps) => {
    return (
        <div
            data-testid="layout--nav-container"
            className={`flex ${flexRow ? 'flex-row' : 'flex-col'} content-between  ${
                backgroundColor ? backgroundColor : 'bg-gray-800 w-full'
            }  ${className}`}
        >
            {children}
        </div>
    );
};

export default NavContainer;
