import qs from 'qs';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import ActionsRequired from './ActionsRequired';
import PriorityDeals from './PriorityDeals';
import SwitchPracticeWindow from './SwitchPracticeWindow';
import {
    DealStatusFilter,
    useGetDealCardDetailsQuery,
    useGetUnitCardDetailsQuery,
    useGetUserOrganisationQuery,
    useNotificationsQuery
} from '../../../generated/graphql';
import Grid from '../../../shared/Grid';
import Loading from '../../../shared/Loading';
import Notifications from '../../../shared/Notifications';

type DealsProps = {
    userID?: string;
    setHeaderContent: React.Dispatch<React.SetStateAction<React.ReactChild | null>>;
};

const LandlordDashboard = ({ setHeaderContent, userID }: DealsProps) => {
    const history = useHistory();

    const [showInviteWindow, setShowInviteWindow] = useState<boolean>(false);

    const { data: userOrganisationData } = useGetUserOrganisationQuery();

    const { loading, data } = useGetDealCardDetailsQuery({
        variables: {
            limit: 3
        },
        fetchPolicy: 'network-only'
    });

    const { data: pinnedData } = useGetDealCardDetailsQuery({
        variables: {
            limit: 3,
            filter: { status: [DealStatusFilter.Pinned] }
        },
        fetchPolicy: 'network-only'
    });

    const { data: notificationsData, refetch } = useNotificationsQuery({
        fetchPolicy: 'cache-and-network'
    });

    const unseenInvites = notificationsData?.notifications.notifications.filter(
        (notification) => notification.read === false && notification.type === 'INVITE'
    );

    const { loading: unitLoading, data: unitData } = useGetUnitCardDetailsQuery({
        variables: {
            limit: 3
        }
    });
    useEffect(() => {
        setHeaderContent(<h1 className="text-xl font-bold p-2">Dashboard</h1>);
    }, [setHeaderContent]);

    useEffect(() => {
        const { practiceInvite } = qs.parse(window.location.search.slice(1));

        if (practiceInvite) setShowInviteWindow(true);
    }, [window.location.search]);

    useEffect(() => {
        const { practiceInvite } = qs.parse(window.location.search.slice(1));

        if (!practiceInvite && unseenInvites?.[0]?.url && unseenInvites?.[0]?.url !== window.location.href) {
            const url = new URL(unseenInvites[0].url);
            // get relative path
            history.push(url.toString().substring(url.origin.length));
        }
    }, [unseenInvites]);

    if (loading || unitLoading) return <Loading />;

    return (
        <div className="bg-navy-lightest min-h-screen">
            {showInviteWindow && userOrganisationData && (
                <SwitchPracticeWindow setShow={setShowInviteWindow} refetch={refetch} />
            )}

            <PriorityDeals data={data} pinnedData={pinnedData} />
            <div className={`w-full md:px-32 flex justify-center p-4`}>
                <div className="container">
                    <Grid large="5">
                        <div className="md:col-span-3 col-span-5">
                            <ActionsRequired data={data} unitData={unitData} />
                        </div>
                        <div className="md:col-span-2 col-span-5">
                            <Notifications userID={userID} compact={true} />
                        </div>
                    </Grid>
                </div>
            </div>
        </div>
    );
};

export default LandlordDashboard;
