import React from 'react';

import { DealUserParty, GetUserTypeQuery } from '../../../generated/graphql';
import DealCard from '../../Deals/DealCard';

type AllPriorityDealsProps = {
    deals: any[];
    typeData: GetUserTypeQuery | undefined;
    isTenant: boolean;
};

const AllPriorityDeals = ({ deals, typeData, isTenant }: AllPriorityDealsProps) => {
    return (
        <>
            {deals.length > 0 &&
                [...deals]
                    .sort((a, b) => (a.score > b.score ? 1 : -1))
                    .map(
                        (deal, index) =>
                            index <= 2 && (
                                <DealCard
                                    id={deal._id}
                                    key={deal._id}
                                    approvals={deal.approvals}
                                    hasMaxPinnedDeals={typeData?.user.hasMaxPinnedDeals}
                                    name={
                                        !isTenant && deal.tenant?.name
                                            ? deal.tenant?.name
                                            : deal.unit?.organisation.name ?? ''
                                    }
                                    unitID={deal.unit?._id ?? ''}
                                    isPinned={deal.isPinned}
                                    unitName={deal.unit?.name ?? 'Untitled Demise'}
                                    unitRating={deal.unit?.rating ?? 0}
                                    pending={deal.pending}
                                    buildingName={deal.unit?.buildingName ?? 'Untitled Building'}
                                    state={deal.state}
                                    currentUserParty={deal.currentUserParty}
                                    tenantView={deal.currentUserParty === DealUserParty.Tenant}
                                    clientName={
                                        typeData?.user.__typename === 'User' && typeData.user.type === 'AGENT'
                                            ? deal.currentUserParty === 'TENANT'
                                                ? deal.tenant?.name
                                                : deal.unit?.organisation.name
                                            : null
                                    }
                                    round={deal.round}
                                    clauseSummary={deal.clauseSummary}
                                    created={deal.created}
                                    createdAtClassName={'mr-4'}
                                />
                            )
                    )}
        </>
    );
};

export default AllPriorityDeals;
