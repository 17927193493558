import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';

type ExternalWindowProps = {
    title: string;
    children: React.ReactNode;
    options?: {
        width?: number;
        height?: number;
        left?: number;
        top?: number;
    };
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
    setShowExternalWindow: React.Dispatch<React.SetStateAction<boolean>>;
};

const ExternalWindow = ({
    title,
    children,
    setShow,
    setShowExternalWindow,
    options = { width: 900, height: 800, top: 200, left: 300 }
}: ExternalWindowProps) => {
    // eslint-disable-next-line
    const [externalWindow, setExternalWindow] = useState<Window | null>(null);
    const containerElement = useRef(document.createElement('div'));

    useEffect(() => {
        const { width, height, top, left } = options;
        const externalWindow = window.open('', '', `width=${width}, height=${height}, left=${left} top=${top}`);

        if (containerElement && height) containerElement.current.style.height = '100vh';

        if (externalWindow) {
            externalWindow.document.body.appendChild(containerElement.current);

            // Copy App's styles into new window
            const stylesheets = Array.from(document.styleSheets);
            stylesheets.forEach((stylesheet) => {
                const css = stylesheet as CSSStyleSheet;

                if (stylesheet.href) {
                    const newStyleElement = document.createElement('link');
                    newStyleElement.rel = 'stylesheet';
                    newStyleElement.href = stylesheet.href;
                    externalWindow.document.head.appendChild(newStyleElement);
                } else if (css && css.cssRules && css.cssRules.length > 0) {
                    const newStyleElement = document.createElement('style');
                    Array.from(css.cssRules).forEach((rule) => {
                        newStyleElement.appendChild(document.createTextNode(rule.cssText));
                    });
                    externalWindow.document.head.appendChild(newStyleElement);
                }
            });

            externalWindow.document.title = title;

            externalWindow.addEventListener('beforeunload', () => {
                setShow(false);
                setShowExternalWindow(false);
            });

            // Close window on component unmount
            return () => {
                if (externalWindow) externalWindow.close();
            };
        }

        setExternalWindow(externalWindow);
    }, [options, title, setShow, setShowExternalWindow]);

    if (!containerElement) return null;

    return ReactDOM.createPortal(children, containerElement.current);
};

export default ExternalWindow;
