import React, { useEffect, useState } from 'react';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

import ClauseBank from './ClauseBank/';
import Clients from './Clients';
import Companies, { organisationFilterConfig } from './Companies/';
import Company from './Company';
import LandlordReadiness from './LandlordReadiness/';
import Notifications from './Notifications';
import Profile from './Profile/Profile';
import Sidebar from './Sidebar';
import StraightToLease from './StraightToLease';
import Team, { trustedFilterConfig } from './Team/';
import Users, { usersFilterConfig } from './Users';
import { ReactComponent as ChevronLeft } from '../../assets/images/chevron/left/teal.svg';
import { UserType } from '../../generated/graphql';
import { FilterProvider } from '../../shared/Filter/context';
import useMobileScreen from '../../shared/hooks/useMobileScreen';
import SidebarSettings from '../../v2/components/Sidebar/Settings';
import SidebarWrapper from '../../v2/components/Sidebar/SidebarWrapper';

type SettingsProps = {
    userType?: UserType;
    setHeaderContent: React.Dispatch<React.SetStateAction<React.ReactChild | null>>;
};

const Settings = ({ setHeaderContent, userType }: SettingsProps) => {
    let location = useLocation();
    const mobileScreen = useMobileScreen();
    const [loaded, setLoaded] = useState<boolean>(false);

    useEffect(() => {
        mobileScreen !== null && setLoaded(true);
    }, [mobileScreen]);

    useEffect(() => {
        setHeaderContent(<h1 className="text-xl font-bold p-2">Settings</h1>);
    }, [setHeaderContent]);

    const history = useHistory();

    return (
        <SidebarWrapper sidebar={<SidebarSettings />}>
            <div className="min-h-full-header w-full md:flex">
                <div
                    className={`overflow-y-hidden w-full ${
                        !location.pathname.includes('/settings/clausebank') ? 'p4' : ''
                    }`}
                >
                    {loaded && (
                        <>
                            <Switch>
                                <Route path="/settings" exact>
                                    {mobileScreen ? (
                                        <Redirect to="/settings/menu" />
                                    ) : (
                                        <Redirect to="/settings/profile" />
                                    )}
                                </Route>
                                <Route path="/settings/menu" exact>
                                    {mobileScreen ? <Sidebar showMobile={true} /> : <Redirect to="/settings/profile" />}
                                </Route>
                            </Switch>

                            {/* [mobile] Back Button */}
                            {mobileScreen && (
                                <div
                                    className="flex cursor-pointer w-full p-6 pb-2"
                                    onClick={() => history.push('/settings/menu')}
                                >
                                    <ChevronLeft className="w-6 h-6 text-black -ml-2" />
                                    <span>Back</span>
                                </div>
                            )}

                            {/* Pages Content */}
                            <Switch>
                                <Route path="/settings/profile">
                                    <Profile />
                                </Route>
                                <Route path="/settings/team">
                                    <FilterProvider key="team" page="team" filters={trustedFilterConfig(userType)}>
                                        <Team />
                                    </FilterProvider>
                                </Route>
                                <Route path="/settings/clients">
                                    <Clients />
                                </Route>
                                <Route path="/settings/notifications">
                                    <Notifications userType={userType} />
                                </Route>
                                <Route path="/settings/clausebank" exact>
                                    <ClauseBank />
                                </Route>
                                <Route path="/settings/clausebank/:orgId" exact>
                                    <ClauseBank />
                                </Route>
                                <Route path="/settings/landlordreadiness" exact>
                                    <LandlordReadiness />
                                </Route>
                                <Route path="/settings/users" exact>
                                    <FilterProvider key="users" page="users" filters={usersFilterConfig(userType)}>
                                        <Users />
                                    </FilterProvider>
                                </Route>
                                <Route path="/settings/language">
                                    <h1 className="mb-4 font-semibold text-xl p-6">Language & Region</h1>
                                </Route>
                                <Route path="/settings/notifications">
                                    <h1 className="mb-4 font-semibold text-xl p-6">Notifications</h1>
                                </Route>
                                <Route path="/settings/clauses">
                                    <h1 className="mb-4 font-semibold text-xl p-6">Clause Hierarchy</h1>
                                </Route>
                                <Route path="/settings/company">
                                    <Company />
                                </Route>
                                <Route path="/settings/straight-to-lease">
                                    <StraightToLease />
                                </Route>
                                <Route path="/settings/companies" exact>
                                    <FilterProvider
                                        key="organisations"
                                        page="organisations"
                                        filters={organisationFilterConfig()}
                                    >
                                        <Companies />
                                    </FilterProvider>
                                </Route>
                            </Switch>
                        </>
                    )}
                </div>
            </div>
        </SidebarWrapper>
    );
};

export default Settings;
