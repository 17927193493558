import React from 'react';

import { AgreedBy, DealRoundType, DealState } from '../../../../../../generated/graphql';

type DealActorsLastActivityProps = {
    state?: DealState;
    type: 'Landlord' | 'Tenant';
    dealRound?: DealRoundType | null;
    agreedBy?: AgreedBy | null;
};

const DealActorsLastActivity = ({ state, type, dealRound, agreedBy }: DealActorsLastActivityProps) => {
    const days = Math.ceil((new Date().getTime() - new Date(dealRound?.date).getTime()) / (1000 * 3600 * 24) - 1);

    if (
        state?.toString().toLowerCase() === type.toLowerCase() ||
        (state?.toString() === DealState.Agreed && agreedBy && agreedBy.valueOf() !== type)
    ) {
        return (
            <>
                <p className="text-sm font-light text-center">HoTs Received</p>
                <p
                    className="font-semibold text-center"
                    style={{ fontSize: '15px' }} // text-base class would make it too big
                >
                    {days === 0 ? 'Today' : `${days} Day${days > 1 ? 's' : ''} Ago`}
                </p>
            </>
        );
    } else {
        return <></>;
    }
};

export default DealActorsLastActivity;
