import { useApolloClient } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { SubmitHandler } from 'react-hook-form';
import { Link, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import { useLoginMutation, UserType } from '../../generated/graphql';
import { Button } from '../../shared/button';
import { useQuery } from '../../shared/helpers';
import Input, { InputType } from '../../shared/Inputs';
import Form from '../../shared/Inputs/Form';

interface ILoginFormData {
    email: string;
    password: string;
}

type LoginFormProps = {
    email: string;
    error: string;
};

const LoginForm = ({ email, error: ErrorProp }: LoginFormProps) => {
    const history = useHistory();
    const client = useApolloClient();
    const query = useQuery();
    const [login] = useLoginMutation();

    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        if (ErrorProp) setError(ErrorProp);
    }, [ErrorProp]);

    // SECTION Form Handler
    const handleSubmit: SubmitHandler<ILoginFormData> = async ({ email, password }) => {
        // Remove any cached data for the previous user
        await client.clearStore();

        // Clear previous errors
        toast.dismiss();

        const { data, errors } = await login({
            variables: {
                email,
                password
            }
        });

        if (errors) {
            if (errors[0].extensions?.message) {
                setError(errors[0].extensions?.message);
            } else {
                setError(errors[0].message);
            }
            return;
        }

        const previous = query.get('prev');

        if (data?.userAccessTokenCreate?.is2FAEnabled) {
            sessionStorage.setItem('email', email);
            return history.push(`/2FA?prev=${previous ? 'prev=' + previous : ''}`);
        }

        await client.resetStore();

        if (!data?.userAccessTokenCreate?.activated) {
            return history.push(`/restricted${previous ? 'prev=' + previous : ''}`); //if account not activated, send them to the restricted screen
        }

        // Redirect to previous route if given
        if (previous) return history.push(previous);

        return history.push(data.userAccessTokenCreate.type === UserType.Tenant ? '/deals' : '/');
    };

    return (
        <Form onSubmit={handleSubmit} className="bg-white px-8 pt-6 mb-6 sm:h-auto max-w-sm md:max-w-sm w-full">
            {({ register, formState: { errors } }) => (
                <>
                    <div className="mb-4">
                        <Input
                            type={InputType.email}
                            placeholder="Your email"
                            label="Email"
                            error={errors.email}
                            {...register('email', {
                                required: 'Email is required'
                            })}
                            className="w-full bg-navy-lightest"
                            autoFocus
                            autoComplete="email"
                        />
                    </div>
                    <div className="mb-4 flex flex-col content-center">
                        <Input
                            type={InputType.password}
                            placeholder="Your password"
                            label="Password"
                            error={errors.password}
                            {...register('password', {
                                required: 'Password is required'
                            })}
                            className="w-full bg-navy-lightest"
                        />
                        <Link
                            to="/forgot"
                            className="mt-1 text-right text-xs text-blue-500 hover:text-blue-800 underline"
                        >
                            Forgot your password?
                        </Link>
                    </div>
                    <div className="flex items-center justify-center mt-6">
                        <Button background="newTeal-main" text="white" className="w-full font-semibold">
                            Log-In
                        </Button>
                    </div>
                    {error && (
                        <div className="bg-red-200 border border-red-600 rounded w-full py-2 px-4 mt-4">
                            <span className="text-center text-red-600">{error}</span>
                        </div>
                    )}
                </>
            )}
        </Form>
    );
};

export default LoginForm;
