import React from 'react';

import { ReactComponent as Delete } from '../../../../../../assets/images/delete/user/black.svg';
import { DealUserParty } from '../../../../../../generated/graphql';
import { Button } from '../../../../../../shared/button/index';

type RemoveTenantButtonProps = {
    tenantID: string | undefined;
    currentParty?: false | DealUserParty | null | undefined;
    type: 'Landlord' | 'Tenant';
    setShowRemoveTenantWindow: React.Dispatch<React.SetStateAction<boolean>>;
};

const RemoveTenantButton = ({ tenantID, type, currentParty, setShowRemoveTenantWindow }: RemoveTenantButtonProps) => {
    if (currentParty === DealUserParty.Landlord && tenantID && type === 'Tenant') {
        return (
            <div className="py-1 md:pb-0 md:pt-2 flex justify-center">
                {/* Remove Tenant Organisation */}
                <Button onClick={() => setShowRemoveTenantWindow(true)} className="pt-4 md:pt-2 pb-4" border="none">
                    <Delete className="w-5 h-5 mr-2 text-base" />
                    Remove Tenant
                </Button>
            </div>
        );
    } else {
        return <></>;
    }
};

export default RemoveTenantButton;
