import { noop } from 'lodash';
import React, { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { GetDealTenantDetailsDocument, useTenantActionFinalStageMutation } from '../../../../../generated/graphql';
import { ReadinessContext } from '../context';
import Stage from '../Stage';

type FinalProps = {
    onSuccess?: () => void;
};

const Final = ({ onSuccess = noop }: FinalProps) => {
    const { isChanging, changeStage, clear } = useContext(ReadinessContext);
    const { id } = useParams<{ id: string }>();

    const [actionStage] = useTenantActionFinalStageMutation();

    const saveValues = async () => {
        await actionStage({
            variables: {
                dealID: id,
                input: {
                    completed: true,
                    informLandlord: false
                }
            },
            onCompleted: () => {
                changeStage();
                onSuccess();
            },
            refetchQueries: [
                {
                    query: GetDealTenantDetailsDocument,
                    variables: {
                        dealID: id
                    }
                }
            ]
        });
    };

    useEffect(() => {
        if (isChanging) saveValues();
    }, [isChanging]);

    const information = (text: string) => {
        return (
            <div className="flex flex-row items-center mt-4">
                <div className="bg-newTeal-main w-2 h-2 rounded-full mr-4 ml-2" />
                <div className="flex flex-col text-xs">
                    <p className="font-bold">{text}</p>
                    <p>(if one is not already in place)</p>
                </div>
            </div>
        );
    };

    return (
        <div className="py-14 h-full w-full mx-auto flex flex-col items-start ">
            <Stage
                stageNumber={6}
                title="Final Information"
                nextStage="I confirm I have read them all"
                onNextClick={() => {
                    saveValues();
                    clear();
                }}
                buttonAlign={'justify-start'}
            >
                <p className={`mt-2  mb-14`} style={{ fontSize: '0.7rem' }}>
                    Before you go we wanted to let you know of a couple more milestones a Tenant should accomplish
                    before signing the lease. As ever, the earlier these can be achieved the better.
                </p>
                <p className="text-xs font-semibold mt-6">I understand that Tenants need to:</p>
                {information('Identify a fibre provider')}
                {information('Provided the Landlord with fit out plans')}
                {information('Obtain a licence to alter from the Landlord')}
            </Stage>
        </div>
    );
};

export default Final;
