import React, { useContext } from 'react';

import { FilterContext } from './context';
import { FieldFilter, FilterType } from './FilterWindow';
import { ReactComponent as CloseIcon } from '../../assets/images/cross/white.svg';

type RangeFilterChipProps = {
    from?: string | number | undefined;
    to?: string | number | undefined;
    filter: FieldFilter;
};

const RangeFilterChip = ({ from, to, filter }: RangeFilterChipProps) => {
    const { removeRangeValue } = useContext(FilterContext);

    return (
        <div
            className={
                'bg-newGray-main flex items-center pr-2 cursor-pointer rounded-l ml-1 text-white rounded-r h-7 text-xs'
            }
            style={{
                minWidth: '7rem'
            }}
        >
            <div
                className="flex items-center justify-center cursor-pointer"
                style={{
                    width: '2rem'
                }}
                onClick={() => removeRangeValue(filter.id)}
            >
                <CloseIcon width={13} height={13} />
            </div>
            {filter.type === FilterType.Range ? (
                <p>
                    {filter.label} (
                    {from && to
                        ? `${from} - ${to} ${filter.suffix}`
                        : from
                        ? `More than ${from} ${filter.suffix}`
                        : `Less than ${to} ${filter.suffix}`}
                    )
                </p>
            ) : (
                filter.type === FilterType.RangeDate && (
                    <p>
                        {filter.label} ({from && to ? `${from} - ${to}` : from ? `From ${from}` : `To ${to}`})
                    </p>
                )
            )}
        </div>
    );
};

export default RangeFilterChip;
