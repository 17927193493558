import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

import TwoFA from './2FA';
import LoginForm from './LoginForm';
import ResetPasswordForm from './ResetPasswordForm';
import Restricted from './Restricted';
import SignupForm from './SignupForm';
import BackgroundImage from '../../assets/images/login/Background.svg';
import Logo from '../../assets/images/logo/kato.svg';

type AuthProps = {
    login?: boolean;
    signup?: boolean;
    forgotpw?: boolean;
    restricted?: boolean;
    twoFA?: boolean;
};

type LocationState = {
    email: string;
    error: string;
};

const Auth = ({ login, signup, forgotpw, restricted, twoFA }: AuthProps) => {
    const [resetPasswordResult, setResetPasswordResult] = useState<boolean>(false);
    const [passedEmail, setPassedEmail] = useState<string | null>(null);
    const [passedError, setPassedError] = useState<string | null>(null);

    const location = useLocation<LocationState>();

    useEffect(() => {
        const locationState = location.state;
        if (locationState) {
            if (locationState.email) setPassedEmail(locationState.email);
            if (locationState.error) setPassedError(locationState.error);
        }
    }, [location.state]);

    return (
        <div className="w-full h-screen flex">
            <section className="w-full lg:w-600 flex flex-col">
                <div className="flex flex-col flex-grow items-center justify-center h-full">
                    <img className="mb-8 w-32" src={Logo} alt="Least Logo" />
                    {forgotpw && (
                        <>
                            {!resetPasswordResult ? (
                                <>
                                    <p className="-mt-4 mb-2">Forgot your password?</p>
                                    <p className="px-6 mb-1 sm:h-auto max-w-sm md:max-w-sm w-full text-center text-gray-600 font-medium">
                                        Not to worry, we'll send a password reset link straight to your inbox
                                    </p>
                                    <ResetPasswordForm setResetPasswordResult={setResetPasswordResult} />
                                    <p className="text-gray-600 font-medium">
                                        Remembered your password?{' '}
                                        <Link
                                            to="/login"
                                            className="ml-1 font-semibold text-newTeal-main hover:text-black"
                                        >
                                            Login
                                        </Link>
                                    </p>
                                </>
                            ) : (
                                <div className="bg-white px-8 pt-6 pb-8 mb-4 sm:h-auto max-w-sm md:max-w-sm w-full text-center">
                                    <div className="bg-white-200 w-full py-2 px-4 mt-4">
                                        <span className="text-center text-black-600">
                                            If an account exists with that email, check your inbox to reset your
                                            password.{' '}
                                        </span>
                                    </div>
                                </div>
                            )}
                        </>
                    )}
                    {restricted && <Restricted />}
                    {login && <LoginForm email={passedEmail ?? ''} error={passedError ?? ''} />}
                    {signup && (
                        <>
                            <p className="-mt-4">Registration</p>
                            <SignupForm />
                            <p className="text-gray-600 font-medium">
                                Already have an account?
                                <Link to="/login" className="ml-1 font-semibold text-newTeal-main hover:text-black">
                                    Log-In
                                </Link>{' '}
                            </p>
                            <div className="sm:px-0 md:px-8 lg:px-0 max-w-sm">
                                <p className="text-sm text-gray-600 font-medium max-w-sm p-8 text-center">
                                    By clicking Register you accept our{' '}
                                    <a
                                        className="text-newTeal-main"
                                        href="https://getleast.com/termsandconditions"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        Terms and Conditions
                                    </a>{' '}
                                    and{' '}
                                    <a
                                        className="text-newTeal-main"
                                        href="https://getleast.com/privacypolicy"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        Privacy Policy
                                    </a>
                                    . Our GDPR contact is GDPR@getleast.com.
                                </p>
                            </div>
                        </>
                    )}
                    {twoFA && <TwoFA />}
                </div>
                {/* Footer */}
                <span className="mb-10 text-center">
                    <a href="https://www.kato.app/" className="text-salmon underline" style={{ fontSize: '1rem;' }}>
                        Kato.
                    </a>{' '}
                    Bring everyone in. Get everything done.♥
                </span>
            </section>
            <section
                className="hidden lg:flex w-full h-screen flex-col justify-items-center bg-newTeal-main p-15"
                style={{
                    backgroundImage: `url(${BackgroundImage})`,
                    backgroundPosition: 'right bottom',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: '30vw'
                }}
            >
                <div
                    className="absolute top-1/2"
                    style={{
                        transform: 'translateY(-50%)',
                        paddingRight: '32vw'
                    }}
                >
                    <h1
                        className="font-extrabold mb-4 text-white"
                        style={{
                            fontFamily: 'CabinetGrotesk-Extrabold',
                            fontSize: '5vw',
                            lineHeight: '95%'
                        }}
                    >
                        Bring everyone in.
                        <br />
                        Get everything done.
                    </h1>
                    <h4
                        className="font-medium text-navy-main"
                        style={{
                            fontFamily: 'CabinetGrotesk-Medium',
                            fontSize: '24px',
                            lineHeight: '22.8px'
                        }}
                    >
                        <span className="font-bold">Kato</span> is the fastest way to build, share, negotiate and agree
                        Heads of Terms - all in one place, without a Word doc in sight.
                    </h4>
                </div>
            </section>
        </div>
    );
};

export default Auth;
