import React, { useState } from 'react';

type MainContextType = {
    windowOpen: boolean;
    setWindowOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export const MainContext = React.createContext<MainContextType>({
    windowOpen: false,
    setWindowOpen: () => {}
});

type MainProviderProps = { children: React.ReactNode };

const MainProvider = ({ children }: MainProviderProps) => {
    const [windowOpen, setWindowOpen] = useState<boolean>(false);

    return <MainContext.Provider value={{ windowOpen, setWindowOpen }}>{children}</MainContext.Provider>;
};

export default MainProvider;
