import { formatDistance } from 'date-fns';
import React from 'react';
import ReactTooltip from 'react-tooltip';

import { ReactComponent as Download } from '../../../assets/images/download/black.svg';
import { ReactComponent as ReadinessTask } from '../../../assets/images/readinessTask/teal.svg';
import { UserDownload } from '../../../generated/graphql';

type FileCardProps = {
    name: string;
    description?: string;
    handleClick: () => void;
    url: string;
    user: UserDownload | undefined;
};

const FileCard = ({ name, description, handleClick, url, user }: FileCardProps) => {
    return (
        <div
            className={`grid grid-cols-8 mt-4 px-3 py-2 rounded-lg cursor-pointer border-2 
                ${!user && 'border-newTeal-main border-l-8'} items-center h-16`}
        >
            <div className="col-span-6 md:col-span-4 xl:col-span-3 flex flex-col h-full justify-around">
                <div className="flex flex-row items-center">
                    <p
                        data-border-color={'white'}
                        data-tip={name}
                        className="text-base truncate inline-block font-semibold"
                    >
                        {name}
                    </p>
                    <ReactTooltip
                        backgroundColor="black"
                        textColor="white"
                        effect="solid"
                        place="bottom"
                        clickable={false}
                        borderColor="white"
                    />
                </div>
                {description && (
                    <div className="flex flex-row items-center">
                        <div>
                            <ReadinessTask className="w-3 h-3 mr-2" />
                        </div>
                        <p
                            data-border-color={'white'}
                            data-tip={description}
                            className="text-xs truncate inline-block text-newTeal-main font-semibold font-semibold"
                        >
                            {description}
                        </p>
                        <ReactTooltip
                            backgroundColor="black"
                            textColor="white"
                            effect="solid"
                            place="bottom"
                            clickable={false}
                            borderColor="white"
                        />
                    </div>
                )}
            </div>
            <div className="col-span-2 hidden xl:flex flex-row justify-between items-center">
                <div className="text-gray-600 border-r-2 mr-5 h-7 text-sm border-navy-light" />
                <p className="text-base truncate">
                    {user
                        ? `Downloaded ${formatDistance(new Date(user.timestamp), new Date(), { addSuffix: true })}`
                        : 'Not downloaded'}
                </p>
                <div className="text-gray-600 border-r-2 ml-5 h-7 text-sm border-navy-light" />
            </div>
            <Download className="col-span-2 hidden md:block justify-self-center" onClick={handleClick} />
            <a
                href={url}
                target="_blank"
                rel="noopener noreferrer"
                className={`col-span-2 xl:col-span-1 w-full h-10 bg-newTeal-main flex rounded-lg inline-flex items-center
                    justify-self-end justify-center text-center cursor-pointer font-semibold`}
            >
                <p className="text-base text-white">View</p>
            </a>
        </div>
    );
};

export default FileCard;
