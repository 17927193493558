import React, { useContext, useEffect, useState } from 'react';

import {
    DealCardFragment,
    DealStatusFilter,
    GetUserOrganisationDocument,
    useDealBulkAddActorsMutation,
    useGetUserOrganisationQuery,
    useGetUserProfileQuery,
    UserType
} from '../../../../../../generated/graphql';
import { BulkSelectContext } from '../../../../../../shared/bulk/context';
import { FilterContext } from '../../../../../../shared/Filter/context';
import { transformFilters } from '../../../../../../shared/Filter/helpers';
import Select from '../../../../../../shared/Inputs/Select';
import Window from '../../../../../../shared/Window';

type BulkAddUsersWindowProps = {
    dealStatus: DealStatusFilter[] | undefined;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
    deals: DealCardFragment[];
};

const BulkAddUsersWindow = ({ dealStatus, setShow, deals }: BulkAddUsersWindowProps) => {
    const { data: userOrganisationData } = useGetUserOrganisationQuery();
    const {
        selectedItems,
        all,
        saveSelectMode,
        saveSelectAll,
        deals: selectedDeals,
        saveSelectedItems
    } = useContext(BulkSelectContext);
    // Error message
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [emails, setEmails] = useState<string[]>([]);
    const dealsData = all ? deals : selectedDeals;

    const { data: typeData } = useGetUserProfileQuery();

    const [addUsers, { error: addUserError, loading: addUserLoading }] = useDealBulkAddActorsMutation();
    const { values, filters } = useContext(FilterContext);

    const isLandlordOrAdmin =
        typeData?.user.type && [UserType.Admin, UserType.Landlord, UserType.Agent].includes(typeData?.user.type);

    const [pickerEmails, setPickerEmails] = useState<string[]>([]);

    const handleAdd = async () => {
        if (!emails) return setErrorMessage('Please enter an email address');

        await addUsers({
            variables: {
                dealIDs: all ? [] : selectedItems,
                emails,
                all: all,
                filter: {
                    ...transformFilters(filters, values),
                    ...(dealStatus ? { status: dealStatus } : {})
                }
            },
            refetchQueries: [{ query: GetUserOrganisationDocument }]
        });

        if (addUserError) return;

        setShow(false);
        saveSelectAll(false);
        saveSelectMode(false);
        saveSelectedItems([]);
    };

    Array.prototype.count = function (lit = false) {
        if (!lit) {
            return this.length;
        } else {
            var count = 0;
            for (var i = 0; i < this.length; i++) {
                if (lit == this[i]) {
                    count++;
                }
            }
            return count;
        }
    };

    useEffect(() => {
        const newEmailArray = [...(pickerEmails ?? [])];
        const duplicatedEmailsInside = [] as any[];
        const tenantAgents = [] as any[];

        if (selectedDeals.length === 1) {
            selectedDeals.map((deal) => {
                deal.actors.find((actor) => {
                    if (actor.type === 'AGENT') {
                        newEmailArray.push(actor.email);
                    }
                });
                deal.tenant?.members.map((member) => tenantAgents.push(member.email));
            });
            const tenantAndLandlordAgents = newEmailArray.concat(tenantAgents);
            const emailsToRemove = tenantAndLandlordAgents.filter((val, id, array) => {
                return array.indexOf(val) === id;
            });
            setPickerEmails(emailsToRemove);
        } else {
            selectedDeals.map((deal) => {
                deal.tenant?.members.map((member) => newEmailArray.push(member.email));
                deal.actors.find((actor) => {
                    if (actor.type === 'AGENT') {
                        newEmailArray.push(actor.email);
                    }
                });

                let count = 0;
                newEmailArray.forEach((email) => {
                    count = newEmailArray.count(email);
                    if (count === selectedItems.length) {
                        duplicatedEmailsInside.push(email);
                    }
                });
            });

            const emailsToRemove = newEmailArray.filter((email) => {
                const agentsSet = new Set(duplicatedEmailsInside);
                return agentsSet.has(email);
            });
            setPickerEmails(emailsToRemove);
        }
    }, [deals]);

    const pickerData =
        userOrganisationData &&
        userOrganisationData?.user.organisation.trustedUsers.filter((trustedUser) => {
            const agentsSet = new Set(pickerEmails);
            return !agentsSet.has(trustedUser.email);
        });

    return (
        <Window
            title="Add User"
            width="w-1/3"
            primaryBtn
            onPrimaryBtnClick={() => handleAdd()}
            primaryBtnText={addUserLoading ? 'Saving...' : 'Confirm'}
            setShow={setShow}
        >
            <>
                <form onSubmit={() => handleAdd()} className="pt-10 md:pt-4  w-full flex flex-col gap-2">
                    <div>
                        <p className="px-4  text-gray-600 font-medium text-sm mb-0.5">
                            Deals Selected ({selectedItems.length})
                        </p>
                        <div className="bg-gray-200 py-2 border-t border-b border-navy-light w-full">
                            {dealsData.map((deal) => {
                                return (
                                    <p className="px-4 text-gray-800 font-medium text-xs mb-0.5">
                                        {deal.unit &&
                                            (deal.unit?.name && deal.unit?.name + ', ') +
                                                (deal.unit?.buildingName && deal.unit?.buildingName + ', ') +
                                                (deal.unit?.address.line1 && deal.unit?.address.line1)}
                                    </p>
                                );
                            })}
                        </div>
                    </div>
                    <div className="p-4">
                        {isLandlordOrAdmin && userOrganisationData && pickerData && (
                            <Select
                                data={Object.values(pickerData).map((trustedUser) => {
                                    return {
                                        text: trustedUser.activated
                                            ? trustedUser.firstName +
                                              ' ' +
                                              trustedUser.lastName +
                                              ' (' +
                                              trustedUser.organisation.name +
                                              ')'
                                            : trustedUser.email,
                                        id: trustedUser.email
                                    };
                                })}
                                label="Users"
                                placeholder={emails && emails.length > 0 ? 'Type to add another email' : 'Enter email'}
                                isMulti
                                blackStyle
                                createable
                                color="black"
                                chipBackground="white"
                                className="md:mr-2 bg-navy-lightest w-full mb-4"
                                onChange={(value: any) => {
                                    if (!value || value.length === 0) {
                                        setEmails([]);
                                        return;
                                    }

                                    const users = value.map((item: any) => {
                                        return item.value;
                                    });

                                    setEmails(users);
                                }}
                            />
                        )}

                        {errorMessage && (
                            <div className="bg-red-200 border border-red-600 rounded py-2 px-4 my-4 mt-2">
                                <span className="text-center text-red-600">{errorMessage}</span>
                            </div>
                        )}
                    </div>
                </form>
            </>
        </Window>
    );
};

export default BulkAddUsersWindow;
