import React, { useEffect, useRef } from 'react';

import { ReactComponent as Copy } from '../../../../../../assets/images/copy/black.svg';
import { useGetPreviewLinkQuery } from '../../../../../../generated/graphql';
import { handleFetchError } from '../../../../../../shared/helpers';
import Input, { InputType } from '../../../../../../shared/Inputs';
import Loading from '../../../../../../shared/Loading';
import Window from '../../../../../../shared/Window';

type PreviewLinkWindowProps = {
    dealID: string;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
};

const PreviewLinkWindow = ({ dealID, setShow }: PreviewLinkWindowProps) => {
    const editableInputRef = useRef<HTMLInputElement>(null);
    const readInputRef = useRef<HTMLInputElement>(null);

    const { data, error, loading } = useGetPreviewLinkQuery({
        variables: {
            dealID
        },
        fetchPolicy: 'network-only'
    });

    useEffect(() => {
        handleFetchError('Failed to generate the link', error, data?.deal);
    }, [data, error]);

    const copyToClipboard = (inputType: string) => {
        if (data?.deal.__typename !== 'Deal' || !data.deal.preview) return;

        if (inputType === 'readOnly') {
            readInputRef.current?.select();
        } else if (inputType === 'editable') {
            editableInputRef.current?.select();
        }

        document.execCommand('copy');
    };

    if (loading)
        return (
            <Window title="Send Preview" width="w-1/3" setShow={setShow}>
                <Loading />
            </Window>
        );

    return (
        <Window
            title="Send Preview"
            titleCenter
            width="w-1/3"
            primaryBtn
            primaryBtnText="Done"
            onPrimaryBtnClick={() => setShow(false)}
            setShow={setShow}
        >
            <div className="p-4 pt-2 w-full md:mt-0 mt-8">
                <p className="text-gray-600 font-medium text-sm mb-0.5">Read Only Link</p>
                <div className="flex items-center justify-center gap-2">
                    <Input
                        type={InputType.text}
                        ref={readInputRef}
                        readOnly
                        value={data?.deal.__typename === 'Deal' && data.deal.preview}
                        className="flex-1 w-full"
                    />
                    <Copy
                        onClick={() => copyToClipboard('readOnly')}
                        className="text-newTeal-main stroke-current h-12 w-12 p-3 cursor-pointer rounded border border-navy-light hover:border-newTeal-main transition-all duration-150"
                    />
                </div>
                <p className="text-gray-600 font-medium text-sm mb-0.5 mt-4">Editable Link</p>
                <div className="flex items-center justify-center gap-2">
                    <Input
                        type={InputType.text}
                        ref={editableInputRef}
                        readOnly
                        value={window.location.href}
                        className="flex-1 w-full"
                    />
                    <Copy
                        onClick={() => copyToClipboard('editable')}
                        className="text-newTeal-main stroke-current h-12 w-12 p-3 cursor-pointer rounded border border-navy-light hover:border-newTeal-main transition-all duration-150"
                    />
                </div>
            </div>
        </Window>
    );
};

export default PreviewLinkWindow;
