import React, { useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';

import NavContainer from './components/NavContainer';
import NavIcon from './components/NavIcon';
import Profile from './components/Profile';
import { ReactComponent as ChevronLeft } from '../../assets/images/chevron/left/white.svg';
import { ReactComponent as Cross } from '../../assets/images/cross/white.svg';
import { ReactComponent as Menu } from '../../assets/images/menu/white.svg';
import Logo from '../../assets/images/newLogo/pink.png';
import {
    GetLayoutUserInfoQuery,
    useGetDealCardDetailsQuery,
    useGetNotificationsLazyQuery,
    useNotificationsQuery
} from '../../generated/graphql';
import NewDealWindow from '../../pages/Deals/NewDealWindow';
import NewUnitWindow from '../../pages/Units/NewUnitWindow';
import useGetNavbarLinks, { Link } from '../../shared/hooks/useGetNavbarLinks';

type SideBarProps = {
    visible: boolean;
    setVisible: React.Dispatch<React.SetStateAction<boolean>>;
    data?: GetLayoutUserInfoQuery;
};

const SideBar = ({ visible, setVisible, data }: SideBarProps) => {
    const location = useLocation();

    const [fetchNotifications] = useGetNotificationsLazyQuery();
    const { data: dealsData } = useGetDealCardDetailsQuery({
        variables: {
            limit: 1
        }
    });
    const { data: notificationsData } = useNotificationsQuery({
        // unread notification count
        variables: {
            compact: true
        },
        fetchPolicy: 'network-only'
    });

    const pluralDeal = () => {
        if (dealsData?.deals) {
            return 'My Deals';
        }
    };

    const notificationCount = notificationsData?.notifications ? notificationsData.notifications.total : 0;

    const userType = data?.user.__typename === 'User' ? data.user.type : '';

    const Links: Link[] = useGetNavbarLinks(userType, notificationCount, fetchNotifications, pluralDeal);

    const [showNewDealWindow, setShowNewDealWindow] = useState<boolean>(false);
    const [showNewUnitWindow, setShowNewUnitWindow] = useState<boolean>(false);

    return (
        <>
            {showNewDealWindow && <NewDealWindow setShow={setShowNewDealWindow} />}
            {showNewUnitWindow && <NewUnitWindow setShow={setShowNewUnitWindow} />}
            <div
                data-testid="layout--sidebar"
                className={`bg-navy-main left-0 h-full w-auto min-h-screen transform z-40`}
            >
                {/* Mobile Close */}
                <button
                    data-testid="layout--sidebar-close"
                    className="absolute top-0 right-0 mr-2 mt-2 p-4 md:hidden focus:outline-none"
                    onClick={() => setVisible((visible) => !visible)}
                >
                    <Cross className="w-7 h-7" />
                </button>
                {/* Header */}
                <div
                    className={`hidden px-5 w-full md:flex flex-row justify-between h-20 content-between items-center border-b border-newTeal-main`}
                >
                    <img src={Logo} alt="Logo" className="h-6" />
                    <div
                        className="cursor-pointer  flex flex-row items-center navCloseButton mr-0"
                        onClick={() => setVisible(!visible)}
                    >
                        <ChevronLeft className="w-6 chevron" />
                        <Menu className="w-6 hamburger" />
                    </div>
                </div>
                {!data ? (
                    <div className="mt-4">
                        <NavContainer className="">
                            <NavLink
                                to={`/login?prev=${location.pathname}`}
                                exact={true}
                                className="w-full bg-gray-800 flex items-center justify-start px-4 h-12 hover:bg-gray-900"
                                activeClassName="bg-gray-900 border-l-2 border-newTeal-main"
                                onClick={() => {
                                    if (window.innerWidth <= 768) {
                                        setVisible(!visible);
                                    } else return;
                                }}
                            >
                                {/* Icon */}
                                <NavIcon routeName="Login" />
                                {/* Link */}
                                <p className="text-white text-base font-medium">Login</p>
                            </NavLink>
                            <NavLink
                                to={`/signup?prev=${location.pathname}`}
                                exact={true}
                                className="w-full bg-gray-800 flex items-center justify-start px-4 h-12 hover:bg-gray-900"
                                activeClassName="bg-gray-900 border-l-2 border-newTeal-main"
                                onClick={() => {
                                    if (window.innerWidth <= 768) {
                                        setVisible(!visible);
                                    } else return;
                                }}
                            >
                                {/* Icon */}
                                <NavIcon routeName="Create Account" />
                                {/* Link */}
                                <p className="text-white text-base font-medium">Create Account</p>
                            </NavLink>
                        </NavContainer>
                    </div>
                ) : (
                    <div>
                        <NavContainer className="mb-6">
                            {/* Profile */}
                            <div className="flex pt-6 px-4 justify-start items-center w-full">
                                <Profile
                                    name={
                                        data?.user.__typename === 'User' && data.user?.organisation.name
                                            ? data.user.organisation.name
                                            : ''
                                    }
                                    src={data?.user.organisation.avatar}
                                />
                                <div className="text-left ml-3">
                                    <h1 className="text-lg text-white font-semibold">
                                        {data?.user.__typename === 'User' &&
                                            `${data.user?.firstName ?? ''} ${data.user?.lastName ?? ''}`}
                                    </h1>
                                    <div>
                                        <h2 className="text-white md:inline text-base">
                                            {data?.user.__typename === 'User' && data.user?.organisation.name}
                                        </h2>
                                    </div>
                                </div>
                            </div>
                        </NavContainer>
                        {userType !== 'TENANT' && (
                            <NavContainer className="mb-8">
                                {/* Main Buttons */}
                                <div className="w-full flex flex-col bg-gray-750 py-2 border-t border-b border-gray-725">
                                    {/* New Deal */}
                                    <div
                                        className="flex justify-start items-center h-12 hover:bg-gray-900 cursor-pointer px-4 h-12"
                                        onClick={() => {
                                            setShowNewDealWindow(true);
                                        }}
                                    >
                                        <NavIcon routeName="New Deal" />
                                        <p className="text-white font-medium text-base">Start New Deal</p>
                                    </div>
                                    {/* New Space */}
                                    <div
                                        className="flex justify-start items-center h-12 hover:bg-gray-900 cursor-pointer px-4 h-12"
                                        onClick={() => {
                                            setShowNewUnitWindow(true);
                                        }}
                                    >
                                        <NavIcon routeName="Get Ready" />

                                        <p className="text-white font-medium text-base">Add New Space</p>
                                    </div>
                                </div>
                            </NavContainer>
                        )}
                        {/* Main Links */}
                        <NavContainer>
                            {Links.filter((link) => link.type === 'main').map((link, i) => {
                                return (
                                    <NavLink
                                        to={link.href}
                                        exact={true}
                                        className="w-full bg-gray-800 flex items-center justify-start px-4 h-12"
                                        activeClassName="bg-gray-900 border-l-2 border-newTeal-main"
                                        onClick={() => {
                                            if (window.innerWidth <= 768) {
                                                setVisible(!visible);
                                            } else return;
                                        }}
                                        onMouseEnter={() => {
                                            link.onHover && link.onHover!();
                                        }}
                                        key={i}
                                    >
                                        {/* Icon */}
                                        <NavIcon routeName={link.title} />
                                        {/* Link */}
                                        <p className="text-white text-base font-medium flex items-center justify-start">
                                            {link.title}
                                            {link.badge !== undefined && (
                                                <span className="bg-white text-gray-800 text-sm font-semibold rounded-full w-5 h-5 ml-2 flex items-center justify-center">
                                                    {link.badge}
                                                </span>
                                            )}
                                        </p>
                                    </NavLink>
                                );
                            })}
                        </NavContainer>
                        {/* Secondary Links */}
                        <NavContainer>
                            {Links.filter((link) => link.type === 'secondary').map((link, i) => {
                                return (
                                    <NavLink
                                        to={link.href}
                                        className="w-full bg-gray-800 flex items-center justify-start px-4 h-12"
                                        activeClassName="bg-gray-900 border-l-2 border-newTeal-main"
                                        onClick={() => {
                                            if (window.innerWidth <= 768) {
                                                setVisible(!visible);
                                            } else return;
                                        }}
                                        key={i}
                                    >
                                        {/* Icon */}
                                        <NavIcon routeName={link.title} />
                                        {/* Link */}
                                        <p className="text-white text-base font-medium">{link.title}</p>
                                    </NavLink>
                                );
                            })}
                        </NavContainer>
                    </div>
                )}
            </div>
        </>
    );
};

export default SideBar;
