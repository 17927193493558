import React from 'react';

import { AdminTaskType, GetDealUnitDetailsQuery } from '../../../../generated/graphql';
import File from '../File';

type FilesProps = {
    data: GetDealUnitDetailsQuery | undefined;
};

const Files = ({ data }: FilesProps) => {
    const noInfo = (
        <div className="p-3 bg-white rounded">
            <p className="text-sm text-newGray-darkish">
                No information to show yet. Remind the landlord to start filling out their details.
            </p>
        </div>
    );
    if (data?.deal.__typename !== 'Deal') return noInfo;

    const tasks = data.deal.unit?.tasks?.filter(
        (task) => task.task.type === AdminTaskType.File && task.value && task.value?.__typename === 'File'
    );

    if ((!tasks || tasks.length === 0) && data.deal.unit?.files.length === 0) return noInfo;

    const tasksID = tasks
        ?.map((task) => task.value?.__typename === 'File' && task?.value._id)
        .filter((el) => el !== null);

    return (
        <div className="rounded flex flex-col gap-0.5 overflow-hidden">
            {tasks?.map((task) => {
                if (task.value?.__typename !== 'File') return null;

                return (
                    <File
                        key={task._id}
                        name={task.task.title ?? ''}
                        organisation={
                            data.deal?.__typename === 'Deal' && data.deal.unit
                                ? data.deal.unit?.organisation.name
                                : 'Landlord'
                        }
                        url={task.value.url}
                        date={new Date(task.edited)}
                    />
                );
            })}
            {data.deal.unit?.files.map((file) => {
                if (tasksID?.includes(file._id)) return null;
                return (
                    <File
                        key={file._id}
                        name={file.name}
                        organisation={
                            data.deal?.__typename === 'Deal' && data.deal.unit
                                ? data.deal.unit?.organisation.name
                                : 'Landlord'
                        }
                        url={file.url}
                        date={new Date(file.created)}
                    />
                );
            })}
        </div>
    );
};

export default Files;
