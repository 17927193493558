import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import Header from './../../layout/Header';
import Logo from '../../assets/images/logo/kato.svg';
import { ReactComponent as Svg } from '../../assets/images/registration/typeOfUser.svg';
import { useCreateUserMutation, UserType } from '../../generated/graphql';
import { useQuery } from '../../shared/helpers';

type LocationState = {
    firstName: string;
    lastName: string;
    company: string;
    type: string;
    mobile: string;
    email: string;
    password: string;
};

const _UserType = () => {
    const [error, setError] = useState<string | null>(null);
    const [createUser] = useCreateUserMutation();
    const location = useLocation<LocationState>();
    const [showHeader, setShowHeader] = useState<boolean>(true);
    const history = useHistory();
    const query = useQuery();

    const login = query.get('login') === 'true';
    const previous = query.get('prev');

    if (!location.state) {
        history.push('/signup');
        return <></>;
    }

    const { firstName, lastName, company, mobile, email, password } = location.state;

    const handleSubmit = async (type: string) => {
        let userType: UserType;
        switch (type) {
            case 'LANDLORD':
                userType = UserType.Landlord;
                break;
            case 'TENANT':
                userType = UserType.Tenant;
                break;
            case 'AGENT':
                userType = UserType.Agent;
                break;
            default:
                setError('Incorrect user type. Must be either LANDLORD or TENANT or AGENT');
                return;
        }

        const { errors } = await createUser({
            variables: {
                firstName,
                lastName,
                type: userType,
                company,
                mobile,
                email,
                password,
                login: login
            }
        });

        if (errors) {
            if (errors[0].extensions?.code === 'USER_EXISTS_EMAIL') {
                return history.push('/login', {
                    email,
                    error: `405: User already exists. Please login.`
                });
            } else if (errors[0].extensions?.message) {
                setError(errors[0]?.extensions?.message);
            } else {
                setError(errors[0]?.message);
            }
            return;
        }

        history.push(previous || '/');
    };

    return (
        <>
            <Header visible={showHeader} setVisible={setShowHeader}>
                <img src={Logo} alt="Logo" className="h-6 ml-6" />
            </Header>
            <div className="w-full h-screen flex flex-col items-center justify-center -mt-20">
                <Svg className={`md:w-1/5 w-3/5 h-auto mb-5`} />
                <h1 className="text-xl text-center font-semibold">{'Hey ' + firstName}</h1>
                <p className="text-newGray-main font-semibold">What best describes you?</p>
                <div className="flex flex-col md:flex-row items-start justify-center mt-6">
                    <div className="m-5 w-64">
                        <div
                            onClick={() => handleSubmit('LANDLORD')}
                            className="bg-navy-lightest cursor-pointer flex flex-col items-center justify-center hover:shadow-md hover:scale-105 transform font-semibold py-4 rounded transition duration-200 hover:bg-newTeal-main"
                        >
                            Landlord
                        </div>
                        <p className="p-2 mt-3 text-sm text-newGray-main font-semibold text-center">
                            You are the building owner and wish to have them ready to negotiate on Kato.
                        </p>
                    </div>
                    <div className="m-5 w-64">
                        <div
                            onClick={() => handleSubmit('TENANT')}
                            className="bg-navy-lightest cursor-pointer flex flex-col items-center justify-center hover:shadow-md hover:scale-105 transform font-semibold w-64 py-4 rounded transition duration-200 hover:bg-newTeal-main"
                        >
                            Tenant
                        </div>
                        <p className="p-2 mt-3 text-sm text-newGray-main font-semibold text-center">
                            You are a building occupier and have been invited to negotiate a deal on Kato.
                        </p>
                    </div>
                    <div className="m-5 w-64">
                        <div
                            onClick={() => handleSubmit('AGENT')}
                            className="bg-navy-lightest cursor-pointer flex flex-col items-center justify-center hover:shadow-md hover:scale-105 transform font-semibold py-4 rounded transition duration-200 hover:bg-newTeal-main"
                        >
                            Agent
                        </div>
                        <p className="p-2 mt-3 text-sm text-newGray-main font-semibold text-center">
                            You represent a building owner or occupier and wish to advise them on Kato.
                        </p>
                    </div>
                </div>

                {error && (
                    <div className="bg-red-200 border border-red-600 rounded w-full py-2 px-4 mt-4">
                        <span className="text-center text-red-600">{error}</span>
                    </div>
                )}
            </div>
        </>
    );
};

export default _UserType;
