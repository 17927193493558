import React from 'react';

import NoUnitsImage from '../../assets/images/noData/spaces.png';
import { AddButton } from '../../shared/add-button';

type NoUnitsProps = {
    setShowNewUnitWindow: React.Dispatch<React.SetStateAction<boolean>>;
};

const NoUnits = ({ setShowNewUnitWindow }: NoUnitsProps) => {
    return (
        <div className="h-full w-full flex flex-col items-center justify-center">
            <div
                className="h-64 w-64 bg-no-repeat bg-contain"
                style={{
                    backgroundImage: `url(${NoUnitsImage})`
                }}
            />
            <div className="flex flex-col items-center text-newGray-darkish text-center">
                <p className="font-semibold mt-4">Add your first Space</p>
                <p className="text-sm mt-1">
                    Select the button below to start building out <br /> your portfolio on{' '}
                    <span className="font-semibold">
                        <i>K</i>ato
                    </span>
                </p>
            </div>
            <AddButton
                onClick={() => {
                    setShowNewUnitWindow(true);
                }}
                height="10"
                className={
                    'pr-2 mt-3 bg-newTeal-light text-newGray-darkest hover:text-white hover:bg-newTeal-main border-none w-40'
                }
            >
                New Space
            </AddButton>
        </div>
    );
};

export default NoUnits;
