import React, { useContext } from 'react';
import ReactTooltip from 'react-tooltip';

import { ReactComponent as Check } from '../../../assets/images/check/circle/white.svg';
import { ReactComponent as Send } from '../../../assets/images/send/black.svg';
import { DealSendType, DealState } from '../../../generated/graphql';
import DealHeaderContext from '../../pages/Deals/DealHeader/DealHeaderContext';
import { isCompletedDeal, isLandlordSide } from '../helpers';
import MobileWindow from '../MobileWindow';

const SendDealWindow = () => {
    const { setShowShareWindow, setShareWindowType, setShowSendWindow, detailsData, canSend, deal, currentParty } =
        useContext(DealHeaderContext);

    const isCompleted = isCompletedDeal(deal?.state);
    const isLandlordOrAdminSide = isLandlordSide(currentParty);

    const doSendDeal = (type: DealSendType) => {
        setShareWindowType(type);
        setShowSendWindow(true);
    };
    const tenantState = detailsData?.deal.state === DealState.Tenant;

    return (
        <>
            {setShowShareWindow && (
                <MobileWindow setShow={setShowShareWindow}>
                    <div className="border-t border-newGray-main w-full mb-4" />
                    {isCompleted ? (
                        <div
                            className="font-medium flex flex-row items-center mr-8"
                            onClick={() => doSendDeal(DealSendType.Other)}
                        >
                            {' '}
                            <Send className="w-5 h-5 mr-5" />
                            Share HoTs
                        </div>
                    ) : (
                        <>
                            <div
                                className={`font-medium flex flex-row items-center ${isLandlordOrAdminSide && 'mr-10'}`}
                                onClick={() =>
                                    canSend &&
                                    (tenantState && !isLandlordOrAdminSide
                                        ? doSendDeal(DealSendType.Landlord)
                                        : !tenantState && isLandlordOrAdminSide && doSendDeal(DealSendType.Tenant))
                                }
                                data-border-color={'white'}
                                data-tip={
                                    !isLandlordOrAdminSide
                                        ? !tenantState
                                            ? 'The Terms are currently with the counterparty.'
                                            : 'You must action all the clauses before sending to counterparty.'
                                        : tenantState
                                        ? 'The Terms are currently with the counterparty.'
                                        : 'You must action all the clauses before sending to counterparty.'
                                }
                            >
                                {' '}
                                <Send className="w-5 h-5 mr-5" />
                                Send to {isLandlordOrAdminSide ? 'Tenant' : 'Counterparty'}
                            </div>
                            <div className="border-t border-newGray-main w-full mb-4 mt-4" />
                            <div
                                className="font-medium flex flex-row mr-6 items-center"
                                onClick={() => {
                                    setShareWindowType(DealSendType.Approver);
                                    setShowSendWindow(true);
                                }}
                            >
                                {' '}
                                <Check className="w-5 h-5 font-semibold mr-5" />
                                Send for Approval
                            </div>
                        </>
                    )}
                </MobileWindow>
            )}
            {(!canSend || (!tenantState && !isLandlordOrAdminSide) || (tenantState && isLandlordOrAdminSide)) && (
                <ReactTooltip
                    backgroundColor="black"
                    textColor="white"
                    effect="solid"
                    place="bottom"
                    clickable={false}
                    borderColor="white"
                />
            )}
        </>
    );
};

export default SendDealWindow;
