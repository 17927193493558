import { useApolloClient } from '@apollo/client';
import axios from 'axios';
import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';

import { ReactComponent as Cloud } from '../../../../assets/images/cloud/black.svg';
import { ReactComponent as Cross } from '../../../../assets/images/cross/black.svg';
import { ReactComponent as External } from '../../../../assets/images/external/black.svg';
import {
    DocType,
    GetDealTenantDetailsDocument,
    useGetDealUnitDetailsQuery,
    useTenantActionStageThreeFileMutation,
    useUploadDocumentMutation
} from '../../../../generated/graphql';
import ConfirmDeleteFileWindow from '../../../../pages/Units/Unit/ConfirmDeleteFileWindow';
import Loading from '../../../../shared/Loading';

type DropzoneProps = {
    dealID: string;
    unitID: string;
    setDidUpload: React.Dispatch<React.SetStateAction<boolean>>;
};

const Dropzone = ({ dealID, unitID, setDidUpload }: DropzoneProps) => {
    const [files, setFiles] = useState<File[]>([]);
    const client = useApolloClient();
    const { data, refetch } = useGetDealUnitDetailsQuery({
        variables: { dealID }
    });
    const [stage3File, { loading: stage3FileLoading }] = useTenantActionStageThreeFileMutation();

    const [fileID, setFileID] = useState<string | null>(null);
    const [showFileDeleteWindow, setShowFileDeleteWindow] = useState<boolean>(false);

    const [documentCreate, { error: documentCreateError }] = useUploadDocumentMutation();

    const updateCache = (fileName: string, fileUrl: string, fileId?: string) => {
        const cachedFile = {
            __typename: 'File',
            _id: fileId ?? new Date().toTimeString(),
            name: fileName,
            url: fileUrl
        };

        client.cache.modify({
            id: client.cache.identify({
                __typename: 'Unit',
                _id: dealID
            }),
            fields: {
                files(existingFiles = []) {
                    return [...existingFiles, cachedFile];
                }
            }
        });

        client.cache.modify({
            id: client.cache.identify({
                __typename: 'UnitTask',
                _id: '3'
            }),
            fields: {
                value() {
                    return cachedFile;
                }
            }
        });
    };

    const upload = async (file: File) => {
        const res = await documentCreate({
            variables: {
                type: DocType.Deal,
                filename: file.name,
                filetype: file.type,
                frontend: true,
                id: dealID,
                taskID: '3'
            },
            onCompleted: () => refetch()
        });

        if (documentCreateError) return;

        const documentUrl = res?.data?.docCreate.url ?? '';
        const signedUrl = res.data?.docCreate.signedUrl ?? '';

        await axios.put(signedUrl, file, {
            headers: {
                'Content-Type': file.type,
                'Access-Control-Allow-Origin': '*'
            }
        });
        await stage3File({
            variables: {
                dealID,
                file: {
                    name: file.name,
                    url: documentUrl
                }
            },
            refetchQueries: [
                {
                    query: GetDealTenantDetailsDocument,
                    variables: {
                        dealID
                    }
                }
            ]
        });
        updateCache(file.name, documentUrl);
    };

    const onDrop = useCallback(
        (acceptedFiles) => {
            acceptedFiles.map((file: File) => {
                const filesList = files;
                filesList && filesList.push(file);
                setFiles(filesList);
                upload(file);
                setDidUpload(true);
            });
        },
        [files]
    );

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop
    });

    return (
        <>
            {showFileDeleteWindow && fileID && (
                <ConfirmDeleteFileWindow
                    type={DocType.Deal}
                    fileId={fileID}
                    unitId={unitID}
                    dealId={dealID}
                    setWindow={setShowFileDeleteWindow}
                />
            )}
            <div
                className="bg-newTeal-light cursor-pointer border-2 border-dashed border-newTeal-main rounded-md h-28  mb-2 flex flex-col justify-center items-center"
                {...getRootProps()}
            >
                <div className="rounded-full bg-newTeal-main w-8 h-8 flex items-center mb-2">
                    <Cloud className="w-6 h-6 m-auto" style={{ color: 'white' }} />
                </div>
                <input {...getInputProps()} />
                {isDragActive ? (
                    <p className="text-xxs text-newTeal-main ">Drop the files here ...</p>
                ) : (
                    <p className="text-xxs text-newTeal-main ">Drag and drop files</p>
                )}
                <div className="text-xxs text-white rounded-md bg-newTeal-main px-9 mt-2 py-1">Upload</div>
            </div>
            {stage3FileLoading ? (
                <Loading />
            ) : (
                data?.deal.details.three?.files?.map((file) => (
                    <div className="flex flex-row items-center w-full mt-1 pr-2 ml-1">
                        <div className="flex flex-col text-xxs w-full">
                            <div className="flex flex-row items-center hover:bg-navy-lightest justify-between w-full">
                                <div className="flex flex-row">
                                    <a
                                        href={file.url}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="flex-1 truncate"
                                    >
                                        <External className="w-3 h-3 mr-2" />
                                    </a>
                                    <p>{file.name}</p>
                                </div>
                                <Cross
                                    onClick={() => {
                                        setFileID(file._id);
                                        setShowFileDeleteWindow(true);
                                        setDidUpload(true);
                                    }}
                                    className="w-2 h-2"
                                />
                            </div>
                        </div>
                    </div>
                ))
            )}
        </>
    );
};

export default Dropzone;
