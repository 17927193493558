import React, { useEffect, useState } from 'react';

// Desktop Shared Components
import DealActorsAddMemberButton from './DealActorsAddMemberButton';
import DealActorsLastActivity from './DealActorsLastActivity';
import DealActorsList from './DealActorsList';
import DealActorsRemoveTenantButton from './DealActorsRemoveTenantButton';
import DealActorsWaitingApproval from './DealActorsWaitingApproval';
import DealActorsWindows from './DealActorsWindows';
import { ReactComponent as Users } from '../../../../../../assets/images/users/black.svg';
import {
    DealActorFragment,
    DealApprovalType,
    DealRoundType,
    DealState,
    DealUserParty,
    User
} from '../../../../../../generated/graphql';
import { Button } from '../../../../../../shared/button';
import { isCompletedDeal } from '../../../../../../v2/common/helpers';
type DealActorsMobileProps = {
    dealID: string;
    approvalTenant?: DealApprovalType | null;
    approvalLandlord?: DealApprovalType | null;
    dealRound?: DealRoundType | null;
    landlordOrganization: string;
    tenantOrganization: string;
    currentParty?: false | DealUserParty | null | undefined;
    state?: DealState;
    landlords: DealActorFragment[];
    organisationID?: string;
    tenants: DealActorFragment[];
    tenantID?: string;
    tenantPendingMembers?: string[];
    showTenantPendingMembersWindow?: boolean;
    setShowTenantPendingMembersWindow?: React.Dispatch<React.SetStateAction<boolean>>;
    setShowingTenantPending?: React.Dispatch<React.SetStateAction<boolean>>;
};

const DealActorsMobile = ({
    dealID,
    dealRound,
    landlordOrganization,
    tenantOrganization,
    approvalTenant,
    approvalLandlord,
    currentParty,
    state,
    landlords,
    organisationID,
    tenants,
    tenantID,
    tenantPendingMembers,
    showTenantPendingMembersWindow,
    setShowTenantPendingMembersWindow,
    setShowingTenantPending
}: DealActorsMobileProps) => {
    const [isOpen, setOpen] = useState<boolean>(false);
    const [type, setType] = useState<'Landlord' | 'Tenant'>('Landlord');
    const [showAddUserWindow, setShowAddUserWindow] = useState<boolean>(false);
    const [showRemoveUserWindow, setShowRemoveUserWindow] = useState<boolean>(false);
    const [user, setUser] = useState<User | null>(null);
    const [showEmailUserWindow, setShowEmailUserWindow] = useState<boolean>(false);
    const [userEmail, setUserEmail] = useState<string | null>(null);
    const [showRemoveTenantWindow, setShowRemoveTenantWindow] = useState<boolean>(false);

    const [uniqueLandlords, setUniqueLandlords] = useState<DealActorFragment[]>([]);
    const [uniqueTenants, setUniqueTenants] = useState<DealActorFragment[]>([]);

    const isCompleted = isCompletedDeal(state);

    useEffect(() => {
        if (currentParty && currentParty.toLowerCase() === 'landlord') {
            setType('Landlord');
        } else {
            setType('Tenant');
        }
    }, [currentParty]);

    useEffect(() => {
        if (tenants) {
            const unique = [...new Set(tenants.map((tenant) => tenant._id))];
            const unTenants = unique.map((id) => tenants.find((tenant) => tenant._id === id));
            unTenants && setUniqueTenants(unTenants as DealActorFragment[]);
        }
        if (landlords) {
            const unique = [...new Set(landlords.map((landlord) => landlord._id))];
            const unLandlords = unique.map((id) => landlords.find((landlord) => landlord._id === id));
            unLandlords && setUniqueLandlords(unLandlords as DealActorFragment[]);
        }
    }, [tenants, landlords]);

    return (
        <>
            <DealActorsWindows
                dealID={dealID}
                organisationID={type === 'Landlord' ? organisationID : tenantID}
                userID={user?._id ?? ''}
                activated={!!user?.activated}
                type={type}
                currentUserParty={currentParty || DealUserParty.Other}
                tenantPendingMembers={tenantPendingMembers}
                userEmail={userEmail}
                setUserEmail={setUserEmail}
                showAddUserWindow={showAddUserWindow}
                setShowAddUserWindow={setShowAddUserWindow}
                showRemoveUserWindow={showRemoveUserWindow}
                setShowRemoveUserWindow={setShowRemoveUserWindow}
                showEmailUserWindow={showEmailUserWindow}
                setShowEmailUserWindow={setShowEmailUserWindow}
                showRemoveTenantWindow={showRemoveTenantWindow}
                setShowRemoveTenantWindow={setShowRemoveTenantWindow}
                showTenantPendingMembersWindow={showTenantPendingMembersWindow}
                setShowTenantPendingMembersWindow={setShowTenantPendingMembersWindow}
                setShowingTenantPending={setShowingTenantPending}
            />
            {/* Trigger Button */}
            <div className={`relative w-1/2 `}>
                <Button
                    className="bg-navy-lightest flex flex-col justify-center rounded items-center w-full h-12"
                    onClick={() => setOpen(!isOpen)}
                    text={'xs'}
                >
                    <Users className="w-5 h-5 cursor-pointer " />
                    Team
                </Button>
            </div>
            <div>
                {isOpen && (
                    <>
                        {/* Backdrop */}
                        <div
                            className="fixed w-full h-full z-10 left-0 top-0"
                            onClick={() => {
                                setOpen(false);
                            }}
                        ></div>
                        {/* Window */}
                        <div className="absolute bg-white border rounded-b-lg md:rounded-none md:border-newTeal-main shadow-lg md:rounded z-20 left-0 md:mr-2 mr-40 md:mt-1 mt-8 md:mt-5 flex flex-col text-sm  overflow-hidden overflow-y-auto w-full">
                            <div>
                                {/* Landlord */}
                                <div
                                    className={`w-full pt-2 px-2 -mb-3 z-40 relative bg-navy-light flex justify-between items-center ${
                                        state?.toString().toLowerCase() === 'landlord' && 'border border-newTeal-main'
                                    }`}
                                >
                                    <div>
                                        <p className="font-semibold">Landlord</p>
                                    </div>
                                    {approvalLandlord && (
                                        <div className="border-l-2 border-r-2 border-white px-3">
                                            <DealActorsWaitingApproval
                                                state={state}
                                                type="Tenant"
                                                approval={approvalLandlord}
                                            />
                                        </div>
                                    )}
                                    {/* Last Activity */}
                                    <div className="mr-6">
                                        <DealActorsLastActivity type="Landlord" state={state} dealRound={dealRound} />
                                    </div>
                                </div>
                                <DealActorsList
                                    uniqueUsers={uniqueLandlords}
                                    currentParty={currentParty}
                                    type="Landlord"
                                    setShowRemoveUserWindow={setShowRemoveUserWindow}
                                    setUser={setUser}
                                    setShowEmailUserWindow={setShowEmailUserWindow}
                                    typeOfArray={'LandlordArray'}
                                />
                                {!isCompleted && (
                                    <DealActorsAddMemberButton
                                        type="Landlord"
                                        currentParty={currentParty}
                                        setShowAddUserWindow={setShowAddUserWindow}
                                    />
                                )}
                                {/* Tenant */}
                                <div
                                    className={`w-full px-2 pt-2 bg-navy-light  -mb-3 z-40 relative flex justify-between items-center ${
                                        state?.toString().toLowerCase() === 'tenant' && 'border border-newTeal-main'
                                    }`}
                                >
                                    <div>
                                        <p className="font-semibold">Tenant</p>
                                    </div>
                                    {approvalTenant && (
                                        <div className="border-l-2 border-r-2 border-white px-3">
                                            <DealActorsWaitingApproval
                                                state={state}
                                                type="Tenant"
                                                approval={approvalTenant}
                                            />
                                        </div>
                                    )}
                                    {/* Last Activity */}
                                    <div className="mr-6">
                                        <DealActorsLastActivity type="Tenant" state={state} dealRound={dealRound} />
                                    </div>
                                </div>
                                <DealActorsList
                                    uniqueUsers={uniqueTenants}
                                    currentParty={currentParty}
                                    type="Tenant"
                                    setShowRemoveUserWindow={setShowRemoveUserWindow}
                                    setUser={setUser}
                                    setShowEmailUserWindow={setShowEmailUserWindow}
                                    typeOfArray={'TenantArray'}
                                />
                                {!isCompleted && (
                                    <DealActorsAddMemberButton
                                        type="Tenant"
                                        currentParty={currentParty}
                                        setShowAddUserWindow={setShowAddUserWindow}
                                    />
                                )}
                                <DealActorsRemoveTenantButton
                                    tenantID={tenantID}
                                    type="Tenant"
                                    currentParty={currentParty}
                                    setShowRemoveTenantWindow={setShowRemoveTenantWindow}
                                />
                            </div>
                        </div>
                    </>
                )}
            </div>
        </>
    );
};

export default DealActorsMobile;
