import { Form, Formik, useFormikContext } from 'formik';
import { noop } from 'lodash';
import React, { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';

import Contact, { ContactSchema, ContactType, ContactValues } from './Contact';
import {
    GetDealTenantDetailsDocument,
    UserType,
    useTenantActionStageTwoMutation
} from '../../../../../generated/graphql';
import { hasErrors } from '../../../../common/helpers';
import Checkbox from '../Checkbox';
import { ReadinessContext, STAGE } from '../context';
import Stage from '../Stage';

export type LawyerValues = {
    lawyer: ContactValues & { visible?: boolean };
};

const Lawyer = () => {
    const { details } = useContext(ReadinessContext);

    const lawyer = details?.two?.lawyer ?? {};

    const initialValues: LawyerValues = {
        lawyer: {
            name: lawyer.name,
            company: lawyer.company,
            email: lawyer.email,
            phone: lawyer.phone,
            visible: lawyer.visible ?? true
        }
    };

    const validationSchema = Yup.object().shape({
        lawyer: ContactSchema
    });

    return (
        <Formik validationSchema={validationSchema} initialValues={initialValues} onSubmit={noop} enableReinitialize>
            <LawyerForm edited={!!details?.two?.edited} />
        </Formik>
    );
};

type LawyerFormProps = {
    edited: boolean;
};

const LawyerForm = ({ edited }: LawyerFormProps) => {
    const { id } = useParams<{ id: string }>();
    const { isChanging, setStage, changeStage } = useContext(ReadinessContext);

    const { values, setFieldValue, dirty, validateForm } = useFormikContext<LawyerValues>();

    const [actionStage] = useTenantActionStageTwoMutation();

    const saveValues = async () => {
        if (!dirty && edited) {
            changeStage();
            return;
        }

        const isValid = !hasErrors<LawyerValues>(await validateForm(values));

        await actionStage({
            variables: {
                dealID: id,
                completed: isValid,
                lawyer: values.lawyer
            },
            onCompleted: changeStage,
            refetchQueries: () => [GetDealTenantDetailsDocument]
        });
    };

    useEffect(() => {
        if (isChanging) saveValues();
    }, [isChanging]);

    return (
        <Form className="bg-white pt-14 h-full w-full mx-auto flex flex-col items-start">
            <Stage
                stageNumber={2}
                title="Lawyer Details"
                nextStage="Next: Stage 3 - Team"
                onNextClick={() => {
                    setStage(STAGE.TEAM);
                }}
                requiredFieldsText
            >
                <p className="text-sm font-medium mt-2">Add Lawyer's contact details</p>
                <p className="text-xs mb-2">(or person reviewing the lease)</p>
                <Checkbox
                    name="lawyer.visible"
                    checked={!values.lawyer.visible}
                    onChange={() => setFieldValue('lawyer.visible', !values.lawyer.visible)}
                    label="Not using a Lawyer"
                />
                {values.lawyer.visible && <Contact contactType={ContactType.Lawyer} userType={UserType.Agent} />}
            </Stage>
        </Form>
    );
};

export default Lawyer;
