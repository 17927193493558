import { isEmpty } from 'lodash';
import React, { useContext, useRef, useState } from 'react';
import ReactTooltip from 'react-tooltip';
import Swal from 'sweetalert2';

import { ReactComponent as ChevronDown } from '../../../../assets/images/chevron/down/black.svg';
import { ReactComponent as ChevronUp } from '../../../../assets/images/chevron/up/black.svg';
import { ReactComponent as ShareIcon } from '../../../../assets/images/share/black.svg';
import {
    DealApprovalSendType,
    DealSendType,
    DealUserParty,
    GetDealDetailsNoHistoryDocument,
    useGetUserTypeQuery,
    UserType,
    useSendDealApprovalMutation
} from '../../../../generated/graphql';
import { DealContext } from '../../../../pages/Deals/Deal/context';
import EmailMultiInput from '../../../../shared/Inputs/EmailMultiInput';
import { isLandlordSide } from '../../../common/helpers';

type ShareProps = {
    currentUserParty: DealUserParty;
    type: DealSendType;
    setLoading: React.Dispatch<React.SetStateAction<boolean>>;
    close: () => void;
};

const Share = ({ currentUserParty, type, setLoading, close }: ShareProps) => {
    const [message, setMessage] = useState<string>('');
    const [showShare, setShowShare] = useState<boolean>(false);
    const [previewEmails, setPreviewEmails] = useState<string[]>([]);

    const { deal } = useContext(DealContext);
    const emailRef = useRef<HTMLInputElement>(null);
    const { data: userData } = useGetUserTypeQuery();

    const [shareDeal] = useSendDealApprovalMutation();

    const isLandlordOrAdminSide = isLandlordSide(currentUserParty);
    const handleConfirm = async () => {
        const email = emailRef.current?.value;

        const emails = isEmpty(email) ? previewEmails : email;

        const variables = {
            dealID: deal?._id,
            emails,
            message,
            additionalApproval: false,
            accountType: type === DealSendType.Tenant ? UserType.Tenant : UserType.Landlord
        };

        setLoading(true);

        await shareDeal({
            variables: {
                ...variables,
                type: DealApprovalSendType.Other,
                currentUserParty,
                currentUserEmail: userData?.user.email
            },
            refetchQueries: () => [GetDealDetailsNoHistoryDocument],
            onCompleted: close
        });
        Swal.fire({
            title: 'Success!',
            text: '',
            icon: 'success',
            iconColor: '#3CA48E',
            confirmButtonColor: '#3CA48E'
        });
        setLoading(false);
    };
    const pristine = isEmpty(previewEmails) || isEmpty(message);

    return (
        <div className={`mt-4 ${isLandlordOrAdminSide && 'border-b border-gray-600'}  pb-4`}>
            <div className="flex flex-row items-center mb-2 cursor-pointer" onClick={() => setShowShare(!showShare)}>
                <ShareIcon className="text-newTeal-main h-5 w-5 mr-4" />
                <p className="font-semibold text-xl">Share</p>
                <div className="bg-newTeal-main w-5 h-5 ml-2">
                    {showShare ? (
                        <ChevronUp className="text-white w-5 h-5" />
                    ) : (
                        <ChevronDown className="text-white w-5 h-5" />
                    )}
                </div>
            </div>
            {showShare && (
                <div className="w-full bg-navy-lightest rounded-lg py-4 xs:px-4 sm:px-8">
                    <div className="flex flex-row items-center">
                        <p className="text-lg text-newGray-darkish mb-2">To:</p>
                        <EmailMultiInput
                            setEmails={setPreviewEmails}
                            ref={emailRef}
                            name="email"
                            placeholder={previewEmails.length > 0 ? 'Type to add another email' : 'Enter email'}
                            className="bg-white text-newGray-darkish"
                            containerClassName="rounded bg-white text-newGray-darkish"
                            chipClassName="chip-download-modal"
                            chipIconClassName="chip-svg-download-modal"
                            chipCloseClassName="chip-close-download-modal"
                        />
                    </div>
                    <textarea
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        rows={10}
                        className="w-full p-2 h-40 text-newGray-darkishborder border-transparent focus:outline-none  rounded-lg "
                        placeholder="Send a message..."
                        style={{ resize: 'none' }}
                    ></textarea>
                    <button
                        className={`xs:w-full sm:w-1/4 ${
                            pristine ? 'bg-newGray-main' : 'bg-newTeal-main text-white cursor-pointer'
                        } font-semibold xs:h-12 sm:h-10 rounded-lg items-center justify-center flex mt-2 button `}
                        onClick={() => !pristine && handleConfirm()}
                        data-border-color={'white'}
                        data-tip={'You must enter a valid email and message before sharing the Terms.'}
                        data-for="share"
                    >
                        Share
                    </button>
                    {pristine && (
                        <ReactTooltip
                            backgroundColor="#484444"
                            textColor="white"
                            effect="solid"
                            place="bottom"
                            clickable={false}
                            id="share"
                        />
                    )}
                </div>
            )}
        </div>
    );
};

export default Share;
