import React from 'react';
import { useHistory } from 'react-router-dom';

import { Button } from '../../../../../../shared/button';

export type WindowFooterProps = {
    setShow?: React.Dispatch<React.SetStateAction<boolean>>;
    backTo?: string | null;
    nextTo?: string | null;
    nextText?: string;
    onNextClick?: () => void;
};
const WindowFooter = ({ setShow, backTo, nextTo, nextText, onNextClick }: WindowFooterProps) => {
    const history = useHistory();

    return (
        <div className="w-full p-2 flex justify-center items-center flex-wrap">
            <Button
                background="white"
                text="black"
                className="px-10 border-navy-light font-semibold w-full md:w-auto md:mr-2"
                onClick={() => {
                    if (setShow) setShow!(false);
                    if (backTo) history.push(backTo);
                }}
            >
                Back
            </Button>
            <Button
                background="newTeal-main"
                text="white"
                className={`px-10 font-semibold w-full md:w-auto mt-2 md:mt-0`}
                onClick={() => {
                    onNextClick && onNextClick();
                    if (!onNextClick && nextTo) history.push(nextTo);
                }}
            >
                {nextText ? nextText : 'Next'}
            </Button>
        </div>
    );
};

export default WindowFooter;
