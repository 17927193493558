import React, { useState } from 'react';
import { SubmitHandler } from 'react-hook-form';
import Switch from 'react-switch';

import { useUpdateIntercom } from './hooks/useUpdateIntercom';
import ProfileForm from './ProfileForm';
import { useGetUserProfileQuery } from '../../../generated/graphql';
import { useUpdateUserMutation } from '../../../generated/graphql';
import { Button } from '../../../shared/button';
import { useLogout } from '../../../shared/hooks/useLogout';
import Loading from '../../../shared/Loading';
import ChangePasswordForm from '../../../shared/Settings/ChangePasswordForm';
import DeleteProfileWindow from '../DeleteProfileWindow';
import PasswordCheckWindow from '../PasswordCheckWindow';

export interface IUserFormData {
    firstName: string;
    lastName: string;
    email: string;
    mobile: string;
}

const Profile = () => {
    // SECTION Queries and mutations
    const { data, loading, error, refetch } = useGetUserProfileQuery();
    const [updateUser, { error: updateError }] = useUpdateUserMutation();

    // SECTION Hooks
    const { logout, loading: logoutLoading } = useLogout();

    // Update intercom
    useUpdateIntercom(data);

    // SECTION States
    const [showPasswordCheckWindow, setShowPasswordCheckWindow] = useState(false);
    const [updatedUser, setUpdatedUser] = useState({});
    const [showDeleteProfileWindow, setShowDeleteProfileWindow] = useState(false);
    const [showPasswordForm, setShowPasswordForm] = useState(false);
    const [isUpdating, setUpdating] = useState<boolean>(false);

    const is2FAEnabled = data?.user.is2FAEnabled ?? false;
    const handleChange = async (checked: boolean) => {
        await updateUser({ variables: { is2FAEnabled: checked } });
        refetch();
    };

    // SECTION Form Handler
    const onSubmit: SubmitHandler<IUserFormData> = async ({ firstName, lastName, mobile, email }) => {
        const inputs = {
            firstName,
            lastName,
            email,
            mobile: mobile.replace(/\s/g, '')
        };

        const initialEmail = data?.user.email ?? '';

        if (email !== initialEmail) {
            setUpdatedUser(inputs);
            setShowPasswordCheckWindow(true);
        } else {
            await updateUser({
                variables: inputs
            });

            if (updateError) return;

            refetch();
            setUpdating(false);
        }
    };

    if (loading) return <Loading />;

    if (error) return null;

    return (
        <div className="w-full lg:w-4/5 p-6">
            {showDeleteProfileWindow && <DeleteProfileWindow setShow={setShowDeleteProfileWindow} />}
            {showPasswordCheckWindow && (
                <PasswordCheckWindow
                    setShowPasswordCheckWindow={setShowPasswordCheckWindow}
                    updatedUser={updatedUser}
                    setUpdating={setUpdating}
                />
            )}
            <h1 className="mb-4 font-semibold text-xl">Profile</h1>
            <ProfileForm
                defaultValues={data?.user}
                onSubmit={onSubmit}
                isUpdating={isUpdating}
                setUpdating={setUpdating}
            />

            {/* Change Password Section */}
            <div className="border-t border-b border-newGray-main mt-8 py-8 ">
                <div className="flex items-end justify-between">
                    <div>
                        <h3 className="text-lg font-semibold">Password</h3>
                        <p className="text-newGray-darkish mt-1 font-medium text-sm">
                            Change the password of your account
                        </p>
                    </div>
                    <Button
                        onClick={() => setShowPasswordForm(!showPasswordForm)}
                        text="black"
                        className="font-semibold underline text-sm whitespace-no-wrap ml-4 border-none"
                    >
                        {!showPasswordForm ? 'Change Password' : 'Cancel'}
                    </Button>
                </div>

                {showPasswordForm && <ChangePasswordForm setShowPasswordForm={(value) => setShowPasswordForm(value)} />}
            </div>
            <div className="py-8 border-b border-newGray-main">
                <div className="flex items-end justify-between">
                    <div>
                        <h3 className="text-lg font-semibold">Two Factor Authentication</h3>
                        <p className="text-newGray-darkish mt-1 font-medium text-sm">
                            Increase your account security with an email verification before you sign in
                        </p>
                    </div>
                    <Switch
                        onChange={() => handleChange(!is2FAEnabled)}
                        checked={is2FAEnabled}
                        onColor="#3CA48E"
                        offColor="#f5f5f5"
                        handleDiameter={20}
                        uncheckedIcon={
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    height: '100%',
                                    fontSize: 11,
                                    color: '#737373',
                                    paddingRight: 2,
                                    fontWeight: 900
                                }}
                            >
                                OFF
                            </div>
                        }
                        activeBoxShadow={'0 0 1px 1px white'}
                        checkedIcon={
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    height: '100%',
                                    fontSize: 11,
                                    color: 'white',
                                    paddingRight: 2,
                                    fontWeight: 900
                                }}
                            >
                                ON
                            </div>
                        }
                        offHandleColor="#737373"
                    />
                </div>
            </div>
            {/* Logout Section */}
            <div className="py-8 border-b border-newGray-main">
                <div className="flex items-end justify-between">
                    <div>
                        <h3 className="text-lg font-semibold">Logout</h3>
                        <p className="text-newGray-darkish mt-1 font-medium text-sm">
                            This will log you out of your account
                        </p>
                    </div>
                    <Button
                        onClick={logout}
                        text="black"
                        className="font-semibold underline text-sm whitespace-no-wrap ml-4 border-none"
                    >
                        {logoutLoading ? 'Logging out ...' : 'Logout'}
                    </Button>
                </div>
            </div>

            {/* Deactivate account Section */}
            <div className="py-8 ">
                <div className="flex items-end justify-between">
                    <div>
                        <h3 className="text-lg font-semibold">Deactivate</h3>
                        <p className="text-newGray-darkish mt-1 font-medium text-sm">
                            This will remove your entire account and remove access to your teams
                        </p>
                    </div>
                    <Button
                        onClick={() => setShowDeleteProfileWindow(true)}
                        text="black"
                        className="font-semibold underline text-sm whitespace-no-wrap ml-4 border-none"
                    >
                        Deactivate Account
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default Profile;
