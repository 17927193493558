import useOutsideClick from '@rooks/use-outside-click';
import React, { useEffect, useRef, useState } from 'react';

import EditHelpWindow from './EditHelpWindow';
import useMoveSection from './hooks/useMoveSection';
import InfoWindow from './InfoWindow';
import { ReactComponent as Down } from '../../assets/images/arrow/down/black.svg';
import { ReactComponent as Up } from '../../assets/images/arrow/up/black.svg';
import { ReactComponent as Edit } from '../../assets/images/edit/black.svg';
import { ReactComponent as InfoIcon } from '../../assets/images/info/black.svg';
import { ReactComponent as Plus } from '../../assets/images/plus/black.svg';
import { DealDetailsFragment, DealState, DealUserParty, useGetUserTypeQuery, UserType } from '../../generated/graphql';
import DealSettings from '../../pages/Deals/Deal/Header/components/DealSettings';
import { useUserType } from '../../v2/common/helpers';
import { Button } from '../button';

type SectionProps = {
    userType?: UserType | undefined;
    id: string;
    isReordering?: boolean;
    deal: DealDetailsFragment | null;
    length: number | undefined;
    index?: number;
    title: string;
    setOpenDetails: React.Dispatch<React.SetStateAction<{ section?: string; level2Group?: string } | null>>;
    setShow?: React.Dispatch<React.SetStateAction<boolean>>;
    children: React.ReactChild;
    className?: string;
    description?: string | null;
    rics?: string | null;
    setClauseReordering?: React.Dispatch<React.SetStateAction<boolean>>;
    setTitlesReordering?: React.Dispatch<React.SetStateAction<boolean>>;
};

const Section = ({
    id,
    title,
    children,
    index,
    rics,
    className,
    length,
    deal,
    userType,
    isReordering,
    setShow,
    description,
    setOpenDetails,
    setClauseReordering,
    setTitlesReordering
}: SectionProps) => {
    const moveSection = useMoveSection(deal, id);
    const [showInfoWindow, setShowInfoWindow] = useState<boolean>(false);
    const [showEditHelpWindow, setShowEditHelpWindow] = useState<boolean>(false);
    const [isOpen, setOpen] = useState<boolean>(false);

    // Get User Type
    const [type, setUserType] = useState<UserType | undefined>(undefined);
    const { data: typeData, loading: typeLoading, error: typeError } = useGetUserTypeQuery();
    useEffect(() => {
        if (typeData?.user.__typename === 'User') {
            setUserType(typeData.user.type);
        }
    }, [typeData, typeError, typeLoading]);

    const { isLandlordOrAdmin, isLandlordSide } = useUserType(type, deal?.currentUserParty);

    const wrapperRef = useRef();
    const outsideClick = () => {
        setOpen(false);
    };
    useOutsideClick(wrapperRef, outsideClick);

    return (
        <>
            {showInfoWindow && (
                <InfoWindow rics={rics} title={title} description={description} setShow={setShowInfoWindow} />
            )}
            {showEditHelpWindow && (
                <EditHelpWindow
                    setShow={setShowEditHelpWindow}
                    dealID={deal?._id}
                    parentID={''}
                    sectionID={id}
                    title={title}
                    description={description}
                    rics={rics}
                />
            )}

            <div className={`w-full rounded ${className}`} key={id}>
                <div className="w-full flex justify-between items-center">
                    <div className="w-full bg-white text-black font-semibold text-lg flex items-center">
                        <span>{title}</span>
                        {deal && deal.state === 'EDIT' && type === UserType.Admin ? (
                            <button
                                className="p-2 cursor-pointer mr-px focus:outline-none"
                                onClick={() => {
                                    setShowEditHelpWindow(true);
                                }}
                            >
                                <Edit className="h-5 w-6" />
                            </button>
                        ) : (
                            description && (
                                <InfoIcon
                                    className="w-6 h-6 cursor-pointer ml-2"
                                    onClick={() => {
                                        setShowInfoWindow(true);
                                    }}
                                />
                            )
                        )}
                    </div>
                    <div className="relative cursor-pointer">
                        {isReordering && index !== undefined && length !== undefined ? (
                            <div className="flex flex-row items-center content-center justify-center">
                                <button
                                    className={`cursor-pointer focus:outline-none mr-4 ${
                                        index === 0 && 'text-newGray-darkish cursor-not-allowed'
                                    }`}
                                    disabled={index === 0}
                                    onClick={() => moveSection(index, index - 1)}
                                >
                                    <Up className="w-6 h-6" />
                                </button>
                                <button
                                    className={`cursor-pointer mr-px focus:outline-none ${
                                        length === index + 1 && 'text-newGray-darkish cursor-not-allowed'
                                    }`}
                                    disabled={length === index + 1}
                                    onClick={() => moveSection(index, index + 1)}
                                >
                                    <Down className="w-6 h-6" />
                                </button>
                            </div>
                        ) : (
                            <></>
                        )}
                        <div className="flex flex-row gap-4">
                            {!isReordering &&
                                ((!userType && !deal) || //if not either have been passed (some pages dont need, then assume they can)
                                    (deal?.state && deal.state === DealState.Edit) ||
                                    deal?.state.toString() === deal?.currentUserParty?.toString() ||
                                    (deal?.state === DealState.Agreed &&
                                        deal.currentUserParty === DealUserParty.Landlord)) && (
                                    <Button
                                        onClick={() => {
                                            if (setShow && setOpenDetails) {
                                                setShow(true);
                                                setOpenDetails({
                                                    section: title
                                                });
                                            }
                                        }}
                                        text={'sm'}
                                        height={'9'}
                                        className="p-2 lg:px-4 font-semibold hover:border-navy-light hover:bg-navy-light bg-navy-lightestxs:w-8 sm:w-8 lg:w-28 justify-between items-center"
                                    >
                                        <Plus className="w-4 h-4" />
                                        <p className="xs:hidden sm:hidden lg:flex">Clause</p>
                                    </Button>
                                )}
                            {(isLandlordOrAdmin || isLandlordSide) && deal && !isReordering && (
                                <div ref={wrapperRef}>
                                    <DealSettings
                                        deal={deal}
                                        setTitlesReordering={setTitlesReordering}
                                        setClauseReordering={
                                            deal?.state === DealState.Complete ? undefined : setClauseReordering
                                        }
                                        isOpen={isOpen}
                                        setOpen={setOpen}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <div className="w-full bg-newTeal-main rounded mt-2" style={{ height: '1.5px' }}></div>
                <div>{children}</div>
            </div>
        </>
    );
};

export default Section;
