import React, { useMemo, useReducer, useState } from 'react';

import Task from './Task';
import { Task as UnitTask } from '../../../../../generated/graphql';
import { FileManageModal } from '../../../../../shared/file/FileManageModal';
import useMobileScreen from '../../../../../shared/hooks/useMobileScreen';

type TasksProps = {
    unitID: string;
    tasks: UnitTask[];
    expanded?: boolean;
    cardView: boolean;
};

export type TaskState = { [id: string]: string | null };
export type TaskAction = { id: string; value: string | null };

const Tasks = ({ expanded, cardView, tasks, unitID }: TasksProps) => {
    const isMobile = useMobileScreen();

    // Lift task value states up to parent component, so that users can fill all fields out
    // and then click the action button (previously this would clear all data not yet saved in other tasks)
    const [taskValues, setTaskValues] = useReducer(
        (state: TaskState, action: TaskAction) => ({
            ...state,
            [action.id]: action.value
        }),
        {}
    );

    const [selectedTaskID, setSelectedTaskID] = useState<string>();

    const selectedTaskFiles = useMemo(() => {
        const task = tasks.find((t) => t._id === selectedTaskID);

        if (task && task.value?.__typename === 'FilesValue') {
            return task.value.filesValue;
        }

        return [];
    }, [tasks, selectedTaskID]);

    return (
        <div
            className={`${isMobile ? 'w-full' : 'w-95 '} flex ${
                cardView ? 'flex-row flex-wrap' : 'flex-col rounded gap-0.5'
            }`}
        >
            {tasks.map((task) => (
                <Task
                    id={task._id}
                    index={task.task.index}
                    key={task._id}
                    unitID={unitID}
                    optional={task.task.optional}
                    title={task.task.title}
                    action={task.task.type}
                    applicable={task.applicable}
                    value={task.value}
                    expanded={expanded}
                    cardView={cardView}
                    taskValues={taskValues}
                    onUploadClick={setSelectedTaskID}
                    setTaskValues={setTaskValues}
                />
            ))}
            {selectedTaskID && (
                <FileManageModal
                    files={selectedTaskFiles}
                    unitID={unitID}
                    taskID={selectedTaskID}
                    setShow={() => setSelectedTaskID(undefined)}
                />
            )}
        </div>
    );
};

export default Tasks;
