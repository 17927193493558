import { Reference, StoreObject } from '@apollo/client';
import React, { useEffect, useState } from 'react';

import { AdminTaskType, UnitUse } from '../../../generated/graphql';
import { useDeleteTaskMutation, useUpdateTaskMutation } from '../../../generated/graphql';
import { humanizeString } from '../../../shared/helpers';
import Input, { InputType } from '../../../shared/Inputs/index';
import Select from '../../../shared/Inputs/Select';
import Loading from '../../../shared/Loading';
import { UseConditionToText } from '../../../shared/NewUnit/hooks/useGetUnitSelectData';
import { FixedTags } from '../../../shared/Tags';
import Window from '../../../shared/Window';

type EditTaskWindowProps = {
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
    title: string;
    index: number;
    weight: number;
    tags: string[];
    category?: string | null;
    id: string;
    type: AdminTaskType;
    optional: boolean;
    uses?: UnitUse[] | null;
};

const EditTaskWindow = ({
    setShow,
    title,
    index,
    weight,
    tags,
    category,
    id,
    type,
    optional,
    uses
}: EditTaskWindowProps) => {
    const [taskName, setTaskName] = useState<string>(title);
    const [taskWeight, setTaskWeight] = useState<string>(String(weight));
    const [taskIndex, setTaskIndex] = useState<string>(String(index));
    const [taskTags, setTaskTags] = useState<string[]>(tags);
    const [taskType, setTaskType] = useState<AdminTaskType>(type);
    const [associatedUses, setAssociatedUses] = useState<UnitUse[] | undefined | null>(uses);
    const [taskCategory, setTaskCategory] = useState<string | null>(null);
    const [taskOptional, setTaskOptional] = useState<boolean>(optional);
    const [formError, setFormError] = useState<string>('');

    useEffect(() => {
        if (!taskCategory && category) {
            //if not set, assume default or already set
            setTaskCategory(category);
        }
    }, [category, taskCategory]);

    let taskTypeLabel;
    switch (taskType) {
        case 'TEXT':
            taskTypeLabel = 'Entry of text';
            break;
        case 'BOOLEAN':
            taskTypeLabel = 'Confirm';
            break;
        case 'INTEGER':
            taskTypeLabel = 'Number';
            break;
        case 'FILE':
            taskTypeLabel = 'Upload';
            break;
        default:
            taskTypeLabel = 'Confirm';
    }

    const handleChangeTaskType = (id: string) => {
        switch (id) {
            case 'boolean':
                setTaskType(AdminTaskType.Boolean);
                break;
            case 'file':
                setTaskType(AdminTaskType.File);
                break;
            case 'integer':
                setTaskType(AdminTaskType.Integer);
                break;
            case 'text':
                setTaskType(AdminTaskType.Text);
                break;
            default:
                setTaskType(AdminTaskType.Boolean);
        }
    };

    const [deleteTask, { loading: deleteLoading, error: deleteError }] = useDeleteTaskMutation();

    const handleDeleteTask = async (id: string) => {
        await deleteTask({
            variables: {
                id: id
            },
            optimisticResponse: {
                adminTaskDelete: id
            },
            update(cache, { data }) {
                if (!data) return;

                cache.modify({
                    fields: {
                        tasks(existingTasks = [], { readField }) {
                            return existingTasks.filter(
                                (result: Reference | StoreObject | undefined) => readField('_id', result) !== id
                            );
                        }
                    }
                });
            }
        });
        if (deleteError) return;

        setShow(false);
    };

    const [updateTask, { loading: updateLoading, error: updateError }] = useUpdateTaskMutation();

    const handleUpdate = async () => {
        let activeErrors = false;
        let filteredTags = taskTags.filter((tag) => tag.substr(0, 9) !== 'category:');
        let mergedTags = [...filteredTags, ...[`category:${taskCategory}`]];

        if (taskName === '') {
            setFormError('Task name must not be empty');
            activeErrors = true;
        } else if (isNaN(+taskWeight) || taskWeight === '') {
            setFormError('Weight must be a valid number');
            activeErrors = true;
        } else if (isNaN(+taskIndex) || taskIndex === '') {
            setFormError('Index must be a valid number');
            activeErrors = true;
        } else if (+taskWeight > 10) {
            setFormError('Weight must not be bigger than 10');
            activeErrors = true;
        } else if (!taskCategory || taskCategory === '') {
            setFormError('A valid category must be selected');
            activeErrors = true;
        } else if (!associatedUses || associatedUses.length === 0) {
            setFormError('Associated uses must be selected');
            activeErrors = true;
        } else {
            setFormError('');
            activeErrors = false;
        }

        if (!activeErrors) {
            await updateTask({
                variables: {
                    id: id,
                    title: taskName,
                    index: +taskIndex,
                    weight: +taskWeight,
                    tags: mergedTags,
                    type: taskType,
                    optional: taskOptional,
                    associatedUses
                }
            });

            if (updateError) return;

            setShow(false);
        }
    };

    if (deleteLoading || updateLoading)
        return (
            <Window title="Edit Readiness Task" width="w-2/5" setShow={setShow}>
                <Loading />
            </Window>
        );

    return (
        <Window
            title="Edit Readiness Task"
            width="w-2/5"
            primaryBtn
            onPrimaryBtnClick={handleUpdate}
            secondaryBtn
            secondaryBtnText="Delete Task"
            onSecondaryBtnClick={() => handleDeleteTask(id)}
            setShow={setShow}
        >
            <div>
                <form className="flex flex-col" onSubmit={(e) => e.preventDefault()}>
                    <Input
                        type={InputType.text}
                        autoFocus
                        className="bg-navy-lightest w-full md:w-3/5"
                        containerClassName="m-2"
                        placeholder="Task name"
                        label="Task"
                        value={taskName}
                        onChange={(e) => setTaskName(e.target.value)}
                    />
                    <Select
                        data={[
                            {
                                id: 'boolean',
                                text: 'Confirm'
                            },
                            {
                                id: 'file',
                                text: 'Upload'
                            },
                            {
                                id: 'text',
                                text: 'Entry of text'
                            },
                            {
                                id: 'integer',
                                text: 'Number'
                            }
                            // { id: "date", text: "Date" }
                        ]}
                        defaultValue={{
                            label: taskTypeLabel,
                            value: taskType
                        }}
                        className="md:mr-2 bg-navy-lightest w-full md:w-3/5 mb-2 md:mb-auto"
                        containerClassName="m-2"
                        onItemClick={(id) => id && handleChangeTaskType(id)}
                        label="Action"
                    />
                    <Input
                        type={InputType.text}
                        className="bg-navy-lightest w-full md:w-3/5"
                        containerClassName="m-2"
                        placeholder="Task Weight"
                        label="Weight (max.10)"
                        value={taskWeight}
                        onChange={(e) => setTaskWeight(e.target.value)}
                    />
                    <Input
                        type={InputType.text}
                        className="bg-navy-lightest w-full md:w-3/5"
                        containerClassName="m-2"
                        placeholder="Task index"
                        label="Index"
                        value={taskIndex}
                        onChange={(e) => setTaskIndex(e.target.value)}
                    />
                    <Select
                        data={Object.values(FixedTags)}
                        defaultValue={tags
                            .filter((tag) => tag.substr(0, 9) !== 'category:')
                            .map((tag) => ({
                                value: tag,
                                label: tag
                            }))}
                        label="Tags"
                        placeholder="Select tags"
                        isMulti
                        createable
                        className="bg-navy-lightest w-full md:w-3/5"
                        containerClassName="m-2 mt-0"
                        onChange={(value) => setTaskTags(Array.isArray(value) ? value.map((task) => task.value) : [])}
                    />
                    <div className="flex flex-row items-center content-start w-full p-2">
                        <Select
                            data={Object.values(UnitUse).map((use) => {
                                return {
                                    text: UseConditionToText(use),
                                    id: use
                                };
                            })}
                            label="Designated Uses"
                            placeholder="Select use cases"
                            isMulti
                            className="md:mr-2 bg-navy-lightest w-full md:w-3/5 mb-2 md:mb-auto"
                            defaultValue={
                                associatedUses
                                    ? associatedUses.map((use) => ({
                                          value: use,
                                          label: UseConditionToText(use) ?? ''
                                      }))
                                    : undefined
                            }
                            onChange={(value: any) => {
                                if (!value || value.length === 0) {
                                    setAssociatedUses([]);
                                    return;
                                }

                                const uses = value.map((item: any) => {
                                    return UnitUse[humanizeString(item.value)];
                                });

                                setAssociatedUses(uses);
                            }}
                        />
                    </div>
                    <Select
                        data={[
                            { id: 'Practical', text: 'Practical' },
                            { id: 'Building', text: 'Building' },
                            { id: 'Cost', text: 'Cost' },
                            { id: 'Legals', text: 'Legals' }
                        ]}
                        label="Category"
                        placeholder="Select category"
                        createable
                        defaultValue={category ? { value: category, label: category } : undefined}
                        className="bg-navy-lightest w-full md:w-3/5"
                        containerClassName="m-2 mt-0"
                        onChange={(value: any) => {
                            setTaskCategory(value.value);
                        }}
                    />
                    <div className="flex flex-row items-center content-start w-full p-2 mb-4 mt-2">
                        <input
                            type="checkbox"
                            id="optional"
                            defaultChecked={taskOptional}
                            onChange={() => setTaskOptional(!taskOptional)}
                            className="form-checkbox p-2 rounded-sm bg-white text-newTeal-main border border-newGray-main focus:shadow-none focus:outline-none"
                        />
                        <label htmlFor="optional" className="text-sm text-gray-600 ml-2">
                            Provide a N/A option
                        </label>
                    </div>
                    {formError !== '' && (
                        <div className="bg-red-200 border border-red-600 rounded py-2 px-4 mx-2 mt-4">
                            <p className="text-red-600">{formError}</p>
                        </div>
                    )}
                </form>
            </div>
        </Window>
    );
};

export default EditTaskWindow;
