import { format } from 'date-fns/esm';
import _ from 'lodash';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ValueType } from 'react-select';
import ReactTooltip from 'react-tooltip';

import DeleteUnitWindow from './DeleteUnitWindow';
import CompletedDealButton from './Unit/Landlord/CompletedDealButton';
import UnitReadiness from './Unit/Landlord/UnitReadiness';
import { ReactComponent as Check } from '../../assets/images/check/regularCheck/check.svg';
import { ReactComponent as Clock } from '../../assets/images/clock/gray.svg';
import {
    DealState,
    GetDealCardDetailsDocument,
    GetUnitCardDetailsDocument,
    GetUnitCardDetailsQuery,
    GetUserTypeDocument,
    SingleUnitCardFragment,
    UnitAvailability,
    UnitCondition,
    UnitFilterCountsDocument,
    UnitUse,
    useDeleteUnitMutation,
    useGetUnitDetailsLazyQuery,
    useGetUserOrganisationQuery,
    useGetUserTypeQuery,
    useUnitDuplicateMutation,
    useUpdateUnitMutation
} from '../../generated/graphql';
import { BulkSelectContext } from '../../shared/bulk/context';
import { CardDropdownOptions } from '../../shared/Grid/CardDropdown';
import { handleFetchError, isTenant } from '../../shared/helpers';
import Input, { InputType } from '../../shared/Inputs';
import Select from '../../shared/Inputs/Select';
import Loading from '../../shared/Loading';
import useGetUnitSelectData from '../../shared/NewUnit/hooks/useGetUnitSelectData';
import { UseConditionToText } from '../../shared/NewUnit/hooks/useGetUnitSelectData';
import Window from '../../shared/Window';
import Card from '../../v2/components/Card';

type UnitCardProps = {
    id: string;
    name: string;
    buildingName: string;
    pending?: boolean;
    address: string;
    client?: string | null;
    rating: number;
    refetch: () => void;
    countsRefetch: () => void;
    isHidden?: boolean;
    organisationName?: string;
    hasHiddenDeals: boolean | null | undefined;
    hasConnectedDeals: boolean;
    created?: string | undefined;
    unit: SingleUnitCardFragment;
    available?: UnitAvailability;
};

const UnitCard = ({
    id,
    name,
    buildingName,
    pending = false,
    client,
    rating,
    isHidden,
    organisationName,
    created,
    unit,
    available
}: UnitCardProps) => {
    const history = useHistory();

    const { selectMode, selectAll, manageSelectedItems, saveSelectedUnits, manageSelectState } =
        useContext(BulkSelectContext);

    const { data: typeData, loading: typeLoading, error: typeError, refetch: typeRefetch } = useGetUserTypeQuery();

    const [useData, conditionData] = useGetUnitSelectData();
    const { data: userOrganisationData } = useGetUserOrganisationQuery();

    const completedDeal = unit.deals.find((deal) => (deal.state === DealState.Complete ? deal : undefined));

    const inNegotiation = unit.deals.some((deal) => deal.state !== DealState.Complete);

    const isTenantUser = isTenant(typeData?.user);

    useEffect(() => {
        // Check for errors
        handleFetchError('Could not load user', typeError, typeData?.user);
    }, [typeData, typeError]);

    // Unit card hover - Prefetch unit data
    const [prefetchUnit] = useGetUnitDetailsLazyQuery({
        variables: {
            unitID: id
        }
    });

    // Error message
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    // Form values
    const buildingNameRef = useRef<HTMLInputElement>(null);
    const areaRef = useRef<HTMLInputElement>(null);
    const emailRef = useRef<HTMLInputElement>();
    // Delete unit mutation
    const [deleteUnit, { error }] = useDeleteUnitMutation();
    const [duplicateUnit, { error: duplicateUnitError }] = useUnitDuplicateMutation();
    const [duplicateUnitWindow, setDuplicateUnitWindow] = useState<boolean>(false);
    const [emails, setEmails] = useState<string[] | null>([]);
    const [deleteUnitWindow, setDeleteUnitWindow] = useState<boolean>(false);

    const [associatedCompany, setAssociatedCompany] = useState<string>();
    const [use, setUse] = useState<UnitUse>(unit?.use ?? undefined);
    const [condition, setCondition] = useState<UnitCondition>(unit?.condition ?? undefined);
    const [selectState, setSelectState] = useState<boolean>(selectAll);
    const unitUrl = `/units/${id}?landlordorg=${organisationName}`;

    const isAvailableToLet = available === UnitAvailability.AvailableToLet;

    useEffect(() => {
        typeData?.user.type !== 'AGENT' &&
            typeData?.user.organisation &&
            setAssociatedCompany(typeData?.user.organisation._id);
    }, [typeData]);

    useEffect(() => {
        const newEmailArray = [...(emails ?? [])];
        unit?.actors.find((actor) => {
            if (actor.type === 'AGENT') {
                newEmailArray.push(actor.email);
            }
        });
        const newEmailArray1 = newEmailArray.filter((val, id, array) => {
            return array.indexOf(val) == id;
        });
        setEmails(newEmailArray1);
    }, [unit?.actors]);

    useEffect(() => {
        if (!selectMode) setSelectState(false);
    }, [selectMode]);

    useEffect(() => {
        if (selectAll) {
            setSelectState(true);
        } else setSelectState(false);
    }, [selectAll]);

    useEffect(() => {
        manageSelectedItems(selectState, id);
        saveSelectedUnits(selectState, id, unit);
    }, [selectState]);

    const [updateUnit, { loading: updateLoading }] = useUpdateUnitMutation();

    const handleDuplicateClick = async () => {
        const buildingName = buildingNameRef.current?.value;
        const area = areaRef.current?.value && parseInt(areaRef.current?.value);

        if (!area) {
            setErrorMessage('Demise area should be greater than 0');

            return;
        }

        if (associatedCompany) {
            const { data } = await duplicateUnit({
                variables: {
                    unitID: id,
                    name: buildingName,
                    emails,
                    organisation: associatedCompany,
                    use,
                    condition,
                    area
                },
                refetchQueries: () => [
                    GetUserTypeDocument,
                    UnitFilterCountsDocument,
                    GetUnitCardDetailsDocument,
                    GetDealCardDetailsDocument
                ]
            });

            setDuplicateUnitWindow(false);
            if (duplicateUnitError) {
                handleFetchError('Failed to duplicate the demise', error, data?.unitDuplicate);
                return;
            }
        }
    };

    const handleDeleteClick = async () => {
        const { data } = await deleteUnit({
            variables: {
                unitID: id
            },
            optimisticResponse: {
                unitDelete: {
                    __typename: 'UnitDeleteResponse',
                    deletedUnitID: id
                }
            },
            update(store, { data }) {
                if (!data) return;

                let unitData = store.readQuery<GetUnitCardDetailsQuery>({
                    query: GetUnitCardDetailsDocument
                });

                if (!unitData) return;

                // Remove the unit from the cache
                unitData &&
                    store.writeQuery<GetUnitCardDetailsQuery>({
                        query: GetUnitCardDetailsDocument,
                        data: {
                            ...unitData,
                            units: unitData.units?.results.filter((unit) => unit._id !== data.unitDelete.deletedUnitID)
                        }
                    });
            },
            refetchQueries: () => [
                GetUserTypeDocument,
                UnitFilterCountsDocument,
                GetUnitCardDetailsDocument,
                GetDealCardDetailsDocument
            ]
        });

        if (error) {
            handleFetchError('Failed to delete the demise', error, data?.unitDelete);
            return;
        } else {
            setDeleteUnitWindow(false);
        }
    };

    const options: CardDropdownOptions = [
        {
            title: isAvailableToLet ? 'Archive' : 'Available to Let',
            onClick: () => {
                handleChangeAvailability(
                    isAvailableToLet ? UnitAvailability.UnavailableToLet : UnitAvailability.AvailableToLet
                );
            }
        },
        {
            title: 'Duplicate',
            onClick: () => {
                setDuplicateUnitWindow(true);
            }
        },
        {
            title: 'Delete',
            onClick: () => {
                setDeleteUnitWindow(true);
            }
        }
    ];

    const handleChangeAvailability = async (available: UnitAvailability) => {
        await updateUnit({
            variables: {
                unitID: id,
                available: available
            },
            refetchQueries: () => [
                GetUserTypeDocument,
                UnitFilterCountsDocument,
                GetUnitCardDetailsDocument,
                GetDealCardDetailsDocument
            ]
        });
    };

    const pickerData =
        userOrganisationData &&
        userOrganisationData?.user.organisation.trustedUsers.filter((trustedUser) => {
            const actorsEmails = emails && emails.map((email) => email);
            const agentsSet = new Set(actorsEmails);
            return !agentsSet.has(trustedUser.email);
        });

    const emailsWithoutCurrent = userOrganisationData?.user.organisation.members.filter(() => {
        const advisorEmails = new Set(userOrganisationData?.user.organisation.members);
        return !advisorEmails.has(typeData?.user.email);
    });

    const advisorPickerData = emailsWithoutCurrent?.filter((trustedUser) => {
        const agentsSet = new Set(emails);
        return !agentsSet.has(trustedUser.email);
    });

    const createdOnLabel = 'Created on ' + format(new Date(created as string), 'dd MMM yyyy, h:mm a');

    if (typeLoading || updateLoading) {
        return <Loading />;
    }

    return (
        <>
            {duplicateUnitWindow && (
                <Window
                    title="Duplicate Demise"
                    width="w-2/5"
                    primaryBtn
                    primaryBtnText="Duplicate"
                    onPrimaryBtnClick={handleDuplicateClick}
                    setShow={setDuplicateUnitWindow}
                >
                    <>
                        <form
                            className="flex flex-col py-4 px-2 w-full"
                            onSubmit={(event) => {
                                event.preventDefault();
                                handleDuplicateClick();
                            }}
                        >
                            <div className="flex flex-row flex-wrap md:flex-no-wrap w-full gap-4 mb-4">
                                <Input
                                    type={InputType.text}
                                    ref={buildingNameRef}
                                    defaultValue={'Copy of ' + name}
                                    placeholder="Demise Reference"
                                    label="Demise Reference"
                                    className="w-full"
                                />
                                <Input
                                    type={InputType.number}
                                    required
                                    showRequiredAsterisk
                                    ref={areaRef}
                                    placeholder="Demise Area (sq ft)"
                                    label="Demise Area"
                                    className="w-full"
                                />
                            </div>
                            <div className="flex flex-row flex-wrap w-full mb-4">
                                {typeData?.user.__typename === 'User' && typeData.user.type === 'AGENT' ? (
                                    <>
                                        {' '}
                                        <div className="flex flex-row flex-wrap md:flex-no-wrap w-full gap-4 mb-4">
                                            <Select
                                                data={useData}
                                                required
                                                defaultValue={{
                                                    label: UseConditionToText(unit?.use),
                                                    value: unit?.use as UnitUse
                                                }}
                                                onItemClick={(use) => {
                                                    if (!use) return;
                                                    setUse(use as UnitUse);
                                                }}
                                                placeholder="Select Use"
                                                label="Select Use"
                                                className="w-full z-50 absolute"
                                            />
                                            <Select
                                                data={conditionData}
                                                required
                                                defaultValue={{
                                                    label: UseConditionToText(unit?.condition),
                                                    value: unit?.condition as UnitCondition
                                                }}
                                                onItemClick={(condition) => {
                                                    if (!condition) return;
                                                    setCondition(condition as UnitCondition);
                                                }}
                                                placeholder="Condition"
                                                label="Condition"
                                                className="w-full z-50 absolute"
                                            />
                                        </div>
                                        <div className="w-full flex flex-row items-center justify-between px-1 mb-1">
                                            <span className="font-semibold text-sm">
                                                Advisor Access (Agent, Managing Agents ando/or Lawyers)
                                            </span>
                                        </div>
                                        <div className="flex flex-col gap-2 w-full">
                                            {userOrganisationData && pickerData && (
                                                <Select
                                                    data={Object.values(advisorPickerData).map((trustedUser) => {
                                                        return {
                                                            text: trustedUser.activated
                                                                ? trustedUser.firstName +
                                                                  ' ' +
                                                                  trustedUser.lastName +
                                                                  ' (' +
                                                                  trustedUser.organisation.name +
                                                                  ')'
                                                                : trustedUser.email,
                                                            id: trustedUser.email
                                                        };
                                                    })}
                                                    unit={unit}
                                                    setEmails={setEmails}
                                                    initialChips={emails}
                                                    ref={emailRef}
                                                    label="Email"
                                                    placeholder={
                                                        _.isEmpty(emails)
                                                            ? 'Advisors Email Address'
                                                            : 'Type to add another email'
                                                    }
                                                    color={'black'}
                                                    agentEmail={typeData.user.email}
                                                    isMulti
                                                    blackStyle
                                                    createable
                                                    chipBackground="white"
                                                    className="md:mr-2 bg-navy-lightest w-full"
                                                    onChange={(value) => {
                                                        if (!value && !emails) {
                                                            setEmails([]);
                                                            return;
                                                        } else if (!value || value.length === 0) {
                                                            setEmails(emails);
                                                            return;
                                                        } else {
                                                            const users = value.map((item: ValueType) => {
                                                                return item.value;
                                                            });

                                                            const newChipsArray = [...(emails || [])];

                                                            newChipsArray.push(...users);
                                                            setEmails(newChipsArray);
                                                            return;
                                                        }
                                                    }}
                                                />
                                            )}
                                        </div>
                                        <div
                                            className="w-full flex mt-8 items-center relative"
                                            onClick={() => typeRefetch()}
                                        >
                                            <Select
                                                maxMenuHeight={90}
                                                data={
                                                    typeData?.user.trustedOrganisations
                                                        .filter((organisation) => organisation.active)
                                                        .map((trustedOrganisation) => ({
                                                            id: trustedOrganisation._id,
                                                            text: trustedOrganisation.name
                                                        })) ?? []
                                                }
                                                required
                                                onItemClick={(trustedOrganisation) =>
                                                    setAssociatedCompany(trustedOrganisation!)
                                                }
                                                placeholder="Associated Company"
                                                label="Associated Company"
                                                className="w-full"
                                            />
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <div className="flex flex-row flex-wrap md:flex-no-wrap w-full gap-4 mb-4">
                                            <Select
                                                data={useData}
                                                required
                                                defaultValue={{
                                                    label: UseConditionToText(unit?.use),
                                                    value: unit?.use as UnitUse
                                                }}
                                                onItemClick={(use) => {
                                                    if (!use) return;
                                                    setUse(use as UnitUse);
                                                }}
                                                placeholder="Select Use"
                                                label="Select Use"
                                                className="w-full z-50 absolute"
                                            />
                                            <Select
                                                data={conditionData}
                                                required
                                                defaultValue={{
                                                    label: UseConditionToText(unit?.condition),
                                                    value: unit?.condition as UnitCondition
                                                }}
                                                onItemClick={(condition) => {
                                                    if (!condition) return;
                                                    setCondition(condition as UnitCondition);
                                                }}
                                                placeholder="Condition"
                                                label="Condition"
                                                className="w-full z-50 absolute"
                                            />
                                        </div>
                                        <div className="w-full flex flex-row items-center justify-between px-1 mb-1">
                                            <span className="font-semibold text-sm">
                                                Advisor Access (Agent, Managing Agents ando/or Lawyers)
                                            </span>
                                        </div>
                                        <div className="flex flex-col gap-2 w-full">
                                            {userOrganisationData && pickerData && (
                                                <Select
                                                    data={Object.values(pickerData).map((trustedUser) => {
                                                        return {
                                                            text: trustedUser.activated
                                                                ? trustedUser.firstName +
                                                                  ' ' +
                                                                  trustedUser.lastName +
                                                                  ' (' +
                                                                  trustedUser.organisation.name +
                                                                  ')'
                                                                : trustedUser.email,
                                                            id: trustedUser.email
                                                        };
                                                    })}
                                                    setEmails={setEmails}
                                                    initialChips={emails}
                                                    ref={emailRef}
                                                    label="Email"
                                                    placeholder={
                                                        emails && emails.length > 0
                                                            ? 'Type to add another email'
                                                            : 'Advisors Email Address'
                                                    }
                                                    color={'black'}
                                                    isMulti
                                                    blackStyle
                                                    createable
                                                    chipBackground="white"
                                                    className="md:mr-2 bg-navy-lightest w-full"
                                                    onChange={(value: any) => {
                                                        if (!value && !emails) {
                                                            setEmails([]);
                                                            return;
                                                        } else if (
                                                            (emails && !value) ||
                                                            (emails && value && value.length === 0)
                                                        ) {
                                                            setEmails(emails);
                                                            return;
                                                        }

                                                        if (emails && value) {
                                                            const users = value.map((item: any) => {
                                                                return item.value;
                                                            });
                                                            const newChipsArray = emails && [...emails];

                                                            newChipsArray && newChipsArray.push(...users);
                                                            setEmails(newChipsArray);
                                                            return;
                                                        }
                                                    }}
                                                />
                                            )}
                                        </div>
                                    </>
                                )}
                            </div>
                            {errorMessage && (
                                <div className="bg-red-200 border border-red-600 rounded w-full py-2 px-4 mt-4">
                                    <span className="text-center text-red-600">{errorMessage}</span>
                                </div>
                            )}
                        </form>
                    </>
                </Window>
            )}
            {deleteUnitWindow && (
                <DeleteUnitWindow setDeleteUnitWindow={setDeleteUnitWindow} handleDeleteClick={handleDeleteClick} />
            )}
            <Card
                options={!selectMode ? options : undefined}
                selectState={selectState}
                setSelectState={setSelectState}
                className={`${pending && `opacity-50`}`}
                height={'auto'}
                isHidden={isHidden}
                userType={typeData?.user.type}
                clientName={client}
            >
                <div className="p-4 flex flex-row items-center">
                    {!isTenantUser && (
                        <div
                            className={`w-5 h-5 rounded z-20 mr-4 ${
                                selectState ? 'bg-newTeal-main' : 'bg-navy-light'
                            } `}
                            onClick={() => {
                                manageSelectState();
                                setSelectState(!selectState);
                            }}
                        >
                            {selectState && <Check className="w-5 h-5 text-white" />}
                        </div>
                    )}
                    <div
                        className="px-4 cursor-pointer"
                        onClick={() => {
                            !selectMode && history.push(unitUrl);
                        }}
                    >
                        <h1 className="text-lg font-semibold truncate text-navy-main">{name}</h1>
                        <h1 className="text-lg font-semibold mb-1 truncate text-navy-main">{buildingName}</h1>
                    </div>
                </div>
                <div className="flex flex-row mx-4 mt-4 text-sm w-full justify-between cursor-pointer">
                    <div
                        className="flex flex-row"
                        onClick={() => {
                            !selectMode && history.push(unitUrl);
                        }}
                    >
                        <div className="flex flex-col border-r-2 mx-4 pr-4 sm:w-16 xxl:w-20 items-center ">
                            <span className="text-xxs text-gray-600">Use Class</span>
                            <span
                                className="font-semibold text-navy-main text-xs mt-2 sm:w-16 xxl:w-20 mx-auto truncate sm:pl-1 xxl:pl-2"
                                data-tip={UseConditionToText(unit.use)}
                            >
                                {UseConditionToText(unit.use)}
                            </span>
                        </div>
                        <div className="flex flex-col border-r-2 mr-4 pr-4 sm:w-16 xxl:w-20 items-center ">
                            <span className="text-xxs text-gray-600">Condition</span>
                            <span
                                className="font-semibold text-xs mt-2 text-navy-main sm:w-16 xxl:w-20 truncate sm:pl-0 xxl:pl-2 "
                                data-tip={UseConditionToText(unit.condition)}
                            >
                                {UseConditionToText(unit.condition)}
                            </span>
                        </div>
                        <div className="flex flex-col sm:w-16 xxl:w-20 items-center">
                            <span className="text-xxs text-gray-600">Demise Area</span>
                            <span className="font-semibold text-navy-main text-xs mt-2">{unit.area}</span>
                            <span className="text-xxs text-gray-600 mt-1">sq ft</span>
                        </div>
                    </div>
                    {completedDeal && completedDeal.tenant?.name && (
                        <CompletedDealButton
                            id={completedDeal._id}
                            tenantName={completedDeal.tenant?.name}
                            date={completedDeal.round?.date}
                        />
                    )}
                </div>
                <div
                    className="cursor-pointer"
                    onClick={() => {
                        !selectMode && history.push(unitUrl);
                    }}
                    onMouseEnter={() => prefetchUnit()}
                >
                    <UnitReadiness rating={rating} card />
                    <div className="flex flex-row h-full justify-between">
                        {created && (
                            <div className="flex flex-row items-center mb-2">
                                <Clock className="w-3 h-3 mx-2 ml-4 text-white" data-tip={createdOnLabel} />
                                <p className="text-xxs xl:flex sm:hidden">{createdOnLabel}</p>
                            </div>
                        )}
                        {inNegotiation && (
                            <div className="bg-newTeal-light text-xs font-semibold px-4 py-1 rounded-tl-lg absolute bottom-0 right-0 border-b border-newTeal-light">
                                In Negotiation
                            </div>
                        )}
                    </div>
                </div>
                <ReactTooltip
                    backgroundColor="#484444"
                    textColor="white"
                    effect="solid"
                    place="top"
                    clickable={false}
                    multiline={true}
                    scrollHide={true}
                    className="tooltip-w "
                />
            </Card>
        </>
    );
};

export default UnitCard;
