import React from 'react';

import DealCard from './DealCard';
import { DealCardFragment, DealStatusFilter, User } from '../../generated/graphql';
import Grid from '../../shared/Grid';

type DealsListProps = {
    user?: User;
    deals?: DealCardFragment[];
    pinnedPresence: boolean;
    isTenant: boolean;
    dealStatus?: DealStatusFilter[];
};

const DealsList = ({ user, deals, pinnedPresence, isTenant, dealStatus }: DealsListProps) => {
    return (
        <>
            <Grid className={`${pinnedPresence ? 'mt-9 gap-y-7' : 'mt-4'}`}>
                {deals?.map((deal) => {
                    // Don't show if invitation is still pending
                    if (deal.pending && user?.type !== 'AGENT') return null;

                    return (
                        <DealCard
                            deal={deal}
                            id={deal._id}
                            key={deal._id}
                            isPinned={!dealStatus?.includes(DealStatusFilter.Hidden) && deal.isPinned}
                            hasMaxPinnedDeals={user?.hasMaxPinnedDeals}
                            userType={user?.type}
                            approvals={deal.approvals}
                            isHidden={user?.hiddenDeals?.some((hiddenDeal) => hiddenDeal._id === deal._id)}
                            name={
                                !isTenant && deal.tenant?.name ? deal.tenant?.name : deal.unit?.organisation.name ?? ''
                            }
                            created={deal.created}
                            clientName={
                                user?.type === 'AGENT'
                                    ? deal.currentUserParty === 'TENANT'
                                        ? deal.tenant?.name
                                        : deal.unit?.organisation.name
                                    : null
                            }
                            unitID={deal.unit?._id ?? ''}
                            unitName={deal.unit?.name ?? 'Untitled Demise'}
                            unitRating={deal.unit?.rating ?? 0}
                            pending={deal.pending}
                            buildingName={deal.unit?.buildingName ?? 'Untitled Building'}
                            state={deal.state}
                            currentUserParty={deal.currentUserParty}
                            round={deal.round}
                            clauseSummary={deal.clauseSummary}
                            hasHiddenUnit={deal.hasHiddenUnit}
                            agreedBy={deal.agreedBy}
                            showSelectButton={true}
                        />
                    );
                })}
            </Grid>
        </>
    );
};

export default DealsList;
