import React, { useContext } from 'react';

import ClauseContext from './Context';
import { ReactComponent as Clock } from '../../../../../assets/images/clock/black.svg';

type InfoProps = {
    noBorder?: boolean;
};

const Info = ({ noBorder }: InfoProps) => {
    const { setShowHistory, isRejectOpen } = useContext(ClauseContext);

    return (
        <div
            className={`flex justify-center items-center absolute top-0 right-0 bottom-0 w-14 ${
                !noBorder && 'border-l-2 border-gray-450'
            } bg-transparent transitionAll my-1 `}
        >
            <div className="flex flex-row gap-2">
                <button
                    onClick={() => setShowHistory!(true)}
                    className={`focus:outline-none ${isRejectOpen && 'opacity-50 cursor-not-allowed'}`}
                    disabled={isRejectOpen}
                >
                    <Clock className="w-5 h-5" />
                </button>
            </div>
        </div>
    );
};

export default Info;
