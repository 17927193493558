import React, { useEffect, useRef, useState } from 'react';

import ClauseAction from './ClauseAction';
import { DealType as TypeOfDeal } from './ConfirmClauseDeleteWindow.tsx';
import ConfirmGroupDeleteWindow from './ConfirmGroupDeleteWindow';
import useMoveClause from './hooks/useMoveClause';
import { ReactComponent as Down } from '../../assets/images/arrow/down/black.svg';
import { ReactComponent as Up } from '../../assets/images/arrow/up/black.svg';
import { ReactComponent as Remove } from '../../assets/images/delete/black.svg';
import { ReactComponent as Settings } from '../../assets/images/edit/black.svg';
import { ReactComponent as Edit } from '../../assets/images/hash/black.svg';
import { ReactComponent as Add } from '../../assets/images/plus/black.svg';
import {
    DealDetailsFragment,
    DealState,
    useGetUserTypeQuery,
    UserType,
    useUpdateGroupMutation
} from '../../generated/graphql';
import { useUserType } from '../../v2/common/helpers';
import { Button } from '../button';
import Input, { InputType } from '../Inputs';

type ClauseGroupInput = {
    section: string;
    group: string;
    groupLength: number;
};

type GroupProps = {
    id: string;
    parentID: string;
    deal: DealDetailsFragment | null;
    title: string;
    setOpenDetails?: React.Dispatch<React.SetStateAction<{ section?: string; level2Group?: string } | null>>;
    setShow?: React.Dispatch<React.SetStateAction<boolean>>;
    clauseGroupInput?: ClauseGroupInput;
    templateID?: string;
    type?: TypeOfDeal;
    level?: number;
    clauseID?: string;
    isReordering?: boolean;
    index?: number;
    clauses?: number;
    children: React.ReactChild;
};

const Group = ({
    id,
    parentID,
    deal,
    title,
    setOpenDetails,
    setShow,
    clauseGroupInput,
    templateID,
    type,
    level = 1,
    clauseID,
    isReordering,
    clauses,
    index,
    children
}: GroupProps) => {
    const moveClause = useMoveClause(deal, clauseID ?? '', id, parentID);
    const [updateGroup, { loading: updateGroupLoading }] = useUpdateGroupMutation();

    const [updateOpen, setUpdateOpen] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const titleRef = useRef<HTMLInputElement>(null);

    // Get User Type
    const [userType, setUserType] = useState<UserType>();

    const { isLandlordOrAdmin } = useUserType(userType);

    const addClause = () => {
        setShow!(true);
        if (setOpenDetails && clauseGroupInput)
            setOpenDetails({
                section: clauseGroupInput.section,
                level2Group: title
            });
        setUpdateOpen(false);
    };

    const { data: typeData, loading: typeLoading, error: typeError } = useGetUserTypeQuery();
    useEffect(() => {
        if (typeData?.user.__typename === 'User') {
            setUserType(typeData.user.type);
        }
    }, [typeData, typeError, typeLoading]);

    const handleSaveClick = async () => {
        if (deal?._id && index != null) {
            let inputs = {
                from: index,
                to: index,
                title: titleRef.current?.value,
                groupID: id,
                parentID,
                dealID: deal._id
            };

            await updateGroup({
                variables: inputs
            });
        }

        setIsEditing(false);
        setUpdateOpen(false);
    };

    return (
        <>
            {/* Group delete confirm window */}
            {isDeleting && type !== undefined && (
                <ConfirmGroupDeleteWindow
                    type={type}
                    groupID={id}
                    parentID={parentID}
                    templateID={templateID}
                    deal={deal}
                    setShow={setIsDeleting}
                />
            )}

            <div
                className={`w-full bg-white p-4 pb-2 mb-2 md:rounded-tl md:rounded-bl ${level === 2 && 'bg-black'}`}
                key={id}
            >
                <div className={`w-full flex flex-row justify-between pb-6 pt-2 -mr-4`}>
                    {isEditing ? (
                        <Input
                            type={InputType.text}
                            defaultValue={title}
                            readOnly={false}
                            ref={titleRef}
                            className="bg-navy-lightest text-black border-none w-96"
                            label="Title"
                        />
                    ) : (
                        title
                    )}

                    {level === 2 &&
                        moveClause &&
                        index !== undefined &&
                        (isReordering ? (
                            <div className="flex flex-row items-center content-center justify-center">
                                <button
                                    className={`cursor-pointer focus:outline-none mr-4 ${
                                        (!clauses || clauses <= 1 || index === 0) &&
                                        'text-newGray-darkish cursor-not-allowed'
                                    }`}
                                    disabled={!clauses || clauses <= 1 || index === 0}
                                    onClick={() => moveClause(index, index - 1)}
                                >
                                    <Up className="w-6 h-6" />
                                </button>
                                <button
                                    className={`cursor-pointer mr-px focus:outline-none ${
                                        (!clauses || clauses <= 1 || index === clauses - 1) &&
                                        'text-newGray-darkish cursor-not-allowed'
                                    }`}
                                    disabled={!clauses || clauses <= 1 || index === clauses - 1}
                                    onClick={() => moveClause(index, index + 1)}
                                >
                                    <Down className="w-6 h-6" />
                                </button>
                            </div>
                        ) : isEditing ? (
                            <Button
                                onClick={handleSaveClick}
                                background="black"
                                text="white"
                                className="px-4 w-20 mr-4"
                            >
                                {updateGroupLoading ? 'Saving' : 'Save'}
                            </Button>
                        ) : (
                            <div className="flex items-center text-black">
                                {updateOpen && (
                                    <div className="flex flex-row absolute max-w-full bg-white right-5 pr-px">
                                        <ClauseAction
                                            icon={<Edit className="w-4 h-4 mb-1" />}
                                            text={'Edit'}
                                            onClick={() => setIsEditing(true)}
                                            className={
                                                deal &&
                                                deal.state !== DealState.Edit &&
                                                userType !== (deal.state as string)
                                                    ? ' opacity-50 cursor-not-allowed rounded-l'
                                                    : 'rounded-l'
                                            }
                                        />
                                        <ClauseAction
                                            icon={<Add className="w-4 h-4 mb-1" />}
                                            text={'Add Clause'}
                                            onClick={addClause}
                                        />
                                        <ClauseAction
                                            icon={<Remove className="w-4 h-4 mb-1" />}
                                            text={'Remove'}
                                            onClick={() => setIsDeleting(true)}
                                        />
                                        <div
                                            className="bg-navy-light hover:bg-gray-200 rounded-r text-xs flex flex-col content-center items-center justify-center px-2 cursor-pointer"
                                            onClick={() => {
                                                setUpdateOpen((updateOpen) => !updateOpen);
                                            }}
                                        >
                                            <Settings className="w-5 h-5" />
                                        </div>
                                    </div>
                                )}
                                {isLandlordOrAdmin && deal?.state !== DealState.Complete && (
                                    <button
                                        className="p-2 cursor-pointer mr-px focus:outline-none"
                                        onClick={() => {
                                            setUpdateOpen((updateOpen) => !updateOpen);
                                        }}
                                    >
                                        <Settings className="w-5 h-5" />
                                    </button>
                                )}
                            </div>
                        ))}
                </div>
                <div>{children}</div>
            </div>
        </>
    );
};

export default Group;
