import Fuse from 'fuse.js';
import _ from 'lodash';
import React, { useContext, useEffect, useState } from 'react';

import Member from './Member';
import PendingMember from './PendingMember';
import RemoveUserWindow from './RemoveUserWindow';
import { ReactComponent as FiltersIcon } from '../../../assets/images/filters/sliders.svg';
import {
    OrderBy,
    OrganisationMemberFragment,
    useGetUserOrganisationQuery,
    useGetUserTypeQuery,
    UserSortFields,
    UserType
} from '../../../generated/graphql';
import { AddButton } from '../../../shared/add-button';
import { Button } from '../../../shared/button';
import { FilterContext } from '../../../shared/Filter/context';
import { FilterConfig, FilterType, FilterWindow } from '../../../shared/Filter/FilterWindow';
import {
    transformFilters,
    transformToFilterUrlParams,
    transformToSortUrlParams,
    useUpdateUrl
} from '../../../shared/Filter/helpers';
import SortChip from '../../../shared/Filter/SortChip';
import { handleFetchError } from '../../../shared/helpers';
import useMobileScreen from '../../../shared/hooks/useMobileScreen';
import Input, { InputType } from '../../../shared/Inputs';
import { RadioValue } from '../../../shared/Inputs/Radio';
import Select from '../../../shared/Inputs/Select';
import Loading from '../../../shared/Loading';
import SuccessIcon from '../../../shared/SuccessIcon';
import Window from '../../../shared/Window';
import UserInviteWindow from '../UserInviteWindow';

export enum MemberAccountType {
    COLLEAGUES = 'Colleagues',
    TRUSTED = 'Advisors'
}

const Team = () => {
    const { values, isWindowOpen, openWindow, sort, filters, chips } = useContext(FilterContext);
    const { data, loading, error, refetch } = useGetUserOrganisationQuery({
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-first',
        variables: {
            sort: sort
                ? {
                      field: sort.field as UserSortFields,
                      order: sort.order ?? OrderBy.Desc
                  }
                : undefined,
            filter: { ...transformFilters(filters, values) }
        }
    });
    const { data: typeData } = useGetUserTypeQuery();

    const [showUserInviteWindow, setShowUserInviteWindow] = useState<boolean>(false);
    const [showResendPopup, setShowResendPopup] = useState<boolean>(false);
    const [showRemoveMemberPopup, setShowRemoveMemberPopup] = useState<boolean>(false);
    const [userID, setUserID] = useState<string>();
    const [memberEmail, setMemberEmail] = useState<string>();
    const [organisationID, setOrganisationID] = useState<string | null>(null);
    const [optionId, setOptionId] = useState<MemberAccountType | null>(MemberAccountType.COLLEAGUES);
    const [isTrustedUser, setIsTrustedUser] = useState<boolean>(false);

    const [colleagues, setColleagues] = useState<OrganisationMemberFragment[] | undefined>([]);
    const [trustedUsers, setTrustedUsers] = useState<OrganisationMemberFragment[] | undefined>([]);
    const [isUserInvited, setIsUserInvited] = useState<boolean>(false);

    const fuse = new Fuse(data?.user.organisation.members ?? [], {
        keys: ['firstName', 'lastName', 'email'],
        threshold: 0.3
    });

    const trustedFuse = new Fuse(data?.user.organisation.trustedUsers ?? [], {
        keys: ['firstName', 'lastName', 'email', 'organisation.name'],
        threshold: 0.3
    });

    const { updateUrl } = useUpdateUrl();
    useEffect(() => {
        if (!_.isEmpty(values) || sort) {
            const sortParams = sort ? transformToSortUrlParams(sort) : {};
            const filterParams = !_.isEmpty(values) ? transformToFilterUrlParams(filters, values) : {};

            updateUrl({
                ...filterParams,
                ...sortParams
            });
        }
    }, [values, sort]);

    useEffect(() => {
        // Check for errors
        handleFetchError('Could not load your company details', error, data?.user);
    }, [data, error]);

    useEffect(() => {
        // Check for errors
        if (error) return handleFetchError('Could not load your demises', error, data?.user.organisation.members);

        console.log(data?.user.organisation.members);

        setColleagues(data?.user.organisation.members ?? []);
        fuse.setCollection(data?.user.organisation.members ?? []);
        setTrustedUsers(data?.user.organisation.trustedUsers ?? []);
        trustedFuse.setCollection(data?.user.organisation.trustedUsers ?? []);
    }, [data, error]);

    const searchColleague = (query?: string) => {
        if (!data) return;

        if (!query || query.trim().length === 0) return setColleagues(data.user.organisation.members ?? []);

        setColleagues(fuse.search(query ?? '').map((item) => item.item));
    };

    const searchTrusted = (query?: string) => {
        if (!data) return;

        if (!query || query.trim().length === 0) return setTrustedUsers(data.user.organisation.trustedUsers ?? []);

        setTrustedUsers(trustedFuse.search(query ?? '').map((item) => item.item));
    };

    if (isUserInvited) {
        refetch();
        setIsUserInvited(false);
        setOptionId(MemberAccountType.TRUSTED);
    }

    const isMobile = useMobileScreen();

    if (loading) return <Loading />;

    if (error) return null;

    return (
        <div className="w-full lg:w-4/5 p-6">
            {showUserInviteWindow && (
                <UserInviteWindow
                    email={memberEmail}
                    setShow={setShowUserInviteWindow}
                    setIsUserInvited={setIsUserInvited}
                />
            )}
            {showResendPopup && (
                <Window title="Invite Resent" setShow={setShowResendPopup} width="w-2/5">
                    <div className="w-full flex flex-col items-center justify-center py-10">
                        <SuccessIcon />
                        <h1 className="text-xl font-bold text-center mt-5">Invite Resent</h1>
                    </div>
                </Window>
            )}
            {showRemoveMemberPopup && (userID || memberEmail) && organisationID && (
                <RemoveUserWindow
                    organisationID={organisationID}
                    userID={userID!}
                    setShow={setShowRemoveMemberPopup}
                    isTrustedUser={isTrustedUser}
                />
            )}

            {isWindowOpen && optionId && (
                <FilterWindow
                    config={
                        optionId === MemberAccountType.COLLEAGUES
                            ? trustedFilterConfig(UserType.Agent)
                            : trustedFilterConfig(typeData?.user.type)
                    }
                    sortOptions={getUserFields()}
                />
            )}
            <div
                className={`flex ${
                    isMobile ? 'flex-col' : 'flex-row'
                } justify-between items-center border-b w-full pb-4 border-newGray-main`}
            >
                <AddButton
                    onClick={() => setShowUserInviteWindow(true)}
                    background="newTeal-main"
                    text="white"
                    primary
                    className={`${isMobile ? 'w-full mb-2' : ''} px-4 font-semibold`}
                >
                    {' '}
                    {typeData?.user.type === UserType.Agent ? 'Invite Colleague' : ' Invite User'}
                </AddButton>
                <div className={`flex ${isMobile ? ' w-full' : ''}`}>
                    <Input
                        type={InputType.text}
                        placeholder="Search Users"
                        className={`${isMobile ? 'w-full' : 'w-52'}  h-12 pr-6 mr-2`}
                        autoFocus
                        onChange={(e) => {
                            optionId === MemberAccountType.COLLEAGUES
                                ? searchColleague(e.target.value)
                                : searchTrusted(e.target.value);
                        }}
                    />
                    <Button background="white" text="black" className="filter-button-size" onClick={() => openWindow()}>
                        <FiltersIcon />
                    </Button>
                </div>
            </div>
            <div className="mb-2 flex items-center flex-wrap">
                {typeData?.user.type !== UserType.Agent && (
                    <div className="pb-2 w-48" style={{ marginTop: '-7.5px' }}>
                        <Select
                            label={optionId ?? MemberAccountType.COLLEAGUES}
                            defaultValue={{
                                value: optionId ?? MemberAccountType.COLLEAGUES,
                                label: optionId ?? MemberAccountType.COLLEAGUES
                            }}
                            onItemClick={(id) => id && setOptionId(id as MemberAccountType)}
                            data={[
                                {
                                    id: MemberAccountType.COLLEAGUES,
                                    text: MemberAccountType.COLLEAGUES
                                },
                                {
                                    id: MemberAccountType.TRUSTED,
                                    text: MemberAccountType.TRUSTED
                                }
                            ]}
                        />
                    </div>
                )}
                <div
                    className="flex my-2 items-center flex-wrap"
                    style={typeData?.user.type === UserType.Agent ? { marginLeft: '-6px' } : {}}
                >
                    {sort && typeData?.user && <SortChip sortOptions={getUserFields()} />}
                    {values && !loading && optionId === MemberAccountType.TRUSTED && chips && chips()}
                </div>
            </div>

            {data?.user.__typename === 'User' && (
                <>
                    {/* Members */}
                    <div className="flex flex-col items-center content-center mb-10">
                        {optionId &&
                            typeData?.user.type !== UserType.Agent &&
                            [MemberAccountType.COLLEAGUES].includes(optionId) &&
                            colleagues &&
                            colleagues
                                .filter((member) => member.type !== UserType.Agent)
                                ?.map((member) => (
                                    <Member
                                        key={member._id}
                                        member={member}
                                        canDelete={
                                            data.user.__typename === 'User' &&
                                            data.user._id !== member._id &&
                                            (data.user.organisation.members?.length ?? 1) > 1
                                        }
                                        organisationID={
                                            data.user.__typename === 'User' ? data.user.organisation._id : ''
                                        }
                                        isTrustedUser={false}
                                        setIsTrustedUser={setIsTrustedUser}
                                        setUserID={setUserID}
                                        setOrganisationID={setOrganisationID}
                                        setShowRemoveMemberPopup={setShowRemoveMemberPopup}
                                        setShowResendPopup={setShowResendPopup}
                                    />
                                ))}
                        {optionId &&
                            typeData?.user.type === UserType.Agent &&
                            [MemberAccountType.COLLEAGUES].includes(optionId) &&
                            colleagues &&
                            colleagues
                                .filter((member) => member.type === UserType.Agent)
                                ?.map((member) => (
                                    <Member
                                        key={member._id}
                                        member={member}
                                        canDelete={
                                            data.user.__typename === 'User' &&
                                            data.user._id !== member._id &&
                                            (data.user.organisation.members?.length ?? 1) > 1
                                        }
                                        organisationID={
                                            data.user.__typename === 'User' ? data.user.organisation._id : ''
                                        }
                                        isTrustedUser={false}
                                        setIsTrustedUser={setIsTrustedUser}
                                        setUserID={setUserID}
                                        setOrganisationID={setOrganisationID}
                                        setShowRemoveMemberPopup={setShowRemoveMemberPopup}
                                        setShowResendPopup={setShowResendPopup}
                                    />
                                ))}
                        {optionId &&
                            typeData?.user.type !== UserType.Agent &&
                            [MemberAccountType.TRUSTED].includes(optionId) &&
                            trustedUsers?.map((trustedUser) => (
                                <Member
                                    key={trustedUser._id}
                                    member={trustedUser}
                                    canDelete={data.user.__typename === 'User' && data.user._id !== trustedUser._id}
                                    organisationID={data.user.__typename === 'User' ? data.user.organisation._id : ''}
                                    isTrustedUser={true}
                                    setIsTrustedUser={setIsTrustedUser}
                                    setUserID={setUserID}
                                    setOrganisationID={setOrganisationID}
                                    setShowRemoveMemberPopup={setShowRemoveMemberPopup}
                                    setShowResendPopup={setShowResendPopup}
                                />
                            ))}
                        {data.user.organisation.pendingMembers && data.user.organisation.pendingMembers.length > 0 && (
                            <>
                                <h2 className="w-full font-bold mt-4">Pending Members</h2>
                                <p className="w-full text-sm">
                                    These are members of your team awaiting an invite. Send them an invite now so they
                                    can get started on Kato.
                                </p>
                                {data.user.organisation.pendingMembers?.map((member, index) => (
                                    <PendingMember
                                        key={index}
                                        organisationID={
                                            data.user.__typename === 'User' ? data.user.organisation._id : ''
                                        }
                                        setMemberEmail={setMemberEmail}
                                        setOrganisationID={setOrganisationID}
                                        setShowRemoveMemberPopup={setShowRemoveMemberPopup}
                                        setShowUserInviteWindow={setShowUserInviteWindow}
                                    >
                                        {member}
                                    </PendingMember>
                                ))}
                            </>
                        )}
                    </div>
                </>
            )}
        </div>
    );
};

export const trustedFilterConfig = (userType?: UserType): FilterConfig => [
    ...(userType && userType !== UserType.Agent
        ? [
              {
                  id: 'organisation',
                  label: 'Company Name',
                  type: FilterType.AsyncList,
                  query: useGetUserOrganisationQuery,
                  path: '[$distinct(user.organisation.trustedUsers.organisation.{ "value": _id, "label": name })]'
              }
          ]
        : [])
];

export const getUserFields = (): RadioValue[] => [
    { id: UserSortFields.FirstName, label: 'First Name' },
    { id: UserSortFields.LastLogin, label: 'Recently Active' }
];

export default Team;
