import { FieldInputProps, FormikProps } from 'formik';
import React, { Ref, useState } from 'react';
import { ChangeHandler, FieldError } from 'react-hook-form';

import { ReactComponent as Cross } from '../../assets/images/cross/currentColor.svg';

export type EmailMultiInputProps = {
    placeholder?: string;
    label?: string | boolean;
    value?: any;
    defaultValue?: any;
    name?: string;
    disabled?: boolean;
    required?: boolean;
    autoFocus?: boolean;
    autoComplete?: string;
    readOnly?: boolean;
    border?: string;
    className?: string;
    containerClassName?: string;
    error?: FieldError;
    onBlur?: (() => void) | ChangeHandler;
    onClick?: () => void;
    setEmails: any;
    inputClassName?: string;
    setOnFocus?: React.Dispatch<React.SetStateAction<boolean>>;
    initialChips?: string[] | null;
    field: FieldInputProps<any>;
    form: FormikProps<any>;
    labelClassName?: string;
    chipIconClassName?: string;
    chipClassName?: string;
    chipCloseClassName?: string;
};

const EmailMultiInput = React.forwardRef(
    (
        {
            placeholder,
            value,
            defaultValue,
            name,
            label,
            disabled = false,
            required = false,
            readOnly = false,
            className,
            containerClassName,
            error,
            onClick,
            setEmails,
            inputClassName,
            setOnFocus,
            initialChips,
            field,
            form,
            labelClassName,
            chipIconClassName,
            chipClassName,
            chipCloseClassName
        }: EmailMultiInputProps,
        ref: Ref<any>
    ) => {
        const [chips, setChips] = useState<string[]>(initialChips ?? []);
        const [inputValue, setInputValue] = useState<string>('');
        const [emailValidation, setEmailValidation] = useState<string>('');

        const checkForDuplicates = () => {
            return chips.find((chip) => chip === inputValue);
        };

        const emailIsValid = (email: string) => {
            return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
        };

        const addToChips = (e: any) => {
            if (emailIsValid(inputValue)) {
                if (checkForDuplicates()) {
                    setEmailValidation('Email already added');
                } else {
                    const newChipsArray = [...chips];
                    newChipsArray.push(inputValue);
                    setChips(newChipsArray);
                    setEmails(newChipsArray);
                    e.currentTarget.value = '';
                    setInputValue('');
                    setEmailValidation('');
                }
            } else setEmailValidation('Email not valid');
        };

        const removeChips = (index: number) => {
            const newChipsArray = [...chips];
            newChipsArray.splice(index, 1);
            setChips(newChipsArray);
            setEmails(newChipsArray);
        };

        const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
            setChips(chips);
            setInputValue(e.target.value);
        };

        return (
            <>
                {label && !error && (
                    <p className={`text-gray-600 font-medium text-sm mb-0.5 ${labelClassName}`}>
                        {label}
                        <span className="text-newTeal-main ml-1">{required && '*'}</span>
                    </p>
                )}
                {error && (
                    <p className="absolute top-0 left-0 text-red-500 font-medium text-sm mb-0.5">{error.message}</p>
                )}
                <div className={`border relative w-full flex ${containerClassName}`}>
                    <div
                        className={`flex flex-wrap items-center z-20 relative w-full mr-1 px-2 ${inputClassName}`}
                        style={{ display: 'contents !important' }}
                    >
                        {chips &&
                            chips.map((chip, i) => (
                                <div className={`${chipClassName ?? 'chip mr-1 mb-3'} mt-1`} key={i}>
                                    <div className="chip-content">{chip}</div>
                                    <div
                                        className={`${chipCloseClassName ?? 'chip-close'} h-5 w-5`}
                                        onClick={() => removeChips(i)}
                                    >
                                        <Cross className={`${chipIconClassName ?? 'chip-svg'} h-4 w-4`} />
                                    </div>
                                </div>
                            ))}
                        <input
                            type={'email'}
                            placeholder={placeholder}
                            value={value}
                            defaultValue={defaultValue}
                            name={name}
                            disabled={disabled}
                            required={required}
                            readOnly={readOnly}
                            onBlur={(e) => {
                                inputValue && addToChips(e);
                                setOnFocus && setOnFocus(false);
                            }}
                            onKeyDown={(e) => e.key === 'Enter' && addToChips(e)}
                            onFocus={() => setOnFocus && setOnFocus(true)}
                            onChange={handleChange}
                            onClick={onClick}
                            ref={ref}
                            className={`pr-2 h-10 mb-1 rounded focus:outline-none flex bg-white ${className}`}
                            style={{
                                maxWidth: '100%',
                                minWidth: '30%',
                                flexGrow: '3'
                            }}
                        />
                    </div>
                </div>
                {emailValidation && <p className="left-0 text-red-500 font-medium text-sm mb-0.5">{emailValidation}</p>}
                {form?.touched[field.name] && form?.errors[field.name] && (
                    <div className="text-red-600">{form?.errors[field?.name]}</div>
                )}
            </>
        );
    }
);

export default EmailMultiInput;
