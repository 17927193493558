import React from 'react';

import ClauseActions from './Actions';
import ClauseContext from './Context';
import Info from './Info';
import useMobileScreen from '../../../../../shared/hooks/useMobileScreen';

const ActionsBar = () => {
    const { isEditing } = React.useContext(ClauseContext);

    const mobileScreen = useMobileScreen();

    if (!mobileScreen) return null;

    return (
        <div className={`flex items-center px-4 bg-navy-lightest h-14 rounded-b relative headerVisible`}>
            {/* Actions */}
            <ClauseActions side="left" />

            {/* History */}
            {!isEditing && <Info noBorder={true} />}
        </div>
    );
};

export default ActionsBar;
