import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';

import {
    AvatarType,
    UnitCondition,
    UnitUse,
    useGetBuildingsAutofillDetailsQuery,
    useGetUserTypeQuery,
    useUploadAvatarMutation
} from '../../generated/graphql';
import { handleFetchError } from '../../shared/helpers';
import { InputType } from '../../shared/Inputs';
import Autofill from '../../shared/Inputs/Autofill';
import Loading from '../../shared/Loading';
import useNewUnitComponent from '../../shared/NewUnit';
import useNewUnit from '../../shared/NewUnit/hooks/useNewUnit';
import Window from '../../shared/Window';

type NewUnitWindowProps = {
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
    refetch?: () => void;
    countsRefetch?: () => void;
};

const NewUnitWindow = ({ refetch, countsRefetch, ...props }: NewUnitWindowProps) => {
    const history = useHistory();
    // Error message
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    // Form values
    const buildingNameRef = useRef<HTMLInputElement>(null);
    const unitNameRef = useRef<HTMLInputElement>(null);
    const urlRef = useRef<HTMLInputElement>(null);
    const addressLine1Ref = useRef<HTMLInputElement>(null);
    const addressLine2Ref = useRef<HTMLInputElement>(null);
    const addressCityRef = useRef<HTMLInputElement>(null);
    const addressPostCodeRef = useRef<HTMLInputElement>(null);
    const [use, setUse] = useState<UnitUse | null>(null);
    const [condition, setCondition] = useState<UnitCondition | null>(null);
    const areaRef = useRef<HTMLInputElement>(null);
    const [emails, setEmails] = useState<string[]>([]);
    const [newAvatar, setNewAvatar] = useState<any>(null);
    const [associatedCompany, setAssociatedCompany] = useState<string>('');

    // User Type Check
    const { data: typeData, loading: typeLoading, error: typeError } = useGetUserTypeQuery();

    // Add Unit Image
    const [mediaCreate, { error: uploadError, loading: uploadGenerateLoading }] = useUploadAvatarMutation();
    const [uploadLoading, setUploadLoading] = useState<boolean>(false);

    useEffect(() => {
        // Check for errors
        handleFetchError('Could not load user', typeError, typeData?.user);
    }, [typeData, typeError]);

    // New Unit component
    const { setAddressLine1Value, setAddressLine2Value, setAddressCityValue, setAddressPostCodeValue, NewUnit } =
        useNewUnitComponent({
            unitNameRef,
            addressLine1Ref,
            urlRef,
            addressLine2Ref,
            addressCityRef,
            addressPostCodeRef,
            areaRef,
            emails,
            setEmails,
            userType: typeData?.user.__typename === 'User' && typeData.user.type,
            setUse,
            setCondition,
            setNewAvatar,
            setAssociatedCompany,
            associatedCompany
        });

    // Building Autocomplete
    const {
        data: buildingsData,
        loading: buildingsLoading,
        error: buildingsError
    } = useGetBuildingsAutofillDetailsQuery();
    const [buildings, setBuildings] = useState<
        {
            id: string;
            text: string;
            subtext?: string;
            address?: {
                line1?: string | null;
                line2?: string | null;
                city?: string | null;
                postCode?: string | null;
            };
        }[]
    >([]);

    useEffect(() => {
        // Check for errors
        handleFetchError('Could not load your buildings', buildingsError, buildingsData?.units.results);

        // Check if we got any buildings back
        if (buildingsData) {
            // Format for autofill type
            const buildings:
                | {
                      id: string;
                      text: string;
                      subtext: string;
                      organisation: string;
                      unitName: string;
                      address: {
                          line1: string | null | undefined;
                          line2: string | null | undefined;
                          city: string | null | undefined;
                          postCode: string | null | undefined;
                      };
                  }[]
                | undefined = [];

            buildingsData?.units?.results.map((unit) => {
                const item = {
                    id: unit._id,
                    // If there is another building with the same name, under another organisation, then append the organisation's name to the building text
                    text: unit.buildingName,
                    subtext: unit.organisation.name,
                    organisation: unit.organisation._id,
                    unitName: unit.name ?? '',
                    address: {
                        line1: unit.address.line1,
                        line2: unit.address.line2,
                        city: unit.address.city,
                        postCode: unit.address.postCode
                    }
                };

                // Remove duplicate buildings
                if (
                    buildings.find(
                        (building) =>
                            building.text === item.text &&
                            building.organisation === item.organisation &&
                            building.address.line1 === item.address.line1 &&
                            building.address.line2 === item.address.line2 &&
                            building.address.city === item.address.city &&
                            building.address.postCode === item.address.postCode
                    )
                )
                    return;

                buildings.push(item);
            });

            buildings && setBuildings(buildings);
        }
    }, [buildingsData, buildingsError]);

    // User clicked autocompleted building
    const handleBuildingClick = (id: string) => {
        const building = buildings.find((building) => building.id === id);

        if (!building) {
            // Enable all inputs
            addressLine1Ref.current?.removeAttribute('disabled');
            addressLine2Ref.current?.removeAttribute('disabled');
            addressCityRef.current?.removeAttribute('disabled');
            addressPostCodeRef.current?.removeAttribute('disabled');
            return;
        }

        // Disable all inputs
        addressLine1Ref.current?.setAttribute('disabled', 'true');
        addressLine2Ref.current?.setAttribute('disabled', 'true');
        addressCityRef.current?.setAttribute('disabled', 'true');
        addressPostCodeRef.current?.setAttribute('disabled', 'true');

        // Set input values
        building.address?.line1 && setAddressLine1Value(building.address?.line1);
        building.address?.line2 && setAddressLine2Value(building.address?.line2);
        building.address?.city && setAddressCityValue(building.address?.city);
        building.address?.postCode && setAddressPostCodeValue(building.address?.postCode);
    };

    const newUnit = useNewUnit(setErrorMessage, typeData?.user.__typename === 'User' && typeData.user.type);

    const handleConfirmClick = async () => {
        const buildingName = buildingNameRef.current?.value;
        const unitName = unitNameRef.current?.value;
        const addressLine1 = addressLine1Ref.current?.value;
        const addressLine2 = addressLine2Ref.current?.value;
        const addressCity = addressCityRef.current?.value;
        const addressPostCode = addressPostCodeRef.current?.value;
        const unitUse = use;
        const unitCondition = condition;
        const unitArea = areaRef.current?.value;
        const url = urlRef.current?.value;
        const unitAssociatedCompany = associatedCompany;

        const newUnitSuccess = await newUnit(
            buildingName,
            unitName,
            addressLine1,
            addressLine2,
            addressCity,
            addressPostCode,
            unitUse,
            unitCondition,
            unitArea,
            emails,
            url,
            unitAssociatedCompany
        );

        if (newUnitSuccess && newAvatar) {
            const fileParts = newAvatar.name.split('.');
            const fileType = fileParts[1];

            const res = await mediaCreate({
                variables: {
                    type: AvatarType.Unit,
                    filetype: newAvatar.type,
                    id: newUnitSuccess.unitCreate.__typename === 'Unit' ? newUnitSuccess.unitCreate._id : ''
                }
            });

            setUploadLoading(true);

            if (uploadError) {
                handleFetchError('Something went wrong', uploadError, res?.data);
                return;
            }

            const signedUrl: string =
                res.data?.avatarCreate.__typename === 'S3Payload' ? res.data.avatarCreate.signedUrl : '';

            await axios.put(signedUrl, newAvatar, {
                headers: {
                    'Content-Type': fileType,
                    'Access-Control-Allow-Origin': '*'
                }
            });

            setUploadLoading(false);
        }

        if (newUnitSuccess) {
            refetch && refetch();
            countsRefetch && countsRefetch();
            props.setShow(false);
            history.push(`/units/${newUnitSuccess?.unitCreate.__typename === 'Unit' && newUnitSuccess.unitCreate._id}`);
        }
    };

    if (typeLoading || buildingsLoading || uploadGenerateLoading || uploadLoading)
        return (
            <Window title="New Space" width="w-2/5" {...props}>
                <Loading />
            </Window>
        );
    if (typeError) return null;

    return (
        <Window
            title="New Space"
            width="w-2/5"
            className="mt-10"
            primaryBtn
            onPrimaryBtnClick={handleConfirmClick}
            {...props}
        >
            <form
                className="flex flex-col px-2 w-full py-4 mb-6 md:mb-0"
                onSubmit={(event) => {
                    event.preventDefault();
                    handleConfirmClick();
                }}
            >
                <div className="flex flex-row flex-wrap md:flex-no-wrap w-full gap-4 mb-4">
                    <Autofill
                        type={InputType.text}
                        data={buildings}
                        onItemClick={handleBuildingClick}
                        placeholder="Building Name"
                        label="Building Name (optional)"
                        className="w-full md:w-1/2"
                        showOnClick
                        ref={buildingNameRef}
                    />
                </div>

                {/* New Unit form */}
                {NewUnit}

                {errorMessage && (
                    <div className="bg-red-200 border border-red-600 rounded w-full py-2 px-4 mt-4">
                        <span className="text-center text-red-600">{errorMessage}</span>
                    </div>
                )}
            </form>
        </Window>
    );
};

export default NewUnitWindow;
