import React, { useContext, useEffect, useState } from 'react';

import {
    GetUserOrganisationDocument,
    SingleUnitCardFragment,
    useGetUserOrganisationQuery,
    useGetUserProfileQuery,
    UserType,
    useUnitBulkAddActorsMutation
} from '../../generated/graphql';
import { BulkSelectContext } from '../../shared/bulk/context';
import { FilterContext } from '../../shared/Filter/context';
import { transformFilters } from '../../shared/Filter/helpers';
import Select from '../../shared/Inputs/Select';
import Window from '../../shared/Window';

type BulkAddUsersWindowProps = {
    units: SingleUnitCardFragment[];
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
};

const BulkAddUsersWindow = ({ units, setShow }: BulkAddUsersWindowProps) => {
    const { data: userOrganisationData } = useGetUserOrganisationQuery();
    const { selectedItems, all, saveSelectMode, saveSelectAll, selectedUnits, saveSelectedItems } =
        useContext(BulkSelectContext);
    const { values, filters } = useContext(FilterContext);
    const unitsData = all ? units : selectedUnits;

    Array.prototype.count = function (lit = false) {
        if (!lit) {
            return this.length;
        } else {
            var count = 0;
            for (var i = 0; i < this.length; i++) {
                if (lit == this[i]) {
                    count++;
                }
            }
            return count;
        }
    };

    // Error message
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [emails, setEmails] = useState<string[]>([]);
    const [pickerEmails, setPickerEmails] = useState<string[]>([]);

    const { data: typeData } = useGetUserProfileQuery();

    const [addUser, { error: addUserError, loading: addUserLoading }] = useUnitBulkAddActorsMutation();

    const isLandlordOrAdmin =
        typeData?.user.type && [UserType.Admin, UserType.Landlord, UserType.Agent].includes(typeData?.user.type);

    useEffect(() => {
        const newEmailArray = [...(pickerEmails ?? [])];
        const duplicatedEmailsInside = [] as any[];

        if (selectedUnits.length === 1) {
            selectedUnits.map((unit) => {
                unit?.actors.find((actor) => {
                    if (actor.type === 'AGENT') {
                        newEmailArray.push(actor.email);
                    }
                });
            });
            const emailsToRemove = newEmailArray.filter((val, id, array) => {
                return array.indexOf(val) == id;
            });
            setPickerEmails(emailsToRemove);
        } else {
            selectedUnits.map((unit) => {
                unit?.actors.find((actor) => {
                    if (actor.type === 'AGENT') {
                        newEmailArray.push(actor.email);
                        let count = 0;
                        newEmailArray.forEach((email) => {
                            count = newEmailArray.count(email);
                            if (count === selectedItems.length) {
                                duplicatedEmailsInside.push(email);
                            }
                        });
                        const emailsToRemove = newEmailArray.filter((email) => {
                            const agentsSet = new Set(duplicatedEmailsInside);
                            return agentsSet.has(email);
                        });
                        setPickerEmails(emailsToRemove);
                    }
                });
            });
        }
    }, [units]);

    const pickerData =
        userOrganisationData &&
        userOrganisationData?.user.organisation.trustedUsers.filter((trustedUser) => {
            const actorsEmails = pickerEmails.map((email) => email);
            const agentsSet = new Set(actorsEmails);
            return !agentsSet.has(trustedUser.email);
        });

    const handleAdd = async () => {
        if (!emails) return setErrorMessage('Please enter an email address');

        await addUser({
            variables: {
                dealIDs: all ? [] : selectedItems,
                emails,
                filter:
                    units.length === selectedItems.length
                        ? {
                              ...transformFilters(filters, values)
                          }
                        : {},
                all: all
            },
            refetchQueries: [{ query: GetUserOrganisationDocument }]
        });

        if (!addUserError) {
            setShow(false);
            saveSelectAll(false);
            saveSelectMode(false);
            saveSelectedItems([]);
        } else return;
    };

    return (
        <Window
            title="Add User"
            width="w-1/3"
            primaryBtn
            onPrimaryBtnClick={() => handleAdd()}
            primaryBtnText={addUserLoading ? 'Saving...' : 'Confirm'}
            setShow={setShow}
        >
            <>
                <form onSubmit={() => handleAdd()} className="pt-10 md:pt-4  w-full flex flex-col gap-2">
                    <div>
                        <p className="px-4  text-gray-600 font-medium text-sm mb-0.5">
                            Spaces Selected ({selectedItems.length})
                        </p>
                        <div className="bg-gray-200 py-2 border-t border-b border-navy-light w-full">
                            {unitsData.map((selectedUnit) => {
                                return (
                                    <p className="px-4 text-gray-800 font-medium text-xs mb-0.5">
                                        {(selectedUnit.name && selectedUnit.name + ', ') +
                                            (selectedUnit.buildingName && selectedUnit.buildingName + ', ') +
                                            (selectedUnit.address.line1 && selectedUnit.address.line1)}
                                    </p>
                                );
                            })}
                        </div>
                    </div>
                    <div className="p-4">
                        {isLandlordOrAdmin && userOrganisationData && (
                            <Select
                                data={Object.values(pickerData).map((trustedUser) => {
                                    return {
                                        text: trustedUser.activated
                                            ? trustedUser.firstName +
                                              ' ' +
                                              trustedUser.lastName +
                                              ' (' +
                                              trustedUser.organisation.name +
                                              ')'
                                            : trustedUser.email,
                                        id: trustedUser.email
                                    };
                                })}
                                label="Users"
                                placeholder={emails && emails.length > 0 ? 'Type to add another email' : 'Enter email'}
                                isMulti
                                blackStyle
                                createable
                                color="black"
                                chipBackground="#e3e3e3"
                                className="md:mr-2 bg-navy-lightest w-full mb-4"
                                onChange={(value: any) => {
                                    if (!value || value.length === 0) {
                                        setEmails([]);
                                        return;
                                    }

                                    const users = value.map((item: any) => {
                                        return item.value;
                                    });

                                    setEmails(users);
                                }}
                            />
                        )}

                        {errorMessage && (
                            <div className="bg-red-200 border border-red-600 rounded py-2 px-4 my-4 mt-2">
                                <span className="text-center text-red-600">{errorMessage}</span>
                            </div>
                        )}
                    </div>
                </form>
            </>
        </Window>
    );
};

export default BulkAddUsersWindow;
