import React, { useState } from 'react';

import { DealBreaker, useSetDealBreakerMutation } from '../../../generated/graphql';
import { handleFetchError } from '../../helpers';
import Loading from '../../Loading';
import SuccessIcon from '../../SuccessIcon';
import Window from '../../Window';

type DealBreakerWindowProps = {
    clauseID: string;
    parentID: string;
    dealID: string;
    dealBreaker?: DealBreaker | null;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
};

enum SubmitType {
    SET,
    REMOVE
}

const DealBreakerWindow = ({ clauseID, parentID, dealID, dealBreaker, ...props }: DealBreakerWindowProps) => {
    // Error message
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    // Deal breaker reason
    const [message, setMessage] = useState<string | null>(dealBreaker?.message ?? null);

    // Show success icon
    const [success, setSuccess] = useState<SubmitType | null>(null);

    const [setDealBreaker, { error, loading }] = useSetDealBreakerMutation();

    // Submit button
    const handleSubmit = async (type: SubmitType = SubmitType.SET) => {
        if (type === SubmitType.SET && (!message || message.trim().length === 0))
            return setErrorMessage('Please enter a reason for setting this as a locked clause');

        const { data } = await setDealBreaker({
            variables: {
                id: clauseID,
                parentID,
                dealID,
                dealBreaker: type === SubmitType.SET ? true : false,
                message: type === SubmitType.SET ? message : null
            },
            optimisticResponse: {
                dealClauseUpdate: {
                    __typename: 'Clause',
                    _id: clauseID,
                    dealBreaker: {
                        isDealBreaker: true,
                        message
                    }
                }
            }
        });

        if (error || data?.dealClauseUpdate.__typename !== 'Clause') {
            return handleFetchError('Failed to set the clause as a locked clause', error, data?.dealClauseUpdate);
        }

        // Show success icon, then close the window
        setSuccess(type);
        setTimeout(() => props.setShow(false), 1500);
    };

    if (loading)
        return (
            <Window title="Reason For Locked Clause" width="w-2/5" {...props}>
                <Loading />
            </Window>
        );

    if (success !== null)
        return (
            <Window title="Reason For Locked Clause" width="w-2/5" {...props}>
                <div className="w-full flex flex-col items-center justify-center py-10">
                    <SuccessIcon />
                    <h1 className="text-xl font-bold text-center mt-5">
                        {success === SubmitType.REMOVE ? 'Locked Clause Removed' : 'Locked Clause Set'}
                    </h1>
                </div>
            </Window>
        );

    return (
        <Window
            title="Reason For Locked Clause"
            width="w-2/5"
            primaryBtn
            primaryBtnText={dealBreaker?.isDealBreaker ? 'Update' : 'Set Locked Clause'}
            onPrimaryBtnClick={() => handleSubmit(SubmitType.SET)}
            secondaryBtn={dealBreaker?.isDealBreaker}
            secondaryBtnText="Remove Locked Clause"
            onSecondaryBtnClick={() => handleSubmit(SubmitType.REMOVE)}
            {...props}
        >
            <>
                <form className="flex flex-col p-2" onSubmit={(e) => e.preventDefault()}>
                    <p className="text-gray-600 font-medium text-sm mb-0.5">Reason</p>
                    <textarea
                        autoFocus
                        className="w-full p-3 border border-navy-light focus:border-newTeal-main rounded focus:outline-none"
                        placeholder="Please enter a reason as to why this clause is a locked clause"
                        rows={10}
                        value={message ?? ''}
                        onChange={(e) => setMessage(e.target.value)}
                    />
                </form>
                {errorMessage && (
                    <div className="bg-red-200 border border-red-600 rounded py-2 px-4 my-4 mt-2">
                        <span className="text-center text-red-600">{errorMessage}</span>
                    </div>
                )}
            </>
        </Window>
    );
};

export default DealBreakerWindow;
