import React from 'react';

type LoadingProps = {
    className?: string;
};

const Loading = ({ className }: LoadingProps) => {
    return (
        <div className={`w-full h-full flex items-center justify-center ${className}`}>
            <div className="lds-ripple flex items-center justify-center">
                <div></div>
                <div></div>
            </div>
        </div>
    );
};

export default Loading;
