import { isEmpty } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { ReactComponent as Info } from '../../../assets/images/info/black_new.svg';
import {
    DealApprovalSendType,
    DealDetailsFragment,
    DealSendType,
    DealUserParty,
    useGetUserTypeQuery,
    UserType,
    useSendDealApprovalMutation,
    useSendDealMutation
} from '../../../generated/graphql';
import { Button } from '../../../shared/button';
import Input, { InputType } from '../../../shared/Inputs';
import EmailMultiInput from '../../../shared/Inputs/EmailMultiInput';
import Loading from '../../../shared/Loading';
import SuccessIcon from '../../../shared/SuccessIcon';
import Window from '../../../shared/Window';
import Modal from '../../../v2/components/Modal';
import { Option, RadioGroup } from '../../../v2/components/RadioGroup';

type DealSentWindowProps = {
    dealID: string;
    type: DealSendType;
    deal?: DealDetailsFragment | undefined;
    email?: string;
    showEmail?: boolean;
    currentUserParty: DealUserParty;
    approvalID?: string | null | undefined;
    redirect?: string;
    approvalResponse?: DealApprovalSendType;
    setWindowSuccess?: React.Dispatch<React.SetStateAction<boolean>>;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
    forceReadinessClose?: React.Dispatch<React.SetStateAction<boolean>>;
    onSend?: () => void;
    isCompleted?: boolean;
};

const DealSendWindow = ({
    dealID,
    type,
    email,
    setWindowSuccess = () => {},
    currentUserParty,
    approvalID,
    showEmail = true,
    redirect,
    forceReadinessClose,
    approvalResponse,
    setShow,
    onSend,
    isCompleted
}: DealSentWindowProps) => {
    const history = useHistory();

    // Error message
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    // Success message
    const [success, setSuccess] = useState<boolean>(false);
    // Reset Success in case the deal needs to be sent to another party
    useEffect(() => {
        setTimeout(() => {
            setSuccess(false);
            setWindowSuccess(false);
        }, 1500);
    }, [success, setWindowSuccess]);

    const emailRef = useRef<HTMLInputElement>(null);
    const [message, setMessage] = useState<string>('');

    const [accountType, setAccountType] = useState<UserType>();

    let title = '';

    switch (type) {
        case DealSendType.Approver:
            if (approvalResponse === DealApprovalSendType.Reject) title = 'Send Changes';
            else title = isCompleted ? 'Share Heads of Terms' : 'Send For Approval';
            break;
        case DealSendType.Tenant:
            title = 'Send To Tenant';
            break;
        case DealSendType.Landlord:
            title = 'Send To Counterparty';
            break;
        default:
            title = 'Share Heads of Terms';
    }
    const [previewEmails, setPreviewEmails] = useState<string[]>([]);

    // Reapproval
    const [reapprove, setReapprove] = useState<boolean>(false);

    const [sendDeal, { loading: sendDealLoading }] = useSendDealMutation();
    const [shareDeal, { loading: shareDealLoading }] = useSendDealApprovalMutation();
    const { data: userData, loading: userLoading } = useGetUserTypeQuery();

    const handleConfirm = async () => {
        const email = emailRef.current?.value;

        if (showEmail && type === DealSendType.Tenant && !accountType) {
            return setErrorMessage('Please select who you are sending deal to');
        }

        if ((!email || email.length === 0) && showEmail && type === DealSendType.Tenant)
            return setErrorMessage('Please enter an email');
        if (!message || message.length === 0)
            return setErrorMessage('Please enter a message to send along with the deal');
        if ((!previewEmails || previewEmails.length === 0) && type !== DealSendType.Tenant && isCompleted)
            return setErrorMessage('Please enter an email');

        const emails = isEmpty(email) ? previewEmails : email;

        const variables = {
            dealID,
            emails,
            message,
            additionalApproval: reapprove,
            accountType: type === DealSendType.Tenant ? accountType ?? UserType.Tenant : UserType.Landlord
        };

        if (redirect && redirect.trim().length > 0) {
            // After 1 second redirect to preview
            setTimeout(() => history.push(redirect), 1500);
        }

        switch (type) {
            // @ts-ignore
            case DealSendType.Approver:
            // eslint-disable-next-line
            case DealSendType.Other:
                await shareDeal({
                    variables: {
                        ...variables,
                        type: approvalResponse,
                        approvalID,
                        currentUserParty,
                        currentUserEmail: userData?.user.email
                    },
                    onCompleted: () => {
                        sessionStorage.setItem('round_window_shown', '0');
                        setShow(false);
                    },
                    onError: () => setShow(false)
                });
                break;
            default:
                await sendDeal({
                    variables: {
                        ...variables,
                        type
                    },
                    onCompleted: () => {
                        sessionStorage.setItem('round_window_shown', '0');
                        setShow(false);
                    },
                    onError: () => setShow(false)
                });
        }

        setMessage('');
        if (setWindowSuccess) setWindowSuccess(true);
        if (forceReadinessClose) forceReadinessClose(true);

        setSuccess(true);
        onSend && onSend();

        if (redirect && redirect.trim().length > 0 && (type === DealSendType.Tenant || type === DealSendType.Landlord))
            // After 1 second redirect to preview
            setTimeout(() => history.push(redirect), 1500);
        // Otherwise close the window
        else
            setTimeout(() => {
                setShow(false);
                history.push({
                    search: ''
                });
            }, 1500);
    };

    if (sendDealLoading || shareDealLoading || userLoading)
        return (
            <Window title={title} setShow={setShow} width="w-2/5">
                <Loading />
            </Window>
        );

    const displayMessage = (type: DealSendType) => {
        switch (type) {
            case DealSendType.Landlord:
                return <p>This action will send the Heads of Terms back to the person that sent you the offer.</p>;
            case DealSendType.Approver:
                return (
                    <p>
                        Send the Heads of Terms to seek feedback, or approval from anyone on your side of the deal (they
                        don’t have to be registered with Kato to review the Heads of Terms).
                    </p>
                );
            case DealSendType.Other:
                return (
                    <p>
                        Send the Heads of Terms to anyone on your side of the deal (they don’t have to be registered
                        with Kato to review the Heads of Terms).
                    </p>
                );
        }
    };

    return (
        <Modal.Window className="xs:w-full xs:h-full" isOpen={true} close={() => setShow(false)} title={title}>
            {success ? (
                <div className="w-full flex flex-col items-center justify-center py-10">
                    <SuccessIcon />
                    <h1 className="text-xl font-bold text-center mt-5">Message Sent</h1>
                </div>
            ) : (
                <div>
                    {currentUserParty === DealUserParty.Tenant && !isCompleted && (
                        <div className="flex inline-flex items-center w-full px-4 py-3 bg-navy-lightest">
                            <p className="text-xs inline-flex items-center ">
                                <Info className="w-14 h-4" />
                                {displayMessage(type)}
                            </p>
                        </div>
                    )}
                    {showEmail &&
                        (email && email.length > 0 ? (
                            <Input
                                type={InputType.email}
                                value={email}
                                ref={emailRef}
                                placeholder="Send to"
                                label="Email"
                                className="bg-grey-100 mb-2 w-2/3"
                                disabled
                            />
                        ) : type === DealSendType.Tenant ? (
                            <div className="space-y-2">
                                <RadioGroup
                                    label="Type"
                                    description="Select whether you would like to send the deal directly to the Tenant or to their representing Agent"
                                    value={accountType}
                                    setValue={setAccountType}
                                >
                                    <Option label="Tenant" value={UserType.Tenant} />
                                    <Option label="Tenant Rep Agent" value={UserType.Agent} />
                                </RadioGroup>
                                <div>
                                    <div className="text-gray-v2-400 font-bold text-xl">Email</div>
                                    <input
                                        type="email"
                                        className="rounded bg-whitetext-newGray-darkishborder border-transparent focus:outline-none focus:border-newTeal-main w-full p-2"
                                        onChange={(e) => setPreviewEmails([e.target.value])}
                                        ref={emailRef}
                                        placeholder="Email"
                                    />
                                </div>
                            </div>
                        ) : (
                            <EmailMultiInput
                                setEmails={setPreviewEmails}
                                ref={emailRef}
                                name="email"
                                placeholder={previewEmails.length > 0 ? 'Type to add another email' : 'Enter email'}
                                label="Email"
                                className="bg-whitetext-newGray-darkish"
                                containerClassName="rounded bg-whitetext-newGray-darkish"
                                labelClassName="text-gray-v2-400 font-bold text-xl"
                            />
                        ))}
                    <div className="text-gray-v2-400 font-bold text-xl mt-2">Message</div>
                    <textarea
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        rows={10}
                        className="w-full p-2 bg-white text-newGray-darkish border border-gray-main rounded focus:outline-none focus:border-newTeal-main"
                        placeholder="Send a message..."
                    ></textarea>
                    {type === DealSendType.Approver && approvalResponse === DealApprovalSendType.Reject && (
                        <>
                            <label className="my-4 flex items-center">
                                <input
                                    type="checkbox"
                                    onChange={() => setReapprove(!reapprove)}
                                    className="leading-tight w-6 h-6 mr-2 form-checkbox p-2 rounded bg-white text-newTeal-main focus:shadow-none focus:outline-none"
                                />
                                <span className="font-medium text-newGray-darkish">
                                    Do you want to approve the terms again after revision?
                                </span>
                            </label>
                        </>
                    )}

                    {errorMessage && (
                        <div className="bg-red-200 border border-red-600 rounded py-2 px-4 my-4 mt-2">
                            <span className="text-center text-red-600">{errorMessage}</span>
                        </div>
                    )}
                </div>
            )}
            <Modal.Footer>
                {!success && (
                    <Button text="white" className="bg-newTeal-main w-full mb-12" onClick={handleConfirm}>
                        Send
                    </Button>
                )}
            </Modal.Footer>
        </Modal.Window>
    );
};

export default DealSendWindow;
