import { Reference, StoreObject, useApolloClient } from '@apollo/client';

import { DealDetailsFragment, useUpdateSectionMutation } from '../../../generated/graphql';
import { move } from '../../helpers';

const useMoveSection = (deal: DealDetailsFragment | null, id: string) => {
    const client = useApolloClient();
    const [moveSection] = useUpdateSectionMutation();

    return async (from: number, to: number) => {
        // Move in local cache first
        client.cache.modify({
            id: client.cache.identify({
                __typename: 'Deal',
                _id: deal?._id
            }),
            fields: {
                sections(sections: (Reference | StoreObject | undefined)[] = []) {
                    const newSections = move(from, to, ...sections);

                    return newSections;
                }
            }
        });

        // Then tell API
        await moveSection({
            fetchPolicy: 'no-cache',
            variables: {
                dealID: deal?._id ?? '',
                id,
                from,
                to
            }
        });
    };
};

export default useMoveSection;
