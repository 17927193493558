import React from 'react';

import NoTemplatesImage from '../../assets/images/noData/templates.png';
import { AddButton } from '../../shared/add-button';

type NoTemplatesProps = {
    setShowNewTemplate: React.Dispatch<React.SetStateAction<boolean>>;
};

const NoTemplates = ({ setShowNewTemplate }: NoTemplatesProps) => {
    return (
        <div className="h-full w-full flex flex-col items-center justify-center">
            <div
                className="h-64 w-64 bg-no-repeat bg-contain"
                style={{
                    backgroundImage: `url(${NoTemplatesImage})`
                }}
            />
            <div className="flex flex-col items-center text-newGray-darkish text-center">
                <p className="font-semibold mt-4">Create your first Template</p>
                <p className="text-sm mt-1">
                    Select the button below to set up your
                    <br /> 'Heads of Terms' Templates on{' '}
                    <span className="font-semibold">
                        <i>K</i>ato
                    </span>
                </p>
            </div>
            <AddButton
                onClick={() => setShowNewTemplate(true)}
                height="10"
                className={
                    'pr-2 mt-3 bg-newTeal-light text-newGray-darkest hover:text-white hover:bg-newTeal-main border-none w-40'
                }
            >
                Template
            </AddButton>
        </div>
    );
};

export default NoTemplates;
