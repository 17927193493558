import React from 'react';

import { ReactComponent as Check } from '../../../../../assets/images/check/black.svg';

type StageCompletedProps = {
    className?: string;
};

const StageCompleted = ({ className }: StageCompletedProps) => {
    return (
        <div className={`w-full flex justify-center mb-4 ${className}`}>
            <div className="bg-white border border-newTeal-main rounded py-3 px-10 flex items-center">
                <Check className="w-6 h-6 text-newTeal-main mr-1" />
                <p className="font-semibold text-newTeal-main">Stage Completed</p>
            </div>
        </div>
    );
};

export default StageCompleted;
