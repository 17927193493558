import axios from 'axios';
import { format } from 'date-fns';
import { saveAs } from 'file-saver';
import fileDownload from 'js-file-download';
import _ from 'lodash';
import React, { useContext, useState } from 'react';

import { ReactComponent as Close } from '../../../assets/images/cross/black.svg';
import { ReactComponent as CloudDownload } from '../../../assets/images/download/cloudBlack.svg';
import {
    AdminTaskType,
    Deal,
    File,
    Unit,
    useDownloadFilesMutation,
    useGetLayoutUserInfoQuery,
    UserDownload
} from '../../../generated/graphql';
import { Button } from '../../../shared/button';
import useMobileScreen from '../../../shared/hooks/useMobileScreen';
import Loading from '../../../shared/Loading';
import Window from '../../../shared/Window';
import { WindowContext } from '../../../shared/Window/WindowContext';
import FileCard from '../../../v2/common/FileCard';

type UnitFileModalProps = {
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
    unitDetails?: Unit;
};

type HeaderProps = {
    tenantFiles?: File[];
    files: UnitFile[];
    handleDownloadFiles: (ids: string[]) => Promise<void>;
    isTenantSide: boolean;
    deal?: Deal;
};

export type UnitFile = Pick<File, '_id' | 'name' | 'url' | 'downloads'> & {
    readinessTask?: string;
};

const Header = ({ files, handleDownloadFiles }: HeaderProps) => {
    const { setShow } = useContext(WindowContext);

    const fileIDs = files.map((file) => file._id);

    const isMobile = useMobileScreen();

    const hasFiles = fileIDs.length > 0;

    return (
        <div className="flex flex-col">
            <div className="flex flex-row cursor-pointer mt-2 justify-end">
                <Close onClick={() => setShow(false)} className="w-12" />
            </div>
            <div className="md:flex flex-row justify-between items-center ml-10 mr-10">
                <div className={`flex flex-row items-center`}>
                    <div>
                        <p className="font-semibold text-xl border-b-3 border-newTeal-main">Files</p>
                    </div>
                </div>
                <div className="mt-2 md:mt-0">
                    <Button
                        background={`${hasFiles ? 'newTeal-main' : 'navy-light'}`}
                        text={hasFiles ? 'white' : 'black'}
                        height="10"
                        disabled={!hasFiles}
                        className={`px-4 my-2 whitespace-no-wrap rounded-md font-semibold flex items-center`}
                        onClick={() => handleDownloadFiles([...fileIDs])}
                    >
                        <CloudDownload className="w-6 -h-6 mr-4" />
                        <p className="text-base">Download All {!isMobile ? 'Files' : ''}</p>
                    </Button>
                </div>
            </div>
        </div>
    );
};

export const UnitFileModal = ({ setShow, unitDetails }: UnitFileModalProps) => {
    const { data: userData } = useGetLayoutUserInfoQuery();

    const [downloadFiles] = useDownloadFilesMutation();
    const [loading, setLoading] = useState<boolean>(false);

    const downloadUnitFiles = async (ids: string[]) => {
        setLoading(true);
        const { data } = await downloadFiles({
            variables: {
                ids
            }
        });

        if (data && data.downloadDoc.length > 1) {
            const JSZip = require('jszip');
            let zip = new JSZip();

            await Promise.all(
                data.downloadDoc.map(async (doc) => {
                    const response = await axios.get(doc?.url!, {
                        responseType: 'blob'
                    });
                    zip.file(doc.name!, response.data);
                })
            );

            zip.generateAsync({ type: 'blob' }).then((content: any) => {
                const timestamp = format(new Date(), 'dd MMM yyyy');
                saveAs(content, `${unitDetails?.organisation.name} Readiness Files - ${timestamp}.zip`);
            });
        } else {
            const response = await axios.get(data?.downloadDoc[0]?.url!, {
                responseType: 'blob'
            });
            fileDownload(response.data, data?.downloadDoc[0].name!);
        }
        setLoading(false);
    };
    const isDownloaded = (downloads: UserDownload[]) => {
        const user = downloads?.find((download) => download.user === userData?.user._id);
        return user;
    };

    const tasks = unitDetails?.tasks?.filter(
        (task) => task.task.type === AdminTaskType.File && task.value && task.value?.__typename === 'File'
    );

    const taskIDs = tasks
        ?.map((task) => task.value?.__typename === 'File' && task?.value._id)
        .filter((el) => el !== null);

    const taskFiles =
        tasks?.map((task) => ({
            ...(task.value as File),
            readinessTask: task.task.title
        })) || [];

    const files = unitDetails?.files.filter((file) => !taskIDs?.includes(file._id)) || [];

    const unitFiles: UnitFile[] = files?.concat(taskFiles);

    return (
        <Window
            className="max-h-screen"
            width="w-3/5"
            header={<Header files={unitFiles} handleDownloadFiles={downloadUnitFiles} />}
            setShow={setShow}
            scrollContent={true}
        >
            {loading ? (
                <Loading />
            ) : (
                <div className="mb-10 ml-10 mr-10 overflow-y-auto">
                    {!_.isEmpty(unitFiles) ? (
                        unitFiles?.map((file) => {
                            return (
                                <FileCard
                                    name={file.name}
                                    description={file.readinessTask}
                                    handleClick={() => downloadUnitFiles([file._id])}
                                    url={file.url}
                                    user={isDownloaded(file.downloads)}
                                />
                            );
                        })
                    ) : (
                        <div className="border-2 rounded-lg border-dashed mt-2 justify-center border-navy-light h-56 flex items-center">
                            <p className="text-3xl text-navy-light font-medium ">No Files Uploaded</p>
                        </div>
                    )}
                </div>
            )}
        </Window>
    );
};
