import React from 'react';

import {
    DealUserParty,
    GetDealDetailsDocument,
    GetUserOrganisationDocument,
    useRemoveActorFromDealMutation,
    useRemoveMemberMutation
} from '../../../../../../generated/graphql';
import Loading from '../../../../../../shared/Loading';
import Window from '../../../../../../shared/Window';

type RemoveUserWindowProps = {
    dealID: string;
    userID: string;
    activated: boolean;
    organisationID: string;
    type: 'Landlord' | 'Tenant';
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
};

const RemoveUserWindow = ({ dealID, userID, activated, organisationID, type, setShow }: RemoveUserWindowProps) => {
    const [removeUser, { loading: removeUserLoading }] = useRemoveActorFromDealMutation();

    const handleLandlordClick = async () => {
        await removeUser({
            variables: {
                dealID,
                input: {
                    userID,
                    party: type === 'Landlord' ? DealUserParty.Landlord : DealUserParty.Tenant
                }
            },
            refetchQueries: () => [GetDealDetailsDocument, GetUserOrganisationDocument]
        });

        return setShow(false);
    };

    const handleTenantClick = async () => {
        await handleRemoveMember(userID);

        return setShow(false);
    };

    const [removeMember, { loading: removeMemberLoading }] = useRemoveMemberMutation();
    const handleRemoveMember = async (userID: string) => {
        await removeMember({
            variables: {
                organisationID,
                userID
            },
            refetchQueries: () => [GetDealDetailsDocument, GetUserOrganisationDocument]
        });
    };

    if (removeUserLoading || removeMemberLoading)
        return (
            <Window title="Remove User" width="w-1/4" setShow={setShow}>
                <Loading />
            </Window>
        );

    return (
        <Window
            title={type === 'Tenant' ? 'Remove Tenant' : 'Remove User'}
            titleCenter
            width="w-1/4"
            contentMiddle={true}
            primaryBtn
            primaryBtnText={'Confirm'}
            onPrimaryBtnClick={() => {
                type === 'Landlord' ? handleLandlordClick() : handleTenantClick();
            }}
            secondaryBtn
            secondaryBtnText={'Cancel'}
            onSecondaryBtnClick={() => setShow(false)}
            setShow={setShow}
        >
            <p className="text-newGray-darkish px-4 py-6 text-center">
                Are you sure you want to <span className="font-semibold text-red-500 text-uppercase">remove</span>{' '}
                {!activated
                    ? ' the user from this Company '
                    : type === 'Landlord'
                    ? 'the user from this deal?'
                    : 'the Tenant?'}
                {activated && type === 'Landlord'
                    ? 'They will no longer receive updates regarding this deal.'
                    : 'They will no longer have access to any of your company data.'}
            </p>
        </Window>
    );
};

export default RemoveUserWindow;
