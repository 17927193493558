import React from 'react';

import Window from '../../shared/Window';

type DeleteDealWindowProps = {
    setDeleteDealWindow: React.Dispatch<React.SetStateAction<boolean>>;
    handleDeleteClick: () => void;
};

const DeleteDealWindow = ({ setDeleteDealWindow, handleDeleteClick }: DeleteDealWindowProps) => {
    return (
        <Window
            title={'Delete Deal'}
            titleCenter
            underlined
            width="w-2/5"
            setShow={setDeleteDealWindow}
            primaryBtn
            onPrimaryBtnClick={handleDeleteClick}
            secondaryBtn
            onSecondaryBtnClick={() => setDeleteDealWindow(false)}
            onCloseClick={() => setDeleteDealWindow(false)}
        >
            <div className="flex flex-col items-center">
                <h2 className="text-center text-lg my-8 w-4/5">
                    Are you sure you want to <span className="font-semibold text-uppercase">delete</span> this deal?
                    This process is irreversible.
                </h2>
            </div>
        </Window>
    );
};

export default DeleteDealWindow;
