import React, { useEffect, useState } from 'react';
import stc from 'string-to-color';

import EditTaskWindow from './EditTaskWindow';
import { ReactComponent as EditIcon } from '../../../assets/images/edit/black.svg';
import { AdminTaskType, UnitUse } from '../../../generated/graphql';
import { UseConditionToText } from '../../../shared/NewUnit/hooks/useGetUnitSelectData';

type TaskProps = {
    id: string;
    title: string;
    index: number;
    weight: number;
    tags: string[];
    type: AdminTaskType;
    optional: boolean;
    associatedUses?: UnitUse[] | null;
};

const Task = ({ title, index, weight, tags, type, id, optional, associatedUses }: TaskProps) => {
    const [showEditWindow, setShowEditWindow] = useState<boolean>(false);
    const [taskCategory, setTaskCategory] = useState<string | null>(null);

    useEffect(() => {
        tags.map((tag) => {
            if (tag.substr(0, 9) === 'category:') {
                let category = tag.substr(9, tag.length - 1);
                setTaskCategory(category);
            } else {
                setTaskCategory('Practical');
            }
            return false;
        });
    }, [tags]);

    return (
        <>
            {showEditWindow && (
                <EditTaskWindow
                    setShow={setShowEditWindow}
                    id={id}
                    title={title}
                    optional={optional}
                    index={index}
                    weight={weight}
                    tags={tags}
                    category={taskCategory}
                    type={type}
                    uses={associatedUses}
                />
            )}
            <div className="flex justify-between px-4 bg-white rounded w-full py-4 mb-2">
                <div className="grid grid-cols-9 gap-4 w-full">
                    <span className="col-span-1 font-medium text-gray-600">{index}</span>
                    <span className="col-span-2 font-medium text-black">{title}</span>
                    <span className="col-span-1 font-medium text-center">{weight}</span>
                    <div className="col-span-2 flex items-center justify-start gap-2 overflow-x-auto">
                        {tags
                            .filter((tag) => tag.substr(0, 9) !== 'category:')
                            .map((tag, index) => (
                                <span
                                    key={index}
                                    className="rounded-full py-1 px-2 cursor-default text-white text-xs transition-all duration-150"
                                    style={{ backgroundColor: stc(tag) }}
                                >
                                    {tag}
                                </span>
                            ))}
                    </div>
                    <div className="col-span-2 flex items-center justify-start overflow-x-auto">
                        {associatedUses &&
                            associatedUses.map((use, index) => (
                                <span
                                    key={index}
                                    className="rounded-full py-1 px-2 cursor-default text-white text-xs transition-all duration-150 "
                                    style={{ backgroundColor: stc(use) }}
                                >
                                    {UseConditionToText(use)}
                                </span>
                            ))}
                    </div>
                    <div className="col-span-1 flex items-center justify-start overflow-x-auto">
                        {tags
                            .filter((tag) => tag.substr(0, 9) === 'category:')
                            .map((tag, index) => (
                                <span
                                    key={index}
                                    className="rounded-full py-1 px-2 cursor-default text-white text-xs transition-all duration-150 "
                                    style={{ backgroundColor: stc(tag) }}
                                >
                                    {tag.replace('category:', '')}
                                </span>
                            ))}
                    </div>
                </div>
                <EditIcon className="w-6 h-6 cursor-pointer" onClick={() => setShowEditWindow(true)} />
            </div>
        </>
    );
};

export default Task;
