import { ApolloClient, from, HttpLink, InMemoryCache, NormalizedCacheObject } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import React from 'react';
import { toast } from 'react-toastify';

import { PROTECTED_ROUTES } from './App';
import ErrorPopup from './shared/ErrorPopup';

interface IError {
    message: string;
    code: string;
}

export class Client {
    private client: ApolloClient<NormalizedCacheObject>;

    constructor() {
        const errorLink = this.buildErrorLink();

        this.client = new ApolloClient({
            cache: new InMemoryCache(),
            link: from([
                errorLink,
                new HttpLink({
                    uri: process.env.REACT_APP_API_URL + '/graphql',
                    credentials: 'include'
                })
            ])
        });
    }

    get apolloClient() {
        return this.client;
    }

    private buildErrorLink() {
        return onError(({ graphQLErrors, networkError }) => {
            if (graphQLErrors) {
                graphQLErrors.map((_error) => {
                    const error: IError = {
                        message: _error.extensions?.message ?? _error.message ?? 'An error occurred',
                        code: _error.extensions?.code ?? 'UNKNOWN'
                    };

                    if (
                        !['UNAUTHENTICATED', 'BAD_2FA'].includes(error.code) &&
                        // Disable "parent not found" messages
                        !error.message.includes('parent')
                    )
                        this.showError(error);

                    if (
                        error.code === 'UNAUTHENTICATED' &&
                        !PROTECTED_ROUTES.find((route) => window.location.pathname.startsWith(route))
                    )
                        window.location.pathname = '/login';
                });
            }
        });
    }

    private showError(error: IError) {
        toast(
            <ErrorPopup
                title={error.message}
                details={[`Code: ${error.code ?? 'UNKNOWN'}`, `Message: ${error.message}`]}
            />,
            {
                hideProgressBar: true,
                draggable: false,
                autoClose: false,
                closeOnClick: false,
                closeButton: true
            }
        );
    }
}
