import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import { DealDetailsFragment, useGetDealDetailsQuery } from '../../../generated/graphql';
import { useDisclosure } from '../../../shared/hooks/useDisclosure';

type FilterContextType = {
    isEdit: boolean;
    setIsEdit: React.Dispatch<React.SetStateAction<boolean>>;
    seenDealAgreed: boolean;
    setSeenDealAgreed: React.Dispatch<React.SetStateAction<boolean>>;
    reload: any;
    setReload: React.Dispatch<React.SetStateAction<any>>;
    isDownloadOpen: boolean;
    downloadOpen: () => void;
    downloadClose: () => void;
    deal: DealDetailsFragment | null | undefined;
};

export const DealContext = React.createContext<FilterContextType>({
    isEdit: false,
    setIsEdit: () => {},
    seenDealAgreed: false,
    setSeenDealAgreed: () => {},
    reload: undefined,
    setReload: () => {},
    isDownloadOpen: false,
    downloadOpen: () => {},
    downloadClose: () => {},
    deal: null
});

type DealProviderProps = {
    children: React.ReactNode;
};

export const DealProvider = ({ children }: DealProviderProps) => {
    const [isEdit, setIsEdit] = useState<boolean>(false);
    const [seenDealAgreed, setSeenDealAgreed] = useState<boolean>(false);
    const [reload, setReload] = useState();
    const { isOpen: isDownloadOpen, open: downloadOpen, close: downloadClose } = useDisclosure();

    const { id, token } = useParams<{ id: string; token: string }>();

    const { data: detailsData } = useGetDealDetailsQuery({
        variables: {
            dealID: id,
            token
        }
    });

    const deal = detailsData?.deal;

    return (
        <DealContext.Provider
            value={{
                isEdit,
                setIsEdit,
                seenDealAgreed,
                setSeenDealAgreed,
                reload,
                setReload,
                isDownloadOpen,
                downloadOpen,
                downloadClose,
                deal
            }}
        >
            {children}
        </DealContext.Provider>
    );
};
