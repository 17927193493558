import { useEffect, useState } from 'react';

import {
    askUserPermission,
    createNotificationSubscription,
    getUserSubscription,
    isPushNotificationSupported,
    registerServiceWorker
} from './utils';
import { useGetUserTypeQuery, useSubscribeEndpointMutation } from '../generated/graphql';
import { handleFetchError } from '../shared/helpers';

const pushNotificationSupported = isPushNotificationSupported();

const usePushNotifications = () => {
    // Check if user has consented to notifications
    const [userConsent, setUserConsent] = useState<NotificationPermission>(
        'Notification' in window ? Notification.permission : 'default'
    );
    // User's current subscription object
    const [userSubscription, setUserSubscription] = useState<PushSubscription | null | undefined>(null);

    // Get user's ID to attach to the subscription object
    const { data: userData } = useGetUserTypeQuery();

    const [subscribeEndpoint, { error }] = useSubscribeEndpointMutation();

    // Register SW on load
    useEffect(() => {
        if (pushNotificationSupported) {
            registerServiceWorker();
        }
    });

    // Check if there's an existing subscription
    useEffect(() => {
        const getExistingSubscription = async () => {
            // Ask user permission again on logout
            if (
                userData?.user.__typename === 'User' &&
                (!userData.user.subscribers || userData.user.subscribers.length === 0)
            )
                return setUserConsent('default');

            const existingSubscription = await getUserSubscription();
            setUserSubscription(existingSubscription);
        };
        getExistingSubscription();
    }, [userData]);

    // Ask the user for permissions to show notifications
    const userPermissionClick = () => {
        if (!isPushNotificationSupported()) return;

        askUserPermission().then((consent) => {
            setUserConsent(consent);

            if (consent !== 'granted') {
                // No consent given
                // setError({
                //     name: "Consent denied",
                //     m
                // })
            }

            userSubscriptionClick();
        });
    };

    const userSubscriptionClick = () => {
        createNotificationSubscription()
            .then(async (subscription) => {
                if (!subscription) return;
                setUserSubscription(subscription);
                sendSubscription(subscription);
            })
            .catch((err) => {
                handleFetchError('Failed to subscribe to notifications', err, {});
            });
    };

    const sendSubscription = async (subscription: PushSubscription) => {
        const { data } = await subscribeEndpoint({
            variables: {
                // Attach user ID along with subscription data so that we can send the subscriptions to the correct user
                endpoint: JSON.stringify(subscription)
            }
        });

        if (error) {
            handleFetchError('Could not enable push notifications on your account', error, data?.userSubscribe);
            setUserConsent('default');
            setUserSubscription(null);
        }
    };

    return {
        userPermissionClick,
        userSubscriptionClick,
        userConsent,
        pushNotificationSupported,
        userSubscription
    };
};

export default usePushNotifications;
