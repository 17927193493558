import React, { useEffect, useRef, useState } from 'react';
import ContentEditable from 'react-contenteditable';

type LongFormBoxProps = {
    defaultValue?: string | null;
    label: string;
    value?: string;
    suffix?: string;
};
const useLongFormBox = ({ defaultValue, label, value, suffix }: LongFormBoxProps) => {
    const [htmlValue, setHTMLValue] = useState<string>(defaultValue || '');
    const [clauseValue, setClauseValue] = useState(value);
    const [clauseValueSuffix, setClauseValueSuffix] = useState(suffix);
    const innerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (!defaultValue || !value) return;

        setHTMLValue(defaultValue.replace('{$v}', valueHTML(value, suffix)));
    }, [defaultValue, value, suffix]);

    const valueHTML = (value?: string, valueSuffix?: string) => {
        let formattedValue =
            value !== null && value !== undefined ? `${value}${!!valueSuffix ? ' ' + valueSuffix : ''}` : '__';

        // Place currency before value
        if (valueSuffix === '£' || valueSuffix === '$' || valueSuffix === '€')
            formattedValue = value !== null && value !== undefined ? `${!!valueSuffix && valueSuffix}${value}` : '__';

        return `<span class="bg-gray-800 rounded text-white px-2 py-1 h-full" contenteditable="false">${formattedValue}</span>`;
    };

    const updateClauseValue = (newValue?: string, newValueSuffix?: string) => {
        setClauseValue(newValue);
        setClauseValueSuffix(newValueSuffix);
        setHTMLValue(
            htmlValue.replace(
                /<span class="bg-gray-800 rounded text-white px-2 py-1 h-full" contenteditable="false">(?:.*?)<\/span>/g,
                valueHTML(newValue, newValueSuffix)
            )
        );
        return htmlValue;
    };

    const formatClauseValue = () => {
        return htmlValue
            .replace(
                /<span class="bg-gray-800 rounded text-white px-2 py-1 h-full" contenteditable="false">(?:.*?)<\/span>/g,
                '{$v}'
            )
            .replace(/&nbsp;/g, ' ');
    };

    const insertValue = () => {
        innerRef.current?.focus();

        if (clauseValue === '') {
            setClauseValue('*');
        }

        let sel, range;
        if (window.getSelection) {
            sel = window.getSelection();
            if (sel && sel.getRangeAt && sel.rangeCount) {
                range = sel.getRangeAt(0);
                range.deleteContents();
                range.insertNode(range.createContextualFragment(valueHTML(clauseValue, clauseValueSuffix) + '&nbsp'));
                sel.removeAllRanges();
            }
        }
    };

    return {
        insertValue,
        updateClauseValue,
        formatClauseValue,
        LongFormBox: (
            <>
                {label && <p className="text-gray-600 font-medium text-sm -mb-0.5 mt-2">{label}</p>}
                <ContentEditable
                    innerRef={innerRef}
                    onPaste={(event) => {
                        event.preventDefault();
                        let text = event.clipboardData.getData('text/plain');
                        document.execCommand('insertHTML', false, text);
                    }}
                    html={htmlValue}
                    onChange={(event) => setHTMLValue(event.target.value)}
                    className="bg-navy-lightest border border-navy-light focus:border-newTeal-main focus:outline-none h-32 mt-2 overflow-scroll p-2 rounded w-full"
                />
            </>
        )
    };
};

export default useLongFormBox;
