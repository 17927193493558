import React, { useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Button } from '../../shared/button';
import { useQuery } from '../../shared/helpers';
import Input, { InputType } from '../../shared/Inputs';

const SignupForm = () => {
    const history = useHistory();
    const query = useQuery();

    const [error, setError] = useState<string | null>(null);
    const [fieldsError, setFieldsError] = useState({
        firstName: false,
        lastName: false,
        company: false,
        mobile: false,
        email: false,
        password: false
    });

    const firstNameRef = useRef<HTMLInputElement>(null);
    const lastNameRef = useRef<HTMLInputElement>(null);
    const companyRef = useRef<HTMLInputElement>(null);
    const mobileRef = useRef<HTMLInputElement>(null);
    const emailRef = useRef<HTMLInputElement>(null);
    const passwordRef = useRef<HTMLInputElement>(null);

    const handleSubmit = async () => {
        setError(null);

        const firstName = firstNameRef.current?.value;
        const lastName = lastNameRef.current?.value;
        const company = companyRef.current?.value;
        const mobile = mobileRef.current?.value;
        const email = emailRef.current?.value;
        const password = passwordRef.current?.value;

        // Local fields error obj
        let fieldsErr = fieldsError;

        const checkEmptyField = (field: string, fieldValue: string | undefined) => {
            !fieldValue
                ? (fieldsErr = { ...fieldsErr, [field]: true })
                : (fieldsErr = { ...fieldsErr, [field]: false });
        };

        checkEmptyField('firstName', firstName);
        checkEmptyField('lastName', lastName);
        checkEmptyField('company', company);
        checkEmptyField('mobile', mobile);
        checkEmptyField('email', email);
        checkEmptyField('password', password);

        if (!firstName || !lastName || !company || !mobile || !email || !password) {
            setError('Please enter the required details');
            setFieldsError(fieldsErr);
            return;
        }

        // Password
        if (password.length < 8) {
            setError('Passwords must be at least 8 characters long');
            fieldsErr = { ...fieldsErr, password: true };
            setFieldsError(fieldsErr);
            return;
        }
        if (!/\d/.test(password)) {
            setError('Passwords must contain at least one number');
            fieldsErr = { ...fieldsErr, password: true };
            setFieldsError(fieldsErr);
            return;
        }
        if (!/[a-zA-Z]/.test(password)) {
            setError('Passwords must contain at least one character');
            fieldsErr = { ...fieldsErr, password: true };
            setFieldsError(fieldsErr);
            return;
        }

        history.push(`/signup/type?${query.toString()}`, {
            firstName,
            lastName,
            company,
            mobile: mobile.replace(/\s/g, ''),
            email,
            password
        });
    };

    return (
        <form
            className="bg-white px-8 pt-6 pb-8 mb-4 sm:h-auto w-full md:w-auto max-w-sm"
            onClick={(e) => e.preventDefault()}
        >
            <div className="mb-4 flex flex-row">
                <div className="w-1/2 mr-2">
                    <Input
                        className={`bg-navy-lightest w-full border ${
                            fieldsError.firstName && 'border-red-300 bg-red-100'
                        }`}
                        type={InputType.text}
                        placeholder="Your first name"
                        label="First Name"
                        ref={firstNameRef}
                        autoFocus
                        autoComplete="given-name"
                        required
                    />
                </div>
                <div className="w-1/2">
                    <Input
                        className={`bg-navy-lightest w-full border ${
                            fieldsError.lastName && 'border-red-300 bg-red-100'
                        }`}
                        type={InputType.text}
                        placeholder="Your last name"
                        label="Last Name"
                        ref={lastNameRef}
                        autoComplete="family-name"
                        required
                    />
                </div>
            </div>
            <div className="mb-4 flex flex-row">
                <div className="w-full">
                    <Input
                        className={`bg-navy-lightest w-full border ${
                            fieldsError.company && 'border-red-300 bg-red-100'
                        }`}
                        type={InputType.text}
                        placeholder="Company name"
                        label="Company"
                        ref={companyRef}
                        autoComplete="off"
                        required
                    />
                </div>
            </div>
            <div className="mb-4 flex flex-col">
                <div className="w-full mb-4">
                    <Input
                        className={`bg-navy-lightest w-full border ${
                            fieldsError.mobile && 'border-red-300 bg-red-100'
                        }`}
                        type={InputType.phone}
                        placeholder="Your business mobile"
                        label="Business Mobile"
                        autoComplete="tel"
                        ref={mobileRef}
                    />
                </div>
                <div className="w-full mb-4">
                    <Input
                        className={`bg-navy-lightest w-full border ${fieldsError.email && 'border-red-300 bg-red-100'}`}
                        type={InputType.email}
                        placeholder="Your email"
                        label="Email"
                        ref={emailRef}
                        autoComplete="email"
                        required
                    />
                </div>
                <div className="w-full mb-4">
                    <Input
                        className={`bg-navy-lightest w-full border ${
                            fieldsError.password && 'border-red-300 bg-red-100'
                        }`}
                        type={InputType.password}
                        placeholder="Your password"
                        label="Password"
                        ref={passwordRef}
                        autoComplete="off"
                        required
                    />
                </div>
            </div>

            <div className="flex items-center justify-center w-full">
                <Button background="newTeal-main" text="white" className="w-full font-semibold" onClick={handleSubmit}>
                    Register
                </Button>
            </div>
            {error && (
                <div className="bg-red-200 border border-red-600 rounded w-full py-2 px-4 mt-4">
                    <span className="text-center text-red-600">{error}</span>
                </div>
            )}
        </form>
    );
};

export default SignupForm;
