import React, { useEffect } from 'react';
import { FormState } from 'react-hook-form';

/**
 * Show a save button when the form's data changes
 * @param formState Form state
 * @param setState Show button
 */
export const useShowSaveButton = <T extends unknown>(
    formState: FormState<T>,
    setState?: (value: React.SetStateAction<boolean>) => void
) => {
    const { isDirty } = formState;
    useEffect(() => {
        setState?.(isDirty);
    }, [isDirty, setState]);
};
