import React, { useContext } from 'react';

import { ReactComponent as Menu } from '../../assets/images/menu/black.svg';
import useMobileScreen from '../../shared/hooks/useMobileScreen';
import { MainContext } from '../main.provider';

interface HeaderProps {
    setVisible: React.Dispatch<React.SetStateAction<boolean>>;
    visible: boolean;
    children: React.ReactChild | null;
}

const Header = ({ setVisible, children, visible }: HeaderProps) => {
    const { windowOpen } = useContext(MainContext);
    const mobileScreen = useMobileScreen();

    return (
        <div
            className={`w-full flex items-center justify-start p-2 h-16 border-b border-navy-main overflow-hidden sticky ${
                windowOpen && mobileScreen ? 'z-0' : 'z-40'
            } bg-white`}
        >
            <Menu
                className={`cursor-pointer w-7 h-7  md:ml-4  z-50 transitionAll ${
                    visible && !mobileScreen
                        ? 'quickFadeOut -mr-8 ml-2 mr-4'
                        : !mobileScreen
                        ? 'smoothFadeIn mr-4 ml-2 mr-4'
                        : 'ml- mr-1'
                }`}
                onClick={() => setVisible(!visible)}
            />
            {children}
            <div className="absolute bg-navy-lightest grey-header-polygon"></div>
        </div>
    );
};

export default Header;
