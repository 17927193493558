import { useState } from 'react';

export enum WindowType {
    ALL,
    FILTER,
    SORT
}

export enum PageType {
    COMPANIES,
    COMPANY_DETAILS
}

export type Window = {
    type: WindowType;
    field?: string;
    page?: PageType;
};

/**
 * Handle disclosure for Filter window
 */
export const useDisclosure = () => {
    const [window, setWindow] = useState<Window | undefined>(undefined);

    /**
     *
     * @param window opens a specific window for filter dialog
     * if not passed, will open ALL window, showing all possible filters
     */
    const openWindow = (window = { type: WindowType.ALL }) => setWindow(window);
    const close = () => setWindow(undefined);

    return { isWindowOpen: window !== undefined, window, openWindow, close };
};
