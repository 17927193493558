import React, { useEffect, useState } from 'react';

import UnitsList from './UnitsList';
import { SingleUnitCardFragment, UnitAvailability, UnitFilterCount, User } from '../../generated/graphql';

type SearchUnitCardsProps = {
    user?: User;
    unitFilterCounts?: UnitFilterCount;
    units: SingleUnitCardFragment[];
    countsRefetch: () => void;
    refetch: () => void;
    query: string;
};

const SearchUnitCards = ({ user, unitFilterCounts, units, countsRefetch, refetch }: SearchUnitCardsProps) => {
    const [availableUnits, setAvailableUnits] = useState<SingleUnitCardFragment[]>([]);
    const [unavailableUnits, setUnavailableUnits] = useState<SingleUnitCardFragment[]>([]);

    useEffect(() => {
        const result = units.reduce(
            (acc, item) => {
                if (item.available === UnitAvailability.AvailableToLet) {
                    acc.available.push(item);
                } else if (item.available === UnitAvailability.UnavailableToLet) {
                    acc.unavailable.push(item);
                }
                return acc;
            },
            { available: [], unavailable: [] }
        );

        setAvailableUnits(result.available);
        setUnavailableUnits(result.unavailable);
    }, [units]);

    const handleNextSection = (section: string) => {
        const unitSection = document.getElementById(section);
        unitSection?.scrollIntoView({
            behavior: 'smooth'
        });
    };

    return (
        <>
            <div className="flex mt-4 justify-between">
                <div className="flex">
                    <div id="available" className="text-newGray-darkishfont-semibold text-lg">
                        Available to Let
                    </div>
                    <div
                        style={{
                            height: '20px',
                            backgroundColor: '#BFBFBF',
                            width: '20px'
                        }}
                        className="ml-2 text-newGray-darkishfont-semibold text-sm flex justify-center rounded"
                    >
                        {unitFilterCounts?.availableToLet}
                    </div>
                </div>
                <div
                    onClick={() => handleNextSection('unavailable')}
                    className="text-gray-v2-400 font-semibold text-xs cursor-pointer"
                >
                    Jump to next section
                </div>
            </div>
            <UnitsList units={availableUnits} user={user} countsRefetch={countsRefetch} refetch={refetch} />
            <div className="flex mt-4 justify-between">
                <div className="flex">
                    <div id="unavailable" className="text-newGray-darkishfont-semibold text-lg">
                        Archive
                    </div>
                    <div
                        style={{
                            height: '20px',
                            backgroundColor: '#BFBFBF',
                            width: '20px'
                        }}
                        className="ml-2 text-newGray-darkishfont-semibold text-sm flex justify-center rounded"
                    >
                        {unitFilterCounts?.unavailableToLet}
                    </div>
                </div>
                <div
                    onClick={() => handleNextSection('available')}
                    className="text-gray-v2-400 font-semibold text-xs cursor-pointer"
                >
                    Jump to next section
                </div>
            </div>
            <UnitsList units={unavailableUnits} user={user} countsRefetch={countsRefetch} refetch={refetch} />
        </>
    );
};

export default SearchUnitCards;
