import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import Window from '../../../../../../shared/Window';
import Tenant from '../../../../../Units/Unit/Tenant/index';

type ReadinessWindowProps = {
    setHeaderContent: React.Dispatch<React.SetStateAction<React.ReactChild | null>>;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
};

const tabItems = ['Readiness', 'Overview & Deals'];

const ReadinessWindow = ({ setHeaderContent, setShow }: ReadinessWindowProps) => {
    const [selectedTab, setSelectedTab] = useState({
        index: 0,
        tab: tabItems[0]
    });
    const { id } = useParams<{ id: string }>();
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const dealId = params.get('deal');

    useEffect(() => {
        if (dealId && dealId.length > 0) setSelectedTab({ index: 1, tab: tabItems[1] });
    }, [dealId]);
    return (
        <Window
            className="max-h-screen"
            width="w-3/5"
            title="Tenant Readiness"
            backgroundColor="bg-navy-light"
            setShow={setShow}
        >
            <div className="container pt-16 mx-auto p-4">
                <Tenant id={id} selectedTab={selectedTab} setHeaderContent={setHeaderContent} />
            </div>
        </Window>
    );
};

export default ReadinessWindow;
