import { useEffect, useState } from 'react';

const useScreenWidth = () => {
    const [screenWidth, setScreenWidth] = useState<number>();
    useEffect(() => setScreenWidth(window.innerWidth), []);
    // On Resize
    useEffect(() => {
        const handleResize = () => setScreenWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
    }, []);
    return screenWidth;
};

export default useScreenWidth;
