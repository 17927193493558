import { format } from 'date-fns';
import React, { FC } from 'react';

import { ReactComponent as CommentIcon } from '../../../../../assets/images/comment/black.svg';
import { ClauseCommentFragment } from '../../../../../generated/graphql';

interface CommentProps extends ClauseCommentFragment {}

const Comment: FC<CommentProps> = ({ _id, actor, date, message }) => {
    const userLabel = actor?.firstName && actor?.lastName ? `${actor.firstName} ${actor.lastName}` : actor?.email;
    const dateLabel = date ? format(new Date(date), 'dd/MM/yyyy') : 'Date not available.';
    return (
        <div className="flex flex-col" key={_id}>
            <span className="text-xs">{`${userLabel} - ${dateLabel}`}</span>
            <span className="border border-newTeal-main bg-newTeal-light text-sm p-1 flex flex-row justify-start gap-1 rounded items-start whitespace-pre-wrap">
                <CommentIcon className="text-newTeal-main w-5 h-5" />
                {message}
            </span>
        </div>
    );
};

export default Comment;
