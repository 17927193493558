import React from 'react';

import Window from '../../../../../../shared/Window';

type DealPreventSendWindowProps = {
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
    backTo: 'Landlord' | 'Tenant' | undefined;
    setHideToolbar?: React.Dispatch<React.SetStateAction<boolean>>;
    setMenuOpen?: React.Dispatch<React.SetStateAction<boolean>>;
};

const DealPreventSendWindow = ({ setShow, backTo, setHideToolbar, setMenuOpen }: DealPreventSendWindowProps) => {
    return (
        <Window
            title="Clauses not actioned"
            titleCenter
            width="w-1/4"
            contentMiddle={true}
            primaryBtn
            primaryBtnText="Continue"
            onPrimaryBtnClick={() => {
                setHideToolbar && setHideToolbar(false);
                setShow(false);
                setMenuOpen && setMenuOpen(false);
            }}
            setShow={() => {
                setShow(false);
                setHideToolbar && setHideToolbar(false);
                setMenuOpen && setMenuOpen(false);
            }}
        >
            <h2 className="text-gray-800 px-4 py-6 text-center">
                You <span className="font-bold">must</span> action all the clauses before you can share the deal with
                the <span className="text-red-500 font-bold">{backTo}</span>.
            </h2>
        </Window>
    );
};

export default DealPreventSendWindow;
