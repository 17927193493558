import { useApolloClient } from '@apollo/client';
import { formatDistance } from 'date-fns';
import { capitalize } from 'lodash';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import AdminDeleteUserWindow from './AdminDeleteUserWindow';
import AdminEditUserWindow from './AdminEditUserWindow';
import AdminPasswordWindow from './AdminPasswordWindow';
import { ReactComponent as Activity } from '../../../assets/images/activity/activityGray.svg';
import { ReactComponent as BriefCase } from '../../../assets/images/briefcase/black.svg';
import { ReactComponent as Company } from '../../../assets/images/company/small-company.svg';
import { ReactComponent as Delete } from '../../../assets/images/delete/black.svg';
import { ReactComponent as Edit } from '../../../assets/images/edit/black.svg';
import { ReactComponent as Envelope } from '../../../assets/images/envelope/black.svg';
import { ReactComponent as Lock } from '../../../assets/images/lock/black.svg';
import { ReactComponent as Phone } from '../../../assets/images/phone/black.svg';
import { ReactComponent as Swap } from '../../../assets/images/swap/black.svg';
import { useAdminMakeAdminMutation, useAdminUserImpersonateMutation, UserType } from '../../../generated/graphql';
import DefaultProfile from '../../../layout/SideBar/components/DefaultProfile';
import { handleFetchError } from '../../../shared/helpers';
import useMobileScreen from '../../../shared/hooks/useMobileScreen';
import Options from '../../../shared/Options';
import CompanyDetailsWindow from '../Companies/CompanyDetailsWindow';

type UserProps = {
    popup?: boolean;
    id: string;
    email: string;
    type: UserType;
    organisation: {
        name: string;
        avatar?: string | null;
    };
    firstName?: string | null;
    lastName?: string | null;
    mobile?: string | null;
    locked?: boolean | null;
    lastLogin?: string | null;
    activated: boolean;
    created?: string;
    organisationId?: string;
};

const User = ({
    popup,
    id,
    firstName,
    lastName,
    email,
    type,
    organisation,
    mobile,
    locked,
    lastLogin,
    activated,
    created,
    organisationId
}: UserProps) => {
    const client = useApolloClient();
    const history = useHistory();

    const [showAdminPasswordWindow, setShowAdminPasswordWindow] = useState<boolean>(false);
    const [passwordVerificationReason, setPaswordVerificationReason] = useState<
        'SWITCH_USER' | 'USER_MAKE_ADMIN' | undefined
    >(undefined);

    const [showAdminEditUserWindow, setShowAdminEditUserWindow] = useState<boolean>(false);
    const [showAdminDeleteUserWindow, setShowAdminDeleteUserWindow] = useState<boolean>(false);
    const [showCompanyWindow, setShowCompanyWindow] = useState<boolean>(false);

    const [impersonateUser] = useAdminUserImpersonateMutation();
    const [makeAdmin, { error: makeAdminError }] = useAdminMakeAdminMutation();

    const lastLoginText = lastLogin && formatDistance(new Date(lastLogin), new Date());

    const mobileScreen = useMobileScreen();

    const handleChangeRoleClick = async (password: string) => {
        const { data } = await makeAdmin({
            variables: {
                id,
                password
            }
        });

        if (makeAdminError || data?.adminMakeAdmin.__typename !== 'User')
            return handleFetchError("Could not update the selected user's role", makeAdminError, data?.adminMakeAdmin);

        client.cache.modify({
            id: client.cache.identify({
                __typename: 'User',
                _id: id
            }),
            fields: {
                type() {
                    return UserType.Admin;
                }
            }
        });

        // Refetch all with new user
        setShowAdminPasswordWindow(false);
    };

    const handleImpersonateClick = async (password: string) => {
        try {
            await impersonateUser({
                variables: {
                    id,
                    input: {
                        password
                    }
                }
            });
        } catch {
            return;
        }

        // Refetch all with new user
        setShowAdminPasswordWindow(false);
        history.replace('/');
        await client.resetStore();
    };

    return (
        <>
            {showAdminPasswordWindow && (
                <AdminPasswordWindow
                    text={
                        type !== UserType.Landlord && passwordVerificationReason === 'USER_MAKE_ADMIN'
                            ? 'Only Landlords can be promoted to an admin to avoid issues with deals.'
                            : `Please enter your admin password in order to  ${
                                  passwordVerificationReason === 'USER_MAKE_ADMIN'
                                      ? 'change this user to an admin'
                                      : 'switch to this user'
                              }.`
                    }
                    hideConfirm={type !== UserType.Landlord && passwordVerificationReason === 'USER_MAKE_ADMIN'}
                    title={passwordVerificationReason === 'USER_MAKE_ADMIN' ? 'Update User Role' : undefined}
                    onClick={
                        passwordVerificationReason === 'USER_MAKE_ADMIN'
                            ? handleChangeRoleClick
                            : handleImpersonateClick
                    }
                    setShow={setShowAdminPasswordWindow}
                />
            )}
            {showAdminEditUserWindow && created && (
                <AdminEditUserWindow
                    id={id}
                    firstName={firstName}
                    lastName={lastName}
                    email={email}
                    mobile={mobile}
                    locked={locked}
                    created={created}
                    setShow={setShowAdminEditUserWindow}
                />
            )}
            {showAdminDeleteUserWindow && <AdminDeleteUserWindow id={id} setShow={setShowAdminDeleteUserWindow} />}
            {showCompanyWindow && (
                <CompanyDetailsWindow organisationId={organisationId} setShow={setShowCompanyWindow} />
            )}
            <div
                key={id}
                className={`flex justify-between ${
                    mobileScreen ? 'h-40' : 'h-24'
                }  w-full relative bg-white rounded px-3 py-4 mb-2`}
            >
                <div className="flex gap-2 items-center justify-start">
                    {!popup && organisation.avatar ? (
                        <img
                            src={organisation.avatar}
                            alt={organisation.name}
                            className="w-10 h-10 rounded object-cover"
                        />
                    ) : (
                        !popup && <DefaultProfile name={organisation.name} size="2.5rem" fontSize="1rem" />
                    )}
                    <div className={`flex ${mobileScreen ? 'flex-col' : 'flex-row'} items-center ml-4`}>
                        <div className={`flex flex-col h-18 ${mobileScreen ? 'w-full ml-4' : 'w-48 border-r-2'}  mr-4`}>
                            <p className="font-medium flex items-center justify-start">
                                {(firstName && firstName.trim().length > 0) || (lastName && lastName.trim().length > 0)
                                    ? ` ${firstName} ${lastName}`
                                    : popup && 'Invited User'}
                                {locked && <Lock className="w-5 h-5 text-newGray-darkish ml-2" />}
                            </p>
                            {!popup && (
                                <>
                                    {' '}
                                    <p className="text-sm">{organisation.name}</p>
                                </>
                            )}
                            <p className="text-sm">{capitalize(type)}</p>
                        </div>
                        <div className="flex flex-col">
                            {email && (
                                <div className="flex flex-row items-center">
                                    <Envelope stroke="#a9a9a9" className="w-3 h-3 mr-2 text-sm " />
                                    <p className={`${mobileScreen ? 'w-56 truncate' : ''}  text-sm`}>{email}</p>
                                </div>
                            )}
                            {mobile && (
                                <p className="text-sm">
                                    {' '}
                                    <Phone stroke="#a9a9a9" className="w-3 h-3 mr-2 text-sm inline " />
                                    {mobile}
                                </p>
                            )}
                            {lastLogin ? (
                                <p className="text-sm">
                                    {' '}
                                    <Activity className="w-3 h-3 mr-2 text-sm inline " />
                                    {lastLoginText} ago
                                </p>
                            ) : (
                                <p className="text-sm">
                                    {' '}
                                    <Activity className="w-3 h-3 mr-2 text-sm inline " />
                                    {activated ? 'Activated' : 'Invited'}
                                </p>
                            )}
                        </div>
                    </div>
                </div>

                <Options popup={popup}>
                    {[
                        {
                            title: 'Delete User',
                            action: () => setShowAdminDeleteUserWindow(true),
                            icon: Delete
                        },
                        {
                            title: 'Edit User',
                            action: () => setShowAdminEditUserWindow(true),
                            icon: Edit
                        },
                        {
                            title: 'Switch to User',
                            action: () => {
                                setPaswordVerificationReason('SWITCH_USER');
                                setShowAdminPasswordWindow(true);
                            },
                            icon: Swap
                        },
                        ...(!popup
                            ? [
                                  {
                                      title: 'Go to Company',
                                      action: () => {
                                          setShowCompanyWindow(true);
                                      },
                                      icon: Company
                                  }
                              ]
                            : []),
                        {
                            title: 'Make Admin',
                            action: () => {
                                setPaswordVerificationReason('USER_MAKE_ADMIN');
                                setShowAdminPasswordWindow(true);
                            },
                            icon: BriefCase
                        }
                    ]}
                </Options>
            </div>
        </>
    );
};

export default User;
