import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';

import { useGetLayoutUserInfoQuery, useResendActivationMutation } from '../../generated/graphql';
import { Button } from '../../shared/button';
import Loading from '../../shared/Loading';
import SuccessIcon from '../../shared/SuccessIcon';

const Restricted = () => {
    const history = useHistory();
    const { data: userData } = useGetLayoutUserInfoQuery();
    const [resendActivation, { loading }] = useResendActivationMutation();
    const [result, setResult] = useState<any>(null);

    useEffect(() => {
        if (userData?.user.__typename === 'User' && userData.user.activated) history.push('/');
    }, [userData, history]);

    const resendEmail = async () => {
        if (userData?.user.__typename !== 'User') return;
        let email = userData.user.email;
        const { data } = await resendActivation({
            variables: {
                email
            }
        });
        setResult(data?.userResendActivation);
    };

    if (loading) {
        return <Loading />;
    }

    return (
        <>
            {!result && (
                <>
                    {' '}
                    <p className="px-6 mb-1 sm:h-auto max-w-sm md:max-w-sm w-full text-center text-gray-600 font-medium">
                        It looks like you haven't confirmed your email
                        <span className="text-black font-bold">
                            {userData?.user.__typename === 'User' && ' ' + userData.user.email}
                        </span>
                        . Please check your inbox and follow our instructions to continue
                    </p>
                    <p className="px-6 mb-1 sm:h-auto max-w-sm md:max-w-sm w-full text-center text-gray-600 font-medium mt-8">
                        Haven't received an email from us yet?
                    </p>
                    <div className="flex flex-col items-center justify-center sm:h-auto max-w-sm md:max-w-sm w-full mt-6">
                        <Button
                            background="newTeal-main"
                            text="white"
                            className="w-full font-semibold"
                            onClick={() => {
                                resendEmail();
                            }}
                        >
                            Send Another
                        </Button>
                        <Link to="/login" className="w-full">
                            <Button
                                background="white-200"
                                text="black"
                                className="w-full font-semibold mt-5"
                                onClick={() => {
                                    resendEmail();
                                }}
                            >
                                Change User
                            </Button>
                        </Link>
                    </div>
                </>
            )}
            {result?.__typename === 'UserLinkRequested' && (
                <>
                    <div className="mt-5"></div>
                    <SuccessIcon />
                    <h1 className="text-xl font-bold text-center mt-5">Email Resent</h1>
                    <p className="mt-2 px-6 sm:h-auto max-w-sm md:max-w-sm w-full text-center text-gray-600 font-medium">
                        Please check your email for further instructions on how to continue
                    </p>
                </>
            )}
        </>
    );
};

export default Restricted;
