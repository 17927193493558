import React, { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { useHistory } from 'react-router-dom';

import TenantReadinessCard from './TenantReadinessCard';
import { DealCardFragment, useGetDealCardDetailsQuery } from '../../generated/graphql';
import Grid from '../../shared/Grid';
import Loading from '../../shared/Loading';

type ReadinessProps = {
    setHeaderContent: React.Dispatch<React.SetStateAction<React.ReactChild | null>>;
};

const Readiness = ({ setHeaderContent }: ReadinessProps) => {
    useEffect(() => {
        setHeaderContent(<h1 className="text-xl font-bold p-2">Readiness</h1>);
    }, [setHeaderContent]);

    const history = useHistory();

    const limit = 10;
    const { loading, error, data, fetchMore } = useGetDealCardDetailsQuery({
        variables: {
            limit,
            offset: 0
        }
    });
    const [deals, setDeals] = useState<DealCardFragment[]>([]);
    const [showDeals, setShowDeals] = useState<Boolean>(false);
    const [hasNextPage, setNextPage] = useState<boolean>(false);

    const dealsLength = data?.deals?.results ? data.deals.results.length : 0;

    useEffect(() => {
        if (!loading && !error) {
            if (deals) {
                if (dealsLength === 1 && deals[0] && deals[0]._id) return history.push(`/units/deal/${deals[0]._id}`);

                setShowDeals(true);
            }
        }
    }, [loading, error, deals, dealsLength, history]);

    useEffect(() => {
        setDeals(data?.deals.results ?? []);
        setNextPage(!!data?.deals.pageInfo.hasNextPage);
    }, [data]);

    const loadMore = async () => {
        if (!hasNextPage) return;

        const { data: newData } = await fetchMore({
            variables: {
                limit,
                offset: deals.length ?? 0
            }
        });
        setDeals((deals) => [...deals, ...newData.deals.results]);
        setNextPage(newData.deals.pageInfo.hasNextPage);
    };

    if (!showDeals) return <Loading />;

    return (
        <div className="container mx-auto p-4">
            <InfiniteScroll
                loadMore={loadMore}
                hasMore={hasNextPage}
                loader={
                    <div className="container mx-auto p-4">
                        <Loading />
                    </div>
                }
            >
                <Grid>
                    {deals.map((deal) => (
                        <TenantReadinessCard
                            id={deal._id}
                            name={deal.unit?.organisation.name ?? ''}
                            buildingName={deal.unit?.buildingName ?? 'Untitled Building'}
                        />
                    ))}
                </Grid>
            </InfiniteScroll>
        </div>
    );
};

export default Readiness;
