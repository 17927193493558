import React, { useEffect, useRef, useState } from 'react';

import Comment from './Comment';
import CommentButtons from './CommentButtons';
import ClauseContext from './Context';
import { ReactComponent as Cross } from '../../../../../assets/images/cross/black.svg';
import {
    CommentType,
    GetDealDetailsDocument,
    useCommentClauseMutation,
    useGetLayoutUserInfoQuery
} from '../../../../../generated/graphql';
import { Button } from '../../../../../shared/button';
import { handleFetchError } from '../../../../../shared/helpers';
import { ClauseMode } from '../Types';

const Comments = () => {
    const { id, parentID, dealID, history, type, mode, comments, isResponding, setResponding } =
        React.useContext(ClauseContext);

    // Error message
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [complete, setComplete] = useState<boolean>(false);

    const messageRef = useRef<HTMLTextAreaElement>(null);

    const { data: userData } = useGetLayoutUserInfoQuery();

    const [commentClause, { error: commentClauseError, loading: commentClauseLoading }] = useCommentClauseMutation();

    const handleSubmit = async () => {
        if (!messageRef.current?.value || messageRef.current.value.trim().length === 0)
            return setErrorMessage('Please enter a message');

        const { data } = await commentClause({
            variables: {
                dealID: dealID ?? '',
                parentID: parentID!,
                clauseID: id!,
                comment: {
                    message: messageRef.current.value,
                    type: CommentType.Edit
                }
            },
            refetchQueries: [{ query: GetDealDetailsDocument, variables: { dealID: dealID ?? '' } }],
            optimisticResponse: {
                dealClauseUpdate: {
                    __typename: 'Clause',
                    _id: id!,
                    history: history as any,
                    comments: [
                        ...(comments ?? []),
                        {
                            _id: 'new_clause_comment',
                            type: CommentType.Edit,
                            date: new Date(),
                            actor: {
                                _id: userData?.user.__typename === 'User' ? userData.user._id : '',
                                firstName: userData?.user.__typename === 'User' ? userData.user.firstName : '',
                                lastName: userData?.user.__typename === 'User' ? userData.user.lastName : ''
                            },
                            message: messageRef.current.value
                        }
                    ]
                }
            }
        });

        if (commentClauseError || data?.dealClauseUpdate.__typename !== 'Clause')
            return handleFetchError('Failed to send your response', commentClauseError, data?.dealClauseUpdate);

        setComplete(true);
        setResponding!(false);
    };

    // When undo button is pressed, we need to show comment reponse buttons again
    useEffect(() => {
        setComplete(false);
    }, [type, history]);

    return (
        <div className="bg-navy-lightest px-4 py-2 text-sm border-t-2 rounded-bl rounded-br border-white flex flex-col gap-2">
            {/* Comments */}
            {comments?.map((comment) => (
                <Comment {...comment} />
            ))}
            {/* Accept/Reject Change */}
            {mode === ClauseMode.EDIT &&
                (isResponding ? (
                    <>
                        <div className="w-full flex flex-row justify-start items-center gap-2">
                            <button onClick={() => setResponding!(false)} className="focus:outline-none">
                                <Cross className="w-5 h-5" />
                            </button>
                            <p className="text-gray-600 font-medium text-sm mb-0.5">Message</p>
                            <textarea
                                className="rounded bg-white p-2 border border-transparent focus:outline-none focus:border-newTeal-main flex-1"
                                placeholder="Your Comment"
                                autoFocus
                                rows={1}
                                ref={messageRef}
                            ></textarea>
                            <Button
                                onClick={handleSubmit}
                                background="newTeal-main"
                                text="white"
                                className="px-4 font-semibold"
                                height="10"
                            >
                                {commentClauseLoading ? 'Sending...' : 'Add Comment'}
                            </Button>
                        </div>
                        {errorMessage && (
                            <div className="bg-grey-100 border border-red-600 rounded py-2 px-4 my-4 mt-2">
                                <span className="text-center text-red-600">{errorMessage}</span>
                            </div>
                        )}
                    </>
                ) : (
                    <CommentButtons complete={complete} />
                ))}
        </div>
    );
};

export default Comments;
