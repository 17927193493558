import { get, isNil } from 'lodash';
import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';

import { useGetDealTenantDetailsQuery } from '../../../../generated/graphql';
import NavIcon, { NavRouteName } from '../../../../layout/SideBar/components/NavIcon';
import useScreenHeight from '../../../../shared/hooks/useScreenHeight';
import { ReadinessContext, STAGE } from '../../Tenant/Readiness/context';

type StageStepProps = {
    stage: STAGE;
    title: string;
    icon: NavRouteName;
    status?: StageStatus;
};

enum StageStatus {
    PENDING,
    EDITED,
    COMPLETED
}

const StageStep = ({ stage, title, icon, status }: StageStepProps) => {
    const screenHeight = useScreenHeight();
    const isThinner = screenHeight <= 476;

    const { stage: currentStage, setStage } = useContext(ReadinessContext);
    const isActive = stage === currentStage;

    const getStatusColor = () => {
        switch (status) {
            case StageStatus.EDITED:
                return 'bg-yellow-main border-yellow-main';
            case StageStatus.COMPLETED:
                return 'bg-newTeal-main border-newTeal-main';
            default:
                return 'bg-white border-r-2';
        }
    };

    return (
        <div
            onClick={() => setStage(stage)}
            className={`cursor-pointer w-full flex items-center justify-start hover:bg-newGray-dark h-15 mt-2.5 mb-2 ${
                isActive ? 'bg-newGray-dark' : ''
            }`}
        >
            <div style={{ borderRadius: '0 2rem 2rem 0' }} className={`h-4 w-10  mr-2 ${getStatusColor()}`} />
            <NavIcon routeName={icon} isThinner={isThinner} />
            <p className="text-white md:hidden xl:flex lg:hiden lg-xl:flex xl-flex text-base ml-1 font-normal">
                {title}
            </p>
        </div>
    );
};

const LeftHandSideBar = () => {
    const { id } = useParams<{ id: string }>();
    const { data: stageData } = useGetDealTenantDetailsQuery({
        variables: { dealID: id }
    });

    const { stage } = useContext(ReadinessContext);

    const getStageStatus = (completed?: boolean) => {
        if (isNil(completed)) return StageStatus.PENDING;

        if (completed) return StageStatus.COMPLETED;
        else return StageStatus.EDITED;
    };

    return (
        <div
            data-testid="layout--sidebar"
            //style={{ backgroundColor: "#404040" }}
            className={`md:pb-14 pt-7 overflow-y-auto h-full overflow-x-hidden sidebar-height bg-navy-main sticky top-10 ${
                stage !== undefined ? 'z-60' : 'z-10'
            }`}
        >
            <div className={`xxxl:mb-6 w-full flex flex-row ml-4`}>
                <div
                    style={{
                        position: 'relative',
                        width: '20px',
                        height: '20px',
                        backgroundColor: '#fff',
                        borderRadius: '50%',
                        marginTop: '0.5rem'
                    }}
                >
                    <div
                        className="bg-navy-main"
                        style={{
                            transform: 'translate(16%, 16%)',
                            width: '15px',
                            height: '15px',
                            borderRadius: '50%'
                        }}
                    >
                        <div
                            className="bg-newTeal-main"
                            style={{
                                transform: 'translate(25%, 25%)',
                                width: '10px',
                                height: '10px',
                                borderRadius: '50%'
                            }}
                        ></div>
                    </div>
                </div>
                <div className="flex flex-col flex-1 overflow-hidden mt-1 ml-2">
                    <p
                        style={{ fontSize: '18px' }}
                        className="text-white sm:hidden pr-6 md:hidden xl:flex lg:flex mt-0 pt-0 font-normal"
                    >
                        Tenant Readiness
                    </p>
                    <p
                        style={{
                            fontSize: '10px',
                            color: '#BFBFBF',
                            width: '80%'
                        }}
                        className="text-white md:hidden xl:flex lg:hidden text-base font-sm text-sm"
                    >
                        Completing Readiness as quickly as possible will push the Deal to close quicker.
                    </p>
                </div>
            </div>
            <StageStep
                stage={STAGE.TENANT_INFORMATION}
                title="Tenant Details"
                icon="Tenant Details"
                status={getStageStatus(get(stageData, 'deal.details.one.completed'))}
            />
            <StageStep
                stage={STAGE.LAWYER}
                title="Lawyer Details"
                icon="Lawyer Details"
                status={getStageStatus(get(stageData, 'deal.details.two.completed'))}
            />
            <StageStep
                stage={STAGE.TEAM}
                title="Team"
                icon="Team"
                status={getStageStatus(get(stageData, 'deal.details.team.completed'))}
            />
            <StageStep
                stage={STAGE.COVENANT}
                title="Covenant"
                icon="Covenant"
                status={getStageStatus(get(stageData, 'deal.details.three.completed'))}
            />
            <StageStep
                stage={STAGE.AML_CHECK}
                title="AML Checks"
                icon="AML Check"
                status={getStageStatus(get(stageData, 'deal.details.aml.completed'))}
            />
            <StageStep
                stage={STAGE.FINAL_INFO}
                title="Final Info"
                icon="Final Info"
                status={getStageStatus(get(stageData, 'deal.details.final.completed'))}
            />
        </div>
    );
};

export default LeftHandSideBar;
