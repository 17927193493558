import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { DealState, useGetDealTenantDetailsQuery } from '../../../generated/graphql';

type TenantActionCardProps = {
    id: string;
    state?: DealState;
    name: string;
    dealId?: string;
    buildingName: string;
    address: string;
};

const TenantActionCard = ({ id, name, state, dealId, buildingName, address }: TenantActionCardProps) => {
    const [progressColor, setProgressColor] = useState<string>('bg-red-500');
    const [canNudge] = useState<boolean>(true);
    const { data: detailsData } = useGetDealTenantDetailsQuery({
        variables: {
            dealID: dealId ? dealId : id
        },
        fetchPolicy: 'cache-and-network'
    });

    const { ...details } = detailsData?.deal.__typename === 'Deal' && detailsData.deal.details;
    delete details['__typename'];

    const totalStages = Object.keys(details).length - 1 ?? 6;

    let totalCompleted = 0;
    for (const key in details) {
        if (details[key]?.completed) {
            totalCompleted++;
        }
    }

    useEffect(() => {
        setProgressColor(
            totalCompleted && totalCompleted < 3 ? 'bg-red-500' : totalCompleted < 5 ? 'bg-orange-400' : 'bg-green-500'
        );
    }, [totalCompleted, totalStages]);

    if (!canNudge) return <></>;
    return (
        <div
            className="flex flex-1 flex-col md:flex-row justify-between w-100 relative bg-white rounded hover:shadow-md transition duration-200 mt-2 p-4"
            key={id}
        >
            <div className="mb-3 md:mb-0 md:w-2/5 w-full">
                <>
                    <p className="font-semibold text-lg">{name}</p>
                    <p className="font-semibold text-xs">{buildingName}</p>
                    <p className="text-xs text-newGray-main">{address}</p>
                </>
            </div>
            <div className="md:w-3/5 w-full bg-navy-lightest flex flex-col rounded overflow-hidden">
                <div className="flex justify-between items-center p-3 pb-1">
                    <div>
                        <>
                            <p className="text-xs text-gray-600 font-bold">Tenant Readiness</p>
                            <p>
                                <span className="text-lg font-semibold">Stage {totalCompleted}</span>
                                /6
                            </p>
                        </>
                    </div>
                    <div className="flex flex-row">
                        <Link
                            to={`/units/deal/${id}`}
                            className="bg-white w-28 h-10 rounded inline-flex items-center justify-center text-center focus:outline-none"
                        >
                            Improve
                        </Link>
                    </div>
                </div>
                {/* Bar */}
                {totalStages !== undefined && (
                    <div
                        className={`bg-gray-450 h-3 w-full 
                                 relative overflow-hidden rounded-b`}
                    >
                        <span
                            className={`${progressColor} absolute h-full top-0 left-0 transition-all duration-200 bg-red-500`}
                            style={{
                                width: `${(totalCompleted / totalStages) * 100}%`
                            }}
                        ></span>
                        {/* Indents */}
                        <div className="absolute w-1/6">
                            <div className="bg-white h-2 ml-auto" style={{ width: '2px' }}></div>
                        </div>
                        <div className="absolute w-2/6">
                            <div className="bg-white h-2 ml-auto" style={{ width: '2px' }}></div>
                        </div>
                        <div className="absolute w-3/6">
                            <div className="bg-white h-2 ml-auto" style={{ width: '2px' }}></div>
                        </div>
                        <div className="absolute w-4/6">
                            <div className="bg-white h-2 ml-auto" style={{ width: '2px' }}></div>
                        </div>
                        <div className="absolute w-5/6">
                            <div className="bg-white h-2 ml-auto" style={{ width: '2px' }}></div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default TenantActionCard;
