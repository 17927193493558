import { Form, Formik, useFormikContext } from 'formik';
import React, { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';

import { GetDealTenantDetailsDocument, useTenantActionStageOneMutation } from '../../../../../generated/graphql';
import { hasErrors, requiredIfVisible } from '../../../../common/helpers';
import Checkbox from '../Checkbox';
import { ReadinessContext, STAGE } from '../context';
import InputField from '../InputField';
import Stage from '../Stage';

type TenantDetailsValues = {
    addressLine1?: string | null;
    addressLine2?: string | null;
    city?: string | null;
    postcode?: string | null;
    currentAddressLine1?: string | null;
    currentAddressLine2?: string | null;
    currentCity?: string | null;
    currentPostcode?: string | null;
    isSameAddress?: boolean | null;
};

const TenantDetails = () => {
    const { details } = useContext(ReadinessContext);

    const one = details?.one;

    const initialValues: TenantDetailsValues = {
        addressLine1: one?.entity?.address?.line1,
        addressLine2: one?.entity?.address?.line2,
        city: one?.entity?.address?.city,
        postcode: one?.entity?.address?.postCode,
        currentAddressLine1: one?.building?.address?.line1,
        currentAddressLine2: one?.building?.address?.line2,
        currentCity: one?.building?.address?.city,
        currentPostcode: one?.building?.address?.postCode,
        isSameAddress: one?.isSameAddress
    };

    const validationSchema = Yup.object().shape({
        addressLine1: Yup.string().required(''),
        city: Yup.string().required(''),
        postcode: Yup.string().required(''),
        currentAddressLine1: requiredIfVisible(Yup.string().nullable(), ' ', 'isSameAddress'),
        currentCity: requiredIfVisible(Yup.string().nullable(), ' ', 'isSameAddress'),
        currentPostcode: requiredIfVisible(Yup.string().nullable(), ' ', 'isSameAddress')
    });

    return (
        <Formik validationSchema={validationSchema} initialValues={initialValues} onSubmit={() => {}}>
            <TenantDetailsForm edited={!!one?.edited} />
        </Formik>
    );
};

type TenantDetailsFormProps = {
    edited: boolean;
};

const TenantDetailsForm = ({ edited }: TenantDetailsFormProps) => {
    const { values, dirty, validateForm } = useFormikContext<TenantDetailsValues>();

    const { isChanging, setStage, changeStage } = useContext(ReadinessContext);
    const { id } = useParams<{ id: string }>();

    const [actionStage] = useTenantActionStageOneMutation();

    const saveValues = async () => {
        if (!dirty && edited) {
            changeStage();
            return;
        }

        const isValid = !hasErrors<TenantDetailsValues>(await validateForm(values));

        await actionStage({
            variables: {
                dealID: id!,
                completed: isValid,
                isSameAddress: values.isSameAddress,
                entity: {
                    address: {
                        line1: values.addressLine1,
                        line2: values.addressLine2,
                        city: values.city,
                        postCode: values.postcode
                    }
                },
                building: {
                    visible: true,
                    address: {
                        line1: values.currentAddressLine1,
                        line2: values.currentAddressLine2,
                        city: values.currentCity,
                        postCode: values.currentPostcode
                    }
                }
            },
            onCompleted: changeStage,
            refetchQueries: [
                {
                    query: GetDealTenantDetailsDocument,
                    variables: {
                        dealID: id!
                    }
                }
            ]
        });
    };

    useEffect(() => {
        if (isChanging) saveValues();
    }, [isChanging]);

    return (
        <Form className="bg-white pt-14 h-full w-full mx-auto flex flex-col items-start">
            <Stage
                stageNumber={1}
                title="Tenant Details"
                subtitle="Tenant Registered Address"
                nextStage="Next: Stage 2 - Lawyer Details"
                onNextClick={() => {
                    setStage(STAGE.LAWYER);
                }}
                requiredFieldsText
            >
                <InputField name={`addressLine1`} label="Address Line 1" required />
                <InputField name={`addressLine2`} label="Address Line 2" />
                <div className="flex flex-row w-full gap-8">
                    <div className="flex flex-col w-1/2">
                        <InputField name={`city`} label="City" width="w-full" required />
                    </div>
                    <div className="flex flex-col w-1/2">
                        <InputField name={`postcode`} label="Postcode" width="w-full" required />
                    </div>
                </div>

                <p className="text-sm font-medium my-3">Current Business Address</p>
                <Checkbox
                    name="isSameAddress"
                    label="Select if your Current Address is different
                                to your Registered Address"
                    checked={values.isSameAddress!}
                />
                {values.isSameAddress && (
                    <>
                        <InputField name={`currentAddressLine1`} label="Address Line 1" required />
                        <InputField name={`currentAddressLine2`} label="Address Line 2" />
                        <div className="flex flex-row w-full gap-8">
                            <div className="flex flex-col w-1/2">
                                <InputField name={`currentCity`} label="City" width="w-full" required />
                            </div>
                            <div className="flex flex-col w-1/2">
                                <InputField name={`currentPostcode`} label="Postcode" width="w-full" required />
                            </div>
                        </div>
                    </>
                )}
            </Stage>
        </Form>
    );
};

export default TenantDetails;
