import { ApolloError } from '@apollo/client';
import axios from 'axios';
import fileDownload from 'js-file-download';
import { useLocation } from 'react-router-dom';

import { FixedTags } from './Tags';
import { DealDetailsFragment, DealState, DealUserParty, UnitTaskFragment, User, UserType } from '../generated/graphql';

/**
 * Generic type guard. Determins if given variable is of the given type.
 * @param toCheck Variable to check
 * @param property Known property available on that type
 * @returns Type guard
 */
export const isOfType = <T extends unknown>(toCheck: any, property: keyof T): toCheck is T => {
    return (toCheck as T)[property] !== undefined;
};

// Get URL Parameters
export const useQuery = () => {
    return new URLSearchParams(useLocation().search);
};

export const getOrdinal = (n: number) => {
    var s = ['th', 'st', 'nd', 'rd'],
        v = n % 100;
    return n + (s[(v - 20) % 10] || s[v] || s[0]);
};

export const getTaskValue = (tasks: UnitTaskFragment[], tag: { id: string }) => {
    if (!tasks || tasks.length === 0) return null;
    const value = tasks.find((task) => task.task?.tags?.includes(tag.id) && task.value)?.value;
    switch (value?.__typename) {
        case 'StringValue':
            return value.stringValue;
        case 'FloatValue':
            return `${value.floatValue}`;
        case 'BooleanValue':
            return value.booleanValue ? 'Yes' : 'No';
        case 'File':
            return value.url;
        default:
            return null;
    }
};

export const getLandlordContactInfo = (tasks: UnitTaskFragment[]) => {
    if (tasks) {
        const landlordName = getTaskValue(tasks, FixedTags.landlordContactName);
        const landlordEmail = getTaskValue(tasks, FixedTags.landlordContactEmail);
        const landlordPhone = getTaskValue(tasks, FixedTags.landlordContactPhone);
        const landlordLawyerName = getTaskValue(tasks, FixedTags.landlordLawyerContactName);
        const landlordLawyerEmail = getTaskValue(tasks, FixedTags.landlordLawyerContactEmail);
        const landlordLawyerPhone = getTaskValue(tasks, FixedTags.landlordLawyerContactPhone);
        const landlordLawyerCompany = getTaskValue(tasks, FixedTags.landlordLawyerContactCompany);

        return {
            landlord: {
                name: landlordName,
                email: landlordEmail,
                phone: landlordPhone
            },
            lawyer: {
                name: landlordLawyerName,
                email: landlordLawyerEmail,
                phone: landlordLawyerPhone,
                company: landlordLawyerCompany
            }
        };
    } else {
        return {
            landlord: {
                name: null,
                email: null,
                phone: null
            },
            lawyer: {
                name: null,
                email: null,
                phone: null,
                company: null
            }
        };
    }
};

// Substitute values into clause long form text
export const formatClause = (
    longForm: string | undefined,
    value: string | number | null | undefined,
    valueSuffix: string | null | undefined,
    tags: string[],
    window?: boolean,
    lean?: boolean,
    changed?: boolean
) => {
    const numeric_thousands_value =
        !isNaN(Number(value)) && !tags.includes('no_format') ? Number(value).toLocaleString() : value;

    let formattedValue =
        numeric_thousands_value !== null && value !== undefined
            ? `${numeric_thousands_value}${!!valueSuffix ? ' ' + valueSuffix : ''}`
            : '__';

    // Place currency before value
    if (valueSuffix === '£' || valueSuffix === '$' || valueSuffix === '€') {
        formattedValue =
            numeric_thousands_value !== null && value !== undefined
                ? `${!!valueSuffix && valueSuffix}${numeric_thousands_value}`
                : '__';
    }

    if (lean) return longForm?.replace(/({\$v})/g, formattedValue);

    return `${longForm?.replace(
        /\{\$v\}/gm,
        window
            ? `<span class="bg-gray-800 rounded text-white px-2 py-1 h-full" contenteditable="false">${formattedValue}</span>`
            : `<span class="font-semibold ${changed && 'text-red'}">${formattedValue}</span>`
    )}`;
};

// Convert bytes to readable sizes
export const fileSize = (size: number) => {
    if (size === 0) return '0 Bytes';
    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    const i = Math.floor(Math.log(size) / Math.log(k));
    return parseFloat((size / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
};

// Send image through API proxy
export const proxyImage = (url: string, width?: number, height?: number, quality?: number, cropMode?: string) =>
    `${process.env.REACT_APP_API_URL}/proxy/${encodeURIComponent(
        url
    )}?width=${width}&height=${height}&quality=${quality}&crop=${cropMode}`;

export enum DealCardImportant {
    NEGOTIATIONS,
    READINESS
}

// Highlight most important out of negotiation time and unit readiness
//  Days (-2 if not us) > (rating / 5)
export const dealMostImportant = (
    state: DealState,
    currentUserParty: DealUserParty | null,
    days: number,
    unitRating: number
): DealCardImportant =>
    ((state === DealState.Landlord && currentUserParty === DealUserParty.Landlord) ||
    (state === DealState.Tenant && currentUserParty === DealUserParty.Tenant)
        ? days
        : days - 2) >
    (100 - unitRating ?? 1) / 5
        ? DealCardImportant.NEGOTIATIONS
        : DealCardImportant.READINESS;

export const capitaliseFirst = (value: string) =>
    value?.charAt(0).toLocaleUpperCase() + value?.slice(1).toLocaleLowerCase();

export const capitaliseAllWords = (value: string) =>
    value.replace(/(^\w{1})|(\s{1}\w{1})/g, (match) => match.toUpperCase());

export const underscoredToSpace = (value: string) => value.replace(/_/g, ' ');

// Debounce search queries
export const debounce = (func: (...args: any[]) => any, wait: number) => {
    let timeout: NodeJS.Timeout;

    return function executedFunction(...args: any[]) {
        const later = () => {
            clearTimeout(timeout);
            func(...args);
        };

        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
    };
};

/**
 * Add query string required information for intercome
 */
export const addQsInfo = (history: any, userType?: UserType, organisationName?: string | string[], _location?: any) => {
    const location = _location ?? window.location;
    let qs = location.search;
    if (
        (!qs || qs.length === 0) &&
        (userType === UserType.Landlord ||
            userType === UserType.Admin ||
            (userType === UserType.Tenant && Array.isArray(organisationName)))
    ) {
        qs = qs.replace(/(\?|&)org=([A-Za-z0-9%\s])*/g, '');

        const hasQs = qs && qs.length > 0;

        history.replace({
            pathname: location.pathname,
            search: `${hasQs && qs.substring(0, 1) !== '?' ? '?' : ''}${hasQs ? qs + '&' : '?'}${
                Array.isArray(organisationName) ? 'landlord' : ''
            }org${Array.isArray(organisationName) ? 's' : ''}=${removeSpaces(
                !Array.isArray(organisationName)
                    ? removeSpaces(organisationName)
                    : removeSpaces(organisationName.join(','))
            )}`
        });
    }
};

export const humanizeString = (str: string) => {
    str = str.toLowerCase();
    var i,
        frags = str.toLowerCase().split('_');
    for (i = 0; i < frags.length; i++) {
        frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
    }
    return frags.join('');
};

export const urlToQsInfo = (url: string, userType?: UserType, organisationName?: string) => {
    if (userType !== UserType.Landlord && userType !== UserType.Admin) return url;
    return `${url}?org=${removeSpaces(organisationName)}`;
};

export const removeSpaces = (value?: string) => value?.replace(/\s/g, '');

/**
 * Remove duplicates from array
 * @param value Value of strings
 */
export const removeDuplicatesFromArr = (value: string[]) => {
    return [...new Set(value)];
};

/**
 * Move item from index to another index
 * @param from Index position to move from
 * @param to Index position to move to
 * @param a Array
 * @returns New array containing moved items
 */
export const move = <T extends unknown>(
    from: number,
    to: number,
    ...a: T[]
): // eslint-disable-next-line no-sequences
T[] => (a.splice(to, 0, ...a.splice(from, 1)), a);

export const handleFetchError = (title: string, error?: ApolloError | Error, data?: any) => {
    // Errors are now handled by Apollo Link Error
    return;

    // // Check if any errors thrown
    // if ((!error && !data) || data?.__typename !== "Error") return

    // let detail = []

    // // ApolloError or generic Error thrown, handle accordingly
    // if (error) {
    //     // eslint-disable-next-line
    //     console.error(error)
    //     detail.push(error.name)
    //     detail.push(error.message)
    // }
    // // API Error
    // if (data && data?.__typename === "Error") {
    //     // eslint-disable-next-line
    //     console.error(`APIError: ${data.code} - ${data.message}`)
    //     detail.push(`Code: ${data.code}`)
    //     detail.push(`Message: ${data.message}`)

    //     // Don't show 401 errors to the user
    //     if (data.code === 401) return
    // }

    // // TODO: Set up Sentry
    // // Tell sentry if the error is not done so already
    // // if (!support)
    // //   support = Sentry.captureException(err)

    // // detail.push('Support ID: ' + support)

    // toast(<ErrorPopup title={title} details={detail} />, {
    //     hideProgressBar: true,
    //     draggable: false,
    //     autoClose: false,
    //     closeOnClick: false,
    //     closeButton: true
    // })
};

export const getUserFullName = (user?: Partial<User> | undefined | null) => {
    if (!user) return 'Deleted User';

    return `${user.firstName} ${user.lastName}`;
};

export const isTenantSide = (dealData?: DealDetailsFragment) => {
    return dealData?.state.toString() === UserType.Tenant && dealData?.currentUserParty.toString() === UserType.Tenant;
};

export const isTenant = (user: User) => user.type === UserType.Tenant;

export const downloadFile = async (url: string, name: string) => {
    const response = await axios.get(url, {
        responseType: 'blob'
    });
    fileDownload(response.data, name);
};
