import React from 'react';

import { ReactComponent as ChevronDown } from '../../../../../assets/images/chevron/down/black.svg';
import { ReactComponent as ChevronUp } from '../../../../../assets/images/chevron/up/black.svg';
import { DealDetailsFragment, DealState } from '../../../../../generated/graphql';
import { Button } from '../../../../../shared/button';

type DealSettingsProps = {
    deal?: DealDetailsFragment;
    setClauseReordering?: React.Dispatch<React.SetStateAction<boolean>>;
    setTitlesReordering: React.Dispatch<React.SetStateAction<boolean>>;
    isOpen?: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const DealSettings = ({ setClauseReordering, setTitlesReordering, deal, isOpen, setOpen }: DealSettingsProps) => {
    const showReorderButton = [DealState.Landlord, DealState.Edit].includes(deal?.state!);

    const check = deal && deal?.clauseSummary.incomplete > 2;

    return (
        <div>
            {showReorderButton && (
                <Button
                    onClick={() => setOpen((isOpen) => !isOpen)}
                    text={'sm'}
                    height={'9'}
                    className="p-2 lg:px-4 font-semibold hover:border-navy-light hover:bg-navy-light bg-navy-lightestxs:w-8 sm:w-8 lg:w-28 z-10 justify-between items-center"
                >
                    <div className="flex flex-col">
                        <ChevronUp className="w-4 h-4 -mb-1" />
                        <div className="w-3 border-b-2 border-newGray-darkish" style={{ marginLeft: '1.5px' }} />
                        <div
                            className="w-3 border-b-2 border-newGray-darkish"
                            style={{ marginLeft: '1.5px', marginTop: '1.5px' }}
                        />
                        <ChevronDown className="w-4 h-4 -mt-1" />
                    </div>
                    <p className="xs:hidden sm:hidden lg:flex">Reorder</p>
                </Button>
            )}
            {isOpen && (
                <div className="absolute z-50 right-3">
                    <div className=" w-40 bg-white border border-newTeal-main rounded  mt-2 mr-2 flex flex-col">
                        <span
                            onClick={() => setTitlesReordering((isReordering) => !isReordering)}
                            className="border-navy-light border-b inline-flex items-center justify-center h-10 cursor-pointer hover:bg-navy-lightest"
                        >
                            Reorder Headings
                        </span>
                        {setClauseReordering && check && (
                            <span
                                onClick={() => {
                                    setClauseReordering((isReordering) => !isReordering);
                                    setOpen(false);
                                }}
                                className="border-navy-light border-b inline-flex items-center justify-center h-10 cursor-pointer hover:bg-navy-lightest"
                            >
                                Reorder Clauses
                            </span>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default DealSettings;
