import React from 'react';

import Window from '../../shared/Window';

type DeleteUnitWindowProps = {
    setDeleteUnitWindow: React.Dispatch<React.SetStateAction<boolean>>;
    handleDeleteClick: () => void;
};

const DeleteUnitWindow = ({ setDeleteUnitWindow, handleDeleteClick }: DeleteUnitWindowProps) => {
    return (
        <Window
            title={'Delete Space'}
            titleCenter
            underlined
            width="w-2/5"
            setShow={setDeleteUnitWindow}
            contentMiddle={true}
            primaryBtn
            onPrimaryBtnClick={handleDeleteClick}
            secondaryBtn
            onSecondaryBtnClick={() => setDeleteUnitWindow(false)}
        >
            <div className="flex flex-col items-center">
                <h2 className="text-center text-lg my-8 w-4/5">
                    Are you sure you want to <span className="font-semibold text-uppercase">delete</span> this space?
                    This process is irreversible.
                </h2>
            </div>
        </Window>
    );
};

export default DeleteUnitWindow;
