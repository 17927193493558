import React, { ChangeEvent, Ref } from 'react';
import { ChangeHandler, FieldError } from 'react-hook-form';

export enum InputType {
    text = 'text',
    password = 'password',
    email = 'email',
    url = 'url',
    number = 'number',
    phone = 'tel',
    date = 'date'
}

export type InputProps = {
    type: InputType;
    placeholder?: string;
    min?: string;
    max?: string;
    label?: string | boolean;
    value?: any;
    defaultValue?: any;
    name?: string;
    disabled?: boolean;
    required?: boolean;
    showRequiredAsterisk?: boolean;
    autoFocus?: boolean;
    autoComplete?: string;
    readOnly?: boolean;
    border?: string;
    className?: string;
    containerClassName?: string;
    height?: string;
    error?: FieldError;
    onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
    onBlur?: (() => void) | ChangeHandler;
    onClick?: () => void;
    onKeyPress?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
    suffix?: string;
};

const Input = React.forwardRef(
    (
        {
            type,
            placeholder,
            min,
            max,
            value,
            defaultValue,
            name,
            label,
            disabled = false,
            required = false,
            showRequiredAsterisk = false,
            autoFocus: autofocus = false,
            autoComplete = 'on',
            readOnly = false,
            className,
            containerClassName = '',
            height,
            error,
            onChange,
            onBlur,
            onClick,
            onKeyPress,
            suffix
        }: InputProps,
        ref: Ref<any>
    ) => {
        return (
            <div className={`relative w-full flex ${containerClassName}`}>
                {label && !error && (
                    <p className="absolute top-0 left-0 text-gray-600 font-medium text-sm mb-0.5">
                        {label}
                        <span className="text-newTeal-main ml-1">{required && showRequiredAsterisk && '*'}</span>
                    </p>
                )}
                {error && (
                    <p className="absolute top-0 left-0 text-red-500 font-medium text-sm mb-0.5">{error.message}</p>
                )}
                <input
                    type={type.toString()}
                    placeholder={placeholder}
                    min={min}
                    max={max}
                    value={value}
                    defaultValue={defaultValue}
                    name={name}
                    autoFocus={autofocus}
                    disabled={disabled}
                    required={required}
                    readOnly={readOnly}
                    autoComplete={autoComplete}
                    onChange={onChange}
                    onClick={onClick}
                    ref={ref}
                    className={`p-3 h-${height ?? '12'} border rounded focus:outline-none text-lg ${
                        error
                            ? 'border border-red-500 focus:border-red-500 shadow-red'
                            : 'border-navy-light focus:border-newTeal-main'
                    } ${className} ${label && 'mt-6'}`}
                    onKeyPress={onKeyPress}
                    onBlur={onBlur}
                />
                {suffix && <div className="absolute right-4 top-9 text-gray-600">{suffix}</div>}
            </div>
        );
    }
);

export default Input;
