import { format } from 'date-fns';
import React, { useRef, useState } from 'react';

import ConfirmClauseDelete from './ConfirmClauseDeleteWindow';
import { ConfirmClauseUpdate } from './ConfirmClauseUpdateWindow';
import { ReactComponent as Remove } from '../../../assets/images/delete/black.svg';
import { ReactComponent as Refresh } from '../../../assets/images/refresh/refresh.svg';
import { ReactComponent as Save } from '../../../assets/images/save/save.svg';
import {
    ClauseTemplate,
    SearchClauseBankDocument,
    SearchClauseBankQuery,
    SingleLinkedTemplateClauseFragment,
    UnitUse,
    useClauseEditAdminMutation,
    useOrganisationClauseEditMutation
} from '../../../generated/graphql';
import { Button } from '../../../shared/button';
import { useDesignatedUseChange } from '../../../shared/hooks/useDesignatedUseChange';
import Select from '../../../shared/Inputs/Select';
import { LinkedClause } from '../../../shared/Negotiator/NewClauseWindow';
import NewClauseDetails from '../../../shared/Negotiator/NewClauseWindow/NewClauseDetails';
import SuccessIcon from '../../../shared/SuccessIcon';
import { FixedTags } from '../../../shared/Tags';
import Window from '../../../shared/Window';

type ClauseBankEditWindowProps = {
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
    _id: string;
    organisationID?: string;
    value?: string | null;
    valueSuffix?: string | null;
    shortForm?: string | null;
    longForm?: string | null;
    tags?: string[] | null;
    linkedClauses?: SingleLinkedTemplateClauseFragment[];
    section?: string | null;
    level1Group?: string | null;
    level2Group?: string | null;
    dropdowns?: {
        title?: string | null;
        groups: {
            title?: string | null;
            groups: {
                title?: string | null;
            }[];
        }[];
    }[];
    uses?: UnitUse[] | undefined;
    updated?: Date | null;
    updatedDealsAt?: Date | null;
    updatedTemplatesAt?: Date | null;
};

export type AddClauseItem = {
    value: string;
    valueSuffix: string;
    shortForm: string;
    longForm: string;
};

const ClauseBankEditWindow = ({
    setShow,
    _id,
    organisationID,
    value,
    shortForm,
    longForm,
    tags,
    linkedClauses: linked,
    level1Group,
    section,
    dropdowns,
    level2Group,
    valueSuffix,
    uses,
    updated,
    updatedDealsAt,
    updatedTemplatesAt
}: ClauseBankEditWindowProps) => {
    // Error message
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    const { onChange, designatedUseOptions, associatedUses, selectValue } = useDesignatedUseChange(uses);

    // Display linked clauses
    const [linkedClauses, setLinkedClauses] = useState<SingleLinkedTemplateClauseFragment[]>(
        (linked ?? []).filter((clause) => clause._id !== _id)
    );
    // API linked clause IDs - store changed
    const [addedLinkedClauses, setAddedLinkedClauses] = useState<string[]>([]);
    const [removedLinkedClauses, setRemovedLinkedClauses] = useState<string[]>([]);
    // Add/remove from display box and api
    const addLinkedClause = (clause: LinkedClause) => {
        setLinkedClauses([...linkedClauses, clause as SingleLinkedTemplateClauseFragment]);
        setAddedLinkedClauses([...addedLinkedClauses, clause._id ?? '']);
    };
    const removeLinkedClause = (clause: LinkedClause) => {
        setLinkedClauses(linkedClauses.filter((linkedClause) => linkedClause._id !== clause._id));
        setRemovedLinkedClauses([...removedLinkedClauses, clause._id ?? '']);
    };

    // Sections and Groups Selection
    const [sectionName, setSectionName] = useState<string | undefined | null>(section);
    const [groupName, setGroupName] = useState<string | undefined | null>(level1Group);
    const [clauseGroupName, setClauseGroupName] = useState<string | undefined | null>(level2Group);

    // Tags
    const [clauseTags, setClauseTags] = useState<string[]>(tags ?? []);

    const [showDeleteWindow, setShowDeleteWindow] = useState(false);
    const [showClauseUpdate, setShowClauseUpdate] = useState(false);

    const [editClauseBank] = useClauseEditAdminMutation();
    const [editOrgClauseBank] = useOrganisationClauseEditMutation();

    // Input Refs
    const shortFormRef = useRef<HTMLInputElement>(null);
    const longFormRef = useRef<HTMLInputElement>(null);
    const valueRef = useRef<HTMLInputElement>(null);
    const valueSuffixRef = useRef<HTMLInputElement>(null);

    // Success Messages
    const [success, setSuccess] = useState(false);

    // Submit button
    // Add to clause bank selected
    const handleAddClauseClick = async () => {
        let longForm = longFormRef.current?.value;
        let shortForm = shortFormRef.current?.value;
        let valueSuffix = valueSuffixRef.current?.value;
        let value: any = valueRef.current?.value;

        // if {$v} is present in lognform than value will be value or '*' if empty
        // if {$v} is not present we save value as null
        if (longForm?.includes('{$v}')) {
            if (!value) value = '*';
        } else value = null;

        // Evaluates if value contains special characters
        let specialCharacters = /^[_A-z0-9*]*((-|\s)*[_A-z0-9*])*$/g;
        let finalValue: any = value;
        if (!specialCharacters.test(finalValue)) {
            setErrorMessage('Special characters, commas and punctuation are not accepted as a valid value');
            return;
        }

        if (!groupName || !sectionName) {
            setErrorMessage('Both the Clause Group and Clause Heading are required');
            return;
        }

        if (!groupName || !sectionName || !shortForm || !longForm) {
            setErrorMessage('Short form and Long form are required fields');
            return;
        }

        let variables: any = {
            _id,
            section: sectionName,
            level1Group: groupName,
            level2Group: clauseGroupName,
            value,
            valueSuffix,
            longForm: longForm
                ? longForm
                      .replace(
                          /<span class="bg-gray-800 rounded text-white px-2 py-1 h-full" contenteditable="false">(?:.*?)<\/span>/g,
                          '{$v}'
                      )
                      .replace(/&nbsp;/g, ' ')
                : undefined,
            shortForm,
            linked: {
                add: addedLinkedClauses,
                remove: removedLinkedClauses
            },
            associatedUses
        };

        if (organisationID) {
            variables.organisationID = organisationID;
            await editOrgClauseBank({
                variables,
                updateQueries: {
                    searchClauseBank: ({ clauseBank }) => {
                        return {
                            clauseBank: clauseBank.map((clause: ClauseTemplate) =>
                                clause._id === _id
                                    ? {
                                          ...clause,
                                          associatedUses,
                                          value,
                                          valueSuffix,
                                          longForm: longForm
                                              ? longForm
                                                    .replace(
                                                        /<span class="bg-gray-800 rounded text-white px-2 py-1 h-full" contenteditable="false">(?:.*?)<\/span>/g,
                                                        '{$v}'
                                                    )
                                                    .replace(/&nbsp;/g, ' ')
                                              : undefined,
                                          shortForm
                                      }
                                    : linkedClauses.find((linkedClause) => linkedClause._id === clause._id)
                                    ? {
                                          ...clause,
                                          associatedUses,
                                          linked: {
                                              results: linkedClauses.map((linkedClause) => ({
                                                  __typename: 'ClauseNonLinkedTemplate',
                                                  ...linkedClause
                                              }))
                                          }
                                      }
                                    : clause
                            )
                        };
                    }
                }
            });
        } else {
            await editClauseBank({
                variables: {
                    ...variables,
                    associatedUses,
                    tags: clauseTags
                },
                update(store, { data }) {
                    if (!data) return;

                    let clauseBankData = store.readQuery<SearchClauseBankQuery>({
                        query: SearchClauseBankDocument,
                        variables: {
                            organisationID: [],
                            only: true,
                            query: ''
                        }
                    });

                    if (!clauseBankData) return;

                    store.writeQuery<SearchClauseBankQuery>({
                        query: SearchClauseBankDocument,
                        variables: {
                            organisationID: [],
                            only: true,
                            query: ''
                        },
                        data: {
                            ...clauseBankData,
                            clauseBank: (clauseBankData.clauseBank ?? []).map((clause) =>
                                clause._id === _id
                                    ? {
                                          ...clause,
                                          associatedUses,
                                          value,
                                          valueSuffix,
                                          longForm: longForm
                                              ? longForm
                                                    .replace(
                                                        /<span class="bg-gray-800 rounded text-white px-2 py-1 h-full" contenteditable="false">(?:.*?)<\/span>/g,
                                                        '{$v}'
                                                    )
                                                    .replace(/&nbsp;/g, ' ')
                                              : undefined,
                                          shortForm
                                      }
                                    : variables.linked.remove.includes(clause._id)
                                    ? {
                                          ...clause,
                                          associatedUses,
                                          linked: {
                                              results: []
                                          }
                                      }
                                    : [...variables.linked.add, ...linkedClauses].find(
                                          (linkedClause) =>
                                              linkedClause._id === clause._id || linkedClause === clause._id
                                      )
                                    ? {
                                          ...clause,
                                          associatedUses,
                                          linked: {
                                              results: [variables, ...linkedClauses].map((linkedClause) => ({
                                                  __typename: 'ClauseNonLinkedTemplate',
                                                  ...linkedClause
                                              }))
                                          }
                                      }
                                    : clause
                            )
                        }
                    });
                }
            });
        }

        setSuccess(true);
    };

    const buttons = (
        <div className="pt-2 flex items-center justify-center flex-wrap">
            {_id && (
                <Button
                    background="navy-lightest"
                    text="black"
                    onClick={() => setShowDeleteWindow(true)}
                    className={`px-10 mr-2 font-semibold w-full md:w-auto ml-2 md:mr-2 mb-2`}
                >
                    <Remove className="w-5 h-5 mr-2" />
                    Delete Clause
                </Button>
            )}
            <Button
                background="newTeal-main"
                text="white"
                onClick={handleAddClauseClick}
                className={`px-10 mr-2 font-semibold w-full md:w-auto ml-2 md:mr-2 mb-2`}
            >
                <div>
                    <div className="flex items-center justify-center">
                        <Save className="w-5 h-5 mr-2" />
                        <p>{`${_id ? 'Save' : 'Add To Clause Bank'}`}</p>
                    </div>
                    <p className="text-xs">{updated && format(new Date(updated), 'dd/MM/yyyy HH:mm')}</p>
                </div>
            </Button>
            <Button
                background="newTeal-main"
                text="white"
                onClick={() => setShowClauseUpdate(true)}
                className={`px-10 mr-2 font-semibold w-full md:w-auto ml-2 md:mr-2 mb-2`}
            >
                <Refresh className="w-5 h-5 mr-2" />
                Update
            </Button>
        </div>
    );

    return (
        <div>
            <Window
                title={`${_id ? 'Edit' : 'New'} Clause Bank Entry`}
                width="w-3/5"
                buttons={buttons}
                setShow={setShow}
                scrollContent={true}
            >
                <div className="md:mb-6">
                    <form className="flex flex-col py-2 px-5" onSubmit={(e) => e.preventDefault()}>
                        <div className="mb-2">
                            <p className="text-gray-600 font-medium text-sm mb-0.5">Group</p>
                            <Select
                                data={
                                    dropdowns?.map((item) => ({
                                        id: item.title,
                                        text: item.title
                                    })) ?? []
                                }
                                defaultValue={section ? { label: section, value: section } : undefined}
                                createable
                                placeholder="Clause Group"
                                className="w-3/4 md:w-1/2"
                                onItemClick={(section) => setSectionName(section)}
                            />
                        </div>
                        <div className="mb-2">
                            <p className="text-gray-600 font-medium text-sm mb-0.5">Clause Heading</p>
                            <Select
                                data={
                                    dropdowns
                                        ?.find((item) => item.title === sectionName)
                                        ?.groups.map((item) => ({
                                            id: item.title,
                                            text: item.title
                                        })) ?? []
                                }
                                disabled={!sectionName}
                                defaultValue={
                                    level1Group
                                        ? {
                                              label: level1Group,
                                              value: level1Group
                                          }
                                        : undefined
                                }
                                createable
                                clearable
                                placeholder="Clause Heading"
                                className="w-3/4 md:w-1/2"
                                onItemClick={(group) => setGroupName(group)}
                            />
                        </div>
                        <div className="mb-2">
                            <p className="text-gray-600 font-medium text-sm mb-0.5">Sub-List Heading</p>
                            <Select
                                data={
                                    dropdowns
                                        ?.find((item) => item.title === sectionName)
                                        ?.groups?.find((item) => item.title === groupName)
                                        ?.groups.map((item) => ({
                                            id: item.title,
                                            text: item.title
                                        })) ?? []
                                }
                                disabled={!level1Group || !sectionName || !groupName}
                                defaultValue={
                                    level2Group
                                        ? {
                                              label: level2Group,
                                              value: level2Group
                                          }
                                        : undefined
                                }
                                createable
                                clearable
                                placeholder="Sub-List Heading"
                                className="w-3/4 md:w-1/2"
                                onItemClick={(clauseGroup) => setClauseGroupName(clauseGroup)}
                            />
                        </div>
                        <div className="mb-2">
                            <p className="text-gray-600 font-medium text-sm mb-0.5">Designated Uses</p>
                            <Select
                                data={designatedUseOptions}
                                value={selectValue}
                                placeholder="Select use cases"
                                isMulti
                                className="w-3/4 md:w-1/2"
                                onChange={(value: any) => onChange(value)}
                            />
                        </div>
                        {!organisationID && (
                            <div className="mb-2">
                                <p className="text-gray-600 font-medium text-sm mb-0.5">Tags</p>
                                <Select
                                    data={[
                                        {
                                            value: 'selectAll',
                                            label: 'Select All'
                                        },
                                        ...Object.values(FixedTags)
                                    ]}
                                    defaultValue={tags?.map((tag) => ({
                                        value: tag,
                                        label: tag
                                    }))}
                                    placeholder="Select tags"
                                    isMulti
                                    createable
                                    className="w-3/4 md:w-1/2"
                                    onChange={(value) =>
                                        setClauseTags(Array.isArray(value) ? value.map((task) => task.value) : [])
                                    }
                                />
                            </div>
                        )}

                        <NewClauseDetails
                            organisationID={organisationID ?? ''}
                            clauseID={_id}
                            valueRef={valueRef}
                            defaultValue={value}
                            defaultLong={longForm}
                            defaultShort={shortForm}
                            defaultSuffix={valueSuffix}
                            suffixRef={valueSuffixRef}
                            shortFormRef={shortFormRef}
                            longFormRef={longFormRef}
                            linkedClauses={linkedClauses as LinkedClause[]}
                            addLinkedClauses={addLinkedClause}
                            removeLinkedClauses={removeLinkedClause}
                            sectionName={sectionName}
                            groupName={groupName}
                        />
                        {errorMessage && (
                            <div className="bg-red-200 border border-red-600 rounded py-2 px-4 my-4 mt-2">
                                <span className="text-center text-red-600">{errorMessage}</span>
                            </div>
                        )}
                    </form>
                </div>
            </Window>
            {showDeleteWindow && (
                <ConfirmClauseDelete
                    setShow={setShowDeleteWindow}
                    clauseID={_id}
                    organisationID={organisationID}
                    setShowEditWindow={setShow}
                />
            )}
            {showClauseUpdate && (
                <ConfirmClauseUpdate
                    setShow={setShowClauseUpdate}
                    clauseID={_id}
                    organisationID={organisationID}
                    updatedDealsAt={updatedDealsAt}
                    updatedTemplatesAt={updatedTemplatesAt}
                />
            )}
            {success && (
                <Window width="w-1/4" setShow={setSuccess}>
                    <div className="flex flex-col items-center justify-center py-10">
                        <SuccessIcon />
                        <h1 className="text-xl font-bold text-center mt-5">Clause Saved</h1>
                    </div>
                </Window>
            )}
        </div>
    );
};

export default ClauseBankEditWindow;
