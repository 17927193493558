import React, { useRef, useState } from 'react';
import Swal from 'sweetalert2';

import {
    ClauseSectionsDocument,
    SearchClauseBankDocument,
    useClauseAddAdminMutation,
    useNewClauseMutation
} from '../../../generated/graphql';
import { useDesignatedUseChange } from '../../../shared/hooks/useDesignatedUseChange';
import Select from '../../../shared/Inputs/Select';
import Loading from '../../../shared/Loading';
import { LinkedClause } from '../../../shared/Negotiator/NewClauseWindow';
import NewClauseDetails from '../../../shared/Negotiator/NewClauseWindow/NewClauseDetails';
import { FixedTags } from '../../../shared/Tags';
import Window from '../../../shared/Window';

type ClauseBankAddWindowProps = {
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
    organisationID?: string;
    dropdowns?: {
        title?: string | null;
        groups: {
            title?: string | null;
            groups: {
                title?: string | null;
            }[];
        }[];
    }[];
};

export type AddClauseItem = {
    value: string;
    valueSuffix: string;
    shortForm: string;
    longForm: string;
};

const ClauseBankAddWindow = ({ setShow, dropdowns, organisationID }: ClauseBankAddWindowProps) => {
    const [newClauseBank, { loading: newClauseBankLoading }] = useNewClauseMutation();

    const { onChange, designatedUseOptions, associatedUses, selectValue } = useDesignatedUseChange();

    // Error message
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    const [linkedClauses, setLinkedClauses] = useState<LinkedClause[]>([]);
    // As this is a new clause, we don't need to worry about existing clauses being removed - all linked clauses are getting added
    const addLinkedClause = (clause: LinkedClause) => {
        setLinkedClauses([...linkedClauses, clause]);
    };
    const removeLinkedClause = (clause: LinkedClause) => {
        setLinkedClauses(linkedClauses.filter((linkedClause) => linkedClause._id !== clause._id));
    };

    // Sections and Groups Selection
    const [groupName, setGroupName] = useState<string | null>();

    const [clauseGroupName, setClauseGroupName] = useState<string | null>();

    const [sectionName, setSectionName] = useState<string | null>();

    // Tags
    const [clauseTags, setClauseTags] = useState<string[]>([]);

    const [addClauseBank, { loading }] = useClauseAddAdminMutation();

    // Input Refs
    const shortFormRef = useRef<HTMLInputElement>(null);
    const longFormRef = useRef<HTMLInputElement>(null);
    const valueRef = useRef<HTMLInputElement>(null);
    const valueSuffixRef = useRef<HTMLInputElement>(null);

    // Submit button
    // Add to clause bank selected
    const handleAddClauseClick = async () => {
        let longForm = longFormRef.current?.value;
        let shortForm = shortFormRef.current?.value;
        let valueSuffix = valueSuffixRef.current?.value;
        let value: any = valueRef.current?.value;

        // if {$v} is present in lognform than value will be value or '*' if empty
        // if {$v} is not present we save value as null
        if (longForm?.includes('{$v}')) {
            if (!value) value = '*';
        } else value = null;

        // Evaluates if value contains special characters
        let specialCharacters = /^[_A-z0-9*]*((-|\s)*[_A-z0-9*])*$/g;
        let finalValue: any = value;
        if (!specialCharacters.test(finalValue)) {
            setErrorMessage('Special characters, commas and punctuation are not accepted as a valid value');
            return;
        }

        if (!groupName || !sectionName) {
            setErrorMessage('Both the Clause Group and Clause Heading are required');
            return;
        }

        if (!associatedUses || associatedUses.length === 0) {
            setErrorMessage('Associated uses must be selected');
            return;
        }

        if (!groupName || !sectionName || !shortForm || !longForm) {
            setErrorMessage('Short form and Long form are required fields');
            return;
        }

        if (organisationID) {
            await newClauseBank({
                variables: {
                    organisationID,
                    value,
                    valueSuffix,
                    longForm: longForm!,
                    shortForm: shortForm!,
                    section: sectionName!,
                    level1Group: groupName!,
                    level2Group: clauseGroupName,
                    associatedUses,
                    ...(linkedClauses && linkedClauses.length > 0
                        ? {
                              linked: {
                                  add: linkedClauses
                                      .filter((linkedClause) => linkedClause._id)
                                      .map((linkedClause) => linkedClause._id ?? '')
                              }
                          }
                        : {})
                },
                updateQueries: {
                    searchClauseBank: ({ clauseBank }, { mutationResult }) => {
                        return {
                            clauseBank: [...clauseBank, mutationResult.data?.organisationClauseAdd]
                        };
                    }
                },
                refetchQueries: () => [SearchClauseBankDocument, ClauseSectionsDocument],
                onCompleted: () => {
                    setShow(false);
                    Swal.fire({
                        title: 'Success!',
                        text: 'Clause created.',
                        icon: 'success',
                        iconColor: '#3CA48E',
                        confirmButtonText: 'Continue',
                        confirmButtonColor: '#3CA48E'
                    });
                }
            });
        } else {
            await addClauseBank({
                variables: {
                    section: sectionName!,
                    level1Group: groupName!,
                    level2Group: clauseGroupName,
                    value,
                    valueSuffix,
                    longForm: longForm!,
                    shortForm: shortForm!,
                    tags: clauseTags,
                    associatedUses,
                    ...(linkedClauses && linkedClauses.length > 0
                        ? {
                              linked: {
                                  add: linkedClauses
                                      .filter((linkedClause) => linkedClause._id)
                                      .map((linkedClause) => linkedClause._id ?? '')
                              }
                          }
                        : {})
                },
                updateQueries: {
                    searchClauseBank: ({ clauseBank }, { mutationResult }) => {
                        return {
                            clauseBank: [...clauseBank, mutationResult.data?.adminClauseAdd]
                        };
                    }
                },
                onCompleted: () => {
                    setShow(false);
                    Swal.fire({
                        title: 'Success!',
                        text: 'Clause created.',
                        icon: 'success',
                        iconColor: '#3CA48E',
                        confirmButtonText: 'Continue',
                        confirmButtonColor: '#3CA48E'
                    });
                }
            });
        }
    };

    if (loading || newClauseBankLoading) {
        return (
            <Window title="New Clause Bank Entry" width="w-3/5" setShow={setShow}>
                <Loading />
            </Window>
        );
    }

    return (
        <div>
            <Window
                title="New Clause Bank Entry"
                width="w-3/5"
                primaryBtn
                primaryBtnText="Add To Clause Bank"
                onPrimaryBtnClick={handleAddClauseClick}
                setShow={setShow}
            >
                <div>
                    <form className="flex flex-col py-2 px-5" onSubmit={(e) => e.preventDefault()}>
                        <div className="w-full mb-2">
                            <p className="text-gray-600 font-medium text-sm mb-0.5">Group</p>
                            <Select
                                data={
                                    dropdowns?.map((item) => ({
                                        id: item.title,
                                        text: item.title
                                    })) ?? []
                                }
                                createable
                                placeholder="Clause Group"
                                className="w-full md:w-1/2"
                                onItemClick={(section) => setSectionName(section)}
                            />
                        </div>
                        <div className="w-full mb-2">
                            <p className="text-gray-600 font-medium text-sm mb-0.5">Heading</p>
                            <Select
                                data={
                                    dropdowns
                                        ?.find((item) => item.title === sectionName)
                                        ?.groups.map((item) => ({
                                            id: item.title,
                                            text: item.title
                                        })) ?? []
                                }
                                disabled={!sectionName}
                                createable
                                clearable
                                placeholder="Clause Heading"
                                className="w-full md:w-1/2"
                                onItemClick={(group) => setGroupName(group)}
                            />
                        </div>
                        <div className="mb-2">
                            <p className="text-gray-600 font-medium text-sm mb-0.5">Sub-List Heading</p>
                            <Select
                                data={
                                    dropdowns
                                        ?.find((item) => item.title === sectionName)
                                        ?.groups?.find((item) => item.title === groupName)
                                        ?.groups.map((item) => ({
                                            id: item.title,
                                            text: item.title
                                        })) ?? []
                                }
                                disabled={!sectionName || !groupName}
                                createable
                                clearable
                                placeholder="Clause Sub-List Heading"
                                className="w-full md:w-1/2"
                                onItemClick={(clauseGroup) => setClauseGroupName(clauseGroup)}
                            />
                        </div>
                        <div className="mb-2">
                            <p className="text-gray-600 font-medium text-sm mb-0.5">Designated Uses</p>
                            <Select
                                data={designatedUseOptions}
                                value={selectValue}
                                placeholder="Select use cases"
                                isMulti
                                className="w-full md:w-1/2"
                                onChange={(value: any) => onChange(value)}
                            />
                        </div>
                        {!organisationID && (
                            <div className="W-full mb-2">
                                <Select
                                    data={Object.values(FixedTags)}
                                    label="Tags"
                                    placeholder="Select tags"
                                    isMulti
                                    createable
                                    className="w-full md:w-1/2"
                                    onChange={(value) =>
                                        setClauseTags(Array.isArray(value) ? value.map((task) => task.value) : [])
                                    }
                                />
                            </div>
                        )}

                        <NewClauseDetails
                            organisationID={organisationID ?? ''}
                            valueRef={valueRef}
                            suffixRef={valueSuffixRef}
                            shortFormRef={shortFormRef}
                            longFormRef={longFormRef}
                            linkedClauses={linkedClauses}
                            addLinkedClauses={addLinkedClause}
                            removeLinkedClauses={removeLinkedClause}
                            sectionName={sectionName}
                            groupName={groupName}
                        />
                        {errorMessage && (
                            <div className="bg-red-200 border border-red-600 rounded py-2 px-4 my-4 mt-2">
                                <span className="text-center text-red-600">{errorMessage}</span>
                            </div>
                        )}
                    </form>
                </div>
            </Window>
        </div>
    );
};

export default ClauseBankAddWindow;
