import React from 'react';

import Window from '../../shared/Window';

type DeleteDealWindowProps = {
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
};

const MaximumPinnedWindow = ({ setShow }: DeleteDealWindowProps) => {
    return (
        <Window
            title="Too Many Pins"
            titleCenter
            width="w-2/5"
            setShow={setShow}
            primaryBtnText="Close"
            onPrimaryBtnClick={() => setShow(false)}
            onCloseClick={() => setShow(false)}
        >
            <div className="flex flex-col items-center py-8">
                <p className="text-center max-w-xl  text-newGray-darkish mx-auto">
                    You've reached the maximum number of pins (3)
                </p>
                <p className="text-center max-w-xl  text-newGray-darkish mx-auto mb-2 mt-3">
                    You will need to unpin a deal before being able to pin another.
                </p>
            </div>
        </Window>
    );
};

export default MaximumPinnedWindow;
