import { useState } from 'react';

import { UnitUse } from '../../generated/graphql';
import { humanizeString } from '../helpers';
import { UseConditionToText } from '../NewUnit/hooks/useGetUnitSelectData';

export const useDesignatedUseChange = (initialUses?: UnitUse[] | undefined) => {
    const [associatedUses, setAssociatedUses] = useState<UnitUse[] | undefined>(initialUses);

    const designatedUseOptions = [
        Object.values(UnitUse).length !== associatedUses?.length && {
            id: 'selectAll',
            text: 'Select All'
        },
        ...Object.values(UnitUse).map((use) => {
            return {
                text: UseConditionToText(use),
                id: use
            };
        })
    ];

    const selectValue = associatedUses
        ? associatedUses.map((use) => ({
              value: use,
              label: UseConditionToText(use) ?? ''
          }))
        : undefined;

    const onChange = (value) => {
        if (!value || value.length === 0) {
            setAssociatedUses([]);
            return;
        }

        if (value.some((item) => item.value === 'selectAll')) {
            const allOptions = Object.values(UnitUse).map((item) => UnitUse[humanizeString(item)]);

            setAssociatedUses(allOptions);
        } else {
            const uses = value.map((item) => UnitUse[humanizeString(item.value)]);
            setAssociatedUses(uses);
        }
    };

    return { onChange, designatedUseOptions, associatedUses, selectValue };
};
