import React, { useEffect, useState } from 'react';
import { ValueType } from 'react-select';

import { ReactComponent as Cross } from '../../../assets/images/cross/currentColor.svg';
import { ReactComponent as Info } from '../../../assets/images/info/black_new.svg';
import {
    GetUnitDetailsDocument,
    GetUserOrganisationDocument,
    UnitSingleActorFragment,
    useAddActorToUnitMutation,
    useGetUserOrganisationQuery,
    UserType
} from '../../../generated/graphql';
import { handleFetchError } from '../../../shared/helpers';
import EmailMultiInput from '../../../shared/Inputs/EmailMultiInput';
import Select from '../../../shared/Inputs/Select';
import Window from '../../../shared/Window';

export enum AdvisorType {
    OUTSIDE_ORGANISATION = 'outside',
    TEAM_MEMBER = 'team'
}

type AddUserAsAgentWindowProps = {
    unitID: string;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
    agentArray?: any;
    setDataActors: React.Dispatch<React.SetStateAction<UnitSingleActorFragment[]>>;
};

const AddUserAsAgentWindow = ({ unitID, agentArray, setDataActors, setShow }: AddUserAsAgentWindowProps) => {
    const { data: userOrganisationData } = useGetUserOrganisationQuery();
    // Error message
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    const [userType, setType] = useState<AdvisorType>();
    const [asMember, setAsMember] = useState<boolean>(false);
    const [emails, setEmails] = useState<string[]>([]);
    const [addActor, { error: addActorError, loading: addActorLoading }] = useAddActorToUnitMutation();

    useEffect(() => {
        if (userType === AdvisorType.OUTSIDE_ORGANISATION) setAsMember(false);
        else setAsMember(true);
    }, [userType]);

    const handleAddUser = async () => {
        if (!emails) return setErrorMessage('Please enter an email address');

        if (!userType) return setErrorMessage('Please select advisor type');

        const { data } = await addActor({
            variables: {
                unitID,
                emails,
                type: UserType.Agent,
                asMember
            },
            refetchQueries: [
                { query: GetUserOrganisationDocument },
                { query: GetUnitDetailsDocument, variables: { unitID } }
            ]
        });

        handleFetchError('Could not add the user to the unit', addActorError, data?.unitAddActor);

        data?.unitAddActor.actors && setDataActors(data.unitAddActor.actors);
        setShow(false);
    };

    if (addActorError && emails.length > 1) {
        setShow(false);
    }

    const pickerData =
        userOrganisationData &&
        userOrganisationData.user.organisation.members.filter((trustedUser) => {
            const actorsEmails = agentArray.map((actor) => actor.email);
            const agentsSet = new Set(actorsEmails);
            return !agentsSet.has(trustedUser.email);
        });

    const advisorButton = (type: AdvisorType, text: string) => {
        return (
            <div
                onClick={() => setType(type)}
                className={`flex items-center justify-center w-full rounded h-10 px-2 cursor-pointer ${
                    userType === type ? 'text-gray-200 bg-newGray-darkish' : 'text-newGray-darkish bg-navy-lightest '
                }`}
            >
                Advisor ({text})
            </div>
        );
    };

    return (
        <Window
            header={
                <div className="flex p-2 ml-4 mr-2 mt-3 mb-2 place-items-center justify-between">
                    <div className="flex flex-row items-center">
                        <p className="text-xl font-semibold border-b-3 border-newTeal-main mr-4">Add Advisor</p>
                    </div>
                    <Cross
                        className="w-5 h-5 -mt-4"
                        onClick={() => {
                            setShow(false);
                        }}
                    />
                </div>
            }
            width="w-1/3"
            primaryBtn
            noScroll
            onPrimaryBtnClick={() => handleAddUser()}
            primaryBtnText={addActorLoading ? 'Adding...' : 'Add'}
            setShow={setShow}
        >
            <>
                {userType && (
                    <div className="flex inline-flex items-center w-full px-4 py-3 bg-navy-lightest">
                        {userType === AdvisorType.OUTSIDE_ORGANISATION ? (
                            <p className="text-xs inline-flex items-center ">
                                <Info className="w-14 h-4" />
                                Use this account type to invite people in other companies that you work closely with,
                                such as Agents/Managing Agents and Lawyers
                            </p>
                        ) : (
                            <p className="text-xs inline-flex items-center">
                                <Info className="w-6 h-4" />
                                Use this account type to invite members of your company
                            </p>
                        )}
                    </div>
                )}
                <form onSubmit={() => handleAddUser()} className="pt-10 md:pt-4 p-4 w-full flex flex-col gap-2 md:mb-8">
                    <div className="flex flex-row items-center w-full font-semibold text-xs gap-2">
                        {advisorButton(AdvisorType.OUTSIDE_ORGANISATION, 'outside your organisation')}
                        {advisorButton(AdvisorType.TEAM_MEMBER, 'team member')}
                    </div>
                    <div />
                    {userType === AdvisorType.TEAM_MEMBER ? (
                        pickerData && (
                            <Select
                                data={Object.values(pickerData).map((trustedUser) => {
                                    return {
                                        text: trustedUser.activated
                                            ? trustedUser.firstName +
                                              ' ' +
                                              trustedUser.lastName +
                                              ' (' +
                                              trustedUser.organisation.name +
                                              ')'
                                            : trustedUser.email,
                                        id: trustedUser.email
                                    };
                                })}
                                label="Email"
                                placeholder={emails.length > 0 ? 'Type to add another email' : 'Enter email'}
                                isMulti
                                blackStyle
                                createable
                                color="black"
                                chipBackground="white"
                                className="md:mr-2 bg-navy-lightest w-full mb-4"
                                onChange={(value: ValueType) => {
                                    if (!value || value.length === 0) {
                                        setEmails([]);
                                        return;
                                    }

                                    value.map((item: ValueType) => {
                                        const newUsersArr = [...emails];
                                        newUsersArr.push(item.value);
                                        setEmails(newUsersArr);
                                    });
                                }}
                            />
                        )
                    ) : (
                        <EmailMultiInput
                            setEmails={setEmails}
                            name="email"
                            placeholder={emails.length > 0 ? 'Type to add another email' : 'Enter email'}
                            label="Email"
                            className={`border w-full mr-1 border-gray-50`}
                        />
                    )}

                    {errorMessage && (
                        <div className="bg-red-200 border border-red-600 rounded py-2 px-4 my-4 mt-2">
                            <span className="text-center text-red-600">{errorMessage}</span>
                        </div>
                    )}
                </form>
            </>
        </Window>
    );
};

export default AddUserAsAgentWindow;
