import React, { RefObject, useEffect, useRef, useState } from 'react';

import ActionsBar from './ActionsBar';
import Body from './Body';
import ClauseContext from './Context';
import useSaveClause from './hooks/useSaveClause';
import useSetClauseType from './hooks/useSetClauseType';
import LongForm from './LongForm';
import {
    DealBreaker,
    DealDetailsFragment,
    DealUserParty,
    useGetNegotiationSummaryQuery
} from '../../../../../generated/graphql';
import { formatClause } from '../../../../../shared/helpers';
import ConfirmClauseDelete, { DealType } from '../../../../../shared/Negotiator/ConfirmClauseDeleteWindow.tsx';
import DealBreakerWindow from '../../../../../shared/Negotiator/DealBreakerWindow';
import SwapClauseWindow from '../../../../../shared/Negotiator/SwapClauseWindow';
import Window from '../../../../../shared/Window';
import { ClauseHistoryWindow } from '../ClauseHistoryWindow';
import EditClauseWindow from '../EditClauseWindow';
import RequestChangeWindow from '../RequestChangeWindow';
import { ClauseMode, ClauseType, Comments, History, LinkedClauses } from '../Types';

export type allClausesID = {
    _id: string;
};

export type ClauseProps = {
    id: string;
    parentID: string;
    allClausesID?: allClausesID[] | undefined;
    dealType: DealType;
    deal: DealDetailsFragment | null;
    value?: string;
    valueSuffix?: string;
    shortForm?: string;
    longForm?: string;
    tags: string[];
    approved?: boolean;
    deleted?: boolean;
    visible?: boolean;
    isReordering?: boolean;
    history: History;
    comments?: Comments;
    dealBreaker: DealBreaker | null;
    sectionName: string;
    groupName: string;
    sectionID?: string;
    groupID: string;
    clauseGroupName?: string;
    linkedClauses: LinkedClauses | [] | null | undefined;
    mode: ClauseMode;
    index?: number;
    clauses?: number;
    setDealComplete: React.Dispatch<any>;
    children?: React.ReactChild;
};

const Clause = React.forwardRef(
    (
        {
            dealType,
            clauseGroupName,
            groupName,
            sectionName,
            setDealComplete,
            allClausesID,
            clauses = 0,
            index = 0,
            isReordering = false,
            ...props
        }: ClauseProps,
        ref: string | ((instance: HTMLDivElement | null) => void) | RefObject<HTMLDivElement> | null | undefined
    ) => {
        const [value, setValue] = useState<string>(props.value ?? '');
        const [type, setType] = useState<ClauseType>(ClauseType.DEFAULT);
        const [isEditing, setEditing] = useState<boolean>(false);
        const [isHidden, setHidden] = useState<boolean>(false);
        const [isBadged, setBadged] = useState<boolean>(false);
        const [showConfirmDelete, setShowConfirmDelete] = useState<boolean>(false);
        const [showDealBreakerWindow, setShowDealBreakerWindow] = useState<boolean>(false);
        const [showSwapClause, setShowSwapClause] = useState<boolean>(false);
        const [showCantFind, setShowCantFind] = useState<boolean>(false);
        const [showHistory, setShowHistory] = useState<boolean>(false);
        const [manualComplete, setManualComplete] = useState<boolean>(false);
        const [isResponding, setResponding] = useState<boolean>(false);
        const [isRejectOpen, setRejectOpen] = useState<boolean>(false);

        const [showDealBreakerReasonWindow, setShowDealBreakerReasonWindow] = useState<boolean>(false);

        // Set clause display type
        useSetClauseType(!!props.approved, props.history, setType);

        const { handleSaveClick, updateClauseLoading } = useSaveClause(
            props.id,
            props.parentID,
            props.deal?._id ?? '',
            setEditing
        );

        // Get negotiation summary
        const {
            data: summaryData,
            error,
            refetch
        } = useGetNegotiationSummaryQuery({
            variables: { id: props.deal?._id ?? '' }
        });

        // Set value on update (e.g. undo button was pressed)
        useEffect(() => {
            setValue(props.value ?? '');
        }, [props.value]);

        // If a clause needs a response load it open
        useEffect(() => {
            setBadged(type !== ClauseType.DEFAULT && type !== ClauseType.APPROVED && type !== ClauseType.REMOVED);
        }, [props.mode, props.deal, props.history, type]);

        // CHECK FOR COMPLETES
        useEffect(() => {
            const incompletes =
                summaryData?.deal.__typename === 'Deal' && summaryData.deal.clauseSummary.incomplete === 0;

            if (incompletes) {
                if (manualComplete) {
                    setDealComplete({ complete: true, type: 'manual' });
                } else {
                    setDealComplete({ complete: true, type: 'auto' });
                }
            }
        }, [summaryData, error, manualComplete, setDealComplete]);

        // Check for expanded class added by walkthrough
        const childRef = useRef<HTMLDivElement>(null);

        if (!props.visible)
            return (
                <div className="w-full mb-2 bg-white border rounded-tl rounded-b" ref={ref} key={props.id}>
                    <div>{props.children}</div>
                </div>
            );

        // Fix for flipmove bug
        if (isHidden) return null;

        return (
            <ClauseContext.Provider
                value={{
                    ...props,
                    dealState: props.deal?.state,
                    dealID: props.deal?._id,
                    value,
                    type,
                    isEditing,
                    hidden: isHidden,
                    isBadged,
                    isResponding,
                    updateClauseLoading,
                    currentUserParty: props.deal?.currentUserParty ?? DealUserParty.Other,
                    handleSaveClick,
                    setValue,
                    setType,
                    setEditing,
                    setBadged,
                    setSwapping: setShowSwapClause,
                    setShowHistory,
                    setDeleting: setShowConfirmDelete,
                    setDealBreaker: setShowDealBreakerWindow,
                    setShowCantFind,
                    setManualComplete,
                    setShowDealBreakerReasonWindow,
                    setResponding,
                    refetch,
                    isRejectOpen,
                    setRejectOpen
                }}
            >
                {showConfirmDelete && (
                    <ConfirmClauseDelete
                        type={dealType}
                        clauseID={props.id}
                        parentID={props.parentID}
                        groupID={props.groupID}
                        deal={props.deal}
                        remove={props.deleted}
                        setShow={setShowConfirmDelete}
                        setClauseDeleted={props.deleted ? setHidden : undefined}
                    />
                )}
                {showDealBreakerWindow && (
                    <DealBreakerWindow
                        clauseID={props.id}
                        parentID={props.parentID}
                        dealID={props.deal?._id ?? ''}
                        dealBreaker={props.dealBreaker}
                        setShow={setShowDealBreakerWindow}
                    />
                )}
                {showSwapClause && (
                    <SwapClauseWindow
                        clauseID={props.id}
                        parentID={props.parentID}
                        dealID={props.deal?._id ?? ''}
                        currentValue={value}
                        currentValueSuffix={props.valueSuffix}
                        currentShortForm={props.shortForm}
                        currentLongForm={props.longForm}
                        currentTags={props.tags}
                        setShow={setShowSwapClause}
                        setShowCantFind={setShowCantFind}
                        linkedClauses={props.linkedClauses ?? []}
                        section={sectionName}
                        group={groupName}
                        clauseGroup={clauseGroupName}
                    />
                )}
                {/* Clause edit window */}
                {showCantFind &&
                    (props.deal?.currentUserParty === DealUserParty.Landlord ? (
                        <EditClauseWindow
                            setShow={setShowCantFind}
                            dealID={props.deal?._id ?? ''}
                            clauseID={props.id}
                            parentID={props.parentID}
                            section={sectionName}
                            group={groupName}
                            value={value}
                            shortForm={props.shortForm}
                            longForm={props.longForm}
                        />
                    ) : (
                        <RequestChangeWindow
                            setShow={setShowCantFind}
                            dealID={props.deal?._id ?? ''}
                            clauseID={props.id}
                            parentID={props.parentID}
                            shortForm={props.shortForm}
                            longForm={formatClause(
                                props.longForm,
                                props.value,
                                props.valueSuffix,
                                props.tags,
                                false,
                                true
                            )}
                            currentTags={props.tags}
                            allClausesID={allClausesID}
                            group={groupName}
                        />
                    ))}

                {showHistory && (
                    <ClauseHistoryWindow
                        currentUserParty={props.deal?.currentUserParty}
                        history={props.history}
                        setShow={setShowHistory}
                    />
                )}
                {/* Reason for deal breaker window */}
                {showDealBreakerReasonWindow && props.dealBreaker?.message && (
                    <Window
                        title="Locked Clause Reason"
                        titleCenter
                        setShow={setShowDealBreakerReasonWindow}
                        width="w-1/4"
                    >
                        <div className="p-4">{props.dealBreaker.message}</div>
                    </Window>
                )}

                <div
                    className={`w-full rounded relative transition duration-500 border rounded-tl rounded-bl mb-2 ${
                        props.mode !== ClauseMode.EDIT || props.approved || type === ClauseType.APPROVED
                            ? 'border-navy-lightest'
                            : 'border-newTeal-main'
                    }`}
                    ref={ref}
                    id={props.id}
                    key={props.id}
                >
                    {/* Body */}
                    <div
                        ref={childRef}
                        className={`w-full px-4 flex flex-row items-center relative z-0 rounded-tl rounded-bl clauseCollapsed text-sm bg-gray-2
                        ${type === ClauseType.DEFAULT && 'clauseExpanded'}
                        `}
                    >
                        <Body
                            clauseID={props.id}
                            deal={props.deal}
                            parentID={props.parentID}
                            groupID={props.groupID}
                            isReordering={isReordering}
                            index={index}
                            clauses={clauses}
                        />
                    </div>
                    {/* Long form */}
                    <LongForm />
                    {/* Actions Bar */}
                    <ActionsBar />
                </div>
            </ClauseContext.Provider>
        );
    }
);

export default Clause;
