import React, { useContext } from 'react';

import { DealDetailsFragment, GetNegotiationSummaryDocument, useUndoDealMutation } from '../../../../generated/graphql';
import Window from '../../../../shared/Window';
import { DealContext } from '../context';

type MakingChangesWindowProps = {
    deal: DealDetailsFragment;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
};

const MakingChangesWindow = ({ deal, setShow }: MakingChangesWindowProps) => {
    const continueMakingChanges = () => {
        setShow(false);
    };

    const { setSeenDealAgreed } = useContext(DealContext);
    const [undoDeal] = useUndoDealMutation();

    const returnToAgreedTerms = async () => {
        await undoDeal({
            variables: { dealID: deal._id },
            refetchQueries: [
                {
                    query: GetNegotiationSummaryDocument,
                    variables: {
                        id: deal._id
                    }
                }
            ],
            onCompleted: () => {
                setSeenDealAgreed(false);
                setShow(false);
            }
        });
    };

    return (
        <Window
            title="Were you making changes?"
            width="w-2/5"
            setShow={setShow}
            titleCenter
            primaryBtn
            primaryBtnText="Return to Agreed Terms"
            onPrimaryBtnClick={returnToAgreedTerms}
            secondaryBtn
            secondaryBtnText="Continue Making Changes"
            onSecondaryBtnClick={() => continueMakingChanges()}
            disableClose
        >
            <div className="pb-4 pt-8 md:pt-4">
                <p className="text-newGray-darkish text-center text-medium mx-auto mt-3">Did you leave the page?</p>
                <p className="text-newGray-darkish text-center text-medium mx-auto mt-3">
                    It looks like you were making changes to terms the tenant has agreed to.
                </p>
                <p className="text-newGray-darkish text-center text-medium mx-auto mt-3 max-w-sm">
                    You may <b>Continue Making Changes</b> or cancel any changes made by retuning to the last ageed
                    terms.
                </p>
            </div>
        </Window>
    );
};

export default MakingChangesWindow;
