import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';

import { DealCardContext } from '..';
import { ReactComponent as Chevron } from '../../../../assets/images/chevron/right/black.svg';
import TenantDetailsWindow from '../../../Units/Unit/Landlord/TenantDetailsWindow';

type DealCardTenantReadinessBUttonProps = {
    unitsPage?: boolean;
};

const DealCardTenantReadinessButton = ({ unitsPage }: DealCardTenantReadinessBUttonProps) => {
    const { compact, id, totalCompleted, total } = useContext(DealCardContext);

    const [showReadiness, setShowReadiness] = useState<boolean>(false);

    return (
        <>
            {showReadiness && <TenantDetailsWindow dealID={id} setShow={setShowReadiness} />}
            <Link
                to={!unitsPage && `/deals/${id}`}
                onClick={() => unitsPage && setShowReadiness(true)}
                className={`flex-1 flex items-center justify-between ml-2 px-1 py-2 rounded quickTransitionAll z-40  hover:shadow-md bg-navy-lightest`}
            >
                <div className={`${compact ? 'pl-3 border-l' : 'ml-3'} border-navy-light`}>
                    <p className="font-light text-sm">Tenant Readiness</p>
                    <p className="font-bold text-lg">
                        {totalCompleted}/{total - 1}
                    </p>
                </div>
                {!compact && <Chevron className="w-5 h-5" />}
            </Link>
        </>
    );
};

export default DealCardTenantReadinessButton;
