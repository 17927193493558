import React from 'react';

import { GetDealUnitDetailsQuery } from '../../../generated/graphql';

type TenantReadinessProps = {
    data: GetDealUnitDetailsQuery;
};

const TenantReadiness = ({ data }: TenantReadinessProps) => {
    // Details
    const { ...details } = data?.deal.__typename === 'Deal' && data.deal.details;
    delete details['__typename'];

    // Get the first stage that has not yet been actioned
    let totalCompleted = 0;
    for (const key in details) {
        if (details[key]?.completed) {
            totalCompleted++;
        }
    }

    if (data.deal.__typename !== 'Deal' || !data.deal.details) {
        return (
            <div className="p-3 bg-white rounded">
                <p className="text-sm text-newGray-darkish">No tasks to complete</p>
            </div>
        );
    }

    return (
        <div className="md:w-2/4 w-full">
            <p className="text-sm font-semibold text-newGray-darkish">Tenant Readiness</p>
            {/* Readiness number */}
            <p className="-mt-2 mb-2">
                <span className="font-semibold text-3xl">Stages {totalCompleted}</span>
                <span className="text-sm font-light">/6</span>
            </p>
            {/* Bar */}
            <div className="mb-4 w-full h-5 bg-navy-lightest relative overflow-hidden rounded">
                <span
                    className={`${
                        totalCompleted < 3 ? 'bg-red-500' : totalCompleted < 5 ? 'bg-orange-400' : 'bg-green-500'
                    } bg-red-500 absolute h-full top-0 left-0`}
                    style={{
                        width: `${(totalCompleted / 6) * 100}%`
                    }}
                ></span>
                {/* Indents */}
                <div className="absolute w-1/6">
                    <div className="bg-white h-2 ml-auto" style={{ width: '2px' }}></div>
                </div>
                <div className="absolute w-2/6">
                    <div className="bg-white h-2 ml-auto" style={{ width: '2px' }}></div>
                </div>
                <div className="absolute w-3/6">
                    <div className="bg-white h-2 ml-auto" style={{ width: '2px' }}></div>
                </div>
                <div className="absolute w-4/6">
                    <div className="bg-white h-2 ml-auto" style={{ width: '2px' }}></div>
                </div>
                <div className="absolute w-5/6">
                    <div className="bg-white h-2 ml-auto" style={{ width: '2px' }}></div>
                </div>
            </div>
            {/* Sub Text and Continue button */}
            <div className="flex items-center justify-between">
                <p className="text-sm text-newGray-darkish font-medium">
                    {totalCompleted === 6
                        ? "You've completed all stages!"
                        : totalCompleted > 3
                        ? "You're over half way there now!"
                        : 'The more stages you complete, the faster your Kato experience will be and the faster you will have your new office.'}
                </p>
            </div>
        </div>
    );
};

export default TenantReadiness;
