import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';
import ReactTooltip from 'react-tooltip';

import AdminPasswordWindow from './AdminPasswordWindow';
import { ReactComponent as Cross } from '../../../assets/images/cross/black.svg';
import { ReactComponent as Info } from '../../../assets/images/info/passwordInfo.svg';
import { useAdminEditUserMutation } from '../../../generated/graphql';
import { Button } from '../../../shared/button';
import { handleFetchError } from '../../../shared/helpers';
import Input, { InputType } from '../../../shared/Inputs';
import Loading from '../../../shared/Loading';
import Window from '../../../shared/Window';

type AdminEditUserWindowProps = {
    id: string;
    firstName?: string | null;
    lastName?: string | null;
    mobile?: string | null;
    email: string;
    locked?: boolean | null;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
    created: string;
};

const AdminEditUserWindow = ({ id, created, setShow, ...props }: AdminEditUserWindowProps) => {
    const [showAdminPasswordWindow, setShowAdminPasswordWindow] = useState<boolean>(false);
    const passwordPattern = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;
    // Input values
    const [firstName, setFirstName] = useState<string | undefined | null>(props.firstName);
    const [lastName, setLastName] = useState<string | undefined | null>(props.lastName);
    const [mobile, setMobile] = useState<string | undefined | null>(props.mobile);
    const [email, setEmail] = useState<string | undefined | null>(props.email);
    const [password, setPassword] = useState<string | undefined>();
    const [confirmPassword, setConfirmPassword] = useState<string | undefined>();
    const [locked, setLocked] = useState<boolean | undefined | null>(props.locked);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    const [updateUser, { error: updateUserError, loading: updateUserLoading }] = useAdminEditUserMutation();

    useEffect(() => {
        if (password && !passwordPattern.test(password)) {
            return setErrorMessage('Passwords entered does not match the required criteria');
        } else if (password !== confirmPassword) {
            return setErrorMessage('Passwords must match');
        } else {
            return setErrorMessage('');
        }
    }, [password, confirmPassword]);

    const handleSubmit = async (adminPassword: string) => {
        const { data: updateUserData } = await updateUser({
            variables: {
                id,
                input: {
                    firstName,
                    lastName,
                    email,
                    mobile,
                    password,
                    locked,
                    currentPassword: adminPassword
                }
            }
        });

        if (updateUserError || updateUserData?.userUpdate.__typename !== 'User')
            return handleFetchError('Failed to update the user', updateUserError, updateUserData?.userUpdate);

        setShowAdminPasswordWindow(false);
        setShow(false);
    };

    if (updateUserLoading)
        return (
            <Window title="Update User" width="w-2/5" setShow={setShow}>
                <Loading />
            </Window>
        );

    return (
        <>
            {showAdminPasswordWindow && (
                <AdminPasswordWindow
                    text="Please enter your admin password in order to update this user."
                    onClick={handleSubmit}
                    setShow={setShowAdminPasswordWindow}
                />
            )}
            <Window
                header={
                    <div className="flex border-b p-2 mx-2 mt-1 place-items-center justify-between">
                        <p className="text-xl font-semibold">Update User</p>
                        {created && (
                            <div className="flex flex-col-reverse items-end">
                                <p className="text-xs mt-2">
                                    Created on {format(new Date(created), 'dd MMM yyyy, h:mm a')}
                                </p>
                                <Cross
                                    data-testid="window--close"
                                    className="cursor-pointer w-5 "
                                    onClick={() => setShow(false)}
                                />
                            </div>
                        )}
                    </div>
                }
                footer={
                    <>
                        <div className="flex justify-center items-center">
                            <Button
                                background={errorMessage ? 'navy-light' : 'newTeal-main'}
                                text={errorMessage ? 'black' : 'white'}
                                disabled={errorMessage && errorMessage}
                                onClick={() => setShowAdminPasswordWindow(true)}
                                className={`px-10 mr-2 font-semibold w-full md:w-auto ml-2 md:mr-2 mb-3 mt-3`}
                            >
                                Update User
                            </Button>
                        </div>
                    </>
                }
                width="w-2/5"
                setShow={setShow}
            >
                <form
                    className="text-sm text-gray-600 p-4"
                    onSubmit={(e) => {
                        e.preventDefault();
                        setShowAdminPasswordWindow(true);
                    }}
                >
                    <div className="flex flex-col md:flex-row pt-6 md:pt-0 mb-2">
                        <Input
                            type={InputType.text}
                            value={firstName}
                            onChange={(e) => setFirstName(e.target.value)}
                            className="text-black text-base w-full mb-2 md:mb-0"
                            containerClassName="md:mr-2"
                            placeholder="User first name"
                            label="First Name"
                        />
                        <Input
                            type={InputType.text}
                            value={lastName}
                            onChange={(e) => setLastName(e.target.value)}
                            className="text-black text-base w-full"
                            placeholder="User last name"
                            label="Last Name"
                        />
                    </div>
                    <div className="flex flex-col md:flex-row mb-2">
                        <Input
                            type={InputType.email}
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            className="text-black text-base w-full mb-2 md:mb-0"
                            containerClassName="md:mr-2"
                            placeholder="User email"
                            label="Email"
                        />
                        <Input
                            type={InputType.phone}
                            value={mobile}
                            onChange={(e) => setMobile(e.target.value)}
                            className="text-black text-base w-full"
                            placeholder="User mobile"
                            label="Mobile"
                        />
                    </div>
                    <div className="flex flex-col md:flex-row mb-2">
                        <div className="w-full mb-2 md:mb-0">
                            <div className="flex flex-row items-center">
                                <p className="text-gray-600 font-medium text-sm mb-0.5">Password</p>
                                <Info
                                    data-border-color={'white'}
                                    data-tip={`Password must be at least 8 characters and contain both a number and special character.`}
                                    className="ml-2 w-3.5 h-3.5 mb-0.5"
                                />
                            </div>
                            <ReactTooltip
                                backgroundColor="#f4f4f4"
                                textColor="black"
                                effect="solid"
                                place="bottom"
                                clickable={false}
                                borderColor="white"
                            />
                            <Input
                                type={InputType.password}
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                className="text-black text-base w-full mb-2 md:mb-0"
                                placeholder="User password"
                                containerClassName="md:pr-2"
                            />
                        </div>
                        <Input
                            type={InputType.password}
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            className="text-black text-base w-full mb-2 md:mb-0"
                            placeholder="Confirm password"
                            label="Confirm Password"
                        />
                    </div>
                    <div className="flex flex-col md:flex-row">
                        <label className="block">
                            <input
                                type="checkbox"
                                id="optional"
                                checked={!!locked}
                                onChange={(e) => setLocked(e.target.checked)}
                                className="leading-tight w-8 h-8 form-checkbox p-2 rounded bg-white text-newTeal-main focus:shadow-none focus:outline-none mr-2"
                            />
                            Account Locked?
                        </label>
                    </div>
                    <div>
                        {errorMessage && (
                            <div className="bg-red-200 border border-red-600 rounded py-2 px-4 my-4 mt-2">
                                <span className="text-center text-red-600">{errorMessage}</span>
                            </div>
                        )}
                    </div>
                </form>
            </Window>
        </>
    );
};

export default AdminEditUserWindow;
