import { Field, FieldArray, FieldArrayRenderProps, Form, Formik, useFormikContext } from 'formik';
import { noop } from 'lodash';
import React, { useContext, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';

import { ContactValues } from './Contact';
import countries from '../../../../../assets/countries.json';
import { ReactComponent as AddUser } from '../../../../../assets/images/addUser/addUser.svg';
import { ReactComponent as User } from '../../../../../assets/images/user/gray.svg';
import { GetDealTenantDetailsDocument, useTenantActionStageAmlMutation } from '../../../../../generated/graphql';
import { Button } from '../../../../../shared/button';
import { hasErrors } from '../../../../common/helpers';
import { ReadinessContext, STAGE } from '../context';
import InputField from '../InputField';
import SelectField, { Country } from '../SelectField';
import Stage from '../Stage';

type AMLCheckValues = {
    companyJurisdiction?: string | null;
    companyName?: string | null;
    companyRegistrationNumber?: string | null;
    personalDetails?: ContactValues[] | null;
};

const AMLCheck = () => {
    const { details } = useContext(ReadinessContext);
    const aml = details?.aml ?? {};

    const initialValues: AMLCheckValues = {
        companyJurisdiction: aml.companyJurisdiction,
        companyName: aml.companyName,
        companyRegistrationNumber: aml.companyRegistrationNumber,
        personalDetails: aml?.personalDetails?.map((person) => ({
            name: person.name,
            phone: person.phone
        })) ?? [{ phone: '', name: '' }]
    };

    const validationSchema = Yup.object().shape({
        companyJurisdiction: Yup.string().required(' '),
        companyRegistrationNumber: Yup.string().nullable().required(' '),
        companyName: Yup.string().required(' '),
        personalDetails: Yup.array().of(
            Yup.object().shape({
                name: Yup.string().required(' '),
                phone: Yup.string().required(' ')
            })
        )
    });

    return (
        <Formik validationSchema={validationSchema} initialValues={initialValues} onSubmit={noop} enableReinitialize>
            <AMLForm edited={!!details?.aml?.edited} />
        </Formik>
    );
};

type AMLFormProps = {
    edited: boolean;
};

const AMLForm = ({ edited }: AMLFormProps) => {
    const { id } = useParams<{ id: string }>();
    const { changeStage, isChanging, setStage } = useContext(ReadinessContext);
    const arrayHelpersRef = useRef<FieldArrayRenderProps>();

    const { values, validateForm, setFieldValue, dirty } = useFormikContext<AMLCheckValues>();

    const [actionStage] = useTenantActionStageAmlMutation();

    const saveValues = async () => {
        if (!dirty && edited) {
            changeStage();
            return;
        }

        const isValid = !hasErrors<AMLCheckValues>(await validateForm(values));

        await actionStage({
            variables: {
                dealID: id,
                completed: isValid,
                companyJurisdiction: values.companyJurisdiction,
                companyName: values.companyName,
                companyRegistrationNumber: values.companyRegistrationNumber,
                personalDetails: values.personalDetails
            },
            onCompleted: changeStage,
            refetchQueries: () => [GetDealTenantDetailsDocument]
        });
    };

    useEffect(() => {
        if (isChanging) saveValues();
    }, [isChanging]);

    return (
        <Form className="bg-white pt-14 h-full w-full mx-auto flex flex-col items-start">
            <Stage
                stageNumber={5}
                title="AML Checks"
                subtitle="Company Details"
                requiredFieldsText
                nextStage="Next: Stage 6 - Final Info"
                onNextClick={async () => setStage(STAGE.FINAL_INFO)}
            >
                <label className="font-medium text-xs">
                    <span className="text-newTeal-main text-md">*</span>
                    Company Jurisdiction
                </label>
                <Field
                    placeholder={values.companyJurisdiction ?? 'Select Country'}
                    component={SelectField}
                    options={countries}
                    onChange={(country: Country) => setFieldValue('companyJurisdiction', country.label)}
                />
                <InputField name={`companyName`} label="Company Name" required />
                <InputField name={`companyRegistrationNumber`} label="Company Registration Number" required />
                <div className="flex flex-row justify-between">
                    <p className="text-sm font-medium mt-7">Personal Details</p>
                    <Button
                        type="submit"
                        text={'black hover:text-white'}
                        background="newTeal-light"
                        height="7"
                        className="w-11 mt-7 hover:bg-newTeal-main"
                        onClick={() =>
                            arrayHelpersRef.current?.push({
                                name: '',
                                phone: ''
                            })
                        }
                    >
                        <AddUser className="w-5 h-5" />
                    </Button>
                </div>
                <FieldArray
                    name="personalDetails"
                    render={(arrayHelpers) => {
                        arrayHelpersRef.current = arrayHelpers;
                        return (
                            <div>
                                {values?.personalDetails?.map((person, index) => (
                                    <div key={index} className="flex flex-col mb-2">
                                        <div className="flex flex-row justify-center items-center mb-2 mt-2">
                                            <User className="w-4 h-4" />
                                            <p className="text-xs flex-initial text-newGray-main">{`Person ${
                                                index + 1
                                            }`}</p>
                                            <div className="border-b flex-1 border-navy-light items-center ml-2" />
                                        </div>
                                        {values.personalDetails && values.personalDetails.length > 1 && (
                                            <p
                                                onClick={() => arrayHelpers.remove(index)}
                                                className="w-18 text-xs h-4 bg-newTeal-light hover:bg-newTeal-main text-black hover:text-white self-end cursor-pointer rounded px-2"
                                            >
                                                Remove
                                            </p>
                                        )}
                                        <InputField name={`personalDetails[${index}].name`} label="Name" required />
                                        <InputField
                                            name={`personalDetails[${index}].phone`}
                                            label="Mobile Number"
                                            required
                                        />
                                    </div>
                                ))}
                            </div>
                        );
                    }}
                />
            </Stage>
        </Form>
    );
};

export default AMLCheck;
