import React, { useRef, useState } from 'react';

import {
    GetAllOrganisationsDocument,
    InvitedUserFragment,
    useInviteUserMutation,
    UserType
} from '../../../generated/graphql';
import { Button } from '../../../shared/button';
import EmailMultiInput from '../../../shared/Inputs/EmailMultiInput';
import Window from '../../../shared/Window';

type InviteUserWindowProps = {
    organisationId?: string;
    organisationType?: UserType;
    email?: string;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
    setRefetchOnInvite: React.Dispatch<React.SetStateAction<boolean>>;
};

const InviteUserWindow = ({
    organisationId,
    organisationType,
    email,
    setShow,
    setRefetchOnInvite
}: InviteUserWindowProps) => {
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    const [userInvited, setUserInvited] = useState<InvitedUserFragment[]>();
    const [emails, setEmails] = useState<string[]>([]);

    const [inviteUser] = useInviteUserMutation();

    const inviteUserEmailRef = useRef<HTMLInputElement>(null);

    const handleInviteSubmit = async () => {
        if (!emails) return setErrorMessage('Please enter an email address');

        const { data: inviteUserData, errors: inviteUserErrors } = await inviteUser({
            variables: {
                organisationID: organisationId,
                emails: emails,
                type: organisationType
            },
            refetchQueries: [{ query: GetAllOrganisationsDocument }]
        });

        if (inviteUserErrors) {
            setErrorMessage(inviteUserErrors[0].message);
            return;
        }
        setUserInvited(inviteUserData?.userInvite.users);
        setRefetchOnInvite(true);
        setShow(false);
    };

    return (
        <div>
            <Window
                title="Invite User"
                width="w-1/3"
                setShow={setShow}
                titleCenter
                footer={
                    <div className="w-full p-2 flex items-center justify-center flex-wrap border-t border-navy-lightest">
                        {!!userInvited && userInvited.map((user) => !user.existing) ? (
                            <Button
                                background="newTeal-main"
                                text="white"
                                onClick={() => setShow(false)}
                                className={`px-10 font-semibold text-sm w-full md:w-auto`}
                            >
                                Go back
                            </Button>
                        ) : (
                            <div className="flex flex-col md:flex-row w-full">
                                <Button
                                    background="navy-lightest"
                                    text="black"
                                    onClick={() => setShow(false)}
                                    className={`px-10 border-navy-light font-semibold w-full md:w-1/3 mt-2 md:mt-0 md:mr-2`}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    background="newTeal-main"
                                    text="white"
                                    onClick={handleInviteSubmit}
                                    className={`px-10 font-semibold w-full md:w-2/3`}
                                >
                                    Send Invite
                                </Button>
                            </div>
                        )}
                    </div>
                }
            >
                <div className="p-4">
                    <form
                        className="flex flex-col flex-wrap md:flex-no-wrap w-full gap-4 mb-4"
                        onSubmit={(e) => e.preventDefault()}
                    >
                        <EmailMultiInput
                            setEmails={setEmails}
                            ref={inviteUserEmailRef}
                            defaultValue={email}
                            name="email"
                            placeholder={emails.length > 0 ? 'Type to add another email' : 'Enter email'}
                            label="Email"
                            className="border-b border-t border-gray-50"
                        />
                    </form>
                    {errorMessage && (
                        <div className="bg-red-200 border border-red-600 rounded w-full py-2 px-4 mt-4">
                            <span className="text-center text-red-600">{errorMessage}</span>
                        </div>
                    )}
                </div>
            </Window>
        </div>
    );
};

export default InviteUserWindow;
