import React, { FC } from 'react';
import ReactTooltip from 'react-tooltip';

import { Button, ButtonProps } from '../button/Button';

interface IconButtonProps extends ButtonProps {
    id: string;
    icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
    tooltip?: string;
}

export const IconButton: FC<IconButtonProps> = ({ id, icon, tooltip, className, ...props }) => (
    <div data-for={id} data-tip={tooltip}>
        <Button className={`w-15 h-15 bg-white hover:bg-navy-lightest border-none ${className}`} {...props}>
            {React.createElement(icon)}
        </Button>
        <ReactTooltip
            id={id}
            backgroundColor="black"
            textColor="white"
            effect="solid"
            place="left"
            clickable={false}
            borderColor="white"
        />
    </div>
);

export default IconButton;
