import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { DealRoundType, DealState, DealUserParty } from '../../../generated/graphql';
import NudgeButton from '../../../pages/Deals/NudgeButton';

export enum ActionCardType {
    DEAL,
    UNIT
}

type ActionCardProps = {
    key?: string;
    type: ActionCardType;
    state?: DealState;
    currentUserParty?: DealUserParty | null;
    round?: DealRoundType | null;
    id: string;
    dealId?: string;
    name: string;
    buildingName: string;
    address: string;
    dealActionDetails?: string;
    rating?: number;
    total?: number;
};

const ActionCard = ({
    type,
    id,
    name,
    currentUserParty,
    state,
    round,
    dealId,
    buildingName,
    address,
    dealActionDetails,
    rating,
    total
}: ActionCardProps) => {
    const [progressColor, setProgressColor] = useState<string>('bg-red-500');
    const [canNudge, setCanNudge] = useState<boolean>(true);
    useEffect(() => {
        let progress = rating && total ? (rating / total) * 100 : null;
        setProgressColor(
            progress
                ? progress >= 80
                    ? 'bg-green-500'
                    : progress >= 30
                    ? 'bg-orange-400'
                    : 'bg-red-500'
                : 'bg-red-500'
        );
    }, [rating, total]);
    if (!canNudge) return <></>;
    return (
        <div
            className="flex flex-1 flex-col md:flex-row justify-between w-100 relative bg-white rounded hover:shadow-md transition duration-200 mt-2 p-4"
            key={id}
        >
            <div className="mb-3 md:mb-0 md:w-2/5 w-full">
                {type === ActionCardType.DEAL ? (
                    <>
                        <p className="font-semibold text-lg">{name}</p>
                        <p className="font-semibold text-xs">{buildingName}</p>
                        <p className="text-xs text-newGray-main">{address}</p>
                    </>
                ) : (
                    <>
                        <p className="font-semibold text-xs">{name}</p>
                        <p className="font-semibold text-lg">{buildingName}</p>
                        <p className="text-xs text-newGray-main">{address}</p>
                    </>
                )}
            </div>
            <div className="md:w-3/5 w-full bg-navy-lightest flex flex-col rounded overflow-hidden">
                <div className="flex justify-between items-center p-3 pb-1">
                    <div>
                        {type === ActionCardType.DEAL ? (
                            <>
                                <p className="text-xs text-gray-600 font-bold">Negotiations</p>
                                <p className="font-semibold">{dealActionDetails}</p>
                            </>
                        ) : (
                            <>
                                <p className="text-xs text-gray-600 font-bold">Unit Readiness</p>
                                <p>
                                    <span className="text-lg font-semibold">{rating}</span>/{total}
                                </p>
                            </>
                        )}
                    </div>
                    <div className="flex flex-row">
                        {currentUserParty && round && state && type === ActionCardType.DEAL && (
                            <div
                                onClick={(e) => {
                                    e.stopPropagation();
                                }}
                            >
                                <NudgeButton
                                    actionsRequired={true}
                                    dealID={dealId ? dealId : id}
                                    currentUserParty={currentUserParty}
                                    state={state}
                                    round={round}
                                    setCanNudge={setCanNudge}
                                />
                            </div>
                        )}
                        <Link
                            to={`/${type === ActionCardType.DEAL ? 'deals' : 'units'}/${id}`}
                            className="bg-white w-28 h-10 rounded inline-flex items-center justify-center text-center focus:outline-none"
                        >
                            {type === ActionCardType.DEAL ? 'Action' : 'Improve'}
                        </Link>
                    </div>
                </div>
                {/* Bar */}
                {type === ActionCardType.UNIT && rating !== undefined && total !== undefined && (
                    <div
                        className={`bg-gray-450 h-3 w-full 
                                 relative overflow-hidden rounded-b`}
                    >
                        <span
                            className={`${progressColor} absolute h-full top-0 left-0 transition-all duration-200 bg-red-500`}
                            style={{
                                width: `${(rating / total) * 100}%`
                            }}
                        ></span>
                        {/* Indents */}
                        <div className="absolute w-1/5">
                            <div className="bg-white h-2 ml-auto" style={{ width: '2px' }}></div>
                        </div>
                        <div className="absolute w-2/5">
                            <div className="bg-white h-2 ml-auto" style={{ width: '2px' }}></div>
                        </div>
                        <div className="absolute w-3/5">
                            <div className="bg-white h-2 ml-auto" style={{ width: '2px' }}></div>
                        </div>
                        <div className="absolute w-4/5">
                            <div className="bg-white h-2 ml-auto" style={{ width: '2px' }}></div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default ActionCard;
