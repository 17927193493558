import React from 'react';

import { UnitSingleActorFragment, useRemoveUserFromUnitMutation } from '../../../../generated/graphql';
import { handleFetchError } from '../../../../shared/helpers';
import Loading from '../../../../shared/Loading';
import Window from '../../../../shared/Window';

type ConfirmUserRemoveWindowProps = {
    unitID: string;
    userID: string;
    actors?: UnitSingleActorFragment[];
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
    setDataActors: React.Dispatch<React.SetStateAction<UnitSingleActorFragment[]>>;
};

const ConfirmUserRemoveWindow = ({ unitID, userID, actors, setShow, setDataActors }: ConfirmUserRemoveWindowProps) => {
    const [removeUser, { error: removeUserError, loading: removeUserLoading }] = useRemoveUserFromUnitMutation();

    const handleDeleteClick = async () => {
        const { data: removeUserData } = await removeUser({
            variables: {
                userID,
                unitID
            },
            optimisticResponse: {
                unitRemoveActor: {
                    __typename: 'Unit',
                    _id: unitID,
                    actors: [...(actors ? actors.filter((user) => user._id !== userID) : [])]
                }
            }
        });

        if (removeUserError) {
            return handleFetchError('Could not remove the user', removeUserError, removeUserData?.unitRemoveActor);
        }

        removeUserData && removeUserData.unitRemoveActor.actors && setDataActors(removeUserData.unitRemoveActor.actors);

        setShow(false);
    };

    if (removeUserLoading)
        return (
            <Window title="Remove User" titleCenter width="w-1/4" setShow={setShow}>
                <Loading />
            </Window>
        );

    return (
        <Window
            title="Remove User"
            titleCenter
            setShow={setShow}
            width="w-1/4"
            primaryBtn
            onPrimaryBtnClick={handleDeleteClick}
            secondaryBtn
            onSecondaryBtnClick={() => setShow(false)}
        >
            <div className="flex flex-col items-center">
                <h2 className="text-center text-lg my-8 w-4/5">
                    Are you sure you want to <span className="font-semibold text-red-500 text-uppercase">remove</span>{' '}
                    the user from this Space?
                </h2>
            </div>
        </Window>
    );
};

export default ConfirmUserRemoveWindow;
