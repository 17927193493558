import { useApolloClient } from '@apollo/client';

import { DealDetailsFragment, useMoveClauseMutation } from '../../../generated/graphql';
import { move } from '../../helpers';

const useMoveClause = (deal: DealDetailsFragment | null, id: string, groupID: string, parentID: string) => {
    const client = useApolloClient();
    const [moveClause] = useMoveClauseMutation();

    return async (from: number, to: number) => {
        // Move in local cache first
        const clauseIDs: string[] = [];
        client.cache.modify({
            id: client.cache.identify({
                __typename: 'Group',
                _id: groupID
            }),
            fields: {
                clauses(clauses = []) {
                    const newClauses = move(from, to, ...clauses);

                    return newClauses;
                }
            }
        });

        // Make a space in the local cached clause indexes
        clauseIDs.map((clause) => {
            client.cache.modify({
                id: client.cache.identify({
                    __typename: 'Clause',
                    _id: clause
                }),
                fields: {
                    index(index = 0) {
                        return from > to
                            ? index < from && index >= to
                                ? index + 1
                                : index
                            : index > from && index <= to
                            ? index - 1
                            : index;
                    }
                }
            });
        });

        // Update the selected clauses
        client.cache.modify({
            id: client.cache.identify({
                __typename: 'Clause',
                _id: id
            }),
            fields: {
                index() {
                    return to;
                }
            }
        });

        // Then tell API
        await moveClause({
            fetchPolicy: 'no-cache',
            variables: {
                dealID: deal?._id ?? '',
                parentID,
                clauseID: id,
                indexFrom: from,
                indexTo: to
            }
        });
    };
};

export default useMoveClause;
