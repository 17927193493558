import React from 'react';

import { AddedEventProps } from './AddedEvent';
import { ReactComponent as Comment } from '../../../../../assets/images/comment/black.svg';

type CommentedEventProps = AddedEventProps & {};

const CommentedEvent = ({ event }: CommentedEventProps) => {
    // Get first comment not in old clause
    const message = event.change?.new.comments?.find(
        (commment) => !event.change?.old?.comments?.find((oldComment) => oldComment._id === commment._id)
    )?.message;

    if (!message || message.length === 0) return null;

    return (
        <div>
            <h3 className="font-semibold text-sm mb-2">
                {event.party.charAt(0) + event.party.slice(1).toLocaleLowerCase()} left a comment on the clause
            </h3>
            <div className="border bg-newTeal-light rounded py-1 px-2 flex flex-row items-center justify-between gap-2">
                <Comment className="w-5 h-5 text-newTeal-main" />
                <span className="flex-1">{message}</span>
            </div>
        </div>
    );
};

export default CommentedEvent;
