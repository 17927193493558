import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { DealApprovalSendType, DealUserParty, useSendDealApprovalMutation } from '../../../../generated/graphql';
import SuccessIcon from '../../../../shared/SuccessIcon';
import Window from '../../../../shared/Window';

type ApproveDealWindowProps = {
    dealID: string;
    emails: string;
    currentUserParty: DealUserParty;
    approvalID?: string | null | undefined;
    redirect?: string;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
};

const ApproveDealWindow = ({
    dealID,
    emails,
    currentUserParty,
    approvalID,
    redirect,
    setShow
}: ApproveDealWindowProps) => {
    const history = useHistory();
    // Success message
    const [success, setSuccess] = useState<boolean>(false);

    const [approveDeal] = useSendDealApprovalMutation({
        variables: {
            approvalID,
            currentUserParty,
            dealID,
            emails,
            message: '',
            type: DealApprovalSendType.Approve
        }
    });

    const handleConfirm = async () => {
        if (success) return;

        await approveDeal();

        setSuccess(true);

        if (redirect && redirect.trim().length > 0) {
            // After 1 second redirect to preview
            setTimeout(() => history.push(redirect), 1500);
        }
    };

    return (
        <Window
            title="Approve Deal"
            titleCenter
            width="w-1/4"
            setShow={setShow}
            contentMiddle={true}
            primaryBtn={!success}
            onPrimaryBtnClick={handleConfirm}
            secondaryBtn={!success}
            onSecondaryBtnClick={() => setShow(false)}
        >
            {success ? (
                <div className="w-full flex flex-col items-center justify-center py-10">
                    <SuccessIcon />
                    <h1 className="text-xl font-bold text-center mt-5">Approved</h1>
                </div>
            ) : (
                <p className="text-newGray-darkish px-4 py-6 text-center">
                    Are you sure you want to approve these Heads of Terms?
                </p>
            )}
        </Window>
    );
};

export default ApproveDealWindow;
