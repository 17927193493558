import React from 'react';

import { DealApprovalType, DealState } from '../../../../../../generated/graphql';

type DealActorsWaitingApprovalProps = {
    state?: DealState;
    type?: 'Landlord' | 'Tenant';
    approval?: DealApprovalType | null;
};

const DealActorsWaitingApproval = ({ state, type, approval }: DealActorsWaitingApprovalProps) => {
    const days = Math.ceil((new Date().getTime() - new Date(approval?.date).getTime()) / (1000 * 3600 * 24) - 1);

    return (
        <>
            <p className="text-sm font-light text-center">Waiting Approval</p>
            <p
                className="font-semibold text-center"
                style={{ fontSize: '15px' }} // text-base class would make it too big
            >
                {days === 0 ? 'Today' : `${days} Day${days > 1 ? 's' : ''} Ago`}
            </p>
        </>
    );
};

export default DealActorsWaitingApproval;
