import React, { useState } from 'react';

import { useGetUserProfileQuery, useUpdateUserMutation } from '../../generated/graphql';
import { Button } from '../button';
import Input, { InputType } from '../Inputs';

type ProfileProps = {
    setShowPasswordForm: React.Dispatch<React.SetStateAction<boolean>>;
};

const ChangePasswordForm = ({ setShowPasswordForm }: ProfileProps) => {
    const [validateError, setValidateError] = useState('');

    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [repeatPassword, setRepeatPassword] = useState('');

    const [updateUser, { error: updateError }] = useUpdateUserMutation();
    const { refetch } = useGetUserProfileQuery();

    const handlePasswordChange = async () => {
        if (currentPassword === '') return setValidateError('Current password is required');
        if (newPassword === '') return setValidateError('New password is required');
        if (repeatPassword === '') return setValidateError('Repeat password is required');
        if (newPassword !== repeatPassword) return setValidateError('Passwords do not match');

        const inputs = {};
        if (newPassword !== '') Object.assign(inputs, { password: newPassword });
        if (currentPassword !== '') Object.assign(inputs, { currentPassword: currentPassword });

        await updateUser({
            variables: inputs
        });

        if (updateError) {
            if (updateError.graphQLErrors[0]) {
                if (updateError.graphQLErrors[0].extensions?.code === 'BAD_LOGIN') {
                    setValidateError('Incorrect current password');
                } else {
                    setValidateError(updateError.graphQLErrors[0].message);
                }
            } else {
                if (updateError?.message) {
                    setValidateError(updateError.message);
                }
            }
            return;
        }

        setCurrentPassword('');
        setNewPassword('');
        setRepeatPassword('');
        setValidateError('');
        refetch();
    };

    return (
        <div className="w-full mt-8">
            {/* Current Password */}
            <div className="w-full md:w-1/2">
                <label htmlFor="email" className="block text-newGray-darkish mb-2 font-medium">
                    Current Password
                </label>
                <Input
                    type={InputType.password}
                    name="currentPassword"
                    value={currentPassword}
                    onChange={(e) => {
                        setCurrentPassword(e.target.value);
                    }}
                    className="bg-navy-lightest mb-2 border-none w-full"
                />
            </div>
            <div className="flex flex-row justify-between w-100">
                {/* New Password */}
                <div className="w-100 md:w-1/2 mr-4">
                    <div className="flex flex-row justify-between">
                        <label htmlFor="role" className="block text-newGray-darkish mb-2 font-medium">
                            New Password
                        </label>
                    </div>
                    <Input
                        type={InputType.password}
                        name="password"
                        value={newPassword}
                        onChange={(e) => {
                            setNewPassword(e.target.value);
                        }}
                        className="bg-navy-lightest mb-4 border-none w-full"
                    />
                </div>
                {/*  Repeat Password */}
                <div className="w-100 md:w-1/2">
                    <div className="flex flex-row justify-between">
                        <label htmlFor="role" className="block text-newGray-darkish mb-2 font-medium">
                            Repeat Password
                        </label>
                    </div>
                    <Input
                        type={InputType.password}
                        name="password"
                        value={repeatPassword}
                        onChange={(e) => {
                            setRepeatPassword(e.target.value);
                        }}
                        className="bg-navy-lightest mb-4 border-none w-full"
                    />
                </div>
            </div>
            {/* Footer */}
            <div className="w-full mt-2 flex align-center justify-between">
                <p className="text-newGray-darkish text-sm w-1/2 mr-4">
                    Password must be 8 characters or longer, and include at least three of the following: lowercase
                    letters, uppercase letters, numerals, and symbols/punctuation.
                </p>
                <div>
                    <Button
                        text="black"
                        background="white"
                        className="px-10 font-semibold mr-2"
                        onClick={() => setShowPasswordForm(false)}
                    >
                        Cancel
                    </Button>
                    {currentPassword !== '' && newPassword !== '' && repeatPassword !== '' && (
                        <Button
                            text="white"
                            background="newTeal-main"
                            className="px-10 font-semibold"
                            onClick={handlePasswordChange}
                        >
                            Save
                        </Button>
                    )}
                </div>
            </div>
            {/* Errors */}
            {validateError && (
                <div className="bg-red-200 border border-red-600 rounded py-2 mt-6 px-4 w-full">
                    <span className="text-center text-red-600">{validateError}</span>
                </div>
            )}
        </div>
    );
};

export default ChangePasswordForm;
