import { formatDistance } from 'date-fns';
import React from 'react';

import { ReactComponent as Activity } from '../../../assets/images/activity/activityGray.svg';
import { ReactComponent as Delete } from '../../../assets/images/delete/user/black.svg';
import { ReactComponent as Envelope } from '../../../assets/images/envelope/black.svg';
import { ReactComponent as Phone } from '../../../assets/images/phone/black.svg';
import { OrganisationMemberFragment, useInviteUserMutation } from '../../../generated/graphql';
import { Button } from '../../../shared/button';
import useMobileScreen from '../../../shared/hooks/useMobileScreen';

type MemberProps = {
    organisationID: string;
    canDelete?: boolean;
    member: OrganisationMemberFragment;
    setShowRemoveMemberPopup: React.Dispatch<React.SetStateAction<boolean>>;
    setUserID: React.Dispatch<React.SetStateAction<string | undefined>>;
    setOrganisationID: React.Dispatch<React.SetStateAction<string | null>>;
    setShowResendPopup: React.Dispatch<React.SetStateAction<boolean>>;
    setIsTrustedUser: React.Dispatch<React.SetStateAction<boolean>>;
    isTrustedUser: boolean;
};

const Member = ({
    organisationID,
    canDelete = true,
    member,
    setUserID,
    setOrganisationID,
    setShowRemoveMemberPopup,
    setShowResendPopup,
    setIsTrustedUser,
    isTrustedUser
}: MemberProps) => {
    const [inviteUser] = useInviteUserMutation();

    const lastLogin = member.lastLogin && formatDistance(new Date(member.lastLogin), new Date());

    const isMember = member.organisation._id === organisationID;

    const isMobile = useMobileScreen();

    return (
        <div
            key={member._id}
            className={`flex  justify-between w-full bg-white rounded px-3 py-4  items-center mb-2 ${
                isMobile ? 'h-40' : 'h-20 '
            }`}
        >
            <div>
                {member.pending ? (
                    <div className={`flex ${isMobile ? 'flex-col' : 'flex-row items-center'} `}>
                        <div className={`flex items-center h-12 w-48 ${isMobile ? '' : 'border-r-2'}  mr-4`}>
                            <p
                                onClick={async () => {
                                    await inviteUser({
                                        variables: {
                                            organisationID,
                                            emails: [member.email],
                                            type: member.type,
                                            resend: true
                                        }
                                    });
                                    setShowResendPopup(true);
                                }}
                                className="font-medium underline text-sm whitespace-no-wrap cursor-pointer"
                            >
                                Resend Invite
                            </p>
                        </div>
                        <div className={` flex flex-col`}>
                            <div className="flex flex-row items-center">
                                <Envelope stroke="#a9a9a9" className="w-3 h-3 mr-2 text-sm " />
                                <p className={`${isMobile ? 'w-64 truncate' : ''}  text-sm`}>{member.email}</p>
                            </div>
                            <p className="text-sm">
                                {' '}
                                <Activity className="w-3 h-3 mr-2 text-sm inline " />
                                Invited
                            </p>
                        </div>
                    </div>
                ) : (
                    <div className={`flex ${isMobile ? 'flex-col' : 'flex-row  items-center'} `}>
                        <div
                            className={`flex  h-12 w-48 ${
                                isMobile && !isTrustedUser
                                    ? 'items-center'
                                    : isMobile && isTrustedUser
                                    ? 'flex-col'
                                    : 'border-r-2 flex-col'
                            }  mr-4`}
                        >
                            <p className="font-medium ">{`${member.firstName} ${member.lastName}`}</p>
                            {isTrustedUser && member.organisation.name && (
                                <p className="text-sm">{member.organisation.name}</p>
                            )}
                        </div>
                        <div className="flex flex-col">
                            {member.email && (
                                <div className="flex flex-row items-center">
                                    <Envelope stroke="#a9a9a9" className="w-3 h-3 mr-2 text-sm " />
                                    <p className={`${isMobile ? 'w-64 truncate' : ''}  text-sm`}>{member.email}</p>
                                </div>
                            )}
                            {member.mobile && (isMember || isTrustedUser) && (
                                <p className={`${isMobile ? 'w-64 truncate' : ''}  text-sm`}>
                                    {' '}
                                    <Phone stroke="#a9a9a9" className="w-3 h-3 mr-2 text-sm inline " />
                                    {member.mobile}
                                </p>
                            )}
                            {member.lastLogin && (isMember || isTrustedUser) && (
                                <p className="text-sm">
                                    {' '}
                                    <Activity className="w-3 h-3 mr-2 text-sm inline " />
                                    {lastLogin} ago
                                </p>
                            )}
                            {member.activated && !member.lastLogin && (isMember || isTrustedUser) && (
                                <p className="text-sm">
                                    {' '}
                                    <Activity className="w-3 h-3 mr-2 text-sm inline " />
                                    Activated
                                </p>
                            )}
                        </div>
                    </div>
                )}
            </div>
            {canDelete && (
                <Button
                    onClick={() => {
                        setUserID(member._id);
                        setOrganisationID(organisationID);
                        setShowRemoveMemberPopup(true);
                        setIsTrustedUser(isTrustedUser);
                    }}
                    className="border border-navy-light hover:border-black rounded text-navy-light hover:text-black px-3 py-3 transition duration-150"
                >
                    <Delete className="w-6 h-6" />
                </Button>
            )}
        </div>
    );
};

export default Member;
