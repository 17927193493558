import React, { useEffect, useState } from 'react';

import {
    GetAllOrganisationsDocument,
    PracticeType,
    UserType,
    useUpdateOrganisationMutation
} from '../../../generated/graphql';
import OrganisationAvatarUpload from '../../../shared/file/OrganisationAvatarUpload';
import { handleFetchError } from '../../../shared/helpers';
import Input, { InputType } from '../../../shared/Inputs';
import Select from '../../../shared/Inputs/Select';
import Loading from '../../../shared/Loading';
import Window from '../../../shared/Window';

type EditCompanyWindowProps = {
    organisation: any;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
};

const EditCompanyWindow = ({ setShow, organisation }: EditCompanyWindowProps) => {
    const [companyName, setCompanyName] = useState('');
    const [companyWebsite, setCompanyWebsite] = useState('');
    const [companyAddressLine1, setCompanyAddressLine1] = useState('');
    const [companyAddressLine2, setCompanyAddressLine2] = useState('');
    const [companyAddressCity, setCompanyAddressCity] = useState('');
    const [companyAddressPostCode, setCompanyAddressPostCode] = useState('');
    const [isUpdating, setIsUpdating] = useState(false);
    const [optionId, setOptionId] = useState<PracticeType | undefined>(
        organisation.practiceType ? organisation.practiceType : undefined
    );
    const [practiceTypeLabel, setPracticeTypeLabel] = useState<string | undefined>();

    useEffect(() => {
        setCompanyName(organisation.name ?? '');
        setCompanyWebsite(organisation.website ?? '');
        setCompanyAddressLine1(organisation.address?.line1 ?? '');
        setCompanyAddressLine2(organisation.address?.line2 ?? '');
        setCompanyAddressCity(organisation.address?.city ?? '');
        setCompanyAddressPostCode(organisation.address?.postCode ?? '');
    }, [organisation]);

    useEffect(() => {
        switch (optionId ? optionId : organisation.practiceType) {
            case PracticeType.Legal:
                return setPracticeTypeLabel('Legal Practice');
            case PracticeType.Agency:
                return setPracticeTypeLabel('Agency Practice');
            case PracticeType.PropertyManagement:
                return setPracticeTypeLabel('Property Management Practice');
        }
    }, [organisation.practiceType, optionId]);
    const [updateCompany, { error: updateCompanyError, loading: updateCompanyLoading }] =
        useUpdateOrganisationMutation();

    const handleSaveClick = async () => {
        if (organisation) {
            const input = {
                name: companyName,
                website: companyWebsite,
                address: {
                    line1: companyAddressLine1,
                    line2: companyAddressLine2,
                    city: companyAddressCity,
                    postCode: companyAddressPostCode
                },
                practiceType: optionId
            };

            const { data: updateCompanyData } = await updateCompany({
                variables: {
                    id: organisation._id,
                    input
                },
                optimisticResponse: {
                    organisationUpdate: {
                        __typename: 'Organisation',
                        _id: organisation._id,
                        ...input
                    }
                },
                refetchQueries: [{ query: GetAllOrganisationsDocument }]
            });

            if (updateCompanyError || updateCompanyData?.organisationUpdate.__typename !== 'Organisation')
                return handleFetchError(
                    'Failed to update your company details',
                    updateCompanyError,
                    updateCompanyData?.organisationUpdate
                );
        }
        setIsUpdating(false);
    };

    if (updateCompanyLoading) return <Loading />;

    return (
        <Window
            title="Edit Company"
            setShow={setShow}
            width="w-1/2"
            titleCenter
            primaryBtnText={'Save'}
            primaryBtn={isUpdating}
            onPrimaryBtnClick={() => handleSaveClick()}
        >
            <div className="p-4">
                <OrganisationAvatarUpload
                    id={organisation._id}
                    avatar={organisation.avatar ?? ''}
                    companyName={companyName}
                />
                <form onSubmit={(e) => e.preventDefault()}>
                    <div className="flex flex-wrap  w-full gap-4">
                        {organisation.type === UserType.Agent && (
                            <div style={{ width: '49%' }}>
                                <label htmlFor="lastName" className="block text-newGray-darkish mb-2 font-medium">
                                    Practice Type
                                </label>
                                <Select
                                    value={
                                        organisation.practiceType
                                            ? {
                                                  value: organisation.practiceType,
                                                  label: practiceTypeLabel
                                              }
                                            : optionId && {
                                                  value: optionId,
                                                  label: practiceTypeLabel
                                              }
                                    }
                                    onItemClick={(id) => id && (setOptionId(id as PracticeType), setIsUpdating(true))}
                                    data={[
                                        {
                                            id: 'LEGAL',
                                            text: 'Legal Practice'
                                        },
                                        {
                                            id: 'AGENCY',
                                            text: 'Agency Practice'
                                        },
                                        {
                                            id: 'PROPERTY_MANAGEMENT',
                                            text: 'Property Management Practice'
                                        }
                                    ]}
                                    controlNoBorder={true}
                                    graySelect={true}
                                    className={`mb-2 w-full`}
                                />
                            </div>
                        )}
                        <div className={`flex flex-wrap md:flex-no-wrap w-full gap-4 mb-6`}>
                            <div className="w-full md:w-1/2">
                                <label htmlFor="firstName" className="block text-newGray-darkish mb-2 font-medium">
                                    Company Name
                                </label>
                                <Input
                                    type={InputType.text}
                                    placeholder="Name"
                                    name="companyName"
                                    value={companyName}
                                    onChange={(e) => {
                                        setCompanyName(e.target.value);
                                        setIsUpdating(true);
                                    }}
                                    className={`bg-gray-200 border-none w-full`}
                                />
                            </div>
                            <div className="w-full md:w-1/2">
                                <label htmlFor="lastName" className="block text-newGray-darkish mb-2 font-medium">
                                    Company Website
                                </label>
                                <Input
                                    type={InputType.text}
                                    placeholder="Website"
                                    name="companyWebsite"
                                    value={companyWebsite}
                                    onChange={(e) => {
                                        setCompanyWebsite(e.target.value);
                                        setIsUpdating(true);
                                    }}
                                    className={`bg-gray-200  border-none w-full`}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-wrap md:flex-no-wrap w-full gap-4 items-end">
                        <div className="w-full md:w-1/2">
                            <label htmlFor="firstName" className="block text-newGray-darkish mb-2 font-medium">
                                Company Address
                            </label>
                            <Input
                                type={InputType.text}
                                placeholder="Line 1"
                                name="companyAddressLine1"
                                value={companyAddressLine1}
                                onChange={(e) => {
                                    setCompanyAddressLine1(e.target.value);
                                    setIsUpdating(true);
                                }}
                                className={`bg-gray-200 mb-4 border-none w-full`}
                            />
                        </div>
                        <div className="w-full md:w-1/2">
                            <Input
                                type={InputType.text}
                                placeholder="Line 2"
                                name="companyAddressLine2"
                                value={companyAddressLine2}
                                onChange={(e) => {
                                    setCompanyAddressLine2(e.target.value);
                                    setIsUpdating(true);
                                }}
                                className={`bg-gray-200 mb-4 border-none w-full`}
                            />
                        </div>
                    </div>
                    <div className="flex flex-wrap md:flex-no-wrap w-full gap-4">
                        <div className="w-full md:w-1/2">
                            <Input
                                type={InputType.text}
                                placeholder="City"
                                name="companyAddressCity"
                                value={companyAddressCity}
                                onChange={(e) => {
                                    setCompanyAddressCity(e.target.value);
                                    setIsUpdating(true);
                                }}
                                className={`bg-gray-200 mb-4 border-none w-full`}
                            />
                        </div>
                        <div className="w-full md:w-1/2">
                            <Input
                                type={InputType.text}
                                placeholder="Post Code"
                                name="companyAddressPostCode"
                                value={companyAddressPostCode}
                                onChange={(e) => {
                                    setCompanyAddressPostCode(e.target.value);
                                    setIsUpdating(true);
                                }}
                                className={`bg-gray-200 mb-4 border-none w-full`}
                            />
                        </div>
                    </div>
                </form>
            </div>
        </Window>
    );
};

export default EditCompanyWindow;
