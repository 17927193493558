import React from 'react';

import ClauseContext from './Context';
import { ReactComponent as ApproveIcon } from '../../../../../assets/images/check/black.svg';
import { ReactComponent as CommentIcon } from '../../../../../assets/images/comment/black.svg';
import { ReactComponent as RemovedIcon } from '../../../../../assets/images/delete/black.svg';
import { ReactComponent as UpdateIcon } from '../../../../../assets/images/hash/teal.svg';
import { ReactComponent as NewIcon } from '../../../../../assets/images/plus/teal.svg';
import { ReactComponent as SwappedIcon } from '../../../../../assets/images/swap/black.svg';
import { ClauseType } from '../Types';

const Icon = () => {
    const { type } = React.useContext(ClauseContext);

    switch (type) {
        case ClauseType.APPROVED:
            return <ApproveIcon className="w-5 h-5 mr-2 text-newTeal-main" style={{ marginBottom: '2px' }} />;
        case ClauseType.NEW:
            return <NewIcon className="w-5 h-5 mr-2 text-newTeal-main" style={{ marginBottom: '2px' }} />;
        case ClauseType.VALUE:
        case ClauseType.CHANGED:
            return <UpdateIcon className="w-5 h-5 mr-2 text-newTeal-main" style={{ marginBottom: '2px' }} />;
        case ClauseType.SWAPPED:
            return <SwappedIcon className="w-5 h-5 mr-2 text-newTeal-main" style={{ marginBottom: '2px' }} />;
        case ClauseType.COMMENTED:
            return <CommentIcon className="w-5 h-5 mr-2 text-newTeal-main" style={{ marginBottom: '2px' }} />;
        case ClauseType.REMOVED:
            return <RemovedIcon className="w-5 h-5 mr-2 text-newTeal-main" style={{ marginBottom: '2px' }} />;
    }

    return null;
};

export default Icon;
