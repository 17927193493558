import React, { useContext, useEffect, useState } from 'react';

import { DealUserParty, UserType } from '../../../generated/graphql';
import { BulkSelectContext } from '../../../shared/bulk/context';
import CardDropdown, { CardDropdownOptions } from '../../../shared/Grid/CardDropdown';

type CardProps = {
    options?: CardDropdownOptions | undefined;
    children: React.ReactNode;
    className?: string;
    selectState: boolean;
    setSelectState?: React.Dispatch<React.SetStateAction<boolean>>;
    height?: string;
    userType?: UserType;
    isHidden?: boolean;
    clientName?: string | null;
    currentUserParty?: DealUserParty | null;
};

const Card = ({
    options,
    children,
    className,
    selectState,
    setSelectState,
    height,
    userType,
    isHidden,
    clientName,
    currentUserParty
}: CardProps) => {
    const { selectMode } = useContext(BulkSelectContext);
    const [dropdownVisible, setDropdownVisible] = useState<boolean>(false);

    useEffect(() => {
        if (!selectMode && setSelectState) setSelectState(false);
    }, [selectMode]);

    const isAgent = userType === UserType.Agent;

    return (
        <>
            {dropdownVisible && (
                <div
                    className="absolute w-full h-full left-0 top-0 z-10 transparent"
                    onClick={() => setDropdownVisible(false)}
                ></div>
            )}

            <div
                className={`w-100 h-${
                    height ? height : '60'
                } relative rounded-l hover:shadow-md transition duration-200 ${className} ${
                    isHidden ? 'bg-white' : 'bg-white'
                }  ${selectState && 'border border-newTeal-main'}`}
                onClick={() => {
                    if (selectMode && setSelectState) setSelectState(!selectState);
                }}
            >
                {isAgent && (
                    <div className="w-full bg-newGray-main h-7 rounded-tl text-white flex flex-row items-center justify-center text-sm">
                        <span className="mr-1 font-semibold">Client:</span>
                        <span>
                            {`${clientName ?? ''} `}
                            {currentUserParty &&
                                ' (' +
                                    currentUserParty.charAt(0).toUpperCase() +
                                    currentUserParty.toLowerCase().slice(1) +
                                    ')'}
                        </span>
                    </div>
                )}{' '}
                <div className="">
                    {options && options.length > 0 && !selectMode && (
                        <CardDropdown
                            options={options}
                            dropdownVisible={dropdownVisible}
                            setDropdownVisible={(bool) => setDropdownVisible(bool)}
                            verticalOptions={true}
                            showClientName={isAgent}
                        />
                    )}
                </div>
                <div className={`${isHidden && 'hidden-card-container'}`}>{children}</div>
            </div>
        </>
    );
};

export default Card;
