import React, { useEffect, useState } from 'react';

import DealsList from './DealsList';
import { DealCardFragment, DealFilterCount, DealState, DealStatusFilter, User } from '../../generated/graphql';

type SearchDealCardsProps = {
    user?: User;
    dealFilterCounts?: DealFilterCount;
    deals?: DealCardFragment[];
    pinnedPresence: boolean;
    isTenant: boolean;
    dealStatus?: DealStatusFilter[];
};

const SearchDealCards = ({
    user,
    dealFilterCounts,
    deals,
    pinnedPresence,
    isTenant,
    dealStatus
}: SearchDealCardsProps) => {
    const [liveDeals, setLiveDeals] = useState<DealCardFragment[] | undefined>([]);
    const [completedDeals, setCompletedDeals] = useState<DealCardFragment[] | undefined>([]);

    useEffect(() => {
        const result = deals?.reduce(
            (acc, item) => {
                if (item.state !== DealState.Complete) {
                    acc.live.push(item);
                } else if (item.state === DealState.Complete) {
                    acc.completed.push(item);
                }
                return acc;
            },
            { live: [], completed: [] }
        );

        setLiveDeals(result?.live);
        setCompletedDeals(result?.completed);
    }, [deals]);

    const handleNextSection = (section: string) => {
        const dealSection = document.getElementById(section);
        dealSection?.scrollIntoView({
            behavior: 'smooth'
        });
    };

    return (
        <>
            <div className="flex mt-4 justify-between">
                <div className="flex">
                    <div id="live" className="text-newGray-darkishfont-semibold text-lg">
                        Live
                    </div>
                    <div
                        style={{
                            height: '20px',
                            backgroundColor: '#BFBFBF',
                            width: '20px'
                        }}
                        className="ml-2 text-newGray-darkishfont-semibold text-sm flex justify-center rounded"
                    >
                        {dealFilterCounts?.live}
                    </div>
                </div>
                <div
                    onClick={() => handleNextSection('completed')}
                    className="text-gray-v2-400 font-semibold text-xs cursor-pointer"
                >
                    Jump to next section
                </div>
            </div>
            <DealsList
                deals={liveDeals}
                user={user}
                isTenant={isTenant}
                pinnedPresence={pinnedPresence}
                dealStatus={dealStatus}
            />
            <div className="flex mt-4 justify-between">
                <div className="flex">
                    <div id="completed" className="text-newGray-darkishfont-semibold text-lg">
                        Completed
                    </div>
                    <div
                        style={{
                            height: '20px',
                            backgroundColor: '#BFBFBF',
                            width: '20px'
                        }}
                        className="ml-2 text-newGray-darkishfont-semibold text-sm flex justify-center rounded"
                    >
                        {dealFilterCounts?.complete}
                    </div>
                </div>
                <div
                    onClick={() => handleNextSection('live')}
                    className="text-gray-v2-400 font-semibold text-xs cursor-pointer"
                >
                    Jump to next section
                </div>
            </div>
            <DealsList
                deals={completedDeals}
                user={user}
                isTenant={isTenant}
                pinnedPresence={pinnedPresence}
                dealStatus={dealStatus}
            />
        </>
    );
};

export default SearchDealCards;
