import { format } from 'date-fns';
import { isEmpty, last } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import CoverNote from './CoverNote/';
import DealCompleteWindow from './DealCompleteWindow';
import NegotiateSections from './NegotiateSections';
import NegotiateWindows from './NegotiateWindows';
import ToolBar from './ToolBar/index';
import ToolBarProvider from './ToolBar/Provider';
import { ReactComponent as Clock } from '../../../../assets/images/clock/black.svg';
import {
    Clause,
    Deal,
    DealDetailsFragment,
    DealSendType,
    DealState,
    DealUserParty,
    GetDealDetailsQuery,
    HistoryEventType,
    Section,
    useGetDealDetailsQuery,
    useGetUserTypeQuery
} from '../../../../generated/graphql';
import { useUpdateUrl } from '../../../../shared/Filter/helpers';
import { getUserFullName } from '../../../../shared/helpers';
import useMobileScreen from '../../../../shared/hooks/useMobileScreen';
import Loading from '../../../../shared/Loading';
import useSeenDeal from '../../../../shared/Negotiator/hooks/useSeenDeal';
import { useUserType } from '../../../../v2/common/helpers';
import CreatedWindow from '../../../../v2/common/MobileWindow/CreatedWindow';
import { LandlordFilesContext } from '../../../../v2/components/LandlordsFileModal/LandlordFilesContext';
import { LandlordsFileModal } from '../../../../v2/components/LandlordsFileModal/LandlordsFileModal';
import TenantDeal from '../../../../v2/components/Sidebar/Deal/Tenant';
import SidebarWrapper from '../../../../v2/components/Sidebar/SidebarWrapper';
import DealHeader from '../../../../v2/pages/Deals/DealHeader';
import DownloadDataWindow from '../../../../v2/pages/Deals/DownloadData/DownloadDataWIndow';
import { AddClauseGroupInput } from '../../../Templates/Template';
import { DealContext } from '../context';
import OldDealHeader from '../Header';
import useSortDeal from '../hooks/useSortDeal';
import NoClauses from '../NoClauses';

export type WalkthroughClause = {
    id: string;
    accordianID: string;
    complete: boolean;
};
type NegotiateDealProps = {
    setHeaderContent: React.Dispatch<React.SetStateAction<React.ReactChild | null>>;
    setForceCloseCoverNote?: React.Dispatch<React.SetStateAction<boolean>>;
    forceCloseCoverNote?: boolean;
    setShowingHeaderContent?: React.Dispatch<React.SetStateAction<boolean>>;
    setSeenDealAgreed?: React.Dispatch<React.SetStateAction<boolean>>;
    seenDealAgreed?: boolean;
};

export const hasChanges = (deal: DealDetailsFragment) => {
    const getLeafClauses = (sections: Section[]) => {
        const groups = sections.map((section) => section.groups).flat();

        const getClauses = (clauses: Clause[]): Clause[] => {
            return clauses
                .map((clause) => {
                    if (clause.groups && !isEmpty(clause.groups)) {
                        return clause.groups.map((group) => getClauses(group.clauses)).flat();
                    } else {
                        return [clause];
                    }
                })
                .flat();
        };

        return getClauses(groups.map((group) => group.clauses).flat());
    };

    return !isEmpty(
        getLeafClauses(deal.sections as Section[]).filter((clause) => {
            const lastChange = last(clause.history);
            return (
                !isEmpty(clause.history) &&
                lastChange?.party === deal?.currentUserParty &&
                lastChange.type !== HistoryEventType.Approve
            );
        })
    );
};

export const canReturn = (deal?: Deal, isEdit?: boolean) => {
    return (
        deal &&
        deal.currentUserParty === DealUserParty.Landlord &&
        [DealState.Agreed, DealState.Landlord].includes(deal.state) &&
        (isEdit || (hasChanges(deal) && deal.agreedBy && deal.agreedBy?.valueOf() !== deal.currentUserParty))
    );
};

const NegotiateDeal = ({ setHeaderContent, setForceCloseCoverNote, forceCloseCoverNote }: NegotiateDealProps) => {
    const { showLandlordFilesWindow, setShowLandlordFilesWindow } = useContext(LandlordFilesContext);

    const { downloadClose, isDownloadOpen } = useContext(DealContext);

    const isMobile = useMobileScreen();
    const history = useHistory();
    const { id }: { id: string } = useParams();
    const {
        loading: detailsLoading,
        error: detailsError,
        data: detailsData,
        refetch
    } = useGetDealDetailsQuery({
        variables: {
            dealID: id
        }
    });
    const seenDeal = useSeenDeal();

    useEffect(() => {
        if (
            detailsData?.deal &&
            detailsData.deal.__typename === 'Deal' &&
            !detailsData.deal.round?.seen &&
            detailsData.deal?.currentUserParty?.toString() === detailsData?.deal.state.toString()
        ) {
            seenDeal(detailsData?.deal.__typename === 'Deal' ? detailsData.deal : undefined);
        }
    }, [detailsData, seenDeal]);

    useEffect(() => {
        if (detailsData?.deal.__typename === 'Deal' && detailsData.deal.state === DealState.Agreed) {
            setForceCloseCoverNote && setForceCloseCoverNote(true);
        }
    }, [detailsData, setForceCloseCoverNote]);

    const { isTenantSide } = useUserType(undefined, detailsData?.deal.currentUserParty);

    const { data: typeData } = useGetUserTypeQuery();

    const [deal, setDeal] = useState<DealDetailsFragment>();
    const sortDeal = useSortDeal();

    const [showAddClauseWindow, setShowAddClauseWindow] = useState(false);
    const [showDealAgreedWindow, setShowDealAgreedWindow] = useState(false);

    const [, setShowLowReadinessWarning] = useState(false);

    const [, setShareWindowType] = useState<DealSendType>(DealSendType.Other);

    const [forceOpenShareWindow, setForceOpenShareWindow] = useState(false);

    const [showFilterAddClauseWindow, setShowFilterAddClauseWindow] = useState<AddClauseGroupInput>();
    const [showNewClauseWindow, setShowNewClauseWindow] = useState(false);
    const [isClauseReordering, setClauseReordering] = useState(false);
    const [isTitlesReordering, setTitlesReordering] = useState(false);
    const [showCreatedWindow, setShowCreatedWindow] = useState(false);

    const [openDetails, setOpenDetails] = useState<{
        section?: string;
        level2Group?: string;
    } | null>(null);
    // IDs of clauses & whether they are visible - used in clause walkthrough (ToolBar > Next button)
    const [walkthroughClauses, setWalkthroughClauses] = useState<WalkthroughClause[]>([]);

    const [financialsOpen, setFinancialsOpen] = useState<boolean>(false);
    const [hideToolbar, setHideToolbar] = useState<boolean>(false);
    const [showingTenantPending, setShowingTenantPending] = useState<boolean>(false);

    const [showActivateTenant, setShowActivateTenant] = useState<boolean>(false);

    useEffect(() => {
        // Reset filter on window close
        !showAddClauseWindow && setOpenDetails(null);
    }, [showAddClauseWindow]);

    const [dealComplete, setDealComplete] = useState<any>({
        complete: false,
        type: ''
    });

    // Set the header to the building & unit name
    useEffect(() => {
        setHeaderContent(
            <div className="-ml-10 md:ml-0 flex justify-between relative z-40 w-full">
                <div className="pl-12 md:pl-0 flex flex-col p-2  w-2/3">
                    <h1 className={`font-semibold text-xl truncate w-full`}>
                        {detailsData?.deal.__typename === 'Deal' &&
                            detailsData.deal.unit?.buildingName + ' - HoTs Negotiation'}
                    </h1>
                    <p className="text-xs md:text-sm -mt-1">
                        {detailsData?.deal.__typename === 'Deal' && detailsData.deal.unit?.name}
                    </p>
                    {!isTenantSide && (
                        <p className="hidden md:flex text-sm -mt-1">
                            {detailsData?.deal.__typename === 'Deal' && detailsData.deal.template?.name}
                        </p>
                    )}
                </div>
                <div className="-mt-1 z-10 flex justify-center items-center md:flex-col-reverse md:items-end">
                    <Clock className="w-6 h-6 mt-2 mr-2 xs:flex md:hidden" onClick={() => setShowCreatedWindow(true)} />
                    <p className={`text-sm  md:flex xs:hidden`}>
                        {detailsData?.deal.__typename === 'Deal' &&
                            (detailsData.deal.created ? 'by ' : 'Created by') +
                                getUserFullName(detailsData.deal.creator)}
                    </p>
                    <p className={`"text-sm  md:flex xs:hidden`}>
                        {detailsData?.deal.__typename === 'Deal' &&
                            detailsData.deal.created &&
                            'Created on ' + format(new Date(detailsData.deal.created), 'dd MMM yyyy, h:mm a')}
                    </p>
                </div>
            </div>
        );
    }, [setHeaderContent, detailsData, isMobile]);

    const { addToUrl, removeFromUrl } = useUpdateUrl();

    useEffect(() => {
        // Sort the deal by it's clauses
        const sortedDeal = sortDeal(detailsData);
        sortedDeal && setDeal(sortedDeal);
        const tenant = isTenantSide ? { tenant: isTenantSide } : {};
        addToUrl(tenant);
        if (showActivateTenant) {
            addToUrl({ activateTenant: true });
        } else {
            removeFromUrl(['activateTenant']);
        }
        // eslint-disable-next-line
    }, [detailsData, detailsError, history, showActivateTenant]);

    const { seenDealAgreed, reload } = useContext(DealContext);
    const [showMakingChanges, setShowMakingChanges] = useState(false);

    useEffect(() => {
        // if tenant is inactive show new tenant form
        if (deal?.currentUserParty.valueOf() === DealUserParty.Tenant && deal && deal.tenant && !deal.tenant.active) {
            setShowActivateTenant(true);
            return;
        }

        if (dealComplete.complete && deal?.state !== DealState.Agreed) {
            if (
                !seenDealAgreed &&
                deal?.agreedBy === 'TENANT' &&
                deal?.currentUserParty?.toString() !== 'LANDLORD' &&
                !hasChanges(deal)
            ) {
                setShowDealAgreedWindow(true);
            }
        } else if (
            deal?.state === DealState.Agreed &&
            deal?.currentUserParty?.toString() !== deal?.agreedBy?.toString() &&
            !hasChanges(deal)
        ) {
            if (
                !seenDealAgreed &&
                deal?.agreedBy &&
                deal?.agreedBy?.toString() !== deal?.currentUserParty?.toString()
            ) {
                setShowDealAgreedWindow(true);
            }
        } else if (
            deal?.agreedBy &&
            deal.agreedBy.valueOf() !== deal.currentUserParty &&
            hasChanges(deal) &&
            deal.state.valueOf() === deal.currentUserParty &&
            !reload
        ) {
            setShowMakingChanges(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dealComplete, deal, reload]);

    const isSeenAgreement = isTenantSide
        ? !detailsData?.deal.agreementSeenTenant
        : !detailsData?.deal.agreementSeenLandlord;

    if (detailsLoading || !detailsData?.deal) return <Loading />;

    return (
        <SidebarWrapper wrap={!isTenantSide} sidebar={isTenantSide && <TenantDeal />} className={'overflow-y-hidden'}>
            {showCreatedWindow && (
                <CreatedWindow
                    setShowCreatedWindow={setShowCreatedWindow}
                    date={format(new Date(detailsData.deal.created), 'dd MMM yyyy, h:mm a')}
                    creator={getUserFullName(detailsData.deal.creator)}
                />
            )}
            <div id="content">
                {/* Header */}
                {detailsData && !isMobile ? (
                    <div className={`top-0 sticky z-40`}>
                        <DealHeader
                            userType={typeData?.user.__typename === 'User' ? typeData.user.type : undefined}
                            deal={detailsData?.deal.__typename === 'Deal' ? detailsData?.deal : null}
                            dealAgreedWindowOpen={showDealAgreedWindow}
                            triggerOpen={forceOpenShareWindow}
                            setShowAddClauseWindow={setShowAddClauseWindow}
                            isClauseReordering={isClauseReordering}
                            setClauseReordering={setClauseReordering}
                            setWalkthroughClauses={setWalkthroughClauses}
                            setFinancialsOpen={setFinancialsOpen}
                            setHideToolbar={setHideToolbar}
                            setShowingTenantPending={setShowingTenantPending}
                            isTitlesReordering={isTitlesReordering}
                            setTitlesReordering={setTitlesReordering}
                        >
                            {detailsData}
                        </DealHeader>
                    </div>
                ) : (
                    <OldDealHeader
                        userType={typeData?.user.__typename === 'User' ? typeData.user.type : undefined}
                        deal={detailsData?.deal.__typename === 'Deal' ? detailsData?.deal : null}
                        dealAgreedWindowOpen={showDealAgreedWindow}
                        triggerOpen={forceOpenShareWindow}
                        setShowAddClauseWindow={setShowAddClauseWindow}
                        isClauseReordering={isClauseReordering}
                        setClauseReordering={setClauseReordering}
                        setWalkthroughClauses={setWalkthroughClauses}
                        setFinancialsOpen={setFinancialsOpen}
                        setHideToolbar={setHideToolbar}
                        setShowingTenantPending={setShowingTenantPending}
                        isTitlesReordering={isTitlesReordering}
                        setTitlesReordering={setTitlesReordering}
                        setHeaderContent={setHeaderContent}
                    >
                        {detailsData}
                    </OldDealHeader>
                )}
                <div className={`pb-4 md:py-4 transform duration-300 ${isMobile ? 'px-2' : 'md:px-10 lg:px-24'}`}>
                    {detailsData.deal.sections.length > 0 ? (
                        <ToolBarProvider clauses={walkthroughClauses}>
                            <div className={`container mx-auto flex flex-col px-4 ${isMobile ? 'pt-20' : 'pt-4'}`}>
                                {/* Sections */}
                                <NegotiateSections
                                    deal={deal}
                                    typeData={typeData}
                                    setShowAddClauseWindow={setShowAddClauseWindow}
                                    setOpenDetails={setOpenDetails}
                                    detailsData={detailsData}
                                    setDealComplete={setDealComplete}
                                    isClauseReordering={isClauseReordering}
                                    isTitlesReordering={isTitlesReordering}
                                    setClauseReordering={setClauseReordering}
                                    setTitlesReordering={setTitlesReordering}
                                />

                                {/* Toolbar */}
                                <ToolBar
                                    visible={
                                        deal?.state === deal?.currentUserParty &&
                                        !showingTenantPending &&
                                        (!isMobile || (isMobile && !financialsOpen && !hideToolbar))
                                    }
                                />
                            </div>
                        </ToolBarProvider>
                    ) : (
                        <NoClauses setShowAddClauseWindow={setShowAddClauseWindow} />
                    )}
                </div>
            </div>
            {/* WINDOWS */}
            <NegotiateWindows
                showAddClauseWindow={showAddClauseWindow}
                setShowAddClauseWindow={setShowAddClauseWindow}
                showFilterAddClauseWindow={showFilterAddClauseWindow}
                setShowFilterAddClauseWindow={setShowFilterAddClauseWindow}
                showNewClauseWindow={showNewClauseWindow}
                setShowNewClauseWindow={setShowNewClauseWindow}
                showDealAgreedWindow={showDealAgreedWindow}
                setShowDealAgreedWindow={setShowDealAgreedWindow}
                setShowLowReadinessWarning={setShowLowReadinessWarning}
                detailsData={detailsData}
                setShareWindowType={setShareWindowType}
                setForceOpenShareWindow={setForceOpenShareWindow}
                refetch={refetch}
                openDetails={openDetails}
                showActivateTenant={showActivateTenant}
                setShowActivateTenant={setShowActivateTenant}
                showMakingChanges={showMakingChanges}
                setShowMakingChanges={setShowMakingChanges}
            />
            {showLandlordFilesWindow && (
                <LandlordsFileModal
                    setShow={setShowLandlordFilesWindow}
                    dealId={id}
                    organisationName={detailsData?.deal.unit?.organisation.name}
                />
            )}
            {/* Cover Note */}
            {deal && (
                <CoverNote
                    id={deal._id}
                    forceCloseCoverNote={forceCloseCoverNote}
                    currentUserParty={deal.currentUserParty}
                    visible={!showingTenantPending && (!isMobile || !financialsOpen)}
                />
            )}
            <DownloadDataWindow
                isOpen={isDownloadOpen}
                close={downloadClose}
                currentUserParty={
                    detailsData?.deal.currentUserParty ? detailsData?.deal.currentUserParty : DealUserParty.Tenant
                }
                type={deal?.currentUserParty === DealUserParty.Landlord ? DealSendType.Other : DealSendType.Landlord}
            />
            {isSeenAgreement && typeData && detailsData?.deal.state === DealState.Complete && (
                <DealCompleteWindow
                    userType={typeData?.user.__typename === 'User' ? typeData?.user.type : null}
                    dealComplete={dealComplete}
                    deal={detailsData as GetDealDetailsQuery}
                />
            )}
        </SidebarWrapper>
    );
};

export default NegotiateDeal;
