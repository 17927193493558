import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { useDeleteUserMutation, useGetUserOrganisationQuery } from '../../generated/graphql';
import { handleFetchError } from '../../shared/helpers';
import Window from '../../shared/Window';

type DeleteProfileWindowProps = {
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
};

const DeleteProfileWindow = ({ setShow }: DeleteProfileWindowProps) => {
    const [deleteUser, { error: deleteError }] = useDeleteUserMutation();
    const { data } = useGetUserOrganisationQuery();
    const history = useHistory();
    const [confirmDelete, setConfirmDelete] = useState<boolean>(false);
    const [isOnlyMember, setIsOnlyMember] = useState<boolean>(false);

    const handleDeleteClick = async () => {
        const { data: deleteData } = await deleteUser();

        if (deleteError) {
            handleFetchError('Failed to delete the user', deleteError, deleteData?.userDelete);
            return;
        }

        history.push('/login');
    };

    return (
        <div>
            {confirmDelete ? (
                <Window
                    setShow={setShow}
                    title="Deactivate account"
                    titleCenter
                    width="w-2/5"
                    primaryBtn
                    primaryBtnText="Deactivate"
                    onPrimaryBtnClick={() => handleDeleteClick()}
                    secondaryBtn
                    onSecondaryBtnClick={() => setShow(false)}
                >
                    <div className="flex flex-col items-center">
                        <h2 className="text-center text-lg my-8 w-4/5">Deactivate your account</h2>
                    </div>
                </Window>
            ) : isOnlyMember ? (
                <Window
                    setShow={setShow}
                    title="Deactivate account"
                    titleCenter
                    width="w-2/5"
                    primaryBtn
                    onPrimaryBtnClick={() => setConfirmDelete(true)}
                    secondaryBtn
                    onSecondaryBtnClick={() => setShow(false)}
                >
                    <div className="flex flex-col items-center">
                        <h2 className="text-center text-lg my-8 w-4/5">
                            You are the only user within the Company. Deactivating your account will permanently delete
                            your Company. Are you sure you want to deactivate your account and thus permanently delete
                            your Company?
                        </h2>
                    </div>
                </Window>
            ) : (
                <Window
                    setShow={setShow}
                    title="Deactivate account"
                    titleCenter
                    width="w-2/5"
                    primaryBtn
                    onPrimaryBtnClick={() =>
                        (data?.user.organisation.members?.length ?? 0) > 1
                            ? setConfirmDelete(true)
                            : setIsOnlyMember(true)
                    }
                    secondaryBtn
                    onSecondaryBtnClick={() => setShow(false)}
                >
                    <div className="flex flex-col items-center">
                        <h2 className="text-center text-lg my-8 w-4/5">
                            Are you sure you want to <span className="font-semibold text-uppercase">deactivate</span>{' '}
                            your account? This process is irreversible.
                        </h2>
                    </div>
                </Window>
            )}
        </div>
    );
};

export default DeleteProfileWindow;
