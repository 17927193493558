import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import Clause from './Clause';
import ClauseBankAddWindow from './ClauseBankAddWindow';
import { ReactComponent as ChevronRight } from '../../../assets/images/chevron/right/black.svg';
import { ReactComponent as Search } from '../../../assets/images/search/black.svg';
import { SingleTemplateClauseFragment, useSearchClauseBankQuery } from '../../../generated/graphql';
import Acordian from '../../../shared/Accordian';
import { AddButton } from '../../../shared/add-button';
import { debounce, handleFetchError } from '../../../shared/helpers';
import useMobileScreen from '../../../shared/hooks/useMobileScreen';
import Input, { InputType } from '../../../shared/Inputs';
import Loading from '../../../shared/Loading';

type ClauseBankProps = {
    organisationID?: String;
};

export type Clauses = SingleTemplateClauseFragment[];

export type SortedClauses = {
    [key: string]: {
        [key: string]: Clauses;
    };
};

const ClauseBank = ({ organisationID }: ClauseBankProps) => {
    const mobileScreen = useMobileScreen();

    const { orgId } = useParams<{ orgId: string }>();
    // Show add clause window
    const [showAddClauseWindow, setShowAddClauseWindow] = useState<boolean>(false);

    // Sorted clauses
    const [clauses, setClauses] = useState<SortedClauses>({});
    const [descriptions, setDescriptions] = useState<any>({});
    // Add clauses dropdowns
    const [dropdowns, setDropdowns] = useState<
        {
            title?: string | null;
            groups: {
                title?: string | null;
                groups: {
                    title?: string | null;
                }[];
            }[];
        }[]
    >([]);

    // Load clauses from API
    const {
        data,
        error,
        loading,
        refetch: searchClauses
    } = useSearchClauseBankQuery({
        variables: {
            organisationID: orgId ? [orgId] : [],
            only: true,
            query: ''
        },
        fetchPolicy: 'cache-and-network',
        notifyOnNetworkStatusChange: true
    });

    // Handle API response
    useEffect(() => {
        // Check for errors
        handleFetchError('Could not reach the clause bank', error, data?.clauseBank);

        // Sort clauses into sections/groups
        if (data && Array.isArray(data?.clauseBank)) return sortResponse(data.clauseBank);
    }, [data, error]);

    // Sort clauses into sections and groups
    const sortResponse = (results: Clauses) => {
        let sortedClauses: SortedClauses = {};
        let sortedDescriptions: any = {};

        if (results && results.length > 0) {
            results
                .filter((clause) => {
                    return clause?.section?.title;
                })
                .map((clause) => {
                    let sectionName = clause.section.title;
                    let groupName = clause.section.group.title;
                    sortedClauses[sectionName] = sortedClauses[sectionName] ? sortedClauses[sectionName] : {};
                    sortedDescriptions[sectionName] = sortedDescriptions[sectionName]
                        ? sortedDescriptions[sectionName]
                        : {
                              groups: {},
                              description: clause.section.description,
                              rics: clause.section.rics
                          };

                    sortedClauses[sectionName][groupName] = sortedClauses[sectionName][groupName]
                        ? sortedClauses[sectionName][groupName]
                        : [];
                    sortedDescriptions[sectionName].groups[groupName] = {
                        description: clause.section.group.description,
                        rics: clause.section.group.rics
                    };

                    return sortedClauses[sectionName][groupName].push(clause);
                });
        }

        setDescriptions(sortedDescriptions);
        setClauses(sortedClauses);
    };

    // Set the dropdown sections and groups
    useEffect(() => {
        if (!data) {
            return;
        }

        // Sort sections/groups into corresponding parts for the dropdown
        let _dropdowns: typeof dropdowns = [];
        data.clauseBank
            ?.filter((clause) => {
                return clause?.section?.title;
            })
            .map((clause) => {
                const sectionIndex = _dropdowns.findIndex((item) => item.title === clause.section.title);
                const section = _dropdowns[sectionIndex] ?? {
                        title: clause.section.title,
                        groups: []
                    } ?? { title: clause.section.title, groups: [] };
                const groupIndex = section.groups.findIndex((item) => item.title === clause.section.group.title);
                const group = section.groups[groupIndex] ?? {
                        title: clause.section.group.title,
                        groups: []
                    } ?? { title: clause.section.group.title, groups: [] };
                const clauseGroupIndex = group.groups.findIndex(
                    (item) => item.title === clause.section.group.group?.title
                );
                const clauseGroup = group.groups[clauseGroupIndex] ?? {
                    title: clause.section.group.group?.title
                };

                // Set clause groups
                group.groups[clauseGroupIndex !== -1 ? clauseGroupIndex : group.groups.length] = clauseGroup;
                // Set groups
                section.groups[groupIndex !== -1 ? groupIndex : section.groups.length] = group;
                // Set section
                _dropdowns[sectionIndex !== -1 ? sectionIndex : _dropdowns.length] = section;
            });
        setDropdowns(_dropdowns);
    }, [data]);

    // Search clause bank
    const handleChange = debounce((value?: string) => {
        searchClauses({
            organisationID: orgId ? [orgId] : [],
            only: true,
            query: value
        });
    }, 250);

    return (
        <>
            {showAddClauseWindow && (
                <ClauseBankAddWindow
                    dropdowns={dropdowns}
                    setShow={setShowAddClauseWindow}
                    organisationID={orgId ? orgId : undefined}
                />
            )}

            <div
                className={` ${
                    mobileScreen ? 'flex-col' : 'flex-row'
                } py-5 px-5 border-b border-navy-light flex w-full items-center`}
            >
                <AddButton
                    onClick={() => {
                        setShowAddClauseWindow(true);
                    }}
                    background="newTeal-main"
                    text="white"
                    height="12"
                    border="transparent"
                    className={`px-4 mr-2 my-2 ${
                        mobileScreen ? 'w-full ml-1' : 'w-40'
                    } whitespace-no-wrap font-semibold`}
                    disabled={loading}
                >
                    Create Clause
                </AddButton>

                <div className="relative w-100">
                    <Search className="w-5 h-5 absolute left-0 top-0 ml-4 z-20" style={{ top: '13px' }} />
                    <Input
                        type={InputType.text}
                        placeholder="Search Clauses"
                        className="w-full h-12 pl-12"
                        autoFocus
                        onChange={(e) => handleChange(e.target.value)}
                    />
                </div>
            </div>

            <div className="py-4">
                {loading && !data ? (
                    <Loading />
                ) : (
                    clauses &&
                    (Object.keys(clauses).length <= 0 ? (
                        <button
                            className="p-3 px-4 text-sm bg-newGray-main text-white text-left rounded font-semibold flex ml-5"
                            onClick={() => {
                                if (orgId) return;
                                setShowAddClauseWindow(true);
                            }}
                        >
                            {!orgId ? "Can't find what you're looking for?" : 'No results to display'}
                            {!orgId && <ChevronRight className="w-5 h-5 ml-2" />}
                        </button>
                    ) : (
                        Object.keys(clauses).map((_, sectionIndex) => {
                            let section = Object.keys(clauses)[sectionIndex];
                            let groups = Object.keys(clauses[section]);
                            return (
                                <div className="mx-6 bg-white rounded px-6 py-4 mb-3" key={sectionIndex}>
                                    <Acordian
                                        title={section}
                                        fromSection={section}
                                        organisationID={orgId ? orgId : undefined}
                                        description={descriptions[section].description}
                                        rics={descriptions[section].rics}
                                        className="bg-white font-semibold"
                                        isOpen={true}
                                        key={sectionIndex}
                                        index={sectionIndex}
                                        length={groups.length}
                                    >
                                        {groups &&
                                            groups.map((_, groupIndex) => {
                                                let group = groups[groupIndex];

                                                return (
                                                    <Acordian
                                                        title={group}
                                                        organisationID={orgId ? orgId : undefined}
                                                        fromSection={section}
                                                        fromGroup={group}
                                                        description={descriptions[section].groups[group].description}
                                                        rics={descriptions[section].groups[group].rics}
                                                        className="bg-navy-lightest mt-3 p-4 py-3"
                                                        key={groupIndex}
                                                    >
                                                        {clauses[section][group].map((clause) => (
                                                            <Clause
                                                                orgId={orgId}
                                                                uses={clause.associatedUses}
                                                                {...clause}
                                                                key={clause._id}
                                                                linkedClauses={clause.linked?.results ?? []}
                                                                section={clause.section?.title ?? ''}
                                                                level1Group={clause?.section?.group?.title ?? ''}
                                                                level2Group={
                                                                    clause.section?.group?.group?.title ?? undefined
                                                                }
                                                                dropdowns={dropdowns}
                                                            />
                                                        ))}
                                                    </Acordian>
                                                );
                                            })}
                                    </Acordian>
                                </div>
                            );
                        })
                    ))
                )}
            </div>
        </>
    );
};

export default ClauseBank;
