import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import { DealContext } from './context';
import { DealState, useGetDealStateQuery, useVerifyDealMutation } from '../../../generated/graphql';
import { useQuery } from '../../../shared/helpers';
import Loading from '../../../shared/Loading';
import { isErrorCode } from '../../../v2/common/helpers';

const Deal = () => {
    const history = useHistory();
    const location = useLocation();
    const query = useQuery();
    const token = query.get('token');
    const { id } = useParams();
    const { error: detailsError, data: detailsData } = useGetDealStateQuery({
        variables: {
            dealID: id
        }
    });

    const { isEdit } = useContext(DealContext);

    const [verifyDeal, { error: verifyError }] = useVerifyDealMutation();

    // Set to loading if need to verify token
    const [verificationLoading, setVerificationLoading] = useState<boolean>(!!token);

    // Check if verification route
    useEffect(() => {
        const verify = async (token?: string) => {
            if (token) {
                await verifyDeal({
                    variables: {
                        token: token
                    }
                });

                if (
                    verifyError
                    // (verifyData?.dealVerify.__typename === "Error" &&
                    //     verifyData.dealVerify.code !== 408)
                ) {
                    // Check for errors
                    // handleFetchError(
                    //     "Could not verify the deal",
                    //     verifyError,
                    //     verifyData?.dealVerify
                    // )
                    // Redirect to deals page if error, and not already verified
                    // If user is not logged in, redirect to login page and store details
                    if (isErrorCode('UNAUTHENTICATED', verifyError))
                        return history.push(`/login?prev=${location.pathname}?token=${token}`);

                    return history.push('/deals');
                }

                setVerificationLoading(false);
            }
        };
        verificationLoading && token && verify(token);
    }, [token, history, verifyError, verifyDeal, verificationLoading, location.pathname]);

    useEffect(() => {
        // Wait for verification first
        if (verificationLoading) return;

        if (query.get('s')) return;

        if (isErrorCode('UNAUTHENTICATED', detailsError)) return history.push(`/login?prev=${location.pathname}`);

        // Redirect to deals page if error e.g. ID in url is not valid
        if (detailsError) return history.push('/deals');

        if (detailsData?.deal.__typename === 'Deal') {
            // Redirect to edit, negotiate or preview page
            if (detailsData.deal.state === DealState.Edit)
                history.replace(`/deals/${id}/edit?landlordorg=${detailsData.deal.unit?.organisation.name}`);
            else if (detailsData.deal.state !== DealState.Complete || isEdit) {
                history.replace(`/deals/${id}/negotiate?landlordorg=${detailsData.deal.unit?.organisation.name}`);
            } else if (detailsData.deal.state === DealState.Complete) {
                history.replace(`/deals/${id}/preview?landlordorg=${detailsData.deal.unit?.organisation.name}`);
            } else history.replace('/deals');
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [detailsData, detailsError, history, id, verificationLoading]);

    if (verificationLoading)
        return (
            <div className="h-full flex flex-col items-center content-center justify-center">
                <div>
                    <Loading />
                    <h2 className="text-xl text-center text-gray-800">Verifying the deal...</h2>
                </div>
            </div>
        );
    return null;
    // return <Loading />
};

export default Deal;
