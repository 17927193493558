import { useApolloClient } from '@apollo/client';
import React, { useEffect, useState } from 'react';

import { useGetUserProfileQuery, UserType, useUserNotificationsDisableMutation } from '../../generated/graphql';
import { Button } from '../../shared/button';
import { handleFetchError } from '../../shared/helpers';
import Loading from '../../shared/Loading';

type NotificationsProps = {
    userType?: UserType;
};

const Notifications = ({ userType }: NotificationsProps) => {
    const client = useApolloClient();
    const { data, loading, refetch } = useGetUserProfileQuery();

    const [disabledNotifications, setDisabledNotifications] = useState<{
        userEmail?: boolean | null;
        userNotification?: boolean | null;
        documentEmail?: boolean | null;
        documentNotification?: boolean | null;
        approvalEmail?: boolean | null;
        approvalNotification?: boolean | null;
        dealEmail?: boolean | null;
        dealNotification?: boolean | null;
        unitEmail?: boolean | null;
        unitNotification?: boolean | null;
        seenNotification?: boolean | null;
        seenEmail?: boolean | null;
    } | null>(null);

    const [showSave, setShowSave] = useState<boolean>(false);

    const [disableNotifications, { error: disableNotificationsError, loading: disableNotificationsLoading }] =
        useUserNotificationsDisableMutation();

    const handleSave = async () => {
        if (!disabledNotifications || data?.user.__typename !== 'User') return;
        const { data: saveData } = await disableNotifications({
            variables: disabledNotifications
        });
        if (disableNotificationsError) {
            return handleFetchError(
                'Failed to get your notifications',
                disableNotificationsError,
                saveData?.userNotificationsDisable
            );
        } else {
            setShowSave(false);
            client.cache.modify({
                id: client.cache.identify({
                    __typename: 'User',
                    _id: data?.user._id
                }),
                fields: {
                    disabledNotifications() {
                        return disabledNotifications;
                    }
                }
            });
            refetch();
        }
    };

    useEffect(() => {
        if (data?.user.__typename === 'User' && data.user.disabledNotifications && !disabledNotifications) {
            setDisabledNotifications({
                userEmail: data.user.disabledNotifications.users?.email,
                userNotification: data.user.disabledNotifications.users?.notification,
                documentEmail: data.user.disabledNotifications.documents?.email,
                documentNotification: data.user.disabledNotifications.documents?.notification,
                approvalEmail: data.user.disabledNotifications.approvals?.email,
                approvalNotification: data.user.disabledNotifications.approvals?.notification,
                dealEmail: data.user.disabledNotifications.deals?.email,
                dealNotification: data.user.disabledNotifications.deals?.notification,
                unitEmail: data.user.disabledNotifications.units?.email,
                unitNotification: data.user.disabledNotifications.units?.notification,
                seenEmail: data.user.disabledNotifications.seen?.email,
                seenNotification: data.user.disabledNotifications.seen?.notification
            });
        }
    }, [data, disabledNotifications]);

    useEffect(() => {
        if (data?.user.__typename !== 'User' || !data.user.disabledNotifications || !disabledNotifications) {
            setShowSave(false);
            return;
        }
        let original = data.user.disabledNotifications;
        if (
            disabledNotifications.userEmail === original.users?.email &&
            disabledNotifications.userNotification === original.users?.notification &&
            disabledNotifications.approvalEmail === original.approvals?.email &&
            disabledNotifications.approvalNotification === original.approvals?.notification &&
            disabledNotifications.dealEmail === original.deals?.email &&
            disabledNotifications.dealNotification === original.deals?.notification &&
            disabledNotifications.documentEmail === original.documents?.email &&
            disabledNotifications.documentNotification === original.documents?.notification &&
            disabledNotifications.unitEmail === original.units?.email &&
            disabledNotifications.unitNotification === original.units?.notification &&
            disabledNotifications.seenEmail === original.seen?.email &&
            disabledNotifications.seenNotification === original.seen?.notification
        ) {
            setShowSave(false);
            return;
        }
        setShowSave(true);
    }, [disabledNotifications, data]);

    if (loading) return <Loading />;

    return (
        <div className="w-full lg:w-4/5 p-6">
            <h1 className="mb-1 font-semibold text-xl">Notifications</h1>
            <p className="text-newGray-darkish mb-4 font-medium text-sm">
                Change what notifications you receive based on their category.
            </p>
            <div className="flex flex-col">
                <div className="flex flex-col">
                    <h3 className="font-semibold text-l">Users</h3>
                    <p>
                        User orientated notifications. This includes notifications for invitations and joins for
                        organisations and units, too.
                    </p>
                </div>

                <label className="flex flex-row items-center cursor-pointer select-none mt-3 ml-5">
                    <input
                        onChange={() => {
                            setDisabledNotifications({
                                ...disabledNotifications,
                                ...{
                                    userEmail: disabledNotifications?.userEmail ? false : true
                                }
                            });
                        }}
                        type="checkbox"
                        checked={disabledNotifications?.userEmail ? false : true}
                        className="leading-tight w-6 h-6 form-checkbox p-2 rounded bg-white text-newTeal-main focus:shadow-none focus:outline-none mr-2"
                    />
                    <p>Email Notifications</p>
                </label>

                <label className="flex flex-row items-center cursor-pointer select-none mt-3 ml-5">
                    <input
                        onChange={() => {
                            setDisabledNotifications({
                                ...disabledNotifications,
                                ...{
                                    userNotification: disabledNotifications?.userNotification ? false : true
                                }
                            });
                        }}
                        type="checkbox"
                        checked={disabledNotifications?.userNotification ? false : true}
                        className="leading-tight w-6 h-6 form-checkbox p-2 rounded bg-white text-newTeal-main focus:shadow-none focus:outline-none mr-2"
                    />
                    <p>In-platform Notifications</p>
                </label>
            </div>
            <div className="flex flex-col mt-5">
                <div className="flex flex-col">
                    <h3 className="font-semibold text-l">Documents</h3>
                    <p>
                        Document orientated notifications. This includes notifications triggered when any new documents
                        have been uploaded.
                    </p>
                </div>

                <label className="flex flex-row items-center cursor-pointer select-none mt-3 ml-5">
                    <input
                        onChange={() => {
                            setDisabledNotifications({
                                ...disabledNotifications,
                                ...{
                                    documentEmail: disabledNotifications?.documentEmail ? false : true
                                }
                            });
                        }}
                        type="checkbox"
                        checked={disabledNotifications?.documentEmail ? false : true}
                        className="leading-tight w-6 h-6 form-checkbox p-2 rounded bg-white text-newTeal-main focus:shadow-none focus:outline-none mr-2"
                    />
                    <p>Email Notifications</p>
                </label>

                <label className="flex flex-row items-center cursor-pointer select-none mt-3 ml-5">
                    <input
                        onChange={() => {
                            setDisabledNotifications({
                                ...disabledNotifications,
                                ...{
                                    documentNotification: disabledNotifications?.documentNotification ? false : true
                                }
                            });
                        }}
                        type="checkbox"
                        checked={disabledNotifications?.documentNotification ? false : true}
                        className="leading-tight w-6 h-6 form-checkbox p-2 rounded bg-white text-newTeal-main focus:shadow-none focus:outline-none mr-2"
                    />
                    <p>In-platform Notifications</p>
                </label>
            </div>
            <div className="flex flex-col mt-5">
                <div className="flex flex-col">
                    <h3 className="font-semibold text-l">Deals</h3>
                    <p>
                        Deal orientated notifications. This includes invites and various notifications for new updates
                        within a deal or any reminders you may need for them.
                    </p>
                </div>

                <label className="flex flex-row items-center cursor-pointer select-none mt-3 ml-5">
                    <input
                        onChange={() => {
                            setDisabledNotifications({
                                ...disabledNotifications,
                                ...{
                                    dealEmail: disabledNotifications?.dealEmail ? false : true
                                }
                            });
                        }}
                        type="checkbox"
                        checked={disabledNotifications?.dealEmail ? false : true}
                        className="leading-tight w-6 h-6 form-checkbox p-2 rounded bg-white text-newTeal-main focus:shadow-none focus:outline-none mr-2"
                    />
                    <p>Email Notifications</p>
                </label>

                <label className="flex flex-row items-center cursor-pointer select-none mt-3 ml-5">
                    <input
                        onChange={() => {
                            setDisabledNotifications({
                                ...disabledNotifications,
                                ...{
                                    dealNotification: disabledNotifications?.dealNotification ? false : true
                                }
                            });
                        }}
                        type="checkbox"
                        checked={disabledNotifications?.dealNotification ? false : true}
                        className="leading-tight w-6 h-6 form-checkbox p-2 rounded bg-white text-newTeal-main focus:shadow-none focus:outline-none mr-2"
                    />
                    <p>In-platform Notifications</p>
                </label>
            </div>
            <div className="flex flex-col mt-5">
                <div className="flex flex-col">
                    <h3 className="font-semibold text-l">Approvals</h3>
                    <p>
                        Approval orientated notifications. This includes notifications triggered by an approval request,
                        rejection or acceptance.
                    </p>
                </div>

                <label className="flex flex-row items-center cursor-pointer select-none mt-3 ml-5">
                    <input
                        onChange={() => {
                            setDisabledNotifications({
                                ...disabledNotifications,
                                ...{
                                    approvalEmail: disabledNotifications?.approvalEmail ? false : true
                                }
                            });
                        }}
                        type="checkbox"
                        checked={disabledNotifications?.approvalEmail ? false : true}
                        className="leading-tight w-6 h-6 form-checkbox p-2 rounded bg-white text-newTeal-main focus:shadow-none focus:outline-none mr-2"
                    />
                    <p>Email Notifications</p>
                </label>

                <label className="flex flex-row items-center cursor-pointer select-none mt-3 ml-5">
                    <input
                        onChange={() => {
                            setDisabledNotifications({
                                ...disabledNotifications,
                                ...{
                                    approvalNotification: disabledNotifications?.approvalNotification ? false : true
                                }
                            });
                        }}
                        type="checkbox"
                        checked={disabledNotifications?.approvalNotification ? false : true}
                        className="leading-tight w-6 h-6 form-checkbox p-2 rounded bg-white text-newTeal-main focus:shadow-none focus:outline-none mr-2"
                    />
                    <p>In-platform Notifications</p>
                </label>
            </div>

            <div className="flex flex-col mt-5">
                <div className="flex flex-col">
                    <h3 className="font-semibold text-l">Views</h3>
                    <p>
                        View orientated notifications. This includes notifications triggered by an individual viewing a
                        deal after a change in sides, for example.
                    </p>
                </div>

                <label className="flex flex-row items-center cursor-pointer select-none mt-3 ml-5">
                    <input
                        onChange={() => {
                            setDisabledNotifications({
                                ...disabledNotifications,
                                ...{
                                    seenEmail: disabledNotifications?.seenEmail ? false : true
                                }
                            });
                        }}
                        type="checkbox"
                        checked={disabledNotifications?.seenEmail ? false : true}
                        className="leading-tight w-6 h-6 form-checkbox p-2 rounded bg-white text-newTeal-main focus:shadow-none focus:outline-none mr-2"
                    />
                    <p>Email Notifications</p>
                </label>

                <label className="flex flex-row items-center cursor-pointer select-none mt-3 ml-5">
                    <input
                        onChange={() => {
                            setDisabledNotifications({
                                ...disabledNotifications,
                                ...{
                                    seenNotification: disabledNotifications?.seenNotification ? false : true
                                }
                            });
                        }}
                        type="checkbox"
                        checked={disabledNotifications?.seenNotification ? false : true}
                        className="leading-tight w-6 h-6 form-checkbox p-2 rounded bg-white text-newTeal-main focus:shadow-none focus:outline-none mr-2"
                    />
                    <p>In-platform Notifications</p>
                </label>
            </div>

            {userType && userType !== UserType.Tenant && (
                <div className="flex flex-col mt-5">
                    <div className="flex flex-col">
                        <h3 className="font-semibold text-l">Units</h3>
                        <p>Notifications relating to Unit task completion and related.</p>
                    </div>

                    <label className="flex flex-row items-center cursor-pointer select-none mt-3 ml-5">
                        <input
                            onChange={() => {
                                setDisabledNotifications({
                                    ...disabledNotifications,
                                    ...{
                                        unitEmail: disabledNotifications?.unitEmail ? false : true
                                    }
                                });
                            }}
                            type="checkbox"
                            checked={disabledNotifications?.unitEmail ? false : true}
                            className="leading-tight w-6 h-6 form-checkbox p-2 rounded bg-white text-newTeal-main focus:shadow-none focus:outline-none mr-2"
                        />
                        <p>Email Notifications</p>
                    </label>

                    <label className="flex flex-row items-center cursor-pointer select-none mt-3 ml-5">
                        <input
                            onChange={() => {
                                setDisabledNotifications({
                                    ...disabledNotifications,
                                    ...{
                                        unitNotification: disabledNotifications?.unitNotification ? false : true
                                    }
                                });
                            }}
                            type="checkbox"
                            checked={disabledNotifications?.unitNotification ? false : true}
                            className="leading-tight w-6 h-6 form-checkbox p-2 rounded bg-white text-newTeal-main focus:shadow-none focus:outline-none mr-2"
                        />
                        <p>In-platform Notifications</p>
                    </label>
                </div>
            )}

            {showSave && (
                <div className="flex flex-row w-full justify-end">
                    <Button
                        onClick={() => {
                            handleSave();
                        }}
                        background="newTeal-main"
                        text="white"
                        className="px-4 w-20 font-semibold mt-8"
                    >
                        {disableNotificationsLoading ? 'Saving' : 'Save'}
                    </Button>
                </div>
            )}
        </div>
    );
};

export default Notifications;
