import { isEmpty } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import ReactTooltip from 'react-tooltip';

import BulkAddUsersWindow from './BulkAddUsersWindow';
import NewUnitWindow from './NewUnitWindow';
import NoUnits from './NoUnits';
import SearchUnitCards from './SearchUnitCards';
import UnitCards from './UnitCards';
import UnitsHeader from './UnitsHeader';
import {
    OrderBy,
    SingleUnitCardFragment,
    UnitAvailability,
    UnitCondition,
    UnitSortFields,
    UnitUse,
    useBulkUnitUpdateMutation,
    useExportUnitsLazyQuery,
    useGetUnitBuildingNamesQuery,
    useGetUnitCardDetailsQuery,
    useGetUnitCitiesQuery,
    useGetUnitCreatorQuery,
    useGetUnitOrganisationsQuery,
    useGetUserProfileQuery,
    useGetUserTypeQuery,
    UserType,
    useUnitFilterCountsQuery
} from '../../generated/graphql';
import { BulkSelectContext } from '../../shared/bulk/context';
import { FilterContext } from '../../shared/Filter/context';
import { FilterConfig, FilterType, FilterWindow } from '../../shared/Filter/FilterWindow';
import {
    transformFilters,
    transformToFilterUrlParams,
    transformToSortUrlParams,
    useUpdateUrl
} from '../../shared/Filter/helpers';
import { debounce, downloadFile, handleFetchError } from '../../shared/helpers';
import useMobileScreen from '../../shared/hooks/useMobileScreen';
import { RadioValue } from '../../shared/Inputs/Radio';
import Loading from '../../shared/Loading';

type UnitProps = {
    setHeaderContent: React.Dispatch<React.SetStateAction<React.ReactChild | null>>;
};

type UnitType = SingleUnitCardFragment;

const Units = ({ setHeaderContent }: UnitProps) => {
    const {
        saveSelectMode,
        selectAll,
        saveSelectAll,
        selectedItems,
        checkIsAllUnits,
        saveSelectedItems,
        all,
        saveAll
    } = useContext(BulkSelectContext);

    const limit = 9;
    const [unitAvailability, setUnitAvailability] = useState<UnitAvailability>(UnitAvailability.AvailableToLet);
    const [hasNextPage, setHasNextPage] = useState<boolean>(true);
    const { values, isWindowOpen, sort, filters } = useContext(FilterContext);

    const [isSearching, setIsSearching] = useState<string | undefined>('');
    const isMobile = useMobileScreen();
    const {
        data: countsData,
        loading: countsLoading,
        refetch: countsRefetch
    } = useUnitFilterCountsQuery({
        fetchPolicy: 'cache-and-network',
        variables: {
            filter: {
                available: unitAvailability,
                ...transformFilters(filters, values)
            },
            query: isSearching
        }
    });

    const { loading, error, data, refetch, fetchMore } = useGetUnitCardDetailsQuery({
        fetchPolicy: 'cache-and-network',
        variables: {
            limit,
            offset: 0,
            sort: sort
                ? {
                      field: sort.field as UnitSortFields,
                      order: sort.order ?? OrderBy.Desc
                  }
                : undefined,
            filter: {
                available: unitAvailability,
                ...transformFilters(filters, values)
            },
            query: isSearching
        }
    });

    const { updateUrl } = useUpdateUrl();
    useEffect(() => {
        const sortParams = sort ? transformToSortUrlParams(sort) : {};
        const filterParams = !_.isEmpty(values) ? transformToFilterUrlParams(filters, values) : {};

        updateUrl({
            ...filterParams,
            ...sortParams
        });
    }, [values, sort]);

    const [units, setUnits] = useState<UnitType[]>([]);

    const [bulkUnitUpdate, { loading: bulkUnitUpdateLoading }] = useBulkUnitUpdateMutation();

    const [showNewUnitWindow, setShowNewUnitWindow] = useState<boolean>(false);
    const [showBulkAddUserWindow, setShowBulkAddUserWindow] = useState<boolean>(false);

    const { data: userProfileData } = useGetUserProfileQuery();

    const isLandlordOrAdmin =
        userProfileData?.user.type &&
        [UserType.Admin, UserType.Landlord, UserType.Agent].includes(userProfileData?.user.type);

    useEffect(() => {
        setHeaderContent(<h1 className="text-xl font-bold p-2">Spaces</h1>);
    }, [setHeaderContent]);

    useEffect(() => {
        checkIsAllUnits(units);
    }, [selectAll]);

    useEffect(() => {
        saveSelectMode(false);
        saveSelectAll(false);
    }, [unitAvailability]);

    useEffect(() => {
        setHasNextPage(true);
    }, []);

    useEffect(() => {
        // Check for errors
        if (error) return handleFetchError('Could not load your demises', error, data?.units);
        if (isEmpty(data?.units)) {
            setUnits([]);
        }
        setUnits(data?.units.results ?? []);
    }, [data, error]);

    useEffect(() => {
        data?.units && saveAll(data?.units.results.length);
    }, [selectedItems.length]);

    // User Type Check
    const { data: typeData, loading: typeLoading, error: typeError } = useGetUserTypeQuery();
    useEffect(() => {
        // Check for errors
        handleFetchError('Could not load user', typeError, typeData?.user);
    }, [typeData, typeError]);

    const searchSpaces = debounce((query?: string) => {
        setIsSearching(query);
        refetch({ query });
        countsRefetch({ query });
    }, 250);

    const handleBulkClick = (id: string) => {
        if (id === 'addUser' && selectedItems.length > 0) {
            setShowBulkAddUserWindow(true);
            saveSelectMode(true);
        } else if (id === 'availability' && selectedItems.length > 0) {
            handleAvailabilityClick();
            saveSelectMode(true);
        }
    };

    const handleAvailabilityClick = async () => {
        await bulkUnitUpdate({
            variables: {
                unitIDs: all ? [] : selectedItems,
                all,
                filter: all
                    ? {
                          ...transformFilters(filters, values)
                      }
                    : {},
                available:
                    unitAvailability === UnitAvailability.AvailableToLet
                        ? UnitAvailability.UnavailableToLet
                        : UnitAvailability.AvailableToLet
            },
            onCompleted: () => {
                refetch();
                countsRefetch();
            }
        });
        saveSelectedItems([]);
        saveSelectAll(false);
        saveSelectMode(false);
    };

    const [exportUnits] = useExportUnitsLazyQuery({
        fetchPolicy: 'no-cache'
    });

    const handleExportUnits = async () => {
        const { data } = await exportUnits();

        downloadFile(data?.exportUnits.url!, `Kato - ${typeData?.user.organisation.name}, Space Export.csv`);
    };

    const loadMore = async () => {
        if (!hasNextPage) return;

        const { data: newData } = await fetchMore({
            variables: {
                limit,
                offset: units?.length ?? 0,
                filter: {
                    ...transformFilters(filters, values),
                    available: unitAvailability
                }
            }
        });

        setUnits([...units!, ...newData.units.results]);
        setHasNextPage(newData?.units.pageInfo.hasNextPage);
    };

    const isAvailableToLetFilter = unitAvailability === UnitAvailability.AvailableToLet;

    const isLoading = loading || typeLoading || countsLoading || bulkUnitUpdateLoading;

    const unitFilterCounts = countsData?.unitFilterCounts;

    return (
        <>
            {showBulkAddUserWindow && <BulkAddUsersWindow units={units} setShow={setShowBulkAddUserWindow} />}

            {showNewUnitWindow && (
                <NewUnitWindow setShow={setShowNewUnitWindow} refetch={refetch} countsRefetch={countsRefetch} />
            )}
            {data?.units?.pageInfo?.hasResults ||
            (unitFilterCounts?.availableToLet || unitFilterCounts?.unavailableToLet) !== 0 ? (
                <div className="md:px-32 mx-auto p-4">
                    <UnitsHeader
                        isSearching={isSearching}
                        isMobile={isMobile}
                        isAvailableToLetFilter={isAvailableToLetFilter}
                        searchSpaces={searchSpaces}
                        user={typeData?.user}
                        setUnitAvailability={setUnitAvailability}
                        setShowNewUnitWindow={setShowNewUnitWindow}
                        unitFilterCounts={countsData?.unitFilterCounts}
                        isLandlordOrAdmin={isLandlordOrAdmin}
                        getUnitFields={getUnitFields}
                        units={units}
                        unitAvailability={unitAvailability}
                        handleBulkClick={handleBulkClick}
                        handleExportUnits={handleExportUnits}
                    />

                    {isSearching && <div className="border-t border-newGray-main z-20 w-100" />}

                    {isLoading ? (
                        <Loading className="mt-52" />
                    ) : units.length > 0 && isSearching ? (
                        <SearchUnitCards
                            user={typeData?.user}
                            unitFilterCounts={countsData?.unitFilterCounts}
                            units={units}
                            refetch={refetch}
                            countsRefetch={countsRefetch}
                            query={isSearching}
                        />
                    ) : (
                        <InfiniteScroll
                            loadMore={loadMore}
                            useWindow={false}
                            getScrollParent={() => document.getElementById('layout-content')}
                            hasMore={hasNextPage}
                            loader={<div className="container mx-auto p-4">{loading && <Loading />}</div>}
                        >
                            <UnitCards
                                user={typeData?.user}
                                units={units}
                                refetch={refetch}
                                countsRefetch={countsRefetch}
                            />
                        </InfiniteScroll>
                    )}
                    {isWindowOpen && typeData?.user && (
                        <FilterWindow config={unitFilterConfig(typeData.user.type)} sortOptions={getUnitFields()} />
                    )}
                    <ReactTooltip
                        backgroundColor="black"
                        textColor="white"
                        effect="solid"
                        place="left"
                        clickable={false}
                        borderColor="white"
                        id="space"
                    />
                </div>
            ) : (
                <NoUnits setShowNewUnitWindow={setShowNewUnitWindow} />
            )}
        </>
    );
};

const USE_OPTIONS = [
    { label: 'Industrial', value: UnitUse.Industrial },
    { label: 'Medical', value: UnitUse.Medical },
    { label: 'Office', value: UnitUse.Office },
    { label: 'Office Flex', value: UnitUse.OfficeFlex },
    { label: 'Retail High Street', value: UnitUse.RetailHighStreet },
    { label: 'Retail Pop Up', value: UnitUse.RetailPopUp },
    { label: 'Retail Shopping Centre', value: UnitUse.RetailShoppingCentre },
    { label: 'Arch', value: UnitUse.Arch },
    { label: 'F&B', value: UnitUse.Fb },
    { label: 'Managed', value: UnitUse.Managed }
];

const CONDITION_OPTIONS = [
    { label: 'Category A', value: UnitCondition.CategoryA },
    { label: 'Category A plus', value: UnitCondition.CategoryAplus },
    { label: 'Category B', value: UnitCondition.CategoryB },
    { label: 'Existing Fit Out', value: UnitCondition.ExistingFitOut },
    { label: 'Flex', value: UnitCondition.Flex },
    { label: 'Plug & Play', value: UnitCondition.PlugAndPlay },
    { label: 'Ready', value: UnitCondition.Ready },
    { label: 'Shell and Core', value: UnitCondition.ShellAndCore },
    { label: 'Development', value: UnitCondition.Development },
    { label: 'Warm Shell', value: UnitCondition.WarmShell },
    { label: 'White-Box', value: UnitCondition.WhiteBox }
];

export const unitFilterConfig = (userType?: UserType): FilterConfig => [
    {
        id: 'buildingName',
        label: 'Building Name',
        type: FilterType.AsyncList,
        query: useGetUnitBuildingNamesQuery,
        path: '[$distinct(units.results.{ "value": buildingName, "label": buildingName })]'
    },
    {
        id: 'city',
        label: 'City',
        type: FilterType.AsyncList,
        query: useGetUnitCitiesQuery,
        path: '[$distinct(units.results.{ "value": address.city, "label": address.city })]'
    },
    {
        id: 'area',
        label: 'Demise Area',
        type: FilterType.Range,
        suffix: 'sq ft'
    },
    {
        id: 'use',
        label: 'Use',
        type: FilterType.List,
        options: USE_OPTIONS
    },
    {
        id: 'condition',
        label: 'Condition',
        type: FilterType.List,
        options: CONDITION_OPTIONS
    },
    ...(userType && userType === UserType.Agent
        ? [
              {
                  id: 'client',
                  label: 'Client',
                  type: FilterType.AsyncList,
                  query: useGetUnitOrganisationsQuery,
                  path: '[$distinct(units.results.{ "value": organisation._id, "label": organisation.name })]'
              }
          ]
        : []),
    {
        id: 'creator',
        label: 'Creator',
        type: FilterType.AsyncList,
        query: useGetUnitCreatorQuery,
        path: '[$distinct(units.results[creator != null].{ "value": creator._id, "label": creator.firstName & " " & creator.lastName })]'
    },
    {
        id: 'created',
        label: 'Date Created',
        type: FilterType.RangeDate
    }
];

export const getUnitFields = (): RadioValue[] => [
    {
        id: UnitSortFields.BuildingName,
        label: 'Building Name'
    },
    {
        id: UnitSortFields.Created,
        label: 'Date Created'
    }
];

export default Units;
