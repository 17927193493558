import { useEffect } from 'react';
import { useIntercom } from 'react-use-intercom';

import { GetUserProfileQuery } from '../../../../generated/graphql';

/**
 * Update intercom with user's details
 * @param data User details
 */
export const useUpdateIntercom = (data: GetUserProfileQuery | undefined) => {
    const { update } = useIntercom();

    useEffect(() => {
        if (!data?.user) return;
        const userId = data.user._id ?? '';
        const name = `${data.user.firstName ?? ''} ${data.user.lastName ?? ''}`;
        const email = data.user.email ?? '';
        const phone = data.user.mobile ?? '';
        const userHash = data.user.hash ?? '';

        update({
            userId,
            name,
            email,
            phone,
            userHash
        });
    }, [data, update]);
};
