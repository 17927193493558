import { BlobProvider, Document, Font, Image, Link, Page, pdf, StyleSheet, Text, View } from '@react-pdf/renderer';
import React from 'react';
import { useState } from 'react';
import ReactDOM from 'react-dom';

import Logo from '../../../assets/images/logo/blue.png';
import { Deal, DealDetailsFragment, useGetDealDetailsLazyQuery } from '../../../generated/graphql';
import { DealUnit } from '../../../pages/Deals/Deal/Preview/PreviewContent';
import { formatClause, getLandlordContactInfo, handleFetchError, proxyImage } from '../../helpers';

const useDownloadPDF = (): [(dealID: string) => Promise<void | Blob | null>, boolean] => {
    const [loading, setLoading] = useState<boolean>(false);
    const [getDetails] = useGetDealDetailsLazyQuery();

    return [
        async (dealID: string) => {
            setLoading(true);

            // Get full deal data from API
            const { data } = await getDetails({
                variables: {
                    dealID
                }
            });

            if (data?.deal.__typename !== 'Deal')
                return handleFetchError('Could not load the deal', undefined, data?.deal);

            const a = document.createElement('a');

            ReactDOM.render(
                <BlobProvider document={<DealDocument deal={data.deal} unit={data.deal.unit!} />}>
                    {({ url }) => {
                        if (data.deal.__typename !== 'Deal' || !url) return null;

                        // Create a hidden <a> tag to download
                        const a = document.createElement('a');
                        /// Point <a> href to blob URL
                        a.href = url ?? '';
                        /// Set file name to the unit's name, or address, or the deal ID
                        a.download = `${
                            data.deal.unit?.buildingName ?? data.deal.unit?.address.line1 ?? data.deal._id
                        }.pdf`;

                        // Release object URL to free up RAM after download
                        const clickHandler = () => {
                            setTimeout(() => {
                                URL.revokeObjectURL(url ?? '');
                                a.removeEventListener('click', clickHandler);
                            }, 150);
                        };

                        a.addEventListener('click', clickHandler, false);

                        // Programatically click the <a> element to trigger download
                        a.click();

                        return <div>Loading</div>;
                    }}
                </BlobProvider>,
                a
            );

            setTimeout(() => setLoading(false), 150);
        },
        loading
    ];
};

export const getPDFBlob = async (deal: Deal) => {
    const doc = <DealDocument deal={deal} unit={deal.unit!} />;

    return pdf(doc).toBlob();
};

const DealDocument = ({ deal, unit }: { deal?: DealDetailsFragment; unit?: DealUnit }) => {
    Font.register({
        family: 'Avenir Next',
        fonts: [
            {
                src: 'https://least-media.s3.eu-west-2.amazonaws.com/fonts/AvenirNext-Regular.woff'
            },
            {
                src: 'https://least-media.s3.eu-west-2.amazonaws.com/fonts/AvenirNext-Medium.woff',
                fontWeight: 500
            },
            {
                src: 'https://least-media.s3.eu-west-2.amazonaws.com/fonts/AvenirNext-Demi.woff',
                fontWeight: 600
            }
        ]
    });

    const styles = StyleSheet.create({
        page: {
            position: 'relative',
            marginLeft: 'auto',
            marginRight: 'auto',
            backgroundColor: 'white',
            display: 'flex',
            flexDirection: 'column',
            fontFamily: 'Avenir Next',
            lineHeight: '1.5',
            fontSize: '11pt',
            paddingTop: '38pt',
            paddingBottom: '65pt'
        },
        section: {
            margin: '12pt'
        },
        container: {
            padding: '0 38pt'
        },
        header: {
            display: 'flex',
            flexDirection: 'column',
            padding: '0.5rem',
            fontSize: '10pt'
        },
        line: {
            borderBottom: '1pt solid #3CA48E',
            marginTop: '16pt',
            marginBottom: '16pt',
            width: '100%',
            height: '1pt'
        },
        avatarContainer: {
            backgroundColor: '#ededed',
            padding: '6pt',
            borderRadius: '3pt'
        },
        avatar: {
            objectFit: 'cover',
            borderRadius: '3pt',
            width: '90pt',
            height: '90pt'
        },
        partiesTitle: {
            fontSize: '12pt',
            fontWeight: 600,
            marginTop: '10px'
        }
    });

    const stripHtml = (str: string) => {
        let tmp = document.createElement('DIV');
        tmp.innerHTML = str;
        return tmp.textContent || tmp.innerText || '';
    };

    const contactInfo = getLandlordContactInfo(unit?.tasks || undefined);
    return (
        <Document>
            <Page size="A4" style={styles.page} wrap>
                {/* Main Container */}
                <View style={styles.container}>
                    {/* Header */}
                    <View style={styles.header}>
                        <Text style={{ fontWeight: 600, fontSize: '18pt' }}>Heads of Terms</Text>
                        <Text style={{ marginTop: '-0.25rem' }}>Subject to Contract</Text>
                        <Text style={{ marginTop: '-0.25rem' }}>Strictly Private and Confidential</Text>
                    </View>

                    <View key="separator-1" style={styles.line} />

                    <View
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            width: '100%'
                        }}
                    >
                        <View style={{ fontWeight: 500 }}>
                            <Text>{unit?.name}</Text>
                            {unit?.buildingName && unit.buildingName !== unit.address.line1 && (
                                <Text>{unit?.buildingName}</Text>
                            )}
                            <Text>{unit?.address.line1}</Text>
                            <Text>{unit?.address.line2}</Text>
                            <Text>{unit?.address.city}</Text>
                            <Text>{unit?.address.postCode}</Text>

                            {unit?.url !== null && (
                                <View>
                                    <Link
                                        style={{
                                            marginTop: 7,
                                            fontWeight: 400
                                        }}
                                        src={unit?.url ?? ''}
                                    >
                                        {unit?.url ?? ''}
                                    </Link>
                                </View>
                            )}

                            <Text style={{ marginTop: 10, fontWeight: 400 }}>{new Date().toLocaleDateString()}</Text>
                        </View>
                        <View
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                alignItems: 'center'
                            }}
                        >
                            {unit?.avatar && unit?.avatar.length > 0 && (
                                <View style={[styles.avatarContainer, { marginRight: '32pt' }]}>
                                    <Image src={proxyImage(unit.avatar, 200, 200, 100)} style={styles.avatar} />
                                </View>
                            )}
                            {unit?.organisation.avatar && unit?.organisation.avatar.length > 0 && (
                                <View style={styles.avatarContainer}>
                                    <Image
                                        src={proxyImage(unit.organisation.avatar, 200, 200, 100)}
                                        style={styles.avatar}
                                    />
                                </View>
                            )}
                        </View>
                    </View>

                    <View key="separator-2" style={styles.line} />

                    <View key="parties-section" style={styles.section}>
                        <Text
                            style={{
                                fontWeight: 600,
                                fontSize: '13pt'
                            }}
                        >
                            1. The Parties
                        </Text>
                        <View
                            style={{
                                display: 'flex',
                                flexDirection: 'row'
                            }}
                        >
                            <View style={{ width: '50%' }}>
                                <Text style={{ fontWeight: 500 }}>{unit?.organisation.name}</Text>
                                <Text>{unit?.organisation.address?.line1}</Text>
                                <Text>{unit?.organisation.address?.line2}</Text>
                                <Text>{unit?.organisation.address?.city}</Text>
                                <Text>{unit?.organisation.address?.postCode}</Text>
                                <Text>{unit?.organisation.website}</Text>
                            </View>
                            <View>
                                <Text style={{ fontWeight: 500 }}>{deal?.tenant?.name}</Text>
                                <Text>{deal?.details?.one?.entity?.address?.line1}</Text>
                                <Text>{deal?.details?.one?.entity?.address?.line2}</Text>
                                <Text>{deal?.details?.one?.entity?.address?.city}</Text>
                                <Text>{deal?.details?.one?.entity?.address?.postCode}</Text>
                            </View>
                        </View>
                    </View>

                    <View key="separator-3" style={styles.line} />

                    {/* Begin Sections */}
                    {deal?.sections?.map((section, sectionIndex) => (
                        <View key={section._id} wrap style={styles.section}>
                            <View>
                                <Text
                                    style={{
                                        fontWeight: 600,
                                        fontSize: '13pt'
                                    }}
                                >{`${sectionIndex + 2}. ${section.title}`}</Text>
                                <View style={{ marginLeft: '20pt' }}>
                                    {section.groups?.map((group) => (
                                        <React.Fragment key={group._id}>
                                            <Text
                                                style={{
                                                    fontWeight: 600
                                                }}
                                            >
                                                {group.title}
                                            </Text>
                                            {group.clauses?.map((clause) =>
                                                clause.visible ? (
                                                    <Text
                                                        key={clause._id}
                                                        style={{
                                                            fontSize: '10pt'
                                                        }}
                                                    >
                                                        {formatClause(
                                                            clause.longForm
                                                                ? stripHtml(clause.longForm).replace(
                                                                      /\p{Extended_Pictographic}/u,
                                                                      ''
                                                                  )
                                                                : '',
                                                            clause.value
                                                                ? stripHtml(clause.value).replace(
                                                                      /\p{Extended_Pictographic}/u,
                                                                      ''
                                                                  )
                                                                : '',
                                                            clause.valueSuffix
                                                                ? stripHtml(clause.valueSuffix).replace(
                                                                      /\p{Extended_Pictographic}/u,
                                                                      ''
                                                                  )
                                                                : '',
                                                            clause.tags ?? [],
                                                            false,
                                                            true
                                                        )}
                                                    </Text>
                                                ) : (
                                                    clause.groups?.map((level2Group) => (
                                                        <View
                                                            style={{
                                                                marginLeft: '12pt'
                                                            }}
                                                            key={level2Group._id}
                                                        >
                                                            <Text
                                                                style={{
                                                                    fontWeight: 600,
                                                                    fontSize: '11pt',
                                                                    marginTop: '5pt'
                                                                }}
                                                            >
                                                                {level2Group.title}
                                                            </Text>
                                                            {level2Group.clauses?.map((clause) => (
                                                                <Text
                                                                    key={clause._id}
                                                                    style={{
                                                                        fontSize: '10pt'
                                                                    }}
                                                                >
                                                                    {formatClause(
                                                                        clause.longForm
                                                                            ? stripHtml(clause.longForm).replace(
                                                                                  /\p{Extended_Pictographic}/u,
                                                                                  ''
                                                                              )
                                                                            : '',
                                                                        clause.value
                                                                            ? stripHtml(clause.value).replace(
                                                                                  /\p{Extended_Pictographic}/u,
                                                                                  ''
                                                                              )
                                                                            : '',
                                                                        clause.valueSuffix
                                                                            ? stripHtml(clause.valueSuffix).replace(
                                                                                  /\p{Extended_Pictographic}/u,
                                                                                  ''
                                                                              )
                                                                            : '',
                                                                        clause.tags ?? [],
                                                                        false,
                                                                        true
                                                                    )}
                                                                </Text>
                                                            ))}
                                                        </View>
                                                    ))
                                                )
                                            )}
                                        </React.Fragment>
                                    ))}
                                </View>
                            </View>
                            <View key="separator-4" style={styles.line} />
                        </View>
                    ))}

                    <View key="contacts-section" wrap={false} style={styles.section}>
                        <View>
                            <Text
                                style={{
                                    fontWeight: 600,
                                    fontSize: '13pt'
                                }}
                            >
                                {(deal?.sections?.length || 1) + 2}. Contacts
                            </Text>
                            <View
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row'
                                }}
                            >
                                <View style={{ width: '50%' }}>
                                    <Text style={styles.partiesTitle}>Landlord's Legal Contact</Text>
                                    <Text>{contactInfo.lawyer.name}</Text>
                                    <Text>{contactInfo.lawyer.company}</Text>
                                    <Text>{contactInfo.lawyer.email}</Text>
                                    <Text>{contactInfo.lawyer.phone}</Text>
                                </View>
                                <View>
                                    <Text style={styles.partiesTitle}>Tenant's Legal Contact</Text>
                                    <Text>{deal?.details?.two?.lawyer?.name}</Text>
                                    <Text>{deal?.details?.two?.lawyer?.company}</Text>
                                    <Text>{deal?.details?.two?.lawyer?.email}</Text>
                                    <Text>{deal?.details?.two?.lawyer?.phone}</Text>
                                </View>
                            </View>
                            <View
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row'
                                }}
                            >
                                <View style={{ width: '50%' }}>
                                    <Text style={styles.partiesTitle}>Landlord's Deal Contact</Text>
                                    <Text>{contactInfo.landlord.name}</Text>
                                    <Text>{contactInfo.landlord.email}</Text>
                                    <Text>{contactInfo.landlord.phone}</Text>
                                </View>

                                <View>
                                    <Text style={styles.partiesTitle}>Tenant's Deal Contact</Text>
                                    <Text>{deal?.details?.team?.tenant?.name}</Text>
                                    <Text>{deal?.details?.team?.tenant?.email}</Text>
                                    <Text>{deal?.details?.team?.tenant?.phone}</Text>
                                </View>
                            </View>
                        </View>
                    </View>
                </View>
                {/* Footer */}
                <View
                    key="footer-section"
                    fixed
                    style={{
                        position: 'absolute',
                        bottom: '0',
                        left: '0',
                        right: '0',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-end',
                        backgroundColor: '#e3e3e3',
                        padding: '25pt',
                        width: '100%',
                        marginLeft: 'auto',
                        marginRight: 'auto'
                    }}
                >
                    <Image
                        src={Logo}
                        style={{
                            height: '27pt',
                            width: '80pt'
                        }}
                    />
                </View>
            </Page>
        </Document>
    );
};

export default useDownloadPDF;
