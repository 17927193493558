import { Field } from 'formik';
import React, { ChangeEvent } from 'react';

import { InputField as FormikInputField } from '../../../../shared/Formik/InputField';
import { InputType } from '../../../../shared/Inputs';

type TextFieldProps = {
    name: string;
    label: string;
    type?: InputType;
    width?: string;
    required?: boolean;
    value?: string;
    onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
};

const InputField = ({ name, label, type = InputType.text, width, required, ...props }: TextFieldProps) => (
    <>
        <label className="font-medium text-xs mt-1">
            {required && <span className="text-newTeal-main text-xs">*</span>}
            {label}
        </label>
        <Field
            name={name}
            component={FormikInputField}
            containerStyle={`h-8 ${width ?? 'w-full'}`}
            className={`mb- bg-navy-lightest h-2 w-full text-xs rounded-r-2xl`}
            type={type}
            {...props}
        />
    </>
);

export default InputField;
