import { useApolloClient } from '@apollo/client';
import axios from 'axios';
import React, { useMemo, useRef, useState } from 'react';

import Dropzone from './Dropzone';
import {
    DocType,
    FilesValue,
    GetUnitRatingDocument,
    File as graphqlFile,
    useActionTaskMutation,
    useUnitTaskRemoveFileMutation,
    useUploadDocumentMutation
} from '../../generated/graphql';
import { Button } from '../button';
import { fileSize } from '../helpers';
import { ReactComponent as BinIcon } from '../icons/BinIcon.svg';
import { ReactComponent as CloseIcon } from '../icons/CloseIcon.svg';
import { ReactComponent as CloudIcon } from '../icons/CloudIcon.svg';
import Window from '../Window';

export interface PendingFilesState extends File {
    url?: string;
}

type FileUploadModalProps = {
    files: graphqlFile[];
    taskID: string;
    unitID: string;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
};

export const FileManageModal = ({ files, taskID, unitID, setShow }: FileUploadModalProps) => {
    const client = useApolloClient();

    const uploadRef = useRef<HTMLInputElement>(null);

    // States
    const [pendingFiles, setPendingFiles] = useState<PendingFilesState[]>([]);
    const [progress, setProgress] = useState<number[]>([]);
    const [errorMessage, setErrorMessage] = useState<string>();

    // Upload document mutation
    const [documentCreate] = useUploadDocumentMutation();

    // Add link mutations
    const [actionTask, { loading: actionTaskLoading }] = useActionTaskMutation();

    // Remove file mutation
    const [removeFile] = useUnitTaskRemoveFileMutation();

    // Generate signed URL and upload selected file
    const handleUploadFiles = async (newFiles?: FileList) => {
        setPendingFiles([...pendingFiles, ...(newFiles ?? [])]);
        if (!pendingFiles) return setErrorMessage('Please select a file');

        await Promise.all(
            [...pendingFiles, ...(newFiles ?? [])].map(async (file, index) => {
                setProgress((progress) => {
                    progress[index] = 0;
                    return progress;
                });

                const fileNameSplit = file.name.split('.');

                const fileType = file.type || fileNameSplit[fileNameSplit.length - 1];

                const res = await documentCreate({
                    variables: {
                        type: DocType.Unit,
                        filename: file.name,
                        filetype: fileType,
                        frontend: true,
                        id: unitID,
                        taskID
                    }
                });

                const documentUrl = res.data?.docCreate.url ?? '';
                const signedUrl = res.data?.docCreate.signedUrl ?? '';

                await axios.put(signedUrl, file, {
                    headers: {
                        'Content-Type': file.type,
                        'Access-Control-Allow-Origin': '*'
                    },
                    onUploadProgress: (progressEvent) => {
                        setProgress((progress) => {
                            return progress.map((value, i) =>
                                i === index ? Math.round((progressEvent.loaded * 100) / progressEvent.total) : value
                            );
                        });
                    }
                });

                setPendingFiles((prev) => {
                    if (!prev[index].url) {
                        prev[index].url = documentUrl;
                    }

                    return prev;
                });
            })
        );
    };

    // Set the task's file to a link
    const handleSubmit = async () => {
        const { data } = await actionTask({
            variables: {
                unitID,
                taskID,
                fileValue: pendingFiles.map(({ name, url }) => ({
                    name: name ?? url,
                    url: url ?? ''
                }))
            },
            refetchQueries: [
                {
                    query: GetUnitRatingDocument,
                    variables: {
                        unitID
                    }
                }
            ]
        });
        setPendingFiles([]);
        if (!data) return;
        // Successfully added the link to the task, now update the cache
        updateCache(data.unitTaskAction.value as FilesValue);
    };

    const updateCache = (value: FilesValue) => {
        client.cache.modify({
            id: client.cache.identify({
                __typename: 'UnitTask',
                _id: taskID
            }),
            fields: {
                value() {
                    return value;
                }
            }
        });
    };

    const handleDrop = async (newFiles?: FileList) => {
        handleUploadFiles(newFiles);
    };

    const handleRemoveFIle = async (fileID: string) => {
        const { data } = await removeFile({
            variables: {
                unitID,
                taskID,
                fileID
            }
        });

        updateCache(data?.unitTaskRemoveFile.value as FilesValue);
    };

    const savedFilesContent = useMemo(() => {
        if (!files.length) {
            return null;
        }

        return (
            <div className="mt-8">
                <div className="flex flex-col mb-4">
                    <span className="font-extrabold text-xl">Saved Files</span>
                    <span className="text-sm">Click file name to view</span>
                </div>
                {files.map((file) => {
                    return (
                        <div className="flex flex-row items-center justify-between">
                            <a
                                className="font-bold hover:text-newTeal-main"
                                href="#"
                                onClick={() => window.open(file.url)}
                            >
                                {file.name}
                            </a>
                            <BinIcon
                                className="cursor-pointer w-4 h-4 align-middle"
                                onClick={() => handleRemoveFIle(file._id)}
                            />
                        </div>
                    );
                })}
            </div>
        );
    }, [files]);

    return (
        <Window title="File Upload" width="w-3/5" setShow={setShow} underlined>
            <div className="p-4 w-full items-center justify-around flex-col">
                {/* Upload file */}
                <form className="flex flex-col flex-1 border-navy-light" onSubmit={(e) => e.preventDefault()}>
                    {/* File upload dropzone */}
                    <Dropzone callback={handleDrop}>
                        <div
                            className="flex flex-col items-center justify-center h-full cursor-pointer"
                            onClick={(e) => {
                                e.preventDefault();
                                uploadRef.current?.click();
                            }}
                        >
                            <CloudIcon
                                className="w-18 h-20 text-teal-400 stroke-current"
                                style={{
                                    stroke: '#4fd1c5',
                                    width: '50px',
                                    height: '50px'
                                }}
                            />
                            <p className="text-base text-black-700 font-bold text-center mt-2">
                                Click to Add or Drag files here
                            </p>
                        </div>
                    </Dropzone>

                    {/* Hidden input to get file selector */}
                    <input
                        type="file"
                        name="file"
                        className="hidden"
                        multiple
                        value={undefined}
                        ref={uploadRef}
                        onChange={(e) => e.target.files && handleDrop(e.target.files)}
                    />
                </form>

                {pendingFiles.length > 0 && (
                    <div className="max-h-full overflow-hidden overflow-y-auto">
                        {pendingFiles.map((file, index) => (
                            <div
                                key={index}
                                className="truncate flex flex-col w-full py-2 border-navy-lightest"
                                style={{ borderTopWidth: '1px' }}
                            >
                                <div className="flex flex-row justify-between items-center">
                                    <div className="flex flex-row items-center">
                                        <p className="text-black-700 font-bold">{file.name}</p>
                                        <p className="text-gray-600 text-sm ml-4">{fileSize(file.size)}</p>
                                    </div>

                                    <CloseIcon
                                        className="cursor-pointer w-4 h-4 inline"
                                        onClick={() =>
                                            setPendingFiles(
                                                pendingFiles.filter((_, selectedIndex) => index !== selectedIndex)
                                            )
                                        }
                                    />
                                </div>
                                {/* Progress bar */}
                                <div
                                    className={`w-full h-2 bg-navy-light overflow-hidden relative rounded-full my-1 ${
                                        progress.reduce((a, b) => a + b, 0) === 0 && 'opacity-50'
                                    }`}
                                >
                                    <span
                                        className="bg-teal-400 absolute h-full top-0 left-0 transition-all duration-100 rounded-full"
                                        style={{
                                            backgroundColor: '#4fd1c5',
                                            width: `${
                                                progress[index] === 0 || pendingFiles.length === 0 ? 0 : progress[index]
                                            }%`
                                        }}
                                    ></span>
                                </div>
                                <p className="text-gray-600 text-sm">{`${
                                    progress.reduce((a, b) => a + b, 0) === 0 || pendingFiles.length === 0
                                        ? 0
                                        : progress[index]
                                }%`}</p>
                            </div>
                        ))}
                        <div className="flex flex-row justify-end">
                            <Button
                                onClick={() => handleSubmit()}
                                className="p-3 px-4 bg-newTeal-main  text-white rounded"
                                style={{
                                    width: 'max-content',
                                    minWidth: 'max-content'
                                }}
                            >
                                {!actionTaskLoading ? 'Save' : 'Saving...'}
                            </Button>
                        </div>
                    </div>
                )}
                <div className="px-4">
                    {errorMessage && (
                        <div className="bg-red-200 border border-red-600 rounded py-2 px-4 my-4 mt-2">
                            <span className="text-center text-red-600">{errorMessage}</span>
                        </div>
                    )}
                </div>
                {savedFilesContent}
            </div>
        </Window>
    );
};
