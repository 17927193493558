import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import Emoji from '../../../../shared/Emoji';
import ReadinessBar from '../../../../shared/ReadinessBar';
import Window from '../../../../shared/Window';
import { ReadinessContext } from '../../../../v2/components/Tenant/Readiness/context';

type LowSendWindowProps = {
    rating?: number;
    stages?: number;
    dealComplete?: boolean;
    totalStages?: number;
    round?: number;
    id?: string;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
    shareNow?: () => void;
    setShowSendDeal?: React.Dispatch<React.SetStateAction<boolean>>;
};

const LowSendWindow = ({
    setShow,
    rating,
    id,
    stages,
    dealComplete,
    totalStages,
    shareNow,
    round,
    setShowSendDeal = () => {}
}: LowSendWindowProps) => {
    const history = useHistory();

    const [progress, setProgress] = useState<number>(0);

    const { setStage, firstIncompleteStage } = useContext(ReadinessContext);

    useEffect(() => {
        let calculatedProgress;
        if (rating !== undefined) {
            calculatedProgress = rating !== undefined ? (rating / 100) * 100 : 0;

            setProgress(calculatedProgress);
        } else if (stages !== undefined && totalStages) {
            calculatedProgress = stages !== undefined ? (stages / totalStages) * 100 : 0;
        }
        if (calculatedProgress) {
            setProgress(calculatedProgress);
        }
    }, [rating, stages, totalStages]);

    return (
        <Window
            title="Low Readiness Warning"
            width="w-2/5"
            setShow={(isShown: boolean) => {
                setShow(isShown);
                setShowSendDeal(false);
            }}
            titleCenter
            primaryBtn
            primaryBtnText="Improve Now"
            onPrimaryBtnClick={() => {
                if (!id) return;
                if (rating !== undefined) {
                    history.push(`/units/${id}`);
                } else {
                    if (firstIncompleteStage) {
                        setStage(firstIncompleteStage);
                    }
                    setShow(false);
                    setShowSendDeal(false);
                }
            }}
            secondaryBtn
            secondaryBtnText="Send Now"
            onSecondaryBtnClick={() => {
                if (shareNow) {
                    shareNow();
                    setShow(false);
                    setShowSendDeal(true);
                }
            }}
        >
            <div className="pt-4 pb-4">
                <p className="text-center text-sm text-newGray-darkish">
                    {rating === undefined ? 'Tenant' : 'Unit'} Readiness
                </p>
                <h1 className="font-bold text-center text-3xl mt-1 mb-3">
                    {totalStages ? ('Stage ' + stages === undefined ? 0 : stages) : '' + rating || 0}/
                    {totalStages ? totalStages : '100'}
                </h1>
                <div className="flex flex-col items-center">
                    {' '}
                    {progress !== undefined && (
                        <div className="w-3/5">
                            <ReadinessBar
                                rating={rating ? rating : stages}
                                total={rating ? 100 : totalStages}
                                rounded={true}
                            />
                        </div>
                    )}
                    <div className="w-2/3 mb-2 mt-5">
                        {rating === undefined ? (
                            <>
                                <p className="text-center text-sm text-newGray-darkish">Let's dance!</p>
                                <p className="text-center text-sm text-newGray-darkish mt-3">
                                    You are missing some key info at stage {stages || 1} which is critical to speeding
                                    things up.
                                </p>
                            </>
                        ) : dealComplete ? (
                            <>
                                <p className="text-center text-sm text-newGray-darkish">
                                    <Emoji symbol="⚠️" />
                                    You are about to agree terms on the deal and get the lawyers working. Your readiness
                                    is still not up to scratch, which will mean delays whilst in legals.
                                </p>
                                <p className="text-center text-sm text-newGray-darkish mt-3">
                                    <Emoji symbol="👇" /> Complete more tasks, boost your score and get your lawyers off
                                    to a flying start.
                                </p>
                            </>
                        ) : !round || round === 1 ? (
                            <>
                                <p className="text-center text-sm text-newGray-darkish my-3">
                                    Your Readiness Score is low... so jump into readiness <Emoji symbol="👇" /> to
                                    complete more tasks, boost your score, and speed up the process.
                                </p>
                                <p className="text-center text-sm text-newGray-darkish">
                                    The more information you can share with the Occupier at this stage the better and
                                    more streamlined the leasing process will be. <Emoji symbol="🚀" />
                                </p>
                            </>
                        ) : (
                            <>
                                <p className="text-center text-sm text-newGray-darkish">
                                    <Emoji symbol="⚠️" />
                                    You are now mid negotiation and your readiness is still low.
                                </p>
                                <p className="text-center text-sm text-newGray-darkish my-3">
                                    Your Readiness Score is low... so jump into readiness <Emoji symbol="👇" /> to
                                    complete more tasks, boost your score, and speed up the process.
                                </p>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </Window>
    );
};
export default LowSendWindow;
