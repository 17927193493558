import _ from 'lodash';
import React, { useContext, useEffect, useState } from 'react';

import DealActorList, { ListType } from './DealActorList';
import DealHeaderContext from './DealHeaderContext';
import { ReactComponent as Down } from '../../../../assets/images/chevron/down/black.svg';
import { ReactComponent as Up } from '../../../../assets/images/chevron/up/black.svg';
import { ReactComponent as DeleteIcon } from '../../../../assets/images/trash/black.svg';
import {
    AgreedBy,
    DealActorFragment,
    DealApprovalType,
    DealDetailsFragment,
    DealRoundType,
    DealState,
    DealUserParty,
    useGetUserOrganisationQuery,
    useGetUserProfileQuery,
    User
} from '../../../../generated/graphql';
import DealActorsAddMemberButton from '../../../../pages/Deals/Deal/Header/components/DealActors/DealActorsAddMemberButton';
import DealActorsWindows from '../../../../pages/Deals/Deal/Header/components/DealActors/DealActorsWindows';
import useMobileScreen from '../../../../shared/hooks/useMobileScreen';
import { isCompletedDeal } from '../../../common/helpers';

type DealActorsProps = {
    approval?: DealApprovalType | null;
    dealID: string;
    deal?: DealDetailsFragment;
    organisationID?: string;
    organisationName: string;
    type: 'Landlord' | 'Tenant';
    currentUserParty: DealUserParty;
    state?: DealState;
    users: DealActorFragment[];
    tenantPendingMembers?: string[];
    dealRound?: DealRoundType | null;
    showTenantPendingMembersWindow?: boolean;
    setShowTenantPendingMembersWindow?: React.Dispatch<React.SetStateAction<boolean>>;
    setShowingTenantPending?: React.Dispatch<React.SetStateAction<boolean>>;
    agreedBy?: AgreedBy | null;
};

const DealActors = ({
    dealID,
    deal,
    organisationID,
    organisationName,
    type,
    currentUserParty,
    users,
    tenantPendingMembers,
    showTenantPendingMembersWindow,
    setShowTenantPendingMembersWindow,
    setShowingTenantPending
}: DealActorsProps) => {
    const { data: typeData } = useGetUserProfileQuery();
    const { data } = useGetUserOrganisationQuery();

    const [isOpen, setOpen] = useState<boolean>(false);

    const [showAddUserWindow, setShowAddUserWindow] = useState<boolean>(false);
    const [showRemoveUserWindow, setShowRemoveUserWindow] = useState<boolean>(false);

    const [user, setUser] = useState<User | null>(null);
    const [showEmailUserWindow, setShowEmailUserWindow] = useState<boolean>(false);
    const [userEmail, setUserEmail] = useState<string | null>(null);
    const [showRemoveTenantWindow, setShowRemoveTenantWindow] = useState<boolean>(false);
    const [landlordArray, setLandlordArray] = useState<DealActorFragment[]>([]);
    const [tenantArray, setTenantArray] = useState<DealActorFragment[]>([]);
    const [agentArray, setAgentArray] = useState<DealActorFragment[]>([]);

    const { completedDeal } = useContext(DealHeaderContext);

    useEffect(() => {
        if (users) {
            const unique = [...new Set(users.map((user) => user._id))];
            const uniqueUsers = unique.map((id) => users.find((user) => user._id === id));

            const landlords = uniqueUsers.filter((user) => user?.type === 'LANDLORD' || user?.type === 'ADMIN');
            setLandlordArray(landlords as DealActorFragment[]);
            const tenant = uniqueUsers.filter((user) => user?.type === 'TENANT');
            setTenantArray(tenant as DealActorFragment[]);
            const agent = uniqueUsers.filter((user) => user?.type === 'AGENT');
            setAgentArray(agent as DealActorFragment[]);
        }
    }, [users]);

    const mobileScreen = useMobileScreen();

    const agentsIds = agentArray.map((user) => user._id);
    const membersIds = data?.user.organisation.members.map((member) => member._id);
    const sameOrganisation = _.intersection(membersIds, agentsIds);
    const differentOrganisation = _.difference(agentsIds, membersIds!);
    const agentsInSameOrganisation = agentArray.filter((user) => sameOrganisation.find((id) => user._id === id));
    const agentsNotInSameOrganisation = agentArray.filter((user) =>
        differentOrganisation.find((id) => user._id === id)
    );

    const showRemoveTenant =
        currentUserParty === DealUserParty.Landlord && type === 'Tenant' && !isCompletedDeal(deal?.state);

    return (
        <>
            <DealActorsWindows
                dealID={dealID}
                deal={deal}
                organisationID={organisationID}
                userID={user?._id ?? ''}
                activated={!!user?.activated}
                type={type}
                userEmail={userEmail}
                currentUserParty={currentUserParty}
                tenantPendingMembers={tenantPendingMembers ?? []}
                setUserEmail={setUserEmail}
                showAddUserWindow={showAddUserWindow}
                setShowAddUserWindow={setShowAddUserWindow}
                showRemoveUserWindow={showRemoveUserWindow}
                setShowRemoveUserWindow={setShowRemoveUserWindow}
                showEmailUserWindow={showEmailUserWindow}
                setShowEmailUserWindow={setShowEmailUserWindow}
                showRemoveTenantWindow={showRemoveTenantWindow}
                setShowRemoveTenantWindow={setShowRemoveTenantWindow}
                showTenantPendingMembersWindow={showTenantPendingMembersWindow}
                setShowTenantPendingMembersWindow={setShowTenantPendingMembersWindow}
                setShowingTenantPending={setShowingTenantPending}
                agentArray={agentArray}
            />
            <div className="relative  w-1/2" style={{ width: '49%' }}>
                <div
                    onClick={() => setOpen(!isOpen)}
                    className={`px-2 pl-4 mr-2 h-14 border w-full actor-list-shadow flex flex-row items-center justify-between rounded-lg truncate ${
                        isOpen ? 'bg-navy-light' : 'bg-navy-lightest'
                    }`}
                >
                    <div className="flex flex-col justify-between w-full truncate text-sm pl-2">
                        <span className="font-semibold">{type}</span>
                        <div className="flex flex-row space-x-2">
                            <span className="md:hidden text-09 xl:flex font-light">{organisationName}</span>
                        </div>
                    </div>

                    {isOpen ? <Up className="w-6 h-6 ml-2" /> : <Down className="w-6 h-6 ml-2" />}
                </div>
                {isOpen && (
                    <>
                        <div
                            className="fixed w-full h-full z-10 left-0 top-0"
                            onClick={() => {
                                setOpen(false);
                            }}
                        />
                        <div
                            className="absolute bg-white rounded-lg  shadow-lg  z-40 md:-right-1 lg:left-0  md:mr-2 mr-40 md:mt-1 mt-5 flex flex-col text-sm max-h-96 overflow-hidden overflow-y-auto"
                            style={{
                                width: mobileScreen ? 'calc(100vw - 14px)' : '350px'
                            }}
                        >
                            {landlordArray && type === 'Landlord' && (
                                <>
                                    <DealActorList
                                        uniqueUsers={landlordArray}
                                        currentParty={currentUserParty}
                                        type={type}
                                        setShowRemoveUserWindow={setShowRemoveUserWindow}
                                        setUser={setUser}
                                        setShowEmailUserWindow={setShowEmailUserWindow}
                                        listType={ListType.Landlord}
                                    />

                                    {typeData?.user.type === 'AGENT' && currentUserParty === 'LANDLORD' ? (
                                        <>
                                            {!_.isEmpty(agentsInSameOrganisation) && (
                                                <DealActorList
                                                    uniqueUsers={agentsInSameOrganisation}
                                                    currentParty={currentUserParty}
                                                    type={type}
                                                    setShowRemoveUserWindow={setShowRemoveUserWindow}
                                                    setUser={setUser}
                                                    setShowEmailUserWindow={setShowEmailUserWindow}
                                                    listType={ListType.AgentSameOrg}
                                                />
                                            )}
                                            {!_.isEmpty(agentsNotInSameOrganisation) && (
                                                <DealActorList
                                                    uniqueUsers={agentsNotInSameOrganisation}
                                                    currentParty={currentUserParty}
                                                    type={type}
                                                    setShowRemoveUserWindow={setShowRemoveUserWindow}
                                                    setUser={setUser}
                                                    setShowEmailUserWindow={setShowEmailUserWindow}
                                                    listType={ListType.AgentNotSameOrg}
                                                />
                                            )}
                                        </>
                                    ) : (
                                        !_.isEmpty(agentArray) && (
                                            <DealActorList
                                                uniqueUsers={agentArray}
                                                currentParty={currentUserParty}
                                                type={type}
                                                setShowRemoveUserWindow={setShowRemoveUserWindow}
                                                setUser={setUser}
                                                setShowEmailUserWindow={setShowEmailUserWindow}
                                                listType={ListType.Agent}
                                            />
                                        )
                                    )}
                                </>
                            )}
                            {tenantArray && type === 'Tenant' && (
                                <>
                                    <DealActorList
                                        uniqueUsers={tenantArray}
                                        currentParty={currentUserParty}
                                        type={type}
                                        setShowRemoveUserWindow={setShowRemoveUserWindow}
                                        setUser={setUser}
                                        setShowEmailUserWindow={setShowEmailUserWindow}
                                        listType={ListType.Tenant}
                                        action={showRemoveTenant}
                                        actionIcon={<DeleteIcon className="w-4" />}
                                        onActionHandle={() => setShowRemoveTenantWindow(true)}
                                    />
                                    {typeData?.user.type === 'AGENT' && currentUserParty === 'TENANT' ? (
                                        <>
                                            {!_.isEmpty(agentsInSameOrganisation) && (
                                                <DealActorList
                                                    uniqueUsers={agentsInSameOrganisation}
                                                    currentParty={currentUserParty}
                                                    type={type}
                                                    setShowRemoveUserWindow={setShowRemoveUserWindow}
                                                    setUser={setUser}
                                                    setShowEmailUserWindow={setShowEmailUserWindow}
                                                    listType={ListType.AgentSameOrg}
                                                />
                                            )}
                                            {!_.isEmpty(agentsNotInSameOrganisation) && (
                                                <DealActorList
                                                    uniqueUsers={agentsNotInSameOrganisation}
                                                    currentParty={currentUserParty}
                                                    type={type}
                                                    setShowRemoveUserWindow={setShowRemoveUserWindow}
                                                    setUser={setUser}
                                                    setShowEmailUserWindow={setShowEmailUserWindow}
                                                    listType={ListType.AgentNotSameOrg}
                                                />
                                            )}
                                        </>
                                    ) : (
                                        !_.isEmpty(agentArray) && (
                                            <DealActorList
                                                uniqueUsers={agentArray}
                                                currentParty={currentUserParty}
                                                type={type}
                                                setShowRemoveUserWindow={setShowRemoveUserWindow}
                                                setUser={setUser}
                                                setShowEmailUserWindow={setShowEmailUserWindow}
                                                listType={ListType.Agent}
                                            />
                                        )
                                    )}
                                </>
                            )}
                            {!completedDeal && (
                                <DealActorsAddMemberButton
                                    type={type}
                                    currentParty={currentUserParty}
                                    setShowAddUserWindow={setShowAddUserWindow}
                                />
                            )}
                        </div>
                    </>
                )}
            </div>
        </>
    );
};

export default DealActors;
