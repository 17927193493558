import React from 'react';

import NoClausesImage from '../../../assets/images/noData/deals.png';
import { AddButton } from '../../../shared/add-button';

type NoClausesProps = {
    setShowAddClauseWindow: React.Dispatch<React.SetStateAction<boolean>>;
    financialsOpen?: boolean;
};

const NoClauses = ({ setShowAddClauseWindow, financialsOpen }: NoClausesProps) => {
    return (
        <div
            className={`md:min-h-full-header min-h-screen w-full flex flex-col items-center justify-center ${
                !financialsOpen && 'pt-24'
            }`}
        >
            <div
                className="h-52 w-60 bg-no-repeat bg-contain"
                style={{
                    backgroundImage: `url(${NoClausesImage})`
                }}
            />
            <div className="flex flex-col items-center text-newGray-darkish">
                <p className="text-sm text-center">
                    Select <span className="font-semibold">Add Clause</span> to begin building out
                    <br /> the Terms you would like to offer
                </p>
            </div>
            <AddButton
                onClick={() => setShowAddClauseWindow(true)}
                height="10"
                className={
                    'pr-2 mt-2 bg-newTeal-light text-newGray-darkest hover:text-white hover:bg-newTeal-main border-none w-40'
                }
            >
                Clause
            </AddButton>
        </div>
    );
};

export default NoClauses;
