import React, { useContext, useState } from 'react';

import { ReactComponent as Check } from '../../../../../assets/images/check/black.svg';
import { Button } from '../../../../../shared/button';
import { ReadinessContext } from '../../../../../v2/components/Tenant/Readiness/context';
import StageWindow from '../../../../../v2/components/Tenant/Readiness/Mobile/StageWindow';

type TaskProps = {
    dealID: string;
    stage: number;
    nextStage: number | null;
    title: string;
    complete?: boolean;
};

const Task = ({ stage, nextStage, title, complete }: TaskProps) => {
    const [stageWindow, setStageWindow] = useState<boolean>(false);

    const { setStage } = useContext(ReadinessContext);

    return (
        <>
            {stageWindow && <StageWindow setShow={setStageWindow} />}
            <div
                className={`${nextStage && stage === nextStage && 'border border-newTeal-main rounded'} ${
                    stage === 6 && 'rounded-b'
                } py-2 px-4 bg-white h-18 w-full flex justify-between items-center mb-0.5`}
            >
                <div className={`flex items-center justify-start ${complete && 'opacity-25'} w-2/3`}>
                    {complete && <Check className="w-9 h-9 pt-2" />}
                    <div className="leading-4 -mt-1">
                        <span className={`font-medium text-xs ${complete ? 'text-gray-800' : 'text-newGray-darkish'}`}>
                            {stage === 0 ? 'Final Submissions' : `Stage ${stage}`}
                        </span>
                        <h3 className="font-medium sm:mt-1 mt-0.5 leading-5">{title}</h3>
                    </div>
                </div>
                <Button
                    onClick={() => {
                        setStage(stage - 1);
                        setStageWindow(true);
                    }}
                    className={`${
                        complete ? 'bg-white border border-navy-light' : 'bg-newTeal-main'
                    } sm:w-36 w-24 h-12 font-semibold text-sm rounded inline-flex items-center justify-center text-center focus:outline-none`}
                >
                    {complete ? 'View' : 'Action'}
                </Button>
            </div>
        </>
    );
};

export default Task;
