import { format } from 'date-fns';
import _ from 'lodash';
import React, { MutableRefObject, useContext, useEffect, useRef, useState } from 'react';
import ReactExport from 'react-export-excel';

import InviteUserWindow from './InviteUserWindow';
import Stats from './Stats';
import { ReactComponent as AddUser } from '../../../assets/images/addUser/addUser.svg';
import { ReactComponent as Chevron } from '../../../assets/images/chevron/down/teal.svg';
import { ReactComponent as ChevronUp } from '../../../assets/images/chevron/up/teal.svg';
import { ReactComponent as FiltersIcon } from '../../../assets/images/filters/sliders.svg';
import { ReactComponent as MapIcon } from '../../../assets/images/map-pin/black.svg';
import { ReactComponent as Search } from '../../../assets/images/search/black.svg';
import { ReactComponent as Upload } from '../../../assets/images/upload/black.svg';
import { ReactComponent as Web } from '../../../assets/images/web/webGray.svg';
import {
    DealState,
    OrderBy,
    useGetOrganisationFromIdQuery,
    useGetUserTypeQuery,
    UserSortFields,
    UserType
} from '../../../generated/graphql';
import DefaultProfile from '../../../layout/SideBar/components/DefaultProfile';
import { Button } from '../../../shared/button';
import { FilterContext } from '../../../shared/Filter/context';
import { FilterConfig, FilterType, FilterWindow } from '../../../shared/Filter/FilterWindow';
import { transformFilters } from '../../../shared/Filter/helpers';
import { PageType, WindowType } from '../../../shared/Filter/hooks/useDisclosure';
import SortChip from '../../../shared/Filter/SortChip';
import { debounce } from '../../../shared/helpers';
import { handleFetchError } from '../../../shared/helpers';
import useMobileScreen from '../../../shared/hooks/useMobileScreen';
import Input, { InputType } from '../../../shared/Inputs';
import { RadioValue } from '../../../shared/Inputs/Radio';
import Loading from '../../../shared/Loading';
import { UseConditionToText } from '../../../shared/NewUnit/hooks/useGetUnitSelectData';
import Window from '../../../shared/Window';
import User from '../Users/User';

export enum Height {
    SM = 375,
    LG = 575
}

type CompanyDetailsWindowProps = {
    organisationId: any;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
};

const CompanyDetailsWindow = ({ setShow, organisationId }: CompanyDetailsWindowProps) => {
    const [showInviteUserWindow, setShowInviteUserWindow] = useState<boolean>(false);
    const [refetchOnInvite, setRefetchOnInvite] = useState<boolean>(false);
    const [screenSize, setScreenSize] = useState<string>();
    const filtersHeight: MutableRefObject = useRef();
    const [showStats, setShowStats] = useState<boolean>(false);
    const { values, window: contextWindow, openWindow, sort, filters, chips } = useContext(FilterContext);
    const { data, error, refetch, loading } = useGetOrganisationFromIdQuery({
        variables: {
            id: organisationId,
            sort: sort
                ? {
                      field: sort.field as UserSortFields,
                      order: sort.order ?? OrderBy.Desc
                  }
                : undefined,
            filter: {
                ...transformFilters(filters, values)
            }
        }
    });
    const { data: typeData, loading: typeLoading } = useGetUserTypeQuery();
    const createdFormat =
        data?.organisation.created && format(new Date(data?.organisation.created), 'dd MMM yyyy, h:mm a');

    const createAddress = (address: any) => {
        return address.line1.concat(' ', address.line2).concat(', ', address.city).concat(', ', address.postCode);
    };

    const openInNewTab = (url: string) => {
        window.open(url, '_blank', 'noopener,noreferrer');
    };

    const mobileScreen = useMobileScreen();

    const spacesRating = (range: string) => {
        switch (range) {
            case 'veryPoor':
                return data?.organisation.units.filter((unit) => unit.rating < 20).length;
            case 'poor':
                return data?.organisation.units.filter((unit) => unit.rating > 20 && unit.rating < 40).length;
            case 'fair':
                return data?.organisation.units.filter((unit) => unit.rating > 40 && unit.rating < 60).length;
            case 'good':
                return data?.organisation.units.filter((unit) => unit.rating > 60 && unit.rating < 80).length;
            case 'excellent':
                return data?.organisation.units.filter((unit) => unit.rating > 80).length;
        }
    };

    const draftDeals = data?.organisation.deals.filter((deal) => deal.state === DealState.Edit);
    const completedDeals = data?.organisation.deals.filter((deal) => deal.state === DealState.Complete);

    const dealsWithLandlord = data?.organisation.deals.filter((deal) => deal.state === DealState.Landlord);
    const dealsWithTenant = data?.organisation.deals.filter((deal) => deal.state === DealState.Tenant);
    const activeDeals =
        dealsWithLandlord?.length && dealsWithTenant?.length && dealsWithLandlord?.length + dealsWithTenant?.length;
    const dealsRoundDates = data?.organisation.deals.map((deal) => {
        return Math.ceil((new Date().getTime() - new Date(deal?.round?.date).getTime()) / (1000 * 3600 * 24));
    });
    const dealsRoundDatesWithouNan =
        dealsRoundDates &&
        dealsRoundDates.filter(function (n) {
            return n || n === 0;
        });
    const sumOfDays = dealsRoundDatesWithouNan && dealsRoundDatesWithouNan.reduce((a, v) => (a = a + v), 0);

    const useCases = data?.organisation.units.map((unit) => unit.use);
    const uniqueUseCases = _.uniq(useCases);

    useEffect(() => {
        let calculateHeight;
        if (window && window.innerWidth < 1600) {
            if (filtersHeight.current?.clientHeight) {
                calculateHeight = Height.SM - filtersHeight.current?.clientHeight;
                setScreenSize(
                    `${data?.organisation.type === UserType.Landlord ? calculateHeight : calculateHeight + 60}px`
                );
            } else setScreenSize(`${data?.organisation.type === UserType.Landlord ? Height.SM : Height.SM + 60}px`);
        } else {
            if (filtersHeight.current?.clientHeight) {
                calculateHeight = Height.LG - filtersHeight.current?.clientHeight;
                setScreenSize(
                    `${data?.organisation.type === UserType.Landlord ? calculateHeight : calculateHeight + 60}px`
                );
            } else setScreenSize(`${data?.organisation.type === UserType.Landlord ? Height.LG : Height.LG + 60}px`);
        }
    }, [window.innerWidth, filtersHeight.current, filters, values, sort]);

    useEffect(() => {
        handleFetchError('Could not get all companies', error, data?.organisation);
    }, [data, error]);

    useEffect(() => {
        if (refetchOnInvite) {
            refetch();
            setRefetchOnInvite(false);
        }
    }, [refetchOnInvite]);

    const handleChange = debounce((value?: string) => {
        refetch({
            query: value
        });
    }, 250);

    const ExcelFile = ReactExport.ExcelFile;
    const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
    const dataSet = [
        {
            columns: ['Spaces'],
            data: [['']]
        },
        {
            columns: ['Unit Readiness', 'Value'],
            data: [
                ['Very Poor', spacesRating('veryPoor')],
                ['Poor', spacesRating('poor')],
                ['Fair', spacesRating('fair')],
                ['Good', spacesRating('good')],
                ['Excellent', spacesRating('excellent')],
                [{ value: 'Total Spaces', style: { font: { bold: true } } }, data?.organisation.units.length],
                ['Use cases', uniqueUseCases?.map((useCase) => UseConditionToText(useCase)).toString()]
            ]
        },
        {
            ySteps: 1,
            columns: ['Deals'],
            data: [['']]
        },
        {
            columns: ['Name', 'Value'],
            data: [
                ['Completed Deals', completedDeals?.length],
                ['Active Deals', activeDeals],
                ['Draft Deals', draftDeals?.length],
                ['Deals with Landlord', dealsWithLandlord?.length],
                ['Deals with Tenant', dealsWithTenant?.length],
                [{ value: 'Total deals', style: { font: { bold: true } } }, data?.organisation.deals.length],
                [
                    'Average time to complete deal',
                    `${Math.round((sumOfDays ?? 0) / (data?.organisation.deals.length ?? 0))} days`
                ]
            ]
        },
        {
            ySteps: 1,
            columns: ['Custom Data'],
            data: [['']]
        },
        {
            columns: ['Name', 'Value'],
            data: [
                ['Clauses', data?.organisation.clauses?.length],
                ['Templates', data?.organisation.templates.length]
            ]
        }
    ];

    if (loading || typeLoading)
        return (
            <div className="container mx-auto p-4">
                <Loading />
            </div>
        );

    return (
        <>
            <Window
                title="Company View"
                height="h-11/12"
                width="w-3/5"
                className="overflow-hidden"
                contentBackground="navy-lightest"
                setShow={setShow}
            >
                <>
                    {showInviteUserWindow && (
                        <InviteUserWindow
                            organisationId={data?.organisation._id}
                            organisationType={data?.organisation.type ?? undefined}
                            setShow={setShowInviteUserWindow}
                            setRefetchOnInvite={setRefetchOnInvite}
                        />
                    )}
                    <div className={`${mobileScreen ? 'mt-6' : ''} bg-white pl-3 pr-3 pt-3 pb-3`}>
                        <div className={`flex ${mobileScreen ? 'flex-col' : 'flex-row'} justify-between`}>
                            <div className={`flex flex-row ${mobileScreen ? 'items-end' : 'items-center'}`}>
                                {data?.organisation.avatar ? (
                                    <img
                                        src={data?.organisation.avatar}
                                        alt={data?.organisation.name}
                                        className="w-20 h-20 rounded object-cover mx-5"
                                    />
                                ) : (
                                    <div className="mx-5">
                                        <DefaultProfile name={data?.organisation.name} size="5rem" fontSize="2rem" />
                                    </div>
                                )}
                                <div className="flex flex-row">
                                    <div className="flex flex-col">
                                        <p className="text-3xl font-medium ">{data?.organisation.name}</p>

                                        {data?.organisation.address.line1 &&
                                            data?.organisation.address.line2 &&
                                            data?.organisation.address.city &&
                                            data?.organisation.address.postCode && (
                                                <div className="flex flex-row justify-between">
                                                    <div className="flex flex-row items-center">
                                                        <MapIcon stroke="black" className="w-4 h-5=4 mr-2" />
                                                        <p className="text-sm">
                                                            {createAddress(data?.organisation.address)}
                                                        </p>
                                                    </div>
                                                </div>
                                            )}

                                        {data?.organisation.website && (
                                            <div className="flex flex-row items-center">
                                                <Web stroke="black" className="w-4 h-5=4 mr-2" />
                                                <p
                                                    className="text-sm underline"
                                                    style={{ color: '#0000EE' }}
                                                    onClick={() =>
                                                        data?.organisation.website &&
                                                        openInNewTab(data?.organisation.website)
                                                    }
                                                >
                                                    {data?.organisation.website}
                                                </p>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>

                            <div className="flex flex-row justify-end">
                                {data?.organisation.created && (
                                    <div className="flex flex-col justify-end">
                                        <p className={`text-sm`}>Created on {createdFormat}</p>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>

                    <div className="bg-navy-lightest pt-3 pb-3">
                        <div className={`flex ${mobileScreen ? 'flex-col' : 'flex-row'} w-full items-center px-3`}>
                            <Button
                                onClick={() => setShowInviteUserWindow(true)}
                                background="newTeal-main"
                                text="white"
                                height="12"
                                border="transparent"
                                className={`px-4 mr-2 my-2 ${
                                    mobileScreen ? 'w-full' : 'w-40 justify-around'
                                } whitespace-no-wrap font-semibold flex items-center `}
                            >
                                <AddUser className="w-5 h-5 mr-2" />
                                Add User
                            </Button>
                            <div className={`flex flex-row  w-full ${mobileScreen ? ' mb-2' : ''}`}>
                                <div className="relative w-100">
                                    <Search
                                        className="w-5 h-5 absolute left-0 top-0 ml-4 z-20"
                                        style={{ top: '13px' }}
                                    />
                                    <Input
                                        type={InputType.text}
                                        placeholder="Search"
                                        className="w-full h-12 pl-12"
                                        autoFocus
                                        onChange={(e) => handleChange(e.target.value)}
                                    />
                                </div>

                                {data?.organisation.type &&
                                    [UserType.Landlord, UserType.Admin].includes(data?.organisation.type) && (
                                        <ExcelFile
                                            filename={`${data.organisation.name}_stats`}
                                            element={
                                                <Button
                                                    background="white"
                                                    text="black"
                                                    className="filter-button-size ml-2"
                                                >
                                                    <Upload />
                                                </Button>
                                            }
                                        >
                                            <ExcelSheet dataSet={dataSet} name={`${data.organisation.name}_stats`} />
                                        </ExcelFile>
                                    )}
                                <Button
                                    background="white"
                                    text="black"
                                    className="filter-button-size ml-2"
                                    onClick={() =>
                                        openWindow({
                                            type: WindowType.ALL,
                                            page: PageType.COMPANY_DETAILS
                                        })
                                    }
                                >
                                    <FiltersIcon />
                                </Button>
                            </div>
                        </div>

                        {showStats && (
                            <div className="mx-4">
                                <Stats
                                    units={data?.organisation.units}
                                    clauses={data?.organisation.clauses}
                                    deals={data?.organisation.deals}
                                    templates={data?.organisation.templates}
                                />
                            </div>
                        )}
                        {data?.organisation.type &&
                            [UserType.Landlord, UserType.Admin].includes(data?.organisation.type) && (
                                <div
                                    onClick={() => setShowStats(!showStats)}
                                    className="w-full flex flex-row cursor-pointer items-center justify-center p-3 bg-navy-light"
                                >
                                    <p>Show Company Stats</p>
                                    {showStats ? <ChevronUp className="w-9 h-5" /> : <Chevron className="w-9 h-5" />}
                                </div>
                            )}
                        <div ref={filtersHeight} className="ml-1 mb-2 flex items-center flex-wrap">
                            {sort && typeData?.user && <SortChip sortOptions={getUserFields()} />}
                            {values && !loading && chips && FilterContext && chips()}
                        </div>

                        <div
                            className="overflow-scroll px-3 overflow-x-hidden mb-2"
                            style={{
                                height: mobileScreen ? '100%' : screenSize
                            }}
                        >
                            {loading ? (
                                <Loading />
                            ) : (
                                data?.organisation.members &&
                                data?.organisation.members.map((user) => (
                                    <User
                                        popup={true}
                                        id={user._id}
                                        key={user._id}
                                        email={user.email}
                                        firstName={user.firstName}
                                        lastName={user.lastName}
                                        type={user.type}
                                        organisation={data?.organisation}
                                        mobile={user.mobile}
                                        lastLogin={user.lastLogin}
                                        activated={user.activated}
                                    />
                                ))
                            )}
                        </div>
                    </div>
                    {contextWindow?.page === PageType.COMPANY_DETAILS && typeData?.user && (
                        <FilterWindow
                            page={PageType.COMPANY_DETAILS}
                            config={userFilterConfig()}
                            sortOptions={getUserFields()}
                        />
                    )}
                </>
            </Window>
        </>
    );
};

const LOCKED_OPTIONS = [
    { label: 'Yes', value: true },
    { label: 'No', value: false }
];

export const userFilterConfig = (): FilterConfig => [
    {
        id: 'lastLogin',
        label: 'Last Activity',
        type: FilterType.RangeDate
    },
    {
        id: 'created',
        label: 'User Joined',
        type: FilterType.RangeDate
    },
    {
        id: 'locked',
        label: 'Locked',
        type: FilterType.RadioList,
        options: LOCKED_OPTIONS
    }
];

export const getUserFields = (): RadioValue[] => [
    {
        id: UserSortFields.FirstName,
        label: 'Name'
    },
    {
        id: UserSortFields.LastLogin,
        label: 'Last Activity'
    }
];

export default CompanyDetailsWindow;
