import { format } from 'date-fns';
import React, { useState } from 'react';

import { ReactComponent as Check } from '../../../assets/images/check/regularCheck/check.svg';
import {
    ClauseTemplate,
    useUpdateAdminClauseMutation,
    useUpdateOrganisationClauseMutation
} from '../../../generated/graphql';
import SuccessIcon from '../../../shared/SuccessIcon';
import Window from '../../../shared/Window';

type ConfirmClauseUpdateProps = {
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
    clauseID: string;
    organisationID?: string | null;
    updatedDealsAt?: Date | null;
    updatedTemplatesAt?: Date | null;
};

type CheckboxProps = {
    label: string;
    checked: boolean;
    date?: Date | null;
    onClick: () => void;
};

const Checkbox = ({ label, checked, date, onClick }: CheckboxProps) => {
    const buttonStyle = checked ? 'border border-teal-700 bg-newTeal-light' : 'bg-gray-200';

    return (
        <button
            className={`cursor-pointer p-2 ${buttonStyle}  rounded-sm flex flex-row items-center justify-start gap-4 focus:outline-none`}
            style={{ height: '64px' }}
            onClick={onClick}
        >
            <div className="flex flex-col p-1 items-start ">
                <div className="flex flex-col items-start  gap-2">
                    <div className="relative text-lg font-bold">{label}</div>
                    {date && <div className="relative text-base">{format(new Date(date), 'dd/MM/yyyy, hh:mm')}</div>}
                </div>
            </div>
            <div className="rounded-full bg-white p-1 box-border">
                {checked ? <Check className="w-5 h-5" color="#3CA48E" /> : <div className="w-5 h-5" />}
            </div>
        </button>
    );
};

export const ConfirmClauseUpdate = ({
    clauseID,
    organisationID,
    updatedDealsAt,
    updatedTemplatesAt,
    setShow
}: ConfirmClauseUpdateProps) => {
    const [updateTemplates, setUpdateTemplates] = useState(false);
    const [updateDeals, setUpdateDeals] = useState(false);

    const [updateAdminClause] = useUpdateAdminClauseMutation();
    const [updateOrganisationClause] = useUpdateOrganisationClauseMutation();

    const [success, setSuccess] = useState(false);

    const updateClause = () => {
        if (organisationID) {
            updateOrganisationClause({
                variables: {
                    organisationID: organisationID,
                    id: clauseID,
                    updateDeals,
                    updateTemplates
                },
                updateQueries: {
                    searchClauseBank: ({ clauseBank }, { mutationResult }) => {
                        const index = clauseBank.findIndex(
                            (clause: ClauseTemplate) => clause._id === mutationResult.data?.organisationClauseUpdate._id
                        );
                        const updated = {
                            ...clauseBank[index],
                            ...mutationResult.data?.organisationClauseUpdate
                        };

                        return {
                            clauseBank: [
                                ...clauseBank.slice(0, index),
                                updated,
                                ...clauseBank.slice(index + 1, clauseBank.length)
                            ]
                        };
                    }
                }
            });
        } else {
            updateAdminClause({
                variables: { id: clauseID, updateDeals, updateTemplates },
                updateQueries: {
                    searchClauseBank: ({ clauseBank }, { mutationResult }) => {
                        const index = clauseBank.findIndex(
                            (clause: ClauseTemplate) => clause._id === mutationResult.data?.adminClauseUpdate._id
                        );
                        const updated = {
                            ...clauseBank[index],
                            ...mutationResult.data?.adminClauseUpdate
                        };

                        return {
                            clauseBank: [
                                ...clauseBank.slice(0, index),
                                updated,
                                ...clauseBank.slice(index + 1, clauseBank.length)
                            ]
                        };
                    }
                }
            });
        }

        setSuccess(true);
    };

    if (success) {
        return (
            <Window width="w-1/4" setShow={setShow}>
                <div className="flex flex-col items-center justify-center py-10">
                    <SuccessIcon />
                    <h1 className="text-xl font-bold text-center mt-5">Clause Updated</h1>
                </div>
            </Window>
        );
    }

    return (
        <Window primaryBtn onPrimaryBtnClick={() => updateClause()} setShow={setShow}>
            <div className="flex items-center justify-center px-4 py-4 gap-2">
                <Checkbox
                    label="Update Templates"
                    checked={updateTemplates}
                    date={updatedTemplatesAt}
                    onClick={() => setUpdateTemplates(!updateTemplates)}
                />
                <Checkbox
                    label="Update Draft Deals"
                    checked={updateDeals}
                    date={updatedDealsAt}
                    onClick={() => setUpdateDeals(!updateDeals)}
                />
            </div>
        </Window>
    );
};
