import { useApolloClient } from '@apollo/client';
import React, { useEffect, useRef, useState } from 'react';

import { DealRoundType, DealState, DealUserParty, useDealNudgeMutation } from '../../generated/graphql';
import { Button } from '../../shared/button';
import Loading from '../../shared/Loading';
import Window from '../../shared/Window';

type NudgeProps = {
    dealID: string;
    actionsRequired?: boolean;
    state?: DealState;
    currentUserParty?: DealUserParty | null;
    round?: DealRoundType | null;
    setCanNudge?: React.Dispatch<React.SetStateAction<boolean>>;
};

const NudgeButton = ({ dealID, actionsRequired, state, currentUserParty, round, setCanNudge }: NudgeProps) => {
    const client = useApolloClient();
    const [show, setShow] = useState<boolean>(false);
    const [showWarning, setShowWarning] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);
    const [disabled, setDisabled] = useState<boolean>(false);
    const messageRef = useRef<HTMLTextAreaElement>(null);
    const [nudge, { error: nudgeError, loading }] = useDealNudgeMutation();

    useEffect(() => {
        if (!round) return;
        let date = new Date();
        let day = date.getDate();
        let roundDate = null;
        if (round.date) {
            roundDate = new Date(round.date);
            roundDate.setDate(roundDate.getDate() + 1);
            roundDate.setHours(0, 0, 0, 0); //to start of next day (working day)
            roundDate.setDate(roundDate.getDate() + 2); //2 working days later
            roundDate = roundDate.getTime();
        }

        if (round.nudge) {
            let nudgeExpires = new Date(round.nudge).getTime();
            if (nudgeExpires > Date.now()) {
                setDisabled(true);
                setCanNudge && setCanNudge(false);
            }
        } else if (day === 6 || day === 0) {
            //not enabled on the weekends
            setDisabled(true);
            setCanNudge && setCanNudge(false);
        } else if (roundDate && roundDate > Date.now()) {
            //if 2 working days
            setDisabled(true);
            setCanNudge && setCanNudge(false);
        }
    }, [round, disabled, setCanNudge]);

    const checkTime = async () => {
        if (disabled) {
            return;
        }
        const date = new Date();
        const hour = date.getHours();
        if (hour < 8 || hour > 18) {
            setShowWarning(true);
        } else {
            setError(null);
            setShow(true);
        }
    };
    const handleClick = async () => {
        const message = messageRef.current?.value;
        if (!message || message.trim().length === 0) {
            setError('A message is required when nudging');
            return;
        }

        await nudge({
            variables: {
                dealID,
                message
            }
        });

        if (nudgeError) {
            setError(nudgeError.graphQLErrors[0].extensions?.message ?? 'Something went wrong'); //not using fetch error as some errors look less alarming and are more of a notice
        } else {
            client.cache.modify({
                id: client.cache.identify({
                    __typename: 'Deal',
                    _id: dealID
                }),
                fields: {
                    round() {
                        return {
                            ...round,
                            ...{ nudge: new Date().toISOString() }
                        };
                    }
                }
            });
            setDisabled(true);
            setCanNudge && setCanNudge(false);
            //update nudge date for deal
            setShow(false);
        }
    };

    if (currentUserParty?.toString() === state?.toString() || state === DealState.Edit || state === DealState.Complete)
        return <> </>;
    return (
        <>
            {show && (
                <Window
                    title="Send Nudge"
                    titleCenter
                    width="w-1/3"
                    primaryBtn
                    primaryBtnText="Nudge"
                    onPrimaryBtnClick={handleClick}
                    setShow={setShow}
                >
                    <div className="p-4 flex items-center justify-center gap-2 flex-col">
                        {loading && <Loading />}
                        {!loading && (
                            <>
                                <textarea
                                    ref={messageRef}
                                    defaultValue="It's been a while since you've looked over our deal on Kato. When you next get a free moment, please review so we can proceed further. Thanks."
                                    rows={5}
                                    className="w-full p-2 bg-navy-lightest border border-transparent rounded focus:outline-none focus:border-newTeal-main"
                                    placeholder="Send a message..."
                                ></textarea>
                                {error && (
                                    <div className="bg-red-200 border border-red-600 rounded w-full py-2 px-4 mt-4">
                                        <span className="text-center text-red-600">{error}</span>
                                    </div>
                                )}
                            </>
                        )}
                    </div>
                </Window>
            )}
            {showWarning && (
                <Window
                    title="Caution!"
                    titleCenter
                    width="w-1/3"
                    primaryBtn
                    primaryBtnText="Continue"
                    onPrimaryBtnClick={() => {
                        setShowWarning(false);
                        setError(null);
                        setShow(true);
                    }}
                    secondaryBtn
                    onSecondaryBtnClick={() => setShowWarning(false)}
                    setShow={setShowWarning}
                >
                    <div className="p-4 flex items-center justify-center gap-2 flex-col">
                        <p className="text-newGray-darkish px-4 py-6">
                            It's currently outside social hours (8am - 7pm) are you sure you want to send a nudge?
                        </p>
                    </div>
                </Window>
            )}

            <Button
                onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    checkTime();
                }}
                disabled={disabled}
                style={{
                    height: actionsRequired ? '2.5rem' : undefined,
                    fontWeight: actionsRequired ? 400 : undefined
                }}
                className={`mr-3  ${!actionsRequired ? 'p-3 font-bold' : 'px-5 h-10'}${disabled ? ' opacity-50' : ''}`}
                background={`${actionsRequired ? 'white' : 'navy-light'}`}
                text="black"
            >
                Nudge
            </Button>
        </>
    );
};

export default NudgeButton;
