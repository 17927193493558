import {
    GetUnitCardDetailsDocument,
    UnitCondition,
    UnitFilterCountsDocument,
    UnitUse,
    useNewUnitMutation,
    UserType
} from '../../../generated/graphql';
import { ExtendedHTMLElement } from '../../ExtendedHTMLElement';

const useNewUnit = (
    setErrorMessage: React.Dispatch<React.SetStateAction<string | null>>,
    userType: UserType | false
) => {
    // New Unit Mutation
    const [newUnit, { error: newUnitError }] = useNewUnitMutation();

    return async (
        buildingName?: string,
        unitName?: string,
        addressLine1?: string,
        addressLine2?: string,
        addressCity?: string,
        addressPostCode?: string,
        unitUse?: UnitUse | null,
        unitCondition?: UnitCondition | null,
        unitArea?: string,
        emails?: string[],
        url?: string,
        associatedCompany?: string
    ) => {
        const vanillaForm = document.getElementById('newUnit-form') as ExtendedHTMLElement;

        if (vanillaForm) if (!vanillaForm.checkValidity()) vanillaForm.reportValidity();

        if (
            !unitName ||
            !addressLine1 ||
            !addressCity ||
            !addressPostCode ||
            !unitUse ||
            !unitCondition ||
            !unitArea ||
            !associatedCompany
        ) {
            setErrorMessage('All fields (except Building Name and Address Line 2) are required');

            return null;
        }

        if (unitArea === '0') {
            setErrorMessage('Demise area should be greater than 0');

            return null;
        }

        let inputBuildingName;
        if (!buildingName) {
            inputBuildingName = addressLine1;
        } else {
            inputBuildingName = buildingName;
        }

        const { data } = await newUnit({
            variables: {
                emails,
                name: unitName,
                buildingName: inputBuildingName,
                area: +unitArea,
                use: unitUse,
                condition: unitCondition,
                line1: addressLine1,
                url,
                line2: addressLine2,
                city: addressCity,
                postCode: addressPostCode,
                straightToLease: false,
                organisation: associatedCompany
            },
            refetchQueries: [
                { query: GetUnitCardDetailsDocument },
                {
                    query: UnitFilterCountsDocument
                }
            ]
        });

        if (newUnitError) {
            // handleFetchError(
            //     "Failed to create the demise",
            //     newUnitError,
            //     data?.unitCreate
            // )
            return null;
        }

        return data;
    };
};

export default useNewUnit;
