import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import AllPriorityDeals from './AllPriorityDeals';
import { ReactComponent as Plus } from '../../../assets/images/plus/black.svg';
import { ReactComponent as Target } from '../../../assets/images/target/gray.svg';
import { DealState, GetDealCardDetailsQuery, useGetUserTypeQuery } from '../../../generated/graphql';
import { handleFetchError } from '../../../shared/helpers';
import Loading from '../../../shared/Loading';
import NewDealWindow from '../../Deals/NewDealWindow';

type PriorityDealsProps = {
    data?: GetDealCardDetailsQuery;
    pinnedData?: GetDealCardDetailsQuery;
};

const PriorityDeals = ({ data, pinnedData }: PriorityDealsProps) => {
    const history = useHistory();
    const [showNewDealWindow, setShowNewDealWindow] = useState<boolean>(false);

    // User Type Check
    const { data: typeData, loading: typeLoading, error: typeError } = useGetUserTypeQuery();
    useEffect(() => {
        // Check for errors
        handleFetchError('Could not load user', typeError, typeData?.user);
    }, [typeData, typeError]);

    if (typeLoading)
        return (
            <div className="container mx-auto p-4">
                <Loading />
            </div>
        );

    const isTenant = typeData?.user.__typename === 'User' && typeData?.user.type === 'TENANT';
    if (isTenant) history.push('/');

    const pinnedDeals = pinnedData?.deals.results;

    const deals =
        data?.deals.results &&
        data.deals.results.filter(
            (deal) =>
                deal.isPinned === false &&
                deal.state !== DealState.Complete &&
                !(typeData?.user.__typename === 'User' && typeData.user.type !== 'AGENT' && deal.pending)
        );

    const priorityDeals = [
        ...(pinnedDeals ? pinnedDeals : []),
        ...(pinnedDeals && pinnedDeals.length < 3 && deals ? deals : [])
    ];

    if (!deals) return null;

    const viewAll = () => {
        history.push('/deals');
    };

    return (
        <>
            {showNewDealWindow && <NewDealWindow setShow={setShowNewDealWindow} />}

            <div className={`w-full md:px-32 flex items-center justify-center p-4 bg-navy-light`}>
                <div className="container">
                    <p
                        className={`${
                            pinnedDeals && pinnedDeals?.length > 0 ? 'mb-9' : 'mb-4'
                        } font-bold text-newGray-main flex items-center`}
                    >
                        <Target className="w-4 h-4 mr-1" />
                        Priority Deals
                    </p>
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 w-full mx-auto min-h-64">
                        <AllPriorityDeals deals={priorityDeals} typeData={typeData} isTenant={isTenant} />

                        {/* Create new deal */}
                        {(data?.deals.results.length ?? 0) < 3 && priorityDeals.length <= 2 && (
                            <button
                                onClick={() => setShowNewDealWindow(true)}
                                className={` w-100 py-24 relative bg-navy-lightest font-medium border-2 border-gray-200 rounded focus:outline-none hover:shadow-md transition duration-200 flex flex-col items-center justify-center`}
                            >
                                <Plus className="w-5 h-5" />
                                New Deal
                            </button>
                        )}
                    </div>
                    {(data?.deals.results.length ?? 0) > 3 && (
                        <div className="w-full text-center py-2 mt-2">
                            <div className="font-bold text-gray-600 cursor-pointer" onClick={() => viewAll()}>
                                <p>View All</p>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

export default PriorityDeals;
