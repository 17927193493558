import React, { useContext } from 'react';

import { DealApprovalSendType, DealSendType, DealState, DealUserParty } from '../../../../../generated/graphql';
import DealHeaderContext from '../../../../../v2/pages/Deals/DealHeader/DealHeaderContext';
import DealSendWindow from '../../DealSendWindow';
import ValueNotEnteredWindow from '../../Edit/ValueNotEnteredWindow';
import DealRoundCompleteWindow from '../../Negotiate/DealRoundCompleteWindow';
import LowSendWindow from '../../Negotiate/LowSendWindow';

const DealHeaderWindows = () => {
    const {
        showRoundCompleteWindow,
        showSendWindow,
        detailsData,
        dealComplete,
        dealAgreedWindowOpen,
        shownRoundCompleteWindow,
        setShowRoundCompleteWindow,
        setShareWindowType,
        setShowSendWindow,
        setForceReadinessClose,
        valuelessClauses,
        setValuelessClauses,
        shareWindowType,
        forceReadinessClose,
        lowReadinessWarning,
        setLowReadinessWarning,
        stages,
        totalStages,
        setSendWindowSuccess,
        triggerOpen,
        deal
    } = useContext(DealHeaderContext);
    return (
        <>
            {/* Show round complete window */}
            {showRoundCompleteWindow &&
                !showSendWindow &&
                detailsData &&
                (!dealComplete || deal?.state !== DealState.Complete) &&
                !dealAgreedWindowOpen &&
                !shownRoundCompleteWindow &&
                detailsData.deal.state.toString() === detailsData.deal.currentUserParty?.toString() && (
                    <DealRoundCompleteWindow
                        deal={detailsData.deal}
                        currentUserParty={detailsData.deal.currentUserParty}
                        setShow={setShowRoundCompleteWindow}
                        setShareWindowType={setShareWindowType}
                        setShowSendWindow={setShowSendWindow}
                        setForceReadinessClose={setForceReadinessClose}
                    />
                )}

            {/* Show confirmation window for first offer */}
            {showSendWindow &&
            valuelessClauses.length > 0 &&
            shareWindowType === DealSendType.Tenant &&
            detailsData?.deal.__typename === 'Deal' &&
            detailsData.deal.state === DealState.Edit ? (
                <ValueNotEnteredWindow
                    clauses={valuelessClauses}
                    setShowSendWindow={setShowSendWindow}
                    lowReadinessWarning={lowReadinessWarning}
                    setShowLowReadiness={setLowReadinessWarning}
                    setShow={setValuelessClauses}
                />
            ) : !forceReadinessClose &&
              showSendWindow &&
              lowReadinessWarning &&
              detailsData?.deal.__typename === 'Deal' &&
              shareWindowType !== DealSendType.Approver &&
              shareWindowType !== DealSendType.Other ? (
                <LowSendWindow
                    dealComplete={dealComplete}
                    round={detailsData.deal.round?.count}
                    setShow={setLowReadinessWarning}
                    setShowSendDeal={setShowSendWindow}
                    id={
                        detailsData.deal.currentUserParty !== 'TENANT' && detailsData.deal.unit
                            ? detailsData.deal.unit._id
                            : detailsData.deal._id
                    }
                    rating={detailsData.deal.currentUserParty !== 'TENANT' ? detailsData.deal.unit?.rating : undefined}
                    totalStages={totalStages}
                    stages={stages}
                    shareNow={() => {
                        setLowReadinessWarning(false);
                    }}
                />
            ) : (
                //Otherwise show share window
                showSendWindow && (
                    <DealSendWindow
                        isCompleted={detailsData?.deal.state === DealState.Complete}
                        setWindowSuccess={setSendWindowSuccess}
                        forceReadinessClose={setForceReadinessClose}
                        dealID={detailsData?.deal._id ?? ''}
                        redirect={
                            detailsData?.deal.state === 'EDIT' ? `/deals/${detailsData.deal._id}/negotiate` : undefined
                        }
                        currentUserParty={
                            detailsData?.deal.currentUserParty
                                ? detailsData?.deal.currentUserParty
                                : DealUserParty.Tenant
                        }
                        type={
                            triggerOpen
                                ? deal?.currentUserParty === DealUserParty.Landlord
                                    ? DealSendType.Tenant
                                    : DealSendType.Landlord
                                : shareWindowType
                        }
                        showEmail={
                            !(detailsData?.deal.tenant?.members && detailsData.deal.tenant?.members?.length > 0) ||
                            (!triggerOpen &&
                                (shareWindowType === DealSendType.Approver || shareWindowType === DealSendType.Other))
                        }
                        approvalResponse={
                            shareWindowType === DealSendType.Approver
                                ? DealApprovalSendType.Invite
                                : DealApprovalSendType.Other
                        }
                        setShow={setShowSendWindow}
                    />
                )
            )}
        </>
    );
};

export default DealHeaderWindows;
