import React from 'react';
import { Link } from 'react-router-dom';

import { ReactComponent as Close } from '../../../assets/images/cross/black.svg';

type FileProps = {
    id?: string;
    name: string;
    organisation?: String;
    other?: boolean;
    canDelete?: boolean;
    user?: String;
    date?: Date;
    url: string;
    local?: boolean;
    setShowDelete?: React.Dispatch<React.SetStateAction<boolean>>;
    setDeletedFile?: React.Dispatch<React.SetStateAction<string | null>>;
};

const File = ({
    id,
    name,
    organisation,
    user,
    date,
    url,
    other,
    canDelete,
    setShowDelete,
    setDeletedFile,
    local = false
}: FileProps) => {
    return (
        <div className={`py-3 px-4 bg-white h-18 w-full flex justify-between items-center${other ? ' rounded' : ''}`}>
            <div className="leading-5 truncate flex-1">
                <h3 className="font-semibold truncate" style={{ maxWidth: 300 }}>
                    {name}
                </h3>
                <span className="font-light text-sm text-gray-600">
                    {organisation && <span className="font-medium text-gray-600">{organisation} </span>}
                    {user ? ` - ${user}` : ''}{' '}
                    <span className="block font-medium text-gray-600">
                        {date ? `Uploaded - ${date.toLocaleDateString()}` : ''}
                    </span>
                </span>
            </div>
            <div className="flex flex-row">
                {local ? (
                    <Link
                        to={url}
                        className="bg-navy-light w-36 h-12 rounded inline-flex items-center justify-center text-center font-semibold focus:outline-none"
                    >
                        View File
                    </Link>
                ) : (
                    <a
                        href={url}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="bg-navy-light w-36 h-12 rounded inline-flex items-center justify-center text-center font-semibold focus:outline-none"
                    >
                        View File
                    </a>
                )}
                {other && setShowDelete && setDeletedFile && id && canDelete && (
                    <div
                        className="ml-2 cursor-pointer flex items-center h-12 px-3 bg-white border border-navy-light rounded"
                        onClick={() => {
                            setShowDelete(true);
                            setDeletedFile(id);
                        }}
                    >
                        <Close className="w-5 h-5" />
                    </div>
                )}
            </div>
        </div>
    );
};

export default File;
