import React, { useState } from 'react';

import { ReactComponent as Link } from '../../../assets/images/link/black.svg';
import { ReactComponent as Minus } from '../../../assets/images/minus/black.svg';
import { ReactComponent as Plus } from '../../../assets/images/plus/black.svg';
import { Button } from '../../button';
import { formatClause } from '../../helpers';

interface ClauseProps {
    id: string;
    linked: boolean;
    value?: string;
    valueSuffix?: string;
    shortForm?: string;
    longForm?: string;
    tags: string[];
    onClick: (id: string, value?: string, valueSuffix?: string, shortForm?: string, longForm?: string) => void;
}

const Clause = ({ id, linked, value, valueSuffix, shortForm, longForm, tags, onClick }: ClauseProps) => {
    const [open, setOpen] = useState<boolean>(false);

    const formattedLongClause = {
        __html: formatClause(longForm, value, valueSuffix, tags) ?? ''
    };

    return (
        <div className="w-full border-b border-navy-light bg-white relative">
            <div className="w-full flex flex-row items-center justify-between px-6 pt-4 pb-3">
                {shortForm}
                {open ? (
                    <button
                        className="focus:outline-none"
                        onClick={() => {
                            setOpen(false);
                        }}
                    >
                        <Minus className="w-4 h-4 cursor-pointer" />
                    </button>
                ) : (
                    <button
                        className="focus:outline-none"
                        onClick={() => {
                            setOpen(true);
                        }}
                    >
                        <Plus className="w-8 h-6 cursor-pointer -mr-3" />
                    </button>
                )}
            </div>
            {open && (
                <div className="w-full bg-navy-lightest py-2 pr-2 flex flex-row items-center justify-between">
                    <p className="px-6 py-3 text-sm w-4/5" dangerouslySetInnerHTML={formattedLongClause}></p>
                    <Button
                        onClick={() => onClick(id, value, valueSuffix, shortForm, longForm)}
                        background="black"
                        text="white"
                        height="10"
                        className="w-1/5 text-sm"
                    >
                        <Link className="w-4 h-4 mr-1" />
                        {linked ? 'Unlink Clause' : 'Link Clause'}
                    </Button>
                </div>
            )}
        </div>
    );
};

export default Clause;
