import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import WindowFooter from './layout/WindowFooter';
import WindowHeader from './layout/WindowHeader';
import StageCompleted from './StageCompleted';
import StageContext from './StageContext';
import { GetDealTenantDetailsDocument, useTenantActionStageFiveMutation } from '../../../../../generated/graphql';
import { handleFetchError } from '../../../../../shared/helpers';
import Loading from '../../../../../shared/Loading';
import Window from '../../../../../shared/Window';

const Stage5Window = () => {
    const history = useHistory();

    const { dealID, details, backTo, setShowStage5Window: setShow } = React.useContext(StageContext);

    // Checkboxes
    const [noLawyer, setNoLawyer] = useState<boolean>(details?.five?.noLawyer ?? false);

    const [formalInstructions, setFormalInstructions] = useState<boolean>(details?.five?.instructions ?? false);

    const [termsOfEngagement, setTermsOfEngagement] = useState<boolean>(details?.five?.signed ?? false);

    // Set Stage 5
    const [actionStage, { loading: actionStageLoading, error: actionStageError }] = useTenantActionStageFiveMutation();

    const saveToDB = async (completed?: boolean) => {
        const { data } = await actionStage({
            variables: {
                dealID: dealID!,
                completed: completed ?? false,
                instructions: formalInstructions,
                signed: termsOfEngagement,
                noLawyer
            },
            refetchQueries: [
                {
                    query: GetDealTenantDetailsDocument,
                    variables: {
                        dealID: dealID!
                    }
                }
            ]
        });

        if (actionStageError) {
            return handleFetchError('Could not set your details', actionStageError, data?.dealTenantDetailsSet);
        }
    };

    const handleCompleteStage = async () => {
        if (!formalInstructions || !termsOfEngagement) {
            saveToDB(noLawyer ? true : false);
        } else {
            saveToDB(true);
        }

        history.push('#readiness-stage6');
    };

    return (
        <Window
            width="w-4/5"
            scrollContent={true}
            className="md:max-h-11/12"
            contentBackground="navy-light"
            header={
                <WindowHeader
                    setShow={setShow!}
                    backTo={backTo!}
                    stage="5"
                    title="Lawyer Instructions"
                    description="We are getting pretty close now. Have you officially instructed your lawyer (if you are using one)?"
                />
            }
            footer={
                <WindowFooter
                    backTo="#readiness-stage4"
                    nextTo="#readiness-stage6"
                    onNextClick={async () => await handleCompleteStage()}
                />
            }
            setShow={setShow!}
            onCloseClick={() => {
                setShow!(false);
                history.push(backTo!);
            }}
        >
            <div className="flex flex-col min-h-full">
                <div className="w-full bg-navy-light flex-1">
                    {actionStageLoading && <Loading className="mt-20" />}
                    <div className={`lg:w-2/5 md:w-5/5 mx-auto py-12 px-2 ${actionStageLoading && 'opacity-0'}`}>
                        {/* Check 1 */}
                        <div className="flex items-center flex-start relative">
                            <label className="flex items-center mb-6 relative">
                                <input
                                    type="checkbox"
                                    id="optional"
                                    checked={noLawyer}
                                    onChange={() => {
                                        setNoLawyer(!noLawyer);
                                    }}
                                    className="leading-tight w-7 h-7 form-checkbox p-2 rounded bg-white text-newTeal-main focus:shadow-none focus:outline-none"
                                />
                                <span className="text-sm text-newGray-darkish ml-2">I'm not using a Lawyer</span>
                            </label>
                        </div>

                        {!noLawyer && (
                            <>
                                {/* Check 2 */}
                                <div className="flex items-center flex-start relative">
                                    <label className="flex items-center mb-6 relative">
                                        <input
                                            type="checkbox"
                                            id="optional"
                                            checked={termsOfEngagement}
                                            onChange={() => {
                                                setTermsOfEngagement(!termsOfEngagement);
                                            }}
                                            className="leading-tight w-7 h-7 form-checkbox p-2 rounded bg-white text-newTeal-main focus:shadow-none focus:outline-none"
                                        />
                                        <span className="text-sm text-newGray-darkish ml-2">
                                            <span className="text-newTeal-main mr-1">*</span>
                                            Are the terms of engagement with your Lawyer signed?
                                        </span>
                                    </label>
                                </div>

                                {/* Check 3 */}
                                <div className="flex items-center flex-start relative">
                                    <label className="flex items-center mb-6 relative">
                                        <input
                                            type="checkbox"
                                            id="optional"
                                            checked={formalInstructions}
                                            onChange={() => {
                                                setFormalInstructions(!formalInstructions);
                                            }}
                                            className="leading-tight w-7 h-7 form-checkbox p-2 rounded bg-white text-newTeal-main focus:shadow-none focus:outline-none"
                                        />
                                        <span className="text-sm text-newGray-darkish ml-2">
                                            <span className="text-newTeal-main mr-1">*</span>
                                            Have you given your Lawyer formal instructions?
                                        </span>
                                    </label>
                                </div>
                                {/* Bottom text */}
                                <p className="text-gray-600 font-medium text-sm mt-8 mb-4">
                                    <span className="text-newTeal-main mr-1">*</span>
                                    All the fields are required to complete the stage
                                </p>
                            </>
                        )}

                        {details?.five?.completed && <StageCompleted />}
                    </div>
                </div>
            </div>
        </Window>
    );
};

export default Stage5Window;
