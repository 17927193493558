import React, { useState } from 'react';

import { Button } from '../../../shared/button';
import Input, { InputType } from '../../../shared/Inputs';
import Window from '../../../shared/Window';

type AdminPasswordWindowProps = {
    text?: string;
    title?: string;
    onClick: (password: string) => any;
    hideConfirm?: boolean;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
};

const AdminPasswordWindow = ({ text, title, onClick, hideConfirm = false, setShow }: AdminPasswordWindowProps) => {
    const [password, setPassword] = useState<string>();
    return (
        <Window title={title ?? 'Confirm Password'} zIndex="60" setShow={setShow}>
            <div className="w-full flex flex-col items-center justify-around gap-4 text-center p-4 text-sm text-newGray-darkish">
                <form className="flex gap-2 w-full flex-col items-center" onSubmit={(e) => e.preventDefault()}>
                    {text && text.length > 0 && <p className="w-3/4 mb-4">{text}</p>}
                    {!hideConfirm && (
                        <div className="flex gap-2 w-full">
                            <Input
                                type={InputType.password}
                                autoFocus
                                autoComplete="off"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                placeholder="Your Password"
                                className="w-full"
                            />
                            <Button
                                onClick={() => password && onClick(password)}
                                text="white"
                                background="newTeal-main"
                                className="w-1/4"
                            >
                                Confirm
                            </Button>
                        </div>
                    )}
                </form>
            </div>
        </Window>
    );
};

export default AdminPasswordWindow;
