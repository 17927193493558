import React, { useEffect, useState } from 'react';

import Clause from './Clause';
import { ReactComponent as FiltersIcon } from '../../../assets/images/filters/sliders.svg';
import {
    DealDetailsFragment,
    UnitUse,
    useClauseSectionsQuery,
    useGetUserTypeQuery,
    useNewSectionGroupClauseMutation,
    useNewSectionGroupGroupClauseMutation,
    UserType,
    useSearchClauseBankQuery
} from '../../../generated/graphql';
import { Clauses, SortedClauses } from '../../../pages/Settings/ClauseBank';
import Acordian from '../../Accordian';
import { Button } from '../../button/index';
import { debounce, handleFetchError } from '../../helpers';
import useMobileScreen from '../../hooks/useMobileScreen';
import Input, { InputType } from '../../Inputs';
import Checkbox from '../../Inputs/Checkbox';
import Loading from '../../Loading';
import Window from '../../Window';

type AddClauseWindowProps = {
    dealID: string;
    deal?: DealDetailsFragment | null;
    organisationID: string;
    isTemplate: boolean;
    refetch: () => void;
    include?: {
        section?: string;
        level2Group?: string;
    } | null;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
    setShowNewClause: React.Dispatch<React.SetStateAction<boolean>>;
    templateUses?: UnitUse[];
    selectedAll: boolean;
};

export type AddClauseItem = {
    value: string;
    valueSuffix: string;
    shortForm: string;
    longForm: string;
    clauseRef: string;
    tags?: string[] | null;
};

const AddClauseWindow = ({
    dealID,
    deal,
    organisationID,
    isTemplate,
    refetch,
    include,
    setShowNewClause,
    templateUses,
    selectedAll,
    ...props
}: AddClauseWindowProps) => {
    const mobileScreen = useMobileScreen();

    const [clauses, setClauses] = useState<SortedClauses>({});
    const [allClauses, setAllClauses] = useState<SortedClauses>({});

    const [isUserLandlord, setUserLandlord] = useState<boolean>(false);

    const [searched, setSearched] = useState<boolean>(false);

    const [checkBoxValue, setCheckboxValue] = useState<string[]>([]);
    const [selectAll, setSelectAll] = useState<boolean>(false);
    const [selectedClauses, setSelectedClauses] = useState<SortedClauses>({});
    const [clausesNotSelected, setClausesNotSelected] = useState<SortedClauses>({});
    const [isSelectedAll, setIsSelectedAll] = useState<boolean>(false);

    const [showDrawer, setShowDrawer] = useState<boolean>(false);

    useEffect(() => {
        if (mobileScreen) setShowDrawer(mobileScreen);
    }, [mobileScreen]);

    // Get user type for the clause layout
    const { data: typeData } = useGetUserTypeQuery();

    const searchVariables = isUserLandlord
        ? {
              organisationID: organisationID ? [organisationID] : [],
              only: false,
              query: '',
              options: {
                  section: include?.section,
                  level2Group: include?.level2Group
              },
              filter: {
                  ...(deal?.unit?.use ? { use: deal?.unit?.use } : { uses: templateUses ?? [] })
              }
          }
        : {
              organisationID:
                  typeData?.user.type === UserType.Agent
                      ? [organisationID, typeData?.user.organisation._id]
                      : [organisationID],
              only: false,
              query: '',
              options: {
                  section: include?.section,
                  level2Group: include?.level2Group
              },
              filter: {
                  ...(deal?.unit?.use ? { use: deal?.unit?.use } : { uses: templateUses ?? [] })
              }
          };

    const {
        data: clauseData,
        loading: clauseLoading,
        error: clauseError,
        refetch: searchClauses
    } = useSearchClauseBankQuery({
        fetchPolicy: 'network-only',
        variables: searchVariables
    });

    const { data: clausesSections, loading: clausesSectionsLoading } = useClauseSectionsQuery({
        fetchPolicy: 'network-only',
        variables: {
            organisationID:
                typeData?.user.type === UserType.Agent
                    ? [organisationID, typeData?.user.organisation._id]
                    : [organisationID]
        }
    });

    useEffect(() => {
        // Check for errors
        handleFetchError('Could not load your clauses', clauseError, clauseData?.clauseBank);

        if (deal?.state.toString() === 'TENANT' && deal.currentUserParty.toString() === 'TENANT') {
            setUserLandlord(false);
        } else {
            // Set the type of user
            setUserLandlord(
                typeData?.user.__typename === 'User' &&
                    (typeData.user.type === UserType.Landlord ||
                        typeData?.user.type === UserType.Admin ||
                        typeData.user.type === UserType.Agent)
            );
        }
        // Sort clauses into sections/groups
        if (
            clauseData &&
            clausesSections &&
            Array.isArray(clauseData?.clauseBank) &&
            Array.isArray(clausesSections?.clauseBank)
        ) {
            return sortResponse(clauseData.clauseBank, clausesSections.clauseBank);
        }

        if (clauseData?.clauseBank.length === 0) {
            setSelectAll(false);

            setCheckboxValue([]);
        }
    }, [clauseData, clausesSections]);

    // If incldues are given, search straight away
    useEffect(() => {
        include && handleChange();
        // eslint-disable-next-line
    }, []);

    const clausesSelected = (includes: boolean) => {
        return Object.keys(allClauses)
            .filter((clause) => (includes ? checkBoxValue.includes(clause) : !checkBoxValue.includes(clause)))
            .reduce((cur, key) => {
                return Object.assign(cur, {
                    [key]: (searched ? clauses : allClauses)[key]
                });
            }, {});
    };

    useEffect(() => {
        setSelectedClauses(clausesSelected(true));
        setClausesNotSelected(clausesSelected(false));
        setIsSelectedAll(selectedClauses === allClauses);
    }, [checkBoxValue]);

    useEffect(() => {
        clauses && setIsSelectedAll(selectedClauses === allClauses);
        if (selectAll) {
            setSelectedClauses(allClauses);
            setCheckboxValue(
                Object.keys(allClauses).map((value) => {
                    return value;
                })
            );
            setClausesNotSelected({});
        } else if (
            !selectAll &&
            !searched &&
            checkBoxValue.length ===
                Object.keys(allClauses).map((value) => {
                    return value;
                }).length
        ) {
            setSelectedClauses({});
            setCheckboxValue([]);
            setClausesNotSelected(allClauses);
        }

        if (searched) {
            setCheckboxValue([]);
            setSelectAll(false);

            if (clauseData?.clauseBank.length !== 0) {
                setCheckboxValue(
                    Object.keys(clauses).map((value) => {
                        return value;
                    })
                );
            }
            if (clauseData?.clauseBank.length === 0) {
                setSelectAll(false);
                setCheckboxValue([]);
            }
        }
    }, [selectAll, clauses, allClauses, searched]);

    const handleChange = debounce((value?: string) => {
        if (value && value.length >= 3) {
            searchClauses({
                organisationID: organisationID ? [organisationID] : [],
                only: false,
                query: value,
                options: {}
            });
            setSearched(true);
        } else {
            setSearched(false);
        }
    }, 250);

    // New combined mutations
    const [newSectionGroupClause, { loading: newSectionGroupClauseLoading }] = useNewSectionGroupClauseMutation();
    const [newSectionGroupGroupClause, { loading: newSectionGroupGroupClauseLoading }] =
        useNewSectionGroupGroupClauseMutation();

    const handleAddClauseClick = async (
        parentId: string,
        clause: AddClauseItem,
        section?: string,
        sectionDescription?: string,
        sectionRics?: string,
        groupDescription?: string,
        groupRics?: string,
        level1Group?: string,
        level2Group?: string,
        linkedClauses?: {
            _id: string;
            value: string;
            valueSuffix: string;
            shortForm: string;
            longForm: string;
            tags: string[];
        }[]
    ) => {
        let clauseID: string | null = null;
        if (level2Group) {
            const { data: newSectionGroupGroupClauseData } = await newSectionGroupGroupClause({
                variables: {
                    dealID,
                    sectionDescription,
                    sectionRics,
                    groupDescription,
                    groupRics,
                    sectionTitle: section ?? 'Untitled Section',
                    groupTitle: level1Group ?? 'Untitled Group',
                    clauseGroupTitle: level2Group,
                    clauseIndex: -1,
                    value: clause.value,
                    valueSuffix: clause.valueSuffix,
                    shortForm: clause.shortForm,
                    longForm: clause.longForm,
                    tags: clause.tags,
                    linked: linkedClauses,
                    clauseRef: clause.clauseRef
                }
            });

            if (newSectionGroupGroupClauseData?.dealSectionGroupClauseCreate.__typename === 'Clause')
                clauseID = newSectionGroupGroupClauseData?.dealSectionGroupClauseCreate._id;
        } else {
            const { data: newSectionGroupClauseData } = await newSectionGroupClause({
                variables: {
                    dealID,
                    sectionDescription,
                    groupDescription,
                    sectionTitle: section ?? 'Untitled Section',
                    groupTitle: level1Group ?? 'Untitled Group',
                    sectionRics,
                    groupRics,
                    clauseIndex: -1,
                    value: clause.value,
                    valueSuffix: clause.valueSuffix,
                    shortForm: clause.shortForm,
                    longForm: clause.longForm,
                    tags: clause.tags,
                    linkedClauses: linkedClauses,
                    clauseRef: clause.clauseRef
                }
            });

            if (newSectionGroupClauseData?.dealSectionGroupClauseCreate.__typename === 'Clause')
                clauseID = newSectionGroupClauseData?.dealSectionGroupClauseCreate._id;
        }

        refetch();
        // If the user is a tenant, close the window straight away
        !isUserLandlord && props.setShow(false);

        // Scroll to new clause
        if (clauseID) {
            setTimeout(() => {
                const clauseElement = document.getElementById(clauseID ?? '');

                if (!clauseElement) return;

                clauseElement?.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center'
                });

                // Open group to show new clause
                const groupElement = clauseElement?.parentElement?.parentElement;
                if (groupElement) {
                    groupElement.classList.remove('overflow-hidden');
                    groupElement.style.maxHeight = '1300px';
                }
            }, 1000);
        }
    };

    const sortClauses = (results: Clauses) => {
        let sortedClauses: SortedClauses = {};
        results.map((clause) => {
            let sectionName = clause?.section?.title ?? '';
            let groupName = clause?.section?.group.title ?? '';
            sortedClauses[sectionName] = sortedClauses[sectionName] ? sortedClauses[sectionName] : {};
            sortedClauses[sectionName][groupName] = sortedClauses[sectionName][groupName]
                ? sortedClauses[sectionName][groupName]
                : [];
            return sortedClauses[sectionName][groupName].push(clause);
        });
        return sortedClauses;
    };

    // Sort clauses into sections and groups
    const sortResponse = (results: Clauses, sectionNames: Clauses) => {
        let sortedClauses: SortedClauses = {};
        let sortedSectionNames: SortedClauses = {};

        if (!clauseLoading && results && results.length > 0) {
            sortedClauses = sortClauses(results);
            setClauses(sortedClauses);
        }

        if (!clausesSectionsLoading && sectionNames && sectionNames.length > 0) {
            sortedSectionNames = sortClauses(sectionNames);
            setAllClauses(sortedSectionNames);
        }

        if (include && !searched) {
            setSelectAll(false);
            include.section && setCheckboxValue([include.section]);
            setClausesNotSelected(allClauses);
        } else if (!searched) {
            setSelectAll(selectedAll);
        }
    };

    if (newSectionGroupClauseLoading || newSectionGroupGroupClauseLoading)
        return (
            <Window title="Add Clause" width="w-3/5" {...props}>
                <Loading />
            </Window>
        );

    return (
        <div className="hello-add-clause">
            <Window title="Add Clause" width="w-3/5" scrollContent={true} {...props}>
                <div className={`${mobileScreen ? 'h-full' : ''} flex flex-row`}>
                    {typeData?.user.type !== UserType.Tenant && (
                        <div
                            className={`flex flex-col bg-navy-lightest ${mobileScreen ? 'w-full' : 'w-72'} ${
                                mobileScreen ? (showDrawer ? '' : 'hidden') : ''
                            }`}
                        >
                            {mobileScreen && (
                                <button
                                    className="bg-newTeal-main mt-7  mx-3 h-10 font-semibold rounded"
                                    onClick={() => setShowDrawer(!showDrawer)}
                                >
                                    Apply
                                </button>
                            )}
                            <div
                                onClick={() => searched && setSearched(false)}
                                className={` w-44 h-10 my-4 flex align-center bg-newTeal-main text-white items-center ml-3 rounded`}
                            >
                                <Checkbox
                                    label={isSelectedAll ? 'Deselect All' : 'Select All'}
                                    setValue={() => setSelectAll(!selectAll)}
                                    value={isSelectedAll}
                                    className="w-full flex flex-row-reverse justify-end font-semibold"
                                    justifyEnd={true}
                                    reverse={true}
                                    inputClassName="mr-3"
                                />
                            </div>
                            <div
                                className="flex flex-col overflow-auto"
                                style={{
                                    height: mobileScreen ? 'auto' : '32rem',
                                    minHeight: '10rem'
                                }}
                            >
                                {Object.keys(selectedClauses).map((_, sectionIndex) => {
                                    let section = Object.keys(selectedClauses).sort()[sectionIndex];
                                    return (
                                        <>
                                            <Checkbox
                                                label={section}
                                                setValue={() => {
                                                    setCheckboxValue(
                                                        checkBoxValue &&
                                                            checkBoxValue.filter((item) => item !== section)
                                                    );
                                                }}
                                                value={!!checkBoxValue.find((item) => item === section)}
                                                className="w-full flex flex-row-reverse justify-end pl-6 font-semibold"
                                                justifyEnd={true}
                                                inputClassName="mr-3"
                                            />
                                        </>
                                    );
                                })}
                                {Object.keys(selectedClauses).length > 0 && (
                                    <div className="border-b border-newGray-main w-40 items-center ml-6 mr-2" />
                                )}
                                {Object.keys(clausesNotSelected).map((_, sectionIndex) => {
                                    let section = Object.keys(clausesNotSelected).sort()[sectionIndex];
                                    return (
                                        <div
                                            onClick={() => {
                                                searched && setSearched(false);
                                            }}
                                        >
                                            <Checkbox
                                                label={section}
                                                setValue={() => {
                                                    setCheckboxValue([...(checkBoxValue as []), section]);
                                                }}
                                                value={!!checkBoxValue.find((item) => item === section)}
                                                className="w-full flex flex-row-reverse justify-end pl-6 font-semibold"
                                                justifyEnd={true}
                                                inputClassName="mr-3"
                                            />
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    )}
                    <div
                        className={` ${
                            mobileScreen
                                ? showDrawer && typeData?.user.type !== UserType.Tenant
                                    ? 'hidden'
                                    : 'mt-6 pt-2'
                                : ''
                        } relative bg-navy-light w-full overflow-auto`}
                        style={{
                            height: mobileScreen ? '100%' : '38rem',
                            minHeight: '10rem'
                        }}
                    >
                        {/* Search Input */}
                        <form
                            className={`flex flex-col bg-navy-light border-navy-light border-b p-2 sticky top-0 ${
                                mobileScreen ? '' : 'z-10'
                            }`}
                            onSubmit={(event) => {
                                event.preventDefault();
                            }}
                        >
                            <div className="flex flex-row w-full items-center">
                                <Input
                                    type={InputType.text}
                                    placeholder="Search Clauses"
                                    className="w-full bg-navy-lightest"
                                    autoFocus
                                    onChange={(e) => handleChange(e.target.value)}
                                />
                                {mobileScreen && typeData?.user.type !== UserType.Tenant && (
                                    <Button
                                        background="white"
                                        text="black"
                                        className="filter-button-size ml-2"
                                        onClick={() => setShowDrawer(true)}
                                    >
                                        <FiltersIcon />
                                    </Button>
                                )}
                            </div>
                        </form>
                        <div className=" relative">
                            {/* Search in progress */}
                            {clauseLoading ? (
                                <Loading />
                            ) : isUserLandlord ? (
                                clauseData ? (
                                    <>
                                        {selectedClauses &&
                                            Object.keys(
                                                typeData?.user.type === UserType.Tenant ? clauses : selectedClauses
                                            ).map((_, sectionIndex) => {
                                                let section = Object.keys(selectedClauses).sort()[sectionIndex];
                                                let groups =
                                                    section &&
                                                    selectedClauses &&
                                                    Object.keys(selectedClauses[section]).sort();

                                                return (
                                                    <div
                                                        className="mx-2 mt-2 bg-white rounded px-6 py-4"
                                                        key={sectionIndex}
                                                    >
                                                        <Acordian
                                                            title={section}
                                                            className="bg-white font-semibold"
                                                            isOpen={true}
                                                            key={sectionIndex}
                                                            isReordering={false}
                                                        >
                                                            {groups &&
                                                                groups.map((_, groupIndex) => {
                                                                    let group = groups[groupIndex];
                                                                    return (
                                                                        <div
                                                                            id={
                                                                                sectionIndex.toString() +
                                                                                groupIndex.toString()
                                                                            }
                                                                        >
                                                                            <Acordian
                                                                                title={group}
                                                                                className="bg-navy-lightest mt-3 p-4 py-3 font-semibold"
                                                                                key={groupIndex}
                                                                                isReordering={false}
                                                                            >
                                                                                {selectedClauses[section][group].map(
                                                                                    (clause) => (
                                                                                        <div
                                                                                            className="w-full bg-white rounded -my-px mb-2 mt-3"
                                                                                            key={clause._id}
                                                                                        >
                                                                                            <Clause
                                                                                                parentId={
                                                                                                    sectionIndex.toString() +
                                                                                                    groupIndex.toString()
                                                                                                }
                                                                                                id={clause._id}
                                                                                                isLandlordClause={true}
                                                                                                key={clause._id}
                                                                                                shortForm={
                                                                                                    clause.shortForm ??
                                                                                                    ''
                                                                                                }
                                                                                                longForm={
                                                                                                    clause.longForm ??
                                                                                                    ''
                                                                                                }
                                                                                                tags={clause.tags ?? []}
                                                                                                value={clause.value}
                                                                                                valueSuffix={
                                                                                                    clause.valueSuffix ??
                                                                                                    ''
                                                                                                }
                                                                                                section={
                                                                                                    clause?.section
                                                                                                        ?.title
                                                                                                }
                                                                                                sectionDescription={
                                                                                                    clause?.section
                                                                                                        ?.description ||
                                                                                                    undefined
                                                                                                }
                                                                                                sectionRics={
                                                                                                    clause?.section
                                                                                                        ?.rics ||
                                                                                                    undefined
                                                                                                }
                                                                                                groupDescription={
                                                                                                    clause?.section
                                                                                                        ?.group
                                                                                                        .description ||
                                                                                                    undefined
                                                                                                }
                                                                                                groupRics={
                                                                                                    clause?.section
                                                                                                        ?.group.rics ||
                                                                                                    undefined
                                                                                                }
                                                                                                level1Group={
                                                                                                    clause?.section
                                                                                                        ?.group.title
                                                                                                }
                                                                                                level2Group={
                                                                                                    clause?.section
                                                                                                        ?.group.group
                                                                                                        ?.title ??
                                                                                                    undefined
                                                                                                }
                                                                                                linkedClauses={
                                                                                                    clause.linked
                                                                                                        ?.results ?? []
                                                                                                }
                                                                                                onClick={
                                                                                                    handleAddClauseClick
                                                                                                }
                                                                                            />
                                                                                        </div>
                                                                                    )
                                                                                )}
                                                                            </Acordian>
                                                                        </div>
                                                                    );
                                                                })}
                                                        </Acordian>
                                                    </div>
                                                );
                                            })}

                                        {/* *Pinned* Can't find what you're looking for */}
                                        <div className="bg-navy-light sticky bottom-0 py-1 z-10 w-full left-0 right-0 flex justify-center border-t border-navy-light">
                                            <Button
                                                text="white"
                                                className="border-none bg-newTeal-main text white text-left rounded font-semibold flex px-2 hover:shadow-lg transitionAll"
                                                onClick={() => {
                                                    setShowNewClause(true);
                                                    props.setShow(false);
                                                }}
                                            >
                                                ‍
                                                <span
                                                    className="text-2xl mr-1"
                                                    role="img"
                                                    aria-label="man-shrugging emoji"
                                                >
                                                    🤷‍♂️
                                                </span>
                                                <span className="pt-px text-sm">
                                                    Can't find what you're looking for?
                                                </span>
                                            </Button>
                                        </div>
                                        {/* <div className="p-3 bg-gray-200">
                                        <button
                                            className="p-3 px-4 text-sm bg-navy-light text-left rounded font-semibold flex"
                                            onClick={() => {
                                                setShowNewClause(true)
                                                props.setShow(false)
                                            }}
                                        >
                                            Can't find what you're looking for?
                                            <ChevronRight className="w-5 h-5 ml-2" />
                                        </button>
                                    </div> */}
                                    </>
                                ) : (
                                    <div className="w-full h-full flex justify-center items-center -mt-20 -mt-0">
                                        <p className="text-sm text-center w-full mt-8 z-10">Search to see clauses</p>
                                    </div>
                                )
                            ) : (
                                /* Search Results */
                                <div
                                    className={`relative w-full ${
                                        !clauseData && 'h-full flex justify-center items-center -mt-20 -mt-0'
                                    }`}
                                >
                                    {clauseData && (isUserLandlord || searched) ? (
                                        <>
                                            {clauseData.clauseBank?.map(
                                                (clause) =>
                                                    clause &&
                                                    (include
                                                        ? clause?.section?.title === include.section &&
                                                          (include.level2Group
                                                              ? clause?.section?.group.group?.title ===
                                                                include.level2Group
                                                              : true)
                                                        : true) && (
                                                        <Clause
                                                            parentId={clause._id}
                                                            id={clause._id}
                                                            isLandlordClause={false}
                                                            key={clause._id}
                                                            shortForm={clause.shortForm ?? ''}
                                                            sectionDescription={clause?.section?.description ?? ''}
                                                            sectionRics={clause?.section?.rics ?? ''}
                                                            groupDescription={clause?.section?.group.description ?? ''}
                                                            groupRics={clause?.section?.group.rics ?? ''}
                                                            longForm={clause.longForm ?? ''}
                                                            tags={clause.tags ?? []}
                                                            value={clause.value}
                                                            valueSuffix={clause.valueSuffix ?? ''}
                                                            section={clause?.section?.title}
                                                            level1Group={clause?.section?.group.title}
                                                            level2Group={
                                                                clause?.section?.group.group?.title ?? undefined
                                                            }
                                                            linkedClauses={clause.linked?.results ?? []}
                                                            onClick={handleAddClauseClick}
                                                        />
                                                    )
                                            )}

                                            {/* *Pinned* Can't find what you're looking for */}
                                            <div className="bg-navy-lightest sticky bottom-0 py-1 z-10 w-full left-0 right-0 flex justify-center border-t border-navy-light">
                                                <Button
                                                    className="border-none bg-newTeal-main text-white text-left rounded font-semibold flex px-2 hover:shadow-lg transitionAll"
                                                    onClick={() => {
                                                        setShowNewClause(true);
                                                        props.setShow(false);
                                                    }}
                                                >
                                                    ‍
                                                    <span
                                                        className="text-2xl mr-1"
                                                        role="img"
                                                        aria-label="man-shrugging emoji"
                                                    >
                                                        🤷‍♂️
                                                    </span>
                                                    <span className="pt-px text-sm">
                                                        Can't find what you're looking for?
                                                    </span>
                                                </Button>
                                            </div>
                                            {/* <div className="p-3 bg-gray-200">
                                            <button
                                                className="p-3 px-4 text-sm bg-navy-light text-left rounded font-semibold flex"
                                                onClick={() => {
                                                    setShowNewClause(true)
                                                    props.setShow(false)
                                                }}
                                            >
                                                Can't find what you're looking
                                                for?
                                                <ChevronRight className="w-5 h-5 ml-2" />
                                            </button>
                                        </div> */}
                                        </>
                                    ) : (
                                        <p className="text-sm text-center w-full mt-8 z-10">Search to see clauses</p>
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </Window>
        </div>
    );
};

export default AddClauseWindow;
