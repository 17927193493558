import React from 'react';

import { DealUserParty } from '../../../../../../generated/graphql';
import { AddButton } from '../../../../../../shared/add-button';

type DealActorsAddMemberButtonProps = {
    currentParty?: false | DealUserParty | null | undefined;
    type: 'Landlord' | 'Tenant';
    setShowAddUserWindow: React.Dispatch<React.SetStateAction<boolean>>;
};

const DealActorsAddMemberButton = ({ type, currentParty, setShowAddUserWindow }: DealActorsAddMemberButtonProps) => {
    if (currentParty && currentParty.toLowerCase() === type.toLowerCase()) {
        return (
            <div className="py-1 flex justify-center bg-newTeal-main">
                <AddButton
                    primary
                    onClick={() => setShowAddUserWindow(true)}
                    className="py-4 text-base bg-newTeal-main border-none text-white"
                >
                    Add User
                </AddButton>
            </div>
        );
    } else {
        return <></>;
    }
};

export default DealActorsAddMemberButton;
