import React, { useEffect, useState } from 'react';

import Clause from './Clause';
import { useSwapClauseMutation } from '../../../generated/graphql';
import { Button } from '../../button';
import { handleFetchError } from '../../helpers';
import Loading from '../../Loading';
import Window from '../../Window';
import { LinkedClauses } from '../Clause';

type SwapClauseWindowProps = {
    dealID: string;
    parentID: string;
    clauseID: string;
    currentValue?: string;
    currentValueSuffix?: string;
    currentShortForm?: string;
    currentLongForm?: string;
    currentTags: string[];
    section: string;
    group: string;
    clauseGroup?: string | null;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
    setShowCantFind?: React.Dispatch<React.SetStateAction<boolean>>;
    linkedClauses: LinkedClauses;
    isTemplate?: boolean;
};

const SwapClauseWindow = ({
    dealID,
    parentID,
    clauseID,
    currentValue,
    currentValueSuffix,
    currentShortForm,
    currentLongForm,
    currentTags,
    section,
    group,
    clauseGroup,
    linkedClauses,
    setShowCantFind,
    isTemplate,
    ...props
}: SwapClauseWindowProps) => {
    const [clauses, setClauses] = useState<LinkedClauses>([]);
    const [valueIsRequired, setValueIsRequired] = useState<boolean>(false);

    useEffect(() => {
        setClauses(linkedClauses);
    }, [linkedClauses]);

    const [swapClause, { error: swapClauseError, loading: swapClauseLoading }] = useSwapClauseMutation();

    const handleSwapClauseClick = async (
        value?: string,
        valueSuffix?: string,
        shortForm?: string,
        longForm?: string,
        tags?: string[]
    ) => {
        if (!isTemplate && valueSuffix && value === '*') {
            setValueIsRequired(true);
            return;
        }
        const { data: swapClauseData } = await swapClause({
            variables: {
                dealID,
                parentID,
                clauseID,
                value,
                valueSuffix,
                shortForm,
                longForm,
                tags
            }
        });

        if (swapClauseError) {
            return handleFetchError('Could not swap the clause', swapClauseError, swapClauseData?.dealClauseUpdate);
        }

        props.setShow(false);
    };

    if (swapClauseLoading)
        return (
            <Window title="Suggested Alternatives" width="w-2/5" {...props}>
                <Loading />
            </Window>
        );

    return (
        <Window title="Suggested Alternatives" width="w-2/5" {...props}>
            <div className="h-full md:h-96 bg-navy-lightest">
                {/* Search Input */}
                <form
                    className="flex flex-col bg-white border-navy-light border-b p-2 pt-4 md:pt-0"
                    onSubmit={(event) => {
                        event.preventDefault();
                    }}
                >
                    <div className="pt-2 pb-1">
                        <span className="bg-navy-light rounded py-2 px-4 mr-2 text-black text-sm text-center">
                            {section}
                        </span>
                        <span className="bg-navy-light rounded py-2 px-4 mr-2 text-black text-sm text-center">
                            {group}
                        </span>
                        {clauseGroup && (
                            <span className="bg-navy-light rounded py-2 px-4 text-black text-sm text-center">
                                {clauseGroup}
                            </span>
                        )}
                    </div>
                </form>

                {/* Search Results */}
                <div className="w-full">
                    {clauses?.map(
                        (clause, index) =>
                            // The list of related clauses includes the current clause, so it needs to be filtered out
                            // This allows clauses to be swapped backwards and forwards without actually needing to do it (ie. we just change the field values)
                            clause &&
                            !(
                                clause.valueSuffix === currentValueSuffix &&
                                clause.shortForm === currentShortForm &&
                                clause.longForm === currentLongForm
                            ) && (
                                <Clause
                                    valueIsRequired={valueIsRequired}
                                    key={clause.shortForm ?? index}
                                    shortForm={clause.shortForm ?? ''}
                                    longForm={clause.longForm ?? ''}
                                    tags={clause.tags ?? []}
                                    currentValue={currentValue}
                                    currentValueSuffix={currentValueSuffix}
                                    currentLongForm={currentLongForm}
                                    currentTags={currentTags}
                                    value={clause.value ?? ''}
                                    valueSuffix={clause.valueSuffix ?? ''}
                                    onClick={handleSwapClauseClick}
                                />
                            )
                    )}
                    {setShowCantFind && (
                        <div className="bg-navy-lightest sticky bottom-0 py-1 z-10 w-full left-0 right-0 flex justify-center border-t border-navy-light">
                            <Button
                                className="border-none bg-newTeal-main text-white text-left rounded font-semibold flex px-2 hover:shadow-lg transitionAll"
                                onClick={() => {
                                    setShowCantFind(true);
                                    props.setShow(false);
                                }}
                            >
                                Edit Existing Clause
                            </Button>
                        </div>
                    )}
                </div>
            </div>
        </Window>
    );
};

export default SwapClauseWindow;
