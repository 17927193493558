import React from 'react';

import { ReactComponent as Close } from '../../assets/images/cross/black.svg';
import { ReactComponent as More } from '../../assets/images/more/horizontal/black.svg';

export type CardDropdownOptions = {
    title: string;
    onClick: () => void;
    disabled?: boolean;
}[];

type CardDropdownProps = {
    options: CardDropdownOptions;
    dropdownVisible: boolean;
    setDropdownVisible: React.Dispatch<React.SetStateAction<boolean>>;
    verticalOptions?: boolean;
    showClientName?: boolean;
};

const CardDropdown = ({
    options,
    dropdownVisible,
    setDropdownVisible,
    verticalOptions,
    showClientName
}: CardDropdownProps) => {
    return (
        <div className={`absolute top-0 right-0 ${showClientName ? 'mt-4' : ' -mt-2'} -mr-3 `}>
            <div className="flex flex-row">
                {/* Trigger */}
                <button
                    style={{ zIndex: 10 }}
                    data-testid="card--options-button"
                    className="text-center flex flex-row float-right focus:outline-none p-2  "
                    onClick={() => setDropdownVisible(!dropdownVisible)}
                >
                    {!dropdownVisible ? (
                        <More
                            className={`${
                                verticalOptions ? 'w-7 h- mr-2 mt-4  rotate-90 transform' : 'w-6 h-6 mr-4 mt-2'
                            } text-sm`}
                        />
                    ) : (
                        <Close
                            className={`${verticalOptions ? 'h-8' : 'h-6'} w-6  mr-4 text-sm`}
                            style={{ marginTop: '8px' }}
                        />
                    )}
                </button>
                {/* Dropdown */}
                <div
                    style={{ zIndex: 100 }}
                    data-testid="card--options-dropdown"
                    className={`bg-white ${
                        dropdownVisible ? 'visible' : 'invisible'
                    } bg-white visible flex flex-col border border-newTeal-main rounded items-center float-right shadow-lg absolute right-0 top-0 mt-11 mr-4`}
                >
                    {options.map((option, i) => (
                        <span
                            onClick={() => {
                                option.onClick();
                                setDropdownVisible(false);
                            }}
                            key={option.title}
                            className={`${i < options.length - 1 && 'border-b'} ${
                                option.disabled ? 'opacity-50' : ''
                            } px-10 py-3 border-navy-light cursor-pointer hover:bg-navy-lightest w-full text-center`}
                        >
                            {option.title}
                        </span>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default CardDropdown;
