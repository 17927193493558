import React from 'react';

import DealAgreedWindow from './DealAgreedWindow';
import MakingChangesWindow from './MakingChangesWindow';
import { DealSendType, GetDealDetailsQuery } from '../../../../generated/graphql';
import { isTenantSide } from '../../../../shared/helpers';
import AddClauseWindow from '../../../../shared/Negotiator/AddClauseWindow';
import NewClauseWindow from '../../../../shared/Negotiator/NewClauseWindow';
import { AddClauseGroupInput } from '../../../Templates/Template';
import NewTenantCompanyWindow from '../../NewTenantCompanyWindow';

type NegotiateWindowsType = {
    showAddClauseWindow: boolean;
    setShowAddClauseWindow: React.Dispatch<React.SetStateAction<boolean>>;
    showFilterAddClauseWindow: AddClauseGroupInput | undefined;
    setShowFilterAddClauseWindow: React.Dispatch<React.SetStateAction<AddClauseGroupInput | undefined>>;
    showNewClauseWindow: boolean;
    setShowNewClauseWindow: React.Dispatch<React.SetStateAction<boolean>>;
    showDealAgreedWindow: boolean;
    setShowDealAgreedWindow: React.Dispatch<React.SetStateAction<boolean>>;
    setShowLowReadinessWarning: React.Dispatch<React.SetStateAction<boolean>>;
    detailsData: GetDealDetailsQuery | undefined;
    setShareWindowType: React.Dispatch<React.SetStateAction<DealSendType>>;
    setForceOpenShareWindow: React.Dispatch<React.SetStateAction<boolean>>;
    refetch: any;
    openDetails: {
        section?: string;
        level2Group?: string;
    } | null;
    showActivateTenant: boolean;
    setShowActivateTenant: React.Dispatch<React.SetStateAction<boolean>>;
    showMakingChanges: boolean;
    setShowMakingChanges: React.Dispatch<React.SetStateAction<boolean>>;
};

const NegotiateWindows = ({
    // Add Clause Window
    showAddClauseWindow,
    setShowAddClauseWindow,
    // Filter Add Clause Widnow
    showFilterAddClauseWindow,
    setShowFilterAddClauseWindow,
    // New Clause Window
    showNewClauseWindow,
    setShowNewClauseWindow,
    // Deal Agreed Window
    showDealAgreedWindow,
    setShowDealAgreedWindow,
    // Others
    setShowLowReadinessWarning,
    detailsData,
    setShareWindowType,
    setForceOpenShareWindow,
    refetch,
    openDetails,
    showActivateTenant,
    setShowActivateTenant,
    showMakingChanges,
    setShowMakingChanges
}: NegotiateWindowsType) => {
    return (
        <>
            {(showAddClauseWindow || showFilterAddClauseWindow) && detailsData?.deal.__typename === 'Deal' && (
                <AddClauseWindow
                    dealID={detailsData?.deal._id}
                    deal={detailsData.deal}
                    organisationID={detailsData.deal.unit?.organisation._id ?? ''}
                    isTemplate={false}
                    refetch={refetch}
                    setShowNewClause={setShowNewClauseWindow}
                    setShow={(input) => {
                        showFilterAddClauseWindow
                            ? setShowFilterAddClauseWindow(undefined)
                            : setShowAddClauseWindow(input);
                    }}
                    include={!isTenantSide(detailsData.deal) ? openDetails : null}
                    selectedAll={true}
                />
            )}
            {showDealAgreedWindow && detailsData?.deal.__typename === 'Deal' && (
                <DealAgreedWindow
                    setForceReadinessClose={setShowLowReadinessWarning}
                    setShow={setShowDealAgreedWindow}
                    deal={detailsData.deal}
                    currentUserParty={detailsData.deal.currentUserParty}
                    setShareWindowType={setShareWindowType}
                    setShowSendWindow={setForceOpenShareWindow}
                />
            )}
            {showNewClauseWindow && detailsData?.deal.__typename === 'Deal' && (
                <NewClauseWindow
                    sections={detailsData?.deal?.sections ?? []}
                    dealID={detailsData?.deal._id}
                    organisationID={detailsData.deal.unit?.organisation._id ?? ''}
                    simple
                    refetch={refetch}
                    setShow={setShowNewClauseWindow}
                />
            )}
            {showActivateTenant && detailsData?.deal.__typename === 'Deal' && (
                <NewTenantCompanyWindow
                    deal={detailsData.deal}
                    setNewTenantCompanyWindow={() => setShowActivateTenant(false)}
                />
            )}
            {showMakingChanges && detailsData?.deal.__typename === 'Deal' && (
                <MakingChangesWindow deal={detailsData.deal} setShow={setShowMakingChanges} />
            )}
        </>
    );
};

export default NegotiateWindows;
