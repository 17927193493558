import React from 'react';

import DefaultProfile from './DefaultProfile';
import { proxyImage } from '../../../shared/helpers';

type ProfileProps = {
    src: string | any;
    name: string;
    long?: boolean;
    className?: string;
    size?: string;
    fontSize?: string;
    headerClass?: string;
};

const Profile = ({ src, name, long, className, size, fontSize, headerClass }: ProfileProps) => {
    return (
        <div className={`flex-none ${headerClass}`}>
            {src && src.length > 0 ? (
                <div data-testid="layout--profile" onClick={() => {}}>
                    <img
                        className={`m-auto object-cover rounded ${className}`}
                        style={{
                            width: size ?? '66px',
                            height: size ?? '66px'
                        }}
                        src={proxyImage(src, 200, 200, 80)}
                        alt="{User Name}"
                        loading="lazy"
                    />
                </div>
            ) : (
                <DefaultProfile name={name} long={long} size={size ?? '66px'} fontSize={fontSize} />
            )}
        </div>
    );
};

export default Profile;
