import React, { useEffect, useRef } from 'react';

import DoughnutChart from './DoughnutChart';

export const DoughnutChartComponent = ({ data }) => {
    const ref = useRef(null);

    useEffect(() => {
        if (ref.current) {
            DoughnutChart(ref.current, data);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ref]);

    return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <div ref={ref} />
        </div>
    );
};
