import React, { useState } from 'react';

import ToolbarContext from './Context';
import { WalkthroughClause } from '..';

type ToolBarProps = {
    clauses: WalkthroughClause[];
    children: React.ReactNode;
};

const ToolBarProvider = ({ clauses, children }: ToolBarProps) => {
    // Current Clause
    const [cursor, setCursor] = useState<string | null>(null);

    // Handle Next Clause Button Click
    const handleNextClauseClick = () => {
        const nextClause = getNextClause(cursor);

        // Deactivate all other clauses
        clauses.map((clause) => clause.id !== nextClause?.id && deactivateClause(clause.id, clause.accordianID));

        // Activate selected clause
        if (nextClause) {
            activateClause(nextClause?.id, nextClause?.accordianID);
            setCursor(nextClause.id);
        }
    };

    /**
     * Find the current clause
     * @param cursor
     */
    const getNextClause = (cursor: string | null) => {
        const index = cursor ? clauses.findIndex((clause) => clause.id === cursor) + 1 : 0;

        const preCursor = [...clauses].slice(0, index);
        const postCursor = [...clauses].splice(index);
        const loopedClauses = postCursor.concat(preCursor);

        // Find the next incomplete clause after the current clause
        return loopedClauses.find((clause) => !clause.complete);
    };

    // Helper: Activate a clause
    const activateClause = (id: string, accordianID: string) => {
        const clauseElement = document.getElementById(id);
        clauseElement?.scrollIntoView({
            behavior: 'smooth',
            block: 'center'
        });
        clauseElement?.classList.add('shadow-glow');
        clauseElement?.classList.add('border-newTeal-main');
        clauseElement?.children[0]?.classList.add('walkthroughFocused');

        // Remove glow after 2 seconds
        setTimeout(() => clauseElement?.classList.remove('shadow-glow'), 1500);

        // Remove overflow hidden from parent element to show shadow
        const accordianElement = document.getElementById(accordianID);
        if (accordianElement) {
            accordianElement.classList.remove('overflow-hidden');
            accordianElement.style.maxHeight = '8000px';
        }
    };

    // Helper: Deactivate a clause
    const deactivateClause = (id: string, accordianID: string) => {
        const clauseElement = document.getElementById(id);
        clauseElement?.classList.remove('shadow-glow');
        clauseElement?.classList.remove('border-newTeal-main');
        clauseElement?.children[0]?.classList.remove('walkthroughFocused');

        // Set overflow hidden from parent element to reset accordian
        const accordianElement = document.getElementById(accordianID);
        accordianElement?.classList.add('overflow-hidden');
    };

    return (
        <ToolbarContext.Provider
            value={{
                cursor,
                setCursor,
                handleNextClauseClick
            }}
        >
            {children}
        </ToolbarContext.Provider>
    );
};

export default ToolBarProvider;
