import React from 'react';

import { ReactComponent as Delete } from '../../../assets/images/delete/user/black.svg';
import { ReactComponent as Envelope } from '../../../assets/images/envelope/black.svg';
import Options from '../../../shared/Options';

type PendingMemberProps = {
    children: string;
    organisationID: string;
    setMemberEmail: React.Dispatch<React.SetStateAction<string | undefined>>;
    setOrganisationID: React.Dispatch<React.SetStateAction<string | null>>;
    setShowRemoveMemberPopup: React.Dispatch<React.SetStateAction<boolean>>;
    setShowUserInviteWindow: React.Dispatch<React.SetStateAction<boolean>>;
};

const PendingMember = ({
    children: email,
    organisationID,
    setMemberEmail,
    setOrganisationID,
    setShowRemoveMemberPopup,
    setShowUserInviteWindow
}: PendingMemberProps) => {
    return (
        <div className="flex items-center justify-between w-full bg-white rounded px-3 py-2 mb-2 relative">
            <p className="text-sm">{email}</p>
            <Options>
                {[
                    {
                        title: 'Remove',
                        action: () => {
                            setMemberEmail(email);
                            setOrganisationID(organisationID);
                            setShowRemoveMemberPopup(true);
                        },
                        icon: Delete
                    },
                    {
                        title: 'Send Invite',
                        action: () => {
                            setMemberEmail(email);
                            setShowUserInviteWindow(true);
                        },
                        icon: Envelope
                    }
                ]}
            </Options>
        </div>
    );
};

export default PendingMember;
