import React, { useContext } from 'react';

import ClauseContext from './Context';
import { ReactComponent as Undo } from '../../../../../assets/images/undo/black.svg';
import {
    GetNegotiationSummaryDocument,
    UndoClauseMutation,
    useUndoClauseMutation
} from '../../../../../generated/graphql';
import { handleFetchError } from '../../../../../shared/helpers';
import { DealType } from '../../../../../shared/Negotiator/ConfirmClauseDeleteWindow.tsx';
import useDeleteClause from '../../../../../shared/Negotiator/ConfirmClauseDeleteWindow.tsx/useDeleteClause';
import { DealContext } from '../../context';

type ClauseUndoProps = {
    hideClause?: React.Dispatch<React.SetStateAction<boolean>>;
};

const ClauseUndo = ({ hideClause }: ClauseUndoProps) => {
    const { id, parentID, dealID } = React.useContext(ClauseContext);

    const [undoClause, { error: undoClauseError, loading: undoClauseLoading }] = useUndoClauseMutation();

    const updateCache = useDeleteClause(DealType.DEAL);

    const { setSeenDealAgreed } = useContext(DealContext);

    const handleUndo = async () => {
        const { data } = await undoClause({
            variables: {
                clauseID: id!,
                parentID: parentID!,
                dealID: dealID!
            },
            refetchQueries: [
                {
                    query: GetNegotiationSummaryDocument,
                    variables: {
                        id: dealID
                    }
                }
            ],
            update(store, { data }) {
                if (!data || !hideClause) return;

                updateCache<UndoClauseMutation>(
                    store,
                    data.dealClauseUndo.__typename === 'Clause' ? data.dealClauseUndo._id : '',
                    dealID
                );
                // Hacky fix for the FlipMove bug with undoing added clauses
                hideClause(true);
            },
            onCompleted: () => setSeenDealAgreed(false)
        });

        handleFetchError('Failed to undo', undoClauseError, data?.dealClauseUndo);
    };

    return (
        <div className="xl:pr-4 xl:mr-4 ">
            <button
                className={`focus:outline-none px-2 group py-1 bg-navy-lightestrounded flex h-8 flex-col border border-transparent hover:bg-navy-lightest transitionAll items-center justify-center content-center ${
                    undoClauseLoading && 'cursor-wait'
                }`}
                onClick={handleUndo}
                disabled={undoClauseLoading}
            >
                <Undo className="w-5 h-5 opacity-100 group-hover:opacity-0" style={{ marginBottom: '2px' }} />
                <span className="text-xxs opacity-0 -mt-5 group-hover:opacity-100 font-semibold">Undo</span>
            </button>
        </div>
    );
};

export default ClauseUndo;
