import { UnitCondition, UnitUse } from '../../../generated/graphql';
import { capitaliseFirst } from '../../helpers';

type SelectData = {
    text?: string | null;
    id?: string | null;
}[];

export const UseConditionToText = (value: UnitUse | UnitCondition) => {
    const stringValue = capitaliseFirst(value?.toString());

    switch (value) {
        case UnitUse.Office:
        case UnitUse.Medical:
        case UnitUse.Industrial:
            return stringValue;
        case UnitUse.RetailHighStreet:
            return 'Retail (High Street)';
        case UnitUse.RetailShoppingCentre:
            return 'Retail (Shopping Centre)';
        case UnitUse.RetailPopUp:
            return 'Retail (Pop-up)';
        case UnitUse.OfficeFlex:
            return 'Office (Flex)';
        case UnitUse.Arch:
            return 'Arch';
        case UnitUse.Fb:
            return 'F&B';
        case UnitCondition.CategoryA:
            return 'Category A';
        case UnitCondition.CategoryAplus:
            return 'Category A+ (Flex)';
        case UnitCondition.CategoryB:
            return 'Category B';
        case UnitCondition.ShellAndCore:
            return 'Shell & Core';
        case UnitCondition.ExistingFitOut:
            return 'Existing Fit Out';
        case UnitCondition.Flex:
            return 'Flex';
        case UnitCondition.PlugAndPlay:
            return 'Plug & Play';
        case UnitCondition.Development:
            return 'Development';
        case UnitCondition.WarmShell:
            return 'Warm Shell';
        case UnitCondition.WhiteBox:
            return 'White-Box';
        case UnitUse.Managed:
            return 'Managed';
    }
};

export const enumToSelectData = (value: UnitUse | UnitCondition) => {
    return {
        text: UseConditionToText(value),
        id: value
    };
};

const useGetUnitSelectData = (): [SelectData, SelectData] => {
    const useData = [
        enumToSelectData(UnitUse.Office),
        enumToSelectData(UnitUse.OfficeFlex),
        enumToSelectData(UnitUse.Medical),
        enumToSelectData(UnitUse.Arch),
        enumToSelectData(UnitUse.Fb),
        enumToSelectData(UnitUse.Managed),

        enumToSelectData(UnitUse.Industrial),
        enumToSelectData(UnitUse.RetailHighStreet),
        enumToSelectData(UnitUse.RetailShoppingCentre),
        enumToSelectData(UnitUse.RetailPopUp)
    ];

    const conditionData = [
        enumToSelectData(UnitCondition.CategoryA),
        enumToSelectData(UnitCondition.CategoryAplus),
        enumToSelectData(UnitCondition.ShellAndCore),
        enumToSelectData(UnitCondition.ExistingFitOut),
        enumToSelectData(UnitCondition.PlugAndPlay),
        enumToSelectData(UnitCondition.Development),
        enumToSelectData(UnitCondition.WarmShell),
        enumToSelectData(UnitCondition.WhiteBox)
        // enumToSelectData(UnitCondition.Flex),
    ];

    return [useData, conditionData];
};

export default useGetUnitSelectData;
