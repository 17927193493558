import { useEffect, useState } from 'react';

const useScreenHeight = () => {
    const [screenHeight, setScreenHeight] = useState<number>(1080);
    useEffect(() => setScreenHeight(window.innerHeight), []);
    // On Resize
    useEffect(() => {
        const handleResize = () => setScreenHeight(window.innerHeight);
        window.addEventListener('resize', handleResize);
    }, []);
    return screenHeight;
};

export default useScreenHeight;
