import { useEffect, useRef, useState } from 'react';

export const useDisclosure = () => {
    const [isOpen, setOpen] = useState<boolean>(false);
    const ref = useRef<HTMLDivElement>(null);

    const open = () => setOpen(true);
    const close = () => setOpen(false);
    const toggle = () => setOpen(!isOpen);

    useEffect(() => {
        function handleClickOutside(event: any) {
            if (ref.current && !ref.current.contains(event.target)) {
                setOpen(false);
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [ref]);

    return { isOpen, open, close, toggle, ref };
};
