import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import WindowFooter from './layout/WindowFooter';
import WindowHeader from './layout/WindowHeader';
import StageContext from './StageContext';
import { GetDealTenantDetailsDocument, useTenantActionFinalStageMutation } from '../../../../../generated/graphql';
import { handleFetchError } from '../../../../../shared/helpers';
import Loading from '../../../../../shared/Loading';
import Window from '../../../../../shared/Window';

const FinalStageWindow = () => {
    const { dealID, backTo, setShowFinalStageWindow: setShow } = React.useContext(StageContext);

    const [fibreProvider, setFibreProvider] = useState<boolean>(false);
    const [informLandlord, setInformLandlord] = useState<boolean>(false);
    const [fitOutPlans, setFitOutPlans] = useState<boolean>(false);
    const [licence, setLicence] = useState<boolean>(false);

    const history = useHistory();

    const [errorMessage, setErrorMessage] = useState<string>('');

    const [stageCompleted, setStageCompleted] = useState<boolean>(false);

    const [actionStage, { error: actionStageError, loading: actionStageLoading }] = useTenantActionFinalStageMutation();

    const handleCompleteStage = async () => {
        if (!fibreProvider || !fitOutPlans) {
            setErrorMessage('You must accept all the terms');
            return;
        }

        // Reset errors and submit
        setErrorMessage('');
        setStageCompleted(true);
        history.push(backTo!);
        setShow!(false);
        // Send to backend
        const { data } = await actionStage({
            variables: {
                dealID: dealID!,
                input: {
                    completed: true,
                    informLandlord
                }
            },
            refetchQueries: [
                {
                    query: GetDealTenantDetailsDocument,
                    variables: {
                        dealID: dealID!
                    }
                }
            ]
        });

        if (actionStageError) {
            return handleFetchError('Could not complete stage', actionStageError, data?.dealTenantDetailsSet);
        }
    };

    return (
        <Window
            width="w-4/5"
            scrollContent={true}
            className="md:max-h-11/12"
            contentBackground="navy-light"
            header={
                <WindowHeader
                    setShow={setShow!}
                    backTo={backTo!}
                    content={
                        <div className="py-6 border-b border-newTeal-main">
                            <h1 className="text-center font-semibold text-3xl mb-0">Before you go</h1>
                        </div>
                    }
                />
            }
            footer={
                <WindowFooter
                    setShow={setShow}
                    backTo="#readiness-stage6"
                    nextTo={backTo!}
                    nextText="Done"
                    onNextClick={() => {
                        handleCompleteStage();
                    }}
                />
            }
            setShow={setShow!}
            onCloseClick={() => {
                setShow!(false);
                history.push(backTo!);
            }}
        >
            <div className="flex flex-col min-h-full">
                <div className="w-full bg-navy-light flex-1 px-2">
                    {actionStageLoading && <Loading className="mt-20" />}
                    <div className="container lg:w-4/5 md:w-5/5 mx-auto md:py-8 pt-0 pb-96">
                        <p className="font-medium text-center max-w-xl text-sm text-gray-800 mx-auto">
                            Before you go we wanted to let you know of a couple more milestones you should accomplish
                            before signing your lease. As ever, the earlier these can be achieved the better.
                        </p>
                        <p className="my-8 font-semibold text-gray-600 text-center">
                            I understand that I will need to:
                        </p>
                        <div className="lg:w-2/5 sm:w-5/5 mx-auto">
                            <label className="block mb-5 ml-6">
                                <input
                                    type="checkbox"
                                    id="optional"
                                    checked={fibreProvider}
                                    onChange={() => {
                                        setFibreProvider(!fibreProvider);
                                    }}
                                    disabled={stageCompleted ? true : false}
                                    className="leading-tight w-7 h-7 form-checkbox p-2 rounded bg-white text-newTeal-main focus:shadow-none focus:outline-none"
                                />
                                <span className="text-sm text-newGray-darkish ml-2 font-medium">
                                    Identify a fibre provider (if applicable)
                                </span>
                            </label>
                            <label className="block mb-5 ml-6">
                                <input
                                    type="checkbox"
                                    id="optional"
                                    checked={fitOutPlans}
                                    onChange={() => {
                                        setFitOutPlans(!fitOutPlans);
                                    }}
                                    disabled={stageCompleted ? true : false}
                                    className="leading-tight w-7 h-7 form-checkbox p-2 rounded bg-white text-newTeal-main focus:shadow-none focus:outline-none"
                                />
                                <span className="text-sm text-newGray-darkish ml-2 font-medium">
                                    Provided the Landlord with fit out plans (if applicable)
                                </span>
                            </label>
                            <label className="block mb-5 ml-6">
                                <input
                                    type="checkbox"
                                    id="optional"
                                    checked={licence}
                                    onChange={() => {
                                        setLicence(!licence);
                                    }}
                                    disabled={stageCompleted ? true : false}
                                    className="leading-tight w-7 h-7 form-checkbox p-2 rounded bg-white text-newTeal-main focus:shadow-none focus:outline-none"
                                />
                                <span className="text-sm text-newGray-darkish ml-2 font-medium">
                                    Obtain a licence to alter (if applicable)
                                </span>
                            </label>
                            <label className="block ml-6">
                                <input
                                    type="checkbox"
                                    id="optional"
                                    checked={informLandlord}
                                    onChange={() => setInformLandlord((informLandlord) => !informLandlord)}
                                    className="leading-tight w-7 h-7 form-checkbox p-2 rounded bg-white text-newTeal-main focus:shadow-none focus:outline-none"
                                />
                                <span className="text-sm text-newGray-darkish ml-2 font-medium">
                                    Inform the Landlord when your Board Approval is in place?
                                </span>
                            </label>
                        </div>
                        <div className="bg-navy-light mt-4 mb-2">
                            {errorMessage && (
                                <div className="container mx-auto bg-red-200 border border-red-600 rounded py-2 px-4 mb-2">
                                    <span className="text-center text-red-600">{errorMessage}</span>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </Window>
    );
};

export default FinalStageWindow;
