import { noop } from 'lodash';
import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';

import DealSend from './DealSend';
import { DealHeaderPage } from '..';
import { ReactComponent as Archive } from '../../../../../assets/images/archive/black.svg';
import { ReactComponent as Download } from '../../../../../assets/images/download/black.svg';
import { ReactComponent as Eye } from '../../../../../assets/images/eye/black.svg';
import { ReactComponent as Plus } from '../../../../../assets/images/plus/black.svg';
import { ReactComponent as Save } from '../../../../../assets/images/save/newSave/black.svg';
import {
    DealState,
    DealUserParty,
    GetNegotiationSummaryDocument,
    useUndoDealMutation
} from '../../../../../generated/graphql';
import { AddButton } from '../../../../../shared/add-button';
import { Button } from '../../../../../shared/button';
import useMobileScreen from '../../../../../shared/hooks/useMobileScreen';
import { isCompletedDeal } from '../../../../../v2/common/helpers';
import { LandlordFilesContext } from '../../../../../v2/components/LandlordsFileModal/LandlordFilesContext';
import DealHeaderContext from '../../../../../v2/pages/Deals/DealHeader/DealHeaderContext';
import RejectDealDropdown from '../../Approve/RejectDealDropdown';
import { DealContext } from '../../context';
import { hasChanges } from '../../Negotiate';

const DealHeaderButtons = () => {
    const {
        detailsData,
        setShareWindowType,
        setShowSendWindow,
        deal,
        page,
        setShowApproveDealWindow = noop,
        isClauseReordering,
        setClauseReordering = noop,
        setShowRejectDealWindow,
        isTitlesReordering,
        setTitlesReordering = noop,
        setShowAddClauseWindow = noop,
        getDealDetails,
        userType,
        downloadData,
        downloadDataLoading,
        downloadPDF,
        downloadPDFLoading,
        canSend,
        setHideToolbar
    } = useContext(DealHeaderContext);

    const { downloadOpen } = useContext(DealContext);

    const history = useHistory();
    const mobileScreen = useMobileScreen();

    const { setShowLandlordFilesWindow = noop } = useContext(LandlordFilesContext);

    const { isEdit, setIsEdit } = useContext(DealContext);
    const [undoDeal] = useUndoDealMutation();

    const { setSeenDealAgreed, reload, setReload } = useContext(DealContext);
    const isCompleted = isCompletedDeal(deal?.state);

    const canReturn =
        deal &&
        deal.currentUserParty === DealUserParty.Landlord &&
        [DealState.Agreed, DealState.Landlord].includes(deal.state) &&
        (isEdit || (hasChanges(deal) && deal.agreedBy && deal.agreedBy?.valueOf() !== deal.currentUserParty));

    const isReordering = isTitlesReordering || isTitlesReordering;

    return (
        <div
            className={`flex flex-row content-center items-center  md:justify-center xl:justify-end  xl:w-auto justify-end gap-2 mr-2 ${
                isCompleted ? 'w-1/2' : isReordering ? 'w-full' : 'w-4/6'
            }`}
        >
            {/* Approval page */}
            {page === DealHeaderPage.APPROVE ? (
                <>
                    {setShowRejectDealWindow && detailsData?.deal.__typename === 'Deal' && (
                        <RejectDealDropdown
                            dealID={detailsData.deal._id}
                            showMakeChanges={
                                !!(
                                    detailsData.deal &&
                                    (!detailsData.deal.approvalID ||
                                        (detailsData.deal.approvalID && detailsData.deal.hasAccess))
                                )
                            }
                            setShowRejectDealWindow={setShowRejectDealWindow}
                        />
                    )}
                    <Button
                        onClick={() => setShowApproveDealWindow(true)}
                        background="newTeal-main"
                        text="white"
                        className="px-4"
                    >
                        Approved
                    </Button>
                </>
            ) : // All other pages
            isClauseReordering || isTitlesReordering ? (
                <Button
                    onClick={() => {
                        if (detailsData?.deal.__typename === 'Deal') {
                            getDealDetails({
                                variables: {
                                    dealID: detailsData?.deal._id
                                }
                            });
                        }
                        setTitlesReordering(false);
                        setClauseReordering(false);
                    }}
                    background="gray-1"
                    text="white"
                    className="px-4 text-xs flex flex-col"
                >
                    <Save className="w-4 h-4" />
                    Save Order
                </Button>
            ) : (
                <>
                    {/* Add Clause */}
                    {page === DealHeaderPage.DEFAULT &&
                        !mobileScreen &&
                        ((!userType && !deal) ||
                            (userType &&
                                deal?.state &&
                                (deal?.state === 'EDIT' ||
                                    deal?.state.toString() === deal?.currentUserParty?.toString() ||
                                    (deal.state === 'AGREED' && deal.currentUserParty === 'LANDLORD')))) && (
                            <AddButton
                                onClick={() => setShowAddClauseWindow(true)}
                                className="px-2 md:px-4 mr-2"
                                primary
                                border="black"
                            >
                                Clause
                            </AddButton>
                        )}
                    {canReturn && (
                        <Button
                            background="newTeal-main"
                            text="white"
                            className="mr-2 p-2 md:p-0 xs:w-28 md:w-auto text-sm md:text-lg"
                            onClick={async () => {
                                await undoDeal({
                                    variables: { dealID: deal._id },
                                    refetchQueries: [
                                        {
                                            query: GetNegotiationSummaryDocument,
                                            variables: {
                                                id: deal._id
                                            }
                                        }
                                    ],
                                    onCompleted: () => {
                                        setSeenDealAgreed(false);
                                        setReload(!reload);
                                    }
                                });
                                setIsEdit(false);
                            }}
                        >
                            Return to <br />
                            Agreed Terms
                        </Button>
                    )}
                    {/* Preview Mode / Least Mode */}
                    {!mobileScreen && !isCompleted ? (
                        <Button
                            onClick={() => {
                                if (detailsData?.deal.__typename !== 'Deal') return;
                                const url = page === DealHeaderPage.DEFAULT ? 'preview' : '';
                                history.push(`/deals/${detailsData.deal._id}/${url}`);
                            }}
                            background="black"
                            text="white"
                            className="px-4 mr-2 w-40"
                        >
                            {page === DealHeaderPage.DEFAULT ? 'Preview Mode' : 'Least Mode'}
                        </Button>
                    ) : mobileScreen ? (
                        <>
                            {!isCompleted && (
                                <Button
                                    onClick={() => setShowAddClauseWindow(true)}
                                    text={'xs'}
                                    className="bg-navy-lightest flex flex-col justify-center rounded text-xs itemscenter w-1/4 h-12"
                                >
                                    <Plus className="h-5 w-5" />
                                    Clause
                                </Button>
                            )}
                            <Button
                                onClick={() => setShowLandlordFilesWindow(true)}
                                text={'xs'}
                                className={`bg-navy-lightest flex flex-col justify-center rounded text-xs items-center ${
                                    isCompleted ? 'w-1/2' : 'w-1/4'
                                }  h-12`}
                            >
                                <Archive className="h-5 w-5" />
                                Files
                            </Button>
                            {!isCompleted ? (
                                <Button
                                    onClick={() => {
                                        if (detailsData?.deal.__typename !== 'Deal') return;
                                        const url = page === DealHeaderPage.DEFAULT ? 'preview' : '';
                                        history.push(`/deals/${detailsData.deal._id}/${url}`);
                                    }}
                                    className="bg-navy-lightest flex flex-col justify-center rounded items-center w-1/4 h-12"
                                >
                                    <Eye className="h-5 w-5" />
                                    <p className="text-xs">
                                        {page === DealHeaderPage.PREVIEW ? 'Negotiator' : 'Preview'}
                                    </p>
                                </Button>
                            ) : (
                                <Button
                                    onClick={downloadOpen}
                                    className="bg-newTeal-main flex flex-col justify-center rounded items-center xs:w-1/2 sm:w-3/4 h-12"
                                >
                                    <Download className="h-5 w-5" />
                                    <p className="text-xs xs:leading-3">Download Deal Data</p>
                                </Button>
                            )}
                        </>
                    ) : (
                        !mobileScreen &&
                        isCompleted && (
                            // Download Data / PDF
                            <>
                                <Button
                                    onClick={() =>
                                        downloadPDF(detailsData?.deal.__typename === 'Deal' ? detailsData.deal._id : '')
                                    }
                                    background="navy-lightest"
                                    text="black"
                                    className={`md:px-10 ml-2 font-light md:w-full`}
                                >
                                    {downloadPDFLoading ? 'Generating...' : 'Download PDF'}
                                </Button>
                                <Button
                                    onClick={() =>
                                        downloadData(
                                            detailsData?.deal.__typename === 'Deal' ? detailsData.deal._id : ''
                                        )
                                    }
                                    background="navy-lightest"
                                    text="black"
                                    className={`md:px-10 mx-2 font-light   md:w-full`}
                                >
                                    {downloadDataLoading ? 'Generating...' : 'Download Data Pack'}
                                </Button>
                            </>
                        )
                    )}
                    {/* Share With */}
                    {!isCompleted && (
                        <DealSend
                            dealID={detailsData?.deal._id ?? ''}
                            isCompleted={detailsData?.deal.state === DealState.Complete}
                            deal={detailsData}
                            showOtherParty={
                                (detailsData?.deal.state === DealState.Agreed &&
                                    detailsData.deal.currentUserParty.toString() !==
                                        detailsData.deal.agreedBy?.toString()) ||
                                detailsData?.deal.state === DealState.Edit
                                    ? true
                                    : detailsData?.deal.currentUserParty?.toLowerCase() ===
                                      detailsData?.deal.state.toLowerCase()
                            }
                            disabled={!canSend}
                            currentParty={detailsData?.deal.currentUserParty}
                            edit={detailsData?.deal.state === DealState.Edit}
                            showSendWindow={(type) => {
                                setShareWindowType(type);
                                setShowSendWindow(true);
                            }}
                            setHideToolbar={setHideToolbar}
                        />
                    )}
                </>
            )}
        </div>
    );
};

export default DealHeaderButtons;
