import Tippy from '@tippyjs/react';
import { format } from 'date-fns';
import { isEmpty } from 'lodash';
import React from 'react';

import { ReactComponent as Clock } from '../../../../assets/images/clock/filledBlack.svg';
import { ReactComponent as FileIcon } from '../../../../assets/images/file/leastGreenThinner.svg';
import { ActivityLog as ActivityLogDocument } from '../../../../generated/graphql';

type ActivityLogProps = {
    logs: ActivityLogDocument[];
};

type ActivityLogObjectProps = {
    object: string | string[];
};

const ActivityLogObject = ({ object }: ActivityLogObjectProps) => {
    if (typeof object === 'object' && object.length > 1) {
        const [first, ...rest] = object;

        const tooltipContent = (
            <div>
                {rest.map((email) => (
                    <p>{email}</p>
                ))}
            </div>
        );

        return (
            <div className="flex space-x-1 xs:text-xxs sm:text-sm xs:w-1/2 ">
                <p className="font-semibold xs:w-1/3  truncate ">{first}</p>
                <p>and</p>
                <Tippy placement="right" content={tooltipContent}>
                    <p className="bg-newGray-main text-white rounded-sm px-1">{object.length - 1} more</p>
                </Tippy>
            </div>
        );
    }

    return <p className="font-semibold xs:text-xxs sm:text-sm xs:w-2/5 truncate">{object}</p>;
};

const ActivityLog = ({ logs = [] }: ActivityLogProps) => {
    return (
        <div className="flex flex-col mt-4">
            <div className="flex flex-row mb-2">
                <FileIcon className="w-5 h-5 mr-4" />
                <p className="font-semibold text-xl">Activity Log</p>
            </div>
            {!isEmpty(logs) && (
                <div className="flex flex-col bg-navy-lightest rounded-lg xs:pr-7 xs:pl-4 sm:pr-10 pl-8 py-4 max-h-48 overflow-y-scroll">
                    {logs.map((log, index) => (
                        <div>
                            <div className="flex flex-row justify-between items-center w-full">
                                <div className="flex flex-row items-center xs:w-3/4 ">
                                    <div className="bg-newGray-main w-2 h-2 rounded-full xs:mr-3 sm:mr-6" />
                                    <p className="font-semibold xs:text-xxs sm:text-sm mr-1">
                                        {`${log.actor.firstName} ${log.actor.lastName}`}
                                    </p>
                                    <p className="mr-1 xs:text-xxs sm:text-sm ">{log.action}</p>
                                    <ActivityLogObject object={log.object} />
                                </div>
                                <div className="flex flex-row items-center xs:w-1/4 w-1/5">
                                    <Clock className="text-gray-600 w-2 mr-1" />
                                    <p className="sm:text-xxs xs:text-3xs text-newGray-darkish">
                                        {format(new Date(log.created), 'dd MMM yyyy, hh:mm')}
                                    </p>
                                </div>
                            </div>
                            {index + 1 !== logs.length && (
                                <div className="border-b border-gray-600 xs:ml-4 sm:ml-8 my-2" />
                            )}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default ActivityLog;
