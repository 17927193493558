import { useApolloClient } from '@apollo/client';
import axios from 'axios';
import React, { useEffect, useState } from 'react';

import { ReactComponent as Cross } from '../../../../assets/images/cross/currentColor.svg';
import {
    AvatarType,
    UnitCondition,
    UnitUse,
    UpdateUnitMutationVariables,
    useGetBuildingUpdateDetailsQuery,
    useGetUnitDetailsQuery,
    useUpdateUnitMutation,
    useUploadAvatarMutation
} from '../../../../generated/graphql';
import UnitAvatarUpload from '../../../../shared/file/UnitAvatarUpload';
import { handleFetchError } from '../../../../shared/helpers';
import Input, { InputType } from '../../../../shared/Inputs';
import Select from '../../../../shared/Inputs/Select';
import Loading from '../../../../shared/Loading';
import useGetUnitSelectData, { enumToSelectData } from '../../../../shared/NewUnit/hooks/useGetUnitSelectData';
import Window from '../../../../shared/Window';

type NewUnitWindowProps = {
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
    unitID: string;
    refetch: () => void;
};

type EditWindowProps = {
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
};

const EditUnitWindow = ({ unitID, refetch, ...props }: NewUnitWindowProps) => {
    const { refetch: unitRefetch } = useGetUnitDetailsQuery({
        variables: {
            unitID: unitID
        }
    });

    const client = useApolloClient();

    // Error message
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    const [buildingName, setBuildingName] = useState<string>('');
    const [unitName, setUnitName] = useState<string>('');
    const [url, setUrl] = useState<string>('');
    const [addressLine1, setAddressLine1] = useState<string>('');
    const [addressLine2, setAddressLine2] = useState<string>('');
    const [addressCity, setAddressCity] = useState<string>('');
    const [addressPostCode, setAddressPostCode] = useState<string>('');
    const [use, setUse] = useState<UnitUse | null>(null);
    const [condition, setCondition] = useState<UnitCondition | null>(null);
    const [area, setArea] = useState<number>(0);
    const [avatar, setAvatar] = useState<string>('');
    const [newAvatar, setNewAvatar] = useState<any>(null);
    const [straightToLease, setStraightToLease] = useState<boolean>(true);

    const [useData, conditionData] = useGetUnitSelectData();

    // Building Autocomplete
    const {
        data: buildingData,
        loading: buildingLoading,
        error: buildingError
    } = useGetBuildingUpdateDetailsQuery({ variables: { unitID } });

    // Add Unit Image
    const [mediaCreate, { error: uploadError, loading: uploadGenerateLoading }] = useUploadAvatarMutation();
    const [uploadLoading, setUploadLoading] = useState<boolean>(false);

    const [updateUnit, { loading: updateLoading }] = useUpdateUnitMutation();

    useEffect(() => {
        // Check for errors
        handleFetchError('Could not load your buildings', buildingError, buildingData?.unit);

        // Check if we got a building back
        if (buildingData?.unit.__typename === 'Unit') {
            const buildingUnit = buildingData.unit;
            setBuildingName(buildingUnit.buildingName);
            setUnitName(buildingUnit.name ?? '');
            setUrl(buildingUnit.url ?? '');
            setAddressLine1(buildingUnit.address.line1 ?? '');
            setAddressLine2(buildingUnit.address.line2 ?? '');
            setAddressCity(buildingUnit.address.city ?? '');
            setAddressPostCode(buildingUnit.address.postCode ?? '');
            setUse(buildingUnit.use ?? UnitUse.Office);
            setCondition(buildingUnit.condition);
            setArea(buildingUnit.area);
            setAvatar(buildingUnit.avatar ?? '');
            setStraightToLease(buildingUnit.straightToLease !== undefined ? !!buildingUnit.straightToLease : true);
        }
    }, [buildingData, buildingError]);

    const handleConfirmClick = async () => {
        if (
            !addressLine1.trim() ||
            !addressCity.trim() ||
            !addressPostCode.trim() ||
            typeof area !== 'number' ||
            !use ||
            !condition
        ) {
            setErrorMessage('All fields (except Building Name, Website URL and Address Line 2) are required');
            return;
        }

        if (!area) {
            setErrorMessage('Demise area should be greater than 0');

            return;
        }

        let inputBuildingName;
        if (!buildingName.trim()) {
            inputBuildingName = addressLine1;
        } else {
            inputBuildingName = buildingName;
        }

        const input: UpdateUnitMutationVariables = {
            unitID: unitID,
            name: unitName,
            buildingName: inputBuildingName,
            area: area,
            url: url && url.trim().length > 0 ? url : undefined,
            condition: condition,
            line1: addressLine1,
            line2: addressLine2,
            city: addressCity,
            postCode: addressPostCode,
            straightToLease
        };

        if (newAvatar) {
            const fileParts = newAvatar.name.split('.');
            const fileType = fileParts[1];

            const res = await mediaCreate({
                variables: {
                    type: AvatarType.Unit,
                    filetype: newAvatar.type,
                    id: unitID
                }
            });

            if (uploadError) {
                handleFetchError('Something went wrong', uploadError, res?.data);
                return;
            }

            const imageUrl: string = res.data?.avatarCreate.__typename === 'S3Payload' ? res.data.avatarCreate.url : '';
            const signedUrl: string =
                res.data?.avatarCreate.__typename === 'S3Payload' ? res.data.avatarCreate.signedUrl : '';

            await axios.put(signedUrl, newAvatar, {
                headers: {
                    'Content-Type': fileType,
                    'Access-Control-Allow-Origin': '*'
                }
            });

            if (imageUrl.length > 1) input.avatar = imageUrl;

            client.cache.modify({
                id: client.cache.identify({
                    __typename: 'Unit',
                    _id: unitID
                }),
                fields: {
                    avatar() {
                        return imageUrl;
                    }
                }
            });

            unitRefetch();
            setUploadLoading(false);
        }

        await updateUnit({
            variables: input
        });

        client.cache.modify({
            id: client.cache.identify({
                __typename: 'Unit',
                _id: unitID
            }),
            fields: {
                straightToLease() {
                    return input.straightToLease;
                }
            }
        });
        // refetch()
        props.setShow(false);
    };

    if (buildingLoading || updateLoading || uploadGenerateLoading || uploadLoading)
        return (
            <Window header={<EditWindowHeader setShow={props.setShow} />} title="Edit Unit" width="w-2/5" {...props}>
                <Loading />
            </Window>
        );

    return (
        <Window
            header={<EditWindowHeader setShow={props.setShow} />}
            width="w-2/5"
            primaryBtn
            className="mt-10"
            scrollContent
            onPrimaryBtnClick={handleConfirmClick}
            {...props}
        >
            <form
                className="flex flex-col pt-4 px-6 w-full"
                onSubmit={(event) => {
                    event.preventDefault();
                    handleConfirmClick();
                }}
            >
                <div>
                    <div className="flex flex-row flex-wrap md:flex-no-wrap w-full gap-4 mb-4">
                        <div className="flex flex-col w-full gap-4">
                            <Input
                                type={InputType.text}
                                name="Building Name"
                                placeholder="Building Name"
                                label="Building Name (optional)"
                                value={buildingName}
                                onChange={(e) => setBuildingName(e.target.value)}
                                className="w-full text-xs"
                            />
                            <Input
                                type={InputType.text}
                                name="Demise Reference"
                                placeholder="Demise Reference"
                                label="Demise Reference"
                                value={unitName}
                                onChange={(e) => setUnitName(e.target.value)}
                                className="w-full text-xs"
                            />
                            <Input
                                type={InputType.number}
                                name="Demise Area"
                                value={area}
                                onChange={(e) => setArea(e.target.valueAsNumber)}
                                placeholder="Demise Area"
                                label="Demise Area"
                                className="w-full text-xs"
                            />
                        </div>
                        <UnitAvatarUpload setNewAvatar={setNewAvatar} avatar={avatar} editing />
                    </div>
                    <div className="flex flex-row flex-wrap md:flex-no-wrap w-full gap-4 mb-4">
                        <Input
                            type={InputType.text}
                            name="address-line-1"
                            value={addressLine1}
                            onChange={(e) => setAddressLine1(e.target.value)}
                            placeholder="Address Line 1"
                            label="Address Line 1"
                            className="w-full text-xs"
                        />
                        <Input
                            type={InputType.text}
                            name="address-line-2"
                            value={addressLine2}
                            onChange={(e) => setAddressLine2(e.target.value)}
                            placeholder="Address Line 2"
                            label="Address Line 2"
                            className="w-full text-xs"
                        />
                    </div>
                    <div className="flex flex-row flex-wrap md:flex-no-wrap w-full gap-4 mb-4">
                        <Input
                            type={InputType.text}
                            name="address-city"
                            value={addressCity}
                            onChange={(e) => setAddressCity(e.target.value)}
                            placeholder="City"
                            label="City"
                            className="w-full text-xs"
                        />
                        <Input
                            type={InputType.text}
                            name="address-post-code"
                            value={addressPostCode}
                            onChange={(e) => setAddressPostCode(e.target.value)}
                            placeholder="Post Code"
                            label="Post Code"
                            className="w-full text-xs"
                        />
                    </div>
                    <div className="flex flex-row flex-wrap md:flex-no-wrap w-full gap-4 mb-4">
                        <Select
                            defaultValue={
                                use
                                    ? {
                                          value: enumToSelectData(use).id.toString(),
                                          label: enumToSelectData(use).text ?? ''
                                      }
                                    : undefined
                            }
                            data={useData}
                            onItemClick={(use) => {
                                if (!use) return;
                                setUse(use as UnitUse);
                            }}
                            disabled
                            placeholder="Select Use"
                            label="Select Use"
                            value={
                                use
                                    ? {
                                          value: enumToSelectData(use).id.toString(),
                                          label: enumToSelectData(use).text ?? ''
                                      }
                                    : undefined
                            }
                            className="w-full text-xs"
                        />
                        <Select
                            defaultValue={
                                condition
                                    ? {
                                          value: enumToSelectData(condition).id.toString(),
                                          label: enumToSelectData(condition).text ?? ''
                                      }
                                    : undefined
                            }
                            data={conditionData}
                            onItemClick={(condition) => {
                                if (!condition) return;
                                setCondition(condition as UnitCondition);
                            }}
                            placeholder="Condition"
                            label="Condition"
                            className="w-full text-xs"
                        />
                    </div>
                </div>
                <div className="flex flex-row flex-wrap md:flex-no-wrap w-full mb-4">
                    <Input
                        type={InputType.url}
                        name="website-url"
                        placeholder="Website URL"
                        label="Website URL (optional)"
                        value={url}
                        onChange={(e) => setUrl(e.target.value)}
                        className="w-full text-xs"
                    />
                </div>
                {errorMessage && (
                    <div className="bg-red-200 border border-red-600 rounded w-full py-2 px-4 my-4">
                        <span className="text-center text-red-600">{errorMessage}</span>
                    </div>
                )}
            </form>
        </Window>
    );
};

const EditWindowHeader = ({ setShow }: EditWindowProps) => {
    return (
        <div className="flex p-2 ml-4 mr-2 mt-3 mb-2 place-items-center justify-between">
            <div className="flex flex-row items-center">
                <p className="text-xl font-semibold border-b-3 border-newTeal-main mr-4">Edit Space</p>
            </div>
            <Cross
                className="w-5 h-5 -mt-4"
                onClick={() => {
                    setShow(false);
                }}
            />
        </div>
    );
};

export default EditUnitWindow;
