import React, { useEffect } from 'react';

import { HistoryEventType } from '../../../../../../generated/graphql';
import { ClauseType, History } from '../../Types';

const useSetClauseType = (
    approved: boolean,
    history: History | null,
    setType: React.Dispatch<React.SetStateAction<ClauseType>>
) => {
    useEffect(() => {
        // Get most recent history event
        const mostRecent = history && history.length > 0 && history[history.length - 1];

        if (!mostRecent || !setType) return;

        if (approved || mostRecent.type === HistoryEventType.Approve) return setType(ClauseType.APPROVED);
        if (mostRecent.type === HistoryEventType.New) return setType(ClauseType.NEW);
        if (mostRecent.type === HistoryEventType.Update) {
            if (
                mostRecent &&
                mostRecent.change?.new.shortForm === mostRecent.change?.old?.shortForm &&
                mostRecent.change?.new.longForm === mostRecent.change?.old?.longForm &&
                mostRecent.change?.new.valueSuffix === mostRecent.change?.old?.valueSuffix
            )
                return setType(ClauseType.VALUE);
            else return setType(ClauseType.CHANGED);
        }
        if (mostRecent.type === HistoryEventType.Swap) return setType(ClauseType.SWAPPED);
        if (mostRecent.type === HistoryEventType.Comment) return setType(ClauseType.COMMENTED);
        if (mostRecent.type === HistoryEventType.Delete) return setType(ClauseType.REMOVED);

        setType(ClauseType.DEFAULT);
        // eslint-disable-next-line
    }, [approved, history]);
};

export default useSetClauseType;
