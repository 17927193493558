import React from 'react';

import useDeleteGroup from './useDeleteGroup';
import { DealDetailsFragment, useDeleteGroupMutation } from '../../../generated/graphql';
import Window from '../../Window';
import { DealType } from '../ConfirmClauseDeleteWindow.tsx';

type ConfirmGroupDeleteProps = {
    deal: DealDetailsFragment | null;
    templateID?: string;
    groupID: string;
    parentID: string;
    type: DealType;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
};

const ConfirmGroupDelete = ({ deal, groupID, parentID, templateID, type, setShow }: ConfirmGroupDeleteProps) => {
    const [deleteGroup, { error: deleteGroupError }] = useDeleteGroupMutation();

    const updateCache = useDeleteGroup(type);

    const handleDeleteClick = async () => {
        await deleteGroup({
            variables: {
                dealID: deal?._id ?? '',
                parentID,
                groupID
            },
            update(store, { data }) {
                if (!data) return;

                updateCache(
                    store,
                    data.dealGroupDelete.__typename === 'DeletedObject' ? data.dealGroupDelete.deletedObjectID : '',
                    templateID || deal?._id
                );
            }
        });

        if (deleteGroupError) {
            return;
            //  handleFetchError(
            //     "Could not delete the clause",
            //     deleteGroupError,
            //     deleteGroupData?.dealGroupDelete
            // )
        }

        setShow(false);
    };

    return (
        <Window
            setShow={setShow}
            title="Remove Group"
            titleCenter
            primaryBtn
            onPrimaryBtnClick={handleDeleteClick}
            secondaryBtn
            onSecondaryBtnClick={() => setShow(false)}
        >
            <div className="flex flex-col items-center">
                <h2 className="text-center text-lg my-8 w-4/5">
                    Are you sure you want to <span className="font-semibold text-uppercase">remove</span> this group?
                    This process is irreversible.
                </h2>
            </div>
        </Window>
    );
};

export default ConfirmGroupDelete;
