import React, { ReactNode } from 'react';

import { Button } from '../../../../shared/button';

type StageProps = {
    stageNumber: number;
    title: string;
    subtitle?: string;
    note?: string;
    description?: string;
    nextStage?: string;
    onNextClick?: () => void;
    children: ReactNode;
    buttonAlign?: string;
    requiredFieldsText?: boolean;
};

const Stage = ({
    stageNumber,
    title,
    subtitle,
    note,
    nextStage,
    onNextClick,
    children,
    buttonAlign,
    requiredFieldsText
}: StageProps) => {
    return (
        <>
            <div className="flex flex-row items-start text-mb">
                <p className="font-bold text-newTeal-main whitespace-pre-wrap">Stage {stageNumber}: </p>
                <p className="font-semibold">{title}</p>
            </div>
            {subtitle && <p className="text-sm font-medium mt-2">{subtitle}</p>}
            {note && <p className={`text-xxs mb-2`}>{note}</p>}
            <div className="mt-1 w-full h-full flex flex-col">
                {children}
                {nextStage && (
                    <div className={`w-full mt-auto`}>
                        {requiredFieldsText && (
                            <p className={`text-xxs mt-10`}>*All the fields are required to complete the stage</p>
                        )}
                        <Button
                            type="submit"
                            text={'sm'}
                            border={'none'}
                            background="newTeal-light"
                            className={`w-full md:mb-8 mb-20 text-black hover:text-white ${
                                requiredFieldsText ? '' : 'mt-10'
                            } hover:bg-newTeal-main`}
                            onClick={onNextClick}
                            height={'10'}
                        >
                            {nextStage}
                        </Button>
                    </div>
                )}
            </div>
        </>
    );
};

export default Stage;
