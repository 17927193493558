import React, { useEffect, useRef, useState } from 'react';

import {
    useGetUserOrganisationsQuery,
    useGetUserTypeQuery,
    UserType,
    useTemplateDuplicateMutation
} from '../../generated/graphql';
import { handleFetchError } from '../../shared/helpers';
import Input, { InputType } from '../../shared/Inputs';
import Select, { SelectProps } from '../../shared/Inputs/Select';
import Loading from '../../shared/Loading';
import Window from '../../shared/Window';

type DuplicateTemplateWindowProps = {
    refetch: () => void;
    name?: string;
    id: string;
    description?: string;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
};

const DuplicateTemplateWindow = ({
    name,
    description,
    setShow,
    id,
    refetch,
    ...props
}: DuplicateTemplateWindowProps) => {
    const [duplicateTemplate, { error: duplicateError }] = useTemplateDuplicateMutation();

    const {
        data: organisationsData,
        loading: organisationsLoading,
        error: organisationsError
    } = useGetUserOrganisationsQuery();

    const { data: userTypeData } = useGetUserTypeQuery();
    // Form values
    const templateNameRef = useRef<HTMLInputElement>(null);

    const templateDescriptionRef = useRef<HTMLTextAreaElement>(null);
    const [organisationID, setOrganisationID] = useState<string | null>();

    const [organisationSelectData, setOrganisationSelectData] = useState<SelectProps['data']>([]);
    const [errors, setErrors] = useState<any>();

    const clearError = (field: string) => {
        const newErrors = { ...errors };
        delete newErrors[field];

        setErrors(newErrors);
    };

    useEffect(() => {
        if (organisationsError || organisationsData?.user.__typename !== 'User') {
            handleFetchError('Failed to fetch your organisations', organisationsError, organisationsData?.user);
            return;
        }

        if (organisationsData.user.type === UserType.Agent) {
            const organisations =
                userTypeData?.user.trustedOrganisations
                    ?.filter((organisation) => organisation.active)
                    .map((org) => ({
                        id: org._id,
                        text: org.name
                    })) ?? [];

            setOrganisationSelectData(organisations);
        }

        if ([UserType.Admin, UserType.Landlord].includes(organisationsData.user.type)) {
            setOrganisationID(organisationsData.user.organisation._id);
        }
    }, [organisationsData, organisationsError]);

    const handleConfirmClick = async () => {
        if (!organisationID) {
            setErrors({ ...errors, organisation: 'required' });
            return;
        }

        const { data } = await duplicateTemplate({
            variables: {
                organisation: organisationID,
                templateID: id,
                name: templateNameRef.current?.value,
                description: templateDescriptionRef.current?.value
            }
        });
        if (duplicateError) {
            handleFetchError('Failed to duplicate the template', duplicateError, data?.templateDuplicate);
            return;
        }
        refetch();
        setShow(false);
    };

    if (organisationsLoading)
        return (
            <Window title="Duplicate Template" width="w-1/3" {...props}>
                <Loading />
            </Window>
        );

    return (
        <Window
            setShow={setShow}
            title="Duplicate Template"
            width="w-1/3"
            primaryBtn
            primaryBtnText="Duplicate"
            onPrimaryBtnClick={handleConfirmClick}
            {...props}
        >
            <form className="flex flex-col py-4 px-2 ">
                <Input
                    type={InputType.text}
                    defaultValue={name}
                    placeholder="Template Name"
                    label="Template Name"
                    className="w-full mb-4"
                    ref={templateNameRef}
                />
                <p className="text-gray-600 font-medium text-sm mb-0.5">Description/Help Text</p>
                <textarea
                    cols={50}
                    rows={5}
                    placeholder="Template Description"
                    className="w-full border border-navy-light rounded mb-4 p-4 focus:outline-none"
                    defaultValue={description}
                    ref={templateDescriptionRef}
                ></textarea>
                {organisationsData?.user.type === UserType.Agent && (
                    <Select
                        placeholder="Associated Company"
                        label="Associated Company"
                        data={organisationSelectData}
                        onItemClick={(id) => {
                            setOrganisationID(id);
                            clearError('organisation');
                        }}
                        required
                        error={errors?.organisation}
                        className="mb-4"
                    />
                )}
            </form>
        </Window>
    );
};

export default DuplicateTemplateWindow;
