/* eslint-disable no-restricted-globals */
import React, { useEffect, useRef, useState } from 'react';
import { Redirect, Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { useIntercom } from 'react-use-intercom';

import {
    GetUserOrganisationDocument,
    useGetDealOrganisationNamesLazyQuery,
    useGetUserOrganisationQuery,
    useGetUserTypeQuery,
    useNewPracticeMutation,
    UserType
} from './generated/graphql';
import Layout from './layout';
import Auth from './pages/Auth';
import UserTypePage from './pages/Auth/UserType';
import LandlordDashboard from './pages/Dashboard/Landlord';
import Deals, { dealFilterConfig } from './pages/Deals';
import Deal from './pages/Deals/Deal';
import ApproveDeal from './pages/Deals/Deal/Approve';
import { DealProvider } from './pages/Deals/Deal/context';
import EditDeal from './pages/Deals/Deal/Edit';
import NegotiateDeal from './pages/Deals/Deal/Negotiate';
import PreviewDeal from './pages/Deals/Deal/Preview';
import GuidanceVideosPage from './pages/GuidanceVideos';
import Landing from './pages/Landing';
import NotificationsPage from './pages/Notifications';
import Readiness from './pages/Readiness';
import Settings from './pages/Settings';
import Templates, { saveValuesOverride, templateFilterConfig } from './pages/Templates';
import Template from './pages/Templates/Template';
import Units, { unitFilterConfig } from './pages/Units';
import Unit from './pages/Units/Unit';
import QueryStringData from './QueryStringData';
import { BulkSelectProvider } from './shared/bulk/context';
import { FilterProvider } from './shared/Filter/context';
import useMobileScreen from './shared/hooks/useMobileScreen';
import Input, { InputType } from './shared/Inputs';
import Window from './shared/Window';
import { pageMatch, PAGES } from './v2/common/helpers';
import { LandlordFilesProvider } from './v2/components/LandlordsFileModal/LandlordFilesContext';
import { ReadinessProvider } from './v2/components/Tenant/Readiness/context';

export const PROTECTED_ROUTES = [
    '/login',
    '/restricted',
    '/forgot',
    '/signup/type',
    '/signup',
    '/invite',
    '/reset',
    '/activate',
    '/deals',
    '/2FA'
];

const App = () => {
    const { boot } = useIntercom();

    const [headerContent, setHeaderContent] = useState<React.ReactChild | null>(null);

    const [showLandlordFilesWindow, setShowLandlordFilesWindow] = useState<boolean>(false);

    const [forceCloseCoverNote, setForceCloseCoverNote] = useState<boolean>(false);

    const mobileScreen = useMobileScreen();

    const { data: typeData } = useGetUserTypeQuery();

    const { data: userOrganisationData } = useGetUserOrganisationQuery();

    const [showNewPracticeDialog, setShowNewPracticeDialog] = useState<boolean>(
        userOrganisationData?.user.removed ? userOrganisationData?.user.removed : false
    );

    const [newPractice] = useNewPracticeMutation();

    const nameRef = useRef<HTMLInputElement>(null);

    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    const handleNewPractice = async (id: string, name: string) => {
        await newPractice({
            variables: {
                id,
                name
            },
            refetchQueries: [GetUserOrganisationDocument]
        });
    };

    const [getLandlordOrganisationNames, { data: landlordOrganisationNameData }] = useGetDealOrganisationNamesLazyQuery(
        {
            fetchPolicy: 'cache-and-network'
        }
    );

    useEffect(() => {
        userOrganisationData?.user.removed &&
            setShowNewPracticeDialog(userOrganisationData?.user.removed ? true : false);
    }, [userOrganisationData]);

    useEffect(() => {
        if (typeData?.user.type === UserType.Tenant) getLandlordOrganisationNames();
    }, [typeData]);

    useEffect(() => {
        if (typeData?.user.__typename === 'User') {
            const userId = typeData.user._id ?? '';
            const name = `${typeData.user.firstName ?? ''} ${typeData.user.lastName ?? ''}`;
            const email = typeData.user.email ?? '';
            const phone = typeData.user.mobile ?? '';
            const type = typeData.user.type;
            const company = typeData.user.organisation;

            boot({
                userId,
                name,
                email,
                phone,
                customAttributes: {
                    type
                },
                company: {
                    companyId: company._id,
                    name: company.name,
                    website: company.website ?? undefined
                },
                actionColor: '#3CA48E',
                hideDefaultLauncher: pageMatch(location.pathname, [PAGES.GUIDANCE_VIDEOS])
            });
        }
    }, [typeData, mobileScreen, boot]);

    const handleConfirmClick = async () => {
        const name = nameRef.current?.value;

        if (name?.length === 0) {
            setErrorMessage('Please enter the name of your new Practice Company');
        } else {
            userOrganisationData && name && handleNewPractice(userOrganisationData?.user.organisation._id, name);
            setShowNewPracticeDialog(false);
        }
    };

    return (
        <Router>
            <QueryStringData typeData={typeData} landlordOrganisationNameData={landlordOrganisationNameData} />
            <Switch>
                {/* Public Routes */}
                <Route path="/login">
                    <Auth login />
                </Route>
                <Route path="/restricted">
                    <Auth restricted />
                </Route>
                <Route path="/forgot">
                    <Auth forgotpw />
                </Route>
                <Route path="/signup/type" exact>
                    <UserTypePage />
                </Route>
                <Route path="/signup">
                    <Auth signup />
                </Route>
                <Route path="/2FA">
                    <Auth twoFA />
                </Route>

                {/* Email link routes */}
                <Route path="/invite/:token">
                    <Landing invite />
                </Route>

                <Route path="/reset/:token">
                    <Landing reset />
                </Route>

                <Route path="/activate/:token">
                    <Landing activate />
                </Route>

                {/* Deal preview route */}
                <Route path="/deals/:id/preview/:token?">
                    <DealProvider>
                        <LandlordFilesProvider
                            showLandlordFilesWindow={showLandlordFilesWindow}
                            setShowLandlordFilesWindow={setShowLandlordFilesWindow}
                        >
                            <ReadinessProvider>
                                <Layout headerContent={headerContent}>
                                    <PreviewDeal setHeaderContent={setHeaderContent} />
                                </Layout>
                            </ReadinessProvider>
                        </LandlordFilesProvider>
                    </DealProvider>
                </Route>
                {/* Deal approval route */}
                <Route path="/deals/:id/approve/:token?">
                    <LandlordFilesProvider
                        showLandlordFilesWindow={showLandlordFilesWindow}
                        setShowLandlordFilesWindow={setShowLandlordFilesWindow}
                    >
                        <Layout headerContent={headerContent}>
                            <ApproveDeal setHeaderContent={setHeaderContent} />
                        </Layout>
                    </LandlordFilesProvider>
                </Route>

                {/* Secured Routes */}
                <Route path="/units" exact>
                    {typeData?.user &&
                        (typeData?.user.type === 'TENANT' ? (
                            <Redirect to="/" />
                        ) : (
                            <FilterProvider key="unit" page="unit" filters={unitFilterConfig(typeData?.user.type)}>
                                <BulkSelectProvider>
                                    <Layout headerContent={headerContent}>
                                        <Units setHeaderContent={setHeaderContent} />
                                    </Layout>
                                </BulkSelectProvider>
                            </FilterProvider>
                        ))}
                </Route>
                <Route path="/units/deal/:id" exact>
                    {typeData?.user.type === 'TENANT' || typeData?.user.type === 'AGENT' ? (
                        <Layout headerContent={headerContent}>
                            <Unit tenant setHeaderContent={setHeaderContent} />
                        </Layout>
                    ) : (
                        <Redirect to="/" />
                    )}
                </Route>
                <Route path="/units/:id" exact>
                    <LandlordFilesProvider
                        showLandlordFilesWindow={showLandlordFilesWindow}
                        setShowLandlordFilesWindow={setShowLandlordFilesWindow}
                    >
                        <Layout headerContent={headerContent}>
                            <Unit setHeaderContent={setHeaderContent} />
                        </Layout>
                    </LandlordFilesProvider>
                </Route>

                <Route path="/deals" exact>
                    {typeData?.user && (
                        <FilterProvider key="deal" page="deal" filters={dealFilterConfig(typeData?.user.type)}>
                            <BulkSelectProvider>
                                <Layout headerContent={headerContent}>
                                    <Deals setHeaderContent={setHeaderContent} />
                                </Layout>
                            </BulkSelectProvider>
                        </FilterProvider>
                    )}
                </Route>
                <Route path="/deals/:id/edit" exact>
                    <LandlordFilesProvider
                        showLandlordFilesWindow={showLandlordFilesWindow}
                        setShowLandlordFilesWindow={setShowLandlordFilesWindow}
                    >
                        <Layout headerContent={headerContent}>
                            <ReadinessProvider>
                                <EditDeal setHeaderContent={setHeaderContent} />
                            </ReadinessProvider>
                        </Layout>
                    </LandlordFilesProvider>
                </Route>
                <Route path="/deals/:id/negotiate">
                    <DealProvider>
                        <LandlordFilesProvider
                            showLandlordFilesWindow={showLandlordFilesWindow}
                            setShowLandlordFilesWindow={setShowLandlordFilesWindow}
                        >
                            <ReadinessProvider>
                                <Layout headerContent={headerContent}>
                                    <NegotiateDeal
                                        setForceCloseCoverNote={setForceCloseCoverNote}
                                        forceCloseCoverNote={forceCloseCoverNote}
                                        setHeaderContent={setHeaderContent}
                                    />
                                </Layout>
                            </ReadinessProvider>
                        </LandlordFilesProvider>
                    </DealProvider>
                </Route>
                <Route path="/deals/:id">
                    <DealProvider>
                        <Layout headerContent={headerContent}>
                            <ReadinessProvider>
                                <Deal />
                            </ReadinessProvider>
                        </Layout>
                    </DealProvider>
                </Route>
                <Route path="/templates" exact>
                    {typeData?.user.type === 'TENANT' ? (
                        <Redirect to="/" />
                    ) : (
                        typeData?.user && (
                            <FilterProvider
                                key="template"
                                page="template"
                                filters={templateFilterConfig(typeData?.user.type)}
                                saveValuesOverride={saveValuesOverride}
                                userType={typeData?.user.type}
                            >
                                <Layout headerContent={headerContent}>
                                    <Templates setHeaderContent={setHeaderContent} />
                                </Layout>
                            </FilterProvider>
                        )
                    )}
                </Route>
                <Route path="/templates/:id">
                    <Layout headerContent={headerContent}>
                        <Template setHeaderContent={setHeaderContent} />
                    </Layout>
                </Route>
                <Route path="/settings">
                    {typeData?.user && (
                        <Layout headerContent={headerContent}>
                            <Settings
                                setHeaderContent={setHeaderContent}
                                userType={typeData?.user ? typeData?.user.type : undefined}
                            />
                        </Layout>
                    )}
                </Route>

                <Route path="/notifications">
                    <Layout headerContent={headerContent}>
                        <NotificationsPage
                            userID={typeData?.user ? typeData.user._id : undefined}
                            setHeaderContent={setHeaderContent}
                        />
                    </Layout>
                </Route>

                <Route path="/guidance-videos">
                    <Layout headerContent={headerContent}>
                        <GuidanceVideosPage
                            userType={typeData?.user ? typeData.user.type : undefined}
                            setHeaderContent={setHeaderContent}
                        />
                    </Layout>
                </Route>

                <Route path="/readiness">
                    {typeData?.user.type === 'TENANT' ? (
                        <Layout headerContent={headerContent}>
                            <Readiness setHeaderContent={setHeaderContent} />
                        </Layout>
                    ) : (
                        <Redirect to="/" />
                    )}
                </Route>

                <Route path="/" exact>
                    <Layout headerContent={headerContent}>
                        {typeData?.user.type === 'TENANT' ? (
                            <FilterProvider key="deal" page="deal" filters={dealFilterConfig(typeData?.user.type)}>
                                <Deals setHeaderContent={setHeaderContent} />
                            </FilterProvider>
                        ) : (
                            <LandlordDashboard setHeaderContent={setHeaderContent} userID={typeData?.user._id} />
                        )}
                    </Layout>
                </Route>
            </Switch>

            <ToastContainer />

            {showNewPracticeDialog && (
                <Window
                    title="New Practice"
                    width="w-1/3"
                    setShow={setShowNewPracticeDialog}
                    titleCenter
                    primaryBtn
                    onPrimaryBtnClick={() => handleConfirmClick()}
                    disableClose
                >
                    <>
                        <div className="flex flex-center flex-wrap  justify-center px-12">
                            <p className="text-newGray-darkish  py-4 pt-6 text-center">
                                {`You have been removed from a Practice. Please enter the name of your new Practice in the box below to continue.`}
                            </p>
                            <div className="flex flex-center flex-wrap  justify-center px-4 w-full">
                                <Input
                                    type={InputType.text}
                                    placeholder="Practice Name"
                                    className={`bg-grey-100 ${errorMessage ? 'mb-4' : 'mb-7'} w-full `}
                                    ref={nameRef}
                                />
                            </div>
                        </div>
                        {errorMessage && (
                            <div className="bg-red-200 border border-red-600 rounded py-2 px-4 mx-2 mb-4 ">
                                <span className="text-center text-red-600">{errorMessage}</span>
                            </div>
                        )}
                    </>
                </Window>
            )}
        </Router>
    );
};

export default App;
