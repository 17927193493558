import React, { useContext, useEffect } from 'react';

import ClauseAction from './Action';
import ApprovedAction from './ApprovedAction';
import ClauseContext from './Context';
import ClauseUndo from './Undo';
import Value from './Value';
import { ReactComponent as Cross } from '../../../../../assets/images/cross/black.svg';
import { DealState, DealUserParty } from '../../../../../generated/graphql';
import { Button } from '../../../../../shared/button';
import useMobileScreen from '../../../../../shared/hooks/useMobileScreen';
import { ClauseMode, ClauseType } from '../Types';

type ClauseActionsProps = {
    side?: 'left' | 'right';
};

const ClauseActions = ({ side }: ClauseActionsProps) => {
    const {
        approved,
        currentUserParty,
        dealBreaker,
        dealState,
        isEditing,
        mode,
        type,
        updateClauseLoading,
        value,
        handleSaveClick,
        setEditing,
        setRejectOpen
    } = useContext(ClauseContext);

    const mobileScreen = useMobileScreen();
    // Detect tenant first offer on Mobile
    const tenantFirstOffer =
        mobileScreen &&
        value === '*' &&
        currentUserParty === DealUserParty.Tenant &&
        type !== ClauseType.NEW &&
        type !== ClauseType.VALUE &&
        type !== ClauseType.CHANGED &&
        type !== ClauseType.SWAPPED &&
        type !== ClauseType.REMOVED &&
        type !== ClauseType.COMMENTED;

    useEffect(() => {
        if (tenantFirstOffer && !dealBreaker) {
            setEditing && setEditing(true);
        }
    }, [tenantFirstOffer, setEditing, dealBreaker]);

    return (
        <>
            {isEditing ? (
                <div className="flex justify-between items-center w-full">
                    {mobileScreen && (
                        <div className="flex items-center">
                            {/* X */}
                            <button
                                className={`mr-2`}
                                onClick={() => {
                                    if (tenantFirstOffer) {
                                        setEditing && setEditing(false);
                                        setRejectOpen && setRejectOpen(true);
                                    } else {
                                        setRejectOpen && setRejectOpen(false);
                                        setEditing && setEditing(false);
                                    }
                                }}
                            >
                                <Cross className="w-6 h-6" />
                            </button>
                            {isEditing ? <Value /> : <ClauseAction noControls />}
                        </div>
                    )}
                    <Button
                        onClick={() => value && handleSaveClick && handleSaveClick(value, value)}
                        disabled={updateClauseLoading}
                        background="newTeal-main"
                        text="white"
                        className={`w-16 mt-1 mr-4 rounded font-semibold smoothFadeIn ${side === 'left' && 'mt-0.5'}`}
                        height="8"
                    >
                        {updateClauseLoading ? 'Saving' : 'Save'}
                    </Button>
                </div>
            ) : (
                <div>
                    {mode === ClauseMode.MODIFIED && (
                        <div
                            className={`z-0 absolute transitionAll top-0 mt-1
                            ${side === 'left' ? 'undoHiddenLeft' : 'undoHidden'}
                            ${type === ClauseType.APPROVED && side !== 'left' && 'undoIconVisible'}
                            ${type !== ClauseType.APPROVED && side !== 'left' && 'undoVisible'}
                            ${type === ClauseType.APPROVED && side === 'left' && 'undoVisibleLeft'}
                            ${type !== ClauseType.APPROVED && side === 'left' && 'undoVisibleLeft'}
                            `}
                        >
                            <ClauseUndo />
                        </div>
                    )}
                    {mode === ClauseMode.EDIT && (!approved || type !== ClauseType.APPROVED) && (
                        <div
                            className={`z-10 ${side === 'left' ? 'actionsHiddenLeft' : 'actionsHidden'} ${
                                type !== ClauseType.APPROVED && side === 'left'
                                    ? 'actionsVisibleLeft'
                                    : type !== ClauseType.APPROVED && side !== 'left'
                                    ? 'actionsVisible'
                                    : ''
                            }`}
                        >
                            <ClauseAction />
                        </div>
                    )}
                    {approved && currentUserParty === 'LANDLORD' && dealState !== DealState.Complete && (
                        <ApprovedAction side={side} />
                    )}
                </div>
            )}
        </>
    );
};

export default ClauseActions;
