import { useApolloClient } from '@apollo/client';
import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';

import Dropzone from './Dropzone';
import { ReactComponent as Cloud } from '../../assets/images/cloud/black.svg';
import { ReactComponent as Cross } from '../../assets/images/cross/black.svg';
import {
    DocType,
    GetDealTenantDetailsDocument,
    GetUnitRatingDocument,
    useActionTaskMutation,
    useTenantActionStageFourFileMutation,
    useTenantActionStageThreeFileMutation,
    useUnitOtherFileMutation,
    useUploadDocumentMutation
} from '../../generated/graphql';
import { Button } from '../button/index';
import { fileSize, handleFetchError } from '../helpers';
import Input, { InputType } from '../Inputs';
import Loading from '../Loading';
import Window from '../Window';

type FileUploadModalProps = {
    id: string;
    other?: boolean;
    multiple?: boolean;
    taskID?: string;
    type: DocType;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
};

export const FileUploadModal = ({ id, taskID, other, multiple = false, type, setShow }: FileUploadModalProps) => {
    const client = useApolloClient();

    const uploadRef = useRef<HTMLInputElement>(null);

    const [files, setFiles] = useState<File[]>([]);
    const [fileUrls, setFileUrls] = useState<string[]>([]);
    const [progress, setProgress] = useState<number[]>([]);
    const [filesTotal, setFilesTotal] = useState<number>(0);
    const [filesComplete, setFilesComplete] = useState<number>(0);
    const [link, setLink] = useState<string | null>(null);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    // Upload document mutation
    const [documentCreate, { error: documentCreateError }] = useUploadDocumentMutation();

    // Add link mutations
    const [actionTask, { error: actionTaskError, loading: actionTaskLoading }] = useActionTaskMutation();
    const [otherFile, { error: otherFileError }] = useUnitOtherFileMutation();
    const [stage3File, { error: stage3FileError, loading: stage3Loading }] = useTenantActionStageThreeFileMutation();
    const [stage4File, { error: stage4FileError, loading: stage4Loading }] = useTenantActionStageFourFileMutation();

    // Generate signed URL and upload selected file
    const handleSubmitFile = async (newFiles?: FileList) => {
        setFiles([...files, ...(newFiles ?? [])]);
        if (!files) return setErrorMessage('Please select a file');

        setFilesTotal(files.length);

        await Promise.all(
            [...files, ...(newFiles ?? [])].map(async (file, index) => {
                // Only one file allowed if not specified as multiple
                if (!multiple && index > 0) return;
                if (progress[index] && progress[index] !== 0) return;

                setProgress((progress) => {
                    progress[index] = 0;
                    return progress;
                });

                const fileNameSplit = file.name.split('.');

                const fileType = file.type || fileNameSplit[fileNameSplit.length - 1];

                const res = await documentCreate({
                    variables: {
                        type: type,
                        filename: file.name,
                        filetype: fileType,
                        frontend: true,
                        id,
                        taskID
                    }
                });

                if (documentCreateError) return;

                const documentUrl = res.data?.docCreate.url ?? '';
                const signedUrl = res.data?.docCreate.signedUrl ?? '';

                await axios.put(signedUrl, file, {
                    headers: {
                        'Content-Type': file.type,
                        'Access-Control-Allow-Origin': '*'
                    },
                    onUploadProgress: (progressEvent) => {
                        setProgress((progress) => {
                            return progress.map((value, i) =>
                                i === index ? Math.round((progressEvent.loaded * 100) / progressEvent.total) : value
                            );
                        });
                    }
                });

                updateCache(file.name, documentUrl);

                if (!multiple) {
                    await handleSubmitLink(documentUrl, file.name);
                } else {
                    let tmp;
                    tmp = fileUrls;
                    tmp[index] = tmp[index] ? tmp[index] : documentUrl;
                    setFileUrls(tmp);
                }
            })
        );
    };

    // Set the task's file to a link
    const handleSubmitLink = async (link: string, name?: string) => {
        if (!link || link.trim().length === 0) return setErrorMessage('Please enter a valid link');

        let fileID;
        if (other) {
            const { data } = await otherFile({
                variables: {
                    unitID: id,
                    name: name || link,
                    url: link
                }
            });
            if (otherFileError) return;

            if (data) fileID = data?.unitOtherFile._id;
        } else if (type === DocType.Unit) {
            const { data } = await actionTask({
                variables: {
                    unitID: id,
                    taskID: taskID!,
                    fileValue: {
                        name: name ?? link,
                        url: link
                    }
                },
                refetchQueries: [
                    {
                        query: GetUnitRatingDocument,
                        variables: {
                            unitID: id
                        }
                    }
                ]
            });

            if (actionTaskError || !data) return;

            fileID = data.unitTaskAction.value?.__typename === 'File' ? data.unitTaskAction.value._id : undefined;
        } else if (type === DocType.Deal && taskID === '3') {
            const { data } = await stage3File({
                variables: {
                    dealID: id,
                    file: {
                        name: name ?? link,
                        url: link
                    }
                },
                refetchQueries: [
                    {
                        query: GetDealTenantDetailsDocument,
                        variables: {
                            dealID: id
                        }
                    }
                ]
            });

            if (stage3FileError || data?.dealTenantDetailsSet.__typename !== 'TenantDetails')
                return handleFetchError(
                    'Failed to add the link to your details',
                    stage3FileError,
                    data?.dealTenantDetailsSet
                );

            fileID = data.dealTenantDetailsSet.three?.files?.find((file) => file.url === link)?._id;
        } else if (type === DocType.Deal && taskID === '4') {
            const { data } = await stage4File({
                variables: {
                    dealID: id,
                    file: {
                        name: name ?? link,
                        url: link
                    }
                },
                refetchQueries: [
                    {
                        query: GetDealTenantDetailsDocument,
                        variables: {
                            dealID: id
                        }
                    }
                ]
            });

            if (stage4FileError || data?.dealTenantDetailsSet.__typename !== 'TenantDetails')
                return handleFetchError(
                    'Failed to add the link to your details',
                    stage4FileError,
                    data?.dealTenantDetailsSet
                );

            fileID = data.dealTenantDetailsSet.four?.files?.find((file) => file.url === link)?._id;
        }

        // Successfully added the link to the task, now update the cache
        updateCache(name ?? link, link, fileID);
        setFilesComplete((filesComplete) => filesComplete + 1);
    };

    const updateCache = (fileName: string, fileUrl: string, fileId?: string) => {
        const cachedFile = {
            __typename: 'File',
            _id: fileId ?? new Date().toTimeString(),
            name: fileName,
            url: fileUrl
        };

        client.cache.modify({
            id: client.cache.identify({
                __typename: 'Unit',
                _id: id
            }),
            fields: {
                files(existingFiles = []) {
                    return [...existingFiles, cachedFile];
                }
            }
        });

        if (!other) {
            client.cache.modify({
                id: client.cache.identify({
                    __typename: 'UnitTask',
                    _id: taskID
                }),
                fields: {
                    value() {
                        return cachedFile;
                    }
                }
            });
        }
    };

    useEffect(() => {
        if (filesComplete > 0 && filesComplete >= filesTotal) {
            // setSuccess(true)
            setShow(false);
        }
    }, [filesComplete, filesTotal, setShow]);

    if (actionTaskLoading)
        return (
            <Window title="File Upload" width="w-1/3" zIndex="60" setShow={setShow}>
                <Loading />
            </Window>
        );

    const handleDrop = async (newFiles?: FileList) => {
        handleSubmitFile(newFiles);
    };

    return (
        <Window title="File Upload" width="w-3/5" setShow={setShow} zIndex="60">
            <div className="p-4 w-full items-center justify-around flex-col">
                {/* Upload file */}
                <form className="flex flex-col flex-1 border-navy-light" onSubmit={(e) => e.preventDefault()}>
                    {/* File upload dropzone */}
                    <Dropzone callback={handleDrop}>
                        <div
                            className="flex flex-col items-center justify-center h-full cursor-pointer"
                            onClick={(e) => {
                                if (!multiple && files.length > 0) return;
                                e.preventDefault();
                                uploadRef.current?.click();
                            }}
                        >
                            <Cloud
                                className="w-18 h-20 text-teal-400 stroke-current"
                                style={{
                                    stroke: '#4fd1c5',
                                    width: '50px',
                                    height: '50px'
                                }}
                            />
                            <p className="text-base text-black-700 font-bold text-center mt-2">
                                Drag {multiple ? 'files' : 'a file'} here
                            </p>
                        </div>
                    </Dropzone>

                    {/* Hidden input to get file selector */}
                    <input
                        type="file"
                        name="file"
                        className="hidden"
                        multiple={multiple}
                        value={undefined}
                        ref={uploadRef}
                        onChange={(e) => e.target.files && handleDrop(e.target.files)}
                    />

                    <div className="flex md:flex-row flex-col items-center mt-4">
                        <Button
                            background="newTeal-main"
                            text="white"
                            className={`px-4 font-semibold w-full md:w-auto mb-2 md:mb-0 whitespace-no-wrap`}
                            onClick={(e) => {
                                e.preventDefault();
                                uploadRef.current?.click();
                            }}
                        >
                            Choose File
                        </Button>
                        <p className="px-6 text-gray-600 mb-2 md:mb-0">Or</p>
                        <div className="flex flex-row items-center w-full">
                            <Input
                                type={InputType.text}
                                value={link}
                                onChange={(e) => setLink(e.target.value)}
                                className="w-full"
                                placeholder="Add link to a file"
                            />
                            <button
                                onClick={(e) => {
                                    e.preventDefault();
                                    setFilesTotal(1);
                                    link && handleSubmitLink(link, link);
                                }}
                                className="p-3 px-4 bg-gray-200 text-black border-gray-200 rounded ml-2"
                                style={{
                                    width: 'max-content'
                                }}
                            >
                                {link && (actionTaskLoading || stage3Loading || stage4Loading) ? 'Saving...' : 'Add'}
                            </button>
                        </div>
                    </div>
                </form>

                {files && files.length > 0 && (
                    <div className="max-h-full overflow-hidden overflow-y-auto">
                        <h2 className="text-black-700 font-bold text-lg my-2">Files</h2>
                        {files.map((file, index) => (
                            <div
                                key={index}
                                className="truncate flex flex-col w-full py-2 border-navy-lightest"
                                style={{ borderTopWidth: '1px' }}
                            >
                                <div className="flex flex-row justify-between items-center">
                                    <div className="flex flex-row items-center">
                                        <p className="text-black-700 font-bold">{file.name}</p>
                                        <p className="text-gray-600 text-sm ml-4">{fileSize(file.size)}</p>
                                    </div>

                                    <Cross
                                        className="cursor-pointer w-4 h-4 inline"
                                        onClick={() =>
                                            setFiles(files.filter((_, selectedIndex) => index !== selectedIndex))
                                        }
                                    />
                                </div>
                                {/* Progress bar */}
                                <div
                                    className={`w-full h-2 bg-navy-light overflow-hidden relative rounded-full my-1 ${
                                        progress.reduce((a, b) => a + b, 0) === 0 && 'opacity-50'
                                    }`}
                                >
                                    <span
                                        className="bg-teal-400 absolute h-full top-0 left-0 transition-all duration-100 rounded-full"
                                        style={{
                                            backgroundColor: '#4fd1c5',
                                            width: `${
                                                progress[index] === 0 || files.length === 0 ? 0 : progress[index]
                                            }%`
                                        }}
                                    ></span>
                                </div>
                                <p className="text-gray-600 text-sm">{`${
                                    progress.reduce((a, b) => a + b, 0) === 0 || files.length === 0
                                        ? 0
                                        : progress[index]
                                }%`}</p>
                            </div>
                        ))}
                        {multiple && (
                            <div className="flex flex-row justify-end">
                                <button
                                    onClick={async (e) => {
                                        e.preventDefault();
                                        if (fileUrls && fileUrls.length > 0) {
                                            await Promise.all(
                                                fileUrls.map(async (url: string, index: number) => {
                                                    await handleSubmitLink(url, files[index].name);
                                                })
                                            );
                                        }
                                    }}
                                    className="p-3 px-4 bg-newTeal-main text-white rounded"
                                    style={{
                                        width: 'max-content',
                                        minWidth: 'max-content'
                                    }}
                                >
                                    Finish
                                </button>
                            </div>
                        )}
                    </div>
                )}
                <div className="px-4">
                    {errorMessage && (
                        <div className="bg-red-200 border border-red-600 rounded py-2 px-4 my-4 mt-2">
                            <span className="text-center text-red-600">{errorMessage}</span>
                        </div>
                    )}
                </div>
            </div>
        </Window>
    );
};
