import React, { FC, HTMLAttributes, useMemo } from 'react';

export type FlexDirection = 'column' | 'column-reverse' | 'row' | 'row-reverse';

export interface FlexProps extends HTMLAttributes<HTMLDivElement> {
    className?: string;
    direction?: FlexDirection;
}

export const Flex: FC<FlexProps> = ({ className, direction, ...otherProps }) => {
    const flexDirection = useMemo(() => {
        switch (direction) {
            case 'column':
                return 'flex-col';
            case 'column-reverse':
                return 'flex-col-reverse';
            case 'row':
                return 'flex-row';
            case 'row-reverse':
                return 'flex-row-reverse';
            default:
                return '';
        }
    }, [direction]);

    return <div className={`flex ${flexDirection} ${className}`} {...otherProps}></div>;
};
