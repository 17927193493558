import React, { useState } from 'react';

import { useGetUserProfileQuery, useUpdateUserMutation } from '../../generated/graphql';
import Input, { InputType } from '../../shared/Inputs';
import Window from '../../shared/Window';

type PasswordCheckWindowProps = {
    setShowPasswordCheckWindow: React.Dispatch<React.SetStateAction<boolean>>;
    updatedUser: object;
    setUpdating: React.Dispatch<React.SetStateAction<boolean>>;
};

const PasswordCheckWindow = ({ setShowPasswordCheckWindow, updatedUser, setUpdating }: PasswordCheckWindowProps) => {
    const { refetch } = useGetUserProfileQuery();
    const [password, setPassword] = useState('');
    const [validateError, setValidateError] = useState('');
    const [updateUser, { error: updateError }] = useUpdateUserMutation();

    const handlePasswordCheck = async () => {
        if (password === '') return setValidateError('Current password is required');

        const data = {
            ...updatedUser,
            currentPassword: password
        };

        await updateUser({
            variables: data
        });

        if (updateError) {
            if (updateError.graphQLErrors[0].extensions?.code === 'BAD_LOGIN') {
                setValidateError(`Incorrect password`);
            } else {
                if (updateError?.message) {
                    setValidateError(updateError.message);
                }
            }
            return;
        }
        setPassword('');
        setValidateError('');
        refetch();
        setShowPasswordCheckWindow(false);
        setUpdating(false);
    };

    return (
        <div>
            <Window
                title={`Password check`}
                width="w-2/5"
                setShow={setShowPasswordCheckWindow}
                contentMiddle={true}
                primaryBtn
                onPrimaryBtnClick={handlePasswordCheck}
                secondaryBtn
                onSecondaryBtnClick={() => setShowPasswordCheckWindow(false)}
            >
                <div className="flex flex-col items-center px-8">
                    <h2 className="text-center text-lg my-4 w-4/5">
                        Please enter your password to update your profile:
                    </h2>
                    <Input
                        type={InputType.password}
                        name="password"
                        value={password}
                        placeholder="Your password"
                        label="Password"
                        onChange={(e) => {
                            setPassword(e.target.value);
                        }}
                        className="bg-navy-lightest mb-6 border-none w-full"
                    />
                    {/* Errors */}
                    {validateError && (
                        <div className="bg-red-200 border border-red-600 mb-6 rounded py-2 px-4 w-full">
                            <span className="text-center text-red-600">{validateError}</span>
                        </div>
                    )}
                </div>
            </Window>
        </div>
    );
};

export default PasswordCheckWindow;
