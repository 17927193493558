import React from 'react';

import NoDealsImage from '../../assets/images/noData/deal.png';
import { AddButton } from '../../shared/add-button';

type NoDealsProps = {
    setShowNewDealWindow: React.Dispatch<React.SetStateAction<boolean>>;
    isTenant: boolean;
};

const NoDeals = ({ setShowNewDealWindow, isTenant }: NoDealsProps) => {
    return (
        <div className="h-full w-full flex flex-col items-center justify-center">
            <div
                className="h-64 w-64 bg-no-repeat bg-contain"
                style={{
                    backgroundImage: `url(${NoDealsImage})`
                }}
            />
            <div className="flex flex-col items-center text-newGray-darkish">
                <p className="font-semibold ">{isTenant ? 'No Deals found' : 'Start your first Deal'}</p>
                {isTenant ? (
                    <p className="text-sm mt-1">
                        You currently have no offers available on{' '}
                        <span className="font-semibold">
                            <i>K</i>ato
                        </span>
                    </p>
                ) : (
                    <>
                        <p className="text-sm mt-1">
                            Select the button below to begin your
                            <br /> digital negotiation experience on{' '}
                            <span className="font-semibold">
                                <i>L</i>east.
                            </span>
                        </p>
                        <AddButton
                            onClick={() => {
                                setShowNewDealWindow(true);
                            }}
                            height="10"
                            className={
                                'pr-2 mt-2 bg-newTeal-light text-newGray-darkest hover:text-white hover:bg-newTeal-main border-none w-40'
                            }
                        >
                            New Deal
                        </AddButton>
                    </>
                )}
            </div>
        </div>
    );
};

export default NoDeals;
