import React, { useRef, useState } from 'react';

import {
    SearchClauseBankDocument,
    useClauseBankEditSectionsMutation,
    useOrganisationSectionsEditMutation
} from '../../../generated/graphql';
import { handleFetchError } from '../../../shared/helpers';
import Input, { InputType } from '../../../shared/Inputs';
import Loading from '../../../shared/Loading';
import Window from '../../../shared/Window';

type EditHelpWindowProps = {
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
    sectionID?: string | null;
    organisationID?: string | null;
    fromSection?: string | null;
    fromGroup?: string | null;
    group?: boolean;
    description?: string | null;
    rics?: string | null;
    setShowConfirmDelete: React.Dispatch<React.SetStateAction<boolean>>;
    children: React.ReactNode[];
};
const EditHelpWindow = ({
    setShow,
    sectionID,
    organisationID,
    fromSection,
    rics,
    fromGroup,
    description,
    group,
    setShowConfirmDelete,
    children
}: EditHelpWindowProps) => {
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    const [editAdminClauseSections, { error: editAdminError, loading: editAdminLoading }] =
        useClauseBankEditSectionsMutation();

    const [editOrgClauseSections, { error: editOrgError, loading: editOrgLoading }] =
        useOrganisationSectionsEditMutation();

    const handleUpdate = async () => {
        if (!fromSection) return;
        const descriptionValue =
            descriptionRef.current?.value && descriptionRef.current?.value.trim().length > 0
                ? descriptionRef.current?.value
                : undefined;

        const ricsValue =
            ricsRef.current?.value && ricsRef.current.value.trim().length > 0 ? ricsRef.current.value : undefined;

        const titleValue =
            titleRef.current?.value && titleRef.current?.value.trim().length > 0 ? titleRef.current?.value : undefined;

        if (!titleValue) {
            setErrorMessage('Title is a required field');
            return;
        }

        if (organisationID) {
            const { data } = await editOrgClauseSections({
                variables: {
                    organisationID: organisationID,
                    fromSection: fromSection,
                    toSection: !fromGroup ? titleValue || fromSection : null,
                    toGroup: fromGroup ? titleValue : null,
                    fromGroup: fromGroup,
                    sectionDescription: descriptionValue && !fromGroup ? descriptionValue : undefined,
                    sectionRics: ricsValue && !fromGroup ? ricsValue : undefined,
                    groupRics: ricsValue && fromGroup ? ricsValue : undefined,
                    groupDescription: descriptionValue && fromGroup ? descriptionValue : undefined
                },
                refetchQueries: [
                    {
                        query: SearchClauseBankDocument,
                        variables: { organisationID: organisationID || '' }
                    }
                ]
            });
            if (editOrgError) {
                return handleFetchError('Failed to update the entry', editOrgError, data?.organisationSectionsEdit);
            } else {
                setShow(false);
            }
        } else {
            const { data } = await editAdminClauseSections({
                variables: {
                    fromSection: fromSection,
                    toSection: !fromGroup ? titleValue || fromSection : null,
                    toGroup: fromGroup ? titleValue : null,
                    fromGroup: fromGroup,
                    sectionDescription: descriptionValue && !fromGroup ? descriptionValue : undefined,
                    sectionRics: ricsValue && !fromGroup ? ricsValue : undefined,
                    groupRics: ricsValue && fromGroup ? ricsValue : undefined,
                    groupDescription: descriptionValue && fromGroup ? descriptionValue : undefined
                },
                refetchQueries: [
                    {
                        query: SearchClauseBankDocument,
                        variables: { organisationID: organisationID || '' }
                    }
                ]
            });

            if (editAdminError) {
                return handleFetchError('Failed to update the entry', editAdminError, data?.adminClauseSectionsEdit);
            } else {
                setShow(false);
            }
        }
    };
    const titleRef = useRef<HTMLInputElement>(null);
    const descriptionRef = useRef<HTMLTextAreaElement>(null);
    const ricsRef = useRef<HTMLTextAreaElement>(null);
    return (
        <>
            <Window
                width="w-2/5"
                title={`Edit Clause ${sectionID ? 'Group' : 'Heading'}`}
                setShow={setShow}
                primaryBtn={!editAdminLoading && !editOrgLoading}
                primaryBtnText="Confirm Edit"
                onPrimaryBtnClick={handleUpdate}
                secondaryBtn={children.length < 1}
                secondaryBtnText={`Delete Clause ${sectionID ? 'Group' : 'Heading'}`}
                onSecondaryBtnClick={() => {
                    setShowConfirmDelete(true);
                    setShow(false);
                }}
            >
                <form
                    className="flex flex-col py-4 px-2 w-full"
                    onSubmit={(event) => {
                        event.preventDefault();
                        handleUpdate();
                    }}
                >
                    {editAdminLoading || editOrgLoading ? (
                        <Loading />
                    ) : (
                        <>
                            <div className="flex flex-row items-center md:flex-no-wrap w-full gap-4 mb-4">
                                <Input
                                    type={InputType.text}
                                    ref={titleRef}
                                    defaultValue={fromGroup || fromSection}
                                    placeholder="Name"
                                    label={`Clause ${sectionID ? 'Group' : 'Heading'} Name`}
                                    className="w-full"
                                />
                            </div>
                            <div className="flex flex-col">
                                <label htmlFor="section-name" className="text-sm text-gray-600 mb-2">
                                    Description/Help Text
                                </label>
                                <textarea
                                    ref={descriptionRef}
                                    rows={2}
                                    defaultValue={description ? description : undefined}
                                    className="p-3 border border-navy-light focus:border-newTeal-main rounded w-full  focus:outline-none"
                                    placeholder="Description text"
                                ></textarea>
                                <label htmlFor="section-name" className="text-sm text-gray-600 mb-2 mt-2">
                                    Longer RICS Explainer
                                </label>
                                <textarea
                                    ref={ricsRef}
                                    rows={3}
                                    defaultValue={rics ? rics : undefined}
                                    className="p-3 border border-navy-light focus:border-newTeal-main rounded w-full  focus:outline-none"
                                    placeholder="Description text"
                                ></textarea>
                            </div>
                        </>
                    )}
                    <div className="w-full">
                        <div className="px-2">
                            {errorMessage && (
                                <div className="bg-red-200 border border-red-600 rounded py-2 px-2 my-2 mt-2">
                                    <span className="text-center text-red-600">{errorMessage}</span>
                                </div>
                            )}
                        </div>
                    </div>
                </form>
            </Window>
        </>
    );
};

export default EditHelpWindow;
