import React from 'react';

import MobileWindow from '../MobileWindow';

type CreatedWindowProps = {
    setShowCreatedWindow: React.Dispatch<React.SetStateAction<boolean>>;
    date?: string;
    creator?: string;
};

const CreatedWindow = ({ setShowCreatedWindow, date, creator }: CreatedWindowProps) => {
    return (
        <MobileWindow setShow={setShowCreatedWindow}>
            <div className="flex flex-col gap-4 mb-4">
                <div className="flex flex-row border-b border-gray-600  pb-2">
                    <p className="font-semibold mr-2">Created on </p>
                    <p> {date && date}</p>
                </div>
                <div className="flex flex-row border-b pt-4 border-gray-600 pb-2">
                    <p className="font-semibold mr-2">Created by </p>
                    <p> {creator && creator}</p>
                </div>
            </div>
        </MobileWindow>
    );
};

export default CreatedWindow;
