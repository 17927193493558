// TODO: Remove next line once everything around company view is done
/* eslint-disable @typescript-eslint/no-unused-vars */
import { format } from 'date-fns';
import React, { useState } from 'react';

import { ReactComponent as Activity } from '../../../assets/images/activity/activityGray.svg';
import { ReactComponent as Anchor } from '../../../assets/images/anchor/anchor.svg';
import { ReactComponent as Delete } from '../../../assets/images/delete/black.svg';
import { ReactComponent as Edit } from '../../../assets/images/edit/black.svg';
import { ReactComponent as Eye } from '../../../assets/images/eye/black.svg';
import { ReactComponent as User } from '../../../assets/images/user/black.svg';
import { ReactComponent as Web } from '../../../assets/images/web/webGray.svg';
import { UserType } from '../../../generated/graphql';
import DefaultProfile from '../../../layout/SideBar/components/DefaultProfile';
import useMobileScreen from '../../../shared/hooks/useMobileScreen';
import Options from '../../../shared/Options';

type CompanyCardProps = {
    id: string;
    name: string;
    avatar?: string | null;
    members: number;
    created?: string;
    website?: string | undefined;
    type?: string;
    organisation: any;
    practiceType: string;
    setCompanyDetailsWindow: React.Dispatch<React.SetStateAction<boolean>>;
    setShowAdminDeleteCompanyWindow: React.Dispatch<React.SetStateAction<boolean>>;
    setShowAdminEditOrganisationWindow: React.Dispatch<React.SetStateAction<boolean>>;
    setOrganisation: React.Dispatch<React.SetStateAction<any>>;
};

enum ClientType {
    TENANT = 'Tenant Company',
    LANDLORD = 'Landlord Company',
    ADMIN = LANDLORD,
    AGENT = 'Agent Company',
    LEGAL = 'Legal Practice',
    AGENCY = 'Agency Practice',
    PROPERTY_MANAGEMENT = 'Property Managment Practice'
}

const CompanyCard = ({
    id,
    name,
    avatar,
    members,
    created,
    website,
    type,
    organisation,
    practiceType,
    setCompanyDetailsWindow,
    setShowAdminDeleteCompanyWindow,
    setShowAdminEditOrganisationWindow,
    setOrganisation
}: CompanyCardProps) => {
    const createdFormat = created && format(new Date(created), 'dd MMMM yyyy');
    const mobileScreen = useMobileScreen();

    const openInNewTab = (url: string) => {
        window.open(url, '_blank', 'noopener,noreferrer');
    };

    return (
        <>
            <div
                key={id}
                className={`flex justify-between ${
                    mobileScreen ? 'h-44' : 'h-24'
                } w-full bg-white rounded px-3 py-4 mb-2`}
            >
                <div className="flex gap-2 items-center justify-start">
                    {avatar ? (
                        <img src={avatar} alt={name} className="w-10 h-10 rounded object-cover" />
                    ) : (
                        <DefaultProfile name={name} size="2.5rem" fontSize="1rem" />
                    )}
                    <div className={`flex ${mobileScreen ? 'flex-col' : 'flex-row'} items-center ml-4 mr-2`}>
                        <div className={`flex flex-col h-18 w-48 ${mobileScreen ? '' : ' border-r-2'} mr-4 pr-1`}>
                            <p className="font-medium flex items-center justify-start">{name}</p>
                            {type && (type !== UserType.Agent || !practiceType) ? (
                                <p className="text-sm">{ClientType[type]}</p>
                            ) : (
                                type === UserType.Agent &&
                                practiceType && <p className="text-sm">{ClientType[practiceType]}</p>
                            )}
                        </div>
                        <div className={`flex flex-col ${mobileScreen ? 'w-full' : ''}`}>
                            {members && (
                                <div className="flex flex-row items-center">
                                    <User stroke="#a9a9a9" className="w-3 h-3 mr-2 text-sm " />
                                    <p className="text-sm">{members}</p>
                                </div>
                            )}
                            {createdFormat && (
                                <p className="text-sm">
                                    {' '}
                                    <Anchor stroke="#a9a9a9" className="w-3 h-3 mr-2 text-sm inline " />
                                    {createdFormat}
                                </p>
                            )}
                            {website && (
                                <p
                                    className="text-sm underline"
                                    style={{ color: '#0000EE' }}
                                    onClick={() => openInNewTab(website)}
                                >
                                    {' '}
                                    <Web stroke="#a9a9a9" className="w-3 h-3 mr-2 text-sm inline " />
                                    {website}
                                </p>
                            )}
                        </div>
                    </div>
                </div>
                <Options className={'sm:w-24'}>
                    {[
                        {
                            title: 'Delete Company',
                            action: () => {
                                setShowAdminDeleteCompanyWindow(true);
                                setOrganisation(organisation);
                            },
                            icon: Delete
                        },
                        {
                            title: 'Edit Company',
                            action: () => {
                                setShowAdminEditOrganisationWindow(true);
                                setOrganisation(organisation);
                            },
                            icon: Edit
                        },
                        {
                            title: 'View Company',
                            action: () => {
                                setCompanyDetailsWindow(true);
                                setOrganisation(organisation);
                            },
                            icon: Eye
                        }
                    ]}
                </Options>
            </div>
        </>
    );
};

export default CompanyCard;
