import React, { useEffect, useState } from 'react';
import { ValueType } from 'react-select';

import AddUserAsAgentWindow from './AddUserAsAgent';
import ConfirmUserRemoveWindow from './Landlord/ConfirmUserRemoveWindow';
import { ReactComponent as ArrowDown } from '../../../assets/images/chevron/down/black.svg';
import { ReactComponent as Close } from '../../../assets/images/cross/black.svg';
import { ReactComponent as Remove } from '../../../assets/images/delete/black.svg';
import { ReactComponent as Envelope } from '../../../assets/images/envelope/black.svg';
import { ReactComponent as Phone } from '../../../assets/images/phone/black.svg';
import { ReactComponent as Plus } from '../../../assets/images/plus/black.svg';
import {
    GetUnitDetailsDocument,
    GetUserOrganisationDocument,
    UnitAddressFragment,
    UnitCondition,
    UnitSingleActorFragment,
    UnitUse,
    useAddActorToUnitMutation,
    useGetUserCompanyQuery,
    useGetUserOrganisationQuery,
    useGetUserProfileQuery,
    UserType
} from '../../../generated/graphql';
import DefaultProfile from '../../../layout/SideBar/components/DefaultProfile';
import { Button } from '../../../shared/button';
import { handleFetchError } from '../../../shared/helpers';
import Select from '../../../shared/Inputs/Select';
import { UseConditionToText } from '../../../shared/NewUnit/hooks/useGetUnitSelectData';
import Window from '../../../shared/Window';

type UnitOverviewProps = {
    _id: string;
    tenant?: boolean;
    address: UnitAddressFragment;
    area: number | undefined;
    use?: UnitUse | false | null;
    condition: UnitCondition | undefined;
    actors?: UnitSingleActorFragment[];
    avatar?: string | null;
    url?: string | null;
};

const UnitOverview = ({
    _id,
    tenant = false,
    address,
    area,
    use,
    condition,
    actors,
    avatar,
    url
}: UnitOverviewProps) => {
    const { data: userOrganisationData } = useGetUserOrganisationQuery();
    const [showConfirmRemoveUserWindow, setShowConfirmRemoveUserWindow] = useState<boolean>(false);
    const [userID, setUserID] = useState<string>('');

    const [showAddUser, setshowAddUser] = useState<boolean>(false);
    const [dataActors, setDataActors] = useState<UnitSingleActorFragment[]>(actors ?? []);

    const [emails, setEmails] = useState<string[]>([]);

    const { data: typeData } = useGetUserProfileQuery();

    let agentArray = dataActors?.filter((actor) => actor.type === UserType.Agent);
    const sortedArray: UnitSingleActorFragment[] = [];
    agentArray.forEach((x) => {
        if (x.organisation._id === typeData?.user.organisation._id) sortedArray.unshift(x);
        else sortedArray.push(x);
    });
    agentArray = sortedArray;
    const firstFour = agentArray.slice(0, 4);
    const [collapsed, setCollapsed] = useState<boolean>(false);

    useEffect(() => {
        setCollapsed(agentArray.length > 4);
    }, [dataActors]);

    const [addActor, { error: addActorError, loading: addActorLoading }] = useAddActorToUnitMutation();

    const { data: companyData, error: companyError } = useGetUserCompanyQuery({
        variables: {
            unitID: _id
        }
    });

    useEffect(() => {
        handleFetchError('Could not load user company', companyError, companyData?.user);
    }, [companyData, companyError]);

    const pickerData =
        userOrganisationData &&
        userOrganisationData?.user.organisation.trustedUsers.filter((trustedUser) => {
            const actorsEmails = agentArray.map((actor) => actor.email);
            const agentsSet = new Set(actorsEmails);
            return !agentsSet.has(trustedUser.email);
        });

    const handleAddUser = async () => {
        setShowConfirmAdd(false);

        const { data } = await addActor({
            variables: {
                unitID: _id,
                emails,
                type: UserType.Agent
            },
            refetchQueries: [
                { query: GetUserOrganisationDocument },
                { query: GetUnitDetailsDocument, variables: { unitID: _id } }
            ]
        });

        handleFetchError('Could not add the user to the unit', addActorError, data?.unitAddActor);
        setshowAddUser(false);

        data?.unitAddActor.actors && setDataActors(data.unitAddActor.actors);
    };

    const checkBeginningURL = (url: string) => {
        const lowerURL = url.toLowerCase().trim();
        if (lowerURL.startsWith('http://') || lowerURL.startsWith('https://')) {
            return '';
        }
        return 'http://';
    };

    const [showConfirmAdd, setShowConfirmAdd] = useState<boolean>(false);

    return (
        <>
            {showAddUser && typeData?.user.type === UserType.Agent && (
                <AddUserAsAgentWindow
                    unitID={_id}
                    setDataActors={setDataActors}
                    agentArray={agentArray}
                    setShow={setshowAddUser}
                />
            )}
            {showConfirmRemoveUserWindow && (
                <ConfirmUserRemoveWindow
                    setShow={setShowConfirmRemoveUserWindow}
                    unitID={_id}
                    userID={userID}
                    actors={dataActors}
                    setDataActors={setDataActors}
                />
            )}
            {showConfirmAdd && typeData?.user.type !== UserType.Agent && (
                <Window
                    title="Confirm"
                    width="w-2/5"
                    setShow={setShowConfirmAdd}
                    primaryBtn
                    onPrimaryBtnClick={() => handleAddUser()}
                    secondaryBtn
                    onSecondaryBtnClick={() => setShowConfirmAdd(false)}
                >
                    <div className="text-center p-4">
                        “Are they a team member” checkbox remains unchecked. By continuing you will be adding a user who
                        will be created a separate company rather than joining yours. Do you wish to proceed?
                    </div>
                </Window>
            )}
            <div className="bg-white rounded">
                {/* Address & Profile */}
                <div className="p-3 flex flex-row w-full relative border-b border-navy-light">
                    {avatar ? (
                        <img
                            src={avatar ? avatar : 'https://via.placeholder.com/125'}
                            alt="Building"
                            className="object-cover rounded self-center h-32 w-32"
                        />
                    ) : (
                        <DefaultProfile
                            name={companyData?.user.__typename === 'User' ? companyData.user.organisation?.name : ''}
                            size={'8rem'}
                        />
                    )}
                    <div className="ml-3">
                        <p className="text-sm font-semibold text-newGray-darkish">Address</p>
                        <p>{address && address.line1}</p>
                        <p>{address && address.line2}</p>
                        <p>{address && address.city}</p>
                        <p>{address && address.county}</p>
                        <p>{address && address.postCode}</p>
                    </div>
                </div>
                {/* Demise Area / Use Cause / Condition */}
                <div className="w-full flex items-start p-3 pb-4 border-b border-navy-light">
                    <div className="w-1/3">
                        <p className="text-sm font-semibold text-newGray-darkish">Demise Area</p>
                        <p>{`${area && area.toLocaleString() + ' sq ft'}`}</p>
                    </div>
                    <div className="w-1/3">
                        <p className="text-sm font-semibold text-newGray-darkish">Use Clause</p>
                        <p>{use && UseConditionToText(use)}</p>
                    </div>
                    <div className="w-1/3">
                        <p className="text-sm font-semibold text-newGray-darkish">Condition</p>
                        <p>{condition && UseConditionToText(condition)}</p>
                    </div>
                </div>
                {url && (
                    <div className="w-full flex items-start p-3 pb-4">
                        <div className="w-full">
                            <p className="text-sm font-semibold text-newGray-darkish mr-2">Website</p>
                            <a href={checkBeginningURL(url) + url} target="_blank" rel="noopener noreferrer">
                                {url}
                            </a>
                        </div>
                    </div>
                )}
            </div>
            {/* Actors */}
            <div className={`w-full bg-navy-light rounded pt-3`}>
                <div className="flex flex-row">
                    <p className="text-base font-semibold text-newGray-darkish mb-3 mt-2 w-full">Advisors</p>
                    <div
                        className="flex justify-center items-center cursor-pointer quickTransitionAll"
                        onClick={() => {
                            typeData?.user.type === UserType.Agent
                                ? setshowAddUser(true)
                                : setshowAddUser(!showAddUser);
                        }}
                    >
                        {!showAddUser || typeData?.user.type === UserType.Agent ? (
                            <Plus className="w-auto mr-2 h-5" />
                        ) : (
                            <Close className="w-auto mr-2 h-5" />
                        )}
                        <span
                            className={
                                showAddUser ? 'w-auto' : typeData?.user.type === UserType.Agent ? 'w-20' : 'w-24'
                            }
                        >
                            {!showAddUser && (typeData?.user.type === 'AGENT' ? 'Add User' : 'Add Advisor')}
                        </span>
                    </div>
                </div>
                {showAddUser && userOrganisationData && pickerData && typeData?.user.type !== UserType.Agent && (
                    <div className="mt-2">
                        <form
                            className="flex flex-row flex-wrap items-end w-full rounded pt-4"
                            onSubmit={(e) => {
                                e.preventDefault();
                            }}
                        >
                            <Select
                                data={Object.values(pickerData).map((trustedUser) => {
                                    return {
                                        text: trustedUser.activated
                                            ? trustedUser.firstName +
                                              ' ' +
                                              trustedUser.lastName +
                                              ' (' +
                                              trustedUser.organisation.name +
                                              ')'
                                            : trustedUser.email,
                                        id: trustedUser.email
                                    };
                                })}
                                label="Email"
                                placeholder={emails.length > 0 ? 'Type to add another email' : 'Enter email'}
                                isMulti
                                blackStyle
                                createable
                                color="black"
                                chipBackground="white"
                                className="md:mr-2 bg-navy-lightest w-full flex-auto mb-4"
                                onChange={(value: ValueType) => {
                                    if (!value || value.length === 0) {
                                        setEmails([]);
                                        return;
                                    }

                                    value.map((item: ValueType) => {
                                        const newUsersArr = [...emails];
                                        newUsersArr.push(item.value);
                                        setEmails(newUsersArr);
                                    });
                                }}
                            />

                            <div className="flex flex-row justify-between w-full my-3">
                                <Button
                                    type="button"
                                    onClick={() => handleAddUser()}
                                    text="white"
                                    background="newTeal-main"
                                    className="px-8 font-semibold font-sm mr-1"
                                    disabled={addActorLoading}
                                >
                                    {addActorLoading ? 'Adding...' : 'Add'}
                                </Button>
                            </div>
                        </form>
                    </div>
                )}

                {dataActors &&
                    (collapsed ? firstFour : agentArray).map(
                        (actor) =>
                            actor.type === UserType.Agent && (
                                <div
                                    key={actor._id}
                                    className="flex flex-row w-full rounded mb-2 bg-white py-2 px-2 border "
                                    style={
                                        actor.organisation?._id === typeData?.user.organisation?._id
                                            ? {
                                                  borderLeft: '6px solid #3CA48E'
                                              }
                                            : { borderLeft: '6px solid white' }
                                    }
                                >
                                    <div className="w-1/3 rounded-bl rounded-tl border-r-2 border-navy-light mr-4 flex content-start self-center flex-wrap flex-col">
                                        {!actor.pending && (
                                            <p className="font-semibold mt-1">{`${actor.firstName} ${
                                                actor.lastName ? actor.lastName : ''
                                            }`}</p>
                                        )}
                                        <p
                                            className={`text-sm font-medium text-newGray-darkish mt-1 ${
                                                actor.pending && 'mt-4 mb-4'
                                            }`}
                                        >
                                            {actor.organisation?.name}
                                        </p>
                                    </div>
                                    <div className="w-2/3 h-16 rounded-tr rounded-br flex flex-row items-center justify-between overflow-x-hidden ">
                                        <div className="flex flex-wrap items-center" style={{ maxWidth: '90%' }}>
                                            <div
                                                className="flex flex-row items-center"
                                                style={{
                                                    minWidth: '90%',
                                                    maxWidth: '100%'
                                                }}
                                            >
                                                <Envelope stroke="black" className="w-3 h-3 mr-2 text-sm -mt-1" />
                                                <p
                                                    className="text-sm overflow-x-hidden"
                                                    style={{
                                                        textOverflow: 'ellipsis'
                                                    }}
                                                >
                                                    {actor.email}
                                                </p>
                                            </div>
                                            {!actor.pending ? (
                                                <p className="text-sm">
                                                    {' '}
                                                    <Phone
                                                        stroke="black"
                                                        className="w-3 h-3 mr-2 text-sm inline -mt-1 "
                                                    />
                                                    {actor.mobile}
                                                </p>
                                            ) : (
                                                <>
                                                    <br />
                                                    <p className="text-gray-800 text-sm font-light">Invitation sent</p>
                                                </>
                                            )}
                                        </div>
                                        {!tenant && (
                                            <button
                                                className="focus:outline-none mr-1"
                                                onClick={() => {
                                                    setUserID(actor._id);
                                                    setShowConfirmRemoveUserWindow(true);
                                                }}
                                            >
                                                <Remove className="w-5 h-5" />
                                            </button>
                                        )}
                                    </div>
                                </div>
                            )
                    )}
                {!tenant && (
                    <>
                        {collapsed && (
                            <div
                                className="flex justify-center bg-white rounded w-full items-center py-2 cursor-pointer hover:bg-navy-lightest quickTransitionAll"
                                onClick={() => setCollapsed(false)}
                            >
                                <p style={{ color: '#7d7d7d' }}>View All ({agentArray.length} Users)</p>
                                <ArrowDown className="w-7 h-5" />
                            </div>
                        )}
                    </>
                )}
            </div>
        </>
    );
};

export default UnitOverview;
