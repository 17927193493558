import React from 'react';

import ClauseContext from './Context';
import { DealUserParty } from '../../../../../generated/graphql';
import { Button } from '../../../../../shared/button';

type CommentButtonsProps = {
    complete?: boolean;
};

const CommentButtons = ({ complete }: CommentButtonsProps) => {
    const { tags, deleted, currentUserParty, setDeleting, setSwapping, setShowCantFind, setResponding } =
        React.useContext(ClauseContext);

    return (
        <>
            {/* Hide UI while waiting for server response (due to optmistic UI) */}
            {!complete && currentUserParty === DealUserParty.Landlord && (
                <div className="flex flex-row justify-start items-center gap-2 mt-1">
                    {tags?.indexOf('comment_clause') === -1 ? (
                        <>
                            {' '}
                            <Button
                                onClick={() => {
                                    if (deleted) setDeleting && setDeleting(true);
                                    else setSwapping && setSwapping(true);
                                }}
                                background="newTeal-main"
                                text="white"
                                className="px-4 text-sm font-semibold"
                                height="10"
                            >
                                {deleted ? 'Delete' : 'Change'} Clause
                            </Button>
                            <Button
                                onClick={() => setResponding!(true)}
                                background="transparent"
                                text="black"
                                border="black"
                                className="px-4"
                                height="10"
                            >
                                Decline Change
                            </Button>
                        </>
                    ) : (
                        <Button
                            onClick={() => {
                                setShowCantFind && setShowCantFind(true);
                            }}
                            background="newTeal-main"
                            text="white"
                            className="px-4 text-sm font-semibold"
                            height="10"
                        >
                            Respond
                        </Button>
                    )}
                </div>
            )}
        </>
    );
};

export default CommentButtons;
